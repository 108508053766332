import React, { Component } from "react";
import { connect } from "react-redux";

import {
  KeyboardView,
  Wrapper,
  Copy,
  Row,
  Bold,
  Title,
  Container
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  GhostButton,
  BottomNavBar,
  InputBox
} from "app/NativeComponents/snippets";

import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

import ChangePhone from "./ChangePhone";
import {
  popSidePanel,
  pushSidePanel,
  updateUser,
  dismissMobileKeyboard,
  displayIntercom
} from "app/NativeActions";

class VerifyPhone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verification_code: "",
      submitting: false,
      loading: false,
      new_phone_loading: false,
      phone_number: props.user?.phone
    };

    this._verification_input = React.createRef();
    this._phone_input = React.createRef();
  }

  changePhoneToggle(toggle) {
    this.setState({
      change_phone_toggle: toggle
    });
  }

  setPhone() {
    dismissMobileKeyboard();

    this.props.updateUser({
      token: this.props.token,
      type: "change_verify_phone",
      payload: {
        phone: this.state.phone_number
      },
      onLoading: () => {
        this.setState({
          new_phone_loading: true
        });
      },
      onError: () => {
        this.setState({
          new_phone_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          new_phone_loading: false
        });
        this.handleBack();
      }
    });
  }

  componentDidMount() {
    if (this.props.user?.phone_verified == 1) {
      this.handleBack();
    } else if (this.props.user?.phone_sent == 0) {
      if (!this.props.loading) {
        this.props.updateUser({
          token: this.props.token,
          type: "send_verify_phone",
          onLoading: () => {
            this.setState({
              loading: true
            });
          },
          onError: () => {
            this.setState({
              loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              loading: false
            });
          }
        });
      }
    }
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  verifyPhone() {
    dismissMobileKeyboard();

    if (this.state.verification_code && !this.props.loading) {
      this.props.updateUser({
        token: this.props.token,
        type: "verify_phone",
        payload: {
          verification_code: this.state.verification_code
        },
        onLoading: () => {
          this.setState({
            submitting: true
          });
        },
        onError: () => {
          this.setState({
            submitting: false
          });
        },
        onSuccess: results => {
          this.setState({
            submitting: false
          });

          this.handleBack();
        }
      });
    }
  }

  renderBody() {
    return (
      <>
        <KeyboardView style={{ flex: 1 }}>
          <OnboardingText
            slug="verifyPhone"
            image_size={50}
            style={{
              padding: 25
            }}
            innerStyle={{
              paddingLeft: 25
            }}
          />

          {!!this.props.user?.phone ? (
            <>
              <Wrapper style={{ padding: 25 }}>
                <Copy style={{ textAlign: "center" }}>
                  We sent a verification code via SMS to your phone number:{" "}
                  <Bold>{this.props.user.phone}.</Bold> Do not share this code
                  with anyone.
                </Copy>
              </Wrapper>

              <InputBox
                input_ref={this._verification_input}
                autoFocus={this.props.device == "mobile" ? false : true}
                name="verify_phone"
                disabled={this.state.submitting}
                returnKeyType="done"
                placeholder={
                  this.props.isMobile ? "Verify" : "Enter Verification Code"
                }
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    verification_code: value
                  });
                }}
                blurOnSubmit={true}
                value={this.state.verification_code}
                input_type="text"
                require_confirm={true}
                has_changed={true}
                submit_button_primary={true}
                submit_button_title={"Verify Phone Number"}
                onSubmit={() => this.verifyPhone()}
                onSubmitEditing={() => {
                  this.verifyPhone();
                }}
                loading={this.state.submitting}
                style={{ borderRadius: 5, margin: 5 }}
              />
            </>
          ) : (
            <>
              <Wrapper style={{ padding: 25 }}>
                <Copy style={{ textAlign: "center" }}>
                  Add your phone number to your account. We use this so you can
                  get calls back when sending mail.
                </Copy>
              </Wrapper>

              <InputBox
                input_ref={this._phone_input}
                autoFocus={this.props.device == "mobile" ? false : true}
                name="phone"
                disabled={this.state.new_phone_loading}
                returnKeyType="done"
                placeholder={"Phone Number"}
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    phone_number: value
                  });
                }}
                blurOnSubmit={true}
                value={this.state.phone_number}
                type="text"
                mask_type={"cel-phone"}
                mask={"(999) 999-9999"}
                require_confirm={true}
                has_changed={true}
                submit_button_primary={true}
                submit_button_title={"Submit"}
                onSubmit={() => this.setPhone()}
                autoComplete={"sms-otp"}
                textContentType={"oneTimeCode"}
                onSubmitEditing={() => {
                  this.setPhone();
                }}
                loading={this.state.new_phone_loading}
                style={{ borderRadius: 5, margin: 5 }}
              />
            </>
          )}

          <InlineButton
            id="intercom_button"
            button_type="full"
            onPress={() => {
              displayIntercom();
            }}
          >
            Need help? Chat with us.
          </InlineButton>
        </KeyboardView>
        {!!this.props.user?.phone ? (
          <BottomNavBar>
            <Row style={{ justifyContent: "space-between" }}>
              <InlineButton
                loading={this.state.loading}
                onPress={() => {
                  dismissMobileKeyboard();
                  if (!this.props.loading) {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "resend_verify_phone",
                      onLoading: () => {
                        this.setState({
                          loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          loading: false
                        });
                      },
                      onSuccess: results => {
                        this.setState({
                          loading: false
                        });
                      }
                    });
                  }
                }}
              >
                {this.state.loading ? "Sending SMS..." : "Resend SMS"}
              </InlineButton>

              <InlineButton
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "verify_use_different_phone",
                    overlay: true
                  });
                }}
              >
                Use A Different Phone Number
              </InlineButton>
            </Row>
          </BottomNavBar>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <Container>
        {!this.props.onboarding || this.props.device == "mobile" ? (
          <NewHeader title="Verify Phone Number" />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Title>Verify Phone Number</Title>
          </Wrapper>
        )}

        {this.renderBody()}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, property_map, settings }) => {
  const { token, user, onboarding } = auth;
  const { device, platform, isMobile } = native;
  const { loading } = settings;

  return {
    onboarding,
    token,
    user,
    device,
    isMobile,
    platform,
    loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  updateUser
})(VerifyPhone);
