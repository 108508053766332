import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class HOA extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, offset: 0 };
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    const { property, search, colors } = this.props;
    const feeFrequency = { "M": "Monthly","S": "Semi-Annual", "Q" : "Quarterly", "A":"Annual" };
    return (
      <>
        {"HOA Information".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "HOA Name".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "HOA Type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "HOA Fee".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "HOA Fee Frequency".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Second HOA Name".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Second HOA Type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Second HOA Fee".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Second HOA Fee Frequency"
          .toLowerCase()
          .indexOf(search?.toLowerCase()) !== -1 ||
        !search ? (
          <Card
            id={"hoa-information"}
            label={"HOA Information"}
            style={{ paddingTop: 10, marginTop: 15 }}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y
              });
            }}
          >
            <Row
              style={
                this.props.expanded
                  ? {
                      flexDirection: "row",
                      alignItems: "stretch",
                      flexWrap: "wrap"
                    }
                  : { flexDirection: "column", alignItems: "stretch" }
              }
            >
              <InformationItem
                style={{ flex: 1 }}
                item={property?.HOA1Name}
                label={"HOA Name:"}
                hiddenFromNonSubs={true}
                format={"name"}
                helper={"hoa_name"}
              />

              {this.state.more_info || !!search ? (
                <>
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.HOA1Type}
                    label={"HOA Type:"}
                    hiddenFromNonSubs={true}
                    format={"name"}
                    helper={"hoa_type"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.HOA1FeeValue}
                    label={"HOA Fee:"}
                    hiddenFromNonSubs={true}
                    format={"money"}
                    helper={"hoa_fee"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={feeFrequency[property?.HOA1FeeFrequency]?feeFrequency[property?.HOA1FeeFrequency]:property?.HOA1FeeFrequency}
                    label={"HOA Fee Frequency:"}
                    hiddenFromNonSubs={true}
                    helper={"hoa_fee_frequency"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.HOA2Name}
                    label={"Second HOA Name:"}
                    hiddenFromNonSubs={true}
                    format={"name"}
                    helper={"second_hoa_name"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.HOA2Type}
                    label={"Second HOA Type:"}
                    hiddenFromNonSubs={true}
                    format={"name"}
                    helper={"second_hoa_type"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.HOA2FeeValue}
                    label={"Second HOA Fee:"}
                    hiddenFromNonSubs={true}
                    format={"money"}
                    helper={"second_hoa_fee"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={feeFrequency[property?.HOA2FeeFrequency]?feeFrequency[property?.HOA2FeeFrequency]:property?.HOA2FeeFrequency}
                    label={"Second HOA Fee Frequency:"}
                    hiddenFromNonSubs={true}
                    helper={"second_hoa_fee_frequency"}
                  />
                </>
              ) : null}
            </Row>
            {!search && this.state.more_info ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: false
                    },
                    () => {
                      if (this.props.device === "desktop") {
                        this.props.scrollToSection({
                          id: "hoa-information",
                          offset: this.state.offset
                        });
                      } else {
                        this._interval = setTimeout(() => {
                          this.props.scrollToSection({
                            id: "hoa-information",
                            offset: this.state.offset
                          });
                        }, 50);
                      }
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-up"}
              >
                Less Info
              </InlineButton>
            ) : !search ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: true
                    },
                    () => {
                      this.props.scrollToSection({
                        id: "hoa-information",
                        offset: this.state.offset
                      });
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-down"}
              >
                More Info
              </InlineButton>
            ) : null}
          </Card>
        ) : null}
      </>
    );
  }
}

export default HOA;
