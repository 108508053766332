import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Icon,
  Button,
  Bold,
  Wrapper,
  Row,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SpinWrapper,
  SearchBar,
  SmallLabel,
  List,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLeads,
  updateLead,
  showSuccess,
  determineMainOwnerInfo,
  numberWithCommas
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import LeadActionsButton from "app/DealMachineCore/components/Leads/SearchAndFilter/ActionButtons/LeadActionsButton";
import moment from "moment";
class ViewListHistoryItem extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      loading: false,
      refreshing: false,
      limit: 25,
      loaded_all: false,
      list_leads: [],
      search: "",
      list_history_item: panel_data?.list_history_item || "",
      total_number_of_leads:
        panel_data?.list_history_item?.number_of_leads || 0,
      subtitle: panel_data?.subtitle || "",
      selected_leads: [],
      selected_all_leads: false
    };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  componentDidUpdate(prevProps, prevState) {}

  getData({ load_type }) {
    const { list_history_item, limit, search } = this.state;

    this.props.getLeads({
      token: this.props.token,
      load_type: load_type,
      sort_by: "date_created_desc",
      list_id: list_history_item?.list_id,
      list_history_id:
        list_history_item.change_type +
        "|" +
        list_history_item.source_type +
        "|" +
        moment(list_history_item.history_date).format("YYYY-MM-DD"),
      search,
      begin: load_type === "load_more" ? this.state.list_leads?.length : 0,
      limit,
      noredux: true,
      onLoading: () => {
        this.setState({
          loading: load_type == "refresh" ? false : true,
          refreshing: load_type == "refresh" ? true : false
        });
      },
      onError: () => {
        this.setState({
          loading: false,
          refreshing: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false,
          refreshing: false,
          loaded_all: results?.properties.length < limit ? true : false,
          list_leads: results?.properties
            ? load_type === "load_more"
              ? [...this.state.list_leads, ...results?.properties]
              : results?.properties
            : this.state.list_leads
        });
      }
    });
  }
  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  handleBack() {
    this.props.popSidePanel();

    if (this.state.panel_data?.onBack) {
      this.state.panel_data.onBack();
    }
  }

  handleCheckboxSelect = item => {
    const { selected_leads } = this.state;

    if (selected_leads.find(x => x.id == item.id)) {
      this.setState({
        selected_leads: selected_leads.filter(x => x.id !== item.id)
      });
    } else {
      this.setState({
        selected_leads: [...selected_leads, item]
      });
    }
  };

  renderCheckbox = item => {
    const { selected_leads, selected_all_leads } = this.state;
    const isChecked =
      selected_leads.find(x => x.id == item.id) || selected_all_leads;
    return (
      <Button
        onPress={() => {
          if (!selected_all_leads) {
            this.handleCheckboxSelect(item);
          }
        }}
        disabled={selected_all_leads}
        style={{
          margin: 12,
          width: 40,
          height: 40,

          alignItems: "center",
          justifyContent: "center",
          textAlign: "center"
        }}
      >
        <Icon
          icon={isChecked ? "check-box" : "check-box-outline-blank"}
          size={22}
        />
      </Button>
    );
  };

  render() {
    const { colors } = this.props;
    const {
      limit,
      loaded_all,
      refreshing,
      loading,
      list_leads,
      list_history_item,
      search,
      selected_all_leads,
      selected_leads
    } = this.state;

    return (
      <Container>
        <NewHeader
          title="Viewing History"
          subtitle={this.state.subtitle}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              value={search}
              style={{
                margin: 10,
                marginRight: 0,
                borderWidth: 0,
                borderBottomWidth: 0
              }}
              title="Search Leads"
              onChange={value => {
                this.setState(
                  {
                    search: value
                  },
                  () => {
                    clearInterval(this._search_interval);
                    this._search_interval = setTimeout(() => {
                      this.getData({ load_type: "refresh" });
                    }, 250);
                  }
                );
              }}
            />
          </Wrapper>
          <LeadActionsButton
            {...this.props}
            ghost_button={false}
            include_all_buttons={true}
            not_a_list={true}
            selected_all={selected_all_leads}
            selected_leads={selected_leads}
            active_list_tab={{ id: list_history_item.list_id }}
            list_history_id={
              list_history_item.change_type +
              "|" +
              list_history_item.source_type +
              "|" +
              moment(list_history_item.history_date).format("YYYY-MM-DD")
            }
            filtered_count={list_history_item.number_of_leads}
            clearAll={() => {
              const numberOfLeads = selected_all_leads
                ? list_history_item.number_of_leads
                : selected_leads.length;
              this.props.showSuccess(
                numberOfLeads == 1
                  ? "1 lead updated"
                  : numberOfLeads + " leads updated.",
                "Success"
              );
            }}
          />
        </Row>

        <Row style={{ margin: 10, marginLeft: 20 }}>
          {(selected_all_leads || selected_leads.length > 0) && (
            <Copy style={{ marginRight: 5 }}>
              {selected_all_leads
                ? list_history_item.number_of_leads
                : selected_leads.length}{" "}
              lead{selected_leads.length == 1 ? "" : "s"} selected.
            </Copy>
          )}
          {!selected_all_leads &&
            selected_leads.length != list_history_item.number_of_leads && (
              <Copy style={{ marginRight: 5 }}>
                {list_history_item.number_of_leads - selected_leads.length} lead
                {list_history_item.number_of_leads - selected_leads.length == 1
                  ? ""
                  : "s"}{" "}
                unselected.
              </Copy>
            )}
          <Button
            onPress={() => {
              if (
                selected_all_leads ||
                selected_leads.length == list_history_item.number_of_leads
              ) {
                this.setState({
                  selected_all_leads: false,
                  selected_leads: []
                });
              } else {
                this.setState({
                  selected_all_leads: true
                });
              }
            }}
          >
            <Copy
              style={{
                color: colors.actionable_text_color
              }}
            >
              <Bold>
                {!selected_all_leads &&
                selected_leads.length != list_history_item.number_of_leads
                  ? "Select All"
                  : "Clear Selection"}
              </Bold>
            </Copy>
          </Button>
        </Row>

        {(list_leads.length == 0 && loading) || refreshing ? (
          <SpinWrapper text="Loading Leads" />
        ) : list_leads.length == 0 ? (
          <Wrapper style={{ padding: 25 }}>
            <Copy>There is nothing to display.</Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            style={{ flex: 1 }}
            items={list_leads}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              let owner_name = "";
              const owner_info = determineMainOwnerInfo(item);
              owner_name = owner_info?.owner_name;

              const owner_updated =
                item?.deal?.lead_data_update_confirmation
                  ?.owner_information_updated == 1
                  ? true
                  : false;

              return (
                <Row>
                  <Wrapper>{this.renderCheckbox(item)}</Wrapper>
                  <SelectItem
                    key={"lead_" + index}
                    style={{ marginTop: 0 }}
                    subtext={owner_updated ? "Owner Updated" : ""}
                    subtextStyle={{ color: colors.orange_color }}
                    description={item.property_address_full}
                    select_type="none"
                    icon="keyboard-arrow-right"
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "property",
                        id: item.property_id,
                        overlay: true,
                        focus_side_panel:
                          this.props.user?.user_settings?.property_expanded ===
                          "not_expanded"
                            ? false
                            : true,
                        data: {
                          property: item
                        }
                      });
                    }}
                    renderLeft={() => {
                      return (
                        <Wrapper
                          style={{
                            padding: 15,
                            paddingLeft: 25,
                            paddingRight: 0
                          }}
                        >
                          <LeadMainPhoto
                            property={item}
                            height={parseInt(50)}
                            style={{
                              width: parseInt(50),
                              margin: 0
                            }}
                          />
                        </Wrapper>
                      );
                    }}
                  >
                    {owner_name}
                  </SelectItem>
                </Row>
              );
            }}
            canRefresh={true}
            onRefresh={() => {
              this.getData({ load_type: "refresh" });
            }}
            is_refreshing={refreshing}
            canLoadMore={
              !loaded_all && !loading && !refreshing && list_leads.length > 0
            }
            isLoadingMore={loading && !refreshing && list_leads.length > 0}
            onLoadMore={() => {
              if (!loaded_all && refreshing != true && loading != true) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return {
    token,
    user,
    device,
    colors
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLeads,
  showSuccess,
  updateLead
})(ViewListHistoryItem);
