import React, { Component } from "react";
import {
  Scroll,
  Wrapper,
  Button,
  Icon,
  ProfilePic,
  Row,
  Copy,
  Bold,
  Spin
} from "app/NativeComponents/common";

import { SelectItem, SpinWrapper } from "app/NativeComponents/snippets";

class Mentions extends Component {
  handleSelection(username, user) {
    var comment = this.props.message.slice(
      0,
      this.props.message.length - this.props.mention_keyword.length
    );
    this.props.updateMessageProp({
      prop: "message",
      value: comment + "@" + username + " "
    });
    this.props.updateMessageProp({ prop: "mention_keyword", value: "" });
    this.props.updateMessageProp({ prop: "mention_prev_char", value: " " });
    this.props.updateMessageProp({ prop: "mention_tracker", value: false });
  }

  filterData() {
    //const max_limit = 2;
    const member_array = this.props.team_members;
    var output_array = [];

    if (this.props.mention_tracker) {
      if (member_array.length > 0) {
        for (var i = 0; i < member_array.length; i++) {
          const member = member_array[i];
          if (member.id != this.props.user.id) {
            const keyword = this.props.mention_keyword.replace("@", "");
            if (
              member.username.toLowerCase().startsWith(keyword.toLowerCase())
            ) {
              output_array.push(member);
            } else if (
              member.firstname.toLowerCase().startsWith(keyword.toLowerCase())
            ) {
              output_array.push(member);
            }
          }
        }
      }
    }
    return output_array;
  }

  render() {
    if (this.props.team_members_loading && this.props.mention_tracker) {
      return <SpinWrapper text="Loading Team" />;
    }

    if (this.props.mention_tracker && this.filterData().length > 0) {
      return (
        <Scroll
          style={{
            maxHeight: 300,
            margin: 10,
            borderRadius: 5,
            borderColor: this.props.colors.border_color,
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: this.props.colors.card_color,
            ...this.props.style
          }}
          keyboardShouldPersistTaps={"always"}
        >
          {this.filterData().map((item, i) => {
            return (
              <Wrapper
                key={"mention_" + i}
                style={{
                  width: "100%"
                }}
              >
                <SelectItem
                  icon={"keyboard-arrow-right"}
                  select_type="profile_pic"
                  user={item}
                  selected={false}
                  onPress={() => this.handleSelection(item.username, item)}
                  description={"@" + item.username}
                >
                  {item.firstname && item.lastname
                    ? item.firstname + " " + item.lastname
                    : item.email}
                </SelectItem>
              </Wrapper>
            );
          })}
        </Scroll>
      );
    }
    return <Wrapper />;
  }
}

export default Mentions;
