import React, { Component } from "react";

import DefaultTag from "./DefaultTag";
import { SearchBar } from "app/NativeComponents/snippets";
class TagList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag_search: ""
    };
  }
  filterOptions(options) {
    let filtered_options = [];
    const { tag_search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title?.toLowerCase().indexOf(tag_search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  render() {
    return (
      <>
        <SearchBar
          title={"Search Default Tags"}
          onChange={value => {
            this.setState({
              tag_search: value
            });
          }}
          value={this.state.tag_search}
          style={{
            margin: 10,
            marginRight: 0,
            borderWidth: 0,
            borderBottomWidth: 0
          }}
        />
        {this.filterOptions(this.props.default_tags).map((tag, i) => {
          return <DefaultTag key={"tag_" + i} tag={tag} {...this.props} />;
        })}
      </>
    );
  }
}

export default TagList;
