import React, { PureComponent } from "react";
import { Wrapper, Copy } from "app/NativeComponents/common";

class CurrentLocation extends PureComponent {
  render() {
    return (
      <div>
        <Wrapper
          style={{
            width: 14,
            height: 14,
            borderRadius: 9,
            backgroundColor: "#31cce5",
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#fff"
          }}
        />
      </div>
    );
  }
}

export default CurrentLocation;
