import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  KeyboardView,
  Row,
  Copy,
  ExternalImage,
  Animation
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  SpinWrapper,
  BottomNavBar,
  ConfirmInlineButton,
  List
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  selectActiveProperty,
  getRouteProperties,
  selectActiveRoute,
  determineMainOwnerInfo,
  renderDate,
  removeTrackedRoute
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

class ViewRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      page_length: props?.map_side_panel_pages?.length
    };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getProperties("load");
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.active_route &&
      this.props.active_route?.id !== prevProps.active_route?.id
    ) {
      this.getProperties("refresh");
    }
  }

  handleBack() {
    this.props.selectActiveRoute(null);
    if (this.props.device === "desktop") {
      this.props.popSidePanel();
    }
  }

  getProperties(type = "load") {
    if (
      !this.props.route_properties_loading &&
      !this.props.route_properties_refreshing &&
      this.props.active_route?.id
    ) {
      this.props.getRouteProperties({
        token: this.props.token,
        begin: type == "refresh" ? 0 : this.props.route_properties_begin,
        limit: this.props.route_properties_limit,
        type: type,
        route_id: this.props.active_route?.id
      });
    }
  }

  render() {
    const { colors, isMobile, device } = this.props;

    let title = "";
    if (!!this.props.active_route?.title) {
      title = this.props.active_route?.title;
    } /*else if (this.props.active_route?.user_id == this.props.user?.id) {
      title = "Drive";
    }*/ else {
      title =
        this.props.active_route?.user_firstname &&
        this.props.active_route?.user_lastname
          ? "Drive by " +
            this.props.active_route?.user_firstname +
            " " +
            this.props.active_route?.user_lastname
          : this.props.active_route?.user_email;
    }

    return (
      <>
        {device == "desktop" ? (
          <NewHeader
            title="Drive Summary"
            leftButton={{
              icon: "close",
              onPress: this.handleBack
            }}
          />
        ) : null}

        <Wrapper
          onLayout={event => {
            const { x, y, width, height } = event.nativeEvent.layout;
            if (this.props.onResize) {
              this.props.onResize({ height });
            }
          }}
        >
          <SelectItem
            select_type="none"
            hover_icon_color={colors.orange_color}
            noPress={true}
            selected={false}
            label={isMobile ? "Drive Summary" : ""}
            subtext={renderDate(this.props.active_route?.start_time, true)}
            innerStyle={{ paddingBottom: 0 }}
          >
            {title}
          </SelectItem>
          {!!this.props.active_route?.image &&
          isMobile &&
          device == "desktop" ? (
            <Wrapper
              style={{
                height: 200,
                overflow: "hidden",
                borderRadius: 5
              }}
            >
              <ExternalImage
                image={this.props.active_route?.image}
                style={{
                  height: 200,
                  margin: 5,
                  borderRadius: 5,
                  resizeMode: "cover"
                }}
              />
            </Wrapper>
          ) : null}

          <Row style={{ padding: 25, paddingBottom: 40, margin: 5 }}>
            <Wrapper
              style={{
                flex: 1,
                paddingRight: 15
              }}
            >
              <Copy
                style={{
                  fontSize: 10,
                  whiteSpace: "nowrap"
                }}
              >
                Leads added:
              </Copy>
              <Copy
                style={{
                  whiteSpace: "nowrap"
                }}
              >
                {this.props.active_route?.properties_added || 0}
              </Copy>
            </Wrapper>
            <Wrapper
              style={{
                flex: 1,
                paddingRight: 15,
                paddingLeft: 15
              }}
            >
              <Copy
                style={{
                  fontSize: 10,

                  whiteSpace: "nowrap"
                }}
              >
                Miles driven:
              </Copy>
              <Copy
                style={{
                  whiteSpace: "nowrap"
                }}
              >
                {this.props.active_route?.total_miles || "0.00"} mi
              </Copy>
            </Wrapper>
            <Wrapper
              style={{
                flex: 1,
                paddingLeft: 15
              }}
            >
              <Copy
                style={{
                  fontSize: 10,
                  whiteSpace: "nowrap"
                }}
              >
                Time driven:
              </Copy>
              <Copy
                style={{
                  whiteSpace: "nowrap"
                }}
              >
                {this.props.active_route?.total_time || "0:00:00"}
              </Copy>
            </Wrapper>
          </Row>
        </Wrapper>
        {this.props.current_index >= 2 || device === "desktop" ? (
          <KeyboardView style={{ flex: 1 }}>
            <Animation type={"fadeIn"} style={{ flex: 1 }}>
              {(this.props.route_properties_loading &&
                this.props.route_properties.length == 0) ||
              this.props.route_properties_refreshing ? (
                <KeyboardView style={{ flex: 1 }}>
                  <SpinWrapper text="Loading leads..." />
                </KeyboardView>
              ) : this.props.route_properties.length > 0 ? (
                <>
                  <List
                    rowNumber={1}
                    style={{ flex: 1 }}
                    items={this.props.route_properties}
                    infiniteScroll={true}
                    itemStructure={({ item, index }) => {
                      let owner_type = item ? item?.owner_type : "";
                      let owner_name = "";
                      const owner_info = determineMainOwnerInfo(item);
                      owner_name = owner_info?.owner_name;
                      if (owner_info?.owner_status_info) {
                        owner_type = owner_info?.owner_status_info?.text;
                      }
                      return (
                        <LeadItem
                          key={"lead_" + index}
                          property={item}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "property",
                              id: item?.property_id,
                              focus_side_panel:
                                this.props.user?.user_settings
                                  ?.property_expanded === "not_expanded"
                                  ? false
                                  : true,
                              data: {
                                property: item
                              }
                            });
                          }}
                        />
                      );
                    }}
                    canRefresh={true}
                    onRefresh={() => {
                      this.getProperties("refresh");
                    }}
                    is_refreshing={this.props.route_properties_refreshing}
                    canLoadMore={
                      !this.props.route_properties_loaded_all &&
                      !this.props.route_properties_loading &&
                      !this.props.route_properties_refreshing &&
                      this.props.route_properties.length > 0
                    }
                    isLoadingMore={
                      this.props.route_properties_loading &&
                      !this.props.route_properties_refreshing &&
                      this.props.route_properties.length > 0
                    }
                    onLoadMore={() => {
                      if (
                        !this.props.route_properties_loaded_all &&
                        this.props.route_properties_refreshing != true &&
                        this.props.route_properties_loading != true
                      ) {
                        this.getProperties("load");
                      }
                    }}
                  />
                </>
              ) : null}
            </Animation>
          </KeyboardView>
        ) : (
          <KeyboardView style={{ flex: 1 }}></KeyboardView>
        )}

        <BottomNavBar>
          <ConfirmInlineButton
            button_type="full"
            confirm_text="Really Delete?"
            confirm_description="Your leads will not be deleted."
            confirm_icon="delete"
            loading_text="Deleting"
            loading={this.state.deleting}
            onPress={() => {
              this.props.removeTrackedRoute({
                token: this.props.token,
                route_id: this.props.active_route.route_id,
                route_type: this.props.active_route.route_type,
                onLoading: () => {
                  this.setState({
                    deleting: true
                  });
                },
                onError: () => {
                  this.setState({
                    deleting: false
                  });
                },
                onSuccess: results => {
                  this.setState({
                    deleting: false
                  });

                  this.handleBack();
                }
              });
            }}
          >
            Delete Drive
          </ConfirmInlineButton>
        </BottomNavBar>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, route }) => {
  const { token, user } = auth;
  const { device, isMobile } = native;
  const { colors } = settings;

  const {
    active_route,
    route_properties,
    route_properties_loading,
    route_properties_error,
    route_properties_begin,
    route_properties_refreshing,
    route_properties_loaded_all,
    route_properties_limit
  } = route;

  return {
    token,
    user,
    device,
    isMobile,
    colors,
    active_route,
    route_properties,
    route_properties_loading,
    route_properties_error,
    route_properties_begin,
    route_properties_refreshing,
    route_properties_loaded_all,
    route_properties_limit
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  selectActiveProperty,
  getRouteProperties,
  selectActiveRoute,
  removeTrackedRoute
})(ViewRoute);
