import React from "react";
import { Button, Wrapper, Icon } from "app/NativeComponents/common";
import { store } from "app/store";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";

const CloseButton = ({ style, onPress, color = null, icon = "close" }) => {
  const colors = store.getState().settings.colors;

  return (
    <Wrapper
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        ...style
      }}
    >
      <HeaderButton
        buttonContents={{
          onPress: onPress,
          icon: icon,
          color: color || colors.light_text_color,
          hover_color: colors.hover_color
        }}
      />
    </Wrapper>
  );
};

export { CloseButton };
