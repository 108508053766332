import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  Header,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  updateUser,
  setModal,
  toggleModal,
  uploadProfileAnimation,
  popSidePanel,
  pushSidePanel,
  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

var md5 = require("js-md5");

class EditUserPhoneNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      loading: false
    };

    this.saveUserInfo = this.saveUserInfo.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.updateUserFieldChange = this.updateUserFieldChange.bind(this);
  }

  checkIfNeedsToSave() {
    if (
      this.props.user.user_phone != this.state.user.user_phone &&
      this.state.user.user_phone
    ) {
      return true;
    }

    return false;
  }

  updateUserFieldChange({ prop, value }) {
    this.setState({
      user: {
        ...this.state.user,
        [prop]: value
      }
    });
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  saveUserInfo() {
    /*mobile*/
    dismissMobileKeyboard();

    const { firstname, lastname, username, company, user_phone, image } =
      this.state.user;
    this.props.updateUser({
      token: this.props.token,
      type: "update_user_information_phone",
      payload: {
        firstname,
        lastname,
        username,
        company,
        phone: user_phone,
        check_phone: 1,
        image,
        use_image: this.props.user.use_image
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        if (results?.user?.phone_verified != 1) {
          this.props.popSidePanel();

          this.props.pushSidePanel({
            slug: "verify_phone",
            overlay: true,
            locked: true
          });
        } else {
          this.handleBack();
        }
      }
    });
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="Edit Phone Number"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <Body
            {...this.props}
            editUser={this.state.user}
            loading={this.state.loading}
            saveUserInfo={this.saveUserInfo}
            checkIfNeedsToSave={this.checkIfNeedsToSave}
            updateUserFieldChange={this.updateUserFieldChange}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  updateUser,
  setModal,
  toggleModal,
  uploadProfileAnimation,
  popSidePanel,
  pushSidePanel
})(EditUserPhoneNumber);
