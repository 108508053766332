export const TEAM_SEARCH_CHANGED = "team_search_changed";
export const EDIT_TEAM_SEARCH_CHANGED = "edit_team_search_changed";
export const RESET_ACTIVITY = "reset_activity";
export const DRAG_PANEL = "drag_panel";
export const TOGGLE_DRAG = "toggle_drag";
export const IS_DRAGGING = "is_dragging";

export const GET_ACTIVITY = "get_activity";
export const GET_ACTIVITY_FAIL = "get_activity_fail";
export const GET_ACTIVITY_SUCCESS = "get_activity_success";
export const POST_NOTE = "post_note";
export const POST_NOTE_FAIL = "post_note_fail";
export const POST_NOTE_SUCCESS = "post_note_success";
export const EDIT_NOTE_FIELD_CHANGED = "edit_note_field_changed";
export const INIT_EDIT_NOTE = "init_edit_note";
export const TOGGLE_EDIT_NOTE = "toggle_edit_note";
export const TOGGLE_MAIL_TIMELINE = "toggle_mail_timeline";
export const INIT_MAIL_TIMELINE = "init_mail_timeline";
export const UPDATE_ACTIVITY = "update_activity";
export const TRIGGER_ACTIVITY_UPDATE = "trigger_activity_update";
export const UPDATE_NOTE = "update_note";
export const UPDATE_NOTE_FAIL = "update_note_fail";
export const UPDATE_NOTE_SUCCESS = "update_note_success";
export const REMOVE_NOTE_SUCCESS = "remove_note_success";

export const GET_TEAM_ACTIVITY = "get_team_activity";
export const GET_TEAM_ACTIVITY_FAIL = "get_team_activity_fail";
export const GET_TEAM_ACTIVITY_SUCCESS = "get_team_activity_success";

export const REFRESH_TEAM_ACTIVITY = "refresh_team_activity";
export const LOAD_MORE_TEAM_ACTIVITY = "load_more_team_activity";

export const UPDATE_DETAILED_OPTIONS = "update_detailed_options";

export const GET_ACTIVITY_PROPERTIES = "get_activity_properties";
export const GET_ACTIVITY_PROPERTIES_FAIL = "get_activity_properties_fail";
export const GET_ACTIVITY_PROPERTIES_SUCCESS =
  "get_activity_properties_success";
export const REFRESH_ACTIVITY_PROPERTIES = "refresh_activity_properties";
export const LOAD_MORE_ACTIVITY_PROPERTIES = "load_more_activity_properties";

export const SET_ACTIVITY_PROPERTIES_TYPE = "set_activity_properties_type";

export const GET_MAILERS = "get_mailers";
export const GET_MAILERS_FAIL = "get_mailers_fail";
export const GET_MAILERS_SUCCESS = "get_mailers_success";
export const REFRESH_MAILERS = "refresh_mailers";
export const LOAD_MORE_MAILERS = "load_more_mailers";

export const SWITCH_NOTIFICATION_PANEL_TAB = "switch_notification_panel_tab";

export const GET_CALENDAR = "get_calendar";
export const GET_CALENDAR_FAIL = "get_calendar_fail";
export const GET_CALENDAR_SUCCESS = "get_calendar_success";
export const REFRESH_CALENDAR = "refresh_calendar";

export const OPEN_ACTIVITY_TAB = "open_activity_tab";

export const GET_MAILERS_FAILED = "get_mailers_failed";
export const TOGGLE_TEAM_ACTIVITY_MODAL = "toggle_team_activity_modal";
export const TOGGLE_TEAM_ACTIVITY_LIST = "toggle_team_activity_list";
