import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Title,
  Wrapper,
  Copy,
  Row,
  Bold,
  Web
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  GhostButton,
  BottomNavBar,
  InputBox
} from "app/NativeComponents/snippets";

import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

import {
  popSidePanel,
  pushSidePanel,
  updateUser,
  dismissMobileKeyboard,
  displayIntercom
} from "app/NativeActions";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verification_code: "",
      submitting: false,
      loading: false
    };

    this._verification_input = React.createRef();
    this.verifyEmail = this.verifyEmail.bind(this);
  }

  componentDidMount() {
    if (this.props.user?.email_verified == 1) {
      this.handleBack();
    } else if (this.props.user?.email_sent == 0) {
      if (!this.props.loading) {
        this.props.updateUser({
          token: this.props.token,
          type: "send_verify_email",
          onLoading: () => {
            this.setState({
              loading: true
            });
          },
          onError: () => {
            this.setState({
              loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              loading: false
            });
          }
        });
      }
    }
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  verifyEmail() {
    dismissMobileKeyboard();

    if (this.state.verification_code && !this.props.loading) {
      this.props.updateUser({
        token: this.props.token,
        type: "verify_email",
        payload: {
          verification_code: this.state.verification_code
        },
        onLoading: () => {
          this.setState({
            submitting: true
          });
        },
        onError: () => {
          this.setState({
            submitting: false
          });
        },
        onSuccess: results => {
          this.setState({
            submitting: false
          });
          this.handleBack();
        }
      });
    }
  }

  renderBody() {
    return (
      <>
        <KeyboardView style={{ flex: 1 }}>
          <OnboardingText
            slug="verifyEmail"
            image_size={50}
            style={{
              padding: 25
            }}
            innerStyle={{
              paddingLeft: 25
            }}
          />
          <Wrapper style={{ padding: 25 }}>
            <Copy>
              We sent a verification code to your email address:{" "}
              <Bold>{this.props.user?.email}.</Bold> If you have trouble finding
              the email, try checking your spam folder.
            </Copy>
          </Wrapper>
          {/* DM-4055 - Syncs with the DealMachine Content Library for analytics */}
          {this.props.device == "desktop" ? (
            <Web
              title="Content Library"
              url={
                "https://start.dealmachine.com/m/success?token=" +
                window.localStorage.getItem("token") +
                "&user=" +
                window.localStorage.getItem("ajs_user_id")
              }
              width="1"
              height="1"
            />
          ) : null}

          <InputBox
            input_ref={this._verification_input}
            autoFocus={this.props.device == "mobile" ? false : true}
            name="verify_email"
            disabled={this.state.submitting}
            returnKeyType="done"
            placeholder={"Enter Verification Code"}
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                verification_code: value
              });
            }}
            blurOnSubmit={true}
            value={this.state.verification_code}
            input_type="text"
            require_confirm={true}
            has_changed={true}
            submit_button_primary={true}
            submit_button_title={
              this.props.isMobile ? "Verify" : "Verify Email Address"
            }
            onSubmit={() => this.verifyEmail()}
            onSubmitEditing={() => {
              this.verifyEmail();
            }}
            loading={this.state.submitting}
            style={{ borderRadius: 5, margin: 5 }}
          />

          <InlineButton
            id="intercom_button"
            button_type="full"
            onPress={() => {
              displayIntercom();
            }}
          >
            Need help? Chat with us.
          </InlineButton>
        </KeyboardView>

        <BottomNavBar>
          <Row style={{ justifyContent: "space-between" }}>
            <InlineButton
              loading={this.state.loading}
              onPress={() => {
                dismissMobileKeyboard();
                if (!this.props.loading) {
                  this.props.updateUser({
                    token: this.props.token,
                    type: "resend_verify_email",
                    onLoading: () => {
                      this.setState({
                        loading: true
                      });
                    },
                    onError: () => {
                      this.setState({
                        loading: false
                      });
                    },
                    onSuccess: results => {
                      this.setState({
                        loading: false
                      });
                    }
                  });
                }
              }}
            >
              {this.state.loading ? "Sending Email..." : "Resend Email"}
            </InlineButton>

            <InlineButton
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "verify_use_different_email",
                  overlay: true
                });
              }}
            >
              Use A Different Email
            </InlineButton>
          </Row>
        </BottomNavBar>
      </>
    );
  }

  render() {
    return (
      <Container>
        {!this.props.onboarding || this.props.device == "mobile" ? (
          <NewHeader title="Verify Email Address" />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Title>Verify Email Address</Title>
          </Wrapper>
        )}

        {this.renderBody()}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, property_map, settings }) => {
  const { token, user, onboarding } = auth;
  const { device, platform, isMobile } = native;
  const { loading } = settings;
  return {
    token,
    user,
    onboarding,
    device,
    isMobile,
    platform,
    loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  updateUser
})(VerifyEmail);
