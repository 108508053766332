export const GET_PARTNER_ANALYTICS = "get_partner_analytics";
export const REFRESH_PARTNER_ANALYTICS = "refresh_partner_analytics";
export const GET_PARTNER_ANALYTICS_FAIL = "get_partner_analytics_fail";
export const GET_PARTNER_ANALYTICS_SUCCESS = "get_partner_analytics_success";

export const SAVE_PARTNER_INFO = "save_partner_info";
export const SAVE_PARTNER_INFO_FAIL = "save_partner_info_fail";
export const SAVE_PARTNER_INFO_SUCCESS = "save_partner_info_success";

export const INIT_UPDATE_PARTNER = "initUpdatePartner";
export const UPDATE_PARTNER_FIELD_CHANGE = "updatePartnerFieldChange";
