import React from "react";
import { Button, CardBody, Wrapper, Copy } from "app/NativeComponents/common";

import { store } from "app/store";

const TextButton = ({
  style,
  text,
  onPress,
  color = null,
  to = null,
  id = null
}) => {
  const colors = store.getState().settings.colors;

  return (
    <Button onPress={onPress} to={to} id={id}>
      <CardBody style={style}>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Copy
            style={{
              textAlign: "center",
              color: color ? color : colors.text_color
            }}
          >
            {text}
          </Copy>
        </Wrapper>
      </CardBody>
    </Button>
  );
};

export { TextButton };
