import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  PillButton,
  Select,
  SmallHeader,
  GhostButton,
  SpinWrapper,
  SelectBox,
  InputBox,
  SelectItem,
  InlineButton,
  SmallLabel
} from "app/NativeComponents/snippets";
import BracketPicker from "./BracketPicker";
class WebhookStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      methods: [
        {
          value: "POST",
          label: "POST"
        },
        {
          value: "GET",
          label: "GET"
        },
        {
          value: "PUT",
          label: "PUT"
        }
      ],
      data: props.active_step?.data
        ? props.active_step.data
        : {
            method: "POST",
            url: "",
            include_request_signature: false,
            request_signature: "",
            use_custom_parameters: false,
            custom_parameters: []
          }
    };
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (!!this.state.data.url) {
        if (
          (this.state.data.include_request_signature &&
            !!this.state.data.request_signature) ||
          !this.state.data.include_request_signature
        ) {
          return true;
        }
      }
    }

    return false;
  }

  renderBracketVariables(content) {
    let new_content = content;
    const { enrollment_object } = this.props;
    let bracket_variables = enrollment_object?.data;
    if (bracket_variables) {
      for (const [key, value] of Object.entries(bracket_variables)) {
        new_content = new_content
          .split("{{" + key + "}}")
          .join(value.sample_value);
      }
    }

    return new_content;
  }

  renderRequestObject(custom_parameters) {
    let formatted_custom_parameters = {};

    for (let i = 0; i < custom_parameters.length; i++) {
      if (!!custom_parameters[i].param) {
        let formmatted_key = custom_parameters[i].param
          .trim()
          .replace(/[^a-zA-Z0-9_]/g, "");

        formatted_custom_parameters[formmatted_key] =
          this.renderBracketVariables(custom_parameters[i].value);
      }
    }

    return formatted_custom_parameters;
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading,
      sample_request_object
    } = this.props;
    const { data } = this.state;
    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Trigger Webhook"
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {enrollment_object_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <SelectBox
                  items={this.state.methods}
                  value={data.method}
                  placeholder="Method"
                  onFocus={() => {}}
                  onBlur={() => {}}
                  onSelect={value => {
                    this.setState({
                      data: {
                        ...data,
                        method: value
                      }
                    });
                  }}
                  onSubmitEditing={() => {}}
                />

                <InputBox
                  ref="url"
                  style={{ flex: 1 }}
                  autoFocus={false}
                  name="url"
                  returnKeyType="next"
                  placeholder="Webhook URL"
                  renderLeft={() => {
                    return (
                      <Wrapper style={{ padding: 15, paddingRight: 0 }}>
                        <Copy>https://</Copy>
                      </Wrapper>
                    );
                  }}
                  onChange={value => {
                    this.setState({
                      data: {
                        ...data,
                        url: value
                      }
                    });
                  }}
                  onSubmitEditing={() => {}}
                  blurOnSubmit={false}
                  value={data.url}
                  type="text"
                />

                <SelectItem
                  selected={data.include_request_signature}
                  onPress={() => {
                    this.setState({
                      data: {
                        ...data,
                        include_request_signature:
                          !data.include_request_signature,
                        request_signature:
                          !data.include_request_signature == false
                            ? ""
                            : data.request_signature
                      }
                    });
                  }}
                >
                  Use Request Signature
                </SelectItem>

                {data.include_request_signature ? (
                  <InputBox
                    ref="request_signature"
                    style={{ flex: 1 }}
                    autoFocus={false}
                    name="request_signature"
                    returnKeyType="next"
                    placeholder="App ID"
                    onChange={value => {
                      this.setState({
                        data: {
                          ...data,
                          request_signature: value
                        }
                      });
                    }}
                    onSubmitEditing={() => {}}
                    blurOnSubmit={false}
                    value={data.request_signature}
                    type="text"
                  />
                ) : null}

                <SelectItem
                  selected={data.use_custom_parameters}
                  onPress={() => {
                    this.setState({
                      data: {
                        ...data,
                        use_custom_parameters: !data.use_custom_parameters,
                        custom_parameters:
                          !data.use_custom_parameters == false
                            ? []
                            : [{ param: "", value: "" }]
                      }
                    });
                  }}
                >
                  Use Custom Parameters
                </SelectItem>

                {data.use_custom_parameters ? (
                  <>
                    {data.custom_parameters.map((param, i) => {
                      return (
                        <Wrapper key={"param_" + i}>
                          <Row>
                            <InputBox
                              ref="param_name"
                              style={{ flex: 1 }}
                              autoFocus={false}
                              name="param_name"
                              returnKeyType="next"
                              placeholder="Param Name"
                              onChange={value => {
                                let new_custom_params = JSON.parse(
                                  JSON.stringify(data.custom_parameters)
                                );

                                new_custom_params[i] = {
                                  param: value,
                                  value: param.value
                                };
                                this.setState({
                                  data: {
                                    ...data,
                                    custom_parameters: new_custom_params
                                  }
                                });
                              }}
                              onSubmitEditing={() => {}}
                              blurOnSubmit={false}
                              value={param.param}
                              type="text"
                            />

                            <InputBox
                              ref="param_value"
                              style={{ flex: 1 }}
                              autoFocus={false}
                              name="param_value"
                              returnKeyType="next"
                              placeholder="Param Value"
                              onChange={value => {
                                let new_custom_params = JSON.parse(
                                  JSON.stringify(data.custom_parameters)
                                );

                                new_custom_params[i] = {
                                  param: param.param,
                                  value
                                };
                                this.setState({
                                  data: {
                                    ...data,
                                    custom_parameters: new_custom_params
                                  }
                                });
                              }}
                              onSubmitEditing={() => {}}
                              blurOnSubmit={false}
                              value={param.value}
                              type="text"
                              renderRight={() => {
                                return (
                                  <BracketPicker
                                    onSelect={bracket => {
                                      let new_custom_params = JSON.parse(
                                        JSON.stringify(data.custom_parameters)
                                      );

                                      new_custom_params[i] = {
                                        param: param.param,
                                        value:
                                          param.value +
                                          "{{" +
                                          bracket.key +
                                          "}}"
                                      };
                                      this.setState({
                                        data: {
                                          ...data,
                                          custom_parameters: new_custom_params
                                        }
                                      });
                                    }}
                                    enrollment_object={enrollment_object}
                                    enrollment_object_loading={
                                      enrollment_object_loading
                                    }
                                  />
                                );
                              }}
                            />
                          </Row>
                        </Wrapper>
                      );
                    })}

                    <Row>
                      <InlineButton
                        onPress={() => {
                          this.setState({
                            data: {
                              ...data,
                              custom_parameters: [
                                ...data.custom_parameters,
                                { param: "", value: "" }
                              ]
                            }
                          });
                        }}
                        icon={"add"}
                      >
                        Add another parameter
                      </InlineButton>
                    </Row>
                  </>
                ) : null}
                <SmallLabel>Sample Request Object:</SmallLabel>
                <Card style={{ padding: 25 }}>
                  <Copy style={{ whiteSpace: "pre" }}>
                    {data.use_custom_parameters
                      ? JSON.stringify(
                          this.renderRequestObject(data.custom_parameters),
                          null,
                          "\t"
                        )
                      : JSON.stringify(sample_request_object, null, "\t")}
                  </Copy>
                </Card>
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
              disabled={!this.checkIfNeedsToSave()}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ workflow }) => {
  const {
    enrollment_object,
    sample_request_object,
    enrollment_object_loading
  } = workflow;
  return {
    enrollment_object,
    sample_request_object,
    enrollment_object_loading
  };
};

export default connect(mapStateToProps, {})(WebhookStep);
