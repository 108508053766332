export const ADDRESS_INIT = "address_init";
export const ADDRESS_FIELD_CHANGE = "settings_field_change";
export const UPDATE_RETURN_ADDRESS = "update_return_address";
export const UPDATE_RETURN_ADDRESS_FAIL = "update_return_address_fail";
export const UPDATE_RETURN_ADDRESS_SUCCESS = "update_return_address_success";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_FAIL = "update_user_fail";
export const UPDATE_USER_SUCCESS = "update_user_success";
export const UPDATE_PHOTO_TYPE = "update_photo_type";
export const RESET_SETTINGS = "reset_settings";
export const INIT_UPDATE_USER = "init_update_user";
export const UPDATE_USER_FIELD_CHANGE = "updated_user_field_change";
export const SELECT_DEFAULT_SENDING_OPTIONS = "select_default_sending_options";
export const SET_PHOTO_CAMERAROLL = "set_photo_cameraroll";

export const SELECT_STATE = "select_state";

export const SET_DARK_MODE = "set_dark_mode";
export const SET_TRACKING = "set_tracking";
export const UPDATE_DASHBOARD_SETTINGS_SUCCESS =
  "update_dashboard_settings_success";
export const GET_DASHBOARD_SETTINGS = "get_dashboard_settings";
export const GET_DASHBOARD_SETTINGS_SUCCESS = "get_dashboard_settings_success";

export const GET_MENTOR_AUTOCOMPLETE = "get_mentor_autocomplete";
export const GET_MENTOR_AUTOCOMPLETE_FAIL = "get_mentor_autocomplete_fail";
export const GET_MENTOR_AUTOCOMPLETE_SUCCESS =
  "get_mentor_autocomplete_success";
export const CLEAR_MENTOR_AUTOCOMPLETE = "clear_mentor_autocomplete";

export const BYPASS_LOCATION = "bypass_location";
