import {
  GET_VISITOR,
  GET_VISITOR_ERROR,
  GET_VISITOR_SUCCESS,
  TRIGGER_LOGOUT,
  DISMISS_ALMA
} from "app/DealMachineCore/types";

import { AppConfig, getData, saveData } from "app/NativeActions";

import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";
import { store } from "../../store";
const api = APIV2.create();

export const getChatSystemMessages = ({
  token,
  chat_type,
  message_type,
  property_id,
  property_address,
  max_allowable_offer,
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getChatSystemMessages({
        token,
        chat_type,
        message_type,
        property_id,
        property_address,
        max_allowable_offer
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getChatPrompts = ({
  token,
  chat_type,
  property_id,
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getChatPrompts({
        token,
        chat_type,
        property_id
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getAlmaChat = ({
  token,
  chat_type,
  property_id,
  property_address,
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getAlmaChat({
        token,
        chat_type,
        property_id,
        property_address
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const prePromptChat = ({
  token,
  type,
  prompt,
  centerLat,
  centerLng,
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getAlmaChat({
        token,
        chat_type: "pre_prompt",
        type,
        prompt,
        centerLat,
        centerLng
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const updateAlmaChat = ({
  token,
  type,
  chat_type,
  property_id,
  property_address,
  chat_id,
  message_id,
  feedback,
  display_content,
  content,
  messages_json,
  role,
  prompt_slug,
  answer_slug,
  answer_group,
  follow_up_slug,
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .updateAlmaChat({
        token,
        type,
        chat_type,
        property_id,
        property_address,
        chat_id,
        message_id,
        feedback,
        display_content,
        content,
        role,
        messages_json,
        prompt_slug,
        answer_slug,
        answer_group,
        follow_up_slug
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};
export const getPropertiesFromPrompt = ({
  token,
  prompt,
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getPropertiesFromPrompt({
        token,
        prompt
      })
      .then(response => {
        if (response?.data?.data) {
          onSuccess(response?.data?.data);
        }
      });
  };
};

export const getFormattedDataForOpenAI = ({
  token,
  property_ids,
  data_type = "property",
  onError = () => {},
  onLoading = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    api
      .getFormattedDataForOpenAI({
        token,
        property_ids,
        data_type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response?.data?.results);
        }
      });
  };
};

export const dismissAlma = () => {
  return {
    type: DISMISS_ALMA
  };
};
