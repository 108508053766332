import React, { PureComponent } from "react";
import { store } from "app/store";

import {
  Wrapper,
  Gradient,
  Row,
  StatusBarStyle,
  Title,
  Copy
} from "app/NativeComponents/common";
import HeaderButton from "./HeaderButton";

class NewHeader extends PureComponent {
  render() {
    const colors = store.getState().settings.colors;
    const platform = store.getState().native.platform;
    const isIphoneX = store.getState().native.isIphoneX;

    const {
      style,
      outerStyle,
      backgroundColor = colors.gradient_color_1,
      backgroundColor2 = colors.gradient_color_2,
      statusBarStyle = "light-content",
      statusBarHidden = false,
      title,
      subtitle,
      title_color = colors.white_text_color,

      leftButton = null,
      rightButton = null,

      renderRight = null,
      renderLeft = null,

      renderTitleComponent = null
    } = this.props;

    return (
      <>
        <StatusBarStyle
          statusBarStyle={statusBarStyle ? statusBarStyle : "light-content"}
        />
        <Gradient
          style={{
            height: isIphoneX ? 105 : platform == "ios" ? 85 : 75,
            ...outerStyle
          }}
          color1={backgroundColor}
          color2={backgroundColor2 ? backgroundColor2 : backgroundColor}
        >
          <Wrapper
            style={{
              height: isIphoneX ? 105 : platform == "ios" ? 85 : 75,
              display: "flex",
              justifyContent: "center",
              ...style
            }}
          >
            <Wrapper
              style={{
                /*native*/
                paddingTop: isIphoneX ? 40 : platform == "ios" ? 20 : 10,
                ...style
              }}
            >
              <Row
                style={{
                  width: "100%",
                  height: "100%"
                }}
              >
                {renderLeft ? renderLeft() : null}
                {leftButton ? (
                  <HeaderButton buttonContents={leftButton} />
                ) : null}
                <Wrapper
                  style={{
                    flex: 1,
                    paddingRight: rightButton ? 0 : 15,
                    paddingLeft: leftButton ? 0 : 15
                  }}
                >
                  {renderTitleComponent ? (
                    renderTitleComponent()
                  ) : (
                    <>
                      <Title style={{ color: title_color }}>{title}</Title>
                      {!!subtitle ? (
                        <Copy style={{ color: title_color, fontSize: 12 }}>
                          {subtitle}
                        </Copy>
                      ) : null}
                    </>
                  )}
                </Wrapper>

                {rightButton ? (
                  <HeaderButton buttonContents={rightButton} />
                ) : null}

                {renderRight ? renderRight() : null}
              </Row>
            </Wrapper>
          </Wrapper>
        </Gradient>
      </>
    );
  }
}

export { NewHeader };
