import React, { Component } from "react";
import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Icon,
  Bold,
  Row,
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";
import moment from "moment";
import {
  formatAddress,
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata,
} from "app/NativeActions";
import { store } from "app/store";
import TimelineItem from "./TimelineItem";
import Screenshot from "./Screenshot";

class MailerTimeline extends Component {
  createMailArrayFromMailer = (mailer) => {
    let mailArray = [];
    if (mailer.date_created && mailer.mail_provider != "ballpoint") {
      mailArray.push({
        icon: "print",
        title: "Mail Printed",
        date: mailer.date_created,
        justDate: false,
      });
    }
    if (mailer.in_transit_date && mailer.mail_provider != "ballpoint") {
      mailArray.push({
        icon: "directions-bus",
        title: "Mail In Transit",
        date: mailer.in_transit_date,
        justDate: false,
      });
    }
    if (mailer.in_local_area_date && mailer.mail_provider != "ballpoint") {
      mailArray.push({
        icon: "my-location",
        title: "Mail In Local Area",
        date: mailer.in_local_area_date,
        justDate: false,
      });
    }
    if (
      mailer.processed_for_delivery_date &&
      mailer.mail_provider != "ballpoint"
    ) {
      mailArray.push({
        icon: "mail",
        title: "Mail Delivered",
        date: mailer.processed_for_delivery_date,
        justDate: false,
      });
    }
    if (mailer.re_routed_date && mailer.mail_provider != "ballpoint") {
      mailArray.push({
        icon: "refresh",
        title: "Mail Rerouted",
        date: mailer.re_routed_date,
        justDate: false,
      });
    }
    if (mailer.returned_to_sender_date && mailer.mail_provider != "ballpoint") {
      mailArray.push({
        icon: "error",
        title: "Mail Returned To Sender",
        date: mailer.returned_to_sender_date,
        justDate: false,
      });
    }
    if (mailer.in_transit_date && mailer.mail_provider == "ballpoint") {
      mailArray.push({
        icon: "print",
        title: "Received by Provider",
        date: mailer.in_transit_date,
        justDate: false,
      });
    }
    if (
      mailer.in_transit_date &&
      mailer.mail_provider == "ballpoint" &&
      moment() >= moment(mailer.in_transit_date).add(5, "days")
    ) {
      mailArray.push({
        icon: "directions-bus",
        title: "In Transit",
        tooltip: this.renderBallpointToolTip(),
        date: moment(mailer.in_transit_date).add(5, "days"),
        justDate: false,
      });
    }
    if (mailer.date_created && mailer.mail_provider == "ballpoint") {
      mailArray.push({
        icon: "send",
        title: "Sent to Provider",
        date: mailer.date_created,
        justDate: false,
      });
    }
    if (
      mailer.mail_provider == "ballpoint" &&
      this.checkBallPointDeliveredDate(mailer)
    ) {
      mailArray.push({
        icon: "mail",
        title: "Mail Delivered",
        date: this.checkBallPointDeliveredDate(mailer),
        justDate: false,
      });
    }
    return mailArray.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate - bDate;
    });
  };

  renderBallpointToolTip = () => {
    const device = store.getState().native.device;

    const { mailer, colors, user } = this.props;
    let tooltip = null;

    if (device === "mobile") {
      tooltip = (
        <MoreInfoButton
          title={"Handwritten Delivery Times"}
          content={
            "DealMachine does not receive USPS tracking information about handwritten letters. They are dependent on standard postal service delivery timelines."
          }
          style={{ alignSelf: "stretch" }}
          innerStyle={{ alignSelf: "stretch" }}
          renderComponent={({ hovering, pressedIn, buttonText }) => {
            return (
              <Icon icon={"help"} color={colors.light_text_color} size={14} />
            );
          }}
        />
      );
    } else {
      tooltip = (
        <Wrapper
          tooltipPlacement={"top"}
          tooltip={
            "DealMachine does not receive USPS tracking information about handwritten letters. They are dependent on standard postal service delivery timelines."
          }
          style={{ marginLeft: 5, flex: 2 }}
        >
          <Icon icon={"help"} color={colors.light_text_color} size={14} />
        </Wrapper>
      );
    }

    return tooltip;
  };

  checkBallPointDeliveredDate = (mailer) => {
    const deliveryDateIsValid =
      mailer.processed_for_delivery_date &&
      moment(mailer.processed_for_delivery_date) >=
        moment(mailer.in_transit_date).add(15, "days");

    const shouldBeMarkedDelivered =
      moment() >= moment(mailer.in_transit_date).add(15, "days");

    const deliveryDate = shouldBeMarkedDelivered
      ? deliveryDateIsValid
        ? mailer.processed_for_delivery_date
        : moment(mailer.in_transit_date).add(15, "days")
      : null;

    return deliveryDate;
  };

  render() {
    const { mailer, colors, user } = this.props;
    if (mailer) {
      const billing_addons = store.getState().billing.billing_addons;
      const mailArray = this.createMailArrayFromMailer(mailer);
      if (
        (checkIfUserHasBillingAddon({
          billing_addons: billing_addons,
          slug: "mail_tracking",
        }) &&
          user?.user_version < 6) ||
        (user?.user_version >= 6 && checkIfUserHasMetadata("mail_tracking"))
      ) {
        return (
          <Wrapper style={{ marginLeft: 50, marginTop: 5 }}>
            <Screenshot mailer={mailer} />

            <Wrapper
              style={{
                marginTop: 10,
              }}
            >
              {mailArray?.map((m) => (
                <TimelineItem
                  display={true}
                  icon={m.icon}
                  title={m.title}
                  date={m.date}
                  colors={colors}
                  just_date={m.justDate}
                  tooltip={m.tooltip}
                />
              ))}
            </Wrapper>
          </Wrapper>
        );
      }

      return (
        <Wrapper style={{ marginLeft: 50, marginTop: 5 }}>
          <Screenshot mailer={mailer} />
          <Wrapper
            style={{
              marginTop: 10,
            }}
          >
            <TimelineItem
              display={mailer.date_created}
              icon="print"
              title="Mail Printed"
              date={mailer.date_created}
              colors={colors}
            />
          </Wrapper>
        </Wrapper>
      );
    }
    return null;
  }
}

export default MailerTimeline;
