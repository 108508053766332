import React, { PureComponent } from "react";
import { Copy } from "app/NativeComponents/common";
import { renderDate, numberWithCommas } from "app/NativeActions";

class ItemType extends PureComponent {
  render() {
    const { item, header, style } = this.props;
    switch (header.item_type) {
      case "text":
      default:
        return <Copy style={style}>{item[header.slug]}</Copy>;

      case "number":
        return (
          <Copy style={style}>
            {item[header.slug] == 0
              ? "--"
              : numberWithCommas(item[header.slug])}
          </Copy>
        );

      case "date":
        return <Copy style={style}>{renderDate(item[header.slug], true)}</Copy>;

      case "custom":
        if (header.renderComponent) {
          return header.renderComponent(item);
        }

        return <Copy style={style}>{item[header.slug]}</Copy>;
    }
  }
}

export default ItemType;
