import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Body from "./Body";

import {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  changeTab,
  popSidePanel,
  getMarketingCredits,
  getInvoices,
  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

class MarketingCredits extends Component {
  componentDidMount() {
    this.props.getMarketingCredits({
      token: this.props.token,
      type: "marketing_credits",
      begin: 0,
      limit: 50,
      utc_offset: moment().utcOffset(),
      timezone: moment.tz.guess()
    });
  }

  render() {
    return <Body {...this.props} />;
  }
}

const mapStateToProps = ({ auth, native, billing, drawer, settings }) => {
  const { token, user, onboarding } = auth;
  const { device, platform } = native;
  const { colors } = settings;
  const {
    marketing_credits,
    marketing_credits_loading,
    marketing_credits_refreshing,
    marketing_credits_loaded_all,
    marketing_credits_error,
    total_spent,
    start_date,
    end_date,
    begin
  } = billing;
  const { stats } = drawer;

  return {
    token,
    user,
    onboarding,
    device,
    platform,
    marketing_credits,
    marketing_credits_loading,
    marketing_credits_refreshing,
    marketing_credits_loaded_all,
    marketing_credits_error,
    total_spent,
    start_date,
    end_date,
    stats,
    begin,
    colors
  };
};

export default connect(mapStateToProps, {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,

  changeTab,
  popSidePanel,

  getMarketingCredits,
  getInvoices
})(MarketingCredits);
