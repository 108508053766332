import React, { Component } from "react";

import { store } from "app/store";
import { Row, Wrapper, Copy, Bold, Icon } from "app/NativeComponents/common";

import { SelectItem } from "app/NativeComponents/snippets";
import { formatAddress, formatUsPhone, renderDate } from "app/NativeActions";
class CallItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { call } = this.props;
    const colors = store.getState().settings.colors;

    return (
      <>
        <SelectItem
          onPress={() => {
            this.props.pushSidePanel({
              slug: "call_log",
              overlay: true,
              data: {
                call: call
              }
            });
          }}
          select_type={"icon"}
          select_icon={
            call?.call_format === "sms"
              ? "sms"
              : call?.call_type === "outgoing"
              ? "call-made"
              : "call-received"
          }
          icon={"keyboard-arrow-right"}
          renderContent={() => {
            const property = call?.property;
            const formatted_address = formatAddress({
              address: {
                address: property?.property_address,
                address2: property?.property_address2,
                address_city: property?.property_address_city,
                address_state: property?.property_address_state,
                address_zip: property?.property_address_zip,
                latitude: property?.location?.latitude,
                longitude: property?.location?.longitude
              }
            });
            return (
              <Wrapper style={{ flex: 1 }}>
                <Row>
                  <Wrapper style={{ marginRight: 10, marginBottom: 5 }}>
                    <Row>
                      <Copy style={{ marginRight: 10 }}>
                        <Bold>{call?.contact?.full_name}</Bold>
                      </Copy>

                      <Copy
                        style={{ color: colors.active_color, fontSize: 12 }}
                      >
                        {call?.skipped ? "Skipped" : call?.call_results_label}
                      </Copy>
                    </Row>

                    <Row>
                      <Copy>{formatUsPhone(call?.to_phone_number)}</Copy>
                    </Row>
                  </Wrapper>
                </Row>

                {call?.property ? (
                  <>
                    {call?.call_format === "sms" ? (
                      <Copy
                        style={{
                          fontSize: 12,
                          color: colors.light_text_color
                        }}
                      >
                        Texted about: {formatted_address?.line1}
                      </Copy>
                    ) : (
                      <Copy
                        style={{
                          fontSize: 12,
                          color: colors.light_text_color
                        }}
                      >
                        Called about: {formatted_address?.line1}
                      </Copy>
                    )}
                  </>
                ) : null}
              </Wrapper>
            );
          }}
        />
      </>
    );
  }
}

export default CallItem;
