import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

const InnerCopy = ({
  children,
  style,
  onPress = null,
  tooltip,
  tooltipPlacement
}) => {
  if (!!tooltip) {
    return (
      <Tooltip
        disableFocusListener
        title={tooltip}
        placement={tooltipPlacement}
      >
        <span
          style={style}
          onClick={
            onPress
              ? ev => {
                  if (ev) {
                    ev.preventDefault();
                    ev.stopPropagation();
                  }
                  onPress();
                }
              : null
          }
        >
          {children}
        </span>
      </Tooltip>
    );
  }

  return (
    <span
      style={style}
      onClick={
        onPress
          ? ev => {
              if (ev) {
                ev.preventDefault();
                ev.stopPropagation();
              }
              onPress();
            }
          : null
      }
    >
      {children}
    </span>
  );
};

export { InnerCopy };
