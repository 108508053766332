import React, { Component } from "react";
import {
  Wrapper,
  Card,
  Gradient,
  ExternalImage,
  Row,
  Copy,
  Title,
  Bold,
  ContentfulRichText
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import { loadDashboardCaseStudies, openUrl } from "app/NativeActions";

class CaseStudies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      casestudies: [],
      loading: true
    };
  }

  componentDidMount() {
    loadDashboardCaseStudies()
      .then(data => {
        if (data && data.items) {
          let total_number = data.items.length - 1;
          let random_number = Math.floor(
            Math.random() * (total_number - 0 + 1) + 0
          );
          const selected_study = data.items[random_number];
          const casestudy = {
            title: selected_study.fields.title,
            subtitle: "",
            description: selected_study.fields.description,
            image: selected_study.fields.image,
            buttonText: selected_study.fields.buttonText,
            buttonLink: selected_study.fields.buttonLink
          };

          this.setState({
            casestudies: [...this.state.casestudies, casestudy],
            loading: false
          });
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderImage(item) {
    const { colors } = this.props;
    if (item.image && item.image.fields) {
      return (
        <Wrapper
          style={{
            padding: 0,
            paddingRight: 0,
            marginBottom: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ExternalImage
            style={{
              width: 80,
              height: 80,
              borderRadius: 40,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: colors.white_color
            }}
            image={item.image.fields.file ? item.image.fields.file.url : ""}
          />
        </Wrapper>
      );
    }
  }

  renderButton(item, primary = false) {
    if (item.buttonLink && item.buttonText) {
      return (
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <PillButton
            style={{ margin: 0 }}
            onPress={() => {
              openUrl(item.buttonLink);
            }}
            primary={primary}
          >
            {item.buttonText}
          </PillButton>
        </Row>
      );
    }
  }

  renderSubtitle(item) {
    if (!!item.subtitle) {
      return (
        <Copy
          style={{
            textAlign: "center",
            paddingBottom: 15
          }}
        >
          <Bold>{item.subtitle}</Bold>
        </Copy>
      );
    }
  }

  renderCaseStudies() {
    const { colors } = this.props;

    if (!this.state.loading && this.state.casestudies.length > 0) {
      return (
        <Wrapper
          style={{
            height: "100%",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {this.state.casestudies.map((casestudy, i) => {
            return (
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 20
                }}
                key={i}
              >
                <Wrapper>
                  {this.renderImage(casestudy)}

                  <Wrapper style={{ padding: 0, flex: 1 }}>
                    <Title
                      style={{
                        color: colors.white_text_color,
                        textAlign: "center"
                      }}
                    >
                      {casestudy.title}
                    </Title>
                    <ContentfulRichText
                      style={{
                        color: colors.white_text_color,
                        textAlign: "center"
                      }}
                      richText={casestudy.description}
                    />
                    {this.renderButton(casestudy)}
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            );
          })}
        </Wrapper>
      );
    }
  }

  render() {
    const { colors } = this.props;
    return (
      <Card style={{ flex: 1 }}>
        <Gradient
          color1={colors.gradient_color_1}
          color2={colors.gradient_color_2}
          style={{
            overflow: "hidden",
            borderRadius: 5,
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {this.renderCaseStudies()}
        </Gradient>
      </Card>
    );
  }
}

export default CaseStudies;
