import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Card,
  CardBody,
  Title,
  Input,
  Icon,
  Spin,
  Button,
  Copy,
  Bold,
  KeyboardView
} from "app/NativeComponents/common";
import {
  Header,
  List,
  SearchBar,
  BottomNavBar,
  InlineButton,
  SpinWrapper,
  SelectItem,
  InlineTabs,
  UpsellButton,
  IconButton,
  ConfirmLabel,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

import {
  setStartMailerCampaignPage,
  setRightPanel,
  popSidePanel,
  setNewMailerCampaign,
  getMailerTemplates,
  renderPrice
} from "app/NativeActions";

class MailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selected_template: null,
      resend_freq_toggle: props.user?.default_resend_freq == 0 ? false : true,
      resend_freq: props.user?.default_resend_freq
        ? props.user.default_resend_freq
        : 21,
      resend_limit_toggle: props.user?.default_resend_limit == 0 ? true : false,
      resend_limit: props.user?.default_resend_limit
        ? props.user.default_resend_limit
        : 0,
      loading: false
    };

    this.confirmTemplate = this.confirmTemplate.bind(this);
  }
  componentDidMount() {
    this.getTemplates();
  }
  componentDidUpdate(prevProps) {}

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  getTemplates(load_type = "load", search = "") {
    const { mailer_templates_begin, mailer_templates_limit } = this.props;

    this.props.getMailerTemplates({
      token: this.props.token,
      load_type: load_type,
      begin: load_type !== "load_more" ? 0 : mailer_templates_begin,
      limit: mailer_templates_limit,
      search
    });
  }

  updateSearch(search) {
    this.setState({ search });

    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      this.getTemplates("refresh", search);
    }, 250);
  }

  triggerSearch(value) {
    this.props.clearAutocomplete();
  }

  confirmTemplate() {
    const {
      selected_template,
      resend_freq,
      resend_limit,
      resend_freq_toggle,
      resend_limit_toggle
    } = this.state;

    let rf = resend_freq_toggle ? resend_freq.toString() : "0";
    let rl = resend_limit_toggle ? "0" : resend_limit.toString();

    if (rf <= 0 && resend_freq_toggle) {
      rf = "21";
    }
    if (rl <= 0 && rf > 0 && !resend_limit_toggle) {
      rl = "1";
    }

    const campaign_id =
      "repeating_" + selected_template.id + "_" + rf + "_" + rl;

    this.props.selectCampaign({
      ...selected_template,
      id: campaign_id,
      selected_campaign: "",
      campaign_title: selected_template.template_title,
      campaign_type: "repeating_mail"
    });
  }

  render() {
    const {
      show_start_mailer_campaign_page,
      start_mailer_campaign_page,
      device,
      isMobile,
      colors,
      user,
      mailer_templates,
      mailer_templates_begin,
      mailer_templates_limit,
      mailer_templates_loading,
      mailer_templates_refreshing,
      mailer_templates_error,
      mailer_templates_loaded_all
    } = this.props;
    const {
      search,
      tab,
      selected_template,
      resend_freq_toggle,
      resend_limit_toggle,
      resend_freq,
      resend_limit,
      loading
    } = this.state;

    if (selected_template) {
      return (
        <>
          <KeyboardView style={{ flex: 1 }}>
            {/*
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="mail"
              label={"Confirm Selection"}
              confirmButton={"Start Mail"}
              loading={loading}
              primary={true}
              disabled={false}
              onPress={() => {}}
            />
            */}

            <SelectItem
              select_type="none"
              noPress={true}
              label="Selected Mail Sequence"
              renderRight={() => {
                return (
                  <InlineButton
                    onPress={() => {
                      this.setState({
                        selected_template: null
                      });
                    }}
                    icon_right="keyboard-arrow-right"
                  >
                    Change
                  </InlineButton>
                );
              }}
            >
              {selected_template?.template_title}
            </SelectItem>
            <Wrapper
              style={{
                margin: 5,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <MailerFlipCard
                mailer={selected_template}
                scale={
                  selected_template.template_type == "postcard6x11"
                    ? 0.2
                    : selected_template.template_type == "postcard6x9"
                    ? 0.25
                    : 0.3
                }
                back_scale={
                  selected_template.template_type == "postcard6x11"
                    ? 0.2
                    : selected_template.template_type == "postcard6x9"
                    ? 0.25
                    : 0.3
                }
              />
            </Wrapper>

            {resend_freq_toggle ? (
              <>
                <InputBox
                  style={{ flex: 1 }}
                  ref="resend_freq"
                  name="resend_freq"
                  blurOnSubmit={true}
                  autoCapitalize="none"
                  returnKeyType={"done"}
                  keyboardType={"default"}
                  placeholder="Repeat mail every (in days)"
                  onChange={value => {
                    this.setState({
                      resend_freq: value
                    });
                  }}
                  value={resend_freq.toString()}
                  onSubmitEditing={() => {}}
                  type={"number"}
                />
              </>
            ) : null}

            {resend_freq_toggle && !resend_limit_toggle ? (
              <>
                <InputBox
                  style={{ flex: 1 }}
                  ref="resend_limit"
                  name="resend_limit"
                  blurOnSubmit={true}
                  autoCapitalize="none"
                  returnKeyType={"done"}
                  keyboardType={"default"}
                  placeholder="Total number of times to send"
                  onChange={value => {
                    this.setState({
                      resend_limit: value
                    });
                  }}
                  value={resend_limit.toString()}
                  onSubmitEditing={() => {}}
                  type={"number"}
                />
              </>
            ) : null}
            <GhostButton
              primary={true}
              button_type={"full"}
              onPress={this.confirmTemplate}
              loading={this.props.start_campaign_loading}
            >
              {start_mailer_campaign_page?.number_of_leads > 1
                ? "Continue"
                : "Start Mail"}
            </GhostButton>
          </KeyboardView>

          <BottomNavBar>
            <Row style={{ justifyContent: "space-between" }}>
              <InlineButton
                select_type="toggle"
                icon={resend_freq_toggle ? "toggle-on" : "toggle-off"}
                onPress={() => {
                  this.setState({
                    resend_freq_toggle: resend_freq_toggle ? false : true,
                    resend_freq: 21,
                    resend_limit_toggle: resend_freq_toggle
                      ? false
                      : resend_limit > 0
                      ? false
                      : true
                  });
                }}
                iconColor={resend_freq_toggle ? colors.success_color : null}
              >
                Repeat Mail
              </InlineButton>
              {resend_freq_toggle ? (
                <InlineButton
                  select_type="toggle"
                  icon={
                    resend_limit_toggle > 0 && resend_freq_toggle
                      ? "toggle-on"
                      : "toggle-off"
                  }
                  onPress={() => {
                    this.setState({
                      resend_limit_toggle: resend_limit_toggle ? false : true,
                      resend_limit: resend_limit_toggle ? 12 : 0
                    });
                  }}
                  iconColor={
                    resend_limit_toggle && resend_freq_toggle
                      ? colors.success_color
                      : null
                  }
                >
                  Repeat Forever
                </InlineButton>
              ) : null}
            </Row>
          </BottomNavBar>
        </>
      );
    }

    return (
      <>
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              value={search}
              style={{ margin: 10, borderWidth: 0, borderBottomWidth: 0 }}
              title="Search Mail Templates"
              onChange={value => {
                this.updateSearch(value);
              }}
            />
          </Wrapper>
        </Row>

        <KeyboardView style={{ flex: 1 }}>
          {mailer_templates_refreshing ||
          (mailer_templates_loading && mailer_templates.length == 0) ? (
            <SpinWrapper text="Loading Mail Templates..." />
          ) : mailer_templates.length > 0 ? (
            <List
              rowNumber={1}
              style={{ flex: 1 }}
              items={mailer_templates}
              infiniteScroll={true}
              itemStructure={({ item, index }) => {
                let description =
                  item?.template_type_text +
                  " - " +
                  renderPrice(item?.mailer_price);

                let scale = 0.2;
                switch (item?.template_type) {
                  case "postcard":
                  default:
                    scale = 0.2;
                    break;
                  case "postcard6x9":
                    scale = 0.15;
                    break;
                  case "postcard6x11":
                    scale = 0.125;
                    break;
                }

                return (
                  <>
                    <SelectItem
                      select_type={"radio"}
                      //icon={"keyboard-arrow-right"}
                      selected={selected_template?.id == item?.id}
                      onPress={() => {
                        this.setState({
                          selected_template: item
                        });
                      }}
                      description={description}
                      renderRight={() => {
                        return (
                          <Row>
                            <Wrapper style={{ margin: 5 }}>
                              <MailerFlipCard
                                mailer={item}
                                scale={scale}
                                back_scale={scale}
                                include_options={false}
                              />
                            </Wrapper>
                            <IconButton
                              icon={"keyboard-arrow-right"}
                              noPress={true}
                              style={{ paddingRight: 20 }}
                            />
                          </Row>
                        );
                      }}
                    >
                      {item.template_title}
                    </SelectItem>
                  </>
                );
              }}
              canRefresh={true}
              onRefresh={() => {
                this.getTemplates("refresh");
              }}
              is_refreshing={mailer_templates_refreshing}
              canLoadMore={
                !mailer_templates_loaded_all &&
                !mailer_templates_loading &&
                !mailer_templates_refreshing &&
                mailer_templates.length > 0
              }
              isLoadingMore={
                mailer_templates_loading &&
                !mailer_templates_refreshing &&
                mailer_templates.length > 0
              }
              onLoadMore={() => {
                if (
                  !mailer_templates_loaded_all &&
                  mailer_templates_refreshing !== true &&
                  mailer_templates_loading !== true
                ) {
                  this.getTemplates("load_more");
                }
              }}
            />
          ) : mailer_templates.length == 0 ? (
            <Wrapper />
          ) : null}
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const {
    start_mailer_campaign_page,
    show_start_mailer_campaign_page,

    mailer_templates,
    mailer_template_count,
    default_bracket_variables,
    mailer_templates_begin,
    mailer_templates_limit,
    mailer_templates_loading,
    mailer_templates_refreshing,
    mailer_templates_error,
    mailer_templates_loaded_all
  } = mailer;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,

    start_mailer_campaign_page,
    show_start_mailer_campaign_page,

    mailer_templates,
    mailer_template_count,
    default_bracket_variables,
    mailer_templates_begin,
    mailer_templates_limit,
    mailer_templates_loading,
    mailer_templates_refreshing,
    mailer_templates_error,
    mailer_templates_loaded_all
  };
};

export default connect(mapStateToProps, {
  setStartMailerCampaignPage,
  setRightPanel,
  setNewMailerCampaign,
  getMailerTemplates,
  popSidePanel
})(MailTemplates);
