import {
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  LOAD_MORE_TASKS,
  LOAD_MORE_TASKS_SUCCESS,
  GET_TASK,
  GET_TASK_FAIL,
  GET_TASK_SUCCESS,
  CLEAR_TASK_LIST,
  UPDATE_TASK,
  UPDATE_TASK_FAIL,
  SET_ACTIVE_TASK_COUNT,
  UPDATE_TASK_SUCCESS,
  SET_TASKS_PAGE,
  LOAD_TASK_LIST_SUCCESS,
  LOAD_TASK_LIST_FAIL,
  SET_TASKS_LIMIT,
  MARK_TASK_COMPLETE,
  REMOVE_TASK,
  CREATE_TASK,
  CREATE_TASK_FAIL,
  CREATE_TASK_SUCCESS,
  SET_TASK_PANEL,
  SELECT_ACTIVE_TASK,
  SELECT_ACTIVE_TASK_FAIL,
  SELECT_ACTIVE_TASK_SUCCESS,
  COMPLETE_TASK_SUCCESS,
  UNDO_COMPLETE_TASK_SUCCESS
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  active_task: {},
  current_tasks: [],
  task_list: {},
  get_tasks_loading: false,
  get_tasks_refreshing: false,
  tasks_begin: 0,
  tasks_limit: 200,
  sort_by: "Most Urgent",
  filter_by: "Active Tasks",
  filter_user: 0,
  tasks_loaded_all: false,
  active_task_loading: false,
  active_task_count: false,
  task_panel: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        sort_by: action.payload.sort_by,
        filter_by: action.payload.filter_by,
        filter_user: action.payload.filter_user,
        get_tasks_loading: true
      };
    case LOAD_MORE_TASKS:
      return {
        ...state,
        get_tasks_error: "",
        get_tasks_loading: true
      };
    case SET_ACTIVE_TASK_COUNT:
      return {
        ...state,
        active_task_count: action.payload.count
      };
    case GET_TASKS_FAIL:
      return {
        ...state,
        get_tasks_error: action.payload,
        get_tasks_loading: false
      };

    case GET_TASKS_SUCCESS:
      return {
        ...state,
        get_tasks_loading: false,
        current_tasks: action.payload.tasks
      };

    case LOAD_MORE_TASKS_SUCCESS:
      return {
        ...state,
        get_tasks_loading: false,
        get_tasks_refreshing: false,
        current_tasks: state.current_tasks.concat(action.payload.tasks),
        tasks_begin: state.tasks_begin + action.payload.tasks.length,
        tasks_loaded_all:
          action.payload.tasks.length < state.current_tasks.length,

        trigger_tasks_reload: false
      };

    case SET_TASKS_PAGE:
      return {
        ...state
      };
    case GET_TASK:
      return {
        ...state,
        active_task_loading: true
      };
    case GET_TASK_FAIL:
      return {
        ...state,
        get_tasks_error: action.payload,
        active_task_loading: false
      };

    case GET_TASK_SUCCESS:
      return {
        ...state,
        active_task_loading: false,
        active_task: action.payload
      };
    case LOAD_TASK_LIST_FAIL:
      return {
        ...state,
        get_tasks_error: action.payload,
        active_task_loading: false
      };

    case LOAD_TASK_LIST_SUCCESS:
      return {
        ...state,
        active_task_loading: false,
        task_list: action.payload
      };
    case CLEAR_TASK_LIST:
      return {
        ...state,
        active_task_loading: false,
        task_list: []
      };
    case SET_TASKS_LIMIT:
      return {
        ...state
      };

    case SET_TASK_PANEL:
      return {
        ...state,
        task_panel: action.payload
      };
    case SELECT_ACTIVE_TASK:
      return {
        ...state,
        active_task: action.payload
      };
    case SELECT_ACTIVE_TASK_SUCCESS:
      return {
        ...state,
        active_task:
          state.active_task && state.active_task.id == action.payload?.id
            ? action.payload
            : state.active_task
      };

    case REMOVE_TASK:
      return {
        ...state,
        current_tasks: action.payload.task_id
          ? state.current_tasks.filter(({ id }) => id != action.payload.task_id)
          : state.current_tasks,
        active_task_count: action.payload.active_task_count
          ? action.payload.active_task_count
          : state.active_task_count
      };
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        current_tasks:
          state.current_tasks && action.payload.task
            ? [action.payload.task, ...state.current_tasks]
            : state.task_list,
        active_task_count: action.payload.active_task_count
          ? action.payload.active_task_count
          : state.active_task_count
      };

    case UNDO_COMPLETE_TASK_SUCCESS:
    case COMPLETE_TASK_SUCCESS:
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        active_task:
          state.active_task &&
          action.payload.task &&
          state.active_task.id == action.payload?.task_id
            ? action.payload.task
            : state.active_task,
        current_tasks:
          state.current_tasks && action.payload.task
            ? state.current_tasks.map(task => {
                if (task.id == action.payload?.task_id) {
                  return action.payload.task;
                }
                return task;
              })
            : state.current_tasks,
        active_task_count: action.payload.active_task_count
          ? action.payload.active_task_count
          : state.active_task_count
      };
    default:
      return state;
  }
};
