import React, { Component } from "react";
import {
  Wrapper,
  Scroll,
  CardBody,
  Copy,
  Bold,
  Form
} from "app/NativeComponents/common";

import Inputs from "./Inputs";

import {
  /*common functions*/
  renderPrice,
  numberWithCommas
} from "app/NativeActions";

class Body extends Component {
  render() {
    const { stats, editCreditPurchase, credit_amount } = this.props;
    if (stats?.billing && editCreditPurchase) {
      return (
        <>
          <Wrapper style={{ padding: 25 }}>
            <Copy>
              Select the amount to add to your account. (Minimum of $50.00)
            </Copy>
          </Wrapper>
          <Inputs {...this.props} />
          <Wrapper style={{ padding: 25 }}>
            <Copy>
              By pressing "Complete Purchase" your card will be charged{" "}
              <Bold>{renderPrice(100 * credit_amount)}</Bold> and{" "}
              <Bold>{renderPrice(100 * credit_amount)}</Bold> will be added to
              your account.
            </Copy>
          </Wrapper>
        </>
      );
    }

    return null;
  }
}

export default Body;
