import React, { Component } from "react";
import { Card, CardBody, Wrapper, Copy } from "app/NativeComponents/common";

import { RadioButton, SelectItem } from "app/NativeComponents/snippets";
import { checkIfUserHasMetadata } from "app/NativeActions";
class ClearanceLevel extends Component {
  renderDealFinderOption() {
    if (
      (checkIfUserHasMetadata("driving_for_dollars") &&
        this.props.user?.user_version >= 6) ||
      (this.props.plan_module_info.has_module &&
        this.props.card_info.bad_card != 1 &&
        this.props.user?.user_version < 6)
    ) {
      let number_of_seats = "";

      if (this.props.user?.user_version < 6) {
        if (this.props.plan_module_info.dealfinder_limit == -1) {
          number_of_seats =
            this.props.plan_module_info.dealfinder_count +
            " of unlimited seats taken.";
        } else {
          number_of_seats =
            this.props.plan_module_info.dealfinder_count +
            " of " +
            this.props.plan_module_info.dealfinder_limit +
            " seats taken.";
        }
      }

      return (
        <SelectItem
          select_type="radio"
          selected={
            this.props.edit_team_member.team_clearance_level == 0 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "team_clearance_level",
              value: 0
            });
          }}
          description={"Can only see their added leads. "}
        >
          Driver
        </SelectItem>
      );
    }
  }

  render() {
    return (
      <>
        {this.renderDealFinderOption()}

        <SelectItem
          select_type="radio"
          selected={
            this.props.edit_team_member.team_clearance_level == 1 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "team_clearance_level",
              value: 1
            });
          }}
          description={"Access to DealMachine CRM"}
        >
          Partner
        </SelectItem>

        <SelectItem
          select_type="radio"
          selected={
            this.props.edit_team_member.team_clearance_level == 2 ? true : false
          }
          onPress={() => {
            this.props.editTeamMember({
              prop: "team_clearance_level",
              value: 2
            });
          }}
          description={"Can edit and add team members. Access to billing."}
        >
          Admin
        </SelectItem>
      </>
    );
  }
}

export default ClearanceLevel;
