import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Title,
  Copy,
  InnerCopy,
  Bold,
  Row
} from "app/NativeComponents/common";
import { GhostButton, InformationItem } from "app/NativeComponents/snippets";
import moment from "moment";
class LienInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: ""
    };
  }

  updateHouseTab(tab) {
    this.setState({ tab: tab === this.state.tab ? "" : tab });
  }

  render() {
    const { property, search = "" } = this.props;

    if (this.props.property?.lien_data) {
      return (
        <Wrapper style={this.props.style}>
          {!search ? (
            <Wrapper
              style={{
                padding: 25,
                paddingTop: 15,
                paddingBottom: 15
              }}
            >
              <Copy>
                <Bold>Lien Info</Bold>

                {!!this.props.property?.last_updated_from_data_provider ? (
                  <InnerCopy
                    style={{
                      fontSize: 10,
                      color: this.props.colors.light_text_color
                    }}
                  >
                    {"  Updated "}
                    {moment(
                      this.props.property?.lien_data?.date_updated
                    ).format("MMM Do, YYYY")}
                  </InnerCopy>
                ) : null}
              </Copy>
            </Wrapper>
          ) : null}
          <Wrapper>
            <InformationItem
              item={property.lien_data.doc_title}
              label={"Document Title:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.doc_type}
              label={"Document Type:"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.doc_type_id}
              label={"Document Type Id:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.doc_category}
              label={"Document Category:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={false}
            />

            <InformationItem
              item={property.lien_data.doc_filing_date}
              label={"Document Filing Date:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.state}
              label={"State:"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.county}
              label={"County:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.amount_due}
              label={"Amount Due:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.amount_of_default}
              label={"Default Amount:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.bond_amount}
              label={"Bond Amount:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.cash_down_amount}
              label={"Cash Down Amount:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.unpaid_balance}
              label={"Unpaid Balance:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.unpaid_prinicipal_amount}
              label={"Unpaid Principal Amount:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.tax_type}
              label={"Tax Type:"}
              format={"test"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.tax_penalty_amount}
              label={"Tax Penalty Amount:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.tax_period_begin_date}
              label={"Tax Period Beginning Date:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.tax_period_end_date}
              label={"Tax Period End Date:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.tax_last_day_for_refiling}
              label={"Tax Last Date For Filing:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.date_of_death}
              label={"Date Of Death:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.date_of_divorce}
              label={"Date Of Divorce:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.federal_tax_lien_area}
              label={"Federal Tax Lien Area:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.installment_judgment}
              label={"Installment Judgment:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.judgment_grand_total}
              label={"Judgment Grand Total:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.lien_number}
              label={"Lien Number:"}
              format={"number"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.mechanics_lien_number}
              label={"Mechanics Lien Number:"}
              format={"number"}
              search={search}
              hiddenFromNonSubs={false}
            />
            <InformationItem
              item={property.lien_data.trustees_sale_number}
              label={"Trustees Sale Number:"}
              format={"number"}
              search={search}
              hiddenFromNonSubs={false}
            />
          </Wrapper>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default LienInfo;
