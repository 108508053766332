import React, { PureComponent } from "react";

import {
  Row,
  Wrapper,
  Card,
  CardBody,
  Copy,
  Bold,
  Title
} from "app/NativeComponents/common";

import { numberWithCommas } from "app/NativeActions";

import { store } from "app/store";

class LineGraphStats extends PureComponent {
  renderPercent(new_value, old_value) {
    let percent = 0;
    let percent_type = "positive";
    if (
      new_value != 0 &&
      old_value != 0 &&
      new_value != "--" &&
      old_value != "--"
    ) {
      const colors = store.getState().settings.colors;

      if (new_value > old_value) {
        percent = ((new_value - old_value) / old_value) * 100;
        percent = Math.round(parseFloat(percent));

        percent_type = "positive";
      } else {
        percent = ((old_value - new_value) / old_value) * 100;
        percent = Math.round(parseFloat(percent));

        percent_type = "negative";
      }

      if (percent_type == "positive") {
        return (
          <Wrapper
            style={{
              backgroundColor: colors.success_color_muted,
              padding: 3,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 20
            }}
          >
            <Copy>{"+" + percent + "%"}</Copy>
          </Wrapper>
        );
      } else {
        return (
          <Wrapper
            style={{
              backgroundColor: colors.orange_color_muted,
              padding: 3,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 20
            }}
          >
            <Copy>{"-" + percent + "%"}</Copy>
          </Wrapper>
        );
      }
    }
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay;
  }

  renderValueFormat(value) {
    if (value != "--" && value != 0 && value) {
      switch (this.props.format) {
        case "profit":
          return "$" + numberWithCommas(value);

        case "spend":
          return "$" + numberWithCommas(parseFloat(value / 100).toFixed(2));

        case "miles":
          return parseFloat(value).toFixed(1) + " mi";

        case "hours":
          return this.secondsToHms(value);

        default:
          return numberWithCommas(value);
      }
    }

    return value;
  }

  render() {
    const colors = store.getState().settings.colors;

    return (
      <Wrapper
        style={{
          flex: 1,
          alignSelf: "stretch"
        }}
      >
        <CardBody
          style={{
            padding: 15,
            paddingBottom: 5
          }}
        >
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Copy>{this.props.title}</Copy>

            <Wrapper
              style={{
                marginLeft: 20
              }}
            >
              {this.renderPercent(this.props.new_value, this.props.old_value)}
            </Wrapper>
          </Row>
        </CardBody>
        <CardBody
          style={{
            paddingTop: 0,
            padding: 15
          }}
        >
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Wrapper
              style={{
                alignItems: "flex-start",
                justifyContent: "center",
                marginRight: 20
              }}
            >
              <Title
                style={{
                  fontSize: 32,
                  marginBottom: 5,
                  color: colors.active_color
                }}
              >
                {this.renderValueFormat(this.props.new_value)}
              </Title>
              <Copy>{this.props.new_label}</Copy>
            </Wrapper>
            <Wrapper
              style={{
                alignItems: "flex-end",
                justifyContent: "center",
                marginLeft: 20
              }}
            >
              <Title
                style={{
                  fontSize: 32,
                  marginBottom: 5,
                  color: colors.light_text_color
                }}
              >
                {this.renderValueFormat(this.props.old_value)}
              </Title>
              <Copy>{this.props.old_label}</Copy>
            </Wrapper>
          </Row>
        </CardBody>
      </Wrapper>
    );
  }
}

export { LineGraphStats };
