import React, { Component } from "react";

import {
  Card,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { Map } from "app/NativeComponents/snippets";

class DiscoverAProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 0,
      lat: 38.584402,
      long: -90.3095469
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);
    this._interval = setInterval(() => {
      var scene = this.state.scene;
      if (scene > 6) {
        scene = 0;
      } else {
        scene = scene + 1;
      }

      this.setState({ scene: scene });

      if (
        this.state.scene == 4 ||
        this.state.scene == 5 ||
        this.state.scene == 6 ||
        this.state.scene == 7
      ) {
        this.setState({ lat: 38.585383896334, long: -90.310837179422 });
      } else {
        this.setState({ lat: 38.584402, long: -90.3095469 });
      }
    }, 2000);
  }

  renderSpinner() {
    if (this.state.scene == 4 || this.state.scene == 0) {
      return (
        <Card>
          <CardBody style={{ padding: "10px 15px" }}>
            <Row>
              <div style={{ marginRight: 10 }}>
                <Spin size={"small"} />
              </div>
              <Copy>Locating Address</Copy>
            </Row>
          </CardBody>
        </Card>
      );
    } else if (
      this.state.scene == 2 ||
      this.state.scene == 1 ||
      this.state.scene == 3
    ) {
      return (
        <Card>
          <CardBody style={{ padding: "10px 15px" }}>
            <Copy>
              <Bold>268 Main St.</Bold>
            </Copy>
            <Copy>Carmel, IN 26380</Copy>
          </CardBody>
        </Card>
      );
    } else if (
      this.state.scene == 5 ||
      this.state.scene == 6 ||
      this.state.scene == 7
    ) {
      return (
        <Card>
          <CardBody style={{ padding: "10px 15px" }}>
            <Copy>
              <Bold>590 Broadway Blvd.</Bold>
            </Copy>
            <Copy>Carmel, IN 26380</Copy>
          </CardBody>
        </Card>
      );
    }
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 320,
          textAlign: "left"
        }}
      >
        <div className="animated fadeIn">
          <Card>
            <Map
              style={{
                height: 200
              }}
              lat={parseFloat(this.state.lat)}
              long={parseFloat(this.state.long)}
              initialRegion={{
                latitude: parseFloat(this.state.lat),
                longitude: parseFloat(this.state.long),
                latitudeDelta: 0.002,
                longitudeDelta: 0.002
              }}
              dontGetPosition={true}
              onRegionChange={() => {}}
              onRegionChangeComplete={() => {}}
              withMarker={true}
              cacheEnabled={false}
              onPress={() => {}}
            />
          </Card>
          {this.renderSpinner()}
        </div>
      </div>
    );
  }
}

export default DiscoverAProperty;
