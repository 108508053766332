import React, { Component } from "react";

import { Wrapper, Card, Row, Title } from "app/NativeComponents/common";

import GetTheAppStepAB1 from "./GetTheAppStepAB1";
import GetTheAppStepAB2 from "./GetTheAppStepAB2";

class DownloadApp extends Component {
  componentDidMount() {}

  render() {
    if (
      !this.props.is_enterprise &&
      this.props.user.completed_enterprise_steps === false
    ) {
      return (
        <Wrapper style={{ alignSelf: "stretch", flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <Wrapper
              style={{
                backgroundColor: this.props.colors.background_color,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5
              }}
            ></Wrapper>
            {!this.props.user.completed_dashboard_steps ? (
              <Wrapper>
                <GetTheAppStepAB2 {...this.props} />
              </Wrapper>
            ) : (
              <Wrapper />
            )}
          </Card>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default DownloadApp;
