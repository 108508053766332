import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  GhostButton,
  SelectItem,
  InlineButton
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription
} from "app/NativeActions";

class CallOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      marketing_offer_loading: false
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/cancel/we-want-to-call-you");
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.accepted_an_offer) {
      return (
        <>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <Header1
              style={{
                textAlign: "center"
              }}
            >
              <Bold>Have your phone ready!</Bold>
            </Header1>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              Someone from our team will be calling you shortly.
            </Header3>
          </Wrapper>

          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 0
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              loading={this.state.completing}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.tabNavigation({ slug: "leads" });
              }}
              icon_right={"arrow-right-alt"}
            >
              Take Me To My Account
            </GhostButton>
            <InlineButton
              button_type={"full"}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.logout();
              }}
            >
              Log me out. I'll come back later.
            </InlineButton>
          </Wrapper>
        </>
      );
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>We're here for you, can we chat a bit more about this?</Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              We're happy to answer any and all questions you have about
              DealMachine. Click below and someone from our team will be in
              touch shortly.
            </Header3>
          </Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              loading={this.state.marketing_offer_loading}
              //marketing_slug={}
              icon_right={"arrow-right-alt"}
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "requested_churn_call",
                  property_value: "Yes"
                });
                this.props.acceptedAnOffer();
              }}
            >
              Sure, give me a call.
            </GhostButton>
            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "how_did_we_fall_short",
                  property_value: this.state.selected_options?.join(";")
                });
                this.props.nextStep();
              }}
            >
              No, I'd rather just cancel.
            </InlineButton>
            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.tabNavigation({ slug: "leads" });
                this.props.toggleChurnBuster(false);
              }}
            >
              Nevermind, I don't want to cancel.
            </InlineButton>
            <Wrapper style={{ height: 40 }} />
          </Wrapper>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    desktopMobile,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription
})(CallOffer);
