import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  List,
  SearchBar,
  SelectItem,
  Tag,
  SpinWrapper
} from "app/NativeComponents/snippets";
import { updateLead, getTags, toTitleCase } from "app/NativeActions";

class ManageTagsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false,
      selected_options: props.property?.deal?.tags || [],
      loading_options: []
    };

    this._scroll_view = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { property, all_tags, tags_loading } = this.props;

    if (
      prevState.show !== this.state.show &&
      all_tags?.length == 0 &&
      !tags_loading
    ) {
      this.props.getTags(this.props.token);
    }

    if (
      property?.property_id !== prevProps.property?.property_id ||
      (property?.deal?.tags !== prevProps.property?.deal?.tags &&
        this.state.loading_options.length == 0) ||
      (this.state.show && prevState.show !== this.state.show)
    ) {
      this.setState({
        selected_options:
          property && property.deal && property.deal.tags
            ? property.deal.tags
            : [],
        loading_options: []
      });
    }

    if (this.state.selected_options !== prevState.selected_options) {
      this.props.onLoading({
        number_of_tags: this.state.selected_options.length,
        tags: this.state.selected_options
      });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }
  noExactMatches(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (options[i].label?.toLowerCase() == search?.toLowerCase()) {
        return false;
      }
    }

    return true;
  }

  onRemoveOption(option) {
    const {
      property: { deal }
    } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "remove_tags_from_leads",
      deal_ids: deal.id,
      tag_ids: option.id,
      no_loading: true,
      onLoading: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: selected_options.filter(
            ({ id }) => id !== option.id
          ),
          loading_options: [...loading_options, option]
        });
        this.scrollToBottom();
      },
      onSuccess: results => {
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : this.props.property
          );
        }

        const { selected_options, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      },
      onError: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: [...selected_options, option],
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  onAddOption(option) {
    const {
      property: { deal }
    } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "add_tags_to_leads",
      deal_ids: deal.id,
      tag_ids: option.id,
      no_loading: true,
      onLoading: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: [...selected_options, option],
          loading_options: [...loading_options, option]
        });

        this.scrollToBottom();
      },
      onSuccess: results => {
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : this.props.property
          );
        }

        const { selected_options, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      },
      onError: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: selected_options.filter(
            ({ id }) => id !== option.id
          ),
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  onCreateOption(new_tag) {
    const {
      property: { deal }
    } = this.props;

    let option = {
      label: toTitleCase(new_tag),
      title: toTitleCase(new_tag),
      value: "new_tag",
      id: "new_tag"
    };

    this.props.updateLead({
      token: this.props.token,
      type: "add_tags_to_leads",
      deal_ids: deal.id,
      new_tag_name: new_tag,
      no_loading: true,
      onLoading: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          search: "",
          selected_options: [...selected_options, option],
          loading_options: [...loading_options, option]
        });

        this.scrollToBottom();
      },
      onSuccess: results => {
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : this.props.property
          );
        }

        const { selected_options, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id),
          selected_options: results.new_tag_id
            ? selected_options.map(option => {
                if (option.id == "new_tag") {
                  return {
                    ...option,
                    value: results.new_tag_id,
                    id: results.new_tag_id
                  };
                }
                return option;
              })
            : selected_options
        });
      },
      onError: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: selected_options.filter(
            ({ id }) => id !== option.id
          ),
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  scrollToBottom() {
    const { device } = this.props;

    if (device == "desktop" && this._scroll_view && this._scroll_view.current) {
      this._scroll_view.current.scrollTop =
        this._scroll_view.current.scrollHeight + 20;
    } else if (
      device == "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      this._scroll_view.current.scrollToEnd
    ) {
      this._scroll_view.current.scrollToEnd({ animated: true });
    }
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      all_tags,
      tags_loading,
      style,
      property,
      property: { deal },
      popoverPlacement = "bottom",
      renderComponent = () => {},
      renderComponentLoading = () => {},
      componentStyle = {}
    } = this.props;
    const { selected_options, loading_options, search, show } = this.state;
    const visible_tags = all_tags?.filter(tg => tg.hidden_tag != "1");

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={350}
        popover_height={450}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={"Manage tags for " + property.property_address}
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <Wrapper wrapper_ref={this._popover}>
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {tags_loading ? (
                <SpinWrapper text="Loading tags..." />
              ) : (
                <>
                  {selected_options.length > 0 && typeof(selected_options.map)==='function' ? (
                    <Wrapper
                      style={{
                        alignSelf: "stretch",
                        padding: 10,
                        paddingBottom: 0
                      }}
                    >
                      <Row
                        style={{
                          flexWrap: "wrap",
                          justifyContent: "flex-start"
                        }}
                      >
                        {selected_options.map((option, i) => {
                          let in_loading_options = false;
                          for (let i = 0; i < loading_options.length; i++) {
                            if (option.value == loading_options[i].value) {
                              in_loading_options = true;
                            }
                          }

                          return (
                            <Tag
                              key={"selected_option_" + i}
                              onPress={() => {
                                if (!in_loading_options) {
                                  this.onRemoveOption(option);
                                }
                              }}
                              icon={!in_loading_options ? "close" : ""}
                              loading={in_loading_options}
                              selected={true}
                            >
                              {option.title}
                            </Tag>
                          );
                        })}
                      </Row>
                    </Wrapper>
                  ) : null}
                  <Wrapper style={{ alignSelf: "stretch" }}>
                    <SearchBar
                      style={{
                        margin: 10,
                        marginTop: selected_options.length > 0 ? 5 : 10
                      }}
                      title="Search Tags"
                      onChange={value => {
                        this.setState({
                          search: value
                        });
                      }}
                      value={search}
                    />
                  </Wrapper>

                  <List
                    style={{ alignSelf: "stretch" }}
                    items={this.filterOptions(visible_tags)}
                    listHeader={() => {
                      return (
                        <>
                          {!!search && this.noExactMatches(visible_tags) ? (
                            <SelectItem
                              select_type="icon"
                              select_icon="add"
                              primary={true}
                              onPress={() => {
                                this.onCreateOption(search);
                              }}
                            >
                              Add "{search}"
                            </SelectItem>
                          ) : null}

                          {loading_options &&
                            loading_options.map((option, i) => {
                              if (option.id == "new_list") {
                                return (
                                  <SelectItem
                                    key={"option_" + i}
                                    loading={true}
                                    selected={true}
                                    onPress={() => {}}
                                  >
                                    {option.label}
                                  </SelectItem>
                                );
                              }
                              return null;
                            })}
                        </>
                      );
                    }}
                    itemStructure={({ item, index }) => {
                      let in_selected_options = false;
                      for (let i = 0; i < selected_options.length; i++) {
                        if (item.value == selected_options[i].value) {
                          in_selected_options = true;
                        }
                      }

                      let in_loading_options = false;
                      for (let i = 0; i < loading_options.length; i++) {
                        if (item.value == loading_options[i].value) {
                          in_loading_options = true;
                        }
                      }

                      return (
                        <SelectItem
                          key={"option_" + index}
                          selected={in_selected_options}
                          loading={in_loading_options}
                          onPress={() => {
                            if (!in_loading_options && property) {
                              if (in_selected_options) {
                                this.onRemoveOption(item);
                              } else {
                                this.onAddOption(item);
                              }
                            }
                          }}
                        >
                          {item.label}
                        </SelectItem>
                      );
                    }}
                  />
                </>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, property_tags }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;
  const { all_tags, tags_loading } = property_tags;

  return {
    token,
    user,
    isMobile,
    device,
    colors,

    all_tags,
    tags_loading
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getTags
})(ManageTagsButton);
