import React, { Component } from "react";

import {
  Card,
  Wrapper,
  Copy,
  Bold,
  Row,
  Title,
  Header1,
  Header2
} from "app/NativeComponents/common";
import {
  SelectItem,
  GhostButton,
  InlineButton,
  IconButton,
  CloseButton
} from "app/NativeComponents/snippets";
import {
  AppConfig,
  openUrl,
  renderPrice,
  loadPlanFromId,
  loadOnboardingText,
  startRedirect
} from "app/NativeActions";

import { store } from "app/store";

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_url: false,
      contentful_content: null,
      contentful_loading: true,
      selected_addons: []
    };
    this.getStripeCheckoutUrl = this.getStripeCheckoutUrl.bind(this);

    this.addOrRemoveAddon = this.addOrRemoveAddon.bind(this);
    this.addDialerAddonIfNecessary = this.addDialerAddonIfNecessary.bind(this);
    this.cancelPlanSelection = this.cancelPlanSelection.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      loadPlanFromId(this.props.id)
        .then(data => {
          if (data && data.items) {
            if (data.items.length > 0) {
              this.setState(
                {
                  contentful_content: data.items[0]
                    ? data.items[0].fields
                      ? data.items[0].fields
                      : null
                    : null,
                  contentful_loading: false
                },
                () => {
                  this.addDialerAddonIfNecessary();
                }
              );
            }
          }
          this.setState(() => ({ contentful_loading: false }));
        })
        .catch(err => {
          this.setState(() => ({ contentful_loading: false }));
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.plan_interval !== prevProps.plan_interval) {
      this.setState(
        {
          selected_addons: []
        },
        () => {
          this.addDialerAddonIfNecessary();
        }
      );
    }

    if (
      this.props.selected_plan !== prevProps.selected_plan &&
      !this.props.selected_plan
    ) {
      this.setState(
        {
          selected_addons: []
        },
        () => {
          this.addDialerAddonIfNecessary();
        }
      );
    }
  }

  cancelPlanSelection() {
    this.props.onPlanSelection({
      stripe_id: null,
      stripe_id_year: null
    });
  }

  addDialerAddonIfNecessary() {
    if (
      this.props.needs_dialer &&
      this.state.contentful_content?.addons &&
      this.props.plan_interval === "month" &&
      this.props.device !== "mobile"
    ) {
      const dialer_addon = this.state.contentful_content.addons.find(addon =>
        addon?.fields?.title?.toLowerCase()?.includes("dialer")
      )?.fields;
      if (dialer_addon) {
        this.addOrRemoveAddon(dialer_addon);
      }
    }
  }

  addOrRemoveAddon(addon, add_type = "add_or_remove") {
    //check if the monthly
    //we're only going to use monthly addons (if that's possible)
    let stripe_id = addon?.stripePriceIdMonthly;

    if (this.props.plan_interval === "year") {
      stripe_id = addon?.stripePriceIdYearly;
    }

    // if there is an item in the array this.state.selected_addons with the key of stripe_id where it equals the stripe_id, then remove it...unless the add_type is "add_only" then increase the quanity key by 1...otherwise add it to the array with the quanity key set to 1

    let selected_addons = this.state.selected_addons;
    let index = selected_addons.findIndex(item => item.stripe_id === stripe_id);
    if (index !== -1) {
      if (add_type === "add_only") {
        selected_addons[index].quantity += 1;
      } else if (add_type === "remove_only") {
        if (selected_addons[index].quantity > 1) {
          selected_addons[index].quantity -= 1;
        } else {
          selected_addons.splice(index, 1);
        }
      } else {
        selected_addons.splice(index, 1);
      }
    } else {
      selected_addons.push({
        stripe_id,
        quantity: 1
      });
    }

    this.setState({ selected_addons });
  }

  getStripeCheckoutUrl(include_addons = false) {
    let query_redirect_string = "";
    const queryString = window?.location?.search;
    let currentUrlParams = null;

    if (!!queryString) {
      currentUrlParams = new URLSearchParams(queryString);
    }

    if (currentUrlParams) {
      for (const [key, value] of currentUrlParams) {
        if (key !== "items") {
          query_redirect_string += "&" + key + "=" + value;
        }
      }
    }

    let cancel_url = "";

    let url =
      AppConfig().api_url + "billing/checkout/?token=" + this.props.token;

    if (this.props.plan_interval == "year") {
      url = url + `&items=${this.props.stripe_price_id_year}`;
    } else {
      url = url + `&items=${this.props.stripe_price_id_month}`;
    }

    if (
      include_addons &&
      this.state.selected_addons.length > 0 &&
      this.props.plan_interval !== "year" &&
      this.props.device !== "mobile"
    ) {
      url = url + "&addons=";
      this.state.selected_addons.map(addon => {
        if (addon.quantity > 0) {
          url = url + `${addon.stripe_id},${addon.quantity},`;
        }
      });
    }

    if (!!this.props.offer_promo) {
      url = url + "&offer_promo=" + this.props.offer_promo;
    }
    if (!!this.props.free_trial_days) {
      url = url + "&trial_days=" + this.props.free_trial_days;
    }
    if (this.props.no_trial) {
      url = url + "&no_trial=true";
    }

    if (this.props.device === "mobile") {
      url =
        url +
        "&cancel_url=https://dealmachine.app.link/refresh-account/" +
        "&success_url=https://dealmachine.app.link/refresh-account/" +
        query_redirect_string;
    } else {
      url =
        url +
        "&cancel_url=" +
        AppConfig().app_url +
        cancel_url +
        "&success_url=" +
        AppConfig().app_url +
        query_redirect_string;
    }

    return url;
  }

  renderCardWrapper(renderCard) {
    if (this.props.device === "mobile") {
      return renderCard();
    }

    if (this.props.selected_plan && !this.props.desktopMobile) {
      return (
        <Wrapper
          className={"dm-plan-select-page-overlay"}
          onClick={this.cancelPlanSelection}
        >
          <Wrapper
            className={"dm-plan-select selected animated fadeIn"}
            style={{
              padding: 40
            }}
          >
            {renderCard()}
          </Wrapper>
          <CloseButton onPress={this.cancelPlanSelection} />
        </Wrapper>
      );
    }

    return <Wrapper className={"dm-plan-select"}>{renderCard()}</Wrapper>;
  }

  render() {
    const {
      featured = false,
      title,
      description,
      buttonText,
      colors,
      desktopMobile,
      device,
      subtext,
      price,
      plan_interval,
      year_price,
      includes_items,
      marketing_slug,
      ios_plan_id
    } = this.props;

    const signup_funnel = store.getState().marketing.signup_funnel;
    const platform = store.getState().native.platform;

    const addon_quantity = this.state.selected_addons.reduce(
      (a, b) => a + b.quantity,
      0
    );

    return (
      <>
        {this.props.selected_plan && !desktopMobile ? (
          <>
            <Card
              style={
                featured
                  ? {
                      flex: 1,
                      alignSelf: device === "mobile" ? "stretch" : "center",
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderColor: colors.active_color,
                      boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                      maxWidth: 400,
                      minWidth: 320,
                      margin:
                        desktopMobile && device == "desktop" ? "10px auto" : 10
                    }
                  : {
                      flex: 1,
                      alignSelf: device === "mobile" ? "stretch" : "center",
                      boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                      borderWidth: 0,
                      maxWidth: 400,
                      minWidth: 320,
                      margin:
                        desktopMobile && device == "desktop" ? "10px auto" : 5
                    }
              }
            >
              <SelectItem
                select_type="none"
                subtext={subtext}
                noPress={true}
                selected={featured}
              >
                {title}
              </SelectItem>

              {price === 0 ? (
                <Row style={{ paddingRight: 25, paddingLeft: 25 }}>
                  <Title style={{ fontSize: 36 }}>
                    <Bold>Free</Bold>
                  </Title>
                </Row>
              ) : (
                <Row style={{ paddingRight: 25, paddingLeft: 25 }}>
                  <Title style={{ fontSize: 36 }}>
                    <Bold>
                      {plan_interval == "year"
                        ? renderPrice(year_price / 12, "no_decimal")
                        : renderPrice(price, "no_decimal")}
                    </Bold>
                  </Title>
                  <Row style={{ alignItems: "flex-end" }}>
                    <Copy style={{ marginLeft: 5 }}>{" / mo"}</Copy>
                    {plan_interval == "year" ? (
                      <Copy
                        style={{
                          marginLeft: 5,
                          color: colors.light_text_color
                        }}
                      >
                        {" - billed annually"}
                      </Copy>
                    ) : null}
                  </Row>
                </Row>
              )}

              <Wrapper style={{ padding: 25 }}>
                <Copy>{description}</Copy>
              </Wrapper>
              {includes_items.map((item, i) => {
                return (
                  <SelectItem
                    key={"item_" + i}
                    select_type="icon"
                    select_icon={"check"}
                    icon_color={colors.success_color}
                    noPress={true}
                    tooltip={!!item?.tooltip ? item.tooltip : ""}
                    tooltipPlacement={"top"}
                    innerStyle={{
                      paddingTop: 0,
                      paddingRight: 0
                    }}
                    textStyle={
                      !!item?.tooltip
                        ? {
                            textDecoration: "underline",
                            textDecorationStyle: "dotted"
                          }
                        : {}
                    }
                  >
                    {item.type === "featured" ? (
                      <Bold>{item.text}</Bold>
                    ) : (
                      item.text
                    )}
                  </SelectItem>
                );
              })}
            </Card>
          </>
        ) : null}

        {this.renderCardWrapper(() => {
          return (
            <>
              {this.props.selected_plan ? (
                <Wrapper style={{ padding: 25 }}>
                  <Header2 style={{ textAlign: "center" }}>
                    <Bold>Great choice! Confirm your selection.</Bold>
                  </Header2>
                </Wrapper>
              ) : null}
              <Card
                className={"dm-plan-select-card"}
                style={
                  featured
                    ? {
                        flex: 1,
                        alignSelf: device === "mobile" ? "stretch" : "center",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor: colors.active_color,
                        boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                        maxWidth: 400,
                        minWidth: 320,
                        margin:
                          desktopMobile && device == "desktop"
                            ? "10px auto"
                            : 10
                      }
                    : {
                        flex: 1,
                        alignSelf: device === "mobile" ? "stretch" : "center",
                        boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                        borderWidth: 0,
                        maxWidth: 400,
                        minWidth: 320,
                        margin:
                          desktopMobile && device == "desktop" ? "10px auto" : 5
                      }
                }
              >
                <SelectItem
                  select_type="none"
                  subtext={subtext}
                  noPress={true}
                  selected={featured}
                >
                  {title}
                </SelectItem>

                {price === 0 ? (
                  <Row style={{ paddingRight: 25, paddingLeft: 25 }}>
                    <Title style={{ fontSize: 36 }}>
                      <Bold>Free</Bold>
                    </Title>
                  </Row>
                ) : (
                  <Row style={{ paddingRight: 25, paddingLeft: 25 }}>
                    <Title style={{ fontSize: 36 }}>
                      <Bold>
                        {plan_interval == "year"
                          ? renderPrice(year_price / 12, "no_decimal")
                          : renderPrice(price, "no_decimal")}
                      </Bold>
                    </Title>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Copy style={{ marginLeft: 5 }}>{" / mo"}</Copy>
                      {plan_interval == "year" ? (
                        <Copy
                          style={{
                            marginLeft: 5,
                            color: colors.light_text_color
                          }}
                        >
                          {" - " +
                            renderPrice(year_price, "no_decimal") +
                            " billed annually"}
                        </Copy>
                      ) : null}
                    </Row>
                  </Row>
                )}
                <Wrapper style={{ padding: 25 }}>
                  <Copy>{description}</Copy>
                </Wrapper>
                {includes_items.map((item, i) => {
                  return (
                    <Wrapper style={{ flex: 1 }}>
                      <SelectItem
                        key={"item_" + i}
                        select_type="icon"
                        select_icon={"check"}
                        icon_color={colors.success_color}
                        noPress={true}
                        tooltip={!!item?.tooltip ? item.tooltip : ""}
                        tooltipPlacement={"top"}
                        innerStyle={{
                          paddingTop: 0,
                          paddingRight: 0
                        }}
                        textStyle={
                          !!item?.tooltip
                            ? {
                                textDecoration: "underline",
                                textDecorationStyle: "dotted"
                              }
                            : {}
                        }
                      >
                        {item.type === "featured" ? (
                          <Bold>{item.text}</Bold>
                        ) : (
                          item.text
                        )}
                      </SelectItem>
                    </Wrapper>
                  );
                })}

                <Wrapper style={{ padding: 25, paddingTop: 0 }}>
                  {this.props.selected_plan && this.props.addons ? (
                    <>
                      <InlineButton
                        selected={true}
                        button_type={"full"}
                        icon={"check"}
                        onPress={this.cancelPlanSelection}
                        pressedIn={true}
                        pressed_in_background_color={colors.active_color_muted}
                      >
                        Selected Plan
                      </InlineButton>
                    </>
                  ) : !this.props.selected_plan ? (
                    <>
                      {featured ? (
                        <GhostButton
                          button_type={"full"}
                          marketing_slug={marketing_slug}
                          loading={this.state.loading_url}
                          onPress={() => {
                            if (
                              ((this.props.addons &&
                                this.props.plan_interval !== "year") ||
                                this.props.force_select_plan) &&
                              this.props.device !== "mobile"
                            ) {
                              this.props.onPlanSelection({
                                stripe_id: this.props.stripe_price_id_month,
                                stripe_id_year: this.props.stripe_price_id_year
                              });
                            } else {
                              this.setState(
                                {
                                  loading_url: true
                                },
                                () => {
                                  if (
                                    this.props.platform == "ios" &&
                                    this.props.signup_funnel == "use_iap"
                                  ) {
                                    this.props.onPurchase(ios_plan_id, () => {
                                      this.setState({ loading_url: false });
                                    });
                                  } else {
                                    openUrl(this.getStripeCheckoutUrl(), true);
                                  }
                                }
                              );
                            }
                          }}
                          primary={false}
                          icon_right={
                            ((this.props.addons &&
                              this.props.plan_interval !== "year") ||
                              this.props.force_select_plan) &&
                            this.props.device !== "mobile"
                              ? ""
                              : "arrow-right-alt"
                          }
                          innerStyle={{
                            borderColor: colors.active_color
                          }}
                          selected={this.props.selected_plan}
                        >
                          {signup_funnel === "use_iap" && platform === "ios"
                            ? "Subscribe To Plan"
                            : buttonText}
                        </GhostButton>
                      ) : (
                        <InlineButton
                          button_type={"full"}
                          loading={this.state.loading_url}
                          marketing_slug={marketing_slug}
                          onPress={() => {
                            if (
                              ((this.props.addons &&
                                this.props.plan_interval !== "year") ||
                                this.props.force_select_plan) &&
                              this.props.device !== "mobile"
                            ) {
                              this.props.onPlanSelection({
                                stripe_id: this.props.stripe_price_id_month,
                                stripe_id_year: this.props.stripe_price_id_year
                              });
                            } else {
                              this.setState(
                                {
                                  loading_url: true
                                },
                                () => {
                                  if (
                                    this.props.platform == "ios" &&
                                    this.props.signup_funnel == "use_iap"
                                  ) {
                                    this.props.onPurchase(ios_plan_id, () => {
                                      this.setState({ loading_url: false });
                                    });
                                  } else {
                                    openUrl(this.getStripeCheckoutUrl(), true);
                                    this.setState({ loading_url: false });
                                  }
                                }
                              );
                            }
                          }}
                          primary={featured}
                          icon_right={
                            ((this.props.addons &&
                              this.props.plan_interval !== "year") ||
                              this.props.force_select_plan) &&
                            this.props.device !== "mobile"
                              ? ""
                              : "arrow-right-alt"
                          }
                        >
                          {signup_funnel === "use_iap" && platform === "ios"
                            ? "Subscribe To Plan"
                            : buttonText}
                        </InlineButton>
                      )}
                    </>
                  ) : null}
                </Wrapper>

                {((this.props.addons && this.props.plan_interval !== "year") ||
                  this.props.force_select_plan) &&
                this.props.device !== "mobile" ? (
                  <>
                    {this.props.addons ? (
                      <Wrapper
                        className={
                          this.props.selected_plan
                            ? "dm-plan-select-addons active"
                            : "dm-plan-select-addons"
                        }
                      >
                        <Wrapper
                          style={{
                            padding: 25,
                            paddingBottom: 0,
                            paddingTop: 0
                          }}
                        >
                          <Copy>
                            <Bold>Get the most out of your trial.</Bold> Each
                            license gives a member of your team unlimited
                            access.
                            {this.props.needs_dialer
                              ? " We've added one license for you based on your needs. "
                              : " "}
                            Add as many as you need.
                          </Copy>
                        </Wrapper>

                        {this.state?.contentful_content &&
                          this.state?.contentful_content?.addons &&
                          this.state?.contentful_content.addons.map(
                            (item, i) => {
                              const addon = item?.fields;
                              return (
                                <SelectItem
                                  key={"addon_" + i}
                                  onPress={() => this.addOrRemoveAddon(addon)}
                                  selected={this.state.selected_addons.find(
                                    selected_addon =>
                                      (selected_addon.stripe_id ===
                                        addon?.stripePriceIdMonthly &&
                                        plan_interval === "month") ||
                                      (selected_addon.stripe_id ===
                                        addon?.stripePriceIdYearly &&
                                        plan_interval === "year")
                                  )}
                                  subtext={!!addon?.tooltip ? "[?]" : null}
                                  subtextTooltip={addon?.tooltip}
                                  renderRight={() => {
                                    return (
                                      <>
                                        <Row>
                                          <IconButton
                                            button_type={"small"}
                                            icon={"add"}
                                            onPress={() =>
                                              this.addOrRemoveAddon(
                                                addon,
                                                "add_only"
                                              )
                                            }
                                            disabled={
                                              this.state.selected_addons.find(
                                                selected_addon =>
                                                  (selected_addon.stripe_id ===
                                                    addon?.stripePriceIdMonthly &&
                                                    plan_interval ===
                                                      "month") ||
                                                  (selected_addon.stripe_id ===
                                                    addon?.stripePriceIdYearly &&
                                                    plan_interval === "year")
                                              )?.quantity >= 10
                                            }
                                          />
                                          <Copy>
                                            {this.state.selected_addons.find(
                                              selected_addon =>
                                                (selected_addon.stripe_id ===
                                                  addon?.stripePriceIdMonthly &&
                                                  plan_interval === "month") ||
                                                (selected_addon.stripe_id ===
                                                  addon?.stripePriceIdYearly &&
                                                  plan_interval === "year")
                                            )?.quantity || 0}
                                          </Copy>
                                          <IconButton
                                            button_type={"small"}
                                            icon={"remove"}
                                            disabled={
                                              this.state.selected_addons.find(
                                                selected_addon =>
                                                  (selected_addon.stripe_id ===
                                                    addon?.stripePriceIdMonthly &&
                                                    plan_interval ===
                                                      "month") ||
                                                  (selected_addon.stripe_id ===
                                                    addon?.stripePriceIdYearly &&
                                                    plan_interval === "year")
                                              )?.quantity <= 0 ||
                                              !this.state.selected_addons.find(
                                                selected_addon =>
                                                  (selected_addon.stripe_id ===
                                                    addon?.stripePriceIdMonthly &&
                                                    plan_interval ===
                                                      "month") ||
                                                  (selected_addon.stripe_id ===
                                                    addon?.stripePriceIdYearly &&
                                                    plan_interval === "year")
                                              )
                                            }
                                            onPress={() =>
                                              this.addOrRemoveAddon(
                                                addon,
                                                "remove_only"
                                              )
                                            }
                                          />
                                        </Row>
                                      </>
                                    );
                                  }}
                                  description={addon?.description}
                                >
                                  {addon?.title} -{" "}
                                  {renderPrice(
                                    this.props.plan_interval === "month"
                                      ? addon?.monthPrice
                                      : addon?.yearPrice / 12,
                                    "no_decimal"
                                  )}{" "}
                                  / mo
                                </SelectItem>
                              );
                            }
                          )}

                        <GhostButton
                          button_type={"full"}
                          marketing_slug={"add_license_to_trial"}
                          loading={this.state.loading_url}
                          disabled={this.state.selected_addons.length === 0}
                          onPress={() => {
                            this.setState(
                              {
                                loading_url: true
                              },
                              () => {
                                if (
                                  this.props.platform == "ios" &&
                                  this.props.signup_funnel == "use_iap"
                                ) {
                                  this.props.onPurchase(ios_plan_id, () => {
                                    this.setState({ loading_url: false });
                                  });
                                } else {
                                  openUrl(
                                    this.getStripeCheckoutUrl(true),
                                    true
                                  );
                                }
                              }
                            );
                          }}
                          primary={true}
                        >
                          {addon_quantity > 0
                            ? "Add To Your Trial (" + addon_quantity + ")"
                            : "Add To Your Trial"}
                        </GhostButton>
                        <InlineButton
                          button_type={"full"}
                          loading={this.state.loading_url_2}
                          onPress={() => {
                            this.setState(
                              {
                                loading_url_2: true,
                                selected_addons: []
                              },
                              () => {
                                if (
                                  this.props.platform == "ios" &&
                                  this.props.signup_funnel == "use_iap"
                                ) {
                                  this.props.onPurchase(ios_plan_id, () => {
                                    this.setState({ loading_url: false });
                                  });
                                } else {
                                  openUrl(this.getStripeCheckoutUrl(), true);
                                }
                              }
                            );
                          }}
                        >
                          I don't want these right now. Let's continue.
                        </InlineButton>
                      </Wrapper>
                    ) : price === 0 ? (
                      <Wrapper
                        className={
                          this.props.selected_plan
                            ? "dm-plan-select-addons active"
                            : "dm-plan-select-addons"
                        }
                        style={{ margin: 5 }}
                      >
                        <GhostButton
                          button_type={"full"}
                          style={{
                            marginRight: 20,
                            marginLeft: 20
                          }}
                          //marketing_slug={"confirm_purchase"}
                          loading={this.state.loading_url}
                          disabled={false}
                          onPress={() => {
                            this.props.manageSubscription({
                              token: this.props.token,
                              type: "get_free_plan",
                              no_messaging: true,
                              onLoading: () => {
                                this.setState({
                                  loading_url: true
                                });
                              },
                              onError: () => {
                                this.setState({
                                  loading_url: false
                                });
                              },
                              onSuccess: () => {
                                this.setState({
                                  loading_url: false
                                });
                                if (this.props.prompt) {
                                  this.props.popSidePanel();
                                }
                              }
                            });
                          }}
                          primary={false}
                          icon_right={"arrow-right-alt"}
                        >
                          Confirm Selection
                        </GhostButton>
                        <InlineButton
                          button_type={"full"}
                          onPress={this.cancelPlanSelection}
                        >
                          Cancel Selection
                        </InlineButton>

                        <Wrapper style={{ marginBottom: 20 }}>
                          <Copy style={{ textAlign: "center" }}>
                            <Bold>OR</Bold>
                          </Copy>
                        </Wrapper>

                        <Wrapper className="sparkle-effect">
                          <SelectItem
                            onPress={this.props.selectProPlan}
                            select_type="icon"
                            select_icon="call"
                            icon_color={colors.success_color}
                            icon="keyboard-arrow-right"
                            selected={true}
                            primary={false}
                            innerStyle={{
                              backgroundColor: colors.active_color_muted
                            }}
                            style={{ margin: 0 }}
                          >
                            Get unlimited phone numbers, email addresses &
                            contact info when you purchase a Pro plan.
                          </SelectItem>
                        </Wrapper>
                      </Wrapper>
                    ) : (
                      <Wrapper
                        className={
                          this.props.selected_plan
                            ? "dm-plan-select-addons active"
                            : "dm-plan-select-addons"
                        }
                      >
                        {price > 0 ? (
                          <>
                            {!this.props.hide_annual_toggle ? (
                              <Wrapper
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <Wrapper
                                  style={{ borderRadius: 30, margin: 5 }}
                                >
                                  <InlineButton
                                    iconColor={
                                      this.props.plan_interval == "year"
                                        ? colors.success_color
                                        : null
                                    }
                                    icon={
                                      this.props.plan_interval == "year"
                                        ? "toggle-on"
                                        : "toggle-off"
                                    }
                                    style={{
                                      margin: 0
                                    }}
                                    onPress={this.props.onTogglePlanInterval}
                                  >
                                    <Bold>{this.props.annual_toggle_text}</Bold>
                                  </InlineButton>
                                </Wrapper>
                              </Wrapper>
                            ) : null}
                          </>
                        ) : null}

                        <GhostButton
                          button_type={"full"}
                          style={{
                            marginRight: 20,
                            marginLeft: 20
                          }}
                          //marketing_slug={"confirm_purchase"}
                          loading={this.state.loading_url}
                          disabled={false}
                          onPress={() => {
                            this.setState(
                              {
                                loading_url: true
                              },
                              () => {
                                if (
                                  this.props.platform == "ios" &&
                                  this.props.signup_funnel == "use_iap"
                                ) {
                                  this.props.onPurchase(ios_plan_id, () => {
                                    this.setState({ loading_url: false });
                                  });
                                } else {
                                  openUrl(
                                    this.getStripeCheckoutUrl(true),
                                    true
                                  );
                                }
                              }
                            );
                          }}
                          primary={false}
                          icon_right={"arrow-right-alt"}
                        >
                          Continue to Purchase Plan
                        </GhostButton>
                        <InlineButton
                          button_type={"full"}
                          onPress={this.cancelPlanSelection}
                        >
                          Cancel Selection
                        </InlineButton>
                      </Wrapper>
                    )}
                  </>
                ) : null}
              </Card>
              {this.props.selected_plan && !desktopMobile ? (
                <Wrapper style={{ height: 50 }} />
              ) : this.props.selected_plan ? (
                <InlineButton
                  button_type={"full"}
                  onPress={this.cancelPlanSelection}
                >
                  Cancel selection
                </InlineButton>
              ) : null}
            </>
          );
        })}
      </>
    );
  }
}

export default Plan;
