import React from "react";

const Blur = ({ children, style, blurAmount = 10 }) => {
  return (
    <div style={{ position: "relative", ...style }}>
      {children}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backdropFilter: "blur(6px)"
        }}
      />
    </div>
  );
};

export { Blur };
