import React, { Component } from "react";

import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Icon,
  Button,
  Input
} from "app/NativeComponents/common";
import {
  PillButton,
  PopoverMenu,
  Select,
  ConfirmInput,
  ConfirmInlineButton,
  SelectItem,
  UpsellButton
} from "app/NativeComponents/snippets";
import { getLeadStatusColor, getLimitsForAllPlan } from "app/NativeActions";
class LeadStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lead_status: props.lead_status || {},
      deleting: false,
      status_type_options: [
        {
          label: "No Status Type",
          value: ""
        },
        {
          label: "Lead Generation",
          value: "lead_generation_status"
        },
        {
          label: "Marketing",
          value: "marketing_status"
        },
        {
          label: "Warm Lead",
          value: "warm_lead_status"
        },
        {
          label: "Hot Lead",
          value: "hot_lead_status"
        },
        {
          label: "Acquisitions",
          value: "acquisition_status"
        },
        {
          label: "Dispositions",
          value: "disposition_status"
        },
        {
          label: "Not Interested (Complete)",
          value: "not_interested_status"
        },
        {
          label: "Unqualified (Complete)",
          value: "unqualified_status"
        },
        {
          label: "Unresponsive (Complete)",
          value: "marketing_complete_status"
        },
        {
          label: "Inactive (Complete)",
          value: "inactive_status"
        },
        {
          label: "Won (Complete)",
          value: "won_status"
        }
      ],
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lead_status &&
      this.props.lead_status !== prevProps.lead_status
    ) {
      this.setState({
        lead_status: this.props.lead_status
      });
    }
  }

  getStatusTypeTitle(value) {
    const { status_type_options } = this.state;
    for (let i = 0; i < status_type_options.length; i++) {
      if (status_type_options[i].value == value) {
        return this.state.status_type_options[i].label;
      }
    }
  }

  renderStatusTypeLabel(lead_status, status_order) {
    const { colors } = this.props;

    switch (lead_status.status_type) {
      default:
        break;

      case "lead_generation_status":
        return "Lead Generation";

      case "marketing_status":
        return "Marketing";

      case "warm_lead_status":
        return "Warm Lead";
      case "hot_lead_status":
        return "Hot Lead";

      case "acquisition_status":
        return "Acquisitions";

      case "disposition_status":
        return "Dispositions";

      case "won_status":
        return "Won (Complete)";
      case "inactive_status":
        return "Inactive (Complete)";
      case "marketing_complete_status":
        return "Unresponsive (Complete)";
      case "not_interested_status":
        return "Not Interested (Complete)";
      case "unqualified_status":
        return "Unqualified (Complete)";
      case "return_to_sender_status":
        return "Return To Sender";
      case "trash_status":
        return "Trash";
    }
  }

  render() {
    const { lead_status, loading } = this.state;
    const {
      colors,
      active_lead_status,
      needs_to_save_order,
      status_order,
      locked = false,
      plan_modules,
      source_of_truth
    } = this.props;
    const active = active_lead_status == lead_status.id;
    const limits = getLimitsForAllPlan(source_of_truth);

    return (
      <>
        <UpsellButton
          type="plan"
          slug="driving"
          tier={2}
          contentful_slug="unlock_feature"
          meta_slug="custom_statuses"
          force_unlock={
            limits?.include_custom_statuses && this.props.user?.user_version < 6
          }
          onPress={() => {
            this.props.setActiveLeadStatus(lead_status);
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <SelectItem
                noPress={true}
                pressedIn={hovering}
                select_type={"icon"}
                select_icon={"lock"}
                subtext={this.renderStatusTypeLabel(lead_status, status_order)}
                onPress={() => {}}
                renderRight={() => {
                  return (
                    <Row>
                      {this.props.wrappedHandle
                        ? this.props.wrappedHandle()
                        : null}
                    </Row>
                  );
                }}
              >
                {lead_status.title}
              </SelectItem>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <SelectItem
                noPress={true}
                pressedIn={hovering}
                select_type={"none"}
                subtext={this.renderStatusTypeLabel(lead_status, status_order)}
                onPress={() => {}}
                renderRight={() => {
                  return (
                    <Row>
                      {this.props.wrappedHandle
                        ? this.props.wrappedHandle()
                        : null}
                    </Row>
                  );
                }}
              >
                {lead_status.title}
              </SelectItem>
            );
          }}
        />
      </>
    );
  }
}

export default LeadStatus;
