import React, { Component } from "react";
import { InputBox } from "app/NativeComponents/snippets";

import {
  /*common functions */
  focusNextField
} from "app/NativeActions";

class Inputs extends Component {
  render() {
    return (
      <>
        <InputBox
          input_ref={this._email_input}
          name="email"
          autoCompleteType={"email"}
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          keyboardType="email-address"
          placeholder="Email Address"
          onChange={value =>
            this.props.authFieldChanged({ prop: "email", value })
          }
          value={this.props.email}
          type="text"
          autoFocus={true}
          onSubmitEditing={() => {
            this.props.forgot();
          }}
        />
      </>
    );
  }
}

export default Inputs;
