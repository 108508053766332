import React, { Component } from "react";
import { connect } from "react-redux";

import { KeyboardView, Container } from "app/NativeComponents/common";
import { NewHeader, SpinWrapper } from "app/NativeComponents/snippets";
import moment from "moment";

import CampaignSteps from "../CampaignSteps";
import {
  updateMailerCampaign,
  setRightPanel,
  popSidePanel,
  setMailerSelector,
  updateBillingAddon,
  setModal,
  toggleModal,
  pushSidePanel,
  getMailerTemplates
} from "app/NativeActions";

class ViewMailSequence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_mailer_campaign: null,
      templates_loading: false,
      mailer_templates: []
    };

    this.editMailerCampaign = this.editMailerCampaign.bind(this);
    this.getRandomTemplate = this.getRandomTemplate.bind(this);
  }

  editMailerCampaign({ prop, value }) {
    const { new_mailer_campaign } = this.state;
    this.setState({
      new_mailer_campaign: {
        ...new_mailer_campaign,
        [prop]: value
      }
    });
  }

  getRandomTemplate() {
    let template = {};
    const { mailer_templates } = this.state;
    const { user } = this.props;
    let found_success = false;
    while (found_success === false) {
      template =
        mailer_templates[Math.floor(Math.random() * mailer_templates.length)];
      if (template?.id !== user?.default_template_id) {
        found_success = true;
      }
    }

    return template;
  }

  setEditMailerCampaign(mailer_campaign) {
    if (mailer_campaign) {
      return {
        ...mailer_campaign,
        id: mailer_campaign.id,
        campaign_title: mailer_campaign.campaign_title,
        campaign_steps: mailer_campaign.campaign_steps.map((step, i) => {
          return {
            ...step
          };
        })
      };
    }
  }

  componentDidMount() {
    this.props.getMailerTemplates({
      token: this.props.token,
      load_all: true,
      onLoading: () => {
        this.setState({ templates_loading: true });
      },
      onError: () => {
        this.setState({ templates_loading: false });
      },
      onSuccess: results => {
        this.setState({
          templates_loading: false,
          mailer_templates: results.mailer_templates
        });

        const panel_data =
          this.props.device == "mobile"
            ? this.props?.route?.params?.panel_data
            : this.props.panel_data;

        this.setState({
          new_mailer_campaign: this.setEditMailerCampaign(
            panel_data?.mail_sequence
          )
        });
      }
    });
  }

  render() {
    const {
      colors,
      user,
      right_panel,
      new_mailer_campaign_settings,
      isMobile
    } = this.props;
    const { templates_loading } = this.state;

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    return (
      <Container>
        <NewHeader
          title={"View Mail Sequence"}
          leftButton={{
            onPress: () => {
              this.props.popSidePanel();
            },
            icon: isMobile ? "arrow-back" : "close"
          }}
        />
        {templates_loading ? (
          <SpinWrapper text={"Loading..."} />
        ) : this.state.new_mailer_campaign ? (
          <KeyboardView style={{ flex: 1 }}>
            <CampaignSteps
              token={this.props.token}
              user={user}
              colors={colors}
              editable={
                this.state.new_mailer_campaign?.can_still_edit ? true : false
              }
              new_mailer_campaign_settings={new_mailer_campaign_settings}
              mailer_campaign={this.state.new_mailer_campaign}
              mailer_templates={this.state.mailer_templates}
              editMailerCampaign={this.editMailerCampaign}
              new_mailer_campaign={false}
              updateMailerCampaign={this.props.updateMailerCampaign}
              source_of_truth={this.props.source_of_truth}
              getRandomTemplate={this.getRandomTemplate}
              popSidePanel={this.props.popSidePanel}
              onSuccess={panel_data?.onSuccess}
            />
          </KeyboardView>
        ) : null}
      </Container>
    );
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, mailer, billing }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, platform, isMobile } = native;
  const { mailer_campaign_count, active_mailer_campaign } = mailer;
  const { billing_addons, card_info, source_of_truth } = billing;
  return {
    token,
    user,
    colors,
    device,
    platform,
    isMobile,
    mailer_campaign_count,
    active_mailer_campaign,
    billing_addons,
    card_info,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateMailerCampaign,
  setRightPanel,
  setMailerSelector,
  updateBillingAddon,
  setModal,
  toggleModal,
  pushSidePanel,
  popSidePanel,
  getMailerTemplates
})(ViewMailSequence);
