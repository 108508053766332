import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Wrapper,
  Row,
  Icon,
  ExternalImage,
  Copy,
  InnerCopy,
  Bold
} from "app/NativeComponents/common";
import { Tag } from "app/NativeComponents/snippets";
import { formatAddress } from "app/NativeActions";
class AutocompleteItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  shouldPhoneNumberBeHighlighted(phonenumber) {
    const { search, colors } = this.props;
    if (!!search && !!phonenumber.phone_number) {
      let number_search = search.replace(/\D/g, "");
      let phonenumber_number = phonenumber.phone_number.replace(/\D/g, "");

      if (number_search.length > 0 && phonenumber_number.length > 0) {
        if (phonenumber_number.includes(number_search)) {
          return (
            <Tag
              noPress={true}
              style={{
                margin: 0,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor: "transparent"
              }}
              innerStyle={{
                padding: 5,
                backgroundColor: "transparent"
              }}
            >
              <Bold style={{ backgroundColor: colors.active_color_muted }}>
                {phonenumber.phone_number}
                {"   "}
              </Bold>
            </Tag>
          );
        }
      }
    }
    return null;
  }

  shouldParcelIDBeHighlighted(parcel_id) {
    const { search, colors } = this.props;
    if (!!search && !!parcel_id) {
      let number_search = search.replace(/\D/g, "");
      let parcel_id_number = parcel_id.replace(/\D/g, "");

      if (number_search.length > 0 && parcel_id.length > 0) {
        if (parcel_id_number.includes(number_search)) {
          return (
            <Tag
              noPress={true}
              style={{
                margin: 0,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor: "transparent"
              }}
              innerStyle={{
                padding: 5,
                backgroundColor: "transparent"
              }}
            >
              <Bold style={{ backgroundColor: colors.active_color_muted }}>
                {parcel_id}
                {"   "}
              </Bold>
            </Tag>
          );
        }
      }
    }
    return null;
  }

  shouldEmailBeHighlighted(email) {
    const { search, colors } = this.props;
    if (!!search && !!email.email_address) {
      if (email.email_address.includes(search)) {
        return (
          <Tag
            noPress={true}
            style={{
              margin: 0,
              marginRight: 5,
              marginBottom: 5,
              backgroundColor: "transparent"
            }}
            innerStyle={{
              padding: 5,
              backgroundColor: "transparent"
            }}
          >
            {this.getHighlightedText(email.email_address)}
          </Tag>
        );
      }
    }
    return null;
  }

  getHighlightedText(text) {
    const { search, colors } = this.props;
    // Split text on highlight term, include term itself into parts, ignore case
    if (!!text) {
      const parts = text.split(new RegExp(`(${search})`, "gi"));
      return (
        <InnerCopy>
          {parts.map(part =>
            part.toLowerCase() === search.toLowerCase() ? (
              <Bold
                key={Math.random().toString(36).substring(2, 15)}
                style={{ backgroundColor: colors.active_color_muted }}
              >
                {part}
              </Bold>
            ) : (
              part
            )
          )}
        </InnerCopy>
      );
    }
    return text;
  }
  render() {
    const { item, colors } = this.props;
    const formatted_address = formatAddress({
      address: {
        address: item.property_address,
        address2: item.property_address2,
        address_city: item.property_address_city,
        address_state: item.property_address_state,
        address_zip: item.property_address_zip,
        latitude: item?.location?.latitude,
        longitude: item?.location?.longitude
      }
    });

    return (
      <Button
        onHover={() => {
          this.setState({
            hovering: true
          });
        }}
        onHoverOut={() => {
          this.setState({
            hovering: false
          });
        }}
        onPress={this.props.onPress}
      >
        <Wrapper
          style={{
            padding: 15,
            margin: 5,
            backgroundColor: this.state.hovering
              ? colors.hover_color
              : "transparent"
          }}
        >
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <Row style={{ alignSelf: "stretch" }}>
                <Wrapper
                  style={{
                    borderRadius: 5,
                    width: 36,
                    height: 36,
                    backgroundColor: colors.gray_color,
                    marginRight: 15
                  }}
                >
                  <ExternalImage
                    style={{
                      borderRadius: 5,
                      width: 36,
                      height: 36,
                      backgroundColor: colors.gray_color
                    }}
                    image={item.deal.image}
                  />
                </Wrapper>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>{this.getHighlightedText(item.owner_name)}</Bold>
                  </Copy>
                  <Copy>
                    {this.getHighlightedText(formatted_address.line1)}
                  </Copy>
                  <Copy>
                    {this.getHighlightedText(formatted_address.line2)}
                  </Copy>
                </Wrapper>
              </Row>
              <Row style={{ flexWrap: "wrap" }}>
                {item?.deal?.owner?.phone_numbers.map(phonenumber => {
                  return this.shouldPhoneNumberBeHighlighted(phonenumber);
                })}
              </Row>
              <Row style={{ flexWrap: "wrap" }}>
                {item?.deal?.owner?.email_addresses.map(email => {
                  return this.shouldEmailBeHighlighted(email);
                })}
              </Row>
              <Row style={{ flexWrap: "wrap" }}>
                {this.shouldParcelIDBeHighlighted(item.parcel_id)}
              </Row>
            </Wrapper>
            <Icon icon="keyboard-arrow-right" size={18} />
          </Row>
        </Wrapper>
      </Button>
    );
  }
}

export default AutocompleteItem;
