import React, { Component } from "react";

import { Wrapper, Row, Copy, Bold, Icon } from "app/NativeComponents/common";

import { renderPrice, numberWithCommas } from "app/NativeActions";
import { store } from "app/store";

class MailerInvoice extends Component {
  render() {
    const { estimated_invoice_info, colors } = this.props;
    const device = store.getState().native.device;
    if (
      estimated_invoice_info &&
      estimated_invoice_info.invoice_type == "mailer_campaign"
    ) {
      const {
        estimated_invoice_info: {
          mailer_campaign,
          number_of_leads,
          number_of_addresses
        }
      } = this.props;

      return (
        <Wrapper>
          {/*
          <Wrapper
            style={{
              padding: 25
            }}
          >
            {mailer_campaign.campaign_steps?.map((step, i) => {
              return (
                <Row key={"step_" + i} style={{ marginBottom: 10 }}>
                  <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                    <Row>
                      <Copy>
                        <Bold>{step.step_label}</Bold>
                      </Copy>
                      <Copy
                        style={{
                          fontSize: 12,
                          marginLeft: 5,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 20,
                          backgroundColor: colors.background_color
                        }}
                      >
                        {step.template_type_text}
                      </Copy>
                    </Row>
                    <Copy>
                      {step.total_mailers == 1
                        ? "1 mail piece "
                        : step.total_mailers + " mail pieces "}
                      {number_of_leads > 1
                        ? "x " + numberWithCommas(number_of_leads) + " leads "
                        : ""}
                      {number_of_addresses > 1
                        ? "x " +
                          numberWithCommas(number_of_addresses) +
                          " addresses "
                        : ""}
                      {"x " + renderPrice(step.mailer_price)}
                    </Copy>
                  </Wrapper>
                  <Wrapper>
                    <Copy>
                      {renderPrice(
                        step.total_price * number_of_leads * number_of_addresses
                      )}
                    </Copy>
                  </Wrapper>
                </Row>
              );
            })}
          </Wrapper>
          */}

          <Wrapper style={{ padding: 25 }}>
            {/*<Row>
              <Copy>
                <Bold>Estimated totals: </Bold>
              </Copy>
              {device == "desktop" ? (
                <Wrapper
                  style={{ marginLeft: 5 }}
                  tooltip="Total subject to change. This does not include multiple leads with different owner types."
                >
                  <Icon icon="help" size={18} />
                </Wrapper>
              ) : null}
            </Row>*/}
            <Row>
              <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                <Row>
                  <Copy>
                    Estimated total after
                    {number_of_leads == 1 && number_of_addresses == 1
                      ? " first mail piece is "
                      : " first mail pieces are "}
                    sent:
                  </Copy>
                  {device == "desktop" ? (
                    <Wrapper
                      style={{ marginLeft: 5 }}
                      tooltip="Total subject to change. This does not include multiple leads with different owner addresses."
                    >
                      <Icon icon="help" size={18} />
                    </Wrapper>
                  ) : null}
                </Row>
              </Wrapper>
              <Wrapper>
                <Copy>
                  {renderPrice(
                    estimated_invoice_info?.mailer_template
                      ? number_of_leads *
                          estimated_invoice_info?.mailer_template?.mailer_price
                      : estimated_invoice_info?.mailer_campaign
                      ? estimated_invoice_info?.total_price_on_start_of_campaign
                      : 0
                  )}
                </Copy>
              </Wrapper>
            </Row>
            {/*
            <Row>
              <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                <Copy>Total cost at the end of campaign:</Copy>
              </Wrapper>

              <Wrapper>
                <Copy>
                  {renderPrice(
                    estimated_invoice_info.total_price_on_end_of_campaign
                  )}
                </Copy>
              </Wrapper>
            </Row>
            */}
          </Wrapper>
        </Wrapper>
      );
    }

    return null;
  }
}

export default MailerInvoice;
