import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  List,
  InlineButton,
  SelectItem,
  Tag,
  ConfirmLabel,
  SpinWrapper,
  BottomNavBar
} from "app/NativeComponents/snippets";
import {
  updateLead,
  getLists,
  toTitleCase,
  addListToListTabs,
  setActiveListTab,
  pushSidePanel,
  renderDate
} from "app/NativeActions";
class OpenListsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false
    };

    this._scroll_view = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { property, lists, lists_loading } = this.props;

    if (prevState.show !== this.state.show && !lists_loading) {
      this.props.getLists({
        token: this.props.token,
        load_type: lists.length == 0 ? "load" : "reload",
        no_count: true,
        type: "all_lists",
        begin: 0
      });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      lists,
      lists_loading,
      style,
      property,
      popoverPlacement = "bottom",
      renderComponent = () => {},
      renderComponentLoading = () => {},
      componentStyle = {},
      list_tabs,
      active_list_tab
    } = this.props;
    const { search, show } = this.state;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={400}
        popover_height={450}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={isMobile ? "Lists & Filters" : "My Lists"}
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <Wrapper
              wrapper_ref={this._popover}
              style={{ flex: device == "mobile" ? 0 : 1 }}
            >
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {lists_loading ? (
                <SpinWrapper text="Loading Lists..." />
              ) : (
                <>
                  <Wrapper style={{ alignSelf: "stretch" }}>
                    <SearchBar
                      style={{
                        margin: 10
                      }}
                      //autoFocus={true}
                      title="Search Lists"
                      onChange={value => {
                        this.setState({
                          search: value
                        });
                      }}
                      value={search}
                    />
                  </Wrapper>
                  <List
                    style={{ alignSelf: "stretch" }}
                    items={this.filterOptions(lists)}
                    listHeader={() => {
                      let label_title = "All Leads";

                      if (
                        isMobile &&
                        label_title
                          .toLowerCase()
                          .indexOf(search?.toLowerCase()) != -1
                      ) {
                        return (
                          <SelectItem
                            select_type={isMobile ? "radio" : "none"}
                            selected={active_list_tab == "all_leads"}
                            onPress={() => {
                              this.props.setActiveListTab("all_leads");
                              this.setState({
                                show: false
                              });
                            }}
                          >
                            {label_title}
                          </SelectItem>
                        );
                      }
                      return null;
                    }}
                    listFooter={() => {
                      let label_title = "In Trash";

                      if (
                        label_title
                          .toLowerCase()
                          .indexOf(search?.toLowerCase()) != -1
                      ) {
                        return (
                          <SelectItem
                            select_type={isMobile ? "radio" : "none"}
                            selected={active_list_tab == "in_trash_leads"}
                            onPress={() => {
                              if (isMobile) {
                                this.props.setActiveListTab("in_trash_leads");
                              } else {
                                this.props.addListToListTabs("in_trash_leads");
                              }

                              this.setState({
                                show: false
                              });
                            }}
                          >
                            {label_title}
                          </SelectItem>
                        );
                      }
                      return null;
                    }}
                    itemStructure={({ item, index }) => {
                      let in_selected_options = false;
                      for (let i = 0; i < list_tabs.length; i++) {
                        if (item.id == list_tabs[i]?.id) {
                          in_selected_options = true;
                        }
                      }

                      return (
                        <SelectItem
                          key={"option_" + index}
                          select_type={
                            isMobile ? "radio" : item.pinned ? "icon" : "none"
                          }
                          select_icon={
                            item.pinned && !isMobile ? "push-pin" : ""
                          }
                          hover_icon={"launch"}
                          hover_icon_color={colors.orange_color}
                          subtext={renderDate(item.date_created)}
                          icon_color={colors.light_text_color}
                          selected={active_list_tab?.id == item.id}
                          onPress={() => {
                            if (isMobile) {
                              this.props.setActiveListTab(item);
                            } else {
                              this.props.addListToListTabs(item);
                            }

                            this.setState({
                              show: false
                            });
                          }}
                        >
                          {item.label}
                        </SelectItem>
                      );
                    }}
                  />
                  <BottomNavBar
                    style={{ backgroundColor: colors.popover_color }}
                  >
                    <InlineButton
                      primary={false}
                      button_type={"full"}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "list_library",
                          overlay: true
                        });
                        this.setState({
                          show: false
                        });
                      }}
                    >
                      Open List Library
                    </InlineButton>
                  </BottomNavBar>
                </>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, list }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors, dark_mode } = settings;
  const { lists, lists_loading, list_tabs, active_list_tab } = list;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode,
    lists,
    lists_loading,
    list_tabs,
    active_list_tab
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getLists,
  addListToListTabs,
  setActiveListTab,
  pushSidePanel
})(OpenListsButton);
