export const GET_MAILER_CAMPAIGNS = "get_mailer_campaigns";
export const GET_MAILER_CAMPAIGNS_SUCCESS = "get_mailer_campaigns_success";
export const GET_MAILER_CAMPAIGNS_FAIL = "get_mailer_campaigns_fail";
export const REFRESH_MAILER_CAMPAIGNS = "refresh_mailer_campaigns";
export const LOAD_MORE_MAILER_CAMPAIGNS = "load_more_mailer_campaigns";
export const LOAD_MORE_MAILER_CAMPAIGNS_SUCCESS =
  "load_more_mailer_campaigns_success";
export const SET_ACTIVE_MAILER_CAMPAIGN = "set_active_mailer_campaign";
export const SET_ACTIVE_MAILER_CAMPAIGN_FAIL =
  "set_active_mailer_campaign_fail";
export const SET_ACTIVE_MAILER_CAMPAIGN_SUCCESS =
  "set_active_mailer_campaign_success";

export const UPDATE_MAILER_CAMPAIGN = "update_mailer_campaign";
export const UPDATE_MAILER_CAMPAIGN_FAIL = "udpate_mailer_campaign_fail";
export const UPDATE_MAILER_CAMPAIGN_SUCCESS = "update_mailer_campaign_success";
export const CREATE_MAILER_CAMPAIGN_SUCCESS = "create_mailer_campaign_success";
export const REMOVE_MAILER_CAMPIGN_SUCCESS = "remove_mailer_campaign_success";
export const SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS =
  "set_default_mailer_campaign_success";

export const SET_START_MAILER_CAMPAIGN_PAGE = "set_start_mailer_campaign_page";

export const GET_ESTIMATED_INVOICE_INFO = "get_estimated_invoice_info";
export const GET_ESTIMATED_INVOICE_INFO_FAIL =
  "get_estimated_invoice_info_fail";
export const GET_ESTIMATED_INVOICE_INFO_SUCCESS =
  "get_estimated_invoice_info_success";

export const GET_LEAD_MAILER_CAMPAIGN = "get_lead_mailer_campaign";
export const GET_LEAD_MAILER_CAMPAIGN_SUCCESS =
  "get_lead_mailer_campaign_success";
export const GET_LEAD_MAILER_CAMPAIGN_FAIL = "get_lead_mailer_campaign_fail";

export const GET_MAILER_TEMPLATES = "get_mailer_templates";
export const GET_MAILER_TEMPLATES_SUCCESS = "get_mailer_templates_success";
export const GET_MAILER_TEMPLATES_FAIL = "get_mailer_templates_fail";
export const REFRESH_MAILER_TEMPLATES = "refresh_mailer_templates";
export const LOAD_MORE_MAILER_TEMPLATES = "load_more_mailer_templates";
export const LOAD_MORE_MAILER_TEMPLATES_SUCCESS =
  "load_more_mailer_templates_success";

export const SET_ACTIVE_MAILER_TEMPLATE = "set_active_mailer_template";
export const SET_ACTIVE_MAILER_TEMPLATE_FAIL =
  "set_active_mailer_template_fail";
export const SET_ACTIVE_MAILER_TEMPLATE_SUCCESS =
  "set_active_mailer_template_success";

export const TOGGLE_MAILER_DESIGNER = "toggle_mailer_designer";
export const TOGGLE_MAILER_SELECTOR = "toggle_mailer_selector";
export const SET_MAILER_SELECTOR = "set_mailer_selector";

export const UPDATE_MAILER_TEMPLATE = "update_mailer_template";
export const UPDATE_MAILER_TEMPLATE_SUCCESS = "update_mailer_template_success";
export const UPDATE_MAILER_TEMPLATE_FAIL = "update_mailer_template_fail";
export const CREATE_MAILER_TEMPLATE_SUCCESS = "create_mailer_template_success";
export const REMOVE_MAILER_TEMPLATE_SUCCESS = "remove_mailer_template_success";

export const SET_DEFAULT_MAILER_TEMPLATE_SUCCESS =
  "set_default_mailer_template_success";

export const SET_ACTIVE_MAILER_SIGNATURE = "set_active_mailer_signature";
export const SET_ACTIVE_MAILER_SIGNATURE_FAIL =
  "set_active_mailer_signature_fail";
export const SET_ACTIVE_MAILER_SIGNATURE_SUCCESS =
  "set_active_mailer_signature_success";

export const GET_MAILER_SIGNATURES = "get_mailer_signatures";
export const GET_MAILER_SIGNATURES_SUCCESS = "get_mailer_signatures_success";
export const GET_MAILER_SIGNATURES_FAIL = "get_mailer_signatures_fail";
export const REFRESH_MAILER_SIGNATURES = "refresh_mailer_signatures";
export const LOAD_MORE_MAILER_SIGNATURES = "load_more_mailer_signatures";
export const LOAD_MORE_MAILER_SIGNATURES_SUCCESS =
  "load_more_mailer_signatures_success";

export const UPDATE_MAILER_SIGNATURE = "update_mailer_signature";
export const UPDATE_MAILER_SIGNATURE_SUCCESS =
  "update_mailer_signature_success";
export const UPDATE_MAILER_SIGNATURE_FAIL = "update_mailer_signature_fail";
export const CREATE_MAILER_SIGNATURE_SUCCESS =
  "create_mailer_signature_success";
export const REMOVE_MAILER_SIGNATURE_SUCCESS =
  "remove_mailer_signature_success";

export const GET_MAILER_DESIGN_TEMPLATES = "get_mailer_design_templates";
export const GET_MAILER_DESIGN_TEMPLATES_FAIL =
  "get_mailer_design_templates_fail";
export const GET_MAILER_DESIGN_TEMPLATES_SUCCESS =
  "get_mailer_design_templates_success";

export const SET_DEFAULT_MAILER_SIGNATURE_SUCCESS =
  "set_default_mailer_signature_success";

export const SET_MAILER_VIEW = "set_mailer_view";

export const GET_SENDING_QUEUE = "get_sending_queue";
export const GET_SENDING_QUEUE_FAIL = "get_sending_queue_fail";
export const GET_SENDING_QUEUE_SUCCESS = "get_sending_queue_success";
export const LOAD_MORE_SENDING_QUEUE = "load_more_sending_queue";
export const LOAD_MORE_SENDING_QUEUE_SUCCESS =
  "load_more_sending_queue_success";

export const GET_SENDING_QUEUE_COUNTS_SUCCESS =
  "get_sending_queue_counts_success";
export const SWITCH_MARKETING_TAB = "switch_marketing_tab";
export const SET_NEW_MAILER_CAMPAIGN = "set_new_mailer_campaign";
