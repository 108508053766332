import React, { Component } from "react";
import EqualOption from "./EqualOption";

class NumberType extends Component {
  render() {
    const {
      filter,
      selected_filter,
      selected_equal_type,
      selected_input,
      selected_date_object,
      colors,
      additional_condition_values
    } = this.props;
    if (filter.type == "number" || filter.type == "number_array") {
      return (
        <>
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_equal_to"}
            title={"Is equal to"}
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            updateSelectedInput={this.props.updateSelectedInput}
            is_active={selected_equal_type == "is_equal_to"}
            onPress={() => {
              this.props.selectEqualType("is_equal_to");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_not_equal_to"}
            title={"Is not equal to"}
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            updateSelectedInput={this.props.updateSelectedInput}
            is_active={selected_equal_type == "is_not_equal_to"}
            onPress={() => {
              this.props.selectEqualType("is_not_equal_to");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_less_than"}
            title={filter.label == "Year Built" ? "Is before" : "Is less than"}
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            updateSelectedInput={this.props.updateSelectedInput}
            is_active={selected_equal_type == "is_less_than"}
            onPress={() => {
              this.props.selectEqualType("is_less_than");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_less_than_or_equal_to"}
            title={
              filter.label == "Year Built"
                ? "Is on or before"
                : "Is less than or equal to"
            }
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            updateSelectedInput={this.props.updateSelectedInput}
            is_active={selected_equal_type == "is_less_than_or_equal_to"}
            onPress={() => {
              this.props.selectEqualType("is_less_than_or_equal_to");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            filter_type="number"
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_greater_than"}
            title={
              filter.label == "Year Built" ? "Is after" : "Is greater than"
            }
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            updateSelectedInput={this.props.updateSelectedInput}
            is_active={selected_equal_type == "is_greater_than"}
            onPress={() => {
              this.props.selectEqualType("is_greater_than");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_greater_than_or_equal_to"}
            title={
              filter.label == "Year Built"
                ? "Is on or after"
                : "Is greater than or equal to"
            }
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            updateSelectedInput={this.props.updateSelectedInput}
            is_active={selected_equal_type == "is_greater_than_or_equal_to"}
            onPress={() => {
              this.props.selectEqualType("is_greater_than_or_equal_to");
            }}
            additional_condition_values={additional_condition_values}
          />

          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_between"}
            title={"Is between"}
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            selected_date_object={selected_date_object}
            updateSelectedInput={this.props.updateSelectedInput}
            updateSelectedDateObject={this.props.updateSelectedDateObject}
            is_active={selected_equal_type == "is_between"}
            onPress={() => {
              this.props.selectEqualType("is_between");
            }}
            additional_condition_values={additional_condition_values}
          />

          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_not_between"}
            title={"Is not between"}
            allow_options={false}
            allow_input={true}
            input_type="number"
            selected_input={selected_input}
            selected_date_object={selected_date_object}
            updateSelectedInput={this.props.updateSelectedInput}
            updateSelectedDateObject={this.props.updateSelectedDateObject}
            is_active={selected_equal_type == "is_not_between"}
            onPress={() => {
              this.props.selectEqualType("is_not_between");
            }}
            additional_condition_values={additional_condition_values}
          />

          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_known"}
            title={"Is known"}
            allow_options={false}
            is_active={selected_equal_type == "is_known"}
            onPress={() => {
              this.props.selectEqualType("is_known");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_unknown"}
            title={"Is unknown"}
            allow_options={false}
            is_active={selected_equal_type == "is_unknown"}
            onPress={() => {
              this.props.selectEqualType("is_unknown");
            }}
            additional_condition_values={additional_condition_values}
          />
        </>
      );
    }
    return null;
  }
}

export default NumberType;
