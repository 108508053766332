import React, { Component } from "react";
import { connect } from "react-redux";

import {
  appRedirect,
  getWorkflowHistory,
  setActiveWorkflow,
  toggleWorkflowDesigner,
  selectActiveProperty,
  setActiveWorkflowHistory,
  setRightPanel,
  formatAddress
} from "app/NativeActions";
import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Icon,
  Spin
} from "app/NativeComponents/common";
import { ResponsiveList, SearchBar } from "app/NativeComponents/snippets";
import moment from "moment";

class WorkflowHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_by: "date_created",
      sort_by_dir: "desc",
      search_loading: false,
      search: "",
      edit_search: ""
    };
  }
  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  componentDidUpdate(prevProps, prevState) {
    const { sort_by, sort_by_dir, search } = this.state;
    if (
      sort_by !== prevState.sort_by ||
      sort_by_dir !== prevState.sort_by_dir ||
      search !== prevState.search
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  getData({ load_type = "load" }) {
    const { workflow_history_begin, workflow_history_limit } = this.props;
    const { sort_by, sort_by_dir } = this.state;
    this.props.getWorkflowHistory({
      token: this.props.token,
      load_type: load_type,
      begin: load_type !== "load_more" ? 0 : workflow_history_begin,
      limit: workflow_history_limit,
      search: this.state.edit_search,
      sort_by: sort_by + "_" + sort_by_dir,
      onError: () => {
        this.setState({
          search_loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          search_loading: false
        });
      }
    });
  }

  renderCurrentStatus(item) {
    const { date_ended } = item;
    const { colors } = this.props;

    let status_color = colors.gray_color;
    switch (item.status) {
      case "In Progress":
      case "In Delay":
      default:
        status_color = colors.gray_color;
        break;
      case "Completed":
        status_color = colors.success_color;
        break;

      case "Completed With Error(s)":
        status_color = colors.yellow_color;
        break;

      case "Ended From Error":
        status_color = colors.error_color;
        break;
    }
    return (
      <Row>
        <Icon
          icon="circle"
          size={14}
          color={status_color}
          style={{ marginRight: 5 }}
        />
        <Wrapper>
          <Copy>{item.status}</Copy>
          <Copy style={{ fontSize: 10, color: colors.light_text_color }}>
            Updated {moment(item.status_date).format("MMM Do, YYYY h:mm a")}
          </Copy>
        </Wrapper>
      </Row>
    );
  }

  render() {
    const {
      token,
      isMobile,
      colors,
      workflow_history,
      workflow_history_loading,
      workflow_history_refreshing,
      workflow_history_loaded_all,
      workflow_history_begin,
      workflow_history_limit
    } = this.props;

    const { sort_by, sort_by_dir } = this.state;
    return (
      <>
        <Row style={{ padding: 20 }}>
          <SearchBar
            style={{
              width: isMobile ? "100%" : 300,
              marginBottom: isMobile ? 10 : 0,
              marginRight: isMobile ? 10 : 0
            }}
            value={this.state.edit_search}
            title="Search History"
            onChange={value => {
              this.setState({
                edit_search: value
              });
            }}
            showButton={true}
            disabledButton={
              this.state.search_loading ||
              (this.state.search == this.state.edit_search &&
                !this.state.search_loading)
            }
            buttonText={
              this.state.search !== this.state.edit_search &&
              this.state.edit_search.length == 0
                ? "Clear"
                : "Search"
            }
            buttonLoading={this.state.search_loading}
            onPress={() => {
              this.setState({
                search: this.state.edit_search,
                search_loading: true
              });
            }}
          />
        </Row>
        <ResponsiveList
          items={workflow_history}
          active_item_id={0}
          selected_items={[]}
          selected_all={false}
          items_loading={workflow_history_loading}
          headers={[
            {
              slug: "property_address",
              title: "Lead Enrolled",
              sortable: false,
              main_column: true,
              item_type: "custom",
              renderComponent: item => {
                const address = formatAddress({
                  address: {
                    address: item.property_address,
                    address2: item.property_address2,
                    address_city: item.property_address_city,
                    address_state: item.property_address_state,
                    address_zip: item.property_address_zip
                  }
                });

                return (
                  <Wrapper>
                    <Copy>
                      <Bold>{address.line1}</Bold>
                    </Copy>
                    <Copy>{address.line2}</Copy>
                  </Wrapper>
                );
              }
            },
            {
              slug: "workflow_title",
              title: "Workflow",
              sortable: false,
              item_type: "text"
            },
            {
              slug: "current_status",
              title: "Current Status",
              sortable: false,
              item_type: "custom",
              renderComponent: item => this.renderCurrentStatus(item)
            },

            {
              slug: "date_created",
              title: "Date Created",
              sortable: true,
              item_type: "date"
            }
          ]}
          onItemPress={({ item }) => {}}
          primary_button={"View Lead"}
          primary_button_right_icon={"keyboard-arrow-right"}
          onPrimaryButtonPress={({ item }) => {}}
          secondary_button={"Full History"}
          secondary_button_right_icon={"history"}
          onSecondaryButtonPress={({ item }) => {}}
          sort_by={sort_by}
          sort_by_dir={sort_by_dir}
          limit={workflow_history_limit}
          begin={workflow_history_begin}
          onSortBy={({ sort_by, sort_by_dir }) => {
            this.setState({
              sort_by,
              sort_by_dir
            });
          }}
          onPageChange={page => {}}
          onLimitChange={limit => {}}
          onRefresh={() => {
            this.getData({ load_type: "refresh" });
          }}
          infinite_scroll={true}
          loaded_all_items={this.props.workflow_history_loaded_all}
          onLoadMore={() => {
            this.getData({ load_type: "load_more" });
          }}
          include_checkboxes={false}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, workflow }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const {
    workflow_history,
    workflow_history_begin,
    workflow_history_limit,
    workflow_history_loading,
    workflow_history_refreshing,
    workflow_history_error,
    workflow_history_loaded_all
  } = workflow;
  return {
    token,
    user,
    isMobile,
    colors,
    device,
    workflow_history,
    workflow_history_begin,
    workflow_history_limit,
    workflow_history_loading,
    workflow_history_refreshing,
    workflow_history_error,
    workflow_history_loaded_all
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getWorkflowHistory,
  setActiveWorkflow,
  toggleWorkflowDesigner,
  selectActiveProperty,
  setActiveWorkflowHistory,

  setRightPanel
})(WorkflowHistory);
