import React, { Component } from "react";
import { Wrapper, Card, Input } from "app/NativeComponents/common";
import {
  Select,
  SelectBox,
  InputBox,
  SmallLabel,
  SelectItem
} from "app/NativeComponents/snippets";

import {
  /*common functions */
  focusNextField,
  getStateName
} from "app/NativeActions";

class BillingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_focus: false,
      show_address: !!props.address?.address ? false : true,
      show_contact: !!props.address?.name ? false : true
    };

    this._name_input = React.createRef();
    this._email_input = React.createRef();

    this._address_input = React.createRef();
    this._address2_input = React.createRef();
    this._city_input = React.createRef();
    this._state_input = React.createRef();
    this._zip_input = React.createRef();
  }

  // Canadian provinces need an input that allows non-numeris characters
  checkCanadaProvince(state) {
    var canada_prov_array = [
      "AB",
      "BC",
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "ON",
      "PE",
      "QC",
      "SK",
      "YT"
    ];

    if (canada_prov_array.includes(state)) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    //get state name Array
    var state_data = [];
    for (var i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    return (
      <>
        {this.state.show_address ? (
          <>
            <SmallLabel>Contact Information:</SmallLabel>
            <InputBox
              input_ref={this._name_input}
              name="name"
              disabled={this.props.loading}
              returnKeyType="next"
              placeholder="Name On Card"
              onChange={value => {
                this.props.editAddressField({ prop: "name", value });
              }}
              onSubmitEditing={() => {
                this._email_input.current.focus();
              }}
              blurOnSubmit={false}
              value={this.props.address.name}
              type="text"
            />
            <InputBox
              input_ref={this._email_input}
              name="email"
              disabled={this.props.loading}
              returnKeyType="next"
              placeholder="Billing Email Address"
              onChange={value => {
                this.props.editAddressField({ prop: "email", value });
              }}
              onSubmitEditing={() => {
                this._address_input.current.focus();
              }}
              blurOnSubmit={false}
              value={this.props.address.email}
              type="text"
            />
            <SmallLabel>Billing Address:</SmallLabel>
            <InputBox
              input_ref={this._address_input}
              name="address"
              disabled={this.props.loading}
              returnKeyType="next"
              placeholder="Address"
              onChange={value => {
                this.props.editAddressField({ prop: "address", value });
              }}
              onSubmitEditing={() => {
                this._address2_input.current.focus();
              }}
              blurOnSubmit={false}
              value={this.props.address.address}
              type="text"
            />

            <InputBox
              input_ref={this._address2_input}
              name="address2"
              disabled={this.props.loading}
              returnKeyType="next"
              placeholder="Address Line 2"
              onChange={value =>
                this.props.editAddressField({ prop: "address2", value })
              }
              onSubmitEditing={() => {
                this._city_input.current.focus();
              }}
              blurOnSubmit={false}
              value={this.props.address.address2}
              type="text"
            />

            <InputBox
              input_ref={this._city_input}
              name="city"
              disabled={this.props.loading}
              returnKeyType="next"
              autoCapitalize="words"
              keyboardType="default"
              placeholder="City"
              onChange={value => {
                this.props.editAddressField({ prop: "city", value });
              }}
              blurOnSubmit={true}
              onSubmitEditing={() => {
                this.setState({ state_focus: true });
              }}
              value={this.props.address.city}
              type="text"
            />
            <SelectBox
              select_ref={this._state_input}
              items={state_data}
              disabled={this.props.loading}
              value={this.props.address.state}
              placeholder="State"
              show={this.state.state_focus}
              onFocus={() => {
                this.setState({ state_focus: true });
              }}
              onBlur={() => {
                this.setState({ state_focus: false });
              }}
              onSelect={item => {
                this.props.editAddressField({ prop: "state", value: item });
              }}
              onSubmitEditing={() => {
                this._zip_input.current.focus();
              }}
            />

            <InputBox
              input_ref={this._zip_input}
              name="zip"
              disabled={this.props.loading}
              returnKeyType="done"
              placeholder="Zip Code"
              onChange={value =>
                this.props.editAddressField({ prop: "zip", value })
              }
              onSubmitEditing={() => {
                this.props.addProperty();
              }}
              blurOnSubmit={false}
              value={this.props.address.zip}
              keyboardType={
                this.checkCanadaProvince(this.props.address.state)
                  ? "default"
                  : "numeric"
              }
              type={
                this.checkCanadaProvince(this.props.address.state)
                  ? "text"
                  : "number"
              }
              maxLength={
                this.checkCanadaProvince(this.props.address.state) ? null : "5"
              }
            />
          </>
        ) : (
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-down"
            onPress={() => {
              this.setState({ show_address: true });
            }}
          >
            Contact Information & Billing Address
          </SelectItem>
        )}
      </>
    );
  }
}

export default BillingAddress;
