import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import Quote from "app/DealMachineCore/reuseable/Quote";
import SkipButton from "./SkipButton";

import { loadDashboardStep, displayIntercom } from "app/NativeActions";

class WorkWithUsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("work_with_us")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderContents(dashboard_step) {
    //if (!this.state.completed_step) {
    return (
      <Wrapper
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Quote
          center={this.props.isMobile ? true : false}
          style={{ padding: 15, paddingTop: 5, maxWidth: 600 }}
          image={
            dashboard_step.quoteImage
              ? dashboard_step.quoteImage.fields.file.url
              : ""
          }
          title={dashboard_step.quoteText}
          description={dashboard_step.quoteDescription}
        />
        <Wrapper
          style={{
            paddingTop: 20,
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 600
          }}
        >
          <Copy style={{ textAlign: "center", marginBottom: 10 }}>
            {dashboard_step.header}
          </Copy>

          <Row>
            <PillButton
              id="intercom_button"
              primary={false}
              onPress={() => displayIntercom()}
            >
              {dashboard_step.secondaryButtonText}
            </PillButton>
            <PillButton primary={true} onPress={this.completedStep}>
              {dashboard_step.nextButtonText}
            </PillButton>
          </Row>
        </Wrapper>
      </Wrapper>
    );
    //}
  }

  completedStep() {
    this.props.updateDashboard({
      token: this.props.token,
      type: "completed_enterprise_steps"
    });
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab == "work_with_us") {
      if (!loading && dashboard_step) {
        return (
          <Wrapper>
            <CardBody>{this.renderContents(dashboard_step)}</CardBody>
          </Wrapper>
        );
      }
    }
    return <Wrapper />;
  }
}

export default WorkWithUsStep;
