import React, { Component } from "react";

import { store } from "app/store";
import { Row, Wrapper, Copy } from "app/NativeComponents/common";

import { SelectItem } from "app/NativeComponents/snippets";
import { formatUsPhone, renderDate } from "app/NativeActions";
class CallItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { call } = this.props;
    const { show_more } = this.state;
    const colors = store.getState().settings.colors;

    return (
      <>
        <SelectItem
          onPress={() => {
            if (this.props.hideMore) {
              this.props.hideMore();
            }
            this.props.pushSidePanel({
              slug: "call_log",
              overlay: true,
              data: {
                call: call
              }
            });
          }}
          select_type={"icon"}
          select_icon={
            call?.call_format === "sms"
              ? "sms"
              : call?.call_type === "outgoing"
              ? "call-made"
              : "call-received"
          }
          text_color={colors.white_text_color}
          innerStyle={{
            padding: 10
          }}
          description={
            !!call?.call_duration && call?.call_format !== "sms"
              ? !!call.from_phone_number
                ? "Called from " +
                  formatUsPhone(call.from_phone_number) +
                  " on " +
                  renderDate(call.date_created) +
                  " - " +
                  call?.call_duration
                : "Called on " +
                  renderDate(call.date_created) +
                  " - " +
                  call?.call_duration
              : renderDate(call.date_created)
          }
          textStyle={{ fontSize: 12 }}
          descriptionStyle={{ fontSize: 10, color: colors.white_text_color }}
          subtextStyle={{ color: colors.active_color }}
          subtext={call?.skipped ? "Skipped" : call?.call_results_label}
          icon={"keyboard-arrow-right"}
          renderBottom={
            !!call?.note
              ? () => {
                  return (
                    <Wrapper
                      style={{
                        padding: 10,
                        backgroundColor: colors.dark_phone_color,
                        marginLeft: 50,
                        marginRight: 35,
                        borderRadius: 5
                      }}
                    >
                      <Copy
                        style={{ color: colors.white_text_color, fontSize: 12 }}
                      >
                        {call?.note}
                      </Copy>
                    </Wrapper>
                  );
                }
              : null
          }
        >
          {call?.call_format === "sms"
            ? call?.call_type === "outgoing"
              ? "Outgoing SMS"
              : "Incoming SMS"
            : call?.call_type === "outgoing"
            ? "Outgoing call"
            : "Incoming call"}
        </SelectItem>
      </>
    );
  }
}

export default CallItem;
