import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, KeyboardView, Animation } from "app/NativeComponents/common";
import {
  NewHeader,
  ConfirmInlineButton,
  BottomNavBar,
  InputBox,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  createTeamTag,
  dismissMobileKeyboard
} from "app/NativeActions";

class TagBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_title: props.edit_tag_info?.title || "",
      loading: false
    };

    this._tag_title = React.createRef();
    this.createTag = this.createTag.bind(this);
  }

  componentDidMount() {}

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    const { tag_title } = this.state;
    if (!!tag_title) {
      return true;
    }

    return false;
  }

  createTag() {
    this.props.createTeamTag({
      token: this.props.token,
      title: this.state.tag_title,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const { edit_tag_info, colors } = this.props;
    const { tag_title, loading, deleting } = this.state;
    return (
      <>
        <NewHeader
          title={"New Tag"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._tag_title}
            autoFocus={true}
            name="tag_title"
            disabled={loading}
            returnKeyType="done"
            placeholder={!!tag_title ? "Tag name" : "Enter a name for this tag"}
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                tag_title: value
              });
            }}
            blurOnSubmit={true}
            value={tag_title}
            input_type="text"
            require_confirm={true}
            has_changed={this.checkIfNeedsToSave()}
            submit_button_primary={true}
            submit_button_title={"Create Tag"}
            onSubmit={this.createTag}
            onSubmitEditing={this.createTag}
            loading={this.state.loading}
          />
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, tag, modal }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { side_panel_views } = modal;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  createTeamTag
})(TagBody);
