import React, { Component } from "react";

import {
  Section,
  ColorOverlay,
  SectionInner,
  SectionContainer,
  Diangle
} from "app/NativeComponents/website/common";

import moment from "moment";

class LandingPageFooter extends Component {
  render() {
    return (
      <Section id="dm-website-landing-page-footer-section">
        <Diangle type="Top" />
        <ColorOverlay type={"Gray"} has_media={false}>
          <SectionInner>
            <SectionContainer>
              <div className="dm-website-footer-icon">
                <img alt="" src="/assets/images/DealMachineIcon.svg" />
              </div>
            </SectionContainer>

            <SectionContainer>
              <div className="dm-website-footer-bottom-text">
                <p>
                  <a href="mailto: support@dealmachine.com">
                    support@dealmachine.com
                  </a>{" "}
                  | Copyright DealMachine {moment().format("YYYY")} |{" "}
                  <a href="https://dealmachine.com/privacy-policy">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </SectionContainer>
          </SectionInner>
        </ColorOverlay>
      </Section>
    );
  }
}

export { LandingPageFooter };
