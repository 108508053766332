import React, { Component } from "react";

import { Spin } from "app/NativeComponents/common";
import { Header3 } from "app/NativeComponents/website/common";

import HubspotForm from "react-hubspot-form";

import { AppConfig } from "app/NativeActions";

class FooterForm extends Component {
  render() {
    return (
      <div>
        <Header3>Sign up for our newsletter:</Header3>
        <HubspotForm
          portalId={AppConfig().hubspot_id}
          formId={"5a3e67a9-4962-4bd0-89b3-9a322bfb27e3"}
          onSubmit={() => {}}
          onReady={form => {}}
          loading={
            <div style={{ textAlign: "center" }}>
              <Spin color={"#fff"} size="small" />
            </div>
          }
        />
      </div>
    );
  }
}

export default FooterForm;
