import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Card,
  CardBody,
  Icon,
  Title,
  Copy,
  Bold,
  Spin
} from "app/NativeComponents/common";

class Scene3 extends Component {
  render() {
    if (this.props.scene == 4) {
      return (
        <div className={"animated fadeInUp"}>
          <div style={{ textAlign: "center", padding: 25 }}>
            <Icon size={62} fa_icon={"file-excel-o"} />
            <div
              style={{
                width: "100%",
                marginTop: 10
              }}
            >
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon
                  size={26}
                  color="#28F29E"
                  style={{
                    marginRight: 5
                  }}
                  icon={"check-circle"}
                />
                <Title>Export Complete!</Title>
              </Row>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene3;
