import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  IconButton,
  SelectItem,
  CircleButton
} from "app/NativeComponents/snippets";
import { openEmail, copyToClipBoard } from "app/NativeActions";
class EmailAddressItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  render() {
    const { item, colors, property } = this.props;

    let deliverable_title = "";
    let deliverable_copy = "";
    let deliverable_icon_color = colors.success_color;
    let deliverable_icon = "";

    if (item.valid_email_status) {
      deliverable_title = "Deliverable";
      deliverable_copy = "This email address is verified and deliverable.";
      deliverable_icon_color = colors.success_color;
      deliverable_icon = "check-circle";

      switch (item.valid_email_status) {
        default:
        case "deliverable":
          deliverable_title = "Deliverable";
          deliverable_copy = "This email address is verified and deliverable.";
          deliverable_icon_color = colors.success_color;
          deliverable_icon = "check-circle";
          break;

        case "undeliverable":
          deliverable_title = "Undeliverable";
          deliverable_copy = "This email address undeliverable.";
          deliverable_icon_color = colors.error_color;
          deliverable_icon = "cancel";
          break;

        case "risky":
          deliverable_title = "Risky";
          deliverable_copy =
            "This and email sent to this address may not be delivered.";
          deliverable_icon_color = colors.yellow_card_color;
          deliverable_icon = "warning";
          break;
        case "unknown":
          deliverable_title = "Unknown";
          deliverable_copy =
            "This and email sent to this address may or may not be delivered.";
          deliverable_icon_color = colors.yellow_card_color;
          deliverable_icon = "warning";
          break;
      }
    }

    return (
      <SelectItem
        icon_color={colors.light_text_color}
        selected={false}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "edit_email_address",
            overlay: true,
            data: {
              property,
              email_address: item,
              propertyUpdated: this.props.propertyUpdated
            }
          });
          this.props.toggleShow(false);
        }}
        subtext={item.manual == 1 ? "Manually Added" : "Skip Trace"}
        label={!!item.email_label ? item.email_label : ""}
        innerStyle={!!deliverable_copy ? { paddingLeft: 15 } : {}}
        select_type="custom"
        renderCustomSelect={() => {
          if (!!deliverable_copy) {
            return (
              <IconButton
                icon={deliverable_icon}
                icon_color={deliverable_icon_color}
                tooltip={deliverable_copy}
                tooltipPlacement="top"
                noPress={true}
              />
            );
          }

          return null;
        }}
        renderRight={() => {
          return (
            <Row>
              <CircleButton
                icon="send"
                tooltip={"Send Email"}
                tooltipPlacement="top"
                onPress={() => {
                  openEmail({ email: item.email_address });
                }}
              />
              <CircleButton
                icon="content-copy"
                tooltip={"Copy Email"}
                tooltipPlacement="top"
                onPress={() => {
                  copyToClipBoard(item.email_address);
                  this.props.showSuccess(
                    item.email_address + " was copied to your clipboard",
                    "Copied"
                  );
                }}
              />

              <IconButton
                button_type="small"
                noPress={true}
                icon="keyboard-arrow-right"
              />
            </Row>
          );
        }}
      >
        {item.email_address}
      </SelectItem>
    );
  }
}

export default EmailAddressItem;
