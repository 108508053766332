import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Wrapper,
  KeyboardView,
  Scroll
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineTabs,
  SelectItem,
  SelectBox,
  UpsellButton,
  InlineButton
} from "app/NativeComponents/snippets";
import {
  updateMainMapLocation,
  switchMapMode,
  checkIfUserHasSubscription
} from "app/NativeActions";
import Legend from "./Legend";

class MapOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property_label_type_focus: false,
      map_mode_type_focus: false,
      page_length: props?.map_side_panel_pages?.length
    };
  }

  renderInner() {
    return (
      <>
        <Wrapper
          style={{
            margin: 10,
            marginBottom: 0,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <InlineTabs
            selected_tab={this.props?.main_map_location?.map_type}
            tabs={[
              {
                onPress: () => {
                  this.props.updateMainMapLocation({
                    map_type: "standard"
                  });
                },
                title: "Standard",
                icon: "map",
                slug: "standard"
              },
              {
                onPress: () => {
                  this.props.updateMainMapLocation({
                    map_type: "satellite"
                  });
                },
                title: "Satellite",
                icon: "terrain",
                slug: "satellite"
              }
            ]}
          />
        </Wrapper>

        {!this.props.no_routes ? (
          <UpsellButton
            type="plan"
            slug="driving"
            tier={1}
            contentful_slug="unlock_feature"
            meta_slug="route_tracking"
            onPress={() => {
              this.props.updateMainMapLocation({
                show_routes_on_map:
                  !this.props?.main_map_location?.show_routes_on_map
              });
            }}
            onLockPress={() => {
              if (this.props.hideMapOptions) {
                this.props.hideMapOptions();
              }
            }}
            renderLockedChildren={({ hovering }) => {
              return (
                <>
                  <SelectItem
                    select_type="icon"
                    select_icon="lock"
                    selected={false}
                    noPress={true}
                    pressedIn={hovering}
                    renderBottom={() => {
                      return <Legend colors={this.props.colors} />;
                    }}
                  >
                    Show routes on map
                  </SelectItem>
                </>
              );
            }}
            renderChildren={({ hovering }) => {
              return (
                <>
                  <SelectItem
                    select_type="toggle"
                    selected={this.props?.main_map_location?.show_routes_on_map}
                    noPress={true}
                    pressedIn={hovering}
                    renderBottom={() => {
                      return <Legend colors={this.props.colors} />;
                    }}
                  >
                    Show routes on map
                  </SelectItem>
                </>
              );
            }}
          />
        ) : null}

        <SelectBox
          select_ref={this._state_input}
          items={[
            {
              value: "property_address_range",
              label: "Property Address Number"
            },
            {
              value: "property_address",
              label: "Property Address"
            },
            {
              value: "owner_lastname",
              label: "Owner Last Name"
            },
            {
              value: "owner_name",
              label: "Owner Full Name"
            },
            {
              value: "equity_percent",
              label: "Equity Percent"
            },
            {
              value: "saleprice",
              label: "Sale Price"
            },
            {
              value: "EstimatedValue",
              label: "Estimated Value"
            }
          ]}
          disabled={false}
          value={this.props?.main_map_location?.property_label_type}
          placeholder="Property Label"
          show={this.state.property_label_type_focus}
          onFocus={() => {
            this.setState({ property_label_type_focus: true });
          }}
          onBlur={() => {
            this.setState({ property_label_type_focus: false });
          }}
          onSelect={item => {
            this.props.updateMainMapLocation({
              property_label_type: item
            });
          }}
        />
        <SelectItem
          select_type="toggle"
          selected={this.props?.main_map_location?.north_always_up}
          onPress={() => {
            this.props.updateMainMapLocation({
              north_always_up: !this.props?.main_map_location?.north_always_up
            });
          }}
          description={
            "When location tracking is set to on, your map orientation will be locked into always pointing North while driving."
          }
        >
          North Always Up
        </SelectItem>
        {checkIfUserHasSubscription(
          this.props.user,
          this.props.source_of_truth
        ) ? (
          <SelectItem
            select_type="toggle"
            selected={this.props.map_mode === "tap_to_add"}
            onPress={() => {
              this.props.switchMapMode(
                this.props.map_mode === "tap_to_add" ? "pin" : "tap_to_add"
              );
            }}
            description={
              "Properties will automatically be added as leads when tapped on the map."
            }
          >
            Tap-to-add properites
          </SelectItem>
        ) : null}
      </>
    );
  }

  render() {
    const { colors, isMobile, main_map_location } = this.props;

    return (
      <>
        {!isMobile ? (
          <>
            <KeyboardView style={{ flex: 1 }}>
              {this.renderInner()}
            </KeyboardView>
          </>
        ) : (
          <Scroll>{this.renderInner()}</Scroll>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, property_map, billing }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { main_map_location, map_mode } = property_map;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    map_mode,
    main_map_location,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateMainMapLocation,
  switchMapMode
})(MapOptions);
