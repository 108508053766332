import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  GET_WORKFLOW_INFO,
  GET_WORKFLOW_INFO_SUCCESS,
  GET_WORKFLOW_INFO_FAIL,
  GET_WORKFLOW_ENROLLMENT_OBJECT,
  GET_WORKFLOW_ENROLLMENT_OBJECT_SUCCESS,
  GET_WORKFLOW_STEP_TYPES,
  GET_WORKFLOW_STEP_TYPES_SUCCESS,
  SWITCH_WORKFLOW_TAB,
  SET_ACTIVE_WORKFLOW,
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAIL,
  LOAD_MORE_WORKFLOWS,
  LOAD_MORE_WORKFLOWS_SUCCESS,
  REFRESH_WORKFLOWS,
  UPDATE_WORKFLOW,
  UPDATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_FAIL,
  CREATE_WORKFLOW_SUCCESS,
  REMOVE_WORKFLOW_SUCCESS,
  GET_TESTING_CRITERIA,
  GET_TESTING_CRITERIA_SUCCESS,
  GET_WORKFLOW_HISTORY,
  GET_WORKFLOW_HISTORY_SUCCESS,
  GET_WORKFLOW_HISTORY_FAIL,
  LOAD_MORE_WORKFLOW_HISTORY,
  LOAD_MORE_WORKFLOW_HISTORY_SUCCESS,
  REFRESH_WORKFLOW_HISTORY,
  SET_ACTIVE_WORKFLOW_HISTORY,
  RELOAD_WORKFLOW_HISTORY_SUCCESS,
  GET_WORKFLOW_HISTORY_FOR_LEAD,
  GET_WORKFLOW_HISTORY_FOR_LEAD_SUCCESS,
  MANUALLY_ENROLL_LEAD_IN_WORKFLOW,
  MANUALLY_ENROLL_LEAD_IN_WORKFLOW_FAIL,
  MANUALLY_ENROLL_LEAD_IN_WORKFLOW_SUCCESS,
  GET_BASIC_AUTOMATION,
  GET_BASIC_AUTOMATION_FAIL,
  GET_BASIC_AUTOMATION_SUCCESS,
  UPDATE_BASIC_AUTOMATION,
  UPDATE_BASIC_AUTOMATION_FAIL,
  UPDATE_BASIC_AUTOMATION_SUCCESS,
  GET_API_INFO,
  GET_API_INFO_FAIL,
  GET_API_INFO_SUCCESS,
  UPDATE_API_INFO,
  UPDATE_API_INFO_FAIL,
  UPDATE_API_INFO_SUCCESS,
  GET_ENROLLMENT_TEMPLATES,
  GET_ENROLLMENT_TEMPLATES_FAIL,
  GET_ENROLLMENT_TEMPLATES_SUCCESS,
  GET_TOTAL_ENROLLMENT_COUNT,
  GET_TOTAL_ENROLLMENT_COUNT_SUCCESS
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";

import moment from "moment";

import API from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = API.create();

export const switchWorkflowTab = tab => {
  return {
    type: SWITCH_WORKFLOW_TAB,
    payload: tab
  };
};

export const setActiveWorkflow = ({ token, workflow }) => {
  return dispatch => {
    if (workflow) {
      dispatch({
        type: SET_ACTIVE_WORKFLOW,
        payload: workflow
      });
    }
  };
};

export const getWorkflowInfo = ({
  token,
  type,
  category,
  search,
  filters,
  property_db_only
}) => {
  return dispatch => {
    switch (type) {
      case "enrollment_object":
        dispatch({
          type: GET_WORKFLOW_ENROLLMENT_OBJECT
        });
        break;

      case "testing_criteria":
        dispatch({
          type: GET_TESTING_CRITERIA
        });
        break;

      case "get_total_enrollment_count":
        dispatch({
          type: GET_TOTAL_ENROLLMENT_COUNT
        });
        break;

      case "workflow_step_types":
        dispatch({
          type: GET_WORKFLOW_STEP_TYPES
        });
        break;
      default:
        dispatch({
          type: GET_WORKFLOW_INFO
        });

        break;
    }
    api
      .getWorkflows({
        token,
        type,
        category,
        search,
        filters: JSON.stringify(filters),
        property_db_only
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_WORKFLOW_INFO_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_WORKFLOW_INFO_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (type) {
            case "enrollment_object":
              dispatch({
                type: GET_WORKFLOW_ENROLLMENT_OBJECT_SUCCESS,
                payload: {
                  results: response.data.results,
                  type: property_db_only ? "map" : "leads"
                }
              });
              break;

            case "testing_criteria":
              dispatch({
                type: GET_TESTING_CRITERIA_SUCCESS,
                payload: response.data.results
              });
              break;

            case "get_total_enrollment_count":
              dispatch({
                type: GET_TOTAL_ENROLLMENT_COUNT_SUCCESS,
                payload: response.data.results
              });
              break;

            case "workflow_step_types":
              dispatch({
                type: GET_WORKFLOW_STEP_TYPES_SUCCESS,
                payload: response.data.results
              });
              break;

            default:
              dispatch({
                type: GET_WORKFLOW_INFO_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const getWorkflows = ({
  token,
  load_type,
  lead_id,
  begin = 0,
  limit = 25,
  search,
  sort_by,
  filter_type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    let type = "workflows";

    switch (load_type) {
      default:
      case "load":
        dispatch({ type: GET_WORKFLOWS });
        break;
      case "load_all":
        dispatch({ type: GET_WORKFLOWS });
        type = "load_all_workflows";

        break;

      case "load_more":
        dispatch({ type: LOAD_MORE_WORKFLOWS });
        break;

      case "refresh":
        dispatch({ type: REFRESH_WORKFLOWS });
        break;
    }

    api
      .getWorkflows({
        token,
        type,
        lead_id,
        begin,
        limit,
        search,
        sort_by,
        filter_type
      })
      .then(response => {
        if (response.problem != null) {
          onError();
          dispatch({
            type: GET_WORKFLOWS_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          onError();
          dispatch({
            type: GET_WORKFLOWS_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess();
          switch (load_type) {
            default:
            case "load":
            case "refresh":
              dispatch({
                type: GET_WORKFLOWS_SUCCESS,
                payload: response.data.results
              });
              break;

            case "load_more":
              dispatch({
                type: LOAD_MORE_WORKFLOWS_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const updateWorkflow = ({
  token,
  type,
  workflow_id,
  workflow_title,
  category,
  template_type,
  enrollment_triggers,
  workflow_steps,
  only_enroll_manually,
  workflow_on,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  workflow_ids,
  selected_all
}) => {
  return dispatch => {
    onLoading();
    dispatch({
      type: UPDATE_WORKFLOW
    });

    api
      .updateWorkflow({
        token,
        type,
        workflow_id,
        workflow_title,
        category,
        template_type,
        enrollment_triggers,
        workflow_steps,
        only_enroll_manually,
        workflow_on,
        workflow_ids,
        selected_all
      })
      .then(response => {
        if (response.problem != null) {
          onError();

          dispatch({
            type: UPDATE_WORKFLOW_FAIL,
            payload: response.problem
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data?.error != false) {
          onError();

          dispatch({
            type: UPDATE_WORKFLOW_FAIL,
            payload: response.data.error
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });

          switch (type) {
            case "create_workflow":
              dispatch({
                type: CREATE_WORKFLOW_SUCCESS,
                payload: response.data?.results
              });

              if (onSuccess) {
                onSuccess(response.data?.results?.workflows[0]);
              }

              break;
            case "remove_workflow":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've removed this workflow",
                  title: "Success!"
                }
              });
              dispatch({
                type: REMOVE_WORKFLOW_SUCCESS,
                payload: { workflow_id }
              });
              break;

            default:
            case "update_workflow":
              dispatch({
                type: UPDATE_WORKFLOW_SUCCESS,
                payload: response.data?.results
              });

              break;
          }

          onSuccess(response.data?.results?.workflows);
        }
      });
  };
};

export const getWorkflowHistory = ({
  token,
  load_type,
  begin = 0,
  limit = 25,
  lead_id,
  search,
  sort_by,
  filter_type,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    let type = "workflow_history";

    switch (load_type) {
      default:
      case "load":
        dispatch({ type: GET_WORKFLOW_HISTORY });
        break;

      case "lead":
        dispatch({ type: GET_WORKFLOW_HISTORY_FOR_LEAD, payload: { lead_id } });
        break;
      case "load_more":
        dispatch({ type: LOAD_MORE_WORKFLOW_HISTORY });
        break;

      case "refresh":
        dispatch({ type: REFRESH_WORKFLOW_HISTORY });
        break;
    }

    api
      .getWorkflows({
        token,
        type,
        begin,
        limit,
        lead_id,
        search,
        sort_by,
        filter_type
      })
      .then(response => {
        if (response.problem != null) {
          onError();

          dispatch({
            type: GET_WORKFLOW_HISTORY_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          onError();

          dispatch({
            type: GET_WORKFLOW_HISTORY_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          onSuccess();
          switch (load_type) {
            default:
            case "load":
            case "refresh":
              dispatch({
                type: GET_WORKFLOW_HISTORY_SUCCESS,
                payload: response.data.results
              });
              break;

            case "load_more":
              dispatch({
                type: LOAD_MORE_WORKFLOW_HISTORY_SUCCESS,
                payload: response.data.results
              });
              break;

            case "lead":
              dispatch({
                type: GET_WORKFLOW_HISTORY_FOR_LEAD_SUCCESS,
                payload: {
                  ...response.data.results,
                  lead_id
                }
              });
              break;
          }
        }
      });
  };
};

export const setActiveWorkflowHistory = ({ workflow_history }) => {
  return dispatch => {
    if (workflow_history) {
      dispatch({
        type: SET_ACTIVE_WORKFLOW_HISTORY,
        payload: workflow_history
      });
    }
  };
};

export const reloadWorkflowHistory = ({
  token,
  workflow_enrollment_log_id
}) => {
  return dispatch => {
    let type = "workflow_history";
    if (workflow_enrollment_log_id) {
      api
        .getWorkflows({
          token,
          type,
          workflow_enrollment_log_id
        })
        .then(response => {
          if (response.problem != null) {
          } else if (response.data.error != false) {
          } else {
            dispatch({
              type: RELOAD_WORKFLOW_HISTORY_SUCCESS,
              payload: response.data.results
            });
          }
        });
    }
  };
};

export const manuallyEnrollLeadInWorkflow = ({
  token,
  type,
  workflow_id,
  lead_id,
  onSuccess = null
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });

    const type = "manually_enroll_lead_in_workflow";
    dispatch({
      type: MANUALLY_ENROLL_LEAD_IN_WORKFLOW
    });

    api
      .updateWorkflow({
        token,
        type,
        workflow_id,
        lead_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: MANUALLY_ENROLL_LEAD_IN_WORKFLOW_FAIL,
            payload: response.problem
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data?.error != false) {
          dispatch({
            type: MANUALLY_ENROLL_LEAD_IN_WORKFLOW_FAIL,
            payload: response.data.error
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: {
              message: "You've enrolled this lead into a workflow.",
              title: "Success!"
            }
          });

          dispatch({
            type: MANUALLY_ENROLL_LEAD_IN_WORKFLOW_SUCCESS,
            payload: {
              ...response.data?.results,
              lead_id
            }
          });
          if (onSuccess) {
            onSuccess(response.data?.results?.workflow_history);
          }
        }
      });
  };
};

export const getBasicAutomation = ({ token }) => {
  return dispatch => {
    dispatch({
      type: GET_BASIC_AUTOMATION
    });
    api
      .getWorkflows({
        token,
        type: "basic_automation"
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_BASIC_AUTOMATION_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_BASIC_AUTOMATION_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_BASIC_AUTOMATION_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const updateBasicAutomation = ({
  token,
  slug,
  automation_on,
  automation_data,
  onSuccess = null,
  onError = null,
  onLoading = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    }

    dispatch({
      type: UPDATE_BASIC_AUTOMATION
    });
    api
      .updateWorkflow({
        token,
        type: "update_basic_automation",
        slug,
        automation_on,
        automation_data
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          dispatch({
            type: UPDATE_BASIC_AUTOMATION_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          dispatch({
            type: UPDATE_BASIC_AUTOMATION_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }
          dispatch({
            type: UPDATE_BASIC_AUTOMATION_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getApiInfo = ({ token }) => {
  return dispatch => {
    dispatch({
      type: GET_API_INFO
    });
    api
      .getApiInfo({
        token
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_API_INFO_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_API_INFO_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_API_INFO_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const updateApiInfo = ({
  token,
  type,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    }
    dispatch({
      type: UPDATE_API_INFO
    });
    api
      .updateApiInfo({
        token,
        type
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          dispatch({
            type: UPDATE_API_INFO_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          dispatch({
            type: UPDATE_API_INFO_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }
          dispatch({
            type: UPDATE_API_INFO_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getEnrollmentTemplates = ({ token }) => {
  return dispatch => {
    dispatch({
      type: GET_ENROLLMENT_TEMPLATES
    });
    api
      .getWorkflows({
        token,
        type: "enrollment_templates"
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_ENROLLMENT_TEMPLATES_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_ENROLLMENT_TEMPLATES_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_ENROLLMENT_TEMPLATES_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};
