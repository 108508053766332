import React, { Component } from "react";

import {
  SectionInner,
  SectionContainer
} from "app/NativeComponents/website/common";

import SectionImage from "./SectionImage";
import SectionTitle from "./SectionTitle";
import SectionSubtitle from "./SectionSubtitle";
import SectionDescription from "./SectionDescription";
import ContentItems from "./ContentItems";
import SectionFooterContent from "./SectionFooterContent";
import CallToActions from "./CallToActions";

class FormSectionContent extends Component {
  render() {
    return (
      <SectionInner>
        <SectionContainer>
          <div className="dm-website-form-section">
            <div className="dm-website-form-section-content">
              <SectionImage {...this.props} />
              <SectionTitle {...this.props} />
              <SectionSubtitle {...this.props} />
              <SectionDescription {...this.props} />
            </div>
            <ContentItems {...this.props} />
          </div>
        </SectionContainer>
        <SectionContainer>
          <SectionFooterContent {...this.props} />
          <CallToActions {...this.props} />
        </SectionContainer>
      </SectionInner>
    );
  }
}

export default FormSectionContent;
