import React, { Component } from "react";
import { connect } from "react-redux";
import { FocusModal } from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  changeTab,
  getStats,
  selectActiveTeamMember,
  popSidePanel,
  dismissMobileKeyboard
} from "app/NativeActions";

class TrainingVideos extends Component {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.selectActiveTeamMember(null);
    this.props.popSidePanel();
  }

  render() {
    return (
      <FocusModal
        title="Driver Training"
        onClose={this.handleBack}
        show_side_bar={false}
        renderMainView={() => {
          return <Body {...this.props} />;
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, drawer, team_link }) => {
  const { token, user } = auth;
  const { device, isMobile } = native;
  const { open } = drawer;
  const { require_training, user_dealfinder_page, dealfinder_page_defaults } =
    team_link;

  return {
    token,
    user,
    device,
    isMobile,
    open,

    require_training,
    user_dealfinder_page,
    dealfinder_page_defaults
  };
};

export default connect(mapStateToProps, {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  changeTab,
  getStats,
  selectActiveTeamMember,
  popSidePanel,
  dismissMobileKeyboard
})(TrainingVideos);
