import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row, MultiLineInput } from "app/NativeComponents/common";
import {
  SelectItem,
  SmallLabel,
  IconButton
} from "app/NativeComponents/snippets";

import Mentions from "../Mentions";
import BottomButtons from "./BottomButtons";
import { getTeamMembers, uploadActivityFile, openUrl } from "app/NativeActions";

class NoteTextBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: props.noteValue ? props.noteValue : "",
      mention_tracker: false,
      mention_keyword: "",
      mention_prev_char: " ",
      message_height: 0,
      files_loading: []
    };

    this.updateMessageProp = this.updateMessageProp.bind(this);
    this.updateFilesLoading = this.updateFilesLoading.bind(this);
    this.addFilesLoading = this.addFilesLoading.bind(this);
    this.addToFiles = this.addToFiles.bind(this);
  }

  getTeam(search = "") {
    if (!this.props.team_members_loading) {
      this.props.getTeamMembers({
        token: this.props.token,
        load_type: "replace",
        type: "accepted_members_and_dealfinders",
        username_search: search,
        begin: 0
      });
    }
  }

  componentDidMount() {
    this.getTeam();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.noteValue !== this.props.noteValue) {
      this.setState({
        message: this.props.noteValue
      });
    }

    if (prevState.message != this.state.message && this.props.onNoteChange) {
      this.props.onNoteChange(this.state.message);
    }
    if (
      !prevState.message &&
      prevState.message != "" &&
      this.state.message == ""
    ) {
      this.resetTextbox();
    }

    if (prevProps.active_property != this.props.active_property) {
      this.resetTextbox();
      this.updateMessageProp({ prop: "message", value: "" });
    }
  }

  updateMessageProp({ prop, value }) {
    this.setState({ [prop]: value });
  }

  componentWillUnmount() {
    clearInterval(this._mention_interval);
  }

  updateSuggestions(lastKeyword) {
    this.updateMessageProp({ prop: "mention_keyword", value: lastKeyword });
    clearInterval(this._mention_interval);
    this._mention_interval = setTimeout(() => {
      const username_search = lastKeyword.replace("@", "");
      this.getTeam(username_search);
    }, 250);
  }

  identifyKeyword(val) {
    if (this.state.mention_tracker) {
      const boundary = "B";
      const trigger = "@";
      const pattern = new RegExp(
        `\\${boundary}${trigger}[a-z0-9_-]+|\\${boundary}${trigger}`,
        `gi`
      );
      const keywordArray = val.match(pattern);
      if (keywordArray && !!keywordArray.length) {
        const lastKeyword = keywordArray[keywordArray.length - 1];
        this.updateSuggestions(lastKeyword);
      }
    }
  }

  onChange(event) {
    let value = "";
    if (this.props.device == "desktop") {
      value = event;
    } else if(event.nativeEvent) {

      value = event.nativeEvent.text;
    } else {
      value = event;
    }
    this.updateMessageProp({ prop: "message", value });

    const lastChar = value.substr(value.length - 1);
    const wordBoundry = this.state.mention_prev_char.trim().length == 0;
    if (lastChar == "@" && wordBoundry) {
      this.updateMessageProp({ prop: "mention_tracker", value: true });
      this.updateMessageProp({ prop: "mention_keyword", value: lastChar });
    } else if (
      ((lastChar == " " || lastChar == "\n") && this.state.mention_tracker) ||
      value == ""
    ) {
      this.updateMessageProp({ prop: "mention_tracker", value: false });
    }
    this.updateMessageProp({ prop: "mention_prev_char", value: lastChar });

    this.identifyKeyword(value);
  }

  resetTextbox() {
    this.updateMessageProp({ prop: "mention_prev_char", value: " " });
    this.updateMessageProp({ prop: "mention_tracker", value: false });
    this.updateMessageProp({ prop: "mention_keyword", value: "" });
  }

  updateFilesLoading(file) {
    this.setState({
      files_loading: this.state.files_loading.map(f => {
        if (f.unique_string == file.unique_string) {
          return {
            ...f,
            ...file
          };
        }
        return f;
      })
    });
  }

  addFilesLoading(file) {
    this.setState({
      files_loading: [...this.state.files_loading, file]
    });
  }
  removeFilesLoading(file) {
    this.setState({
      files: this.state.files_loading.filter(
        ({ unique_string }) => unique_string != file.unique_string
      )
    });
  }

  addToFiles(file) {
    this.setState({
      files_loading: this.state.files_loading.filter(
        ({ unique_string }) => unique_string != file.unique_string
      )
    });

    let files = JSON.parse(this.props.file_urls);
    files.push(file);
    this.props.onFilesChanged(JSON.stringify(files));
  }

  removeFromFiles(file) {
    let files = JSON.parse(this.props.file_urls);
    this.props.onFilesChanged(
      JSON.stringify(
        files.filter(({ unique_string }) => unique_string != file.unique_string)
      )
    );
  }

  render() {
    let files = [];
    if (!!this.props.file_urls) {
      files = JSON.parse(this.props.file_urls);
    }
    return (
      <>
        <>
          <Mentions
            updateMessageProp={this.updateMessageProp}
            mention_tracker={this.state.mention_tracker}
            mention_keyword={this.state.mention_keyword}
            mention_prev_char={this.state.mention_prev_char}
            message={this.state.message}
            team_members={this.props.team_members}
            team_members_loading={this.props.team_members_loading}
            user={this.props.user}
            colors={this.props.colors}
          />
          <Wrapper
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              minHeight: 150,
              paddingLeft: 10,
              paddingRight: 10
            }}
          >
            <MultiLineInput
              name={"message"}
              no_container
              placeholder={
                "Type @ to mention a team member and they'll be notified."
              }
              label={"Enter your note here."}
              style={[
                {
                  flex: 1,
                  fontSize: 14,
                  marginRight: 0,
                  marginLeft: 0
                }
              ]}
              value={this.state.message}
              onChange={event => {
                this.onChange(event);
              }}
              all_changes={true}
              onContentSizeChange={event => {
                this.updateMessageProp({
                  prop: "message_height",
                  value: event.nativeEvent.contentSize.height
                });
              }}
              autoCorrect={true}
              spellCheck={true}
            />
          </Wrapper>
        </>
        {files.length > 0 || this.state.files_loading?.length > 0 ? (
          <Wrapper
            style={{
              backgroundColor: this.props.colors.background_color,
              borderRadius: 5,
              margin: 5
            }}
          >
            <SmallLabel>Attached Files: </SmallLabel>
            {files &&
              files.length > 0 &&
              files.map((file, i) => {
                return (
                  <SelectItem
                    key={"file_" + i}
                    select_type={"icon"}
                    select_icon="description"
                    onPress={() => {
                      openUrl(file.file);
                    }}
                    innerStyle={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingRight: 20,
                      paddingLeft: 20
                    }}
                    style={{}}
                    renderRight={() => {
                      return (
                        <IconButton
                          icon="close"
                          onPress={() => {
                            this.removeFromFiles(file);
                          }}
                        />
                      );
                    }}
                  >
                    {file.title}
                  </SelectItem>
                );
              })}
            {this.state.files_loading.map((loading_file, i) => {
              if (!loading_file.error) {
                return (
                  <SelectItem
                    key={"loading_file_" + i}
                    select_type={"none"}
                    noPress={true}
                    innerStyle={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingRight: 20,
                      paddingLeft: 20
                    }}
                    loading={!loading_file.error}
                    style={
                      !!loading_file.error
                        ? {
                            backgroundColor:
                              this.props.colors.orange_color_muted
                          }
                        : {}
                    }
                    subtext={!!loading_file.error ? "Failed" : ""}
                    renderRight={() => {
                      return (
                        <IconButton
                          icon="close"
                          onPress={() => {
                            this.removeFilesLoading(loading_file);
                          }}
                        />
                      );
                    }}
                  >
                    {loading_file.title}
                  </SelectItem>
                );
              }
              return null;
            })}
          </Wrapper>
        ) : null}

        <BottomButtons
          updateMessageProp={this.updateMessageProp}
          onFilesChanged={this.props.onFilesChanged}
          mention_tracker={this.state.mention_tracker}
          message={this.state.message}
          onSubmit={this.props.onSubmit}
          colors={this.props.colors}
          disabled={this.props.disabled}
          loading={this.props.loading}
          button_text={this.props.button_text}
          uploadActivityFile={this.props.uploadActivityFile}
          updateFilesLoading={this.updateFilesLoading}
          addFilesLoading={this.addFilesLoading}
          addToFiles={this.addToFiles}
          token={this.props.token}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, team, property_map }) => {
  const { token, user } = auth;
  const { team_members, team_members_loading } = team;
  const { active_property } = property_map;
  return {
    token,
    user,
    active_property,
    team_members,
    team_members_loading
  };
};

export default connect(mapStateToProps, {
  getTeamMembers,
  uploadActivityFile
})(NoteTextBox);
