import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Row,
  ProfilePic,
  AlmaOrb,
  MarkdownView,
  Spin
} from "app/NativeComponents/common";
import { IconButton } from "app/NativeComponents/snippets";

import { copyToClipBoard } from "app/NativeActions";
import { store } from "app/store";

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display_content: "",
      currentIndex: 0,
      feedback: props.feedback
    };

    this.renderTypewriter = this.renderTypewriter.bind(this);
  }
  componentDidMount() {
    this.renderTypewriter();
  }

  stripMarkdown(markdownText) {
    return markdownText
      .replace(/(^|\s)(#{1,6}\s*)/g, "$1") // Headers
      .replace(/(\*\*|__)(?=\S)([^\r]*?\S)\1/g, "$2") // Bold
      .replace(/(\*|_)(?=\S)([^\r]*?\S)\1/g, "$2") // Italic
      .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Inline links
      .replace(/\n?\*\s(.+)/g, "$1") // Unordered lists
      .replace(/\n?\d+\.\s(.+)/g, "$1") // Ordered lists
      .replace(/```[^`]*```/g, "") // Code blocks
      .replace(/`([^`]+)`/g, "$1") // Inline code
      .replace(/(\n|^)>\s(.+)/g, "$2") // Blockquotes
      .replace(/\n{2,}/g, "\n\n") // Remove extra newlines
      .trim();
  }

  renderTypewriter() {
    const { content } = this.props;
    this.setState(
      {
        display_content: "",
        currentIndex: 0
      },
      () => {
        if (this.props.typewriter) {
          const words = content.split(" ");
          let time = 0;
          if (words.length > 30) {
            time = 1000 / words.length;
          } else {
            time = 500 / words.length;
          }

          this.intervalId = setInterval(() => {
            if (this.state.currentIndex === words.length) {
              clearInterval(this.intervalId);
              return;
            }
            this.setState(prevState => ({
              display_content:
                prevState.display_content + words[prevState.currentIndex] + " ",
              currentIndex: prevState.currentIndex + 1
            }));
          }, time);
        }
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.chat_id !== this.props.chat_id) {
      this.renderTypewriter();
    }

    if (prevProps.feedback !== this.props.feedback) {
      this.setState({
        feedback: this.props.feedback
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { display_content } = this.state;
    const colors = store.getState().settings.colors;
    const { role, user } = this.props;
    return (
      <Wrapper
        style={{
          alignSelf: "stretch",
          alignItems:
            role === "assistant" || role === "assistant_no_chat"
              ? "flex-start"
              : "flex-end"
        }}
      >
        <Row
          style={{
            paddingLeft:
              role === "assistant" || role === "assistant_no_chat" ? 15 : 50,
            paddingRight:
              role === "assistant" || role === "assistant_no_chat" ? 50 : 15,
            alignItems: "flex-end"
          }}
        >
          {role === "assistant" || role === "assistant_no_chat" ? (
            <Wrapper style={{ marginBottom: 15 }}>
              <AlmaOrb active={this.props.active} size={20} />
            </Wrapper>
          ) : null}
          <Wrapper>
            <Wrapper
              style={{
                backgroundColor:
                  role === "assistant" || role === "assistant_no_chat"
                    ? colors.purple_color_muted
                    : colors.gray_color,
                padding: 15,
                margin: 5,
                borderRadius: 15,
                borderBottomLeftRadius:
                  role === "assistant" || role === "assistant_no_chat" ? 0 : 15,
                borderBottomRightRadius:
                  role === "user" || role === "user_no_chat" ? 0 : 15
              }}
            >
              {(this.props.typewriter && !display_content) ||
              (!this.props.typewriter &&
                !this.props.content &&
                this.props.loading) ? (
                <Spin size={"small"} />
              ) : null}
              <MarkdownView
                style={{
                  whiteSpace: "pre-wrap"
                }}
                source={
                  this.props.typewriter ? display_content : this.props.content
                }
                escapeHtml={false}
              />
            </Wrapper>
          </Wrapper>

          {(role === "user" || role === "user_no_chat") && user ? (
            <Wrapper style={{ marginBottom: 15 }}>
              <ProfilePic
                email={user.email}
                image={user.image}
                firstname={user.firstname}
                lastname={user.lastname}
                style={{}}
                size={25}
              />
            </Wrapper>
          ) : null}
        </Row>

        {role === "assistant" && !this.props.chat_rendering ? (
          <Row style={{ marginLeft: 45 }}>
            <IconButton
              onPress={() => {
                copyToClipBoard(
                  this.props.typewriter ? display_content : this.props.content
                );
                this.props.showSuccess(
                  "The message was copied to your clipboard",
                  "Copied"
                );
              }}
              button_type="small"
              icon="content-copy"
              tooltip="Copy message to clipboard"
              icon_color={colors.light_text_color}
            />

            <IconButton
              onPress={() => {
                this.setState(
                  {
                    feedback: "thumbs_up"
                  },
                  () => {
                    this.props.updateAlmaChat({
                      token: this.props.token,
                      type: "message_feedback",
                      message_id: this.props.message_id,
                      feedback: "thumbs_up",
                      onLoading: () => {},
                      onError: () => {},
                      onSuccess: () => {}
                    });
                  }
                );
              }}
              disabled={!this.props.message_id}
              tooltip="This message was helpful"
              button_type="small"
              icon="thumb-up"
              icon_color={
                this.state.feedback === "thumbs_up"
                  ? colors.active_color
                  : colors.light_text_color
              }
            />
            <IconButton
              onPress={() => {
                this.setState(
                  {
                    feedback: "thumbs_down"
                  },
                  () => {
                    this.props.updateAlmaChat({
                      token: this.props.token,
                      type: "message_feedback",
                      message_id: this.props.message_id,
                      feedback: "thumbs_down",

                      onLoading: () => {},
                      onError: () => {},
                      onSuccess: () => {}
                    });
                  }
                );
              }}
              disabled={!this.props.message_id}
              tooltip="This message was not helpful"
              button_type="small"
              icon="thumb-down"
              icon_color={
                this.state.feedback === "thumbs_down"
                  ? colors.active_color
                  : colors.light_text_color
              }
            />
          </Row>
        ) : null}
      </Wrapper>
    );
  }
}

export default Message;
