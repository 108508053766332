import React from "react";
import {
  Row,
  Copy,
  Bold,
  Wrapper,
  Icon,
  Spin
} from "app/NativeComponents/common";
import ButtonWrapper from "./ButtonWrapper";
import { store } from "app/store";

const InlineButton = props => {
  const {
    children,
    primary,
    style,
    innerStyle,
    disabled = false,
    deleteButton = false,
    icon,
    fa_icon,
    icon_right,
    fa_icon_right,
    noCopy = false,
    pressedIn = false, //pressed in override from props
    pressed_in_background_color = null,
    loading = false,
    button_type = "normal",
    description,
    textColor,
    iconColor = null,
    selected = false,
    renderBottom = null,
    renderRight = null,
    inBottomSheet = false,
    textStyle,
    notification = null,
    b_color = null,
    hover_color = null
  } = props;
  const colors = store.getState().settings.colors;

  return (
    <ButtonWrapper
      {...props}
      renderInner={({
        hovering /*actual pressed in from state of button wrapper*/
      }) => {
        const text_color = textColor
          ? textColor
          : deleteButton
          ? colors.error_color
          : primary
          ? colors.orange_color
          : colors.actionable_text_color;
        const copyStyle = {
          color: text_color,
          textAlign: "center",
          whiteSpace: "nowrap",
          ...textStyle
        };

        const background_color =
          pressedIn || hovering
            ? !!pressed_in_background_color
              ? pressed_in_background_color
              : !!hover_color
              ? hover_color
              : colors.hover_color
            : b_color
            ? b_color
            : "transparent";

        let s = {
          padding: 10,
          paddingRight: 15,
          paddingLeft: 15,
          borderRadius: 30,
          margin: 5,
          backgroundColor: background_color,
          opacity: disabled ? 0.5 : 1
        };
        switch (button_type) {
          case "full":
            s.padding = 15;
            s.borderRadius = 5;
            break;
          case "select_button":
            s = {
              ...s,
              padding: 20,
              margin: 5,
              borderRadius: 5,
              alignItems: "flex-start"
            };
            break;
          default:
            break;
        }

        return (
          <Wrapper style={{ ...s, ...style }}>
            <Row
              style={{
                alignItems: "center",
                justifyContent: "center",
                ...innerStyle
              }}
            >
              {loading && !icon && !fa_icon && !icon_right && !fa_icon_right ? (
                <Wrapper style={{ marginRight: 5 }}>
                  <Spin
                    size="small"
                    color={iconColor ? iconColor : text_color}
                    style={{ fontSize: 18 }}
                  />
                </Wrapper>
              ) : null}

              {!!notification ? (
                <>
                  {loading ? (
                    <Wrapper style={{ marginRight: 5 }}>
                      <Spin
                        size="small"
                        color={iconColor ? iconColor : text_color}
                        style={{ fontSize: 18 }}
                      />
                    </Wrapper>
                  ) : (
                    <Wrapper
                      style={{
                        backgroundColor: iconColor ? iconColor : text_color,
                        borderRadius: 30,
                        padding: 0,
                        paddingRight: 5,
                        paddingLeft: 5,
                        marginRight: 5,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Copy
                        style={{
                          color: colors.white_text_color,
                          fontSize: 12
                        }}
                      >
                        {notification}
                      </Copy>
                    </Wrapper>
                  )}
                </>
              ) : !!icon || !!fa_icon ? (
                <>
                  {loading ? (
                    <Wrapper style={{ marginRight: 5 }}>
                      <Spin
                        size="small"
                        color={iconColor ? iconColor : text_color}
                        style={{ fontSize: 18 }}
                      />
                    </Wrapper>
                  ) : (
                    <Icon
                      size={18}
                      color={iconColor ? iconColor : text_color}
                      icon={icon}
                      fa_icon={fa_icon}
                      style={{ marginRight: 5 }}
                    />
                  )}
                </>
              ) : null}
              {noCopy ? (
                children
              ) : selected ? (
                <Copy style={copyStyle}>
                  <Bold>{children}</Bold>
                </Copy>
              ) : (
                <Copy style={copyStyle}>{children}</Copy>
              )}

              {!!icon_right || !!fa_icon_right ? (
                <>
                  {loading ? (
                    <Wrapper style={{ marginLeft: 5 }}>
                      <Spin
                        size="small"
                        color={iconColor ? iconColor : text_color}
                        style={{ fontSize: 18 }}
                      />
                    </Wrapper>
                  ) : (
                    <Icon
                      size={18}
                      color={iconColor ? iconColor : text_color}
                      icon={icon_right}
                      fa_icon={fa_icon_right}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </>
              ) : null}

              {renderRight ? renderRight() : null}
            </Row>
            {!!description ? (
              <Copy
                style={{ fontSize: 10, color: text_color, textAlign: "center" }}
              >
                {description}
              </Copy>
            ) : null}
            {renderBottom ? renderBottom() : null}
          </Wrapper>
        );
      }}
    />
  );
};

export { InlineButton };
