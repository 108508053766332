import { toTitleCase } from "app/NativeActions";
import { Spin, Wrapper, Copy, Bold } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

import { store } from "app/store";
import React, { PureComponent } from "react";
import Headers from "./Headers";
import Items from "./Items";
import MobileList from "./MobileList";
import Pagination from "./Pagination";
import InfiniteScroll from "./InfiniteScroll";
class ResponsiveList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sort_by_title: ""
    };
  }

  componentDidMount() {
    const { sort_by, sort_by_dir } = this.props;
    this.determineSortByTitle(sort_by, sort_by_dir);
  }
  componentDidUpdate(prevProps, prevState) {
    const { sort_by, sort_by_dir } = this.props;

    if (
      sort_by !== prevProps.sort_by ||
      sort_by_dir !== prevProps.sort_by_dir
    ) {
      this.determineSortByTitle(sort_by, sort_by_dir);
    }
  }
  determineSortByTitle(sort_by, sort_by_dir) {
    let sort_by_title = sort_by.replace(/_/g, " ");

    if (sort_by_dir == "asc") {
      sort_by_title = toTitleCase(sort_by_title) + " Ascending";
    }
    sort_by_title = toTitleCase(sort_by_title) + " Descending";
    this.setState({
      sort_by_title
    });
  }
  render() {
    const {
      onlyMobile = false,
      items = [],
      headers = [],
      selected_items = [],
      selected_all = false,
      selected_all_in_account = false,

      items_loading = false,
      items_refreshing = false,
      loaded_all_items = true,

      loading_text = "Loading...",
      refreshing_text = "Loading...",
      loading_more_text = "Loading...",

      total_count = 0,
      total_count_loading = false,
      limit = 25,
      begin = 0,
      sort_by = "",
      sort_by_dir = "asc",
      primary_button = null,
      primary_button_right_icon = null,
      secondary_button = null,
      secondary_button_right_icon = null,
      include_checkboxes = false,

      onItemPress = () => {},
      onSortBy = () => {},
      onPrimaryButtonPress = () => {},
      onSecondaryButtonPress = () => {},
      onCheckBoxPress = () => {},
      onCheckBoxHeaderPress = () => {},
      onPageChange = () => {},
      onLimitChange = () => {},
      onRefresh = () => {},
      onLoadMore = () => {},

      no_items_title = "No items found.",
      no_items_description = "There are no items found matching your criteria.",
      no_items_button_text,
      onNoItemsButtonPress = () => {},

      renderItem = null,
      renderTooltip = null,

      selected_option_actions,
      active_item_id = null,
      page = 1,
      infinite_scroll = false,
      hide_edit_button,
      custom_edit_button
    } = this.props;
    const { sort_by_title } = this.state;
    const colors = store.getState().settings.colors;
    const dark_mode = store.getState().settings.dark_mode;
    const isMobile = store.getState().native.isMobile;
    const device = store.getState().native.device;

    if (isMobile || onlyMobile) {
      return (
        <MobileList
          colors={colors}
          device={device}
          items={items}
          active_item_id={active_item_id}
          renderItem={renderItem}
          headers={headers}
          selected_all={selected_all}
          selected_items={selected_items}
          selected_all_in_account={selected_all_in_account}
          selected_option_actions={selected_option_actions}
          total_count={total_count}
          items_loading={items_loading}
          loaded_all_items={loaded_all_items}
          items_refreshing={items_loading}
          loading_text={loading_text}
          refreshing_text={refreshing_text}
          limit={limit}
          begin={begin}
          include_checkboxes={include_checkboxes}
          onCheckBoxPress={onCheckBoxPress}
          onCheckBoxHeaderPress={onCheckBoxHeaderPress}
          onRefresh={onRefresh}
          onLoadMore={onLoadMore}
          onSortBy={onSortBy}
          sort_by_title={sort_by_title}
          no_items_title={no_items_title}
          no_items_description={no_items_description}
          no_items_button_text={no_items_button_text}
          onNoItemsButtonPress={onNoItemsButtonPress}
          primary_button={primary_button}
          primary_button_right_icon={primary_button_right_icon}
          secondary_button={secondary_button}
          secondary_button_right_icon={secondary_button_right_icon}
          onPrimaryButtonPress={onPrimaryButtonPress}
          onSecondaryButtonPress={onSecondaryButtonPress}
          hide_edit_button={hide_edit_button}
          custom_edit_button={custom_edit_button}
        />
      );
    }
    return (
      <Wrapper className={dark_mode} style={{ position: "relative", flex: 1 }}>
        <Headers
          headers={headers}
          sort_by={sort_by}
          sort_by_dir={sort_by_dir}
          onSortBy={onSortBy}
          colors={colors}
          include_checkboxes={include_checkboxes}
          onCheckBoxHeaderPress={onCheckBoxHeaderPress}
          selected_all={selected_all}
          selected_items={selected_items}
        />
        <Items
          headers={headers}
          items={items}
          renderTooltip={renderTooltip}
          active_item_id={active_item_id}
          colors={colors}
          primary_button={primary_button}
          primary_button_right_icon={primary_button_right_icon}
          secondary_button={secondary_button}
          secondary_button_right_icon={secondary_button_right_icon}
          onPrimaryButtonPress={onPrimaryButtonPress}
          onSecondaryButtonPress={onSecondaryButtonPress}
          onItemPress={onItemPress}
          include_checkboxes={include_checkboxes}
          onCheckBoxPress={onCheckBoxPress}
          selected_all={selected_all}
          selected_items={selected_items}
        />
        {infinite_scroll ? (
          <InfiniteScroll
            items_loading={items_loading}
            items={items}
            onLoadMore={onLoadMore}
            loaded_all_items={loaded_all_items}
            loading_more_text={loading_more_text}
          />
        ) : (
          <Pagination
            items={items}
            page={page}
            items_loading={items_loading}
            limit={limit}
            begin={begin}
            onLimitChange={onLimitChange}
            total_count={total_count}
            total_count_loading={total_count_loading}
            colors={colors}
            onPageChange={onPageChange}
          />
        )}

        {items_loading && (!infinite_scroll || items.length == 0) ? (
          <Wrapper
            style={{
              backgroundColor: colors.white_color_opacity,
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0
            }}
          >
            <Spin text={loading_text} />
          </Wrapper>
        ) : items.length == 0 ? (
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Copy style={{ textAlign: "center" }}>
              <Bold>{no_items_title}</Bold>
            </Copy>
            <Copy style={{ textAlign: "center", marginBottom: 10 }}>
              {no_items_description}
            </Copy>
            {!!no_items_button_text ? (
              <GhostButton
                primary={true}
                style={{}}
                innerStyle={{}}
                onPress={() => onNoItemsButtonPress()}
              >
                {no_items_button_text}
              </GhostButton>
            ) : null}
          </Wrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export { ResponsiveList };
