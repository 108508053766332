import React, { Component } from "react";
import { connect } from "react-redux";
import Streetview from "react-google-streetview";

import { setStreetViewCoords } from "app/NativeActions";
class NativeStreetView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState({
        refresh: true
      });
    }

    if (this.state.refresh && this.state.refresh !== prevState.refresh) {
      this.setState({
        refresh: false
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this._moving_interval);
    clearInterval(this._looking_interval);
  }

  render() {
    const { street_view_coords } = this.props;
    const google_key = "AIzaSyB4OaKDa5cihTwLebwF_RVKJptyOStr4sg";
    //const google_key = "AIzaSyCSqWxybt6K0wSW77qnddvl7evwcybsYqI";

    if (
      street_view_coords?.latitude &&
      street_view_coords?.longitude &&
      !this.state.refresh
    ) {
      return (
        <>
          <Streetview
            apiKey={google_key}
            streetViewPanoramaOptions={{
              position: {
                lat: street_view_coords.latitude,
                lng: street_view_coords.longitude
              },
              pov: {
                heading: street_view_coords.heading || 0,
                pitch: street_view_coords.pitch || 0
              },
              imageDateControl: false,
              fullscreenControl: false,
              linksControl: false,
              addressControl: false,
              zoomControl: false,
              panControl: false
            }}
            onPositionChanged={e => {
              if (e) {
                if (
                  e?.lat() !== street_view_coords.latitude ||
                  e?.lng() !== street_view_coords.longitude
                ) {
                  clearInterval(this._moving_interval);
                  this._moving_interval = setTimeout(() => {
                    this.props.setStreetViewCoords({
                      latitude: e?.lat(),
                      longitude: e?.lng(),
                      heading: street_view_coords.heading,
                      pitch: street_view_coords.pitch
                    });
                  }, 50);
                }
              }
            }}
            onPovChanged={e => {
              if (e) {
                if (
                  e?.heading !== street_view_coords.heading ||
                  e?.pitch !== street_view_coords.pitch
                ) {
                  clearInterval(this._looking_interval);
                  this._looking_interval = setTimeout(() => {
                    this.props.setStreetViewCoords({
                      latitude: street_view_coords.latitude,
                      longitude: street_view_coords.longitude,
                      heading: e?.heading,
                      pitch: e?.pitch
                    });
                  }, 50);
                }
              }
            }}
            onVisibleChanged={e => {
              //console.log(e);
            }}
          />
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, property_map }) => {
  const { token, user } = auth;
  const { street_view_coords, mapIsMoving } = property_map;
  return {
    token,
    user,
    street_view_coords,
    mapIsMoving
  };
};

export default connect(mapStateToProps, { setStreetViewCoords })(
  NativeStreetView
);
