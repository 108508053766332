import React, { Component } from "react";
import { Animation, Wrapper, Copy, Row } from "app/NativeComponents/common";
import {
  CircleButton,
  InlineButton,
  ConfirmInlineButton,
  UpsellButton
} from "app/NativeComponents/snippets";

import { checkIfUserHasMetadata } from "app/NativeActions";
import { store } from "app/store";
import AllPrompts from "./AllPrompts";
class SuggestedPrompts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_prompts: false,
      skip_trace_loading: false,
      mail_loading: false
    };

    this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.current_parent !== this.props.current_parent) {
      this.startTimer();
    }
  }

  startTimer() {
    const device = store.getState().native.device;

    this.setState(
      {
        show_prompts: false
      },
      () => {
        this.intervalId = setTimeout(
          () => {
            this.setState(
              {
                show_prompts: true
              },
              () => {
                this.props.onDisplay();
              }
            );
          },
          device === "desktop" ? 500 : 0
        );
      }
    );
  }

  render() {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const desktopMobile = store.getState().native.desktopMobile;

    let counter = 0;
    if (this.state.show_prompts && !this.props.chat_rendering) {
      let prompts = this.props.prompts;
      if (
        this.props.current_parent &&
        this.props.current_parent_children_prompts?.length > 0
      ) {
        prompts = this.props.current_parent_children_prompts;
      }

      let follow_up_slugs = [];
      if (!!this.props.last_message_follow_up_slugs) {
        follow_up_slugs = this.props.last_message_follow_up_slugs.split(",");
      }

      return (
        <Animation type="fadeIn">
          <Row style={{ justifyContent: "flex-end", flexWrap: "wrap" }}>
            {prompts &&
              (!this.props.pressed_one_prompt || this.props.current_parent) &&
              prompts.map((item, index) => {
                let already_used_prompt = false;
                for (let i = 0; i < this.props.messages?.length; i++) {
                  if (
                    this.props.messages[i].prompt_slug === item.prompt_slug &&
                    !this.props.current_parent
                  ) {
                    already_used_prompt = true;
                  }
                }
                if (!already_used_prompt && counter < 3) {
                  counter++;
                  return (
                    <UpsellButton
                      key={"suggested_prompt_" + index}
                      meta_slug="alma"
                      force_unlock={this.props.onboarding}
                      disabled={this.props.disabled}
                      onPress={() => {
                        this.props.onSelectSuggestedPrompt({
                          content: item.content,
                          messages: item.messages,
                          display_content: item.display_content,
                          openai_model: item.openai_model,
                          prompt_slug: item.prompt_slug,
                          prompt_type: item.prompt_type,
                          assistant_response: item.assistant_response,
                          prompt_children: item.children,
                          answer_slug: item.answer_slug,
                          answer_group: item.answer_group,
                          follow_up_slug: item.follow_up_slug
                        });

                        if (this.props.onboardingComplete) {
                          this.props.onboardingComplete();
                        }
                      }}
                      renderLockedChildren={({ hovering }) => {
                        return (
                          <InlineButton
                            buttonContainerStyle={{ alignSelf: "stretch" }}
                            disabled={this.props.disabled}
                            noPress={true}
                            pressedIn={hovering}
                            style={
                              desktopMobile || device === "mobile"
                                ? {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    flex: 1,
                                    alignSelf: "stretch"
                                  }
                                : {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    maxWidth: 500
                                  }
                            }
                          >
                            {item?.display_content}
                          </InlineButton>
                        );
                      }}
                      renderChildren={({ hovering }) => {
                        return (
                          <InlineButton
                            disabled={this.props.disabled}
                            buttonContainerStyle={{ alignSelf: "stretch" }}
                            noPress={true}
                            pressedIn={hovering}
                            style={
                              desktopMobile || device === "mobile"
                                ? {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    flex: 1,
                                    alignSelf: "stretch"
                                  }
                                : {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    maxWidth: 500
                                  }
                            }
                          >
                            {item?.display_content}
                          </InlineButton>
                        );
                      }}
                    />
                  );
                }
                return null;
              })}

            {follow_up_slugs && follow_up_slugs.length > 0 ? (
              <>
                {follow_up_slugs.map((item, index) => {
                  switch (item) {
                    case "add_lead":
                      if (!this.props.property?.deal) {
                        return (
                          <InlineButton
                            disabled={this.props.disabled}
                            key={"follow_up_" + index}
                            buttonContainerStyle={{ alignSelf: "stretch" }}
                            loading={this.props.add_deal_loading.some(
                              loading_property =>
                                loading_property.property_id ===
                                this.props.property.property_id
                            )}
                            onPress={() => {
                              if (
                                !this.props.property?.deal &&
                                !this.props.add_deal_loading.some(
                                  loading_property =>
                                    loading_property.property_id ===
                                    this.props.property.property_id
                                )
                              ) {
                                this.props.addDeal({
                                  token: this.props.token,
                                  property: this.props.property,
                                  route_id: this.props.current_route
                                    ? this.props.current_route.route_id
                                    : 0,
                                  address: this.props.property.property_address,
                                  address2:
                                    this.props.property.property_address2,
                                  city: this.props.property
                                    .property_address_city,
                                  state:
                                    this.props.property.property_address_state,
                                  zip: this.props.property.property_address_zip,
                                  onLoading: () => {
                                    if (this.props.updateMainState) {
                                      this.props.updateMainState({
                                        show_alma_panel: "hide"
                                      });
                                    }
                                  },
                                  devicetype:
                                    this.props.device == "mobile"
                                      ? "mobile_map"
                                      : "desktop_map"
                                });
                              }
                            }}
                            style={
                              desktopMobile || device === "mobile"
                                ? {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    flex: 1,
                                    alignSelf: "stretch"
                                  }
                                : {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    maxWidth: 500
                                  }
                            }
                          >
                            Add Lead
                          </InlineButton>
                        );
                      }

                      return null;

                    case "send_mail":
                      if (
                        this.props.property?.deal &&
                        this.props.property?.deal?.approved == 0
                      ) {
                        return (
                          <InlineButton
                            disabled={this.props.disabled}
                            key={"follow_up_" + index}
                            buttonContainerStyle={{ alignSelf: "stretch" }}
                            loading={this.state.mail_loading}
                            onPress={() => {
                              this.props.updateLead({
                                token: this.props.token,
                                deal_ids: this.props.property?.deal?.id,
                                campaign_id:
                                  this.props.property?.deal
                                    ?.current_default_campaign_id,
                                type: "start_mailers",
                                onLoading: () => {
                                  this.setState({
                                    mail_loading: true
                                  });
                                  if (this.props.updateMainState) {
                                    this.props.updateMainState({
                                      mail_loading: true,
                                      show_alma_panel: "hide"
                                    });
                                  }
                                },
                                onError: () => {
                                  this.setState({
                                    mail_loading: false
                                  });
                                  if (this.props.updateMainState) {
                                    this.props.updateMainState({
                                      mail_loading: false
                                    });
                                  }
                                },
                                onSuccess: results => {
                                  if (
                                    this.props.propertyUpdated &&
                                    results?.properties?.length == 1
                                  ) {
                                    this.props.propertyUpdated(
                                      results.properties[0]
                                    );
                                  }

                                  this.setState({
                                    mail_loading: false
                                  });
                                  if (this.props.updateMainState) {
                                    this.props.updateMainState({
                                      mail_loading: false
                                    });
                                  }
                                }
                              });
                            }}
                            style={
                              desktopMobile || device === "mobile"
                                ? {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    flex: 1,
                                    alignSelf: "stretch"
                                  }
                                : {
                                    borderWidth: 1,
                                    borderColor: colors.border_color,
                                    borderStyle: "solid",
                                    borderRadius: 15,
                                    borderBottomRightRadius: 0,
                                    maxWidth: 500
                                  }
                            }
                          >
                            Start Mail
                          </InlineButton>
                        );
                      }

                      return null;
                  }
                })}
              </>
            ) : null}

            {this.props.current_parent &&
            this.props.current_parent_children_prompts?.length > 0 ? (
              <>
                {!this.props.onboarding &&
                this.props.source_of_truth?.subscription?.metadata?.alma ? (
                  <InlineButton
                    disabled={this.props.disabled}
                    buttonContainerStyle={{ alignSelf: "stretch" }}
                    style={
                      desktopMobile || device === "mobile"
                        ? {
                            borderWidth: 1,
                            borderColor: colors.border_color,
                            borderStyle: "solid",
                            borderRadius: 15,
                            borderBottomRightRadius: 0,
                            flex: 1,
                            alignSelf: "stretch"
                          }
                        : {
                            borderWidth: 1,
                            borderColor: colors.border_color,
                            borderStyle: "solid",
                            borderRadius: 15,
                            borderBottomRightRadius: 0,
                            maxWidth: 500
                          }
                    }
                    onPress={() => {
                      this.props.cancelPromptParent(this.props.current_parent);
                    }}
                    icon={"close"}
                  >
                    Nevermind
                  </InlineButton>
                ) : null}
              </>
            ) : (
              <Row>
                {this.props.sent_messages ? (
                  <InlineButton
                    onPress={this.props.regenerateLastResponse}
                    buttonContainerStyle={{ alignSelf: "stretch" }}
                    icon={"refresh"}
                  >
                    Try Again
                  </InlineButton>
                ) : null}

                <AllPrompts
                  disabled={this.props.disabled}
                  popoverPlacement="top"
                  renderComponent={({ pressedIn, hovering }) => {
                    return (
                      <InlineButton
                        noPress={true}
                        pressedIn={pressedIn || hovering}
                        buttonContainerStyle={{ alignSelf: "stretch" }}
                        icon={"more-horiz"}
                      >
                        View Prompts
                      </InlineButton>
                    );
                  }}
                  prompts={this.props.prompts}
                  onSelectSuggestedPrompt={this.props.onSelectSuggestedPrompt}
                />
              </Row>
            )}
          </Row>
        </Animation>
      );
    }
    return null;
  }
}

export default SuggestedPrompts;
