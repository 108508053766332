import React, { Component } from "react";
import { Wrapper, Card } from "app/NativeComponents/common";
import ElementItem from "./ElementItem";
import { store } from "app/store";
import Inkfree from "./Inkfree";
import { LoadFonts } from "app/NativeComponents/common";
import { getScaleWithAnchor } from "app/NativeActions";
class MailerPreview extends Component {
  constructor(props) {
    super(props);

    let width = 600;
    let height = 408;
    let scale = 1;
    if (!!props.width && !!props.height) {
      width = props.width;
      height = props.height;
    }

    if (props.scale) {
      scale = props.scale;
    } else if (props.scaled_width) {
      scale = props.scaled_width / width;
    }

    let scaled_width = scale * width;
    let scaled_height = scale * height;

    this.state = {
      width: width,
      height: height,
      scale: scale,
      scaled_width: scaled_width,
      scaled_height: scaled_height,
      elements: props.elements || []
    };

    this.renderBracketVariables = this.renderBracketVariables.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.elements && this.props.elements !== prevState.elements) {
      this.setState({
        elements: this.props.elements
      });
    } else if (this.props.scale && this.props.scale !== prevProps.scale) {
      let scale = this.props.scale;
      let scaled_width = scale * this.props.width;
      let scaled_height = scale * this.props.height;

      this.setState({
        scale,
        scaled_width,
        scaled_height
      });
    }
  }

  renderCardWrapper(children) {
    const { with_card = false } = this.props;
    const { width, height, scaled_width, scaled_height } = this.state;

    if (with_card) {
      return (
        <Card
          style={{
            width: scaled_width,
            height: scaled_height
          }}
        >
          {children()}
        </Card>
      );
    }

    return children();
  }

  renderBracketVariables(content) {
    let new_content = content;
    const { bracket_variables } = this.props;
    if (bracket_variables) {
      for (let i = 0; i < bracket_variables.length; i++) {
        if (!bracket_variables[i].value) {
          new_content = new_content.split(bracket_variables[i].slug).join("");
        } else {
          new_content = new_content
            .split(bracket_variables[i].slug)
            .join(bracket_variables[i].value);
        }
      }
    }

    return new_content;
  }

  renderGrid() {
    const { width, height } = this.state;
    const { grid = false } = this.props;
    if (grid) {
      const colors = store.getState().settings.colors;

      let veritcal_lines = [];
      for (let i = 0; i < width; i = i + 24) {
        veritcal_lines.push(i);
      }

      let horizontal_lines = [];
      for (let i = 0; i < height; i = i + 24) {
        horizontal_lines.push(i);
      }

      return (
        <Wrapper
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 999999999
          }}
        >
          {veritcal_lines.map((line, i) => {
            return (
              <Wrapper
                key={"hori_" + i}
                style={{
                  top: 0,
                  bottom: 0,
                  left: line,
                  pointerEvents: "none",
                  position: "absolute",
                  width: 1,
                  height: "100%",

                  backgroundColor: colors.border_color
                }}
              />
            );
          })}

          {horizontal_lines.map((line, i) => {
            return (
              <Wrapper
                key={"verti" + i}
                style={{
                  top: line,
                  right: 0,
                  left: 0,
                  pointerEvents: "none",
                  position: "absolute",
                  height: 1,
                  width: "100%",

                  backgroundColor: colors.border_color
                }}
              />
            );
          })}
        </Wrapper>
      );
    }
  }

  renderTextSafe() {
    const { show_text_safe = false } = this.props;
    if (show_text_safe) {
      const colors = store.getState().settings.colors;

      return (
        <Wrapper
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 999999999
          }}
        >
          <Wrapper
            className={"striped-error-border"}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,

              pointerEvents: "none",
              borderWidth: 18,
              borderStyle: "solid",
              borderColor: colors.error_color,
              opacity: 0.75
            }}
          />
        </Wrapper>
      );
    }
  }

  render() {
    const { width, height, scaled_width, scaled_height, scale, elements } =
      this.state;
    const {
      selected_element,
      updateLayer,
      side,
      backgroundColor = "#fff",
      bracket_variables,
      template_type = "postcard",
      grid = false,
      show_text_safe = false,
      draggable = false,
      no_lazy = false
    } = this.props;
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    return this.renderCardWrapper(() => {
      return (
        <Wrapper
          style={{
            width: scaled_width,
            height: scaled_height
          }}
        >
          <Wrapper
            className="deal-mailer-scale"
            style={
              device == "mobile"
                ? getScaleWithAnchor({
                    scale,
                    width: scaled_width,
                    height: height
                  })
                : {
                    transform: "scale(" + scale + ")",
                    transformOrigin: "0 0"
                  }
            }
          >
            <Wrapper
              className="deal-mailer-preview"
              style={{
                backgroundColor,
                width,
                height,
                position: "relative"
              }}
            >
              <LoadFonts {...this.props} elements={elements} />
              {elements &&
                elements.map((element, i) => {
                  return (
                    <ElementItem
                      no_lazy={no_lazy}
                      colors={colors}
                      device={device}
                      zIndex={i}
                      key={"elements_" + i}
                      element={element}
                      scale={scale}
                      selected_element={selected_element}
                      updateLayer={updateLayer}
                      renderBracketVariables={this.renderBracketVariables}
                      grid={grid}
                      draggable={draggable}
                      template_type={template_type}
                    />
                  );
                })}
              {side == "back" ? (
                <Inkfree
                  template_type={template_type}
                  show_text_safe={show_text_safe}
                  colors={colors}
                  device={device}
                  renderBracketVariables={this.renderBracketVariables}
                />
              ) : null}
              {this.renderGrid()}
              {this.renderTextSafe()}
            </Wrapper>
          </Wrapper>
        </Wrapper>
      );
    });
  }
}

export default MailerPreview;
