import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton,
  SelectItem
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class EditPhoneNumber extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      panel_data,
      phone_number_text: panel_data?.phone_number?.phone_number || "",
      phone_label: panel_data?.phone_number?.phone_label || "",
      bad_phone: parseInt(panel_data?.phone_number?.bad_phone)
        ? panel_data?.phone_number?.bad_phone
        : 0,
      loading: false,
      deleting: false
    };

    this._phone_number_input = React.createRef();
    this._phone_number_label = React.createRef();

    this.savePhoneNumber = this.savePhoneNumber.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }
  checkIfNeedsToSave() {
    if (
      !!this.state.phone_number_text &&
      (this.state.phone_number_text !=
        this.state.panel_data?.phone_number?.phone_number ||
        this.state.phone_label !=
          this.props.panel_data?.phone_number?.phone_label ||
        this.state.bad_phone != this.state.panel_data?.phone_number?.bad_phone)
    ) {
      return true;
    }

    return false;
  }

  savePhoneNumber() {
    const {
      panel_data: { property, phone_number }
    } = this.state;

    const { phone_label, phone_number_text, bad_phone } = this.state;
    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "edit_phone_number",
      phone_label,
      phone_number: phone_number_text,
      bad_phone: bad_phone ? 1 : 0,
      owner_phone_number_id: phone_number.owner_phone_number_id,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      panel_data: { property, phone_number }
    } = this.state;
    const { phone_number_text, phone_label, loading } = this.state;
    let formatted_property_address = "";
    if (property) {
      const display_property = determineDisplayProperty(property);
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    }
    return (
      <Container>
        <NewHeader
          title="Edit Phone Number"
          subtitle={"Edit phone number for " + formatted_property_address.line1}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._phone_number_label}
            name="phone_number_label"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            placeholder="Label"
            disabled={loading}
            onChange={value => {
              this.setState({
                phone_label: value
              });
            }}
            value={phone_label}
            type="text"
            onSubmitEditing={() => {
              if (phone_number.manual) {
                if (this._phone_number_input?.current) {
                  if (this.props.device == "desktop") {
                    this._phone_number_input.current.getInputDOMNode().focus();
                  } else {
                    this._phone_number_input.current.focus();
                  }
                }
              }
            }}
          />
          <InputBox
            input_ref={this._phone_number_input}
            name="phone_number"
            disabled={loading || !phone_number.manual}
            returnKeyType="done"
            placeholder="Phone Number"
            onChange={value =>
              this.setState({
                phone_number_text: value
              })
            }
            onSubmitEditing={this.savePhoneNumber}
            blurOnSubmit={true}
            value={phone_number_text}
            type="text"
            mask_type={"cel-phone"}
            mask={"(999) 999-9999"}
          />

          <SelectItem
            select_type="toggle"
            selected={this.state.bad_phone}
            onPress={() => {
              this.setState({
                bad_phone: !this.state.bad_phone
              });
            }}
          >
            Flag as "Wrong Number"
          </SelectItem>

          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.savePhoneNumber}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Save Phone Number
          </GhostButton>
        </KeyboardView>
        <BottomNavBar>
          <ConfirmInlineButton
            confirm_text="Really Delete?"
            confirm_icon="delete"
            loading_text="Deleting"
            loading={this.state.deleting}
            button_type="full"
            onPress={() => {
              this.props.updateOwnerInfo({
                token: this.props.token,
                deal_id: property?.deal?.id,
                type: "remove_phone_number",
                owner_phone_number_id: phone_number.owner_phone_number_id,
                onLoading: () => {
                  this.setState({
                    deleting: true
                  });
                },
                onError: () => {
                  this.setState({
                    deleting: false
                  });
                },
                onSuccess: results => {
                  if (
                    this.state.panel_data?.propertyUpdated &&
                    results?.properties?.length == 1
                  ) {
                    this.state.panel_data.propertyUpdated(
                      results.properties[0]
                    );
                  }
                  this.setState({
                    deleting: false
                  });
                  this.handleBack();
                }
              });
            }}
          >
            Delete Phone Number
          </ConfirmInlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(EditPhoneNumber);
