import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Spin,
  Row,
  Icon,
  Title,
  Copy,
  Bold,
  FlipOverCard,
  ExternalImage,
  Button,
  ModalOverlay,
  Modal,
  HTMLMarkdown,
} from "app/NativeComponents/common";

import MailerPreview from "app/DealMachineCore/reuseable/MailerPreview";
import { setMailerView } from "app/NativeActions";
import TemplateOverlay from "./TemplateOverlay";

class MailerFlipCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flipped: props.flipped ? props.flipped : false,
    };
  }

  renderMailer({ scale, back_scale, side }) {
    const { mailer, colors, isMobile } = this.props;

    const front_width = mailer.front_width * scale;
    const front_height = mailer.front_height * scale;
    const back_width = mailer.back_width * back_scale;
    const back_height = mailer.back_height * back_scale;
    switch (mailer.template_system_type) {
      case "legacy":
      case "sent_mailer":
        //revisit for HTML previews
        // if (
        //   mailer?.html_front_preview &&
        //   mailer?.html_back_preview &&
        //   !isMobile
        // ) {
        //   const sourceHTML =
        //     side == "front"
        //       ? mailer?.html_front_preview
        //       : mailer?.html_back_preview;
        //   return (
        //     <HTMLMarkdown
        //       source={sourceHTML}
        //       style={{
        //         height: side == "front" ? front_height : back_height,
        //         width: side == "front" ? front_width : back_width,
        //         zoom: scale,
        //         backgroundColor: "#fff",
        //       }}
        //     />
        //   );
        // }
        return (
          <ExternalImage
            style={{
              width: side == "front" ? front_width : back_width,
              height: side == "front" ? front_height : back_height,
            }}
            image={
              side == "front"
                ? mailer.template_preview
                : mailer.template_preview_back
            }
          />
        );

      default:
        return (
          <MailerPreview
            template_type={mailer.template_type}
            backgroundColor={"#fff"}
            width={side == "front" ? mailer.front_width : mailer.back_width}
            height={side == "front" ? mailer.front_height : mailer.back_height}
            scale={scale}
            elements={
              side == "front" ? mailer.front_layers : mailer.back_layers
            }
            bracket_variables={mailer.bracket_variables}
            side={side}
            with_card={false}
          />
        );
    }
  }

  renderFlipCard({ scale, back_scale }) {
    const {
      mailer,
      title,
      options,
      colors,
      is_viewing = false,
      include_options = true,
    } = this.props;
    const { flipped } = this.state;

    const front_width = mailer.front_width * scale;
    const front_height = mailer.front_height * scale;
    const back_width = mailer.back_width * back_scale;
    const back_height = mailer.back_height * back_scale;

    return (
      <FlipOverCard
        width={flipped === "front" ? front_width : back_width}
        height={flipped === "front" ? front_height : back_height}
        flipped={flipped}
        frontSide={() => {
          return (
            <Wrapper
              style={{
                width: front_width,
                height: front_height,
                position: "relative",
                borderColor: colors.border_color,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              {this.renderMailer({ scale, back_scale, side: "front" })}
              {include_options ? (
                <TemplateOverlay
                  title={title}
                  colors={colors}
                  mailer={mailer}
                  options={options}
                  scale={scale}
                  is_viewing={is_viewing}
                  flipped={flipped}
                  setMailerView={this.props.setMailerView}
                  include_edit_button={this.props.include_edit_button}
                  onEditButtonPress={this.props.onEditButtonPress}
                  onFlip={() => {
                    this.setState({
                      flipped: !flipped,
                    });
                  }}
                  onView={() => {
                    this.setState({
                      view: true,
                    });
                  }}
                  onMailerPress={this.props.onMailerPress}
                  include_swap_button={this.props.include_swap_button}
                  onSwap={this.props.onSwap}
                />
              ) : null}
            </Wrapper>
          );
        }}
        backSide={() => {
          return (
            <Wrapper
              style={{
                width: back_width,
                height: back_height,
                alignItems: "center",
                justifyContent: "center",
                borderColor: colors.border_color,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <Wrapper
                style={{
                  width: back_width,
                  height: back_height,
                  position: "relative",
                }}
              >
                {this.renderMailer({ scale, back_scale, side: "back" })}

                {include_options ? (
                  <TemplateOverlay
                    title={title}
                    colors={colors}
                    mailer={mailer}
                    options={options}
                    scale={scale}
                    is_viewing={is_viewing}
                    flipped={flipped}
                    setMailerView={this.props.setMailerView}
                    include_edit_button={this.props.include_edit_button}
                    onEditButtonPress={this.props.onEditButtonPress}
                    onFlip={() => {
                      this.setState({
                        flipped: !flipped,
                      });
                    }}
                    onView={() => {
                      this.setState({
                        view: true,
                      });
                    }}
                    onMailerPress={this.props.onMailerPress}
                    include_swap_button={this.props.include_swap_button}
                    onSwap={this.props.onSwap}
                  />
                ) : null}
              </Wrapper>
            </Wrapper>
          );
        }}
      />
    );
  }

  render() {
    const { scale = 0.4, back_scale = null, style, mailer } = this.props;
    const { view, flipped } = this.state;
    if (mailer) {
      const width = flipped
        ? mailer.back_width * scale
        : mailer.front_width * scale;
      const height = flipped
        ? mailer.back_height * scale
        : mailer.front_height * scale;
      return (
        <Wrapper style={{ ...style }}>
          <Wrapper
            style={{
              width: width,
              height: height,
            }}
          >
            {this.renderFlipCard({
              scale,
              back_scale: back_scale ? back_scale : scale,
            })}
          </Wrapper>
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, message }) => {
  const { colors } = settings;
  const { isMobile } = native;

  return {
    colors,
    isMobile,
  };
};

export default connect(mapStateToProps, {
  setMailerView,
})(MailerFlipCard);
