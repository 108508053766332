import React, { PureComponent } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { store } from "app/store";
import { connect } from "react-redux";

class Button extends PureComponent {
  constructor() {
    super();
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    this.state = { canceled: false };
  }

  handleButtonPress() {
    this.buttonPressTimer = setTimeout(() => {
      if (this.props.onLongPress) {
        this.props.onLongPress();
        this.setState({ canceled: true });
      }
    }, 1000);

    this.setState({ canceled: false });
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }
  componentWillUnmount() {
    clearTimeout(this.buttonPressTimer);
  }

  handleClick(ev) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.state.canceled) {
      this.props.onPress();
    }

    this.setState({ canceled: false });
  }

  render() {
    const {
      children,
      style,
      onPress,
      to,
      external,
      formButton,
      id,
      ref,
      className,
      tooltip,
      tooltipPlacement,
      trackingId,
      onHover = () => {},
      onHoverOut = () => {},
      internal = false,
      rel = "noopener noreferrer"
    } = this.props;

    const dark_mode = store.getState().settings.dark_mode;

    if (formButton) {
      return (
        <button
          id={id}
          ref={ref}
          type="submit"
          role="button"
          //onClick={onPress}
          //onLongPress={onLongPress}
          className={"deal-button " + className + " " + dark_mode}
          style={style}
          onMouseEnter={() => {
            onHover();
          }}
          onMouseLeave={() => {
            onHoverOut();
          }}
        >
          {children}
        </button>
      );
    } else if (external) {
      return (
        <div
          id={id}
          ref={ref}
          className={"deal-button " + className + " " + dark_mode}
          href={to ? to : "#"}
          target="_blank"
          rel={rel}
          style={style}
          onMouseEnter={() => {
            onHover();
          }}
          onMouseLeave={() => {
            onHoverOut();
          }}
        >
          {children}
        </div>
      );
    } else if (internal) {
      return (
        <a
          id={id}
          ref={ref}
          className={"deal-button " + className + " " + dark_mode}
          href={to ? to : "#"}
          rel={rel}
          style={style}
          onMouseEnter={() => {
            onHover();
          }}
          onMouseLeave={() => {
            onHoverOut();
          }}
        >
          {children}
        </a>
      );
    }
    if (!!tooltip) {
      return (
        <Tooltip
          disableFocusListener
          title={tooltip}
          placement={tooltipPlacement}
        >
          <div
            id={id}
            role="button"
            //onLongPress={onLongPress}
            className={"deal-button " + className + " " + dark_mode}
            onTouchStart={this.handleButtonPress}
            onTouchEnd={this.handleButtonRelease}
            onMouseDown={this.handleButtonPress}
            onMouseUp={this.handleButtonRelease}
            onClick={this.handleClick.bind(this)}
            href={to ? to : "#"}
            style={style}
            onMouseEnter={() => {
              onHover();
            }}
            onMouseLeave={() => {
              onHoverOut();
            }}
          >
            {children}
          </div>
        </Tooltip>
      );
    }
    return (
      <div
        id={id}
        role="button"
        //onLongPress={onLongPress}
        className={"deal-button " + className + " " + dark_mode}
        onTouchStart={this.handleButtonPress}
        onTouchEnd={this.handleButtonRelease}
        onMouseDown={this.handleButtonPress}
        onMouseUp={this.handleButtonRelease}
        onClick={this.handleClick.bind(this)}
        href={to ? to : "#"}
        style={style}
        onMouseEnter={() => {
          onHover();
        }}
        onMouseLeave={() => {
          onHoverOut();
        }}
      >
        {children}
      </div>
    );
  }
}

const ConnectedButton = connect(null, {})(Button);

export { ConnectedButton as Button };
