import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  BottomNavBar,
  InlineButton,
  List,
  SelectItem,
  IconButton,
  CircleButton,
  AttentionBox
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  showSuccess,
  copyToClipBoard,
  determineMainOwnerInfo,
  formatAddress
} from "app/NativeActions";
import MailingAddressItem from "./MailingAddressItem";
class MailingAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false,
      loading: false
    };

    this._scroll_view = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  scrollToBottom() {
    const { device } = this.props;

    if (device == "desktop" && this._scroll_view && this._scroll_view.current) {
      this._scroll_view.current.scrollTop =
        this._scroll_view.current.scrollHeight + 20;
    } else if (
      device == "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      this._scroll_view.current.scrollToEnd
    ) {
      this._scroll_view.current.scrollToEnd({ animated: true });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  toggleShow(s) {
    this.setState({
      show: s
    });
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      style,
      property,
      popoverPlacement = "bottom",
      renderComponent = () => {},
      renderComponentLoading = () => {},
      componentStyle = {}
    } = this.props;
    const {
      selected_lists,
      stacked_lists,
      loading_options,
      search,
      show,
      hide_unselected
    } = this.state;

    const disabled = !property?.deal || property?.deal?.removed == 1;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.toggleShow(s);
        }}
        popover_width={450}
        popover_height={350}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={
          "Manage mailing addresses for " + property.property_address
        }
        disabled={disabled}
        includeCloseButton={true}
        renderComponent={({ hovering, pressed_in }) => {
          if (this.props.people_data) {
            return (
              <IconButton
                noPress={true}
                pressedIn={hovering || pressed_in || show}
                style={{ marginLeft: 0 }}
                tooltip={"Other Mailing Addresses."}
                tooltipPlacement={"top"}
                icon="more-vert"
                disabled={disabled}
              />
            );
          }

          return (
            <CircleButton
              noPress={true}
              pressedIn={hovering || pressed_in || show}
              style={{ marginLeft: 0 }}
              tooltip={"Mailing Addresses"}
              tooltipPlacement={"top"}
              icon="mail"
              show_notification={!disabled}
              notification_color={colors.active_color}
              notification_number={
                parseInt(property?.deal?.additional_mailing_addresses?.length) +
                1
              }
              disabled={disabled}
            />
          );
        }}
        renderMenu={() => {
          return (
            <>
              {/*
              <Wrapper style={{ alignSelf: "stretch" }}>
                <Row>
                  <Wrapper style={{ flex: 1 }}>
                    <SearchBar
                      style={{
                        margin: 10,
                        marginRight: 0,
                        borderWidth: 0,
                        borderBottomWidth: 0
                      }}
                      //autoFocus={true}
                      title="Search Mailing Addresses"
                      onChange={value => {
                        this.setState({
                          search: value
                        });
                      }}
                      value={search}
                    />
                  </Wrapper>
                </Row>
              </Wrapper>
              */}

              <List
                style={{ alignSelf: "stretch" }}
                items={this.filterOptions(
                  property?.deal?.owner?.mailing_addresses
                )}
                listHeader={() => {
                  const owner_info = determineMainOwnerInfo(property);
                  const formatted_address = formatAddress({
                    address: {
                      address: owner_info.owner_address,
                      address2: owner_info.owner_address2,
                      address_city: owner_info.owner_address_city,
                      address_state: owner_info.owner_address_state,
                      address_zip: owner_info.owner_address_zip
                    }
                  });
                  if (
                    formatted_address.line1
                      ?.toLowerCase()
                      .indexOf(search?.toLowerCase()) != -1 ||
                    formatted_address.line2
                      ?.toLowerCase()
                      .indexOf(search?.toLowerCase()) != -1
                  ) {
                    return (
                      <SelectItem
                        icon_color={colors.light_text_color}
                        selected={owner_info.send_to_owner_address == 1}
                        noPress={true}
                        label={"County records:"}
                        description={owner_info?.owner_address_full}
                        innerStyle={{ paddingLeft: 15 }}
                        style={{
                          backgroundColor: colors.active_color_muted
                        }}
                        select_tooltip={"Toggle Sending Mail"}
                        select_type="custom"
                        renderRight={() => {
                          return (
                            <Row>
                              <CircleButton
                                icon="content-copy"
                                tooltip={"Copy Mailing Address"}
                                tooltipPlacement="top"
                                style={{ marginRight: 25 }}
                                onPress={() => {
                                  copyToClipBoard(
                                    formatted_address.line1 +
                                      ", " +
                                      formatted_address.line2
                                  );
                                  this.props.showSuccess(
                                    formatted_address.line1 +
                                      ", " +
                                      formatted_address.line2 +
                                      " was copied to your clipboard",
                                    "Copied"
                                  );
                                }}
                              />
                            </Row>
                          );
                        }}
                        renderCustomSelect={() => {
                          return (
                            <IconButton
                              icon={
                                owner_info.send_to_owner_address == 1
                                  ? "check-box"
                                  : "check-box-outline-blank"
                              }
                              tooltip={"Toggle Sending Mail"}
                              tooltipPlacement="top"
                              loading={this.state.loading}
                              onPress={() => {
                                this.props.updateOwnerInfo({
                                  token: this.props.token,
                                  deal_id: property.deal?.id,
                                  owner_mailing_address_id: "main_address",
                                  type: "toggle_owner_address",
                                  send_to_address:
                                    owner_info.send_to_owner_address == 1
                                      ? 0
                                      : 1,

                                  onLoading: () => {
                                    this.setState({
                                      loading: true
                                    });
                                  },
                                  onError: () => {
                                    this.setState({
                                      loading: false
                                    });
                                  },
                                  onSuccess: results => {
                                    this.setState({
                                      loading: false
                                    });

                                    if (
                                      this.props.propertyUpdated &&
                                      results?.properties?.length == 1
                                    ) {
                                      this.props.propertyUpdated(
                                        results.properties[0]
                                      );
                                    }
                                  }
                                });
                              }}
                            />
                          );
                        }}
                      >
                        {owner_info?.owner_name}
                      </SelectItem>
                    );
                  }
                }}
                itemStructure={({ item, index }) => {
                  return (
                    <MailingAddressItem
                      key={"option_" + index}
                      item={item}
                      toggleShow={this.toggleShow}
                      {...this.props}
                    />
                  );
                }}
              />
              <BottomNavBar>
                <InlineButton
                  button_type={"full"}
                  primary={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "add_mailing_address",
                      overlay: true,
                      data: {
                        property,
                        propertyUpdated: this.props.propertyUpdated
                      }
                    });
                    this.toggleShow(false);
                  }}
                  icon="add"
                  tooltipPlacement={"top"}
                  tooltip={
                    "By adding additional mailing addresses, you can send mail to multiple addresses. However, if our data changes, we NOT be able to update your manually added addresses, you will still be mailing to these addresses if the owner changes."
                  }
                >
                  Add Mailing Address
                </InlineButton>
              </BottomNavBar>
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors, dark_mode } = settings;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  showSuccess
})(MailingAddresses);
