import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  InputBox,
  SelectBox,
  GhostButton,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  determineDisplayProperty,
  formatAddress,
  searchDealMachine
} from "app/NativeActions";

import SelectAPropertyToLink from "./SelectAPropertyToLink";

class LinkPropertyWithData extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      loading: false,
      properties: null,

      address_range: "",
      address_predir: "",
      address_name: "",
      address_suffix: "",
      address_postdir: "",
      address_suite_name: "",

      address_suite_range: "",

      city: panel_data?.property?.property_address_city,
      state: panel_data?.property?.property_address_state,
      zip: panel_data?.property?.property_address_zip
    };

    this._address_range_input = React.createRef();
    this._address_predir_input = React.createRef();
    this._address_name_input = React.createRef();
    this._address_suffix_input = React.createRef();
    this._address_postdir_input = React.createRef();

    this._address_suite_name_input = React.createRef();
    this._address_suite_range_input = React.createRef();

    this._city_input = React.createRef();
    this._state_input = React.createRef();
    this._zip_input = React.createRef();

    this.cancelSearch = this.cancelSearch.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  cancelSearch() {
    this.setState({
      properties: null
    });
  }

  render() {
    const {
      loading,
      address_range,
      address_predir,
      address_name,
      address_suffix,
      address_postdir,
      address_suite_name,
      address_suite_range,
      city,
      state,
      zip
    } = this.state;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    const property = panel_data?.property;
    const display_property = determineDisplayProperty(property);
    const formatted_property_address = formatAddress({
      address: {
        address: display_property.property_address,
        address2: display_property.property_address2,
        address_city: display_property.property_address_city,
        address_state: display_property.property_address_state,
        address_zip: display_property.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    let state_data = [];
    for (let i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    if (this.state.properties) {
      return (
        <SelectAPropertyToLink
          {...this.props}
          propertyUpdated={panel_data.propertyUpdated}
          properties={this.state.properties}
          formatted_property_address={formatted_property_address}
          property={property}
          cancelSearch={this.cancelSearch}
        />
      );
    }

    return (
      <Container>
        <NewHeader
          title="Match Lead with Property Data"
          subtitle={
            property
              ? "Match a property from our database with your lead (" +
                formatted_property_address?.line1 +
                ")"
              : ""
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        {!property?.id < 0 ? (
          <AttentionBox
            title="Lead is Matched"
            description={
              "Your lead is matched with " +
              formatted_property_address?.line1 +
              " " +
              formatted_property_address?.line2 +
              ". If that is not correct, search for a new property in our database to match to your lead."
            }
          />
        ) : (
          <AttentionBox
            title="Lead is Unmatched"
            description={
              "Use this tool to search our database for suggested properties to match with your lead. Try leaving different fields blank to discover a close match that may have a different street spelling, a different city name, or some other small variances."
            }
          />
        )}

        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            autoFocus={false}
            input_ref={this._address_range_input}
            name="address"
            disabled={loading}
            returnKeyType="next"
            placeholder="Street Address Number (Ex. 1170)"
            onChange={value => {
              this.setState({
                address_range: value
              });
            }}
            onSubmitEditing={() => {
              this._address_predir_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_range}
            type="text"
          />

          <InputBox
            autoFocus={false}
            input_ref={this._address_predir_input}
            name="address_predir"
            disabled={loading}
            returnKeyType="next"
            placeholder="Street Address Direction (Ex. W, N, S, E)"
            onChange={value => {
              this.setState({
                address_predir: value
              });
            }}
            onSubmitEditing={() => {
              this._address_name_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_predir}
            type="text"
          />

          <InputBox
            autoFocus={false}
            input_ref={this._address_name_input}
            name="address_name"
            disabled={loading}
            returnKeyType="next"
            placeholder="Street"
            onChange={value => {
              this.setState({
                address_name: value
              });
            }}
            onSubmitEditing={() => {
              this._address_suffix_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_name}
            type="text"
          />

          <InputBox
            autoFocus={false}
            input_ref={this._address_suffix_input}
            name="address_suffix"
            disabled={loading}
            returnKeyType="next"
            placeholder="Street Suffix (Ex. St, Rd, Blvd)"
            onChange={value => {
              this.setState({
                address_suffix: value
              });
            }}
            onSubmitEditing={() => {
              this._address_postdir_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_suffix}
            type="text"
          />

          <InputBox
            autoFocus={false}
            input_ref={this._address_postdir_input}
            name="address_postdir"
            disabled={loading}
            returnKeyType="next"
            placeholder="Ending Street Direction (Ex. W, N, S, E)"
            onChange={value => {
              this.setState({
                address_postdir: value
              });
            }}
            onSubmitEditing={() => {
              this._address_suite_name_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_postdir}
            type="text"
          />

          <InputBox
            autoFocus={false}
            input_ref={this._address_suite_name}
            name="address_suite_name"
            disabled={loading}
            returnKeyType="next"
            placeholder="Suite Name (Ex. Apt, #)"
            onChange={value => {
              this.setState({
                address_suite_name: value
              });
            }}
            onSubmitEditing={() => {
              this._address_suite_range.current.focus();
            }}
            blurOnSubmit={false}
            value={address_suite_name}
            type="text"
          />
          <InputBox
            autoFocus={false}
            input_ref={this._address_suite_range}
            name="address_suite_range"
            disabled={loading}
            returnKeyType="next"
            placeholder="Suite Number (Ex. B, 23)"
            onChange={value => {
              this.setState({
                address_suite_range: value
              });
            }}
            onSubmitEditing={() => {
              this._city_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_suite_range}
            type="text"
          />

          <InputBox
            input_ref={this._city_input}
            name="city"
            disabled={loading}
            returnKeyType="next"
            autoCapitalize="words"
            keyboardType="default"
            placeholder="City"
            onChange={value => {
              this.setState({
                city: value
              });
            }}
            blurOnSubmit={true}
            onSubmitEditing={() => {
              this.setState({ state_focus: true });
            }}
            value={city}
            type="text"
          />
          <SelectBox
            select_ref={this._state_input}
            items={state_data}
            disabled={loading}
            value={state}
            placeholder="State"
            show={this.state.state_focus}
            onFocus={() => {
              this.setState({ state_focus: true });
            }}
            onBlur={() => {
              this.setState({ state_focus: false });
            }}
            onSelect={item => {
              this.setState({
                state: item
              });
            }}
            onSubmitEditing={() => {
              this._zip_input.current.focus();
            }}
          />

          <InputBox
            input_ref={this._zip_input}
            name="zip"
            disabled={loading}
            returnKeyType="done"
            keyboardType="numeric"
            placeholder="Zip Code"
            onChange={value => {
              this.setState({
                zip: value
              });
            }}
            onSubmitEditing={this.addMailingAddress}
            blurOnSubmit={false}
            value={zip}
            type="number"
            maxLength={"5"}
          />

          <GhostButton
            primary={true}
            button_type="full"
            onPress={() => {
              this.props.searchDealMachine({
                token: this.props.token,
                type: "properties",
                address_range,
                address_predir,
                address_name,
                address_suffix,
                address_postdir,
                address_suite_name,
                address_suite_range,
                city,
                state,
                zip,
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                },
                onSuccess: results => {
                  this.setState({
                    loading: false,
                    properties: results.search_results
                  });
                }
              });
            }}
            loading={loading}
          >
            Search Properties
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { states, colors } = settings;

  return { token, user, device, states, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  searchDealMachine
})(LinkPropertyWithData);
