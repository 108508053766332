import {
  ACKNOWLEDGE_END_ROUTE,
  EDIT_ROUTE_FILTER,
  END_PLANNED_ROUTE,
  END_PLANNED_ROUTE_FAIL,
  END_PLANNED_ROUTE_SUCCESS,
  END_TRACKED_ROUTE,
  END_TRACKED_ROUTE_FAIL,
  END_TRACKED_ROUTE_SUCCESS,
  ERROR_MESSAGE,
  GET_ROUTE_PROPERTIES,
  GET_ROUTE_PROPERTIES_FAIL,
  GET_ROUTE_PROPERTIES_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_FAIL,
  GET_ROUTES_SUCCESS,
  MAP_ROUTES_SUCCESS,
  CLEAR_ROUTES,
  GET_MAP_PROPERTIES_SUCCESS,
  REFRESH_MAP_PROPERTIES,
  INIT_EDIT_ROUTE_FILTERS,
  IS_LOADING,
  LOAD_MORE_ROUTES,
  LOAD_MORE_ROUTES_SUCCESS,
  REFRESH_ROUTE_PROPERTIES,
  REFRESH_ROUTES,
  REMOVE_TRACKED_ROUTE,
  REMOVE_TRACKED_ROUTE_FAIL,
  REMOVE_TRACKED_ROUTE_SUCCESS,
  ROUTE_PROPERTIES_TOGGLE_TAB,
  SAVE_ROUTE_FILTERS,
  SELECT_ACTIVE_ROUTE,
  START_PLANNED_ROUTE,
  START_PLANNED_ROUTE_FAIL,
  START_PLANNED_ROUTE_SUCCESS,
  PREPPED_PLANNED_ROUTE,
  SET_PLANNED_ROUTE,
  SET_ROUTE_PLANNED_DISTANCE,
  START_TRACKED_ROUTE,
  START_TRACKED_ROUTE_FAIL,
  START_TRACKED_ROUTE_SUCCESS,
  SUCCESS_MESSAGE,
  TOGGLE_DRIVING_IMAGES,
  TOGGLE_SHOW_ROUTES,
  TRIGGER_LOGOUT,
  UPDATE_CURRENT_ROUTE_SECTION,
  UPDATE_PLANNED_ROUTE,
  UPDATE_PLANNED_ROUTE_FAIL,
  UPDATE_PLANNED_ROUTE_SUCCESS,
  UPDATE_ROUTE_FILTER,
  UPDATE_ROUTE_TIMER,
  UPDATE_SINGLE_ROUTE_FILTER,
  UPDATE_TRACKED_ROUTE,
  UPDATE_TRACKED_ROUTE_FAIL,
  UPDATE_TRACKED_ROUTE_SUCCESS,
  TRIGGER_PLANNED_ROUTE,
  TOGGLE_DRIVING_MENU,
  RESTORE_CURRENT_ROUTE_SUCCESS,
  TOGGLE_VIRTUAL_DRIVE_START
} from "app/DealMachineCore/types";

import { convertDistance, getDistance } from "geolib";

import { appRedirect, AppConfig } from "app/NativeActions";

import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";

const apiv2 = APIV2.create();

const calculateTotalMiles = ({ coordinates, previous_total = 0 }) => {
  let total = 0;
  //Check for null coordinates and remove
  var filtered_coordinates = coordinates.filter(function (coord) {
    if (coord.latitude != null && coord.longitude != null) {
      return coord;
    }
  });
  //Need at least 2 coordinates to calculate mileage
  if (filtered_coordinates.length > 1) {
    for (let i = 0; i < filtered_coordinates.length; i++) {
      if (i != 0 && i != filtered_coordinates.length - 1) {
        let gDistance = getDistance(
          {
            latitude: filtered_coordinates[i - 1].latitude,
            longitude: filtered_coordinates[i - 1].longitude
          },
          {
            latitude: filtered_coordinates[i].latitude,
            longitude: filtered_coordinates[i].longitude
          }
        );
        if (!gDistance) {
          gDistance = 0;
        }
        total = total + gDistance;
      }
    }

    total = convertDistance(total, "mi");

    if (!previous_total) {
      previous_total = 0;
    }
    total = parseFloat(total) + parseFloat(previous_total);

    return parseFloat(total).toFixed(2);
  } else {
    return "0.00";
  }
};

export const loadRoutes = data => {
  return {
    type: MAP_ROUTES_SUCCESS,
    payload: data
  };
};

export const clearRoutes = () => {
  return {
    type: CLEAR_ROUTES
  };
};

export const acknowledgeEndRoute = () => {
  return {
    type: ACKNOWLEDGE_END_ROUTE
  };
};

export const updateRouteTimer = () => {
  return {
    type: UPDATE_ROUTE_TIMER
  };
};
export const routePropertiesToggleTab = toggle => {
  return {
    type: ROUTE_PROPERTIES_TOGGLE_TAB,
    payload: toggle
  };
};
export const toggleDrivingMenu = toggle => {
  return {
    type: TOGGLE_DRIVING_MENU,
    payload: toggle
  };
};
export const getRoutes = ({
  token,
  type,
  begin = 0,
  route_id,
  filters,
  filter_user,
  sort_by,
  start_date,
  end_date,
  search,
  onError = null,
  onSuccess = null,
  onLoading = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      switch (type) {
        case "refresh":
          dispatch({ type: REFRESH_ROUTES });
          break;

        case "load_more":
          dispatch({ type: LOAD_MORE_ROUTES });
          break;

        default:
        case "load":
          dispatch({ type: GET_ROUTES });
          break;
      }
    }

    apiv2
      .getTrackedRoutes({
        token,
        begin,
        route_id,
        filters,
        filter_user,
        sort_by,
        start_date,
        end_date,
        search
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          } else {
            getRoutesFail(dispatch, response.problem);
          }
        } else if (response.data?.error != false) {
          if (onError) {
            onError();
          } else {
            getRoutesFail(dispatch, response.data?.error);
            if (response.data?.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data?.results);
          } else {
            getRoutesSuccess(
              dispatch,
              response.data?.results?.routes,
              type,
              response.data?.results?.route_totals
            );
          }
        }
      });
  };
};

const getRoutesFail = (dispatch, error) => {
  dispatch({ type: GET_ROUTES_FAIL, payload: error });
};

const getRoutesSuccess = (dispatch, routes, type, route_totals) => {
  if (type == "load_more") {
    dispatch({
      type: LOAD_MORE_ROUTES_SUCCESS,
      payload: { routes, route_totals }
    });
  } else {
    dispatch({ type: GET_ROUTES_SUCCESS, payload: { routes, route_totals } });
  }
};

export const toggleShowRoutes = toggle => {
  return {
    type: TOGGLE_SHOW_ROUTES,
    payload: toggle
  };
};
export const toggleDrivingImages = toggle => {
  return {
    type: TOGGLE_DRIVING_IMAGES,
    payload: toggle
  };
};
export const toggleLoading = toggle => {
  return {
    type: IS_LOADING,
    payload: toggle
  };
};
export const toggleVirtualDriveStart = toggle => {
  return {
    type: TOGGLE_VIRTUAL_DRIVE_START,
    payload: toggle
  };
};

export const prepPlannedRoute = () => {
  return {
    type: PREPPED_PLANNED_ROUTE,
    payload: true
  };
};

export const clearPreppedPlan = () => {
  return {
    type: PREPPED_PLANNED_ROUTE,
    payload: false
  };
};

export const setPlannedRoute = planned_route_info => {
  return {
    type: SET_PLANNED_ROUTE,
    payload: planned_route_info
  };
};
export const setRoutePlannedDistance = ({
  route_plan_distance,
  route_plan_distance_left
}) => {
  return {
    type: SET_ROUTE_PLANNED_DISTANCE,
    payload: { route_plan_distance, route_plan_distance_left }
  };
};

export const startPlannedRoute = ({
  token,
  route_id,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    dispatch({ type: START_PLANNED_ROUTE });
    return new Promise((resolve, reject) => {
      apiv2.startPlannedRoute({ token, route_id }).then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          startPlannedRouteFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          startPlannedRouteFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          resolve(response.data.results);

          if (onSuccess) {
            onSuccess();
          }

          startPlannedRouteSuccess({
            dispatch,
            results: response.data.results
          });
        }
      });
    });
  };
};

const startPlannedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: START_PLANNED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const startPlannedRouteSuccess = ({ dispatch, results }) => {
  dispatch({
    type: START_PLANNED_ROUTE_SUCCESS,
    payload: results
  });
};

export const startTrackedRoute = ({
  token,
  track_route_type = "location",
  drive_type = "free_drive",
  coordinates = null,
  start_planned = false,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (onLoading) {
        onLoading();
      } else {
        dispatch({ type: IS_LOADING, payload: true });
      }
      dispatch({
        type: START_TRACKED_ROUTE,
        payload: { track_route_type, coordinates }
      });

      apiv2.startTrackedRoute({ token, drive_type }).then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          startTrackedRouteFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          startTrackedRouteFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          resolve(response.data.results);
          startTrackedRouteSuccess({
            dispatch,
            results: response.data.results,
            start_planned
          });

          if (onSuccess) {
            onSuccess(response.data.results);
          }
        }
      });
    });
  };
};

const startTrackedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: START_TRACKED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const startTrackedRouteSuccess = ({ dispatch, results, start_planned }) => {
  if (!start_planned) {
    dispatch({ type: IS_LOADING, payload: false });
  }

  dispatch({
    type: START_TRACKED_ROUTE_SUCCESS,
    payload: results,
    start_planned: start_planned
  });
};

export const updateCurrentRouteSection = ({
  start_time,
  coordinates,
  current_route
}) => {
  let total_miles = null;
  if (current_route) {
    total_miles = calculateTotalMiles({
      coordinates,
      previous_total: current_route ? current_route.total_miles : 0
    });
    return {
      type: UPDATE_CURRENT_ROUTE_SECTION,
      payload: { total_miles, start_time, coordinates }
    };
  }
};
export const triggerPlannedRoute = ({ list_id, trigger_planned }) => {
  return {
    type: TRIGGER_PLANNED_ROUTE,
    payload: {
      list_id: list_id,
      trigger_planned: trigger_planned
    }
  };
};
export const updateTrackedRoute = ({
  token,
  route_id,
  coordinates,
  current_route
}) => {
  const config = AppConfig();

  function routesThunk(dispatch, getState) {
    dispatch({ type: UPDATE_TRACKED_ROUTE });

    const total_miles = calculateTotalMiles({
      coordinates,
      previous_total: current_route.total_miles
    });
    apiv2
      .updateTrackedRoute({ token, route_id, total_miles, coordinates })
      .then(response => {
        if (response.problem != null) {
          updateTrackedRouteFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          updateTrackedRouteFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            // dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (response.data.results.routes[0]) {
            response.data.results.routes[0].total_miles = total_miles;
          }
          updateTrackedRouteSuccess({
            dispatch,
            results: response.data.results
          });
        }
      })
      .catch(error => {
        if (config.device === "mobile") {
          dispatch(catchRouteServiceUnavailable(updateTrackedRoute));
        } else {
          updateTrackedRouteFail({ dispatch, error: error });
        }
      });
  }
  routesThunk.interceptInOffline = true;
  routesThunk.meta = {
    retry: true,
    name: "updateTrackedRoute",
    args: [
      {
        token,
        route_id,
        coordinates,
        current_route
      }
    ]
  };
  return routesThunk;
};

const updateTrackedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: UPDATE_TRACKED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const updateTrackedRouteSuccess = ({ dispatch, results }) => {
  dispatch({ type: UPDATE_TRACKED_ROUTE_SUCCESS, payload: results });
};

export const updatePlannedRoute = ({
  token,
  route_id,
  coordinates,
  current_route
}) => {
  return dispatch => {
    dispatch({ type: UPDATE_PLANNED_ROUTE });

    const total_miles = calculateTotalMiles({
      coordinates,
      previous_total: current_route.total_miles
    });

    apiv2
      .updatePlannedRoute({ token, route_id, total_miles, coordinates })
      .then(response => {
        if (response.problem != null) {
          updatePlannedRouteFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          updatePlannedRouteFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          updatePlannedRouteSuccess({
            dispatch,
            results: response.data.results
          });
        }
      });
  };
};

const updatePlannedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: UPDATE_PLANNED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const updatePlannedRouteSuccess = ({ dispatch, results }) => {
  dispatch({ type: UPDATE_PLANNED_ROUTE_SUCCESS, payload: results });
};

export const stopTrackedRoute = ({
  token,
  route_id,
  coordinates,
  current_route,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    //dispatch({ type: END_TRACKED_ROUTE });

    const total_miles = calculateTotalMiles({
      coordinates,
      previous_total: current_route.total_miles
    });

    apiv2
      .stopTrackedRoute({ token, route_id, coordinates, total_miles })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          stopTrackedRouteFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          stopTrackedRouteFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }

          stopTrackedRouteSuccess({ dispatch, results: response.data.results });
        }
      });
  };
};

const stopTrackedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: END_TRACKED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const stopTrackedRouteSuccess = ({ dispatch, results }) => {
  dispatch({ type: IS_LOADING, payload: false });

  dispatch({ type: END_TRACKED_ROUTE_SUCCESS, payload: results });
};

export const stopPlannedRoute = ({
  token,
  planned_route_id,
  onLoading = null,
  onSuccess = null,
  onError = null
}) => {
  return dispatch => {
    dispatch({ type: PREPPED_PLANNED_ROUTE, payload: false });
    dispatch({ type: END_PLANNED_ROUTE });

    if (onLoading) {
      onLoading();
    }

    apiv2.stopPlannedRoute({ token, planned_route_id }).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        stopPlannedRouteFail({ dispatch, error: response.problem });
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        stopPlannedRouteFail({ dispatch, error: response.data.error });
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (onSuccess) {
          onSuccess(response.data.results);
        }
        stopPlannedRouteSuccess({ dispatch, results: response.data.results });
      }
    });
  };
};

const stopPlannedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: END_PLANNED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const stopPlannedRouteSuccess = ({ dispatch, results }) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: END_TRACKED_ROUTE_SUCCESS, payload: results });
  dispatch({ type: END_PLANNED_ROUTE_SUCCESS, payload: false });
};

export const removeTrackedRoute = ({
  token,
  route_id,
  route_type = "route_section",
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: REMOVE_TRACKED_ROUTE });

    apiv2.removeTrackedRoute({ token, route_id, route_type }).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        removeTrackedRouteFail({ dispatch, error: response.problem });
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        removeTrackedRouteFail({ dispatch, error: response.data.error });
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({
          type: REMOVE_TRACKED_ROUTE_SUCCESS,
          payload: response.data.results
        });
        if (onSuccess) {
          onSuccess();
        } else {
          removeTrackedRouteSuccess({
            dispatch,
            results: response.data.results
          });
        }
      }
    });
  };
};

const removeTrackedRouteFail = ({ dispatch, error }) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({ type: REMOVE_TRACKED_ROUTE_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const removeTrackedRouteSuccess = ({ dispatch, results }) => {
  dispatch({ type: IS_LOADING, payload: false });

  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "You've successfully deleted this route.",
      title: "Success!"
    }
  });

  appRedirect({
    dispatch,
    redirect: "goBack",
    payload: { remove: "route", route_id: results.route_id }
  });
};
export const initEditRouteFilters = () => {
  return {
    type: INIT_EDIT_ROUTE_FILTERS
  };
};

export const updateRouteFilter = ({ prop, value }) => {
  return {
    type: UPDATE_ROUTE_FILTER,
    payload: { prop, value }
  };
};

export const updateSingleRouteFilter = ({ prop, value }) => {
  return {
    type: UPDATE_SINGLE_ROUTE_FILTER,
    payload: { prop, value }
  };
};

export const editRouteFilter = ({ prop, value }) => {
  return {
    type: EDIT_ROUTE_FILTER,
    payload: { prop, value }
  };
};

export const saveRouteFilters = () => {
  return {
    type: SAVE_ROUTE_FILTERS
  };
};

export const selectActiveRoute = route => {
  return {
    type: SELECT_ACTIVE_ROUTE,
    payload: route
  };
};

export const getRouteProperties = ({
  token,
  type,
  begin = 0,
  limit,
  route_id
}) => {
  return dispatch => {
    switch (type) {
      case "refresh":
        dispatch({ type: REFRESH_ROUTE_PROPERTIES });
        break;

      default:
      case "load":
        dispatch({ type: GET_ROUTE_PROPERTIES });
        break;
    }

    apiv2.getLeads({ token, begin, limit, route_id }).then(response => {
      if (response.problem != null) {
        dispatch({
          type: GET_ROUTE_PROPERTIES_FAIL,
          payload: response.problem
        });
      } else if (response.data.error != false) {
        dispatch({
          type: GET_ROUTE_PROPERTIES_FAIL,
          payload: response.data.error
        });
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({
          type: GET_ROUTE_PROPERTIES_SUCCESS,
          payload: response.data.results
        });
      }
    });
  };
};

export const restoreCurrentRoute = ({
  token,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();
    apiv2.restoreCurrentRoute({ token }).then(response => {
      if (response.problem != null) {
        onError();
      } else if (response.data.error != false) {
        onError();
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        onSuccess(response.data.results);
        if (response.data.results?.restored_route) {
          dispatch({
            type: RESTORE_CURRENT_ROUTE_SUCCESS,
            payload: response.data.results
          });
        }
      }
    });
  };
};
