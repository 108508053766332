import React, { Component } from "react";

const TestimonialImage = ({ testimonial }) => {
  if (testimonial.image) {
    return (
      <div className="dm-website-testimonial-image">
        <img
          src={testimonial.image.fields.file.url}
          alt={testimonial.image.fields.file.title}
        />
      </div>
    );
  }

  return <div />;
};

export default TestimonialImage;
