import Segment from "@redux-beacon/segment";
import { applyMiddleware, createStore } from "redux";
import { createMiddleware } from "redux-beacon";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReduxThunk from "redux-thunk";

import reducers from "app/DealMachineCore/reducers";
import * as actionCreators from "app/NativeActions";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = composeWithDevTools({
  actionCreators,
  trace: true,
  traceLimit: 10
});

export const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(ReduxThunk))
);
export const persistor = persistStore(store);

export default { store, persistor };
