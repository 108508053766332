import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class AddEmailAddress extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      email_address: "",
      email_label: "",
      loading: false,
      panel_data
    };

    this._email_address_input = React.createRef();
    this._email_address_label = React.createRef();

    this.addEmailAddress = this.addEmailAddress.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }
  checkIfNeedsToSave() {
    if (!!this.state.email_address) {
      return true;
    }

    return false;
  }

  addEmailAddress() {
    const {
      panel_data: { property }
    } = this.state;

    const { email_label, email_address } = this.state;
    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "create_email_address",
      email_label,
      email_address,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      panel_data: { property }
    } = this.state;
    const { email_address, email_label, loading } = this.state;
    let formatted_property_address = "";
    if (property) {
      const display_property = determineDisplayProperty(property);
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    }
    return (
      <Container>
        <NewHeader
          title="Add Email Address"
          subtitle={"Add email address for " + formatted_property_address.line1}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._email_address_label}
            name="email_address_label"
            autoFocus={true}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            placeholder="Label"
            disabled={loading}
            onChange={value => {
              this.setState({
                email_label: value
              });
            }}
            value={email_label}
            type="text"
            onSubmitEditing={() => {
              if (this._email_address_input?.current) {
                this._email_address_input.current.focus();
              }
            }}
          />
          <InputBox
            input_ref={this._email_address_input}
            name="email_address"
            disabled={loading}
            returnKeyType="done"
            placeholder="Email Address"
            autoCapitalize="none"
            keyboardType="email-address"
            onChange={value =>
              this.setState({
                email_address: value
              })
            }
            onSubmitEditing={this.addEmailAddress}
            blurOnSubmit={true}
            value={email_address}
            type="text"
          />

          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.addEmailAddress}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Add Email Address
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(AddEmailAddress);
