import React, { Component } from "react";

import {
  Bold,
  Copy,
  ExternalImage,
  ProfilePic,
  Row,
  Wrapper,
  Title,
  Icon
} from "app/NativeComponents/common";
import {
  IconButton,
  Notification,
  SelectItem
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { renderDate, openUrl, copyToClipBoard } from "app/NativeActions";

class NewsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countdown: null,
      event_date: null,
      display: false
    };

    if (this.updateCountdown) {
      this.updateCountdown = this.updateCountdown.bind(this);
    }
  }

  componentDidMount() {
    const { item } = this.props;

    let event_date = null;
    if (!!item.event_datetime) {
      event_date = new Date(item.event_datetime);

      //create a living countdown that updates every second and displays the difference between the current time and the event time
      this.updateCountdown(event_date);

      let countdown = setInterval(() => {
        this.updateCountdown(event_date);
      }, 1000);
    } else {
      this.setState({
        display: true
      });
    }
  }

  updateCountdown(event_date) {
    //if the event date is in the past, clear the countdown
    if (event_date < new Date()) {
      clearInterval(this.state.countdown);
      this.setState({
        countdown: "Live Now!",
        //if the event is over after an hour, display the event is over
        display: event_date < new Date() - 3600000 ? false : true
      });
    } else {
      let now = new Date();
      let distance = event_date - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.setState({
        countdown:
          days > 0
            ? days +
              " days " +
              hours +
              " hrs " +
              minutes +
              " mins " +
              seconds +
              " secs "
            : hours + " hrs " + minutes + " mins " + seconds + " secs ",
        display: true
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.countdown);
  }

  render() {
    const { item } = this.props;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;

    if (!this.state.display) {
      return null;
    }

    return (
      <>
        <>
          <SelectItem
            select_type={"none"}
            selected={true}
            style={{
              borderLeftWidth: 5,
              borderLeftColor:
                !this.props.seen_news_slugs?.includes(item?.slug_id) &&
                !this.props.loading_seen_slugs
                  ? item?.featured
                    ? colors.active_color_muted
                    : colors.orange_color_muted
                  : "transparent",
              borderLeftStyle: "solid"
            }}
            selected_background_color={
              item?.featured ? colors.active_color_muted : null
            }
            onHover={() => this.props.seenNewsSlug(item?.slug_id)}
            noPress={!item?.url}
            onPress={() => {
              if (item?.url) {
                openUrl(item.url);
              }
            }}
            renderTop={() => {
              return (
                <>
                  {!!item?.image?.fields?.file.url ? (
                    <Wrapper style={{ borderRadius: 5 }}>
                      <ExternalImage
                        style={{
                          height: 200,
                          flex: 1,
                          borderRadius: 5,
                          backgroundColor: colors?.gray_color
                        }}
                        image={item?.image?.fields?.file.url}
                      />
                    </Wrapper>
                  ) : null}
                </>
              );
            }}
            icon={"keyboard-arrow-right"}
            renderContent={() => {
              return (
                <Wrapper style={{ flex: 1 }}>
                  <Row>
                    <Wrapper style={{ flex: 1, marginBottom: 15 }}>
                      <Row style={{ flex: 1, marginBottom: 5 }}>
                        <Title style={{}}>
                          <Bold>{item.title}</Bold>
                        </Title>
                      </Row>

                      <Copy>{item.description}</Copy>

                      {!!this.state.countdown ? (
                        <Copy
                          style={{ color: colors.active_color, marginTop: 5 }}
                        >
                          <Bold>{this.state.countdown}</Bold>
                        </Copy>
                      ) : null}
                    </Wrapper>
                  </Row>

                  <Row style={{}}>
                    <Wrapper style={{ flex: 1 }}>
                      {!!item?.author_name ? (
                        <Row>
                          <ProfilePic
                            image={item?.author_image?.fields?.file?.url}
                            size={30}
                            style={{ marginRight: 5 }}
                          />
                          <Wrapper>
                            <Copy>{item?.author_name}</Copy>
                            <Copy
                              style={{
                                fontSize: 10,
                                color: colors.light_text_color
                              }}
                            >
                              {renderDate(item.date_created)}
                            </Copy>
                          </Wrapper>

                          {!!item?.url ? (
                            <IconButton
                              icon={"share"}
                              button_type={"small"}
                              tooltip={"Share"}
                              tooltipPlacement={"top"}
                              onPress={() => {
                                copyToClipBoard(item.url);
                                this.props.showSuccess(
                                  item.url + " was copied to your clipboard",
                                  "Copied"
                                );
                              }}
                            />
                          ) : null}
                        </Row>
                      ) : (
                        <Row>
                          <Copy
                            style={{
                              fontSize: 10,
                              color: colors.light_text_color
                            }}
                          >
                            {renderDate(item.date_created)}
                          </Copy>
                          {!!item?.url ? (
                            <IconButton
                              icon={"share"}
                              button_type={"small"}
                              tooltip={"Share"}
                              tooltipPlacement={"top"}
                              onPress={() => {}}
                            />
                          ) : null}
                        </Row>
                      )}
                    </Wrapper>
                  </Row>
                </Wrapper>
              );
            }}
          ></SelectItem>

          {item?.content_type === "podcast" &&
          !!item?.embeded_url &&
          device === "desktop" ? (
            <Wrapper style={{ marginTop: 0, margin: 5, marginBottom: 0 }}>
              <iframe
                src={item?.embeded_url}
                width="100%"
                height="152"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </Wrapper>
          ) : null}
        </>
      </>
    );
  }
}

export default NewsItem;
