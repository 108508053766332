import React from "react";

const Row = ({ children, style, mobile = false, className = "" }) => {
  return (
    <div
      className={
        mobile ? "deal-mobile-row " + className : "deal-row " + className
      }
      style={style}
    >
      {children}
    </div>
  );
};

export { Row };
