import React, { Component } from "react";
import { Wrapper, Card } from "app/NativeComponents/common";
import { ColorPicker } from "app/NativeComponents/snippets";
import { store } from "app/store";

class SecondaryColor extends Component {
  render() {
    const device = store.getState().native.device;

    if (this.props.template.html_template.default_secondary_color.hex != null) {
      return (
        <Card styl>
          <ColorPicker
            {...this.props}
            ref_name={"secondary_color"}
            placeholder={
              this.props.template.html_template.default_secondary_color.title
            }
            onChange={value => {
              if (device == "desktop" && value.hex) {
                this.props.updateTemplate({
                  prop: "secondary_color",
                  value: value.hex
                });
              } else {
                this.props.updateTemplate({
                  prop: "secondary_color",
                  value
                });
              }
            }}
            value={
              this.props.template.secondary_color != null
                ? this.props.template.secondary_color
                : this.props.template.html_template.default_secondary_color.hex
            }
            onColorSelect={() => {}}
          />
        </Card>
      );
    }

    return <Wrapper />;
  }
}

export default SecondaryColor;
