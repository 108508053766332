import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Button,
  Icon
} from "app/NativeComponents/common";
import { Select, SelectBox, CloseButton } from "app/NativeComponents/snippets";

import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import moment from "moment";

class Step extends Component {
  constructor(props) {
    super(props);

    let send_after_options = [];
    for (let i = 1; i <= 180; i++) {
      send_after_options.push({
        label: i == 1 ? "1 day" : i + " days",
        value: i
      });
    }

    let resend_freq_options = [];
    resend_freq_options.push({
      label: "Off",
      value: 0
    });
    for (let i = 1; i <= 180; i++) {
      resend_freq_options.push({
        label: i == 1 ? "1 day" : i + " days",
        value: i
      });
    }
    let resend_limit_options = [];
    for (let i = 1; i <= 24; i++) {
      resend_limit_options.push({
        label: i == 1 ? "1 mail piece" : i + " mail pieces",
        value: i
      });
    }

    this.state = {
      send_after_options,
      resend_freq_options,
      resend_limit_options,
      send_after_focus: false,
      resend_freq_focus: false,
      resend_limit_focus: false,
      template_focus: false
    };

    this._send_after = React.createRef();
    this._resend_freq = React.createRef();
    this._resend_limit = React.createRef();
    this._template = React.createRef();
  }

  getLabel(value, items) {
    for (let i = 0; i < items.length; i++) {
      if (value == items[i].value) {
        return items[i].label;
      }
    }

    return "Off";
  }

  render() {
    const { colors, editable, campaign_step } = this.props;
    const { send_after_options, resend_freq_options, resend_limit_options } =
      this.state;

    return (
      <Wrapper>
        <Row>
          <Wrapper
            style={{
              backgroundColor: colors.background_color,
              margin: 5,
              padding: 10,
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center",
              width: 60
            }}
          >
            <Copy style={{ textAlign: "center", fontSize: 12 }}>Step</Copy>
            <Copy style={{ textAlign: "center" }}>
              <Bold>{campaign_step.step_order}</Bold>
            </Copy>
          </Wrapper>
          <Wrapper
            style={{ flex: 1, margin: 5, marginLeft: 0, position: "relative" }}
          >
            {campaign_step.step_order > 2 ? (
              <CloseButton
                style={{ zIndex: 100 }}
                onPress={() => {
                  let campaign_steps =
                    this.props.mailer_campaign?.campaign_steps.filter(
                      ({ step_order }) =>
                        step_order !== campaign_step.step_order
                    );

                  for (let i = 0; i < campaign_steps.length; i++) {
                    campaign_steps[i].step_order = i + 1;
                  }
                  this.props.editMailerCampaign({
                    prop: "campaign_steps",
                    value: campaign_steps
                  });
                }}
              />
            ) : null}
            <Wrapper
              style={{
                margin: 5,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <MailerFlipCard
                mailer={campaign_step.mailer}
                scale={0.25}
                back_scale={0.25}
              />
            </Wrapper>

            <SelectBox
              select_ref={this._template}
              items={this.props.mailer_templates}
              disabled={editable ? false : true}
              value={campaign_step.template_id || "--"}
              placeholder="Selected design:"
              show={this.state.template_focus}
              onFocus={() => {
                this.setState({ template_focus: true });
              }}
              onBlur={() => {
                this.setState({ template_focus: false });
              }}
              onSelect={item => {
                let mailer = null;

                for (let i = 0; i < this.props.mailer_templates.length; i++) {
                  if (this.props.mailer_templates[i].id == item) {
                    mailer = this.props.mailer_templates[i];
                  }
                }
                if (mailer) {
                  this.props.updateCampaignStep({
                    campaign_step,
                    prop: "mailer",
                    value: mailer
                  });
                }
              }}
              onSubmitEditing={() => {}}
            />

            {campaign_step.step_order > 1 ? (
              <SelectBox
                select_ref={this._send_after}
                items={this.state.send_after_options}
                disabled={editable ? false : true}
                value={campaign_step.send_after || "Off"}
                placeholder="Send after:"
                show={this.state.send_after_focus}
                onFocus={() => {
                  this.setState({ send_after_focus: true });
                }}
                onBlur={() => {
                  this.setState({ send_after_focus: false });
                }}
                onSelect={item => {
                  this.props.updateCampaignStep({
                    campaign_step,
                    prop: "send_after",
                    value: item
                  });
                }}
                onSubmitEditing={() => {}}
              />
            ) : null}
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <SelectBox
                  select_ref={this._resend_freq}
                  items={this.state.resend_freq_options}
                  disabled={editable ? false : true}
                  value={campaign_step.resend_freq || "Off"}
                  placeholder="Repeat step every:"
                  show={this.state.resend_freq_focus}
                  onFocus={() => {
                    this.setState({ resend_freq_focus: true });
                  }}
                  onBlur={() => {
                    this.setState({ resend_freq_focus: false });
                  }}
                  onSelect={item => {
                    this.props.updateCampaignStep({
                      campaign_step,
                      prop: "resend_freq",
                      value: item
                    });
                  }}
                  onSubmitEditing={() => {}}
                />
              </Wrapper>
              {this.props.repeat_mail_sequence != 1 ||
              campaign_step.step_order < this.props.number_of_steps ? (
                <Wrapper style={{ flex: 1 }}>
                  <SelectBox
                    select_ref={this._resend_limit}
                    items={this.state.resend_limit_options}
                    disabled={editable ? false : true}
                    value={campaign_step.resend_limit || "--"}
                    placeholder="Total mail to send:"
                    show={this.state.resend_limit_focus}
                    onFocus={() => {
                      this.setState({ resend_limit_focus: true });
                    }}
                    onBlur={() => {
                      this.setState({ resend_limit_focus: false });
                    }}
                    onSelect={item => {
                      this.props.updateCampaignStep({
                        campaign_step,
                        prop: "resend_limit",
                        value: item
                      });
                    }}
                    onSubmitEditing={() => {}}
                  />
                </Wrapper>
              ) : null}
            </Row>
          </Wrapper>
        </Row>
      </Wrapper>
    );
  }
}

export default Step;
