import React, { PureComponent } from "react";
import LockButton from "./LockButton";
import FeatureUnlockButton from "./FeatureUnlockButton";

import { store } from "app/store";

class UpsellButton extends PureComponent {
  render() {
    return <FeatureUnlockButton {...this.props} />;
  }
}

export { UpsellButton };
