import React from "react";
import { store } from "app/store";

const Icon = ({
  style,
  icon,
  fa_icon,
  fa5_icon,
  color,
  size,
  className = ""
}) => {
  const colors = store.getState().settings.colors;

  if (!!icon) {
    return (
      <i
        className={`material-icons ${className}`}
        style={{
          ...style,
          color: color || colors.text_color,
          fontSize: size || 20
        }}
      >
        {icon.replace(/-/g, "_")}
      </i>
    );
  } else if (!!fa_icon) {
    return (
      <i
        className={`fa fa-${fa_icon} ${className}`}
        style={{
          ...style,
          color: color || colors.text_color,
          fontSize: size || 20
        }}
      ></i>
    );
  } else if (!!fa5_icon) {
    return (
      <i
        className={`fas fa-${fa5_icon} ${className}`}
        style={{
          ...style,
          color: color || colors.text_color,
          fontSize: size || 20
        }}
      />
    );
  }

  return <span />;
};

export { Icon };
