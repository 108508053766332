import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Button, Icon, Copy, Bold } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

import { store } from "app/store";
import { triggerHapticFeedback } from "app/NativeActions";

class HeaderButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false
    };
  }

  renderInner() {
    const { buttonContents = null } = this.props;
    const colors = store.getState().settings.colors;

    return (
      <>
        {!!buttonContents.icon || !!buttonContents.fa_icon ? (
          <Icon
            icon={!!buttonContents.icon ? buttonContents.icon : ""}
            fa_icon={!!buttonContents.fa_icon ? buttonContents.fa_icon : ""}
            color={buttonContents.color || colors.white_text_color}
            size={22}
          />
        ) : null}
      </>
    );
  }
  render() {
    const { buttonContents = null, style } = this.props;
    const { hovering } = this.state;

    const colors = store.getState().settings.colors;
    if (buttonContents) {
      if (buttonContents.noPress) {
        return (
          <Wrapper
            tooltip={buttonContents.tooltip}
            tooltipPlacement={buttonContents.tooltipPlacement}
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              margin: 5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                hovering || buttonContents.hovering || buttonContents.pressedIn
                  ? buttonContents.hover_color
                    ? buttonContents.hover_color
                    : colors.hover_white_color
                  : "transparent",
              ...style
            }}
          >
            {this.renderInner()}
          </Wrapper>
        );
      }

      return (
        <Button
          tooltip={buttonContents.tooltip}
          tooltipPlacement={buttonContents.tooltipPlacement}
          style={{
            width: 50,
            height: 50,
            borderRadius: 25,
            margin: 5,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              hovering || buttonContents.hovering || buttonContents.pressedIn
                ? buttonContents.hover_color
                  ? buttonContents.hover_color
                  : colors.hover_white_color
                : "transparent",
            ...style
          }}
          onPress={
            buttonContents.onPress
              ? () => {
                  triggerHapticFeedback({ type: "impactLight" });
                  buttonContents.onPress();
                }
              : () => {}
          }
          onHover={() => {
            this.setState({ hovering: true });
          }}
          onHoverOut={() => {
            this.setState({ hovering: false });
          }}
        >
          {this.renderInner()}
        </Button>
      );
    }

    return null;
  }
}

export default HeaderButton;
