export const GET_MAP_PROPERTIES = "get_map_properties";
export const REFRESH_MAP_PROPERTIES = "refresh_map_properties";
export const GET_MAP_PROPERTIES_SUCCESS = "get_map_properties_success";
export const GET_MAP_PROPERTIES_FAIL = "get_map_properties_fail";

export const GET_NEXT_PLANNED_PROPERTY = "get_next_planned_property";
export const GET_NEXT_PLANNED_PROPERTY_SUCCESS =
  "get_next_planned_property_success";
export const GET_NEXT_PLANNED_PROPERTY_FAIL = "get_next_planned_property_fail";
export const COMPLETE_PLANNED_PROPERTY = "complete_planned_property";
export const COMPLETE_PLANNED_PROPERTY_SUCCESS =
  "complete_planned_property_success";
export const COMPLETE_PLANNED_PROPERTY_FAIL = "complete_planned_property_fail";
export const CLEAR_NEXT_PLANNED_PROPERTY = "clear_next_planned_property";
export const SET_COMPLETED_ROUTE = "set_completed_route";
export const UPDATE_MAP_LOCATION = "update_map_location";
export const SELECT_ACTIVE_PROPERTY = "select_active_property";
export const SELECT_MULTIPLE_PROPERTIES = "select_multiple_properties";
export const SELECT_ACTIVE_PROPERTY_SUCCESS = "select_active_property_success";
export const SELECT_ACTIVE_PROPERTY_NO_SIDE_EFFECTS =
  "SELECT_ACTIVE_PROPERTY_NO_SIDE_EFFECTS";
export const SELECT_ACTIVE_PROPERTY_FAIL = "select_active_property_fail";
export const TOGGLE_ACTIVE_PROPERTY = "toggle_active_property";

export const GET_MAP_REVERSE_GEOCODE = "get_map_reverse_geocode";
export const GET_MAP_REVERSE_GEOCODE_FAIL = "get_map_reverse_geocode_fail";
export const GET_MAP_REVERSE_GEOCODE_SUCCESS =
  "get_map_reverse_geocode_success";

export const GET_AUTOCOMPLETE = "get_autocomplete";
export const GET_AUTOCOMPLETE_FAIL = "get_autocomplete_fail";
export const GET_AUTOCOMPLETE_SUCCESS = "get_autocomplete_success";
export const CLEAR_AUTOCOMPLETE = "clear_autocomplete";

export const ADD_DEAL = "add_deal";
export const ADD_DEAL_FAIL = "add_deal_fail";
export const ADD_DEAL_SUCCESS = "add_deal_success";

export const TOGGLE_TAP_TO_ADD = "toggle_tap_to_add";
export const SWITCH_MAP_MODE = "switch_map_mode";

export const SET_LAST_LOCATION = "set_last_location";
export const SET_USER_LOCATION = "set_user_location";
export const SET_PERMISSION = "SET_PERMISSION";
export const SET_ALL_PERMISSIONS = "SET_ALL_PERMISSIONS";

export const FOCUS_SEARCH_BAR = "focus_search_bar";
export const HIDE_PROPERTY_LIST = "hide_property_list";

export const SET_APPLIED_HIGHLIGHT = "set_applied_highlight";
export const SET_STREET_VIEW_COORDS = "set_street_view_coords";
export const SET_STREET_VIEW_LINE = "set_street_view_line";
export const TOGGLE_MAP_MOVING = "toggle_map_moving";
export const UPDATE_MAIN_MAP_LOCATION = "update_main_map_location";
export const SELECT_ACTIVE_COORDINATES = "select_active_coordinates";

export const TRIGGER_NEXT_PLANNED_PROPERTY = "trigger_next_planned_property";

export const UPDATE_MAP_FILTERS = "update_map_filters";
export const UPDATE_LEAD_FILTERS = "update_lead_filters";
