import React from "react";

const Split = ({ children, style }) => {
  return (
    <div className="deal-split" style={style}>
      {children}
    </div>
  );
};

export { Split };
