import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Scroll,
  Form,
  Copy,
  Spin,
  KeyboardView,
  Button,
  Icon,
  Bold,
  Row,
  ProfilePic
} from "app/NativeComponents/common";
import {
  NewHeader,
  SearchBar,
  List,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import { getTeamMembers, popSidePanel } from "app/NativeActions";

class LeadOwnerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      search: "",
      selected_lead_owner: props.lead_owner_modal
        ? props.lead_owner_modal.selected_lead_owner
        : 0,
      selected_leads: props.lead_owner_modal
        ? props.lead_owner_modal.selected_leads
        : []
    };
  }

  componentDidMount() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "replace",
      type: "accepted_members",
      begin: 0
    });
  }

  checkItem(item) {
    this.setState({
      selected_lead_owner: item.id
    });
  }

  confirmUpdate() {
    if (this.props.lead_owner_modal.modalAction && !this.state.loading) {
      this.props.lead_owner_modal.modalAction({
        selected_lead_owner:
          this.state.selected_lead_owner == "unassigned"
            ? 0
            : this.state.selected_lead_owner,
        selected_leads: this.state.selected_leads,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });
          this.props.popSidePanel();
        }
      });
    }
  }

  searchTeam(load_type = "load", search = "") {
    if (!this.props.team_members_loading) {
      this.props.getTeamMembers({
        token: this.props.token,
        load_type: "replace",
        type: "accepted_members",
        begin: 0
      });
    }
  }

  renderRightButtonText() {
    switch (this.props.lead_owner_modal.type) {
      default:
        if (this.state.selected_leads.length > 1) {
          return "Update Leads";
        } else {
          return "Update Lead";
        }
    }
  }

  handleBack() {
    if (this.props.lead_owner_modal) {
      if (this.props.lead_owner_modal.cancelAction) {
        this.props.lead_owner_modal.cancelAction();
      }
    }
    this.props.popSidePanel();
  }

  render() {
    const { colors, team_members, team_members_loading } = this.props;
    const { selected_lead_owner, loading, search } = this.state;
    return (
      <>
        <NewHeader
          title={this.props.lead_owner_modal.title}
          subtitle={this.props.lead_owner_modal.description}
          leftButton={{
            icon: this.props.device === "desktop" ? "close" : "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <Wrapper>
          <SearchBar
            title="Search Team"
            value={search}
            onChange={value => {
              if (!loading) {
                this.setState({
                  search: value,
                  selected_lead_owner: 0
                });
              }
            }}
            style={{ margin: 5 }}
          />
        </Wrapper>
        <KeyboardView>
          {team_members_loading ? (
            <SpinWrapper text="Loading Team..." />
          ) : (
            <List
              style={{ alignSelf: "stretch" }}
              items={team_members}
              listHeader={() => {
                return (
                  <>
                    {!search ? (
                      <SelectItem
                        select_type="profile_pic"
                        user={"blank"}
                        selected={selected_lead_owner == "unassigned"}
                        onPress={() => {
                          this.checkItem({
                            id: "unassigned"
                          });
                        }}
                        require_confirm={true}
                        confirm_loading={loading}
                        onConfirm={() => {
                          this.confirmUpdate();
                        }}
                      >
                        Unassign
                      </SelectItem>
                    ) : null}
                  </>
                );
              }}
              itemStructure={({ item, index }) => {
                return (
                  <SelectItem
                    key={"option_" + index}
                    select_type="profile_pic"
                    user={item}
                    selected={selected_lead_owner == item.id}
                    onPress={() => {
                      this.checkItem(item);
                    }}
                    confirm_loading={loading}
                    require_confirm={true}
                    onConfirm={() => {
                      this.confirmUpdate();
                    }}
                  >
                    {item.name ? item.name : item.email}
                  </SelectItem>
                );
              }}
            />
          )}
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, modal, filter, team }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { is_loading } = modal;
  const { lead_owner_modal, show_lead_owner_modal } = filter;

  const { team_members, team_members_loading } = team;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    is_loading,
    team_members,
    team_members_loading,
    lead_owner_modal,
    show_lead_owner_modal
  };
};

export default connect(mapStateToProps, {
  getTeamMembers,
  popSidePanel
})(LeadOwnerModal);
