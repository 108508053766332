import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ReactHtmlParser from "react-html-parser";
const Wrapper = ({
  id = null,
  tooltip = null,
  tooltipPlacement = "bottom",
  wrapper_ref = null,
  children,
  style,
  className = "",
  pointerEvents = "auto",
  html = false,
  onHover = () => {},
  onHoverOut = () => {},
  onLayout = () => {},
  onClick = null,
  ...props
}) => {
  if (!!tooltip) {
    return (
      <Tooltip
        disableFocusListener
        title={tooltip}
        placement={tooltipPlacement}
      >
        <div
          id={id}
          ref={wrapper_ref}
          className={"deal-wrapper " + className}
          style={style}
          pointerEvents={pointerEvents}
          onMouseEnter={() => {
            onHover();
          }}
          onMouseLeave={() => {
            onHoverOut();
          }}
          onClick={onClick}
        >
          {html ? ReactHtmlParser(children) : children}
        </div>
      </Tooltip>
    );
  }
  return (
    <div
      id={id}
      ref={wrapper_ref}
      className={"deal-wrapper " + className}
      style={style}
      pointerEvents={pointerEvents}
      onMouseEnter={() => {
        onHover();
      }}
      onMouseLeave={() => {
        onHoverOut();
      }}
      onClick={onClick}
      {...props}
    >
      {html ? ReactHtmlParser(children) : children}
    </div>
  );
};

export { Wrapper };
