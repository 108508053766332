import React, { Component } from "react";

import {
  Card,
  Map,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import Scene1 from "./Scene1";
import Scene2 from "./Scene2";
import Scene3 from "./Scene3";

class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 0
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);
    if (this.state.scene == 0) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 1 });
        this.startScene();
      }, 100);
    } else if (this.state.scene == 1) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 2 });
        this.startScene();
      }, 2000);
    } else if (this.state.scene == 2) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 3 });
        this.startScene();
      }, 5000);
    } else if (this.state.scene == 3) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 4 });
        this.startScene();
      }, 750);
    } else if (this.state.scene == 4) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 0 });
        this.startScene();
      }, 3000);
    }
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 200,
          textAlign: "left"
        }}
      >
        <div className="animated fadeIn">
          <Scene3 scene={this.state.scene} />
          <Scene1 scene={this.state.scene} />
          <Scene2 scene={this.state.scene} />
        </div>
      </div>
    );
  }
}

export default Notes;
