import React, { Component } from "react";
import { Wrapper, Row } from "app/NativeComponents/common";

import TabButton from "./TabButton";
import PillTabButton from "./PillTabButton";
import { store } from "app/store";
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_tab: props.selected_tab
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selected_tab &&
      this.props.selected_tab !== prevProps.selected_tab &&
      this.props.selected_tab !== this.state.selected_tab
    ) {
      this.setState({
        selected_tab: this.state.selected_tab
      });
    }
  }

  renderTabs() {
    const {
      tabs = [],
      onTabSelect = () => {},
      tabStyle = "tabs",
      renderLeftSide = null,
      renderRightSide = null,
      selected_tab
    } = this.props;
    const colors = store.getState().settings.colors;
    const isMobile = store.getState().native.isMobile;
    return tabStyle == "pill" ? (
      <Row
        style={{
          justifyContent: isMobile ? "flex-start" : "center",
          flexDirection: isMobile ? "column" : "row"
        }}
      >
        {renderLeftSide ? renderLeftSide() : null}
        <Wrapper style={{ justifyContent: "center", alignItems: "center" }}>
          <Row
            style={{
              justifyContent: "center",
              borderRadius: 30,
              backgroundColor: colors.background_color,
              height: 40,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.border_color,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            {tabs &&
              tabs.map((tab, i) => {
                return (
                  <PillTabButton
                    key={"tab_" + i}
                    title={tab.title}
                    mobile_title={tab.mobile_title}
                    icon={tab.icon}
                    notification={tab.notification}
                    notification_type={tab.notification_type}
                    active={selected_tab == tab.slug}
                    onPress={() => {
                      this.setState({
                        selected_tab: tab.slug
                      });
                      onTabSelect(tab.slug);
                    }}
                    colors={colors}
                    isMobile={isMobile}
                  />
                );
              })}
          </Row>
        </Wrapper>
        {renderRightSide ? renderRightSide() : null}
      </Row>
    ) : (
      <Row
        style={{
          borderBottomColor: colors.actionable_text_color,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          paddingLeft: isMobile ? 0 : 20,
          alignItems: "flex-end"
        }}
      >
        {tabs &&
          tabs.map((tab, i) => {
            return (
              <TabButton
                key={"tab_" + i}
                title={tab.title}
                mobile_title={tab.mobile_title}
                icon={tab.icon}
                notification={tab.notification}
                notification_type={tab.notification_type}
                active={selected_tab == tab.slug}
                onPress={() => {
                  this.setState({
                    selected_tab: tab.slug
                  });
                  onTabSelect(tab.slug);
                }}
                colors={colors}
                isMobile={isMobile}
              />
            );
          })}
      </Row>
    );
  }

  render() {
    const { tabs = [], style, componentStyle, hideTabs = false } = this.props;
    const { selected_tab } = this.state;

    return (
      <Wrapper style={{ backgroundColor: "transparent", flex: 1, ...style }}>
        {!hideTabs && this.renderTabs()}
        <Wrapper
          style={{
            paddingTop: hideTabs ? 0 : 10,
            flex: 1,
            ...componentStyle
          }}
        >
          {tabs &&
            tabs.map((tab, i) => {
              return tab.renderComponent && tab.slug == selected_tab
                ? tab.renderComponent()
                : null;
            })}
        </Wrapper>
      </Wrapper>
    );
  }
}

export { Tabs };
