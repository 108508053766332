import React, { Component } from "react";
import { connect } from "react-redux";

import { SpinWrapper } from "app/NativeComponents/snippets";
import Photos from "app/DealMachineCore/reuseable/LeadPhotoManager/Photos";
import PhotoGalleryImage from "app/DealMachineCore/reuseable/LeadPhotoManager/PhotoGalleryImage";

import {
  checkIfHasGoogleStreetPic,
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata,
  getMLSImages
} from "app/NativeActions";

class PropertyPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedGoogleStreetPic: false,
      gotStreetPicInfo: false,
      mls_images: [],
      mls_loading: false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.property?.property_id !== this.props?.property?.property_id) {
      this.getData();
    }
  }

  getData() {
    this.props.getMLSImages({
      token: this.props.token,
      property_id: this.props.property?.property_id,
      onLoading: () => {
        this.setState({ mls_loading: true });
      },
      onError: () => {
        this.setState({ mls_loading: false });
      },
      onSuccess: results => {
        this.setState({ mls_loading: false });

        if (results?.mls_images?.length > 0) {
          this.setState({
            mls_images: results?.mls_images
          });
        }
      }
    });
    checkIfHasGoogleStreetPic({
      address: this.props.property?.property_address_full,
      onSuccess: results => {
        this.setState({
          failedGoogleStreetPic: results.status == "OK" ? false : true,
          gotStreetPicInfo: true
        });
      }
    });
  }
  renderStreetViewPic() {
    const {
      property,
      property: { deal },
      colors,
      billing_addons
    } = this.props;

    /*
    if (
      (checkIfUserHasBillingAddon({
        billing_addons: billing_addons,
        slug: "street_view"
      }) &&
        this.props.user?.user_version < 6) ||
      (this.props.user?.user_version >= 6 &&
        checkIfUserHasMetadata("street_pic"))
    ) {
    */
    let image = null;

    if (
      deal?.failed_street_pic ||
      (this.state.failedGoogleStreetPic && this.state.gotStreetPicInfo)
    ) {
      return null;
    }

    if (this.props.images) {
      for (let i = 0; i < this.props.images?.length; i++) {
        if (this.props.images[i].image_type == "street_view") {
          image = this.props.images[i];
        }
      }
    }

    if (image) {
      return (
        <PhotoGalleryImage
          {...this.props}
          additional_image={{
            ...image,
            image_type: "street_view"
          }}
          includes_primary_button={false}
          photo_height={250}
        />
      );
    }

    return (
      <PhotoGalleryImage
        {...this.props}
        additional_image={{
          image: property?.streetview_image,
          image_type: "street_view"
        }}
        includes_primary_button={false}
        photo_height={250}
      />
    );
    //}

    //return null;
  }

  renderPropertyPic(image_type) {
    const {
      property,
      property: { deal },
      colors,
      images = []
    } = this.props;
    let image = null;
    if (images) {
      for (let i = 0; i < images?.length; i++) {
        if (images[i].image_type == image_type) {
          image = images[i];
        }
      }
    }

    if (image) {
      return (
        <PhotoGalleryImage
          {...this.props}
          additional_image={image}
          includes_primary_button={false}
          photo_height={250}
        />
      );
    } else if (image_type == "satellite") {
      return (
        <PhotoGalleryImage
          {...this.props}
          additional_image={{
            image: property.satellite_image,
            image_type: "satellite"
          }}
          includes_primary_button={false}
          photo_height={250}
        />
      );
    } else if (image_type == "roadmap") {
      return (
        <PhotoGalleryImage
          {...this.props}
          additional_image={{
            image: property?.map_image,
            image_type: "roadmap"
          }}
          includes_primary_button={false}
          photo_height={250}
        />
      );
    }
  }

  render() {
    if (this.state.mls_loading) {
      return <SpinWrapper text={"Loading Images"} />;
    }
    return (
      <>
        {this.state.mls_images.map((item, i) => {
          return (
            <PhotoGalleryImage
              key={"mls_image" + i}
              {...this.props}
              additional_image={{
                image: item.image,
                image_type: "mls"
              }}
              includes_primary_button={false}
              photo_height={250}
            />
          );
        })}
        {this.renderStreetViewPic()}
        {this.renderPropertyPic("satellite")}
        {this.renderPropertyPic("roadmap")}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { colors } = settings;
  return {
    token,
    user,
    colors
  };
};

export default connect(mapStateToProps, {
  getMLSImages
})(PropertyPhotos);
