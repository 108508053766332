export const TOGGLE_EDIT_TAGS = "toggle_edit_tags";
export const EDIT_PROPERTY_TAGS = "edit_property_tags";

export const GET_TAGS = "get_tags";
export const GET_TAGS_SUCCESS = "get_tags_success";
export const GET_TAGS_FAIL = "get_tags_fail";
export const CREATE_TAG_SUCCESS = "create_tag_success";
export const CREATE_TAG_FAIL = "create_tag_fail";
export const UPDATE_TEAM_TAG_ORDER_SUCCESS = "update_team_tag_order_success";
export const UPDATE_TEAM_TAG_ORDER_FAIL = "update_team_tag_order_fail";
export const REMOVE_TAG_SUCCESS = "remove_tag_success";
export const REMOVE_TAG_FAIL = "remove_tag_fail";
export const HIDE_TAG_SUCCESS = "hide_tag_success";
export const HIDE_TAG_FAIL = "hide_tag_fail";
export const SHOW_TAG_SUCCESS = "show_tag_success";
export const SHOW_TAG_FAIL = "show_tag_fail";
export const GET_HIDE_TEAM_TAG_SUCCESS = "get_hide_team_tag_success";
export const GET_HIDE_TEAM_TAG_FAIL = "get_hide_team_tag_fail";
export const GET_PROPERTY_TAGS = "get_property_tags";
export const GET_PROPERTY_TAGS_SUCCESS = "get_property_tags_success";
export const GET_PROPERTY_TAGS_FAIL = "get_property_tags_fail";
