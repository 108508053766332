import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Bold,
  Copy,
  Icon,
  ProfilePic,
  Row,
  Spin,
  Wrapper
} from "app/NativeComponents/common";
import { formatUsPhone, setActiveDialerContact } from "app/NativeActions";
import { IconButton, InlineButton } from "app/NativeComponents/snippets";

class IncomingCallButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { colors, active_dialer_contact, incoming_call_info } = this.props;
    const { call_status } = this.props.active_call;

    return (
      <>
        <Wrapper
          style={{
            paddingRight: 25,
            paddingLeft: 25,
            height: 60,
            justifyContent: "center"
          }}
        >
          <Row>
            <IconButton
              button_type={"small"}
              noPress={true}
              icon={"call-received"}
              icon_color={colors.orange_color}
            />
            <Wrapper style={{ flex: 1 }}>
              <Copy
                style={{
                  color: colors.orange_color,
                  fontSize: 12
                }}
              >
                Incoming Call:
              </Copy>

              <Row>
                <Copy
                  style={{
                    color: colors.white_text_color,
                    marginRight: 5
                  }}
                >
                  <Bold>
                    {this.props.incoming_call_info?.contact?.full_name}
                  </Bold>
                </Copy>
                <Copy style={{ color: colors.white_text_color }}>
                  {formatUsPhone(
                    this.props.incoming_call_info?.from_phone_number
                  )}
                </Copy>
              </Row>
            </Wrapper>
            <Row>
              <Wrapper className={"ring-animation"}>
                <IconButton
                  onPress={() => {
                    this.props.acceptIncomingCall();
                  }}
                  icon={"call"}
                  button_type={"small"}
                  tooltip={"End Current Call & Answer"}
                  tooltipPlacement={"top"}
                  icon_color={colors.success_color}
                  hover_color={colors.hover_white_color}
                />
              </Wrapper>
              <IconButton
                onPress={() => {
                  if (this.props.incoming_call) {
                    this.props.incoming_call.reject();
                  }
                  this.props.rejectIncomingCall();
                }}
                icon={"call-end"}
                button_type={"small"}
                tooltip={"Don't Answer"}
                tooltipPlacement={"top"}
                icon_color={colors.error_color}
                hover_color={colors.hover_white_color}
              />
            </Row>
          </Row>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, dialer, native }) => {
  const { token, user } = auth;
  const { device, desktopMobile } = native;
  const { dark_mode, colors } = settings;
  const { active_dialer_contact, active_call } = dialer;

  return {
    token,
    user,
    dark_mode,
    device,
    desktopMobile,
    colors,
    active_dialer_contact,
    active_call
  };
};

export default connect(mapStateToProps, {
  setActiveDialerContact
})(IncomingCallButtons);
