import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Wrapper,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SpinWrapper,
  GhostButton,
  InlineButton,
  BottomNavBar
} from "app/NativeComponents/snippets";

import {
  popAllSidePanels,
  getStripePriceInformation,
  manageSubscription
} from "app/NativeActions";

class StripeUpgrades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_upgrades: false,
      upsell_loading: false,
      stripe_trial_upgrades: [],
      selected_products: []
    };

    this.handleBack = this.handleBack.bind(this);
    this.confirmUpgrades = this.confirmUpgrades.bind(this);
  }

  componentDidMount() {
    this.props.getStripePriceInformation({
      token: this.props.token,
      type: "stripe_trial_upgrades",
      onLoading: () => {
        this.setState({ loading_upgrades: true });
      },
      onError: () => {
        this.setState({ loading_upgrades: false });
      },
      onSuccess: results => {
        let sp = [];
        if (results?.stripe_trial_upgrades) {
          for (let i = 0; i < results.stripe_trial_upgrades.length; i++) {
            if (results.stripe_trial_upgrades[i].selected) {
              sp.push(results.stripe_trial_upgrades[i]);
            }
          }
        }

        this.setState({
          loading_upgrades: false,
          stripe_trial_upgrades: results?.stripe_trial_upgrades || [],
          selected_products: sp
        });
      }
    });
  }

  onAddOption(option) {
    const { selected_products } = this.state;
    this.setState({
      selected_products: [...selected_products, option]
    });
  }

  onRemoveOption(option) {
    const { selected_products } = this.state;

    this.setState({
      selected_products: selected_products.filter(
        ({ product_slug }) => product_slug !== option.product_slug
      )
    });
  }

  componentDidUpdate(prevProps) {}
  handleBack() {
    this.props.popAllSidePanels();
  }

  confirmUpgrades() {
    const { selected_products } = this.state;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    let stripe_price_ids = "";

    for (let i = 0; i < selected_products.length; i++) {
      if (panel_data?.interval_toggle === "year") {
        if (stripe_price_ids.length == 0) {
          stripe_price_ids = selected_products[i].year_price_id;
        } else {
          stripe_price_ids += "," + selected_products[i].year_price_id;
        }
      } else if (panel_data?.interval_toggle === "month") {
        if (stripe_price_ids.length == 0) {
          stripe_price_ids = selected_products[i].month_price_id;
        } else {
          stripe_price_ids += "," + selected_products[i].month_price_id;
        }
      }
    }

    this.props.manageSubscription({
      token: this.props.token,
      type: "add_items_to_subscriptions",
      stripe_price_ids: stripe_price_ids,
      onLoading: () => {
        this.setState({
          upsell_loading: true
        });
      },

      onError: () => {
        this.setState({
          upsell_loading: false
        });
      },
      onSuccess: () => {
        this.setState(
          {
            upsell_loading: false
          },
          () => {
            this.handleBack();
          }
        );
      }
    });
  }
  render() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    const { colors, source_of_truth } = this.props;
    const {
      loading_upgrades,
      upsell_loading,
      stripe_trial_upgrades,
      selected_products
    } = this.state;
    return (
      <Container>
        <NewHeader
          title={"Upgrade Your Trial"}
          subtitle={"Add some of our upgrades to enhance your trial"}
          leftButton={{
            icon: "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <SelectItem
            noPress={true}
            select_type={"icon"}
            selected={true}
            selected_background_color={colors.success_color_muted}
            select_icon={"check-circle"}
            label={"You're trialing: "}
            description={
              "Your trial starts now. Enhance your trial by adding any upgrades below."
            }
          >
            {panel_data?.selected_product?.title}
          </SelectItem>

          {this.state.loading_upgrades ? (
            <SpinWrapper text={"Loading"} />
          ) : (
            <>
              {stripe_trial_upgrades &&
                stripe_trial_upgrades.map((item, index) => {
                  let did_purchase_product = false;

                  for (
                    let i = 0;
                    i < source_of_truth?.subscription?.products.length;
                    i++
                  ) {
                    if (
                      item?.product_slug ===
                      source_of_truth?.subscription?.products[i].product_slug
                    ) {
                      did_purchase_product = true;
                    }
                  }

                  let in_selected_options = false;
                  for (let i = 0; i < selected_products.length; i++) {
                    if (
                      item?.product_slug === selected_products[i].product_slug
                    ) {
                      in_selected_options = true;
                    }
                  }
                  if (!did_purchase_product) {
                    return (
                      <SelectItem
                        key={"upgrade_" + index}
                        select_type={"check"}
                        noPress={did_purchase_product}
                        selected={did_purchase_product || in_selected_options}
                        description={item.description}
                        onPress={() => {
                          if (in_selected_options) {
                            this.onRemoveOption(item);
                          } else {
                            this.onAddOption(item);
                          }
                        }}
                        label={
                          did_purchase_product
                            ? "Already Included"
                            : panel_data?.interval_toggle == "year"
                            ? item.pricetext_year
                            : item.pricetext
                        }
                        subtext={
                          did_purchase_product
                            ? ""
                            : panel_data?.interval_toggle == "year"
                            ? item.subtext_year
                            : item.subtext
                        }
                      >
                        {item?.title}
                      </SelectItem>
                    );
                  }
                  return null;
                })}
            </>
          )}
        </KeyboardView>
        <BottomNavBar>
          <Wrapper style={{ padding: 10 }}>
            <Copy style={{ textAlign: "center" }}>
              Select from the options above.
            </Copy>
          </Wrapper>
          <GhostButton
            primary={true}
            button_type="full"
            loading={upsell_loading}
            disabled={selected_products.length <= 0}
            onPress={() => this.confirmUpgrades()}
          >
            Add To Trial
          </GhostButton>

          <InlineButton
            button_type="full"
            onPress={() => {
              this.handleBack();
            }}
          >
            Not right now
          </InlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { card, source_of_truth } = billing;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    card,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  popAllSidePanels,
  getStripePriceInformation,
  manageSubscription
})(StripeUpgrades);
