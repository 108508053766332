import React, { Component } from "react";
import {
  Wrapper,
  Card,
  Gradient,
  ExternalImage,
  Row,
  Copy,
  Title,
  Bold,
  ContentfulRichText
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import {
  loadDashboardUpcomingWebinars,
  openUrl,
  formatDateTimeToLocal
} from "app/NativeActions";

class Webinars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webinars: [],
      loading: true
    };
  }

  componentDidMount() {
    loadDashboardUpcomingWebinars()
      .then(data => {
        if (data && data.items) {
          const webinar = {
            title: data.items[0].fields.title,
            subtitle: formatDateTimeToLocal(data.items[0].fields.startAt),
            description: data.items[0].fields.description,
            image: data.items[0].fields.image,
            buttonText: data.items[0].fields.buttonText,
            buttonLink: data.items[0].fields.buttonLink
          };
          this.setState({
            webinars: [...this.state.webinars, webinar]
          });
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderImage(item) {
    const { colors } = this.props;
    if (item.image && item.image.fields) {
      return (
        <Wrapper
          style={{
            padding: 0,
            paddingRight: 0,
            marginBottom: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ExternalImage
            style={{
              width: 80,
              height: 80,
              borderRadius: 40,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: colors.white_color
            }}
            image={item.image.fields.file ? item.image.fields.file.url : ""}
          />
        </Wrapper>
      );
    }
  }

  renderButton(item, primary = false) {
    if (item.buttonLink && item.buttonText) {
      return (
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <PillButton
            style={{ margin: 0 }}
            onPress={() => {
              openUrl(item.buttonLink);
            }}
            primary={primary}
          >
            {item.buttonText}
          </PillButton>
        </Row>
      );
    }
  }

  renderSubtitle(item) {
    if (!!item.subtitle) {
      return (
        <Copy
          style={{
            textAlign: "center",
            paddingBottom: 15
          }}
        >
          <Bold>{item.subtitle}</Bold>
        </Copy>
      );
    }
  }

  renderWebinar() {
    const { colors } = this.props;

    if (!this.state.loading && this.state.webinars.length > 0) {
      return (
        <Wrapper>
          {this.state.webinars.map((webinar, i) => {
            return (
              <Wrapper key={i}>
                <Wrapper style={{ padding: 20 }}>
                  <Wrapper>
                    {this.renderImage(webinar)}

                    <Wrapper style={{ padding: 0, flex: 1 }}>
                      <Title
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {webinar.title}
                      </Title>
                      {this.renderSubtitle(webinar)}
                      <ContentfulRichText
                        style={{
                          textAlign: "center"
                        }}
                        richText={webinar.description}
                      />
                      {this.renderButton(webinar, true)}
                    </Wrapper>
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            );
          })}
        </Wrapper>
      );
    }
  }

  render() {
    return <Card>{this.renderWebinar()}</Card>;
  }
}

export default Webinars;
