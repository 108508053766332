import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper } from "app/NativeComponents/common";
import { SearchBar, IconButton } from "app/NativeComponents/snippets";
import { dismissMobileKeyboard } from "app/NativeActions";

import AutocompleteItem from "./AutocompleteItem";
class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open_search: false,
      search_focused: false,
      search_text: "",
      loading: false,
      autocomplete_items: [],
      error_title: "",
      error_message: ""
    };
    this.triggerAutocomplete = this.triggerAutocomplete.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  triggerAutocomplete(value) {
    if (value.length > 2) {
      clearInterval(this._search_interval);
      this._search_interval = setTimeout(() => {
        this.props.searchDealMachine({
          token: this.props.token,
          search: value,
          onLoading: () => {
            this.setState({
              loading: true,
              autocomplete_items: [],
              error_title: "",
              error_message: ""
            });
          },
          onError: () => {
            this.setState({
              loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              loading: false,
              autocomplete_items: results.search_results,
              error_title:
                !results.search_results || results.search_results.length == 0
                  ? "No results"
                  : "",
              error_message:
                !results.search_results || results.search_results.length == 0
                  ? "We could not find anything matching your search."
                  : ""
            });
          }
        });
      }, 250);
    } else {
      this.setState({
        autocomplete_loading: false,
        autocomplete_items: [],
        error_title: "",
        error_message: ""
      });
    }
  }

  render() {
    const { colors, isMobile, device, tab } = this.props;
    const {
      open_search,
      search_text,
      loading,
      autocomplete_items,
      error_title,
      error_message
    } = this.state;

    let items = [];
    if (autocomplete_items && autocomplete_items.length > 0 && !!search_text) {
      let count = autocomplete_items.length;
      if (count > 5) {
        count = 5;
      }
      for (let i = 0; i < count; i++) {
        const item = autocomplete_items[i];
        switch (item.search_type) {
          case "lead":
          default:
            //if search matches property

            //if search matches owner name

            //if search matches phone number

            //if search matches email

            items.push({
              value: "",
              onPress: () => {},
              renderComponent: () => {
                return (
                  <AutocompleteItem
                    item={item}
                    colors={this.props.colors}
                    search={this.state.search_text.replace(/[^A-Z0-9]+/gi, " ")}
                    onPress={() => {
                      dismissMobileKeyboard();
                      this.props.selectActiveProperty(this.props.token, item);
                      this.props.pushSidePanel({
                        slug: "property",
                        id: item?.property_id,
                        focus_side_panel:
                          this.props.user?.user_settings?.property_expanded ===
                          "not_expanded"
                            ? false
                            : true,
                        overlay: true,
                        data: {
                          property: item
                        }
                      });

                      this.setState({
                        search_text: "",
                        search_focused: false,
                        autocomplete_loading: false,
                        autocomplete_items: []
                      });
                    }}
                  />
                );
              }
            });
            break;
        }
      }
    }

    if (open_search) {
      //
      return (
        <Wrapper
          style={{
            height: 60,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: 400,
            minWidth: 200
          }}
        >
          <SearchBar
            autoFocus={true}
            title={!!search_text ? "Search DealMachine" : "Search DealMachine"}
            value={search_text}
            onChange={value => {
              this.setState({
                search_text: value
              });
              this.triggerAutocomplete(value.replace(/[^A-Z0-9]+/gi, " "));
            }}
            items={items}
            loading={loading}
            error_title={error_title}
            error_message={error_message}
            borderRadius={5}
            style={{
              borderWidth: 1,
              borderBottomWidth: 1,
              width: "100%",
              borderRadius: 5
            }}
            onFocus={() => {
              this.setState({
                search_focused: false
              });
            }}
            onBlur={() => {
              this.setState({
                search_focused: false,
                open_search: !!search_text ? open_search : false,
                error_title: "",
                error_message: ""
              });
            }}
            renderRight={() => {
              return (
                <IconButton
                  icon={"close"}
                  button_type="small"
                  onPress={() => {
                    this.setState({
                      search_text: "",
                      search_focused: false,
                      open_search: false,
                      error_title: "",
                      error_message: ""
                    });
                  }}
                />
              );
            }}
          />
        </Wrapper>
      );
    }
    return (
      <IconButton
        tooltip="Search DealMachine"
        icon_color={colors.white_text_color}
        icon={"search"}
        borderColor={colors.white_text_color}
        image_size={36}
        no_border={true}
        onPress={() => {
          this.setState({
            open_search: true
          });
        }}
        size={22}
        style={{
          borderRadius: 0,
          margin: 0,
          height: 60,
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 0,
          paddingBottom: 0
        }}
      />
    );
  }
}

export default Search;
