import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, KeyboardView, Row } from "app/NativeComponents/common";
import {
  NewHeader,
  List,
  SmallLabel,
  SearchBar,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  getLeadStatuses,
  resetStatusModal,
  updateLead,
  getDealStatusSlug,
  popSidePanel
} from "app/NativeActions";

class LeadStatuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      loading: false,
      selected_status: props.status_modal
        ? props.status_modal.selected_status
        : 0,
      selected_leads: props.status_modal
        ? props.status_modal.selected_leads
        : []
    };
  }

  componentDidMount() {
    this.props.getLeadStatuses({
      token: this.props.token
    });
  }

  checkItem(item) {
    if (!this.state.loading) {
      this.setState({
        selected_status: item.id
      });
    }
  }

  confirmUpdate() {
    if (this.props.status_modal.modalAction && !this.state.loading) {
      this.props.status_modal.modalAction({
        selected_status: this.state.selected_status,
        selected_leads: this.state.selected_leads,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });
          this.props.popSidePanel();
        }
      });
    }
  }

  handleBack() {
    if (this.props.status_modal) {
      if (this.props.status_modal.cancelAction) {
        this.props.status_modal.cancelAction();
      }
    }
    this.props.popSidePanel();
  }

  render() {
    const { lead_statuses, lead_statuses_loading, colors } = this.props;
    const { selected_status, loading, search } = this.state;
    let other_statuses = [];

    if (lead_statuses) {
      for (let i = 0; i < lead_statuses.length; i++) {
        if (
          lead_statuses[i].status_type == "trash_status" ||
          lead_statuses[i].status_type == "return_to_sender_status"
        ) {
          other_statuses.push(lead_statuses[i]);
        }
      }
    }

    return (
      <>
        <NewHeader
          title={this.props.status_modal.title}
          subtitle={this.props.status_modal.description}
          leftButton={{
            icon: this.props.device == "mobile" ? "arrow-back" : "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <SearchBar
          title="Search Statuses"
          value={search}
          onChange={value => {
            if (!loading) {
              this.setState({
                search: value,
                selected_status: 0
              });
            }
          }}
          style={{ margin: 10 }}
        />
        <KeyboardView>
          {lead_statuses_loading ? (
            <SpinWrapper text="Loading Statuses..." />
          ) : (
            <List
              style={{ alignSelf: "stretch" }}
              items={lead_statuses}
              listHeader={() => {
                return (
                  <>
                    {!search ? (
                      <SmallLabel style={{}}>Select a status:</SmallLabel>
                    ) : null}
                  </>
                );
              }}
              itemStructure={({ item, index }) => {
                if (
                  !!item.title &&
                  item.title.toLowerCase().includes(search.toLowerCase())
                ) {
                  if (
                    item.status_type != "trash_status" &&
                    item.status_type != "return_to_sender_status"
                  ) {
                    return (
                      <SelectItem
                        key={"option_" + index}
                        select_type="radio"
                        selected={item.id == selected_status}
                        onPress={() => {
                          this.checkItem(item);
                        }}
                        require_confirm={true}
                        confirm_loading={loading}
                        onConfirm={() => {
                          this.confirmUpdate();
                        }}
                      >
                        {item.title}
                      </SelectItem>
                    );
                  }
                }
                return null;
              }}
              listFooter={() => {
                return (
                  <>
                    {!search ? (
                      <SmallLabel style={{}}>Other statuses:</SmallLabel>
                    ) : null}
                    {other_statuses.map((lead_status, i) => {
                      if (
                        !!lead_status.title &&
                        lead_status.title
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return (
                          <SelectItem
                            key={"option_" + i}
                            select_type="radio"
                            selected={lead_status.id == selected_status}
                            onPress={() => {
                              this.checkItem(lead_status);
                            }}
                            require_confirm={true}
                            confirm_loading={loading}
                            onConfirm={() => {
                              this.confirmUpdate();
                            }}
                          >
                            {lead_status.title}
                          </SelectItem>
                        );
                      }
                      return null;
                    })}
                  </>
                );
              }}
            />
          )}
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  modal,
  customsettings,
  filter
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { is_loading } = modal;

  const { lead_statuses, lead_statuses_loading } = customsettings;
  const { status_modal } = filter;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    is_loading,
    status_modal,
    lead_statuses,
    lead_statuses_loading
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getLeadStatuses,
  resetStatusModal,
  updateLead,
  popSidePanel
})(LeadStatuses);
