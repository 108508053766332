import React from "react";

import { Link } from "react-router-dom";

import SignUpButtons from "./SignUpButtons";

const Buttons = props => {
  return (
    <div>
      {/*
      <div className="dm-website-hover-dropdown-button">
        <Link to={"/"}>What We Offer</Link>
        <div className="dm-website-hover-dropdown animated fadeIn">
          <Link to={"/automated-owner-lookup"}>
            <div className="dm-website-dropdown-icon-button">
              <img alt="" src="/assets/images/FindTheOwner.svg" />
              <div>Automated Owner Lookup</div>
            </div>
          </Link>
          <Link to={"/direct-mail-campaigns"}>
            <div className="dm-website-dropdown-icon-button">
              <img alt="" src="/assets/images/GetInTouch.svg" />
              <div>Direct Mail Mail Sequences</div>
            </div>
          </Link>
          <Link to={"/real-estate-lead-management"}>
            <div className="dm-website-dropdown-icon-button">
              <img alt="" src="/assets/images/PropertyManagementIcon.svg" />
              <div>Real Estate Lead Management</div>
            </div>
          </Link>

          <Link to={"/driving-route-tracking"}>
            <div className="dm-website-dropdown-icon-button">
              <img alt="" src="/assets/images/RouteTracking.svg" />
              <div>Driving Route Tracking</div>
            </div>
          </Link>
        </div>
      </div>
      */}

      {/*<Link to={"/pricing"}>Pricing</Link>*/}
      {/*<Link to={"/blog"}>Blog</Link>*/}
      <SignUpButtons />
    </div>
  );
};

export default Buttons;
