import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Wrapper />;
  }
}

export default Slider;
