import { updateUser } from ".";

export const setRouteForOnboarding = route => {
  return dispatch => {
    dispatch({ type: "set_route_for_onboarding", payload: route });
  };
};
export const setOnboardingAutoCompleteInProgress = value => {
  return dispatch => {
    dispatch({
      type: "onboarding_auto_complete",
      payload: value
    });
  };
};

export const setOnboardingLayout = layoutObject => {
  return dispatch => {
    dispatch({
      type: "set_onboarding_layout",
      payload: layoutObject
    });
  };
};

export const nextOnboardingStep = (token, steps, currentStep, onSuccess) => {
  const currentIndex = steps.findIndex(x => x.slug == currentStep.slug);

  return dispatch => {
    if (currentIndex < steps.length - 1) {
      dispatch(
        updateUser({
          token,
          type: "onboarding_stepthrough_step",
          payload: {
            onboarding_stepthrough_current_step: steps[currentIndex + 1]?.slug,
            onboarding_completed_step: steps[currentIndex]?.slug,
            onboarding_completed_step_number: currentIndex + 1
          },
          onSuccess: onSuccess ? () => onSuccess() : () => {},
          no_loading: true
        })
      );
      dispatch({
        type: "set_tracking_event",
        payload: `onboarding_stepthrough_step_completed_${steps[currentIndex]?.slug}`
      });
      dispatch({
        type: "set_current_onboarding_step",
        payload: {
          currentStep:
            currentIndex != -1 ? steps[currentIndex + 1] : currentStep
        }
      });
    } else {
      dispatch(
        updateUser({
          token,
          type: "onboarding_stepthrough_complete",
          payload: {
            onboarding_stepthrough_complete: 1
          },
          onSuccess: () => {},
          no_loading: true
        })
      );
      dispatch({
        type: "set_tracking_event",
        payload: `onboarding_stepthrough_step_completed_${steps[currentIndex]?.slug}`
      });
      dispatch({
        type: "set_tracking_event",
        payload: `onboarding_stepthrough_completed`
      });
      dispatch({
        type: "set_current_onboarding_step",
        payload: {
          currentStep: currentStep,
          isOnboardingActive: false
        }
      });
    }
  };
};
