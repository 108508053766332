import React, { Component } from "react";
import { Wrapper, KeyboardView } from "app/NativeComponents/common";
import {
  SmallHeader,
  InlineButton,
  SelectItem,
  PillButton
} from "app/NativeComponents/snippets";

import EqualOption from "./EqualOption";
import TextType from "./TextType";
import BoolType from "./BoolType";
import NumberType from "./NumberType";
import ObjectArrayType from "./ObjectArrayType";
import DateType from "./DateType";

class ChooseEquals extends Component {
  constructor(props) {
    super(props);

    const filter = props.enrollment_object?.data[props.selected_filter];

    this.state = {
      selected_equal_type: props.equal_type
        ? props.equal_type
        : filter.type == "text" ||
          filter.type == "object" ||
          filter.type == "object_array" ||
          filter.type == "flattened_object_collection" ||
          filter.type == "bool"
        ? "is_equal_to_any_of"
        : filter.type == "number" || filter.type == "number_array"
        ? "is_equal_to"
        : filter.type == "date"
        ? "is_between"
        : null,
      selected_options: props.values || [],
      selected_input: props.values ? props.values[0]["value"] : "",
      selected_date_object: props.date_object
        ? props.date_object
        : {
            date_1: null,
            date_2: null,
            date_type: "days",
            date_amount: 1,
            time_type: "ago"
          }
    };

    this.selectEqualType = this.selectEqualType.bind(this);
    this.addSelectedOption = this.addSelectedOption.bind(this);
    this.removeSelectedOption = this.removeSelectedOption.bind(this);
    this.updateSelectedInput = this.updateSelectedInput.bind(this);
    this.updateSelectedDateObject = this.updateSelectedDateObject.bind(this);
  }

  selectEqualType(selected_equal_type) {
    this.setState({
      selected_equal_type,
      selected_options:
        selected_equal_type !== this.state.selected_equal_type
          ? []
          : this.state.selected_options,
      selected_input:
        selected_equal_type !== this.state.selected_equal_type
          ? ""
          : this.state.selected_input
    });
  }
  updateSelectedInput(input) {
    this.setState({
      selected_input: input
    });
  }

  updateSelectedDateObject(date_object) {
    this.setState({
      selected_date_object: date_object
    });
  }
  addSelectedOption(option) {
    this.setState({
      selected_options: [...this.state.selected_options, option]
    });
  }
  removeSelectedOption(option) {
    this.setState({
      selected_options: this.state.selected_options.filter(
        ({ value }) => value !== option.value
      )
    });
  }
  render() {
    const { enrollment_object, selected_filter, colors, device } = this.props;
    const {
      selected_equal_type,
      selected_options,
      selected_input,
      selected_date_object
    } = this.state;

    const filter = enrollment_object?.data[selected_filter];
    if (filter) {
      return (
        <>
          <SmallHeader
            leftButton={{
              onPress: () => {
                this.props.selectFilter(null);
              },
              icon: "keyboard-arrow-left"
            }}
            title={filter.label}
            subtitle={filter.description}
            style={
              !!filter.description
                ? device == "desktop"
                  ? { height: "auto", paddingTop: 10, paddingBottom: 10 }
                  : { height: 120, paddingTop: 10, paddingBottom: 10 }
                : {}
            }
          />
          <KeyboardView style={{ flex: 1 }}>
            <TextType
              {...this.props}
              filter={filter}
              selected_filter={selected_filter}
              addFilter={this.props.addFilter}
              selected_equal_type={selected_equal_type}
              selected_options={selected_options}
              addSelectedOption={this.addSelectedOption}
              removeSelectedOption={this.removeSelectedOption}
              selectEqualType={this.selectEqualType}
            />
            <BoolType
              {...this.props}
              filter={filter}
              selected_filter={selected_filter}
              addFilter={this.props.addFilter}
              selected_equal_type={selected_equal_type}
              selected_options={selected_options}
              addSelectedOption={this.addSelectedOption}
              removeSelectedOption={this.removeSelectedOption}
              selectEqualType={this.selectEqualType}
            />
            <NumberType
              {...this.props}
              filter={filter}
              selected_filter={selected_filter}
              addFilter={this.props.addFilter}
              selected_equal_type={selected_equal_type}
              selected_input={selected_input}
              updateSelectedInput={this.updateSelectedInput}
              selectEqualType={this.selectEqualType}
              selected_date_object={selected_date_object}
              updateSelectedDateObject={this.updateSelectedDateObject}
            />
            <ObjectArrayType
              {...this.props}
              filter={filter}
              selected_filter={selected_filter}
              addFilter={this.props.addFilter}
              selected_equal_type={selected_equal_type}
              selected_options={selected_options}
              addSelectedOption={this.addSelectedOption}
              removeSelectedOption={this.removeSelectedOption}
              selectEqualType={this.selectEqualType}
            />

            <DateType
              {...this.props}
              filter={filter}
              selected_filter={selected_filter}
              addFilter={this.props.addFilter}
              selected_equal_type={selected_equal_type}
              selected_input={selected_input}
              selected_date_object={selected_date_object}
              updateSelectedInput={this.updateSelectedInput}
              updateSelectedDateObject={this.updateSelectedDateObject}
              selectEqualType={this.selectEqualType}
            />
          </KeyboardView>
        </>
      );
    }
    return null;
  }
}

export default ChooseEquals;
