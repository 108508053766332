import "array.prototype.flatmap";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Scroll, Wrapper } from "app/NativeComponents/common";
import Header from "./Header";
import SelectAllCheck from "./SelectAllCheck";
import ListStackHeader from "./ListStackHeader";
import {
  updateNewColumnWidth,
  updateUserListSettings,
  changeSortBy,
  toggleLeadImages
} from "app/NativeActions";

class HeaderRow extends Component {
  render() {
    const { list_settings, colors } = this.props;
    return (
      <Row
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflow: "hidden",
          borderTopWidth: 1,
          borderTopColor: colors.border_color,
          borderTopStyle: "solid"
        }}
      >
        <SelectAllCheck {...this.props} />
        <ListStackHeader {...this.props} />
        {list_settings &&
          list_settings.user_list_columns.map((column, i) => {
            if (column.main_column) {
              return (
                <Header
                  {...this.props}
                  key={"header_main_" + i}
                  column_width={column.width}
                  adjustColumnWidth={this.adjustColumnWidth}
                  section={column}
                />
              );
            }
            return null;
          })}
        <Scroll
          className={"hide_scroll_bar"}
          scroll_ref={this.props._header_scroll_view}
          horizontal
          style={{
            width: document.getElementById("scrollCols")
              ? document.getElementById("scrollCols").clientWidth
              : "auto",
            scrollBehavior: "initial",
            overflow: "hidden"
          }}
          onScroll={e => {
            /*
            requestAnimationFrame(() => {
              this.props.updateScrollPositions(
                this.props._header_scroll_view.current.scrollLeft
              );
            });
            */
          }}
        >
          <Row
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start"
            }}
          >
            {this.props.renderColumns({
              renderItem: ({ key, item }) => {
                if (!item.main_column && item.show) {
                  return (
                    <Header
                      {...this.props}
                      key={"header_" + key}
                      column_width={item.width}
                      adjustColumnWidth={this.adjustColumnWidth}
                      section={item}
                    />
                  );
                }
                return null;
              }
            })}
          </Row>
        </Scroll>
      </Row>
    );
  }
}

const mapStateToProps = ({ auth, native, lead }) => {
  const { token, user } = auth;
  const { toggle_lead_images } = lead;

  return {
    token,
    user,
    toggle_lead_images
  };
};

export default connect(mapStateToProps, {
  updateNewColumnWidth,
  updateUserListSettings,
  changeSortBy,
  toggleLeadImages
})(HeaderRow);
