import React, { PureComponent } from "react";

import Preview from "./Preview";

class TemplatePreview extends PureComponent {
  render() {
    return <Preview {...this.props} />;
  }
}

export { TemplatePreview };
