import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyboardView,
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Bold,
  Container,
  Scroll,
  Row,
  Icon,
  Spin,
  Button
} from "app/NativeComponents/common";
import {
  Header,
  NewHeader,
  PillButton,
  InlineButton,
  DragDropList,
  SmallLabel,
  AttentionBox
} from "app/NativeComponents/snippets";
import {
  dismissMobileKeyboard,
  updatePaymentMethodsOrder,
  deleteCard,
  popSidePanel,
  pushSidePanel,
  makePrimaryCard
} from "app/NativeActions";
import { Information } from "./Information";
import { ADD_ADDITIONAL_CARD, DELETE_CARD } from "app/DealMachineCore/types";

const ManagePaymentMethods = ({ toggleClose }) => {
  const dispatch = useDispatch();

  const { token, user } = useSelector(({ auth }) => auth);
  const { platform } = useSelector(({ native }) => native);
  const { card_info } = useSelector(({ billing }) => billing);
  const { colors } = useSelector(({ settings }) => settings);

  const [cardOrder, setCardOrder] = useState([]);

  // Listen for card_info and sort cards, store in local state
  useEffect(() => {
    if (!card_info.cards || card_info.cards.length === 0) return;
    let cards = card_info.cards;
    cards = cards.map(card => ({ ...card, id: card.card_order }));
    cards.sort((a, b) => (a.card_order > b.card_order ? 1 : -1));
    setCardOrder(cards);
  }, [card_info]);

  const handleBack = () => {
    dismissMobileKeyboard();
    dispatch(popSidePanel());
  };

  const handleReorderBackups = data => {
    const orderChanged =
      JSON.stringify(data.map(d => parseInt(d.card_order))) !=
      JSON.stringify(cardOrder.slice(1).map(c => parseInt(c.card_order)));
    if (!orderChanged) return;
    const newOrder = [
      cardOrder[0],
      ...data.map((item, i) => ({ ...item, card_order: `${i + 1}` }))
    ];
    setCardOrder(newOrder);
    dispatch(
      updatePaymentMethodsOrder(
        token,
        newOrder.map(item => item.stripe_card_id)
      )
    );
  };

  if (platform == "ios") {
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"Manage Payment Methods"}
          leftButton={
            user?.bad_card != 1
              ? {
                  icon: "arrow-back",
                  onPress: handleBack
                }
              : {}
          }
        />
        <Wrapper style={{ padding: 25 }}>
          <Copy>
            <Bold>We know it's a hassle.</Bold>
          </Copy>
          <Copy>
            Check out DealMachine on the web to manage payment methods and then
            come back.
          </Copy>
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <NewHeader
        title={"Manage Payment Methods"}
        leftButton={
          user?.bad_card != 1
            ? {
                icon: "arrow-back",
                onPress: handleBack
              }
            : null
        }
      />
      <KeyboardView style={{ flex: 1 }}>
        {/* Informational Paragraphs Section
                content from Contentful */}
        <Information user={user} />

        {/* Primary List Section */}
        <Row
          style={{
            justifyContent: "space-between"
          }}
        >
          <SmallLabel
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 25,
              paddingLeft: 25
            }}
          >
            Primary Payment Methods:
          </SmallLabel>
          <InlineButton
            primary
            icon={"add"}
            onPress={() => {
              dispatch({ type: ADD_ADDITIONAL_CARD });

              dispatch(
                pushSidePanel({
                  slug: "add_card",
                  overlay: true
                })
              );
            }}
          >
            Add New Card
          </InlineButton>
        </Row>
        <Wrapper>
          {cardOrder && cardOrder.length > 0 ? (
            <CreditCardItem
              movable={false}
              brand={cardOrder[0].stripe_brand || "[Unknown brand]"}
              last4={cardOrder[0].stripe_last4 || "????"}
              name_on_card={cardOrder[0].name_on_card}
              card_id={cardOrder[0].stripe_card_id}
              primary={true}
            />
          ) : (
            <Wrapper style={{ padding: 25 }}>
              <Copy>You have no primary payment methods.</Copy>
            </Wrapper>
          )}
        </Wrapper>
        <SmallLabel
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 25,
            paddingLeft: 25
          }}
        >
          Backup Payment Methods
        </SmallLabel>
        {/* Backup List Section Drag+Drop */}
        {cardOrder.length > 1 ? (
          <DragDropList
            id="backup_cards"
            items={cardOrder.slice(1)}
            infiniteScroll={false}
            itemStructure={({ item }) => {
              // XXX: The mobile drag-drog-list has a nested "item" property under the item
              return (
                <CreditCardItem
                  movable={cardOrder.length > 2}
                  brand={item.stripe_brand || "[Unknown brand]"}
                  last4={item.stripe_last4 || "????"}
                  primary={false}
                  name_on_card={item.name_on_card}
                  card_id={item.stripe_card_id}
                  key={item.stripe_card_id}
                />
              );
            }}
            handleReorder={handleReorderBackups}
          />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Copy>
              Use the Add New Card button to establish a backup payment method.
            </Copy>
          </Wrapper>
        )}
      </KeyboardView>
    </Container>
  );
};

const CreditCardItem = ({
  movable,
  brand,
  last4,
  name_on_card,
  primary,
  card_id
}) => {
  const dispatch = useDispatch();
  const { is_loading } = useSelector(({ modal }) => modal);
  const { token } = useSelector(({ auth }) => auth);
  const { platform, isMobile } = useSelector(({ native }) => native);

  return (
    <Wrapper style={{ padding: 25 }}>
      <Row style={{ alignItems: "space-between" }}>
        <Wrapper>
          <Copy>
            <Bold>{`${brand.toUpperCase()} ${
              platform == "android" ? "" : "****"
            } ${last4}`}</Bold>
          </Copy>
          {name_on_card && <Copy>{name_on_card}</Copy>}
        </Wrapper>
        {!primary && (
          <Wrapper>
            <InlineButton
              onPress={() => {
                dispatch(makePrimaryCard(token, card_id));
              }}
            >
              {isMobile ? "Set Primary" : "Set as Primary Payment Method"}
            </InlineButton>
          </Wrapper>
        )}
        {!primary && (
          <Wrapper>
            <InlineButton
              icon="delete"
              onPress={() => {
                dispatch(deleteCard(token, card_id));
              }}
            >
              Delete
            </InlineButton>
          </Wrapper>
        )}

        {movable && <Icon icon="drag-indicator" size={18} />}
      </Row>
    </Wrapper>
  );
};

export default ManagePaymentMethods;
