import React, { Component } from "react";

import { Header3 } from "app/NativeComponents/website/common";

const FormTitle = ({ form }) => {
  if (form.title) {
    return (
      <Header3
        style={{
          textAlign: "left"
        }}
      >
        <b>{form.title}</b>
      </Header3>
    );
  }

  return <div />;
};

export default FormTitle;
