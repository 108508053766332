import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Row,
  Icon,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import StripeForm from "app/NativeComponents/components/StripeForm";
import BillingAddress from "../BillingAddress";

class BillingCard extends Component {
  onSaveCard() {}

  render() {
    return (
      <Wrapper>
        {/*
        <Wrapper style={{ padding: 25 }}>
          <Row>
            <Icon
              icon="lock-outline"
              size={18}
              style={{
                marginRight: 5
              }}
            />
            <Copy>
              <Bold>Securely add a credit card to your account:</Bold>
            </Copy>
          </Row>
          <Copy>This is a secure 256-bit SSL encrypted credit card field.</Copy>
        </Wrapper>
        */}

        <StripeForm
          {...this.props}
          address={this.props.address}
          loading={this.props.loading}
          renderBillingAddress={() => {
            return <BillingAddress {...this.props} />;
          }}
          buttonText={"Save Card"}
        />
      </Wrapper>
    );
  }
}

export default BillingCard;
