export const getMapOptions = (mapType, darkMode, disableDefaultUI = false) => {
  return {
    streetViewControl: false,
    scaleControl: false,
    fullscreenControl: false,

    zoomControl: false,
    rotateControl: false,

    panControl: false,
    mapTypeControl: false,
    overviewMapControl: false,
    disableDefaultUI: disableDefaultUI,

    styles: darkMode
      ? require(`app/assets/dark-map-style.json`)
      : [
          {
            featureType: "poi.business",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 11,
    maxZoom: 20,
    mapTypeId: mapType == "hybrid" ? "hybrid" : "roadmap",
    clickableIcons: false
  };
};
