import React, { PureComponent } from "react";
import { Wrapper, Row, Copy, Spin, Icon } from "app/NativeComponents/common";
import ManageListsButton from "app/DealMachineCore/reuseable/ManageListsButton";

class ManageListsButtonWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lists: props.property?.deal?.lists || []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.property?.deal?.lists != prevProps?.property?.deal?.lists) {
      this.setState({
        lists: this.props.property?.deal.lists
      });
    }
  }

  renderListsText(lists = []) {
    if (lists.length > 0 && Array.isArray(lists)) {
      return (
        <Wrapper
          className="deal-hover-icon"
          style={{
            alignSelf: "stretch"
          }}
        >
          <Row
            style={{
              whiteSpace: "nowrap"
            }}
          >
            <Copy>
              {lists.map((list, i) => {
                if (i === 0) {
                  return list.title;
                }
                return ", " + list.title;
              })}
            </Copy>
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    const { property, colors, section, row_height } = this.props;
    const { lists } = this.state;
    return (
      <ManageListsButton
        popoverPlacement="right"
        onLoading={({ lists }) => {
          this.setState({
            lists
          });
        }}
        renderComponent={() => {
          return (
            <Wrapper
              style={{
                position: "relative",
                width: section.width
              }}
            >
              <Wrapper
                style={{
                  width: section.width,
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}
              >
                <Row
                  style={{
                    padding: 10,
                    paddingLeft: 10,
                    height: row_height,
                    borderRightWidth: 0,
                    borderRightColor: colors.border_color,
                    borderRightStyle: "solid",
                    justifyContent: "center"
                  }}
                >
                  <Wrapper
                    style={{
                      flex: 1,
                      alignItems: "flex-start",
                      justifyContent: "center"
                    }}
                  >
                    <Row
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-start"
                      }}
                    >
                      <Wrapper>
                        <Copy
                          style={{
                            marginRight: 5,
                            color: colors.text_color
                          }}
                        >
                          {this.renderListsText(lists)}
                        </Copy>
                      </Wrapper>
                    </Row>
                  </Wrapper>
                </Row>
              </Wrapper>
            </Wrapper>
          );
        }}
        property={property}
      />
    );
  }
}

export default ManageListsButtonWrapper;
