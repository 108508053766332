import React, { Component } from "react";
import { connect } from "react-redux";

class CreatePost extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {})(CreatePost);
