import React, { Component } from "react";

const StatNumber = ({ stat }) => {
  if (stat.number) {
    return <div className="dm-website-stat-number">{stat.number}</div>;
  }

  return <div />;
};

export default StatNumber;
