import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineMainOwnerInfo,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class EditOwnerName extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    const owner_info = determineMainOwnerInfo(panel_data?.property);
    this.state = {
      owner_name: owner_info?.owner_name || "",
      state_focus: false,
      loading: false,
      panel_data: panel_data
    };

    this._name_input = React.createRef();

    this.saveOwnerName = this.saveOwnerName.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }
  checkIfNeedsToSave() {
    const owner_info = determineMainOwnerInfo(this.state.panel_data?.property);
    if (
      !!this.state.owner_name &&
      this.state.owner_name != owner_info?.owner_name
    ) {
      return true;
    }

    return false;
  }

  saveOwnerName() {
    const {
      panel_data: { property }
    } = this.state;

    const { owner_name } = this.state;
    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "edit_owner_name",
      owner_name,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      owner_name,
      loading,
      panel_data: { property, mailing_address }
    } = this.state;

    let formatted_property_address = "";
    if (property) {
      const display_property = determineDisplayProperty(property);
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    }

    return (
      <Container>
        <NewHeader
          title="Edit Owner Name"
          subtitle={
            "Edit the display name of the owner associated with " +
            formatted_property_address.line1
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._name_input}
            name="name"
            autoFocus={true}
            disabled={loading}
            returnKeyType="next"
            placeholder="Owner Name"
            onChange={value => {
              this.setState({
                owner_name: value
              });
            }}
            onSubmitEditing={this.saveOwnerName}
            blurOnSubmit={false}
            value={owner_name}
            type="text"
          />
          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.saveOwnerName}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Save Owner Name
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(EditOwnerName);
