import React, { Component } from "react";
import {
  Wrapper,
  Card,
  Row,
  PrimaryButton,
  DeleteButton
} from "app/NativeComponents/common";

import { GhostButton } from "app/NativeComponents/snippets";

class SubmitButton extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    if (this.props.require_complete_function) {
      this.props.onPress(() => {
        this.props.toggleModal({ show: false, type: null });
      });
    } else {
      this.props.toggleModal({ show: false, type: null });
      if (this.props.onPress) {
        this.props.onPress();
      }
    }
  }

  render() {
    if (this.props.submit && this.props.submit != "") {
      switch (this.props.buttonType) {
        case "primary":
        default:
          return (
            <GhostButton
              primary={true}
              loading={this.props.submit_loading}
              onPress={this.handleSubmit}
              button_type="full"
            >
              {this.props.submit}
            </GhostButton>
          );

        case "destroy":
          return (
            <GhostButton
              primary={true}
              loading={this.props.submit_loading}
              onPress={this.handleSubmit}
              button_type="full"
            >
              {this.props.submit}
            </GhostButton>
          );
      }
    }

    return <Wrapper />;
  }
}

export default SubmitButton;
