import React from "react";

const WebContainer = ({ children, style, type }) => {
  var container_type;
  switch (type) {
    default:
    case "small":
      container_type = "-small";
      break;

    case "large":
      container_type = "-large";
      break;

    case "xl":
      container_type = "-xl";
      break;

    case "2xl":
      container_type = "-2xl";
      break;
  }
  return (
    <div className={"deal-web-container" + container_type} style={style}>
      {children}
    </div>
  );
};

export { WebContainer };
