import React, { Component } from "react";
import { connect } from "react-redux";
import { Copy, KeyboardView, Wrapper, Row } from "app/NativeComponents/common";

import {
  AttentionBox,
  SmallHeader,
  SpinWrapper
} from "app/NativeComponents/snippets";

import moment from "moment";
import {
  loadNewsAndUpdates,
  updateNewDashboard,
  getDashboardStats,
  showSuccess
} from "app/NativeActions";
import NewsItem from "./NewsItem";
class NewsAndUpdates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_loading: true,
      contentful_content: null,
      loading_seen_slugs: true,
      news_slugs: [],
      seen_news_slugs: []
    };

    this.seenNewsSlug = this.seenNewsSlug.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    loadNewsAndUpdates()
      .then(data => {
        let slugs = [];
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items,
              contentful_loading: false
            });
          }

          //for all of the data items..get the slug_id from each one and put them in slugs
          data.items.map(item => {
            if (item.fields && item.fields.slug_id) {
              slugs.push(item.fields.slug_id);
            }
          });

          this.setState({
            news_slugs: slugs
          });
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.state.news_slugs) !==
      JSON.stringify(prevState.news_slugs)
    ) {
      this.getNotifications();
    }
  }

  seenNewsSlug(slug) {
    //check if slug is in seen_news_slugs
    if (!this.state.seen_news_slugs.includes(slug)) {
      this.setState(
        {
          seen_news_slugs: [...this.state.seen_news_slugs, slug]
        },
        () => {
          this.props.updateNewDashboard({
            token: this.props.token,
            type: "seen_news_and_updates_notification",
            slug: slug,
            onLoading: () => {},
            onError: () => {},
            onSuccess: () => {}
          });
        }
      );
    }
  }

  getNotifications() {
    this.props.getDashboardStats({
      token: this.props.token,
      type: "get_news_and_updates_notifications",
      slugs: this.state.news_slugs.join(","),
      onLoading: () => {},
      onError: error => {},
      onSuccess: results => {
        this.setState({
          loading_seen_slugs: false,
          seen_news_slugs: results?.seen_news_slugs
        });
      }
    });
  }

  render() {
    const { colors } = this.props;
    const { contentful_loading, contentful_content } = this.state;

    return (
      <>
        <Row>
          <SmallHeader title={"News & Updates:"} />
          {parseInt(this.state.news_slug?.length) -
            parseInt(this.state.seen_news_slugs?.length) >
            0 && !this.state.loading_seen_slugs ? (
            <Wrapper
              style={{
                borderRadius: 10,
                width: 20,
                height: 20,
                backgroundColor: colors.orange_color,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Copy
                style={{
                  color: colors.white_text_color,
                  fontSize: 14
                }}
              >
                {parseInt(this.state.news_slug?.length) -
                  parseInt(this.state.seen_news_slugs?.length)}
              </Copy>
            </Wrapper>
          ) : null}
        </Row>

        {contentful_loading ? (
          <SpinWrapper text={"Loading"} />
        ) : (
          <KeyboardView style={{ flex: 1 }}>
            {contentful_content &&
              contentful_content.length > 0 &&
              contentful_content.map((content, i) => {
                return (
                  <Wrapper key={"news_" + i}>
                    <NewsItem
                      seenNewsSlug={this.seenNewsSlug}
                      showSuccess={this.props.showSuccess}
                      loading_seen_slugs={this.state.loading_seen_slugs}
                      item={content.fields}
                      seen_news_slugs={this.state.seen_news_slugs}
                    />
                  </Wrapper>
                );
              })}
          </KeyboardView>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  updateNewDashboard,
  getDashboardStats,
  showSuccess
})(NewsAndUpdates);
