import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Container,
  KeyboardView,
  ContentfulRichText,
  Video,
  Copy,
  Bold,
  ExternalImage
} from "app/NativeComponents/common";
import {
  NewHeader,
  GhostButton,
  InlineButton,
  BottomNavBar,
  SelectItem,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  manageSubscription,
  loadUpsellModal,
  openUrl,
  displayIntercom,
  checkIfUserHasMetadata
} from "app/NativeActions";

import IAPPaywall from "app/NativeComponents/components/IAPPaywall";

import Addon from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/Addon";

class UnlockFeatureModal extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      panel_data,
      interval_toggle: props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month",
      plan_interval: props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month",
      contentful_data: null,
      contentful_loading: false,
      feature_upsell_info: panel_data?.feature_upsell_info,
      description: !!panel_data?.description ? panel_data.description : null,
      renderContent: panel_data?.renderContent
        ? panel_data.renderContent
        : null,
      buttonText: !!panel_data?.buttonText
        ? panel_data.buttonText
        : "Upgrade Now",
      confirmText: !!panel_data?.confirmText
        ? panel_data.confirmText
        : "Confirm?",
      onSuccess: panel_data?.onSuccess ? panel_data.onSuccess : null,
      upsell_loading: false,
      iap: panel_data?.iap,
      upsell_adapty_slug: panel_data?.upsell_adapty_slug
    };

    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    if (!this.state.iap) {
      this.getContentfulData();
    }
  }

  getContentfulData() {
    const { feature_upsell_info, interval_toggle } = this.state;
    const { source_of_truth } = this.props;
    if (!!feature_upsell_info?.upsell_contentful_slug) {
      this.setState({ contentful_loading: true }, () => {
        loadUpsellModal(feature_upsell_info?.upsell_contentful_slug)
          .then(data => {
            if (data && data.items) {
              if (data.items.length > 0) {
                this.setState({
                  contentful_data: data.items[0]
                    ? data.items[0].fields
                      ? data.items[0].fields
                      : null
                    : null,
                  contentful_loading: false
                });
              }
            }
            this.setState({ contentful_loading: false });
          })
          .catch(err => {
            this.setState({ contentful_loading: false });
          });
      });
    }
  }

  render() {
    this.props.user?.team_clearance_level;
    const {
      contentful_data,
      feature_upsell_info,
      interval_toggle,
      iap,
      upsell_adapty_slug
    } = this.state;
    const { source_of_truth, platform, colors } = this.props;

    if (iap) {
      return <IAPPaywall upsell_adapty_slug={upsell_adapty_slug} />;
    }

    if (platform === "ios") {
      return (
        <Container>
          <NewHeader
            title={"We're here for you."}
            leftButton={{
              icon: "arrow-back",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            {this.state.renderContent ? this.state.renderContent() : null}
            <AttentionBox
              description={
                "Go to DealMachine on the web to upgrade now."
              }
            />
            <InlineButton button_type={"full"} onPress={this.handleBack}>
              Dismiss
            </InlineButton>
          </KeyboardView>
        </Container>
      );
    }

    const plan = contentful_data?.planItem?.fields;

    return (
      <Container>
        <NewHeader
          title={contentful_data?.title}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        {plan ? (
          <>
            <KeyboardView style={{ flex: 1 }}>
              {this.state.renderContent ? (
                this.state.renderContent()
              ) : !!this.state.description ? (
                <Wrapper
                  style={{
                    padding: 25
                  }}
                >
                  <Copy>{this.state.description}</Copy>
                </Wrapper>
              ) : !!contentful_data?.content ? (
                <Wrapper
                  style={{
                    padding: 25
                  }}
                >
                  <ContentfulRichText richText={contentful_data.content} />
                </Wrapper>
              ) : null}

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <InlineButton
                  iconColor={
                    this.state.plan_interval == "year"
                      ? colors.success_color
                      : null
                  }
                  marketing_slug={
                    this.state.plan_interval == "year"
                      ? "unselected_annual_on_plan_selection"
                      : "selected_annual_on_plan_selection"
                  }
                  icon={
                    this.state.plan_interval == "year"
                      ? "toggle-on"
                      : "toggle-off"
                  }
                  style={{
                    backgroundColor:
                      this.state.plan_interval == "month"
                        ? colors.orange_color_muted
                        : colors.success_color_muted
                  }}
                  onPress={() => {
                    this.setState({
                      plan_interval:
                        this.state.plan_interval == "month" ? "year" : "month"
                    });
                  }}
                >
                  Save 17% with annual
                </InlineButton>
              </Wrapper>

              <Addon
                colors={this.props.colors}
                desktopMobile={this.props.desktopMobile}
                device={this.props.device}
                marketing_slug={plan?.marketing_event_slug}
                event_slug={feature_upsell_info?.slug}
                token={this.props.token}
                title={plan?.title}
                stripe_price_id_month={plan?.stripePriceIdMonthly}
                stripe_price_id_year={plan?.stripePriceIdYearly}
                featured={false}
                price={plan?.monthPrice}
                year_price={plan?.yearPrice}
                plan_interval={this.state.plan_interval}
                includes_items={plan?.includesItems ? plan.includesItems : null}
                description={plan?.description}
                subtext={plan?.subtext}
                manageSubscription={this.props.manageSubscription}
                onSuccess={() => {
                  if (this.state.onSuccess) {
                    this.state.onSuccess();
                  } else {
                    this.props.popSidePanel();
                  }
                }}
                offer_promo={plan?.promo}
                upsell={true}
                buttonText={this.state.buttonText}
                confirmText={this.state.confirmText}
              />
            </KeyboardView>
          </>
        ) : (
          <>
            <KeyboardView style={{ flex: 1 }}>
              {!!contentful_data?.image?.fields?.file?.url ? (
                <Wrapper
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <ExternalImage
                    style={{
                      resizeMode: "contain",
                      width: 400,
                      height: 120
                    }}
                    contain={true}
                    image={contentful_data?.image?.fields?.file?.url}
                  />
                </Wrapper>
              ) : null}
              {!!contentful_data?.video?.fields?.file?.url ? (
                <Wrapper
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Video
                    video={contentful_data?.video?.fields?.file?.url}
                    height={225}
                    width={400}
                    autoPlay={true}
                    controls={false}
                    muted={true}
                    loop={true}
                  />
                </Wrapper>
              ) : null}

              {!!contentful_data?.content ? (
                <Wrapper
                  style={{
                    padding: 25,
                    paddingTop:
                      !!contentful_data?.image?.fields?.file?.url ||
                      !!contentful_data?.video?.fields?.file?.url
                        ? 0
                        : 25
                  }}
                >
                  <ContentfulRichText richText={contentful_data.content} />
                </Wrapper>
              ) : null}
            </KeyboardView>
            {this.props.user?.team_clearance_level >= 2 ||
            this.props.user?.team_owner == 1 ? (
              <BottomNavBar>
                {feature_upsell_info?.upgrade_metadata ? (
                  <Wrapper
                    style={{
                      padding: 25
                    }}
                  >
                    <Copy>{contentful_data?.upgradeTitleAnnualAndMonthly}</Copy>
                  </Wrapper>
                ) : interval_toggle == "year" ? (
                  <>
                    {!!contentful_data?.upgradeTitleAnnually ||
                    !!contentful_data?.upgradeDescriptionAnnually ? (
                      <Wrapper
                        style={{
                          padding: 25,
                          paddingBottom:
                            !feature_upsell_info?.talk_to_sales &&
                            !feature_upsell_info?.intercom_button
                              ? 0
                              : 25
                        }}
                      >
                        {!!contentful_data?.upgradeTitleAnnually ? (
                          <>
                            <Copy>
                              <Bold>
                                {contentful_data?.upgradeTitleAnnually}
                              </Bold>
                            </Copy>
                          </>
                        ) : null}
                        {!!contentful_data?.upgradeDescriptionAnnually ? (
                          <>
                            <Copy>
                              {contentful_data?.upgradeDescriptionAnnually}
                            </Copy>
                          </>
                        ) : null}
                      </Wrapper>
                    ) : null}
                  </>
                ) : (
                  <>
                    {!!contentful_data?.upgradeTitle ||
                    !!contentful_data?.upgradeDescription ? (
                      <Wrapper
                        style={{
                          padding: 25,
                          paddingBottom:
                            !feature_upsell_info?.talk_to_sales &&
                            !feature_upsell_info?.intercom_button
                              ? 0
                              : 25
                        }}
                      >
                        {!!contentful_data?.upgradeTitle ? (
                          <>
                            <Copy>
                              <Bold>{contentful_data?.upgradeTitle}</Bold>
                            </Copy>
                          </>
                        ) : null}
                        {!!contentful_data?.upgradeDescription ? (
                          <>
                            <Copy>{contentful_data?.upgradeDescription}</Copy>
                          </>
                        ) : null}
                      </Wrapper>
                    ) : null}
                  </>
                )}

                {!!contentful_data?.upgradeButton ? (
                  <>
                    {!feature_upsell_info?.talk_to_sales &&
                    !feature_upsell_info?.intercom_button &&
                    !feature_upsell_info?.upgrade_metadata ? (
                      <SelectItem
                        select_type="toggle"
                        selected={interval_toggle == "year"}
                        onPress={() => {
                          this.setState({
                            interval_toggle:
                              interval_toggle == "year" ? "month" : "year"
                          });
                        }}
                      >
                        {!!contentful_data?.intervalToggle
                          ? contentful_data.intervalToggle
                          : "Save 17% (2 months free) by paying annually."}
                      </SelectItem>
                    ) : null}

                    {source_of_truth?.has_stripe_card_on_file &&
                    !source_of_truth?.uses_in_app_purchases ? (
                      <GhostButton
                        primary={true}
                        button_type="full"
                        loading={this.state.upsell_loading}
                        id={
                          feature_upsell_info?.intercom_button
                            ? "intercom_button"
                            : ""
                        }
                        onPress={() => {
                          if (feature_upsell_info?.talk_to_sales) {
                            openUrl("https://dealmachine.com/talk-to-sales");
                          } else if (feature_upsell_info?.intercom_button) {
                            displayIntercom();
                          } else {
                            const stripe_price_id =
                              interval_toggle == "year"
                                ? feature_upsell_info?.upsell_stripe_price_id_annually
                                : feature_upsell_info?.upsell_stripe_price_id_monthly;
                            this.props.manageSubscription({
                              token: this.props.token,
                              type: "add_to_subscription",
                              stripe_price_id,
                              onLoading: () => {
                                this.setState({
                                  upsell_loading: true
                                });
                              },

                              onError: () => {
                                this.setState({
                                  upsell_loading: false
                                });
                              },
                              onSuccess: () => {
                                this.setState(
                                  {
                                    upsell_loading: false
                                  },
                                  () => {
                                    this.handleBack();
                                  }
                                );
                              }
                            });
                          }
                        }}
                      >
                        {contentful_data?.upgradeButton}
                      </GhostButton>
                    ) : (
                      <GhostButton
                        primary={true}
                        button_type="full"
                        loading={this.state.upsell_loading}
                        id={"intercom_button"}
                        onPress={() => {
                          displayIntercom();
                        }}
                      >
                        Talk To Support
                      </GhostButton>
                    )}
                  </>
                ) : null}

                {!!contentful_data?.learnMoreButton &&
                !!contentful_data?.learnMoreButtonUrl ? (
                  <InlineButton
                    button_type="full"
                    onPress={() => {
                      openUrl(contentful_data?.learnMoreButtonUrl);
                    }}
                  >
                    {contentful_data?.learnMoreButton}
                  </InlineButton>
                ) : null}
              </BottomNavBar>
            ) : null}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device, platform, desktopMobile } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;

  return {
    token,
    user,
    device,
    platform,
    colors,
    source_of_truth,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  manageSubscription
})(UnlockFeatureModal);
