import React, { Component } from "react";

import { Header2, Header3 } from "app/NativeComponents/website/common";

class SectionSubtitle extends Component {
  render() {
    if (this.props.section.subTitle) {
      if (this.props.section.sectionStyle == "HeaderSection") {
        return <Header2>{this.props.section.subTitle}</Header2>;
      }

      return <Header3>{this.props.section.subTitle}</Header3>;
    }

    return <div />;
  }
}

export default SectionSubtitle;
