import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Icon,
  Button
} from "app/NativeComponents/common";

class SkipButton extends Component {
  render() {
    if (!this.props.completed_step) {
      return (
        <Wrapper
          style={{
            padding: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Row>
            <Button onPress={this.props.onPress}>
              <Row>
                <Copy style={{ marginRight: 5 }}>
                  <Bold>
                    {this.props.title ? this.props.title : "Next Step"}
                  </Bold>
                </Copy>
                <Icon
                  icon={this.props.icon ? this.props.icon : "arrow-forward"}
                  size={14}
                />
              </Row>
            </Button>
          </Row>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default SkipButton;
