import React, { Component } from "react";
import { connect } from "react-redux";

import {
  updateRouteTimer,
  updateMapLocation,
  toggleRegionChange,
  lockLocationTracking,
  setActiveDeal,
  removePin,
  updateTrackedRoute,
  updateCurrentRouteSection,
  getMyLocation
} from "app/NativeActions";

import moment from "moment";

class VirtualDriveTracker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      save_route: true,
      location_tracker_ready: false,
      in_background: false,
      started_background_tracker: false,
      last_tracked_location: null
    };

    this.trackRoute = this.trackRoute.bind(this);
  }

  startBackgroundTracker() {}

  componentDidMount() {}

  startRouteTimer(track_route) {
    if (track_route) {
      clearInterval(this._time_interval);
      this._time_interval = setInterval(() => {
        this.props.updateRouteTimer();
      }, 1000);
    }
  }

  breakCurrentRoute() {
    if (
      this.props.track_route &&
      !this.props.save_route_loading &&
      this.props.current_route_section
    ) {
      if (this.props.current_route) {
        if (this.props.current_route_section.coordinates.length > 0) {
          this.props.updateTrackedRoute({
            token: this.props.token,
            route_id: this.props.current_route.route_id,
            coordinates: this.props.current_route_section.coordinates,
            current_route: this.props.current_route
          });
        }
      }
    }
  }

  onLocation(location) {
    this.setState({
      last_tracked_location: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        heading: location.coords.heading
      }
    });

    if (this.props.lock_location_tracking && !this.state.in_background) {
      this.props.updateMapLocation({
        coordinates: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          heading: location.coords.heading
        }
      });
    }

    if (!this.state.in_background) {
      this.trackRoute({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      });
    }
  }

  trackRoute(coordinates) {
    if (
      this.props.track_route &&
      !this.props.save_route_loading &&
      this.props.current_route_section
    ) {
      this.props.updateCurrentRouteSection({
        current_route: this.props.current_route,
        coordinates: [
          ...this.props.current_route_section.coordinates,
          coordinates
        ]
      });

      if (this.props.current_route) {
        if (this.props.current_route_section.coordinates.length > 20) {
          this.props.updateTrackedRoute({
            token: this.props.token,
            route_id: this.props.current_route.route_id,
            coordinates: this.props.current_route_section.coordinates,
            current_route: this.props.current_route
          });
        }
      }
    }
  }

  startVirtualTracker() {
    this.startRouteTimer(this.props.track_route);
  }

  endVirtualTracker() {
    clearInterval(this._time_interval);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.track_route == false &&
      this.props.track_route == true &&
      this.props.track_route_type == "virtual"
    ) {
      this.startVirtualTracker();
    } else if (
      prevProps.track_route == true &&
      this.props.track_route == false &&
      this.props.track_route_type == "virtual"
    ) {
      this.endVirtualTracker();
    }

    if (
      this.props.track_route == true &&
      (this.props.street_view_coords?.latitude !==
        prevProps.street_view_coords?.latitude ||
        this.props.street_view_coords?.longitude !==
          prevProps.street_view_coords?.longitude)
    ) {
      this.trackRoute({
        latitude: this.props.street_view_coords?.latitude,
        longitude: this.props.street_view_coords?.longitude
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this._time_interval);
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ auth, native, property_map, map, route }) => {
  const { token, user } = auth;
  const { platform } = native;
  const { lock_location_tracking, locationAllowed } = map;

  const { street_view_coords } = property_map;

  const {
    track_route,
    current_route,
    save_route_loading,
    current_route_section,
    track_route_type
  } = route;

  return {
    token,
    user,
    platform,
    locationAllowed,
    lock_location_tracking,
    track_route,
    current_route,
    save_route_loading,
    current_route_section,
    track_route_type,
    street_view_coords
  };
};

export default connect(mapStateToProps, {
  updateRouteTimer,
  updateMapLocation,
  toggleRegionChange,
  lockLocationTracking,
  setActiveDeal,
  removePin,

  updateTrackedRoute,
  updateCurrentRouteSection
})(VirtualDriveTracker);
