import React, { Component } from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

class SocialShare extends Component {
  render() {
    return (
      <div className="dm-website-share-button-container">
        <EmailShareButton
          url={this.props.url}
          subject={this.props.title}
          body={this.props.description}
        >
          <EmailIcon size={42} round={true} />
        </EmailShareButton>
        <FacebookShareButton url={this.props.url} quote={this.props.title}>
          <FacebookIcon size={42} round={true} />
        </FacebookShareButton>
        <LinkedinShareButton
          url={this.props.url}
          title={this.props.title}
          description={this.props.title}
        >
          <LinkedinIcon size={42} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton url={this.props.url} title={this.props.title}>
          <TwitterIcon size={42} round={true} />
        </TwitterShareButton>
      </div>
    );
  }
}

export { SocialShare };
