import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Wrapper,
  Copy,
  Icon,
  Row,
  Bold
} from "app/NativeComponents/common";

import { SelectItem } from "app/NativeComponents/snippets";

import moment from "moment";
import { formatAddress } from "app/NativeActions";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

class SendEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_event: 0
    };
  }

  render() {
    const {
      user,
      colors,
      lead_mailer_campaign,
      campaign_step,
      mailing_addresses
    } = this.props;
    const { selected_event } = this.state;
    const {
      property,
      property: { deal },
      campaign_info,
      send_events
    } = lead_mailer_campaign;
    return (
      <>
        {send_events &&
          send_events.map((event, i) => {
            const sentOnce = event.send_count == "1" && deal.resend_freq == "0";
            if (
              (!campaign_step ||
                event.campaign_step == campaign_step?.step_order ||
                (!event.campaign_step && campaign_step?.step_order == 1)) &&
              ((user.pause_sending != 1 &&
                deal.paused != 1 &&
                deal.campaign_complete != 1) ||
                moment(event.date_to_send) <= moment()) &&
              !sentOnce
            ) {
              return (
                <Wrapper key={"event_" + i}>
                  <SelectItem
                    select_type={"icon"}
                    select_icon="event"
                    onPress={() => {
                      this.setState({
                        selected_event:
                          event.id == selected_event ? 0 : event.id
                      });
                    }}
                    label={
                      i == 0 && moment() >= moment(event.date_to_send)
                        ? "Up Next:"
                        : ""
                    }
                    description={
                      mailing_addresses.length > 1
                        ? "Sending to " +
                          mailing_addresses.length +
                          " mailing addresses"
                        : ""
                    }
                    icon={
                      selected_event == event.id
                        ? "keyboard-arrow-up"
                        : "keyboard-arrow-down"
                    }
                  >
                    {moment() >= moment(event.date_to_send)
                      ? user.pause_sending == 1
                        ? "Account mail sequences paused"
                        : deal.mail_failed_info && deal.mail_failed_info?.length
                        ? "Mailer pieces(s) stuck"
                        : deal.paused == 1
                        ? "Mail Sequence paused"
                        : deal.mailer_status == 2
                        ? moment(event.date_to_send).format("MMMM Do, YYYY")
                        : moment(event.date_to_send).format("MMMM Do, YYYY")
                      : moment(event.date_to_send).format("MMMM Do, YYYY")}
                  </SelectItem>

                  {selected_event == event.id ? (
                    mailing_addresses.length > 1 ? (
                      <>
                        {mailing_addresses?.map((address, i) => {
                          return (
                            <Wrapper
                              key={"address_" + i}
                              style={{
                                marginLeft: 50,
                                marginBottom: 0,
                                marginTop: 10
                              }}
                            >
                              <SelectItem
                                select_type="icon"
                                select_icon="place"
                                noPress={true}
                                description={
                                  formatAddress({
                                    address: {
                                      address: address.address,
                                      address2: address.address2,
                                      address_city: address.city,
                                      address_state: address.state,
                                      address_zip: address.zip
                                    }
                                  }).line2
                                }
                              >
                                {
                                  formatAddress({
                                    address: {
                                      address: address.address,
                                      address2: address.address2,
                                      address_city: address.city,
                                      address_state: address.state,
                                      address_zip: address.zip
                                    }
                                  }).line1
                                }
                              </SelectItem>
                            </Wrapper>
                          );
                        })}
                      </>
                    ) : (
                      <Wrapper style={{ marginLeft: 50, marginBottom: 25 }}>
                        <MailerFlipCard
                          mailer={event?.mailer}
                          scale={0.25}
                          back_scale={0.25}
                        />
                      </Wrapper>
                    )
                  ) : null}
                </Wrapper>
              );
            }
            return null;
          })}
      </>
    );
  }
}

export default SendEvents;
