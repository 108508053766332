import React, { Component } from "react";
import { Wrapper, Copy, Bold, Button } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";

class TriggerComponent extends Component {
  renderTriggerType(category) {
    switch (category) {
      case "leads":
      default:
        return "Lead-based";

      case "campaigns":
        return "Mail Sequence-based";

      case "lists":
        return "List-based";

      case "tasks":
        return "Task-based";
    }
  }
  render() {
    const {
      is_active,
      onPress,
      step,
      step_index,
      branch_index = null,
      branch_step_index = null,
      colors,
      workflow,
      active_step
    } = this.props;
    if (step) {
      return (
        <Button
          onPress={onPress}
          style={{
            borderColor: is_active ? colors.active_color : colors.border_color,
            backgroundColor: colors.card_color,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 5,
            margin: "auto",
            marginBottom: 0,
            marginTop: 25,
            width: 350
          }}
        >
          <Wrapper>
            <Wrapper
              style={{
                padding: 15,
                borderBottomColor: is_active
                  ? colors.active_color
                  : colors.border_color,
                borderBottomWidth: 1,
                borderBottomStyle: "solid"
              }}
            >
              <Copy>
                <Bold>
                  {this.renderTriggerType(workflow.category)} enrollment trigger
                </Bold>
              </Copy>
            </Wrapper>
            <Wrapper>
              {is_active ? (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Configuring...
                  </Copy>
                </Wrapper>
              ) : (step.data?.filters?.data &&
                  step.data?.filters?.data.length > 0) ||
                step.data?.only_enroll_manually ? (
                <Wrapper style={{ padding: 20 }}>
                  {step.data.only_enroll_manually ? (
                    <Wrapper>
                      <Copy>Lead is enrolled mannually.</Copy>
                    </Wrapper>
                  ) : (
                    <ShowcaseFilters
                      {...this.props}
                      style={{ padding: 0 }}
                      filters={step.data?.filters?.data}
                      andor_type={step.data?.filters?.andor_type}
                      editable={false}
                    />
                  )}
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    padding: 20,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    How do you want to trigger this automation?
                  </Copy>
                  <GhostButton
                    primary={true}
                    style={{ marginBottom: 0, marginTop: 10 }}
                    onPress={() => {
                      this.props.setActiveStep({
                        step,
                        step_index,
                        branch_index,
                        branch_step_index
                      });
                    }}
                  >
                    Set enrollment triggers
                  </GhostButton>
                </Wrapper>
              )}
            </Wrapper>
          </Wrapper>
        </Button>
      );
    }
    return null;
  }
}

export default TriggerComponent;
