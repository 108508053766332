import React, { Component } from "react";
import { Link } from "react-router-dom";

class FooterNavColumn1 extends Component {
  render() {
    return (
      <div className="dm-website-footer-nav">
        <ul>
          <li>
            <a id="intercom_button">Contact</a>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          {/*
          <li>
            <Link to="/case-studies">Case Studies</Link>
          </li>
          */}
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          {/*
          <li>
            <Link to="/recommendations">Recommendations</Link>
          </li>
          */}
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-of-service">Terms Of Service</Link>
          </li>

          <li>
            <Link to="/sign-up">Sign Up</Link>
          </li>
          <li>
            <Link to="/login">Log in</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default FooterNavColumn1;
