import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUser, getSourceOfTruth, startRedirect } from "app/NativeActions";

class RedirectToApp extends Component {
  componentDidMount() {
    this.props.startRedirect("/");
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user, init, loading } = auth;

  return {
    token,
    user,
    init,
    loading
  };
};

export default withRouter(
  connect(mapStateToProps, { getUser, getSourceOfTruth, startRedirect })(
    RedirectToApp
  )
);
