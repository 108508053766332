import {
  SET_SALES_PAGE_PREVIEW,
  SET_SALES_PAGE_CUSTOM_URL,
  SET_SALES_PAGE_HEADLINE,
  SET_SALES_PAGE_PROPERTY_DETAILS,
  SET_SALES_PAGE_PRICING_INFO,
  SET_SALES_PAGE_CONTACT_NAME,
  SET_SALES_PAGE_CONTACT_EMAIL,
  SET_SALES_PAGE_CONTACT_PHONE,
  SET_SALES_PAGE_CONTACT_COMPANY,
  SET_SALES_PAGE_FOR_SALE_BY,
  SET_SALES_PAGE_SHOW_PROPERTY_ADDRESS,
  SET_SALES_PAGE_IMAGES,
  SET_SALES_PAGE_HAS_HEADLINE,
  SET_SALES_PAGE_HAS_PROPERTY_DETAILS,
  SET_SALES_PAGE_HAS_PRICING_INFO,
  RESET_SALES_PAGE
} from "app/DealMachineCore/types";

export const setSalesPagePreview = preview => ({
  type: SET_SALES_PAGE_PREVIEW,
  payload: preview
});
export const setSalesPageCustomURL = url => ({
  type: SET_SALES_PAGE_CUSTOM_URL,
  payload: url
});
export const setSalesPageHeadline = headline => ({
  type: SET_SALES_PAGE_HEADLINE,
  payload: headline
});
export const setSalesPagePropertyDetails = details => ({
  type: SET_SALES_PAGE_PROPERTY_DETAILS,
  payload: details
});
export const setSalesPagePricingInfo = info => ({
  type: SET_SALES_PAGE_PRICING_INFO,
  payload: info
});
export const setSalesPageContactEmail = email => ({
  type: SET_SALES_PAGE_CONTACT_EMAIL,
  payload: email
});
export const setSalesPageContactPhone = phone => ({
  type: SET_SALES_PAGE_CONTACT_PHONE,
  payload: phone
});
export const setSalesPageContactName = name => ({
  type: SET_SALES_PAGE_CONTACT_NAME,
  payload: name
});
export const setSalesPageContactCompany = company => ({
  type: SET_SALES_PAGE_CONTACT_COMPANY,
  payload: company
});
export const setSalesPageForSaleBy = forSaleBy => ({
  type: SET_SALES_PAGE_FOR_SALE_BY,
  payload: forSaleBy
});
export const setSalesPageShowPropertyAddress = show => ({
  type: SET_SALES_PAGE_SHOW_PROPERTY_ADDRESS,
  payload: show
});
export const setSalesPageImages = (images = []) => ({
  type: SET_SALES_PAGE_IMAGES,
  payload: images
});
export const setSalesPageHasHeadline = hasHeadline => ({
  type: SET_SALES_PAGE_HAS_HEADLINE,
  payload: hasHeadline
});
export const setSalesPageHasPropertyDetails = hasPropertyDetails => ({
  type: SET_SALES_PAGE_HAS_PROPERTY_DETAILS,
  payload: hasPropertyDetails
});
export const setSalesPageHasPricingInfo = hasPricingInfo => ({
  type: SET_SALES_PAGE_HAS_PRICING_INFO,
  payload: hasPricingInfo
});
export const resetSalesPage = () => ({ type: RESET_SALES_PAGE });
