import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  List,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";
import { updateLead, getTeamMembers } from "app/NativeActions";

class AssignLeadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      search: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.show !== this.state.show &&
      !this.props.team_members_loading &&
      this.props.team_members?.length == 0
    ) {
      this.props.getTeamMembers({
        token: this.props.token,
        load_type: "replace",
        type: "accepted_members",
        begin: 0
      });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  updateLeadWithUser(option = null) {
    const {
      property: { deal }
    } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "assign_lead_to_member",
      deal_ids: deal.id,
      assign_to_id: !option ? 0 : option.id,
      no_loading: true,
      onLoading: () => {
        this.props.onLoading({
          loading: true,
          temp_value: !option ? { id: 0 } : option
        });
        this.setState({
          show: false
        });
      },
      onSuccess: results => {
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : this.props.property
          );
        }

        this.props.onLoading({
          loading: false,
          temp_value: null
        });
      },
      onError: () => {
        this.props.onLoading({ loading: false, temp_value: null });
        this.setState({
          show: true
        });
      }
    });
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      team_members,
      team_members_loading,
      style,
      property,
      property: { deal },
      popoverPlacement = "bottom",
      renderComponent = () => {},
      componentStyle = {}
    } = this.props;
    const { show } = this.state;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={350}
        popover_height={398}
        popover_title={"Assign lead owner for " + property.property_address}
        popoverSheetTop={"75%"}
        includeCloseButton={true}
        popoverPlacement={popoverPlacement}
        renderComponent={options => {
          return (
            <Wrapper wrapper_ref={this._popover} style={{ flex: 1 }}>
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {team_members_loading ? (
                <SpinWrapper text="Loading Team..." />
              ) : (
                <>
                  <Wrapper style={{ alignSelf: "stretch" }}>
                    <SearchBar
                      title="Search Team"
                      style={{ margin: 10 }}
                      onChange={value =>
                        this.setState({
                          search: value
                        })
                      }
                      value={this.state.search}
                    />
                  </Wrapper>

                  <List
                    style={{ alignSelf: "stretch" }}
                    items={this.filterOptions(team_members)}
                    listHeader={() => {
                      return (
                        <>
                          {property.deal?.assigned_user ? (
                            <SelectItem
                              icon={"keyboard-arrow-right"}
                              select_type="profile_pic"
                              user={"blank"}
                              selected={false}
                              onPress={() => {
                                this.updateLeadWithUser(null);
                              }}
                            >
                              Unassign
                            </SelectItem>
                          ) : null}
                        </>
                      );
                    }}
                    itemStructure={({ item, index }) => {
                      return (
                        <SelectItem
                          key={"option_" + index}
                          icon={"keyboard-arrow-right"}
                          select_type="profile_pic"
                          user={item}
                          selected={property.deal?.assigned_user?.id == item.id}
                          onPress={() => {
                            this.updateLeadWithUser(item);
                          }}
                        >
                          {item.name ? item.name : item.email}
                        </SelectItem>
                      );
                    }}
                  />
                </>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, team }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;
  const { team_members, team_members_loading } = team;

  return {
    token,
    user,
    isMobile,
    device,
    colors,
    team_members,
    team_members_loading
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getTeamMembers
})(AssignLeadButton);
