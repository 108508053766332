import React, { Component } from "react";
import { connect } from "react-redux";
import { Copy, Container } from "app/NativeComponents/common";

import ListBuilder from "app/DealMachineCore/components/ListBuilder";
import { getMarketingOnboardingData } from "app/NativeActions";
class ListBuilderDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
      location: null,
      latitude: null,
      longitude: null,

      list_builder_filters: null,
      list_builder_filter_title: "",
      loading_filters: false,
      loading_location: false
    };
  }

  componentDidMount() {
    const queryString = window?.location?.search;
    let currentUrlParams = null;
    if (!!queryString) {
      currentUrlParams = new URLSearchParams(queryString);
    }

    if (currentUrlParams) {
      for (const [key, value] of currentUrlParams) {
        if (key === "location") {
          this.setState({
            location: value
          });
        }

        if (key === "filter") {
          this.setState({
            filter: value
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const queryString = window?.location?.search;
    let currentUrlParams = null;

    if (!!queryString) {
      currentUrlParams = new URLSearchParams(queryString);
    }

    if (currentUrlParams) {
      for (const [key, value] of currentUrlParams) {
        if (key === "location" && value !== this.state.location) {
          this.setState({
            location: value
          });
        }

        if (key === "filter" && value !== this.state.filter) {
          this.setState({
            filter: value
          });
        }
      }
    }

    if (this.state.location !== prevState.location) {
      //send location to api and get lat and lng for list builder
      this.props.getMarketingOnboardingData({
        token: this.props.token,
        type: "list_builder_demo_location",
        location: this.state.location,
        onLoading: () => {
          this.setState({
            loading_location: true
          });
        },
        onError: () => {
          this.setState({
            loading_location: false
          });
        },
        onSuccess: ({ latitude, longitude }) => {
          this.setState({
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            loading_location: false
          });
        }
      });
    }

    if (this.state.filter !== prevState.filter) {
      //send filter to api and get filters for list builder
      this.props.getMarketingOnboardingData({
        token: this.props.token,
        type: "list_builder_demo_filters",
        filter: this.state.filter,

        onLoading: () => {
          this.setState({
            loading_filters: true
          });
        },
        onError: () => {
          this.setState({
            loading_filters: false
          });
        },
        onSuccess: ({ filters, title }) => {
          this.setState({
            list_builder_filters: filters,
            list_builder_filter_title: title,
            loading_filters: false
          });
        }
      });
    }
  }

  render() {
    return (
      <div className="dm-app-wrapper animated fadeIn deal-full-app">
        <meta name="robots" content="noindex,nofollow" />
        <Container>
          {!this.state.loading_filters && !this.state.loading_location ? (
            <ListBuilder
              demo={true}
              demo_filters={this.state.list_builder_filters}
              demo_filter_title={this.state.list_builder_filter_title}
              demo_latitude={this.state.latitude}
              demo_longitude={this.state.longitude}
            />
          ) : null}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;
  const { source_of_truth } = billing;
  const { colors } = settings;
  const { isMobile, signUpMobile, device, platform } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    signUpMobile,
    device,
    platform,
    source_of_truth
  };
};

export default connect(mapStateToProps, { getMarketingOnboardingData })(
  ListBuilderDemo
);
