import React, { Component } from "react";

import CardImage from "./CardImage";
import CardTitle from "./CardTitle";
import CardDescription from "./CardDescription";

class CardItem extends Component {
  render() {
    if (this.props.card) {
      return (
        <div className="dm-website-card">
          <CardImage card={this.props.card} />
          <div className="dm-website-card-body">
            <CardTitle card={this.props.card} />
            <CardDescription card={this.props.card} />
          </div>
        </div>
      );
    }

    return <div />;
  }
}

export { CardItem };
