import React, { Component } from "react";
import { GhostButton } from "app/NativeComponents/snippets";

class Buttons extends Component {
  render() {
    return (
      <GhostButton
        button_type="full"
        primary={true}
        onPress={() => this.props.saveUserInfo()}
        disabled={!this.props.checkIfNeedsToSave()}
        loading={this.props.loading}
      >
        Create Password
      </GhostButton>
    );
  }
}

export default Buttons;
