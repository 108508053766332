import {
  START_REDIRECT,
  SET_INITIAL_REDIRECT,
  END_REDIRECT,
  SET_NAVBAR,
  UPDATE_VIEWPORT,
  MOBILE_TOGGLE_DRAWER,
  SET_PLATFORM_TYPE,
  CHANGE_TAB,
  SET_TRACKING_EVENT,
  TRIGGER_INTERCOM,
  BYPASS_MOBILE_REDIRECT
} from "app/DealMachineCore/types";

import {
  iOSBrowserCheck,
  isMobilePhoneCheck
} from "app/NativeActions/WebActions";

var MobileDetect = require("mobile-detect");
var md = new MobileDetect(window.navigator.userAgent);

let web_platform = "desktop";

if (md.os() == "iOS" && md.tablet() == null) {
  web_platform = "ios";
} else if (md.os() == "AndroidOS" && md.tablet() == null) {
  web_platform = "android";
} else {
  web_platform = "desktop";
}

const INITIAL_STATE = {
  platform: "desktop",
  device: "desktop",
  web_platform: web_platform,
  isIphoneX: false,
  window_height: 0,
  window_width: 0,
  sidebar_width: 0,
  isMobile: false,
  dashboardMobile: false,
  desktopMobile: false,
  bypass_mobile_redirect: false,

  mobile_toggle_drawer: false,
  redirectTo: null,
  initialRedirect: null,
  iosBrowser: iOSBrowserCheck(),
  mobilePhoneBrowser: isMobilePhoneCheck(),

  navbar: {
    title: "",
    subtitle: "",
    backgroundColor: "#31cce5",
    leftButtonIcon: "",
    leftButtonAction: () => {},
    leftButtonColor: "#fff",
    rightButtonIcon: "",
    rightButtonText: "",
    rightButtonAction: () => {},
    rightButtonColor: "#fff",
    rightButtonIcon2: "",
    rightButtonText2: "",
    rightButtonAction2: () => {},
    rightButtonColor2: "#fff"
  },
  trigger_intercom: false,
  trackingEvent: null,
  in_background: false,
  keyboard_showing: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_REDIRECT:
      return {
        ...state,
        redirectTo: action.payload
      };
    case SET_INITIAL_REDIRECT:
      return {
        ...state,
        initialRedirect: action.payload
      };
    case BYPASS_MOBILE_REDIRECT:
      return {
        ...state,
        bypass_mobile_redirect: action.payload
      };
    case END_REDIRECT:
      return {
        ...state,
        redirectTo: null
      };
    case SET_NAVBAR:
      return {
        ...state,
        navbar: action.payload.navbar
      };

    case UPDATE_VIEWPORT:
      return {
        ...state,
        window_width: action.payload.width,
        /*mobile_toggle_drawer:
          state.mobile_toggle_drawer === false && action.payload.width > 1700
            ? true
            : state.mobile_toggle_drawer*/

        window_height: action.payload.height,
        sidebar_width: action.payload.sidebar_width,
        //isMobile: action.payload.width <= 767 ? true : false,
        //dashboardMobile: action.payload.width <= 1000 ? true : false,
        //signUpMobile: action.payload.width <= 1200 ? true : false,
        desktopMobile: action.payload?.width <= 767 ? true : false
      };
    case MOBILE_TOGGLE_DRAWER:
      return {
        ...state,
        mobile_toggle_drawer: action.payload
      };
    case CHANGE_TAB:
      return {
        ...state,
        mobile_toggle_drawer: state.isMobile
          ? false
          : state.mobile_toggle_drawer
      };
    case SET_PLATFORM_TYPE:
      return {
        ...state,
        web_platform: action.payload
      };

    case SET_TRACKING_EVENT:
      return {
        ...state,
        trackingEvent: action.payload
      };
    case TRIGGER_INTERCOM:
      return {
        ...state,
        trigger_intercom: action.payload
      };

    default:
      return state;
  }
};
