import React, { Component } from "react";

import { Row, Wrapper } from "app/NativeComponents/common";
import { InlineButton, SmallLabel } from "app/NativeComponents/snippets";
import { store } from "app/store";
import { numberWithCommas, updateMapFilters } from "app/NativeActions";

class BedsAndBaths extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start_building: false,
      list: null,
      editing_title: ""
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}
  render() {
    const colors = store.getState().settings.colors;
    const { beds_min, baths_min, use_beds_exact } = this.props;
    return (
      <>
        <SmallLabel>Number of bedrooms:</SmallLabel>
        <Row style={{ justifyContent: "center", padding: 15 }}>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: !this.props.beds_min
                ? colors.active_color
                : colors.border_color,
              borderRadius: 5
            }}
            selected={!beds_min}
            onPress={() => {
              this.props.updateMapFilters({
                beds_min: null
              });
            }}
          >
            Any
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                beds_min == 1 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={beds_min == 1}
            onPress={() => {
              this.props.updateMapFilters({
                beds_min: 1
              });
            }}
          >
            {use_beds_exact ? "1" : "1+"}
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                beds_min == 2 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={beds_min == 2}
            onPress={() => {
              this.props.updateMapFilters({
                beds_min: 2
              });
            }}
          >
            {use_beds_exact ? "2" : "2+"}
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                beds_min == 3 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={beds_min == 3}
            onPress={() => {
              this.props.updateMapFilters({
                beds_min: 3
              });
            }}
          >
            {use_beds_exact ? "3" : "3+"}
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                beds_min == 4 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={beds_min == 4}
            onPress={() => {
              this.props.updateMapFilters({
                beds_min: 4
              });
            }}
          >
            {use_beds_exact ? "4" : "4+"}
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                beds_min == 5 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={beds_min == 5}
            onPress={() => {
              this.props.updateMapFilters({
                beds_min: 5
              });
            }}
          >
            {use_beds_exact ? "5" : "5+"}
          </InlineButton>
        </Row>
        <InlineButton
          style={{}}
          button_type={"full"}
          onPress={() => {
            this.props.updateMapFilters({
              use_beds_exact: !use_beds_exact
            });
          }}
          icon={use_beds_exact ? "toggle-on" : "toggle-off"}
          iconColor={use_beds_exact ? colors.success_color : null}
        >
          Use exact match
        </InlineButton>
        <SmallLabel>Number of bathrooms:</SmallLabel>
        <Row style={{ justifyContent: "center", padding: 15 }}>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: !baths_min
                ? colors.active_color
                : colors.border_color,
              borderRadius: 5
            }}
            selected={!baths_min}
            onPress={() => {
              this.props.updateMapFilters({
                baths_min: null
              });
            }}
          >
            Any
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                baths_min == 1 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={baths_min == 1}
            onPress={() => {
              this.props.updateMapFilters({
                baths_min: 1
              });
            }}
          >
            1+
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                baths_min == 2 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={baths_min == 2}
            onPress={() => {
              this.props.updateMapFilters({
                baths_min: 2
              });
            }}
          >
            2+
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                baths_min == 3 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={baths_min == 3}
            onPress={() => {
              this.props.updateMapFilters({
                baths_min: 3
              });
            }}
          >
            3+
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                baths_min == 4 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={baths_min == 4}
            onPress={() => {
              this.props.updateMapFilters({
                baths_min: 4
              });
            }}
          >
            4+
          </InlineButton>
          <InlineButton
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor:
                baths_min == 5 ? colors.active_color : colors.border_color,
              borderRadius: 5
            }}
            selected={baths_min == 5}
            onPress={() => {
              this.props.updateMapFilters({
                baths_min: 5
              });
            }}
          >
            5+
          </InlineButton>
        </Row>
      </>
    );
  }
}

export default BedsAndBaths;
