import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  ExternalImage,
  Scroll,
  Title,
  Copy,
  Row,
  ProfilePic,
  HTMLMarkdown,
  Button,
  Icon,
  Gradient
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  InlineButton,
  NewHeader
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  changeTab,
  tabNavigation,
  renderDate
} from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";

class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {
      like_hovering: false,
      comment_hovering: false,
      scroll_header_opacity: 0,
      scrollOffset: 0
    };

    this.handleBack = this.handleBack.bind(this);

    this._scroll_view = React.createRef();
  }

  handleBack() {
    this.props.popSidePanel();
  }

  renderAttachment(post) {
    const { colors } = this.props;
    const { post_type, attachments } = post;
    if (attachments?.length > 0) {
      switch (post_type) {
        default:
        case "text":
          if (attachments?.length === 1) {
            switch (attachments[0].type) {
              default:
              case "image":
                return (
                  <Wrapper style={{ borderRadius: 0 }}>
                    <ExternalImage
                      style={{
                        height: 300,
                        flex: 1,
                        backgroundColor: colors?.gray_color
                      }}
                      image={attachments[0].url}
                    />
                  </Wrapper>
                );
            }
          }

          break;
      }
    }
  }

  render() {
    const { colors, device } = this.props;
    const { like_hovering, comment_hovering } = this.state;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    const post = panel_data?.post;

    const content =
      '<p>You need to get in contact with the owner of the perfect property you just found&nbsp;<a href="https://www.dealmachine.com/blog/how-to-go-driving-for-dollars-like-a-pro-feat.-dealmachine-ceo-david-lecko">driving for dollars</a>, but you have no idea where to find this information. This is where skip tracing becomes a valued tool in your real estate toolbox.&nbsp;</p>\n' +
      "<p>What is Skip Tracing?</p>\n" +
      "<p>You may have heard of a profession called Bounty Hunting (remember&nbsp;Dog the Bounty Hunter&nbsp;in the early 2000s?), where they&nbsp;track&nbsp;down individuals who have&nbsp;skipped&nbsp;bail. &nbsp;Therefore needing to find information (home address, phone number, etc.) about this person who doesn&apos;t want to be found.</p>\n" +
      "<p>What info can you get?</p>\n" +
      "<p>Depending on how you go about skip tracing you can find this information and more:</p>\n" +
      "<ul>\n" +
      "    <li>\n" +
      "        <p>Date of Birth</p>\n" +
      "    </li>\n" +
      "    <li>\n" +
      "        <p>Home addresses</p>\n" +
      "    </li>\n" +
      "    <li>\n" +
      "        <p>Phone numbers</p>\n" +
      "    </li>\n" +
      "    <li>\n" +
      "        <p>Email Addresses</p>\n" +
      "    </li>\n" +
      "    <li>\n" +
      "        <p>Tax Records</p>\n" +
      "    </li>\n" +
      "    <li>\n" +
      "        <p>Relatives</p>\n" +
      "    </li>\n" +
      "</ul>\n" +
      "<p>Can I get this info myself?</p>\n" +
      "<p>You could do it all yourself, google the name of the person and anything else you know about them. Guess what?!?! You&rsquo;re skip tracing! But, you&apos;re doing all the hard work yourself.</p>\n" +
      '<p>You could go to&nbsp;<a href="https://www.fiverr.com/?utm_source=google&utm_medium=cpc-brand&utm_campaign=G_US_Brand_BrandingDeskTop_Exact&utm_term=one-fiverr_(exact)&utm_content=AdID%5e549600552450%5eKeyword%5efiverr%5ePlacement%5e%5eDevice%5ec&caid=731898203&agid=43879774452&ad_id=549600552450&kw=fiverr&lpcat=br_general&show_join=true&cq_src=google_ads&cq_cmp=731898203&cq_term=fiverr&cq_plac=&cq_net=g&cq_plt=gp&gclid=CjwKCAjwyryUBhBSEiwAGN5OCMhMCXuisibtYDEbqVFDnTsFlOf99C1W5R8pA71MnmT8Uz1pxkVEShoCa60QAvD_BwE&gclsrc=aw.ds">Fiverr</a>-type sites that promise $0.03-$0.05 per lookup, those won&rsquo;t always be the most accurate, and it may take them a little while to get you the data back.</p>\n' +
      "<p>You could do a deep investigative report with a local private investigator. Those reports will usually be really accurate, with a ton of deep data on them, family members, etc., but you&rsquo;ll probably pay $10+ per lookup.&nbsp;</p>\n" +
      "<p>In an ideal world, you have something that balances accuracy and cost, while also being available quickly the moment you want that information.</p>\n" +
      '<p><a href="https://dealmachine.app.link/q2blog">DealMachine</a> offers instant one-off skip tracing in-app. We also scrub the phone numbers on the DNC (Do Not Call) List, indicate which lines are connected, and provide caller i.d. to save you more time when trying to get in contact with your leads. Try out our skip tracing today on&nbsp;<a href="https://dealmachine.app.link/q2blog">a free trial</a> with $20 worth of marketing credits.</p>\n' +
      "<p><br></p>";

    if (post) {
      return (
        <Container style={{ position: "relative" }}>
          <Scroll
            scroll_ref={this._scroll_view}
            onScroll={e => {
              if (this.props.device == "desktop") {
                this.setState({
                  scroll_header_opacity: this.state.expanded
                    ? 0
                    : this._scroll_view?.current?.scrollTop / 100 > 1
                    ? 1
                    : this._scroll_view?.current?.scrollTop / 100,
                  scrollOffset: this._scroll_view?.current?.scrollTop
                });
              } else {
                this.setState({
                  scroll_header_opacity: this.state.expanded
                    ? 0
                    : e?.nativeEvent?.contentOffset?.y / 100 > 1
                    ? 1
                    : e?.nativeEvent?.contentOffset?.y / 100,
                  scrollOffset: e?.nativeEvent?.contentOffset?.y
                });
              }
            }}
            style={{ flex: 1, paddingTop: !post.attachments ? 100 : 0 }}
          >
            {this.renderAttachment(post)}
            <Wrapper style={{ padding: 25 }}>
              <Title>{post?.title}</Title>
            </Wrapper>
            <Wrapper style={{ padding: 25, paddingTop: 0 }}>
              <Row>
                <Button onPress={() => {}}>
                  <Row style={{ marginRight: 10 }}>
                    <Icon
                      icon={"favorite"}
                      size={14}
                      style={{ marginRight: 5 }}
                    />
                    <Copy style={{ fontSize: 12 }}>14 Likes</Copy>
                  </Row>
                </Button>
                <Button onPress={() => {}}>
                  <Row>
                    <Icon icon={"forum"} size={14} style={{ marginRight: 5 }} />
                    <Copy style={{ fontSize: 12 }}>5 Comments</Copy>
                  </Row>
                </Button>
              </Row>
            </Wrapper>
            <Wrapper style={{ padding: 25, paddingTop: 0 }}>
              <Row>
                <ProfilePic
                  email={post?.author?.email}
                  image={post?.author?.image}
                  size={30}
                  style={{ marginRight: 5 }}
                />
                <Wrapper>
                  <Copy>
                    {post?.author?.firstname + " " + post?.author?.lastname}
                  </Copy>
                  <Copy
                    style={{ fontSize: 10, color: colors.light_text_color }}
                  >
                    {renderDate(post?.date_created)}
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
            <Wrapper
              className={"dm-community-content"}
              style={{ padding: 25, paddingTop: 0 }}
            >
              <HTMLMarkdown
                source={content}
                escapeHtml={false}
                fontStyle={{
                  color: colors.text_color,
                  fontSize: 14,
                  lineHeight: device == "mobile" ? 24 : "24px"
                }}
                style={{
                  color: colors.text_color,
                  fontSize: 14,
                  lineHeight: device == "mobile" ? 24 : "24px"
                }}
              />
            </Wrapper>
          </Scroll>

          {post?.attachments ? (
            <Wrapper
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "transparent"
              }}
            >
              <HeaderButton
                buttonContents={{
                  onPress: this.handleBack,
                  icon: "arrow-back",
                  color: colors.white_text_color,
                  backgroundColor: colors.hover_color
                }}
                style={{
                  marginTop: this.props.isIphoneX
                    ? 50
                    : this.props.platform == "ios"
                    ? 10
                    : 5,
                  backgroundColor: "rgba(0,0,0,0.25)"
                }}
              />
            </Wrapper>
          ) : (
            <>
              <Wrapper
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "transparent"
                }}
              >
                <HeaderButton
                  buttonContents={{
                    onPress: this.handleBack,
                    icon: "arrow-back",
                    color: "#ffffff",
                    backgroundColor: colors.hover_color
                  }}
                  style={{
                    marginTop: this.props.isIphoneX
                      ? 50
                      : this.props.platform == "ios"
                      ? 17
                      : 17,
                    backgroundColor: "rgba(0,0,0,0.25)",
                    opacity: this.state.scroll_header_opacity
                  }}
                />
              </Wrapper>
              <Wrapper
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  opacity: 1 - this.state.scroll_header_opacity
                }}
              >
                <NewHeader
                  leftButton={{
                    icon: "arrow-back",
                    onPress: this.handleBack
                  }}
                  style={{}}
                />
              </Wrapper>
            </>
          )}

          <BottomNavBar>
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <InlineButton
                  button_type={"full"}
                  onPress={() => {}}
                  icon={"favorite"}
                >
                  Like
                </InlineButton>
              </Wrapper>
              <Wrapper style={{ flex: 1 }}>
                <InlineButton
                  button_type={"full"}
                  onPress={() => {}}
                  icon={"forum"}
                >
                  Comment
                </InlineButton>
              </Wrapper>

              <Wrapper style={{ flex: 1 }}>
                <InlineButton
                  button_type={"full"}
                  onPress={() => {}}
                  icon={"share"}
                >
                  Share
                </InlineButton>
              </Wrapper>
            </Row>
          </BottomNavBar>
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, isIphoneX, platform } = native;
  const { colors, dark_mode } = settings;

  return { token, user, device, isIphoneX, platform, colors, dark_mode };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  changeTab,
  tabNavigation
})(Post);
