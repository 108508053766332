import React from "react";
import { PieChart, Pie, Cell } from "recharts";
const PieContainer = ({ data, width = 320, height = 320, fillColor }) => {
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        cx={"50%"}
        cy={"50%"}
        innerRadius={width / 6}
        outerRadius={width / 3}
        fill={fillColor}
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  );
};

export { PieContainer };
