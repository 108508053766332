import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Bold,
  Copy,
  KeyboardView,
  MultiRangeSlider
} from "app/NativeComponents/common";
import {
  InlineButton,
  InlineTabs,
  InputBox,
  PopoverMenu,
  BottomNavBar
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { numberWithCommas } from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";

class PriceRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_price_range: props.show_price_range
    };
    this.renderPriceRange = this.renderPriceRange.bind(this);
  }

  renderPriceRange(price) {
    if (!!price) {
      let label = price?.toString();

      let num = label.replace(/[^0-9]/g, "");
      num = parseInt(num);
      if (num === 0) {
        return "";
      }
      if (num >= 1000000) {
        label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      } else if (num >= 1000) {
        label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      }
      return label;
    }
    return price;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}
  render() {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const {
      value_range_max,
      value_range_min,
      drawing,
      drawing_coordinates,
      price_type
    } = this.props.map_filters;

    return (
      <>
        <PopoverMenu
          show={this.props.show_price_range}
          allow_multiple={false}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.props.updateMainState({
              show_price_range: s
            });
          }}
          popover_width={450}
          popoverSheetTop={this.props.keyboard_showing ? "85%" : 450}
          popoverPlacement={"bottom"}
          popover_title={"Price Range"}
          hideWithOutsideClick={false}
          disabled={
            this.state.select_drive_start ||
            (drawing && drawing_coordinates?.length <= 2)
          }
          includeCloseButton={false}
          renderTopButtons={
            device === "mobile"
              ? () => {
                  return (
                    <Row>
                      <InlineButton
                        disabled={!value_range_max && !value_range_min}
                        onPress={() => {
                          this.props.updateMapFilters({
                            value_range_max: "",
                            value_range_min: ""
                          });
                        }}
                        style={{ marginRight: 0 }}
                      >
                        Clear
                      </InlineButton>
                      <HeaderButton
                        buttonContents={{
                          onPress: () => {
                            this.props.updateMainState({
                              show_price_range: false
                            });
                          },
                          icon: "close",
                          color: colors.light_text_color,
                          hover_color: colors.hover_color
                        }}
                      />
                    </Row>
                  );
                }
              : null
          }
          renderComponent={options => {
            return (
              <InlineButton
                disabled={
                  this.state.select_drive_start ||
                  (drawing && drawing_coordinates?.length <= 2)
                }
                style={
                  !!value_range_max || !!value_range_min
                    ? {
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: colors.active_color,
                        borderStyle: "solid",
                        marginRight: 0,
                        ...this.props.style
                      }
                    : {
                        borderRadius: 5,
                        borderWidth: 0,
                        borderColor: colors.border_color,
                        borderStyle: "solid",
                        marginRight: 0,
                        ...this.props.style
                      }
                }
                selected={!!value_range_max || !!value_range_min}
                icon={"attach-money"}
                pressedIn={options?.hovering || options?.pressedIn}
                noPress={true}
              >
                {!!value_range_min && !value_range_max
                  ? "$" + this.renderPriceRange(value_range_min) + "+"
                  : !!value_range_max && !value_range_min
                  ? "Up to $" + this.renderPriceRange(value_range_max)
                  : !!value_range_max && !!value_range_min
                  ? "$" +
                    this.renderPriceRange(value_range_min) +
                    " - " +
                    "$" +
                    this.renderPriceRange(value_range_max)
                  : device === "desktop"
                  ? "Price"
                  : "Price Range"}
              </InlineButton>
            );
          }}
          renderMenu={() => {
            return (
              <>
                <Wrapper style={{ alignSelf: "stretch" }}>
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10
                    }}
                  >
                    <InlineTabs
                      selected_tab={price_type}
                      tabs={[
                        {
                          onPress: () => {
                            this.props.updateMapFilters({
                              price_type: "estimated_value"
                            });
                          },
                          title: "Estimated Value",
                          slug: "estimated_value"
                        },
                        {
                          onPress: () => {
                            this.props.updateMapFilters({
                              price_type: "listing_price"
                            });
                          },
                          title: "Listing Price",
                          slug: "listing_price"
                        },
                        {
                          onPress: () => {
                            this.props.updateMapFilters({
                              price_type: "sale_price"
                            });
                          },
                          title: "Sale Price",
                          slug: "sale_price"
                        }
                      ]}
                    />
                  </Wrapper>

                  {device === "mobile" ? (
                    <Wrapper
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10
                      }}
                    >
                      <MultiRangeSlider
                        values={[
                          value_range_min || 0,
                          value_range_max || 2000000
                        ]}
                        min={0}
                        max={2000000}
                        step={100000}
                        enableLabel={true}
                        customLabel={values => {
                          return (
                            <Wrapper
                              style={{
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <Copy>
                                <Bold>
                                  {!!values.oneMarkerValue &&
                                  (!values.twoMarkerValue ||
                                    values.twoMarkerValue >= 2000000)
                                    ? "$" +
                                      this.renderPriceRange(
                                        values.oneMarkerValue
                                      ) +
                                      "+"
                                    : !!values.twoMarkerValue &&
                                      values.twoMarkerValue < 2000000 &&
                                      !values.oneMarkerValue
                                    ? "Up to " +
                                      "$" +
                                      this.renderPriceRange(
                                        values.twoMarkerValue
                                      )
                                    : !!values.twoMarkerValue &&
                                      !!values.oneMarkerValue
                                    ? "$" +
                                      this.renderPriceRange(
                                        values.oneMarkerValue
                                      ) +
                                      " - " +
                                      "$" +
                                      this.renderPriceRange(
                                        values.twoMarkerValue
                                      )
                                    : "Any Price"}
                                </Bold>
                              </Copy>
                            </Wrapper>
                          );
                        }}
                        onChange={values => {
                          this.props.updateMapFilters({
                            value_range_min: values[0] === 0 ? "" : values[0],
                            value_range_max:
                              values[1] >= 2000000 ? "" : values[1]
                          });
                        }}
                        width={300}
                      />
                    </Wrapper>
                  ) : null}

                  <Row>
                    <InputBox
                      style={{ flex: 1 }}
                      ref={this._value_range_min_input}
                      name="value_range_min"
                      blurOnSubmit={true}
                      autoCapitalize="none"
                      returnKeyType={"done"}
                      keyboardType={"number-pad"}
                      placeholder="Minimum"
                      options={[
                        {
                          value: "",
                          label: "No Min"
                        },
                        {
                          value: "100000",
                          label: "$100k"
                        },
                        {
                          value: "200000",
                          label: "$200k"
                        },
                        {
                          value: "300000",
                          label: "$300k"
                        },
                        {
                          value: "400000",
                          label: "$400k"
                        },
                        {
                          value: "500000",
                          label: "$500k"
                        },
                        {
                          value: "600000",
                          label: "$600k"
                        },
                        {
                          value: "700000",
                          label: "$700k"
                        },
                        {
                          value: "800000",
                          label: "$800k"
                        },
                        {
                          value: "900000",
                          label: "$900k"
                        },
                        {
                          value: "1000000",
                          label: "$1M"
                        }
                      ]}
                      onChange={value => {
                        const numbers = parseInt(value.replace(/\D+/g, ""));

                        this.props.updateMapFilters({
                          value_range_min:
                            parseInt(numbers) === 0 || !numbers
                              ? ""
                              : parseInt(numbers)
                        });
                      }}
                      value={
                        !!value_range_min
                          ? "$" + numberWithCommas(value_range_min.toString())
                          : ""
                      }
                      onSubmitEditing={() => {}}
                      type={"text"}
                    />
                    <InputBox
                      style={{ flex: 1 }}
                      ref={this._value_range_max_input}
                      name="value_range_max"
                      blurOnSubmit={true}
                      autoCapitalize="none"
                      returnKeyType={"done"}
                      keyboardType={"number-pad"}
                      placeholder="Maximum"
                      options={[
                        {
                          value: "",
                          label: "No Max"
                        },
                        {
                          value: "100000",
                          label: "$100k"
                        },
                        {
                          value: "200000",
                          label: "$200k"
                        },
                        {
                          value: "300000",
                          label: "$300k"
                        },
                        {
                          value: "400000",
                          label: "$400k"
                        },
                        {
                          value: "500000",
                          label: "$500k"
                        },
                        {
                          value: "600000",
                          label: "$600k"
                        },
                        {
                          value: "700000",
                          label: "$700k"
                        },
                        {
                          value: "800000",
                          label: "$800k"
                        },
                        {
                          value: "900000",
                          label: "$900k"
                        },
                        {
                          value: "1000000",
                          label: "$1M"
                        }
                      ]}
                      onChange={value => {
                        const numbers = parseInt(value.replace(/\D+/g, ""));

                        this.props.updateMapFilters({
                          value_range_max:
                            parseInt(numbers) === 0 || !numbers
                              ? ""
                              : parseInt(numbers)
                        });
                      }}
                      value={
                        !!value_range_max
                          ? "$" + numberWithCommas(value_range_max.toString())
                          : ""
                      }
                      onSubmitEditing={() => {}}
                      type={"text"}
                    />
                  </Row>
                </Wrapper>
                <BottomNavBar
                  style={{
                    alignSelf: "stretch"
                  }}
                >
                  {device === "desktop" ? (
                    <InlineButton
                      button_type={"full"}
                      disabled={!value_range_max && !value_range_min}
                      onPress={() => {
                        this.props.updateMapFilters({
                          value_range_max: "",
                          value_range_min: ""
                        });
                      }}
                    >
                      Clear
                    </InlineButton>
                  ) : null}
                  {device === "mobile" ? (
                    <InlineButton
                      loading={this.props.properties_on_map_count_loading}
                      onPress={() => {
                        this.props.updateMainState({
                          show_price_range: false
                        });
                      }}
                      button_type={"full"}
                    >
                      {this.props.properties_on_map_count == 1
                        ? "View 1 Property"
                        : "View " +
                          numberWithCommas(this.props.properties_on_map_count) +
                          " Properties"}
                    </InlineButton>
                  ) : null}
                </BottomNavBar>
              </>
            );
          }}
          menu_items={null}
          popoverStyle={{ overflow: "visible" }}
        />
      </>
    );
  }
}

export default PriceRange;
