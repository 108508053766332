import React, { Component } from "react";

const Markdown = require("react-markdown");

class SectionFooterContent extends Component {
  render() {
    if (this.props.section.sectionFooterContent) {
      return (
        <Markdown
          source={this.props.section.sectionFooterContent}
          escapeHtml={false}
        />
      );
    }

    return <div />;
  }
}

export default SectionFooterContent;
