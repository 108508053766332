import React, { Component } from "react";
import {
  Button,
  Input,
  Row,
  Wrapper,
  Form,
  Icon,
  Copy,
  Scroll
} from "app/NativeComponents/common";
import {
  InlineButton,
  GhostButton,
  SelectItem
} from "app/NativeComponents/snippets";

import { store } from "app/store";

class InputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      focused: false
    };
    if (props.input_ref) {
      this._text_input = props.input_ref;
    } else {
      this._text_input = React.createRef();
    }
    this.focusInput = this.focusInput.bind(this);
  }

  focusInput() {
    if (
      this._text_input &&
      this._text_input.current &&
      this._text_input.current.focus
    ) {
      this._text_input.current.focus();
    }
  }
  componentWillUnmount() {
    clearInterval(this._blur_timeout);
  }

  render() {
    const {
      style,
      innerStyle,
      autoCorrect = false,
      autoFocus = false,
      autoCapitalize = "none",
      autoCompleteType,
      placeholder,
      disabled = false,
      type = "text",
      name,
      numberOfLines = 4, //used for multiline
      containerStyle = {},
      items = [],
      value = "",
      returnKeyType = "done",
      keyboardType = "default",
      onFocus = () => {},
      onBlur = () => {},
      onSubmitEditing = () => {},
      blurOnSubmit = true,
      maxLength = null,
      mask_type,
      onSubmit = () => {},
      submit_button_title = "Save",
      submit_button_icon = "",
      submit_button_primary = false,
      submit_disabled = false,
      loading = false,
      forceLabel = false,
      forceLabelPlaceholder,
      background_color = null,
      selected_background_color = null,
      hover_background_color = null,

      secondary_button,
      secondary_button_icon,
      onSecondaryPress = () => {},
      require_confirm = false,
      hover_icon,
      hover_fa_icon,
      has_changed = false,
      show_buttons = false,
      mask,
      renderLeft = null,

      icon = null,
      fa_icon = null,

      renderRight = null,
      renderBottom = null,
      renderTop = null,
      error = "",
      success = "",
      validationRequired = false,
      autoComplete = "off",
      textContentType,
      autogrow = false,
      options = []
    } = this.props;
    const colors = store.getState().settings.colors;
    const { hovering, focused } = this.state;
    const backgroundColor = background_color
      ? background_color
      : focused
      ? !!selected_background_color
        ? selected_background_color
        : (require_confirm && has_changed) || error
        ? colors.orange_color_muted
        : colors.active_color_muted
      : (require_confirm && has_changed) || error
      ? colors.orange_color_muted
      : hovering
      ? !!hover_background_color
        ? hover_background_color
        : colors.hover_color
      : "transparent";

    const hover_icon_color = colors.orange_color;
    const icon_color = colors.text_color;

    const device = store.getState().native.device;
    const renderInner = () => {
      return (
        <Button
          className="dm-input-box"
          onPress={() => {
            this.focusInput();
          }}
          style={{
            padding: 20,
            margin: 5,
            borderRadius: 5,
            //backgroundColor: "rgba(255, 255, 255, 0.5)",
            ...innerStyle
          }}
          onHover={() => {
            this.setState({
              hovering: true
            });
          }}
          onHoverOut={() => {
            this.setState({
              hovering: false
            });
          }}
        >
          <Row>
            {!!icon || fa_icon ? (
              <Icon
                size={18}
                color={icon_color}
                icon={icon}
                fa_icon={fa_icon}
                style={{ marginRight: 20 }}
              />
            ) : null}
            <Input
              input_style="dm-new-input"
              input_ref={this._text_input}
              name={name || "confirm_input"}
              returnKeyType={returnKeyType}
              blurOnSubmit={blurOnSubmit}
              autoCorrect={autoCorrect}
              autoFocus={autoFocus}
              autoCapitalize={autoCapitalize}
              autoCompleteType={autoCompleteType}
              keyboardType={keyboardType}
              placeholder={placeholder}
              mask={mask}
              onChange={value => this.props.onChange(value)}
              editable={!disabled && !loading}
              forceLabel={forceLabel}
              forceLabelPlaceholder={forceLabelPlaceholder}
              maxLength={maxLength ? parseInt(maxLength) : null}
              value={value}
              onSubmitEditing={onSubmitEditing}
              secureTextEntry={type == "password" ? true : false}
              onFocus={ev => {
                this.setState({
                  focused: true
                });
                onFocus(ev);
              }}
              onBlur={ev => {
                clearInterval(this._blur_timeout);
                this._blur_timeout = setTimeout(() => {
                  this.setState({
                    focused: false
                  });

                  onBlur(ev);
                }, 250);
              }}
              type={type}
              numberOfLines={numberOfLines}
              mask_type={this.props.mask_type}
              autoComplete={autoComplete}
              textContentType={textContentType}
              autogrow={autogrow}
            />
            {success ? (
              <Icon
                size={18}
                color={colors.success_color}
                icon={"check"}
                style={{ marginLeft: 20 }}
              />
            ) : (!!hover_icon || hover_fa_icon) &&
              hovering &&
              !focused &&
              !has_changed ? (
              <Icon
                size={18}
                color={hover_icon_color}
                icon={hover_icon}
                fa_icon={hover_fa_icon}
                style={{ marginLeft: 20 }}
              />
            ) : null}
          </Row>
        </Button>
      );
    };

    if (require_confirm) {
      return (
        <Wrapper style={{ backgroundColor, ...style }}>
          {renderTop ? renderTop() : null}
          <Row>
            {renderLeft ? renderLeft() : null}

            <Wrapper style={{ flex: 1 }}>{renderInner()}</Wrapper>
            {has_changed || show_buttons ? (
              <>
                {!!secondary_button && !loading ? (
                  <InlineButton
                    disabled={false}
                    icon={secondary_button_icon}
                    onPress={onSecondaryPress}
                  >
                    {secondary_button}
                  </InlineButton>
                ) : null}

                {submit_button_primary ? (
                  <GhostButton
                    primary={true}
                    disabled={!has_changed ? true : false}
                    icon={submit_button_icon}
                    onPress={onSubmit}
                    loading={loading}
                  >
                    {submit_button_title}
                  </GhostButton>
                ) : (
                  <InlineButton
                    disabled={!has_changed ? true : false}
                    icon={submit_button_icon}
                    onPress={onSubmit}
                    loading={loading}
                  >
                    {submit_button_title}
                  </InlineButton>
                )}
              </>
            ) : null}
          </Row>

          {renderBottom ? renderBottom() : null}
        </Wrapper>
      );
    }

    return (
      <Wrapper style={{ position: "relative", backgroundColor, ...style }}>
        {renderTop ? renderTop() : null}
        <Row>
          {renderLeft ? renderLeft() : null}

          <Wrapper style={{ flex: 1 }}>{renderInner()}</Wrapper>
          {renderRight ? renderRight() : null}
        </Row>
        {renderBottom ? (
          renderBottom()
        ) : !!error ? (
          <Copy
            style={{
              color: colors.orange_color,
              paddingRight: 20,
              paddingLeft: 20,
              paddingBottom: 5,
              marginTop: -23,
              fontSize: 10,
              lightHeight: 0
            }}
          >
            {error}
          </Copy>
        ) : null}

        {focused && options && options.length > 0 && device === "desktop" ? (
          <Wrapper
            style={{
              position: "absolute",
              top: 59,
              height: 200,
              left: 0,
              right: 0,
              zIndex: 2,
              backgroundColor: focused ? backgroundColor : colors.card_color,
              borderBottomRightRadius: 5,
              borderBottomLeftRadius: 5,
              overflow: "hidden",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.border_color,
              borderTopWidth: 0
            }}
          >
            <Scroll>
              {options.map((item, index) => {
                return (
                  <SelectItem
                    select_type={"none"}
                    selected={value === item.value}
                    onPress={() => {
                      if (this._text_input?.current?.blur) {
                        this._text_input.current.blur();
                      }
                      this.props.onChange(item.value);
                    }}
                  >
                    {item.label}
                  </SelectItem>
                );
              })}
            </Scroll>
          </Wrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export { InputBox };
