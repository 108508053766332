import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GhostButton,
  SelectItem,
  UpsellButton,
} from "app/NativeComponents/snippets";
import {
  setStartMailerCampaignPage,
  pushSidePanel,
  numberWithCommas,
} from "app/NativeActions";
class AssignToCampaignButton extends Component {
  assignToCampaign() {
    const {
      selected_all,
      selected_leads,
      token,
      user,
      filtered_count,
      lead_filters,
    } = this.props;

    this.props.setStartMailerCampaignPage({
      title: "Select Mail Sequence",
      subtitle: selected_all
        ? numberWithCommas(filtered_count) + " Leads"
        : numberWithCommas(selected_leads.length) + " Leads",
      new_filters:
        this.props.applied_filter && this.props.applied_filter.filters
          ? this.props.applied_filter?.filters
          : null,
      filters:
        this.props.applied_filter && this.props.applied_filter.old_filters
          ? this.props.applied_filter?.old_filters
          : null,
      property_flags: this.props.lead_filters?.property_flags
        .map(({ value }) => value)
        .join(","),
      property_flags_and_or: this.props.lead_filters?.property_flags_and_or,
      search: this.props.search,
      search_type: this.props.search_type,
      list_id: this.props.active_list_tab?.id
        ? this.props.active_list_tab?.id
        : this.props.active_list_tab,
      list_history_id: this.props.list_history_id || "",
      lead_ids: selected_all
        ? ""
        : selected_leads
            .map((property) => {
              return property.deal.id;
            })
            .join(),
      select_all: selected_all ? 1 : 0,
      number_of_leads: selected_all ? filtered_count : selected_leads.length,
      onSuccess: () => {
        this.props.clearAll();
      },
    });
    this.props.pushSidePanel({ slug: "start_mail_campaign", overlay: true });
    if (this.props.toggleMenu) {
      this.props.toggleMenu(false);
    }
  }

  render() {
    const {
      action_button,
      is_list,
      is_actionable,
      user,
      button_search,
      title,
    } = this.props;

    const has_permission =
      user.team_clearance_level > 1 || user.can_approve_mail == 1;
    if (action_button) {
      return (
        <UpsellButton
          meta_slug="mail"
          onPress={() => this.assignToCampaign()}
          renderLockedChildren={({ hovering }) => {
            return (
              <>
                <GhostButton
                  style={{ marginRight: 0 }}
                  noPress={true}
                  pressedIn={hovering}
                  tooltip={
                    !has_permission
                      ? "You do not have permission to use this function."
                      : ""
                  }
                  disabled={!has_permission || !is_actionable}
                >
                  Start Mail
                </GhostButton>
              </>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <>
                <GhostButton
                  style={{ marginRight: 0 }}
                  onPress={() => this.assignToCampaign()}
                  tooltip={
                    !has_permission
                      ? "You do not have permission to use this function."
                      : ""
                  }
                  disabled={!has_permission || !is_actionable}
                  pressedIn={hovering}
                >
                  Start Mail
                </GhostButton>
              </>
            );
          }}
        />
      );
    }
    if (has_permission) {
      if (
        !button_search ||
        title.toLowerCase().includes(button_search.toLowerCase())
      ) {
        return (
          <UpsellButton
            meta_slug="mail"
            onPress={() => this.assignToCampaign()}
            renderLockedChildren={({ hovering }) => {
              return (
                <>
                  <SelectItem
                    select_type="none"
                    icon="keyboard-arrow-right"
                    noPress={true}
                    pressedIn={hovering}
                  >
                    Start Mail
                  </SelectItem>
                </>
              );
            }}
            renderChildren={({ hovering }) => {
              return (
                <>
                  <SelectItem
                    select_type="none"
                    icon="keyboard-arrow-right"
                    noPress={true}
                    pressedIn={hovering}
                  >
                    Start Mail
                  </SelectItem>
                </>
              );
            }}
          />
        );
      }
    }
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;
  return {
    token,
    user,
  };
};

export default connect(mapStateToProps, {
  setStartMailerCampaignPage,
  pushSidePanel,
})(AssignToCampaignButton);
