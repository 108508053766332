import React, { Component } from "react";
import {
  Wrapper,
  Spin,
  Copy,
  Bold,
  Row,
  Button,
  Scroll,
  Input,
  Icon
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  SelectItem,
  InlineButton,
  SpinWrapper
} from "app/NativeComponents/snippets";
import { store } from "app/store";
class BracketPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      search: ""
    };
  }

  filterVariables(data = []) {
    const { search } = this.state;
    let filtered_data = [];
    for (const [key, value] of Object.entries(data)) {
      if (!!value.label && value.type !== "object_array" && value.type !== "flattened_object_collection") {
        if (value.label?.toLowerCase().indexOf(search?.toLowerCase()) != -1) {
          filtered_data.push({
            ...value,
            key
          });
        }
      }
    }

    return filtered_data;
  }

  render() {
    const { style, enrollment_object, enrollment_object_loading } = this.props;
    const { show, search } = this.state;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;

    let filtered_data = this.filterVariables(enrollment_object?.data);

    if (enrollment_object_loading) {
      return (
        <Wrapper
          style={{
            padding: 15,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spin text="Loading..." />
        </Wrapper>
      );
    }

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        tooltip={"Insert Variable"}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={450}
        renderComponent={({ pressedIn, hovering }) => {
          return (
            <InlineButton noPress={true} pressedIn={pressedIn || hovering}>
              <Bold>{"{}"}</Bold>
            </InlineButton>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {enrollment_object_loading ? (
                <SpinWrapper text="Loading..." />
              ) : (
                <Wrapper
                  style={{
                    alignSelf: "stretch"
                  }}
                >
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>
                      <Bold>Select a variable to insert:</Bold>
                    </Copy>
                  </Wrapper>

                  <SearchBar
                    title="Search Variables"
                    onChange={value => {
                      this.setState({
                        search: value
                      });
                    }}
                    value={search}
                    style={{ margin: 10, marginBottom: 0 }}
                  />
                  <Scroll
                    style={{
                      maxHeight: 400
                    }}
                  >
                    {filtered_data.length == 0 ? (
                      <Wrapper
                        style={{
                          padding: 25,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          Nothing to display
                        </Copy>
                      </Wrapper>
                    ) : (
                      filtered_data.map((item, i) => {
                        return (
                          <SelectItem
                            select_type={"none"}
                            icon={"keyboard-arrow-right"}
                            key={"item_" + i}
                            onPress={() => {
                              this.props.onSelect(item);
                              this.setState({ show: false });
                            }}
                            description={"{{" + item.key + "}}"}
                          >
                            {item.label}
                          </SelectItem>
                        );
                      })
                    )}
                  </Scroll>
                </Wrapper>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}

export default BracketPicker;
