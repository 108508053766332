import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  ModalOverlay,
  Animation,
  RightPanelModal,
  KeyboardView,
  Wrapper,
  CardBody,
  Copy,
  Title
} from "app/NativeComponents/common";
import { Header, NewHeader } from "app/NativeComponents/snippets";
import ConfirmInvoice from "app/DealMachineCore/components/StartMailerCampaignModal/ConfirmInvoice";
import { setSkipTracePage, popSidePanel, updateLead } from "app/NativeActions";

class SkipTraceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auto_reload: props.user?.team_default_credit_reload > 0 ? true : false
    };
    this.toggleCreditReload = this.toggleCreditReload.bind(this);
  }

  toggleCreditReload(auto_reload) {
    this.setState({
      auto_reload
    });
  }

  renderBody() {
    const { skip_trace_page, colors, user, isMobile } = this.props;

    return (
      <>
        <NewHeader
          title={skip_trace_page.title}
          subtitle={skip_trace_page.subtitle}
          leftButton={{
            onPress: () => {
              this.props.popSidePanel();
            },
            icon: isMobile ? "arrow-back" : "close"
          }}
        />
        <ConfirmInvoice
          invoice_type="skip_trace"
          confirm_button={
            skip_trace_page.number_of_leads > 1
              ? "Skip Trace Leads"
              : "Skip Trace Lead"
          }
          onConfirm={() => {
            if (skip_trace_page.onSuccess) {
              skip_trace_page.onSuccess();
            }
            this.props.popSidePanel();
          }}
          selected_lead={skip_trace_page.selected_lead}
          number_of_leads={skip_trace_page.number_of_leads}
          lead_ids={skip_trace_page.lead_ids}
          select_all={skip_trace_page.select_all}
          list_history_id={skip_trace_page.list_history_id}
          new_filters={skip_trace_page.new_filters}
          filters={skip_trace_page.filters}
          list_id={skip_trace_page.list_id}
          search={skip_trace_page.search}
          user={user}
          auto_reload={this.state.auto_reload}
          toggleCreditReload={this.toggleCreditReload}
        />
      </>
    );
  }

  render() {
    const { right_panel, skip_trace_page, colors, user } = this.props;
    if (skip_trace_page) {
      return (
        <Container
          style={{
            display: "flex",
            alignSelf: "stretch",
            backgroundColor: this.props.colors.card_color
          }}
        >
          {this.renderBody()}
        </Container>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, lead }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { skip_trace_page } = lead;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    skip_trace_page
  };
};

export default connect(mapStateToProps, {
  setSkipTracePage,
  popSidePanel,
  updateLead
})(SkipTraceModal);
