import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Container,
  KeyboardView,
  ContentfulRichText,
  Video,
  Copy,
  Bold,
  ExternalImage,
  Icon
} from "app/NativeComponents/common";
import {
  NewHeader,
  SmallLabel,
  GhostButton,
  InlineButton,
  BottomNavBar,
  SelectItem,
  SpinWrapper,
  CircleButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getTeamReferralInfo,
  showSuccess,
  copyToClipBoard,
  loadUpsellModal,
  renderPrice
} from "app/NativeActions";

import NativeShareButtons from "app/NativeComponents/components/NativeShareButtons";

class ShareAndEarn extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      panel_data,
      loading: false,
      promo_info: null,
      contentful_data: null,
      contentful_loading: false
    };

    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getContentfulData();

    this.props.getTeamReferralInfo({
      token: this.props.token,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          promo_info: results.promo_info,
          loading: false
        });
      }
    });
  }

  getContentfulData() {
    this.setState({ contentful_loading: true }, () => {
      loadUpsellModal("share_and_earn")
        .then(data => {
          if (data && data.items) {
            if (data.items.length > 0) {
              this.setState({
                contentful_data: data.items[0]
                  ? data.items[0].fields
                    ? data.items[0].fields
                    : null
                  : null,
                contentful_loading: false
              });
            }
          }
          this.setState({ contentful_loading: false });
        })
        .catch(err => {
          this.setState({ contentful_loading: false });
        });
    });
  }

  render() {
    const { contentful_data, loading, promo_info } = this.state;
    const { colors } = this.props;

    return (
      <Container>
        <NewHeader
          title={contentful_data?.title}
          leftButton={{
            icon: "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {loading ? (
            <SpinWrapper text="Loading" />
          ) : (
            <>
              {!!promo_info?.promo ? (
                <>
                  <Wrapper style={{ padding: 25 }}>
                    <ContentfulRichText richText={contentful_data?.content} />
                    <Copy>
                      <Bold>
                        Get {renderPrice(promo_info?.amount_in_cents)} in
                        marketing credit when someone completes a trial with
                        your referral code.
                      </Bold>
                    </Copy>
                  </Wrapper>
                  <SelectItem
                    select_type="none"
                    noPress={true}
                    label={"My referral code:"}
                    renderRight={() => {
                      return (
                        <InlineButton
                          tooltip="Copy referral link"
                          onPress={() => {
                            copyToClipBoard(promo_info?.dynamic_link);
                            this.props.showSuccess(
                              promo_info?.dynamic_link +
                                " was copied to your clipboard",
                              "Copied"
                            );
                          }}
                          icon="content-copy"
                        >
                          Copy Link
                        </InlineButton>
                      );
                    }}
                  >
                    {promo_info?.promo}
                  </SelectItem>

                  <NativeShareButtons
                    url={promo_info?.dynamic_link}
                    amount={promo_info?.amount_in_cents}
                    promo={promo_info?.promo}
                  />
                </>
              ) : null}
            </>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getTeamReferralInfo,
  showSuccess
})(ShareAndEarn);
