import React, { Component } from "react";

import Calendar from "react-calendar";
import "app/assets/css/calendar.css";

import moment from "moment";
import { store } from "app/store";
class EventCalendar extends Component {
  renderDots(calendar_event, dot_type) {
    switch (dot_type) {
      case "total_mailers_in_queue":
        if (calendar_event.total_mailers_in_queue > 0) {
          return <div className="dm-total-mailers-in-queue-dot"></div>;
        }
        break;

      case "total_mailers_to_send":
        if (calendar_event.total_mailers_to_send > 0) {
          return <div className="dm-total-mailers-to-send-dot"></div>;
        }
        break;

      case "total_mailers_sent":
        if (calendar_event.total_mailers_sent > 0) {
          return <div className="dm-total-mailers-sent-dot"></div>;
        }
        break;
      default:
        break;
    }
  }

  render() {
    const dark_mode = store.getState().settings.dark_mode;

    return (
      <div
        className={dark_mode === "dark_mode" ? "dark_mode" : ""}
        style={{ margin: "0px auto" }}
      >
        <Calendar
          onActiveStartDateChange={({ activeStartDate, view }) => {
            if (view === "month") {
              this.props.onMonthChange(activeStartDate);
            }
          }}
          onChange={(value, event) => {
            this.props.onDateChange(value);
          }}
          calendarType="US"
          selectRange={this.props.is_selecting_range}
          value={
            this.props.is_selecting_range
              ? this.props.range_selected_dates.length > 0
                ? this.props.range_selected_dates.map(date => {
                    return moment(date).toDate();
                  })
                : null
              : this.props.selected_date
              ? moment(this.props.selected_date).toDate()
              : null
          }
          tileContent={({ activeStartDate, date, view }) => {
            if (view === "month") {
              const { calendar_events } = this.props;
              for (let i = 0; i < calendar_events.length; i++) {
                if (
                  moment(date).format("YYYY-MM-DD") ===
                  calendar_events[i].calendar_date
                ) {
                  return (
                    <div className="dm-calendar-dot-container">
                      {this.renderDots(
                        calendar_events[i],
                        "total_mailers_in_queue"
                      )}
                      {this.renderDots(
                        calendar_events[i],
                        "total_mailers_to_send"
                      )}
                      {this.renderDots(
                        calendar_events[i],
                        "total_mailers_sent"
                      )}
                    </div>
                  );
                }
              }

              return (
                <div className="dm-calendar-dot-container">
                  <div className="dm-transparent-dot"></div>
                </div>
              );
            }
            return null;
          }}
        />
      </div>
    );
  }
}

export { EventCalendar };
