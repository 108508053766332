import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  Row,
  Copy,
  KeyboardView,
  Icon
} from "app/NativeComponents/common";

import {
  SpinWrapper,
  InlineButton,
  InlineTabs,
  SmallLabel,
  GhostButton,
  EventCalendar,
  SelectItem,
  IconButton,
  UpsellButton,
  BottomNavBar,
  PopoverMenu
} from "app/NativeComponents/snippets";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";
import StatBlock from "./StatBlock";
import SentMail from "./SentMail";
import ScheduledEvents from "./ScheduledEvents";
import {
  pushSidePanel,
  tabNavigation,
  getCalendar,
  renderPrice,
  getSendingQueueCounts,
  numberWithCommas,
  getMailerTemplates,
  getMailerCampaigns,
  displayIntercom,
  changeTab
} from "app/NativeActions";
import moment from "moment";

class MailCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendar_loading: false,
      calendar_events: [],
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),

      selected_date: null,

      is_selecting_range: false,
      range_selected_dates: [],
      tab: "scheduled_events",
      selected_templates_and_sequences: [],
      total_mailers_in_queue: 0,
      total_mailers_to_send: 0,
      total_mailers_sent: 0,
      total_dealcredit_to_spend: 0,

      mail_center_totals: null,
      totals_loading: false,
      default_bracket_variables: null,
      mail_sequence_count: 0,
      mailer_templates_count: 0,
      show_calendar: false
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.getCalendarEvents = this.getCalendarEvents.bind(this);

    this.onFilterConfirm = this.onFilterConfirm.bind(this);

    this.getMailCenterTotals = this.getMailCenterTotals.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      this.props.changeTab("mail");
    }
    this.props.getSendingQueueCounts({
      token: this.props.token
    });

    this.getMailCenterTotals();

    this.getCalendarEvents({
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      load_type: "refresh"
    });

    this.props.getMailerTemplates({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          default_bracket_variables: results?.default_bracket_variables,
          mailer_templates_count:
            results?.mailer_template_count || this.state.mailer_template_count
        });
      }
    });

    this.props.getMailerCampaigns({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          mail_sequence_count:
            results.mailer_campaign_count || this.state.mail_sequence_count
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_date !== this.state.start_date ||
      prevState.end_date !== this.state.end_date ||
      JSON.stringify(prevState.selected_templates_and_sequences) !==
        JSON.stringify(this.state.selected_templates_and_sequences)
    ) {
      this.getCalendarEvents({
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        load_type: "refresh"
      });
    }

    if (
      JSON.stringify(prevState.selected_templates_and_sequences) !==
      JSON.stringify(this.state.selected_templates_and_sequences)
    ) {
      this.getMailCenterTotals();
    }

    if (
      JSON.stringify(prevState.calendar_events) !==
        JSON.stringify(this.state.calendar_events) ||
      prevState.range_selected_dates !== this.state.range_selected_dates ||
      prevState.is_selecting_range !== this.state.is_selecting_range ||
      prevState.start_date !== this.state.start_date ||
      prevState.end_date !== this.state.end_date ||
      prevState.selected_date !== this.state.selected_date
    ) {
      this.generateAmounts();
    }
  }

  generateAmounts() {
    let total_mailers_in_queue = 0;
    let total_mailers_to_send = 0;
    let total_mailers_sent = 0;
    let total_dealcredit_to_spend = 0;

    const {
      is_selecting_range,
      range_selected_dates,
      selected_date,
      start_date,
      end_date,
      calendar_events
    } = this.state;
    if (
      (is_selecting_range && range_selected_dates.length == 0) ||
      (!is_selecting_range && selected_date == null)
    ) {
      for (
        let m = moment(start_date);
        m.isBefore(moment(end_date).add(1, "days"));
        m.add(1, "days")
      ) {
        for (let i = 0; i < calendar_events.length; i++) {
          if (
            calendar_events[i].calendar_date == moment(m).format("YYYY-MM-DD")
          ) {
            total_mailers_in_queue =
              total_mailers_in_queue +
              calendar_events[i].total_mailers_in_queue;
            total_mailers_to_send =
              total_mailers_to_send + calendar_events[i].total_mailers_to_send;
            total_mailers_sent =
              total_mailers_sent + calendar_events[i].total_mailers_sent;
            total_dealcredit_to_spend =
              total_dealcredit_to_spend +
              calendar_events[i].total_dealcredit_to_spend;
          }
        }
      }

      this.setState({
        total_mailers_in_queue: total_mailers_in_queue,
        total_mailers_to_send: total_mailers_to_send,
        total_mailers_sent: total_mailers_sent,
        total_dealcredit_to_spend: total_dealcredit_to_spend
      });
    } else {
      if (is_selecting_range && range_selected_dates.length == 2) {
        let total_mailers_in_queue = 0;
        let total_mailers_to_send = 0;
        let total_mailers_sent = 0;
        let total_dealcredit_to_spend = 0;

        for (
          let m = moment(range_selected_dates[0]);
          m.isBefore(moment(range_selected_dates[1]).add(1, "days"));
          m.add(1, "days")
        ) {
          for (let i = 0; i < calendar_events.length; i++) {
            if (
              calendar_events[i].calendar_date == moment(m).format("YYYY-MM-DD")
            ) {
              total_mailers_in_queue =
                total_mailers_in_queue +
                calendar_events[i].total_mailers_in_queue;
              total_mailers_to_send =
                total_mailers_to_send +
                calendar_events[i].total_mailers_to_send;
              total_mailers_sent =
                total_mailers_sent + calendar_events[i].total_mailers_sent;
              total_dealcredit_to_spend =
                total_dealcredit_to_spend +
                calendar_events[i].total_dealcredit_to_spend;
            }
          }
        }

        this.setState({
          total_mailers_in_queue: total_mailers_in_queue,
          total_mailers_to_send: total_mailers_to_send,
          total_mailers_sent: total_mailers_sent,
          total_dealcredit_to_spend: total_dealcredit_to_spend
        });
      } else if (!is_selecting_range) {
        for (let i = 0; i < calendar_events.length; i++) {
          if (
            calendar_events[i].calendar_date ==
            moment(selected_date).format("YYYY-MM-DD")
          ) {
            total_mailers_in_queue = calendar_events[i].total_mailers_in_queue;
            total_mailers_to_send = calendar_events[i].total_mailers_to_send;
            total_mailers_sent = calendar_events[i].total_mailers_sent;
            total_dealcredit_to_spend =
              calendar_events[i].total_dealcredit_to_spend;
          }
        }

        this.setState({
          total_mailers_in_queue: total_mailers_in_queue,
          total_mailers_to_send: total_mailers_to_send,
          total_mailers_sent: total_mailers_sent,
          total_dealcredit_to_spend: total_dealcredit_to_spend
        });
      }
    }
  }

  onDateChange(selected_item) {
    if (this.state.is_selecting_range == true && selected_item?.length > 0) {
      this.setState({
        selected_date: null,
        range_selected_dates:
          selected_item &&
          selected_item?.map(date => {
            return moment(date).format("YYYY-MM-DD");
          })
      });
    } else {
      this.setState({
        selected_date:
          selected_item &&
          moment(selected_item).format("YYYY-MM-DD") != this.state.selected_date
            ? moment(selected_item).format("YYYY-MM-DD")
            : null,
        range_selected_dates: []
      });
    }
  }

  onMonthChange(active_start_date) {
    this.setState({
      start_date: moment(active_start_date)
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment(active_start_date).endOf("month").format("YYYY-MM-DD"),
      selected_date: null,
      range_selected_dates: []
    });
  }

  getMailCenterTotals() {
    let campaign_ids = [];
    let template_ids = [];

    for (
      let i = 0;
      i < this.state.selected_templates_and_sequences?.length;
      i++
    ) {
      if (this.state.selected_templates_and_sequences[i].type == "template") {
        template_ids.push(this.state.selected_templates_and_sequences[i].id);
      } else {
        campaign_ids.push(this.state.selected_templates_and_sequences[i].id);
      }
    }

    this.props.getCalendar({
      token: this.props.token,
      type: "mail_center_totals",
      campaign_ids: campaign_ids.join(","),
      template_ids: template_ids.join(","),
      onLoading: () => {
        this.setState({ totals_loading: true });
      },
      onError: () => {
        this.setState({ totals_loading: false });
      },
      onSuccess: results => {
        this.setState({
          totals_loading: false,
          mail_center_totals: results.mail_center_totals
        });
      }
    });
  }

  getCalendarEvents({ start_date, end_date, load_type = "refresh" }) {
    let campaign_ids = [];
    let template_ids = [];

    for (
      let i = 0;
      i < this.state.selected_templates_and_sequences?.length;
      i++
    ) {
      if (this.state.selected_templates_and_sequences[i].type == "template") {
        template_ids.push(this.state.selected_templates_and_sequences[i].id);
      } else {
        campaign_ids.push(this.state.selected_templates_and_sequences[i].id);
      }
    }

    this.props.getCalendar({
      token: this.props.token,
      start_date,
      end_date,
      campaign_ids: campaign_ids.join(","),
      template_ids: template_ids.join(","),
      load_type,
      onLoading: () => {
        this.setState({ calendar_loading: true });
      },
      onError: () => {
        this.setState({ calendar_loading: false });
      },
      onSuccess: results => {
        this.setState({
          calendar_loading: false,
          calendar_events: results.calendar_events
        });
      }
    });
  }

  renderDateDisplay() {
    if (this.state.is_selecting_range) {
      if (
        (this.state.is_selecting_range &&
          this.state.range_selected_dates.length == 0) ||
        (!this.state.is_selecting_range && this.state.selected_date == null)
      ) {
        return (
          <Copy
            style={{
              fontSize: 12,
              fontStyle: "italic",
              color: this.props.colors.light_text_color
            }}
            onPress={() => {
              this.setState({ show_calendar: true });
            }}
          >
            Selecting range...
          </Copy>
        );
      }

      return (
        <Copy
          style={{
            fontSize: 12,
            fontStyle: "italic",
            color: this.props.colors.light_text_color
          }}
        >
          Viewing{" "}
          {moment(this.state.range_selected_dates[0]).format("MMM Do, YYYY")} -{" "}
          {moment(this.state.range_selected_dates[1]).format("MMM Do, YYYY")}
        </Copy>
      );
    } else {
      if (
        (this.state.is_selecting_range &&
          this.state.range_selected_dates.length == 0) ||
        (!this.state.is_selecting_range && this.state.selected_date == null)
      ) {
        return (
          <Copy
            style={{
              fontSize: 12,
              fontStyle: "italic",
              color: this.props.colors.light_text_color
            }}
          >
            Viewing all of {moment(this.state.start_date).format("MMMM, YYYY")}
          </Copy>
        );
      }
      return (
        <Copy
          style={{
            fontSize: 12,
            fontStyle: "italic",
            color: this.props.colors.light_text_color
          }}
        >
          Viewing {moment(this.state.selected_date).format("MMM Do, YYYY")}
        </Copy>
      );
    }
  }

  onFilterConfirm(selected_templates_and_sequences) {
    this.setState({ selected_templates_and_sequences });
  }

  renderCalendar() {
    const { colors } = this.props;

    return (
      <Wrapper>
        <Wrapper style={{ position: "relative" }}>
          <EventCalendar
            selected_date={this.state.selected_date}
            range_selected_dates={this.state.range_selected_dates}
            is_selecting_range={this.state.is_selecting_range}
            onDateChange={selected_item => {
              this.onDateChange(
                this.props.device == "desktop" || this.state.is_selecting_range
                  ? selected_item
                  : selected_item[0]
              );
            }}
            onMonthChange={active_start_date => {
              this.onMonthChange(active_start_date);
            }}
            calendar_events={this.state.calendar_events}
            calendar_loading={this.state.calendar_loading}
            colors={this.props.colors}
          />
          {this.state.calendar_loading ? (
            <Wrapper
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor:
                  this.props.dark_mode === "dark_mode"
                    ? "rgba(0,0,0,0.3)"
                    : "rgba(255, 255, 255, 0.75)",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <SpinWrapper text={"Loading Calendar"} />
            </Wrapper>
          ) : null}
        </Wrapper>
        <Row style={{ justifyContent: "center" }}>
          <InlineButton
            select_type="toggle"
            icon={this.state.is_selecting_range ? "toggle-on" : "toggle-off"}
            onPress={() => {
              this.setState({
                is_selecting_range: !this.state.is_selecting_range
              });
            }}
            iconColor={
              this.state.is_selecting_range ? colors.success_color : null
            }
          >
            Select Date Range
          </InlineButton>
        </Row>
        <Row style={{ padding: 25, justifyContent: "center" }}>
          <StatBlock
            title={"Sent"}
            number={this.state.total_mailers_sent}
            smallBlock={true}
          />
          <StatBlock
            title={"Scheduled"}
            number={
              this.state.total_mailers_to_send +
              this.state.total_mailers_in_queue
            }
            smallBlock={true}
          />
          <StatBlock
            title={"Estimated Spend"}
            number={
              !!this.state.total_dealcredit_to_spend
                ? renderPrice(this.state.total_dealcredit_to_spend)
                : ""
            }
            smallBlock={true}
          />
        </Row>
      </Wrapper>
    );
  }

  render() {
    const { colors, isMobile, user, sending_queue_counts, device } = this.props;
    const { mail_center_totals } = this.state;

    let template_filters = null;
    let campaign_filters = null;
    let more_filters = [];
    for (
      let i = 0;
      i < this.state.selected_templates_and_sequences?.length;
      i++
    ) {
      if (this.state.selected_templates_and_sequences[i].type == "template") {
        if (!template_filters) {
          template_filters = {
            label: "Mail Design",
            key: "mail_design",
            equal_type: "is_equal_to_any_of",
            type: "object",
            values: [
              {
                value: this.state.selected_templates_and_sequences[i].value,
                label: this.state.selected_templates_and_sequences[i].label
              }
            ]
          };
        } else {
          template_filters.values.push({
            value: this.state.selected_templates_and_sequences[i].value,
            label: this.state.selected_templates_and_sequences[i].label
          });
        }
      } else {
        if (!campaign_filters) {
          campaign_filters = {
            label: "Assigned Mail Sequence",
            key: "mail_sequence",
            equal_type: "is_equal_to_any_of",
            type: "object",
            values: [
              {
                value: this.state.selected_templates_and_sequences[i].value,
                label: this.state.selected_templates_and_sequences[i].label
              }
            ]
          };
        } else {
          campaign_filters.values.push({
            value: this.state.selected_templates_and_sequences[i].value,
            label: this.state.selected_templates_and_sequences[i].label
          });
        }
      }
    }

    if (template_filters) {
      more_filters.push(template_filters);
    }

    if (campaign_filters) {
      more_filters.push(campaign_filters);
    }

    if (user?.team_clearance_level > 1 || user?.can_edit_templates == 1) {
      return (
        <Container
          fill={true}
          title={"Mail Center"}
          style={
            device == "desktop"
              ? {
                  display: "flex",
                  overflow: "hidden",
                  backgroundColor: colors.card_color
                }
              : {}
          }
        >
          <Row
            style={
              device == "mobile"
                ? {}
                : isMobile
                ? { flexDirection: "column", alignItems: "flex-start" }
                : { alignItems: "flex-start" }
            }
          >
            <Wrapper style={{ flex: 1, alignSelf: "stretch" }}>
              <MainHeader
                title={"Mail Center"}
                renderRight={
                  isMobile
                    ? () => {
                        return (
                          <InlineButton
                            icon={user?.pause_sending == 1 ? "pause" : ""}
                            notification={
                              user?.pause_sending == 1
                                ? null
                                : sending_queue_counts?.sending_queue
                            }
                            primary={true}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "mail_queue",
                                overlay: true
                              });
                            }}
                            textColor={
                              user?.pause_sending == 1
                                ? colors.actionable_text_color
                                : null
                            }
                          >
                            {user?.pause_sending == 1
                              ? "Mail Queue Paused"
                              : "Mail Queue"}
                          </InlineButton>
                        );
                      }
                    : null
                }
              />

              <Row
                style={
                  isMobile
                    ? {
                        paddingBottom: 25
                      }
                    : {}
                }
              >
                <Wrapper style={{ flex: 1 }}>
                  <Row style={isMobile ? {} : { padding: 25, paddingTop: 0 }}>
                    <StatBlock
                      loading={this.state.totals_loading}
                      title={isMobile ? "Active" : "Active Leads"}
                      number={numberWithCommas(
                        mail_center_totals?.total_active_count
                      )}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "view_leads",
                          overlay: true,
                          data: {
                            subtitle: "Showing active leads.",
                            filters: {
                              data: [
                                {
                                  data: [
                                    {
                                      label: "Mail Status",
                                      key: "campaign_status",
                                      equal_type: "is_equal_to_any_of",
                                      type: "object",
                                      values: [
                                        {
                                          value: "Mail Sequence In Progress",
                                          label: "Mail In Progress"
                                        }
                                      ]
                                    },

                                    ...more_filters
                                  ],
                                  andor_type: "and"
                                }
                              ],
                              andor_type: "or"
                            }
                          }
                        });
                      }}
                    />
                    <StatBlock
                      loading={this.state.totals_loading}
                      title={isMobile ? "Paused" : "Paused Leads"}
                      number={numberWithCommas(
                        mail_center_totals?.total_paused_count
                      )}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "view_leads",
                          overlay: true,
                          data: {
                            subtitle: "Showing paused leads.",
                            filters: {
                              data: [
                                {
                                  data: [
                                    {
                                      label: "Mail Status",
                                      key: "campaign_status",
                                      equal_type: "is_equal_to_any_of",
                                      type: "object",
                                      values: [
                                        {
                                          value: "Mail Sequence Paused",
                                          label: "Mail Paused"
                                        }
                                      ]
                                    },

                                    ...more_filters
                                  ],
                                  andor_type: "and"
                                }
                              ],
                              andor_type: "or"
                            }
                          }
                        });
                      }}
                    />
                    <StatBlock
                      loading={this.state.totals_loading}
                      title={isMobile ? "Completed" : "Completed Leads"}
                      number={numberWithCommas(
                        mail_center_totals?.total_completed_count
                      )}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "view_leads",
                          overlay: true,
                          data: {
                            subtitle: "Showing completed leads.",
                            filters: {
                              data: [
                                {
                                  data: [
                                    {
                                      label: "Mail Status",
                                      key: "campaign_status",
                                      equal_type: "is_equal_to_any_of",
                                      type: "object",
                                      values: [
                                        {
                                          value: "Mail Sequence Ended",
                                          label: "Mail Ended"
                                        }
                                      ]
                                    },

                                    ...more_filters
                                  ],
                                  andor_type: "and"
                                }
                              ],
                              andor_type: "or"
                            }
                          }
                        });
                      }}
                    />
                  </Row>
                </Wrapper>

                {isMobile ? (
                  <Row>
                    <InlineButton
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "filter_mail_center",
                          overlay: true,
                          data: {
                            selected_templates_and_sequences:
                              this.state.selected_templates_and_sequences,
                            onConfirm: this.onFilterConfirm
                          }
                        });
                      }}
                      icon={"tune"}
                      notification={
                        this.state.selected_templates_and_sequences?.length
                      }
                    >
                      Filters
                    </InlineButton>
                  </Row>
                ) : null}
              </Row>
            </Wrapper>
            {!isMobile ? (
              <Row style={{ padding: 25, paddingTop: 35 }}>
                <InlineButton
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "filter_mail_center",
                      overlay: true,
                      data: {
                        selected_templates_and_sequences:
                          this.state.selected_templates_and_sequences,
                        onConfirm: this.onFilterConfirm
                      }
                    });
                  }}
                  icon={"tune"}
                  notification={
                    this.state.selected_templates_and_sequences?.length
                  }
                >
                  Filters
                </InlineButton>
                <GhostButton
                  icon={user?.pause_sending == 1 ? "pause" : ""}
                  notification={
                    user?.pause_sending == 1
                      ? null
                      : sending_queue_counts?.sending_queue
                  }
                  primary={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "mail_queue",
                      overlay: true
                    });
                  }}
                  innerStyle={
                    user?.pause_sending == 1
                      ? {
                          backgroundColor: colors.active_color_muted,
                          borderColor: colors.actionable_text_color
                        }
                      : {}
                  }
                  textColor={
                    user?.pause_sending == 1
                      ? colors.actionable_text_color
                      : null
                  }
                >
                  {user?.pause_sending == 1
                    ? "Mail Queue Paused"
                    : "Mail Queue"}
                </GhostButton>
              </Row>
            ) : null}
          </Row>

          <Row style={{ flex: 1, overflow: "hidden" }}>
            <Wrapper
              style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
            >
              <Row style={{ flex: 1, overflow: "hidden" }}>
                {!isMobile ? (
                  <Wrapper
                    style={{
                      width: isMobile ? "100%" : 400,
                      minWidth: isMobile ? "100%" : 400,
                      maxWidth: 1000,
                      alignSelf: "stretch",
                      backgroundColor: colors.card_color,
                      borderRightWidth: 1,
                      borderRightStyle: "solid",
                      borderRightColor: colors.border_color
                    }}
                  >
                    <KeyboardView style={{ flex: 1 }}>
                      {this.renderCalendar()}
                    </KeyboardView>
                  </Wrapper>
                ) : null}

                <Wrapper
                  style={{
                    flex: 1,
                    alignSelf: "stretch",
                    position: "relative",
                    minWidth: isMobile ? "100%" : 550
                  }}
                >
                  <Row>
                    <Wrapper style={{ flex: 1 }}>
                      <InlineTabs
                        tab_type="underline"
                        selected_tab={this.state.tab}
                        tabs={[
                          {
                            onPress: () => {
                              this.setState({
                                tab: "sent"
                              });
                            },
                            slug: "sent",
                            title: "Sent",
                            notification: ""
                          },
                          {
                            onPress: () => {
                              this.setState({
                                tab: "scheduled_events"
                              });
                            },
                            slug: "scheduled_events",
                            title: "Scheduled",
                            notification: ""
                          }
                        ]}
                      />
                    </Wrapper>
                    <Wrapper style={isMobile ? {} : { padding: 25 }}>
                      {isMobile ? (
                        <PopoverMenu
                          show={this.state.show_calendar}
                          no_swipe={true}
                          no_cancel={true}
                          popoverSheetTop={400}
                          popover_width={350}
                          popoverSheetTop={"85%"}
                          onShow={s => {
                            this.setState({
                              show_calendar: s
                            });
                          }}
                          popoverPlacement={"bottom"}
                          popover_title={"Select a date or date range"}
                          includeCloseButton={true}
                          renderComponent={({ hovering }) => {
                            return (
                              <InlineButton
                                noPress={true}
                                hovering={hovering}
                                pressedIn={this.state.show_calendar}
                              >
                                {this.renderDateDisplay()}
                              </InlineButton>
                            );
                          }}
                          renderMenu={() => {
                            return (
                              <Wrapper
                                style={{
                                  alignSelf: "stretch"
                                }}
                              >
                                {this.renderCalendar()}
                              </Wrapper>
                            );
                          }}
                          menu_items={null}
                        />
                      ) : (
                        this.renderDateDisplay()
                      )}
                    </Wrapper>
                  </Row>
                  {this.state.tab === "sent" ? (
                    <SentMail
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                      selected_date={this.state.selected_date}
                      is_selecting_range={this.state.is_selecting_range}
                      range_selected_dates={this.state.range_selected_dates}
                      selected_templates_and_sequences={
                        this.state.selected_templates_and_sequences
                      }
                    />
                  ) : null}

                  {this.state.tab === "scheduled_events" ? (
                    <ScheduledEvents
                      start_date={this.state.start_date}
                      end_date={this.state.end_date}
                      selected_date={this.state.selected_date}
                      is_selecting_range={this.state.is_selecting_range}
                      range_selected_dates={this.state.range_selected_dates}
                      selected_templates_and_sequences={
                        this.state.selected_templates_and_sequences
                      }
                    />
                  ) : null}
                </Wrapper>
              </Row>
            </Wrapper>
          </Row>
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, mailer }) => {
  const { token, user } = auth;
  const { colors, dark_mode } = settings;
  const { isMobile, device } = native;
  const { sending_queue_counts } = mailer;
  return {
    token,
    user,
    isMobile,
    colors,
    dark_mode,
    device,
    sending_queue_counts
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  getCalendar,
  getSendingQueueCounts,
  tabNavigation,
  getMailerTemplates,
  getMailerCampaigns,
  changeTab
})(MailCenter);
