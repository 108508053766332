import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  GhostButton,
  SmallHeader,
  SelectItem
} from "app/NativeComponents/snippets";

class SkipTraceStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_team: false,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            continue_if_skip_trace_fails: true
          }
    };
  }

  checkIfNeedsToSave() {
    return true;
  }

  render() {
    const { colors, device, isMobile, active_step, workflow } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Update Custom Fields "
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            <SelectItem
              selected={data.continue_if_skip_trace_fails}
              onPress={() => {
                this.setState({
                  data: {
                    ...data,
                    continue_if_skip_trace_fails:
                      !data.continue_if_skip_trace_fails
                  }
                });
              }}
              description={
                "* This can happen if no data is returned for the lead or a payment fails during the skip trace."
              }
            >
              Continue workflow even if skip trace fails for lead.
            </SelectItem>
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
  }
}

export default SkipTraceStep;
