import React, { useEffect, useState } from "react";
import { loadManagePaymentMethodsInformation } from "app/NativeActions";
import { Copy } from "app/NativeComponents/common";
import { AttentionBox } from "app/NativeComponents/snippets";

export const Information = props => {
  const [informationParagraphs, setInformationParagraphs] = useState();

  useEffect(() => {
    loadManagePaymentMethodsInformation().then(entries => {
      setInformationParagraphs(
        entries.items.map(item => ({
          text: item.fields.text,
          order: item.fields.order,
          type: item.fields.type
        }))
      );
    });
  }, []);

  if (props.user?.bad_card == 1) {
    return (
      <AttentionBox
        title="Bad Card On File"
        description={
          "Update your card information to continue using your account"
        }
      />
    );
  }

  return (
    <AttentionBox
      title="Payment Methods"
      renderDescription={() => {
        return (
          <>
            {informationParagraphs &&
              informationParagraphs.map((paragraph, i) => {
                if (paragraph.type.includes("italic")) {
                  return (
                    <Copy key={i} style={{ fontStyle: "italic", marginTop: 5 }}>
                      {paragraph.text}
                    </Copy>
                  );
                } else {
                  return (
                    <Copy key={i} style={{ marginTop: 5 }}>
                      {paragraph.text}
                    </Copy>
                  );
                }
              })}
          </>
        );
      }}
    />
  );
};
