import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "app/store";

import {
  ModalContainer,
  Container,
  KeyboardView,
  Wrapper,
  Scroll,
  Row,
  Animated
} from "app/NativeComponents/common";
import { NewHeader, SwipeView } from "app/NativeComponents/snippets";

class FocusModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;
    const colors = store.getState().settings.colors;

    const {
      title,
      subtitle,
      side_panel_mobile_title,
      side_panel_mobile_subtitle,
      main_panel_mobile_title,
      main_panel_mobile_subtitle,
      onClose = () => {},
      onMainPanelClose = () => {},
      renderSideBar = null,
      renderLeftBar = null,
      renderMainView = null,
      mainViewClass = "",
      renderBottomNav = null,
      renderTopNav = null,
      show_side_bar = true,
      show_left_bar = false,
      show_bottom_nav = true,
      topNavStyle = {},
      bottomNavStyle = {},
      mobile_pages = [],
      current_mobile_slug = ""
    } = this.props;

    if (isMobile) {
      if (mobile_pages && mobile_pages.length > 0) {
        let mobile_title = title;
        let mobile_subtitle = subtitle;
        let current_index = 0;
        let onBack = null;
        for (let i = 0; i < mobile_pages.length; i++) {
          if (mobile_pages[i].slug == current_mobile_slug) {
            mobile_title = mobile_pages[i].title;
            mobile_subtitle = mobile_pages[i].subtitle;
            current_index = i;
            onBack = mobile_pages[i].onBack;
          }
        }

        return (
          <Container
            style={{ display: "flex", backgroundColor: colors.card_color }}
          >
            <NewHeader
              title={mobile_title || title}
              subtitle={mobile_subtitle || ""}
              leftButton={{
                icon: "arrow-back",
                onPress: () => {
                  if (current_index > 0) {
                    if (onBack) {
                      onBack();
                    }
                  } else {
                    onClose();
                  }
                }
              }}
            />
            <SwipeView
              style={device == "desktop" ? { height: "100%" } : {}}
              slides={mobile_pages}
              current_slug={current_mobile_slug}
            />
          </Container>
        );
      }
    }

    return (
      <>
        <NewHeader
          title={title}
          rightButton={
            !isMobile
              ? {
                  icon: "close",
                  onPress: onClose
                }
              : null
          }
          leftButton={
            isMobile
              ? {
                  icon: "arrow-back",
                  onPress: onClose
                }
              : null
          }
        />
        <Row
          style={
            device == "desktop" ? { flex: 1, overflow: "hidden" } : { flex: 1 }
          }
        >
          {renderSideBar && show_side_bar ? (
            <Wrapper
              style={{
                width: isMobile ? "100%" : 400,
                minWidth: 300,
                alignSelf: "stretch",
                backgroundColor: colors.card_color,
                borderRightWidth: 1,
                borderRightStyle: "solid",
                borderRightColor: colors.border_color
              }}
            >
              {renderSideBar ? renderSideBar() : null}
            </Wrapper>
          ) : null}
          <Wrapper
            className={mainViewClass}
            style={{
              backgroundColor: colors.card_color,
              flex: 1,
              alignSelf: "stretch",
              overflow: "hidden"
            }}
          >
            {renderMainView ? renderMainView() : null}
          </Wrapper>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ workflow, native }) => {
  const { device } = native;
  return {
    device
  };
};

export { FocusModal };
