import React, { Component } from "react";

const SectionImage = ({ section }) => {
  if (section.mainImage) {
    return (
      <div className="dm-website-section-image">
        <img
          src={section.mainImage.fields.file.url}
          alt={section.mainImage.fields.file.title}
        />
      </div>
    );
  }

  return <div />;
};

export default SectionImage;
