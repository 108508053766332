import Pusher from "pusher-js";
let _pusher;

function getPusher() {
  _pusher = new Pusher("6cc6eb0820d80720f5d6", {
    cluster: "us2"
  });

  return _pusher;
}
export default {
  getPusher
};
