import React, { Component } from "react";
import YouTube from "react-youtube";

import { YouTubeVideo } from "app/NativeComponents/common";

class GreetingVideo extends Component {
  render() {
    if (
      this.props.team_link_info.greeting_video &&
      this.props.team_link_info.greeting_video != ""
    ) {
      return (
        <div className="dm-website-offer-video">
          <YouTubeVideo
            video={this.props.team_link_info.greeting_video}
            autoplay={true}
            height={"270"}
            width={"270"}
          />
        </div>
      );
    }

    return <div />;
  }
}

export default GreetingVideo;
