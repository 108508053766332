import React, { Component } from "react";
import { Row } from "app/NativeComponents/common";
import { CircleButton } from "app/NativeComponents/snippets";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton
} from "react-share";

import { renderPrice } from "app/NativeActions";
class NativeShareButtons extends Component {
  render() {
    const { url, amount, promo } = this.props;
    return (
      <Row style={{ justifyContent: "center" }}>
        <EmailShareButton
          url={url}
          subject={
            "Join DealMachine and earn " +
            renderPrice(amount) +
            " in free marketing credit."
          }
          body={
            "Use my referral code " +
            promo +
            " to join DealMachine to earn  " +
            renderPrice(amount) +
            " in free marketing credit. Join here: " +
            url
          }
        >
          <CircleButton icon="email" noPress={true} />
        </EmailShareButton>

        <FacebookShareButton
          url={url}
          quote={
            "Use my referral code " +
            promo +
            " to join DealMachine to earn  " +
            renderPrice(amount) +
            " in free marketing credit. Join here: " +
            url
          }
        >
          <CircleButton fa_icon="facebook-f" noPress={true} />
        </FacebookShareButton>
        <LinkedinShareButton
          url={url}
          title={
            "Join DealMachine and earn " +
            renderPrice(amount) +
            " in free marketing credit."
          }
          summary={
            "Use my referral code " +
            promo +
            " to join DealMachine to earn  " +
            renderPrice(amount) +
            " in free marketing credit. Join here: " +
            url
          }
          source={url}
        >
          <CircleButton fa_icon="linkedin" noPress={true} />
        </LinkedinShareButton>

        <RedditShareButton
          url={url}
          title={
            "Use my referral code " +
            promo +
            " to join DealMachine to earn  " +
            renderPrice(amount) +
            " in free marketing credit. Join here: " +
            url
          }
        >
          <CircleButton fa_icon="reddit-alien" noPress={true} />
        </RedditShareButton>

        <TwitterShareButton
          url={url}
          title={
            "Use my referral code " +
            promo +
            " to join DealMachine to earn  " +
            renderPrice(amount) +
            " in free marketing credit. Join here: " +
            url
          }
        >
          <CircleButton fa_icon="twitter" noPress={true} />
        </TwitterShareButton>
      </Row>
    );
  }
}

export default NativeShareButtons;
