import React, { Component } from "react";

import { Container, KeyboardView, Row } from "app/NativeComponents/common";

import { openUrl, AppConfig } from "app/NativeActions";

import {
  NewHeader,
  AttentionBox,
  SpinWrapper,
  BottomNavBar,
  InlineButton,
  DateRangePicker
} from "app/NativeComponents/snippets";

import InvoiceCharges from "./InvoiceCharges";
import InvoiceList from "./InvoiceList";

class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen: false,
      start_date: null,
      end_date: null
    };

    this.handleBack = this.handleBack.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  toggleCalendar() {
    this.setState(prevState => {
      return { isCalendarOpen: !prevState.isCalendarOpen };
    });
  }

  exportInvoiceString() {
    var export_string = "";
    if (this.props.start_date == null && this.props.end_date == null) {
      export_string =
        "credits/?token=" + this.props.token + "&type=invoices&export_type=csv";
    } else {
      export_string =
        "credits/?token=" +
        this.props.token +
        "&type=invoices&export_type=csv&&start_date=" +
        this.props.start_date +
        "&end_date=" +
        this.props.end_date;
    }
    return export_string;
  }

  render() {
    return (
      <Container>
        <NewHeader
          title={"Invoices"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}

          /*
            rightButtonIcon={"date-range"}
            rightButtonAction={this.toggleCalendar.bind(this)}
            rightButtonIcon2={"cloud-download"}
            rightButtonAction2={() => {
              openUrl(AppConfig().api_url + this.exportInvoiceString());
            }}
            */
        />

        <KeyboardView style={{ flex: 1 }}>
          <DateRangePicker
            {...this.props}
            isCalendarOpen={this.state.isCalendarOpen}
            toggleCalendar={this.toggleCalendar.bind(this)}
            selectDates={({ start_date, end_date }) => {
              this.props.getInvoices({
                token: this.props.token,
                begin: 0,
                type: "invoices",
                team: this.props.user.team_id,
                start_date: start_date,
                end_date: end_date
              });
            }}
          />

          {this.props.invoices_refreshing ||
          (this.props.invoices_loading && this.props.invoices.length == 0) ? (
            <SpinWrapper text="Loading invoices..." />
          ) : this.props.invoices.length > 0 ? (
            <InvoiceList
              toggleCalendar={this.toggleCalendar.bind(this)}
              isCalendarOpen={this.state.isCalendarOpen}
              {...this.props}
            />
          ) : (
            <AttentionBox
              title="No Invoices Found"
              description="It doesn't look like there are any invoices to display based on
            your search parameters."
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            />
          )}
        </KeyboardView>
        {this.props.invoices.length > 0 ? (
          <BottomNavBar>
            <Row style={{ justifyContent: "space-between" }}>
              <InlineButton
                primary={true}
                onPress={() => {
                  openUrl(AppConfig().api_url + this.exportInvoiceString());
                }}
              >
                Export Invoices
              </InlineButton>
              <InlineButton onPress={this.toggleCalendar}>
                Select Date Range
              </InlineButton>
            </Row>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

export default Body;
