import React, { Component } from "react";
import { Wrapper, Card, Input } from "app/NativeComponents/common";

import {
  /*common functions */
  focusNextField
} from "app/NativeActions";
import { InputBox } from "../../native/GlobalSnippets";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this._email_input = React.createRef();
    this._password_input = React.createRef();
  }
  render() {
    const {
      colors,
      isMobile,
      device,
      estimated_count,
      count_loading,
      list_name,
      renderBottom = null,
      renderLeft = null,
      style,
      dark_mode
    } = this.props;
    return (
      <Wrapper>
        <InputBox
          input_ref={this._email_input}
          name="email"
          autoCompleteType={"email"}
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          keyboardType="email-address"
          placeholder="Email Address"
          onChange={value =>
            this.props.authFieldChanged({ prop: "email", value })
          }
          value={this.props.email}
          type="text"
          autoComplete={"email"}
          textContentType={"emailAddress"}
          autoComplete={"email"}
          onSubmitEditing={() => {
            if (this._password_input?.current) {
              this._password_input.current.focus();
            }
          }}
        />
        <InputBox
          input_ref={this._password_input}
          name="password"
          returnKeyType={"done"}
          blurOnSubmit={false}
          keyboardType="default"
          placeholder="Password"
          secureTextEntry={true}
          onChange={value =>
            this.props.authFieldChanged({ prop: "password", value })
          }
          onSubmitEditing={() => this.props.login()}
          value={this.props.password}
          type="password"
          autoComplete={"password"}
          textContentType={"password"}
          autoComplete={"current-password"}
        />
      </Wrapper>
    );
  }
}

export default Inputs;
