import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Title,
  Copy,
  Bold,
  Row,
  Scroll
} from "app/NativeComponents/common";

import {
  replaceSidePanel,
  selectMultipleProperties,
  numberWithCommas
} from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

class MultipleProperties extends Component {
  constructor(props) {
    super(props);

    this.checkIfThereAreFilters = this.checkIfThereAreFilters.bind(this);
  }

  componentDidMount() {}

  checkIfThereAreFilters() {
    const {
      property_types,
      property_flags,
      value_range_max,
      value_range_min,
      beds_min,
      baths_min
    } = this.props.map_filters;
    if (
      property_flags.length > 0 ||
      property_types.length > 0 ||
      !!value_range_min ||
      !!value_range_max ||
      !!beds_min ||
      !!baths_min
    ) {
      return true;
    }
    if (
      this.props.applied_highlight &&
      this.props.applied_highlight?.data &&
      this.props.applied_highlight?.data?.length > 0
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { selected_active_properties, colors } = this.props;
    const selected_properties = selected_active_properties
      ? this.checkIfThereAreFilters()
        ? selected_properties?.filter(property => {
            return property.highlighted == 1 ? property : null;
          })
        : selected_active_properties
      : [];

    return (
      <Wrapper
        style={{
          flex: 1,
          backgroundColor: colors.popover_color,
          overflow: "hidden"
        }}
      >
        <Row>
          <Wrapper style={{ flex: 1, padding: 25 }}>
            <Title>
              {this.props.highlight
                ? "Properties at location matching filters: (" +
                  numberWithCommas(selected_properties?.length) +
                  ")"
                : "Properties at location: (" +
                  numberWithCommas(selected_properties?.length) +
                  ")"}
            </Title>
          </Wrapper>
          <HeaderButton
            buttonContents={{
              icon: "close",
              color: colors.light_text_color,
              hover_color: colors.hover_color,
              onPress: () => {
                this.props.selectMultipleProperties(null);
              }
            }}
          />
        </Row>
        <Scroll style={{ flex: 1 }}>
          {selected_properties?.map((item, index) => {
            return (
              <LeadItem
                key={"item_" + index}
                include_add_button={true}
                include_check_icon={true}
                property={item}
                onPress={() => {
                  this.props.replaceSidePanel({
                    slug: "property",
                    id: item?.property_id,
                    focus_side_panel:
                      this.props.user?.user_settings?.property_expanded ===
                      "not_expanded"
                        ? false
                        : true,
                    overlay: true,
                    data: {
                      property: {
                        ...item
                      }
                    }
                  });
                }}
              />
            );
          })}
        </Scroll>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, property_map }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { selected_active_properties, map_filters, applied_highlight } =
    property_map;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    selected_active_properties,
    map_filters,
    applied_highlight
  };
};

export default connect(mapStateToProps, {
  replaceSidePanel,
  selectMultipleProperties
})(MultipleProperties);
