import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  List,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  determineDisplayProperty,
  formatAddress,
  updateLead,
  showErrorMessage
} from "app/NativeActions";

class SelectAPropertyToLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selected_property: null
    };
  }

  render() {
    const { loading, selected_property } = this.state;
    const { formatted_property_address, properties, property } = this.props;

    return (
      <Container>
        <NewHeader
          title="Select A Property To Match"
          subtitle={
            formatted_property_address
              ? "Select a property from our database to link with your lead (" +
                formatted_property_address?.line1 +
                ")"
              : ""
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.cancelSearch();
            }
          }}
        />

        {this.props.properties?.length == 0 ? (
          <AttentionBox
            title="Nothing to display."
            description={
              "Your search returned no results. Try altering your search to find the property you're looking for."
            }
          />
        ) : (
          <List
            style={{ flex: 1 }}
            items={properties}
            itemStructure={({ item, index }) => {
              return (
                <SelectItem
                  key={"option_" + index}
                  icon={"keyboard-arrow-right"}
                  select_type="radio"
                  selected={item.property_id == selected_property}
                  onPress={() => {
                    this.setState({
                      selected_property: item.property_id
                    });
                  }}
                  confirm_text="Confirm"
                  require_confirm={selected_property ? true : false}
                  loading={loading}
                  onConfirm={() => {
                    this.props.updateLead({
                      token: this.props.token,
                      type: "link_lead_to_data",
                      property_data_id: item?.property_id,
                      deal_ids: property?.deal?.id,
                      no_loading: true,
                      onLoading: () => {
                        this.setState({
                          loading: true
                        });
                      },
                      onError: error => {
                        this.setState({
                          loading: false
                        });
                        this.props.showErrorMessage(error, "Error");
                      },
                      onSuccess: results => {
                        if (
                          this.props.propertyUpdated &&
                          results?.properties?.length > 0
                        ) {
                          this.props.propertyUpdated(results?.properties[0]);
                        }
                        this.setState({
                          loading: false
                        });

                        this.props.popSidePanel();
                      }
                    });
                  }}
                >
                  {item.property_address_full}
                </SelectItem>
              );
            }}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  updateLead,
  showErrorMessage
})(SelectAPropertyToLink);
