import React, { Component } from "react";

const Markdown = require("react-markdown");

const FeatureDescription = ({ feature }) => {
  if (feature.description) {
    return <Markdown source={feature.description} escapeHtml={false} />;
  }

  return <div />;
};

export default FeatureDescription;
