import React from "react";

const Section = ({ children, type, style, id, media = null }) => {
  var className = "";

  switch (type) {
    case "HeaderSection":
      className = "dm-website-section dm-website-header-section";
      break;

    case "BlogPostHeaderSection":
      className = "dm-website-section dm-website-blog-post-header-section";
      break;

    case "BlogPostSection":
      className = "dm-website-section dm-website-blog-post-section";
      break;

    case "BlogListSection":
      className = "dm-website-section dm-website-blog-list-section";
      break;

    case "BlogListFeatureSection":
      className = "dm-website-section dm-website-blog-list-feature-section";
      break;

    case "BasicLandingPage":
      className = "dm-website-section dm-website-landing-page-section";
      break;

    case "VideoLandingPage":
      className = "dm-website-section dm-website-video-landing-page-section";
      break;

    case "BasicSection":
    case "FeaturedIcons":
    default:
      className = "dm-website-section";
      break;
  }

  if (media) {
    switch (media.fields.file.contentType) {
      case "image/jpeg":
      case "image/png":
      case "image/svg":
      default:
        return (
          <div
            id={id}
            className={className}
            style={{
              ...style,
              backgroundImage: "url(" + media.fields.file.url + ")",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat"
            }}
          >
            {children}
          </div>
        );

      case "video/mp4":
        /*
        return(
          <div
            id={id}
            className={className+" dm-website-video-section"}>
            <video loop autoPlay muted>
              <source src={media.fields.file.url} type="video/mp4"></source>
            </video>
            <div className="dm-website-video-section-info">
              {children}
            </div>
          </div>
        )
        */
        break;
    }
  }

  return (
    <div id={id} className={className} style={style}>
      {children}
    </div>
  );
};

export { Section };
