import React, { Component } from "react";

const CardImage = ({ card }) => {
  if (card.media) {
    return (
      <div className="dm-website-card-image">
        <img
          src={card.media.fields.file.url}
          alt={card.media.fields.file.title}
        />
      </div>
    );
  }

  return <div />;
};

export default CardImage;
