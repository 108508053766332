import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row } from "app/NativeComponents/common";
import {
  List,
  SpinWrapper,
  SelectItem,
  AttentionBox,
  IconButton,
  InlineButton
} from "app/NativeComponents/snippets";

import {
  pushSidePanel,
  getCalendar,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

import moment from "moment";

class ScheduledEvents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_events: false,
      refreshing_events: false,
      can_load_more: false,
      events: [],
      begin: 0,
      limit: 25
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.start_date !== this.props.start_date ||
      prevProps.end_date !== this.props.end_date ||
      prevProps.is_selecting_range !== this.props.is_selecting_range ||
      prevProps.selected_date !== this.props.selected_date ||
      prevProps.range_selected_dates !== this.props.range_selected_dates ||
      JSON.stringify(prevProps.selected_templates_and_sequences) !==
        JSON.stringify(this.props.selected_templates_and_sequences)
    ) {
      this.getData("refresh");
    }
  }

  getData(load_type = "load") {
    const {
      start_date,
      end_date,
      is_selecting_range,
      selected_date,
      range_selected_dates
    } = this.props;
    const { begin } = this.state;
    let d1 = null;
    let d2 = null;
    if (is_selecting_range) {
      if (range_selected_dates.length > 1) {
        d1 = moment(range_selected_dates[0]).format("YYYY-MM-DD");
        d2 = moment(range_selected_dates[1]).format("YYYY-MM-DD");
      }
    } else if (selected_date) {
      d1 = moment(selected_date).format("YYYY-MM-DD");
      d2 = moment(selected_date).format("YYYY-MM-DD");
    } else {
      d1 = moment(start_date).format("YYYY-MM-DD");
      d2 = moment(end_date).format("YYYY-MM-DD");
    }

    let campaign_ids = [];
    let template_ids = [];

    for (
      let i = 0;
      i < this.props.selected_templates_and_sequences?.length;
      i++
    ) {
      if (this.props.selected_templates_and_sequences[i].type == "template") {
        template_ids.push(this.props.selected_templates_and_sequences[i].id);
      } else {
        campaign_ids.push(this.props.selected_templates_and_sequences[i].id);
      }
    }
    if (d1 && d2) {
      this.props.getCalendar({
        token: this.props.token,
        start_date: d1,
        end_date: d2,
        begin: load_type === "refresh" ? 0 : begin,
        limit: this.state.limit,
        type: "scheduled_events",
        campaign_ids: campaign_ids.join(","),
        template_ids: template_ids.join(","),
        load_type,
        onLoading: () => {
          this.setState({
            can_load_more:
              load_type === "refresh" ? true : this.state.can_load_more,
            loading_events: load_type === "refresh" ? false : true,
            refreshing_events: load_type === "refresh" ? true : false,
            events: load_type === "refresh" ? [] : this.state.events
          });
        },
        onError: () => {
          this.setState({ loading_events: false, refreshing_events: false });
        },
        onSuccess: results => {
          if (results?.scheduled_events) {
            this.setState({
              loading_events: false,
              refreshing_events: false,
              events: [...this.state.events, ...results.scheduled_events],
              can_load_more:
                results.scheduled_events?.length < this.state.limit
                  ? false
                  : this.state.can_load_more,
              begin: begin + results.scheduled_events?.length
            });
          } else {
            this.setState({ loading_events: false, refreshing_events: false });
          }
        }
      });
    }
  }

  render() {
    const { user, colors, isMobile } = this.props;
    const { events, loading_events, refreshing_events, can_load_more } =
      this.state;
    return (
      <>
        {(loading_events || refreshing_events) && events.length == 0 ? (
          <SpinWrapper text="Loading" />
        ) : events.length == 0 ? (
          <AttentionBox
            title={"No mail scheduled"}
            description={"There are no leads with mail scheduled to send."}
          />
        ) : (
          <List
            rowNumber={1}
            items={events}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              const display_property = determineDisplayProperty(item?.property);

              const property_address = formatAddress({
                address: {
                  address: display_property.property_address,
                  address2: display_property.property_address2,
                  address_city: display_property.property_address_city,
                  address_state: display_property.property_address_state,
                  address_zip: display_property.property_address_zip,
                  latitude: item?.property?.location?.latitude,
                  longitude: item?.property?.location?.longitude
                }
              });

              let scale = 0.2;
              switch (item?.mailer?.template_type) {
                case "postcard":
                default:
                  scale = 0.2;
                  break;
                case "postcard6x9":
                  scale = 0.15;
                  break;
                case "postcard6x11":
                  scale = 0.125;
                  break;
              }

              return (
                <SelectItem
                  select_type={"none"}
                  noPress={true}
                  description={property_address?.line2}
                  sub_description={item.additional_mailing_addresses > 0
                      ? "You are sending mail to " + item.additional_mailing_addresses +  " additional" + (item.additional_mailing_addresses > 1 ? " addresses " : " address ") + "for this lead."
                      : null}
                  renderLeft={
                    !isMobile
                      ? () => {
                          return (
                            <Wrapper style={{ margin: 5, marginRight: 0 }}>
                              <MailerFlipCard
                                mailer={item?.mailer}
                                scale={scale}
                                back_scale={scale}
                              />
                            </Wrapper>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    return (
                      <Row style={{ margin: 5, marginLeft: 0 }}>
                        {item?.property ? (
                          <InlineButton
                            icon_right={"keyboard-arrow-right"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "property",
                                overlay: true,
                                focus_side_panel:
                                  this.props.user?.user_settings
                                    ?.property_expanded === "not_expanded"
                                    ? false
                                    : true,
                                id: item?.property?.property_id,
                                data: { property: item?.property }
                              });
                            }}
                          >
                            {this.props.isMobile ? "View" : "View Lead"}
                          </InlineButton>
                        ) : null}
                      </Row>
                    );
                  }}
                  label={
                    item.in_queue
                      ? "In Mail Queue"
                      : "Scheduled " +
                        moment(item?.date_to_send).format("MMM Do, YYYY")
                  }
                >
                  {property_address?.line1}
                </SelectItem>
              );
            }}
            onRefresh={() => {
              this.getData("refresh");
            }}
            is_refreshing={refreshing_events}
            canLoadMore={can_load_more}
            isLoadingMore={loading_events}
            onLoadMore={() => {
              this.getData("load_more");
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { colors } = settings;

  return {
    token,
    user,
    colors
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  getCalendar
})(ScheduledEvents);
