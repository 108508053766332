import React from "react";

import { store } from "app/store";
import { InlineButton } from "app/NativeComponents/snippets";

const CallResultOption = props => {
  const { title, slug, active_call, onPress, call_results_loading, tooltip } =
    props;
  const colors = store.getState().settings.colors;

  return (
    <InlineButton
      buttonContainerStyle={{ alignSelf: "stretch" }}
      tooltip={tooltip}
      tooltipPlacement="top"
      style={{
        borderWidth: 1,
        borderRadius: 15,
        borderBottomRightRadius: 0,
        maxWidth: 500,
        borderColor: colors.active_color,
        borderStyle: "solid",
        backgroundColor:
          active_call?.call_results === slug
            ? colors.active_color
            : "transparent"
      }}
      textColor={
        active_call?.call_results === slug
          ? colors.white_text_color
          : colors.active_color
      }
      selected={active_call?.call_results === slug}
      onPress={onPress}
    >
      {title}
    </InlineButton>
  );
};

export default CallResultOption;
