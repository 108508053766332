export const CHANGE_SORT_BY = "change_sort_by";

export const UPDATE_LIST_FILTER = "update_list_filter";
export const EDIT_LEAD_FILTER = "edit_lead_filter";
export const EDIT_CUSTOM_LEAD_FILTER = "edit_custom_lead_filter";
export const EDIT_FILTER_TITLE = "edit_filter_title";
export const EDIT_DATE_ADDED_FILTER = "edit_date_added_filter";
export const EDIT_DATE_EXPORTED_FILTER = "edit_date_exported_filter";

export const SET_STATUS_MODAL = "set_status_modal";
export const RESET_STATUS_MODAL = "reset_status_modal";
export const TOGGLE_STATUS_MODAL = "toggle_status_modal";

export const SET_LEAD_OWNER_MODAL = "set_lead_owner_modal";
export const TOGGLE_LEAD_OWNER_MODAL = "toggle_lead_owner_modal";

export const GET_ALL_STATUSES = "get_all_statuses";
export const GET_ALL_STATUSES_FAIL = "get_all_statuses_fail";
export const GET_ALL_STATUSES_SUCCESS = "get_all_statuses_success";

export const GET_PROPERTY_FIPS = "get_property_fips";
export const GET_PROPERTY_FIPS_FAIL = "get_property_fips_fail";
export const GET_PROPERTY_FIPS_SUCCESS = "get_property_fips_success";

export const SET_ITEM_SELECTOR_MODAL = "set_item_selector_modal";
export const TOGGLE_ITEM_SELECTOR_MODAL = "toggle_item_selector_modal";

export const RESET_EDITED_FILTERS = "reset_edited_filters";
export const APPLY_FILTERS = "apply_filters";
export const APPLY_HIGHLIGHTS = "apply_highlights";

export const CLEAR_ALL_LEAD_FILTERS = "clear_all_lead_filters";
export const CLEAR_EDITED_FILTERS = "clear_edited_filters";

export const SET_TAG_MODAL = "set_tag_modal";
export const UPDATE_TAG_SEARCH = "update_tag_search";
export const TOGGLE_TAG_MODAL = "toggle_tag_modal";

export const GET_TEAM_FILTERS = "get_team_filters";
export const REFRESH_TEAM_FILTERS = "refresh_team_filters";
export const GET_TEAM_FILTERS_FAIL = "get_team_filters_fail";
export const GET_TEAM_FILTERS_SUCCESS = "get_team_filters_success";

export const CREATE_TEAM_FILTER = "create_team_filter";
export const CREATE_TEAM_FILTER_FAIL = "create_team_filter_fail";
export const CREATE_TEAM_FILTER_SUCCESS = "create_team_filter_success";

export const UPDATE_TEAM_FILTER = "update_team_filter";
export const UPDATE_TEAM_FILTER_FAIL = "update_team_filter_fail";
export const UPDATE_TEAM_FILTER_SUCCESS = "update_team_filter_success";

export const REMOVE_TEAM_FILTER = "remove_team_filter";
export const REMOVE_TEAM_FILTER_FAIL = "remove_team_filter_fail";
export const REMOVE_TEAM_FILTER_SUCCESS = "remove_team_filter_success";

export const SELECT_TEAM_FILTER = "select_team_filter";

export const TOGGLE_HIGHLIGHT_FILTERS = "toggle_highlight_filters";

export const SET_FILTERS = "set_filters";
export const SET_PRESET_MODAL = "set_preset_modal";
export const TOGGLE_PRESET_MODAL = "toggle_preset_modal";

export const TOGGLE_ADVANCED_FILTERS = "toggle_advanced_filters";
export const GET_SAVED_FILTERS = "get_saved_filters";
export const GET_SAVED_FILTERS_FAIL = "get_saved_filters_fail";
export const GET_SAVED_FILTERS_SUCCESS = "get_saved_filters_success";

export const SET_APPLIED_FILTER = "set_applied_filter";

export const CREATE_SAVED_FILTER_SUCCESS = "create_saved_filter_success";
export const UPDATE_SAVED_FILTER_SUCCESS = "update_saved_filter_success";
export const REMOVE_SAVED_FILTER_SUCCESS = "remove_saved_filter_success";
