import React, { Component } from "react";

import { Copy, Wrapper, Row, Button } from "app/NativeComponents/common";

class QuickPositions extends Component {
  render() {
    const { layer, colors, updateLayer, card_width, card_height } = this.props;

    const tiny_card_width = card_width / 15;
    const tiny_card_height = card_height / 15;

    return (
      <Wrapper>
        <Row>
          {/*half horizontal*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: 0,
                  width: card_width / 2,
                  height: card_height
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    width: tiny_card_width / 2,
                    height: tiny_card_height,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*other half horizontal*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: card_width / 2,
                  y: 0,
                  width: card_width / 2,
                  height: card_height
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginLeft: tiny_card_width / 2,
                    width: tiny_card_width / 2,
                    height: tiny_card_height,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*half vertical*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: 0,
                  width: card_width,
                  height: card_height / 2
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    width: tiny_card_width,
                    height: tiny_card_height / 2,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*other half vertical*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: card_height / 2,
                  width: card_width,
                  height: card_height / 2
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginTop: tiny_card_height / 2,
                    width: tiny_card_width,
                    height: tiny_card_height / 2,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>
        </Row>

        <Row>
          {/*top left quarter horizontal*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: 0,
                  width: card_width / 2,
                  height: card_height / 2
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    width: tiny_card_width / 2,
                    height: tiny_card_height / 2,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*top right quarter horizontal*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: card_width / 2,
                  y: 0,
                  width: card_width / 2,
                  height: card_height / 2
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginLeft: tiny_card_width / 2,
                    width: tiny_card_width / 2,
                    height: tiny_card_height / 2,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*bottom left quarter horizontal*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: card_height / 2,
                  width: card_width / 2,
                  height: card_height / 2
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginTop: tiny_card_height / 2,
                    width: tiny_card_width / 2,
                    height: tiny_card_height / 2,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*bottom right quarter horizontal*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: card_width / 2,
                  y: card_height / 2,
                  width: card_width / 2,
                  height: card_height / 2
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginTop: tiny_card_height / 2,
                    marginLeft: tiny_card_width / 2,
                    width: tiny_card_width / 2,
                    height: tiny_card_height / 2,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>
        </Row>

        <Row>
          {/*first third vertical*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: 0,
                  width: card_width / 3,
                  height: card_height
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    width: tiny_card_width / 3,
                    height: tiny_card_height,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*first two thirds vertical*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: 0,
                  y: 0,
                  width: card_width / 1.5,
                  height: card_height
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    width: tiny_card_width / 1.5,
                    height: tiny_card_height,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*second two thirds vertical*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: card_width / 3,
                  y: 0,
                  width: card_width / 1.5,
                  height: card_height
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginLeft: tiny_card_width / 3,
                    width: tiny_card_width / 1.5,
                    height: tiny_card_height,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>

          {/*second one third vertical*/}
          <Button
            onPress={() => {
              updateLayer({
                layer: {
                  ...layer,
                  x: card_width / 1.5,
                  y: 0,
                  width: card_width / 3,
                  height: card_height
                }
              });
            }}
          >
            <Wrapper style={{ padding: 10 }}>
              <Wrapper
                style={{
                  width: tiny_card_width,
                  height: tiny_card_height,
                  backgroundColor: colors.card_color
                }}
              >
                <Wrapper
                  style={{
                    marginLeft: tiny_card_width / 1.5,
                    width: tiny_card_width / 3,
                    height: tiny_card_height,
                    backgroundColor: colors.actionable_text_color
                  }}
                ></Wrapper>
              </Wrapper>
            </Wrapper>
          </Button>
        </Row>
      </Wrapper>
    );
  }
}

export default QuickPositions;
