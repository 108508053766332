export const SET_ACTIVE_DIALER_CONTACT = "set_active_dialer_contact";
export const UPDATE_ACTIVE_CALL = "update_active_call";
export const RESET_ACTIVE_CALL = "reset_active_call";

export const UPDATE_DIALER_CONVERSATION = "update_dialer_conversation";
export const UPDATE_DIALER_CONVERSATION_MESSAGE =
  "update_dialer_conversation_message";
export const SET_DIALER_CONVERSATION = "set_dialer_conversation";
export const SET_REJECTED_CALL = "set_rejected_call";

export const REMOVE_DIALER_CONVERSATION_MESSAGE =
  "remove_dialer_conversation_message";

export const SET_CURRENT_CALL_SESSION = "set_current_call_session";

export const UPDATE_CALLED_NUMBERS = "update_called_numbers";
export const UPDATE_QUEUED_NUMBERS = "update_queued_numbers";
export const SET_FOLLOW_UP_QUEUE_COUNT = "set_follow_up_queue_count";
export const UPDATE_CALL_SESSION_COUNT = "update_call_session_count";

export const TRIGGER_FOLLOW_UP_QUEUE_REFRESH =
  "trigger_follow_up_queue_refresh";
