import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrapper, Button, InternalImage } from "app/NativeComponents/common";
import { InlineButton } from "app/NativeComponents/snippets";
import { checkIfUserHasMetadata } from "app/NativeActions";

class Logo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  render() {
    const { colors, device, tab, local_tab, user } = this.props;
    const call_tracking = checkIfUserHasMetadata("call_tracking");

    if (!this.props.user) {
      return (
        <Wrapper
          style={{
            borderRadius: 0,
            margin: 0,
            height: 60,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <Wrapper
            style={{
              width: 200,
              height: 60,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <InternalImage
              style={{
                height: 60,
                width: 160,
                resizeMode: "contain"
              }}
              resizeMode={"contain"}
              contain
              image={
                this.props.device == "mobile"
                  ? ""
                  : this.props.dark_mode == "dark_mode"
                  ? "/assets/images/dm_white_logo_medium.png"
                  : "/assets/images/dm_white_logo_medium.png"
              }
            />
          </Wrapper>
        </Wrapper>
      );
    }

    if (call_tracking) {
      return (
        <Wrapper
          style={{
            borderRadius: 0,
            margin: 0,
            height: 60,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 0,
            paddingBottom: 0
          }}
        >
          <Wrapper
            style={{
              backgroundColor: "transparent",
              width: 60,
              height: 60,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <InternalImage
              style={{
                height: 30,
                width: 30,
                resizeMode: "contain"
              }}
              resizeMode={"contain"}
              contain
              image={
                this.props.device == "mobile"
                  ? ""
                  : this.props.dark_mode == "dark_mode"
                  ? "/assets/images/Icon-white.png"
                  : "/assets/images/Icon-white.png"
              }
            />
          </Wrapper>
        </Wrapper>
      );
    }

    return (
      <Button
        onPress={() => {
          if (user.team_clearance_level > 0) {
            this.props.changeTab("dashboard", tab);
            this.props.tabNavigation({ slug: "dashboard" });
          }
        }}
        noPress={user.team_clearance_level == 0}
        onHover={() => {
          this.setState({
            hovering: true
          });
        }}
        onHoverOut={() => {
          this.setState({
            hovering: false
          });
        }}
        style={{
          borderRadius: 0,
          margin: 0,
          height: 60,
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 0,
          paddingBottom: 0
        }}
      >
        <Wrapper
          style={{
            backgroundColor:
              this.state.hovering || local_tab == "dashboard"
                ? colors.hover_color
                : "transparent",
            width: 60,
            height: 60,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <InternalImage
            style={{
              height: 30,
              width: 30,
              resizeMode: "contain"
            }}
            resizeMode={"contain"}
            contain
            image={
              this.props.device == "mobile"
                ? ""
                : this.props.dark_mode == "dark_mode"
                ? "/assets/images/Icon-white.png"
                : "/assets/images/Icon-white.png"
            }
          />
        </Wrapper>
      </Button>
    );
  }
}

export default Logo;
