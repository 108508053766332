import React, { Component } from "react";

import {
  Wrapper,
  CardBody,
  Copy,
  Spin,
  Row,
  Button,
  Icon,
  Bold
} from "app/NativeComponents/common";
import {
  InputBox,
  GhostButton,
  SpinWrapper
} from "app/NativeComponents/snippets";
import Step from "./Step";

import moment from "moment";

class CampaignSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_campaign_title: props.mailer_campaign.campaign_title,
      add_button_hover: false
    };

    this.updateCampaignStep = this.updateCampaignStep.bind(this);
    this.removeCampaignStep = this.removeCampaignStep.bind(this);

    this._campaign_title = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.mailer_campaign.campaign_title !==
      prevProps.mailer_campaign.campaign_title
    ) {
      this.setState({
        new_campaign_title: this.props.mailer_campaign?.campaign_title
      });
    }
  }

  updateCampaignStep({ campaign_step, prop, value }) {
    let new_campaign_step = campaign_step;

    if (prop == "resend_freq") {
      let resend_limit = campaign_step.resend_limit;

      if (campaign_step.resend_freq == 0 && value > 0) {
        resend_limit = 6;
      } else if (value == 0) {
        resend_limit = 0;
      }
      new_campaign_step = {
        ...new_campaign_step,
        [prop]: value,
        resend_limit
      };
    } else if (prop == "mailer") {
      const mailer = value;
      new_campaign_step = {
        ...new_campaign_step,
        mailer,
        template_id: mailer.id,
        template_title: mailer.template_title
      };
    } else {
      new_campaign_step = {
        ...new_campaign_step,
        [prop]: value
      };
    }

    let campaign_steps = this.props.mailer_campaign?.campaign_steps;
    if (campaign_steps) {
      for (let i = 0; i < campaign_steps.length; i++) {
        if (campaign_steps[i].step_order == campaign_step.step_order) {
          campaign_steps[i] = new_campaign_step;
        }
      }
    }
    this.props.editMailerCampaign({
      prop: "campaign_steps",
      value: campaign_steps || []
    });
  }

  removeCampaignStep(campaign_step) {
    let campaign_steps = this.state.campaign_steps;

    campaign_steps = campaign_steps.filter(
      item => item.step_order != campaign_step.step_order
    );

    for (let i = 0; i < campaign_steps.length; i++) {
      campaign_steps[i].step_order = i + 1;
    }

    this.props.editMailerCampaign({
      prop: "campaign_steps",
      value: campaign_steps || []
    });
  }

  renderAddButton() {
    const { colors, editable, mailer_campaign } = this.props;
    const { add_button_hover } = this.state;
    if (editable) {
      return (
        <Wrapper>
          <Button
            onHover={() => {
              this.setState({ add_button_hover: true });
            }}
            onHoverOut={() => {
              this.setState({ add_button_hover: false });
            }}
            onPress={() => {
              const template = this.props.getRandomTemplate();
              this.props.editMailerCampaign({
                prop: "campaign_steps",
                value: [
                  ...mailer_campaign.campaign_steps,
                  {
                    id:
                      "new_step_" + (mailer_campaign.campaign_steps.length + 1),
                    type: "new_step",
                    template_id: template.id,
                    template_title: template.template_title,
                    resend_freq: 21,
                    resend_limit: 6,
                    step_label: "",
                    step_type: "direct_mail",
                    step_order: mailer_campaign.campaign_steps.length + 1,
                    send_after: 21,
                    date_created: moment().format("YYYY-MM-DD HH:mm:ss"),
                    mailer: template
                  }
                ]
              });
            }}
          >
            <Row>
              <Wrapper
                style={{
                  backgroundColor: add_button_hover
                    ? colors.orange_color
                    : colors.orange_color_muted,
                  margin: 5,
                  padding: 10,
                  paddingTop: 25,
                  paddingBottom: 25,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 60
                }}
              >
                <Icon
                  icon="add"
                  color={
                    add_button_hover
                      ? colors.white_text_color
                      : colors.orange_color
                  }
                  size={18}
                />
              </Wrapper>
              <Wrapper
                style={{
                  flex: 1,
                  backgroundColor: add_button_hover ? colors.hover_color : null,
                  alignItems: "flex-start",
                  justifyContent: "center",
                  alignSelf: "stretch",
                  padding: 25,
                  margin: 5,
                  marginLeft: 0
                }}
              >
                <Copy style={{ color: colors.orange_color }}>
                  <Bold>Add a new step to the mail sequence</Bold>
                </Copy>
              </Wrapper>
            </Row>
          </Button>
          <Wrapper
            style={{
              backgroundColor: colors.background_color,
              padding: 25,
              margin: 5,
              borderRadius: 5
            }}
          >
            <Copy style={{ color: colors.light_text_color, fontSize: 12 }}>
              NOTE: Each step added to a mail sequence is a different piece of
              mail. Use the step repeating function if you want to send the
              smail mail multiple times
            </Copy>
          </Wrapper>
        </Wrapper>
      );
    }
  }

  renderSteps() {
    const { colors, mailer_campaign, new_mailer_campaign, editable } =
      this.props;
    return (
      <Wrapper style={{ backgroundColor: colors.card_color }}>
        {mailer_campaign.campaign_steps?.map((step, i) => {
          return (
            <Step
              key={"step_" + i}
              colors={colors}
              mailer_campaign={mailer_campaign}
              campaign_type={mailer_campaign?.campaign_type}
              campaign_step={step}
              editable={step.type === "new_step" || editable ? true : false}
              updateCampaignStep={this.updateCampaignStep}
              removeCampaignStep={this.removeCampaignStep}
              mailer_templates={this.props.mailer_templates}
              repeat_mail_sequence={mailer_campaign?.repeat_mail_sequence}
              number_of_steps={mailer_campaign?.campaign_steps?.length}
              editMailerCampaign={this.props.editMailerCampaign}
            />
          );
        })}

        {mailer_campaign.campaign_type !== "repeating_mail" &&
        (new_mailer_campaign || editable)
          ? this.renderAddButton()
          : null}

        {this.renderAutoRepeat()}
      </Wrapper>
    );
  }

  renderAutoRepeat() {
    const { mailer_campaign, colors } = this.props;
    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 40
          }}
        >
          <GhostButton
            disabled={!this.props.editable}
            onPress={() => {
              this.props.editMailerCampaign({
                prop: "repeat_mail_sequence",
                value: mailer_campaign.repeat_mail_sequence == 1 ? 0 : 1
              });
            }}
            icon={"repeat"}
            innerStyle={
              mailer_campaign.repeat_mail_sequence == 1
                ? {
                    backgroundColor: colors.active_color_muted,
                    borderColor: colors.actionable_text_color
                  }
                : {
                    backgroundColor: colors.background_color,
                    borderColor: colors.border_color
                  }
            }
            textColor={
              mailer_campaign.repeat_mail_sequence == 1
                ? colors.actionable_text_color
                : colors.border_color
            }
          >
            Auto-repeat last step in mail sequence
          </GhostButton>
        </Wrapper>
      </>
    );
  }

  render() {
    const {
      mailer_campaign,
      editable,
      active_mailer_campaign_loading,
      new_mailer_campaign = false
    } = this.props;
    const { new_campaign_title } = this.state;

    if (active_mailer_campaign_loading) {
      return <SpinWrapper text="Loading..." />;
    }

    if (new_mailer_campaign || editable) {
      return (
        <Wrapper>
          <InputBox
            input_ref={this._campaign_title}
            autoFocus={false}
            name="campaign_title"
            disabled={false}
            returnKeyType="done"
            style={{
              margin: 5,
              borderRadius: 5
            }}
            placeholder={
              !!new_campaign_title
                ? "Mail sequence name"
                : "Enter a name for this mail sequence"
            }
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                new_campaign_title: value
              });
            }}
            blurOnSubmit={true}
            value={new_campaign_title}
            input_type="text"
            require_confirm={true}
            has_changed={
              this.props.editable ||
              this.state.new_campaign_title !==
                this.props.mailer_campaign?.campaign_title
                ? true
                : false
            }
            submit_button_primary={true}
            submit_button_title={
              this.props.mailer_campaign?.id == "new_mailer_campaign"
                ? "Create"
                : "Save"
            }
            onSubmit={() => {
              if (this.props.mailer_campaign?.id == "new_mailer_campaign") {
                this.props.updateMailerCampaign({
                  token: this.props.token,
                  type: "create_mailer_campaign",
                  title: this.state.new_campaign_title,
                  campaign_steps: this.props.mailer_campaign.campaign_steps,
                  repeat_mail_sequence:
                    this.props.mailer_campaign.repeat_mail_sequence,
                  onLoading: () => {
                    this.setState({ loading: true });
                  },
                  onError: () => {
                    this.setState({ loading: false });
                  },
                  onSuccess: results => {
                    this.setState({ loading: false });

                    if (this.props.onSuccess && results?.mailer_campaigns[0]) {
                      this.props.onSuccess(results?.mailer_campaigns[0]);
                    }
                    this.props.popSidePanel();
                  }
                });
              } else {
                this.props.updateMailerCampaign({
                  token: this.props.token,
                  type: "update_mailer_campaign",
                  title: this.state.new_campaign_title,
                  mailer_campaign_id: this.props.mailer_campaign?.id,
                  campaign_steps: this.props.mailer_campaign?.campaign_steps,
                  repeat_mail_sequence:
                    this.props.mailer_campaign?.repeat_mail_sequence,
                  onLoading: () => {
                    this.setState({ loading: true });
                  },
                  onError: () => {
                    this.setState({ loading: false });
                  },
                  onSuccess: results => {
                    this.setState({ loading: false });

                    if (this.props.onSuccess && results?.mailer_campaigns[0]) {
                      this.props.onSuccess(results?.mailer_campaigns[0]);
                    }
                    this.props.popSidePanel();
                  }
                });
              }
            }}
            onSubmitEditing={() => {}}
            loading={this.state.loading}
          />

          {this.renderSteps()}
        </Wrapper>
      );
    }

    if (
      !active_mailer_campaign_loading &&
      mailer_campaign &&
      mailer_campaign?.campaign_steps?.length > 0
    ) {
      return this.renderSteps();
    }
    return null;
  }
}

export default CampaignSteps;
