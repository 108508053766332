import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Card,
  CardBody,
  Icon,
  Wrapper,
  Title,
  Copy,
  Bold,
  Animation,
  Stretch,
  Split,
  Row,
  Button
} from "app/NativeComponents/common";

import { resetErrorMessage } from "app/NativeActions";
import DismissButton from "./DismissButton";

class ErrorMessage extends PureComponent {
  componentDidMount() {
    clearInterval(this._interval);
    this._interval = setInterval(() => {
      this.handleReset();
    }, 5000);
  }

  handleReset() {
    clearInterval(this._interval);
    this.props.resetErrorMessage();
  }

  renderText(text, title) {
    return (
      <Wrapper>
        <Copy style={{ color: this.props.colors.actionable_text_color }}>
          <Bold>{title && title != "" ? title : "Error"}</Bold>
        </Copy>
        <Copy>
          {text &&
          text != "" &&
          text != "NETWORK_ERROR" &&
          text != "TIMEOUT_ERROR" &&
          text != "SERVER_ERROR"
            ? text
            : text == "NETWORK_ERROR"
            ? "Trouble connecting to the network. Please make sure you're connected to the internet"
            : "Something went wrong. Please try again."}
        </Copy>
      </Wrapper>
    );
  }

  render() {
    const { size, colors, text, title, device } = this.props;
    return (
      <Wrapper
        style={
          device == "desktop"
            ? {
                backgroundColor: "transparent",
                flex: 1,
                position: "fixed",
                right: 0,
                bottom: 0,
                width: "100%",
                maxWidth: 500,
                justifyContent: "flex-end",
                alignItems: "center",
                paddingBottom: this.props.isIphoneX ? 15 : 5,
                zIndex: 4
              }
            : {
                backgroundColor: "rgba(0,0,0,0.05)",
                flex: 1,
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                elevation: 6,
                paddingBottom: this.props.isIphoneX ? 15 : 5
              }
        }
      >
        <Animation
          style={{ alignSelf: "stretch" }}
          type="fadeInUp"
          duration={500}
        >
          <Wrapper
            style={{
              backgroundColor: this.props.colors.orange_color_muted,
              borderRadius: 5,
              margin: 10
            }}
          >
            <Row>
              <Stretch>
                <CardBody
                  style={{
                    padding: 25
                  }}
                >
                  <Row>
                    <Icon
                      color={this.props.colors.orange_color}
                      icon={"error"}
                      size={26}
                      style={{ marginRight: 10 }}
                    />
                    <Stretch>{this.renderText(text, title)}</Stretch>
                  </Row>
                </CardBody>
              </Stretch>
              <DismissButton
                {...this.props}
                handleReset={this.handleReset.bind(this)}
              />
            </Row>
          </Wrapper>
        </Animation>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ native, settings }) => {
  const { device, isIphoneX } = native;
  const { colors } = settings;
  return { device, isIphoneX, colors };
};

export default connect(mapStateToProps, { resetErrorMessage })(ErrorMessage);
