import React, { PureComponent } from "react";
import { Wrapper, KeyboardView, Copy } from "app/NativeComponents/common";
import {
  UpsellButton,
  SmallLabel,
  BottomNavBar,
  InlineButton
} from "app/NativeComponents/snippets";

import ListName from "./ListName";

import { numberWithCommas, getNewFiltersString } from "app/NativeActions";
import ListBuilderCountLimits from "./ListBuilderCountLimits";

class ListBuilderComplete extends PureComponent {
  render() {
    const {
      colors,
      isMobile,
      estimated_count,
      count_loading,
      list_name,
      lists_info_banner,
      source_of_truth
    } = this.props;
    if (isMobile) {
      const can_press =
        this.props.checkIfCanBuild() &&
        !!list_name &&
        !count_loading &&
        estimated_count > 0;
      let new_list_count = 0;
      if (this.props.user?.user_version >= 6) {
        new_list_count =
          parseInt(
            source_of_truth?.current_limits
              ?.number_of_leads_added_from_list_builder?.amount
          ) + parseInt(estimated_count);
      } else {
        new_list_count =
          parseInt(lists_info_banner?.current_count) +
          parseInt(estimated_count);
      }

      return (
        <>
          <ListName {...this.props} />
          <KeyboardView style={{ flex: 1 }}>
            {/*
            <SelectItem
              select_type="check"
              selected={false}
              icon="keyboard-arrow-right"
              onPress={() => {}}
              description={"$57.00"}
              //require_confirm={true}
            >
              Start mail
            </SelectItem>
            <SelectItem
              select_type="check"
              selected={false}
              onPress={() => {}}
              description={"$57.00"}
              //require_confirm={true}
            >
              Skip trace
            </SelectItem>
            */}
            <Wrapper style={{ padding: 25 }}>
              <SmallLabel style={{ padding: 0 }}>
                Selected motivations:
              </SmallLabel>
              <Copy>{getNewFiltersString(this.props.filters)}</Copy>
              <SmallLabel style={{ padding: 0 }}>Selected market:</SmallLabel>

              <Copy>
                {this.props.location_type == "city"
                  ? this.props.city + ", " + this.props.state
                  : this.props.location_type == "zip"
                  ? this.props.zip
                  : "Drawn area in " + this.props.zip}
              </Copy>

              <SmallLabel style={{ padding: 0 }}>
                Estimated number of leads:
              </SmallLabel>
              <Copy>{numberWithCommas(estimated_count)}</Copy>
            </Wrapper>
          </KeyboardView>

          <BottomNavBar>
            <UpsellButton
              type="plan"
              slug="lists"
              tier={0}
              contentful_slug="unlock_list_builder"
              onPress={() => {
                this.props.buildList();
              }}
              force_lock={
                (new_list_count > parseInt(lists_info_banner?.limit) &&
                  lists_info_banner?.limit != -1 &&
                  this.props.user?.user_version < 6) ||
                (this.props.user?.user_version >= 6 &&
                  new_list_count >
                    parseInt(
                      source_of_truth?.subscription?.metadata
                        ?.leads_added_from_list_builder
                    ) &&
                  source_of_truth?.subscription?.metadata
                    ?.leads_added_from_list_builder != "unlimited") ||
                (this.props.user?.user_version >= 6 &&
                  !source_of_truth?.subscription?.metadata?.list_builder)
              }
              meta_slug="leads_added_from_list_builder"
              onLockPressOverride={
                lists_info_banner?.free_trial &&
                this.props.user?.user_version < 6
                  ? () => {
                      this.props.setModal({
                        title: lists_info_banner?.pay_now_confirm_title,
                        description:
                          lists_info_banner?.pay_now_confirm_description,
                        submit: lists_info_banner?.pay_now_confirm_button,
                        onPress: () => {
                          this.props.updatePlan({
                            token: this.props.token,
                            slug: lists_info_banner?.slug,
                            type: "bypass_trial"
                          });
                        },
                        cancel: "Cancel",
                        onCancel: () => {}
                      });
                      this.props.toggleModal({ show: true, type: "normal" });
                    }
                  : null
              }
              renderLockedChildren={({ hovering }) => {
                return (
                  <>
                    <InlineButton
                      noPress={true}
                      pressedIn={hovering}
                      button_type={"full"}
                      disabled={!can_press}
                      primary={can_press}
                      style={{
                        backgroundColor: can_press
                          ? colors.orange_color_muted
                          : "transparent"
                      }}
                      icon="lock"
                      renderBottom={() => {
                        return <ListBuilderCountLimits {...this.props} />;
                      }}
                    >
                      {lists_info_banner?.free_trial &&
                      this.props.user?.user_version < 6
                        ? "Pay Now"
                        : "Build List"}
                    </InlineButton>
                  </>
                );
              }}
              renderChildren={({ hovering }) => {
                return (
                  <>
                    <InlineButton
                      noPress={true}
                      pressedIn={hovering}
                      button_type={"full"}
                      disabled={!can_press}
                      primary={can_press}
                      style={{
                        backgroundColor: can_press
                          ? colors.orange_color_muted
                          : "transparent"
                      }}
                      icon="check"
                    >
                      Build List
                    </InlineButton>
                    <ListBuilderCountLimits {...this.props} />
                  </>
                );
              }}
            />
          </BottomNavBar>
        </>
      );
    }

    return null;
  }
}

export default ListBuilderComplete;
