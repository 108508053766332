import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  GET_PARTNER_ANALYTICS,
  REFRESH_PARTNER_ANALYTICS,
  GET_PARTNER_ANALYTICS_FAIL,
  GET_PARTNER_ANALYTICS_SUCCESS,
  SAVE_PARTNER_INFO,
  SAVE_PARTNER_INFO_FAIL,
  SAVE_PARTNER_INFO_SUCCESS,
  UPDATE_PARTNER_FIELD_CHANGE,
  INIT_UPDATE_PARTNER
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";

import API from "app/DealMachineCore/apis/DealMachineAPI";
const api = API.create();

export const savePartnerInfo = ({ token, type, payload }) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });

    switch (type) {
      default:
      case "signup":
        dispatch({ type: SAVE_PARTNER_INFO });
        break;
    }

    api.savePartner(token, type, payload).then(response => {
      if (response.problem != null) {
        savePartnerInfoFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        savePartnerInfoFail(dispatch, response.data.error);
        if (response.data.valid == "false") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        savePartnerInfoSuccess(
          dispatch,
          type,
          response.data.results.stats,
          response.data.results.users,
          response.data.results.promo_codes,
          response.data.results.drawer_stats
        );
      }
    });
  };
};

const savePartnerInfoFail = (dispatch, error) => {
  dispatch({ type: SAVE_PARTNER_INFO_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const savePartnerInfoSuccess = (
  dispatch,
  type,
  stats,
  users,
  promo_codes,
  drawer_stats
) => {
  dispatch({
    type: SAVE_PARTNER_INFO_SUCCESS,
    payload: { stats, users, promo_codes, drawer_stats }
  });

  switch (type) {
    case "signup":
    default:
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've signed up for our affiliate partner program.",
          title: "Success!"
        }
      });

      break;

    case "change_paypal_email":
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message: "You've updated your partner information.",
          title: "Success!"
        }
      });
      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { remove: "edit-paypal-email" }
      });

      break;

    case "change_website":
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: { message: "You've updated your website.", title: "Success!" }
      });

      appRedirect({
        dispatch,
        redirect: "goBack",
        payload: { remove: "edit-partner-website" }
      });

      break;
  }
};

export const getPartnerAnalytics = ({ token, type }) => {
  return dispatch => {
    switch (type) {
      default:
        dispatch({ type: GET_PARTNER_ANALYTICS });
        break;

      case "refresh":
        dispatch({ type: REFRESH_PARTNER_ANALYTICS });
        break;
    }

    api.partner(token).then(response => {
      if (response.problem != null) {
        getPartnerAnalyticsFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        getPartnerAnalyticsFail(dispatch, response.data.error);
        if (response.data.valid == "false") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        getPartnerAnalyticsSuccess(
          dispatch,
          response.data.results.stats,
          response.data.results.users,
          response.data.results.promo_codes,
          response.data.results.drawer_stats
        );
      }
    });
  };
};

const getPartnerAnalyticsFail = (dispatch, error) => {
  dispatch({ type: GET_PARTNER_ANALYTICS_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const getPartnerAnalyticsSuccess = (
  dispatch,
  stats,
  users,
  promo_codes,
  drawer_stats
) => {
  dispatch({
    type: GET_PARTNER_ANALYTICS_SUCCESS,
    payload: { stats, users, promo_codes, drawer_stats }
  });
};

export const initUpdatePartner = ({ partner_info }) => {
  return {
    type: INIT_UPDATE_PARTNER,
    payload: { partner_info }
  };
};

export const updatePartnerFieldChange = ({ prop, value }) => {
  return {
    type: UPDATE_PARTNER_FIELD_CHANGE,
    payload: { prop, value }
  };
};
