import React, { Component } from "react";

import {
  Wrapper,
  Copy,
  InnerCopy,
  Bold,
  Row,
  Icon
} from "app/NativeComponents/common";

import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";

import moment from "moment";
import { store } from "app/store";

import { getHelperDescription } from "app/NativeActions";

class PropertyFlags extends Component {
  constructor(props) {
    super(props);

    this.renderMobileWrapper = this.renderMobileWrapper.bind(this);
  }

  renderMobileWrapper(renderContent = () => {}, key, title, content) {
    const device = store.getState().native.device;

    if (device === "mobile") {
      return (
        <MoreInfoButton
          key={key}
          title={title}
          content={content}
          style={{ alignSelf: "stretch" }}
          innerStyle={{ alignSelf: "stretch" }}
          renderComponent={({ hovering, pressedIn, buttonText }) => {
            return renderContent();
          }}
        />
      );
    }

    return renderContent();
  }

  render() {
    const { property, colors } = this.props;
    const device = store.getState().native.device;
    if (property?.id > 0) {
      return (
        <Row style={{ flexWrap: "wrap" }}>
          {property?.property_flags &&
            property?.property_flags.length > 0 &&
            property?.property_flags.map((flag, i) => {
              if (!!flag.value) {
                let b_color = colors.gray_selected_color;
                let subtext = "";
                switch (flag?.value) {
                  case "mls_active":
                  case "mls_pending":
                  case "mls_contingent":
                    b_color = colors.active_color_muted;
                    break;
                  case "recently_sold":
                    b_color = colors.orange_color_muted;
                    break;
                  case "expired_listing":
                    b_color = colors.orange_color_muted;
                    break;
                  default:
                    break;
                }

                if (!this.props.round || i < 2) {
                  return this.renderMobileWrapper(
                    () => {
                      return (
                        <Wrapper
                          key={"flag" + i}
                          style={
                            this.props.round
                              ? {
                                  backgroundColor: b_color,
                                  borderRadius: 5,
                                  padding: 5,
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  marginRight: 5,
                                  marginBottom: 5,
                                  opacity: this.props.dialer_mode ? 0.5 : 1
                                }
                              : {
                                  backgroundColor: b_color,
                                  borderRadius: 5,
                                  padding: 5,
                                  paddingRight: 15,
                                  paddingLeft: 15,
                                  marginRight: 5,
                                  marginBottom: 5,
                                  opacity: this.props.dialer_mode ? 0.5 : 1
                                }
                          }
                        >
                          <Row>
                            <Copy
                              style={{ fontSize: this.props.round ? 10 : 12 }}
                            >
                              {flag?.label}
                            </Copy>
                            {device === "desktop" ? (
                              <Wrapper
                                tooltipPlacement={"top"}
                                tooltip={getHelperDescription(flag?.value)}
                                style={{ marginLeft: 5 }}
                              >
                                <Icon
                                  icon={"help"}
                                  color={colors.light_text_color}
                                  size={12}
                                />
                              </Wrapper>
                            ) : null}
                          </Row>
                        </Wrapper>
                      );
                    },
                    "flag" + i,
                    flag?.label,
                    getHelperDescription(flag?.value)
                  );
                }
                if (this.props.round && i === 2) {
                  return this.renderMobileWrapper(() => {
                    let rest_of_flags = "";
                    for (let i = 2; i < property?.property_flags.length; i++) {
                      if (i === 2) {
                        rest_of_flags += property?.property_flags[i].label;
                      } else {
                        rest_of_flags +=
                          ", " + property?.property_flags[i].label;
                      }
                    }
                    return (
                      <Wrapper
                        key={"flag" + i}
                        style={{
                          backgroundColor: b_color,
                          borderRadius: 5,
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10,
                          marginRight: 5,
                          marginBottom: 5,
                          opacity: this.props.dialer_mode ? 0.5 : 1
                        }}
                        tooltip={rest_of_flags}
                        tooltipPlacement={"top"}
                      >
                        <Row>
                          <Copy style={{ fontSize: 10 }}>
                            +{property?.property_flags.length - 2}
                          </Copy>
                        </Row>
                      </Wrapper>
                    );
                  }, "flag" + i);
                }
              }
              return null;
            })}
        </Row>
      );
    }

    return null;
  }
}

export default PropertyFlags;
