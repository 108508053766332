import React, { Component } from "react";
import { DropzoneButton } from "app/NativeComponents/common";
import { GhostButton, InlineButton } from "app/NativeComponents/snippets";

class UploadButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { colors, token } = this.props;
    return (
      <DropzoneButton
        accept="image/jpeg, image/png"
        maxSize={5242880}
        onDrop={acceptedFiles => {
          this.setState({
            uploading: true
          });
          this.props.uploadFile({
            acceptedFiles: acceptedFiles[0],
            token,
            onSuccess: results => {
              this.props.updateLayer({
                prop: "image_url",
                value: results.file
              });

              this.setState({
                uploading: false
              });
            },
            onError: error => {
              this.setState({
                uploading: false
              });
            }
          });
        }}
        renderComponent={options => {
          return (
            <InlineButton
              icon="file-upload"
              noPress={true}
              pressedIn={options.hovering}
              primary={false}
              upload_type="photos"
              loading={this.state.uploading}
            >
              Upload Photo
            </InlineButton>
          );
        }}
      ></DropzoneButton>
    );
  }
}

export default UploadButton;
