import React, { Component } from "react";
import {
  Wrapper,
  Card,
  PrimaryButton,
  Button,
  CardBody,
  Copy,
  Bold,
  Row,
  GoogleOAuth,
  AppleLogin
} from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

import { displayIntercom } from "app/NativeActions";

class Buttons extends Component {
  renderChatButton() {
    if (this.props.platform == "ios") {
      return (
        <Button onPress={() => displayIntercom()} id="intercom_button">
          <CardBody
            style={{
              paddingTop: 0
            }}
          >
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Copy
                style={{
                  textAlign: "center"
                }}
              >
                Need help? Chat with us.
              </Copy>
            </Wrapper>
          </CardBody>
        </Button>
      );
    }
  }

  render() {
    return (
      <Wrapper>
        <GhostButton
          loading={this.props.loading}
          button_type="full"
          primary={true}
          onPress={() => this.props.login()}
        >
          Continue With Email
        </GhostButton>

        <Row
          style={{
            padding: 20,
            paddingTop: 10,
            paddingBottom: 10,
            justifyContent: "center"
          }}
        >
          <Wrapper
            style={{
              flex: 1,
              height: 1,
              backgroundColor: this.props.colors.border_color
            }}
          />
          <Copy
            style={{
              width: 50,
              textAlign: "center",
              marginLeft: 5,
              marginRight: 5
            }}
          >
            Or
          </Copy>
          <Wrapper
            style={{
              flex: 1,
              height: 1,
              backgroundColor: this.props.colors.border_color
            }}
          />
        </Row>

        {this.props.device == "desktop" ? (
          <>
            <GoogleOAuth
              onSuccess={this.props.login}
              buttonText={"Sign in with Google"}
              style={{ margin: 5 }}
            />

            <AppleLogin
              onSucc={this.props.appleLogin}
              style={{
                marginTop: 0,
                margin: 5,
                marginLeft: 15,
                marginRight: 15
              }}
            />
          </>
        ) : (
          <>
            <GoogleOAuth
              onSuccess={this.props.login}
              buttonText={"Sign in with Google"}
            />
            {this.props.platform !== "android" ? (
              <AppleLogin
                onSucc={this.props.appleLogin}
                style={{ margin: 5, marginLeft: 10, marginRight: 10 }}
              />
            ) : null}
          </>
        )}
      </Wrapper>
    );
  }
}

export default Buttons;
