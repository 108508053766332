import React, { PureComponent } from "react";

import { Scroll, Wrapper } from "app/NativeComponents/common";
import { SpinWrapper, InlineButton } from "app/NativeComponents/snippets";

class List extends PureComponent {
  componentDidMount(prevProps) {}
  renderHeader(listHeader) {
    if (listHeader) {
      return listHeader();
    }
  }
  renderFooter(listFooter) {
    if (listFooter) {
      return listFooter();
    }
  }
  renderLoadMore({
    canLoadMore,
    onLoadMore,
    isLoadingMore,
    load_more_text,
    loading_more_text
  }) {
    if (canLoadMore || isLoadingMore) {
      return (
        <Wrapper
          style={{
            padding: 20,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <InlineButton
            loading={isLoadingMore}
            button_type="full"
            style={{ margin: 5 }}
            onPress={() => {
              onLoadMore();
            }}
            icon="refresh"
          >
            {isLoadingMore ? loading_more_text : load_more_text}
          </InlineButton>
        </Wrapper>
      );
    }
  }

  render() {
    const {
      id,
      style,
      innerStyle,
      items = [],
      itemStructure,
      canLoadMore,
      onLoadMore,
      isLoadingMore,
      listHeader,
      listFooter,
      invertedScroll,
      reverse = false,
      noScroll = false,
      horizontal = false,
      scroll_ref = null,
      load_more_text = "Load More",
      loading_more_text = "Loading..."
    } = this.props;

    return (
      <Scroll
        id={id}
        horizontal={horizontal}
        scroll_ref={scroll_ref}
        style={{ flex: 1, ...style }}
      >
        {this.renderHeader(listHeader)}
        <div style={innerStyle}>
          {reverse && items && items.length > 0
            ? items
                .slice(0)
                .reverse()
                .map((item, index) => {
                  return itemStructure({ item, index });
                })
            : invertedScroll && items && items.length > 0
            ? items
                .slice(0)
                .reverse()
                .map((item, index) => {
                  return itemStructure({ item, index });
                })
            : items && items.length > 0
            ? items?.map((item, index) => {
                return itemStructure({ item, index });
              })
            : null}
          {this.renderLoadMore({
            canLoadMore,
            onLoadMore,
            isLoadingMore,
            load_more_text,
            loading_more_text
          })}
        </div>
        {this.renderFooter(listFooter)}
      </Scroll>
    );
  }
}

export { List };
