import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  SearchBar,
  List,
  InlineButton,
  SelectItem,
  BottomNavBar,
  InlineTabs
} from "app/NativeComponents/snippets";
import Filter from "./Filter";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";
import { isJson } from "app/NativeActions";

import {
  popSidePanel,
  getSavedFilters,
  updateSavedFilters,
  setAppliedFilter,
  getWorkflowInfo
} from "app/NativeActions";

class SavedFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selected_filter: null,
      edit_filter: props.applied_filter || null,
      show_saved_filters: false
    };

    this.backToFilters = this.backToFilters.bind(this);
    this.showSavedFilters = this.showSavedFilters.bind(this);
  }

  componentDidMount() {
    if (this.props.saved_filters.length == 0) {
      this.props.getSavedFilters({
        token: this.props.token
      });
    }
  }

  handleBack() {
    this.props.popSidePanel();
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  backToFilters() {
    this.setState({
      edit_filter: null
    });
  }

  showSavedFilters(show_saved_filters) {
    this.setState({
      show_saved_filters: show_saved_filters
    });
  }

  render() {
    const { applied_filter, colors, saved_filters, saved_filters_loading } =
      this.props;
    const { search, show_saved_filters, selected_filter, edit_filter } =
      this.state;
    return (
      <>
        {edit_filter ? (
          <>
            <NewHeader
              title={"Edit Filter"}
              subtitle={""}
              leftButton={{
                icon: "arrow-back",
                onPress: () => {
                  this.backToFilters();
                }
              }}
            />
            <Filter
              {...this.props}
              show_save={true}
              saved_filter={edit_filter}
              backToFilters={this.backToFilters}
            />
          </>
        ) : !show_saved_filters ? (
          <>
            <NewHeader
              title={"New Filter"}
              subtitle={""}
              leftButton={{
                icon: this.props.device == "mobile" ? "arrow-back" : "close",
                onPress: () => {
                  this.handleBack();
                }
              }}
            />

            <Filter
              {...this.props}
              saved_filter={{
                id: "new_filter",
                filter_name: "",
                filters: {
                  data: [],
                  andor_type: "or"
                }
              }}
              showSavedFilters={this.showSavedFilters}
              saved_filters={saved_filters}
            />
          </>
        ) : (
          <>
            <NewHeader
              title={"Saved Filters"}
              subtitle={""}
              leftButton={{
                icon: "arrow-back",
                onPress: () => {
                  this.showSavedFilters(false);
                }
              }}
            />

            {saved_filters_loading && saved_filters.length == 0 ? (
              <SpinWrapper text="Loading Filters..." />
            ) : (
              <>
                <Row>
                  <Wrapper style={{ flex: 1 }}>
                    <SearchBar
                      title="Search Saved Filters"
                      style={{
                        margin: 10,
                        marginRight: 0,
                        borderWidth: 0,
                        borderBottomWidth: 0
                      }}
                      onChange={value =>
                        this.setState({
                          search: value
                        })
                      }
                      value={this.state.search}
                    />
                  </Wrapper>
                </Row>
                <List
                  style={{ flex: 1 }}
                  items={this.filterOptions(saved_filters)}
                  itemStructure={({ item, index }) => {
                    const filters = isJson(item.filters)
                      ? JSON.parse(item.filters)
                      : item.filters;

                    return (
                      <SelectItem
                        key={"option_" + index}
                        icon={"keyboard-arrow-right"}
                        select_type="radio"
                        selected={
                          selected_filter
                            ? item.id == selected_filter
                            : item.id == applied_filter?.id
                        }
                        onPress={() => {
                          this.setState({
                            selected_filter: item.id
                          });
                        }}
                        confirm_text="Apply"
                        require_confirm={selected_filter ? true : false}
                        onConfirm={() => {
                          if (item.system_type == "legacy_filter") {
                            this.props.setAppliedFilter({
                              applied_filter: {
                                id: item.id,
                                filter_name: item.title,
                                fitlers: null,
                                old_filters: filters
                              }
                            });
                          } else {
                            this.props.setAppliedFilter({
                              applied_filter: {
                                id: item.id,
                                filter_name: item.title,
                                filters,
                                old_filters: null
                              }
                            });
                          }
                          this.props.popSidePanel();
                        }}
                        secondaryButton="Edit"
                        onSecondaryPress={() => {
                          this.setState({
                            selected_filter: null,
                            edit_filter: {
                              id: item.id,
                              system_type: item.system_type,
                              filter_name: item.title,
                              filters
                            }
                          });
                        }}
                        subtext={
                          item.system_type == "legacy_filter"
                            ? "Legacy filter"
                            : ""
                        }
                      >
                        {item.title}
                      </SelectItem>
                    );
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, filter, lead }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { saved_filters, saved_filters_loading, applied_filter } = filter;
  const { lead_filters } = lead;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    saved_filters,
    saved_filters_loading,
    applied_filter,
    lead_filters
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getSavedFilters,
  updateSavedFilters,
  setAppliedFilter,
  getWorkflowInfo
})(SavedFilters);
