import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  PillButton,
  Select,
  DateSelector,
  SmallHeader,
  BottomNavBar,
  GhostButton,
  SpinWrapper,
  InlineButton,
  SelectBox,
  IconButton,
  InputBox,
  DateBox
} from "app/NativeComponents/snippets";
import { getCustomFields } from "app/NativeActions";
import BracketPicker from "./BracketPicker";
import moment from "moment";

class CustomFieldsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_team: false,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            custom_fields: [
              {
                custom_field_id: 0,
                custom_field_type: "",
                custom_field_options: [],
                custom_field_title: "",
                custom_field_value: ""
              }
            ]
          }
    };
  }

  componentDidMount() {
    this.props.getCustomFields({
      token: this.props.token,
      type: "get_custom_fields"
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (this.state.data?.custom_fields.length > 0) {
        let fully_updated = true;
        for (let i = 0; i < this.state.data?.custom_fields.length; i++) {
          if (this.state.data?.custom_fields[i].custom_field_id == 0) {
            fully_updated = false;
          }
        }
        if (fully_updated) {
          return true;
        }
      }
    }

    return false;
  }

  formatFieldOptions(options) {
    let formatted_options = [];
    let all_options = options.split(",");

    formatted_options.push({
      value: null,
      label: "Not selected"
    });
    for (let i = 0; i < all_options.length; i++) {
      formatted_options.push({
        value: all_options[i],
        label: all_options[i]
      });
    }
    return formatted_options;
  }

  renderCustomFieldType({ custom_field, index }) {
    const { colors, enrollment_object, enrollment_object_loading } = this.props;
    const { data } = this.state;
    switch (custom_field.custom_field_type) {
      case "select":
        return (
          <Wrapper style={{ flex: 1 }}>
            <SelectBox
              items={this.formatFieldOptions(custom_field.custom_field_options)}
              value={custom_field.custom_field_value}
              style={{ flex: 1 }}
              placeholder="Set value"
              onFocus={() => {}}
              onBlur={() => {}}
              onSelect={value => {
                let new_custom_fields = JSON.parse(
                  JSON.stringify(data.custom_fields)
                );

                new_custom_fields[index] = {
                  ...new_custom_fields[index],
                  custom_field_value: value
                };
                this.setState({
                  data: {
                    ...data,
                    custom_fields: new_custom_fields
                  }
                });
              }}
              onSubmitEditing={() => {}}
            />
          </Wrapper>
        );

      case "date":
        return (
          <DateBox
            name="value"
            placeholder="Set value"
            onFocus={() => {}}
            onChange={value => {
              let new_custom_fields = JSON.parse(
                JSON.stringify(data.custom_fields)
              );

              new_custom_fields[index] = {
                ...new_custom_fields[index],
                custom_field_value: value
              };
              this.setState({
                data: {
                  ...data,
                  custom_fields: new_custom_fields
                }
              });
            }}
            value={
              !!custom_field.custom_field_value
                ? moment(custom_field.custom_field_value).format("MMM Do, YYYY")
                : "--"
            }
            date_value={
              !!custom_field.custom_field_value
                ? moment(custom_field.custom_field_value).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD")
            }
          />
        );

      case "number":
        return (
          <InputBox
            ref="value"
            style={{ flex: 1 }}
            autoFocus={false}
            name="value"
            returnKeyType="done"
            placeholder="Set value"
            onChange={value => {
              let new_custom_fields = JSON.parse(
                JSON.stringify(data.custom_fields)
              );

              new_custom_fields[index] = {
                ...new_custom_fields[index],
                custom_field_value: value
              };
              this.setState({
                data: {
                  ...data,
                  custom_fields: new_custom_fields
                }
              });
            }}
            onSubmitEditing={() => {}}
            blurOnSubmit={false}
            value={custom_field.custom_field_value}
            type="number"
          />
        );

      case "text":
      default:
        return (
          <InputBox
            ref="value"
            style={{ flex: 1 }}
            autoFocus={false}
            name="value"
            returnKeyType="done"
            placeholder="Set value"
            onChange={value => {
              let new_custom_fields = JSON.parse(
                JSON.stringify(data.custom_fields)
              );

              new_custom_fields[index] = {
                ...new_custom_fields[index],
                custom_field_value: value
              };
              this.setState({
                data: {
                  ...data,
                  custom_fields: new_custom_fields
                }
              });
            }}
            onSubmitEditing={() => {}}
            blurOnSubmit={false}
            value={custom_field.custom_field_value}
            type="text"
            renderRight={() => {
              return (
                <BracketPicker
                  onSelect={bracket => {
                    let new_custom_fields = JSON.parse(
                      JSON.stringify(data.custom_fields)
                    );

                    new_custom_fields[index] = {
                      ...new_custom_fields[index],
                      custom_field_value:
                        custom_field.custom_field_value +
                        "{{" +
                        bracket.key +
                        "}}"
                    };
                    this.setState({
                      data: {
                        ...data,
                        custom_fields: new_custom_fields
                      }
                    });
                  }}
                  enrollment_object={enrollment_object}
                  enrollment_object_loading={enrollment_object_loading}
                />
              );
            }}
          />
        );
    }
  }

  formatCustomFields(custom_fields) {
    let formatted_custom_fields = [];

    formatted_custom_fields.push({
      value: 0,
      label: "Not selected"
    });
    const { data } = this.state;

    for (let i = 0; i < custom_fields.length; i++) {
      let field_group_fields = custom_fields[i].fields;
      for (let j = 0; j < field_group_fields.length; j++) {
        formatted_custom_fields.push(field_group_fields[j]);
      }
    }

    return formatted_custom_fields;
  }

  getCustomFieldInfoFromId(custom_field_id) {
    let formatted_custom_fields = [];

    const { custom_fields } = this.props;
    for (let i = 0; i < custom_fields.length; i++) {
      let field_group_fields = custom_fields[i].fields;
      for (let j = 0; j < field_group_fields.length; j++) {
        if (field_group_fields[j].id == custom_field_id) {
          return field_group_fields[j];
        }
      }
    }

    return formatted_custom_fields;
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      custom_fields,
      custom_fields_loading,
      enrollment_object_loading
    } = this.props;
    const { data } = this.state;

    const formatted_custom_fields = this.formatCustomFields(custom_fields);

    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Update Custom Fields"
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {custom_fields_loading || enrollment_object_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                {data.custom_fields.map((custom_field, i) => {
                  return (
                    <Row key={"param_" + i}>
                      <Wrapper style={{ flex: 1 }}>
                        <SelectBox
                          style={{ flex: 1 }}
                          items={formatted_custom_fields}
                          value={custom_field.custom_field_id}
                          placeholder="Select field"
                          onFocus={() => {}}
                          onBlur={() => {}}
                          onSelect={value => {
                            if (value) {
                              let new_custom_fields = JSON.parse(
                                JSON.stringify(data.custom_fields)
                              );

                              let custom_field_info =
                                this.getCustomFieldInfoFromId(value);

                              new_custom_fields[i] = {
                                ...new_custom_fields[i],
                                custom_field_id: value,
                                custom_field_type: custom_field_info.field_type,
                                custom_field_options:
                                  custom_field_info.field_options,
                                custom_field_title: custom_field_info.title,
                                custom_field_value: ""
                              };
                              this.setState({
                                data: {
                                  ...data,
                                  custom_fields: new_custom_fields
                                }
                              });
                            }
                          }}
                          onSubmitEditing={() => {}}
                        />
                      </Wrapper>

                      {this.renderCustomFieldType({
                        custom_field,
                        index: i
                      })}
                      {data.custom_fields.length > 1 ? (
                        <IconButton
                          icon={"close"}
                          onPress={() => {
                            let new_custom_fields = JSON.parse(
                              JSON.stringify(data.custom_fields)
                            );

                            new_custom_fields.splice(i, 1);
                            this.setState({
                              data: {
                                ...data,
                                custom_fields: new_custom_fields
                              }
                            });
                          }}
                        />
                      ) : null}
                    </Row>
                  );
                })}

                {formatted_custom_fields.length > 1 ? (
                  <Row>
                    <InlineButton
                      icon={"add"}
                      onPress={() => {
                        this.setState({
                          data: {
                            ...data,
                            custom_fields: [
                              ...data.custom_fields,
                              {
                                custom_field_id: 0,
                                custom_field_type: "",
                                custom_field_options: [],
                                custom_field_title: "",
                                custom_field_value: ""
                              }
                            ]
                          }
                        });
                      }}
                    >
                      Update another field
                    </InlineButton>
                  </Row>
                ) : null}
              </>
            )}
            <Wrapper style={{ height: isMobile ? 200 : 0 }} />
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ customsettings, workflow }) => {
  const { custom_fields, custom_fields_loading } = customsettings;

  const { enrollment_object, enrollment_object_loading } = workflow;

  return {
    enrollment_object,
    enrollment_object_loading,
    custom_fields,
    custom_fields_loading
  };
};

export default connect(mapStateToProps, { getCustomFields })(CustomFieldsStep);
