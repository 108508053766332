import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Row,
  Button,
  Bold,
  Spin
} from "app/NativeComponents/common";
import { GhostButton, UpsellButton } from "app/NativeComponents/snippets";
import {
  updateLead,
  setSkipTracePage,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";
class SkipTraceButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const {
      property,
      colors,
      section,
      row_height,
      type,
      dark_mode,
      toggle_lead_images,
      user
    } = this.props;
    const { loading } = this.state;

    return !checkIfUserHasMetadata("people_data") &&
      property &&
      property.deal.did_skip_trace != 1 &&
      user.team_clearance_level > 1 &&
      !property?.deal?.updating?.is_updating ? (
      <Wrapper
        className="deal-preview"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: toggle_lead_images ? "100%" : "auto",
          /*backgroundColor: toggle_lead_images
                      ? dark_mode == "dark_mode"
                        ? "rgba(0,0,0, 0.25)"
                        : "rgba(255, 255, 255, 0.75)"
                      : colors.card_color*/
          margin: toggle_lead_images ? 0 : 10,
          height: toggle_lead_images ? "100%" : row_height,
          alignItems: "flex-end",
          justifyContent: "center"
        }}
      >
        <UpsellButton
          primary={false}
          meta_slug="skip_trace"
          disabled={
            this.props.user?.can_enhanced_search != 1 &&
            this.props.user.team_clearance_level < 2
          }
          onPress={() => {
            if (!loading) {
              this.setState({ loading: true });
              this.props.updateLead({
                token: this.props.token,
                deal_ids: property?.deal?.id,
                total_count: 1,
                type: "skip_trace_leads_v2",
                no_loading: true,
                onLoading: () => {
                  this.setState({ loading: true });
                },
                onError: () => {
                  this.setState({ loading: false });
                },
                onSuccess: () => {
                  this.setState({ loading: false });
                }
              });
            }
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <>
                <GhostButton
                  loading={loading}
                  noPress={true}
                  pressedIn={hovering}
                  primary={true}
                  style={{
                    margin: 0
                  }}
                  innerStyle={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10
                  }}
                >
                  Skip Trace
                </GhostButton>
              </>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <>
                <GhostButton
                  loading={loading}
                  noPress={true}
                  pressedIn={hovering}
                  primary={true}
                  style={{
                    margin: 0
                  }}
                  innerStyle={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10
                  }}
                >
                  Skip Trace
                </GhostButton>
              </>
            );
          }}
        />
      </Wrapper>
    ) : null;
  }
}

const mapStateToProps = ({ auth, settings, filter }) => {
  const { token, user } = auth;
  const { dark_mode } = settings;
  const { toggle_lead_images } = filter;
  return {
    token,
    user,
    dark_mode,
    toggle_lead_images
  };
};

export default connect(mapStateToProps, {
  updateLead,
  setSkipTracePage,
  pushSidePanel
})(SkipTraceButton);
