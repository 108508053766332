import React, { PureComponent } from "react";
import YouTube from "react-youtube";

class YouTubeVideo extends PureComponent {
  VideoOnReady(event) {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }

  render() {
    if (this.props.video && this.props.video != "") {
      var params = {};
      var parser = document.createElement("a");
      parser.href = this.props.video;
      var query = parser.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      var videoId = "";
      if (params["v"]) {
        videoId = params["v"];
      } else {
        videoId = this.props.video.split("/").pop();
      }

      const opts = {
        height: this.props.height ? parseInt(this.props.height) : "270",
        width: this.props.width ? this.props.width : "480",
        playerVars: {
          autoplay: this.props.autoplay ? 1 : 0,
          controls: this.props.controls ? 1 : 0
        }
      };

      return (
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={this.props.autoplay ? this.VideoOnReady : () => {}}
        />
      );
    }

    return <div />;
  }
}

export { YouTubeVideo };
