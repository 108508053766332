import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Card,
  CardBody,
  Icon
} from "app/NativeComponents/common";
import { store } from "app/store";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";

class Inputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardCompleted: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
      }
    };
    this.handleCardElementOnChange = this.handleCardElementOnChange.bind(this);
  }

  handleCardElementOnChange = e => {
    this.setState(prev => ({
      cardCompleted: {
        ...prev.cardCompleted,
        [e.elementType]: e.complete
      }
    }));
  };

  render() {
    const colors = store.getState().settings.colors;
    const cardCompleted = this.state.cardCompleted;
    const { validateStripe } = this.props;

    if (this.props.no_card_wrapper) {
      return (
        <Wrapper>
          <CardBody style={{ padding: 15, paddingTop: 5, paddingBottom: 5 }}>
            <div className="standard-label">
              <label>Card Number</label>
            </div>
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <CardNumberElement
                  style={{
                    base: {
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      padding: 0,
                      color: colors?.text_color,
                      alignSelf: "stretch",
                      fontSize: 14,
                      lineHeight: "18px",
                      fontFamily: "Open Sans, sans-serif"
                    }
                  }}
                  className={"dm-new-input"}
                  onChange={this.handleCardElementOnChange}
                />
              </Wrapper>
              {cardCompleted.cardNumber && validateStripe && (
                <div
                  className="flex-item"
                  style={{
                    width: 80,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Icon icon={"check"} color={colors.success_color} size={26} />
                </div>
              )}
            </Row>
          </CardBody>

          <CardBody style={{ padding: 15, paddingTop: 5, paddingBottom: 5 }}>
            <div className="standard-label">
              <label>Expiration Date</label>
            </div>
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <CardExpiryElement
                  style={{
                    base: {
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      padding: 0,
                      color: colors?.text_color,
                      alignSelf: "stretch",
                      fontSize: 14,
                      lineHeight: "18px",
                      fontFamily: "Open Sans, sans-serif"
                    }
                  }}
                  className={"dm-new-input"}
                  onChange={this.handleCardElementOnChange}
                />
              </Wrapper>
              {cardCompleted.cardExpiry && validateStripe && (
                <div
                  className="flex-item"
                  style={{
                    width: 80,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Icon icon={"check"} color={colors.success_color} size={26} />
                </div>
              )}
            </Row>
          </CardBody>
          <CardBody style={{ padding: 15, paddingTop: 5, paddingBottom: 5 }}>
            <div className="standard-label">
              <label>CVC</label>
            </div>
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <CardCVCElement
                  style={{
                    base: {
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      padding: 0,
                      color: colors?.text_color,
                      alignSelf: "stretch",
                      fontSize: 14,
                      lineHeight: "18px",
                      fontFamily: "Open Sans, sans-serif"
                    }
                  }}
                  className={"dm-new-input"}
                  onChange={this.handleCardElementOnChange}
                />
              </Wrapper>
              {cardCompleted.cardCvc && validateStripe && (
                <div
                  className="flex-item"
                  style={{
                    width: 80,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Icon icon={"check"} color={colors.success_color} size={26} />
                </div>
              )}
            </Row>
          </CardBody>
        </Wrapper>
      );
    }
    return (
      <Card>
        <CardBody>
          <div className="standard-label">
            <label>Card Number</label>
          </div>
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <CardNumberElement
                style={{
                  base: {
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    padding: 0,
                    color: colors?.text_color,
                    alignSelf: "stretch",
                    fontSize: 14,
                    lineHeight: "18px",
                    fontFamily: "Open Sans, sans-serif"
                  }
                }}
                className={"dm-new-input"}
                onChange={this.handleCardElementOnChange}
              />
            </Wrapper>
            {cardCompleted.cardNumber && validateStripe && (
              <div
                className="flex-item"
                style={{
                  width: 80,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon icon={"check"} color={colors.success_color} size={26} />
              </div>
            )}
          </Row>
        </CardBody>

        <CardBody>
          <div className="standard-label">
            <label>Expiration Date</label>
          </div>
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <CardExpiryElement
                style={{
                  base: {
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    padding: 0,
                    color: colors?.text_color,
                    alignSelf: "stretch",
                    fontSize: 14,
                    lineHeight: "18px",
                    fontFamily: "Open Sans, sans-serif"
                  }
                }}
                className={"dm-new-input"}
                onChange={this.handleCardElementOnChange}
              />
            </Wrapper>
            {cardCompleted.cardExpiry && validateStripe && (
              <div
                className="flex-item"
                style={{
                  width: 80,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon icon={"check"} color={colors.success_color} size={26} />
              </div>
            )}
          </Row>
        </CardBody>
        <CardBody>
          <div className="standard-label">
            <label>CVC</label>
          </div>
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <CardCVCElement
                style={{
                  base: {
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    padding: 0,
                    color: colors?.text_color,
                    alignSelf: "stretch",
                    fontSize: 14,
                    lineHeight: "18px",
                    fontFamily: "Open Sans, sans-serif"
                  }
                }}
                className={"dm-new-input"}
                onChange={this.handleCardElementOnChange}
              />
            </Wrapper>
            {cardCompleted.cardCvc && validateStripe && (
              <div
                className="flex-item"
                style={{
                  width: 80,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon icon={"check"} color={colors.success_color} size={26} />
              </div>
            )}
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default Inputs;
