import React, { Component } from "react";

import ReactMapboxGl, { Source, Layer } from "react-mapbox-gl";

class Map extends Component {
  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.map_type !== this.props.map_type ||
      prevProps.dark_mode !== this.props.dark_mode
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { colors, dark_mode } = this.props;
    //const colors = store.getState().settings.colors;
    //const dark_mode = store.getState().settings.dark_mode;
    const Map = ReactMapboxGl({
      accessToken:
        "pk.eyJ1IjoiZGVhbG1hY2hpbmUiLCJhIjoiY2t4YXZna3ZiM2t2djJvcDlkaXF5ZTF3YyJ9.ies_4s0QK2FRGcaXHsIXXg"
    });

    const onStyleLoad = map => {
      this.props.onStyleLoad();
    };
    const getCenter = map => {
      if (map) {
        if (map?.getCenter) {
          const coordinate = map?.getCenter();
        }
      }
    };

    return (
      <Map
        ref={this.props.onRef}
        style={
          this.props.map_type == "satellite"
            ? "mapbox://styles/dealmachine/ckzhc5qak000i15qpidgpl4ch?optimize=true"
            : dark_mode == "dark_mode"
            ? "mapbox://styles/dealmachine/ckzhc2ntq004c15lcuo7q4qex?optimize=true"
            : "mapbox://styles/dealmachine/ckzhbuxyo000h14qudkuurjga?optimize=true"
        }
        containerStyle={{
          width: "100%",
          height: "100%"
        }}
        zoom={[parseInt(this.props.starting_zoom)]}
        center={[
          parseFloat(this.props.starting_lng),
          parseFloat(this.props.starting_lat)
        ]}
        onStyleLoad={onStyleLoad}
        logoPosition={"none"}
        attributionControl={false}
        onMoveEnd={getCenter}
        onDragEnd={getCenter}
      >
        <Source
          id="routes_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="circle"
          id="routes_on_map_circle"
          sourceId="routes_on_map"
          paint={{
            "circle-color": colors.card_color,
            "circle-radius": 10,
            "circle-stroke-width": 2,
            "circle-stroke-color": colors.active_color
          }}
        />
        <Layer
          type="symbol"
          id="routes_on_map_label"
          sourceId="routes_on_map"
          layout={{
            "text-field": "{label}",
            "text-size": 14,
            "text-anchor": "top",
            "text-justify": "center",
            "text-offset": [0, -0.65]
          }}
          paint={{
            "text-color": colors.text_color
          }}
        />
      </Map>
    );
  }
}

export default Map;
