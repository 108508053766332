import React, { PureComponent } from "react";
import { Icon } from "app/NativeComponents/common";

class Pin extends PureComponent {
  render() {
    if (this.props.pin) {
      if (this.props.pin.lat != null && this.props.pin.lng != null) {
        return (
          <div
            key={0}
            onClick={e => {}}
            style={{
              marginLeft: "-18px",
              marginTop: "-18px"
            }}
          >
            <Icon
              style={{
                marginBottom: 0
              }}
              size={36}
              icon="location-on"
              color={this.props.mapType == "hybrid" ? "#ffffff" : "#31cce5"}
            />
          </div>
        );
      }
    }

    return <div />;
  }
}

export default Pin;
