import {
  LOGOUT,
  LOGIN_USER_SUCCESS,
  GET_PARTNER_ANALYTICS,
  REFRESH_PARTNER_ANALYTICS,
  GET_PARTNER_ANALYTICS_FAIL,
  GET_PARTNER_ANALYTICS_SUCCESS,
  SAVE_PARTNER_INFO,
  SAVE_PARTNER_INFO_FAIL,
  SAVE_PARTNER_INFO_SUCCESS,
  UPDATE_PARTNER_FIELD_CHANGE,
  INIT_UPDATE_PARTNER
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  loading: false,
  refreshing: false,
  error: "",
  stats: {
    total_user_count: 0,
    total_monthly_revenue: 0,
    total_monthly_subscribers: 0,
    free_trial_subscribers: 0
  },
  is_partner: 0,
  accepted_affiliate_latest_terms: 0,
  promo_codes: [],
  users: [],
  editPartner: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case GET_PARTNER_ANALYTICS:
      return {
        ...state,
        users: [],
        promo_codes: [],
        stats: INITIAL_STATE.stats,
        loading: true,
        error: ""
      };
    case REFRESH_PARTNER_ANALYTICS:
      return {
        ...state,
        refreshing: true,
        error: ""
      };
    case GET_PARTNER_ANALYTICS_FAIL:
      return {
        ...state,
        loading: false,
        refreshing: false,
        error: action.payload
      };
    case GET_PARTNER_ANALYTICS_SUCCESS:
    case SAVE_PARTNER_INFO_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        promo_codes: action.payload.promo_codes,
        stats: action.payload.stats,
        is_partner: action.payload.drawer_stats.is_partner,
        accepted_affiliate_latest_terms:
          action.payload.drawer_stats.accepted_affiliate_latest_terms,
        partner_info: action.payload.drawer_stats.partner_info,
        loading: false,
        refreshing: false
      };

    case UPDATE_PARTNER_FIELD_CHANGE:
      return {
        ...state,
        editPartner: {
          ...state.editPartner,
          [action.payload.prop]: action.payload.value
        }
      };

    case INIT_UPDATE_PARTNER:
      return {
        ...state,
        editPartner: action.payload.partner_info
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        is_partner: action.payload.is_partner,
        accepted_affiliate_latest_terms:
          action.payload.accepted_affiliate_latest_terms,
        partner_info: action.payload.partner_info
      };
    default:
      return state;
  }
};
