import React, { PureComponent } from "react";
import {
  Wrapper,
  Button,
  BackgroundImage,
  ExternalImage
} from "app/NativeComponents/common";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { store } from "app/store";

class PhotoOverlay extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: props?.photoIndex ? props?.photoIndex : 0,
      isOpen: false
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const {
      image,
      height = "auto",
      width = "100%",
      outerStyle,
      style,
      images = []
    } = this.props;

    const colors = store.getState().settings.colors;

    return (
      <>
        <Button
          noFeedback
          onPress={() => this.setState({ isOpen: true })}
          style={{ ...outerStyle }}
        >
          {this.props.children ? (
            <BackgroundImage
              style={{
                backgroundColor: colors.gray_color,
                ...style,
                cursor: "pointer"
              }}
              image={image}
              height={height}
              width={width}
            >
              {this.props.children}
            </BackgroundImage>
          ) : (
            <ExternalImage
              style={{
                width: width,
                height: height,
                ...style
              }}
              image={image}
              no_lazy={true}
              onError={this.props.onError}
            />
          )}
        </Button>
        {isOpen && (
          <>
            {images.length > 0 ? (
              <Lightbox
                onCloseRequest={() => this.setState({ isOpen: false })}
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + images.length - 1) % images.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length
                  })
                }
              />
            ) : (
              <Lightbox
                onCloseRequest={() => this.setState({ isOpen: false })}
                mainSrc={image}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export { PhotoOverlay };
