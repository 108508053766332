import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row, Bold, Copy } from "app/NativeComponents/common";
import {
  InlineButton,
  List,
  PopoverMenu,
  BottomNavBar,
  IconButton,
  InlineTabs,
  SearchBar,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

import {
  getPropertyFIPS,
  getPropertyCities,
  getPropertyZips
} from "app/NativeActions";

class AddLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_selected_view: false,
      all_fips: [],
      all_cities: [],
      all_zips: [],
      editing_city: null,
      editing_state: null,
      editing_zip: null,
      editing_fips: null,
      editing_fips_name: null,
      county_search: "",
      city_search: "",
      zip_search: "",
      location_tab: "zip",
      editing_latitude: null,
      editing_longitude: null,
      editing_westLng: null,
      editing_southLat: null,
      editing_eastLng: null,
      editing_northLat: null,
      cites_begin: 0,
      cities_limit: 25,
      cities_loading: false,
      cites_refreshing: false,
      cites_loaded_all: false,

      zips_begin: 0,
      zips_limit: 25,
      zips_loading: false,
      zips_refreshing: false,
      zips_loaded_all: false,

      fips_begin: 0,
      fips_limit: 25,
      fips_loading: false,
      fips_refreshing: false,
      fips_loaded_all: false
    };

    this.getZips = this.getZips.bind(this);
    this.getCities = this.getCities.bind(this);
    this.getFips = this.getFips.bind(this);
  }

  componentDidMount() {}
  componentWillUnmount() {
    clearInterval(this._fips_search_interval);
    clearInterval(this._city_search_interval);
    clearInterval(this._zip_search_interval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.location_tab !== prevState.location_tab ||
      this.state.show_selected_view !== prevState.show_selected_view
    ) {
      this.setState(
        {
          all_fips: [],
          all_cities: [],
          all_zips: [],
          city_search: "",
          zip_search: "",
          county_search: ""
        },
        () => {
          switch (this.state.location_tab) {
            case "zip":
              this.getZips(this.props.state);
              break;
            case "city":
              this.getCities(this.props.state);
              break;
            case "county":
              this.getFips(this.props.state);
              break;
          }
        }
      );
    }
  }

  getZips(state, begin = 0) {
    this.props.getPropertyZips({
      token: this.props.token,
      state: state,
      search: this.state.zip_search,
      begin,
      limit: this.state.zip_limit,
      onLoading: () => {
        this.setState({
          zips_loading: true
        });
      },
      onError: () => {
        this.setState({
          zips_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          all_zips: [...this.state.all_zips, ...results?.zips],
          zips_loading: false,
          zips_begin: begin,
          zips_loaded_all: results?.zips?.length < this.state.zips_limit
        });
      }
    });
  }

  getCities(state, begin = 0) {
    this.props.getPropertyCities({
      token: this.props.token,
      state: state,
      search: this.state.city_search,
      begin,
      limit: this.state.cities_limit,
      onLoading: () => {
        this.setState({
          cities_loading: true
        });
      },
      onError: () => {
        this.setState({
          cities_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          all_cities: [...this.state.all_cities, ...results?.cities],
          cities_loading: false,
          cities_begin: begin,
          cites_loaded_all: results?.cities?.length < this.state.cities_limit
        });
      }
    });
  }

  getFips(state, begin = 0) {
    this.props.getPropertyFIPS({
      token: this.props.token,
      state: state,
      search: this.state.county_search,
      begin,
      limit: this.state.cities_limit,
      onLoading: () => {
        this.setState({
          fips_loading: true
        });
      },
      onError: () => {
        this.setState({
          fips_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          all_fips: [...this.state.all_fips, ...results?.fips],
          fips_loading: false,
          fips_begin: begin,
          fips_loaded_all: results?.fips?.length < this.state.fips_limit
        });
      }
    });
  }

  render() {
    const { properties, isMobile, colors } = this.props;

    const {
      all_cities,
      all_zips,
      all_fips,
      cites_begin,
      cities_limit,
      cities_loading,
      cites_refreshing,
      cites_loaded_all,
      zips_begin,
      zips_limit,
      zips_loading,
      zips_refreshing,
      zips_loaded_all,
      fips_begin,
      fips_limit,
      fips_loading,
      fips_refreshing,
      fips_loaded_all
    } = this.state;

    return (
      <PopoverMenu
        show={this.state.show_selected_view}
        allow_multiple={true}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show_selected_view: s
          });
        }}
        popover_width={450}
        popover_height={450}
        popoverSheetTop={"75%"}
        popoverPlacement={"top"}
        includeCloseButton={true}
        hideWithOutsideClick={true}
        disabled={this.props.disabled}
        renderComponent={options => {
          return (
            <IconButton
              selected={true}
              tooltipPlacement={"top"}
              tooltip={"Add Location"}
              pressedIn={options?.hovering || options?.pressedIn}
              noPress={true}
              icon={"add"}
              disabled={this.props.disabled}
            />
          );
        }}
        renderMenu={() => {
          return (
            <Wrapper
              style={{
                alignSelf: "stretch",
                flex: 1,
                overflow: "hidden"
              }}
            >
              <Wrapper
                style={{
                  margin: 10,
                  marginBottom: 0,
                  alignSelf: "stretch"
                }}
              >
                <InlineTabs
                  selected_tab={this.state.location_tab}
                  tab_type={"underline"}
                  tabs={[
                    {
                      onPress: () => {
                        this.setState({
                          location_tab: "zip"
                        });
                      },
                      title: "Zip Code",
                      slug: "zip"
                    },
                    {
                      onPress: () => {
                        this.setState({
                          location_tab: "city"
                        });
                      },
                      title: "City",
                      slug: "city"
                    },
                    {
                      onPress: () => {
                        this.setState({
                          location_tab: "county"
                        });
                      },
                      title: "County",
                      slug: "county"
                    }
                  ]}
                />
              </Wrapper>

              {this.state.location_tab === "county" ? (
                <>
                  <>
                    <SearchBar
                      style={{ margin: 10 }}
                      title="Search Counties"
                      value={this.state.county_search}
                      onChange={value =>
                        this.setState(
                          {
                            county_search: value
                          },
                          () => {
                            clearInterval(this._fips_search_interval);
                            this._fips_search_interval = setTimeout(() => {
                              this.getFips(this.props.state, 0);
                            }, 750);
                          }
                        )
                      }
                    />
                    {this.state.fips_loading &&
                    this.state.all_fips.length == 0 ? (
                      <Wrapper style={{ flex: 1 }}>
                        <SpinWrapper text={"Loading"} />
                      </Wrapper>
                    ) : (
                      <List
                        rowNumber={1}
                        items={all_fips}
                        infiniteScroll={true}
                        itemStructure={({ item, index }) => {
                          if (!!item?.label) {
                            return (
                              <SelectItem
                                key={"fips" + index}
                                select_type={"radio"}
                                selected={this.state.editing_fips == item.value}
                                require_confirm={true}
                                confirm_text={"Add Location"}
                                onConfirm={() => {
                                  if (
                                    this.state.editing_latitude &&
                                    this.state.editing_longitude
                                  ) {
                                    if (this.props.device === "mobile") {
                                      this.props.updateMapLocation({
                                        coordinates: {
                                          latitude: parseFloat(
                                            this.state.editing_latitude
                                          ),
                                          longitude: parseFloat(
                                            this.state.editing_longitude
                                          )
                                        },
                                        zoom: 11
                                      });
                                    } else {
                                      this.props.updateLatLng({
                                        latitude: parseFloat(
                                          this.state.editing_latitude
                                        ),
                                        longitude: parseFloat(
                                          this.state.editing_longitude
                                        ),
                                        zoom: 11
                                      });
                                    }
                                    /*
                                    this.props.updateBounds({
                                      westLng: parseFloat(this.state.editing_westLng),
                                      southLat: parseFloat(this.state.editing_southLat),
                                      eastLng: parseFloat(this.state.editing_eastLng),
                                      northLat: parseFloat(this.state.editing_northLat),
                                      centerLat: parseFloat(this.state.editing_latitude),
                                      centerLng: parseFloat(this.state.editing_longitude)
                                    });
                                    */
                                  }

                                  this.props.updateMapFilters({
                                    search_locations: [
                                      ...this.props.search_locations,
                                      {
                                        type: "fips",
                                        value: this.state.editing_fips,
                                        value2: this.state.editing_state,
                                        label: this.state.editing_fips_name
                                      }
                                    ]
                                  });
                                  this.setState({
                                    show_selected_view: false,
                                    editing_fips: null
                                  });
                                }}
                                onPress={() => {
                                  this.setState({
                                    editing_fips: item.value,
                                    editing_fips_name: item.label,
                                    editing_latitude: !!item?.latitude
                                      ? item.latitude
                                      : null,
                                    editing_longitude: !!item?.longitude
                                      ? item.longitude
                                      : null,
                                    editing_westLng: !!item?.southWestLng
                                      ? item.southWestLng
                                      : null,
                                    editing_southLat: !!item?.southWestLat
                                      ? item.southWestLat
                                      : null,
                                    editing_eastLng: !!item?.northEastLng
                                      ? item.northEastLng
                                      : null,
                                    editing_northLat: !!item?.northEastLat
                                      ? item.northEastLat
                                      : null
                                  });
                                }}
                              >
                                {item?.label}
                              </SelectItem>
                            );
                          }
                          return null;
                        }}
                        onRefresh={() => {
                          this.getFips(this.props.state, 0);
                        }}
                        is_refreshing={fips_refreshing}
                        canLoadMore={
                          !fips_loaded_all &&
                          !fips_loading &&
                          !fips_refreshing &&
                          all_fips.length > 0
                        }
                        isLoadingMore={
                          fips_loading &&
                          !fips_refreshing &&
                          all_fips.length > 0
                        }
                        onLoadMore={() => {
                          this.getZips(
                            this.props.state,
                            fips_begin + fips_limit
                          );
                        }}
                      />
                    )}
                  </>
                </>
              ) : this.state.location_tab === "zip" ? (
                <>
                  <>
                    <SearchBar
                      style={{ margin: 10 }}
                      title="Search Zip Codes"
                      value={this.state.zip_search}
                      onChange={value =>
                        this.setState(
                          {
                            zip_search: value
                          },
                          () => {
                            clearInterval(this._zip_search_interval);
                            this._zip_search_interval = setTimeout(() => {
                              this.getZips(this.props.state, 0);
                            }, 750);
                          }
                        )
                      }
                    />
                    {this.state.zips_loading &&
                    this.state.all_zips.length == 0 ? (
                      <Wrapper style={{ flex: 1 }}>
                        <SpinWrapper text={"Loading"} />
                      </Wrapper>
                    ) : (
                      <List
                        rowNumber={1}
                        items={all_zips}
                        infiniteScroll={true}
                        itemStructure={({ item, index }) => {
                          if (!!item?.label) {
                            return (
                              <SelectItem
                                key={"zips" + index}
                                select_type={"radio"}
                                selected={this.state.editing_zip == item.value}
                                require_confirm={true}
                                confirm_text={"Add Location"}
                                onConfirm={() => {
                                  if (
                                    this.state.editing_latitude &&
                                    this.state.editing_longitude
                                  ) {
                                    if (this.props.device === "mobile") {
                                      this.props.updateMapLocation({
                                        coordinates: {
                                          latitude: parseFloat(
                                            this.state.editing_latitude
                                          ),
                                          longitude: parseFloat(
                                            this.state.editing_longitude
                                          )
                                        },
                                        zoom: 11
                                      });
                                    } else {
                                      this.props.updateLatLng({
                                        latitude: parseFloat(
                                          this.state.editing_latitude
                                        ),
                                        longitude: parseFloat(
                                          this.state.editing_longitude
                                        ),
                                        zoom: 11
                                      });
                                    }
                                    /*
                                    this.props.updateBounds({
                                      westLng: parseFloat(this.state.editing_westLng),
                                      southLat: parseFloat(this.state.editing_southLat),
                                      eastLng: parseFloat(this.state.editing_eastLng),
                                      northLat: parseFloat(this.state.editing_northLat),
                                      centerLat: parseFloat(this.state.editing_latitude),
                                      centerLng: parseFloat(this.state.editing_longitude)
                                    });
                                    */
                                  }
                                  this.props.updateMapFilters({
                                    search_locations: [
                                      ...this.props.search_locations,
                                      {
                                        type: "zip",
                                        value: this.state.editing_zip,
                                        label: this.state.editing_zip,
                                        value2: this.state.editing_state
                                      }
                                    ]
                                  });
                                  this.setState({
                                    show_selected_view: false,
                                    editing_zip: null
                                  });
                                }}
                                onPress={() => {
                                  this.setState({
                                    editing_zip: item.value,
                                    editing_state: item.state_abbr,
                                    editing_latitude: !!item?.latitude
                                      ? item.latitude
                                      : null,
                                    editing_longitude: !!item?.longitude
                                      ? item.longitude
                                      : null,
                                    editing_westLng: !!item?.southWestLng
                                      ? item.southWestLng
                                      : null,
                                    editing_southLat: !!item?.southWestLat
                                      ? item.southWestLat
                                      : null,
                                    editing_eastLng: !!item?.northEastLng
                                      ? item.northEastLng
                                      : null,
                                    editing_northLat: !!item?.northEastLat
                                      ? item.northEastLat
                                      : null
                                  });
                                }}
                              >
                                {item?.label}
                              </SelectItem>
                            );
                          }
                          return null;
                        }}
                        onRefresh={() => {
                          this.getZips(this.props.state, 0);
                        }}
                        is_refreshing={zips_refreshing}
                        canLoadMore={
                          !zips_loaded_all &&
                          !zips_loading &&
                          !zips_refreshing &&
                          all_zips.length > 0
                        }
                        isLoadingMore={
                          zips_loading &&
                          !zips_refreshing &&
                          all_zips.length > 0
                        }
                        onLoadMore={() => {
                          this.getZips(
                            this.props.state,
                            zips_begin + zips_limit
                          );
                        }}
                      />
                    )}
                  </>
                </>
              ) : (
                <>
                  <>
                    <SearchBar
                      style={{ margin: 10 }}
                      title="Search Cities"
                      value={this.state.city_search}
                      onChange={value =>
                        this.setState(
                          {
                            city_search: value
                          },
                          () => {
                            clearInterval(this._city_search_interval);
                            this._city_search_interval = setTimeout(() => {
                              this.getCities(this.props.state, 0);
                            }, 750);
                          }
                        )
                      }
                    />
                    {this.state.cities_loading &&
                    this.state.all_cities.length == 0 ? (
                      <Wrapper style={{ flex: 1 }}>
                        <SpinWrapper text={"Loading"} />
                      </Wrapper>
                    ) : (
                      <List
                        rowNumber={1}
                        items={all_cities}
                        infiniteScroll={true}
                        itemStructure={({ item, index }) => {
                          if (!!item?.label) {
                            return (
                              <SelectItem
                                key={"cities" + index}
                                select_type={"radio"}
                                selected={this.state.editing_city == item.value}
                                require_confirm={true}
                                confirm_text={"Add Location"}
                                onConfirm={() => {
                                  if (
                                    this.state.editing_latitude &&
                                    this.state.editing_longitude
                                  ) {
                                    if (this.props.device === "mobile") {
                                      this.props.updateMapLocation({
                                        coordinates: {
                                          latitude: parseFloat(
                                            this.state.editing_latitude
                                          ),
                                          longitude: parseFloat(
                                            this.state.editing_longitude
                                          )
                                        },
                                        zoom: 11
                                      });
                                    } else {
                                      this.props.updateLatLng({
                                        latitude: parseFloat(
                                          this.state.editing_latitude
                                        ),
                                        longitude: parseFloat(
                                          this.state.editing_longitude
                                        ),
                                        zoom: 11
                                      });
                                    }

                                    /*
                                    this.props.updateBounds({
                                      westLng: parseFloat(this.state.editing_westLng),
                                      southLat: parseFloat(this.state.editing_southLat),
                                      eastLng: parseFloat(this.state.editing_eastLng),
                                      northLat: parseFloat(this.state.editing_northLat),
                                      centerLat: parseFloat(this.state.editing_latitude),
                                      centerLng: parseFloat(this.state.editing_longitude)
                                    });
                                    */
                                  }
                                  this.props.updateMapFilters({
                                    search_locations: [
                                      ...this.props.search_locations,
                                      {
                                        type: "city",
                                        value: this.state.editing_city,
                                        label: this.state.editing_city,
                                        value2: this.state.editing_state
                                      }
                                    ]
                                  });
                                  this.setState({
                                    show_selected_view: false,
                                    editing_city: null
                                  });
                                }}
                                onPress={() => {
                                  this.setState({
                                    editing_city: item.value,
                                    editing_state: item.state_abbr,
                                    editing_latitude: !!item?.latitude
                                      ? item.latitude
                                      : null,
                                    editing_longitude: !!item?.longitude
                                      ? item.longitude
                                      : null,
                                    editing_westLng: !!item?.southWestLng
                                      ? item.southWestLng
                                      : null,
                                    editing_southLat: !!item?.southWestLat
                                      ? item.southWestLat
                                      : null,
                                    editing_eastLng: !!item?.northEastLng
                                      ? item.northEastLng
                                      : null,
                                    editing_northLat: !!item?.northEastLat
                                      ? item.northEastLat
                                      : null
                                  });
                                }}
                              >
                                {item?.label}
                              </SelectItem>
                            );
                          }
                          return null;
                        }}
                        onRefresh={() => {
                          this.getCities(this.props.state, 0);
                        }}
                        is_refreshing={cites_refreshing}
                        canLoadMore={
                          !cites_loaded_all &&
                          !cities_loading &&
                          !cites_refreshing &&
                          all_cities.length > 0
                        }
                        isLoadingMore={
                          cities_loading &&
                          !cites_refreshing &&
                          all_cities.length > 0
                        }
                        onLoadMore={() => {
                          this.getCities(
                            this.props.state,
                            cites_begin + cites_limit
                          );
                        }}
                      />
                    )}
                  </>
                </>
              )}
            </Wrapper>
          );
        }}
        menu_items={null}
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native, route, property_map }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  return {
    token,
    user,
    colors,
    device,
    isMobile
  };
};

export default connect(mapStateToProps, {
  getPropertyFIPS,
  getPropertyCities,
  getPropertyZips
})(AddLocation);
