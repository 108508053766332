import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Wrapper,
  ExternalImage,
  Spin,
  Row,
  Scroll,
  Copy,
  Bold,
  Icon,
  ProgressBar
} from "app/NativeComponents/common";
import { PillButton, List, UpsellButton } from "app/NativeComponents/snippets";
import {
  updatePropertyImage,
  uploadFile,
  setModal,
  toggleModal,
  checkIfUserHasBillingAddon,
  checkIfHasGoogleStreetPic,
  checkIfUserHasMetadata,
  randomString
} from "app/NativeActions";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

import PhotoGalleryImage from "./PhotoGalleryImage";

class Photos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photo_width: props.photo_width || 180,
      photo_height: props.photo_height || 120,
      loading_images: [],
      failedGoogleStreetPic: false,
      gotStreetPicInfo: false
    };

    this.setLoadingImages = this.setLoadingImages.bind(this);
  }
  componentDidMount() {
    checkIfHasGoogleStreetPic({
      address: this.props.property?.property_address_full,
      onSuccess: results => {
        this.setState({
          failedGoogleStreetPic: results.status == "OK" ? false : true,
          gotStreetPicInfo: true
        });
      }
    });
  }

  renderStreetViewPic() {
    const {
      property,
      property: { deal },
      colors,
      billing_addons
    } = this.props;

    if (
      (checkIfUserHasBillingAddon({
        billing_addons: billing_addons,
        slug: "street_view"
      }) &&
        this.props.user?.user_version < 6) ||
      (this.props.user?.user_version >= 6 &&
        checkIfUserHasMetadata("street_pic"))
    ) {
      let image = null;

      if (
        deal.failed_street_pic ||
        (this.state.failedGoogleStreetPic && this.state.gotStreetPicInfo)
      ) {
        return (
          <Wrapper
            style={{
              width: this.state.photo_width,
              height: this.state.photo_height,
              borderWidth: 1,
              borderColor: colors.border_color,
              borderStyle: "solid",
              borderRadius: 5,
              overflow: "hidden",

              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.error_color_muted,
              position: "relative",
              ...this.props.photoStyle
            }}
          >
            <ExternalImage
              style={{
                height: this.state.photo_height,
                borderWidth: 1,
                borderColor: colors.border_color,
                borderStyle: "solid",
                borderRadius: 5
              }}
              image={property.satellite_image}
              no_lazy={true}
            />
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.orange_color_muted,
                opacity: 0.9,
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0
              }}
            >
              <Wrapper style={{ padding: 20 }}>
                <Copy style={{ textAlign: "center" }}>
                  <Bold>Street Pic Failed</Bold>
                </Copy>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        );
      }

      if (this.props.images) {
        for (let i = 0; i < this.props.images?.length; i++) {
          if (this.props.images[i].image_type == "street_view") {
            image = this.props.images[i];
          }
        }
      }

      if (image) {
        return (
          <PhotoGalleryImage
            {...this.props}
            loading_images={this.state.loading_images}
            setLoadingImages={this.setLoadingImages}
            additional_image={{
              ...image,
              image_type: "street_view"
            }}
            overlay_text="Street Pic"
            photoStyle={this.props.photoStyle}
          />
        );
      }

      return (
        <PhotoGalleryImage
          {...this.props}
          loading_images={this.state.loading_images}
          setLoadingImages={this.setLoadingImages}
          additional_image={{
            image: property.streetview_image,
            image_type: "street_view"
          }}
          overlay_text={"Street Pic"}
          photoStyle={this.props.photoStyle}
        />
      );
    } else {
      return (
        <UpsellButton
          slug="street_view"
          type="billing_addon"
          contentful_slug="unlock_street_pic"
          meta_slug="street_pic"
          onLockPress={() => {
            if (this.props.onStreetPickLockPress) {
              this.props.onStreetPickLockPress();
            }
          }}
          renderLockedChildren={() => {
            return (
              <Wrapper
                style={{
                  width: this.state.photo_width,
                  height: this.state.photo_height,
                  overflow: "hidden",

                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: colors.card_color,
                  ...this.props.photoStyle
                }}
              >
                <ExternalImage
                  style={{
                    resizeMode: "contain",
                    width: 60,
                    height: 60
                  }}
                  contain={true}
                  image={
                    "https://api.dealmachine.com/assets/images/StreetPic.png"
                  }
                  no_lazy={true}
                />
                <Copy style={{ textAlign: "center" }}>
                  <Bold>Get Street Pic</Bold>
                </Copy>
              </Wrapper>
            );
          }}
        />
      );
    }
  }

  renderPropertyPic(image_type) {
    const {
      property,
      property: { deal },
      colors,
      images = []
    } = this.props;
    let image = null;
    if (images) {
      for (let i = 0; i < images?.length; i++) {
        if (images[i].image_type == image_type) {
          image = images[i];
        }
      }
    }

    if (image) {
      return (
        <PhotoGalleryImage
          {...this.props}
          loading_images={this.state.loading_images}
          setLoadingImages={this.setLoadingImages}
          additional_image={image}
          overlay_text={image_type == "satellite" ? "Satellite" : "Roadmap"}
        />
      );
    } else if (image_type == "satellite") {
      return (
        <PhotoGalleryImage
          {...this.props}
          loading_images={this.state.loading_images}
          setLoadingImages={this.setLoadingImages}
          additional_image={{
            image: property.satellite_image,
            image_type: "satellite"
          }}
          overlay_text={"Satellite"}
        />
      );
    } else if (image_type == "roadmap") {
      return (
        <PhotoGalleryImage
          {...this.props}
          loading_images={this.state.loading_images}
          setLoadingImages={this.setLoadingImages}
          additional_image={{
            image: property.map_image,
            image_type: "roadmap"
          }}
          overlay_text={"Roadmap"}
        />
      );
    }
  }

  setLoadingImages({ loading_images }) {
    this.setState({ loading_images });
  }

  renderFooter() {
    const {
      token,
      colors,
      isMobile,
      device,
      style,
      property,
      property: { deal },
      images = [],
      popoverPlacement = "bottom",
      renderComponent = () => {},
      componentStyle = {},

      property_images_loading,
      uploading_lead_images
    } = this.props;

    return (
      <>
        {uploading_lead_images &&
          uploading_lead_images.map((image, i) => {
            if (!image.error && image.lead_id == property.deal.id) {
              let file_preview = "";
              if (device == "desktop") {
                file_preview = image.file.preview;
              } else {
                file_preview = image.file?.path;
              }

              return (
                <Wrapper
                  key={"image_" + i}
                  style={{
                    position: "relative",
                    width: this.state.photo_width,
                    height: this.state.photo_height,
                    borderWidth: 1,
                    borderColor: colors.border_color,
                    borderStyle: "solid",
                    alignItems: "center",
                    justifyContent: "center",
                    ...this.props.photoStyle
                  }}
                >
                  <ExternalImage
                    style={{
                      width: this.state.photo_width,
                      height: this.state.photo_height,
                      borderWidth: 1,
                      borderColor: colors.border_color,
                      borderStyle: "solid"
                    }}
                    image={file_preview}
                    no_lazy={true}
                  />
                  <Wrapper
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: "#ffffff85",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {device == "mobile" ? (
                      <ProgressBar
                        size={30}
                        type="circle"
                        progress={parseInt(image.progress)}
                      />
                    ) : (
                      <Spin size="small" />
                    )}
                  </Wrapper>
                </Wrapper>
              );
            }
            return null;
          })}

        {this.renderStreetViewPic()}
        {this.renderPropertyPic("satellite")}
        {this.renderPropertyPic("roadmap")}
      </>
    );
  }

  render() {
    const {
      token,
      colors,
      isMobile,
      device,
      style,
      property,
      property: { deal },
      images = [],
      popoverPlacement = "bottom",
      renderComponent = () => {},
      componentStyle = {},

      property_images_loading,
      uploading_lead_images,
      horizontal = false
    } = this.props;
    const { loading_images } = this.state;

    return (
      <>
        {this.props.listHeader ? this.props.listHeader() : null}
        {images &&
          images.map((additional_image, i) => {
            if (
              additional_image.image_type !== "street_view" &&
              additional_image.image_type !== "roadmap" &&
              additional_image.image_type !== "satellite"
            ) {
              return (
                <PhotoGalleryImage
                  key={"image_" + i}
                  {...this.props}
                  photo_width={this.state.photo_width}
                  photo_height={this.state.photo_height}
                  loading_images={this.state.loading_images}
                  setLoadingImages={this.setLoadingImages}
                  additional_image={additional_image}
                />
              );
            }
            return null;
          })}
        {this.renderFooter()}
      </>
    );
  }
}
const mapStateToProps = ({
  auth,
  native,
  settings,
  lead,
  property_map,
  billing
}) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;
  const { uploading_lead_images } = lead;
  const { property_images_loading } = property_map;
  const { billing_addons } = billing;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    uploading_lead_images,
    property_images_loading,
    billing_addons
  };
};

export default connect(mapStateToProps, {
  updatePropertyImage,
  setModal,
  toggleModal,
  uploadFile
})(Photos);
