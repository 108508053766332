import React, { PureComponent } from "react";
import { Wrapper, Title } from "app/NativeComponents/common";
import { store } from "app/store";

class HeaderTitle extends PureComponent {
  render() {
    const { title, titleColor } = this.props;
    if (title) {
      const colors = store.getState().settings.colors;

      return (
        <Title style={{ color: titleColor || colors.title_color }}>
          {title}
        </Title>
      );
    }

    return <Wrapper />;
  }
}

export default HeaderTitle;
