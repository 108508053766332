import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getBasicAutomation,
  updateBasicAutomation,
  getLeadStatuses,
  popSidePanel
} from "app/NativeActions";
import {
  Container,
  Wrapper,
  KeyboardView,
  Spin,
  Title,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  AttentionBox
} from "app/NativeComponents/snippets";

import AutomationItem from "./AutomationItem";

class BasicAutomation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.getData({ load_type: "load" });

    this.props.getLeadStatuses({
      token: this.props.token
    });
  }

  getData({ load_type = "load" }) {
    this.props.getBasicAutomation({
      token: this.props.token
    });
  }

  render() {
    const {
      token,
      isMobile,
      device,
      colors,
      basic_automation,
      basic_automation_loading,
      lead_statuses
    } = this.props;

    return (
      <Container>
        <NewHeader
          title={"Basic Automation"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {basic_automation_loading ? (
            <SpinWrapper text="Loading..." />
          ) : (
            <>
              <AttentionBox
                title="Basic Automation"
                description={
                  "Manage basic automation for your account. See all your options below."
                }
              />

              {basic_automation && basic_automation.length > 0
                ? basic_automation.map((ba, i) => {
                    return (
                      <Wrapper key={"ba_" + i}>
                        <AutomationItem {...this.props} basic_automation={ba} />
                      </Wrapper>
                    );
                  })
                : null}
            </>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  workflow,
  customsettings
}) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { basic_automation, basic_automation_loading } = workflow;
  const { lead_statuses, lead_statuses_loading } = customsettings;

  return {
    token,
    user,
    isMobile,
    colors,
    device,
    basic_automation,
    basic_automation_loading,
    lead_statuses,
    lead_statuses_loading
  };
};

export default connect(mapStateToProps, {
  getBasicAutomation,
  updateBasicAutomation,
  getLeadStatuses,
  popSidePanel
})(BasicAutomation);
