import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy } from "app/NativeComponents/common";

class CampaignStatusColumnItem extends PureComponent {
  getCampaignText() {
    const {
      property: { deal }
    } = this.props;

    let mailing_options_text = "--";
    if (
      deal.deal_status_type != "inactive_status" ||
      deal.deal_status_type != "trash_status" ||
      deal.deal_status_type != "return_to_sender_status" ||
      deal.deal_status_type != "not_interested_status" ||
      deal.deal_status_type != "marketing_complete_status" ||
      deal.deal_status_type != "unqualified_status"
    ) {
      if (deal.campaign_id && deal.campaign_id != 0) {
        if (
          deal.approved == 1 ||
          deal.paused == 1 ||
          deal.campaign_complete == 1
        ) {
          mailing_options_text = deal.campaign_title;
        }
      } else if (
        (deal.mail_template_id && deal.mail_template_id != 0) ||
        (deal.template_id && deal.template_id != 0)
      ) {
        if (
          deal.approved == 1 ||
          deal.paused == 1 ||
          deal.campaign_complete == 1
        ) {
          if (deal.template_id && deal.template_id != 0) {
            mailing_options_text = "Template: " + deal.template_name;
          } else {
            mailing_options_text = "Template: " + deal.mail_template_name;
          }
        }
      }
    }
    return mailing_options_text;
  }

  getCampaignTextLine2() {
    const {
      property: { deal }
    } = this.props;

    let mailing_options_text = "";
    if (
      deal.deal_status_type != "inactive_status" ||
      deal.deal_status_type != "trash_status" ||
      deal.deal_status_type != "return_to_sender_status" ||
      deal.deal_status_type != "not_interested_status" ||
      deal.deal_status_type != "marketing_complete_status" ||
      deal.deal_status_type != "unqualified_status"
    ) {
      if (deal.campaign_id && deal.campaign_id != 0) {
        if (
          deal.approved == 1 ||
          deal.paused == 1 ||
          deal.campaign_complete == 1
        ) {
          if (deal?.campaign_id.includes("repeating")) {
            if (deal.campaign_complete == 1) {
              mailing_options_text += "Mail Complete";
            } else if (deal.paused == 1) {
              mailing_options_text += "Mail Paused";
            } else {
              if (deal.campaign_step && deal.campaign_step != 0) {
                mailing_options_text += "Step " + deal.campaign_step;
              } else {
                mailing_options_text += "Step 1";
              }
            }
          } else {
            if (deal.campaign_complete == 1) {
              mailing_options_text += "Mail Complete";
            } else if (deal.paused == 1) {
              mailing_options_text += "Mail Paused";
            }
          }
        }
      } else if (
        (deal.mail_template_id && deal.mail_template_id != 0) ||
        (deal.template_id && deal.template_id != 0)
      ) {
        if (
          deal.approved == 1 ||
          deal.paused == 1 ||
          deal.campaign_complete == 1
        ) {
          if (
            parseInt(deal.resend_freq) === 0 ||
            deal.resend_freq_switch === "off"
          ) {
            mailing_options_text += "Repeat Mail Off";
          } else if (deal.resend_freq == 1) {
            mailing_options_text += "Repeat Mail Every Day";
          } else if (deal.resend_freq > 1) {
            mailing_options_text +=
              "Repeat Mail Every " + deal.resend_freq + " Days";
          }
          if (
            (deal.resend_freq_switch && deal.resend_freq_switch == "on") ||
            (!deal.resend_freq_switch && deal.resend_freq > 0)
          ) {
            if (deal.resend_limit == 0 || deal.resend_limit_switch == "on") {
              mailing_options_text += "; Repeating Forever";
            } else if (deal.resend_limit == 1) {
              mailing_options_text += "; Sending Once";
            } else if (deal.resend_limit > 0) {
              mailing_options_text +=
                "; Sending " + deal.resend_limit + " Times";
            }
          }

          if (deal.campaign_complete == 1) {
            mailing_options_text += "Mail Complete";
          } else if (deal.paused == 1) {
            mailing_options_text += "Mail Paused";
          }
        }
      }
    }
    return mailing_options_text;
  }

  render() {
    const { property, colors, section, row_height, type } = this.props;

    return (
      <Wrapper
        style={{
          padding: 10,
          height: row_height,

          position: "relative"
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <Copy style={{ whiteSpace: "nowrap" }}>{this.getCampaignText()}</Copy>
          {!!this.getCampaignTextLine2() ? (
            <Copy style={{ fontSize: 10, whiteSpace: "nowrap" }}>
              {this.getCampaignTextLine2()}
            </Copy>
          ) : null}
        </Wrapper>
      </Wrapper>
    );
  }
}

export default CampaignStatusColumnItem;
