import React from "react";

import CallItem from "./CallItem";
const CallHistory = props => {
  const { call_history } = props;

  return (
    <>
      {call_history.map((call, i) => {
        return (
          <CallItem
            key={"call-history-" + i}
            call={call}
            hideMore={props.hideMore}
            pushSidePanel={props.pushSidePanel}
          />
        );
      })}
    </>
  );
};

export default CallHistory;
