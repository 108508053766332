import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "app/NativeComponents/common";
import SelectTrialOnboarding from "./SelectTrialOnboarding";
import ValidateEmailStep from "./ValidateEmailStep";
import QualifyingQuestions from "./QualifyingQuestions";
import NavigationService from "app/Router/NavigationService";

import FinalStepStageOne from "../TrialSuccessOnboarding/FinalStepStageOne";
import UpsellOnboarding from "../TrialSuccessOnboarding/UpsellOnboarding";
import DesignMailStep from "../TrialSuccessOnboarding/DesignMailStep";
import AlmaStep from "../TrialSuccessOnboarding/AlmaStep";
import SelectLeadStep from "../TrialSuccessOnboarding/SelectLeadStep";
import BuildListStep from "../TrialSuccessOnboarding/BuildListStep";

import {
  logMarketingEvent,
  logout,
  startRedirect,
  updateUser,
  trackSignUpEvent
} from "app/NativeActions";
class PreTrialOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualified_lead: false,
      needs_lists: false,
      needs_dialer: false
    };

    this.onQualifyLead = this.onQualifyLead.bind(this);
    this.onNeedsLists = this.onNeedsLists.bind(this);
    this.onNeedsDialer = this.onNeedsDialer.bind(this);
  }

  componentDidMount() {
    if (this.props.device === "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }

    const { user } = this.props;

    if (!user?.onboarding_stepthrough_step) {
      trackSignUpEvent();

      //increase to the next step so we don't log the sign up again
      this.props.updateUser({
        token: this.props.token,
        type: "onboarding_stepthrough_step",
        payload: {
          onboarding_stepthrough_current_step: "qualifying_questions"
        },
        onLoading: () => {},
        onError: () => {},
        onSuccess: () => {},
        no_loading: true
      });
    }
  }

  onQualifyLead() {
    this.setState({
      qualified_lead: true
    });
  }

  onNeedsLists() {
    this.setState({
      needs_lists: true
    });
  }

  onNeedsDialer() {
    this.setState({
      needs_dialer: true
    });
  }

  render() {
    const { user } = this.props;
    if (this.props.init && user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    if (user?.email_verified != 1) {
      return <ValidateEmailStep />;
    }

    if (
      this.props.visitor?.marketing_experiments?.dealmachine_free ===
      "dealmachine_free"
    ) {
      switch (user?.onboarding_stepthrough_step) {
        case "final_step_stage_one":
          return <FinalStepStageOne />;

        case "upsell_onboarding_step":
          return <UpsellOnboarding />;
        case "design_mail_step":
          return <DesignMailStep />;
        case "alma_step":
          return <AlmaStep />;

        case "select_lead_step":
          return <SelectLeadStep />;

        case "add_property_step":
        case "build_list_step":
          return <BuildListStep />;

        case "qualifying_questions":
          return (
            <QualifyingQuestions
              onQualifyLead={this.onQualifyLead}
              onNeedsList={this.onNeedsLists}
              onNeedsDialer={this.onNeedsDialer}
              needs_dialer={this.state.needs_dialer}
            />
          );

        case "select_plan_option_step":
        case "select_pro_option_step":
          return (
            <SelectTrialOnboarding
              qualified_lead={this.state.qualified_lead}
              needs_lists={this.state.needs_lists}
              needs_dialer={this.state.needs_dialer}
              selected_pro={
                user?.onboarding_stepthrough_step === "select_pro_option_step"
              }
            />
          );
        default:
          return <Spinner />;
      }
    }

    switch (user?.onboarding_stepthrough_step) {
      case "select_plan_option_step":
        return (
          <SelectTrialOnboarding
            qualified_lead={this.state.qualified_lead}
            needs_lists={this.state.needs_lists}
            needs_dialer={this.state.needs_dialer}
          />
        );
      case "qualifying_questions":
        return (
          <QualifyingQuestions
            onQualifyLead={this.onQualifyLead}
            onNeedsList={this.onNeedsLists}
            onNeedsDialer={this.onNeedsDialer}
            needs_dialer={this.state.needs_dialer}
          />
        );
      default:
        return <Spinner />;
    }
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;
  const { visitor } = marketing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    visitor
  };
};

export default connect(mapStateToProps, { logout, startRedirect, updateUser })(
  PreTrialOnboarding
);
