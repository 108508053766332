export const focusNextField = (refs, nextField) => {
  /*
  if(refs[nextField].refs[nextField].refs.mask_input){
    if(refs[nextField].refs[nextField].refs.mask_input.refs["$input-text"]){
      refs[nextField].refs[nextField].refs.mask_input.refs["$input-text"].focus();
    }
  }else{
    refs[nextField].refs[nextField].focus();
  }
  */
};
export const lengthChange = ({
  onChange,
  prop,
  value,
  maxLength,
  refs,
  nextInput
}) => {
  onChange();
  /*
  if(value.length >= maxLength){
    if(nextInput){
      focusNextField(refs, nextInput)
    }
  }
  */
};
