import React, { PureComponent } from "react";

import { hotjar } from "react-hotjar";

class Form extends PureComponent {
  componentDidMount() {
    this.fireTracking();
  }
  fireTracking() {
    hotjar.initialize(1801945);
  }
  render() {
    const { id, children, style, onSubmit } = this.props;
    return (
      <form
        id={id}
        autoComplete="anyrandomstring"
        className="deal-form"
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit();
        }}
        style={style}
      >
        {children}
      </form>
    );
  }
}

export { Form };
