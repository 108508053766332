import React, { Component } from "react";

import { Wrapper, Spin, Button, Icon } from "app/NativeComponents/common";

/*
import {
} from 'app/NativeActions';
*/

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      row_height: props.toggle_lead_images == "large" ? 100 : 35
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toggle_lead_images !== this.props.toggle_lead_images) {
      this.setState({
        row_height: this.props.toggle_lead_images == "large" ? 100 : 35
      });
    }
  }

  toggleCheckBox(toggle) {
    this.setState({
      checked: toggle
    });
  }

  isChecked() {
    const { property, selected_leads, selected_all } = this.props;
    if (selected_all) {
      return true;
    }

    for (let i = 0; i < selected_leads.length; i++) {
      if (selected_leads[i].property_id === property.property_id) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { property, active_property, colors } = this.props;
    const { row_height } = this.state;
    if (
      property &&
      property.deal &&
      property.deal.removed != 1 &&
      property.deal.updating &&
      property.deal.updating.is_updating
    ) {
      return (
        <Button
          onPress={() => {}}
          /*
          onHover={() => {
            this.props.setHoverRow(property.deal?.id);
          }}
          onHoverOut={() => {
            //this.props.setHoverRow(null)
          }}
          */
          style={{
            padding: 10,
            paddingRight: 0,
            paddingLeft: 0,
            width: 60,
            position: "relative",
            height: row_height,
            borderRightWidth: 1,
            borderRightColor: "transparent",
            borderRightStyle: "solid",

            alignItems: "center",
            justifyContent: "center",
            alignSelf: "stretch",

            backgroundColor: colors.card_color
          }}
        >
          <Icon style={{ opacity: 0 }} icon={"check-box"} size={22} />
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.white_color_opacity
            }}
          >
            <Spin size="small" />
          </Wrapper>
        </Button>
      );
    }
    if (property.deal && property.deal.removed != 1) {
      return (
        <Button
          onPress={() => {
            this.props.checkLead(property);
          }}
          /*
          onHover={() => {
            this.props.setHoverRow(property.deal?.id);
          }}
          onHoverOut={() => {
            //this.props.setHoverRow(null)
          }}
          */
          style={{
            padding: 10,
            paddingRight: 0,
            paddingLeft: 0,
            width: 60,
            position: "relative",
            height: row_height,
            borderRightWidth: 1,
            borderRightColor: "transparent",
            borderRightStyle: "solid",

            alignItems: "center",
            justifyContent: "center",
            alignSelf: "stretch",

            backgroundColor:
              active_property && active_property.deal
                ? property.deal.id == active_property.deal.id
                  ? colors.active_color_muted
                  : colors.card_color
                : colors.card_color
          }}
        >
          <Icon
            icon={this.isChecked() ? "check-box" : "check-box-outline-blank"}
            size={22}
          />
        </Button>
      );
    }
    return null;
  }
}

export default Checkbox;
