import {
  LOGOUT,
  GET_LIST_PROPERTIES,
  REFRESH_LIST_PROPERTIES,
  GET_LIST_PROPERTIES_FAIL,
  GET_LIST_PROPERTIES_SUCCESS,
  LOAD_MORE_PROPERTIES,
  LOAD_MORE_PROPERTIES_SUCCESS,
  GET_PROPERTY_COUNT,
  GET_PROPERTY_COUNT_FAIL,
  GET_PROPERTY_COUNT_SUCCESS,
  UPDATE_LEAD,
  UPDATE_LEAD_FAIL,
  UPDATE_LEAD_SUCCESS,
  UPDATE_OWNER_INFO_SUCCESS,
  SET_PROPERTIES_PAGE,
  SET_PROPERTIES_LIMIT,
  UPDATE_HOUSE_SUCCESS,
  GOOGLE_STREET_VIEW_SUCCESS,
  DELETE_DEAL,
  SELECT_EDIT_PHOTO,
  UPLOAD_EDIT_PROGRESS,
  UPLOAD_EDIT_PHOTO_SUCCESS,
  GENERATE_PREVIEW_IMAGES_SUCCESS,
  TRIGGER_LEADS_RELOAD,
  TRIGGER_LEADS_COUNT_RELOAD,
  ADD_DEAL_SUCCESS,
  TOGGLE_LEAD_IMAGES,
  READ_TEXT_MESSAGE,
  RELOAD_LEADS_SUCCESS,
  RELOAD_LEADS,
  GET_BULK_EDITS_SUCCESS,
  UPDATE_BULK_EDIT,
  GET_MAIL_FAILED_DEALS,
  GET_MAIL_FAILED_DEALS_FAIL,
  GET_MAIL_FAILED_DEALS_SUCCESS,
  LOAD_MORE_MAIL_FAILED_DEALS,
  LOAD_MORE_MAIL_FAILED_DEALS_SUCCESS,
  REFRESH_MAIL_FAILED_DEALS,
  SET_SKIP_TRACE_PAGE,
  GET_ESTIMATED_INVOICE_INFO,
  GET_ESTIMATED_INVOICE_INFO_FAIL,
  GET_ESTIMATED_INVOICE_INFO_SUCCESS,
  TOGGLE_EXIT_STRATEGY,
  GET_PROPERTY_IMAGES_SUCCESS,
  UPDATE_PROPERTY_IMAGE_SUCCESS,
  UPDATE_LEAD_PHOTO_START,
  UPDATE_LEAD_PHOTO_PROGRESS,
  UPDATE_LEAD_PHOTO_COMPLETE,
  UPDATE_LEAD_PHOTO_ERROR,
  SET_PHOTO_GALLERY,
  TOGGLE_PHOTO_GALLERY,
  TOGGLE_LEAD_PHOTO_MANAGER,
  SET_EXPORT_MODAL,
  REMOVE_LEADS_SUCCESS,
  UPDATE_COMPS_SETTINGS,
  LOGIN_USER_SUCCESS,
  SET_UPDATED_LEADS,
  SET_UPDATED_OWNER,
  UPDATE_LEAD_FILTERS,
  UPDATE_MANUAL_PROPERTY_STATE,
  RESET_MANUAL_PROPERTY_STATE
} from "app/DealMachineCore/types";

import { combinePropertyArrays } from "app/NativeActions";

const updateProperty = (originalProperty, newProperties) => {
  for (var i = 0; i < newProperties.length; i++) {
    if (originalProperty.property_id == newProperties[i].property_id) {
      return {
        ...newProperties[i],
        highlighted: originalProperty.highlighted,
        deal: {
          ...newProperties[i].deal,
          image: originalProperty.deal ? originalProperty.deal.image : ""
        }
      };
    }
  }
  return originalProperty;
};

const INITIAL_STATE = {
  list_properties_loading: false,
  list_properties_error: "",
  list_properties: [],
  list_properties_begin: 0,
  list_properties_limit: 25,
  list_properties_refreshing: false,
  list_properties_loaded_all: false,

  list_properties_page: 1,
  total_lead_count: 0,
  total_lead_count_loading: false,

  trigger_leads_reload: false,
  trigger_leads_count_reload: false,
  toggle_lead_images: "none",
  bulk_edits: [],
  mail_failed_properties: [],
  mail_failed_properties_loading: false,
  mail_failed_properties_refreshing: false,
  mail_failed_properties_loaded_all: false,
  mail_failed_properties_begin: 0,
  mail_failed_properties_limit: 100,

  skip_trace_page: null,
  show_skip_trace_page: false,

  estimated_invoice_info: null,
  estimated_invoice_info_loading: false,

  show_exit_strategy_modal: false,

  uploading_lead_images: [],

  toggle_photo_gallery: false,
  toggle_lead_photo_manager: false,
  photo_gallery: {
    lead_id: 0,
    property: null,
    images: []
  },

  active_list_tab: "all_leads",
  active_list_tabs: [],

  export_modal: { selected_columns: [] },
  comps_settings: {
    date_range_default: "6_months",
    sqft_range_default: "20",
    bathroom_range_default: "none",
    bedroom_range_default: "none",
    year_built_range_default: "none",
    range_miles_default: 1,
    sort_by: "distance",
    source_type: "mls+county_records"
  },
  updated_properties: [],
  updated_leads: [],
  updated_owner: {
    lead_id: null,
    owners: null
  },
  lead_filters: {
    property_flags: [],
    property_flags_and_or: "or"
  },
  manual_property_state: {
    loading: false,
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    property: null,
    autocomplete_items: [],
    autocomplete_loading: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case GET_LIST_PROPERTIES:
      return {
        ...state,
        list_properties_error: "",
        list_properties_loading: true,
        list_properties_page: action.payload?.page,
        trigger_leads_reload: false
      };
    case LOAD_MORE_PROPERTIES:
      return {
        ...state,
        list_properties_error: "",
        list_properties_loading: true,
        list_properties_page: action.payload?.page,
        trigger_leads_reload: false
      };
    case REFRESH_LIST_PROPERTIES:
      return {
        ...state,
        list_properties_error: "",
        list_properties_loading: true,
        list_properties_refreshing: true,
        list_properties: [],
        list_properties_page: action.payload?.page,
        trigger_leads_reload: false
      };

    case GET_LIST_PROPERTIES_FAIL:
      return {
        ...state,
        list_properties_error: action.payload,
        list_properties_loading: false,
        list_properties_refreshing: false
      };

    case GET_LIST_PROPERTIES_SUCCESS:
      return {
        ...state,
        list_properties_loading: false,
        list_properties_refreshing: false,
        list_properties: action.payload.properties,
        list_properties_begin:
          state.list_properties_begin + action.payload.properties.length,
        list_properties_loaded_all: action.payload?.isMobileisMobile
          ? state.list_properties_begin + action.payload.properties.length <
            parseInt(state.total_lead_count)
          : action.payload.properties.length < state.list_properties_limit,

        trigger_leads_reload: false
      };

    case LOAD_MORE_PROPERTIES_SUCCESS:
      return {
        ...state,
        list_properties_loading: false,
        list_properties_refreshing: false,
        list_properties: state.list_properties.concat(
          action.payload.properties
        ),
        list_properties_begin:
          state.list_properties_begin + action.payload.properties.length,
        list_properties_loaded_all:
          action.payload.properties.length < state.list_properties_limit,

        trigger_leads_reload: false
      };

    case GET_PROPERTY_COUNT:
      return {
        ...state,
        total_lead_count_loading: true,
        trigger_leads_count_reload: false
      };
    case GET_PROPERTY_COUNT_FAIL:
      return {
        ...state,
        total_lead_count_loading: false,
        trigger_leads_count_reload: false
      };
    case GET_PROPERTY_COUNT_SUCCESS:
      return {
        ...state,
        total_lead_count: action.payload.total_lead_count,
        total_lead_count_loading: false,
        trigger_leads_count_reload: false
      };

    case UPDATE_LEAD_SUCCESS:
    case RELOAD_LEADS_SUCCESS:
      return {
        ...state,
        list_properties: action.payload.properties
          ? state.list_properties.map((property, i) =>
              updateProperty(property, action.payload.properties)
            )
          : state.list_properties
      };
    case SET_UPDATED_LEADS:
      return {
        ...state,
        updated_leads: action.payload
      };

    case SET_UPDATED_OWNER:
      return {
        ...state,
        updated_owner: action.payload
      };
    case UPDATE_OWNER_INFO_SUCCESS:
      return {
        ...state,
        list_properties: action.payload.properties
          ? state.list_properties.map((property, i) =>
              updateProperty(property, action.payload.properties)
            )
          : state.list_properties
      };
    case SET_PROPERTIES_PAGE:
      return {
        ...state,
        list_properties_page: action.payload
      };

    case SET_PROPERTIES_LIMIT:
      return {
        ...state,
        list_properties_limit: action.payload
      };

    case READ_TEXT_MESSAGE:
      return {
        ...state,
        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.lead_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    message_notifications:
                      parseInt(property.deal.message_notifications) - 1 > 0
                        ? parseInt(property.deal.message_notifications) - 1
                        : 0
                  }
                }
              : property
            : property
        )
      };

    /* temp reducer items */
    case UPDATE_HOUSE_SUCCESS:
      return {
        ...state,
        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal.id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    ...action.payload.deal,
                    phone_numbers: property.deal.phone_numbers,
                    email_addresses: property.deal.email_addresses,
                    image: property.deal.image
                  }
                }
              : property
            : property
        )
      };
    case GOOGLE_STREET_VIEW_SUCCESS:
      return {
        ...state,
        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    image: action.payload.image,
                    from_google_street_view: 1
                  }
                }
              : property
            : property
        )
      };

    case DELETE_DEAL:
      return {
        ...state,
        list_properties: state.list_properties.filter(
          ({ property_id }) => property_id != action.payload.property_id
        )
      };

    case REMOVE_LEADS_SUCCESS:
      return {
        ...state,
        list_properties: state.list_properties.filter(({ deal }) => {
          for (let i = 0; i < action.payload.leads.length; i++) {
            if (action.payload.leads[i] == deal?.id) {
              return false;
            }
          }
          return true;
        }),
        trigger_leads_count_reload: true
      };

    case RELOAD_LEADS:
      return {
        ...state,
        list_properties:
          action.payload.leads && action.payload.leads.length > 0
            ? state.list_properties.map((property, i) =>
                property.deal
                  ? action.payload.leads.includes(property.deal.id)
                    ? {
                        ...property,
                        deal: {
                          ...property.deal,
                          did_skip_trace: 1,
                          updating: {
                            is_updating: true,
                            updating_title: action.payload.updating_title,
                            updating_type: action.payload.updating_type
                          }
                        }
                      }
                    : property
                  : property
              )
            : state.list_properties
      };
    case SELECT_EDIT_PHOTO:
      return {
        ...state,
        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    image: action.payload.uri
                  }
                }
              : property
            : property
        )
      };

    case UPLOAD_EDIT_PROGRESS:
      return {
        ...state,

        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    progress: action.payload.progress
                  }
                }
              : property
            : property
        )
      };

    case UPLOAD_EDIT_PHOTO_SUCCESS:
      return {
        ...state,
        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    image: action.payload.image
                  }
                }
              : property
            : property
        )
      };

    case GENERATE_PREVIEW_IMAGES_SUCCESS:
      return {
        ...state,
        list_properties: state.list_properties.map((property, i) =>
          property.deal
            ? property.deal.id == action.payload.deal_id
              ? {
                  ...property,
                  deal: {
                    ...property.deal,
                    mailer_preview: action.payload.image,
                    mailer_preview_back: action.payload.image_back
                  }
                }
              : property
            : property
        )
      };

    case TRIGGER_LEADS_RELOAD:
      return {
        ...state,
        trigger_leads_reload: action.payload
      };
    case TRIGGER_LEADS_COUNT_RELOAD:
      return {
        ...state,
        trigger_leads_count_reload: action.payload
      };

    case ADD_DEAL_SUCCESS:
      const { add_type, previously_added_lead } = action.payload;
      return {
        ...state,
        list_properties:
          action.payload.added_property && !previously_added_lead
            ? [action.payload.added_property, ...state.list_properties]
            : state.list_properties,
        trigger_leads_count_reload: true,
        updated_properties:
          action.payload.added_property?.property_id && !previously_added_lead
            ? [
                ...state.updated_properties,
                action.payload.added_property?.property_id
              ]
            : state.updated_properties
      };

    case TOGGLE_LEAD_IMAGES:
      return {
        ...state,
        toggle_lead_images: action.payload
      };

    case GET_BULK_EDITS_SUCCESS:
      return {
        ...state,
        bulk_edits: action.payload.bulk_edits
          ? action.payload.bulk_edits
          : state.bulk_edits
      };
    case UPDATE_BULK_EDIT:
      return {
        ...state,
        bulk_edits: action.payload.id
          ? state.bulk_edits.map(bulk_edit => {
              if (bulk_edit.id == action.payload.id) {
                return {
                  ...bulk_edit,
                  total: action.payload.total,
                  percent_complete: action.payload.percent_complete,
                  percent_remaining: action.payload.percent_remaining,
                  leads_updated: action.payload.leads_updated,
                  leads_failed: action.payload.leads_failed
                };
              }
              return bulk_edit;
            })
          : state.bulk_edits
      };
    case GET_MAIL_FAILED_DEALS:
      return {
        ...state,
        mail_failed_properties: [],
        mail_failed_properties_loading: true,
        mail_failed_properties_refreshing: false,
        mail_failed_properties_begin: 0,
        mail_failed_properties_loaded_all: false
      };
    case REFRESH_MAIL_FAILED_DEALS:
      return {
        ...state,
        mail_failed_properties: [],
        mail_failed_properties_loading: false,
        mail_failed_properties_refreshing: true,
        mail_failed_properties_begin: 0,
        mail_failed_properties_loaded_all: false
      };
    case LOAD_MORE_MAIL_FAILED_DEALS:
      return {
        ...state,
        mail_failed_properties_loading: true,
        mail_failed_properties_refreshing: false,
        mail_failed_properties_loaded_all: true
      };
    case GET_MAIL_FAILED_DEALS_FAIL:
      return {
        ...state,
        mail_failed_properties_loading: false,
        mail_failed_properties_refreshing: false
      };
    case GET_MAIL_FAILED_DEALS_SUCCESS:
      return {
        ...state,
        mail_failed_properties_loading: false,
        mail_failed_properties_refreshing: false,
        mail_failed_properties: action.payload.mail_failed_properties,
        mail_failed_properties_begin:
          state.mail_failed_properties_begin +
          action.payload.mail_failed_properties.length,
        mail_failed_properties_loaded_all:
          action.payload.mail_failed_properties.length <
          state.mail_failed_properties_limit
      };
    case LOAD_MORE_MAIL_FAILED_DEALS_SUCCESS:
      return {
        ...state,
        mail_failed_properties: state.mail_failed_properties.concat(
          action.payload.mail_failed_properties
        ),
        mail_failed_properties_loading: false,
        mail_failed_properties_refreshing: false,
        mail_failed_properties_loaded_all:
          action.payload.mail_failed_properties.length <
          state.mail_failed_properties_limit,
        mail_failed_properties_begin:
          state.mail_failed_properties_begin +
          action.payload.mail_failed_properties.length
      };

    case SET_SKIP_TRACE_PAGE:
      return {
        ...state,
        skip_trace_page: action.payload
      };
    case GET_ESTIMATED_INVOICE_INFO:
      return {
        ...state,
        estimated_invoice_info: null,
        estimated_invoice_info_loading: true
      };
    case GET_ESTIMATED_INVOICE_INFO_FAIL:
      return {
        ...state,
        estimated_invoice_info_loading: false
      };
    case GET_ESTIMATED_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        estimated_invoice_info: action.payload.estimated_invoice_info,
        estimated_invoice_info_loading: false
      };
    case TOGGLE_EXIT_STRATEGY:
      return {
        ...state,
        show_exit_strategy_modal: action.payload
      };

    case GET_PROPERTY_IMAGES_SUCCESS:
    case UPDATE_PROPERTY_IMAGE_SUCCESS:
      return {
        ...state,
        list_properties: action.payload.additional_images
          ? state.list_properties.map((property, i) => {
              if (action.payload.property) {
                updateProperty(property, [action.payload.property]);
              } else if (property.deal?.id == action.payload.lead_id) {
                return {
                  ...property,
                  deal: {
                    ...property.deal,
                    additional_images: action.payload.additional_images
                      ? action.payload.additional_images
                      : state.active_property.deal.additional_images
                  }
                };
              }
              return property;
            })
          : state.list_properties,
        photo_gallery:
          state.photo_gallery &&
          state.photo_gallery.lead_id == action.payload.lead_id
            ? {
                ...state.photo_gallery,
                property: action.payload.property
                  ? action.payload.property
                  : state.photo_gallery.property,
                images: action.payload.additional_images
              }
            : state.photo_gallery
      };

    case UPDATE_LEAD_PHOTO_START:
      return {
        ...state,
        uploading_lead_images: [
          ...state.uploading_lead_images,
          {
            lead_id: action.payload.lead_id,
            unique_string: action.payload.unique_string,
            file: action.payload.file,
            error: false,
            progress: 0
          }
        ]
      };

    case UPDATE_LEAD_PHOTO_PROGRESS:
      return {
        ...state,
        uploading_lead_images: state.uploading_lead_images.map(image => {
          if (
            image.lead_id == action.payload.lead_id &&
            image.unique_string == action.payload.unique_string
          ) {
            return {
              ...image,
              progress: action.payload.progress
            };
          }
          return image;
        })
      };

    case UPDATE_LEAD_PHOTO_COMPLETE:
      return {
        ...state,
        uploading_lead_images: state.uploading_lead_images.filter(
          ({ unique_string }) => unique_string != action.payload.unique_string
        ),
        list_properties: action.payload.property
          ? state.list_properties.map((property, i) => {
              if (property.deal?.id == action.payload.lead_id) {
                return action.payload.property;
              }
              return property;
            })
          : state.list_properties,
        photo_gallery:
          state.photo_gallery &&
          state.photo_gallery.lead_id == action.payload.lead_id
            ? {
                ...state.photo_gallery,
                property: action.payload.property,
                images: action.payload.property?.deal?.additional_images
              }
            : state.photo_gallery
      };

    case UPDATE_LEAD_PHOTO_ERROR:
      return {
        ...state,
        uploading_lead_images: state.uploading_lead_images.map(image => {
          if (
            image.lead_id == action.payload.lead_id &&
            image.unique_string == action.payload.unique_string
          ) {
            return {
              ...image,
              progress: 0,
              error: action.payload.error
            };
          }
          return image;
        })
      };

    case TOGGLE_LEAD_PHOTO_MANAGER:
      return {
        ...state,
        toggle_lead_photo_manager: action.payload
      };

    case TOGGLE_PHOTO_GALLERY:
      return {
        ...state,
        toggle_photo_gallery: action.payload
      };

    case SET_PHOTO_GALLERY:
      return {
        ...state,
        photo_gallery: action.payload.photo_gallery
      };

    case SET_EXPORT_MODAL:
      return {
        ...state,
        export_modal: action.payload
      };

    case UPDATE_COMPS_SETTINGS:
      return {
        ...state,
        comps_settings: {
          ...state.comps_settings,
          ...action.payload
        }
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,

        comps_settings: {
          ...state.comps_settings,
          ...action.payload?.user?.user_settings?.comps_settings
        }
      };

    case UPDATE_LEAD_FILTERS:
      return {
        ...state,
        lead_filters: {
          ...state.lead_filters,
          ...action.payload
        }
      };
    case UPDATE_MANUAL_PROPERTY_STATE:
      return {
        ...state,
        manual_property_state: {
          ...state.manual_property_state,
          ...action.payload
        }
      };
    case RESET_MANUAL_PROPERTY_STATE:
      return {
        ...state,
        manual_property_state: { ...INITIAL_STATE.manual_property_state }
      };

    default:
      return state;
  }
};
