//This is a mobile only view. For web it just replaces with a WebContainer component

import React from "react";

const KeyboardView = ({
  scroll_ref,
  children,
  style,
  container_type,
  onScroll = () => {}
}) => {
  return (
    <div
      ref={scroll_ref}
      className={"deal-scroll deal-keyboard-scroll"}
      style={style}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
};

export { KeyboardView };
