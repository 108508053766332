import React, { Component } from "react";
import { Wrapper, Row, Spin, Title, Copy } from "app/NativeComponents/common";
import { List } from "app/NativeComponents/snippets";

import ActivityItem from "./ActivityItem";

class ActivityList extends Component {
  render() {
    if (
      this.props.team_activity_refreshing ||
      (this.props.team_activity.length === 0 &&
        this.props.team_activity_loading)
    ) {
      return (
        <Wrapper
          style={{
            padding: 20,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Row>
            <Spin size={"small"} />
            <Copy style={{ marginLeft: 10 }}>Loading Activity...</Copy>
          </Row>
        </Wrapper>
      );
    }

    if (this.props.team_activity.length > 0) {
      return (
        <Wrapper
          style={{ flex: 1, backgroundColor: this.props.colors.card_color }}
        >
          <List
            rowNumber={1}
            style={{ flex: 1 }}
            items={this.props.team_activity}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <ActivityItem
                  key={index}
                  item={item}
                  token={this.props.token}
                  user={this.props.user}
                  colors={this.props.colors}
                  toggleTeamActivityModal={this.props.toggleTeamActivityModal}
                  selectActiveProperty={this.props.selectActiveProperty}
                  pushSidePanel={this.props.pushSidePanel}
                  toggleTeamActivityList={this.props.toggleTeamActivityList}
                  setActivityPropertiesType={
                    this.props.setActivityPropertiesType
                  }
                  isMobile={this.props.isMobile}
                />
              );
            }}
            canRefresh={true}
            onRefresh={() => {
              this.props.getActivity({
                load_type: "refresh"
              });
            }}
            is_refreshing={this.props.team_activity_refreshing}
            canLoadMore={
              !this.props.team_activity_loaded_all &&
              !this.props.team_activity_loading &&
              !this.props.team_activity_refreshing &&
              this.props.team_activity.length > 0
            }
            isLoadingMore={
              this.props.team_activity_loading &&
              !this.props.team_activity_refreshing &&
              this.props.team_activity.length > 0
            }
            onLoadMore={() => {
              if (
                !this.props.team_activity_loaded_all &&
                this.props.team_activity_refreshing != true &&
                this.props.team_activity_loading != true
              ) {
                this.props.getActivity({
                  load_type: "load_more",
                  begin: this.props.team_activity_begin,
                  filter_by: this.props.filter_by
                });
              }
            }}
          />
        </Wrapper>
      );
    }

    if (this.props.team_activity.length === 0) {
      return (
        <Wrapper
          style={{
            padding: 20,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Title style={{ textAlign: "center" }}>
            There is nothing to display.
          </Title>
          <Copy>There is no activity on your account to display.</Copy>
        </Wrapper>
      );
    }
  }
}

export default ActivityList;
