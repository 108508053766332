import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Row } from "app/NativeComponents/common";

import { SelectItem, InlineButton } from "app/NativeComponents/snippets";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import { determineMainOwnerInfo, formatAddress } from "app/NativeActions";

class MailerIssueItem extends Component {
  render() {
    const {
      item,
      item: { deal },
      colors,
      device,
      isMobile
    } = this.props;
    if (deal) {
      let mailing_addresses = [];

      const owner_info = determineMainOwnerInfo(item);
      let no_address = false;
      if (deal.send_to_owner_address == 1) {
        mailing_addresses.push({
          address: owner_info.owner_address,
          address2: owner_info.owner_address2,
          city: owner_info.owner_address_city,
          state: owner_info.owner_address_state,
          zip: owner_info.owner_address_zip
        });
      }
      if (deal.owner?.mailing_addresses?.length > 0) {
        for (let i = 0; i < deal.owner?.mailing_addresses?.length; i++) {
          if (deal.owner.mailing_addresses[i].send_to_address) {
            mailing_addresses.push(deal.owner.mailing_addresses[i]);
          }
        }
      } else if (deal.send_to_owner_address == 0) {
        no_address = true;
        mailing_addresses.push({
          address: deal.property_address_full,
          address2: " ",
          city: "No Address Set to Mail To",
          state: " ",
          zip: "Set an address to mail to"
        });
      }
      return (
        <>
          {mailing_addresses.map((address, i) => {
            return (
              <SelectItem
                key={"queue_" + i}
                select_type="none"
                label={"Failed to send to:"}
                description={
                  owner_info.owner_name || !!address.address
                    ? formatAddress({
                        address: {
                          address: address.address,
                          address2: address.address2,
                          address_city: address.city,
                          address_state: address.state,
                          address_zip: address.zip
                        }
                      }).line2
                    : "Mail will not be sent"
                }
                noPress={true}
                renderBottom={() => {
                  return (
                    <Wrapper
                      style={{ padding: 25, paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Copy
                        style={{
                          marginBottom: 5
                        }}
                      >
                        Issue:{" "}
                        {deal.mail_failed_info &&
                        deal.mail_failed_info.length > 0 &&
                        !no_address
                          ? deal.mail_failed_info[0].short_message
                          : no_address
                          ? "No Address Set to Mail to"
                          : "n/a"}
                      </Copy>
                      <Copy
                        style={{
                          marginBottom: 5
                        }}
                      >
                        Resolution:{" "}
                        {deal.mail_failed_info &&
                        deal.mail_failed_info.length > 0 &&
                        !no_address
                          ? deal.mail_failed_info[0].long_message
                          : no_address
                          ? "You need to set at least one address to send to for this lead."
                          : "n/a"}
                      </Copy>
                    </Wrapper>
                  );
                }}
                renderRight={() => {
                  return (
                    <Row>
                      <InlineButton
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "property",
                            id: item?.property_id,
                            focus_side_panel:
                              this.props.user?.user_settings
                                ?.property_expanded === "not_expanded"
                                ? false
                                : true,
                            overlay: true,
                            data: {
                              property: item
                            }
                          });
                        }}
                      >
                        View Lead
                      </InlineButton>
                    </Row>
                  );
                }}
              >
                {!!owner_info.owner_name || !!address.address
                  ? formatAddress({
                      address: {
                        address: address.address,
                        address2: address.address2,
                        address_city: address.city,
                        address_state: address.state,
                        address_zip: address.zip
                      }
                    }).line1
                  : "Owner not found"}
              </SelectItem>
            );
          })}
        </>
      );
    }
    return null;
  }
}

export default MailerIssueItem;
