import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactGA from "react-ga4";
import { logMarketingVisit } from "app/NativeActions";

const queryString = require("query-string");

class VisitorWrapper extends Component {
  constructor(props) {
    super(props);

    const url = new URL(window?.location.href);

    const referrer = url.searchParams.get("referrer")
      ? url.searchParams.get("referrer")
      : document.referrer || null;

    const experiments = url.searchParams.get("experiments");

    this.state = {
      referrer,
      experiments,
      logged_visit: false,
      device: url.searchParams.get("device") || props.device,
      platform: url.searchParams.get("platform")
        ? url.searchParams.get("platform")
        : props.device == "desktop"
        ? props.isMobile
          ? "mobile"
          : "desktop"
        : props.platform
    };

    this.logVisit = this.logVisit.bind(this);
  }

  componentDidMount() {
    //get utm params
    this.logVisit();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  logVisit() {
    if (!this.state.logged_visit) {
      const location = this.props.location;
      const parsed = queryString.parse(location?.search);

      //get hubspot cookie
      const hubspot_cookie =
        document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ) || null;

      //get google analytics cookie
      let google_analytics_cookie = null;
      ReactGA.ga(tracker => {
        google_analytics_cookie = tracker.get("clientId") || null;
      });

      //get first promoter cookie
      const first_promoter_ref = parsed?.fpr || null;
      const first_promoter_cookie =
        document.cookie.replace(
          /(?:(?:^|.*;\s*)_fprom_tid\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ) || null;

      //get podsights key
      //const podsights = window?.pdst;

      const { platform, device, referrer, experiments } = this.state;
      const utm_params = {
        platform,
        device,
        utm_campaign: parsed?.utm_campaign,
        utm_content: parsed?.utm_content,
        utm_medium: parsed?.utm_medium,
        utm_source: parsed?.utm_source,
        utm_source_platform: parsed?.utm_source_platform,
        utm_term: parsed?.utm_term,
        utm_referrer: parsed?.utm_referrer,
        referrer,
        visit_data: parsed,
        hubspot_cookie,
        google_analytics_cookie,
        first_promoter_ref,
        first_promoter_cookie,
        experiments
      };

      this.setState(
        {
          logged_visit: true
        },
        () => {
          this.props.logMarketingVisit(utm_params);
        }
      );
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ native, auth }) => {
  const { platform, device, isMobile } = native;
  const { user, token } = auth;
  return {
    platform,
    device,
    isMobile,
    user
  };
};

export default withRouter(connect(null, { logMarketingVisit })(VisitorWrapper));
