import {
  LOGOUT,
  RESET_ACTIVITY,
  DRAG_PANEL,
  TOGGLE_DRAG,
  CHANGE_MENTION,
  GET_ACTIVITY,
  GET_ACTIVITY_FAIL,
  GET_ACTIVITY_SUCCESS,
  POST_NOTE,
  POST_NOTE_FAIL,
  POST_NOTE_SUCCESS,
  UPDATE_NOTE,
  UPDATE_NOTE_FAIL,
  UPDATE_NOTE_SUCCESS,
  REMOVE_NOTE_SUCCESS,
  EDIT_NOTE_FIELD_CHANGED,
  INIT_EDIT_NOTE,
  TOGGLE_EDIT_NOTE,
  UPDATE_ACTIVITY,
  TRIGGER_ACTIVITY_UPDATE,
  IS_DRAGGING,
  INIT_MAIL_TIMELINE,
  TOGGLE_TEAM_OPTIONS,
  EDIT_TEAM_SEARCH_CHANGED,
  TEAM_SEARCH_CHANGED,
  GET_TEAM_ACTIVITY,
  GET_TEAM_ACTIVITY_FAIL,
  GET_TEAM_ACTIVITY_SUCCESS,
  REFRESH_TEAM_ACTIVITY,
  LOAD_MORE_TEAM_ACTIVITY,
  GET_ACTIVITY_PROPERTIES,
  GET_ACTIVITY_PROPERTIES_FAIL,
  GET_ACTIVITY_PROPERTIES_SUCCESS,
  REFRESH_ACTIVITY_PROPERTIES,
  LOAD_MORE_ACTIVITY_PROPERTIES,
  UPDATE_DETAILED_OPTIONS,
  SET_ACTIVITY_PROPERTIES_TYPE,
  GET_MAILERS,
  GET_MAILERS_FAIL,
  GET_MAILERS_SUCCESS,
  REFRESH_MAILERS,
  LOAD_MORE_MAILERS,
  SWITCH_NOTIFICATION_PANEL_TAB,
  GET_CALENDAR,
  GET_CALENDAR_FAIL,
  GET_CALENDAR_SUCCESS,
  REFRESH_CALENDAR,
  OPEN_ACTIVITY_TAB,
  TOGGLE_TEAM_ACTIVITY_MODAL,
  TOGGLE_TEAM_ACTIVITY_LIST,
  TOGGLE_MAIL_TIMELINE
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  dragged_value: -75,
  drag_toggle: true,
  is_dragging: false,
  refreshing: false,
  reload: false,
  loading: false,
  error: "",
  activity_items: [],
  editNote: {},
  show_edit_note: false,
  show_mail_timeline: false,
  originalNote: {},
  mail_timeline: [],
  detailed_options: false,

  team_activity: [],
  team_activity_loading: false,
  team_activity_refreshing: false,
  team_activity_loaded_all: false,
  team_activity_begin: 0,
  team_activity_limit: 25,
  toggle_team_options: "",
  teamSearch: "",
  editTeamSearch: "",

  activity_properties: [],
  activity_properties_loading: false,
  activity_properties_refreshing: false,
  activity_properties_loaded_all: false,
  activity_properties_begin: 0,
  activity_properties_limit: 25,

  activity_properties_type: null,

  mailers: [],
  mailers_loading: false,
  mailers_refreshing: false,
  mailers_loaded_all: false,
  mailers_begin: 0,
  mailers_limit: 25,

  calendar_loading: false,
  calendar_error: "",
  calendar_events: [],

  notification_panel_tab: "tasks",

  activity_tab: "activity",
  is_activity_open: false,
  show_team_activity_modal: false,
  show_team_activity_list: false
};

const combineCalendarData = (
  current_events,
  new_events,
  array_length = 600
) => {
  let combined_events = [];

  for (var i = 0; i < current_events.length; i++) {
    var add_calendar_event = current_events[i];
    var found = false;
    for (var j = 0; j < new_events.length; j++) {
      if (new_events[j].calendar_date == add_calendar_event.calendar_date) {
        found = true;
        add_calendar_event = new_events[j];
      }
    }

    combined_events.push(add_calendar_event);
  }

  for (var k = 0; k < new_events.length; k++) {
    var add_new_calendar_event = new_events[k];
    var new_found = false;

    for (var l = 0; l < combined_events.length; l++) {
      if (
        combined_events[l].calendar_date == add_new_calendar_event.calendar_date
      ) {
        new_found = true;
      }
    }
    if (new_found != true) {
      combined_events.push(add_new_calendar_event);
    }
  }

  if (combined_events.length >= array_length && array_length != 0) {
    return new_events;
  } else {
    return combined_events;
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case RESET_ACTIVITY:
      return {
        ...state,
        activity_items: INITIAL_STATE.activity_items,
        loading: INITIAL_STATE.loading,
        refreshing: INITIAL_STATE.refreshing,
        error: INITIAL_STATE.error
      };

    case TOGGLE_DRAG:
      return {
        ...state,
        drag_toggle: action.payload
      };
    case IS_DRAGGING:
      return {
        ...state,
        is_dragging: action.payload
      };

    case DRAG_PANEL:
      return {
        ...state,
        dragged_value: action.payload
      };

    case GET_ACTIVITY:
      return {
        ...state,
        activity_items: [],
        loading: true,
        error: ""
      };
    case UPDATE_ACTIVITY:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case TRIGGER_ACTIVITY_UPDATE:
      return {
        ...state,
        reload: action.payload
      };
    case GET_ACTIVITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activity_items: action.payload.activity
      };
    case POST_NOTE:
      return {
        ...state,
        error: "",
        activity_items: [action.payload, ...state.activity_items]
      };

    case UPDATE_NOTE:
      return {
        ...state,
        error: ""
      };
    case POST_NOTE_FAIL:
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case POST_NOTE_SUCCESS:
    case UPDATE_NOTE_SUCCESS:
    case REMOVE_NOTE_SUCCESS:
      return {
        ...state,
        activity_items: action.payload.activity
      };
    case INIT_EDIT_NOTE:
      return {
        ...state,
        editNote: action.payload.note,
        originalNote: action.payload.note
      };
    case TOGGLE_EDIT_NOTE:
      return {
        ...state,
        show_edit_note: action.payload
      };
    case TOGGLE_MAIL_TIMELINE:
      return {
        ...state,
        show_mail_timeline: action.payload
      };
    case EDIT_NOTE_FIELD_CHANGED:
      return {
        ...state,
        editNote: {
          ...state.editNote,
          [action.payload.prop]: action.payload.value
        }
      };
    case INIT_MAIL_TIMELINE:
      return {
        ...state,
        mail_timeline: action.payload.addresses
      };

    case GET_TEAM_ACTIVITY:
      return {
        ...state,
        team_activity: [],
        team_activity_loading: true,
        team_activity_refreshing: false,
        team_activity_begin: 0,
        team_activity_loaded_all: false
      };
    case REFRESH_TEAM_ACTIVITY:
      return {
        ...state,
        team_activity: [],
        team_activity_loading: false,
        team_activity_refreshing: true,
        team_activity_begin: 0,
        team_activity_loaded_all: false
      };
    case LOAD_MORE_TEAM_ACTIVITY:
      return {
        ...state,
        team_activity_loading: true,
        team_activity_refreshing: false,
        team_activity_loaded_all: true
      };
    case GET_TEAM_ACTIVITY_FAIL:
      return {
        ...state,
        team_activity_loading: false,
        team_activity_refreshing: false
      };
    case GET_TEAM_ACTIVITY_SUCCESS:
      return {
        ...state,
        team_activity_loading: false,
        team_activity_refreshing: false,
        team_activity: state.team_activity.concat(action.payload.activity),
        team_activity_begin:
          state.team_activity_begin + action.payload.activity.length,
        team_activity_loaded_all:
          action.payload.activity.length < state.team_activity_limit
      };

    case TOGGLE_TEAM_OPTIONS:
      return {
        ...state,
        toggle_team_options: action.payload
      };
    case TEAM_SEARCH_CHANGED:
      return {
        ...state,
        begin: 0,
        teamSearch: action.payload
      };
    case EDIT_TEAM_SEARCH_CHANGED:
      return {
        ...state,
        editTeamSearch: action.payload
      };

    case UPDATE_DETAILED_OPTIONS:
      return {
        ...state,
        detailed_options: action.payload
      };

    case GET_ACTIVITY_PROPERTIES:
      return {
        ...state,
        activity_properties: [],
        activity_properties_loading: true,
        activity_properties_refreshing: false,
        activity_properties_begin: 0,
        activity_properties_loaded_all: false
      };
    case REFRESH_ACTIVITY_PROPERTIES:
      return {
        ...state,
        activity_properties: [],
        activity_properties_loading: false,
        activity_properties_refreshing: true,
        activity_properties_begin: 0,
        activity_properties_loaded_all: false
      };
    case LOAD_MORE_ACTIVITY_PROPERTIES:
      return {
        ...state,
        activity_properties_loading: true,
        activity_properties_refreshing: false,
        activity_properties_loaded_all: true
      };
    case GET_ACTIVITY_PROPERTIES_FAIL:
      return {
        ...state,
        activity_properties_loading: false,
        activity_properties_refreshing: false
      };
    case GET_ACTIVITY_PROPERTIES_SUCCESS:
      return {
        ...state,
        activity_properties_loading: false,
        activity_properties_refreshing: false,
        activity_properties: state.activity_properties.concat(
          action.payload.properties
        ),
        activity_properties_begin:
          state.activity_properties_begin + action.payload.properties.length,
        activity_properties_loaded_all:
          action.payload.properties.length < state.activity_properties_limit
      };
    case SET_ACTIVITY_PROPERTIES_TYPE:
      return {
        ...state,
        activity_properties_type: action.payload,
        activity_properties: []
      };

    case GET_MAILERS:
      return {
        ...state,
        mailers: [],
        mailers_loading: true,
        mailers_refreshing: false,
        mailers_begin: 0,
        mailers_loaded_all: false
      };
    case REFRESH_MAILERS:
      return {
        ...state,
        mailers: [],
        mailers_loading: false,
        mailers_refreshing: true,
        mailers_begin: 0,
        mailers_loaded_all: false
      };
    case LOAD_MORE_MAILERS:
      return {
        ...state,
        mailers_loading: true,
        mailers_refreshing: false,
        mailers_loaded_all: true
      };
    case GET_MAILERS_FAIL:
      return {
        ...state,
        mailers_loading: false,
        mailers_refreshing: false
      };
    case GET_MAILERS_SUCCESS:
      return {
        ...state,
        mailers_loading: false,
        mailers_refreshing: false,
        mailers: state.mailers.concat(action.payload.mailers),
        mailers_begin: action.payload.mailers
          ? state.mailers_begin + action.payload.mailers.length
          : state.mailers_begin,
        mailers_loaded_all: action.payload.mailers
          ? action.payload.mailers.length < state.mailers_limit
          : true
      };
    case SWITCH_NOTIFICATION_PANEL_TAB:
      return {
        ...state,
        notification_panel_tab: action.payload
      };

    case GET_CALENDAR:
      return {
        ...state,
        calendar_loading: true,
        calendar_error: ""
      };
    case REFRESH_CALENDAR:
      return {
        ...state,
        calendar_loading: true,
        calendar_error: "",
        calendar_events: []
      };
    case GET_CALENDAR_FAIL:
      return {
        ...state,
        calendar_loading: false,
        calendar_error: action.payload
      };
    case GET_CALENDAR_SUCCESS:
      return {
        ...state,
        calendar_loading: false,
        calendar_error: action.payload,
        calendar_events: combineCalendarData(
          state.calendar_events,
          action.payload.calendar_events
        )
      };
    case OPEN_ACTIVITY_TAB:
      return {
        ...state,
        is_activity_open: action.payload.is_activity_open,
        activity_tab: action.payload.activity_tab
          ? action.payload.activity_tab
          : state.activity_tab
      };
    case TOGGLE_TEAM_ACTIVITY_MODAL:
      return {
        ...state,
        show_team_activity_modal: action.payload
      };
    case TOGGLE_TEAM_ACTIVITY_LIST:
      return {
        ...state,
        show_team_activity_list: action.payload
      };
    default:
      return state;
  }
};
