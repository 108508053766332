import React, { Component } from "react";
import { Wrapper, Row, Copy } from "app/NativeComponents/common";
import ZoomButtons from "./ZoomButtons";
class BottomNav extends Component {
  render() {
    const { colors, isMobile } = this.props;
    return (
      <Row>
        <Wrapper style={{ flex: 1 }}></Wrapper>
        <Row>
          <ZoomButtons {...this.props} />
        </Row>
      </Row>
    );
  }
}

export default BottomNav;
