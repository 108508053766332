import React, { PureComponent } from "react";
import { Wrapper, Copy } from "app/NativeComponents/common";
import { store } from "app/store";

class HeaderSubtitle extends PureComponent {
  render() {
    const { subtitle, titleColor } = this.props;
    if (subtitle) {
      const colors = store.getState().settings.colors;

      return (
        <Copy style={{ color: titleColor || colors.text_color }}>
          {subtitle}
        </Copy>
      );
    }

    return <Wrapper />;
  }
}

export default HeaderSubtitle;
