import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Bold,
  Button,
  Icon,
  Row
} from "app/NativeComponents/common";
import Line from "./Line";

class NewComponentButton extends Component {
  render() {
    const {
      colors,
      onPress,
      onCancel,
      step_index,
      branch_index = null,
      branch_step_index = null,
      add_step_type
    } = this.props;

    const is_active =
      add_step_type?.step_index == step_index &&
      add_step_type?.branch_index == branch_index &&
      add_step_type?.branch_step_index == branch_step_index;

    if (is_active) {
      return (
        <>
          <Row style={{ justifyContent: "center" }}>
            <Button
              onPress={onCancel}
              tooltip="Cancel"
              style={{
                borderColor: colors.active_color,
                backgroundColor: colors.card_color,
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 15,
                width: 30,
                height: 30,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Row>
                <Icon size={18} icon="close" color={colors.active_color} />
              </Row>
            </Button>
          </Row>
          <Line colors={colors} />
          <Wrapper
            style={{
              borderColor: colors.active_color,
              backgroundColor: colors.card_color,
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 5,
              margin: "auto",

              width: 350
            }}
          >
            <Wrapper>
              <Wrapper
                style={{
                  padding: 15,
                  borderBottomColor: colors.active_color,
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid"
                }}
              >
                <Copy>
                  <Bold>New Step</Bold>
                </Copy>
              </Wrapper>
              <Wrapper style={{ padding: 20 }}>
                <Copy
                  style={{
                    textAlign: "center",
                    color: colors.light_text_color
                  }}
                >
                  Configuring...
                </Copy>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </>
      );
    }

    return (
      <Row style={{ justifyContent: "center" }}>
        <Button
          onPress={onPress}
          tooltip="Add Step"
          style={{
            borderColor: colors.border_color,
            backgroundColor: colors.card_color,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 15,
            width: 30,
            height: 30,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Row>
            <Icon size={18} icon="add" color={colors.text_color} />
          </Row>
        </Button>
      </Row>
    );
  }
}

export default NewComponentButton;
