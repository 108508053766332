import React, { Component } from "react";
import { connect } from "react-redux";

import {
  KeyboardView,
  Wrapper,
  Copy,
  Container
} from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateUser,
  dismissMobileKeyboard
} from "app/NativeActions";

class ChangeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_address: props.user?.email,
      loading: false
    };

    this.handleBack = this.handleBack.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this._email_address_input = React.createRef();
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  changeEmail() {
    dismissMobileKeyboard();

    this.props.updateUser({
      token: this.props.token,
      type: "change_verify_email",
      payload: {
        email: this.state.email_address
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  renderBody() {
    return (
      <KeyboardView style={{ flex: 1 }}>
        <Wrapper style={{ padding: 25 }}>
          <Copy>
            Enter a new email address to change the email address of your
            account. We'll then send a new verification code to that address.
          </Copy>
        </Wrapper>

        <InputBox
          input_ref={this._email_address_input}
          name="email_address"
          disabled={this.state.loading}
          returnKeyType="next"
          placeholder="Email Address"
          onChange={value => {
            this.setState({ email_address: value });
          }}
          onSubmitEditing={this.changeEmail}
          blurOnSubmit={false}
          value={this.state.email_address}
          type="text"
        />
        <GhostButton
          button_type="full"
          primary={true}
          onPress={this.changeEmail}
          loading={this.state.loading}
        >
          Change Email Address
        </GhostButton>
      </KeyboardView>
    );
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="Change Email Address"
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />
        {this.renderBody()}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, property_map, settings }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { loading } = settings;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateUser
})(ChangeEmail);
