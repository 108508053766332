import React, { Component } from "react";

import {
  Row,
  Wrapper,
  Button,
  Copy,
  Bold,
  Icon,
  Card
} from "app/NativeComponents/common";
import { PopoverMenu } from "app/NativeComponents/snippets";

class TopOptions extends Component {
  componentDidMount() {}
  componentDidUpdate(prevProps) {}

  determineSortByOptions(headers) {
    let sort_by_options = [];
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].sortable) {
        sort_by_options.push({
          title: headers[i].title + " Ascending",
          icon: "keyboard-arrow-up",
          onPress: () => {
            this.props.onSortBy({
              sort_by: headers[i].slug,
              sort_by_dir: "asc",
              sort_by_title: headers[i].title + " Ascending"
            });
          },
          sort_by: headers[i].slug,
          sort_by_dir: "asc"
        });

        sort_by_options.push({
          title: headers[i].title + " Descending",
          icon: "keyboard-arrow-down",
          onPress: () => {
            this.props.onSortBy({
              sort_by: headers[i].slug,
              sort_by_dir: "desc",
              sort_by_title: headers[i].title + " Descending"
            });
          },
          sort_by: headers[i].slug,
          sort_by_dir: "desc"
        });
      }
    }

    return sort_by_options;
  }
  render() {
    const {
      include_checkboxes,
      is_selecting,
      selected_all,
      toggleSelecting,
      onCheckBoxHeaderPress,
      headers,
      sort_by_title
    } = this.props;

    const sort_by_options = this.determineSortByOptions(headers);

    return (
      <Row style={{ justifyContent: "space-between" }}>
        {include_checkboxes ? (
          !is_selecting ? (
            <Wrapper>
              <Button onPress={() => toggleSelecting(true)}>
                <Card
                  style={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    borderRadius: 30
                  }}
                >
                  <Row>
                    <Icon
                      icon="check-box"
                      size={18}
                      style={{ marginRight: 5 }}
                    />
                    <Copy>
                      <Bold>Select</Bold>
                    </Copy>
                  </Row>
                </Card>
              </Button>
            </Wrapper>
          ) : (
            <Wrapper>
              <Row>
                <Button
                  onPress={() => {
                    onCheckBoxHeaderPress({ clear: true });
                    toggleSelecting(false);
                  }}
                >
                  <Card
                    style={{
                      padding: 5,
                      paddingRight: 10,
                      paddingLeft: 10,
                      borderRadius: 30
                    }}
                  >
                    <Row>
                      <Icon icon="clear" size={18} style={{ marginRight: 5 }} />
                      <Copy>
                        <Bold>Cancel Selection</Bold>
                      </Copy>
                    </Row>
                  </Card>
                </Button>
                {!selected_all ? (
                  <Button onPress={() => onCheckBoxHeaderPress({})}>
                    <Card
                      style={{
                        padding: 5,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 30
                      }}
                    >
                      <Row>
                        <Copy>
                          <Bold>Select All</Bold>
                        </Copy>
                      </Row>
                    </Card>
                  </Button>
                ) : null}
              </Row>
            </Wrapper>
          )
        ) : null}
        {!is_selecting && sort_by_options && sort_by_options.length > 0 ? (
          <PopoverMenu
            style={{}}
            tooltipPlacement="bottom"
            renderComponent={() => {
              return (
                <Wrapper
                  style={{
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10,
                    borderRadius: 30
                  }}
                >
                  <Row>
                    <Icon icon="sort" size={14} style={{ marginRight: 5 }} />
                    <Wrapper>
                      <Copy>Sort By</Copy>
                    </Wrapper>
                  </Row>
                </Wrapper>
              );
            }}
            menu_items={sort_by_options}
          />
        ) : null}
      </Row>
    );
  }
}

export default TopOptions;
