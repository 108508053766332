import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import { GhostButton, SelectItem } from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import QuestionSelect from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/QuestionSelect";

import { numberWithCommas, startRedirect, updateUser } from "app/NativeActions";
class WhatIsYourIndustry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_option: null
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/what-kind-of-business-do-you-have");
    }
  }

  addOrRemoveOption(slug) {
    const { selected_options } = this.state;
    this.setState({
      selected_option: slug
    });
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>What kind of business do you have?</Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            <Copy
              style={{
                textAlign: "center"
              }}
            >
              Select the option that best describes your business.
            </Copy>
          </Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <Row
              style={
                this.props.device == "mobile"
                  ? {
                      padding: 10,
                      alignSelf: "stretch",
                      flexDirection: "column"
                    }
                  : this.props.desktopMobile
                  ? {
                      padding: 10,
                      alignSelf: "stretch",
                      flexDirection: "column"
                    }
                  : {
                      flexWrap: "wrap",
                      padding: 25,
                      justifyContent: "center"
                    }
              }
            >
              <QuestionSelect
                slug={"Real Estate Investing"}
                selected_option={this.state.selected_option}
                onPress={() => this.addOrRemoveOption("Real Estate Investing")}
                title={"Real Estate Investing"}
                select_type={"radio"}
              />
              <QuestionSelect
                slug={"Real Estate Wholesaling"}
                selected_option={this.state.selected_option}
                onPress={() =>
                  this.addOrRemoveOption("Real Estate Wholesaling")
                }
                title={"Real Estate Wholesaling"}
                select_type={"radio"}
              />
              <QuestionSelect
                slug={"Real Estate Agent"}
                selected_option={this.state.selected_option}
                onPress={() => this.addOrRemoveOption("Real Estate Agent")}
                title={"Real Estate Agent"}
                select_type={"radio"}
              />
              <QuestionSelect
                slug={"Mortgage Lending"}
                selected_option={this.state.selected_option}
                onPress={() => this.addOrRemoveOption("Mortgage Lending")}
                title={"Mortgage Lending"}
                select_type={"radio"}
              />
              <QuestionSelect
                slug={"Title Company"}
                selected_option={this.state.selected_option}
                onPress={() => this.addOrRemoveOption("Title Company")}
                title={"Title Company"}
                select_type={"radio"}
              />
              <QuestionSelect
                slug={"Home Services"}
                selected_option={this.state.selected_option}
                onPress={() => this.addOrRemoveOption("Home Services")}
                title={"Home Services"}
                select_type={"radio"}
              />
              <QuestionSelect
                slug={"Other"}
                selected_option={this.state.selected_option}
                onPress={() => this.addOrRemoveOption("Other")}
                title={"Other"}
                select_type={"radio"}
              />
            </Row>
            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              disabled={!!this.state.selected_options}
              icon_right={"arrow-right-alt"}
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "industry",
                  property_value: this.state.selected_option
                });
                //update users table too
                this.props.updateUser({
                  token: this.props.token,
                  type: "update_industry",
                  payload: {
                    industry: this.state.selected_option
                  },
                  no_loading: true
                });
                this.props.nextStep();
              }}
            >
              Continue
            </GhostButton>
            <Wrapper style={{ height: 40 }} />
          </Wrapper>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser
})(WhatIsYourIndustry);
