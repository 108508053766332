import React from "react";
import ReactImageFallback from "react-image-fallback";
import { AppConfig } from "app/NativeActions";
import { store } from "app/store";
import { Wrapper, Copy } from "app/NativeComponents/common";

const md5 = require("js-md5");

const getThumbnail = (image, use_full) => {
  if (
    image.startsWith("https://dealmachine.s3.amazonaws.com") &&
    use_full === false
  ) {
    return `${AppConfig().api_url}image/?url=${image}&type=thumb`;
  }
  return image;
};

const ProfilePic = ({
  email,
  image,
  firstname,
  lastname,
  size,
  mask,
  use_full = false,
  style,
  background_color = null,
  fontSize = 14,
  fontColor = null
}) => {
  const dark_mode = store.getState().settings.dark_mode;
  const colors = store.getState().settings.colors;

  // Compute initials
  let image_initials = "";
  if (firstname?.length > 0 && lastname?.length > 0) {
    image_initials = firstname[0] + lastname[0];
  } else if (firstname?.length > 1) {
    image_initials = firstname[0] + firstname[1];
  }
  image_initials = image_initials.toUpperCase();

  const bg_color = background_color
    ? background_color
    : colors.background_color;
  const c = fontColor ? fontColor : colors.text_color;

  const renderInitials = () => (
    <div
      className="dm-profile-image"
      style={{ ...style, opacity: dark_mode === "dark_mode" ? 0.6 : 1 }}
    >
      <Wrapper
        style={{
          width: size || 60,
          height: size || 60,
          borderRadius: size ? size / 2 : 30,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: bg_color
        }}
      >
        <Copy
          style={{
            width: size || 60,
            textAlign: "center",
            fontSize: fontSize,
            color: c
          }}
        >
          {image_initials}
        </Copy>
      </Wrapper>
    </div>
  );

  // If there's a direct image link
  if (!!image || !!email) {
    return (
      <ReactImageFallback
        className="dm-profile-image"
        style={{
          ...style,
          opacity: dark_mode === "dark_mode" ? 0.6 : 1,
          width: size || 60,
          height: size || 60,
          borderRadius: size ? size / 2 : 30
        }}
        src={
          !!image
            ? getThumbnail(image, use_full)
            : !!email
            ? `https://www.gravatar.com/avatar/${md5(
                email.toLowerCase()
              )}?s=100.jpg&d=404`
            : null
        }
        initialImage={
          !!email
            ? `https://www.gravatar.com/avatar/${md5(
                email.toLowerCase()
              )}?s=100.jpg&d=404`
            : null
        }
        fallbackImage={
          image_initials
            ? renderInitials()
            : "https://www.gravatar.com/avatar/?s=100.jpg&d=mm"
        }
      />
    );
  }

  // If no image and no email but there are initials
  if (image_initials) {
    return renderInitials();
  }

  // Default fallback
  return (
    <div
      className="dm-profile-image"
      style={{ ...style, opacity: dark_mode === "dark_mode" ? 0.6 : 1 }}
    >
      <img
        style={{
          width: size || 60,
          height: size || 60,
          borderRadius: size ? size / 2 : 30
        }}
        src="https://www.gravatar.com/avatar/?s=100.jpg&d=mm"
      />
    </div>
  );
};

export { ProfilePic };
