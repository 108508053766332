import React, { Component } from "react";
import { Wrapper, Form } from "app/NativeComponents/common";
import { InlineButton, BottomNavBar } from "app/NativeComponents/snippets";

import Inputs from "./Inputs";
import Buttons from "./Buttons";

class Body extends Component {
  render() {
    return (
      <>
        <Form onSubmit={() => this.props.register()}>
          <Inputs {...this.props} />
          <Buttons {...this.props} />
        </Form>
      </>
    );
  }
}

export default Body;
