import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  List,
  InlineButton,
  IconButton,
  SelectItem,
  Tag,
  ConfirmLabel,
  SmallLabel,
  SpinWrapper
} from "app/NativeComponents/snippets";
import {
  updateLead,
  getLists,
  toTitleCase,
  getListStackColor,
  addListToListTabs,
  pushSidePanel,
  renderDate
} from "app/NativeActions";

class ShowStackedListsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false,
      hide_unselected: false,
      selected_lists: props.property?.deal?.lists || [],
      stacked_lists: props.property?.deal?.stacked_lists || [],
      loading_options: []
    };

    this._scroll_view = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { property, lists, lists_loading } = this.props;
    if (prevState.show !== this.state.show && lists?.length == 0) {
      this.props.getLists({
        token: this.props.token,
        load_type: lists.length == 0 ? "load" : "reload",
        no_count: true,
        type: "all_lists",
        begin: 0
      });
    }

    if (
      property?.property_id !== prevProps.property?.property_id ||
      (property?.deal?.lists !== prevProps.property?.deal?.lists &&
        this.state.loading_options.length == 0) ||
      (this.state.show && prevState.show !== this.state.show)
    ) {
      this.setState({
        selected_lists:
          property && property.deal && property.deal.lists
            ? property.deal.lists
            : [],
        loading_options: []
      });
    }

    if (
      this.state.selected_lists?.length !== prevProps.selected_lists?.length &&
      this.state.selected_lists.length == 0 &&
      this.state.hide_unselected == true
    ) {
      this.setState({
        hide_unselected: false
      });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      lists,
      lists_loading,
      style,
      property,
      popoverPlacement = "bottom",
      renderComponent = () => {},
      renderComponentLoading = () => {},
      componentStyle = {}
    } = this.props;
    const {
      selected_lists,
      stacked_lists,
      loading_options,
      search,
      show,
      hide_unselected
    } = this.state;

    let in_selected_lists = [];
    for (let j = 0; j < lists.length; j++) {
      for (let i = 0; i < selected_lists.length; i++) {
        if (
          lists[j].value == selected_lists[i].value &&
          lists[j].included_in_stack_count
        ) {
          in_selected_lists.push(lists[j]);
        }
      }
    }

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={400}
        popover_height={450}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={
          property
            ? "Stacked lists for " + property.property_address
            : "Stacked Lists"
        }
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <Wrapper
              wrapper_ref={this._popover}
              style={{ flex: device == "mobile" ? 0 : 1 }}
            >
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {lists_loading ? (
                <SpinWrapper text="Loading Lists..." />
              ) : (
                <>
                  <Wrapper
                    style={{ alignSelf: "stretch", alignItems: "flex-start" }}
                  >
                    <ConfirmLabel
                      style={{
                        alignSelf: "stretch",
                        backgroundColor: getListStackColor(stacked_lists.length)
                      }}
                      icon="playlist-add-check"
                      label={
                        stacked_lists.length == 1
                          ? "1 list matching stack count"
                          : stacked_lists.length + " lists matching stack count"
                      }
                      /*
                      description={
                        selected_lists.length > stacked_lists.length
                          ? selected_lists.length == 1
                            ? "1 list selected"
                            : selected_lists.length + " total lists selected"
                          : ""
                      }
                      */
                      renderRight={() => {
                        return (
                          <IconButton
                            tooltip="Manage List Stacking"
                            tooltipPlacement="top"
                            icon={"settings"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "list_stack_settings",
                                overlay: true
                              });
                              this.setState({
                                show: false
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </Wrapper>
                  <SmallLabel
                    style={{
                      padding: 20,
                      paddingTop: 15,
                      paddingBottom: 0,

                      alignSelf: "stretch"
                    }}
                  >
                    Stacked lists:
                  </SmallLabel>
                  <List
                    style={{ alignSelf: "stretch" }}
                    items={in_selected_lists}
                    itemStructure={({ item, index }) => {
                      return (
                        <SelectItem
                          key={"option_" + index}
                          select_type={"none"}
                          hover_icon_color={colors.orange_color}
                          selected={in_selected_lists}
                          noPress={true}
                          subtext={renderDate(item.date_created)}
                        >
                          {item.label}
                        </SelectItem>
                      );
                    }}
                  />
                </>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, list, feature_toggle }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors, dark_mode } = settings;
  const { lists, lists_loading } = list;

  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode,
    lists,
    lists_loading
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getLists,
  addListToListTabs,
  pushSidePanel
})(ShowStackedListsButton);
