import React, { PureComponent } from "react";
import { Button, Wrapper, Row, Icon, Copy } from "app/NativeComponents/common";

import { ChromePicker } from "react-color";
import tinycolor from "tinycolor2";
import { store } from "app/store";
import Popover from "react-simple-popover";
import {
  InputBox,
  IconButton,
  InlineButton
} from "app/NativeComponents/snippets";

class ColorPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      color: props.value
    };

    this._color_picker = React.createRef();
  }

  renderColorPicker({ color, onChange }) {
    if (this.state.show) {
      const colors = store.getState().settings.colors;

      return (
        <Popover
          placement={this.props.popoverPlacement || "right"}
          target={this._color_picker.current}
          show={this.state.show}
          containerStyle={{ opacity: 1, zIndex: 1 }}
          style={{
            width: 225,
            borderRadius: 5,
            padding: 0,
            overflow: "hidden"
          }}
          onHide={() => this.setState({ show: false })}
        >
          <ChromePicker
            color={color}
            onChange={color => {
              this.setState({ color });
            }}
            disableAlpha={true}
            onChangeComplete={onChange}
          />

          <InlineButton
            button_type={"full"}
            onPress={() => {
              this.setState({
                color:
                  this.state.color == "transparent" ? "#ffffff" : "transparent"
              });
              onChange(
                this.state.color == "transparent" ? "#ffffff" : "transparent"
              );
            }}
            icon={
              this.state.color == "transparent"
                ? "check-box"
                : "check-box-outline-blank"
            }
          >
            Transparent
          </InlineButton>
        </Popover>
      );
    }
  }

  render() {
    const { style, ref_name, placeholder, onChange, onColorSelect, value } =
      this.props;
    const colors = store.getState().settings.colors;

    return (
      <Wrapper
        wrapper_ref={this._color_picker}
        style={{
          ...style
        }}
      >
        <InputBox
          autoFocus={false}
          input_ref={ref_name}
          name={ref_name}
          returnKeyType="done"
          placeholder={placeholder}
          autoCapitalize="characters"
          keyboardType="default"
          onChange={onChange}
          onSubmitEditing={() => {}}
          blurOnSubmit={true}
          value={value}
          renderRight={() => {
            return (
              <IconButton
                onPress={() => {
                  this.setState({ show: !this.state.show });
                }}
                style={{
                  backgroundColor:
                    value && value != "" ? value : colors.background_color
                }}
                icon_color={
                  value && value != "" && value != "transparent"
                    ? tinycolor(value).isLight()
                      ? colors.text_color
                      : colors.white_color
                    : colors.text_color
                }
                icon="colorize"
              />
            );
          }}
        />

        {this.renderColorPicker({
          color: this.state.color,
          onChange: onChange
        })}
      </Wrapper>
    );
  }
}

export { ColorPicker };
