import React, { Component } from "react";

const Markdown = require("react-markdown");

class SectionDescription extends Component {
  render() {
    if (this.props.section.description) {
      return (
        <Markdown source={this.props.section.description} escapeHtml={false} />
      );
    }

    return <div />;
  }
}

export default SectionDescription;
