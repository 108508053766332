import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Bold,
  Title
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  GhostButton,
  InlineButton,
  BottomNavBar
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  editCredits,
  updateUser,
  openUrl,
  renderPrice,
  AppConfig
} from "app/NativeActions";

class FixCardForMarketingCredits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      pause_sending_loading: false
    };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {}
  componentWillUnmount() {}

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    return (
      <Container>
        {this.props.device == "mobile" ? (
          <NewHeader title="Failed To Charge For Marketing Credits" />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Title style={{ textAlign: "center" }}>
              Failed To Charge For Marketing Credits
            </Title>
          </Wrapper>
        )}
        <KeyboardView style={{ flex: 1 }}>
          <Wrapper style={{ padding: 25 }}>
            <Copy>
              <Bold>Your card failed to charge for marketing credits.</Bold>
            </Copy>
            <Copy>
              Fix the card on file or attempt to try again. Or pause sending to
              continue to your account.
              {renderPrice(this.props.user?.marketing_credits_unpaid_amount)}
            </Copy>
          </Wrapper>

          <GhostButton
            primary={true}
            button_type="full"
            onPress={() => {
              this.props.editCredits({
                token: this.props.token,
                type: "purchase_credits",
                payload: {
                  amount: parseInt(
                    parseInt(this.props.user?.marketing_credits_unpaid_amount) /
                      100
                  )
                },
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                },
                onSuccess: () => {
                  this.setState({
                    loading: false
                  });
                  this.handleBack();
                }
              });
            }}
            loading={this.state.loading}
          >
            Reattempt Charge
          </GhostButton>
          <InlineButton
            button_type="full"
            onPress={() => {
              openUrl(
                AppConfig().api_url +
                  "billing/customer-portal/?token=" +
                  this.props.token +
                  "&return_url=" +
                  AppConfig().app_url,
                true
              );
            }}
          >
            Manage My Cards On File
          </InlineButton>
        </KeyboardView>

        <BottomNavBar>
          <InlineButton
            button_type="full"
            onPress={() => {
              this.props.updateUser({
                token: this.props.token,
                type: "pause_sending",
                payload: {
                  pause_sending: 1,
                  auto_approve: 0
                },
                onLoading: () => {
                  this.setState({
                    pause_sending_loading: true
                  });
                },
                onSuccess: () => {
                  this.setState(
                    {
                      pause_sending_loading: false
                    },
                    () => {
                      this.handleBack();
                    }
                  );
                },
                onError: () => {
                  this.setState({
                    pause_sending_loading: false
                  });
                }
              });
            }}
          >
            Pause All Sending
          </InlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  editCredits,
  updateUser
})(FixCardForMarketingCredits);
