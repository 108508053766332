import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Row,
  Wrapper,
  Bold,
  Copy,
} from "app/NativeComponents/common";
import {
  NewHeader,
  PillButton,
  SearchBar,
  ConfirmLabel,
  InlineButton,
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  toggleCanPop,
  changeTab,
  lockDrawer,
  popSidePanel,
  updateUserListSettings,
} from "app/NativeActions";

import ListView from "./ListView";

class LeadOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      column_search: "",
      loading: false,
      list_columns: props.list_settings
        ? props.list_settings.user_list_columns
          ? props.list_settings.user_list_columns
          : []
        : [],
    };
  }

  updateColumnSearch(value) {
    this.setState({ column_search: value });
  }

  componentDidUpdate(prevProps) {
    if (this.props.list_settings !== prevProps.list_settings) {
      this.setState({
        list_columns: this.props.list_settings?.user_list_columns
          ? this.props.list_settings?.user_list_columns
          : [],
      });
    }
  }

  checkListItem(item) {
    if (item.main_column !== true) {
      this.setState({
        list_columns: this.state.list_columns.map((column) => {
          if (column.slug === item.slug) {
            return {
              ...column,
              show: !column.show,
            };
          }
          return column;
        }),
      });
    }
  }

  updateListItemFormat(item, format) {
    if (item.main_column !== true) {
      this.setState({
        list_columns: this.state.list_columns.map((column) => {
          if (column.slug === item.slug) {
            return {
              ...column,
              column_format: format,
            };
          }
          return column;
        }),
      });
    }
  }

  reorderItems({ previousIndex, nextIndex }) {
    const { list_columns } = this.state;
    const reordered_items = JSON.parse(JSON.stringify(list_columns));

    const [removed] = reordered_items.splice(previousIndex, 1);
    reordered_items.splice(nextIndex, 0, removed);

    this.setState({
      list_columns: reordered_items,
    });
  }

  checkIfNeedsToSave() {
    return (
      this.props.list_settings &&
      JSON.stringify(this.props.list_settings.user_list_columns) !==
        JSON.stringify(this.state.list_columns)
    );
  }

  checkIfNeedsToClear() {
    if (this.props.list_settings) {
      if (
        JSON.stringify(this.state.list_columns) !=
        JSON.stringify(this.props.list_settings.default_columns)
      ) {
        return true;
      }
    }
    return false;
  }
  renderRightButton() {
    return (
      <Wrapper>
        <Row>
          <PillButton
            primary={true}
            onPress={() => {
              this.props.updateUserListSettings({
                token: this.props.token,
                user_list_columns: this.state.list_columns,
                onSuccess: () => {
                  this.props.popSidePanel();
                },
              });
            }}
            innerStyle={{ padding: 5, paddingRight: 15, paddingLeft: 15 }}
            style={{ margin: 0, marginRight: 0 }}
          >
            Save
          </PillButton>
        </Row>
      </Wrapper>
    );
  }

  renderBody() {
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: this.props.colors.card_color,
        }}
      >
        <NewHeader
          title={"Edit Columns"}
          leftButton={{
            icon: this.props.device == "mobile" ? "arrow-back" : "close",
            onPress: () => {
              this.props.popSidePanel();
            },
          }}
        />

        {this.checkIfNeedsToSave() ? (
          <ConfirmLabel
            style={{
              alignSelf: "stretch",
            }}
            icon="settings"
            label={"Options Changes"}
            confirmButton={"Save"}
            loading={this.state.loading}
            primary={true}
            disabled={!this.checkIfNeedsToSave()}
            onPress={() => {
              this.setState({ loading: true });

              this.props.updateUserListSettings({
                token: this.props.token,
                user_list_columns: this.state.list_columns,
                onLoading: () => {
                  this.setState({ loading: true });
                },
                onError: () => {
                  this.setState({ loading: false });
                },
                onSuccess: () => {
                  this.setState({ loading: false });

                  this.props.popSidePanel();
                },
              });
            }}
          />
        ) : null}

        <Wrapper style={{ padding: 20 }}>
          <Copy>
            <Bold>Update your viewing options</Bold>
          </Copy>
          <Copy>
            You can customize your columns by re-ordering them or choosing which
            ones to show or hide.
          </Copy>
        </Wrapper>
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              style={{ margin: 10, marginRight: 0 }}
              title="Search Columns"
              value={this.state.column_search}
              onChange={(value) => this.setState({ column_search: value })}
            />
          </Wrapper>
          <InlineButton
            onPress={() => {
              this.setState({
                list_columns: this.props.list_settings.default_columns,
              });
            }}
          >
            Reset
          </InlineButton>
        </Row>
        <KeyboardView style={{ flex: 1 }}>
          <ListView
            {...this.props}
            column_search={this.state.column_search}
            reorderItems={this.reorderItems.bind(this)}
            list_columns={this.state.list_columns}
            checkListItem={this.checkListItem.bind(this)}
            updateListItemFormat={this.updateListItemFormat.bind(this)}
          />
        </KeyboardView>
      </Container>
    );
  }
  render() {
    return this.renderBody();
  }
}

const mapStateToProps = ({ auth, native, settings, filter, modal }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { list_settings } = filter;
  const { lead_modal } = modal;

  return {
    token,
    user,
    platform,
    colors,
    device,
    isMobile,
    list_settings,
    lead_modal,
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  toggleCanPop,
  changeTab,
  lockDrawer,
  popSidePanel,
  updateUserListSettings,
})(LeadOptions);
