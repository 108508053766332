import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  GhostButton,
  Select,
  SmallHeader,
  InputBox,
  SelectItem,
  SelectBox
} from "app/NativeComponents/snippets";
import moment from "moment-timezone";

class DelayStep extends Component {
  constructor(props) {
    super(props);

    let times = [];
    let currentDate = moment("12");
    new Array(96).fill().map((acc, index) => {
      times.push({
        value: currentDate.format("h:mm A"),
        label: currentDate.format("h:mm A")
      });
      currentDate = currentDate.add(15, "minutes");

      return index;
    });

    let timezones = [];
    let moment_tz = moment.tz.names();
    for (let i = 0; i < moment_tz.length; i++) {
      timezones.push({
        value: moment_tz[i],
        label: moment_tz[i]
      });
    }

    this.state = {
      days_of_the_week: [
        {
          value: "Sunday",
          label: "Sunday"
        },
        {
          value: "Monday",
          label: "Monday"
        },
        {
          value: "Tuesday",
          label: "Tuesday"
        },
        {
          value: "Wednesday",
          label: "Wednesday"
        },
        {
          value: "Thursday",
          label: "Thursday"
        },
        {
          value: "Friday",
          label: "Friday"
        },
        {
          value: "Saturday",
          label: "Saturday"
        }
      ],
      times,
      timezones,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            days: "",
            hours: "",
            minutes: "",
            delay_until_a_day_of_week: false,
            day_of_week: "",
            delay_until_a_time_of_day: false,
            time_of_day_time: "",
            time_of_day_timezone: moment.tz.guess()
          }
    };
  }

  componentDidMount() {}

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (this.props.active_step?.type == "delay_set_amount_of_time") {
        if (
          this.state.data.days != 0 ||
          this.state.data.hours != 0 ||
          this.state.data.minutes != 0
        ) {
          return true;
        }
      } else if (this.props.active_step?.type == "delay_until_day_or_time") {
        if (
          (this.state.data.delay_until_a_day_of_week &&
            !!this.state.data.day_of_week) ||
          (this.state.data.delay_until_a_time_of_day &&
            !!this.state.data.time_of_day_time &&
            !!this.state.data.time_of_day_timezone)
        ) {
          return true;
        }
      }
    }

    return false;
  }

  render() {
    const { colors, device, isMobile, active_step, workflow } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title={
              active_step.type == "delay_set_amount_of_time"
                ? "Delay for a Set Amount of Time"
                : active_step.type == "delay_until_day_or_time"
                ? "Delay Until a Day or Time"
                : "Delay"
            }
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            <>
              {active_step.type == "delay_set_amount_of_time" ? (
                <>
                  <Row>
                    <InputBox
                      ref="days"
                      style={{ flex: 1 }}
                      autoFocus={false}
                      name="days"
                      returnKeyType="done"
                      placeholder="Days"
                      onChange={value => {
                        this.setState({
                          data: {
                            ...data,
                            days: value
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                      blurOnSubmit={false}
                      value={data?.days?.toString()}
                      type="number"
                    />
                    <InputBox
                      ref="hours"
                      style={{ flex: 1 }}
                      autoFocus={false}
                      name="hours"
                      returnKeyType="done"
                      placeholder="Hours"
                      onChange={value => {
                        this.setState({
                          data: {
                            ...data,
                            hours: value
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                      blurOnSubmit={false}
                      value={data?.hours?.toString()}
                      type="number"
                    />
                    <InputBox
                      ref="hours"
                      style={{ flex: 1 }}
                      autoFocus={false}
                      name="hours"
                      returnKeyType="done"
                      placeholder="Minutes"
                      onChange={value => {
                        this.setState({
                          data: {
                            ...data,
                            minutes: value
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                      blurOnSubmit={false}
                      value={data?.minutes?.toString()}
                      type="number"
                    />
                  </Row>
                  <Wrapper style={{ padding: 25 }}>
                    <Copy
                      style={{ fontSize: 12, color: colors.light_text_color }}
                    >
                      1 day is equal to a full 24-hour period
                    </Copy>
                  </Wrapper>
                </>
              ) : active_step.type == "delay_until_day_or_time" ? (
                <>
                  <SelectItem
                    onPress={() => {
                      this.setState({
                        data: {
                          ...data,
                          delay_until_a_day_of_week:
                            !data.delay_until_a_day_of_week,
                          day_of_week:
                            !data.delay_until_a_day_of_week == false
                              ? null
                              : data.day_of_week
                        }
                      });
                    }}
                    selected={data.delay_until_a_day_of_week}
                  >
                    Delay until a day of the week
                  </SelectItem>

                  {data.delay_until_a_day_of_week ? (
                    <SelectBox
                      style={{ flex: 1 }}
                      items={this.state.days_of_the_week}
                      value={data.day_of_week}
                      placeholder={"Select day of the week"}
                      onFocus={() => {}}
                      onBlur={() => {}}
                      onSelect={value => {
                        this.setState({
                          data: {
                            ...data,
                            day_of_week: value
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                    />
                  ) : null}

                  <SelectItem
                    onPress={() => {
                      this.setState({
                        data: {
                          ...data,
                          delay_until_a_time_of_day:
                            !data.delay_until_a_time_of_day,
                          time_of_day_hour:
                            !data.delay_until_a_time_of_day == false
                              ? null
                              : data.time_of_day_hour,
                          time_of_day_timezone:
                            !data.delay_until_a_time_of_day == false
                              ? moment.tz.guess()
                              : data.time_of_day_timezone
                        }
                      });
                    }}
                    selected={data.delay_until_a_time_of_day}
                  >
                    Delay until a time of day{" "}
                  </SelectItem>

                  {data.delay_until_a_time_of_day ? (
                    <>
                      <SelectBox
                        style={{ flex: 1 }}
                        items={this.state.times}
                        value={data.time_of_day_time}
                        placeholder="Select time of the day"
                        onFocus={() => {}}
                        onBlur={() => {}}
                        onSelect={value => {
                          this.setState({
                            data: {
                              ...data,
                              time_of_day_time: value
                            }
                          });
                        }}
                        onSubmitEditing={() => {}}
                      />
                      <SelectBox
                        style={{ flex: 1 }}
                        items={this.state.timezones}
                        value={data.time_of_day_timezone}
                        placeholder="Select timezone"
                        onFocus={() => {}}
                        onBlur={() => {}}
                        onSelect={value => {
                          this.setState({
                            data: {
                              ...data,
                              time_of_day_timezone: value
                            }
                          });
                        }}
                        onSubmitEditing={() => {}}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

export default DelayStep;
