import React, { Component } from "react";
import {
  Wrapper,
  Card,
  CardBody,
  Input,
  Copy
} from "app/NativeComponents/common";
import { InputBox } from "app/NativeComponents/snippets";

import {
  /*common functions */
  focusNextField
} from "app/NativeActions";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this._new_password_input = React.createRef();
    this._new_password_confirm_input = React.createRef();
  }
  render() {
    return (
      <>
        <InputBox
          input_ref={this._new_password_input}
          name="new_password_confirm"
          returnKeyType="next"
          blurOnSubmit={false}
          autoCapitalize="none"
          keyboardType="default"
          placeholder="New Password"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "new_password", value })
          }
          value={this.props.editUser.new_password}
          type="password"
          autoFocus={true}
          secureTextEntry={true}
          onSubmitEditing={() => {
            if (this._new_password_confirm_input?.current) {
              this._new_password_confirm_input.current.focus();
            }
          }}
        />
        <InputBox
          input_ref={this._new_password_confirm_input}
          name="new_password_confirm_input"
          returnKeyType={"done"}
          blurOnSubmit={false}
          keyboardType="default"
          placeholder="Confirm New Password"
          secureTextEntry={true}
          onChange={value =>
            this.props.updateUserFieldChange({
              prop: "new_password_confirm",
              value
            })
          }
          onSubmitEditing={() => this.props.savePassword()}
          value={this.props.editUser.new_password_confirm}
          type="password"
        />
      </>
    );
  }
}

export default Inputs;
