import React, { Component } from "react";
import { connect } from "react-redux";
import {
  formatUsPhone,
  popSidePanel,
  updateUserSettings,
  getVoiceClone,
  updateVoiceClone,
  uploadFile,
  playAudio,
  pauseAudio,
  killAudio,
  beginRecording,
  endRecording,
  checkIfUserHasMetadata
} from "app/NativeActions";
import {
  Container,
  KeyboardView,
  Wrapper,
  Row,
  CardBody,
  Split,
  Stretch,
  Title,
  CenterCenter,
  Icon,
  Copy,
  Bold,
  DropzoneButton
} from "app/NativeComponents/common";
import {
  AttentionBox,
  BottomNavBar,
  ConfirmInlineButton,
  ConfirmLabel,
  IconButton,
  InlineButton,
  InlineTabs,
  InputBox,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";

class DialerSettings extends Component {
  constructor(props) {
    super(props);
    let goal = "";
    switch (props.user?.industry) {
      default:
        goal =
          "Your goal is to inquire about purchasing the property from the contact.";

        break;
      case "Real Estate Agent":
        goal =
          "Your goal is to inquire about listing the property for sale on behalf of the contact as their agent.";

        break;
    }

    const default_dialer_settings = {
      callback_number: formatUsPhone(props.user?.team_phone),
      dialer_goal: goal,
      ...props.default_dialer_settings
    };

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    const { dialer_settings } = props?.user?.user_settings;

    this.state = {
      loading: false,
      deleting_training: false,
      default_dialer_settings: default_dialer_settings,
      callback_number: dialer_settings
        ? dialer_settings.callback_number
        : default_dialer_settings.callback_number,
      scrub_dnc: dialer_settings
        ? dialer_settings?.scrub_dnc
        : default_dialer_settings.scrub_dnc,
      scrub_landline: dialer_settings
        ? dialer_settings?.scrub_landline
        : default_dialer_settings.scrub_landline,
      scrub_wireless: dialer_settings
        ? dialer_settings?.scrub_wireless
        : default_dialer_settings.scrub_wireless,
      include_likely_owners: dialer_settings
        ? dialer_settings?.include_likely_owners
        : default_dialer_settings.include_likely_owners,
      include_family: dialer_settings
        ? dialer_settings?.include_family
        : default_dialer_settings.include_family,
      include_likely_renters: dialer_settings
        ? dialer_settings?.include_likely_renters
        : default_dialer_settings.include_likely_renters,
      include_potential_property_owners: dialer_settings
        ? dialer_settings?.include_potential_property_owners
        : default_dialer_settings.include_potential_property_owners,
      dialer_script: dialer_settings
        ? dialer_settings.dialer_script
        : default_dialer_settings.dialer_script,
      dialer_voicemail_script: dialer_settings
        ? dialer_settings.dialer_voicemail_script
        : default_dialer_settings.dialer_voicemail_script,
      dialer_goal: dialer_settings
        ? dialer_settings.dialer_goal
        : default_dialer_settings.dialer_goal,
      not_interested_days: dialer_settings
        ? dialer_settings.not_interested_days
        : default_dialer_settings.not_interested_days,
      not_interested_days_toggle: dialer_settings
        ? dialer_settings.not_interested_days > 0
        : default_dialer_settings.not_interested_days > 0,
      no_answer_days: dialer_settings
        ? dialer_settings.no_answer_days
        : default_dialer_settings.no_answer_days,
      warm_lead_days: dialer_settings
        ? dialer_settings.warm_lead_days
        : default_dialer_settings.warm_lead_days,
      hot_lead_days: dialer_settings
        ? dialer_settings.hot_lead_days
        : default_dialer_settings.hot_lead_days,
      unresponsive_number_tries: dialer_settings
        ? dialer_settings.unresponsive_number_tries
        : default_dialer_settings.unresponsive_number_tries,
      ai_voicemail: dialer_settings
        ? dialer_settings.ai_voicemail
        : default_dialer_settings.ai_voicemail,
      use_callback_number_in_voicemail: dialer_settings
        ? dialer_settings.use_callback_number_in_voicemail
        : default_dialer_settings.use_callback_number_in_voicemail,
      practice_mode: dialer_settings
        ? dialer_settings.practice_mode
        : default_dialer_settings.practice_mode,

      settings_tab: panel_data?.tab ? panel_data?.tab : "queue_settings",
      voice_clone_loading: false,
      voice_clone: null,
      training_recordings: [],
      max_training: 20,
      uploading: false,
      uploading_recording: false,
      deleting: false,
      playing_audio: null,
      sample_text: null,
      sample_url: null,
      sample_text_loading: false,
      is_recording: false
    };

    this._phone_number_input = React.createRef();

    this.stopAudio = this.stopAudio.bind(this);
    this.getSampleRecording = this.getSampleRecording.bind(this);
    this.updateDialerSettings = this.updateDialerSettings.bind(this);

    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);

    this._mediaRecorderRef = React.createRef();
  }

  componentWillUnmount() {
    this.stopAudio();
  }

  stopAudio() {
    killAudio({ audio: this._audio });
    this.setState({
      playing_audio: null
    });
  }

  startRecording() {
    beginRecording({
      onRecordingStart: mediaRecorder => {
        this._mediaRecorderRef.current = mediaRecorder;
        this.setState({
          is_recording: true
        });
      },
      onRecordingEnd: ({ audioFile }) => {
        this.setState(
          {
            uploading_recording: true
          },
          () => {
            this.props.uploadFile({
              acceptedFiles: audioFile,
              type: "voice_clone_training",
              token: this.props.token,
              onSuccess: results => {
                this.setState({
                  uploading_recording: false,
                  training_recordings: results?.training_recordings,
                  voice_clone: results?.voice_clone,
                  sample_text: null,
                  sample_url: null
                });
              },
              onError: error => {
                this.setState({
                  uploading_recording: false
                });
              }
            });
          }
        );
      }
    });
  }

  stopRecording() {
    endRecording({
      mediaRecorder: this._mediaRecorderRef?.current,
      onEndComplete: () => {
        this.setState({
          is_recording: false
        });
      }
    });
  }

  componentDidMount() {
    this.props.getVoiceClone({
      token: this.props.token,
      onLoading: () => {
        this.setState({ voice_clone_loading: true });
      },
      onError: () => {
        this.setState({ voice_clone_loading: false });
      },
      onSuccess: results => {
        this.setState({
          voice_clone_loading: false,
          voice_clone: results?.voice_clone,
          training_recordings: results?.training_recordings
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.settings_tab !== prevState.settings_tab) {
      this.stopAudio();
    }
  }

  getSampleRecording() {
    this.props.updateVoiceClone({
      token: this.props.token,
      type: "create_voicemail_sample",
      onLoading: () => {
        this.setState({
          sample_text_loading: true,
          sample_text: null,
          sample_url: null
        });

        if (this.state.playing_audio) {
          this._audio.pause();
        }
      },
      onError: () => {
        this.setState({ sample_text_loading: false });
      },
      onSuccess: results => {
        this.setState(
          {
            sample_text_loading: false,
            sample_text: results?.recording?.generated_text,
            sample_url: results?.recording?.recording_url
          },
          () => {
            if (this.state.playing_audio) {
              pauseAudio({
                audio: this._audio,
                use_track: true
              });
            }

            this.setState(
              {
                playing_audio: "sample"
              },
              () => {
                playAudio({
                  url_file: results?.recording?.recording_url,
                  onEnd: () => {
                    this.setState({
                      playing_audio: null
                    });
                  },
                  onPrepared: audio => {
                    this._audio = audio;
                  }
                });
              }
            );
          }
        );
      }
    });
  }

  checkIfNeedsToSave() {
    const { dialer_settings } = this.props?.user?.user_settings;

    if (
      ((this.state.callback_number != dialer_settings?.callback_number &&
        dialer_settings) ||
        (!dialer_settings &&
          this.state.callback_number !=
            this.state.default_dialer_settings?.callback_number) ||
        (this.state.scrub_dnc != dialer_settings?.scrub_dnc &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.scrub_dnc !=
            this.state.default_dialer_settings?.scrub_dnc) ||
        (this.state.scrub_landline != dialer_settings?.scrub_landline &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.scrub_landline !=
            this.state.default_dialer_settings?.scrub_landline) ||
        (this.state.scrub_wireless != dialer_settings?.scrub_wireless &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.scrub_wireless !=
            this.state.default_dialer_settings?.scrub_wireless) ||
        (this.state.include_likely_owners !=
          dialer_settings?.include_likely_owners &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.practice_mode !=
            this.state.default_dialer_settings?.practice_mode) ||
        (this.state.practice_mode != dialer_settings?.practice_mode &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.include_likely_owners !=
            this.state.default_dialer_settings?.include_likely_owners) ||
        (this.state.include_family != dialer_settings?.include_family &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.include_family !=
            this.state.default_dialer_settings?.include_family) ||
        (this.state.include_likely_renters !=
          dialer_settings?.include_likely_renters &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.include_likely_renters !=
            this.state.default_dialer_settings?.include_likely_renters) ||
        (this.state.dialer_script != dialer_settings?.dialer_script &&
          dialer_settings) ||
        (this.state.include_potential_property_owners !=
          dialer_settings?.include_potential_property_owners &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.include_potential_property_owners !=
            this.state.default_dialer_settings
              ?.include_potential_property_owners) ||
        (!dialer_settings &&
          this.state.dialer_script !=
            this.state.default_dialer_settings?.dialer_script) ||
        (this.state.dialer_voicemail_script !=
          dialer_settings?.dialer_voicemail_script &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.dialer_voicemail_script !=
            this.state.default_dialer_settings?.dialer_voicemail_script) ||
        (this.state.dialer_goal != dialer_settings?.dialer_goal &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.dialer_goal !=
            this.state.default_dialer_settings?.dialer_goal) ||
        (this.state.ai_voicemail != dialer_settings?.ai_voicemail &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.ai_voicemail !=
            this.state.default_dialer_settings?.ai_voicemail) ||
        (this.state.use_callback_number_in_voicemail !=
          dialer_settings?.use_callback_number_in_voicemail &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.use_callback_number_in_voicemail !=
            this.state.default_dialer_settings
              ?.use_callback_number_in_voicemail) ||
        (this.state.not_interested_days !=
          dialer_settings?.not_interested_days &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.not_interested_days !=
            this.state.default_dialer_settings?.not_interested_days) ||
        (this.state.no_answer_days != dialer_settings?.no_answer_days &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.no_answer_days !=
            this.state.default_dialer_settings?.no_answer_days) ||
        (this.state.warm_lead_days != dialer_settings?.warm_lead_days &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.warm_lead_days !=
            this.state.default_dialer_settings?.warm_lead_days) ||
        (this.state.hot_lead_days != dialer_settings?.hot_lead_days &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.hot_lead_days !=
            this.state.default_dialer_settings?.hot_lead_days) ||
        (this.state.unresponsive_number_tries !=
          dialer_settings?.unresponsive_number_tries &&
          dialer_settings) ||
        (!dialer_settings &&
          this.state.unresponsive_number_tries !=
            this.state.default_dialer_settings?.unresponsive_number_tries)) &&
      ((!this.state.scrub_landline && !this.state.scrub_wireless) ||
        (this.state.scrub_landline && !this.state.scrub_wireless) ||
        (!this.state.scrub_landline && this.state.scrub_wireless)) &&
      ((this.state.include_likely_owners &&
        this.state.include_likely_renters) ||
        (!this.state.include_likely_owners &&
          !this.state.include_likely_renters) ||
        (this.state.include_likely_owners &&
          !this.state.include_likely_renters) ||
        (this.state.include_potential_property_owners &&
          !this.state.include_likely_renters)) &&
      this.state.unresponsive_number_tries > 0 &&
      this.state.not_interested_days >= 0 &&
      this.state.no_answer_days >= 0 &&
      this.state.warm_lead_days >= 0 &&
      this.state.hot_lead_days >= 0
    ) {
      return true;
    }

    return false;
  }

  updateDialerSettings() {
    this.props.updateUserSettings({
      token: this.props.token,
      type: "dialer_settings",
      value: {
        callback_number: this.state.callback_number,
        scrub_dnc: this.state.scrub_dnc,
        scrub_landline: this.state.scrub_landline,
        scrub_wireless: this.state.scrub_wireless,
        include_likely_owners: this.state.include_likely_owners,
        include_family: this.state.include_family,
        include_likely_renters: this.state.include_likely_renters,
        include_potential_property_owners:
          this.state.include_potential_property_owners,
        dialer_script: this.state.dialer_script,
        dialer_voicemail_script: this.state.dialer_voicemail_script,
        dialer_goal: this.state.dialer_goal,
        not_interested_days: this.state.not_interested_days,
        not_interested_days_toggle: this.state.not_interested_days > 0,
        no_answer_days: this.state.no_answer_days,
        warm_lead_days: this.state.warm_lead_days,
        hot_lead_days: this.state.hot_lead_days,
        unresponsive_number_tries: this.state.unresponsive_number_tries,
        ai_voicemail: this.state.ai_voicemail,
        use_callback_number_in_voicemail:
          this.state.use_callback_number_in_voicemail,
        practice_mode: this.state.practice_mode
      },
      onLoading: () => {
        this.setState({ loading: true });
      },
      onError: () => {
        this.setState({ loading: false });
      },
      onSuccess: results => {
        if (results?.user?.user_settings?.dialer_settings) {
          const { dialer_settings } = results?.user?.user_settings;
          this.setState({
            loading: false,
            callback_number: dialer_settings?.callback_number,
            scrub_dnc: dialer_settings?.scrub_dnc,
            scrub_landline: dialer_settings?.scrub_landline,
            scrub_wireless: dialer_settings?.scrub_wireless,
            include_likely_owners: dialer_settings?.include_likely_owners,
            include_family: dialer_settings?.include_family,
            include_likely_renters: dialer_settings?.include_likely_renters,
            include_potential_property_owners:
              dialer_settings?.include_potential_property_owners,
            dialer_script: dialer_settings?.dialer_script,
            dialer_voicemail_script: dialer_settings?.dialer_voicemail_script,
            dialer_goal: dialer_settings?.dialer_goal,
            not_interested_days: dialer_settings?.not_interested_days,
            not_interested_days_toggle:
              dialer_settings?.not_interested_days > 0,
            no_answer_days: dialer_settings?.no_answer_days,
            warm_lead_days: dialer_settings?.warm_lead_days,
            hot_lead_days: dialer_settings?.hot_lead_days,
            unresponsive_number_tries:
              dialer_settings?.unresponsive_number_tries,
            ai_voicemail: dialer_settings?.ai_voicemail,
            use_callback_number_in_voicemail:
              dialer_settings?.use_callback_number_in_voicemail,
            practice_mode: dialer_settings?.practice_mode
          });
        } else {
          this.setState({ loading: false });
        }
      }
    });
  }
  render() {
    const { colors } = this.props;

    return (
      <Container>
        <NewHeader
          title={"Dialer Settings"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              loading={this.state.loading}
              icon="settings"
              label={"Settings Changed"}
              primary={true}
              confirmButton={"Confirm Changes"}
              onPress={this.updateDialerSettings}
            />
          ) : null}

          <InlineTabs
            tab_type="underline"
            selected_tab={this.state.settings_tab}
            tabs={[
              {
                onPress: () => {
                  this.setState({
                    settings_tab: "queue_settings"
                  });
                },
                title: "Queue",
                slug: "queue_settings"
              },
              {
                onPress: () => {
                  this.setState({
                    settings_tab: "callback_settings"
                  });
                },
                title: "Callback",
                slug: "callback_settings"
              },
              {
                onPress: () => {
                  this.setState({
                    settings_tab: "follow_up_settings"
                  });
                },
                title: "Follow-up",
                slug: "follow_up_settings"
              },
              {
                onPress: () => {
                  this.setState({
                    settings_tab: "alma_settings"
                  });
                },
                title: this.props.device === "mobile" ? "AI" : "Alma (AI)",
                slug: "alma_settings"
              },
              {
                onPress: () => {
                  this.setState({
                    settings_tab: "voice_clone"
                  });
                },
                title:
                  this.props.device === "mobile"
                    ? "Voice Clone"
                    : "AI Voice Clone",
                slug: "voice_clone"
              }
            ]}
          />

          {this.state.settings_tab == "queue_settings" ? (
            <>
              <AttentionBox
                title="Queue Settings"
                renderDescription={() => {
                  return (
                    <Copy>
                      These settings will determine which contacts and phone
                      numbers are included in your queue.{" "}
                      <Bold>
                        These settings are personal and do not affect any
                        members of your team.
                      </Bold>
                    </Copy>
                  );
                }}
              />
              <SmallLabel>Associated contacts to include:</SmallLabel>
              <Row
                style={{
                  paddingRight: 25,
                  paddingLeft: 25,
                  paddingBottom: 25,
                  flexWrap: "wrap"
                }}
              >
                <InlineButton
                  icon={
                    this.state.include_likely_owners
                      ? "check-box"
                      : "check-box-outline-blank"
                  }
                  onPress={() =>
                    this.setState({
                      include_likely_owners: !this.state.include_likely_owners,
                      include_likely_renters:
                        this.state.include_likely_owners &&
                        !this.state.include_likely_renters
                          ? true
                          : this.state.include_likely_renters
                    })
                  }
                  selected={this.state.include_likely_owners}
                  style={
                    this.state.include_likely_owners
                      ? {
                          borderColor: colors.active_color,
                          borderWidth: 1,
                          borderStyle: "solid",
                          marginLeft: 0
                        }
                      : { marginLeft: 0 }
                  }
                  tooltip={
                    "The contact's address and name match the property's mailing address and owner listed in the county records."
                  }
                  tooltipPlacement={"top"}
                >
                  Likely Property Owners
                </InlineButton>

                {checkIfUserHasMetadata("company_data") ? (
                  <InlineButton
                    icon={
                      this.state.include_potential_property_owners &&
                      this.state.include_likely_owners
                        ? "check-box"
                        : "check-box-outline-blank"
                    }
                    onPress={() =>
                      this.setState({
                        include_potential_property_owners:
                          !this.state.include_potential_property_owners
                      })
                    }
                    disabled={!this.state.include_likely_owners}
                    selected={
                      this.state.include_potential_property_owners &&
                      this.state.include_likely_owners
                    }
                    style={
                      this.state.include_potential_property_owners
                        ? {
                            borderColor: colors.active_color,
                            borderWidth: 1,
                            borderStyle: "solid",
                            marginLeft: 0
                          }
                        : { marginLeft: 0 }
                    }
                    tooltip={
                      !this.state.include_likely_owners
                        ? 'You must have "Likely Property Owners" enabled to include portential property owners.'
                        : "When we can't determine which contact is the property owner, we will include all contacts that are likely to be the property owner."
                    }
                    tooltipPlacement={"top"}
                  >
                    Potential Property Owners
                  </InlineButton>
                ) : null}

                <InlineButton
                  icon={
                    this.state.include_family
                      ? "check-box"
                      : "check-box-outline-blank"
                  }
                  onPress={() =>
                    this.setState({
                      include_family: !this.state.include_family
                    })
                  }
                  selected={this.state.include_family}
                  style={
                    this.state.include_family
                      ? {
                          borderColor: colors.active_color,
                          borderWidth: 1,
                          borderStyle: "solid",
                          marginLeft: 0
                        }
                      : { marginLeft: 0 }
                  }
                  tooltip={
                    "The contact's last name matches the property owner's name listed in the county records."
                  }
                  tooltipPlacement={"top"}
                >
                  Family Members
                </InlineButton>

                <InlineButton
                  icon={
                    this.state.include_likely_renters
                      ? "check-box"
                      : "check-box-outline-blank"
                  }
                  onPress={() =>
                    this.setState({
                      include_likely_renters:
                        !this.state.include_likely_renters,
                      include_likely_owners:
                        this.state.include_likely_renters &&
                        !this.state.include_likely_owners
                          ? true
                          : this.state.include_likely_owners
                    })
                  }
                  selected={this.state.include_likely_renters}
                  style={
                    this.state.include_likely_renters
                      ? {
                          borderColor: colors.active_color,
                          borderWidth: 1,
                          borderStyle: "solid",
                          marginLeft: 0
                        }
                      : { marginLeft: 0 }
                  }
                  tooltip={
                    "The contact's name doesn't align with the listed property owner's, and they don't appear to be related. Additionally, their living address is different from the listed mailing address, suggesting they are a renter."
                  }
                  tooltipPlacement={"top"}
                >
                  Contacts Likely Renting
                </InlineButton>
              </Row>

              <SmallLabel>Phone scrubbing settings:</SmallLabel>

              <SelectItem
                selected={this.state.scrub_dnc}
                onPress={() => {
                  this.setState({
                    scrub_dnc: !this.state.scrub_dnc
                  });
                }}
                select_type="toggle"
                description={
                  'Exclude phone numbers on the federal "Do Not Call" list in your queue.'
                }
              >
                Scrub DNC
              </SelectItem>
              <SelectItem
                selected={this.state.scrub_landline}
                onPress={() => {
                  this.setState({
                    scrub_landline: !this.state.scrub_landline,
                    scrub_wireless: !this.state.scrub_landline
                      ? false
                      : this.state.scrub_wireless
                  });
                }}
                select_type="toggle"
                description={"Exclude landline phone numbers in your queue."}
              >
                Scrub Landline
              </SelectItem>

              <SelectItem
                selected={this.state.scrub_wireless}
                onPress={() => {
                  this.setState({
                    scrub_wireless: !this.state.scrub_wireless,
                    scrub_landline: !this.state.scrub_wireless
                      ? false
                      : this.state.scrub_landline
                  });
                }}
                select_type="toggle"
                description={"Exclude wireless phone numbers in your queue."}
              >
                Scrub Wireless
              </SelectItem>
            </>
          ) : this.state.settings_tab == "callback_settings" ? (
            <>
              <AttentionBox
                title={"Callback Settings"}
                renderDescription={() => {
                  return (
                    <Copy>
                      These settings will determine what happens when someone
                      calls you back after being called from your dialer.{" "}
                      <Bold>
                        These settings are personal and do not affect any
                        members of your team.
                      </Bold>
                    </Copy>
                  );
                }}
              />

              <InputBox
                input_ref={this._phone_number_input}
                name="phone_number"
                disabled={false}
                returnKeyType="next"
                placeholder="Callback Number"
                onChange={value =>
                  this.setState({
                    callback_number: value
                  })
                }
                onSubmitEditing={() => {}}
                blurOnSubmit={true}
                value={this.state.callback_number}
                type="text"
                mask_type={"cel-phone"}
                mask={"(999) 999-9999"}
              />
            </>
          ) : this.state.settings_tab == "follow_up_settings" ? (
            <>
              <AttentionBox
                title="Follow-up Settings"
                renderDescription={() => {
                  return (
                    <Copy>
                      These settings determine when previously called numbers
                      will be placed in your follow-up queue.{" "}
                      <Bold>
                        These settings are personal and do not affect any
                        members of your team.
                      </Bold>
                    </Copy>
                  );
                }}
              />

              <SelectItem
                selected={this.state.not_interested_days_toggle}
                onPress={() => {
                  this.setState({
                    not_interested_days_toggle:
                      !this.state.not_interested_days_toggle,
                    not_interested_days: this.state.not_interested_days_toggle
                      ? 0
                      : this.props.default_dialer_settings.not_interested_days
                  });
                }}
                select_type="toggle"
                description={
                  'When a contact is marked as "not interested", they will be placed in your follow-up queue after the specified number of days.'
                }
              >
                Add to follow-up queue when marked as "Not Interested"
              </SelectItem>

              {this.state.not_interested_days_toggle ? (
                <InputBox
                  style={{ flex: 1 }}
                  ref="not_interested_days"
                  name="not_interested_days"
                  blurOnSubmit={true}
                  autoCapitalize="none"
                  returnKeyType={"done"}
                  keyboardType={"default"}
                  placeholder="Add to follow-up queue after (X) days"
                  onChange={value => {
                    this.setState({
                      not_interested_days: value < 0 ? Math.abs(value) : value
                    });
                  }}
                  value={this.state.not_interested_days?.toString()}
                  onSubmitEditing={() => {}}
                  type={"number"}
                />
              ) : null}

              <SmallLabel
                description={
                  'When a contact is marked as "no answer", they will be placed in your follow-up queue after the specified number of days.'
                }
              >
                "No Answer" follow-up settings:
              </SmallLabel>
              <InputBox
                style={{ flex: 1 }}
                ref="no_answer_days"
                name="no_answer_days"
                blurOnSubmit={true}
                autoCapitalize="none"
                returnKeyType={"done"}
                keyboardType={"default"}
                placeholder="Add to follow-up queue after (X) days"
                onChange={value => {
                  this.setState({
                    no_answer_days: value < 0 ? Math.abs(value) : value
                  });
                }}
                value={this.state.no_answer_days?.toString()}
                onSubmitEditing={() => {}}
                type={"number"}
              />

              <SmallLabel
                description={
                  'When a contact is marked as "warm lead", they will be placed in your follow-up queue after the specified number of days.'
                }
              >
                "Warm Lead" follow-up settings:
              </SmallLabel>
              <InputBox
                style={{ flex: 1 }}
                ref="warm_lead_days"
                name="warm_lead_days"
                blurOnSubmit={true}
                autoCapitalize="none"
                returnKeyType={"done"}
                keyboardType={"default"}
                placeholder="Add to follow-up queue after (X) days"
                onChange={value => {
                  this.setState({
                    warm_lead_days: value < 0 ? Math.abs(value) : value
                  });
                }}
                value={this.state.warm_lead_days?.toString()}
                onSubmitEditing={() => {}}
                type={"number"}
              />

              <SmallLabel
                description={
                  'When a contact is marked as "hot lead", they will be placed in your follow-up queue after the specified number of days.'
                }
              >
                "Hot Lead" follow-up settings:
              </SmallLabel>
              <InputBox
                style={{ flex: 1 }}
                ref="hot_lead_days"
                name="hot_lead_days"
                blurOnSubmit={true}
                autoCapitalize="none"
                returnKeyType={"done"}
                keyboardType={"default"}
                placeholder="Add to follow-up queue after (X) days"
                onChange={value => {
                  this.setState({
                    hot_lead_days: value < 0 ? Math.abs(value) : value
                  });
                }}
                value={this.state.hot_lead_days?.toString()}
                onSubmitEditing={() => {}}
                type={"number"}
              />

              <SmallLabel
                description={
                  'After calling a number "X" times without a response, they will be removed from all dialer queues'
                }
              >
                Unresponsive number settings:
              </SmallLabel>
              <InputBox
                style={{ flex: 1 }}
                ref="unresponsive_number_tries"
                name="unresponsive_number_tries"
                blurOnSubmit={true}
                autoCapitalize="none"
                returnKeyType={"done"}
                keyboardType={"default"}
                placeholder="Remove from all queues after (X) tries"
                onChange={value => {
                  this.setState({
                    unresponsive_number_tries:
                      value < 0 ? Math.abs(value) : value
                  });
                }}
                value={this.state.unresponsive_number_tries?.toString()}
                onSubmitEditing={() => {}}
                type={"number"}
              />
            </>
          ) : this.state.settings_tab == "alma_settings" ? (
            <>
              <AttentionBox
                title="Alma Settings"
                renderDescription={() => {
                  return (
                    <Copy>
                      These settings will guide Alma (your AI assistant) when
                      helping you while using the dialer{" "}
                      <Bold>
                        These settings are personal and do not affect any
                        members of your team.
                      </Bold>
                    </Copy>
                  );
                }}
              />
              <SmallLabel
                description={"Give Alma a goal to accomplish during the call"}
              >
                Dialer goal:
              </SmallLabel>

              <InputBox
                numberOfLines={3}
                name="details"
                disabled={false}
                returnKeyType="done"
                placeholder="Type your goal here."
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    dialer_goal: value
                  });
                }}
                onSubmitEditing={() => {}}
                blurOnSubmit={true}
                value={this.state.dialer_goal}
                type="multiline"
              />
              <SmallLabel
                description={
                  "Give Alma a script you would like to follow while you are on a call. You can also just add notes about how you want your call to sound."
                }
              >
                Dialer script:
              </SmallLabel>
              <InputBox
                numberOfLines={10}
                name="details"
                disabled={false}
                returnKeyType="done"
                placeholder="Type your script here."
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    dialer_script: value
                  });
                }}
                onSubmitEditing={() => {}}
                blurOnSubmit={true}
                value={this.state.dialer_script}
                type="multiline"
              />

              <SmallLabel
                description={
                  "Give Alma a script to follow when you're leaving a voicemail or dropping one. You can also just add notes about how you want your voicemail to sound."
                }
              >
                Voicemail script:
              </SmallLabel>
              <InputBox
                numberOfLines={10}
                name="details"
                disabled={false}
                returnKeyType="done"
                placeholder="Type your voicemail script here."
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    dialer_voicemail_script: value
                  });
                }}
                onSubmitEditing={() => {}}
                blurOnSubmit={true}
                value={this.state.dialer_voicemail_script}
                type="multiline"
              />

              {/*
              <SelectItem
                selected={this.state.use_callback_number_in_voicemail}
                onPress={() => {
                  this.setState({
                    use_callback_number_in_voicemail:
                      !this.state.use_callback_number_in_voicemail
                  });
                }}
                select_type="toggle"
                description={
                  "Instead of listing the number we are calling from in the DealMachine number pool, The voicemail script will always include your callback number instead"
                }
              >
                Use Callback Number in Voicemail Script
              </SelectItem>
              */}

              <Wrapper style={{ height: 40 }} />
            </>
          ) : null}

          {this.state.settings_tab === "voice_clone" ? (
            <>
              <AttentionBox
                title="Voice Clone Settings"
                renderDescription={() => {
                  return (
                    <Copy>
                      These settings allow us to capture your voice and use it
                      to train our AI to leave voicemails on your behalf.{" "}
                      <Bold>
                        These settings are personal and do not affect any
                        members of your team.
                      </Bold>
                    </Copy>
                  );
                }}
              />

              <SelectItem
                selected={this.state.ai_voicemail}
                onPress={() => {
                  this.setState({
                    ai_voicemail: !this.state.ai_voicemail
                  });
                }}
                select_type="toggle"
                description={
                  "Use AI to train off your voice and leave voicemails. By toggling this on, you agree to letting us use recordings of you to train your likeness. Only you will have access to your likeness."
                }
              >
                Enable AI Voice Clone
              </SelectItem>
              {this.state.ai_voicemail ? (
                <>
                  {this.state.voice_clone ? (
                    <>
                      {!!this.state.sample_url ? (
                        <Wrapper>
                          <Row>
                            <Wrapper style={{ flex: 1, padding: 25 }}>
                              <Copy>
                                <Bold>Sample created.</Bold>
                              </Copy>
                              <Copy>
                                This is how your voice clone will sound. If you
                                are not happy with it, upload more recordings of
                                yourself or restart your training data.
                              </Copy>
                            </Wrapper>
                            <IconButton
                              icon={
                                this.state.playing_audio == "sample"
                                  ? "pause"
                                  : "play-arrow"
                              }
                              tooltip={
                                this.state.playing_audio == "sample"
                                  ? "Pause"
                                  : "Play"
                              }
                              tooltipPlacement={"top"}
                              onPress={() => {
                                //play message.url an mp3 file

                                if (this.state.playing_audio == "sample") {
                                  this.setState(
                                    {
                                      playing_audio: null
                                    },
                                    () => {
                                      pauseAudio({ audio: this._audio });
                                    }
                                  );
                                } else if (this.state.playing_audio) {
                                  this.setState(
                                    {
                                      playing_audio: null
                                    },
                                    () => {
                                      pauseAudio({
                                        audio: this._audio,
                                        use_track: true
                                      });

                                      this.setState(
                                        {
                                          playing_audio: "sample"
                                        },
                                        () => {
                                          playAudio({
                                            url_file: this.state.sample_url,
                                            onEnd: () => {
                                              this.setState({
                                                playing_audio: null
                                              });
                                            },
                                            onPrepared: audio => {
                                              this._audio = audio;
                                            }
                                          });
                                        }
                                      );
                                    }
                                  );
                                } else {
                                  this.setState(
                                    {
                                      playing_audio: "sample"
                                    },
                                    () => {
                                      playAudio({
                                        url_file: this.state.sample_url,
                                        onEnd: () => {
                                          this.setState({
                                            playing_audio: null
                                          });
                                        },
                                        onPrepared: audio => {
                                          this._audio = audio;
                                        }
                                      });
                                    }
                                  );
                                }
                              }}
                            />

                            <InlineButton
                              loading={this.state.sample_text_loading}
                              onPress={() => {
                                this.getSampleRecording();
                              }}
                            >
                              Generate New
                            </InlineButton>
                          </Row>
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          <Row>
                            <Wrapper style={{ flex: 1, padding: 25 }}>
                              <Copy>
                                <Bold>Generate a sample recording.</Bold>
                              </Copy>
                              <Copy>
                                This will generate a sample voicemail script for
                                you to listen to. This is how your voice clone
                                will sound. If you are not happy with it, upload
                                more recordings of yourself or restart your
                                training data.
                              </Copy>
                            </Wrapper>
                            <InlineButton
                              loading={this.state.sample_text_loading}
                              onPress={() => {
                                this.getSampleRecording();
                              }}
                            >
                              Generate Sample
                            </InlineButton>
                          </Row>
                        </Wrapper>
                      )}
                    </>
                  ) : null}

                  {this.state.training_recordings &&
                  this.state.training_recordings.length > 0 ? (
                    <>
                      {this.state.training_recordings.map((item, index) => {
                        return (
                          <SelectItem
                            key={"training_recording_" + index}
                            noPress={true}
                            select_type={"icon"}
                            select_icon={"graphic-eq"}
                            renderRight={() => {
                              return (
                                <IconButton
                                  icon={
                                    this.state.playing_audio == item.id
                                      ? "pause"
                                      : "play-arrow"
                                  }
                                  tooltip={
                                    this.state.playing_audio == item.id
                                      ? "Pause"
                                      : "Play"
                                  }
                                  tooltipPlacement={"top"}
                                  onPress={() => {
                                    //play message.url an mp3 file

                                    if (this.state.playing_audio == item.id) {
                                      this.setState(
                                        {
                                          playing_audio: null
                                        },
                                        () => {
                                          this._audio.pause();
                                        }
                                      );
                                    } else if (this.state.playing_audio) {
                                      this.setState(
                                        {
                                          playing_audio: null
                                        },
                                        () => {
                                          pauseAudio({ audio: this._audio });
                                          this.setState(
                                            {
                                              playing_audio: item.id
                                            },
                                            () => {
                                              playAudio({
                                                url_file: item.recording_url,
                                                onEnd: () => {
                                                  this.setState({
                                                    playing_audio: null
                                                  });
                                                },
                                                onPrepared: audio => {
                                                  this._audio = audio;
                                                }
                                              });
                                            }
                                          );
                                        }
                                      );
                                    } else {
                                      this.setState(
                                        {
                                          playing_audio: item.id
                                        },
                                        () => {
                                          playAudio({
                                            url_file: item.recording_url,
                                            onEnd: () => {
                                              this.setState({
                                                playing_audio: null
                                              });
                                            },
                                            onPrepared: audio => {
                                              this._audio = audio;
                                            }
                                          });
                                        }
                                      );
                                    }
                                  }}
                                />
                              );
                            }}
                          >
                            {item.filename}
                          </SelectItem>
                        );
                      })}
                    </>
                  ) : (
                    <Wrapper
                      style={{
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: colors.border_color,
                        padding: 25,
                        margin: 5,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Copy style={{ textAlign: "center" }}>
                        Upload a file or record yourself to create your voice
                        clone.
                      </Copy>
                    </Wrapper>
                  )}

                  <>
                    {this.props.device === "desktop" ? (
                      <Row style={{ justifyContent: "space-between" }}>
                        <InlineButton
                          primary={true}
                          onPress={() => {
                            this.stopAudio();

                            if (!this.state.is_recording) {
                              this.startRecording();
                            } else {
                              this.stopRecording();
                            }
                          }}
                          icon={this.state.is_recording ? "stop" : "mic"}
                          loading={this.state.uploading_recording}
                          disabled={
                            this.state.training_recordings.length >=
                            this.state.max_training
                          }
                        >
                          {this.state.uploading_recording
                            ? "Uploading"
                            : this.state.is_recording
                            ? "Stop Recording"
                            : "Start Recording"}
                        </InlineButton>

                        <DropzoneButton
                          accept="audio/mpeg"
                          maxSize={5242880}
                          disabled={
                            this.state.training_recordings.length >=
                            this.state.max_training
                          }
                          onDrop={acceptedFiles => {
                            this.setState(
                              {
                                uploading: true
                              },
                              () => {
                                this.stopAudio();
                                this.props.uploadFile({
                                  acceptedFiles: acceptedFiles[0],
                                  type: "voice_clone_training",
                                  token: this.props.token,
                                  onSuccess: results => {
                                    this.setState({
                                      uploading: false,
                                      training_recordings:
                                        results?.training_recordings,
                                      voice_clone: results?.voice_clone,
                                      sample_text: null,
                                      sample_url: null
                                    });
                                  },
                                  onError: error => {
                                    this.setState({
                                      uploading: false
                                    });
                                  }
                                });
                              }
                            );
                          }}
                          renderComponent={options => {
                            return (
                              <InlineButton
                                icon="upload"
                                noPress={true}
                                pressedIn={options.hovering}
                                primary={false}
                                loading={this.state.uploading}
                                disabled={
                                  this.state.training_recordings.length >=
                                  this.state.max_training
                                }
                              >
                                Upload MP3
                              </InlineButton>
                            );
                          }}
                        ></DropzoneButton>
                      </Row>
                    ) : null}
                  </>
                </>
              ) : null}
            </>
          ) : null}
        </KeyboardView>
        {this.state.settings_tab === "voice_clone" &&
        this.state.ai_voicemail &&
        this.state.voice_clone ? (
          (<BottomNavBar>
            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Restart?"
              confirm_icon="delete"
              loading_text="Restarting..."
              loading={this.state.deleting}
              onPress={() => {
                this.stopAudio();
                this.props.updateVoiceClone({
                  token: this.props.token,
                  type: "restart_training",
                  onLoading: () => {
                    this.setState({
                      deleting: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      deleting: false
                    });
                  },
                  onSuccess: results => {
                    this.setState({
                      deleting: false,
                      voice_clone: null,
                      training_recordings: [],
                      sample_text: null,
                      sample_url: null
                    });
                  }
                });
              }}
            >
              Restart Training
            </ConfirmInlineButton>
          </BottomNavBar> /* : this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */)
        ) : /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */ /*: this.state.settings_tab === "queue_settings" ? (
          <BottomNavBar>
            <SelectItem
              selected_background_color={colors.orange_color_muted}
              selected={this.state.practice_mode}
              onPress={() => {
                this.setState({
                  practice_mode: !this.state.practice_mode
                });
              }}
              select_type="toggle"
              description={
                "All phone calls made will be replaced by calls to our AI practice bots. This is a great way to practice your scripts and get comfortable with the dialer."
              }
            >
              Practice Mode
            </SelectItem>
          </BottomNavBar>
        ) */
        null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;
  const { colors, notification_settings } = settings;
  const { isMobile, device } = native;
  const { default_dialer_settings } = dialer;

  return {
    token,
    user,
    isMobile,
    colors,
    device,
    notification_settings,
    default_dialer_settings
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateUserSettings,
  getVoiceClone,
  updateVoiceClone,
  uploadFile
})(DialerSettings);
