import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Title,
  Wrapper
} from "app/NativeComponents/common";
import {
  NewHeader,
  BottomNavBar,
  GhostButton
} from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  /*mobile*/
  toggleCanPop,
  updateUser,
  getTermsOfService,
  appRedirect,
  popSidePanel,
  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

class AcceptTerms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    this.props.getTermsOfService({ type: "user" });
  }

  acceptTerms() {
    this.props.updateUser({
      token: this.props.token,
      type: "accept_terms",
      payload: {
        terms_type: "user"
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });
        if (this.props.device == "desktop") {
          this.props.popSidePanel();
        }
      }
    });
  }

  render() {
    return (
      <Container>
        {this.props.device == "mobile" ? (
          <NewHeader title="Updated Terms of Service" />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Title style={{ textAlign: "center" }}>
              Updated Terms of Service
            </Title>
          </Wrapper>
        )}

        <KeyboardView style={{ flex: 1 }}>
          <Body {...this.props} acceptTerms={this.acceptTerms.bind(this)} />
        </KeyboardView>

        <BottomNavBar>
          <GhostButton
            button_type="full"
            primary={true}
            loading={this.state.loading}
            onPress={() => this.acceptTerms()}
          >
            I Accept The Terms of Service
          </GhostButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, drawer }) => {
  const { token, user, terms_of_service, terms_error, terms_loading } = auth;
  const { device, platform } = native;

  return {
    token,
    user,
    terms_of_service,
    terms_error,
    terms_loading,
    device
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,

  updateUser,
  getTermsOfService,
  appRedirect,
  popSidePanel
})(AcceptTerms);
