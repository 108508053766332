import React from "react";
import { Rnd } from "react-rnd";

const Draggable = ({
  style,
  children,
  onDrag = () => {},
  onResize = () => {},
  element,
  enableResizing = true,
  disableDragging = false,
  dragHandleClassName = null,
  grid = null
}) => {
  return (
    <Rnd
      style={style}
      bounds="parent"
      //resizeGrid={grid ? [24, 24] : [1, 1]}
      dragGrid={grid ? grid : [1, 1]}
      size={{
        width: !!element.width ? parseInt(element.width) : 0,
        height: !!element.height ? parseInt(element.height) : 0
      }}
      disableDragging={disableDragging}
      position={{
        x: !!element.x ? parseInt(element.x) : 0,
        y: !!element.y ? parseInt(element.y) : 0
      }}
      onDragStop={(e, d) => {
        //this.setState({ x: d.x, y: d.y });

        onDrag({
          x: d.x,
          y: d.y
        });
      }}
      dragHandleClassName={dragHandleClassName}
      onResizeStop={(e, direction, ref, delta, position) => {
        onResize({
          width: ref.style.width,
          height: ref.style.height,
          x: position.x,
          y: position.y
        });
      }}
      enableResizing={{
        top: enableResizing,
        right: enableResizing,
        bottom: enableResizing,
        left: enableResizing,
        topRight: enableResizing,
        bottomRight: enableResizing,
        bottomLeft: enableResizing,
        topLeft: enableResizing
      }}
    >
      {children}
    </Rnd>
  );
};

export { Draggable };
