import { LOGOUT, DISMISS_ALMA } from "app/DealMachineCore/types";

const INITIAL_STATE = {
  dismissed_alma: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case DISMISS_ALMA:
      return {
        ...state,
        dismissed_alma: true
      };

    default:
      return state;
  }
};
