export const GET_TEAM = "get_team";
export const GET_TEAM_FAIL = "get_team_fail";
export const GET_TEAM_SUCCESS = "get_team_success";
export const REFRESH_TEAM = "refresh_team";
export const SELECT_TEAM = "select_team";
export const TEAM_FIELD_CHANGED = "team_field_changed";
export const UPDATE_TEAM_LINK = "update_team_link";
export const RESET_INVITE = "reset_invite";
export const SET_PERMISSIONS = "set_permissions";
export const SET_MEMBER = "set_member";
export const SET_INVITE = "set_invite";

export const EDIT_TEAM = "edit_team";
export const EDIT_TEAM_FAIL = "edit_team_fail";
export const EDIT_TEAM_SUCCESS = "edit_team_success";

export const REQUEST_TO_JOIN_TEAM_SUCCESS = "request_to_join_team_success";
export const CANCEL_TEAM_REQUEST_SUCCESS = "cancel_team_request_success";

export const GET_TEAM_MEMBERS = "get_team_members";
export const REFRESH_TEAM_MEMBERS = "refresh_team_members";
export const LOAD_MORE_TEAM_MEMBERS = "load_more_team_members";
export const GET_TEAM_MEMBERS_FAIL = "get_team_members_fail";
export const GET_TEAM_MEMBERS_SUCCESS = "get_team_members_success";
export const EDIT_TEAM_MEMBERS_SEARCH = "edit_team_members_search";

export const REPLACE_TEAM_MEMBERS = "replace_team_members";
export const REPLACE_TEAM_MEMBERS_SUCCESS = "replace_team_members_success";

export const SELECT_ACTIVE_TEAM_MEMBER = "select_active_team_member";

export const UPDATE_TEAM_MEMBERS = "update_team_members";
export const UPDATE_TEAM_MEMBERS_FAIL = "update_team_members_fail";
export const UPDATE_TEAM_MEMBERS_SUCCESS = "update_team_members_success";
export const REVOKE_TEAM_INVITE_SUCCESS = "revoke_team_invite_success";
export const REMOVE_TEAM_MEMBER_SUCCESS = "remove_team_member_success";

export const SET_INVITE_TYPE = "set_invite_type";
export const INVITE_TEAM_MEMBER_SUCCESS = "invite_team_member_success";
