import { Copy, Input, Wrapper, Card } from "app/NativeComponents/common";
import { InputBox, Select, SelectBox } from "app/NativeComponents/snippets";
import React, { Component } from "react";

import { store } from "../../../../store";
import { updateMarketingUser } from "app/NativeActions";

const colors = store.getState().settings.colors;

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deal_focus: false
    };

    this._email_input = React.createRef();
    this._password_input = React.createRef();

    this._firstname_input = React.createRef();
    this._lastname_input = React.createRef();
    this._company_input = React.createRef();

    this._email_input = React.createRef();
    this._phone_input = React.createRef();
    this._deals_select_input = React.createRef();
    this._promo_input = React.createRef();
    this._city_input = React.createRef();

    this._password_input = React.createRef();
  }

  renderSelectDealsLast12Months() {
    const {
      affiliate_partner,
      authFieldChanged,
      city,
      company,
      dealfinder,
      defaultPromoText,
      email,
      errors,
      firstname,
      handleChange,
      handleValidation,
      lastname,
      no_container,
      password,
      phone,
      promoText,
      register,
      user_info,
      successfulValidations
    } = this.props;

    const dealsSelectItems = [
      { value: "none", label: "I've never done a deal" },
      { value: "1-5", label: "I've done 1-5 deals" },
      { value: "6-24", label: "I've done 6-24 deals" },
      { value: "24+", label: "I've done more than 24 deals" }
    ];

    if (user_info.signup_type != "team_invite" && !dealfinder) {
      return (
        <Select
          select_ref={this._deals_select_input}
          name="deals_last_12_mo_signup"
          items={dealsSelectItems}
          text={
            dealsSelectItems.find(
              x => x.value == user_info.deals_last_12_mo_signup
            )?.label
          }
          value={user_info.deals_last_12_mo_signup}
          textStyle={{ fontSize: 14, lineHeight: "15px" }}
          placeholder="How many deals have you closed in the last 12 months?"
          title="How many deals have you closed in the last 12 months?"
          titleStyle={{ opacity: 0.5 }}
          show={this.state.deal_focus}
          onFocus={() => {
            this.setState({ deal_focus: true });
          }}
          onBlur={() => {
            this.setState({ deal_focus: false });
          }}
          onSelect={item => {
            this.props.updateMarketingUser({
              prop: "deals_last_12_mo_signup",
              value: item
            });
          }}
          onSubmitEditing={() => {
            if (this._password_input?.current) {
              this._password_input.current.focus();
            }
          }}
        />
      );
    }
  }

  renderCompany() {
    const {
      affiliate_partner,
      authFieldChanged,
      city,
      company,
      dealfinder,
      defaultPromoText,
      email,
      errors,
      firstname,
      handleChange,
      handleValidation,
      lastname,
      no_container,
      password,
      phone,
      promoText,
      register,
      user_info,
      successfulValidations
    } = this.props;

    if (user_info.signup_type != "team_invite" && !dealfinder) {
      return (
        <Wrapper>
          <InputBox
            input_ref={this._company_input}
            name="company"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Company"
            onChange={value => authFieldChanged({ prop: "company", value })}
            value={company}
            type="text"
            onSubmitEditing={() => {
              if (this._email_input?.current) {
                this._email_input.current.focus();
              }
            }}
          />
        </Wrapper>
      );
    } else if (dealfinder) {
      return (
        <Wrapper>
          <InputBox
            input_ref={this._city_input}
            name="city"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Your City"
            onChange={value => authFieldChanged({ prop: "city", value })}
            value={city ? city : ""}
            type="text"
            onSubmitEditing={() => {
              if (this._email_input?.current) {
                this._email_input.current.focus();
              }
            }}
          />
        </Wrapper>
      );
    }
  }
  renderPromo() {
    const {
      affiliate_partner,
      authFieldChanged,
      city,
      company,
      dealfinder,
      defaultPromoText,
      email,
      errors,
      firstname,
      handleChange,
      handleValidation,
      lastname,
      no_container,
      password,
      phone,
      promoText,
      register,
      user_info,
      successfulValidations
    } = this.props;

    if (
      user_info.signup_type != "team_invite" &&
      !dealfinder &&
      !affiliate_partner
    ) {
      if (no_container) {
        return (
          <Wrapper>
            <InputBox
              input_ref={this._promo_input}
              name="promo"
              returnKeyType="done"
              blurOnSubmit={true}
              autoCapitalize="characters"
              keyboardType="default"
              placeholder="Referral Code"
              onChange={value => handleChange(value)}
              onSubmitEditing={() => register()}
              value={
                defaultPromoText && promoText == ""
                  ? defaultPromoText
                  : promoText
              }
              type="text"
              className="uppercase"
              autoComplete="off"
            />
          </Wrapper>
        );
      }
      return (
        <Card>
          <InputBox
            input_ref={this._promo_input}
            name="promo"
            returnKeyType="done"
            blurOnSubmit={true}
            autoCapitalize="characters"
            keyboardType="default"
            placeholder="Referral Code"
            onChange={value => handleChange(value)}
            onSubmitEditing={() => register()}
            value={
              defaultPromoText && promoText == "" ? defaultPromoText : promoText
            }
            type="text"
            className="uppercase"
            autoComplete="off"
          />
        </Card>
      );
    }
  }

  render() {
    const {
      affiliate_partner,
      authFieldChanged,
      city,
      company,
      dealfinder,
      defaultPromoText,
      email,
      errors,
      firstname,
      handleChange,
      handleValidation,
      lastname,
      no_container,
      password,
      phone,
      promoText,
      register,
      user_info,
      successfulValidations
    } = this.props;

    if (no_container) {
      return (
        <Wrapper>
          <InputBox
            input_ref={this._firstname_input}
            name="firstname"
            autoCompleteType={"name"}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="First Name"
            onChange={value => {
              authFieldChanged({ prop: "firstname", value });
            }}
            onBlur={ev => {
              handleValidation("firstname", ev.target.value);
            }}
            success={successfulValidations.firstname}
            error={errors["firstname"]}
            validationRequired={true}
            value={firstname}
            type="text"
            autoFocus={false}
            onSubmitEditing={() => {
              if (this._lastname_input?.current) {
                this._lastname_input.current.focus();
              }
            }}
          />

          <InputBox
            input_ref={this._lastname_input}
            name="lastname"
            autoCompleteType={"name"}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Last Name"
            onBlur={ev => {
              handleValidation("lastname", ev.target.value);
            }}
            onChange={value => authFieldChanged({ prop: "lastname", value })}
            value={lastname}
            success={successfulValidations.lastname}
            error={errors["lastname"]}
            validationRequired={true}
            type="text"
            onSubmitEditing={() => {
              if (this._company_input?.current) {
                this._company_input.current.focus();
              } else if (this._city_input?.current) {
                this._city_input.current.focus();
              } else if (this._email_input?.current) {
                this._email_input.current.focus();
              }
            }}
          />

          {this.renderCompany()}
          <InputBox
            input_ref={this._email_input}
            name="email"
            autoCompleteType={"email"}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="none"
            keyboardType="email-address"
            placeholder="Email Address"
            disabled={user_info?.signup_type == "team_invite" ? true : false}
            onBlur={ev => {
              handleValidation("email", ev.target.value);
            }}
            onChange={value => {
              authFieldChanged({ prop: "email", value });
            }}
            value={email && email != "" ? email : user_info.email}
            success={successfulValidations.email}
            error={errors["email"]}
            validationRequired={true}
            type="text"
            onSubmitEditing={() => {
              if (this._phone_input?.current) {
                if (this.props.device == "desktop") {
                  this._phone_input.current.getInputDOMNode().focus();
                } else {
                  this._phone_input.current._inputElement.focus();
                }
              }
            }}
          />

          <InputBox
            input_ref={this._phone_input}
            name="phone"
            autoCompleteType={"tel"}
            mask_type={"cel-phone"}
            returnKeyType="done"
            blurOnSubmit={true}
            autoCapitalize="none"
            keyboardType="numeric"
            placeholder="Phone Number"
            onBlur={ev => {
              handleValidation("phone", ev.target.value);
            }}
            onChange={value => authFieldChanged({ prop: "phone", value })}
            value={phone ? phone : ""}
            success={successfulValidations.phone}
            error={errors["phone"]}
            validationRequired={true}
            type="text"
            mask={"(999) 999-9999"}
            onSubmitEditing={() => {
              if (user_info.signup_type != "team_invite" && !dealfinder) {
                this.setState({ deal_focus: true });
              } else if (this._password_input?.current) {
                this._password_input.current.focus();
              }
            }}
          />

          {this.renderSelectDealsLast12Months()}

          <InputBox
            input_ref={this._password_input}
            name="password"
            returnKeyType={
              user_info.signup_type == "team_invite" ? "done" : "next"
            }
            blurOnSubmit={user_info.signup_type == "team_invite" ? true : false}
            placeholder="Password"
            secureTextEntry={true}
            onChange={value => authFieldChanged({ prop: "password", value })}
            onBlur={ev => {
              handleValidation("password", ev.target.value);
            }}
            value={password}
            success={successfulValidations.password}
            error={errors["password"]}
            validationRequired={true}
            type="password"
          />

          {this.renderPromo()}
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Card>
          <InputBox
            input_ref={this._firstname_input}
            name="firstname"
            autoCompleteType={"name"}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="First Name"
            error={errors["firstname"]}
            success={successfulValidations.firstname}
            validationRequired={true}
            onChange={value => {
              authFieldChanged({ prop: "firstname", value });
            }}
            onBlur={ev => {
              handleValidation("firstname", ev.target.value);
            }}
            value={firstname}
            onSubmitEditing={() => {
              if (this._lastname_input?.current) {
                this._lastname_input.current.focus();
              }
            }}
            type="text"
          />

          <InputBox
            input_ref={this._lastname_input}
            name="lastname"
            autoCompleteType={"name"}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Last Name"
            error={errors["lastname"]}
            success={successfulValidations.lastname}
            validationRequired={true}
            onChange={value => {
              authFieldChanged({ prop: "lastname", value });
            }}
            onBlur={ev => {
              handleValidation("lastname", ev.target.value);
            }}
            value={lastname}
            onSubmitEditing={() => {
              if (this._company_input?.current) {
                this._company_input.current.focus();
              } else if (this._city_input?.current) {
                this._city_input.current.focus();
              } else if (this._email_input?.current) {
                this._email_input.current.focus();
              }
            }}
            type="text"
          />

          {this.renderCompany()}
        </Card>
        <Card>
          <InputBox
            input_ref={this._email_input}
            name="email"
            autoCompleteType={"email"}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="none"
            keyboardType="email-address"
            placeholder="Email Address"
            error={errors["email"]}
            success={successfulValidations.email}
            validationRequired={true}
            disabled={user_info.signup_type == "team_invite" ? true : false}
            onChange={value => {
              authFieldChanged({ prop: "email", value });
            }}
            onBlur={ev => {
              handleValidation("email", ev.target.value);
            }}
            value={email && email != "" ? email : user_info.email}
            type="text"
            onSubmitEditing={() => {
              if (this._phone_input?.current) {
                if (this.props.device == "desktop") {
                  this._phone_input.current.getInputDOMNode().focus();
                } else {
                  this._phone_input.current.focus();
                }
              }
            }}
          />

          <InputBox
            input_ref={this._phone_input}
            name="phone"
            autoCompleteType={"tel"}
            mask_type={"cel-phone"}
            returnKeyType="done"
            blurOnSubmit={true}
            autoCapitalize="none"
            keyboardType="numeric"
            placeholder="Phone Number"
            error={errors["phone"]}
            success={successfulValidations.phone}
            validationRequired={true}
            onChange={value => {
              authFieldChanged({ prop: "phone", value });
            }}
            onBlur={ev => {
              handleValidation("phone", ev.target.value);
            }}
            value={phone ? phone : ""}
            type="text"
            mask={"(999) 999-9999"}
            onSubmitEditing={() => {
              if (user_info.signup_type != "team_invite" && !dealfinder) {
                this.setState({ deal_focus: true });
              } else if (this._password_input?.current) {
                this._password_input.current.focus();
              }
            }}
          />

          {this.renderSelectDealsLast12Months()}
          <InputBox
            input_ref={this._password_input}
            name="password"
            autoCompleteType={"password"}
            returnKeyType={
              user_info.signup_type == "team_invite" ? "done" : "next"
            }
            blurOnSubmit={user_info.signup_type == "team_invite" ? true : false}
            keyboardType="default"
            placeholder="Password"
            secureTextEntry
            onChange={value => {
              authFieldChanged({ prop: "password", value });
            }}
            onBlur={ev => {
              handleValidation("password", ev.target.value);
            }}
            error={errors["password"]}
            success={successfulValidations.password}
            validationRequired={true}
            value={password}
            type="password"
            onSubmitEditing={() => {
              if (this._promo_input?.current) {
                this._promo_input.current.focus();
              }
            }}
          />
        </Card>

        {this.renderPromo()}
      </Wrapper>
    );
  }
}

export default Inputs;
