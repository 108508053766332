import {
  ModalOverlay,
  Modal,
  Card,
  CardBody,
  Animation
} from "app/NativeComponents/common";
import { store } from "app/store";
import React from "react";

const RightPanelModal = ({
  id,
  children,
  style,
  containerStyle,
  fill,
  size,
  width = 500,
  maxWidth = 500,
  animation_type = "slideInRight",
  className = "deal-right-panel-container"
}) => {
  const colors = store.getState().settings.colors;
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  const isMobile = store.getState().native.isMobile;

  if (fill) {
    return (
      <ModalOverlay>
        <Modal style={{ maxWidth: "90%", height: "90%" }}>
          <Card style={{ height: "100%", overflow: "scroll" }}>
            <CardBody style={{ height: "100%" }}>{children}</CardBody>
          </Card>
        </Modal>
      </ModalOverlay>
    );
  }
  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      style={
        isSafari
          ? {
              overflow: "visible",
              width: isMobile ? "100%" : width,
              maxWidth: isMobile ? "100%" : maxWidth,
              ...containerStyle
            }
          : {
              width: isMobile ? "100%" : width,
              maxWidth: isMobile ? "100%" : maxWidth,
              ...containerStyle
            }
      }
      className={"deal-right-panel-modal"}
    >
      <Animation
        id={id}
        type={animation_type}
        className={
          size === "small" ? " deal-right-panel-container small " : className
        }
        delay={"025"}
        style={{
          backgroundColor: colors.background_color,
          width: isMobile ? "100%" : width,
          maxWidth: isMobile ? "100%" : maxWidth,
          ...style
        }}
      >
        {children}
      </Animation>
    </div>
  );
};

export { RightPanelModal };
