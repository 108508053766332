import React, { PureComponent } from "react";

import LockButton from "./LockButton";

class FeatureLockButton extends PureComponent {
  render() {
    return <LockButton {...this.props} />;
  }
}

export { FeatureLockButton };
