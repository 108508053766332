import React, { Component } from "react";

import { Wrapper, Row, KeyboardView } from "app/NativeComponents/common";
import {
  InlineButton,
  InlineTabs,
  InputBox,
  PopoverMenu,
  BottomNavBar
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { numberWithCommas } from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";
import BedsAndBaths from "./BedsAndBaths";
class BedsAndBathsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_beds_and_baths: props.show_beds_and_baths
    };
    this.renderPriceRange = this.renderPriceRange.bind(this);
  }

  renderPriceRange(price) {
    let label = price;

    let num = label.replace(/[^0-9]/g, "");
    num = parseInt(num);
    if (num === 0) {
      return "";
    }
    if (num >= 1000000) {
      label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1000) {
      label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return label;
  }

  componentDidMount() {}

  render() {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const {
      beds_min,
      baths_min,
      use_beds_exact,
      drawing,
      drawing_coordinates
    } = this.props.map_filters;
    return (
      <>
        <PopoverMenu
          show={this.props.show_beds_and_baths}
          allow_multiple={false}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.props.updateMainState({
              show_beds_and_baths: s
            });
          }}
          disabled={
            this.state.select_drive_start ||
            (drawing && drawing_coordinates?.length <= 2)
          }
          popover_width={450}
          popoverSheetTop={540}
          popoverPlacement={"bottom"}
          popover_title={"Beds & Baths"}
          includeCloseButton={false}
          hideWithOutsideClick={true}
          renderTopButtons={
            device === "mobile"
              ? () => {
                  return (
                    <Row>
                      <InlineButton
                        disabled={!beds_min && !baths_min}
                        onPress={() => {
                          this.props.updateMapFilters({
                            beds_min: null,
                            baths_min: null,
                            use_beds_exact: false
                          });
                        }}
                        style={{ marginRight: 0 }}
                      >
                        Clear
                      </InlineButton>
                      <HeaderButton
                        buttonContents={{
                          onPress: () => {
                            this.props.updateMainState({
                              show_beds_and_baths: false
                            });
                          },
                          icon: "close",
                          color: colors.light_text_color,
                          hover_color: colors.hover_color
                        }}
                      />
                    </Row>
                  );
                }
              : null
          }
          renderComponent={options => {
            return (
              <InlineButton
                disabled={
                  this.props.select_drive_start ||
                  (drawing && drawing_coordinates?.length <= 2)
                }
                style={
                  !!beds_min || !!baths_min
                    ? {
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: colors.active_color,
                        borderStyle: "solid",
                        marginRight: 0,
                        ...this.props.style
                      }
                    : {
                        borderRadius: 5,
                        borderWidth: 0,
                        borderColor: colors.border_color,
                        borderStyle: "solid",
                        marginRight: 0,
                        ...this.props.style
                      }
                }
                selected={!!beds_min || !!baths_min}
                icon={"king-bed"}
                pressedIn={options?.hovering || options?.pressedIn}
                noPress={true}
              >
                {!!beds_min && !baths_min
                  ? use_beds_exact
                    ? beds_min + " bd, 0+ ba"
                    : beds_min + "+ bd, 0+ ba"
                  : !!baths_min && !beds_min
                  ? " 0+ bd, " + baths_min + "+ ba"
                  : !!baths_min && !!beds_min
                  ? use_beds_exact
                    ? beds_min + " bd, " + baths_min + "+ ba"
                    : beds_min + "+ bd, " + baths_min + "+ ba"
                  : "Beds & Baths"}
              </InlineButton>
            );
          }}
          renderMenu={() => {
            return (
              <>
                <KeyboardView style={{ flex: 1 }}>
                  <Wrapper style={{ alignSelf: "stretch" }}>
                    <BedsAndBaths
                      beds_min={beds_min}
                      baths_min={baths_min}
                      use_beds_exact={use_beds_exact}
                      updateMapFilters={this.props.updateMapFilters}
                    />
                  </Wrapper>
                </KeyboardView>
                <BottomNavBar
                  style={{
                    alignSelf: "stretch"
                  }}
                >
                  {device === "desktop" ? (
                    <InlineButton
                      disabled={!beds_min && !baths_min}
                      button_type={"full"}
                      onPress={() => {
                        this.props.updateMapFilters({
                          beds_min: null,
                          baths_min: null,
                          use_beds_exact: false
                        });
                      }}
                    >
                      Clear
                    </InlineButton>
                  ) : null}
                  {device === "mobile" ? (
                    <InlineButton
                      loading={this.props.properties_on_map_count_loading}
                      onPress={() => {
                        this.props.updateMainState({
                          show_beds_and_baths: false
                        });
                      }}
                      button_type={"full"}
                    >
                      {this.props.properties_on_map_count == 1
                        ? "View 1 Property"
                        : "View " +
                          numberWithCommas(this.props.properties_on_map_count) +
                          " Properties"}
                    </InlineButton>
                  ) : null}
                </BottomNavBar>
              </>
            );
          }}
          menu_items={null}
        />
      </>
    );
  }
}

export default BedsAndBathsContainer;
