import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ExternalImage,
  Title,
  Copy,
  Bold,
  Row,
  ContentfulRichText
} from "app/NativeComponents/common";

import { GhostButton } from "app/NativeComponents/snippets";

import { loadOnboardingText, openUrl } from "app/NativeActions";

class OnboardingText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding_text: null,
      loading: true
    };
  }

  componentDidMount() {
    this.loadData(this.props.slug);
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              onboarding_text: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.slug !== this.props.slug ||
      prevState.onboarding_text !== this.state.onboarding_text ||
      prevState.loading !== this.state.loading
    ) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.slug && this.props.slug !== prevProps.slug) {
      this.loadData(this.props.slug);
    }
  }

  renderImage() {
    if (this.state.onboarding_text.image) {
      return (
        <ExternalImage
          style={
            this.state.onboarding_text.profileImage
              ? {
                  resizeMode: "contain",
                  width: this.props.image_size
                    ? this.props.image_size
                    : this.props.largeImage
                    ? 160
                    : 80,
                  height: this.props.image_size
                    ? this.props.image_size
                    : this.props.largeImage
                    ? 160
                    : 80,
                  borderRadius: this.props.image_size
                    ? this.props.image_size / 2
                    : this.props.largeImage
                    ? 80
                    : 40,
                  backgroundColor: this.props.colors.card_color,
                  borderWidth: 3,
                  borderStyle: "solid",
                  borderColor: this.props.colors.card_color,
                  margin: 5
                }
              : this.props.image_width
              ? {
                  resizeMode: "contain",
                  width: this.props.image_width,
                  height: this.props.image_height,
                  margin: 5
                }
              : {
                  resizeMode: "contain",
                  width: this.props.image_size
                    ? this.props.image_size
                    : this.props.largeImage
                    ? 160
                    : 80,
                  height: this.props.image_size
                    ? this.props.image_size
                    : this.props.largeImage
                    ? 160
                    : 80,
                  margin: 5
                }
          }
          contain={true}
          no_lazy={this.props.no_lazy}
          image={this.state.onboarding_text.image.fields.file.url}
        />
      );
    }
  }

  renderTitle() {
    if (
      this.state.onboarding_text.title &&
      this.state.onboarding_text.title != ""
    ) {
      return (
        <Copy
          style={{
            textAlign: this.props.center ? "center" : "left",
            color: this.props.whiteText
              ? this.props.colors.white_text_color
              : this.props.colors.title_color
          }}
        >
          <Bold>{this.state.onboarding_text.title}</Bold>
        </Copy>
      );
    }
  }

  renderCopy() {
    if (
      this.state.onboarding_text.description &&
      this.state.onboarding_text.description != ""
    ) {
      return (
        <Copy
          style={{
            textAlign: this.props.center ? "center" : "left",
            color: this.props.whiteText
              ? this.props.colors.white_text_color
              : this.props.colors.text_color
          }}
        >
          {this.state.onboarding_text.description}
        </Copy>
      );
    }
  }

  renderContent() {
    if (this.state.onboarding_text.richContent) {
      return (
        <ContentfulRichText
          style={{
            textAlign: this.props.center ? "center" : "left",
            color: this.props.whiteText
              ? this.props.colors.white_text_color
              : this.props.colors.text_color
          }}
          richText={this.state.onboarding_text.richContent}
        />
      );
    }
  }

  renderButton() {
    if (
      this.state.onboarding_text.buttonText &&
      this.state.onboarding_text.buttonLink
    ) {
      return (
        <Row
          style={{
            justifyContent: this.props.center ? "center" : "flex-start"
          }}
        >
          <GhostButton
            primary={true}
            onPress={() => {
              openUrl(this.state.onboarding_text.buttonLink);
            }}
          >
            {this.state.onboarding_text.buttonText}
          </GhostButton>
        </Row>
      );
    }
  }

  render() {
    if (
      !!this.props.slug &&
      this.state.loading == false &&
      this.state.onboarding_text != null
    ) {
      return (
        <Wrapper style={{ ...this.props.style }}>
          <Row
            style={
              this.props.center
                ? {
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    ...this.props.titleStyle
                  }
                : {
                    flexDirection: "row",
                    ...this.props.titleStyle
                  }
            }
          >
            {this.renderImage()}
            <Wrapper
              style={{
                flex: this.props.center ? 0 : 1,
                ...this.props.innerStyle
              }}
            >
              {this.renderTitle()}
              {this.renderCopy()}
            </Wrapper>
          </Row>
          {this.renderContent()}
          {this.renderButton()}
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { colors } = settings;
  return {
    token,
    user,
    device,
    platform,
    isMobile,
    colors
  };
};

export default connect(mapStateToProps, {})(OnboardingText);
