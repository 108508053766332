import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row } from "app/NativeComponents/common";

import { SelectItem, InlineButton } from "app/NativeComponents/snippets";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import { formatAddress, determineMainOwnerInfo } from "app/NativeActions";

class QueueItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      loading: false
    };
  }

  render() {
    const {
      item,
      user,
      item: { deal },
      colors,
      device,
      isMobile
    } = this.props;
    const { selected, loading } = this.state;
    if (deal) {
      let mailing_addresses = [];
      const owner = determineMainOwnerInfo(item);
      if (deal.send_to_owner_address == 1) {
        mailing_addresses.push({
          owner_name: owner.owner_name,
          address: owner.owner_address,
          address2: owner.owner_address2,
          city: owner.owner_address_city,
          state: owner.owner_address_state,
          zip: owner.owner_address_zip
        });
      }
      for (let i = 0; i < deal.owner?.mailing_addresses?.length; i++) {
        if (deal.owner.mailing_addresses[i].send_to_address) {
          mailing_addresses.push({
            owner_name: owner.owner_name,
            ...deal.owner.mailing_addresses[i]
          });
        }
      }
      return (
        <>
          {mailing_addresses.map((address, i) => {
            return (
              <SelectItem
                key={"queue_" + i}
                select_type="none"
                label={"Sending mail to:"}
                description={
                  address.owner_name || !!address.owner_address
                    ? formatAddress({
                        address: {
                          address: address.address,
                          address2: address.address2,
                          address_city: address.city,
                          address_state: address.state,
                          address_zip: address.zip
                        }
                      }).line2
                    : "Mail will not be sent"
                }
                noPress={true}
                renderRight={() => {
                  return (
                    <Row>
                      <InlineButton
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "property",
                            id: item?.property_id,
                            focus_side_panel:
                              this.props.user?.user_settings
                                ?.property_expanded === "not_expanded"
                                ? false
                                : true,
                            overlay: true,
                            data: {
                              property: item
                            }
                          });
                        }}
                      >
                        View Lead
                      </InlineButton>
                      {this.props.user?.pause_sending == 0 ? (
                        <InlineButton
                          primary={item.deal.paused == 1}
                          onPress={() => {
                            if (item.deal.paused == 1) {
                              this.props.updateLead({
                                token: this.props.token,
                                deal_ids: item.deal.id,
                                campaign_id: item.deal.campaign_id,
                                type: "start_mailers",
                                onLoading: () => {
                                  this.setState({
                                    loading: true
                                  });
                                },
                                onError: () => {
                                  this.setState({
                                    loading: false
                                  });
                                },
                                onSuccess: () => {
                                  this.setState({
                                    loading: false,
                                    selected: null
                                  });
                                  this.props.getSendingQueueCounts({
                                    token: this.props.token
                                  });
                                }
                              });
                            } else {
                              this.props.updateLead({
                                token: this.props.token,
                                deal_ids: item.deal.id,
                                type: "pause_mailers",
                                onLoading: () => {
                                  this.setState({
                                    loading: true
                                  });
                                },
                                onError: () => {
                                  this.setState({
                                    loading: false
                                  });
                                },
                                onSuccess: () => {
                                  this.setState({
                                    loading: false,
                                    selected: null
                                  });
                                  this.props.getSendingQueueCounts({
                                    token: this.props.token
                                  });
                                }
                              });
                            }
                          }}
                          loading={loading}
                        >
                          {item.deal.paused == 1 ? "Resume Mail" : "Pause Mail"}
                        </InlineButton>
                      ) : null}
                    </Row>
                  );
                }}
              >
                {!!address.owner_name || !!address.owner_address
                  ? formatAddress({
                      address: {
                        address: address.address,
                        address2: address.address2,
                        address_city: address.city,
                        address_state: address.state,
                        address_zip: address.zip
                      }
                    }).line1
                  : "Owner not found"}
              </SelectItem>
            );
          })}
        </>
      );
    }
    return null;
  }
}

export default QueueItem;
