import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row,
  Page
} from "app/NativeComponents/common";
import {
  GhostButton,
  SelectItem,
  InlineButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription,
  loadOnboardingText,
  logout
} from "app/NativeActions";
import DisplayAddons from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/DisplayAddons";
class SalvageOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      pause_loading: false,
      contentful_loading: true,
      contentful_content: null
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/cancel/one-last-thing");
    }

    this.loadData("churn_buster_offer");
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { colors, user } = this.props;
    const { contentful_content } = this.state;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.accepted_an_offer) {
      return (
        <>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <Header1
              style={{
                textAlign: "center"
              }}
            >
              <Bold>🎉 Success!</Bold>
            </Header1>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              Enjoy DealMachine with your new discounted price.
            </Header3>
          </Wrapper>

          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 0
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              loading={this.state.completing}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.tabNavigation({ slug: "leads" });
              }}
              icon_right={"arrow-right-alt"}
            >
              Take Me To My Account
            </GhostButton>
            <InlineButton
              button_type={"full"}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.logout();
              }}
            >
              Log me out. I'll come back later.
            </InlineButton>
          </Wrapper>
        </>
      );
    }

    return (
      <>
        {this.state.contentful_content ? (
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                <Bold>{contentful_content?.title}</Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                {contentful_content?.subtitle}
              </Header3>
            </Wrapper>

            <DisplayAddons
              contentful_content={this.state.contentful_content}
              plan_interval={this.props.source_of_truth?.subscription?.interval}
              manageSubscription={this.props.manageSubscription}
              onSuccess={() => {
                this.props.acceptedAnOffer();
              }}
            />
            <Wrapper
              style={{
                width: "100%",
                margin: this.props.device == "desktop" ? "5px auto" : 0,
                alignSelf: "stretch"
              }}
            >
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "stretch"
                }}
              >
                <InlineButton
                  button_type={"full"}
                  buttonContainerStyle={
                    this.props.desktopMobile ? { alignSelf: "stretch" } : {}
                  }
                  onPress={() => {
                    this.props.submitAnswer({
                      property_slug: "how_did_we_fall_short",
                      property_value: this.state.selected_options?.join(";")
                    });
                    this.props.nextStep();
                  }}
                >
                  No, I'd rather just cancel.
                </InlineButton>
                <InlineButton
                  button_type={"full"}
                  buttonContainerStyle={
                    this.props.desktopMobile ? { alignSelf: "stretch" } : {}
                  }
                  onPress={() => {
                    this.props.tabNavigation({ slug: "leads" });
                    this.props.toggleChurnBuster(false);
                  }}
                >
                  Nevermind, I don't want to cancel.
                </InlineButton>
                <Wrapper style={{ height: 40 }} />
              </Wrapper>
            </Wrapper>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    desktopMobile,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription,
  logout
})(SalvageOffer);
