import React, { Component } from "react";

import { Row } from "app/NativeComponents/common";
import { SelectItem, ConfirmInlineButton } from "app/NativeComponents/snippets";
class DefaultTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { tag, wrappedHandle } = this.props;
    const { loading } = this.state;
    return (
      <SelectItem
        noPress={true}
        select_type="none"
        renderRight={() => {
          return (
            <Row>
              <ConfirmInlineButton
                confirm_text="Really Delete?"
                confirm_icon="delete"
                loading_text="Deleting"
                loading={loading}
                onPress={() => {
                  this.props.removeTeamTag({
                    token: this.props.token,
                    tag_id: tag.id,
                    type: "remove",
                    onLoading: () => {
                      this.setState({ loading: true });
                    },
                    onError: () => {
                      this.setState({ loading: false });
                    },
                    onSuccess: () => {
                      this.setState({ loading: false });
                    }
                  });
                }}
              >
                Delete
              </ConfirmInlineButton>
              {wrappedHandle ? wrappedHandle() : null}
            </Row>
          );
        }}
      >
        {tag.title}
      </SelectItem>
    );
  }
}

export default DefaultTag;
