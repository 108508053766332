import React, { Component } from "react";

import ReactGA from "react-ga4";

const TRACKING_ID = "151615756";
ReactGA.initialize(TRACKING_ID);

class GoogleAnalyticsWrapper extends Component {
  registerGA() {
    const { user } = this.props;
    if (user) {
      ReactGA.set({ userId: user?.id });
    }
  }

  componentDidMount() {
    this.registerGA();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user && user?.id != prevProps.user?.id) {
      this.registerGA();
    }
  }

  render() {
    return null;
  }
}

export default GoogleAnalyticsWrapper;
