import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";
class Line extends Component {
  render() {
    const { colors, style } = this.props;
    return (
      <Wrapper
        style={{
          backgroundColor: colors.light_text_color,
          height: 1,
          width: 25,
          ...style
        }}
      />
    );
  }
}

export default Line;
