import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, KeyboardView, Row } from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  SearchBar,
  List,
  InlineButton,
  IconButton,
  SelectItem,
  BottomNavBar,
  PopoverMenu
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  getLists,
  setEditListInfo,
  pushSidePanel,
  setActiveListTab,
  addListToListTabs,
  renderDate,
  setActiveList
} from "app/NativeActions";
class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      sort_by: "date_created_desc",
      selected_list: null
    };
  }

  componentDidMount() {
    this.getData({ load_type: "refresh" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sort_by !== prevState.sort_by) {
      this.getData({ load_type: "refresh" });
    }
  }

  handleBack() {
    this.props.popSidePanel();
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  getData({ load_type = "load" }) {
    const { token, lists_loading, lists_refreshing, lists_begin, lists } =
      this.props;

    if (!lists_loading && !lists_refreshing) {
      this.props.getLists({
        token: this.props.token,
        load_type: "refresh",
        type: "all_lists",
        search: this.state.search,
        sort_by: this.state.sort_by,
        no_count: true,
        begin: 0,
        onError: () => {},
        onSuccess: () => {}
      });
    }
  }

  render() {
    const {
      colors,
      isMobile,
      lists_refreshing,
      lists,
      lists_loading,
      lists_loaded_all
    } = this.props;
    const { search, sort_by, selected_list } = this.state;
    return (
      <>
        {!this.props.listonly ? (
          <NewHeader
            title={"List Library"}
            subtitle={""}
            leftButton={{
              icon: this.props.device == "mobile" ? "arrow-back" : "close",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
        ) : null}

        <>
          {!this.props.listonly ? (
            <Row>
              <Wrapper style={{ flex: 1 }}>
                <SearchBar
                  value={search}
                  style={{
                    margin: 10,
                    marginRight: 0,
                    borderWidth: 0,
                    borderBottomWidth: 0
                  }}
                  title="Search Lists"
                  onChange={value => {
                    this.setState({
                      search: value
                    });
                  }}
                />
              </Wrapper>

              <PopoverMenu
                popover_width={350}
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <InlineButton
                      onPress={() => {}}
                      noPress={true}
                      style={{ marginLeft: 0, marginRight: 0 }}
                      pressedIn={pressedIn || hovering}
                    >
                      Sort
                    </InlineButton>
                  );
                }}
                menu_items={[
                  {
                    title: "Date Created Descending",
                    select_type: "radio",
                    selected: sort_by == "date_created_desc",
                    onPress: () => {
                      this.setState({
                        sort_by: "date_created_desc"
                      });
                    }
                  },
                  {
                    title: "Date Created Ascending",
                    select_type: "radio",
                    selected: sort_by == "date_created_asc",
                    onPress: () => {
                      this.setState({
                        sort_by: "date_created_asc"
                      });
                    }
                  },
                  {
                    title: "Leads (most to least)",
                    select_type: "radio",
                    selected: sort_by == "lead_count_desc",
                    onPress: () => {
                      this.setState({
                        sort_by: "lead_count_desc"
                      });
                    }
                  },
                  {
                    title: "Leads (least to most)",
                    select_type: "radio",
                    selected: sort_by == "lead_count_asc",
                    onPress: () => {
                      this.setState({
                        sort_by: "lead_count_asc"
                      });
                    }
                  }
                ]}
              />
              <InlineButton
                icon={"add"}
                primary={true}
                select_type="none"
                selected={true}
                style={{ marginLeft: 0 }}
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "new_list",
                    overlay: true
                  });
                }}
              >
                New
              </InlineButton>
            </Row>
          ) : (
            <>
              <SearchBar
                value={search}
                style={{
                  margin: 10
                }}
                title="Search Lists"
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
              />
            </>
          )}

          {lists_loading && lists.length == 0 ? (
            <Wrapper style={{ flex: 1 }}>
              <SpinWrapper text="Loading Lists..." />
            </Wrapper>
          ) : (
            <List
              style={{ flex: 1 }}
              items={this.filterOptions(lists)}
              itemStructure={({ item, index }) => {
                return (
                  <SelectItem
                    key={"option_" + index}
                    //hover_icon={"keyboard-arrow-right"}
                    subtext={renderDate(item.date_created)}
                    selected={
                      selected_list
                        ? item.id == selected_list
                        : item.id == selected_list?.id
                    }
                    onPress={() => {
                      this.setState({
                        selected_list: item.id
                      });
                    }}
                    select_type={item.pinned ? "icon" : "none"}
                    select_icon={item.pinned ? "push-pin" : ""}
                    icon_color={colors.light_text_color}
                    confirm_text={
                      !!this.props.confirm_text
                        ? this.props.confirm_text
                        : "Open"
                    }
                    require_confirm={selected_list ? true : false}
                    onConfirm={
                      this.props.onConfirm
                        ? () => this.props.onConfirm(item)
                        : () => {
                            if (isMobile) {
                              this.props.setActiveListTab(item);
                            } else {
                              this.props.addListToListTabs(item);
                            }
                            this.handleBack();
                            //switch tab to leads view?
                          }
                    }
                    secondaryButton={
                      this.props.remove_secondary_button ? null : "View"
                    }
                    onSecondaryPress={() => {
                      this.props.setActiveList(item);
                      this.props.setEditListInfo({
                        id: item.id,
                        title: item.title,
                        date_created: item.date_created,
                        ...item
                      });
                      this.props.pushSidePanel({
                        slug: "edit_list",
                        id: item.id,
                        data: { panel_id: item.id },
                        overlay: true
                      });

                      this.setState({
                        selected_list: null
                      });
                    }}
                  >
                    {item.title}
                  </SelectItem>
                );
              }}
              /*
              infiniteScroll={true}
              is_refreshing={lists_refreshing}
              canLoadMore={
                !lists_loaded_all &&
                !lists_loading &&
                !lists_refreshing &&
                lists.length > 0
              }
              isLoadingMore={
                lists_loading && !lists_refreshing && lists.length > 0
              }
              onLoadMore={() => {
                this.getData({ load_type: "load_more" });
              }}
              */
            />
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, list }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const {
    lists,
    lists_loading,
    lists_refreshing,
    lists_begin,
    lists_loaded_all,
    lists_limit,
    list_modal,
    list_search
  } = list;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,

    lists,
    lists_loading,
    lists_refreshing,
    lists_begin,
    lists_loaded_all,
    lists_limit,
    list_modal,
    list_search
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getLists,
  setEditListInfo,
  pushSidePanel,
  setActiveListTab,
  setActiveList,
  addListToListTabs
})(Library);
