import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Scroll,
  InnerCopy,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  IconButton,
  SmallLabel,
  BottomNavBar,
  InlineButton,
  CloseButton
} from "app/NativeComponents/snippets";
import {
  determineMainOwnerInfo,
  toTitleCase,
  numberFormat,
  numberWithCommas,
  formatAddress,
  updateLead
} from "app/NativeActions";
import moment from "moment";
class OwnerUpdateDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false
    };
  }

  renderInfo(property) {
    const { slug, format } = this.props;

    let main_owner = property;
    if (property?.deal?.custom_owner) {
      main_owner = determineMainOwnerInfo(property);
    }
    const owner_address = formatAddress({
      address: {
        address: main_owner.owner_address,
        address2: main_owner.owner_address2,
        address_city: main_owner.owner_address_city,
        address_state: main_owner.owner_address_state,
        address_zip: main_owner.owner_address_zip
      }
    });

    switch (slug) {
      default:
        let formatted_value = "";
        let inner_value = !!property[slug] ? property[slug] : "--";
        if (inner_value != "--") {
          switch (format) {
            case "name":
            case "text":
            case "company_name":
            default:
              formatted_value = toTitleCase(inner_value);
              break;

            case "money":
            case "price":
              if (
                parseInt(inner_value) !== 0 &&
                inner_value &&
                inner_value !== ""
              ) {
                formatted_value = "$" + numberFormat(parseInt(inner_value));
              }
              break;

            case "date":
              if (inner_value && inner_value !== "" && inner_value !== 0) {
                formatted_value = moment(inner_value).format("MMM Do, YYYY");
              }
              break;

            case "percent":
              if (inner_value && inner_value !== 0 && inner_value !== "0") {
                formatted_value = parseInt(parseFloat(inner_value) * 100) + "%";
              }
              break;

            case "interest_rate":
              if (inner_value && inner_value !== 0 && inner_value !== "0") {
                formatted_value = inner_value
                  ? numberFormat(parseFloat(inner_value) * 100, 2) + "%"
                  : "";
              }
              break;
            case "term":
              if (parseInt(inner_value) > 60) {
                formatted_value = inner_value + " Month";
              }
              break;

            case "number":
              formatted_value = numberFormat(parseInt(inner_value));
              break;

            case "acre":
              formatted_value = parseFloat(inner_value).toFixed(2);
              break;

            case "feet":
              formatted_value = numberFormat(parseInt(inner_value)) + " ft";
              break;
          }
        } else {
          formatted_value = inner_value;
        }

        return <Copy>{formatted_value}</Copy>;

      case "owner":
        return (
          <Wrapper>
            <Copy>{main_owner.owner_name}</Copy>

            <Copy style={{ fontSize: 10 }}>{owner_address.line1}</Copy>
            <Copy style={{ fontSize: 10 }}>{owner_address.line2}</Copy>
          </Wrapper>
        );

      case "owner_address":
        return (
          <Wrapper>
            <Copy>{owner_address.line1}</Copy>
            <Copy style={{ fontSize: 10 }}>{owner_address.line2}</Copy>
          </Wrapper>
        );

      case "owner_name":
        return <Copy>{main_owner.owner_name}</Copy>;

      case "owner_firstname":
      case "owner_first_name":
        return <Copy>{main_owner.owner_firstname}</Copy>;

      case "owner_lastname":
      case "owner_last_name":
        return <Copy>{main_owner.owner_lastname}</Copy>;
      case "owner_status":
        return (
          <Copy>
            {main_owner.owner_status_info
              ? main_owner.owner_status_info.text
              : "Owner Not Found"}
          </Copy>
        );
    }
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      property,
      slug,
      include_title,
      row = false
    } = this.props;
    const { show } = this.state;

    let has_updates = false;
    let is_owner_slug = false;
    if (
      property?.deal &&
      !property?.deal?.removed &&
      property?.deal?.lead_data_update_confirmation &&
      property?.deal?.last_property_history
    ) {
      switch (slug) {
        case "owner_status":
        case "owner_status_info":
          if (
            property?.owner_status_info?.slug !=
            property?.deal?.last_property_history.owner_status_info?.slug
          ) {
            has_updates = true;
          }
          break;

        case "owner":
          if (
            (property?.deal?.custom_owner &&
              (property?.deal?.owner_status_info?.slug !=
                property?.owner_status_info?.slug ||
                property?.deal?.owner_name != property?.owner_name ||
                property?.deal?.owner_address != property?.owner_address ||
                property?.deal?.owner_address2 != property?.owner_address2 ||
                property?.deal?.owner_address_city !=
                  property?.owner_address_city ||
                property?.deal?.owner_address_state !=
                  property?.owner_address_state ||
                property?.deal?.owner_address_zip !=
                  property?.owner_address_zip)) ||
            property?.deal?.owner_name !=
              property?.deal?.last_property_history?.owner_name ||
            property?.deal?.owner_address !=
              property?.deal?.last_property_history?.owner_address ||
            property?.deal?.owner_address2 !=
              property?.deal?.last_property_history?.owner_address2 ||
            property?.deal?.owner_address_city !=
              property?.deal?.last_property_history?.owner_address_city ||
            property?.deal?.owner_address_state !=
              property?.deal?.last_property_history?.owner_address_state ||
            property?.deal?.owner_address_zip !=
              property?.deal?.last_property_history?.owner_address_zip
          ) {
            has_updates = true;
          }
          break;

        default:
          if (property?.[slug] != property?.deal?.last_property_history[slug]) {
            has_updates = true;
          }
          break;
      }
    }

    if (
      slug == "owner" ||
      slug == "owner_name" ||
      slug == "owner_firstname" ||
      slug == "owner_lastname" ||
      slug == "owner_status" ||
      slug == "owner_address"
    ) {
      is_owner_slug = true;
    }

    if (has_updates) {
      return (
        <>
          {!!include_title ? (
            <Wrapper style={{ padding: 25 }}>
              <Copy style={{ color: colors.actionable_text_color }}>
                <Bold>{include_title}</Bold>
              </Copy>
            </Wrapper>
          ) : null}
          <Wrapper style={row ? { flexDirection: "row" } : {}}>
            <Wrapper style={row ? { flex: 1 } : {}}>
              <SmallLabel>
                Updated Data:{" "}
                <InnerCopy
                  style={{
                    fontStyle: "italic",
                    fontWeight: "normal",
                    marginLeft: 5
                  }}
                >
                  {"  " +
                    moment(property?.last_updated_from_data_provider).format(
                      "MMM Do, YYYY"
                    )}
                </InnerCopy>
              </SmallLabel>
              <Wrapper style={{ padding: 25, paddingTop: 0 }}>
                {property?.deal?.custom_owner && is_owner_slug
                  ? this.renderInfo({
                      ...property,
                      deal: null
                    })
                  : this.renderInfo(property)}
              </Wrapper>
            </Wrapper>
            <Wrapper style={row ? { flex: 1 } : {}}>
              <SmallLabel>
                {property?.deal?.custom_owner && is_owner_slug
                  ? "Custom Data: "
                  : "Old Data: "}
                <InnerCopy
                  style={{
                    fontStyle: "italic",
                    fontWeight: "normal",
                    marginLeft: 5
                  }}
                >
                  {property?.deal?.custom_owner && is_owner_slug
                    ? "  " +
                      moment(property?.deal?.custom_owner_date).format(
                        "MMM Do, YYYY"
                      )
                    : "  " +
                      moment(
                        property?.deal?.last_property_history
                          ?.last_updated_from_data_provider
                      ).format("MMM Do, YYYY")}
                </InnerCopy>
              </SmallLabel>
              <Wrapper style={{ padding: 25, paddingTop: 0 }}>
                {property?.deal?.custom_owner && is_owner_slug
                  ? this.renderInfo(property)
                  : this.renderInfo(property?.deal?.last_property_history)}
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </>
      );
    }
    return null;
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;
  return {
    token,
    user,
    isMobile,
    device,
    colors
  };
};

export default connect(mapStateToProps, {})(OwnerUpdateDisplay);
