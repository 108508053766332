import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Spin,
  Icon,
  Title,
  Row,
  Copy,
  Bold
} from "app/NativeComponents/common";

import Scene3 from "./Scene3";
import Scene4 from "./Scene4";

class Scene2 extends Component {
  render() {
    if (this.props.scene > 1) {
      return (
        <div>
          <div className="animated slideInRight">
            <Row style={{ justifyContent: "flex-end" }}>
              <Card>
                <CardBody style={{ padding: "10px 15px" }}>
                  <Copy>
                    <Bold>2 mailing addresses found!</Bold>
                  </Copy>
                </CardBody>
              </Card>
            </Row>
          </div>
          <Scene3 scene={this.props.scene} />
          <Scene4 scene={this.props.scene} />
        </div>
      );
    }

    return <div />;
  }
}

export default Scene2;
