import React, { Component } from "react";
import EqualOption from "./EqualOption";

class ObjectArrayType extends Component {
  render() {
    const {
      filter,
      selected_filter,
      selected_equal_type,
      selected_options,
      colors,
      additional_condition_values
    } = this.props;

    if (filter.type == "object_array" || filter.type == "object" || filter.type == "flattened_object_collection") {
      return (
        <>
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_equal_to_any_of"}
            title={"Is equal to any of"}
            allow_options={true}
            selected_options={selected_options}
            available_options={filter.available_options || []}
            addSelectedOption={this.props.addSelectedOption}
            removeSelectedOption={this.props.removeSelectedOption}
            is_active={selected_equal_type == "is_equal_to_any_of"}
            onPress={() => {
              this.props.selectEqualType("is_equal_to_any_of");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_not_equal_to_any_of"}
            title={"Is not equal to any of"}
            allow_options={true}
            selected_options={selected_options}
            available_options={filter.available_options || []}
            addSelectedOption={this.props.addSelectedOption}
            removeSelectedOption={this.props.removeSelectedOption}
            is_active={selected_equal_type == "is_not_equal_to_any_of"}
            onPress={() => {
              this.props.selectEqualType("is_not_equal_to_any_of");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_known"}
            title={"Is known"}
            allow_options={false}
            is_active={selected_equal_type == "is_known"}
            onPress={() => {
              this.props.selectEqualType("is_known");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_unknown"}
            title={"Is unknown"}
            allow_options={false}
            is_active={selected_equal_type == "is_unknown"}
            onPress={() => {
              this.props.selectEqualType("is_unknown");
            }}
            additional_condition_values={additional_condition_values}
          />
          {/*
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"contains_any_of"}
            title={"Contains any of"}
            allow_options={true}
            selected_options={selected_options}
            available_options={filter.available_options || []}
            addSelectedOption={this.props.addSelectedOption}
            removeSelectedOption={this.props.removeSelectedOption}
            is_active={selected_equal_type == "contains_any_of"}
            onPress={() => {
              this.props.selectEqualType("contains_any_of");
            }}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"does_not_contain_any_of"}
            title={"Does not contain any of"}
            allow_options={true}
            selected_options={selected_options}
            available_options={filter.available_options || []}
            addSelectedOption={this.props.addSelectedOption}
            removeSelectedOption={this.props.removeSelectedOption}
            is_active={selected_equal_type == "does_not_contain_any_of"}
            onPress={() => {
              this.props.selectEqualType("does_not_contain_any_of");
            }}
          />*/}
        </>
      );
    }
    return null;
  }
}

export default ObjectArrayType;
