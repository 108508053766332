import React, { Component } from "react";

import {
  Section,
  ColorOverlay,
  Diangle
} from "app/NativeComponents/website/common";

import SectionContent from "./SectionContent";

class WebpageSection extends Component {
  render() {
    if (this.props.section) {
      return (
        <Section
          id={this.props.section.slug ? this.props.section.slug : ""}
          type={this.props.section.sectionStyle}
          media={this.props.section.backgroundMedia}
        >
          <Diangle diangles={this.props.section.diangles} location={"Top"} />
          <Diangle diangles={this.props.section.diangles} location={"Bottom"} />
          <ColorOverlay
            type={this.props.section.backgroundColor}
            has_media={this.props.section.backgroundMedia ? true : false}
          >
            <SectionContent {...this.props} />
          </ColorOverlay>
        </Section>
      );
    }

    return <div />;
  }
}

export { WebpageSection };
