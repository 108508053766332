import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";

const SemiCircleProgress = ({
  percentage,
  strokeWidth = 10,
  strokeColor,
  backgroundColor,
  style,
  diameter = 200,
  showPercentValue
}) => {
  return (
    <SemiCircleProgressBar
      percentage={parseInt(percentage)}
      strokeWidth={strokeWidth}
      diameter={diameter}
      stroke={strokeColor}
      background={backgroundColor}
      showPercentValue={showPercentValue}
    />
  );
};

export { SemiCircleProgress };
