import React, { PureComponent } from "react";
import { connect } from "react-redux";

class PodsightsWrapper extends PureComponent {
  constructor() {
    super();
    this.state = {};

    this.trackPodsightsUser = this.trackPodsightsUser.bind(this);
  }
  componentDidMount() {
    if (this.props.user) {
      this.trackPodsightsUser(this.props.user);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user?.id !== prevProps?.user?.id && this.props.user?.id) {
      this.trackPodsightsUser(this.props.user);
    }
  }

  trackPodsightsUser(user) {
    if (window?.pdst && user?.id) {
      window.pdst("alias", {
        id: user?.id
      });
      window.pdst("lead", {});
    }
  }

  render() {
    return null;
  }
}
const mapStateToProps = ({ auth, native }) => {
  const { user } = auth;

  const { device, platform } = native;

  return {
    user,
    device,
    platform
  };
};

export default connect(mapStateToProps, {})(PodsightsWrapper);
