import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon,
  Button,
  Gradient
} from "app/NativeComponents/common";
import { numberWithCommas } from "app/NativeActions";
class PillTabButton extends Component {
  renderNotifications() {
    const { notification, colors, notification_type } = this.props;
    if (!!notification && notification != 0) {
      let gradient_color_1 = colors.gradient_color_1;
      let gradient_color_2 = colors.gradient_color_2;
      let text_color = colors.white_text_color;

      switch (notification_type) {
        case "error":
          gradient_color_1 = colors.error_color_muted;
          gradient_color_2 = colors.error_color_muted;
          text_color = colors.text_color;
          break;

        default:
          break;
      }

      return (
        <Gradient
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 10,
            paddingLeft: 10,
            borderRadius: 20,
            marginLeft: 5
          }}
          color1={gradient_color_1}
          color2={gradient_color_2}
        >
          <Copy
            style={{
              fontSize: 10,
              color: text_color,
              textAlign: "center"
            }}
          >
            <Bold>
              {Number.isNaN(notification)
                ? notification
                : numberWithCommas(notification)}
            </Bold>
          </Copy>
        </Gradient>
      );
    }
  }

  render() {
    const {
      active,
      title,
      mobile_title,
      icon,
      fa_icon,
      colors,
      isMobile,
      onPress = () => {}
    } = this.props;
    if (active) {
      return (
        <Button
          style={{
            padding: 20,
            paddingTop: 0,
            paddingBottom: 0,
            height: 40,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.card_color,
            borderRadius: 30,
            borderColor: colors.border_color,
            borderWidth: isMobile ? 1 : 0,
            borderStyle: "solid"
          }}
          onPress={onPress}
        >
          <Row>
            {!!icon || !!fa_icon ? (
              <Icon
                size={18}
                icon={icon}
                fa_icon={fa_icon}
                style={{ marginRight: 5 }}
              />
            ) : null}
            <Copy style={{ textAlign: "center" }}>
              <Bold>{title}</Bold>
            </Copy>
            {this.renderNotifications()}
          </Row>
        </Button>
      );
    }

    return (
      <Button
        style={{
          padding: 20,
          paddingTop: 0,
          paddingBottom: 0,
          height: 40,
          alignItems: "center",
          justifyContent: "center"
        }}
        onPress={onPress}
      >
        <Row>
          {!!icon || !!fa_icon ? (
            <Icon
              size={18}
              icon={icon}
              fa_icon={fa_icon}
              style={{ marginRight: 5 }}
            />
          ) : null}
          <Copy style={{ textAlign: "center" }}>
            {isMobile ? mobile_title : title}
          </Copy>
          {this.renderNotifications()}
        </Row>
      </Button>
    );
  }
}

export default PillTabButton;
