import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class Repairs extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, offset: 0 };
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }
  render() {
    const { property, search, colors } = this.props;

    return (
      <>
        {"Rehab & Repairs".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Estimated repair cost".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Building condition".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Estimated rehab cost".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Repair cost per sqft".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        !search ? (
          <Card
            id={"rehab-repairs"}
            label={"Rehab & Repairs"}
            style={{ paddingTop: 10, marginTop: 15 }}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y
              });
            }}
          >
            <Row
              style={
                this.props.expanded
                  ? {
                      flexDirection: "row",
                      alignItems: "stretch",
                      flexWrap: "wrap"
                    }
                  : { flexDirection: "column", alignItems: "stretch" }
              }
            >
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                item={property?.estimated_repair_cost}
                label={"Estimated repair cost:"}
                hiddenFromNonSubs={true}
                format={"money"}
                helper={"estimated_repair_cost"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                item={property?.building_condition}
                label={"Building condition:"}
                hiddenFromNonSubs={true}
                helper={"building_condition"}
              />

              {this.state.more_info || !!search ? (
                <>
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.estimated_repair_cost_per_sqft}
                    label={"Repair cost per sqft:"}
                    hiddenFromNonSubs={true}
                    helper={"repair_cost_per_sqft"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    item={property?.BuildingQuality}
                    label={"Building quality:"}
                    hiddenFromNonSubs={true}
                    helper={"building_quality"}
                  />
                </>
              ) : null}
            </Row>
            {!search && this.state.more_info ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: false
                    },
                    () => {
                      if (this.props.device === "desktop") {
                        this.props.scrollToSection({
                          id: "rehab-repairs",
                          offset: this.state.offset
                        });
                      } else {
                        this._interval = setTimeout(() => {
                          this.props.scrollToSection({
                            id: "rehab-repairs",
                            offset: this.state.offset
                          });
                        }, 50);
                      }
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-up"}
              >
                Less Info
              </InlineButton>
            ) : !search ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: true
                    },
                    () => {
                      this.props.scrollToSection({
                        id: "rehab-repairs",
                        offset: this.state.offset
                      });
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-down"}
              >
                More Info
              </InlineButton>
            ) : null}
          </Card>
        ) : null}
      </>
    );
  }
}

export default Repairs;
