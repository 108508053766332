import React, { Component } from "react";

import { Wrapper } from "app/NativeComponents/common";

import CheckBox from "./CheckBox";

class CheckBoxes extends Component {
  render() {
    const {
      toggle_lead_images,
      list_properties,
      active_property,
      selected_leads = [],
      selected_all,
      colors
    } = this.props;
    return (
      <Wrapper>
        {list_properties.map((property, i) => {
          return (
            <Wrapper key={"checkboxes_" + i} style={{ position: "relative" }}>
              <CheckBox
                key={"check_box_" + i}
                toggle_lead_images={toggle_lead_images}
                active_property={active_property}
                selected_leads={selected_leads}
                selected_all={selected_all}
                checkLead={this.props.checkLead}
                property={property}
                colors={colors}
                hover_row={this.props.hover_row}
                setHoverRow={this.props.setHoverRow}
              />
              {this.props.hover_row == property?.deal?.id && false ? (
                <Wrapper
                  style={{
                    padding: 10,
                    paddingRight: 0,
                    paddingLeft: 0,
                    height: this.props.toggle_lead_images == "large" ? 100 : 35,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    backgroundColor: colors.hover_color,
                    pointerEvents: "none",
                    width: window ? window?.innerWidth : "auto"
                  }}
                />
              ) : null}
            </Wrapper>
          );
        })}
      </Wrapper>
    );
  }
}

export default CheckBoxes;
