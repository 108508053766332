import React from "react";

const CenterLeft = ({ children, style }) => {
  return (
    <div className="deal-center-left" style={style}>
      {children}
    </div>
  );
};

export { CenterLeft };
