import React, { Component } from "react";

import { InlineButton } from "app/NativeComponents/snippets";
import { store } from "app/store";

class IncludeOption extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const colors = store.getState().settings.colors;
    return (
      <InlineButton
        onPress={this.props.onPress}
        selected={this.props.selected}
        style={
          this.props.selected
            ? {
                borderColor: colors.active_color,
                borderWidth: 1,
                borderStyle: "solid",
                marginLeft: 0
              }
            : {
                marginLeft: 0,
                borderColor: colors.light_gray_color,
                borderWidth: 1,
                borderStyle: "solid"
              }
        }
        tooltip={this.props.tooltip}
        textColor={
          this.props.selected
            ? colors.actionable_text_color
            : colors.light_text_color
        }
        tooltipPlacement={"top"}
      >
        {this.props.title}
      </InlineButton>
    );
  }
}

export default IncludeOption;
