import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Bold, Row } from "app/NativeComponents/common";

import { GhostButton } from "app/NativeComponents/snippets";
import { pushSidePanel, popSidePanel } from "app/NativeActions";

class FillOutContactInfoStep extends Component {
  render() {
    return (
      <>
        <Wrapper>
          <Wrapper style={{ padding: 25 }}>
            <Copy style={{ marginBottom: 10 }}>
              <Bold>Why should I send a postcard?</Bold>
            </Copy>
            <Copy style={{ marginBottom: 10 }}>
              Sending a postcard to the property owner allows them to get in
              touch with you if they are interested in talking to you.
            </Copy>
            <Copy style={{ marginBottom: 10 }}>
              Once they've contacted you, you can set up an appointment to move
              forward!
            </Copy>
            <Copy style={{ marginBottom: 10 }}>
              We put together a postcard for you. You can swap with another and
              change your contact information.
            </Copy>
            <Copy>
              First, you'll need to add your contact information so the owner
              can get it touch.
            </Copy>
          </Wrapper>

          <GhostButton
            button_type={"full"}
            primary={true}
            onPress={() => {
              this.props.pushSidePanel({
                slug: "signature_onboarding",
                overlay: true,
                data: {
                  onboarding: true,
                  onSuccess: () => {
                    this.props.getMail();
                    this.props.popSidePanel();
                  }
                }
              });
            }}
          >
            Enter Contact Info
          </GhostButton>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  popSidePanel
})(FillOutContactInfoStep);
