import React, { Component } from "react";

import { Button, Wrapper, Icon, Row } from "app/NativeComponents/common";
import { PopoverMenu, IconButton } from "app/NativeComponents/snippets";

class ListStackHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  render() {
    const { selected_all, selected_leads, colors, sort_by } = this.props;
    const { show } = this.state;

    return (
      <Button
        onPress={() => {
          this.props.changeSortBy({
            slug: "list_stack",
            type:
              "list_stack" !== sort_by.slug
                ? sort_by.type
                : sort_by.type === "asc"
                ? "desc"
                : "asc"
          });
        }}
        tooltip={
          sort_by.slug !== "list_stack"
            ? sort_by.type == "asc"
              ? "Sort by list stack count ascending"
              : "Sort by list stack count descending"
            : sort_by.slug == "list_stack" && sort_by.type == "asc"
            ? "Sort by list stack count descending"
            : "Sort by list stack count ascending"
        }
        tooltipPlacement={"top"}
        style={{
          backgroundColor:
            "list_stack" === sort_by.slug
              ? colors.gray_selected_color
              : colors.background_color,
          borderRightWidth: 1,
          borderRightColor: colors.border_color,
          borderRightStyle: "solid",

          borderBottomWidth: 1,
          borderBottomColor: colors.border_color,
          borderBottomStyle: "solid",
          position: "relative"
        }}
      >
        <Wrapper
          style={{
            padding: 10,
            paddingRight: 0,
            paddingLeft: 0,
            width: 60,
            height: 25,

            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Icon icon={"format-list-numbered"} size={22} />
        </Wrapper>

        {sort_by.slug == "list_stack" ? (
          <Wrapper
            style={{
              position: "absolute",
              bottom: 5,
              right: 5
            }}
          >
            <Icon
              icon={
                sort_by.type == "asc"
                  ? "keyboard-arrow-up"
                  : "keyboard-arrow-down"
              }
              color={colors.light_text_color}
              size={18}
            />
          </Wrapper>
        ) : null}
      </Button>
    );
    /*
    return (
      <PopoverMenu
        popoverPlacement={"right"}
        popover_width={350}
        renderComponent={options => {
          return (
            <Wrapper
              tooltip="List Stacking"
              tooltipPlacement={"top"}
              style={{
                backgroundColor: colors.background_color,
                borderRightWidth: 1,
                borderRightColor: colors.border_color,
                borderRightStyle: "solid",

                borderBottomWidth: 1,
                borderBottomColor: colors.border_color,
                borderBottomStyle: "solid"
              }}
            >
              <Wrapper
                style={{
                  padding: 10,
                  paddingRight: 0,
                  paddingLeft: 0,
                  width: 60,
                  height: 25,

                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon icon={"format-list-numbered"} size={22} />
              </Wrapper>
            </Wrapper>
          );
        }}
        menu_items={[
          {
            title:
              sort_by.slug !== "list_stack"
                ? sort_by.type == "asc"
                  ? "Sort by list stack count ascending"
                  : "Sort by list stack count descending"
                : sort_by.slug == "list_stack" && sort_by.type == "asc"
                ? "Sort by list stack count descending"
                : "Sort by list stack count ascending",
            type: "normal",
            icon:
              sort_by.slug !== "list_stack"
                ? sort_by.type == "asc"
                  ? "keyboard-arrow-up"
                  : "keyboard-arrow-down"
                : sort_by.slug == "list_stack" && sort_by.type == "asc"
                ? "keyboard-arrow-down"
                : "keyboard-arrow-up",
            select_type: "icon",
            selected: sort_by.slug == "list_stack",
            onPress: () => {
              this.props.changeSortBy({
                slug: "list_stack",
                type:
                  "list_stack" !== sort_by.slug
                    ? sort_by.type
                    : sort_by.type === "asc"
                    ? "desc"
                    : "asc"
              });
            }
          },
          {
            title: "Manage List Stacking",
            type: "primary",
            icon: "format-list-numbered",
            select_type: "icon",
            onPress: () => {
              this.props.pushSidePanel({
                slug: "list_stack_settings",
                overlay: true
              });
            }
          }
        ]}
      />
    );*/
  }
}

export default ListStackHeader;
