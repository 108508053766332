export const GET_CAMPAIGNS = "get_campaigns";
export const REFRESH_CAMPAIGNS = "refresh_campaigns";
export const GET_CAMPAIGNS_FAIL = "get_campaigns_fail";
export const GET_CAMPAIGNS_SUCCESS = "get_campaigns_success";
export const GET_CAMPAIGN = "get_campaign";
export const GET_CAMPAIGN_FAIL = "get_campaign_fail";
export const GET_CAMPAIGN_SUCCESS = "get_campaign_success";

export const CAMPAIGN_INIT = "campaign_init";
export const CAMPAIGN_RESET = "campaign_reset";
export const CAMPAIGN_FIELD_CHANGED = "campaign_field_changed";
export const SAVE_CAMPAIGN = "save_campaign";
export const CREATE_CAMPAIGN_SUCCESS = "create_campaign_success";
export const SAVE_CAMPAIGN_SUCCESS = "save_campaign_success";
export const SAVE_CAMPAIGN_FAIL = "save_campaign_fail";
export const DELETE_CAMPAIGN = "delete_campaign";

export const ADD_CAMPAIGN_STEP = "add_campaign_step";
export const REMOVE_CAMPAIGN_STEP = "remove_campaign_step";
export const EDIT_CAMPAIGN_STEP = "edit_campaign_step";
export const REINDEX_CAMPAIGN_STEPS = "reindex_campaign_steps";
