export const GET_TASKS = "get_tasks";
export const GET_TASKS_SUCCESS = "get_tasks_success";
export const GET_TASKS_FAIL = "get_tasks_fail";
export const LOAD_MORE_TASKS = "load_more_tasks";
export const LOAD_MORE_TASKS_SUCCESS = "load_more_tasks_success";

export const GET_TASK = "get_task";
export const GET_TASK_FAIL = "get_task_fail";
export const GET_TASK_SUCCESS = "get_task_success";

export const UPDATE_TASK = "update_task";
export const UPDATE_TASK_FAIL = "update_task_fail";
export const UPDATE_TASK_SUCCESS = "update_task_success";
export const LOAD_TASK_LIST_FAIL = "load_task_list_fail";
export const LOAD_TASK_LIST_SUCCESS = "load_task_list_success";
export const CLEAR_TASK_LIST = "clear_task_list";
export const SET_TASKS_PAGE = "set_tasks_page";
export const SET_TASKS_LIMIT = "set_tasks_limit";

export const MARK_TASK_COMPLETE = "mark_task_complete";
export const REMOVE_TASK = "remove_task";

export const CREATE_TASK = "create_task";
export const CREATE_TASK_FAIL = "create_task_fail";
export const CREATE_TASK_SUCCESS = "create_task_success";

export const SET_ACTIVE_TASK_COUNT = "set_active_task_count";
export const SET_TASK_PANEL = "set_task_panel";
export const SELECT_ACTIVE_TASK = "select_active_task";
export const SELECT_ACTIVE_TASK_FAIL = "select_active_task_fail";
export const SELECT_ACTIVE_TASK_SUCCESS = "select_active_task_success";
export const COMPLETE_TASK_SUCCESS = "complete_task_success";
export const UNDO_COMPLETE_TASK_SUCCESS = "undo_complete_task_success";
