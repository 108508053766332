export const SET_EDIT_RETURN_LOCATION = "edit_return_location";
export const GET_SIGNATURES = "get_signatures";
export const REFRESH_SIGNATURES = "refresh_signatures";
export const GET_SIGNATURES_FAIL = "get_signatures_fail";
export const GET_SIGNATURES_SUCCESS = "get_signatures_success";
export const GET_SIGNATURE = "get_signature";
export const GET_SIGNATURE_FAIL = "get_signature_fail";
export const GET_SIGNATURE_SUCCESS = "get_signature_success";

export const SIGNATURE_INIT = "signature_init";
export const SIGNATURE_RESET = "signature_reset";
export const SIGNATURE_FIELD_CHANGED = "signature_field_changed";
export const SAVE_SIGNATURE = "save_signature";
export const CREATE_SIGNATURE_SUCCESS = "create_signature_success";
export const SAVE_SIGNATURE_SUCCESS = "save_signature_success";
export const SAVE_SIGNATURE_FAIL = "save_signature_fail";
export const DELETE_SIGNATURE = "delete_signature";
