export const GET_ANALYTICS = "get_analytics";
export const REFRESH_ANALYTICS = "refresh_analytics";
export const GET_ANALYTICS_FAIL = "get_analytics_fail";
export const GET_ANALYTICS_SUCCESS = "get_analytics_success";
export const TOGGLE_ANALYTICS_TEAM = "toggle_analytics_team";
export const CHANGE_ANALYTICS_DATES_OPTION = "change_analytics_dates_option";

export const UPDATE_ANALYTICS_FILTER = "update_analytics_filter";
export const UPDATE_SINGLE_ANALYTICS_FILTER = "update_single_analytics_filter";
export const SET_ANALYTICS_TYPE = "set_analytics_type";

export const GET_ANALYTICS_DATA = "get_analytics_data";
export const GET_ANALYTICS_DATA_FAIL = "get_analytics_data_fail";
export const GET_ANALYTICS_DATA_SUCCESS = "get_analytics_data_success";
