import React, { PureComponent } from "react";
import { store } from "app/store";
import { Copy } from "app/NativeComponents/common";

class Card extends PureComponent {
  render() {
    const colors = store.getState().settings.colors;
    const { style, children, innerRef, label, id = null } = this.props;

    return (
      <div
        id={id ? id : ""}
        ref={innerRef}
        className={
          this.props.className
            ? this.props.className + " deal-card"
            : "deal-card"
        }
        style={{
          backgroundColor: colors.card_color,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: colors.border_color,
          position: "relative",
          ...style
        }}
        onClick={this.props.onPress}
      >
        {!!label ? (
          <div
            style={{
              backgroundColor: colors.card_color,
              padding: 5,
              top: -15,
              left: 20,
              height: 10,
              position: "absolute"
            }}
          >
            <Copy style={{ fontSize: 12, color: colors.light_text_color }}>
              {label}
            </Copy>
          </div>
        ) : null}

        {children}
      </div>
    );
  }
}

export { Card };
