import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  StatusBarStyle,
  Title,
  Copy,
  Bold,
  Spin,
  Row,
  Button
} from "app/NativeComponents/common";
import { IconButton, CircleButton } from "app/NativeComponents/snippets";
import {
  toggleDrawer,
  mobileToggleDrawer,
  pushSidePanel
} from "app/NativeActions";
class MainHeader extends Component {
  render() {
    const {
      style,
      colors,
      isIphoneX,
      platform,

      title,
      renderTitle,

      subtitle,
      subtitle_loading,
      subtitle_loading_text = "Loading...",
      onSubtitlePress = null,
      backgroundColor = colors.card_color,
      statusBarStyle = null,
      statusBarHidden = false,

      isMobile,
      device,

      renderContent = null,
      renderRight = null
    } = this.props;

    return (
      <>
        <StatusBarStyle
          statusBarStyle={statusBarStyle ? statusBarStyle : "dark-content"}
        />
        <Wrapper
          style={{
            height: isIphoneX ? 125 : platform == "ios" ? 105 : 95,
            display: "flex",
            justifyContent: "center",
            ...style
          }}
        >
          <Wrapper
            style={{
              /*native*/
              paddingTop: isIphoneX ? 50 : platform == "ios" ? 30 : 25,
              ...style
            }}
          >
            {renderContent ? (
              renderContent()
            ) : (
              <Row>
                <Row
                  style={{
                    flex: 1,
                    paddingRight: isMobile ? 20 : 25,
                    paddingLeft: isMobile ? 20 : 25
                  }}
                >
                  <Wrapper
                    style={{
                      flex: 1
                    }}
                  >
                    {renderTitle ? renderTitle() : <Title>{title}</Title>}
                    {!!subtitle ? (
                      subtitle_loading ? (
                        <>
                          {isMobile ? (
                            <Copy
                              style={{
                                color: colors.orange_color,
                                fontSize: 12
                              }}
                            >
                              {subtitle_loading_text}
                            </Copy>
                          ) : (
                            <Spin
                              text={subtitle_loading_text}
                              color={colors.orange_color}
                              style={{ fontSize: 12 }}
                            />
                          )}
                        </>
                      ) : onSubtitlePress ? (
                        <Row>
                          <Button onPress={onSubtitlePress}>
                            <Copy
                              style={{
                                color: colors.orange_color,
                                fontSize: 12
                              }}
                            >
                              <Bold>{subtitle}</Bold>
                            </Copy>
                          </Button>
                        </Row>
                      ) : (
                        <Copy
                          style={{ color: colors.orange_color, fontSize: 12 }}
                        >
                          <Bold>{subtitle}</Bold>
                        </Copy>
                      )
                    ) : null}
                  </Wrapper>
                  {/*
                  <CircleButton
                    onPress={() => {}}
                    icon="notifications"
                    backgroundColor={colors.gray_selected_color}
                    borderColor={colors.gray_selected_color}
                    show_notification={false}
                    notification_color={colors.active_color}
                    notification_number={0}
                  />

                  <CircleButton
                    icon="person"
                    button_type="profile_pic"
                    user={this.props.user}
                    no_border={true}
                    show_notification={false}
                    notification_color={colors.active_color}
                    notification_icon={""}
                    style={{ marginRight: isMobile ? 15 : 5 }}
                  />
                  */}
                </Row>

                {renderRight ? renderRight() : null}

                {this.props.user && this.props.isMobile ? (
                  <CircleButton
                    style={{ margin: 0, marginLeft: 0, marginRight: 10 }}
                    tooltip={"Settings"}
                    icon="person"
                    button_type="profile_pic"
                    user={this.props.user}
                    show_notification={false}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "settings",
                        overlay: true
                      });
                    }}
                  />
                ) : null}
              </Row>
            )}
          </Wrapper>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, feature_toggle }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile, isIphoneX, platform } = native;
  const { colors, dark_mode } = settings;
  const { navbarBeta } = feature_toggle;
  return {
    token,
    user,
    device,
    isMobile,
    isIphoneX,
    platform,
    colors,
    dark_mode,
    navbarBeta
  };
};

export default connect(mapStateToProps, {
  toggleDrawer,
  mobileToggleDrawer,
  pushSidePanel
})(MainHeader);
