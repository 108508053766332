import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Card,
  Container,
  Copy,
  Row,
  Wrapper,
  Scroll,
  Bold
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  Carousel,
  ConfirmLabel,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  toTitleCase,
  popSidePanel,
  getLinkedCompanies,
  pushSidePanel,
  formatAddress,
  decodeIntToString
} from "app/NativeActions";

import NavigationService from "app/Router/NavigationService";

import CompanyPortfolio from "./CompanyPortfolio";
import CompanyPeople from "./CompanyPeople";
class Company extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      company_loading: false,
      company: panel_data?.company || null,
      property: panel_data?.property || null,
      expanded: panel_data?.expanded || false,
      propertyUpdated: panel_data?.propertyUpdated || null
    };
    this._scroll_view = React.createRef();

    this.scrollToSection = this.scrollToSection.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    if (this.props.device == "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }

    if (this.state.company) {
      this.getCompanyData(this.state.company);
    } else {
      const panel_id =
        this.props.device == "mobile"
          ? this.props?.route?.params?.panel_id
          : this.props.panel_id;

      if (!!panel_id) {
        this.getCompanyData(null, panel_id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    const prev_panel_data =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_data
        : prevProps.panel_data;
    const panel_id =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_id
        : this.props.panel_id;

    const prev_panel_id =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_id
        : prevProps.panel_id;
    if (
      (panel_data?.company &&
        panel_data?.company?.company_number !==
          prev_panel_data?.company?.company_number) ||
      panel_id != prev_panel_id
    ) {
      this.getCompanyData(panel_data?.company, panel_id);
    }
  }

  getCompanyData(company, company_id) {
    if (company || company_id) {
      let company_number = null;
      let jurisdiction_code = null;
      if (!company && company_id) {
        const decoded_company_id = decodeIntToString(company_id);
        company_number = decoded_company_id.split("+")[0];
        jurisdiction_code = decoded_company_id.split("+")[1];
      }

      this.props.getLinkedCompanies({
        token: this.props.token,
        company_number: company ? company?.company_number : company_number,
        jurisdiction_code: company
          ? company?.jurisdiction_code
          : jurisdiction_code,
        onLoading: () => {
          this.setState({
            company_loading: true
          });
        },
        onError: () => {
          this.setState({
            company_loading: false
          });
        },
        onSuccess: results => {
          this.setState(
            {
              company_loading: false,
              company:
                results?.companies?.length > 0 &&
                ((results.companies[0]?.company_number ==
                  this.state?.company?.company_number &&
                  results.companies[0]?.jurisdiction_code ==
                    this.state?.company?.jurisdiction_code) ||
                  !this.state?.company)
                  ? {
                      ...this.state.company,
                      ...results?.companies[0]
                    }
                  : this.state.company
            },
            () => {}
          );
        }
      });
    }
  }

  scrollToSection(section) {
    const { device } = this.props;

    if (
      device === "desktop" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.id
    ) {
      const elmnt = document.getElementById(section?.id);
      let top = this.state.expanded
        ? elmnt.offsetTop - 85
        : elmnt.offsetTop - 85;

      this._scroll_view.current.scroll({
        top,
        behavior: "smooth"
      });
    } else if (
      device === "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.offset
    ) {
      let y = section.offset - 15;
      this._scroll_view.current.scrollTo({
        x: 0,
        y: y,
        animated: true
      });
    }
  }

  render() {
    const { isMobile, device, colors } = this.props;
    const { company, property } = this.state;

    const formatted_property_address = formatAddress({
      address: {
        address: property?.property_address,
        address2: property?.property_address2,
        address_city: property?.property_address_city,
        address_state: property?.property_address_state,
        address_zip: property?.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"View Company"}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        {company ? (
          <Scroll style={{ flex: 1 }} scroll_ref={this._scroll_view}>
            <Wrapper
              id={"company-information-" + company?.company_number}
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;
                this.setState({
                  offset: y
                });
              }}
            >
              <SelectItem
                select_type={"icon"}
                select_icon={"business"}
                pressedIn={false}
                noPress={true}
                label={""}
                subtext={""}
                selected={true}
                renderContent={() => {
                  return (
                    <Wrapper>
                      <Row>
                        <Wrapper>
                          <Copy style={{ color: colors.actionable_text_color }}>
                            <Bold>{toTitleCase(company?.company_name)}</Bold>
                          </Copy>
                        </Wrapper>
                      </Row>
                    </Wrapper>
                  );
                }}
              />

              <CompanyPortfolio
                company_number={company?.company_number}
                jurisdiction_code={company?.jurisdiction_code}
                scrollToSection={this.scrollToSection}
              />
              <CompanyPeople
                officers={company?.officers}
                scrollToSection={this.scrollToSection}
                expanded={this.state.expanded}
                property={property}
                propertyUpdated={this.state.propertyUpdated}
              />
            </Wrapper>
          </Scroll>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLinkedCompanies
})(Company);
