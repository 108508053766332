import {
  ERROR_MESSAGE,
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  LOAD_MORE_TASKS,
  LOAD_MORE_TASKS_SUCCESS,
  GET_TASK,
  GET_TASK_FAIL,
  GET_TASK_SUCCESS,
  UPDATE_TASK,
  CLEAR_TASK_LIST,
  SET_ACTIVE_TASK_COUNT,
  LOAD_TASK_LIST_FAIL,
  LOAD_TASK_LIST_SUCCESS,
  UPDATE_TASK_FAIL,
  UPDATE_TASK_SUCCESS,
  SET_TASKS_PAGE,
  SET_TASKS_LIMIT,
  MARK_TASK_COMPLETE,
  REMOVE_TASK,
  CREATE_TASK,
  CREATE_TASK_FAIL,
  CREATE_TASK_SUCCESS,
  TRIGGER_LOGOUT,
  IS_LOADING,
  SET_TRACKING_EVENT,
  SAVE_EDIT_DEALS_FAIL,
  SUCCESS_MESSAGE,
  SELECT_ACTIVE_TASK,
  SELECT_ACTIVE_TASK_FAIL,
  SELECT_ACTIVE_TASK_SUCCESS,
  COMPLETE_TASK_SUCCESS,
  UNDO_COMPLETE_TASK_SUCCESS
} from "app/DealMachineCore/types";

import { AppConfig, appRedirect } from "app/NativeActions";
import { store } from "app/store";

import api from "app/DealMachineCore/apis/DealMachineAPIV2";

const dm_api = api.create();

export const selectActiveTask = ({ token, task, user_location }) => {
  return dispatch => {
    if (task) {
      dispatch({
        type: SELECT_ACTIVE_TASK,
        payload: task
      });
      dm_api
        .getTask({
          token,
          task_id: task.id,
          user_location
        })
        .then(response => {
          if (response.problem != null) {
            dispatch({
              type: SELECT_ACTIVE_TASK_FAIL,
              payload: response.problem
            });
          } else if (response.data.error != false) {
            dispatch({
              type: SELECT_ACTIVE_TASK_FAIL,
              payload: response.data.error
            });

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            dispatch({
              type: SELECT_ACTIVE_TASK_SUCCESS,
              payload: response.data.results
            });
          }
        });
    } else {
      dispatch({
        type: SELECT_ACTIVE_TASK_SUCCESS,
        payload: task
      });
    }
  };
};
export const loadListTask = ({ token, list_id, user_location }) => {
  return dispatch => {
    dispatch({ type: GET_TASK });
    dm_api
      .loadListTask({
        token,
        list_id,
        user_location
      })
      .then(response => {
        if (response.problem != null) {
          loadListTaskFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          loadListTaskFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          loadListTaskSuccess({
            dispatch,
            results: response.data.results
          });
        }
      });
  };
};
export const getTasks = ({
  token,
  type,
  begin,
  limit,
  sort_by,
  filter_by,
  filter_user
}) => {
  return dispatch => {
    switch (type) {
      default:
        dispatch({
          type: GET_TASKS,
          payload: {
            sort_by: sort_by,
            filter_by: filter_by,
            filter_user: filter_user
          }
        });
        break;

      case "load_more":
        dispatch({ type: LOAD_MORE_TASKS });
        break;
    }
    dm_api
      .getTasks({
        token,
        type,
        begin,
        limit,
        sort_by,
        filter_by,
        filter_user
      })
      .then(response => {
        if (response.problem != null) {
          getTasksFail({ dispatch, error: response.problem });
        } else if (response.data.error != false) {
          getTasksFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getTasksSuccess({
            dispatch,
            results: response.data.results,
            type
          });
        }
      });
  };
};

const loadListTaskFail = ({ dispatch, error }) => {
  dispatch({ type: LOAD_TASK_LIST_FAIL, payload: error });
};

const loadListTaskSuccess = ({ dispatch, results, type }) => {
  dispatch({ type: LOAD_TASK_LIST_SUCCESS, payload: results });
};
const getTasksFail = ({ dispatch, error }) => {
  dispatch({ type: GET_TASKS_FAIL, payload: error });
  /*
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
  */
};

const getTasksSuccess = ({ dispatch, results, type }) => {
  if (type === "load_more") {
    dispatch({ type: LOAD_MORE_TASKS_SUCCESS, payload: results });
  } else {
    dispatch({ type: GET_TASKS_SUCCESS, payload: results });
  }
};

export const createTask = ({
  token,
  type,
  title,
  assigned_user,
  date_due,
  house,
  notes,
  onLoading = null,
  onError = () => {},
  onSuccess = () => {},
  list_id
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: CREATE_TASK });

    dm_api
      .saveTask(
        token,
        type,
        title,
        assigned_user,
        date_due,
        house,
        notes,
        list_id
      )
      .then(response => {
        if (response.problem != null) {
          createTaskFail(dispatch, response.problem);
          onError();
        } else if (response.data.error != false) {
          createTaskFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
          onError();
        } else {
          dispatch({
            type: CREATE_TASK_SUCCESS,
            payload: response.data.results
          });
          dispatch({ type: IS_LOADING, payload: false });
          if (onSuccess) {
            onSuccess(response.data?.results);
          }

          if (!onLoading) {
            createTaskSuccess(dispatch, response);
          }
        }
      });
  };
};

const createTaskFail = (dispatch, error) => {
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const createTaskSuccess = dispatch => {
  //if bulk edit includes an enhanced search

  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "You've successfully created a new task.",
      title: "Success!"
    }
  });
};

export const completeTask = ({
  token,
  type = "complete",
  task_id,
  onLoading = null,
  onError = null,
  onSuccess = null,
  load_type
}) => {
  return dispatch => {
    if (load_type !== "soft" && !onLoading) {
      dispatch({ type: IS_LOADING, payload: true });
    } else if (onLoading) {
      onLoading();
    }
    dm_api.completeTask({ token, type, task_id }).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        completeTaskFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        completeTaskFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({
          type: COMPLETE_TASK_SUCCESS,
          payload: response.data.results
        });
        if (onSuccess) {
          onSuccess(response.data.results);
        } else {
          completeTaskSuccess(dispatch, response);
        }
      }
      dispatch({ type: IS_LOADING, payload: false });
    });
  };
};

const completeTaskFail = (dispatch, error) => {
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const completeTaskSuccess = dispatch => {
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "You've successfully completed this task.",
      title: "Success!"
    }
  });
};
export const clearTaskList = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_TASK_LIST
    });
  };
};
export const setActiveTaskCount = ({ count }) => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_TASK_COUNT,
      payload: {
        count: count
      }
    });
  };
};

export const getTaskCount = ({ token }) => {
  return dispatch => {
    dispatch({ type: GET_TASK });
    dm_api
      .getTaskCount({
        token
      })
      .then(response => {
        if (response.problem != null) {
        } else if (response.data.error != false) {
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getTaskCountSuccess({
            dispatch,
            results: response.data.results
          });
        }
      });
  };
};
const getTaskCountSuccess = ({ dispatch, results }) => {
  dispatch({ type: SET_ACTIVE_TASK_COUNT, payload: { count: results } });
};

export const clearActiveTask = () => {
  return dispatch => {
    dispatch({
      type: GET_TASK_SUCCESS,
      payload: {}
    });
  };
};
export const getTask = ({
  token,
  task_id,
  user_location,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    dispatch({ type: GET_TASK });
    if (onLoading) {
      onLoading();
    }

    dm_api
      .getTask({
        token,
        task_id,
        user_location: user_location
      })
      .then(response => {
        if (response.problem != null) {
          getTaskFail({ dispatch, error: response.problem });

          if (onError) {
            onError();
          }
        } else if (response.data.error != false) {
          getTaskFail({ dispatch, error: response.data.error });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }

          if (onError) {
            onError();
          }
        } else {
          getTaskSuccess({
            dispatch,
            results: response.data.results
          });

          if (onSuccess) {
            onSuccess(response.data.results);
          }
        }
      });
  };
};

const getTaskFail = ({ dispatch, error }) => {
  dispatch({ type: GET_TASK_FAIL, payload: error });
  /*
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
  */
};

const getTaskSuccess = ({ dispatch, results }) => {
  dispatch({ type: GET_TASK_SUCCESS, payload: results });
};

export const editTask = ({
  token,
  task_id,
  type,
  title,
  assigned_user,
  date_due,
  house,
  notes,
  assignee_notes,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({ type: UPDATE_TASK });

    dm_api
      .editTask(
        token,
        task_id,
        type,
        title,
        assigned_user,
        date_due,
        house,
        assignee_notes,
        notes
      )
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          editTaskFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          }
          editTaskFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: UPDATE_TASK_SUCCESS,
            payload: response.data.results
          });
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            editTaskSuccess(dispatch, response);
          }
        }
        dispatch({ type: IS_LOADING, payload: false });
      });
  };
};

const editTaskFail = (dispatch, error) => {
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const editTaskSuccess = dispatch => {
  //if bulk edit includes an enhanced search

  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "You've successfully updated this task.",
      title: "Success!"
    }
  });
};

export const deleteTask = ({
  token,
  task_id,
  type = "remove",
  onSuccess = null,
  onLoading = null,
  onError = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    dm_api.editTask(token, task_id, (type = "remove")).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        deleteTaskFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        deleteTaskFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({
          type: REMOVE_TASK,
          payload: {
            active_task_count: response?.data?.results?.active_task_count,
            task_id
          }
        });
        if (onSuccess) {
          onSuccess();
        } else {
          deleteTaskSuccess(dispatch, response);
        }
      }
    });
  };
};

const deleteTaskFail = (dispatch, error) => {
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const deleteTaskSuccess = dispatch => {
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "You've successfully deleted this task.",
      title: "Success!"
    }
  });
};
