import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { PrimaryCTA } from "app/NativeComponents/website/common";

const SignUpButtons = props => {
  const token = window.localStorage.getItem("token");

  if (token != null) {
    return (
      <span>
        <span className="dm-website-ctas">
          <PrimaryCTA to={"/dashboard"}>My Dashboard</PrimaryCTA>
        </span>
      </span>
    );
  }

  return (
    <span>
      <Link to={"/login"}>Log in</Link>
      <span className="dm-website-ctas">
        <PrimaryCTA to={"/sign-up"}>Sign Up</PrimaryCTA>
      </span>
    </span>
  );
};

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;

  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {})(SignUpButtons);
