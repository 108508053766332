import React, { Component } from "react";
import { Card } from "app/NativeComponents/common";
import { InputBox, CircleButton } from "app/NativeComponents/snippets";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false
    };

    this._new_password_input = React.createRef();
    this._new_password_confirm_input = React.createRef();
  }

  render() {
    const { editUser, loading } = this.props;
    return (
      <Card>
        <InputBox
          input_ref={this._new_password_input}
          name="new_password"
          disabled={loading}
          returnKeyType="next"
          placeholder="Password"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "new_password", value })
          }
          onSubmitEditing={() => {
            this._new_password_confirm_input.current.focus();
          }}
          blurOnSubmit={false}
          value={editUser.new_password}
          secureTextEntry
          type="password"
        />
        <InputBox
          input_ref={this._new_password_confirm_input}
          name="new_password_confirm"
          disabled={loading}
          returnKeyType="next"
          placeholder="Confirm Password"
          onChange={value =>
            this.props.updateUserFieldChange({
              prop: "new_password_confirm",
              value
            })
          }
          onSubmitEditing={() => {
            this.props.saveUserInfo();
          }}
          blurOnSubmit={false}
          value={editUser.new_password_confirm}
          secureTextEntry
          type="password"
        />
      </Card>
    );
  }
}

export default Inputs;
