import React, { Component } from "react";

import { Wrapper, Row, Copy, Bold, Icon } from "app/NativeComponents/common";

import { renderPrice, numberWithCommas } from "app/NativeActions";
import { store } from "app/store";

class SkipTraceInvoice extends Component {
  render() {
    const { estimated_invoice_info, colors } = this.props;
    const device = store.getState().native.device;

    if (
      estimated_invoice_info &&
      estimated_invoice_info.invoice_type == "skip_trace"
    ) {
      const {
        estimated_invoice_info: {
          mailer_campaign,
          number_of_leads,
          skip_trace_item,
          skip_trace_price,
          total_price
        }
      } = this.props;
      return (
        <>
          <Wrapper
            style={{
              padding: 25
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                <Row>
                  <Copy>
                    <Bold>{skip_trace_item}</Bold>
                  </Copy>
                  {/*
                  <Copy
                    style={{
                      fontSize: 12,
                      marginLeft: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 20,
                      backgroundColor: colors.background_color
                    }}
                  >
                    {skip_trace_item}
                  </Copy>
                  */}
                </Row>
                <Copy>
                  {number_of_leads > 1
                    ? numberWithCommas(number_of_leads) + " leads "
                    : "1 lead"}

                  {" x " + renderPrice(skip_trace_price)}
                </Copy>
              </Wrapper>
              <Wrapper>
                <Copy>{renderPrice(skip_trace_price * number_of_leads)}</Copy>
              </Wrapper>
            </Row>
          </Wrapper>

          <Wrapper style={{ padding: 25, paddingTop: 0 }}>
            <Row>
              <Wrapper style={{ flex: 1, paddingRight: 25 }}>
                <Row>
                  <Copy>
                    <Bold>Estimated total:</Bold>
                  </Copy>
                  {device == "desktop" ? (
                    <Wrapper
                      style={{ marginLeft: 10 }}
                      tooltip="Total subject to change. You will not be charged for leads that fail a skip trace or leads that have already been skip traced."
                    >
                      <Icon icon="help" size={18} />
                    </Wrapper>
                  ) : null}
                </Row>
              </Wrapper>
              <Wrapper>
                <Copy>
                  <Bold>{renderPrice(total_price)}</Bold>
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>
        </>
      );
    }

    return null;
  }
}

export default SkipTraceInvoice;
