import React, { PureComponent } from "react";
import { Wrapper, Row, Copy, Spin, Icon } from "app/NativeComponents/common";
import { InlineButton } from "app/NativeComponents/snippets";
import LeadStatusButton from "app/DealMachineCore/reuseable/LeadStatusButton";
import { getLeadStatusColor } from "app/NativeActions";
class LeadStatusButtonWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      temp_value: null
    };
  }
  render() {
    const { property, colors, section, row_height } = this.props;
    const { loading, temp_value } = this.state;
    return (
      <LeadStatusButton
        popoverPlacement="right"
        onLoading={({ loading, temp_value }) => {
          this.setState({
            loading,
            temp_value
          });
        }}
        renderComponent={({ pressedIn, hovering }) => {
          return (
            <Wrapper
              style={{
                position: "relative",
                width: section.width
              }}
            >
              <Wrapper
                style={{
                  overflow: "hidden",
                  width: section.width,

                  whiteSpace: "nowrap"
                }}
              >
                <Wrapper
                  style={{
                    padding: 10,
                    paddingLeft: 10,
                    height: row_height,
                    justifyContent: "center"
                  }}
                >
                  <InlineButton
                    pressedIn={pressedIn || hovering}
                    loading={loading}
                    noPress={true}
                    style={{ margin: 0 }}
                    icon_right="keyboard-arrow-down"
                  >
                    {loading ? temp_value : property.deal.deal_status_title}
                  </InlineButton>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          );
        }}
        property={property}
      />
    );
  }
}

export default LeadStatusButtonWrapper;
