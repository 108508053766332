import React, { Component } from "react";
import { connect } from "react-redux";

import { SelectItem, IconButton } from "app/NativeComponents/snippets";

import { getDeviceLocation, renderDate } from "app/NativeActions";

class TaskItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }
  render() {
    const { item } = this.props;

    return (
      <SelectItem
        key={"task_" + item.id}
        innerStyle={{ paddingLeft: 15 }}
        icon="keyboard-arrow-right"
        select_tooltip={
          item.status == "Active" ? "Complete Task" : "Undo Complete"
        }
        select_type="custom"
        renderCustomSelect={() => {
          return (
            <IconButton
              icon={
                item.status == "Active"
                  ? "check-box-outline-blank"
                  : "check-box"
              }
              tooltip={
                item.status == "Active" ? "Complete Task" : "Undo Complete"
              }
              innerStyle={
                item.status != "Active"
                  ? { textDecoration: "line-through" }
                  : {}
              }
              tooltipPlacement="top"
              loading={this.state.loading}
              onPress={() => {
                this.props.completeTask({
                  token: this.props.token,
                  type: item.status == "Active" ? "complete" : "undo_complete",
                  task_id: item.id,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                  }
                });
              }}
            />
          );
        }}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "edit_task",
            id: item?.id,
            overlay: true,
            data: {
              task: item
            }
          });
        }}
        subtext={renderDate(item.date_created)}
        description={item.assigned_first_name + " " + item.assigned_last_name}
      >
        {item.title}
      </SelectItem>
    );
  }
}

export default TaskItem;
