import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import axios from "axios";
import { GhostButton } from "app/NativeComponents/snippets";
const GoogleOAuth = ({
  onSuccess,
  style,
  innerStyle,
  buttonText = "Login with Google"
}) => {
  const loginFailure = response => {};

  const loginSuccess = response => {
    if (typeof onSuccess === "function") {
      onSuccess(response);
    }
  };
  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
        })
        .then(res => res.data);
      let oauth = tokenResponse;
      oauth.accessToken = tokenResponse.access_token;
      oauth.profileObj = userInfo;
      loginSuccess(oauth);
    }
  });
  return (
    <div
      className={"deal-oauth"}
      style={{
        ...style
      }}
    >
      <GhostButton
        fa_icon={"google"}
        onPress={login}
        innerStyle={{ borderRadius: 5 }}
        style={{ ...innerStyle }}
        button_type={"full"}
      >
        {buttonText}
      </GhostButton>
    </div>
  );
};

export { GoogleOAuth };
