import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  PillButton,
  SearchBar,
  SmallHeader,
  SpinWrapper,
  SmallLabel,
  SelectItem
} from "app/NativeComponents/snippets";
import { getWorkflowInfo } from "app/NativeActions";

class SelectStepType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search_types: "",
      selected_type: null
    };
  }

  componentDidMount() {
    const { workflow } = this.props;
    this.props.getWorkflowInfo({
      token: this.props.token,
      type: "workflow_step_types",
      category: workflow.category
    });
  }

  checkIfNeedsToSave() {
    const { selected_type } = this.state;

    if (selected_type) {
      return true;
    }

    return false;
  }

  filterStepTypes(types) {
    const { search_types } = this.state;
    return types.filter(t => {
      if (t.title?.toLowerCase().indexOf(search_types?.toLowerCase()) != -1) {
        return true;
      } else if (
        t.category_title?.toLowerCase().indexOf(search_types?.toLowerCase()) !=
        -1
      ) {
        return true;
      }
      return false;
    });
  }

  filterStepTypeCategories(categories) {
    const { search_types } = this.state;
    return categories.filter(c => {
      if (c.title?.toLowerCase().indexOf(search_types?.toLowerCase()) != -1) {
        return true;
      } else {
        for (let i = 0; i < c.items.length; i++) {
          if (
            c.items[i].title
              .toLowerCase()
              .indexOf(search_types?.toLowerCase()) != -1
          ) {
            return true;
          }
        }
      }

      return false;
    });
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      add_step_type,
      workflow,
      workflow_step_types,
      workflow_step_types_loading
    } = this.props;
    const { selected_type } = this.state;
    return (
      <>
        <SmallHeader
          title="Create A New Step"
          leftButton={{
            icon: "close",
            onPress: () => {
              this.props.setNewStepType();
            }
          }}
        />

        <SearchBar
          style={{ margin: 10 }}
          title="Search step types..."
          onChange={value =>
            this.setState({
              search_types: value,
              selected_type: null
            })
          }
          value={this.state.search_types}
        />

        <KeyboardView style={{ flex: 1 }}>
          {workflow_step_types_loading ? (
            <SpinWrapper text="Loading options..." />
          ) : (
            <>
              <>
                {this.filterStepTypeCategories(workflow_step_types).map(
                  (category, i) => {
                    return (
                      <Wrapper key={"category_" + i}>
                        <SmallLabel>{category.title}</SmallLabel>
                        <>
                          {this.filterStepTypes(category.items).map(
                            (step_type, i) => {
                              const is_selected =
                                selected_type?.slug == step_type.slug;
                              return (
                                <SelectItem
                                  key={"trigger_" + i}
                                  select_type={"none"}
                                  selected={is_selected}
                                  className={
                                    is_selected
                                      ? "dm-white-hover-button active"
                                      : "dm-white-hover-button"
                                  }
                                  icon={"keyboard-arrow-right"}
                                  onPress={() => {
                                    this.setState({
                                      selected_type: step_type
                                    });
                                  }}
                                  confirm_text="Confirm"
                                  require_confirm={true}
                                  onConfirm={() => {
                                    switch (selected_type.slug) {
                                      default:
                                        this.props.addStep({
                                          step: {
                                            id:
                                              "new_step_" +
                                              (workflow.workflow_steps.length +
                                                1),
                                            type: selected_type.slug,
                                            data: null
                                          },
                                          step_index: add_step_type.step_index,
                                          branch_index:
                                            add_step_type.branch_index,
                                          branch_step_index:
                                            add_step_type.branch_step_index
                                        });
                                        break;

                                      case "skip_trace":
                                        this.props.addStep({
                                          step: {
                                            id:
                                              "new_step_" +
                                              (workflow.workflow_steps.length +
                                                1),
                                            type: selected_type.slug,
                                            data: {
                                              continue_if_skip_trace_fails: true
                                            }
                                          },
                                          step_index: add_step_type.step_index,
                                          branch_index:
                                            add_step_type.branch_index,
                                          branch_step_index:
                                            add_step_type.branch_step_index
                                        });
                                        break;

                                      case "pause_campaign":
                                        this.props.addStep({
                                          step: {
                                            id:
                                              "new_step_" +
                                              (workflow.workflow_steps.length +
                                                1),
                                            type: selected_type.slug,
                                            data: {
                                              pause_campaign: true
                                            }
                                          },
                                          step_index: add_step_type.step_index,
                                          branch_index:
                                            add_step_type.branch_index,
                                          branch_step_index:
                                            add_step_type.branch_step_index
                                        });
                                        break;

                                      case "end_campaign":
                                        this.props.addStep({
                                          step: {
                                            id:
                                              "new_step_" +
                                              (workflow.workflow_steps.length +
                                                1),
                                            type: selected_type.slug,
                                            data: {
                                              end_campaign: true
                                            }
                                          },
                                          step_index: add_step_type.step_index,
                                          branch_index:
                                            add_step_type.branch_index,
                                          branch_step_index:
                                            add_step_type.branch_step_index
                                        });
                                        break;

                                      case "filter_branch":
                                        this.props.addStep({
                                          step: {
                                            id:
                                              "new_step_" +
                                              (workflow.workflow_steps.length +
                                                1),
                                            type: selected_type.slug,
                                            data: [
                                              {
                                                branch_name: "Branch 1",
                                                filters: [],
                                                steps: []
                                              },
                                              {
                                                branch_name: "None met",
                                                else_branch: true,
                                                filters: [],
                                                steps: []
                                              }
                                            ]
                                          },
                                          step_index: add_step_type.step_index,
                                          branch_index:
                                            add_step_type.branch_index,
                                          branch_step_index:
                                            add_step_type.branch_step_index
                                        });
                                        break;
                                    }
                                  }}
                                >
                                  {step_type.title}
                                </SelectItem>
                              );
                            }
                          )}
                        </>
                      </Wrapper>
                    );
                  }
                )}
              </>
            </>
          )}

          <Wrapper style={{ height: isMobile ? 200 : 0 }} />
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ workflow }) => {
  const { workflow_step_types, workflow_step_types_loading } = workflow;
  return {
    workflow_step_types,
    workflow_step_types_loading
  };
};

export default connect(mapStateToProps, {
  getWorkflowInfo
})(SelectStepType);
