import React, { Component } from "react";
import { Wrapper, Copy } from "app/NativeComponents/common";
class Inkfree extends Component {
  render() {
    const { template_type, show_text_safe, colors, device } = this.props;
    let width = 315;
    let height = 228;
    switch (template_type) {
      case "postcard":
      default:
        width = 315;
        height = 228;
        break;

      case "postcard6x9":
      case "postcard6x11":
        width = 315;
        height = 228;
        break;
      case "handwritten":
        width = 600;
        height = 434;
        break;
    }

    return (
      <Wrapper
        style={
          template_type == "handwritten"
            ? {
                position: "absolute",
                width,
                height,
                top: 0,
                left: 0,
                backgroundColor: "transparent",
                zIndex: 999999999
              }
            : {
                position: "absolute",
                width,
                height,
                right: 27,
                bottom: 24,
                backgroundColor: "#fff",
                zIndex: 999999999
              }
        }
      >
        <Wrapper>
          <Wrapper
            style={{ alignItems: "space-between", flexDirection: "row" }}
          >
            <Wrapper
              style={
                template_type == "handwritten"
                  ? {
                      flex: 1,
                      float: "left",
                      marginTop: 45,
                      marginLeft: 30,
                      marginRight: 400
                    }
                  : {
                      flex: 1,
                      float: "left",
                      marginTop: 19,
                      marginLeft: 10
                    }
              }
            >
              <Copy
                style={{
                  fontSize: 10,
                  fontFamily: template_type == "handwritten" ? "Rose" : null,
                  color: template_type == "handwritten"? "#003DDC" : "#000",
                  lineHeight: device == "mobile" ? 12 : "12px",
                  textTransform: template_type == "handwritten" ? "capitalize" : "uppercase"
                }}
              >
                {this.props.renderBracketVariables("{{signature_name}}")}
              </Copy>
              <Copy
                style={{
                  fontSize: 10,
                  fontFamily: template_type == "handwritten" ? "Rose" : null,
                  color: template_type == "handwritten"? "#003DDC" : "#000",
                  lineHeight: device == "mobile" ? 12 : "12px",
                  textTransform: template_type == "handwritten" ? "capitalize" : "uppercase"
                }}
              >
                {this.props.renderBracketVariables(
                  "{{return_address}} {{return_address2}}"
                )}
              </Copy>
              <Copy
                style={{
                  fontSize: 10,
                  fontFamily: template_type == "handwritten" ? "Rose" : null,
                  color: template_type == "handwritten"? "#003DDC" : "#000",
                  lineHeight: device == "mobile" ? 12 : "12px",
                  textTransform: template_type == "handwritten" ? "capitalize" : "uppercase"
                }}
              >
                {this.props.renderBracketVariables(
                  "{{return_address_city}}, {{return_address_state}} {{return_address_zip}}"
                )}
              </Copy>
            </Wrapper>
            {template_type !== "handwritten" ? (
              <Wrapper
                style={{
                  float: "right",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#000",
                  width: 76,
                  height: 67,
                  marginRight: 10,
                  marginLeft: 29,
                  marginTop: 10,
                  backgroundColor: "transparent"
                }}
              >
                <Copy
                  style={{
                    marginTop: 19,
                    marginBottom: 19,
                    lineHeight: device == "mobile" ? 14 : "14px",
                    textAlign: "center",
                    color: "#000",
                    textTransform: "uppercase",
                    fontSize: 14
                  }}
                >
                  Postage Indicia
                </Copy>
              </Wrapper>
            ) : null}
          </Wrapper>
        </Wrapper>
        <Wrapper
          style={
            template_type == "handwritten"
              ? {
                  marginTop: 110,
                  marginLeft: 200,
                  marginRight: 200
                }
              : { marginTop: 29, marginLeft: 10 }
          }
        >
          <Copy
            style={{
              fontSize: 16,
              fontFamily: template_type == "handwritten" ? "Rose" : null,
              color: template_type == "handwritten"? "#003DDC" : "#000",
              lineHeight: device == "mobile" ? 17 : "17px",
              textTransform: template_type == "handwritten" ? "capitalize" : "uppercase"
            }}
          >
            {this.props.renderBracketVariables("{{owner_name}}")}
          </Copy>
          <Copy
            style={{
              fontSize: 16,
              fontFamily: template_type == "handwritten" ? "Rose" : null,
              color: template_type == "handwritten"? "#003DDC" : "#000",
              lineHeight: device == "mobile" ? 17 : "17px",
              textTransform: template_type == "handwritten" ? "capitalize" : "uppercase"
            }}
          >
            {this.props.renderBracketVariables(
              "{{owner_address}} {{owner_address2}}"
            )}
          </Copy>
          <Copy
            style={{
              fontSize: 16,
              fontFamily: template_type == "handwritten" ? "Rose" : null,
              color: template_type == "handwritten"? "#003DDC" : "#000",
              lineHeight: device == "mobile" ? 17 : "17px",
              textTransform: template_type == "handwritten" ? "capitalize" : "uppercase"
            }}
          >
            {this.props.renderBracketVariables(
              "{{owner_address_city}}, {{owner_address_state}} {{owner_address_zip}}"
            )}
          </Copy>
        </Wrapper>

        {show_text_safe ? (
          <Wrapper
            className={"striped-error-background"}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              backgroundColor: colors.error_color,
              opacity: 0.75,
              pointerEvents: "none",
              zIndex: 999999999
            }}
          />
        ) : null}
      </Wrapper>
    );
  }
}

export default Inkfree;
