import React from "react";
import {
  Button,
  CardBody,
  Split,
  Wrapper,
  Stretch,
  Title,
  Copy,
  Icon,
  CenterCenter
} from "app/NativeComponents/common";

const renderIcon = ({ value }) => {
  if (value) {
    return <Icon style={{ marginLeft: 10 }} icon="radio-button-checked" />;
  } else {
    return <Icon style={{ marginLeft: 10 }} icon="radio-button-unchecked" />;
  }
};

const renderCopy = (componentView, text) => {
  if (componentView) {
    return componentView();
  } else if (text && text != "") {
    return <Copy>{text}</Copy>;
  }
};

const RadioButton = ({
  style,
  title,
  text,
  componentView,
  onPress,
  value,
  disabled = false
}) => {
  if (disabled) {
    return (
      <CardBody>
        <Split>
          <Stretch>
            <Title>{title}</Title>
            {renderCopy(componentView, text)}
          </Stretch>

          <Wrapper
            style={{
              marginLeft: 10
            }}
          >
            <CenterCenter>{renderIcon({ value })}</CenterCenter>
          </Wrapper>
        </Split>
      </CardBody>
    );
  }
  return (
    <Button style={style} onPress={onPress}>
      <CardBody>
        <Split>
          <Stretch>
            <Title>{title}</Title>
            {renderCopy(componentView, text)}
          </Stretch>

          <Wrapper
            style={{
              marginLeft: 10
            }}
          >
            <CenterCenter>{renderIcon({ value })}</CenterCenter>
          </Wrapper>
        </Split>
      </CardBody>
    </Button>
  );
};

export { RadioButton };
