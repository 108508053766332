import React, { Component } from "react";

import Scene1 from "./Scene1";
import Scene2 from "./Scene2";

class FindTheOwner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 1
    };
  }

  componentDidMount() {
    this.startScene();
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);
    this._interval = setInterval(() => {
      var scene = this.state.scene;
      if (scene > 4) {
        scene = 0;
      } else {
        scene = scene + 1;
        this.setState({ scene: scene });
      }

      if (scene == 0) {
        clearInterval(this._interval);
        this._interval = setTimeout(() => {
          this.setState({ scene: 0 });
          clearInterval(this._interval);
          this._interval = setTimeout(() => {
            this.setState({ scene: 1 });
            this.startScene();
          }, 100);
        }, 2000);
      }
    }, 750);
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 280,
          textAlign: "left"
        }}
      >
        <Scene1 scene={this.state.scene} />
        <Scene2 scene={this.state.scene} />
      </div>
    );
  }
}

export default FindTheOwner;
