import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class TaxInformation extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, offset: 0 };
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    const { property, search, colors } = this.props;

    return (
      <>
        {"Tax Information".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Tax delinquent".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Tax delinquent year".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Tax year".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Tax amount".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Assessment year".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Total assessed value".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Assessed land value".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Assessed improvement value"
          .toLowerCase()
          .indexOf(search?.toLowerCase()) !== -1 ||
        "Total market value".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Market land value".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Market improvement value"
          .toLowerCase()
          .indexOf(search?.toLowerCase()) !== -1 ||
        !search ? (
          <Card
            id={"tax-information"}
            label={"Tax Information"}
            style={{ paddingTop: 10, marginTop: 15 }}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y
              });
            }}
          >
            <Row
              style={
                this.props.expanded
                  ? {
                      flexDirection: "row",
                      alignItems: "stretch",
                      flexWrap: "wrap"
                    }
                  : { flexDirection: "column", alignItems: "stretch" }
              }
            >
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.TaxDelinquent}
                label={"Tax delinquent?"}
                hiddenFromNonSubs={true}
                helper={"tax_delinquent_info"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.TaxDelinquentYear}
                label={"Tax delinquent year:"}
                hiddenFromNonSubs={true}
                helper={"tax_delinquent_year"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.TaxYear}
                label={"Tax year:"}
                hiddenFromNonSubs={true}
                helper={"tax_year"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.TaxAmt}
                label={"Tax amount:"}
                format={"money"}
                hiddenFromNonSubs={true}
                helper={"tax_amount"}
              />

              {this.state.more_info || !!search ? (
                <>
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.AssdYear}
                    label={"Assessment year:"}
                    hiddenFromNonSubs={true}
                    helper={"assessment_year"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.AssdTotalValue}
                    label={"Total assessed value:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"total_assessed_value"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.AssdLandValue}
                    label={"Assessed land value:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"assessed_land_value"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.AssdImprovementValue}
                    label={"Assessed improvement value:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"assessed_improvement_value"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.calculated_total_value}
                    label={"Total market value:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"total_market_value"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.calculated_land_value}
                    label={"Market land value:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"market_land_value"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.calculated_improvement_value}
                    label={"Market improvement value:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"market_improvement_value"}
                  />
                </>
              ) : null}
            </Row>
            {!search && this.state.more_info ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: false
                    },
                    () => {
                      if (this.props.device === "desktop") {
                        this.props.scrollToSection({
                          id: "tax-information",
                          offset: this.state.offset
                        });
                      } else {
                        this._interval = setTimeout(() => {
                          this.props.scrollToSection({
                            id: "tax-information",
                            offset: this.state.offset
                          });
                        }, 50);
                      }
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-up"}
              >
                Less Info
              </InlineButton>
            ) : !search ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: true
                    },
                    () => {
                      this.props.scrollToSection({
                        id: "tax-information",
                        offset: this.state.offset
                      });
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-down"}
              >
                More Info
              </InlineButton>
            ) : null}
          </Card>
        ) : null}
      </>
    );
  }
}

export default TaxInformation;
