import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Card,
  CardBody,
  Icon,
  Wrapper,
  Title,
  Copy,
  Bold,
  Animation,
  Stretch,
  Split,
  Row,
  Spin
} from "app/NativeComponents/common";

class LoadingScreen extends PureComponent {
  renderText(text, title) {
    return (
      <Wrapper>
        <Copy style={{ color: this.props.colors.actionable_text_color }}>
          <Bold>{title && title != "" ? title : "Loading..."}</Bold>
        </Copy>
        <Copy>
          {text && text != ""
            ? text
            : "Please be patient. This can take a few seconds."}
        </Copy>
      </Wrapper>
    );
  }

  render() {
    const { size, colors, text, title, device } = this.props;
    return (
      <Animation
        style={
          device == "desktop"
            ? {
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.75)",
                zIndex: 4
              }
            : {
                backgroundColor: "rgba(0,0,0,0.75)",
                flex: 1,
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                elevation: 6
              }
        }
        type="fadeIn"
        duration={500}
      >
        <Wrapper
          style={
            device == "desktop"
              ? {
                  backgroundColor: "transparent",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  maxWidth: 500,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingBottom: this.props.isIphoneX ? 15 : 5
                }
              : {
                  backgroundColor: "rgba(0,0,0,0.05)",
                  flex: 1,
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  elevation: 6,
                  paddingBottom: this.props.isIphoneX ? 15 : 5
                }
          }
        >
          <Animation
            style={{ alignSelf: "stretch" }}
            type="fadeInUp"
            duration={500}
          >
            <Wrapper
              style={{
                backgroundColor: this.props.colors.card_color,
                borderRadius: 5,
                margin: 10
              }}
            >
              <Row>
                <Stretch>
                  <CardBody
                    style={{
                      padding: 25
                    }}
                  >
                    <Row>
                      <Wrapper
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 10
                        }}
                      >
                        <Spin size="small" />
                      </Wrapper>
                      <Stretch>{this.renderText(text, title)}</Stretch>
                    </Row>
                  </CardBody>
                </Stretch>
              </Row>
            </Wrapper>
          </Animation>
        </Wrapper>
      </Animation>
    );
  }
}

const mapStateToProps = ({ native, settings }) => {
  const { device, isIphoneX } = native;
  const { colors } = settings;

  return { device, isIphoneX, colors };
};

export default connect(mapStateToProps, {})(LoadingScreen);
