import React, { Component } from "react";

import { Copy, Title, Row, Bold, Wrapper } from "app/NativeComponents/common";
import { SelectItem, GhostButton } from "app/NativeComponents/snippets";
import Plan from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/Plan";

import { renderPrice } from "app/NativeActions";
import { store } from "app/store";
class DisplayPlans extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const colors = store.getState().settings.colors;
    const desktopMobile = store.getState().native.desktopMobile;
    const device = store.getState().native.device;
    const platform = store.getState().native.platform;
    const signup_funnel = store.getState().marketing.signup_funnel;
    const token = store.getState().auth.token;

    const {
      contentful_content,
      selected_list_builder,
      needs_lists,
      plan_interval
    } = this.props;

    return (
      <Wrapper style={{ alignSelf: "stretch" }}>
        <Row
          style={
            !desktopMobile
              ? {
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap"
                }
              : {
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }
          }
        >
          {contentful_content?.contentItems &&
            contentful_content?.contentItems.map((item, i) => {
              const plan = item?.fields;
              if (
                (desktopMobile &&
                  this.props.selected_stripe_id &&
                  ((plan_interval === "year" &&
                    plan.stripePriceIdYearly !==
                      this.props.selected_stripe_id) ||
                    (plan_interval === "month" &&
                      plan.stripePriceIdMonthly !==
                        this.props.selected_stripe_id))) ||
                (this.props.no_free && plan.monthPrice == 0)
              ) {
                return null;
              }
              if (plan?.featured && desktopMobile) {
                return (
                  <>
                    <Plan
                      key={"plan_" + i}
                      id={item?.sys?.id}
                      platform={this.props.platform}
                      colors={colors}
                      selected_list_builder={selected_list_builder}
                      desktopMobile={desktopMobile}
                      device={device}
                      marketing_slug={plan?.marketing_event_slug}
                      token={token}
                      title={plan?.title}
                      stripe_price_id_month={plan?.stripePriceIdMonthly}
                      stripe_price_id_year={plan?.stripePriceIdYearly}
                      featured={plan?.featured}
                      price={plan?.monthPrice}
                      year_price={plan?.yearPrice}
                      plan_interval={plan_interval}
                      buttonText={plan?.buttonText}
                      includes_items={
                        plan?.includesItems ? plan.includesItems : null
                      }
                      description={plan?.description}
                      subtext={plan?.subtext}
                      signup_funnel={this.props.signup_funnel}
                      onPurchase={this.props.onPurchase}
                      ios_plan_id={plan?.ios_plan_id}
                      offer_promo={plan?.promo}
                      free_trial_days={plan?.free_trial_days}
                      no_trial={plan?.no_trial}
                      addons={plan?.addons}
                      onPlanSelection={this.props.onPlanSelection}
                      needs_dialer={this.props.needs_dialer}
                      selected_stripe_id={this.props.selected_stripe_id}
                      selected_stripe_id_year={
                        this.props.selected_stripe_id_year
                      }
                      selected_plan={
                        plan_interval === "year"
                          ? plan.stripePriceIdYearly ===
                            this.props.selected_stripe_id_year
                            ? true
                            : false
                          : plan.stripePriceIdMonthly ===
                            this.props.selected_stripe_id
                      }
                      annual_toggle_text={this.props.annual_toggle_text}
                      hide_annual_toggle={this.props.hide_annual_toggle}
                      onTogglePlanInterval={this.props.onTogglePlanInterval}
                      selectProPlan={this.props.selectProPlan}
                      manageSubscription={this.props.manageSubscription}
                      startRedirect={this.props.startRedirect}
                      prompt={this.props.prompt}
                      popSidePanel={this.props.popSidePanel}
                    />
                    {desktopMobile &&
                    (platform !== "ios" || signup_funnel !== "use_iap") ? (
                      <>
                        {contentful_content?.contentItems2 &&
                          contentful_content?.contentItems2.map((item, i) => {
                            const plan = item?.fields;
                            const includes_items = plan?.includesItems;

                            return (
                              <Wrapper
                                style={{
                                  maxWidth: 700,
                                  margin: device == "desktop" ? "0px auto" : 0,
                                  alignSelf: "stretch",
                                  padding: desktopMobile ? 5 : 25
                                }}
                              >
                                <SelectItem
                                  onPress={() => {
                                    this.props.onSelectListBuilder(
                                      !selected_list_builder
                                    );
                                  }}
                                  marketing_slug={
                                    !selected_list_builder
                                      ? "selected_list_builder_pre_checkout"
                                      : "unselected_list_builder_precheckout"
                                  }
                                  selected={selected_list_builder}
                                  description={plan?.description}
                                  subtext={
                                    needs_lists && selected_list_builder
                                      ? "We've selected this for you based on what you're looking for!"
                                      : ""
                                  }
                                  selected_background_color={
                                    selected_list_builder
                                      ? colors.success_color_muted
                                      : colors.orange_color_muted
                                  }
                                  style={
                                    !selected_list_builder
                                      ? {
                                          backgroundColor:
                                            colors.orange_color_muted
                                        }
                                      : {
                                          backgroundColor:
                                            colors.success_color_muted
                                        }
                                  }
                                  renderRight={() => {
                                    return (
                                      <Row style={{ padding: 25 }}>
                                        <Title style={{ fontSize: 36 }}>
                                          <Bold>
                                            {plan_interval == "year"
                                              ? renderPrice(
                                                  plan.yearPrice / 12,
                                                  "no_decimal"
                                                )
                                              : renderPrice(
                                                  plan.monthPrice,
                                                  "no_decimal"
                                                )}
                                          </Bold>
                                        </Title>
                                        <Row style={{ alignItems: "flex-end" }}>
                                          <Copy style={{ marginLeft: 5 }}>
                                            {" / mo"}
                                          </Copy>
                                          {plan_interval == "year" ? (
                                            <Copy
                                              style={{
                                                marginLeft: 5,
                                                color: colors.light_text_color
                                              }}
                                            >
                                              {" - billed annually"}
                                            </Copy>
                                          ) : null}
                                        </Row>
                                      </Row>
                                    );
                                  }}
                                  renderBottom={
                                    !desktopMobile
                                      ? () => {
                                          return (
                                            <>
                                              {includes_items.map((item, i) => {
                                                return (
                                                  <SelectItem
                                                    key={"item_" + i}
                                                    select_type="icon"
                                                    select_icon={"check"}
                                                    icon_color={
                                                      colors.success_color
                                                    }
                                                    noPress={true}
                                                    tooltip={
                                                      !!item?.tooltip
                                                        ? item.tooltip
                                                        : ""
                                                    }
                                                    tooltipPlacement={"top"}
                                                    innerStyle={{
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingLeft: 60
                                                    }}
                                                    textStyle={
                                                      !!item?.tooltip
                                                        ? {
                                                            textDecoration:
                                                              "underline",
                                                            textDecorationStyle:
                                                              "dotted"
                                                          }
                                                        : {}
                                                    }
                                                  >
                                                    {item?.text}
                                                  </SelectItem>
                                                );
                                              })}
                                            </>
                                          );
                                        }
                                      : null
                                  }
                                >
                                  {plan?.title}
                                </SelectItem>
                              </Wrapper>
                            );
                          })}
                      </>
                    ) : null}
                  </>
                );
              }
              return null;
            })}

          {contentful_content?.contentItems &&
            contentful_content?.contentItems.map((item, i) => {
              const plan = item?.fields;

              if (
                (desktopMobile &&
                  this.props.selected_stripe_id &&
                  ((plan_interval === "year" &&
                    plan.stripePriceIdYearly !==
                      this.props.selected_stripe_id) ||
                    (plan_interval === "month" &&
                      plan.stripePriceIdMonthly !==
                        this.props.selected_stripe_id))) ||
                (this.props.no_free && plan.monthPrice == 0)
              ) {
                return null;
              }

              if (!plan?.featured || !desktopMobile) {
                return (
                  <Plan
                    key={"plan_" + i}
                    id={item?.sys?.id}
                    platform={this.props.platform}
                    colors={colors}
                    selected_list_builder={selected_list_builder}
                    desktopMobile={desktopMobile}
                    device={device}
                    marketing_slug={plan?.marketing_event_slug}
                    token={token}
                    title={plan?.title}
                    stripe_price_id_month={plan?.stripePriceIdMonthly}
                    stripe_price_id_year={plan?.stripePriceIdYearly}
                    featured={plan?.featured}
                    price={plan?.monthPrice}
                    year_price={plan?.yearPrice}
                    plan_interval={plan_interval}
                    buttonText={plan?.buttonText}
                    includes_items={
                      plan?.includesItems ? plan.includesItems : null
                    }
                    description={plan?.description}
                    subtext={plan?.subtext}
                    signup_funnel={this.props.signup_funnel}
                    onPurchase={this.props.onPurchase}
                    ios_plan_id={plan?.ios_plan_id}
                    offer_promo={plan?.promo}
                    free_trial_days={plan?.free_trial_days}
                    no_trial={plan?.no_trial}
                    addons={plan?.addons}
                    force_select_plan={this.props.force_select_plan}
                    onPlanSelection={this.props.onPlanSelection}
                    needs_dialer={this.props.needs_dialer}
                    selected_stripe_id={this.props.selected_stripe_id}
                    selected_stripe_id_year={this.props.selected_stripe_id_year}
                    selected_plan={
                      plan_interval === "year"
                        ? plan.stripePriceIdYearly ===
                          this.props.selected_stripe_id_year
                          ? true
                          : false
                        : plan.stripePriceIdMonthly ===
                          this.props.selected_stripe_id
                    }
                    annual_toggle_text={this.props.annual_toggle_text}
                    hide_annual_toggle={this.props.hide_annual_toggle}
                    onTogglePlanInterval={this.props.onTogglePlanInterval}
                    selectProPlan={this.props.selectProPlan}
                    manageSubscription={this.props.manageSubscription}
                    startRedirect={this.props.startRedirect}
                    prompt={this.props.prompt}
                    popSidePanel={this.props.popSidePanel}
                  />
                );
              }
              return null;
            })}
        </Row>
        {!desktopMobile &&
        (platform !== "ios" || signup_funnel !== "use_iap") ? (
          <>
            {contentful_content?.contentItems2 &&
              contentful_content?.contentItems2.map((item, i) => {
                const plan = item?.fields;
                const includes_items = plan?.includesItems;

                return (
                  <Wrapper
                    style={{
                      padding: 25,
                      maxWidth: 700,
                      margin: device == "desktop" ? "0px auto" : 0
                    }}
                  >
                    <SelectItem
                      onPress={() => {
                        this.props.onSelectListBuilder(!selected_list_builder);
                      }}
                      marketing_slug={
                        !selected_list_builder
                          ? "selected_list_builder_pre_checkout"
                          : "unselected_list_builder_precheckout"
                      }
                      selected={selected_list_builder}
                      description={plan?.description}
                      subtext={
                        needs_lists && selected_list_builder
                          ? "We've selected this for you based on what you're looking for!"
                          : ""
                      }
                      selected_background_color={
                        selected_list_builder
                          ? colors.success_color_muted
                          : colors.orange_color_muted
                      }
                      style={
                        !selected_list_builder
                          ? {
                              backgroundColor: colors.orange_color_muted
                            }
                          : {
                              backgroundColor: colors.success_color_muted
                            }
                      }
                      renderRight={() => {
                        return (
                          <Row style={{ padding: 25 }}>
                            <Title style={{ fontSize: 36 }}>
                              <Bold>
                                {plan_interval == "year"
                                  ? renderPrice(
                                      plan.yearPrice / 12,
                                      "no_decimal"
                                    )
                                  : renderPrice(plan.monthPrice, "no_decimal")}
                              </Bold>
                            </Title>
                            <Row style={{ alignItems: "flex-end" }}>
                              <Copy style={{ marginLeft: 5 }}>{" / mo"}</Copy>
                              {plan_interval == "year" ? (
                                <Copy
                                  style={{
                                    marginLeft: 5,
                                    color: colors.light_text_color
                                  }}
                                >
                                  {" - billed annually"}
                                </Copy>
                              ) : null}
                            </Row>
                          </Row>
                        );
                      }}
                      renderBottom={() => {
                        return (
                          <>
                            {includes_items.map((item, i) => {
                              return (
                                <SelectItem
                                  key={"item_" + i}
                                  select_type="icon"
                                  select_icon={"check"}
                                  icon_color={colors.success_color}
                                  noPress={true}
                                  tooltip={!!item?.tooltip ? item.tooltip : ""}
                                  tooltipPlacement={"top"}
                                  innerStyle={{
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingLeft: 60
                                  }}
                                  textStyle={
                                    !!item?.tooltip
                                      ? {
                                          textDecoration: "underline",
                                          textDecorationStyle: "dotted"
                                        }
                                      : {}
                                  }
                                >
                                  {item?.text}
                                </SelectItem>
                              );
                            })}
                          </>
                        );
                      }}
                    >
                      {plan?.title}
                    </SelectItem>
                  </Wrapper>
                );
              })}
          </>
        ) : null}
      </Wrapper>
    );
  }
}

export default DisplayPlans;
