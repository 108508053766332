import React, { Component } from "react";
import EqualOption from "./EqualOption";

class TextType extends Component {
  render() {
    const {
      filter,
      selected_filter,
      selected_equal_type,
      selected_options,
      colors,
      additional_condition_values
    } = this.props;
    if (filter.type == "bool") {
      return (
        <>
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_equal_to_any_of"}
            title={"Is equal to any of"}
            allow_options={true}
            selected_options={selected_options}
            available_options={filter.available_options || []}
            addSelectedOption={this.props.addSelectedOption}
            removeSelectedOption={this.props.removeSelectedOption}
            is_active={selected_equal_type == "is_equal_to_any_of"}
            onPress={() => {
              this.props.selectEqualType("is_equal_to_any_of");
            }}
            additional_condition_values={additional_condition_values}
          />
          <EqualOption
            colors={colors}
            filter={filter}
            selected_filter={selected_filter}
            addFilter={this.props.addFilter}
            slug={"is_not_equal_to_any_of"}
            title={"Is not equal to any of"}
            allow_options={true}
            selected_options={selected_options}
            available_options={filter.available_options || []}
            addSelectedOption={this.props.addSelectedOption}
            removeSelectedOption={this.props.removeSelectedOption}
            is_active={selected_equal_type == "is_not_equal_to_any_of"}
            onPress={() => {
              this.props.selectEqualType("is_not_equal_to_any_of");
            }}
            additional_condition_values={additional_condition_values}
          />
        </>
      );
    }
    return null;
  }
}

export default TextType;
