import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  ExternalImage,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import Quote from "app/DealMachineCore/reuseable/Quote";
import SkipButton from "./SkipButton";

import { loadDashboardStep } from "app/NativeActions";

class BuildAListStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("build_a_list")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  completedStep() {
    this.props.updateTab("contact_leads");
    this.props.updateDashboard({
      token: this.props.token,
      type: this.props.is_enterprise ? "enterprise_step" : "dashboard_step",
      current_step: "contact_leads"
    });
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab == "build_a_list") {
      if (!loading && dashboard_step) {
        return (
          <Wrapper>
            <CardBody>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: this.props.dashboardMobile ? "column" : "row"
                }}
              >
                <Wrapper>
                  <Quote
                    center={this.props.isMobile ? true : false}
                    style={{ padding: 15, paddingTop: 5, maxWidth: 600 }}
                    image={
                      dashboard_step.quoteImage
                        ? dashboard_step.quoteImage.fields.file.url
                        : ""
                    }
                    title={dashboard_step.quoteText}
                    description={dashboard_step.quoteDescription}
                  />
                  <Copy style={{ marginBottom: 20 }}>
                    {dashboard_step.header}
                  </Copy>
                  <Copy>
                    <Bold>{dashboard_step.description}</Bold>
                  </Copy>
                </Wrapper>
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: this.props.dashboardMobile ? 0 : 20,
                    paddingTop: this.props.dashboardMobile ? 20 : 0
                  }}
                >
                  <Card style={{ marginBottom: 20 }}>
                    <ExternalImage
                      style={{
                        maxWidth: 300,
                        width: 300,
                        height: 315
                      }}
                      image={
                        dashboard_step.image
                          ? dashboard_step.image.fields.file.url
                          : ""
                      }
                    />
                  </Card>
                  <Wrapper>
                    <Row stlye={{ justifyContent: "center" }}>
                      <PillButton
                        primary={true}
                        onPress={() => {
                          this.props.pushSidePanel({
                            slug: "list_builder",
                            overlay: true,
                            focus_modal: true
                          });
                        }}
                      >
                        {dashboard_step.nextButtonText}
                      </PillButton>
                    </Row>
                    <SkipButton
                      //completed_step={this.state.completed_step}
                      onPress={this.completedStep}
                      title={dashboard_step.secondaryButtonText}
                    />
                  </Wrapper>
                </Wrapper>
              </Wrapper>
            </CardBody>
          </Wrapper>
        );
      }
    }
    return <Wrapper />;
  }
}

export default BuildAListStep;
