import React, { PureComponent } from "react";
import {
  Button,
  CardBody,
  Split,
  Wrapper,
  Stretch,
  Title,
  Copy,
  Icon,
  CenterCenter,
  Bold
} from "app/NativeComponents/common";

import { store } from "app/store";

class Select extends PureComponent {
  renderCopy(text, size, textStyle) {
    if (size === "small") {
      if (text && text != "") {
        return (
          <Copy style={{ fontSize: 14, whiteSpace: "nowrap", ...textStyle }}>
            {text}
          </Copy>
        );
      } else {
        return (
          <Copy style={{ fontSize: 14, whiteSpace: "nowrap", ...textStyle }}>
            Not selected
          </Copy>
        );
      }
    }
    if (size === "medium") {
      if (text && text != "") {
        return <Copy>{text}</Copy>;
      } else {
        return <Copy>Not selected</Copy>;
      }
    }
    if (text && text != "") {
      return <Title style={{ ...textStyle }}>{text}</Title>;
    } else {
      return <Title style={{ ...textStyle }}>Not selected</Title>;
    }
  }

  renderSelect(items, value, onSelect) {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (
        items[i].value == 0 ||
        items[i].value == "" ||
        items[i].value == null
      ) {
        found = true;
      }
    }
    //add a n/a to select box if none present
    let data = JSON.parse(JSON.stringify(items));
    if (!found) {
      data = [
        {
          id: 0,
          value: 0,
          label: "N/A",
          title: "N/A"
        },
        ...data
      ];
    }

    return (
      <select value={value} onChange={ev => onSelect(ev.target.value)}>
        {data.map((item, i) => {
          if (item.value == value) {
            return (
              <option defaultValue key={i} value={item.value}>
                {item.label}
              </option>
            );
          }
          return (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    );
  }

  render() {
    const {
      style,
      item_ref,
      title,
      text,
      label,
      value,
      onSelect,
      items,
      size,
      wrapperStyle,
      textStyle,
      labelDirection,
      titleStyle
    } = this.props;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;

    if (size == "small") {
      return (
        <Wrapper>
          <div style={{ ...wrapperStyle }} className="dm-select-wrapper">
            <Wrapper style={{ ...style }}>
              <Split
                style={{
                  flex: 1,
                  flexDirection:
                    labelDirection == "horizontal" ? "row" : "column"
                }}
              >
                <Wrapper style={{ overflow: "hidden" }}>
                  {!!title ? (
                    <Copy
                      style={{
                        fontSize: 10,
                        lineHeight: "10px",
                        marginRight: labelDirection == "horizontal" ? 5 : 0,
                        ...titleStyle
                      }}
                    >
                      {title}
                    </Copy>
                  ) : null}
                  {this.renderCopy(text, size, textStyle)}
                </Wrapper>
                <Wrapper>
                  <CenterCenter>
                    <Icon
                      style={{ marginLeft: 15 }}
                      color={colors.light_text_color}
                      fa_icon="sort"
                      size={10}
                    />
                  </CenterCenter>
                </Wrapper>
              </Split>
            </Wrapper>
            {this.renderSelect(items, value, onSelect)}
          </div>
        </Wrapper>
      );
    }

    if (size == "medium") {
      return (
        <Wrapper>
          <div className="dm-select-wrapper">
            <Wrapper style={{ ...style }}>
              <Split
                style={{
                  flex: 1
                }}
              >
                <Wrapper
                  style={{
                    flexDirection:
                      labelDirection == "horizontal" ? "row" : "column"
                  }}
                >
                  {!!title ? (
                    <Copy
                      style={{
                        marginRight: labelDirection == "horizontal" ? 10 : 0
                      }}
                    >
                      <Bold>{title}</Bold>
                    </Copy>
                  ) : null}
                  {this.renderCopy(text, size)}
                </Wrapper>
                <Wrapper>
                  <CenterCenter>
                    <Icon
                      style={{ marginLeft: 15 }}
                      color={colors.light_text_color}
                      fa_icon="sort"
                      size={10}
                    />
                  </CenterCenter>
                </Wrapper>
              </Split>
            </Wrapper>
            {this.renderSelect(items, value, onSelect)}
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div className="dm-select-wrapper">
          <CardBody style={style}>
            <Split
              style={{
                flex: 1
              }}
            >
              <Stretch
                style={{
                  flexDirection:
                    labelDirection == "horizontal" ? "row" : "column",
                  alignItems:
                    labelDirection == "horizontal" ? "center" : "flex-start"
                }}
              >
                <Copy
                  style={{
                    marginRight: labelDirection == "horizontal" ? 10 : 0,
                    ...titleStyle
                  }}
                >
                  {title}
                </Copy>
                {this.renderCopy(text, "", textStyle)}
              </Stretch>
              <Wrapper>
                <CenterCenter>
                  <Icon
                    style={{ marginLeft: 10 }}
                    size={14}
                    color={colors.light_text_color}
                    fa_icon="sort"
                  />
                </CenterCenter>
              </Wrapper>
            </Split>
          </CardBody>
          {this.renderSelect(items, value, onSelect)}
        </div>
      </Wrapper>
    );
  }
}

export { Select };
