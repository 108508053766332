import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Row,
  Title,
  Icon,
  Copy,
  Bold,
  Button,
  Spin
} from "app/NativeComponents/common";

import CheckGoals from "../Goals/CheckGoals";
import EnterpriseGoals from "../Goals/EnterpriseGoals";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

import moment from "moment";
import { numberWithCommas } from "app/NativeActions";
class CompletedStepsEnterprise extends Component {
  componentDidMount() {
    //get goals and calculate tasks on api
    this.props.getDashboardSettings({
      token: this.props.token,
      type: "enterprise_goals"
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.enterprise_goals !== this.props.user.enterprise_goals) {
      this.props.getDashboardSettings({
        token: this.props.token,
        type: "enterprise_goals"
      });
    }
  }

  render() {
    if (
      this.props.is_enterprise &&
      this.props.user.completed_enterprise_steps === true
    ) {
      return (
        <Wrapper style={{ alignSelf: "stretch", flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <Wrapper>
              <Wrapper style={{ padding: 25 }}>
                <Title>Let's Achieve Success Driving for Dollars</Title>
              </Wrapper>
            </Wrapper>
            <CardBody>
              <Wrapper
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <EnterpriseGoals />
              </Wrapper>
            </CardBody>
          </Card>
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

export default CompletedStepsEnterprise;
