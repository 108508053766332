import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Title, Copy, Spin, Row } from "app/NativeComponents/common";
import { InlineButton, List, SelectItem } from "app/NativeComponents/snippets";
import MailerItem from "app/DealMachineCore/reuseable/MailerItem";
import { determineDisplayProperty, formatAddress } from "../../actions";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import moment from "moment/moment";

class MailerList extends Component {
  render() {
    if (this.props.activity_properties_type.slug === "mailers_sent") {
      if (
        this.props.mailers_refreshing ||
        (this.props.mailers_loading && this.props.mailers.length == 0)
      ) {
        return (
          <Wrapper
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Row>
              <Spin size={"small"} />
              <Copy style={{ marginLeft: 10 }}>Loading Mail...</Copy>
            </Row>
          </Wrapper>
        );
      }
      if (this.props.mailers.length > 0) {
        return (
          <List
            rowNumber={1}
            style={{ flex: 1 }}
            items={this.props.mailers}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              const display_property = determineDisplayProperty(item?.property);

              const property_address = formatAddress({
                address: {
                  address: display_property.property_address,
                  address2: display_property.property_address2,
                  address_city: display_property.property_address_city,
                  address_state: display_property.property_address_state,
                  address_zip: display_property.property_address_zip,
                  latitude: item?.property?.location?.latitude,
                  longitude: item?.property?.location?.longitude
                }
              });

              let scale = 0.2;
              switch (item?.template_type) {
                case "postcard":
                default:
                  scale = 0.2;
                  break;
                case "postcard6x9":
                  scale = 0.15;
                  break;
                case "postcard6x11":
                  scale = 0.125;
                  break;
              }

              return (
                <SelectItem
                  select_type={"none"}
                  noPress={true}
                  description={property_address?.line2}
                  renderLeft={
                    !this.props.isMobile
                      ? () => {
                          return (
                            <Wrapper style={{ margin: 5, marginRight: 0 }}>
                              <MailerFlipCard
                                mailer={item}
                                scale={scale}
                                back_scale={scale}
                              />
                            </Wrapper>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    return (
                      <Row style={{ margin: 5, marginLeft: 0 }}>
                        {item?.property ? (
                          <InlineButton
                            icon_right={"keyboard-arrow-right"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "property",
                                overlay: true,
                                focus_side_panel:
                                  this.props.user?.user_settings
                                    ?.property_expanded === "not_expanded"
                                    ? false
                                    : true,
                                id: item?.property?.property_id,
                                data: { property: item?.property }
                              });
                            }}
                          >
                            {this.props.isMobile ? "View" : "View Lead"}
                          </InlineButton>
                        ) : null}
                      </Row>
                    );
                  }}
                >
                  {property_address?.line1}
                </SelectItem>
              );
            }}
            canRefresh={true}
            onRefresh={() => {
              this.props.getItems({
                load_type: "refresh"
              });
            }}
            is_refreshing={this.props.mailers_refreshing}
            canLoadMore={
              !this.props.mailers_loaded_all &&
              !this.props.mailers_loading &&
              !this.props.mailers_refreshing &&
              this.props.mailers.length > 0
            }
            isLoadingMore={
              this.props.mailers_loading &&
              !this.props.mailers_refreshing &&
              this.props.mailers.length > 0
            }
            onLoadMore={() => {
              if (
                !this.props.mailers_loaded_all &&
                this.props.mailers_refreshing != true &&
                this.props.mailers_loading != true
              ) {
                this.props.getItems({
                  load_type: "load_more",
                  begin: this.props.mailers_begin
                });
              }
            }}
          />
        );
      }

      if (this.props.mailers.length == 0) {
        return (
          <Wrapper
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Title style={{ textAlign: "center" }}>
              There is nothing to display.
            </Title>
            <Copy>There are no mail pieces to display.</Copy>
          </Wrapper>
        );
      }
    }

    return <Wrapper />;
  }
}

export default MailerList;
