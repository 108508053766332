import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectItem } from "app/NativeComponents/snippets";

import {
  bulkUpdateLeads,
  toggleModal,
  setModal,
  updateModal,
  numberWithCommas
} from "app/NativeActions";
class PauseCampaignsButton extends Component {
  render() {
    const { button_search, title, selected_all, selected_leads } = this.props;
    const { token, user, filtered_count } = this.props;
    if (user.team_clearance_level > 1 || user.can_approve_mail == 1) {
      if (
        !button_search ||
        title.toLowerCase().includes(button_search.toLowerCase())
      ) {
        return (
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              let change_status_modal_title = "";
              let change_status_modal_description;
              if (selected_all) {
                change_status_modal_title =
                  "Are you sure you want to pause mail for " +
                  numberWithCommas(filtered_count) +
                  " leads?";
                change_status_modal_description =
                  "You can resume the mail at any time.";
              } else {
                change_status_modal_title =
                  "Are you sure you want to pause mail for " +
                  numberWithCommas(selected_leads.length) +
                  " leads?";
                change_status_modal_description =
                  "You can resume the mail at any time.";
              }

              this.props.setModal({
                title: change_status_modal_title,
                description: change_status_modal_description,
                submit: "Pause Mail",

                require_complete_function: true,
                onPress: complete => {
                  //trigger add to list
                  this.props.bulkUpdateLeads({
                    token: token,
                    type: "pause_mailers",
                    select_all: selected_all ? 1 : 0,
                    total_count: selected_all
                      ? filtered_count
                      : selected_leads.length,
                    filters:
                      this.props.applied_filter &&
                      this.props.applied_filter.filters
                        ? this.props.applied_filter?.filters
                        : null,
                    property_flags: this.props.lead_filters?.property_flags
                      .map(({ value }) => value)
                      .join(","),
                    property_flags_and_or:
                      this.props.lead_filters?.property_flags_and_or,
                    search: this.props.search,
                    search_type: this.props.search_type,
                    list_id: this.props.active_list_tab?.id
                      ? this.props.active_list_tab?.id
                      : this.props.active_list_tab,
                    list_history_id: this.props.list_history_id || "",
                    lead_ids: selected_all
                      ? ""
                      : selected_leads
                          .map(property => {
                            return property.deal.id;
                          })
                          .join(),
                    onLoading: () => {
                      this.props.updateModal({
                        submit_loading: true
                      });
                    },
                    onError: () => {
                      this.props.updateModal({
                        submit_loading: false
                      });
                    },
                    onSuccess: () => {
                      this.props.updateModal({
                        submit_loading: false
                      });
                      this.props.clearAll();
                      complete();
                    }
                  });
                },
                cancel: "Nevermind. Cancel Action.",
                onCancel: () => {}
              });
              this.props.toggleModal({ show: true, type: "normal" });

              this.props.toggleMenu(false);
            }}
          >
            {title}
          </SelectItem>
        );
      }
    }
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  bulkUpdateLeads,
  toggleModal,
  setModal,
  updateModal
})(PauseCampaignsButton);
