import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  GET_CUSTOM_FIELDS,
  GET_CUSTOM_FIELDS_FAIL,
  GET_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_FAIL,
  REMOVE_CUSTOM_FIELD_SUCCESS,
  REMOVE_CUSTOM_FIELD_GROUP_SUCCESS,
  GET_LEAD_STATUSES,
  GET_LEAD_STATUSES_FAIL,
  GET_LEAD_STATUSES_SUCCESS,
  UPDATE_LEAD_STATUS,
  UPDATE_LEAD_STATUS_FAIL,
  UPDATE_LEAD_STATUS_SUCCESS
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";
import { store } from "app/store";

import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = APIV2.create();

export const getCustomFields = ({ token, load_type, type }) => {
  return dispatch => {
    let type = "custom_fields";

    switch (load_type) {
      default:
      case "load":
        dispatch({ type: GET_CUSTOM_FIELDS });
        break;
    }

    api
      .getCustomFields({
        token,
        type
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_CUSTOM_FIELDS_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_CUSTOM_FIELDS_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (load_type) {
            default:
            case "load":
              dispatch({
                type: GET_CUSTOM_FIELDS_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const updateCustomFields = ({
  token,
  type,
  custom_field_group_id,
  custom_field_id,
  custom_fields,
  title,
  field_type,
  field_options,
  onLoading = null,
  onSuccess = null,
  onError = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    switch (type) {
      default:
        dispatch({
          type: UPDATE_CUSTOM_FIELDS
        });
        break;
    }

    api
      .updateCustomFields({
        token,
        type,
        custom_field_group_id,
        custom_field_id,
        custom_fields,
        title,
        field_type,
        field_options
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_CUSTOM_FIELDS_FAIL,
            payload: response.problem
          });
          if (onError) {
            onError();
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          }
        } else if (response.data?.error != false) {
          dispatch({
            type: UPDATE_CUSTOM_FIELDS_FAIL,
            payload: response.data.error
          });
          if (onError) {
            onError();
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });

          switch (type) {
            default:
              dispatch({
                type: UPDATE_CUSTOM_FIELDS_SUCCESS,
                payload: response.data?.results
              });

              break;
          }

          if (onSuccess) {
            onSuccess(response.data?.results);
          } else {
            switch (type) {
              case "remove_custom_field":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've removed this field.",
                    title: "Success!"
                  }
                });

                break;
              case "remove_custom_field_group":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've removed this group.",
                    title: "Success!"
                  }
                });

                break;
              case "update_custom_fields":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've updated your custom fields.",
                    title: "Success!"
                  }
                });

                break;
              default:
                break;
            }
          }
        }
      });
  };
};

export const getLeadStatuses = ({ token, load_type, type }) => {
  return dispatch => {
    let type = "lead_statuses";

    switch (load_type) {
      default:
      case "load":
        dispatch({ type: GET_LEAD_STATUSES });
        break;
    }

    api
      .getLeadStatuses({
        token,
        type
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_LEAD_STATUSES_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_LEAD_STATUSES_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (load_type) {
            default:
            case "load":
              dispatch({
                type: GET_LEAD_STATUSES_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const updateLeadStatus = ({
  token,
  type,
  lead_status_id,
  title,
  status_type,
  lead_statuses,
  onLoading = null,
  onSuccess = null,
  onError = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    switch (type) {
      default:
        dispatch({
          type: UPDATE_LEAD_STATUS
        });
        break;
    }

    api
      .updateLeadStatus({
        token,
        type,
        lead_status_id,
        title,
        status_type,
        lead_statuses
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_LEAD_STATUS_FAIL,
            payload: response.problem
          });
          if (onError) {
            onError();
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          }
        } else if (response.data?.error != false) {
          dispatch({
            type: UPDATE_LEAD_STATUS_FAIL,
            payload: response.data.error
          });
          if (onError) {
            onError();
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });

          switch (type) {
            default:
              dispatch({
                type: UPDATE_LEAD_STATUS_SUCCESS,
                payload: response.data?.results
              });

              break;
          }

          if (onSuccess) {
            onSuccess(response.data?.results);
          } else {
            switch (type) {
              case "remove_lead_status":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've removed this status.",
                    title: "Success!"
                  }
                });

                break;

              case "update_lead_status_order":
                dispatch({
                  type: SUCCESS_MESSAGE,
                  payload: {
                    message: "You've updated your lead statuses.",
                    title: "Success!"
                  }
                });

                break;
              default:
                break;
            }
          }
        }
      });
  };
};

export const getLeadStatusColor = lead_status_type => {
  const colors = store.getState().settings.colors;

  switch (lead_status_type) {
    default:
    case "lead_generation_status":
      return colors.background_color;

    case "marketing_status":
    case "warm_lead_status":
    case "hot_lead_status":
      return colors.active_color_muted;

    case "acquisition_status":
      return colors.active_color_muted;

    case "disposition_status":
      return colors.success_color_muted;
    case "won_status":
      return colors.success_color_muted;

    case "inactive_status":
    case "return_to_sender_status":
    case "trash_status":
    case "marketing_complete_status":
    case "not_interested_status":
    case "unqualified_status":
      return colors.error_color_muted;
  }
};
