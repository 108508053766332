import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class LandInfo extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, offset: 0 };
  }
  componentWillUnmount() {
    clearInterval(this._interval);
  }
  render() {
    const { property, search, colors } = this.props;

    return (
      <>
        {"Land Information".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "APN (Parcel ID)".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Lot size (Acres):".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Legal description".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Subdivision name".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Property class".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "County land use code".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "County name".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Census tract".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Lot size (Sqft):".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
        "Lot number".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "School district".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Zoning".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        "Flood zone".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
        !search ? (
          <Card
            id={"land-information"}
            label={"Land Information"}
            style={{ paddingTop: 10, marginTop: 15 }}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y
              });
            }}
          >
            <Row
              style={
                this.props.expanded
                  ? {
                      flexDirection: "row",
                      alignItems: "stretch",
                      flexWrap: "wrap"
                    }
                  : { flexDirection: "column", alignItems: "stretch" }
              }
            >
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.parcel_id}
                label={"APN (Parcel ID):"}
                hiddenFromNonSubs={true}
                helper={"parcel_id"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={search}
                item={property?.lot_acreage}
                label={"Lot size (Acres):"}
                format={"acre"}
                hiddenFromNonSubs={true}
                helper={"lot_acreage"}
              />

              {this.state.more_info || search ? (
                <>
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.LegalDescription}
                    label={"Legal description:"}
                    hiddenFromNonSubs={true}
                    helper={"legal_description"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.SubdivisionName}
                    label={"Subdivision name:"}
                    format={"text"}
                    hiddenFromNonSubs={true}
                    helper={"subdivision_name"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.PropertyClass}
                    label={"Property class:"}
                    hiddenFromNonSubs={true}
                    helper={"property_class"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.CountyLandUseCode}
                    label={"County land use code:"}
                    hiddenFromNonSubs={true}
                    helper={"county_land_use_code"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.property_address_county_name}
                    label={"County name:"}
                    hiddenFromNonSubs={true}
                    helper={"county_name"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.SitusCensusTract}
                    label={"Census tract:"}
                    hiddenFromNonSubs={true}
                    helper={"census_tract"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.lot_square_feet}
                    label={"Lot size (Sqft):"}
                    format={"sqft"}
                    hiddenFromNonSubs={true}
                    helper={"lot_square_feet"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.LotNbr}
                    label={"Lot number:"}
                    hiddenFromNonSubs={true}
                    helper={"lot_number"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.school_district_name}
                    label={"School district:"}
                    hiddenFromNonSubs={true}
                    helper={"school_district_name"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property?.zoning}
                    label={"Zoning:"}
                    hiddenFromNonSubs={true}
                    helper={"zoning"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={property.flood_zone}
                    label={"Flood zone:"}
                    hiddenFromNonSubs={true}
                    helper={"flood_zone"}
                  />
                </>
              ) : null}
            </Row>
            {!search && this.state.more_info ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: false
                    },
                    () => {
                      if (this.props.device === "desktop") {
                        this.props.scrollToSection({
                          id: "land-information",
                          offset: this.state.offset
                        });
                      } else {
                        this._interval = setTimeout(() => {
                          this.props.scrollToSection({
                            id: "land-information",
                            offset: this.state.offset
                          });
                        }, 50);
                      }
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-up"}
              >
                Less Info
              </InlineButton>
            ) : !search ? (
              <InlineButton
                onPress={() => {
                  this.setState(
                    {
                      more_info: true
                    },
                    () => {
                      this.props.scrollToSection({
                        id: "land-information",
                        offset: this.state.offset
                      });
                    }
                  );
                }}
                button_type={"full"}
                icon={"keyboard-arrow-down"}
              >
                More Info
              </InlineButton>
            ) : null}
          </Card>
        ) : null}
      </>
    );
  }
}

export default LandInfo;
