import React, { Component } from "react";

import { Header4 } from "app/NativeComponents/website/common";

const TestimonialTitle = ({ testimonial }) => {
  if (testimonial.name) {
    var company_and_position = "";
    if (
      testimonial.jobTitle &&
      testimonial.jobTitle != "" &&
      testimonial.company &&
      testimonial.company != ""
    ) {
      company_and_position =
        ", " + testimonial.jobTitle + " at " + testimonial.company;
    } else if (testimonial.company && testimonial.company != "") {
      company_and_position = ", " + testimonial.company;
    } else if (testimonial.jobTitle && testimonial.jobTitle != "") {
      company_and_position = ", " + testimonial.jobTitle;
    }

    return (
      <Header4>
        <b>{testimonial.name}</b>
        {company_and_position}
      </Header4>
    );
  }

  return <div />;
};

export default TestimonialTitle;
