import React, { Component } from "react";
import { Copy, Wrapper, Row, Icon } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  List,
  SelectItem,
  InlineButton,
  GhostButton
} from "app/NativeComponents/snippets";

import { store } from "app/store";
import { dismissMobileKeyboard } from "app/NativeActions";

class SelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.show !== prevProps.show &&
      this.props.show != this.state.show
    ) {
      this.setState({
        show: this.props.show
      });
    }

    if (this.state.show !== prevState.show) {
      if (this.state.show) {
        if (this.props.onFocus) {
          this.props.onFocus();
        }
      } else {
        if (this.props.onBlur) {
          this.props.onBlur();
        }
      }
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  getLabel(value) {
    const { items = [] } = this.props;
    for (let i = 0; i < items.length; i++) {
      if (items[i].value == value) {
        return items[i].label;
      }
    }
    return "--";
  }

  renderInner(options) {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const {
      show,
      style,
      innerStyle,
      placeholder,
      value,
      selected_background_color = null,
      hover_background_color = null,
      forceLabel,
      forceLabelPlaceholder,
      icon,
      fa_icon,
      icon_right,
      fa_icon_right,
      renderLeft = null,
      renderRight = null,
      renderMiddle = null,
      renderHover = null,
      pressedIn = false,

      hover_icon = null,
      hover_fa_icon = null,

      has_changed = false,
      show_buttons = false,
      secondary_button,
      secondary_button_icon,
      onSecondaryPress = () => {},
      require_confirm = false,
      loading = false,
      onSubmit = () => {},
      submit_button_title = "Save",
      submit_button_icon = "",
      submit_button_primary = false,
      submit_disabled = false,
      error = ""
    } = this.props;
    const background_color = show
      ? !!selected_background_color
        ? selected_background_color
        : (require_confirm && has_changed) || error
        ? colors.orange_color_muted
        : colors.active_color_muted
      : (require_confirm && has_changed) || error
      ? colors.orange_color_muted
      : options?.hovering
      ? hover_background_color || colors.hover_color
      : "transparent";

    const hover_icon_color = colors.orange_color;
    const icon_color = colors.text_color;

    return (
      <Row
        style={{
          backgroundColor: background_color,
          alignSelf: "stretch",
          ...style
        }}
      >
        {renderLeft ? renderLeft() : null}

        <Wrapper
          style={{
            padding: 25,
            flex: 1,
            ...innerStyle
          }}
        >
          {renderMiddle ? (
            renderMiddle()
          ) : (
            <Row>
              {!!icon || fa_icon ? (
                <Icon
                  size={18}
                  color={icon_color}
                  icon={icon}
                  fa_icon={fa_icon}
                  style={{ marginRight: 20 }}
                />
              ) : null}
              {!value && !forceLabel ? (
                <Wrapper style={{ flex: 1 }}>
                  <Copy style={{ color: colors.light_text_color }}>
                    {placeholder}
                  </Copy>
                </Wrapper>
              ) : (
                <Wrapper style={{ flex: 1 }}>
                  <Copy
                    style={{
                      color: colors.light_text_color,
                      fontSize: 10,
                      lineHeight: device == "desktop" ? "10px" : 15
                    }}
                  >
                    {placeholder}
                  </Copy>
                  <Copy>
                    {forceLabel && !value
                      ? forceLabelPlaceholder
                      : this.getLabel(value)}
                  </Copy>
                </Wrapper>
              )}

              {!!icon_right || fa_icon_right ? (
                <Icon
                  size={18}
                  color={colors.text_color}
                  icon={icon_right}
                  fa_icon={fa_icon_right}
                  style={{ marginLeft: 20 }}
                />
              ) : (!!hover_icon || hover_fa_icon) &&
                options?.hovering &&
                !has_changed &&
                !show ? (
                <Icon
                  size={18}
                  color={hover_icon_color}
                  icon={hover_icon}
                  fa_icon={hover_fa_icon}
                  style={{ marginLeft: 20 }}
                />
              ) : null}
            </Row>
          )}
        </Wrapper>

        {has_changed || show_buttons ? (
          <>
            {!!secondary_button && !loading ? (
              <InlineButton
                disabled={false}
                icon={secondary_button_icon}
                onPress={onSecondaryPress}
              >
                {secondary_button}
              </InlineButton>
            ) : null}

            {submit_button_primary ? (
              <GhostButton
                primary={true}
                disabled={!has_changed ? true : false}
                icon={submit_button_icon}
                onPress={onSubmit}
                loading={loading}
              >
                {submit_button_title}
              </GhostButton>
            ) : (
              <InlineButton
                disabled={!has_changed ? true : false}
                icon={submit_button_icon}
                onPress={onSubmit}
                loading={loading}
              >
                {submit_button_title}
              </InlineButton>
            )}
          </>
        ) : null}

        {renderRight ? renderRight() : null}
        {renderHover && (options?.hovering || pressedIn) ? renderHover() : null}
      </Row>
    );
  }

  render() {
    const {
      style,
      placeholder = "",
      popoverPlacement = "bottom",
      value = "",
      disabled = false,
      popover_title = "Select an option",
      items = [],
      search_title = "Search Options",
      onSubmitEditing = () => {},
      onFocus = () => {},
      onBlur = () => {},
      hide_search = false
    } = this.props;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;

    const { search, show } = this.state;

    if (disabled) {
      return this.renderInner({ hovering: false });
    }
    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          dismissMobileKeyboard();
          this.setState({
            show: s
          });
        }}
        popover_width={350}
        popover_height={300}
        popoverSheetTop={hide_search ? "50%" : "90%"}
        popoverPlacement={popoverPlacement}
        popover_title={popover_title}
        includeCloseButton={true}
        renderComponent={options => {
          return this.renderInner(options);
        }}
        renderMenu={() => {
          return (
            <>
              <Wrapper style={{ alignSelf: "stretch" }}>
                {!hide_search ? (
                  <SearchBar
                    style={{
                      margin: 10
                    }}
                    autoFocus={device == "desktop" && !isMobile ? true : false}
                    title={search_title}
                    onChange={value => {
                      this.setState({
                        search: value
                      });
                    }}
                    value={search}
                    onPress={() => {
                      const filtered_items = this.filterOptions(items);
                      if (filtered_items.length > 0) {
                        this.props.onSelect(filtered_items[0].value);
                        this.setState({ show: false });
                        onSubmitEditing();
                      }
                    }}
                  />
                ) : null}
              </Wrapper>

              <List
                style={{ alignSelf: "stretch" }}
                items={this.filterOptions(items)}
                itemStructure={({ item, index }) => {
                  let selected = item.value == value;
                  return (
                    <SelectItem
                      key={"option_" + index}
                      selected={selected}
                      select_type="radio"
                      onPress={() => {
                        this.props.onSelect(item.value);
                        this.setState({ show: false });

                        onSubmitEditing();
                      }}
                      description={item?.description}
                    >
                      {item.label}
                    </SelectItem>
                  );
                }}
              />
            </>
          );
        }}
      />
    );
  }
}

export { SelectBox };
