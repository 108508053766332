import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  InternalImage,
  Gradient,
  Icon,
  Bold,
  Copy
} from "app/NativeComponents/common";
import { GhostButton, SelectItem } from "app/NativeComponents/snippets";

import {
  logout,
  startRedirect,
  bypassMobileRedriect,
  getMobileRedirectSessionLink,
  openUrl
} from "app/NativeActions";
class NativeMobilePrompt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect_url: null
    };
  }

  componentDidMount() {
    this.getMobileRedirectSessionLink();
  }

  getMobileRedirectSessionLink() {
    this.props.getMobileRedirectSessionLink({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          redirect_url: results?.url
        });
      }
    });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;

    return (
      <>
        <Row>
          <Gradient
            style={{
              borderRadius: 5,

              height: 60,
              width: 60,
              marginLeft: 25
            }}
            color1={colors.purple_color}
            color2={colors.pink_color}
          >
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: 60,
                width: 60
              }}
            >
              <InternalImage
                style={{
                  width: 35,
                  height: 35,
                  objectFit: "contain",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                contain
                image={"/assets/images/Icon-white.png"}
              />
            </Wrapper>
          </Gradient>
          <Wrapper style={{ padding: 25, flex: 1 }}>
            <Copy>
              <Bold>DealMachine for Real Estate</Bold>
            </Copy>
            <Copy> Download the app for the best mobile experience.</Copy>
            <Row>
              <Icon icon={"star"} color={colors.orange_color_muted} size={18} />
              <Icon icon={"star"} color={colors.orange_color_muted} size={18} />
              <Icon icon={"star"} color={colors.orange_color_muted} size={18} />
              <Icon icon={"star"} color={colors.orange_color_muted} size={18} />
              <Icon
                icon={"star-half"}
                color={colors.orange_color_muted}
                size={18}
              />
            </Row>
            <Copy style={{ fontSize: 12 }}>
              4.8 out of 5 stars in over 5,000 reviews
            </Copy>
          </Wrapper>
          <GhostButton
            primary={true}
            disabled={!this.state.redirect_url}
            style={{ marginRight: 15 }}
            onPress={() => {
              openUrl(this.state.redirect_url, true);
            }}
          >
            Install
          </GhostButton>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  bypassMobileRedriect,
  getMobileRedirectSessionLink
})(NativeMobilePrompt);
