import React, { PureComponent } from "react";
import {
  Row,
  Button,
  Wrapper,
  Icon,
  ProfilePic,
  Spin
} from "app/NativeComponents/common";
import { Notification } from "app/NativeComponents/snippets";
import ButtonWrapper from "./ButtonWrapper";
import { store } from "app/store";

const CircleButton = props => {
  const {
    children,
    primary,
    style,
    innerStyle,
    disabled = false,
    deleteButton = false,
    icon,
    fa_icon,
    icon_right,
    fa_icon_right,
    pressedIn = false, //pressed in override from props
    loading = false,
    button_type = "normal",
    user = null,
    textColor = null,
    show_notification = false,
    notification_icon,
    notification_fa_icon,
    notification_number = 0,
    notification_color,
    notification_text_color,
    notification_size = 20,
    notification_font_size = 14,
    backgroundColor,
    borderColor,
    no_border = false,
    image_size = 46,
    size = 22
  } = props;
  const colors = store.getState().settings.colors;

  return (
    <ButtonWrapper
      {...props}
      renderInner={({
        hovering /*actual pressed in from state of button wrapper*/
      }) => {
        const background_color = backgroundColor
          ? backgroundColor
          : pressedIn
          ? primary
            ? colors.orange_color
            : colors.actionable_text_color
          : colors.popover_color;

        const hover_color = hovering ? colors.hover_color : "transparent";

        const border_color = borderColor
          ? borderColor
          : primary
          ? colors.orange_color
          : pressedIn
          ? colors.actionable_text_color
          : colors.border_color;
        const text_color = textColor
          ? textColor
          : pressedIn
          ? colors.white_text_color
          : primary
          ? colors.orange_color
          : colors.actionable_text_color;

        return (
          <Wrapper
            style={{
              width: image_size,
              height: image_size,
              borderColor: border_color,
              borderWidth: !no_border ? 1 : 0,
              borderStyle: "solid",
              backgroundColor: background_color,
              borderRadius: parseInt(image_size / 2) + 1,
              alignItems: "center",
              justifyContent: "center",
              margin: 5,
              opacity: disabled ? 0.5 : 1,
              ...style
            }}
          >
            <Wrapper
              style={{
                width: image_size,
                height: image_size,
                borderRadius: parseInt(image_size / 2) + 1,
                backgroundColor: hover_color,
                alignItems: "center",
                justifyContent: "center",
                position: "relative"
              }}
            >
              {loading ? (
                <Spin size="small" color={text_color} />
              ) : button_type == "profile_pic" ? (
                <>
                  {props.user && props.user?.id != 0 ? (
                    <ProfilePic
                      size={no_border ? image_size : image_size - 4}
                      email={user?.email}
                      image={user?.image}
                      firstname={user?.firstname}
                      lastname={user?.lastname}
                      style={{ margin: 0 }}
                    />
                  ) : (
                    <Icon icon={"person"} size={size} color={text_color} />
                  )}
                </>
              ) : (
                <Icon
                  icon={icon}
                  fa_icon={fa_icon}
                  size={size}
                  color={text_color}
                />
              )}
              {show_notification ? (
                <Notification
                  notification_size={notification_size}
                  notification_font_size={notification_font_size}
                  notification_icon={notification_icon}
                  notification_fa_icon={notification_fa_icon}
                  notification_number={notification_number}
                  notification_color={notification_color}
                  notification_text_color={notification_text_color}
                />
              ) : null}
            </Wrapper>
          </Wrapper>
        );
      }}
    />
  );
};

export { CircleButton };
