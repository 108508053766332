import React, { Component } from "react";
import moment from "moment";

import {
  Card,
  CardBody,
  Title,
  Wrapper,
  Copy
} from "app/NativeComponents/common";

import { SmallLabel } from "app/NativeComponents/snippets";

import { renderPrice } from "app/NativeActions";

class InvoiceCharges extends Component {
  render() {
    if (!this.props.start_date && !this.props.end_date) {
      return (
        <Wrapper
          style={{
            padding: 25,
            margin: 5,

            borderRadius: 5,
            backgroundColor: this.props.colors.active_color_muted
          }}
        >
          <SmallLabel style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}>
            All Charges:
          </SmallLabel>
          <Copy>{renderPrice(this.props.total_spent)}</Copy>
        </Wrapper>
      );
    }

    return (
      <Wrapper
        style={{
          padding: 25,
          margin: 5,
          borderRadius: 5,
          backgroundColor: this.props.colors.active_color_muted
        }}
      >
        <SmallLabel style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}>
          {"Charges from " +
            moment(this.props.start_date).format("MMM Do, YYYY") +
            " to " +
            moment(this.props.end_date).format("MMM Do, YYYY")}
        </SmallLabel>
        <Copy>{renderPrice(this.props.total_spent)}</Copy>
      </Wrapper>
    );
  }
}

export default InvoiceCharges;
