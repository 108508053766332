import React, { Component } from "react";
import { Wrapper, KeyboardView } from "app/NativeComponents/common";
import { BottomNavBar, InlineButton } from "app/NativeComponents/snippets";

import TemplateStyleOptions from "./TemplateStyleOptions";
import SelectTemplateImage from "./SelectTemplateImage";
import { renderPrice } from "app/NativeActions";
class Sidebar extends Component {
  render() {
    const { colors, isMobile, template_type_info, side_tab } = this.props;
    return (
      <>
        <KeyboardView style={{ flex: 1 }}>
          <TemplateStyleOptions {...this.props} />
          {/*<SelectTemplateImage {...this.props} />*/}
        </KeyboardView>

        <BottomNavBar>
          <InlineButton
            button_type={"full"}
            onPress={() => {
              this.props.toggleSignatures(true);
            }}
          >
            Signature
          </InlineButton>
        </BottomNavBar>
      </>
    );
  }
}

export default Sidebar;
