import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row, Bold, Copy } from "app/NativeComponents/common";
import {
  InlineButton,
  List,
  PopoverMenu,
  BottomNavBar,
  IconButton,
  InlineTabs
} from "app/NativeComponents/snippets";

import {
  selectActiveProperty,
  replaceSidePanel,
  numberWithCommas
} from "app/NativeActions";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";
import Routes from "app/DealMachineCore/components/MainMap/MapSidePanel/Routes";

class PropertyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select_drive_loading: false,
      image_toggle: "satellite"
    };
    this.renderSortByTitle = this.renderSortByTitle.bind(this);
  }

  componentWillUnmount() {}

  renderSortByTitle() {
    switch (this.props.sort_by) {
      default:
        break;

      case "saledate_desc":
        return "Sale Date (Newest)";

      case "saledate_asc":
        return "Sale Date (Oldest)";

      case "est_value_asc":
        return "Est. Value (Low to High)";

      case "est_value_desc":
        return "Est. Value (High to Low)";

      case "equity_percent_asc":
        return "Equity Percent (Low to High)";

      case "equity_percent_desc":
        return "Equity Percent (High to Low)";
    }

    return null;
  }

  render() {
    const { properties } = this.props;

    return (
      <>
        {this.props.show_past_drives ? (
          <Routes />
        ) : (
          <>
            <Row style={{ justifyContent: "space-between" }}>
              <PopoverMenu
                popover_width={350}
                disabled={this.props.disable_sort_by}
                renderComponent={({ pressedIn, hovering }) => {
                  return (
                    <InlineButton
                      noPress={true}
                      disabled={this.props.disable_sort_by}
                      tooltipPlacement={"bottom"}
                      tooltip={
                        this.props.disable_sort_by ? "Sorting disabled" : null
                      }
                      pressedIn={pressedIn || hovering}
                    >
                      Sort By: <Bold>{this.renderSortByTitle()}</Bold>
                    </InlineButton>
                  );
                }}
                menu_items={[
                  {
                    title: "Sale Date (Newest)",
                    select_type: "radio",
                    selected: this.props.sort_by == "saledate_desc",
                    onPress: () => {
                      this.props.updateMapFilters({
                        sort_by: "saledate_desc"
                      });
                    }
                  },
                  {
                    title: "Sale Date (Oldest)",
                    select_type: "radio",
                    selected: this.props.sort_by == "saledate_asc",
                    onPress: () => {
                      this.props.updateMapFilters({
                        sort_by: "saledate_asc"
                      });
                    }
                  },
                  {
                    title: "Est. Value (Low to High)",
                    select_type: "radio",
                    selected: this.props.sort_by == "est_value_asc",
                    onPress: () => {
                      this.props.updateMapFilters({
                        sort_by: "est_value_asc"
                      });
                    }
                  },
                  {
                    title: "Est. Value (High to Low)",
                    select_type: "radio",
                    selected: this.props.sort_by == "est_value_desc",
                    onPress: () => {
                      this.props.updateMapFilters({
                        sort_by: "est_value_desc"
                      });
                    }
                  },
                  {
                    title: "Equity Percent (Low to High)",
                    select_type: "radio",
                    selected: this.props.sort_by == "equity_percent_asc",
                    onPress: () => {
                      this.props.updateMapFilters({
                        sort_by: "equity_percent_asc"
                      });
                    }
                  },
                  {
                    title: "Equity Percent (High to Low)",
                    select_type: "radio",
                    selected: this.props.sort_by == "equity_percent_desc",
                    onPress: () => {
                      this.props.updateMapFilters({
                        sort_by: "equity_percent_desc"
                      });
                    }
                  }
                ]}
              />
            </Row>
            {((this.props.highlight &&
              properties.filter(property => {
                return property.highlighted == 1 ? property : null;
              }).length === 0) ||
              properties.length === 0) &&
            !this.props.properties_on_map_loading ? (
              <Wrapper
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Wrapper
                  style={{
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    There are no properties to display. Try reloading the map or
                    changing your filters.
                  </Copy>
                </Wrapper>
              </Wrapper>
            ) : (
              <List
                rowNumber={1}
                style={{ flex: 1 }}
                items={
                  //return all properties where highlight = 1
                  this.props.highlight
                    ? properties.filter(property => {
                        return property.highlighted == 1 ? property : null;
                      })
                    : properties
                }
                infiniteScroll={false}
                itemStructure={({ item, index }) => {
                  return (
                    <LeadItem
                      key={"item_" + index}
                      image_toggle={"none"}
                      include_add_button={true}
                      include_check_icon={true}
                      property={item}
                      onPress={() => {
                        //this.props.selectActiveProperty(this.props.token, item);
                        this.props.hideAllPopovers();

                        this.props.replaceSidePanel({
                          slug: "property",
                          id: item?.property_id,
                          focus_side_panel:
                            this.props.user?.user_settings
                              ?.property_expanded === "not_expanded"
                              ? false
                              : true,

                          overlay: true,
                          data: {
                            property: {
                              ...item
                            }
                          }
                        });
                      }}
                    />
                  );
                }}
                canRefresh={false}
                canLoadMore={false}
              />
            )}
            {this.props.properties_on_map_length > 0 &&
            this.props.properties_on_map_count > 0 ? (
              <BottomNavBar>
                <Wrapper style={{ padding: 10, paddingBottom: 0 }}>
                  {/*
              <Copy style={{ textAlign: "center" }}>
                Showing{" "}
                <Bold>{numberWithCommas(this.props.properties.length)}</Bold> of{" "}
                <Bold>
                  {numberWithCommas(this.props.properties_on_map_count)}
                </Bold>{" "}
                properties
              </Copy>
              */}

                  <Row style={{ justifyContent: "center" }}>
                    <IconButton
                      icon={"keyboard-double-arrow-left"}
                      disabled={this.props.page == 0}
                      tooltip={"First Page"}
                      onPress={() => {
                        this.props.updateMapFilters({
                          page: 0
                        });
                      }}
                    />
                    <IconButton
                      icon={"keyboard-arrow-left"}
                      disabled={this.props.page == 0}
                      tooltip={"Previous Page"}
                      onPress={() => {
                        this.props.updateMapFilters({
                          page: parseInt(this.props.page) - 1
                        });
                      }}
                    />
                    <Copy style={{ marginRight: 5 }}>Page</Copy>
                    <Copy style={{ marginRight: 5 }}>
                      <Bold>{this.props.page + 1}</Bold>
                    </Copy>
                    <Copy style={{ marginRight: 5 }}>
                      {"of "}
                      <Bold>
                        {Math.ceil(
                          this.props.properties_on_map_count / this.props.limit
                        )}
                      </Bold>
                    </Copy>
                    <IconButton
                      icon={"keyboard-arrow-right"}
                      disabled={
                        this.props.page + 1 ==
                        Math.ceil(
                          this.props.properties_on_map_count / this.props.limit
                        )
                      }
                      tooltip={"Next Page"}
                      onPress={() => {
                        this.props.updateMapFilters({
                          page: parseInt(this.props.page) + 1
                        });
                      }}
                    />
                    <IconButton
                      icon={"keyboard-double-arrow-right"}
                      disabled={
                        this.props.page + 1 ==
                        Math.ceil(
                          this.props.properties_on_map_count / this.props.limit
                        )
                      }
                      tooltip={"Last Page"}
                      onPress={() => {
                        this.props.updateMapFilters({
                          page:
                            Math.ceil(
                              this.props.properties_on_map_count /
                                this.props.limit
                            ) - 1
                        });
                      }}
                    />
                  </Row>
                </Wrapper>

                <Wrapper>
                  <Copy
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      color: this.props.colors.light_text_color
                    }}
                  >
                    Showing{" "}
                    {this.props.properties_on_map_length < this.props.limit &&
                    this.props.properties_on_map_length <
                      this.props.properties_on_map_count
                      ? this.props.properties_on_map_length
                      : this.props.properties_on_map_count < this.props.limit
                      ? this.props.properties_on_map_count
                      : this.props.limit}
                    {" of "}
                    {numberWithCommas(this.props.properties_on_map_count)}
                  </Copy>
                </Wrapper>
              </BottomNavBar>
            ) : null}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, route, property_map }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { current_route, track_route, current_route_section } = route;
  const { active_property } = property_map;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    current_route,
    track_route,
    current_route_section,
    active_property
  };
};

export default connect(mapStateToProps, {
  selectActiveProperty,
  replaceSidePanel
})(PropertyList);
