import React, { Component } from "react";
import { Page } from "app/NativeComponents/common";
import Streetview from "react-google-streetview";

class StreetViewiFrame extends Component {
  constructor(props) {
    super(props);

    const url = new URL(window?.location.href);

    this.state = {
      latitude: url.searchParams.get("latitude") || null,
      longitude: url.searchParams.get("longitude") || null,
      heading: url.searchParams.get("heading") || 0,
      pitch: url.searchParams.get("pitch") || 0,
      motion_tracking: url.searchParams.get("motion_tracking") || false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._moving_interval);
    clearInterval(this._looking_interval);
  }

  handleChange(data) {
    this.setState({
      latitude: data?.latitude,
      longitude: data?.longitude,
      heading: data?.heading,
      pitch: data?.pitch
    });
    const d = JSON.stringify(data);

    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(d);
    }
  }

  render() {
    const { latitude, longitude, heading, pitch } = this.state;
    const google_key = "AIzaSyCSqWxybt6K0wSW77qnddvl7evwcybsYqI";
    if (latitude && longitude) {
      return (
        <Page responsive_design={true}>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: "#000000"
            }}
          >
            <Streetview
              apiKey={google_key}
              streetViewPanoramaOptions={{
                position: {
                  lat: parseFloat(latitude),
                  lng: parseFloat(longitude)
                },
                pov: {
                  heading: heading ? parseFloat(heading) : 0,
                  pitch: pitch ? parseFloat(pitch) : 0
                },
                backgroundColor: "#000000",
                imageDateControl: false,
                fullscreenControl: false,
                linksControl: false,
                addressControl: false,
                zoomControl: false,
                panControl: false,
                motionTracking: this.state.motion_tracking || false,
                motionTrackingControl: false
              }}
              onPositionChanged={e => {
                if (e) {
                  if (
                    e?.lat() &&
                    e?.lng() &&
                    (e?.lat() !== latitude || e?.lng() !== longitude)
                  ) {
                    clearInterval(this._moving_interval);
                    this._moving_interval = setTimeout(() => {
                      this.handleChange({
                        latitude: e?.lat(),
                        longitude: e?.lng(),
                        heading: heading,
                        pitch: pitch
                      });
                    }, 50);
                  }
                }
              }}
              onPovChanged={e => {
                if (e) {
                  if (
                    e?.heading &&
                    e?.pitch &&
                    (e?.heading !== heading || e?.pitch !== pitch)
                  ) {
                    clearInterval(this._looking_interval);
                    this._looking_interval = setTimeout(() => {
                      this.handleChange({
                        latitude: latitude,
                        longitude: longitude,
                        heading: e?.heading,
                        pitch: e?.pitch
                      });
                    }, 50);
                  }
                }
              }}
              onVisibleChanged={e => {
                //console.log(e);
              }}
            />
          </div>
        </Page>
      );
    }
    return (
      <Page responsive_design={true}>
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "#000"
          }}
        ></div>
      </Page>
    );
  }
}

export default StreetViewiFrame;
