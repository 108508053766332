import React, { Component } from "react";
import { Wrapper, CardBody, Copy, Form } from "app/NativeComponents/common";

import Buttons from "./Buttons";

class Body extends Component {
  render() {
    return (
      <>
        <Wrapper style={{ padding: 25 }}>
          <Copy style={{ textAlign: "center" }}>
            You've been removed from your team. If you want to try DealMachine
            for yourself, or join a new team, select an option below.
          </Copy>
        </Wrapper>
      </>
    );
  }
}

export default Body;
