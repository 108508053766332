import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  MultiLineInput,
  KeyboardView
} from "app/NativeComponents/common";
import {
  GhostButton,
  SmallHeader,
  BottomNavBar,
  InputBox
} from "app/NativeComponents/snippets";
import BracketPicker from "./BracketPicker";

class NoteStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_team: false,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            note: ""
          }
    };
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (!!this.state.data.note) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Create Note"
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            <Wrapper
              style={{
                position: "relative"
              }}
            >
              <InputBox
                name="note"
                returnKeyType="default"
                placeholder="Note body"
                onChange={value => {
                  this.setState({
                    data: {
                      ...data,
                      note: value
                    }
                  });
                }}
                onFocus={() => {}}
                selected_background_color={"transparent"}
                hover_background_color={"transparent"}
                blurOnSubmit={false}
                value={data.note}
                input_type="text"
                type={"multiline"}
                numberOfLines={5}
                autogrow={true}
              />
              <Wrapper
                style={{
                  alignSelf: "stretch",
                  position: "absolute",
                  top: 0,
                  right: 0
                }}
              >
                <BracketPicker
                  onSelect={bracket => {
                    this.setState({
                      data: {
                        ...data,
                        note: data.note + "{{" + bracket.key + "}}"
                      }
                    });
                  }}
                  enrollment_object={enrollment_object}
                  enrollment_object_loading={enrollment_object_loading}
                />
              </Wrapper>
            </Wrapper>
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
  }
}

const mapStateToProps = ({ workflow }) => {
  const { enrollment_object, enrollment_object_loading } = workflow;

  return {
    enrollment_object,
    enrollment_object_loading
  };
};

export default connect(mapStateToProps, {})(NoteStep);
