import React, { Component } from "react";
import { Spin } from "app/NativeComponents/common";
import { Link } from "react-router-dom";

import Register from "app/DealMachineCore/components/Register";

class RegisterForm extends Component {
  render() {
    return (
      <div
        style={{
          marginLeft: -20,
          marginRight: -20
        }}
      >
        <Register
          buttonText={
            this.props.form.formType == "Register30DayForm"
              ? "Start Your 30-Day FREE Trial"
              : null
          }
          no_container
          no_redirect
        />
        <div className="dm-website-terms-text">
          {/*
         <p>By signing up, you agree to our <Link to="/terms-of-service">Terms of Service:</Link> Pay $0.00 today, then after the trial plans start as low as $49 per month plus mail and skip trace fees (based on how much you use). There are no contracts, you can cancel any time!</p>
        */}
        </div>
      </div>
    );
  }
}

export default RegisterForm;
