import React, { Component } from "react";
import { SelectItem, InlineButton } from "app/NativeComponents/snippets";
class DefaultTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { tag } = this.props;
    const { loading } = this.state;
    return (
      <SelectItem
        noPress={true}
        innerStyle={
          tag.hidden_tag == 1
            ? {
                textDecoration: "line-through"
              }
            : null
        }
        select_type="none"
        renderRight={() => {
          return (
            <InlineButton
              loading={loading}
              onPress={() => {
                this.props.hideDefaultTag({
                  token: this.props.token,
                  tag_id: tag.id,
                  type: tag.hidden_tag == 1 ? "show" : "hide",
                  onLoading: () => {
                    this.setState({ loading: true });
                  },
                  onError: () => {
                    this.setState({ loading: false });
                  },
                  onSuccess: () => {
                    this.setState({ loading: false });
                  }
                });
              }}
            >
              {tag.hidden_tag == 1 ? "Show" : "Hide"}
            </InlineButton>
          );
        }}
      >
        {tag.title}
      </SelectItem>
    );
  }
}

export default DefaultTag;
