import {
  LOGOUT,
  TOGGLE_SETTINGS_PANEL,
  GET_CUSTOM_FIELDS,
  GET_CUSTOM_FIELDS_FAIL,
  GET_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_FAIL,
  REMOVE_CUSTOM_FIELD_SUCCESS,
  REMOVE_CUSTOM_FIELD_GROUP_SUCCESS,
  GET_LEAD_STATUSES,
  GET_LEAD_STATUSES_SUCCESS,
  GET_LEAD_STATUSES_FAIL,
  UPDATE_LEAD_STATUS,
  UPDATE_LEAD_STATUS_FAIL,
  UPDATE_LEAD_STATUS_SUCCESS
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  settings_panel: false,
  custom_fields: [],
  custom_fields_loading: false,
  custom_fields_error: "",

  lead_statuses: [],
  lead_statuses_loading: false,
  lead_statuses_error: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case TOGGLE_SETTINGS_PANEL:
      return {
        ...state,
        settings_panel: action.payload
      };

    case GET_CUSTOM_FIELDS:
      return {
        ...state,
        custom_fields: [],
        custom_fields_error: "",
        custom_fields_loading: true
      };

    case GET_CUSTOM_FIELDS_FAIL:
      return {
        ...state,
        custom_fields_error: action.payload,
        custom_fields_loading: false
      };

    case GET_CUSTOM_FIELDS_SUCCESS:
    case UPDATE_CUSTOM_FIELDS_SUCCESS:
    case REMOVE_CUSTOM_FIELD_GROUP_SUCCESS:
    case REMOVE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        custom_fields_loading: false,
        custom_fields: action.payload.custom_fields
      };

    case GET_LEAD_STATUSES:
      return {
        ...state,
        lead_statuses: [],
        lead_statuses_error: "",
        lead_statuses_loading: true
      };

    case GET_LEAD_STATUSES_FAIL:
      return {
        ...state,
        lead_statuses_error: action.payload,
        lead_statuses_loading: false
      };

    case GET_LEAD_STATUSES_SUCCESS:
    case UPDATE_LEAD_STATUS_SUCCESS:
      return {
        ...state,
        lead_statuses_loading: false,
        lead_statuses: action.payload.lead_statuses
      };

    default:
      return state;
  }
};
