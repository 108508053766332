import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Button, Icon, Copy, Bold } from "app/NativeComponents/common";

import { store } from "app/store";
class HeaderButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false
    };
  }

  renderInner() {
    const { buttonContents = null } = this.props;
    const colors = store.getState().settings.colors;

    return (
      <>
        {!!buttonContents.icon || !!buttonContents.fa_icon ? (
          <Icon
            icon={!!buttonContents.icon ? buttonContents.icon : ""}
            fa_icon={!!buttonContents.fa_icon ? buttonContents.fa_icon : ""}
            color={buttonContents.color || colors.text_color}
            size={18}
          />
        ) : null}
      </>
    );
  }
  render() {
    const { buttonContents = null } = this.props;
    const { hovering } = this.state;

    const colors = store.getState().settings.colors;
    if (buttonContents) {
      if (buttonContents.noPress) {
        return (
          <Wrapper
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
              margin: 5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                hovering || buttonContents.hovering || buttonContents.pressedIn
                  ? buttonContents.hover_color
                    ? buttonContents.hover_color
                    : colors.hover_color
                  : "transparent"
            }}
          >
            {this.renderInner()}
          </Wrapper>
        );
      }

      return (
        <Button
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            margin: 5,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              hovering || buttonContents.hovering || buttonContents.pressedIn
                ? buttonContents.hover_color
                  ? buttonContents.hover_color
                  : colors.hover_color
                : "transparent"
          }}
          tooltip={buttonContents?.tooltip ? buttonContents?.tooltip : ""}
          tooltipPlacement={
            buttonContents?.tooltipPlacement
              ? buttonContents?.tooltipPlacement
              : "top"
          }
          onPress={buttonContents.onPress ? buttonContents.onPress : () => {}}
          onHover={() => {
            this.setState({ hovering: true });
          }}
          onHoverOut={() => {
            this.setState({ hovering: false });
          }}
        >
          {this.renderInner()}
        </Button>
      );
    }

    return null;
  }
}

export default HeaderButton;
