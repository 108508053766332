import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { KeyboardView, Wrapper, Copy, Bold } from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  SpinWrapper,
  UpsellButton
} from "app/NativeComponents/snippets";
import {
  getHighlightTemplates,
  getNewFiltersString,
  renderDate
} from "app/NativeActions";

class HighlightTemplates extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      templates_loading: false,
      selected_highlight: null
    };
  }

  componentDidMount() {
    this.props.getHighlightTemplates({
      token: this.props.token,
      onLoading: () => {
        this.setState({
          templates_loading:
            this.props.highlight_templates?.length == 0 ? true : false
        });
      },
      onError: () => {
        this.setState({
          templates_loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          templates_loading: false
        });
      }
    });
  }

  renderSavedHighlights() {
    const { saved_highlights } = this.props;

    if (this.state.templates_loading) {
      return <SpinWrapper text="Loading..." />;
    }

    if (saved_highlights?.length > 0) {
      return (
        <>
          <SmallLabel
            style={{
              padding: 25
            }}
          >
            Saved highlights
          </SmallLabel>
          {saved_highlights &&
            saved_highlights.map((highlight, i) => {
              return (
                <SelectItem
                  key={"highlight_templates_" + i}
                  icon={"keyboard-arrow-right"}
                  select_type="none"
                  selected={this.state.selected_highlight == highlight.id}
                  onPress={() => {
                    this.setState({
                      selected_highlight: highlight.id
                    });
                  }}
                  subtext={highlight?.default_filter ? "Driver Default" : ""}
                  confirm_text="Apply"
                  require_confirm={this.state.selected_highlight ? true : false}
                  onConfirm={() => {
                    this.props.setAppliedHighlight({
                      applied_highlight: highlight.filters
                    });
                    this.props.popSidePanel();
                  }}
                  secondaryButton="Edit"
                  onSecondaryPress={() => {
                    this.props.nextStep({
                      step_slug: "filters",
                      filter_id: highlight?.id,
                      filter_name: highlight?.title,
                      default_filter: highlight?.default_filter,
                      filters: highlight?.filters
                    });
                  }}
                >
                  {highlight.title}
                </SelectItem>
              );
            })}
        </>
      );
    }
  }

  render() {
    const {
      colors,
      filters,
      selected_template,
      highlight_templates,
      saved_highlights
    } = this.props;
    return (
      <KeyboardView style={{ flex: 1 }}>
        {/*over_the_limit ? this.renderRecentlyBuilt() : null*/}
        <SelectItem
          onPress={() => {
            this.props.nextStep({
              step_slug: "filters",
              filter_id: "",
              filter_name: "",
              default_filter: false,
              filters: {
                data: [],
                andor_type: "or"
              }
            });
          }}
          primary={true}
          select_type="none"
          icon="keyboard-arrow-right"
          style={{
            backgroundColor: colors.orange_color_muted
          }}
        >
          Create a highlight
        </SelectItem>
        {highlight_templates?.length > 0 ? (
          <>
            {highlight_templates.map((template, i) => {
              if (this.props.isMobile) {
                return (
                  <SelectItem
                    key={"highlight_templates_" + i}
                    icon={"keyboard-arrow-right"}
                    select_type="radio"
                    selected={this.state.selected_highlight == template.slug}
                    onPress={() => {
                      this.setState({
                        selected_highlight: template.slug
                      });
                    }}
                    confirm_text="Apply"
                    require_confirm={
                      this.state.selected_highlight ? true : false
                    }
                    onConfirm={() => {
                      this.props.setAppliedHighlight({
                        applied_highlight: template.filters
                      });
                      this.props.popSidePanel();
                    }}
                    secondaryButton="Edit"
                    onSecondaryPress={() => {
                      this.props.nextStep({
                        step_slug: "filters",
                        filter_id: "",
                        filter_name: "",
                        default_filter: false,
                        filters: template.filters
                      });
                    }}
                  >
                    {template.title}
                  </SelectItem>
                );
              }
              return (
                <SelectItem
                  key={"highlight_templates_" + i}
                  onPress={() => {
                    this.props.nextStep({
                      step_slug: "filters",
                      filter_id: "",
                      filter_name: "",
                      default_filter: false,
                      filters: template.filters
                    });
                  }}
                  select_type="none"
                  icon="keyboard-arrow-right"
                  //description={template.description}
                >
                  {template.title}
                </SelectItem>
              );
            })}
          </>
        ) : null}

        {this.renderSavedHighlights()}
      </KeyboardView>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, property_map }) => {
  const { token } = auth;
  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { highlight_templates, saved_highlights } = property_map;
  return {
    token,
    colors,
    isMobile,
    platform,
    device,
    highlight_templates,
    saved_highlights
  };
};

export default connect(mapStateToProps, {
  getHighlightTemplates
})(HighlightTemplates);
