import React, { Component } from "react";

import {
  Wrapper,
  Copy,
  Title,
  ContentfulRichText,
  Card,
  ModalOverlay,
  Modal,
  Bold,
  Row
} from "app/NativeComponents/common";

import {
  CloseButton,
  InlineButton,
  GhostButton
} from "app/NativeComponents/snippets";

import { loadOnboardingText } from "app/NativeActions";
import { store } from "app/store";
class StreetViewOnboardingText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      closed: false
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData("street_view_step");
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, contentful_loading, closed } = this.state;

    const colors = store.getState().settings.colors;

    if (contentful_content) {
      if (closed) {
        return (
          <Row style={{ justifyContent: "flex-end" }}>
            <InlineButton
              icon={"help"}
              textColor={colors.white_text_color}
              onPress={() => {
                this.setState({ closed: false });
              }}
            >
              Need Help?
            </InlineButton>
          </Row>
        );
      }
      return (
        <ModalOverlay
          onPress={() => {
            this.setState({ closed: true });
          }}
        >
          <Modal style={{ maxWidth: 500 }}>
            <Card style={{}}>
              <CloseButton
                onPress={() => {
                  this.setState({ closed: true });
                }}
              />
              <Wrapper style={{ padding: 25 }}>
                <Title style={{ marginBottom: 25 }}>
                  🚗 <Bold>Let’s Drive</Bold>
                </Title>
                <Copy style={{ marginBottom: 10 }}>
                  Use your mouse or arrow keys to move around the map and look
                  at properties. Property info will show in the bottom right
                  corner (Press the top to expand the panel).
                </Copy>
                <Copy style={{ marginBottom: 10 }}>
                  Once you find a property that meets your criteria, press{" "}
                  <Bold>"Add Lead."</Bold>
                </Copy>
                <Copy style={{ marginBottom: 10 }}>
                  You can add your own property if you are just trying this out.
                </Copy>
              </Wrapper>
              <GhostButton
                button_type={"full"}
                style={{ marginBottom: 10 }}
                onPress={() => {
                  this.setState({ closed: true });
                }}
              >
                Start Exploring
              </GhostButton>
            </Card>
          </Modal>
        </ModalOverlay>
      );
    }
    return null;
  }
}

export default StreetViewOnboardingText;
