import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem
} from "app/NativeComponents/snippets";
import { checkIfUserHasMetadata, numberWithCommas } from "app/NativeActions";
import Mortgage from "./Mortgage";
import LandInfo from "./LandInfo";
import PropertyCharacteristics from "./PropertyCharacteristics";
import TaxInformation from "./TaxInformation";
import History from "./History";
import Repairs from "./Repairs";
import HOA from "./HOA";
import Liens from "./Liens";
import CustomFields from "./CustomFields";
import OwnerInfo from "../OwnerInfo";
import Foreclosures from "./Foreclosures";

class PropertyInfoNew extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { property, search, colors } = this.props;
    return (
      <Wrapper>
        {this.props.include_more_data ? (
          <OwnerInfo
            {...this.props}
            property={property}
            no_start_mail_button={true}
            property_loading={this.props.property_loading}
          />
        ) : null}
        <Mortgage {...this.props} />
        <Foreclosures {...this.props} />
        <Liens {...this.props} />
        <PropertyCharacteristics {...this.props} />
        <LandInfo {...this.props} />
        <TaxInformation {...this.props} />
        <History {...this.props} />

        <Repairs {...this.props} />
        <HOA {...this.props} />
        {property.deal &&
        this.props.include_custom_fields &&
        this.props.user?.team_clearance_level > 0 ? (
          <CustomFields property={property} {...this.props} search={search} />
        ) : null}

        <Wrapper style={{ height: 100 }} />
      </Wrapper>
    );
  }
}

export default PropertyInfoNew;
