import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Bold,
  Icon,
  Button,
  Row,
  Input,
  Scroll
} from "app/NativeComponents/common";
import {
  DateSelector,
  DateBox,
  PopoverMenu,
  PillButton,
  SearchBar,
  SelectItem,
  InlineButton,
  InputBox,
  Tag,
  CheckBoxInput
} from "app/NativeComponents/snippets";
import moment from "moment";

class EqualOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      add_value: "",
      search: "",
      filter_search: "",
      filtered_value: "",
      additional_condition_values: {}
    };

    this._input_ref_1 = React.createRef();
    this._input_ref_2 = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.filter.additional_conditions &&
      this.props.filter.additional_conditions.length > 0 &&
      this.props.additional_condition_values === null
    ) {
      const initAddtlConditionValues =
        this.props.filter.additional_conditions.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.slug]: curr.default
          }),
          {}
        );
      this.setState({ additional_condition_values: initAddtlConditionValues });
    } else {
      this.setState({
        additional_condition_values: this.props.additional_condition_values
      });
    }
  }

  renderAddtionalConditionInput = addtlCondition => {
    switch (addtlCondition?.type) {
      case "boolean":
        return (
          <CheckBoxInput
            onPress={() =>
              //toggle value found in additional_condition_values object
              this.setState(prevState => ({
                additional_condition_values: {
                  ...prevState.additional_condition_values,
                  [addtlCondition.slug]:
                    !prevState.additional_condition_values[addtlCondition.slug]
                }
              }))
            }
            title={addtlCondition.label}
            value={this.state.additional_condition_values[addtlCondition.slug]}
            titleStyle={{ fontSize: 12 }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      onPress,
      title,
      is_active,
      slug,
      colors,
      allow_options = false,
      allow_input = false,
      selected_input = "",
      selected_date_object,
      available_options = [],

      selected_options = [],
      input_type = "text",
      filter,
      selected_filter
    } = this.props;

    const { search, filter_search, additional_condition_values } = this.state;
    return (
      <SelectItem
        selected={is_active}
        select_type="radio"
        onPress={onPress}
        require_confirm={true}
        confirm_disabled={
          is_active &&
          (!!selected_input ||
            selected_options.length > 0 ||
            !!this.state.add_value ||
            ((slug == "is_between" || slug == "is_not_between") &&
              !!selected_date_object.date_1 &&
              !!selected_date_object.date_2) ||
            ((slug == "is_more_than_generated_date" ||
              slug == "is_less_than_generated_date") &&
              !!selected_date_object.date_type &&
              !!selected_date_object.time_type &&
              parseInt(selected_date_object.date_amount) > 0) ||
            slug == "is_known" ||
            slug == "is_unknown")
            ? false
            : true
        }
        onConfirm={() => {
          let selected = selected_options;

          if (!!this.state.add_value) {
            selected.push({
              value: this.state.add_value,
              label: this.state.add_value
            });
          }

          if (selected.length == 0) {
            selected = [
              {
                value: selected_input,
                label: selected_input
              }
            ];
          }
          this.props.addFilter({
            label: filter.label,
            key: selected_filter,
            equal_type: slug,
            type: filter.type,
            values: selected,
            date_object: selected_date_object,
            additional_condition_values: additional_condition_values
          });
        }}
        renderBottom={() => {
          if (allow_input && is_active) {
            return (
              <>
                {slug == "is_between" || slug == "is_not_between" ? (
                  <Wrapper>
                    {input_type == "number" ? (
                      <InputBox
                        input_ref={this._input_ref_1}
                        name="add_value"
                        disabled={false}
                        returnKeyType="done"
                        placeholder="Enter a value"
                        onFocus={() => {}}
                        autoFocus={true}
                        onChange={value => {
                          this.props.updateSelectedDateObject({
                            ...this.props.selected_date_object,
                            date_1: value
                          });
                        }}
                        blurOnSubmit={true}
                        value={this.props.selected_date_object?.date_1}
                        input_type={input_type == "number" ? "number" : "text"}
                        selected_background_color={"transparent"}
                        require_confirm={false}
                        show_buttons={false}
                        has_changed={!!this.props.selected_date_object?.date_1}
                      />
                    ) : (
                      <DateBox
                        input_ref={this._input_ref_1}
                        name="select_date"
                        disabled={false}
                        placeholder="Select a date"
                        onFocus={() => {}}
                        onChange={value =>
                          this.props.updateSelectedDateObject({
                            ...this.props.selected_date_object,
                            date_1: value
                          })
                        }
                        value={
                          !!this.props.selected_date_object?.date_1
                            ? moment(
                                this.props.selected_date_object?.date_1
                              ).format("MMM Do, YYYY")
                            : "--"
                        }
                        date_value={
                          !!this.props.selected_date_object?.date_1
                            ? this.props.selected_date_object?.date_1
                            : moment().format("YYYY-MM-DD")
                        }
                        selected_background_color={"transparent"}
                      />
                    )}

                    <Wrapper
                      style={{
                        padding: 20,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      <Copy>
                        <Bold>and</Bold>
                      </Copy>
                    </Wrapper>
                    {input_type == "number" ? (
                      <InputBox
                        input_ref={this._input_ref_2}
                        name="add_value"
                        disabled={false}
                        returnKeyType="done"
                        placeholder="Enter a value"
                        onFocus={() => {}}
                        autoFocus={false}
                        onChange={value => {
                          this.props.updateSelectedDateObject({
                            ...this.props.selected_date_object,
                            date_2: value
                          });
                        }}
                        blurOnSubmit={true}
                        value={this.props.selected_date_object?.date_2}
                        input_type={input_type == "number" ? "number" : "text"}
                        selected_background_color={"transparent"}
                        require_confirm={false}
                        show_buttons={false}
                        has_changed={!!this.props.selected_date_object?.date_2}
                      />
                    ) : (
                      <DateBox
                        input_ref={this._input_ref_2}
                        name="select_date"
                        disabled={false}
                        placeholder="Select a date"
                        onFocus={() => {}}
                        onChange={value =>
                          this.props.updateSelectedDateObject({
                            ...this.props.selected_date_object,
                            date_2: value
                          })
                        }
                        value={
                          !!this.props.selected_date_object?.date_2
                            ? moment(
                                this.props.selected_date_object?.date_2
                              ).format("MMM Do, YYYY")
                            : "--"
                        }
                        date_value={
                          !!this.props.selected_date_object?.date_2
                            ? this.props.selected_date_object?.date_2
                            : moment().format("YYYY-MM-DD")
                        }
                        selected_background_color={"transparent"}
                      />
                    )}
                  </Wrapper>
                ) : slug == "is_more_than_generated_date" ||
                  slug == "is_less_than_generated_date" ? (
                  <Wrapper>
                    <Row>
                      <Wrapper style={{ padding: 20, flex: 1 }}>
                        <Input
                          ref="add_input"
                          name="add_input"
                          style={{ borderBottomWidth: 0 }}
                          input_style="dm-new-input"
                          returnKeyType="done"
                          blurOnSubmit={false}
                          autoCapitalize="words"
                          keyboardType="default"
                          placeholder={
                            this.props.selected_date_object.date_type == "days"
                              ? "Number of days"
                              : this.props.selected_date_object.date_type ==
                                "weeks"
                              ? "Number of weeks"
                              : this.props.selected_date_object.date_type ==
                                "months"
                              ? "Number of months"
                              : this.props.selected_date_object.date_type ==
                                "years"
                              ? "Number of years"
                              : "Amount"
                          }
                          onChange={value =>
                            this.props.updateSelectedDateObject({
                              ...this.props.selected_date_object,
                              date_amount: value
                            })
                          }
                          onSubmitEditing={() => {}}
                          value={this.props.selected_date_object.date_amount}
                          type={"number"}
                        />
                      </Wrapper>
                      <PopoverMenu
                        renderComponent={({ hovering }) => {
                          return (
                            <InlineButton
                              pressedIn={hovering}
                              icon="keyboard-arrow-down"
                              noPress={true}
                              style={{ marginLeft: 0 }}
                            >
                              {this.props.selected_date_object.date_type ==
                              "days"
                                ? "days"
                                : this.props.selected_date_object.date_type ==
                                  "weeks"
                                ? "weeks"
                                : this.props.selected_date_object.date_type ==
                                  "months"
                                ? "months"
                                : this.props.selected_date_object.date_type ==
                                  "years"
                                ? "years"
                                : ""}
                            </InlineButton>
                          );
                        }}
                        menu_items={[
                          {
                            title: "Days",
                            select_type: "radio",
                            selected:
                              this.props.selected_date_object.date_type ==
                              "days"
                                ? true
                                : false,

                            type: "normal",
                            onPress: () => {
                              this.props.updateSelectedDateObject({
                                ...this.props.selected_date_object,
                                date_type: "days"
                              });
                            }
                          },
                          {
                            title: "Weeks",
                            select_type: "radio",
                            selected:
                              this.props.selected_date_object.date_type ==
                              "weeks"
                                ? true
                                : false,

                            type: "normal",
                            onPress: () => {
                              this.props.updateSelectedDateObject({
                                ...this.props.selected_date_object,
                                date_type: "weeks"
                              });
                            }
                          },
                          {
                            title: "Months",
                            select_type: "radio",
                            selected:
                              this.props.selected_date_object.date_type ==
                              "months"
                                ? true
                                : false,

                            type: "normal",
                            onPress: () => {
                              this.props.updateSelectedDateObject({
                                ...this.props.selected_date_object,
                                date_type: "months"
                              });
                            }
                          },
                          {
                            title: "Years",
                            select_type: "radio",
                            selected:
                              this.props.selected_date_object.date_type ==
                              "years"
                                ? true
                                : false,

                            type: "normal",
                            onPress: () => {
                              this.props.updateSelectedDateObject({
                                ...this.props.selected_date_object,
                                date_type: "years"
                              });
                            }
                          }
                        ]}
                      />
                      <PopoverMenu
                        renderComponent={({ hovering }) => {
                          return (
                            <InlineButton
                              pressedIn={hovering}
                              icon="keyboard-arrow-down"
                              noPress={true}
                              style={{ marginLeft: 0 }}
                            >
                              {this.props.selected_date_object.time_type ==
                              "ago"
                                ? "ago"
                                : "from now"}
                            </InlineButton>
                          );
                        }}
                        menu_items={[
                          {
                            title: "Ago",
                            select_type: "radio",
                            selected:
                              this.props.selected_date_object.time_type == "ago"
                                ? true
                                : false,
                            type: "normal",
                            onPress: () => {
                              this.props.updateSelectedDateObject({
                                ...this.props.selected_date_object,
                                time_type: "ago"
                              });
                            }
                          },
                          {
                            title: "From Now",
                            select_type: "radio",
                            selected:
                              this.props.selected_date_object.time_type ==
                              "from_now"
                                ? true
                                : false,

                            type: "normal",
                            onPress: () => {
                              this.props.updateSelectedDateObject({
                                ...this.props.selected_date_object,
                                time_type: "from_now"
                              });
                            }
                          }
                        ]}
                      />
                    </Row>
                  </Wrapper>
                ) : input_type == "date" ? (
                  <DateBox
                    input_ref={this._input_ref_1}
                    name="select_date"
                    disabled={false}
                    placeholder="Select a date"
                    onFocus={() => {}}
                    onChange={value => this.props.updateSelectedInput(value)}
                    value={
                      !!selected_input
                        ? moment(selected_input).format("MMM Do, YYYY")
                        : "--"
                    }
                    date_value={
                      !!selected_input
                        ? selected_input
                        : moment().format("YYYY-MM-DD")
                    }
                    selected_background_color={"transparent"}
                  />
                ) : (
                  <>
                    <InputBox
                      input_ref={this._input_ref_1}
                      name="add_value"
                      disabled={false}
                      returnKeyType="done"
                      placeholder="Enter a value"
                      onFocus={() => {}}
                      autoFocus={true}
                      onChange={value => this.props.updateSelectedInput(value)}
                      blurOnSubmit={true}
                      value={selected_input}
                      input_type={input_type == "number" ? "number" : "text"}
                      selected_background_color={"transparent"}
                      require_confirm={false}
                      show_buttons={false}
                      has_changed={!!selected_input}
                    />
                  </>
                )}
                {/* Render additional conditions */}
                {filter.additional_conditions &&
                filter.additional_conditions.length > 0
                  ? filter.additional_conditions.map(
                      this.renderAddtionalConditionInput
                    )
                  : null}
              </>
            );
          } else if (allow_options && is_active) {
            return (
              <>
                {selected_options.length > 0 ? (
                  <Row
                    style={{
                      flexWrap: "wrap",
                      padding: 20,
                      paddingTop: 10,
                      paddingBottom: 0
                    }}
                  >
                    {selected_options.map((option, i) => {
                      return (
                        <Tag
                          key={"selected_option_" + i}
                          onPress={() => {
                            this.props.removeSelectedOption({
                              value: option.value,
                              label: option.label
                            });
                          }}
                          icon={"close"}
                        >
                          {option.label}
                        </Tag>
                      );
                    })}
                  </Row>
                ) : null}

                {available_options.length == 0 ? (
                  <InputBox
                    input_ref={this._input_ref_1}
                    name="add_value"
                    disabled={false}
                    returnKeyType="done"
                    placeholder="Enter a value"
                    onFocus={() => {}}
                    onChange={value => {
                      this.setState({
                        add_value: value.replace(/,(?=[^\s])/g, ", ")
                      });
                    }}
                    autoFocus={true}
                    blurOnSubmit={true}
                    value={this.state.add_value.replace(/,(?=[^\s])/g, ", ")}
                    input_type="text"
                    selected_background_color={"transparent"}
                    single_form={true}
                    submit_button_icon="add"
                    submit_button_title="Add Value"
                    onSubmitEditing={() => {
                      this.props.addSelectedOption({
                        value: this.state.add_value,
                        label: this.state.add_value
                      });
                      this.setState({
                        add_value: ""
                      });
                    }}
                    onSubmit={() => {
                      this.props.addSelectedOption({
                        value: this.state.add_value,
                        label: this.state.add_value
                      });
                      this.setState({
                        add_value: ""
                      });
                    }}
                    has_changed={
                      this.state.add_value &&
                      !selected_options.includes(this.state.add_value)
                    }
                    require_confirm={true}
                    show_buttons={true}
                  />
                ) : null}
                {available_options ? (
                  <>
                    {filter.require_filter && !this.state.filtered_value ? (
                      <>
                        <Wrapper style={{ padding: 25 }}>
                          <Copy>{filter.filter_text}</Copy>
                        </Wrapper>
                        {available_options.length > 5 ? (
                          <SearchBar
                            title={"Search"}
                            onChange={value => {
                              this.setState({
                                filter_search: value
                              });
                            }}
                            value={filter_search}
                            style={{
                              margin: 10
                            }}
                          />
                        ) : null}
                        <>
                          {filter.filtered_options.map((option, i) => {
                            if (
                              filter.filtered_options.length <= 5 ||
                              !filter_search ||
                              option.label
                                .toLowerCase()
                                .includes(filter_search.toLowerCase())
                            ) {
                              return (
                                <SelectItem
                                  key={"filtered_option_" + i}
                                  select_type={"radio"}
                                  selected={
                                    this.state.filtered_value == option.value
                                  }
                                  onPress={() => {
                                    this.setState({
                                      filtered_value: option.value
                                    });
                                  }}
                                >
                                  {option.label}
                                </SelectItem>
                              );
                            }
                            return null;
                          })}
                        </>
                      </>
                    ) : filter.require_filter && !!this.state.filtered_value ? (
                      <>
                        {available_options.length > 5 ? (
                          <SearchBar
                            title={"Search"}
                            onChange={value => {
                              this.setState({
                                search: value
                              });
                            }}
                            value={search}
                            style={{
                              margin: 10
                            }}
                          />
                        ) : null}
                        <>
                          {available_options
                            .filter(
                              ({ filter }) =>
                                filter == this.state.filtered_value
                            )
                            .map((option, i) => {
                              let in_selected_options = false;
                              for (
                                let i = 0;
                                i < selected_options.length;
                                i++
                              ) {
                                if (option.value == selected_options[i].value) {
                                  in_selected_options = true;
                                }
                              }
                              if (
                                available_options.length <= 5 ||
                                !search ||
                                option.label
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return (
                                  <SelectItem
                                    key={"available_option_" + i}
                                    select_type={"check"}
                                    selected={in_selected_options}
                                    onPress={() => {
                                      if (in_selected_options) {
                                        this.props.removeSelectedOption({
                                          value: option.value,
                                          label: option.label
                                        });
                                      } else {
                                        this.props.addSelectedOption({
                                          value: option.value,
                                          label: option.label
                                        });
                                      }
                                    }}
                                    subtext={
                                      option.description
                                        ? option.description
                                        : !!option.subtitle
                                        ? option.subtitle
                                        : ""
                                    }
                                  >
                                    {option.label}
                                  </SelectItem>
                                );
                              }
                              return null;
                            })}
                        </>
                      </>
                    ) : (
                      <>
                        {available_options.length > 5 ? (
                          <SearchBar
                            title={"Search"}
                            onChange={value => {
                              this.setState({
                                search: value
                              });
                            }}
                            value={search}
                            style={{
                              margin: 10
                            }}
                          />
                        ) : null}
                        <>
                          {available_options.map((option, i) => {
                            let in_selected_options = false;
                            for (let i = 0; i < selected_options.length; i++) {
                              if (option.value == selected_options[i].value) {
                                in_selected_options = true;
                              }
                            }
                            if (
                              available_options.length <= 5 ||
                              !search ||
                              option.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return (
                                <SelectItem
                                  key={"available_option_" + i}
                                  select_type={"check"}
                                  selected={in_selected_options}
                                  onPress={() => {
                                    if (in_selected_options) {
                                      this.props.removeSelectedOption({
                                        value: option.value,
                                        label: option.label
                                      });
                                    } else {
                                      this.props.addSelectedOption({
                                        value: option.value,
                                        label: option.label
                                      });
                                    }
                                  }}
                                  subtext={
                                    option.description ? option.description : ""
                                  }
                                >
                                  {option.label}
                                </SelectItem>
                              );
                            }
                            return null;
                          })}
                        </>
                      </>
                    )}
                  </>
                ) : null}
                {/* Render additional conditions */}
                {filter.additional_conditions &&
                filter.additional_conditions.length > 0
                  ? filter.additional_conditions.map(
                      this.renderAddtionalConditionInput
                    )
                  : null}
              </>
            );
          }

          return null;
        }}
      >
        {title}
      </SelectItem>
    );
  }
}

export default EqualOption;
