import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Page,
  Row,
  Copy,
  Wrapper,
  ProgressBar
} from "app/NativeComponents/common";

import {
  logout,
  startRedirect,
  updateUser,
  submitMarketingForm
} from "app/NativeActions";

import HowDidWeFallShort from "./HowDidWeFallShort";
import DidWeDoAnythingWell from "./DidWeDoAnythingWell";
import PauseOffer from "./PauseOffer";
import MarketingOffer from "./MarketingOffer";
import SalvageOffer from "./SalvageOffer";
import AnythingElse from "./AnythingElse";
import CallOffer from "./CallOffer";

import { InlineButton, OnboardingWrapper } from "app/NativeComponents/snippets";
import NavigationService from "app/Router/NavigationService";

class ChurnQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancel_step: "initial_step",
      needs_to_learn: false,
      needs_to_chat: false,
      salvage_offer: false,
      pause_offer: false,
      needs_budget: false,
      accepted_an_offer: false,
      progress: 1,
      total_steps: 4,
      next_step_loading: false
    };

    this.changeChurnStep = this.changeChurnStep.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.needsToLearn = this.needsToLearn.bind(this);
    this.needsToChat = this.needsToChat.bind(this);
    this.needsBudget = this.needsBudget.bind(this);
    this.salvageOffer = this.salvageOffer.bind(this);
    this.pauseOffer = this.pauseOffer.bind(this);

    this.nextStep = this.nextStep.bind(this);
    this.lastStep = this.lastStep.bind(this);

    this.acceptedAnOffer = this.acceptedAnOffer.bind(this);
  }

  changeChurnStep = step => {
    this.setState({ cancel_step: step });
  };

  needsToLearn = () => {
    this.setState({ needs_to_learn: true });
  };

  needsToChat = () => {
    this.setState({ needs_to_chat: true });
  };

  salvageOffer = () => {
    this.setState({ salvage_offer: true });
  };

  pauseOffer = () => {
    this.setState({ pause_offer: true });
  };

  needsBudget = toggle => {
    this.setState({ needs_budget: toggle });
  };
  acceptedAnOffer = () => {
    this.setState({ accepted_an_offer: true });
  };

  componentDidMount() {
    if (this.props.device === "mobile") {
      NavigationService.setTopLevelNavigator(this.props.navigation);
    }

    const { user } = this.props;
  }

  submitAnswer({ property_slug, property_value }) {
    if (property_slug === "how_did_we_fall_short") {
      if (
        property_value.includes(
          "I need to learn more about real estate first"
        ) ||
        property_value.includes("Not useful right now")
      ) {
        this.needsToLearn();
      }

      if (property_value.includes("I don't have the budget right now")) {
        this.needsBudget(true);
      }

      if (property_value.includes("Didn't see the value")) {
        this.salvageOffer();
      }
      if (property_value.includes("Poor support")) {
        this.needsToChat();
      }
    }

    if (property_slug === "did_we_do_anything_well") {
      if (property_value.includes("Many things - I'll be back")) {
        this.pauseOffer();
      }
      if (property_value.includes("Good value")) {
        this.salvageOffer();
      }
      if (property_value.includes("Poor support")) {
        this.needsToChat();
      }
    }

    this.props.submitMarketingForm({
      token: this.props.token,
      type: "collect_churn_data",
      property_slug,
      property_value
    });
  }

  lastStep() {
    const { progress } = this.state;

    this.setState({
      progress: progress > 1 ? progress - 1 : 1
    });
  }

  nextStep() {
    const { total_steps, progress } = this.state;
    if (progress > total_steps) {
    } else {
      this.setState({
        progress: progress + 1
      });
    }
  }

  renderQuestion() {
    const {
      progress,
      needs_to_learn,
      needs_to_chat,
      pause_offer,
      needs_budget,
      salvage_offer
    } = this.state;

    switch (progress) {
      case 5:
        return (
          <AnythingElse
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
            acceptedAnOffer={this.acceptedAnOffer}
            accepted_an_offer={this.state.accepted_an_offer}
          />
        );
      case 4:
        if (needs_budget && (needs_to_learn || pause_offer)) {
          return (
            <MarketingOffer
              nextStep={this.nextStep}
              lastStep={this.lastStep}
              submitAnswer={this.submitAnswer}
              next_step_loading={this.state.next_step_loading}
              acceptedAnOffer={this.acceptedAnOffer}
              accepted_an_offer={this.state.accepted_an_offer}
              needsBudget={this.needsBudget}
            />
          );
        }
        //salvage
        return (
          <SalvageOffer
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
            acceptedAnOffer={this.acceptedAnOffer}
            accepted_an_offer={this.state.accepted_an_offer}
          />
        );

      case 3:
        if (needs_to_learn || pause_offer) {
          return (
            <PauseOffer
              learning={needs_to_learn}
              nextStep={this.nextStep}
              lastStep={this.lastStep}
              submitAnswer={this.submitAnswer}
              next_step_loading={this.state.next_step_loading}
              acceptedAnOffer={this.acceptedAnOffer}
              accepted_an_offer={this.state.accepted_an_offer}
            />
          );
        }

        if (needs_budget) {
          return (
            <MarketingOffer
              nextStep={this.nextStep}
              lastStep={this.lastStep}
              submitAnswer={this.submitAnswer}
              next_step_loading={this.state.next_step_loading}
              acceptedAnOffer={this.acceptedAnOffer}
              accepted_an_offer={this.state.accepted_an_offer}
              needsBudget={this.needsBudget}
            />
          );
        }

        //chat
        return (
          <CallOffer
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
            acceptedAnOffer={this.acceptedAnOffer}
            accepted_an_offer={this.state.accepted_an_offer}
          />
        );

      case 2:
        return (
          <DidWeDoAnythingWell
            nextStep={this.nextStep}
            lastStep={this.lastStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
          />
        );

      default:
        return (
          <HowDidWeFallShort
            nextStep={this.nextStep}
            submitAnswer={this.submitAnswer}
            next_step_loading={this.state.next_step_loading}
          />
        );
    }
  }

  render() {
    const { colors } = this.props;

    return (
      <Page
        metaTitle={"We're sorry to see you go - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper
          renderInner={() => {
            return (
              <>
                {this.state.progress > 1 && !this.state.accepted_an_offer ? (
                  <Row>
                    <InlineButton icon={"arrow-back"} onPress={this.lastStep}>
                      Go Back
                    </InlineButton>
                  </Row>
                ) : null}
                {!this.state.accepted_an_offer &&
                this.state.progress <= this.state.total_steps ? (
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: 25
                    }}
                  >
                    <Copy style={{ textAlign: "center" }}>
                      Question {this.state.progress} of {this.state.total_steps}
                      .
                    </Copy>
                    <ProgressBar
                      style={{ marginTop: 10 }}
                      color={colors.active_color}
                      backgroundColor={colors.white_color}
                      width={200}
                      progress={
                        parseInt(this.state.progress) / this.state.total_steps
                      }
                    />
                  </Wrapper>
                ) : null}
              </>
            );
          }}
        >
          {this.renderQuestion()}
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  submitMarketingForm
})(ChurnQuestions);
