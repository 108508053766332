import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Wrapper, KeyboardView } from "app/NativeComponents/common";
import {
  List,
  SpinWrapper,
  ConfirmLabel,
  NewHeader
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  setAppliedFilter,
  pushSidePanel,
  changeTab,
  tabNavigation,
  setActiveList,
  selectActiveProperty,
  popSidePanel,
  getLeads
} from "app/NativeActions";

import LeadItem from "app/DealMachineCore/reuseable/LeadItem";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";

class ViewLeads extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.getProperties = this.getProperties.bind(this);
    this.pressProperty = this.pressProperty.bind(this);
  }

  componentDidMount() {
    this.getProperties({ load_type: "refresh" });
  }

  componentDidUpdate(prevProps) {}

  pressProperty(property) {
    this.props.selectActiveProperty(null, property);

    this.props.pushSidePanel({
      slug: "property",
      id: property?.property_id,
      focus_side_panel:
        this.props.user?.user_settings?.property_expanded === "not_expanded"
          ? false
          : true,
      data: {
        property
      }
    });
  }

  getProperties({ load_type = "load", page = 1 }) {
    const {
      token,
      list_properties_refreshing,
      list_properties_loading,
      list_properties_begin,
      list_properties_limit,
      list_properties_page
    } = this.props;

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (!list_properties_refreshing && !list_properties_loading) {
      this.props.getLeads({
        token,
        load_type: load_type,
        sort_by: "date_created_desc",
        search: "",
        list_id: "all_leads_and_in_trash",
        filters: panel_data?.filters ? panel_data?.filters : null,
        begin:
          load_type === "load_more"
            ? list_properties_begin
            : page
            ? list_properties_limit * (page - 1)
            : list_properties_limit * (list_properties_page - 1),
        limit: list_properties_limit,
        page: page,
        onLoading: () => {},
        onError: () => {},
        onSuccess: () => {}
      });
    }
  }

  render() {
    const {
      user,
      colors,

      list_properties,
      list_properties_loading,
      list_properties_loaded_all,
      list_properties_refreshing,

      applied_filter,
      filtered_count,
      filtered_count_loading
    } = this.props;

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    return (
      <Container>
        <NewHeader
          title={"Showing Leads"}
          subtitle={panel_data?.subtitle}
          leftButton={{
            onPress: () => {
              this.props.popSidePanel();
            },
            icon: this.props.isMobile ? "arrow-back" : "close"
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {panel_data?.filters ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              renderLabel={() => {
                return (
                  <Wrapper>
                    <ShowcaseFilters
                      filters={panel_data?.filters?.data}
                      andor_type={panel_data?.filters?.andor_type}
                      editable={false}
                      text_only={true}
                    />
                  </Wrapper>
                );
              }}
            />
          ) : null}

          {((list_properties_loading && list_properties.length == 0) ||
            list_properties_refreshing) &&
          !this.props.search_loading ? (
            <Wrapper style={{ flex: 1 }}>
              <SpinWrapper text="Loading leads..." />
            </Wrapper>
          ) : (
            <List
              rowNumber={1}
              items={list_properties}
              infiniteScroll={true}
              itemStructure={({ item, index }) => {
                return (
                  <LeadItem
                    key={"lead_" + item.property_id}
                    property={item}
                    onPress={() => {
                      this.pressProperty(item);
                    }}
                  />
                );
              }}
              onRefresh={() => {
                this.getProperties({ load_type: "refresh" });
              }}
              is_refreshing={list_properties_refreshing}
              canLoadMore={
                !list_properties_loaded_all &&
                !list_properties_loading &&
                !list_properties_refreshing &&
                list_properties.length > 0
              }
              isLoadingMore={
                list_properties_loading &&
                !list_properties_refreshing &&
                list_properties.length > 0
              }
              onLoadMore={() => {
                this.getProperties({ load_type: "load_more" });
              }}
            />
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, lead, filter, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;

  const {
    list_properties,
    list_properties_begin,
    list_properties_page,
    list_properties_limit,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all
  } = lead;

  return {
    token,
    user,
    colors,
    list_properties,
    list_properties_begin,
    list_properties_page,
    list_properties_limit,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all,
    isMobile,
    device
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setAppliedFilter,
  changeTab,
  tabNavigation,
  setActiveList,
  pushSidePanel,
  selectActiveProperty,
  popSidePanel,
  getLeads
})(ViewLeads);
