import React, { Component } from "react";

import {
  Card,
  CardBody,
  PrimaryButton,
  SecondaryButton,
  Copy,
  Bold,
  Title,
  Wrapper
} from "app/NativeComponents/common";

import { PillButton } from "app/NativeComponents/snippets";

import Typist from "react-typist";

class Scene2 extends Component {
  renderText() {
    if (this.props.scene == 2) {
      return (
        <div style={{ lineHeight: "18px" }}>
          <Typist>
            <b>@DavidLecko</b> make sure to follow up with this lead!
          </Typist>
        </div>
      );
    }
    return (
      <div style={{ lineHeight: "18px" }}>
        <b>@DavidLecko</b> make sure to follow up with this lead!
      </div>
    );
  }

  render() {
    if (
      this.props.scene == 2 ||
      this.props.scene == 3 ||
      this.props.scene == 4
    ) {
      return (
        <div className={this.props.scene == 4 ? "animated fadeOutDown" : ""}>
          <Card>
            <CardBody
              style={{
                padding: "10px 15px"
              }}
            >
              <Wrapper
                name={"message"}
                style={{
                  flex: 1,
                  fontSize: 14,
                  maxHeight: 120,
                  marginRight: 0,
                  marginLeft: 0,
                  color: "rgb(99, 89, 92)",
                  height: 75
                }}
              >
                {this.renderText()}
              </Wrapper>
            </CardBody>

            <CardBody
              style={{
                padding: "10px 15px",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <div className={this.props.scene == 3 ? "animated pulse" : ""}>
                <PillButton
                  primary={true}
                  style={{ margin: 0 }}
                  innerStyle={{ padding: 5, paddingRight: 10, paddingLeft: 10 }}
                  onPress={() => {}}
                >
                  Post
                </PillButton>
              </div>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene2;
