import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  IconButton,
  SelectItem,
  CircleButton
} from "app/NativeComponents/snippets";
import { formatAddress, copyToClipBoard } from "app/NativeActions";

class MailingAddressItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  render() {
    const { item, colors, property } = this.props;
    const formatted_address = formatAddress({
      address: {
        address: item.address,
        address2: item.address2,
        address_city: item.city,
        address_state: item.state,
        address_zip: item.zip
      }
    });

    return (
      <SelectItem
        icon_color={colors.light_text_color}
        selected={item.send_to_address == 1}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "edit_mailing_address",
            overlay: true,
            data: {
              property,
              mailing_address: item,
              propertyUpdated: this.props.propertyUpdated
            }
          });
          this.props.toggleShow(false);
        }}
        label={!!item?.address_label ? item?.address_label : ""}
        description={item?.full_address}
        innerStyle={{ paddingLeft: 15 }}
        select_tooltip={"Toggle Sending Mail"}
        select_type="custom"
        renderCustomSelect={() => {
          return (
            <IconButton
              icon={
                item.send_to_address == 1
                  ? "check-box"
                  : "check-box-outline-blank"
              }
              tooltip={"Toggle Sending Mail"}
              tooltipPlacement="top"
              loading={this.state.loading}
              onPress={() => {
                this.props.updateOwnerInfo({
                  token: this.props.token,
                  deal_id: property.deal.id,
                  owner_mailing_address_id: item.owner_mailing_address_id,
                  type: "toggle_owner_address",
                  send_to_address: item.send_to_address == 1 ? 0 : 1,

                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: results => {
                    if (
                      this.props.propertyUpdated &&
                      results?.properties?.length == 1
                    ) {
                      this.props.propertyUpdated(results.properties[0]);
                    }
                    this.setState({
                      loading: false
                    });
                  }
                });
              }}
            />
          );
        }}
        renderRight={() => {
          return (
            <Row>
              <CircleButton
                icon="content-copy"
                tooltip={"Copy Mailing Address"}
                tooltipPlacement="top"
                onPress={() => {
                  copyToClipBoard(item?.full_address);
                  this.props.showSuccess(
                    item?.full_address + " was copied to your clipboard",
                    "Copied"
                  );
                }}
              />

              <IconButton
                button_type="small"
                noPress
                icon="keyboard-arrow-right"
              />
            </Row>
          );
        }}
      >
        {item?.name}
      </SelectItem>
    );
  }
}

export default MailingAddressItem;
