import React from "react";
import { store } from "app/store";
import ReactMarkdown from "react-markdown";

const HTMLMarkdown = ({ source, escapeHtml, style, fontStyle }) => {
  function replaceDashesAndAsterisksWithUnicode(str) {
    const specialChars = /[-*+=~_]/g;
    const unicodeMap = {
      "-": "&#8211;", // hyphen-minus
      "*": "&#42;", // asterisk
      "+": "&#43;", // plus
      "=": "&#61;", // equals
      "~": "&#126;", // tilde
      _: "&#95;" // underscore
      // Add any other characters and their Unicode values to this object
    };
    return str.replace(specialChars, match => unicodeMap[match] || "");
  }

  return (
    <div
      style={{ ...style, ...fontStyle }}
      dangerouslySetInnerHTML={{
        __html: source
      }}
    />
  );
};

export { HTMLMarkdown };
