import React, { Component } from "react";
import NativeBottomSlider from "app/NativeComponents/components/NativeBottomSlider";
import MapPropertyItem from "./MapPropertyItem";
import RouteItem from "./RouteItem";
import MultipleProperties from "./MultipleProperties";
import { Wrapper } from "app/NativeComponents/common";
import { PullUpSheet } from "app/NativeComponents/snippets";
class BottomSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      show_route: true,
      show_next_property: false,
    };

    this.toggleShow = this.toggleShow.bind(this);
    this.toggleRouteShow = this.toggleRouteShow.bind(this);
    this.toggleNextPropertyShow = this.toggleNextPropertyShow.bind(this);
  }
  toggleShow(toggle) {
    if (!toggle) {
      this.props.selectActiveProperty(this.props.token, null);
      this.props.selectActiveCoordinates(null);
      this.props.selectMultipleProperties(null);
    }

    this.setState({
      show: toggle,
    });
  }

  toggleRouteShow(toggle) {
    if (!toggle) {
      this.props.selectActiveRoute(null);
    }
    this.setState({
      show_route: toggle,
    });
  }

  toggleNextPropertyShow(toggle) {
    if (!toggle) {
    }
    this.setState({
      show_next_property: toggle,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.property &&
        this.props.property?.property_id !== prevProps.property?.property_id) ||
      (this.props.coordinates &&
        (this.props?.coordinates?.latitude !==
          prevProps?.coordinates?.latitude ||
          this.props?.coordinates?.longitude !==
            prevProps?.coordinates?.longitude))
    ) {
      this.toggleShow(true);
    }

    if (
      this.props.route &&
      (this.props.route?.id !== prevProps.route?.id ||
        this.props.route?.route_type !== prevProps.route?.route_type)
    ) {
      this.toggleRouteShow(true);
    }

    if (
      (!this.props.property &&
        !this.props.coordinates &&
        !this.props.route &&
        !this.props.selected_active_properties &&
        prevProps.property) ||
      (!this.props.coordinates &&
        !this.props.property &&
        !this.props.route &&
        !this.props.selected_active_properties &&
        prevProps.coordinates)
    ) {
      this.toggleShow(false);
    }

    if (
      !this.props.route &&
      !this.props.property &&
      !this.props.coordinates &&
      !this.props.selected_active_properties &&
      prevProps.route
    ) {
      this.toggleRouteShow(false);
    }
  }

  render() {
    const {
      property,
      coordinates,
      route,
      next_planned_property,
      selected_active_properties,
      show_street_button = true,
      colors,
    } = this.props;
    const { show, show_route, show_selected_properties } = this.state;

    return (
      <>
        {next_planned_property &&
        !this.props.route_plan_completed &&
        this.props.route_plan_active &&
        !property &&
        !coordinates ? (
          <NativeBottomSlider
            show={this.state.show_next_property}
            toggleShow={this.toggleNextPropertyShow}
            extend={true}
            includeCloseButton={false}
            colors={colors}
            noClose={true}
            renderComponent={({
              positionIndex,
              setSliderHeight,
              setTagsHeight,
            }) => {
              return (
                <MapPropertyItem
                  index={positionIndex}
                  property={next_planned_property}
                  coordinates={coordinates}
                  updateSliderHeight={setSliderHeight}
                  updateTagsHeight={setTagsHeight}
                  next_property={true}
                  route_plan_active={this.props.route_plan_active}
                  show_street_button={false}
                />
              );
            }}
          />
        ) : (property || coordinates) && show ? (
          <NativeBottomSlider
            show={this.state.show}
            toggleShow={this.toggleShow}
            extend={true}
            colors={colors}
            includeCloseButton={true}
            renderComponent={({ positionIndex, setSliderHeight }) => {
              return (
                <MapPropertyItem
                  index={positionIndex}
                  property={property}
                  coordinates={coordinates}
                  updateSliderHeight={setSliderHeight}
                  show_street_button={show_street_button}
                  onboarding_map={this.props.onboarding_map}
                  show_start_driving={this.props.show_start_driving}
                  includeImageOnPropertyPress={
                    this.props.includeImageOnPropertyPress
                  }
                />
              );
            }}
          />
        ) : route && show_route ? (
          <NativeBottomSlider
            toggleShow={this.toggleRouteShow}
            extend={true}
            colors={colors}
            renderComponent={({ positionIndex, setSliderHeight }) => {
              return (
                <RouteItem
                  index={positionIndex}
                  route={route}
                  updateSliderHeight={setSliderHeight}
                />
              );
            }}
          />
        ) : selected_active_properties ? (
          <PullUpSheet
            show={true}
            start_in_middle={false}
            use_index={this.state.pull_up_index}
            onShow={(index) => {}}
            onMiddle={(index) => {}}
            onHide={(index) => {
              this.props.selectMultipleProperties(null);
            }}
            scroll_to_hide={true}
            bottom={1}
            top={"75%"}
            renderMobileHandle={() => {
              return (
                <>
                  <Wrapper
                    style={{
                      borderWidth: 1,
                      backgroundColor: colors.popover_color,
                      borderColor: colors.border_color,
                      borderStyle: "solid",
                      borderTopLeftRadius: 30,
                      borderTopRightRadius: 30,
                      borderBottomWidth: 0,
                      paddingBottom: 0,

                      shadowColor: "rgba(0,0,0,0.05)",
                      shadowOffset: { width: 0, height: -5 }, // Negative value for top shadow
                      shadowOpacity: 1,
                      shadowRadius: 4,
                    }}
                  >
                    <Wrapper
                      style={{
                        padding: 15,

                        height: 40,
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Wrapper
                        style={{
                          height: 8,
                          width: 60,
                          borderRadius: 4,
                          backgroundColor:
                            this.props.dark_mode === "dark_mode"
                              ? colors.card_color
                              : colors.gray_color,
                        }}
                      />
                    </Wrapper>
                  </Wrapper>
                </>
              );
            }}
            enablePanDownToClose={true}
          >
            <MultipleProperties
              selected_active_properties={selected_active_properties}
            />
          </PullUpSheet>
        ) : null}
      </>
    );

    return null;
  }
}

export default BottomSlider;
