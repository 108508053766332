import React, { Component } from "react";

const Markdown = require("react-markdown");

const TestimonialQuote = ({ testimonial }) => {
  if (testimonial.quote) {
    return <Markdown source={testimonial.quote} escapeHtml={false} />;
  }

  return <div />;
};

export default TestimonialQuote;
