import React, { Component } from "react";
import { Wrapper, RightPanelModal } from "app/NativeComponents/common";
import { InlineTabs } from "app/NativeComponents/snippets";
import Highlights from "./Highlights";
import Routes from "./Routes";
import ViewRoute from "./Routes/ViewRoute";
import Street from "./Street";
import MapOptions from "./MapOptions";
class MapSidePanel extends Component {
  renderPage(item) {
    switch (item) {
      case "street":
      case "street_view":
        return (
          <Street
            popMapSidePanel={this.props.popMapSidePanel}
            pushMapSidePanel={this.props.pushMapSidePanel}
            map_side_panel_pages={this.props.map_side_panel_pages}
            toggleExpanded={this.props.toggleExpanded}
            expanded={this.props.expanded}
          />
        );

      case "map_options":
        return (
          <MapOptions
            popMapSidePanel={this.props.popMapSidePanel}
            map_side_panel_pages={this.props.map_side_panel_pages}
          />
        );
      case "highlights":
        return (
          <Highlights
            popMapSidePanel={this.props.popMapSidePanel}
            map_side_panel_pages={this.props.map_side_panel_pages}
          />
        );
      case "view_route":
        return (
          <ViewRoute
            popMapSidePanel={this.props.popMapSidePanel}
            map_side_panel_pages={this.props.map_side_panel_pages}
          />
        );

      default:
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.start_pop_map_side_panel &&
      this.props.start_pop_map_side_panel !== prevProps.start_pop_map_side_panel
    ) {
      clearInterval(this._interval);
      this._interval = setTimeout(() => {
        this.props.completePopMapSidePanel();
      }, 500);
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    const {
      colors,
      isMobile,
      street_view_coords,
      map_side_panel_pages,
      start_pop_map_side_panel
    } = this.props;

    return (
      <Wrapper
        style={{
          width: isMobile
            ? "100%"
            : this.props.map_side_panel_pages[
                this.props.map_side_panel_pages.length - 1
              ] == "street_view" && this.props.expanded
            ? "100%"
            : 500,
          minWidth: 400,
          maxWidth: 1000,
          alignSelf: "stretch",
          backgroundColor: colors.card_color,
          borderRightWidth: 1,
          borderRightStyle: "solid",
          borderRightColor: colors.border_color,
          position: "relative",
          justifyContent: "flex-end"
        }}
      >
        <RightPanelModal
          containerStyle={{
            position: "absolute",
            flex: "1",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "auto"
          }}
          width={isMobile ? "100%" : 500}
          animation_type={"none"}
          style={{ backgroundColor: colors.card_color, boxShadow: "none" }}
        >
          <Routes
            pushMapSidePanel={this.props.pushMapSidePanel}
            select_drive_start={this.props.select_drive_start}
            toggleSelectDriveStart={this.props.toggleSelectDriveStart}
            updateLatLng={this.props.updateLatLng}
          />
        </RightPanelModal>
        <>
          {map_side_panel_pages.map((item, i) => {
            return (
              <RightPanelModal
                key={"slide_out_view_" + i}
                animation_type={
                  i == map_side_panel_pages.length - 1 &&
                  start_pop_map_side_panel
                    ? "slideOutRight"
                    : "slideInRight"
                }
                containerStyle={{
                  position: "absolute",
                  flex: "1",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  height: "auto"
                }}
                style={{
                  backgroundColor: colors.card_color,
                  boxShadow: "none"
                }}
                width={
                  (item == "street_view" && this.props.expanded) || isMobile
                    ? "100%"
                    : 500
                }
              >
                {this.renderPage(item)}
              </RightPanelModal>
            );
          })}
        </>
      </Wrapper>
    );
  }
}

export default MapSidePanel;
