import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";

class ViewPort extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    this.toggleButtons();
  }

  toggleButtons() {
    if (this.props.isMobile) {
      this.props.toggleLeadImages(true);
      this.props.toggleDrivingImages(true);
    } else {
      this.props.toggleLeadImages(false);
      this.props.toggleDrivingImages(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isMobile !== this.props.isMobile ||
      (this.props.user !== prevProps.user && prevProps.user === null)
    ) {
      this.toggleButtons();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.props.updateViewPort({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  render() {
    return null;
  }
}

export default ViewPort;
