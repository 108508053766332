import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Card,
  CardBody,
  Icon,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

class Scene1 extends Component {
  render() {
    if (this.props.scene == 1 || this.props.scene == 2) {
      return (
        <div
          className={
            this.props.scene == 2 ? "animated pulse" : "animated fadeInUp"
          }
        >
          <Card>
            <CardBody>
              <Row>
                <Wrapper
                  style={{
                    flex: 1
                  }}
                >
                  <Title>Export Your Routes</Title>
                </Wrapper>
                <Icon size={26} fa_icon={"download"} />
              </Row>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene1;
