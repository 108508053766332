import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ModalContainer,
  Card,
  Row,
  KeyboardView
} from "app/NativeComponents/common";
import { FocusModal } from "app/NativeComponents/snippets";
import TopNavBar from "./TopNavBar";
import SelectWorkflowTemplate from "./SelectWorkflowTemplate";

import WorkflowStepEditor from "./WorkflowStepEditor";
import MainWorkflowView from "./MainWorkflowView";
import BottomNav from "./BottomNav";
import {
  setModal,
  toggleModal,
  getWorkflowInfo,
  popSidePanel,
  updateWorkflow,
  getEnrollmentTemplates
} from "app/NativeActions";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

class WorkflowDesigner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workflow: {
        workflow_id: props.active_workflow?.workflow_id || "new_workflow",
        workflow_title: props.active_workflow?.workflow_title || "",
        category: props.active_workflow?.category || "leads",
        template_type:
          props.active_workflow?.template_type || "start_from_stratch",
        enrollment_triggers: props.active_workflow?.enrollment_triggers || {
          data: [],
          andor_type: "or"
        },
        workflow_steps: props.active_workflow?.workflow_steps || [],
        only_enroll_manually: props.active_workflow?.only_enroll_manually,
        workflow_on: props.active_workflow?.workflow_on || false
      },
      show_select_template:
        props.active_workflow?.workflow_id == "new_workflow" ? true : false,
      active_step: null,
      scale: 1,
      add_step_type: null
    };

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.checkIfCanSave = this.checkIfCanSave.bind(this);
    this.saveWorkflow = this.saveWorkflow.bind(this);
    this.updateWorkflowDesign = this.updateWorkflowDesign.bind(this);
    this.startNewWorkflow = this.startNewWorkflow.bind(this);
    this.updateWorkflowFromTemplate =
      this.updateWorkflowFromTemplate.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.setActiveStep = this.setActiveStep.bind(this);
    this.setNewStepType = this.setNewStepType.bind(this);
    this.completeUpdateStep = this.completeUpdateStep.bind(this);

    this.saveEnrollmentTriggers = this.saveEnrollmentTriggers.bind(this);
    this.addStep = this.addStep.bind(this);
    this.deleteStep = this.deleteStep.bind(this);
    this.saveStep = this.saveStep.bind(this);
  }

  componentDidMount() {
    const { workflow } = this.state;
    if (!!workflow.category) {
      this.props.getWorkflowInfo({
        token: this.props.token,
        type: "enrollment_object",
        category: workflow.category
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  onZoom(scale) {
    if (scale != 1) {
      this.setState({ scale, selected_layer: null });
    } else {
      this.setState({ scale });
    }
  }

  checkIfNeedsToSave() {
    const { workflow } = this.state;
    const { active_workflow } = this.props;

    if (
      workflow?.workflow_title != active_workflow.workflow_title ||
      workflow?.workflow_steps !== active_workflow.workflow_steps ||
      workflow?.enrollment_triggers !== active_workflow.enrollment_triggers ||
      workflow?.only_enroll_manually != active_workflow.only_enroll_manually ||
      workflow?.workflow_on != active_workflow.workflow_on
    ) {
      return true;
    }

    return false;
  }

  checkIfCanSave() {
    const { workflow } = this.state;
    if (
      !!workflow.workflow_title &&
      (workflow?.enrollment_triggers?.data.length > 0 ||
        workflow.only_enroll_manually) &&
      workflow?.workflow_steps.length > 1
    ) {
      let incomplete_step = false;
      for (let i = 0; i < workflow.workflow_steps.length; i++) {
        if (workflow.workflow_steps[i].type == "filter_branch") {
          if (workflow.workflow_steps[i]?.data.length > 0) {
            const branches = workflow.workflow_steps[i]?.data;
            for (let j = 0; j < branches.length; j++) {
              for (let k = 0; k < branches[j].steps.length; k++) {
                if (!branches[j].steps[k].data) {
                  incomplete_step = true;
                }
              }
            }
          } else {
            incomplete_step = true;
          }
        } else if (!workflow.workflow_steps[i].data) {
          incomplete_step = true;
        }
      }
      if (!incomplete_step) {
        return true;
      }
    }

    return false;
  }

  saveEnrollmentTriggers({ filters, only_enroll_manually }) {
    let new_workflow_steps = [];
    for (let i = 0; i < this.state.workflow.workflow_steps.length; i++) {
      new_workflow_steps.push(this.state.workflow.workflow_steps[i]);
    }

    for (let i = 0; i < new_workflow_steps.length; i++) {
      if (new_workflow_steps[i].type == "enrollment_triggers") {
        new_workflow_steps[i].data = {
          filters,
          only_enroll_manually
        };
      }
    }

    this.setState({
      workflow: {
        ...this.state.workflow,
        only_enroll_manually,
        enrollment_triggers: filters,
        workflow_steps: new_workflow_steps
      },
      active_step: null
    });
  }

  updateWorkflowDesign({ prop, value }) {
    this.setState({
      workflow: {
        ...this.state.workflow,
        [prop]: value
      }
    });
  }

  updateWorkflowFromTemplate(template) {
    this.setState({
      workflow: {
        ...this.state.workflow,
        category: template.category,
        template_type: template.template_type,
        enrollment_triggers: template.enrollment_triggers,
        workflow_steps: template.workflow_steps,
        only_enroll_manually: false
      },
      show_select_template: false,
      active_step: null,
      add_step_type: null
    });
  }

  setActiveStep({
    step = null,
    step_index = null,
    branch_index = null,
    branch_step_index = null
  }) {
    this.setState({
      active_step:
        this.state.active_step &&
        step_index == this.state.active_step?.step_index &&
        branch_index == this.state.active_step?.branch_index &&
        branch_step_index == this.state.active_step?.branch_step_index
          ? this.state.active_step
          : step
          ? {
              ...step,
              step_index,
              branch_index,
              branch_step_index
            }
          : step,
      add_step_type: null
    });
  }

  setNewStepType(add_step_type = null) {
    this.setState({
      active_step: null,
      add_step_type:
        this.state.add_step_type &&
        add_step_type?.step_index == this.state.add_step_type?.step_index &&
        add_step_type?.branch_index == this.state.add_step_type?.branch_index &&
        add_step_type?.branch_step_index ==
          this.state.add_step_type?.branch_step_index
          ? this.state.add_step_type
          : add_step_type
    });
  }

  startNewWorkflow(category) {
    const trigger_workflow_step = {
      id: "enrollment_triggers_step",
      type: "enrollment_triggers",
      data: [],
      step_index: 0,
      branch_index: null,
      branch_step_index: null
    };

    this.setState({
      workflow: {
        ...this.state.workflow,
        workflow_steps: [trigger_workflow_step]
      },
      active_step: trigger_workflow_step,
      show_select_template: false
    });
  }

  addStep({
    step,
    step_index = 0,
    branch_index = null,
    branch_step_index = null
  }) {
    if (branch_index != null && step.type == "filter_branch") {
      this.props.setModal({
        title: "Invaild Action",
        description: "You can only have one branch per workflow.",
        submit: "Dismiss",
        onPress: () => {},
        cancel: "Cancel",
        onCancel: () => {}
      });
      this.props.toggleModal({ show: true, type: "normal" });
    } else if (
      step_index < this.state.workflow.workflow_steps.length - 1 &&
      step.type == "filter_branch"
    ) {
      this.props.setModal({
        title: "Invaild Action",
        description:
          "You cannot place a branch in front of an existing component.",
        submit: "Dismiss",
        onPress: () => {},
        cancel: "Cancel",
        onCancel: () => {}
      });
      this.props.toggleModal({ show: true, type: "normal" });
    } else {
      let new_workflow_steps = [];
      for (let i = 0; i < this.state.workflow.workflow_steps.length; i++) {
        new_workflow_steps.push(this.state.workflow.workflow_steps[i]);
      }

      if (branch_index != null && branch_step_index != null) {
        new_workflow_steps[step_index].data[branch_index].steps.splice(
          branch_step_index,
          0,
          step
        );
      } else {
        new_workflow_steps.splice(step_index + 1, 0, step);
      }

      this.setState({
        workflow: {
          ...this.state.workflow,
          workflow_steps: new_workflow_steps
        },
        active_step: {
          ...step,
          step_index:
            branch_step_index != null && branch_index != null
              ? step_index
              : step_index + 1,
          branch_index,
          branch_step_index
        },
        add_step_type: null
      });
    }
  }

  deleteStep({
    step_index = 0,
    branch_index = null,
    branch_step_index = null
  }) {
    let new_workflow_steps = [];
    for (let i = 0; i < this.state.workflow.workflow_steps.length; i++) {
      new_workflow_steps.push(this.state.workflow.workflow_steps[i]);
    }
    if (branch_index != null && branch_step_index != null) {
      new_workflow_steps[step_index].data[branch_index].steps.splice(
        branch_step_index,
        1
      );
    } else {
      new_workflow_steps.splice(step_index, 1);
    }

    this.setState({
      workflow: {
        ...this.state.workflow,
        workflow_steps: new_workflow_steps
      },
      active_step: null,
      add_step_type: null
    });
  }

  saveStep({
    step_id,
    step,
    step_index,
    branch_index = null,
    branch_step_index = null
  }) {
    let new_workflow_steps = [];
    for (let i = 0; i < this.state.workflow.workflow_steps.length; i++) {
      new_workflow_steps.push(this.state.workflow.workflow_steps[i]);
    }
    if (branch_index != null && branch_step_index != null) {
      new_workflow_steps[step_index].data[branch_index].steps[
        branch_step_index
      ] = step;
    } else {
      new_workflow_steps[step_index] = step;
    }

    this.setState({
      active_step: null,
      workflow: {
        ...this.state.workflow,
        workflow_steps: new_workflow_steps
      }
    });
  }

  completeUpdateStep(step) {
    const { workflow } = this.state;
    this.setState({
      active_step: null,
      workflow: {
        ...workflow,
        workflow_steps: workflow.workflow_steps.map(s => {
          if (s.id == step.id) {
            return step;
          }
          return s;
        })
      }
    });
  }

  saveWorkflow() {
    const { workflow } = this.state;
    this.props.updateWorkflow({
      token: this.props.token,
      type:
        workflow.workflow_id == "new_workflow"
          ? "create_create_workflowworkflow"
          : "update_workflow",
      workflow_id:
        workflow.workflow_id == "new_workflow" ? null : workflow.workflow_id,
      workflow_title: workflow.workflow_title,
      category: workflow.category,
      template_type: workflow.template_type,
      enrollment_triggers: workflow.enrollment_triggers,
      workflow_steps: workflow.workflow_steps,
      only_enroll_manually: workflow.only_enroll_manually,
      workflow_on: workflow.workflow_on,
      onLoading: () => {
        this.setState({
          saving: true
        });
      },
      onError: () => {
        this.setState({
          saving: false
        });
      },
      onSuccess: () => {
        this.setState({
          saving: false
        });
        this.props.popSidePanel();
      }
    });
  }

  render() {
    const { isMobile, colors, device } = this.props;
    const {
      workflow,
      show_select_template,
      scale,
      active_step,
      add_step_type
    } = this.state;

    return (
      <FocusModal
        title="New Workflow"
        onClose={() => {
          if (this.checkIfNeedsToSave()) {
            this.props.setModal({
              title: "Exit Without Saving?",
              description:
                "You've made changes to this workflow. Are you sure you want to exit without saving?",
              submit: this.checkIfCanSave() ? "Save and Exit" : "",
              onPress: () => {
                this.saveWorkflow();
              },
              secondary: "Keep Editing",
              secondaryPress: () => {},
              cancel: "Exit without saving",
              onCancel: () => {
                this.props.popSidePanel();
              }
            });
            this.props.toggleModal({ show: true, type: "normal" });
          } else {
            this.props.popSidePanel();
          }
        }}
        mainViewClass={!show_select_template ? "grid-background" : null}
        renderMainView={
          show_select_template
            ? () => {
                return (
                  <Wrapper
                    style={{
                      flex: 1,
                      alignSelf: "stretch",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: colors.background_color
                    }}
                  >
                    {!!workflow.template_type ? (
                      <OnboardingText
                        slug={"workflow_template_" + workflow.template_type}
                        center={true}
                        image_width={350}
                        image_height={200}
                        style={{
                          padding: 20,
                          margin: "auto",
                          maxWidth: 550
                        }}
                        innerStyle={{}}
                      />
                    ) : null}
                  </Wrapper>
                );
              }
            : () => {
                return (
                  <>
                    <TopNavBar
                      {...this.props}
                      workflow={workflow}
                      checkIfNeedsToSave={this.checkIfNeedsToSave}
                      checkIfCanSave={this.checkIfCanSave}
                      saveWorkflow={this.saveWorkflow}
                      saving={this.state.saving}
                      updateWorkflowDesign={this.updateWorkflowDesign}
                      show_select_template={show_select_template}
                      startNewWorkflow={this.startNewWorkflow}
                    />
                    <KeyboardView>
                      <MainWorkflowView
                        {...this.props}
                        active_step={active_step}
                        workflow={workflow}
                        scale={scale}
                        setActiveStep={this.setActiveStep}
                        setNewStepType={this.setNewStepType}
                        deleteStep={this.deleteStep}
                        add_step_type={add_step_type}
                      />
                    </KeyboardView>
                  </>
                );
              }
        }
        topNavStyle={{ padding: 0 }}
        renderBottomNav={
          !show_select_template
            ? () => {
                return (
                  <BottomNav
                    {...this.props}
                    scale={scale}
                    onZoom={this.onZoom}
                  />
                );
              }
            : null
        }
        renderSideBar={
          show_select_template
            ? () => {
                return (
                  <SelectWorkflowTemplate
                    {...this.props}
                    workflow={workflow}
                    updateWorkflowFromTemplate={this.updateWorkflowFromTemplate}
                    updateWorkflowDesign={this.updateWorkflowDesign}
                    startNewWorkflow={this.startNewWorkflow}
                  />
                );
              }
            : active_step || add_step_type
            ? () => {
                return (
                  <WorkflowStepEditor
                    {...this.props}
                    workflow={workflow}
                    active_step={active_step}
                    saveEnrollmentTriggers={this.saveEnrollmentTriggers}
                    completeUpdateStep={this.completeUpdateStep}
                    setActiveStep={this.setActiveStep}
                    setNewStepType={this.setNewStepType}
                    add_step_type={add_step_type}
                    addStep={this.addStep}
                    saveStep={this.saveStep}
                  />
                );
              }
            : null
        }
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, workflow }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX } = native;
  const { colors } = settings;
  const {
    active_workflow,
    enrollment_templates,
    enrollment_templates_loading,
    total_enrollment_count,
    total_enrollment_count_loading
  } = workflow;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    active_workflow,
    enrollment_templates,
    enrollment_templates_loading,
    total_enrollment_count,
    total_enrollment_count_loading
  };
};

export default connect(mapStateToProps, {
  setModal,
  toggleModal,
  getWorkflowInfo,
  popSidePanel,
  updateWorkflow,
  getEnrollmentTemplates
})(WorkflowDesigner);
