import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  Copy,
  Bold,
  Row,
  Button,
  Icon
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import Quote from "app/DealMachineCore/reuseable/Quote";
import SkipButton from "./SkipButton";

import { loadDashboardStep } from "app/NativeActions";
import MentorAutocomplete from "./MentorAutocomplete";
class FindAMentorStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null,
      selected_option: null,
      mentor_name: ""
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("find_a_mentor")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }
  selectOption(option) {
    this.setState({
      selected_option: option
    });
  }

  renderInput() {
    if (
      this.state.selected_option == "looking_for_a_mentor" ||
      this.state.selected_option == "already_have_a_mentor"
    ) {
      return (
        <MentorAutocomplete
          selectName={mentor_name => {
            this.setState({
              mentor_name
            });
          }}
        />
      );
    }
  }

  renderCheckBoxes() {
    return (
      <Wrapper style={{ alignSelf: "stretch" }}>
        <Wrapper>
          <Button
            style={{
              padding: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
            onPress={() => {
              this.selectOption("looking_for_a_mentor");
            }}
          >
            <Row>
              <Icon
                icon={
                  this.state.selected_option == "looking_for_a_mentor"
                    ? "check-box"
                    : "check-box-outline-blank"
                }
                size={18}
                style={{ marginRight: 5 }}
              />
              <Copy>I'm looking for a mentor</Copy>
            </Row>
          </Button>
          {this.renderInput()}
        </Wrapper>
        <Wrapper>
          <Button
            style={{
              padding: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
            onPress={() => {
              this.selectOption("already_have_a_mentor");
            }}
          >
            <Row>
              <Icon
                icon={
                  this.state.selected_option == "already_have_a_mentor"
                    ? "check-box"
                    : "check-box-outline-blank"
                }
                size={18}
                style={{ marginRight: 5 }}
              />
              <Copy>I already have a mentor</Copy>
            </Row>
          </Button>
          {this.renderInput}
        </Wrapper>
        <Button
          style={{
            padding: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
          onPress={() => {
            this.selectOption("not_looking_for_a_mentor");
          }}
        >
          <Row>
            <Icon
              icon={
                this.state.selected_option == "not_looking_for_a_mentor"
                  ? "check-box"
                  : "check-box-outline-blank"
              }
              size={18}
              style={{ marginRight: 5 }}
            />
            <Copy>I'm not looking for a mentor</Copy>
          </Row>
        </Button>
      </Wrapper>
    );
  }

  renderSubmitButton(dashboard_step) {
    if (this.state.selected_option) {
      return (
        <Row style={{ justifyContent: "center", marginTop: 10 }}>
          <PillButton primary={true} onPress={this.completedStep}>
            {dashboard_step.secondaryButtonText}
          </PillButton>
        </Row>
      );
    }
  }

  renderContents(dashboard_step) {
    //if (!this.state.completed_step) {
    return (
      <Wrapper
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Quote
          center={this.props.isMobile ? true : false}
          style={{ padding: 15, paddingTop: 5, maxWidth: 600 }}
          image={
            dashboard_step.quoteImage
              ? dashboard_step.quoteImage.fields.file.url
              : ""
          }
          title={dashboard_step.quoteText}
          description={dashboard_step.quoteDescription}
        />
        <Wrapper
          style={{
            paddingTop: 20,
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 600
          }}
        >
          <Copy style={{ textAlign: "center", marginBottom: 10 }}>
            {dashboard_step.header}
          </Copy>
          <Copy style={{ textAlign: "center" }}>
            {dashboard_step.description}
          </Copy>

          {this.renderCheckBoxes(dashboard_step)}
          {this.renderSubmitButton(dashboard_step)}
        </Wrapper>
      </Wrapper>
    );
    //}
  }

  completedStep() {
    if (
      this.state.selected_option == "looking_for_a_mentor" ||
      this.state.selected_option == "already_have_a_mentor"
    ) {
      this.props.updateDashboard({
        token: this.props.token,
        type:
          this.state.selected_option == "already_have_a_mentor"
            ? "has_mentor"
            : "request_mentor",
        mentor_name: this.state.mentor_name
      });
    }

    this.props.updateDashboard({
      token: this.props.token,
      type: "completed_dashboard_steps"
    });
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab == "find_a_mentor") {
      if (!loading && dashboard_step) {
        return (
          <Wrapper>
            <CardBody>{this.renderContents(dashboard_step)}</CardBody>
          </Wrapper>
        );
      }
    }
    return <Wrapper />;
  }
}

export default FindAMentorStep;
