import React, { Component } from "react";
import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Row,
  Spin
} from "app/NativeComponents/common";
import { List, PillButton, SpinWrapper } from "app/NativeComponents/snippets";

import TeamMemberItem from "./TeamMemberItem";
class TeamMemberList extends Component {
  handleTeamMemberItemPress(item) {
    this.props.selectActiveTeamMember(item);
    this.props.pushSidePanel({
      slug: "team_member_page",
      overlay: true
    });
  }

  renderTeamMemberItem(item) {
    return (
      <TeamMemberItem
        key={item.id}
        member={item}
        colors={this.props.colors}
        active_team_member={this.props.active_team_member}
        onPress={() => this.handleTeamMemberItemPress(item)}
      />
    );
  }

  renderList() {
    if (
      this.props.team_members_loading &&
      this.props.team_members.length == 0
    ) {
      return <SpinWrapper text="Loading Team..." />;
    }

    return (
      <List
        rowNumber={1}
        style={{ flex: 1 }}
        items={this.props.team_members}
        infiniteScroll={true}
        itemStructure={({ item }) => this.renderTeamMemberItem(item)}
        canRefresh={true}
        onRefresh={() => {
          this.props.getTeamMembers({
            token: this.props.token,
            load_type: "refresh",
            type: "team_members",
            begin: 0,
            search: this.props.team_search
          });
        }}
        is_refreshing={this.props.team_members_refreshing}
        canLoadMore={
          !this.props.team_members_loaded_all &&
          !this.props.team_members_loading &&
          !this.props.team_members_refreshing &&
          this.props.team_members.length > 0
        }
        isLoadingMore={
          this.props.team_members_loading &&
          !this.props.team_members_refreshing &&
          this.props.team_members.length > 0
        }
        onLoadMore={() => {
          if (
            !this.props.team_members_loaded_all &&
            this.props.team_members_refreshing != true &&
            this.props.team_members_loading != true
          ) {
            this.props.getTeamMembers({
              token: this.props.token,
              load_type: "load_more",
              type: this.props.team_tab,
              begin: this.props.team_members_begin,
              search: this.props.team_search
            });
          }
        }}
      />
    );
  }

  render() {
    return <Wrapper>{this.renderList()}</Wrapper>;
  }
}

export default TeamMemberList;
