import React, { Component } from "react";
import { connect } from "react-redux";

import { Copy, Row, Wrapper, Button, Bold } from "app/NativeComponents/common";
import {
  IconButton,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";

import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

import {
  getMailerStats,
  numberWithCommas,
  pushSidePanel,
  renderPrice
} from "app/NativeActions";
class Design extends Component {
  constructor(props) {
    super(props);

    this.state = { hovering: false, loading: false };
  }

  render() {
    const { colors, isMobile } = this.props;

    const { mailer, source_of_truth } = this.props;
    const { hovering, loading } = this.state;
    let scale = 0.35;
    let back_scale = 0.35;

    let template_type_title = "";
    let subtext = "";
    let postcard_price = "";

    switch (mailer?.template_type) {
      case "postcard":
      default:
        template_type_title = "4x6 Postcard";
        scale = 0.35;
        back_scale = 0.35;
        if (!!source_of_truth?.subscription?.metadata["4x6_postcard_price"]) {
          postcard_price =
            source_of_truth?.subscription?.metadata["4x6_postcard_price"];
        }

        break;

      case "postcard6x9":
        template_type_title = "6x9 Postcard";
        scale = 0.3;
        back_scale = 0.3;
        if (!!source_of_truth?.subscription?.metadata["6x9_postcard_price"]) {
          postcard_price =
            source_of_truth?.subscription?.metadata["6x9_postcard_price"];
        }

        break;

      case "postcard6x11":
        template_type_title = "6x11 Postcard";
        scale = 0.25;
        back_scale = 0.25;
        if (!!source_of_truth?.subscription?.metadata["6x11_postcard_price"]) {
          postcard_price =
            source_of_truth?.subscription?.metadata["6x11_postcard_price"];
        }

        break;

      case "handwritten":
        template_type_title = "Ballpoint Letter";
        scale = 0.35;
        back_scale = 0.35;
        if (
          !!source_of_truth?.subscription?.metadata["ballpoint_letter_price"]
        ) {
          postcard_price =
            source_of_truth?.subscription?.metadata["ballpoint_letter_price"];
        }

        break;
    }

    return (
      <Button
        style={{
          padding: 5,

          alignItems: "center",
          justifyContent: "center",

          backgroundColor:
            hovering || loading
              ? colors?.orange_color_muted
              : colors.background_color,
          borderRadius: 5,
          margin: 5
        }}
        onHover={() => {
          this.setState({ hovering: true });
        }}
        onHoverOut={() => {
          this.setState({ hovering: false });
        }}
        onPress={() => {
          this.props.selectDesignTemplate(mailer);
        }}
      >
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 200,
            width: isMobile ? "100%" : 300
          }}
        >
          <MailerFlipCard
            mailer={mailer}
            scale={scale}
            back_scale={back_scale}
            include_options={hovering}
          />
        </Wrapper>
        <Row style={{ alignSelf: "stretch", flex: 1 }}>
          <Row>
            <Wrapper
              style={{
                padding: 10,
                flex: 1
              }}
            >
              <Copy
                style={{
                  color:
                    hovering || loading
                      ? colors.actionable_text_color
                      : colors.text_color
                }}
              >
                <Bold>{mailer?.template_title}</Bold>
              </Copy>
            </Wrapper>
            <Wrapper
              style={{
                padding: 10
              }}
            >
              <Copy style={{ textAlign: "right" }}>{template_type_title}</Copy>
              <Copy
                style={{
                  textAlign: "right",
                  fontSize: 12,
                  color: colors.light_text_color
                }}
              >
                {renderPrice(postcard_price)}
              </Copy>
            </Wrapper>
          </Row>
        </Row>
        {this.props.device == "mobile" ? (
          <GhostButton
            button_type={"full"}
            loading={this.state.loading}
            onPress={() => {
              this.props.selectDesignTemplate(mailer);
            }}
            icon_right={"keyboard-arrow-right"}
          >
            Select Design
          </GhostButton>
        ) : null}
        {(hovering || loading) && this.props.device == "desktop" ? (
          <Wrapper
            style={{
              alignSelf: "stretch",
              height: 60,
              padding: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <GhostButton
              button_type={"full"}
              loading={this.state.loading}
              onPress={() => {
                this.setState(
                  {
                    loading: true
                  },
                  () => {
                    this.props.selectDesignTemplate(mailer);
                  }
                );
              }}
              primary={true}
            >
              Select Design
            </GhostButton>
          </Wrapper>
        ) : this.props.device == "desktop" ? (
          <Wrapper
            style={{
              alignSelf: "stretch",
              height: 60,
              padding: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          />
        ) : null}
      </Button>
    );
  }
}

const mapStateToProps = ({ auth, settings, billing, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device } = native;

  return {
    token,
    user,
    colors,
    source_of_truth,
    isMobile,
    device
  };
};

export default connect(mapStateToProps, { getMailerStats, pushSidePanel })(
  Design
);
