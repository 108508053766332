import React, { Component } from "react";
import { connect } from "react-redux";

import { Row } from "app/NativeComponents/common";
import { SelectItem, IconButton } from "app/NativeComponents/snippets";

import {
  pushSidePanel,
  formatUsPhone,
  copyToClipBoard
} from "app/NativeActions";

class CallTrackingNumberItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copy_success: false
    };
  }

  componentWillUnmount() {
    clearInterval(this.copy_success_timeout);
  }
  render() {
    const { item, colors } = this.props;
    const { copy_success } = this.state;
    return (
      <SelectItem
        select_type={"none"}
        subtext={item.default_tracking_number ? "Default" : null}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "edit_call_tracking_number",
            overlay: true,
            data: {
              call_tracking_number: item,
              handleEditExistingNumber: this.props.handleEditExistingNumber,
              handleRemoveExistingNumber: this.props.handleRemoveExistingNumber
            }
          });
        }}
        renderRight={() => {
          return (
            <Row>
              <IconButton
                icon={copy_success ? "check" : "content-copy"}
                backgroundColor={copy_success ? colors.success_color : null}
                icon_color={copy_success ? colors.white_text_color : null}
                tooltip={
                  copy_success
                    ? "Copied: " + formatUsPhone(item.phone_number)
                    : "Copy: " + formatUsPhone(item.phone_number)
                }
                tooltipPlacement="top"
                onPress={() => {
                  if (!copy_success) {
                    copyToClipBoard(formatUsPhone(item.phone_number));
                    clearInterval(this.copy_success_timeout);
                    let $this = this;
                    this.copy_success_timeout = $this.setState(
                      {
                        copy_success: true
                      },
                      () => {
                        setTimeout(() => {
                          $this.setState({
                            copy_success: false
                          });
                        }, 3000);
                      }
                    );
                  }
                }}
              />

              <IconButton noPress={true} icon="keyboard-arrow-right" />
            </Row>
          );
        }}
        description={"Forwards to " + formatUsPhone(item.callback_number)}
      >
        {!!item?.phone_label
          ? item?.phone_label + " - " + formatUsPhone(item.phone_number)
          : formatUsPhone(item.phone_number)}
      </SelectItem>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;

  return {
    token,
    user,
    isMobile,
    colors,
    device
  };
};

export default connect(mapStateToProps, { pushSidePanel })(
  CallTrackingNumberItem
);
