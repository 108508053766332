import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Button,
  Icon,
  Stretch,
  Copy,
  DropzoneButton
} from "app/NativeComponents/common";

import {
  FeatureLockButton,
  PopoverMenu,
  PillButton
} from "app/NativeComponents/snippets";

import { mobileToggleDrawer } from "app/NativeActions";

import { store } from "app/store";

class HeaderButton extends PureComponent {
  render() {
    const {
      buttonOrder,
      buttonIcon,
      buttonFAIcon,
      buttonTitle,
      buttonPrimary,
      buttonStyle,
      buttonColor,
      buttonAction,
      buttonDisabled,
      isDropzone,
      dropzoneAccept,
      featureLock,
      buttonPopover = false,
      buttonPopoverItems = [],
      buttonComponents = null
    } = this.props;

    const colors = store.getState().settings.colors;

    if (
      featureLock &&
      featureLock != "" &&
      buttonFAIcon &&
      buttonFAIcon != ""
    ) {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <FeatureLockButton feature_slug={featureLock} onPress={buttonAction}>
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Icon
                fa_icon={buttonFAIcon}
                size={24}
                color={buttonColor || colors.text_color}
              />
            </Stretch>
          </FeatureLockButton>
        </Wrapper>
      );
    }
    if (featureLock && featureLock != "" && buttonIcon && buttonIcon != "") {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <FeatureLockButton feature_slug={featureLock} onPress={buttonAction}>
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Icon
                icon={buttonIcon}
                size={26}
                color={buttonColor || colors.text_color}
              />
            </Stretch>
          </FeatureLockButton>
        </Wrapper>
      );
    }

    if (buttonPopover) {
      return (
        <PopoverMenu
          popoverPlacement={"bottom"}
          renderComponent={() => {
            return (
              <Wrapper
                style={{
                  width: 50,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Stretch
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Icon
                    fa_icon={buttonFAIcon}
                    icon={buttonIcon}
                    size={24}
                    color={buttonColor || colors.text_color}
                  />
                </Stretch>
              </Wrapper>
            );
          }}
          menu_items={buttonPopoverItems}
        />
      );
    }

    if (isDropzone && buttonFAIcon && buttonFAIcon != "") {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <DropzoneButton accept={dropzoneAccept} onDrop={buttonAction}>
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Wrapper>
                <Icon
                  fa_icon={buttonFAIcon}
                  size={24}
                  color={buttonColor || colors.text_color}
                />
              </Wrapper>
            </Stretch>
          </DropzoneButton>
        </Wrapper>
      );
    }

    if (isDropzone && buttonIcon && buttonIcon != "") {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <DropzoneButton accept={dropzoneAccept} onDrop={buttonAction}>
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Wrapper>
                <Icon
                  icon={buttonIcon}
                  size={26}
                  color={buttonColor || colors.text_color}
                />
              </Wrapper>
            </Stretch>
          </DropzoneButton>
        </Wrapper>
      );
    }

    if (buttonTitle && buttonTitle != "") {
      if (buttonPrimary) {
        return (
          <Wrapper
            style={{
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: 15
            }}
          >
            <PillButton
              primary={true}
              onPress={buttonAction}
              icon={buttonIcon}
              innerStyle={{ paddingTop: 5, paddingBottom: 5 }}
            >
              {buttonTitle}
            </PillButton>
          </Wrapper>
        );
      }
      return (
        <Wrapper
          style={{
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 15
          }}
        >
          <Button
            style={buttonStyle}
            onPress={buttonAction}
            disabled={buttonDisabled}
          >
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Copy style={{ color: buttonColor || colors.text_color }}>
                {buttonTitle}
              </Copy>
            </Stretch>
          </Button>
        </Wrapper>
      );
    } else if (buttonFAIcon && buttonFAIcon != "") {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button onPress={buttonAction} disabled={buttonDisabled}>
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Icon
                fa_icon={buttonFAIcon}
                size={24}
                color={buttonColor || colors.text_color}
              />
            </Stretch>
          </Button>
        </Wrapper>
      );
    } else if (
      buttonIcon &&
      buttonIcon != "" &&
      buttonIcon != "blank" &&
      buttonIcon != "logo"
    ) {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button onPress={buttonAction} disabled={buttonDisabled}>
            <Stretch
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Icon
                icon={buttonIcon}
                size={26}
                color={buttonColor || colors.text_color}
              />
            </Stretch>
          </Button>
        </Wrapper>
      );
    } else if (buttonComponents != null) {
      return (
        <Wrapper
          style={{
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 15
          }}
        >
          <Stretch
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {buttonComponents()}
          </Stretch>
        </Wrapper>
      );
    } else if (buttonIcon == "logo") {
      if (!this.props.navbarBeta) {
        let onboarding = store.getState().auth.onboarding;

        if (this.props.device == "desktop" && onboarding != true) {
          return (
            <Wrapper
              style={{
                width: 50,
                alignSelf: "stretch",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                onPress={() =>
                  this.props.mobileToggleDrawer(
                    !this.props.mobile_toggle_drawer
                  )
                }
              >
                <Stretch
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Icon icon={"menu"} size={26} />
                </Stretch>
              </Button>
            </Wrapper>
          );
        }

        return (
          <Wrapper
            style={{
              width: 50,
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img
              alt=""
              style={{
                width: 30
              }}
              src="/assets/images/Icon-blue.png"
            />
          </Wrapper>
        );
      } else {
        return (
          <Wrapper
            style={{
              width: 30,
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center"
            }}
          ></Wrapper>
        );
      }
    } else if (buttonIcon == "blank") {
      return (
        <Wrapper
          style={{
            width: 50,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center"
          }}
        />
      );
    }

    return <Wrapper />;
  }
}

const mapStateToProps = ({ native, feature_toggle }) => {
  const { isMobile, mobile_toggle_drawer, device } = native;
  const { navbarBeta } = feature_toggle;
  return {
    isMobile,
    device,
    mobile_toggle_drawer,
    navbarBeta
  };
};

export default connect(mapStateToProps, { mobileToggleDrawer })(HeaderButton);
