import React from "react";

const Gradient = ({ children, style, color1, color2 }) => {
  return (
    <div
      className="deal-gradient"
      style={{
        ...style,
        background: [
          "linear-gradient(to bottom right, " + color1 + ", " + color2 + ")",
          // fallback
          color1
        ]
      }}
      //colors={[color1, color2]}
    >
      {children}
    </div>
  );
};

export { Gradient };
