import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  Copy,
  Input,
  Row,
  Bold
} from "app/NativeComponents/common";
import { PillButton, GhostButton } from "app/NativeComponents/snippets";
import Quote from "app/DealMachineCore/reuseable/Quote";
import SkipButton from "../Steps/SkipButton";

import { loadDashboardStep } from "app/NativeActions";

import DownloadButtons from "app/NativeComponents/components/DownloadButtons";

class GetTheAppStepAB1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null,
      phone: props.user.phone
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("get_the_app")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderDownloadButtons() {
    if (this.props.device == "desktop") {
      return (
        <DownloadButtons
          onPress={() => {
            this.setState({
              completed_step: true
            });
          }}
        />
      );
    }
  }

  renderTextLinkButtons() {
    if (this.props.device == "desktop") {
      return (
        <Card>
          <Row>
            <Input
              ref={"phone"}
              name={"phone"}
              style={{ flex: 1 }}
              placeholder={
                !!this.state.phone
                  ? "Text Me The Download Link"
                  : "Phone Number"
              }
              onChange={value => {
                this.setState({
                  phone: value
                });
              }}
              value={this.state.phone}
              type="text"
              mask_type={"cel-phone"}
              mask={"(999) 999-9999"}
            />

            <PillButton
              primary={true}
              onPress={() => {
                this.props.updateDashboard({
                  token: this.props.token,
                  type: "text_link",
                  phone_number: this.state.phone,
                  onSuccess: () => {}
                });
              }}
            >
              Send Link
            </PillButton>
          </Row>
        </Card>
      );
    }
  }

  renderContents(dashboard_step) {
    //if (!this.state.completed_step) {
    return (
      <Wrapper style={{ alignItems: "center" }}>
        <Title>
          <Bold>{dashboard_step.header}</Bold>
        </Title>
        <Quote
          center={this.props.isMobile ? true : false}
          style={{ padding: 15, paddingTop: 5, maxWidth: 600 }}
          image={
            dashboard_step.quoteImage
              ? dashboard_step.quoteImage.fields.file.url
              : ""
          }
          title={dashboard_step.quoteText}
          description={dashboard_step.quoteDescription}
        />
        {this.renderDownloadButtons()}
        {this.renderTextLinkButtons()}
      </Wrapper>
    );
    //}
  }

  completedStep() {
    this.props.updateTab("add_properties");
    this.props.updateDashboard({
      token: this.props.token,
      type: "dashboard_step",
      current_step: "add_properties"
    });
  }
  renderButton() {
    if (this.props.is_enterprise) {
      return (
        <SkipButton
          //completed_step={this.state.completed_step}
          onPress={this.completedStep}
          // title={dashboard_step.nextButtonText}
        />
      );
    } else {
      return (
        <GhostButton
          style={{ paddingBottom: 35 }}
          primary={true}
          onPress={() => {
            this.props.updateDashboard({
              token: this.props.token,
              type: "completed_dashboard_steps"
            });
          }}
        >
          Unlock My Dashboard
        </GhostButton>
      );
    }
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (!loading && dashboard_step) {
      return (
        <Wrapper style={{ alignItems: "center" }}>
          <CardBody>{this.renderContents(dashboard_step)}</CardBody>
          {this.renderButton()}
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default GetTheAppStepAB1;
