import React, { Component } from "react";

import {
  Button,
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";
import { PopoverMenu, IconButton } from "app/NativeComponents/snippets";

/*
import {
} from 'app/NativeActions';
*/

import ReactResizeDetector from "react-resize-detector";

class Header extends Component {
  constructor(props) {
    super(props);

    this.adjustColumnWidth = this.adjustColumnWidth.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._resize_interval);
  }

  adjustColumnWidth({ width, slug }) {
    this.props.updateNewColumnWidth({
      slug: slug,
      width: width
    });
    clearInterval(this._resize_interval);
    this._resize_interval = setTimeout(() => {
      this.saveNewWidth(width);
    }, 1000);
  }

  saveNewWidth(width) {
    const { list_settings, list_settings_loading, section } = this.props;
    if (list_settings && !list_settings_loading) {
      let new_user_list_columns = list_settings.user_list_columns;
      for (let i = 0; i < new_user_list_columns.length; i++) {
        if (new_user_list_columns[i].slug === section.slug) {
          new_user_list_columns[i].width = width;
          break;
        }
      }
      this.props.updateUserListSettings({
        token: this.props.token,
        user_list_columns: new_user_list_columns,
        type: "in_background"
      });
    }
  }

  renderSortIcon() {
    const { section, sort_by } = this.props;
    if (section.slug === sort_by.slug) {
      switch (sort_by.type) {
        case "asc":
          return (
            <Wrapper
              style={{
                width: 15,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Icon size={18} icon={"keyboard-arrow-up"} />
            </Wrapper>
          );

        case "desc":
        default:
          return (
            <Wrapper
              style={{
                width: 15,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Icon size={18} icon={"keyboard-arrow-down"} />
            </Wrapper>
          );
      }
    }

    if (section.sort_by) {
      return (
        <Wrapper
          style={{ width: 15, alignItems: "center", justifyContent: "center" }}
        >
          <Icon size={12} fa_icon={"sort"} color={"rgba(0,0,0,0.15)"} />
        </Wrapper>
      );
    }

    //hide this to keep same width for header
    return (
      <Wrapper
        style={{ width: 15, alignItems: "center", justifyContent: "center" }}
      ></Wrapper>
    );
  }

  render() {
    const {
      section,
      column_width,
      list_properties_loading,
      list_properties_refreshing,
      sort_by,
      colors,
      toggle_lead_images
    } = this.props;

    return (
      <Wrapper style={{ width: column_width }}>
        <Wrapper
          className="resize-no-handle"
          style={{
            overflow: "hidden",
            width: column_width,
            minWidth: section.main_column ? 120 : 80,
            maxWidth: 500,
            resize: "horizontal"
          }}
        >
          <ReactResizeDetector
            handleWidth={true}
            skipOnMount={true}
            onResize={width => {
              this.adjustColumnWidth({
                width: width,
                slug: section.slug
              });
            }}
          >
            <Button
              disabled={
                !section.sort_by ||
                list_properties_loading ||
                list_properties_refreshing
              }
              onPress={() => {
                if (section.sort_by) {
                  this.props.changeSortBy({
                    slug: this.props.section.slug,
                    type:
                      section.slug !== sort_by.slug
                        ? sort_by.type
                        : sort_by.type === "asc"
                        ? "desc"
                        : "asc"
                  });
                }
              }}
              style={{
                padding: 10,
                height: 25,
                borderRightWidth: 1,
                borderRightColor: colors.border_color,
                borderRightStyle: "solid",
                borderBottomWidth: 1,
                borderBottomColor: colors.border_color,
                borderBottomStyle: "solid",
                backgroundColor:
                  section.slug === sort_by.slug
                    ? colors.gray_selected_color
                    : colors.background_color,
                alignItems: "flex-start",
                justifyContent: "center"
              }}
            >
              <Row
                style={{
                  alignSelf: "stretch"
                }}
              >
                <Wrapper
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    justifyContent: "center"
                  }}
                >
                  <Row>
                    <Copy
                      style={{
                        whiteSpace: "nowrap"
                      }}
                    >
                      <Bold>{this.props.section.title}</Bold>
                    </Copy>
                    {section.main_column ? (
                      <IconButton
                        tooltip={"Toggle Image Size"}
                        tooltipPlacement="top"
                        icon={
                          toggle_lead_images == "large"
                            ? "photo-size-select-large"
                            : toggle_lead_images == "small"
                            ? "photo-size-select-small"
                            : "hide-image"
                        }
                        button_type="small"
                        onPress={() => {
                          if (toggle_lead_images == "small") {
                            this.props.toggleLeadImages("large");
                          } else if (toggle_lead_images == "large") {
                            this.props.toggleLeadImages("none");
                          } else {
                            this.props.toggleLeadImages("small");
                          }
                        }}
                      />
                    ) : null}
                  </Row>
                </Wrapper>

                {this.renderSortIcon()}
              </Row>
            </Button>
          </ReactResizeDetector>
        </Wrapper>
      </Wrapper>
    );
  }
}

export default Header;
