import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Carousel,
  SpinWrapper,
  SmallLabel,
  PhotoOverlay
} from "app/NativeComponents/snippets";
import PhotoGalleryImage from "app/DealMachineCore/reuseable/LeadPhotoManager/PhotoGalleryImage";
import { ExternalImage, Wrapper, Button } from "app/NativeComponents/common";

import {
  checkIfHasGoogleStreetPic,
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata,
  getMLSImages,
  numberWithCommas
} from "app/NativeActions";

class MLSPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedGoogleStreetPic: false,
      gotStreetPicInfo: false,
      mls_images: [],
      mls_loading: false
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.property?.property_id !== this.props?.property?.property_id) {
      this.getData();
    }
  }

  getData() {
    this.setState(
      {
        mls_images: []
      },
      () => {
        this.props.getMLSImages({
          token: this.props.token,
          property_id: this.props.property?.property_id,
          onLoading: () => {
            this.setState({ mls_loading: true });
          },
          onError: () => {
            this.setState({ mls_loading: false });
          },
          onSuccess: results => {
            this.setState({ mls_loading: false });
            if (results?.mls_images?.length > 0) {
              this.setState({
                mls_images: results?.mls_images
              });
            }
          }
        });
      }
    );
  }

  render() {
    const { colors } = this.props;

    if (this.state.mls_images && this.state.mls_images?.length > 2) {
      return (
        <Wrapper
          className={"mls-images " + this.props.dark_mode}
          style={
            this.props.property?.mls?.listing_agent_name
              ? {}
              : {
                  paddingTop: 15,
                  paddingBottom: 15
                }
          }
        >
          <SmallLabel>
            {numberWithCommas(this.state.mls_images?.length) +
              " Photos from the MLS:"}
          </SmallLabel>
          <Carousel
            firstItem={0}
            items={this.state.mls_images}
            slidesToShow={1}
            centerMode={true}
            infinite={this.props.device === "desktop"}
            dots={false}
            centerPadding={this.props.expanded ? "95px" : "120px"}
            itemWidth={this.props.device === "desktop" ? 250 : 300}
            renderItem={obj => {
              const image_obj = obj?.item;
              return (
                <Wrapper
                  key={image_obj?.image}
                  style={{
                    width: this.props.device === "desktop" ? 250 : 300
                  }}
                >
                  <PhotoOverlay
                    style={{
                      overflow: "hidden",
                      borderRadius: 5
                    }}
                    width={this.props.device === "desktop" ? 250 : 300}
                    height={this.props.device === "desktop" ? 150 : 200}
                    outerStyle={{
                      position: "relative",
                      width: this.props.device === "desktop" ? 250 : 300,
                      height: this.props.device === "desktop" ? 150 : 200,
                      overflow: "hidden",
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: colors.border_color,
                      borderStyle: "solid"
                    }}
                    image={image_obj?.image}
                    images={this.state.mls_images.map(({ image }) => image)}
                    photoIndex={obj?.index}
                    onError={() => {
                      //remove image from mls_images and reset state
                      const new_mls_images = this.state.mls_images.filter(
                        ({ image }) => image !== image_obj?.image
                      );
                      this.setState({ mls_images: new_mls_images });
                    }}
                  />
                </Wrapper>
              );
            }}
            include_pagination={true}
            dotColor={colors.text_color}
          />

          {/*this.renderStreetViewPic()}
        {this.renderPropertyPic("satellite")}
        {this.renderPropertyPic("roadmap")*/}

          {this.props.device === "mobile" ? (
            <Wrapper style={{ height: 25 }} />
          ) : null}
        </Wrapper>
      );
    }
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { colors, dark_mode } = settings;
  return {
    token,
    user,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {
  getMLSImages
})(MLSPhotos);
