import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Card,
  CardBody,
  Icon,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

class Scene1 extends Component {
  render() {
    if (this.props.scene > 0) {
      return (
        <div className="animated fadeInUp">
          <div
            style={{
              margin: "10px 15px"
            }}
          >
            <Row>
              <Icon
                color="#28F29E"
                icon="check-circle"
                size={26}
                style={{
                  marginRight: 5
                }}
              />
              <Copy>
                <Bold>Owner found!</Bold>
              </Copy>
            </Row>
          </div>
          <Card>
            <CardBody style={{ padding: "10px 15px" }}>
              <Row>
                <Icon
                  icon="account-circle"
                  size={32}
                  style={{
                    marginRight: 10
                  }}
                />
                <Wrapper>
                  <Title>
                    <Bold>John Smith</Bold>
                  </Title>
                  <Copy>Male - 28 years Old</Copy>
                </Wrapper>
              </Row>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene1;
