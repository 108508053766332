import React, { Component } from "react";

import {
  Wrapper,
  Row,
  InternalImage,
  Button
} from "app/NativeComponents/common";
import { openUrl } from "app/NativeActions";
class DownloadButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iOSUrl:
        "https://apps.apple.com/us/app/dealmachine-for-real-estate/id1136936300",
      androidUrl:
        "https://play.google.com/store/apps/details?id=com.dealmachine&hl=en_US&gl=US"
    };
  }
  render() {
    return (
      <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
        <Row>
          <Button
            style={{ alignSelf: "center" }}
            onPress={() => {
              if (this.props.onPress) {
                this.props.onPress();
              }
              openUrl(this.state.iOSUrl);
            }}
          >
            <InternalImage
              style={{ width: 150 }}
              contain
              image={"/assets/images/app_store.svg"}
            />
          </Button>
          <Button
            style={{ alignSelf: "center" }}
            onPress={() => {
              if (this.props.onPress) {
                this.props.onPress();
              }
              openUrl(this.state.androidUrl);
            }}
          >
            <InternalImage
              style={{ width: 190 }}
              contain
              image={"/assets/images/google-play-badge.png"}
            />
          </Button>
        </Row>
      </Wrapper>
    );
  }
}

export default DownloadButtons;
