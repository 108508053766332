import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  GET_LEAD_CONVERSATIONS,
  GET_LEAD_CONVERSATIONS_FAIL,
  GET_LEAD_CONVERSATIONS_SUCCESS,
  UPDATE_TEAM_PHONE_NUMBER,
  UPDATE_TEAM_PHONE_NUMBER_FAIL,
  UPDATE_TEAM_PHONE_NUMBER_SUCCESS,
  CREATE_TEAM_PHONE_NUMBER_SUCCESS,
  GET_TEAM_PHONE_NUMBERS,
  GET_TEAM_PHONE_NUMBERS_FAIL,
  GET_TEAM_PHONE_NUMBERS_SUCCESS,
  SEND_TEXT_MESSAGE,
  SEND_TEXT_MESSAGE_FAIL,
  SEND_TEXT_MESSAGE_SUCCESS,
  READ_TEXT_MESSAGE,
  READ_TEXT_MESSAGE_FAIL,
  READ_TEXT_MESSAGE_SUCCESS,
  POST_MESSAGE_NOTE,
  POST_MESSAGE_NOTE_FAIL,
  POST_MESSAGE_NOTE_SUCCESS,
  POST_NOTE,
  SET_TO_PHONE_NUMBER,
  UPDATE_CONVERSATION_FILTER,
  GET_CONVERSATIONS,
  REFRESH_CONVERSATIONS,
  GET_CONVERSATIONS_FAIL,
  GET_CONVERSATIONS_SUCCESS,
  SET_ACTIVE_CONVERSATION,
  SET_ACTIVE_CONVERSATION_FAIL,
  SET_ACTIVE_CONVERSATION_SUCCESS,
  TOGGLE_CONVERSATION_LEAD_VIEW,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_FAIL,
  UPDATE_CONVERSATION_SUCCESS,
  CLOSE_CONVERSATION_SUCCESS,
  QUICK_UPDATE_CONVERSATION,
  GET_SAVED_RESPONSES,
  GET_SAVED_RESPONSES_FAIL,
  GET_SAVED_RESPONSES_SUCCESS,
  CREATE_SAVED_RESPONSE_SUCCESS,
  UPDATE_SAVED_RESPONSE_SUCCESS,
  REMOVE_SAVED_RESPONSE_SUCCESS,
  GET_MULTISEND_NUMBERS,
  GET_MORE_MULTISEND_NUMBERS,
  GET_MULTISEND_NUMBERS_FAIL,
  GET_MULTISEND_NUMBERS_SUCCESS,
  GET_MULTISEND_NUMBERS_COUNT,
  GET_MULTISEND_NUMBERS_COUNT_FAIL,
  GET_MULTISEND_NUMBERS_COUNT_SUCCESS,
  SEND_MULTISEND_MESSAGE,
  SKIP_MULTISEND_NUMBER,
  SET_MULTISEND_FILTERS,
  UPDATE_MESSAGE_DISPLAY
} from "app/DealMachineCore/types";

import { appRedirect, renderDate } from "app/NativeActions";
import moment from "moment";

import api from "app/DealMachineCore/apis/DealMachineAPIV2";
const dm_api = api.create();

export const setToPhoneNumber = phone => {
  return {
    type: SET_TO_PHONE_NUMBER,
    payload: phone
  };
};

export const updateConversationFilter = filters => {
  return {
    type: UPDATE_CONVERSATION_FILTER,
    payload: filters
  };
};
export const toggleConversationLeadView = ({ show, new_property }) => {
  return {
    type: TOGGLE_CONVERSATION_LEAD_VIEW,
    payload: { show, new_property }
  };
};

export const setMultisendFilters = ({
  lead_ids,
  filters,
  filter_lists,
  total_property_count,
  onSuccess = () => {}
}) => {
  return dispatch => {
    dispatch({
      type: SET_MULTISEND_FILTERS,
      payload: { lead_ids, filters, filter_lists, total_property_count }
    });

    if (onSuccess) {
      onSuccess();
    }
  };
};

export const setActiveConversation = ({ token, conversation }) => {
  return dispatch => {
    if (conversation) {
      dispatch({ type: SET_ACTIVE_CONVERSATION, payload: conversation });

      if (
        conversation != "conversation_settings" &&
        conversation != "manage_phone_numbers" &&
        conversation != "manage_saved_replies" &&
        conversation != null
      ) {
        dm_api
          .getConversations({
            token,
            type: "active_conversation",
            conversation_id: conversation.id,
            lead_phone_number: conversation.lead_phone_number
              ? conversation.lead_phone_number.phone_number
              : null,
            lead_id: conversation.lead_id ? conversation.lead_id : null
          })
          .then(response => {
            if (response.problem != null) {
              dispatch({
                type: SET_ACTIVE_CONVERSATION_FAIL
              });
            } else if (response.data.error != false) {
              dispatch({
                type: SET_ACTIVE_CONVERSATION_FAIL
              });
              if (response.data.valid == "invalid") {
                dispatch({ type: TRIGGER_LOGOUT, payload: response });
              }
            } else {
              dispatch({
                type: SET_ACTIVE_CONVERSATION_SUCCESS,
                payload: response.data.results
              });
            }
          });
      }
    } else {
      dispatch({
        type: SET_ACTIVE_CONVERSATION_SUCCESS,
        payload: {
          active_conversation: null,
          set_to_phone_number: null,
          set_from_phone_number: null
        }
      });
    }
  };
};

export const getTeamPhoneNumbers = ({ token }) => {
  return dispatch => {
    dispatch({ type: GET_TEAM_PHONE_NUMBERS });

    dm_api
      .getTeamPhoneNumbers({
        token
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_TEAM_PHONE_NUMBERS_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_TEAM_PHONE_NUMBERS_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_TEAM_PHONE_NUMBERS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getConversations = ({
  token,
  load_type,
  filter_type,
  sort_by,
  begin,
  limit = 25,
  search = ""
}) => {
  return dispatch => {
    switch (load_type) {
      default:
        dispatch({ type: GET_CONVERSATIONS });

        break;

      case "refresh":
        dispatch({ type: REFRESH_CONVERSATIONS });
        break;
    }

    dm_api
      .getConversations({
        token,
        type: "conversations",
        filter_type,
        sort_by,
        begin,
        limit,
        search
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_CONVERSATIONS_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_CONVERSATIONS_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_CONVERSATIONS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getLeadConversations = ({ token, lead_id }) => {
  return dispatch => {
    dispatch({ type: GET_LEAD_CONVERSATIONS });

    dm_api
      .getConversations({
        token,
        type: "lead_conversations",
        lead_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_LEAD_CONVERSATIONS_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_LEAD_CONVERSATIONS_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_LEAD_CONVERSATIONS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const updateTeamPhoneNumber = ({
  token,
  type,
  area_code,
  forwarding_number,
  team_phone_number_group_id,
  phone_label,
  default_number = 0,
  onSuccess = null
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });

    dispatch({ type: UPDATE_TEAM_PHONE_NUMBER });

    dm_api
      .updateTeamPhoneNumber({
        token,
        type,
        area_code,
        forwarding_number,
        team_phone_number_group_id,
        phone_label,
        default_number
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_TEAM_PHONE_NUMBER_FAIL,
            payload: response.problem
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: UPDATE_TEAM_PHONE_NUMBER_FAIL,
            payload: response.data.error
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (type) {
            default:
              dispatch({ type: IS_LOADING, payload: false });
              dispatch({
                type: UPDATE_TEAM_PHONE_NUMBER_SUCCESS,
                payload: response.data.results
              });
              break;
            case "update_number":
              dispatch({
                type: UPDATE_TEAM_PHONE_NUMBER_SUCCESS,
                payload: response.data.results
              });
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message:
                    "You've successfully updated this phone number group.",
                  title: "Success!"
                }
              });
              break;
            case "messaging_profile_id":
              dispatch({
                type: UPDATE_TEAM_PHONE_NUMBER_SUCCESS,
                payload: response.data.results
              });
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully activated your SMS messaging.",
                  title: "Success!"
                }
              });
              break;
            case "remove_number":
              dispatch({
                type: CREATE_TEAM_PHONE_NUMBER_SUCCESS,
                payload: response.data.results
              });
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message:
                    "You've successfully deleted this phone number group.",
                  title: "Success!"
                }
              });
              break;
            case "purchase_number":
              dispatch({
                type: CREATE_TEAM_PHONE_NUMBER_SUCCESS,
                payload: response.data.results
              });
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully added a phone number.",
                  title: "Success!"
                }
              });

              break;
            case "forwarding_number":
              dispatch({
                type: UPDATE_TEAM_PHONE_NUMBER_SUCCESS,
                payload: response.data.results
              });
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message:
                    "You've successfully updating the forwarding number for this number block.",
                  title: "Success!"
                }
              });
              break;
          }
          if (onSuccess) {
            onSuccess();
          }
        }
      });
  };
};
export const readTextMessage = ({
  token,
  message_id,
  conversation_id,
  lead_id
}) => {
  return dispatch => {
    dispatch({
      type: READ_TEXT_MESSAGE,
      payload: {
        lead_id,
        conversation_id
      }
    });

    dm_api
      .updateConversation({
        token,
        type: "read_text_message",
        message_id,
        conversation_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: READ_TEXT_MESSAGE_FAIL,
            payload: {
              error: response.problem
            }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: READ_TEXT_MESSAGE_FAIL,
            payload: {
              error: response.data.error
            }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: READ_TEXT_MESSAGE_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};
export const sendTextMessage = ({
  token,
  message_text,
  media = null,
  conversation_id = null,
  lead_id,
  to_phone_number,
  to_phone_number_id,
  from_phone_number
}) => {
  return dispatch => {
    dispatch({
      type: SEND_TEXT_MESSAGE,
      payload: {
        new_message: {
          id: "new_message",
          sending_message: true,
          failed_to_send: false,
          media,
          message_text
        }
      }
    });

    dm_api
      .updateConversation({
        token,
        type: "send_text_message",
        conversation_id,
        lead_id,
        message_text,
        media,
        to_phone_number,
        to_phone_number_id,
        from_phone_number
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: SEND_TEXT_MESSAGE_FAIL,
            payload: {
              error: response.problem
            }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: SEND_TEXT_MESSAGE_FAIL,
            payload: {
              error: response.data.error
            }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: SEND_TEXT_MESSAGE_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const postMessageNote = ({
  token,
  message_text,
  lead_id,
  user,
  to_phone_number,
  conversation_id
}) => {
  return dispatch => {
    dispatch({
      type: POST_MESSAGE_NOTE,
      payload: {
        new_note: {
          id: "new_note",
          sending_message: true,
          failed_to_send: false,
          message_text,
          user,
          conversation_id
        }
      }
    });

    dispatch({
      type: POST_NOTE,
      payload: {
        id: 0,
        activity_type: "note",
        text: message_text,
        date_created: moment().format("YYYY-MM-DD hh:mm:ss"),
        user_id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        image: user.image
      }
    });

    dm_api
      .updateConversation({
        token,
        type: "post_message_note",
        message_text,
        lead_id,
        to_phone_number,
        conversation_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: POST_MESSAGE_NOTE_FAIL,
            payload: {
              error: response.problem
            }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: POST_MESSAGE_NOTE_FAIL,
            payload: {
              error: response.data.error
            }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: POST_MESSAGE_NOTE_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const updateConversation = ({
  token,
  lead_id,
  type,
  conversation_id,
  onSuccess = () => {}
}) => {
  return dispatch => {
    let action_type = type;
    switch (type) {
      default:
        dispatch({
          type: UPDATE_CONVERSATION
        });
        dispatch({ type: IS_LOADING, payload: true });

        break;
      case "active_conversation":
        break;
      case "quick_close_conversation":
        action_type = "close_conversation";
        dispatch({
          type: QUICK_UPDATE_CONVERSATION,
          payload: {
            conversation_id
          }
        });
        break;

      case "quick_open_conversation":
        action_type = "open_conversation";
        dispatch({
          type: QUICK_UPDATE_CONVERSATION,
          payload: {
            conversation_id
          }
        });
        break;
    }

    dm_api
      .updateConversation({
        token,
        type: action_type,
        lead_id,
        conversation_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_CONVERSATION_FAIL,
            payload: {
              conversation_id
            }
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: UPDATE_CONVERSATION_FAIL,
            payload: {
              conversation_id
            }
          });
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (type) {
            case "assign_to_lead":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message:
                    "You've successfully assigned this conversation to a lead.",
                  title: "Success!"
                }
              });
              break;

            case "close_conversation":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully closed this conversation.",
                  title: "Success!"
                }
              });
              break;
            case "open_conversation":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully reopened this conversation.",
                  title: "Success!"
                }
              });
              break;
            default:
              break;
          }
          if (
            action_type == "close_conversation" ||
            action_type == "open_conversation"
          ) {
            dispatch({
              type: CLOSE_CONVERSATION_SUCCESS,
              payload: { ...response.data.results, conversation_id }
            });
          } else {
            dispatch({
              type: UPDATE_CONVERSATION_SUCCESS,
              payload: { ...response.data.results, conversation_id }
            });
          }

          if (onSuccess) {
            onSuccess();
          }
        }
      });
  };
};

export const updateSavedResponse = ({
  token,
  type,
  saved_response_id,
  response_text,
  starting_response = 0,
  onSuccess = () => {}
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    dm_api
      .updateSavedResponse({
        token,
        type,
        saved_response_id,
        response_text,
        starting_response
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: response });
          }
        } else {
          switch (type) {
            default:
            case "update_saved_response":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully updated this reply.",
                  title: "Success!"
                }
              });

              dispatch({
                type: UPDATE_SAVED_RESPONSE_SUCCESS,
                payload: response.data.results
              });
              break;

            case "create_saved_response":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully created this reply.",
                  title: "Success!"
                }
              });

              dispatch({
                type: CREATE_SAVED_RESPONSE_SUCCESS,
                payload: response.data.results
              });
              break;

            case "remove_saved_response":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've successfully removed this reply.",
                  title: "Success!"
                }
              });

              dispatch({
                type: REMOVE_SAVED_RESPONSE_SUCCESS,
                payload: {
                  saved_response_id
                }
              });
              break;
          }

          if (onSuccess) {
            onSuccess();
          }
        }
      });
  };
};

export const getSavedResponses = ({
  token,
  type,
  response_type,
  search = ""
}) => {
  return dispatch => {
    dispatch({ type: GET_SAVED_RESPONSES });
    dm_api
      .getSavedResponses({
        token,
        type,
        response_type,
        search
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_SAVED_RESPONSES_FAIL
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_SAVED_RESPONSES_FAIL
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: response });
          }
        } else {
          dispatch({
            type: GET_SAVED_RESPONSES_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getMultiSendNumbersCount = ({
  token,
  lead_ids,
  filters,
  filter_lists
}) => {
  return dispatch => {
    dispatch({
      type: GET_MULTISEND_NUMBERS_COUNT
    });
    dm_api
      .getMultiSendNumbers({
        token,
        type: "multisend_numbers_count",
        lead_ids,
        filters,
        filter_lists
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_MULTISEND_NUMBERS_COUNT_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_MULTISEND_NUMBERS_COUNT_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_MULTISEND_NUMBERS_COUNT_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const getMultiSendNumbers = ({
  token,
  load_type,
  begin,
  limit = 100,
  lead_ids,
  filters,
  filter_lists
}) => {
  return dispatch => {
    switch (load_type) {
      default:
        dispatch({ type: GET_MULTISEND_NUMBERS });
        break;

      case "load_more":
        dispatch({ type: GET_MORE_MULTISEND_NUMBERS });

        break;
    }

    dm_api
      .getMultiSendNumbers({
        token,
        type: "multisend_numbers",
        begin,
        limit,
        lead_ids,
        filters,
        filter_lists
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_MULTISEND_NUMBERS_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_MULTISEND_NUMBERS_FAIL,
            payload: response.data.error
          });
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_MULTISEND_NUMBERS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const skipMultiSendNumber = ({ lead_phone_id, lead_phone_number }) => {
  return {
    type: SKIP_MULTISEND_NUMBER,
    payload: { lead_phone_id, lead_phone_number }
  };
};

export const sendMultiSendMessage = ({
  token,
  message_text,
  lead_id,
  to_phone_number,
  to_phone_number_id,
  from_phone_number
}) => {
  return dispatch => {
    dispatch({
      type: SEND_MULTISEND_MESSAGE,
      payload: {
        lead_phone_id: to_phone_number_id,
        lead_phone_number: to_phone_number,
        message_text: message_text
      }
    });

    dm_api
      .updateConversation({
        token,
        type: "send_text_message",
        lead_id,
        message_text,
        to_phone_number,
        to_phone_number_id,
        from_phone_number
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_MESSAGE_DISPLAY,
            payload: {
              lead_phone_id: to_phone_number_id,
              success: false,
              failure: true
            }
          });
        } else if (response.data.error != false) {
          dispatch({
            type: UPDATE_MESSAGE_DISPLAY,
            payload: {
              lead_phone_id: to_phone_number_id,
              success: false,
              failure: true,
              failure_message: response.data.error
            }
          });
        } else {
          dispatch({
            type: UPDATE_MESSAGE_DISPLAY,
            payload: {
              lead_phone_id: to_phone_number_id,
              success: true,
              failure: false
            }
          });
        }
      });
  };
};
