import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  GhostButton,
  SelectItem,
  InlineButton
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  startRedirect,
  updateUser,
  tabNavigation,
  toggleChurnBuster,
  manageSubscription
} from "app/NativeActions";
class LearnOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      pause_loading: false
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/cancel/take-some-time");
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>
              {this.props.learning
                ? "If you need time to learn, we'll be right here when you're ready to get started."
                : "Since you'll be back, how about we pause your subscription instead?"}
            </Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              {this.props.learning
                ? "Instead of canceling your account, how about we pause your subscription for 3 months while you take time to learn."
                : "Let's pause your subscription for 3 months since you're coming back anyway."}
            </Header3>
          </Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              marketing_slug={"pressed_churn_buster_pause_button"}
              loading={this.state.pause_loading}
              icon_right={"arrow-right-alt"}
              onPress={() => {
                this.props.manageSubscription({
                  token: this.props.token,
                  type: "pause_subscription",
                  onLoading: () => {
                    this.setState({
                      pause_loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      pause_loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        pause_loading: false
                      },
                      () => {}
                    );
                  }
                });
              }}
            >
              Pause My Subscription
            </GhostButton>
            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "how_did_we_fall_short",
                  property_value: this.state.selected_options?.join(";")
                });
                this.props.nextStep();
              }}
            >
              No, I'd rather just cancel.
            </InlineButton>
            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.tabNavigation({ slug: "leads" });
                this.props.toggleChurnBuster(false);
              }}
            >
              Nevermind, I don't want to cancel.
            </InlineButton>
            <Wrapper style={{ height: 40 }} />
          </Wrapper>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser,
  tabNavigation,
  toggleChurnBuster,
  manageSubscription
})(LearnOffer);
