import React, { Component } from "react";

import { Card, Wrapper } from "app/NativeComponents/common";
import { ToggleSwitch, SelectItem } from "app/NativeComponents/snippets";
import { checkIfUserHasMetadata } from "app/NativeActions";
class PermissionSettings extends Component {
  render() {
    const phone_plan = checkIfUserHasMetadata("dialer");

    if (this.props.edit_team_member.team_clearance_level >= 1) {

      return (
        <>
          <SelectItem
            select_type="toggle"
            selected={
              this.props.edit_team_member.can_see_all_deals == 1 ? true : false
            }
            onPress={() => {
              this.props.editTeamMember({
                prop: "can_see_all_deals",
                value:
                  this.props.edit_team_member.can_see_all_deals == 1 ? 0 : 1
              });
            }}
            description={
              "This user will be able to view and edit all properties from your team."
            }
          >
            Can See Other Team Member Properties?
          </SelectItem>

          <SelectItem
            select_type="toggle"
            selected={
              this.props.edit_team_member.can_approve_mail == 1 ? true : false
            }
            onPress={() => {
              this.props.editTeamMember({
                prop: "can_approve_mail",
                value: this.props.edit_team_member.can_approve_mail == 1 ? 0 : 1
              });
            }}
            description={"This user will be able to start mail."}
          >
            Can Start Mail?
          </SelectItem>
          {phone_plan ? (
            <SelectItem
              select_type="toggle"
              selected={
                this.props.edit_team_member.can_send_message == 1 ? true : false
              }
              onPress={() => {
                this.props.editTeamMember({
                  prop: "can_send_message",
                  value:
                    this.props.edit_team_member.can_send_message == 1 ? 0 : 1
                });
              }}
              description={
                "This user will be able use the Dialer to call prospects."
              }
            >
              Can Use Dialer
            </SelectItem>
          ) : null}

          <SelectItem
            select_type="toggle"
            selected={
              this.props.edit_team_member.can_edit_templates == 1 ? true : false
            }
            onPress={() => {
              this.props.editTeamMember({
                prop: "can_edit_templates",
                value:
                  this.props.edit_team_member.can_edit_templates == 1 ? 0 : 1
              });
            }}
            description={
              "The user will be able to edit existing templates/mail sequences  and create new ones."
            }
          >
            Can Edit & Create Mail Sequences and Mail Designs?
          </SelectItem>

          <SelectItem
            select_type="toggle"
            selected={
              this.props.edit_team_member.can_export_data == 1 ? true : false
            }
            onPress={() => {
              this.props.editTeamMember({
                prop: "can_export_data",
                value: this.props.edit_team_member.can_export_data == 1 ? 0 : 1
              });
            }}
            description={
              "This user will be export any data collected through DealMachine via the Web Application"
            }
          >
            Can Export Data?
          </SelectItem>

          <SelectItem
            select_type="toggle"
            selected={
              this.props.edit_team_member.can_edit_custom_fields == 1
                ? true
                : false
            }
            onPress={() => {
              this.props.editTeamMember({
                prop: "can_edit_custom_fields",
                value:
                  this.props.edit_team_member.can_edit_custom_fields == 1
                    ? 0
                    : 1
              });
            }}
            description={
              "This user will be able to edit custom fields and statuses for leads."
            }
          >
            Can Edit Custom Fields & Statuses?
          </SelectItem>

          <SelectItem
            select_type="toggle"
            selected={
              this.props.edit_team_member.can_mimic_team_owner == 1
                ? true
                : false
            }
            onPress={() => {
              this.props.editTeamMember({
                prop: "can_mimic_team_owner",
                value:
                  this.props.edit_team_member.can_mimic_team_owner == 1 ? 0 : 1
              });
            }}
            description={
              "Admins and Partners can access Credit Card, Invoices and Driver Recruitment page "
            }
          >
            Can Mimic Team Owner?
          </SelectItem>
        </>
      );
    }

    return null;
  }
}

export default PermissionSettings;
