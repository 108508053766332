import React, { Component } from "react";

import {
  Card,
  CardBody,
  PrimaryButton,
  SecondaryButton,
  Copy,
  Bold,
  Title,
  Icon
} from "app/NativeComponents/common";

class Scene4 extends Component {
  render() {
    if (this.props.scene == 4) {
      return (
        <div className="animated fadeIn">
          <Card>
            <CardBody
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div className="animated zoomIn">
                <Icon color="#28F29E" icon="mail" size={44} />
              </div>
              <Title style={{ marginTop: 10 }}>
                <Bold>Mail Sent!</Bold>
              </Title>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene4;
