import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Wrapper,
  Spin,
  DropzoneButton,
  Row,
  Scroll,
  Copy,
  Bold,
  Icon,
  Gradient
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  SpinWrapper,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";
import {
  getPropertyImages,
  updateLeadPhotoUpload,
  uploadFile,
  setPhotoGallery,
  togglePhotoGallery,
  randomString,
  toggleLeadPhotoManager,
  pushSidePanel,
  askPhotoPermissionsThenContinue
} from "app/NativeActions";

import Photos from "./Photos";

class LeadPhotoManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      property: props.property
    };
    this.uploadLeadPhoto = this.uploadLeadPhoto.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      property: { deal }
    } = this.props;
    if (prevState.show !== this.state.show && this.state.show) {
      this.props.getPropertyImages({
        token: this.props.token,
        lead_id: this.props.property?.deal?.id,
        no_loading:
          deal.additional_images && deal.additional_images.length > 0
            ? true
            : false
      });
    }
  }

  uploadLeadPhoto(acceptedFiles = []) {
    const { token, property } = this.props;
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];

      let d = new Date();
      let unique_string = d.getTime();
      unique_string = unique_string + randomString(10) + ".jpeg";

      this.props.uploadFile({
        acceptedFiles: file,
        token,
        type: "lead_photo",
        payload: {
          lead_id: property.deal.id,
          unique_string
        },
        onLoading: () => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            file: file,
            type: "start"
          });
        },
        onSuccess: results => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            type: "complete",
            property: results.property
          });
          if (this.props.propertyUpdated) {
            this.props.propertyUpdated(results.property);
          }
        },
        onError: error => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            type: "error",
            error
          });
        },
        onProgress: progress => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            type: "progress",
            progress
          });
        }
      });
    }
  }

  toggleMenu(show) {
    this.props.toggleLeadPhotoManager(show);
    this.setState({
      show: show
    });
  }

  render() {
    const {
      token,
      colors,
      isMobile,
      device,
      style,
      property,
      property: { deal },
      popoverPlacement = "bottom",
      renderComponent = () => {},
      componentStyle = {},
      askPhotoPermissionsThenContinue,
      property_images_loading
    } = this.props;
    const { show, loading_images } = this.state;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        no_dismiss={true}
        onShow={s => {
          this.toggleMenu(s);
        }}
        popover_width={350}
        popoverPlacement={popoverPlacement}
        includeCloseButton={true}
        popoverSheetTop={440}
        scroll_to_hide={false}
        renderComponent={options => {
          if (device == "desktop") {
            return (
              <Wrapper wrapper_ref={this._popover} style={{ flex: 1 }}>
                {renderComponent({ ...options, pressedIn: show })}
              </Wrapper>
            );
          }
          return renderComponent({ ...options, pressedIn: show });
        }}
        renderMenu={() => {
          return (
            <Wrapper style={{ alignSelf: "stretch" }}>
              <Wrapper>
                <Row>
                  <Wrapper style={{ flex: 1, padding: 20, paddingBottom: 10 }}>
                    <Copy>
                      <Bold>Manage Lead Photos</Bold>
                    </Copy>
                    <Copy
                      style={{ fontSize: 12, color: colors.light_text_color }}
                    >
                      {property.property_address}
                    </Copy>
                  </Wrapper>
                </Row>
              </Wrapper>

              {property_images_loading ? (
                <Wrapper
                  style={{
                    height: 140,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <SpinWrapper text="Loading images..." />
                </Wrapper>
              ) : (
                <>
                  {this.props.device === "desktop" ? (
                    <Scroll
                      scroll_ref={this._scroll_view}
                      keyboardShouldPersistTaps={"always"}
                      horizontal={true}
                      style={{
                        padding: 10,
                        paddingBottom: 20,
                        height: 130,
                        width: "100%"
                      }}
                    >
                      <Row
                        style={{
                          //flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "flex-start"
                        }}
                      >
                        <Photos
                          property={property}
                          onStreetPickLockPress={() => {
                            this.toggleMenu(false);
                          }}
                          horizontal={true}
                          images={property?.deal?.additional_images}
                          photo_width={180}
                          photo_height={120}
                          photoStyle={{ marginRight: 5, marginLeft: 5 }}
                          propertyUpdated={this.props.propertyUpdated}
                        />
                      </Row>
                    </Scroll>
                  ) : (
                    <Wrapper
                      style={{
                        padding: 10,
                        marginBottom: 20,
                        height: 120,
                        width: "100%",
                        position: "relative"
                      }}
                    >
                      <Row
                        style={{
                          //flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "flex-start"
                        }}
                      >
                        <Photos
                          property={property}
                          onStreetPickLockPress={() => {
                            this.toggleMenu(false);
                          }}
                          horizontal={true}
                          images={property?.deal?.additional_images}
                          photo_width={120}
                          photo_height={120}
                          photoStyle={{ marginRight: 5, marginLeft: 5 }}
                          propertyUpdated={this.props.propertyUpdated}
                        />
                      </Row>
                    </Wrapper>
                  )}
                </>
              )}

              {device == "desktop" ? (
                <Wrapper
                  style={{ padding: 10, paddingBottom: 0, paddingTop: 0 }}
                >
                  <DropzoneButton
                    style={{
                      height: "auto",
                      alignSelf: "stretch",
                      cursor: "pointer"
                    }}
                    accept="image/jpeg, image/png"
                    maxSize={5242880}
                    multiple={true}
                    onDrop={acceptedFiles => {
                      this.uploadLeadPhoto(acceptedFiles);
                    }}
                    renderComponent={options => {
                      return (
                        <GhostButton
                          icon="file-upload"
                          noPress={true}
                          pressedIn={options.hovering}
                          primary={false}
                          upload_type="photos"
                          button_type="full"
                        >
                          Upload Photo
                        </GhostButton>
                      );
                    }}
                  ></DropzoneButton>
                  <InlineButton
                    button_type="full"
                    onPress={() => {
                      this.props.setPhotoGallery({
                        lead_id: deal.id,
                        property: property,
                        images: deal.additional_images,
                        propertyUpdated: this.props.propertyUpdated
                      });
                      this.props.pushSidePanel({
                        slug: "photo_gallery",
                        overlay: true,
                        focus_modal: true
                      });

                      this.toggleMenu(false);
                    }}
                  >
                    View Full Gallery
                  </InlineButton>
                </Wrapper>
              ) : (
                <Wrapper
                  style={{ padding: 10, paddingBottom: 0, paddingTop: 0 }}
                >
                  <DropzoneButton
                    style={{
                      height: "auto",
                      alignSelf: "stretch",
                      cursor: "pointer"
                    }}
                    upload_type="camera"
                    accept="image/jpeg, image/png"
                    maxSize={5242880}
                    multiple={true}
                    toggleMenu={this.toggleMenu}
                    askPhotoPermissionsThenContinue={
                      this.props.askPhotoPermissionsThenContinue
                    }
                    onDrop={acceptedFiles => {
                      this.uploadLeadPhoto(acceptedFiles);
                    }}
                    renderComponent={options => {
                      return (
                        <GhostButton
                          icon="add-a-photo"
                          noPress={true}
                          pressedIn={options.hovering}
                          primary={false}
                          upload_type="photos"
                          button_type="full"
                        >
                          Take A Photo
                        </GhostButton>
                      );
                    }}
                  />
                  <DropzoneButton
                    style={{
                      height: "auto",
                      alignSelf: "stretch",
                      cursor: "pointer"
                    }}
                    upload_type="gallery"
                    accept="image/jpeg, image/png"
                    maxSize={5242880}
                    multiple={true}
                    toggleMenu={this.toggleMenu}
                    askPhotoPermissionsThenContinue={
                      this.props.askPhotoPermissionsThenContinue
                    }
                    onDrop={acceptedFiles => {
                      this.uploadLeadPhoto(acceptedFiles);
                    }}
                    renderComponent={options => {
                      return (
                        <GhostButton
                          noPress={true}
                          pressedIn={options.hovering}
                          primary={false}
                          button_type="full"
                          style={{ marginTop: 0 }}
                        >
                          Choose From Library
                        </GhostButton>
                      );
                    }}
                  />
                  <InlineButton
                    button_type="full"
                    onPress={() => {
                      this.props.setPhotoGallery({
                        lead_id: deal.id,
                        property: property,
                        images: deal.additional_images,
                        propertyUpdated: this.props.propertyUpdated
                      });
                      this.props.pushSidePanel({
                        slug: "photo_gallery",
                        overlay: true,
                        focus_modal: true
                      });
                      this.toggleMenu(false);
                    }}
                  >
                    View Full Gallery
                  </InlineButton>
                </Wrapper>
              )}
            </Wrapper>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, property_map }) => {
  const { token, user } = auth;
  const { isMobile, device, is_camera_on } = native;
  const { colors } = settings;
  const { property_images_loading } = property_map;

  return {
    token,
    user,
    isMobile,
    device,
    colors,
    property_images_loading,
    is_camera_on
  };
};

export default connect(mapStateToProps, {
  getPropertyImages,
  updateLeadPhotoUpload,
  uploadFile,
  setPhotoGallery,
  togglePhotoGallery,
  toggleLeadPhotoManager,
  pushSidePanel,
  askPhotoPermissionsThenContinue
})(LeadPhotoManager);
