import React, { Component } from "react";
import {
  Button,
  Card,
  Row,
  Wrapper,
  CardBody,
  Split,
  Stretch,
  Title,
  Icon,
  CenterCenter,
  Copy,
  ExternalImage
} from "app/NativeComponents/common";

import { Select, TemplatePreview } from "app/NativeComponents/snippets";

class UploadButton extends Component {
  render() {
    if (this.props.template.include_custom_image == "include_custom_image") {
      if (this.props.template.custom_image != null) {
        return (
          <Button
            style={{
              borderTopWidth: 1,
              borderTopColor: this.props.colors.border_color,
              borderTopStyle: "solid"
            }}
            onPress={() => {}}
          >
            <Row>
              <TemplatePreview
                platform={this.props.platform}
                template_id={this.props.template.id}
                template_type={
                  this.props.template.html_template
                    ? this.props.template.html_template.template_type
                    : "postcard"
                }
                token={this.props.token}
                image={this.props.template.custom_image}
                save_info={true}
                width={150}
              />
              <Wrapper
                style={{
                  flex: 1
                }}
              >
                <CardBody>
                  <Split>
                    <Stretch>
                      <Title>Edit template image</Title>
                      <Copy>
                        The image you add will override all property photos on
                        mail pieces
                      </Copy>
                    </Stretch>
                  </Split>
                </CardBody>
              </Wrapper>
            </Row>
          </Button>
        );
      } else {
        return (
          <Button
            style={{
              borderTopWidth: 1,
              borderTopColor: this.props.colors.border_color,
              borderTopStyle: "solid"
            }}
            onPress={() => {}}
          >
            <Row>
              <Wrapper>
                <CardBody>
                  <Title>Add an image</Title>
                  <Copy>
                    The image you add will override all property photos on mail
                    pieces
                  </Copy>
                </CardBody>
              </Wrapper>
            </Row>
          </Button>
        );
      }
    }
    return <Wrapper />;
  }
}

export default UploadButton;
