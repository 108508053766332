import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  triggerLeadsCountReload,
  updateListBuilderProgress
} from "app/NativeActions";
import PusherService from "app/Router/PusherService";

class PusherWrapper extends PureComponent {
  constructor(props) {
    super(props);

    // Enable pusher logging - don't include this in production
    //Pusher.logToConsole = true;
    this.handleEvent = this.handleEvent.bind(this);
  }

  componentDidMount() {
    this.initPush();
  }

  initPush() {
    if (this._pusher) {
      this._pusher.allChannels().forEach(channel => {
        if (channel.name?.includes("list")) {
          this._pusher.unsubscribe(channel.name);
        }
      });
    }

    this._pusher = PusherService.getPusher();
    this._channel = this._pusher.subscribe(
      "list-" +
        this.props.list_id +
        "-" +
        this.props.user?.team_ws_key +
        "-dealmachine"
    );

    //all new sms message functions
    this._channel.bind("update_build_list", data =>
      this.handleEvent(data, "update_build_list")
    );
  }

  handleEvent(data, socket_event) {
    switch (socket_event) {
      case "update_build_list":
        if (data && data.list) {
          this.props.updateListBuilderProgress({
            list: data.list,
            success_counter: 1
          });
        }

        break;

      default:
        break;
    }
  }
  render() {
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;

  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  triggerLeadsCountReload,
  updateListBuilderProgress
})(PusherWrapper);
