import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "app/assets/style.css";

import {
  initApp,
  getUser,
  getStats,
  authFieldChanged,
  updateMarketingUser,
  updateMarketingPartner,
  getPartnerFromPromo,
  logout,
  triggerLogout,
  appRedirect,
  setModal,
  toggleModal,
  setPlatformType,
  setNotification,
  showNotification,
  triggerActivityUpdate,
  endRedirect,
  triggerIntercom,
  setDarkMode,
  toggleVideo,
  updateViewPort,
  toggleLeadImages,
  toggleDrivingImages,
  checkIfUserHasMetadata
} from "app/NativeActions";

import Plugins from "./Plugins";

import RedirectSystem from "./RedirectSystem";

import RouteBody from "./RouteBody";
/* Alerts */
import SuccessMessage from "app/DealMachineCore/reuseable/Alerts/SuccessMessage";
import ErrorMessage from "app/DealMachineCore/reuseable/Alerts/ErrorMessage";
import LoadingScreen from "app/DealMachineCore/reuseable/Alerts/LoadingScreen";
import Notification from "app/DealMachineCore/reuseable/Alerts/Notification";
import ModalView from "app/DealMachineCore/reuseable/Alerts/ModalView";

import RightPanels from "./RightPanels";

import Modals from "./Modals";
import PushPopSidePanel from "./PushPopSidePanel";
import UpsellModal from "app/DealMachineCore/components/UpsellModal";
import BackgroundLocation from "app/Router/BackgroundLocation";
import VirtualDriveTracker from "app/DealMachineCore/plugins/VirtualDriveTracker";
import MailerModalView from "app/DealMachineCore/components/MailerModalView";
import NativeDialer from "app/NativeComponents/components/NativeDialer";

import ProgressBars from "app/NativeComponents/components/ProgressBars";

class RouterComponent extends Component {
  /* Alerts */
  renderSuccess() {
    if (this.props.success_message && this.props.success_message != "") {
      return (
        <SuccessMessage
          text={this.props.success_message}
          title={this.props.success_title}
        />
      );
    }
  }

  renderError() {
    if (this.props.error_message && this.props.error_message != "") {
      return (
        <ErrorMessage
          text={this.props.error_message}
          title={this.props.error_title}
        />
      );
    }
  }

  renderLoading() {
    if (this.props.is_loading) {
      return (
        <LoadingScreen
          text={this.props.loading_message}
          title={this.props.loading_title}
        />
      );
    }
  }

  renderNotification() {
    if (this.props.show_notification) {
      return <Notification notification={this.props.notification} />;
    }
  }

  render() {
    return (
      <BrowserRouter>
        <>
          <BackgroundLocation />
          <VirtualDriveTracker />
          <RedirectSystem {...this.props} />
          {/*<OnboardingStepThrough {...this.props} />*/}
          <RouteBody {...this.props} />
          {/* Alerts and absolute items */}
          <RightPanels />
          <PushPopSidePanel />

          {/* TODO move this to push pop soon */}
          <MailerModalView />
          <UpsellModal />
          <Modals />
          <ModalView />

          {this.renderSuccess()}
          {this.renderError()}
          {this.renderLoading()}
          {this.renderNotification()}
          <Plugins {...this.props} />

          <ProgressBars />
          <NativeDialer />
        </>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  drawer,
  house,
  modal,
  marketing,
  partner,
  team_link,
  feature_toggle
}) => {
  const {
    token,
    user,
    onesignal_user_id,
    onesignal_push_token,
    trigger_logout
  } = auth;
  const {
    device,
    redirectTo,
    window_width,
    window_height,
    web_platform,
    isMobile,
    trigger_intercom
  } = native;
  const { can_pop, stats, video, video_toggle } = drawer;

  const {
    success_message,
    success_title,
    error_message,
    error_title,
    loading_message,
    loading_title,
    is_loading,
    show,
    notification,
    show_notification
  } = modal;

  const { info } = house;

  const { user_info, partner_info } = marketing;

  const { user_dealfinder_page } = team_link;

  const { is_partner, accepted_affiliate_latest_terms } = partner;
  return {
    token,
    user,
    device,
    window_width,
    window_height,
    isMobile,

    web_platform,

    redirectTo,
    onesignal_user_id,
    onesignal_push_token,
    trigger_logout,
    can_pop,
    stats,
    video,
    video_toggle,
    info,
    success_message,
    success_title,
    error_message,
    error_title,
    loading_message,
    loading_title,
    is_loading,
    show,
    notification,
    show_notification,

    trigger_intercom,

    user_info,
    partner_info,

    user_dealfinder_page,

    is_partner,
    accepted_affiliate_latest_terms
  };
};

export default connect(mapStateToProps, {
  initApp,
  getUser,
  getStats,

  authFieldChanged,
  updateMarketingUser,
  updateMarketingPartner,
  getPartnerFromPromo,
  logout,
  triggerLogout,

  appRedirect,
  setModal,
  toggleModal,
  setPlatformType,

  setNotification,
  showNotification,
  triggerActivityUpdate,
  endRedirect,
  triggerIntercom,
  setDarkMode,

  toggleVideo,
  updateViewPort,
  toggleLeadImages,
  toggleDrivingImages
})(RouterComponent);
