import React, { Component } from "react";

import { SelectItem } from "app/NativeComponents/snippets";
import { store } from "app/store";

class QuestionSelect extends Component {
  render() {
    const {
      title,
      selected_option,
      selected_options = null,
      slug,
      onPress,
      select_type = "radio"
    } = this.props;
    let selected = selected_option === slug;
    const colors = store.getState().settings.colors;
    const desktopMobile = store.getState().native.desktopMobile;
    const device = store.getState().native.device;

    if (selected_options) {
      selected = selected_options.includes(slug);
    }

    return (
      <SelectItem
        buttonContainerStyle={{ alignSelf: "stretch" }}
        style={
          desktopMobile || device == "mobile"
            ? {
                borderWidth: 1,
                borderColor: selected
                  ? colors.active_color
                  : colors.border_color,
                borderStyle: "solid",
                flex: 1,
                alignSelf: "stretch"
              }
            : {
                borderWidth: 1,
                borderColor: selected
                  ? colors.active_color
                  : colors.border_color,
                borderStyle: "solid",
                maxWidth: 500
              }
        }
        select_type={select_type}
        selected={selected}
        onPress={onPress}
      >
        {title}
      </SelectItem>
    );
  }
}

export default QuestionSelect;
