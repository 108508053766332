import React, { PureComponent } from "react";
import {
  Row,
  Icon,
  Input,
  Wrapper,
  Form,
  Spin,
  KeyboardView,
  Copy,
  Bold,
  Scroll
} from "app/NativeComponents/common";
import {
  GhostButton,
  SelectItem,
  IconButton,
  PopoverMenu
} from "app/NativeComponents/snippets";
import { dismissMobileKeyboard } from "app/NativeActions";

import { store } from "app/store";
class LeadsSearchBar extends PureComponent {
  componentWillUnmount() {
    clearInterval(this._blur_timeout);
  }
  render() {
    const {
      style,
      containerStyle,
      value = "",
      title = "Searc",
      onChange = () => {},
      showButton = false,
      disabledButton = false,
      onPress = () => {},
      onFocus = () => {},
      onBlur = () => {},
      autoFocus = false,
      items = [],
      renderAutocomplete = null,
      isOnboardingActive,
      buttonLoading = false,
      loading = false,
      buttonText = "Submit",
      icon = "search",
      onIconPress = null,
      returnKeyType = "search",
      renderLeft = null,
      renderRight = null,
      error_title = "",
      error_message = "",
      borderRadius = 30,
      input_ref = null
    } = this.props;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;

    return (
      <Wrapper
        style={
          (items &&
            items.length > 0 &&
            !!value &&
            value.length > 0 &&
            !loading) ||
          !!error_title ||
          !!error_message ||
          renderAutocomplete
            ? {
                borderRadius: borderRadius,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.gray_selected_color,
                backgroundColor: colors.card_color,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomWidth: 0,
                ...style
              }
            : {
                borderRadius: borderRadius,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: colors.gray_selected_color,
                borderBottomRightRadius: borderRadius,
                borderBottomLeftRadius: borderRadius,
                borderBottomWidth: 1,
                backgroundColor: colors.card_color,
                ...style
              }
        }
      >
        <Wrapper
          style={
            device == "mobile" &&
            ((items && items.length > 0) ||
              !!error_title ||
              !!error_message ||
              renderAutocomplete)
              ? {}
              : {
                  justifyContent: "center",
                  height: device == "mobile" ? 50 : 40,
                  overflow: "visible",
                  position: "relative"
                }
          }
        >
          <Form
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: 1,
              left: 0
            }}
            onSubmit={() => {
              if (!disabledButton && !buttonLoading) {
                onPress();
              }
            }}
          >
            <Row style={{ height: device == "mobile" ? 50 : 40 }}>
              {renderLeft ? renderLeft() : null}
              {!showButton && loading ? (
                <Wrapper
                  style={{
                    width: 45,
                    alignSelf: "stretch",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Spin size={"small"} />
                </Wrapper>
              ) : icon !== "blank" ? (
                <>
                  {onIconPress ? (
                    <IconButton
                      icon={icon}
                      button_type="small"
                      onPress={onIconPress}
                    />
                  ) : (
                    <IconButton
                      icon={icon}
                      button_type="small"
                      noPress={true}
                    />
                  )}
                </>
              ) : null}

              <Input
                input_ref={input_ref}
                name="search"
                input_style="dm-new-input"
                returnKeyType={returnKeyType}
                blurOnSubmit={true}
                keyboardType="default"
                placeholder={title}
                onChange={value => {
                  onChange(value);
                }}
                style={{
                  borderBottomWidth: 0,
                  flex: 1
                }}
                containerStyle={{
                  ...containerStyle
                }}
                onSubmitEditing={() => {
                  dismissMobileKeyboard();
                  onPress();
                }}
                autoFocus={autoFocus}
                onFocus={() => {
                  onFocus();
                }}
                onBlur={() => {
                  this._blur_timeout = setTimeout(() => {
                    onBlur();
                  }, 250);
                }}
                value={value}
                type="text"
              />
              {renderRight ? renderRight() : null}

              {showButton ? (
                <Row
                  style={{
                    flexDirection: "row",
                    borderColor: colors.border_color,
                    backgroundColor: !this.props.disabledButton
                      ? colors.orange_color
                      : colors.gray_selected_color,
                    borderRadius: 30,
                    padding: 0,
                    marginRight: 0
                  }}
                >
                  <Wrapper
                    style={{
                      borderRightWidth: 1,
                      borderRightColor: "rgba(255, 255, 255, 0.3)",
                      borderRightStyle: "solid"
                    }}
                  >
                    <GhostButton
                      style={{
                        margin: 0
                      }}
                      innerStyle={
                        device == "mobile"
                          ? {
                              height: 50,
                              alignItems: "center",
                              justifyContent: "center"
                            }
                          : null
                      }
                      primary={true}
                      onPress={() => {
                        dismissMobileKeyboard();
                        onPress();
                      }}
                      disabled={disabledButton}
                      pressedIn={true}
                      loading={buttonLoading}
                    >
                      {buttonText}
                    </GhostButton>
                  </Wrapper>
                  <PopoverMenu
                    show={false}
                    no_swipe={true}
                    no_cancel={true}
                    popover_width={300}
                    popoverPlacement={"top"}
                    disabled={false}
                    includeCloseButton={true}
                    renderComponent={options => {
                      return (
                        <IconButton
                          style={{
                            width: 0,
                            height: 0,
                            marginLeft: 15,
                            marginRight: 15
                          }}
                          icon_color={
                            disabledButton
                              ? colors.light_text_color
                              : colors.white_color
                          }
                          icon={"keyboard-arrow-down"}
                          noPress={true}
                          // pressedIn={}
                        />
                      );
                    }}
                    menu_items={[
                      {
                        title: "Search by Address",
                        onPress: () => {
                          this.props.updateSearchType({
                            value: "address"
                          });
                        },
                        select_type: "none",
                        icon:
                          this.props.search_type === "address"
                            ? "radio-button-checked"
                            : "radio-button-unchecked"
                      },
                      {
                        title: "Search by Contact Name",
                        onPress: () => {
                          this.props.updateSearchType({
                            value: "contact_name"
                          });
                        },
                        select_type: "none",
                        icon:
                          this.props.search_type === "contact_name"
                            ? "radio-button-checked"
                            : "radio-button-unchecked"
                      },

                      {
                        title: "Search by Phone",
                        onPress: () => {
                          this.props.updateSearchType({
                            value: "phone"
                          });
                        },
                        select_type: "none",
                        icon:
                          this.props.search_type === "phone"
                            ? "radio-button-checked"
                            : "radio-button-unchecked"
                      },
                      {
                        title: "Search by Email",
                        onPress: () => {
                          this.props.updateSearchType({
                            value: "email"
                          });
                        },
                        select_type: "none",
                        icon:
                          this.props.search_type === "email"
                            ? "radio-button-checked"
                            : "radio-button-unchecked"
                      },
                      {
                        title: "Search by APN",
                        onPress: () => {
                          this.props.updateSearchType({
                            value: "apn"
                          });
                        },
                        select_type: "none",
                        icon:
                          this.props.search_type === "apn"
                            ? "radio-button-checked"
                            : "radio-button-unchecked"
                      }
                    ]}
                  />
                </Row>
              ) : null}
            </Row>
          </Form>
          {(items && items.length > 0) ||
          !!error_title ||
          !!error_message ||
          renderAutocomplete ? (
            <>
              {device == "mobile" ? (
                <KeyboardView
                  style={{
                    marginTop: 55,
                    backgroundColor: colors.card_color
                  }}
                >
                  {renderAutocomplete ? (
                    renderAutocomplete()
                  ) : !!error_title ? (
                    <Wrapper style={{ padding: 25 }}>
                      {!!error_title ? (
                        <Copy>
                          <Bold>{error_title}</Bold>
                        </Copy>
                      ) : null}
                      {!!error_title ? (
                        <Copy style={{ fontSize: 10 }}>{error_message}</Copy>
                      ) : null}
                    </Wrapper>
                  ) : (
                    items.map((item, i) => {
                      return (
                        <SelectItem
                          key={"item_" + i}
                          primary={item.primary}
                          loading={item.loading}
                          trackingId={
                            isOnboardingActive ? "enter_home_address" : ""
                          }
                          select_type="none"
                          icon="keyboard-arrow-right"
                          onPress={() => {
                            dismissMobileKeyboard();
                            if (item.onPress) {
                              item.onPress();
                            }
                          }}
                          subtext={item.subtext}
                          description={item.subtitle}
                        >
                          {item.title}
                        </SelectItem>
                      );
                    })
                  )}
                </KeyboardView>
              ) : (
                <Wrapper
                  style={{
                    position: "absolute",
                    top: device == "mobile" ? 50 : 40,
                    right: -1,
                    left: -1,
                    zIndex: 10,
                    borderBottomRightRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderStyle: "solid",
                    borderColor: colors.gray_selected_color,
                    backgroundColor: colors.card_color
                  }}
                >
                  <Scroll style={{ maxHeight: 350 }}>
                    {renderAutocomplete ? (
                      renderAutocomplete()
                    ) : !!error_title ? (
                      <Wrapper style={{ padding: 25 }}>
                        {!!error_title ? (
                          <Copy>
                            <Bold>{error_title}</Bold>
                          </Copy>
                        ) : null}
                        {!!error_title ? (
                          <Copy style={{ fontSize: 10 }}>{error_message}</Copy>
                        ) : null}
                      </Wrapper>
                    ) : (
                      items.map((item, i) => {
                        if (item.renderComponent) {
                          return (
                            <Wrapper key={"item_" + i}>
                              {item.renderComponent()}
                            </Wrapper>
                          );
                        }
                        return (
                          <SelectItem
                            key={"item_" + i}
                            select_type="none"
                            icon="keyboard-arrow-right"
                            primary={item.primary}
                            onPress={() => {
                              dismissMobileKeyboard();
                              if (item.onPress) {
                                item.onPress();
                              }
                            }}
                            trackingId={
                              isOnboardingActive ? "enter_home_address" : ""
                            }
                            subtext={item.subtext}
                            description={item.subtitle}
                          >
                            {item.title}
                          </SelectItem>
                        );
                      })
                    )}
                  </Scroll>
                </Wrapper>
              )}
            </>
          ) : null}
        </Wrapper>
      </Wrapper>
    );
  }
}

export { LeadsSearchBar };
