import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Title,
  Copy,
  InnerCopy,
  Bold,
  Row
} from "app/NativeComponents/common";
import { GhostButton, InformationItem } from "app/NativeComponents/snippets";
import moment from "moment";
class PropertyInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: ""
    };
  }

  updateHouseTab(tab) {
    this.setState({ tab: tab === this.state.tab ? "" : tab });
  }

  render() {
    const { property, search = "" } = this.props;

    if (this.props.property?.mls) {
      return (
        <Wrapper style={this.props.style}>
          {!search ? (
            <Wrapper
              style={{
                padding: 25,
                paddingTop: 15,
                paddingBottom: 15
              }}
            >
              <Copy>
                <Bold>MLS Info</Bold>

                {!!this.props.property?.last_updated_from_data_provider ? (
                  <InnerCopy
                    style={{
                      fontSize: 10,
                      color: this.props.colors.light_text_color
                    }}
                  >
                    {"  Updated "}
                    {moment(this.props.property?.mls?.date_updated).format(
                      "MMM Do, YYYY"
                    )}
                  </InnerCopy>
                ) : null}
              </Copy>
            </Wrapper>
          ) : null}
          <Wrapper>
            <InformationItem
              item={
                !!property.mls.status_sub_type
                  ? property.mls.status + " - " + property.mls.status_sub_type
                  : property.mls.status
              }
              label={"Market Status:"}
              format={"text"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property?.mls?.initial_listing_date}
              label={"Initial listing date:"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property?.mls?.current_listing_price}
              label={"Current listing price:"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property?.mls?.days_on_market}
              label={"Days on market:"}
              format={"number"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.sold_date}
              label={"Sale date (MLS):"}
              format={"date"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.sold_price}
              label={"Sale price (MLS):"}
              format={"money"}
              search={search}
              hiddenFromNonSubs={true}
            />

            <InformationItem
              item={property.mls.listing_agent_name}
              label={"Listing agent name:"}
              format={"name"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.listing_agent_phone}
              label={"Listing agent phone number:"}
              format={"phone"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.listing_agent_email}
              label={"Listing agent email address:"}
              format={"email"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.listing_office_name}
              label={"Listing office:"}
              format={"name"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.listing_office_address}
              label={"Listing office address:"}
              format={"address"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.listing_office_phone}
              label={"Listing office phone :"}
              format={"phone"}
              search={search}
              hiddenFromNonSubs={true}
            />
            <InformationItem
              item={property.mls.listing_office_email}
              label={"Listing office email address:"}
              format={"email"}
              search={search}
              hiddenFromNonSubs={true}
            />
          </Wrapper>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default PropertyInfo;
