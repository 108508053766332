import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Copy,
  InnerCopy,
  Bold,
  Title,
  Icon,
  Button,
  Spin
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  SmallLabel,
  SelectItem,
  InlineButton,
  Tag,
  IconButton
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  popSidePanel,
  getListStackColor,
  numberWithCommas,
  toTitleCase
} from "app/NativeActions";
import ShowStackedListsButton from "app/DealMachineCore/reuseable/ShowStackedListsButton";

import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import PersonItem from "app/DealMachineCore/components/Property/OwnerInfo/PersonItem";
import {
  determineMainOwnerInfo,
  addDeal,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";
import PropertyFlags from "app/DealMachineCore/components/Property/EstimatedValue/PropertyFlags";
import moment from "moment/moment";
import { triggerHapticFeedback } from "app/NativeActions";

class LeadItem extends Component {
  constructor(props) {
    super(props);

    this._property_status_button =
      props.device == "desktop" ? React.createRef() : null;
    this.state = {
      is_dealfinder: props.user?.team_clearance_level == 0 ? true : false,
      hovering: false,
      added: props.property?.deal && props.property?.deal?.removed == 0
    };

    this.renderPhoto = this.renderPhoto.bind(this);
    this.formatBathsAndBeds = this.formatBathsAndBeds.bind(this);

    this.renderAddButton = this.renderAddButton.bind(this);
  }

  renderAddButton(image_button = false) {
    const {
      colors,
      property,
      add_deal_loading,
      source_of_truth,
      all_feature_metadata
    } = this.props;
    const is_adding =
      add_deal_loading &&
      add_deal_loading.some(p => p?.property_id == property?.property_id);

    if (
      ((property?.deal && property?.deal?.removed == 0) || this.state.added) &&
      this.props.include_check_icon
    ) {
      if (image_button) {
        return (
          <Wrapper
            style={{
              backgroundColor: colors.active_color,
              width: 30,
              height: 30,
              borderRadius: 60,
              position: "absolute",
              bottom: 5,
              right: -10,
              alignItems: "center",
              justifyContent: "center"
            }}
            tooltip={"Added Lead"}
            tooltipPlacement={"top"}
          >
            <Icon icon={"check"} size={14} color={colors.white_text_color} />
          </Wrapper>
        );
      }
      return (
        <Wrapper
          style={{
            backgroundColor: colors.active_color,
            width: 20,
            height: 20,
            borderRadius: 40,
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 10
          }}
          tooltip={"Added Lead"}
          tooltipPlacement={"top"}
        >
          <Icon icon={"check"} size={14} color={colors.white_text_color} />
        </Wrapper>
      );
    }

    if (this.props.include_add_button && this.props.include_check_icon) {
      if (image_button) {
        return (
          <Button
            style={
              this.state.hovering
                ? {
                    backgroundColor: colors.hover_color,
                    width: 30,
                    height: 30,
                    borderRadius: 60,
                    position: "absolute",
                    bottom: 5,
                    right: -10,
                    alignItems: "center",
                    justifyContent: "center"
                  }
                : {
                    width: 30,
                    height: 30,
                    borderRadius: 60,
                    position: "absolute",
                    bottom: 5,
                    right: -10,
                    alignItems: "center",
                    justifyContent: "center"
                  }
            }
            disabled={is_adding}
            onHover={() => {
              this.setState({ hovering: true });
            }}
            onHoverOut={() => {
              this.setState({ hovering: false });
            }}
            onPress={() => {
              if (property) {
                triggerHapticFeedback({ type: "impactMedium" });
                this.props.addDeal({
                  token: this.props.token,
                  property: property,
                  route_id: this.props.current_route
                    ? this.props.current_route?.route_id
                    : 0,
                  address: property ? property.property_address : null,
                  address2: property ? property.property_address2 : null,
                  city: property ? property.property_address_city : null,
                  state: property ? property.property_address_state : null,
                  zip: property ? property.property_address_zip : null,
                  devicetype: this.props.isMobile
                    ? "mobile_map"
                    : "desktop_map",
                  add_type: "no_active",
                  onSuccess: results => {
                    this.setState({
                      added: true
                    });
                  },
                  onError: error => {
                    const upsell_info = error?.upsell_info;
                    this.props.pushSidePanel({
                      slug: "unlock_feature_modal",
                      overlay: true,
                      data: {
                        feature_upsell_info: upsell_info.feature_metadata,
                        renderContent: () => {
                          return (
                            <Wrapper style={{ padding: 25 }}>
                              <Copy>
                                You have added{" "}
                                <Bold>
                                  {numberWithCommas(upsell_info.current_count)}{" "}
                                  leads
                                </Bold>{" "}
                                Adding this property will exceed your limit of{" "}
                                <Bold>
                                  {numberWithCommas(
                                    upsell_info.subscription.metadata
                                      .total_number_of_leads
                                  )}
                                </Bold>
                                . Permanently delete a lead or upgrade to
                                continue.
                              </Copy>
                            </Wrapper>
                          );
                        },
                        buttonText: "Upgrade And Add Lead",
                        confirmText: "Confirm Upgrade?",
                        onSuccess: () => {
                          this.props.popSidePanel();
                          const { property, token, isMobile, current_route } =
                            this.props;
                          this.props.addDeal({
                            token: this.props.token,
                            property: property,
                            route_id: this.props.current_route
                              ? this.props.current_route?.route_id
                              : 0,
                            address: property
                              ? property.property_address
                              : null,
                            address2: property
                              ? property.property_address2
                              : null,
                            city: property
                              ? property.property_address_city
                              : null,
                            state: property
                              ? property.property_address_state
                              : null,
                            zip: property
                              ? property.property_address_zip
                              : null,
                            devicetype: this.props.isMobile
                              ? "mobile_map"
                              : "desktop_map",
                            add_type: "no_active",
                            onSuccess: results => {
                              this.setState({
                                added: true
                              });
                            }
                          });
                        }
                      }
                    });
                  }
                });
              }
            }}
          >
            <Wrapper
              style={{
                backgroundColor: colors.success_color,
                width: 30,
                height: 30,
                borderRadius: 60,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {is_adding ? (
                <Spin size={"small"} color={colors.white_text_color} />
              ) : (
                <Icon icon={"add"} size={18} color={colors.white_text_color} />
              )}
            </Wrapper>
          </Button>
        );
      }

      return (
        <Button
          disabled={is_adding}
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
          onHover={() => {
            this.setState({ hovering: true });
          }}
          onHoverOut={() => {
            this.setState({ hovering: false });
          }}
          tooltip={"Add Lead"}
          tooltipPlacement={"top"}
          onPress={() => {
            if (property) {
              triggerHapticFeedback({ type: "impactMedium" });

              this.props.addDeal({
                token: this.props.token,
                property: property,
                route_id: this.props.current_route
                  ? this.props.current_route?.route_id
                  : 0,
                address: property ? property.property_address : null,
                address2: property ? property.property_address2 : null,
                city: property ? property.property_address_city : null,
                state: property ? property.property_address_state : null,
                zip: property ? property.property_address_zip : null,
                devicetype: this.props.isMobile ? "mobile_map" : "desktop_map",
                add_type: "no_active",
                onSuccess: results => {
                  this.setState({
                    added: true
                  });
                },
                onError: error => {
                  const upsell_info = error?.upsell_info;
                  this.props.pushSidePanel({
                    slug: "unlock_feature_modal",
                    overlay: true,
                    data: {
                      feature_upsell_info: upsell_info.feature_metadata,
                      renderContent: () => {
                        return (
                          <Wrapper style={{ padding: 25 }}>
                            <Copy>
                              You have added{" "}
                              <Bold>
                                {numberWithCommas(upsell_info.current_count)}{" "}
                                leads
                              </Bold>{" "}
                              Adding this property will exceed your limit of{" "}
                              <Bold>
                                {numberWithCommas(
                                  upsell_info.subscription.metadata
                                    .total_number_of_leads
                                )}
                              </Bold>
                              . Permanently delete a lead or upgrade to
                              continue.
                            </Copy>
                          </Wrapper>
                        );
                      },
                      buttonText: "Upgrade And Add Lead",
                      confirmText: "Confirm Upgrade?",
                      onSuccess: () => {
                        this.props.popSidePanel();
                        const { property, token, isMobile, current_route } =
                          this.props;
                        this.props.addDeal({
                          token: this.props.token,
                          property: property,
                          route_id: this.props.current_route
                            ? this.props.current_route?.route_id
                            : 0,
                          address: property ? property.property_address : null,
                          address2: property
                            ? property.property_address2
                            : null,
                          city: property
                            ? property.property_address_city
                            : null,
                          state: property
                            ? property.property_address_state
                            : null,
                          zip: property ? property.property_address_zip : null,
                          devicetype: this.props.isMobile
                            ? "mobile_map"
                            : "desktop_map",
                          add_type: "no_active",
                          onSuccess: results => {
                            this.setState({
                              added: true
                            });
                          }
                        });
                      }
                    }
                  });
                }
              });
            }
          }}
        >
          <Wrapper
            style={{
              backgroundColor: colors.success_color,
              width: 20,
              height: 20,
              borderRadius: 40,
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 10
            }}
          >
            {is_adding ? (
              <Spin size={"small"} color={colors.white_text_color} />
            ) : (
              <Icon icon={"add"} size={14} color={colors.white_text_color} />
            )}
          </Wrapper>
        </Button>
      );
    }

    return null;
  }

  renderLoading() {
    const { property } = this.props;

    if (
      property &&
      property.deal &&
      property.deal.removed != 1 &&
      property.deal.updating &&
      property.deal.updating.is_updating
    ) {
      return <SpinWrapper text="property.deal.updating.updating_title" />;
    }
  }

  renderPhoto({ image_size = 80, no_photo = false }) {
    const { property, colors, is_selecting, add_deal_loading, small_mode } =
      this.props;
    const tags = property?.deal?.tags;
    const is_adding =
      add_deal_loading &&
      add_deal_loading.some(p => p?.property_id == property?.property_id);
    return (
      <Wrapper
        style={{
          paddingLeft: small_mode ? 5 : 15,
          paddingTop: small_mode ? 5 : 15,
          paddingBottom: small_mode ? 5 : 15,
          position: "relative"
        }}
      >
        <LeadMainPhoto
          property={property}
          image_toggle={this.props.image_toggle}
          height={small_mode ? parseInt(image_size) / 2 : parseInt(image_size)}
          style={{
            width: small_mode ? parseInt(image_size) / 2 : parseInt(image_size),
            margin: 0
          }}
        />
        {this.renderAddButton(true)}
      </Wrapper>
    );
  }

  formatBathsAndBeds(number = null) {
    if (!!number) {
      let num = parseFloat(number);

      if (Number.isInteger(num)) {
        return num; // Return the number as is if it's already an integer
      } else {
        return num?.toFixed(1); // Round to one decimal place if there's a decimal part
      }
    }
    return 0;
  }

  render() {
    const {
      property,
      property: { deal },
      colors,
      style,
      is_selecting,
      selected,
      renderBottom = null
    } = this.props;
    const main_owner = determineMainOwnerInfo(property);
    const display_property = determineDisplayProperty(property);
    const { line1, line2 } = formatAddress({
      address: {
        address: display_property.property_address,
        address2: display_property.property_address2,
        address_city: display_property.property_address_city,
        address_state: display_property.property_address_state,
        address_zip: display_property.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    const number_of_stacked_lists =
      this.props.property?.deal?.stacked_lists?.length;

    let display_value = !!property?.EstimatedValue
      ? "$" + numberWithCommas(property?.EstimatedValue)
      : "--";

    let display_value_label = "Est. Value";
    let show_estimated_value = false;

    let description = "";
    if (!!property?.market_status?.value) {
      if (
        property.market_status?.value.startsWith("Active") ||
        property.market_status?.value.startsWith("Contingent")
      ) {
        show_estimated_value = true;
        display_value_label = "Listed Price";
        if (!!property.mls?.current_listing_price) {
          display_value =
            "$" + numberWithCommas(property.mls?.current_listing_price);
        }
      } else if (property?.market_status?.value.startsWith("Sold")) {
        show_estimated_value = true;
        display_value_label = "Sale Price";
        if (!!property.mls?.sold_price) {
          display_value = "$" + numberWithCommas(property.mls?.sold_price);
        }
        if (property?.mls.sold_date) {
          description =
            "Sold on " + moment(property?.mls.sold_date).format("MMM Do, YYYY");
        }
      }
    }

    return (
      <>
        <SelectItem
          renderLeft={() => {
            return (
              <>
                {is_selecting ? (
                  <IconButton
                    noPress={true}
                    icon={selected ? "check-box" : "check-box-outline-blank"}
                  />
                ) : null}
                {this.props.image_toggle !== "none"
                  ? this.renderPhoto({ image_size: 80 })
                  : null}
              </>
            );
          }}
          renderTop={this.props.renderTop}
          onPress={this.props.onPress}
          disabled={
            this.state.loading ||
            this.props.select_drive_start ||
            this.props.onboarding_map
          }
          loading={this.props.loading}
          selected={true}
          select_type="none"
          style={style}
          innerStyle={
            this.props.small_mode
              ? {
                  padding: 10
                }
              : null
          }
          noPress={
            !this.props.select_drive_start &&
            property &&
            !this.props.onboarding_map
              ? false
              : true
          }
          icon={
            !this.props.select_drive_start &&
            property &&
            !this.props.expanded &&
            !this.props.onboarding_map
              ? "keyboard-arrow-right"
              : ""
          }
          renderRight={
            this.props.expanded
              ? () => {
                  return (
                    <Row>
                      {this.renderTagsAndButton()}
                      <IconButton
                        icon={"keyboard-arrow-right"}
                        noPress={true}
                      />
                    </Row>
                  );
                }
              : null
          }
          renderContent={() => {
            return (
              <Wrapper style={{ flex: 1 }}>
                {!!this.props.label ? (
                  <Copy
                    style={{
                      fontSize: 12,
                      color: this.props.dialer_mode
                        ? colors.active_color
                        : colors.text_color
                    }}
                  >
                    <Bold>{this.props.label}</Bold>
                  </Copy>
                ) : null}

                {!this.props.no_value ? (
                  <Wrapper>
                    <Row>
                      <Title style={{ color: colors.actionable_text_color }}>
                        <Bold>{display_value}</Bold>
                      </Title>

                      <Copy
                        style={{
                          color: colors.light_text_color,
                          fontSize: 12,
                          marginLeft: 10
                        }}
                      >
                        {display_value_label}
                      </Copy>

                      {this.props.image_toggle === "none"
                        ? this.renderAddButton()
                        : null}
                    </Row>
                  </Wrapper>
                ) : null}

                <Wrapper
                  style={
                    this.props.small_mode
                      ? {}
                      : { paddingTop: 5, paddingBottom: 10 }
                  }
                >
                  <Copy
                    style={{
                      marginBottom: 5,
                      color: this.props.dialer_mode
                        ? colors.white_text_color
                        : colors.text_color
                    }}
                  >
                    {line1}
                    {!!line2 ? (
                      <InnerCopy style={{ marginBottom: 5 }}>
                        {", " + line2}
                      </InnerCopy>
                    ) : null}
                  </Copy>

                  {!this.props.small_mode ? (
                    <Row>
                      {this.formatBathsAndBeds(property?.total_bedrooms) > 0 ? (
                        <Wrapper>
                          <Copy
                            style={{
                              color: this.props.dialer_mode
                                ? colors.white_text_color
                                : colors.text_color
                            }}
                          >
                            <Bold>
                              {this.formatBathsAndBeds(
                                property?.total_bedrooms
                              )}
                            </Bold>{" "}
                            bds
                          </Copy>
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          <Copy
                            style={{
                              color: this.props.dialer_mode
                                ? colors.white_text_color
                                : colors.text_color
                            }}
                          >
                            -- bds
                          </Copy>
                        </Wrapper>
                      )}

                      {this.formatBathsAndBeds(property?.total_baths) > 0 ? (
                        <Wrapper style={{ paddingLeft: 15 }}>
                          <Copy
                            style={{
                              color: this.props.dialer_mode
                                ? colors.white_text_color
                                : colors.text_color
                            }}
                          >
                            <Bold>
                              {this.formatBathsAndBeds(property?.total_baths)}
                            </Bold>{" "}
                            ba
                          </Copy>
                        </Wrapper>
                      ) : (
                        <Wrapper style={{ paddingLeft: 10 }}>
                          <Copy
                            style={{
                              color: this.props.dialer_mode
                                ? colors.white_text_color
                                : colors.text_color
                            }}
                          >
                            -- ba
                          </Copy>
                        </Wrapper>
                      )}

                      {property?.building_square_feet > 0 ? (
                        <Wrapper style={{ paddingLeft: 10 }}>
                          <Copy
                            style={{
                              color: this.props.dialer_mode
                                ? colors.white_text_color
                                : colors.text_color
                            }}
                          >
                            <Bold>
                              {numberWithCommas(property.building_square_feet)}
                            </Bold>{" "}
                            sqft
                          </Copy>
                        </Wrapper>
                      ) : (
                        <Wrapper style={{ paddingLeft: 10 }}>
                          <Copy
                            style={{
                              color: this.props.dialer_mode
                                ? colors.white_text_color
                                : colors.text_color
                            }}
                          >
                            -- sqft
                          </Copy>
                        </Wrapper>
                      )}
                    </Row>
                  ) : null}
                </Wrapper>
                {!this.props.small_mode ? (
                  <PropertyFlags
                    property={property}
                    dialer_mode={this.props.dialer_mode}
                    colors={colors}
                    round={true}
                  />
                ) : null}

                {this.props.contact_preview &&
                property?.all_contacts?.length > 0 &&
                !this.props.small_mode ? (
                  <>
                    <SmallLabel style={{ paddingLeft: 0, paddingRight: 0 }}>
                      Associated Contacts:
                    </SmallLabel>
                    <Row style={{ flexWrap: "wrap" }}>
                      {property.all_contacts.map((contact, i) => {
                        if (
                          i < 3 &&
                          !!contact.surname &&
                          !!contact.given_name
                        ) {
                          return (
                            <PersonItem
                              key={"person_" + i}
                              person={contact}
                              property={property}
                              use_linking_flags={true}
                              noPress={true}
                              style_type={"small"}
                            />
                          );
                        }
                        return null;
                      })}
                    </Row>
                  </>
                ) : null}

                {renderBottom ? renderBottom() : null}
              </Wrapper>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  route,
  property_map,
  billing
}) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { source_of_truth, all_feature_metadata } = billing;
  const { device, isMobile } = native;
  const { current_route } = route;
  const { add_deal_loading } = property_map;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    current_route,
    add_deal_loading,
    source_of_truth,
    all_feature_metadata
  };
};

export default connect(mapStateToProps, {
  addDeal,
  pushSidePanel,
  popSidePanel
})(LeadItem);
