import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  ADDRESS_FIELD_CHANGE,
  ADDRESS_INIT,
  UPDATE_RETURN_ADDRESS,
  UPDATE_RETURN_ADDRESS_FAIL,
  UPDATE_RETURN_ADDRESS_SUCCESS,
  RESET_SETTINGS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_PHOTO_TYPE,
  INIT_UPDATE_USER,
  UPDATE_USER_FIELD_CHANGE,
  SELECT_STATE,
  SELECT_DEFAULT_SENDING_OPTIONS,
  RELOAD_DEALS,
  TOGGLE_ONBOARDING,
  SET_EDIT_RETURN_LOCATION,
  SET_DARK_MODE,
  SET_PHOTO_CAMERAROLL,
  SET_TRACKING_EVENT,
  UPDATE_MAP_LOCATION,
  UPDATE_DASHBOARD_SETTINGS_SUCCESS,
  GET_DASHBOARD_SETTINGS,
  GET_DASHBOARD_SETTINGS_SUCCESS,
  GET_MENTOR_AUTOCOMPLETE,
  GET_MENTOR_AUTOCOMPLETE_FAIL,
  GET_MENTOR_AUTOCOMPLETE_SUCCESS,
  CLEAR_MENTOR_AUTOCOMPLETE,
  SET_ACTIVE_CONVERSATION,
  SET_TRACKING,
  BYPASS_LOCATION
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";

import API from "app/DealMachineCore/apis/DealMachineAPI";
import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";

const api = API.create();
const apiv2 = APIV2.create();

export const addressInit = ({ address }) => {
  const return_address = {
    address: address.address || "",
    address2: address.address2 || "",
    city: address.address_city || "",
    state: address.address_state || "",
    zipcode: address.address_zip || "",
    phone: address.phone || ""
  };

  return {
    type: ADDRESS_INIT,
    payload: { return_address }
  };
};
export const setTracking = toggle => {
  return {
    type: SET_TRACKING,
    payload: toggle
  };
};
export const selectDefaultSendingOptions = toggle => {
  return {
    type: SELECT_DEFAULT_SENDING_OPTIONS,
    payload: toggle
  };
};

export const bypassLocation = toggle => {
  return {
    type: BYPASS_LOCATION,
    payload: toggle
  };
};

export const resetSettings = () => {
  return {
    type: RESET_SETTINGS
  };
};

export const addressFieldChanged = ({ prop, value }) => {
  return {
    type: ADDRESS_FIELD_CHANGE,
    payload: { prop, value }
  };
};

export const updatePhotoType = photo_type => {
  return {
    type: UPDATE_PHOTO_TYPE,
    payload: photo_type
  };
};

export const updateReturnAddress = ({
  token,
  address,
  address2,
  city,
  state,
  zipcode,
  phone,
  onboarding
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: UPDATE_RETURN_ADDRESS });
    api
      .editReturnAddress(token, address, address2, city, state, zipcode, phone)
      .then(response => {
        if (response.problem != null) {
          updateReturnAddressFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          updateReturnAddressFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          updateReturnAddressSuccess(
            dispatch,
            response.data.results.return_address,
            onboarding
          );
        }
      });
  };
};

const updateReturnAddressFail = (dispatch, error) => {
  dispatch({ type: UPDATE_RETURN_ADDRESS_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const updateReturnAddressSuccess = (dispatch, return_address, onboarding) => {
  dispatch({
    type: UPDATE_RETURN_ADDRESS_SUCCESS,
    payload: {
      address: return_address.address,
      address2: return_address.address2,
      address_city: return_address.address_city,
      address_state: return_address.address_state,
      address_zip: return_address.address_zip,
      phone: return_address.phone
    }
  });

  if (!onboarding) {
    appRedirect({
      dispatch,
      redirect: "goBack",
      payload: { type: "settings" }
    });

    dispatch({
      type: SUCCESS_MESSAGE,
      payload: {
        message: "You've successfully updated your return address.",
        title: "Success!"
      }
    });
  } else {
    dispatch({ type: IS_LOADING, payload: false });
    appRedirect({ dispatch, redirect: "creditReloadOnboarding" });
  }
};

export const selectState = ({ state }) => {
  return dispatch => {
    dispatch({
      type: SELECT_STATE,
      payload: { state }
    });
    appRedirect({ dispatch, redirect: "goBack" });
  };
};

export const updateUser = ({
  token,
  type,
  payload,
  no_loading = false,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (!no_loading && !onLoading) {
      dispatch({ type: IS_LOADING, payload: true });
    } else if (onLoading) {
      onLoading();
    }
    dispatch({ type: UPDATE_USER });
    apiv2.editUser(token, type, payload).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        updateUserFail(dispatch, response.problem, onError);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        updateUserFail(dispatch, response.data.error, onError);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (type === "delete_account") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            if (type == "resend_freq" || type == "default_template") {
              appRedirect({
                dispatch,
                redirect: "goBack",
                payload: { type: "settings" }
              });
            }
          }

          updateUserSuccess(
            dispatch,
            response.data.results.user,
            response.data.results.billing,
            response.data.results.plans,
            response.data.results.team,
            response.data.results.partner,
            response.data.results.notifications,
            type,
            onSuccess
          );
        }
      }
    });
  };
};

const updateUserFail = (dispatch, error, onError) => {
  dispatch({ type: UPDATE_USER_FAIL, payload: error });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });

  if (onError) {
    onError();
  }
};

const updateUserSuccess = (
  dispatch,
  user,
  billing,
  plans,
  team,
  partner,
  notifications,
  type,
  onSuccess = null
) => {
  dispatch({
    type: UPDATE_USER_SUCCESS,
    payload: { user, billing, plans, team, partner, notifications }
  });

  if (onSuccess) {
    dispatch({ type: IS_LOADING, payload: false });
    //onSuccess();
  } else {
    switch (type) {
      default:
        dispatch({ type: IS_LOADING, payload: false });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've updated your account.",
            title: "Success!"
          }
        });
        break;
      case "update_industry":
        dispatch({ type: IS_LOADING, payload: false });
        break;
      case "onboarding_interest":
        dispatch({ type: IS_LOADING, payload: false });

        //appRedirect({ dispatch, redirect: "onboarding_select_plans" });
        appRedirect({ dispatch, redirect: "onboardingSelectPlans" });

        break;

      case "finished_onboarding":
        dispatch({ type: IS_LOADING, payload: false });

        appRedirect({ dispatch, redirect: "crm_completed_signup" });

        break;
      case "accept_terms":
        dispatch({ type: IS_LOADING, payload: false });

        appRedirect({ dispatch, redirect: "main" });
        appRedirect({ dispatch, redirect: "dashboard" });

        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've accepted our terms of service.",
            title: "Success!"
          }
        });

        break;
      case "send_verify_email":
        dispatch({ type: IS_LOADING, payload: false });
        break;
      case "send_verify_phone":
        dispatch({ type: IS_LOADING, payload: false });

        break;
      case "resend_verify_email":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've resent your verification email.",
            title: "Success!"
          }
        });

        break;

      case "resend_verify_phone":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've resent your verification text.",
            title: "Success!"
          }
        });

        break;

      case "default_location":
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { remove: "default-location" }
        });
        dispatch({
          type: TOGGLE_ONBOARDING,
          payload: false
        });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've set your default location.",
            title: "Success!"
          }
        });
        dispatch({
          type: UPDATE_MAP_LOCATION,
          payload: {
            coordinates: {
              latitude: parseFloat(user.user_default_lat),
              longitude: parseFloat(user.user_default_lng),
              heading: 0
            },
            active_property: null
          }
        });

        break;

      case "update_notifications":
      case "update_all_notifications":
        dispatch({ type: IS_LOADING, payload: false });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your notification settings have been updated.",
            title: "Success!"
          }
        });

        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { type: "settings" }
        });

        break;

      case "conversation_settings":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've updated your conversation settings.",
            title: "Success!"
          }
        });

        dispatch({
          type: SET_ACTIVE_CONVERSATION,
          payload: null
        });
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { type: "message_engine" }
        });

        break;

      case "resend_options_onboarding":
      case "mailing_options_onboarding":
        dispatch({ type: IS_LOADING, payload: false });

        dispatch({
          type: TOGGLE_ONBOARDING,
          payload: false
        });
        appRedirect({ dispatch, redirect: "main" });
        appRedirect({ dispatch, redirect: "dashboard" });

        dispatch({
          type: SET_TRACKING_EVENT,
          payload: "intercom_set_repeat_options"
        });

        break;

      case "resend_options":
      case "mailing_options":
        dispatch({ type: IS_LOADING, payload: false });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "You've updated your account's mailing options.",
            title: "Success!"
          }
        });

        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { remove: "mailing-options" }
        });

        break;

      case "resend_options_and_update_deals":
      case "mailing_options_and_update_deals":
        dispatch({ type: IS_LOADING, payload: false });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message:
              "You've updated your account's mailing options. All deals have also been updated to the new settings.",
            title: "Success!"
          }
        });
        dispatch({ type: RELOAD_DEALS });

        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { remove: "mailing-options" }
        });

        break;

      case "create_account":
        dispatch({ type: IS_LOADING, payload: false });
        appRedirect({
          dispatch,
          redirect: "init",
          payload: {
            user,
            billing,
            dealfinder_page: {},
            is_partner: 0,
            accepted_affiliate_latest_terms: 0
          }
        });

        break;

      case "update_information":
      case "update_user_information":
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { type: "user_settings" }
        });

        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your information has been updated.",
            title: "Success!"
          }
        });
        break;

      case "update_user_information_phone":
        appRedirect({ dispatch, redirect: "verifyPhone" });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your information has been updated.",
            title: "Success!"
          }
        });
        break;

      case "verify_email":
        appRedirect({ dispatch, redirect: "onboardingVerifyPhone" });

        if (user.finished_onboarding == 1) {
          appRedirect({ dispatch, redirect: "verifyPhone" });
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: {
              message: "You successfully verified your email.",
              title: "Success!"
            }
          });
        } else {
          dispatch({ type: IS_LOADING, payload: false });
        }

        break;

      case "verify_phone":
        dispatch({ type: IS_LOADING, payload: false });

        if (user.finished_onboarding == 1) {
          appRedirect({ dispatch, redirect: "dashboard" });
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: {
              message: "You successfully verified your phone number.",
              title: "Success!"
            }
          });
        } else {
          appRedirect({
            dispatch,
            redirect: "onboardingSelectPlans"
          });
        }
        break;

      case "change_verify_email":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your email address has been updated.",
            title: "Success!"
          }
        });
        break;

      case "change_verify_phone":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your phone number has been updated.",
            title: "Success!"
          }
        });
        break;

      case "change_email":
        appRedirect({ dispatch, redirect: "verifyEmail" });

        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your email address has been updated.",
            title: "Success!"
          }
        });
        break;

      case "change_password":
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { type: "user_settings" }
        });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message: "Your password has been updated.",
            title: "Success!"
          }
        });
        break;

      case "grab_google_street_view":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message:
              "Your settings have been updated! You photos will begin to populate within 1-2 hours.",
            title: "Success!"
          }
        });
        break;

      case "reset_password":
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: {
            message:
              "Your password has been updated. Please login using your new password",
            title: "Success!"
          }
        });
        appRedirect({
          dispatch,
          redirect: "goBack",
          payload: { type: "login" }
        });
        break;
    }
  }
};

export const initUpdateUser = ({ user }) => {
  return {
    type: INIT_UPDATE_USER,
    payload: {
      user: {
        ...user,
        default_resend_freq: user.default_resend_freq
          ? user.default_resend_freq
          : 21,
        default_resend_freq_switch:
          user.default_resend_freq == 0 ? "off" : "on",
        default_resend_limit_switch:
          user.default_resend_limit == 0 ? "on" : "off"
      }
    }
  };
};

export const updateUserFieldChange = ({ prop, value }) => {
  return {
    type: UPDATE_USER_FIELD_CHANGE,
    payload: { prop, value }
  };
};

export const setPhotoToCameraroll = save_photo_to_cameraroll_toggle => {
  let save_photo_to_cameraroll = false;

  if (save_photo_to_cameraroll_toggle) {
    save_photo_to_cameraroll = true;
  } else {
    save_photo_to_cameraroll = false;
  }

  return {
    type: SET_PHOTO_CAMERAROLL,
    payload: save_photo_to_cameraroll
  };
};

export const setEditReturnLocation = location => {
  return {
    type: SET_EDIT_RETURN_LOCATION,
    payload: location
  };
};

export const getDashboardSettings = ({ token, type }) => {
  return dispatch => {
    dispatch({
      type: GET_DASHBOARD_SETTINGS
    });

    apiv2.getDashboardSettings({ token, type }).then(response => {
      if (response.problem != null) {
      } else if (response.data.error != false) {
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({
          type: GET_DASHBOARD_SETTINGS_SUCCESS,
          payload: response.data.results
        });
      }
    });
  };
};

export const updateDashboard = ({
  token,
  type,
  current_step,
  goals,
  phone_number,
  mentor_name,
  onSuccess = null
}) => {
  return dispatch => {
    if (
      type == "completed_dashboard_steps" ||
      type == "completed_enterprise_steps" ||
      type == "completed_message_steps" ||
      type == "dashboard_goals_confirm" ||
      type == "text_link"
    ) {
      dispatch({ type: IS_LOADING, payload: true });
    }

    apiv2
      .updateDashboard({ token, type, current_step, goals, phone_number })
      .then(response => {
        if (response.problem != null) {
          if (
            type == "completed_dashboard_steps" ||
            type == "completed_enterprise_steps" ||
            type == "completed_message_steps" ||
            type == "dashboard_goals_confirm" ||
            type == "text_link"
          ) {
            dispatch({ type: IS_LOADING, payload: false });
          }
        } else if (response.data.error != false) {
          if (
            type == "completed_dashboard_steps" ||
            type == "completed_enterprise_steps" ||
            type == "completed_message_steps" ||
            type == "dashboard_goals_confirm"
          ) {
            dispatch({ type: IS_LOADING, payload: false });
          } else if (type == "text_link") {
            dispatch({ type: IS_LOADING, payload: false });

            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });
          }
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (
            type == "completed_dashboard_steps" ||
            type == "completed_enterprise_steps" ||
            type == "completed_message_steps" ||
            type == "dashboard_goals_confirm"
          ) {
            dispatch({ type: IS_LOADING, payload: false });
          } else if (type == "text_link") {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload: {
                message:
                  "Check your phone for the link to download DealMachine.",
                title: "Success!"
              }
            });
          }
          dispatch({
            type: UPDATE_DASHBOARD_SETTINGS_SUCCESS,
            payload: response.data.results
          });

          if (onSuccess) {
            onSuccess();
          }
        }
      });
  };
};

export const getMentorAutocomplete = ({ token, search, limit = 5, type }) => {
  return dispatch => {
    dispatch({ type: GET_MENTOR_AUTOCOMPLETE });

    apiv2
      .getDashboardSettings({
        token,
        search,
        limit,
        type: "mentor_autocomplete"
      })
      .then(response => {
        if (response.problem != null) {
          getMentorAutocompleteFail(dispatch, response.problem);
        } else if (response.data.error != false) {
          getMentorAutocompleteFail(dispatch, response.data.error);
          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          getMentorAutocompleteSuccess(dispatch, response.data.results);
        }
      });
  };
};

const getMentorAutocompleteFail = (dispatch, error) => {
  dispatch({ type: GET_MENTOR_AUTOCOMPLETE_FAIL, payload: error });
};

const getMentorAutocompleteSuccess = (dispatch, results) => {
  dispatch({ type: GET_MENTOR_AUTOCOMPLETE_SUCCESS, payload: results });
};

export const clearMentorAutocomplete = () => {
  return {
    type: CLEAR_MENTOR_AUTOCOMPLETE
  };
};
