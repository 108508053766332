import React, { Component } from "react";

import { Row } from "app/NativeComponents/common";
import {
  InlineButton,
  SelectMultiple,
  BottomNavBar
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { getHelperDescription, numberWithCommas } from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";

class PropertyTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_property_types: props.show_property_types
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}
  render() {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const { property_types, drawing, drawing_coordinates } =
      this.props.map_filters;
    return (
      <>
        <SelectMultiple
          style={
            device === "desktop"
              ? {
                  height: 50
                }
              : {}
          }
          innerStyle={{
            paddingTop: 0,
            paddingBottom: 0
          }}
          title={"Property Type"}
          include_search={device === "desktop"}
          include_tags={device === "desktop"}
          disabled={
            this.props.select_drive_start ||
            (drawing && drawing_coordinates?.length <= 2)
          }
          options={[
            { label: "Single Family", value: "single_family" },
            { label: "Multi-Family", value: "multi_family" },
            { label: "Townhouse", value: "townhouse" },
            { label: "Condominium", value: "condominium" },
            { label: "Mobile Home", value: "mobile_home" },
            { label: "Vacant Land", value: "vacant_land" },
            { label: "Cluster Home", value: "cluster_home" },
            { label: "Co-op", value: "co_op" },
            { label: "Apartment", value: "apartment" },
            { label: "Other", value: "other" }
          ]}
          selected_options={property_types}
          placeholder={"Property Type"}
          search_title="Search Property Types"
          icon_right={"keyboard-arrow-down"}
          show={this.props.show_property_types}
          onShow={s => {
            this.props.updateMainState({
              show_property_types: s
            });
          }}
          onSelectedOptionsChange={options => {
            this.props.updateMapFilters({
              property_types: options
            });
          }}
          renderComponent={options => {
            return (
              <InlineButton
                disabled={
                  this.props.select_drive_start ||
                  (drawing && drawing_coordinates?.length <= 2)
                }
                style={
                  property_types.length > 0
                    ? {
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: colors.active_color,
                        borderStyle: "solid",
                        marginRight: 0,
                        ...this.props.style
                      }
                    : {
                        borderRadius: 5,
                        borderWidth: 0,
                        borderColor: colors.border_color,
                        borderStyle: "solid",
                        marginRight: 0,
                        ...this.props.style
                      }
                }
                selected={property_types.length > 0}
                icon={"apartment"}
                pressedIn={options?.hovering || options?.pressedIn}
                noPress={true}
              >
                {property_types.length == 1
                  ? property_types[0].label
                  : property_types.length > 0
                  ? "Property Type [" + property_types.length + "]"
                  : "Property Type"}
              </InlineButton>
            );
          }}
          includeCloseButton={false}
          renderTopButtons={
            device === "mobile"
              ? () => {
                  return (
                    <Row>
                      <InlineButton
                        onPress={() => {
                          this.props.updateMainState({
                            show_property_types: false
                          });
                          this.props.pushSidePanel({
                            slug: "more_filters",
                            data: {
                              open_filter: "property_use_standardized"
                            }
                          });
                        }}
                        style={{ marginRight: 0 }}
                      >
                        See More
                      </InlineButton>
                      <InlineButton
                        disabled={property_types.length === 0}
                        onPress={() => {
                          this.props.updateMapFilters({
                            property_types: []
                          });
                        }}
                        style={{ marginRight: 0 }}
                      >
                        Clear
                      </InlineButton>
                      <HeaderButton
                        buttonContents={{
                          onPress: () => {
                            this.props.updateMainState({
                              show_property_types: false
                            });
                          },
                          icon: "close",
                          color: colors.light_text_color,
                          hover_color: colors.hover_color
                        }}
                      />
                    </Row>
                  );
                }
              : null
          }
          renderBottom={() => {
            return (
              <>
                <BottomNavBar>
                  {device === "desktop" ? (
                    <Row
                      style={{
                        alignSelf: "stretch",

                        justifyContent: "space-between"
                      }}
                    >
                      <InlineButton
                        style={{}}
                        onPress={() => {
                          this.props.updateMainState({
                            show_property_types: false
                          });

                          this.props.pushSidePanel({
                            slug: "more_filters",
                            data: {
                              open_filter: "property_use_standardized"
                            }
                          });
                        }}
                        icon_right={"keyboard-arrow-right"}
                      >
                        See More
                      </InlineButton>
                      <InlineButton
                        disabled={property_types.length === 0}
                        onPress={() => {
                          this.props.updateMapFilters({
                            property_types: []
                          });
                        }}
                      >
                        Clear
                      </InlineButton>
                    </Row>
                  ) : null}
                  {device === "mobile" ? (
                    <InlineButton
                      loading={this.props.properties_on_map_count_loading}
                      onPress={() => {
                        this.props.updateMainState({
                          show_property_types: false
                        });
                      }}
                      button_type={"full"}
                    >
                      {this.props.properties_on_map_count == 1
                        ? "View 1 Property"
                        : "View " +
                          numberWithCommas(this.props.properties_on_map_count) +
                          " Properties"}
                    </InlineButton>
                  ) : null}
                </BottomNavBar>
              </>
            );
          }}
        />
      </>
    );
  }
}

export default PropertyTypes;
