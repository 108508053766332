import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Icon,
  Input,
  MultiLineInput,
  Button,
  Spin,
  Copy
} from "app/NativeComponents/common";

import { Select, DateSelector } from "app/NativeComponents/snippets";
import { store } from "app/store";
import moment from "moment";

class ConfirmInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || ""
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  onChange(value) {
    this.setState({
      value
    });
  }

  canConfirm() {
    const { value } = this.state;
    const { force_confirm, allow_no_value } = this.props;

    if (
      (!!value || allow_no_value) &&
      (value != this.props.value || force_confirm)
    ) {
      return true;
    }

    return false;
  }

  getItemLabel(value, items) {
    for (let i = 0; i < items.length; i++) {
      if (value == items[i].value) {
        return items[i].label;
      }
    }
  }

  renderInputType() {
    const {
      forceLabel = false,
      forceLabelPlaceholder = "--",
      autoCorrect = false,
      autoFocus = false,
      autoCapitalize = "none",
      placeholder,
      loading = false,
      editable = true,
      input_type = "text",
      containerStyle = {},
      items = []
    } = this.props;

    const { value } = this.state;
    const colors = store.getState().settings.colors;

    switch (input_type) {
      case "text":
      case "number":
      default:
        return (
          <Input
            input_style="dm-new-input"
            containerStyle={{ padding: 15, ...containerStyle }}
            ref="confirm_input"
            name="confirm_input"
            returnKeyType={"done"}
            blurOnSubmit={true}
            autoCorrect={autoCorrect}
            autoFocus={autoFocus}
            autoCapitalize={autoCapitalize}
            keyboardType="default"
            placeholder={placeholder}
            onChange={value => this.onChange(value)}
            editable={loading || editable}
            forceLabel={forceLabel}
            forceLabelPlaceholder={forceLabelPlaceholder}
            value={value}
            type={input_type == "number" ? "number" : "text"}
          />
        );

      case "multiline_text":
        return (
          <MultiLineInput
            containerStyle={{ height: 70, ...containerStyle }}
            input_style="dm-new-input"
            name="confirm_input"
            autoCapitalize="sentences"
            blurOnSubmit={true}
            returnKeyType="done"
            keyboardType="default"
            placeholder={placeholder}
            forceLabelPlaceholder={forceLabelPlaceholder}
            label={placeholder}
            forceLabel={forceLabel}
            type={"text"}
            onChange={value => this.onChange(value)}
            value={value}
          />
        );

      case "select":
        return (
          <Wrapper style={{ flex: 1 }}>
            {loading ? (
              <Wrapper>
                <Copy style={{ fontSize: 10, color: colors.light_text_color }}>
                  {placeholder}
                </Copy>
                <Copy style={{ fontSize: 14 }}>
                  {this.getItemLabel(value, items)}
                </Copy>
              </Wrapper>
            ) : (
              <Select
                size="small"
                style={{ paddingRight: 15, flex: 1 }}
                items={items}
                title={placeholder}
                label={forceLabelPlaceholder}
                value={value}
                text={this.getItemLabel(value, items)}
                onSelect={value => {
                  this.onChange(value);
                }}
              />
            )}
          </Wrapper>
        );

      case "date":
        return (
          <DateSelector
            style={{ flex: 1 }}
            autoFocus={autoFocus}
            onDateChange={value =>
              this.setState({
                value
              })
            }
          >
            <Wrapper
              style={{
                padding: 15,
                flex: 1,
                ...containerStyle
              }}
            >
              <Copy style={{ fontSize: 10, color: colors.light_text_color }}>
                {placeholder}
              </Copy>

              <Copy>
                {!!value
                  ? moment(value).format("MMM Do, YYYY")
                  : forceLabelPlaceholder}
              </Copy>
            </Wrapper>
          </DateSelector>
        );
    }
  }

  render() {
    const {
      style,
      confirmEdit = () => {},
      onClear = () => {},
      confirmText = "Confirm Changes",
      cancelText = "Cancel",
      loading = false,
      editable = true,
      loading_text = "",
      loadingStyle = {},
      confirmButtonStyle = {},
      clearButtonStyle = {}
    } = this.props;

    const { value } = this.state;
    const colors = store.getState().settings.colors;

    return (
      <Wrapper style={{ ...style }}>
        <Row>
          {this.renderInputType()}
          {loading ? (
            <Wrapper
              style={{
                padding: 15,
                paddingLeft: 0,
                alignItems: "center",
                justifyContent: "center",
                ...loadingStyle
              }}
            >
              <Spin size="small" text={loading_text} />
            </Wrapper>
          ) : (
            <>
              {this.canConfirm() && editable ? (
                <Button
                  tooltip={confirmText}
                  style={{
                    padding: 15,
                    alignItems: "center",
                    justifyContent: "center",
                    ...confirmButtonStyle
                  }}
                  onPress={() => confirmEdit(value)}
                >
                  <Icon size={18} color={colors.active_color} icon="check" />
                </Button>
              ) : null}
              <Button
                tooltip={cancelText}
                style={{
                  padding: 15,
                  paddingLeft: 0,
                  alignItems: "center",
                  justifyContent: "center",
                  ...clearButtonStyle
                }}
                onPress={() => {
                  this.setState({
                    value: this.props.value
                  });
                  onClear();
                }}
              >
                <Icon size={18} icon={"clear"} />
              </Button>
            </>
          )}
        </Row>
      </Wrapper>
    );
  }
}

export { ConfirmInput };
