import React, { Component } from "react";

import {
  Card,
  Map,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { TemplatePreview, ColorPicker } from "app/NativeComponents/snippets";

class CustomizeYourStyle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 1,
      primary_color: "#31cce5"
    };
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 320,
          textAlign: "left"
        }}
      >
        <Card
          style={{
            width: 305
          }}
        >
          <TemplatePreview
            html_template_id={3}
            payload={{
              primary_color: this.state.primary_color
            }}
            webview={true}
            width={305}
          />
        </Card>
        <Card>
          <ColorPicker
            ref_name={"primary_color"}
            placeholder={"Select a color"}
            onChange={value => {
              this.setState({ primary_color: value.hex });
              //this.props.templateFieldChanged({ prop: "primary_color", value: value.hex })
            }}
            value={this.state.primary_color}
            onColorSelect={() => {}}
          />
        </Card>
      </div>
    );
  }
}

export default CustomizeYourStyle;
