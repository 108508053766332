import React from "react";
import Buttons from "./Buttons";

const DropDown = props => {
  let dropDownClass = "dm-website-nav-dropdown";
  if (props.show) {
    dropDownClass = "dm-website-nav-dropdown animated fadeIn";
  }

  return (
    <nav id="dm-webiste-navbar-dropdown" className={dropDownClass}>
      <ul>
        <Buttons />
      </ul>
    </nav>
  );
};

export default DropDown;
