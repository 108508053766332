import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  enterPromo,
  dismissMobileKeyboard
} from "app/NativeActions";

class EnterPromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promo_code: "",
      loading: false
    };

    this._promo_code = React.createRef();
    this.handleBack = this.handleBack.bind(this);
    this.submitPromoCode = this.submitPromoCode.bind(this);
  }

  componentDidMount() {}

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    const { promo_code } = this.state;
    if (!!promo_code) {
      return true;
    }

    return false;
  }

  submitPromoCode() {
    this.props.enterPromo({
      token: this.props.token,
      promo: this.state.promo_code,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        this.props.popSidePanel();
      },
      onError: () => {
        this.setState({
          loading: false
        });
      }
    });
  }

  render() {
    const { edit_promo_info, colors, source_of_truth } = this.props;
    const { promo_code, loading, deleting } = this.state;
    return (
      <Container>
        <NewHeader
          title={"Enter Referral Code"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        {source_of_truth?.subscription?.iap_ids &&
        source_of_truth?.subscription?.iap_ids?.length > 0 ? (
          <AttentionBox
            title={"Contact Support"}
            description={
              "Contact support to add a referral code to your account."
            }
          />
        ) : (
          <KeyboardView style={{ flex: 1 }}>
            <InputBox
              input_ref={this._promo_code}
              autoFocus={true}
              name="promo_code"
              disabled={loading}
              returnKeyType="done"
              placeholder={
                !!promo_code ? "Referral code" : "Enter your referral code"
              }
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  promo_code: value
                });
              }}
              blurOnSubmit={true}
              value={promo_code}
              input_type="text"
              require_confirm={true}
              has_changed={this.checkIfNeedsToSave()}
              submit_button_primary={true}
              submit_button_title={"Submit"}
              onSubmit={this.submitPromoCode}
              onSubmitEditing={this.submitPromoCode}
              loading={this.state.loading}
            />
          </KeyboardView>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, promo, modal, billing }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { side_panel_views } = modal;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    side_panel_views,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  enterPromo
})(EnterPromo);
