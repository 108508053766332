import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalContainer,
  ModalOverlay,
  Copy
} from "app/NativeComponents/common";
import PhotoGallery from "./PhotoGallery";
import { popSidePanel } from "app/NativeActions";

class PhotoGalleryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { photo_gallery, toggle_photo_gallery, device } = this.props;
    if (photo_gallery) {
      return <PhotoGallery />;
    }

    return null;
  }
}

const mapStateToProps = ({ lead, native }) => {
  const { photo_gallery, toggle_photo_gallery } = lead;
  const { device } = native;
  return {
    photo_gallery,
    toggle_photo_gallery,
    device
  };
};

export default connect(mapStateToProps, {
  popSidePanel
})(PhotoGalleryModal);
