import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

class FooterNavColumn2 extends Component {
  componentWillUnmount() {
    clearInterval(this._interval);
  }

  renderSupportButton() {
    //temp fix until intercom works on mobile safari
    if (this.props.web_platform != "ios") {
      return (
        <li>
          <a id="intercom_button">Open Support Ticket</a>
        </li>
      );
    }
  }

  render() {
    return (
      <div className="dm-website-footer-nav">
        <ul>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/automated-owner-lookup">Automated Owner Lookup</Link>
          </li>
          <li>
            <Link to="/direct-mail-campaigns">Direct Mail Mail Sequences</Link>
          </li>
          <li>
            <Link to="/real-estate-lead-management">
              Real Estate Lead Management
            </Link>
          </li>
          <li>
            <a href="https://help.dealmachine.com">Help Center</a>
          </li>
          {this.renderSupportButton()}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ native }) => {
  const { web_platform } = native;
  return {
    web_platform
  };
};

export default connect(mapStateToProps, {})(FooterNavColumn2);
