import React, { PureComponent } from "react";

import moment from "moment";
import { AppConfig } from "app/NativeActions";

const getThumbnail = ({ image, date_created, use_full = false }) => {
  if (image && image != "" && use_full == false) {
    if (date_created) {
      if (moment(date_created).format("YYYY-MM-DD") < "2017-04-03") {
        return image;
      }
    }

    if (image.startsWith("https://dealmachine.s3.amazonaws.com")) {
      let thumbnail =
        AppConfig().api_url + "image/?url=" + image + "&type=thumb";
      return thumbnail;
    }
  }

  return image;
};

class BackgroundImage extends PureComponent {
  render() {
    const { style, image, children, height, width } = this.props;

    return (
      <div
        className={"deal-background-image"}
        style={{
          backgroundImage: "url(" + getThumbnail({ image }) + ")",
          backgroundSize: "cover",
          height: height,
          width: width
        }}
      >
        {children}
      </div>
    );
  }
}

export { BackgroundImage };
