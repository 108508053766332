import React, { Component } from "react";

import TemplatePreviewModal from "app/DealMachineCore/components/TemplatePreviewModal";

class Modals extends Component {
  render() {
    return (
      <>
        <TemplatePreviewModal />
      </>
    );
  }
}

export default Modals;
