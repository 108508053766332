import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment";

import {
  Wrapper,
  Row,
  CardBody,
  Card,
  Copy,
  Bold,
  Title,
  Spin,
  Icon,
  KeyboardView
} from "app/NativeComponents/common";

import { GhostButton, SpinWrapper } from "app/NativeComponents/snippets";

import {
  getEstimatedInvoiceInfo,
  billingFieldChanged,
  saveCard,
  bulkUpdateLeads,
  setRightPanel,
  renderPrice,
  numberWithCommas,
  resetBilling,
  getSendingQueueCounts,
  toggleCreditReload
} from "app/NativeActions";

import MailerInvoice from "./MailerInvoice";
import SkipTraceInvoice from "./SkipTraceInvoice";

import DealCreditBalance from "./DealCreditBalance";
import PausedCard from "../PausedCard";
class ConfirmInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_credit: 0,
      selected_reload_amount:
        props.user?.team_default_credit_reload > 0
          ? props.user?.team_default_credit_reload * 100
          : 5000,
      started_on: props.user?.team_default_credit_reload > 0 ? true : false,
      update_loading: false,
      editing_card:
        this.cardCompleted(props.card_info) || props.user?.user_version >= 6
          ? false
          : true,
      address: {
        name: props.user.name || "",
        address: props.user.address || "",
        address2: props.user.address2 || "",
        city: props.user.address_city || "",
        state: props.user.address_state || "",
        zip: props.user.address_zip || "",
        email: props.user.team_email || ""
      }
    };

    this.updateSelectedCredit = this.updateSelectedCredit.bind(this);
    this.updateSelectedReloadAmount =
      this.updateSelectedReloadAmount.bind(this);

    this.toggleEditCard = this.toggleEditCard.bind(this);
    this.cardCompleted = this.cardCompleted.bind(this);
    this.saveCard = this.saveCard.bind(this);
  }

  componentDidMount() {
    const {
      token,
      selected_campaign,
      selected_lead,
      invoice_type,
      number_of_leads,
      number_of_addresses
    } = this.props;
    this.props.getSendingQueueCounts({
      token: this.props.token
    });
    this.props.getEstimatedInvoiceInfo({
      token: token,
      invoice_type,
      mailer_campaign_id:
        selected_campaign && invoice_type == "mailer_campaign"
          ? selected_campaign?.id
          : 0,
      lead_id:
        selected_lead && invoice_type == "skip_trace" && number_of_leads == 1
          ? selected_lead?.deal?.id
          : null,
      number_of_leads,
      number_of_addresses,
      selected_campaign
    });
  }
  componentDidUpdate(prevProps) {
    const { estimated_invoice_info, sending_queue_counts } = this.props;
    if (
      estimated_invoice_info &&
      estimated_invoice_info !== prevProps.estimated_invoice_info
    ) {
      this.setState({
        started_on:
          estimated_invoice_info.default_credit_reload > 0 ? true : false,
        selected_reload_amount:
          estimated_invoice_info.default_credit_reload > 0
            ? estimated_invoice_info.default_credit_reload
            : 5000
      });
      this.props.toggleCreditReload({
        auto_reload:
          estimated_invoice_info.default_credit_reload > 0 ||
          this.props.sending_queue_counts.sending_queue > 0 ||
          estimated_invoice_info.total_to_subtract >
            estimated_invoice_info.dealcredit_balance
            ? true
            : false
      });
    }
  }

  toggleEditCard(editing_card) {
    this.setState({
      editing_card
    });
  }

  updateSelectedCredit(selected_credit) {
    this.setState({
      selected_credit
    });
  }

  updateSelectedReloadAmount(selected_reload_amount) {
    this.setState({
      selected_reload_amount
    });
  }

  // toggleConfirmBox(confirm_box) {
  //   this.setState({
  //     confirm_box
  //   });
  // }

  confirmInvoice() {
    const {
      selected_reload_amount,
      selected_credit,
      started_on,
      confirm_box,
      editing_card
    } = this.state;
    const {
      selected_campaign,
      start_mailer_campaign_page,
      token,
      estimated_invoice_info,
      lead_ids,
      new_filters,
      filters, //legacy old filters
      property_flags = null,
      property_flags_and_or = null,
      search,
      search_type,
      list_history_id,
      list_id,
      select_all,
      number_of_leads,
      auto_reload,
      invoice_type,
      onConfirm = null
    } = this.props;

    let credit = 0;
    let credit_update_type = "";
    if (auto_reload) {
      if (confirm_box && started_on) {
        credit_update_type = "skip_single_lead_prompt";
        credit = selected_reload_amount;
      } else {
        credit_update_type = "auto_reload_on";
        credit = selected_reload_amount;
      }
    } else {
      if (
        selected_credit > 0 &&
        estimated_invoice_info.final_dealcredit_balance <= 0
      ) {
        credit_update_type = "add_credit";
        credit = selected_credit;
      } else {
        credit_update_type = "auto_reload_off";
      }
    }

    switch (invoice_type) {
      case "mailer_campaign":
        this.props.bulkUpdateLeads({
          token,
          select_all: select_all,
          total_count: number_of_leads,
          lead_ids: lead_ids,
          filters: new_filters,
          property_flags: property_flags,
          property_flags_and_or: property_flags_and_or,
          list_id: list_id,
          list_history_id: this.props.list_history_id || "",
          search: search,
          search_type: search_type,
          credit_update_type,
          credit,
          campaign_id: selected_campaign.id,
          type: "start_mailers",
          onLoading: () => {
            this.setState({
              update_loading: true
            });
          },
          onError: () => {
            this.setState({
              update_loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              update_loading: false
            });
            if (onConfirm) {
              onConfirm(results);
            }
          }
        });
        break;

      default:
        break;
    }
  }

  renderInvoiceType() {
    const {
      invoice_type,
      device,
      estimated_invoice_info,
      selected_campaign,
      colors
    } = this.props;
    switch (invoice_type) {
      case "mailer_campaign":
        switch (selected_campaign?.campaign_type) {
          case "campaign":
            return (
              <MailerInvoice
                colors={colors}
                estimated_invoice_info={{
                  ...estimated_invoice_info,
                  mailer_template: null
                }}
              />
            );
          case "repeating_mail":
            return (
              <MailerInvoice
                colors={colors}
                estimated_invoice_info={{
                  ...estimated_invoice_info,
                  mailer_template: selected_campaign
                }}
              />
            );
          default:
            break;
        }

      case "skip_trace":
        return (
          <SkipTraceInvoice
            colors={colors}
            estimated_invoice_info={estimated_invoice_info}
          />
        );
      default:
        break;
    }
  }

  cardCompleted(card_info) {
    if (
      card_info.has_card == 1 &&
      card_info.dealcredit_bad_card != 1 &&
      card_info.bad_card != 1 &&
      !!card_info.last4
    ) {
      return true;
    }
    return false;
  }

  saveCard(card_token, error) {
    const { token, card, platform } = this.props;
    const { address } = this.state;
    if (card_token || error) {
      this.props.saveCard({
        token: token,
        card_token: card_token,
        error: error,
        type: "confirm_payment_update_card",
        device: platform,
        address: address,
        email: address.email
      });
    } else {
      const { number, expMonth, expYear, cvc } = card;
      this.props.saveCard({
        token: token,
        number,
        expMonth,
        expYear,
        cvc,
        type: "confirm_payment_update_card",
        device: platform,
        address: address,
        email: address.email
      });
    }
  }

  render() {
    const {
      user,
      device,
      isMobile,
      colors,
      selected_campaign,

      estimated_invoice_info_loading,
      estimated_invoice_info,
      auto_reload,
      card,
      card_info,
      loading,
      toggleCreditReload,
      number_of_leads,
      invoice_type,
      confirm_button,
      renderHeader = null
    } = this.props;
    const {
      selected_credit,
      selected_reload_amount,
      started_on,
      editing_card,
      update_loading
    } = this.state;

    return (
      <KeyboardView style={{ flex: 1 }}>
        {renderHeader ? renderHeader() : null}
        {estimated_invoice_info_loading ? (
          <SpinWrapper text="Loading estimated payment..." />
        ) : estimated_invoice_info ? (
          this.renderInvoiceType()
        ) : null}
        {!estimated_invoice_info_loading && estimated_invoice_info ? (
          <>
            <DealCreditBalance
              colors={colors}
              estimated_invoice_info={estimated_invoice_info}
              selected_credit={selected_credit}
              selected_reload_amount={selected_reload_amount}
              auto_reload={this.props.auto_reload}
              started_on={started_on}
              invoice_type={invoice_type}
              updateSelectedCredit={this.updateSelectedCredit}
              updateSelectedReloadAmount={this.updateSelectedReloadAmount}
              toggleCreditReload={this.props.toggleCreditReload}
              // toggleConfirmBox={this.toggleConfirmBox}
              card={card}
              card_info={card_info}
              loading={loading}
              editing_card={editing_card}
              toggleEditCard={this.toggleEditCard}
              billingFieldChanged={this.props.billingFieldChanged}
              cardCompleted={this.cardCompleted}
              saveCard={this.saveCard}
              address={this.state.address}
            />
            {!editing_card ? (
              <GhostButton
                primary={true}
                button_type="full"
                loading={update_loading}
                disabled={
                  !this.cardCompleted(card_info) &&
                  this.props.user?.user_version < 6
                    ? true
                    : false
                }
                onPress={() => this.confirmInvoice()}
              >
                {confirm_button}
              </GhostButton>
            ) : null}
            {invoice_type == "mailer_campaign" ? (
              <PausedCard onSuccess={() => this.props.setRightPanel(null)} />
            ) : null}
          </>
        ) : // {!editing_card && invoice_type == "mailer_campaign" ? (
        //   <Wrapper
        //     style={{
        //       marginTop: 10
        //     }}
        //   >
        //     <Copy
        //       style={{ fontSize: 12, color: colors.light_text_color }}
        //     >
        //       * If a lead is in the middle of a mail sequence other than
        //       the one selected, this will complete the current mail
        //       sequence and start the newly selected mail sequence from the
        //       begining. If a lead is in the middle of the same mail
        //       sequence as the one selected, nothing will change for that
        //       lead.
        //     </Copy>
        //   </Wrapper>
        // ) : null}

        null}
      </KeyboardView>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, lead, billing, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;

  const { estimated_invoice_info, estimated_invoice_info_loading } = lead;
  const { sending_queue_counts } = mailer;
  const { card, card_info, loading, auto_reload } = billing;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,

    estimated_invoice_info,
    estimated_invoice_info_loading,
    sending_queue_counts,
    auto_reload,
    card,
    card_info,
    loading
  };
};

export default connect(mapStateToProps, {
  getEstimatedInvoiceInfo,
  billingFieldChanged,
  saveCard,
  bulkUpdateLeads,
  setRightPanel,
  resetBilling,
  getSendingQueueCounts,

  toggleCreditReload
})(ConfirmInvoice);
