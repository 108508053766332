import React, { Component } from "react";
import { connect } from "react-redux";

import { Copy, Row, Wrapper, Button } from "app/NativeComponents/common";
import {
  IconButton,
  GhostButton,
  InlineButton,
  PopoverMenu
} from "app/NativeComponents/snippets";

import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

import {
  getMailerStats,
  numberWithCommas,
  pushSidePanel,
  updateMailerTemplate
} from "app/NativeActions";
class Postcard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      loading_stats: false,
      stats: null,
      saving: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { token } = this.props;
    const { stats, hovering, loading_stats } = this.state;
    if (
      !stats &&
      hovering &&
      !loading_stats &&
      hovering !== prevState.hovering
    ) {
      this.props.getMailerStats({
        token,
        mailer_template_id: this.props.mailer?.id,
        onLoading: () => {
          this.setState({
            loading_stats: true
          });
        },
        onError: () => {
          this.setState({
            loading_stats: false
          });
        },
        onSuccess: results => {
          this.setState({
            loading_stats: false,
            stats: results?.mailer_stats || null
          });
        }
      });
    }
  }

  render() {
    const { colors, isMobile, device } = this.props;

    const { mailer } = this.props;
    const { hovering, stats, loading_stats } = this.state;
    let scale = 0.35;
    let back_scale = 0.35;

    switch (mailer?.template_type) {
      case "handwritten":
        scale = 0.35;
        back_scale = 0.35;

        break;
      case "postcard":
      default:
        scale = 0.35;
        back_scale = 0.35;
        break;
      case "postcard6x9":
        scale = 0.3;
        back_scale = 0.3;
        break;
      case "postcard6x11":
        scale = 0.25;
        back_scale = 0.25;
        break;
    }
    return (
      <Button
        style={{
          padding: 5,

          alignItems: "center",
          justifyContent: "center",

          backgroundColor: hovering
            ? colors?.orange_color_muted
            : colors.background_color,
          borderRadius: 5,
          margin: 5
        }}
        disabled={device == "mobile" ? true : false}
        onHover={() => {
          this.setState({ hovering: true });
        }}
        onHoverOut={() => {
          this.setState({ hovering: false });
        }}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "postcard_designer_widget",
            overlay: true,
            focus_modal: true,
            locked: true,
            data: {
              mailer,
              template_system_type: mailer?.template_system_type,
              onSuccess: results => {
                if (results?.mailer_templates?.length > 0) {
                  this.props.updateMailTemplate(results?.mailer_templates[0]);
                }
              },
              onDeleteSuccess: this.props.onDeleteSuccess
            }
          });
        }}
      >
        <Wrapper
          style={{
            height: 20,
            padding: 5,
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "stretch"
          }}
        >
          <Copy style={{ fontSize: 12, textAlign: "center" }}>
            {hovering
              ? stats?.total_leads_sent_mail
                ? "You have sent this postcard to " +
                  numberWithCommas(stats?.total_leads_sent_mail) +
                  " leads"
                : ""
              : ""}
          </Copy>
        </Wrapper>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 200,
            width: isMobile ? "100%" : 300
          }}
        >
          <MailerFlipCard
            mailer={mailer}
            scale={scale}
            back_scale={back_scale}
            include_options={hovering || device == "mobile" ? true : false}
          />
        </Wrapper>
        <Row style={{ alignSelf: "stretch" }}>
          {mailer.is_default_template ? (
            <IconButton
              noPress={true}
              icon={"star"}
              icon_color={colors.active_color}
              tooltip={"First Choice"}
              style={{ margin: 0 }}
            />
          ) : null}

          <Wrapper
            style={{
              padding: 10,
              flex: 1
            }}
          >
            <Copy
              style={{
                color: hovering
                  ? colors.actionable_text_color
                  : colors.text_color
              }}
            >
              {mailer?.template_title}
            </Copy>
            <Copy style={{ color: colors.light_text_color, fontSize: 12 }}>
              {mailer?.template_type_text}
            </Copy>
          </Wrapper>

          <PopoverMenu
            renderComponent={options => {
              return (
                <IconButton
                  icon={"more-vert"}
                  noPress={true}
                  pressedIn={options.hovering || options.pressed_in}
                />
              );
            }}
            menu_items={[
              {
                title: "Set First Choice",
                icon: "star",
                type: "normal",
                onPress: () => {
                  this.props.updateMailerTemplate({
                    token: this.props.token,
                    type: "set_default_mailer_template",
                    mailer_template_id: mailer?.id,

                    onLoading: () => {
                      this.props.removeFirstChoiceFromAll();
                      this.setState({ saving: true });
                    },
                    onError: () => {
                      this.setState({ saving: false });
                    },
                    onSuccess: results => {
                      this.setState({ saving: false });

                      if (results?.mailer_templates?.length > 0) {
                        this.props.updateMailTemplate(
                          results?.mailer_templates[0]
                        );
                      }
                    }
                  });
                }
              },
              {
                title: "Edit Design",
                icon: "edit",
                type: "normal",
                onPress: () => {
                  this.props.pushSidePanel({
                    slug: "postcard_designer_widget",
                    overlay: true,
                    focus_modal: true,
                    locked: true,
                    data: {
                      mailer,
                      template_system_type: mailer?.template_system_type,
                      onSuccess: results => {
                        if (results?.mailer_templates?.length > 0) {
                          this.props.updateMailTemplate(
                            results?.mailer_templates[0]
                          );
                        }
                      },
                      onDeleteSuccess: this.props.onDeleteSuccess
                    }
                  });
                }
              },
              {
                title: "Clone Design",
                icon: "content-copy",
                type: "normal",
                onPress: () => {
                  this.props.pushSidePanel({
                    slug: "postcard_designer_widget",
                    overlay: true,
                    focus_modal: true,
                    locked: true,
                    data: {
                      template_type: mailer.template_type,
                      template_system_type: mailer?.template_system_type,
                      mailer: {
                        ...mailer,
                        id: "new_template",
                        cloned: true,
                        template_title: mailer.template_title + " (Clone)",
                        onSuccess: results => {
                          if (results?.mailer_templates?.length > 0) {
                            this.props.updateMailTemplate(
                              results?.mailer_templates[0]
                            );
                          }
                        }
                      }
                    }
                  });
                }
              },
              {
                title: "Delete Design",
                icon: "delete",
                type: "delete",
                onPress: () => {
                  this.props.updateMailerTemplate({
                    token: this.props.token,
                    type: "remove_mailer_template",
                    mailer_template_id: mailer.id,
                    onSuccess: () => {
                      this.props.onDeleteSuccess(mailer.id);
                    }
                  });
                }
              }
            ]}
          />
        </Row>
      </Button>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  return {
    token,
    user,
    colors,
    isMobile,
    device
  };
};

export default connect(mapStateToProps, {
  getMailerStats,
  pushSidePanel,
  updateMailerTemplate
})(Postcard);
