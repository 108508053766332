import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Icon,
  Bold,
  Row,
  Copy,
  Wrapper
} from "app/NativeComponents/common";
import { triggerHapticFeedback } from "app/NativeActions";
class BottomTabButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  renderInner() {
    const {
      onPress,
      icon,
      title_icon,
      title,
      active,
      style,
      colors,
      primary,
      textColor = null,
      activeColor = null
    } = this.props;
    let text_color = primary
      ? colors.orange_color
      : active
        ? !!activeColor
          ? activeColor
          : colors.actionable_text_color
        : !!textColor
          ? textColor
          : colors.text_color;

    return (
      <>
        <Icon size={26} icon={icon} color={text_color} />
        {active ? (
          <Row>
            <Copy
              style={{
                color: text_color,
                fontSize: 10,
                marginTop: 0,
                textAlign: "center"
              }}
            >
              <Bold>{title}</Bold>
            </Copy>
            {!!title_icon ? (
              <Icon
                size={12}
                style={{ marginLeft: 5 }}
                icon={title_icon}
                color={text_color}
              />
            ) : null}
          </Row>
        ) : (
          <Row>
            <Copy
              style={{
                color: text_color,
                fontSize: 10,
                marginTop: 0,
                textAlign: "center"
              }}
            >
              {title}
            </Copy>
            {!!title_icon ? (
              <Icon
                size={12}
                style={{ marginLeft: 5 }}
                icon={title_icon}
                color={text_color}
              />
            ) : null}
          </Row>
        )}
      </>
    );
  }

  render() {
    const {
      onPress = () => {},
      icon,
      title,
      active,
      colors,
      width = 87,
      tooltip = null,
      tooltipPlacement = "top"
    } = this.props;
    const { hovering } = this.state;

    let background_color = active
      ? colors.hover_color
      : hovering
        ? colors.hover_color
        : "transparent";

    if (this.props.noPress) {
      return (
        <Wrapper
          style={{
            width: width,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: background_color,
            padding: 10,
            borderRadius: 5,
            marginTop: 5
          }}
          tooltip={tooltip}
          tooltipPlacement={tooltipPlacement}
          onHover={() => {
            this.setState({
              hovering: true
            });
          }}
          onHoverOut={() => {
            this.setState({
              hovering: false
            });
          }}
        >
          {this.renderInner()}
        </Wrapper>
      );
    }
    return (
      <Button
        onPress={() => {
          triggerHapticFeedback({ type: "impactLight" });
          onPress();
        }}
        tooltip={tooltip}
        tooltipPlacement={tooltipPlacement}
        style={{
          width: width - 12,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: background_color,
          padding: 10,
          borderRadius: 5,
          marginTop: 5
        }}
        onHover={() => {
          this.setState({
            hovering: true
          });
        }}
        onHoverOut={() => {
          this.setState({
            hovering: false
          });
        }}
      >
        {this.renderInner()}
      </Button>
    );
  }
}

export default BottomTabButton;
