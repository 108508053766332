import React from "react";
import { OnboardingNavbar } from "app/NativeComponents/snippets";

const OnboardingWrapper = ({ children, style, renderInner = null }) => {
  return (
    <div id={"onboarding-container"}>
      <OnboardingNavbar />
      {renderInner ? renderInner() : null}
      <div id={"onboarding-container-inner"}>{children}</div>
    </div>
  );
};

export { OnboardingWrapper };
