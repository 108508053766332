import React, { Component } from "react";

import { Row } from "app/NativeComponents/common";
import {
  InlineButton,
  SelectMultiple,
  BottomNavBar,
  IconButton
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { getHelperDescription, numberWithCommas } from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";

class QuickFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_quick_filters: props.show_quick_filters
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}
  render() {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const {
      property_flags,
      drawing,
      drawing_coordinates,
      property_flags_and_or
    } = this.props.map_filters;
    return (
      <>
        <SelectMultiple
          style={
            device === "desktop"
              ? {
                  height: 50
                }
              : {}
          }
          title={"Quick Filters"}
          innerStyle={{
            paddingTop: 0,
            paddingBottom: 0
          }}
          show={this.props.show_quick_filters}
          onShow={s => {
            this.props.updateMainState({
              show_quick_filters: s
            });
          }}
          options={[
            {
              label: "MLS Active",
              value: "mls_active",
              helper: getHelperDescription("mls_active")
            },
            {
              label: "MLS Pending",
              value: "mls_pending",
              helper: getHelperDescription("mls_pending")
            },
            {
              label: "MLS Contingent",
              value: "mls_contingent",
              helper: getHelperDescription("mls_contingent")
            },
            {
              label: "Recently Sold",
              value: "recently_sold",
              helper: getHelperDescription("recently_sold")
            },
            {
              label: "Expired Listings",
              value: "expired_listing",
              helper: getHelperDescription("expired_listing")
            },
            {
              label: "Owner Occupied",
              value: "owner_occupied",
              helper: getHelperDescription("owner_occupied")
            },
            {
              label: "Out of State Owners",
              value: "out_of_state_owner",
              helper: getHelperDescription("out_of_state_owner")
            },
            {
              label: "Absentee Owners",
              value: "absentee_owner",
              helper: getHelperDescription("absentee_owner")
            },
            {
              label: "Corporate Owners",
              value: "corporate_owner",
              helper: getHelperDescription("corporate_owner")
            },
            {
              label: "Vacant Homes",
              value: "vacant_home",
              helper: getHelperDescription("vacant_home")
            },
            {
              label: "Adjustable Loans",
              value: "adjustable_loan",
              helper: getHelperDescription("adjustable_loan")
            },
            {
              label: "Bank Owned",
              value: "bank_owned",
              helper: getHelperDescription("bank_owned")
            },
            {
              label: "Cash Buyers",
              value: "cash_buyer",
              helper: getHelperDescription("cash_buyer")
            },
            {
              label: "Free and Clear",
              value: "free_and_clear",
              helper: getHelperDescription("free_and_clear")
            },
            {
              label: "High Equity",
              value: "high_equity",
              helper: getHelperDescription("high_equity")
            },
            {
              label: "Low Equity",
              value: "low_equity",
              helper: getHelperDescription("low_equity")
            },
            {
              label: "Tax Delinquent",
              value: "tax_delinquent",
              helper: getHelperDescription("tax_delinquent")
            },
            {
              label: "Preforeclosures",
              value: "preforeclosure",
              helper: getHelperDescription("preforeclosure")
            },
            {
              label: "Upcoming Auctions",
              value: "upcoming_auction",
              helper: getHelperDescription("upcoming_auction")
            },
            {
              label: "Senior Owners",
              value: "senior_owner",
              helper: getHelperDescription("senior_owner")
            },
            {
              label: "Tired Landlords",
              value: "tired_landlord",
              helper: getHelperDescription("tired_landlord")
            },

            {
              label: "Zombie Properties",
              value: "zombie_property",
              helper: getHelperDescription("zombie_property")
            },
            {
              label: "Off Market",
              value: "off_market",
              helper: getHelperDescription("off_market")
            },
            {
              label: "Probates",
              value: "pre_probate",
              helper: getHelperDescription("pre_probate")
            },
            {
              label: "Intrafamily Transfer",
              value: "intra_family_transfer",
              helper: getHelperDescription("intra_family_transfer")
            }
          ]}
          selected_options={property_flags ? property_flags : []}
          disabled={
            this.props.select_drive_start ||
            (drawing && drawing_coordinates?.length <= 2)
          }
          placeholder={"Quick Filters"}
          search_title="Search Filters"
          icon_right={"keyboard-arrow-down"}
          include_search={device === "desktop"}
          include_tags={device === "desktop"}
          renderComponent={options => {
            return (
              <InlineButton
                disabled={
                  this.props.select_drive_start ||
                  (drawing && drawing_coordinates?.length <= 2)
                }
                style={
                  property_flags.length > 0
                    ? {
                        borderRadius: 5,
                        borderWidth: 1,
                        marginRight: 0,
                        borderColor: colors.active_color,
                        borderStyle: "solid",
                        ...this.props.style
                      }
                    : {
                        borderRadius: 5,
                        borderColor: colors.border_color,
                        borderWidth: 0,
                        marginRight: 0,
                        borderStyle: "solid",
                        ...this.props.style
                      }
                }
                selected={property_flags.length > 0}
                icon={"tune"}
                pressedIn={options?.hovering || options?.pressedIn}
                noPress={true}
              >
                {property_flags.length === 1
                  ? property_flags[0].label
                  : property_flags.length > 0
                  ? "Quick Filters [" + property_flags.length + "]"
                  : "Quick Filters"}
              </InlineButton>
            );
          }}
          includeCloseButton={false}
          renderTopButtons={
            device === "mobile"
              ? () => {
                  return (
                    <Row>
                      <InlineButton
                        style={{ marginRight: 0, marginLeft: 0 }}
                        onPress={() => {
                          this.props.updateMapFilters({
                            property_flags_and_or:
                              property_flags_and_or === "or" ? "and" : "or"
                          });
                        }}
                        disabled={property_flags.length === 0}
                        icon={
                          property_flags_and_or === "and"
                            ? "toggle-on"
                            : "toggle-off"
                        }
                        iconColor={
                          property_flags_and_or === "and"
                            ? colors.success_color
                            : null
                        }
                      >
                        Match All
                      </InlineButton>
                      <InlineButton
                        disabled={property_flags.length === 0}
                        style={{ marginRight: 0, marginLeft: 0 }}
                        onPress={() => {
                          this.props.updateMapFilters({
                            property_flags: []
                          });
                        }}
                      >
                        Clear
                      </InlineButton>
                      <HeaderButton
                        style={{ marginLeft: 0 }}
                        buttonContents={{
                          onPress: () => {
                            this.props.updateMainState({
                              show_quick_filters: false
                            });
                          },
                          icon: "close",
                          color: colors.light_text_color,
                          hover_color: colors.hover_color
                        }}
                      />
                    </Row>
                  );
                }
              : null
          }
          renderBottom={() => {
            return (
              <BottomNavBar>
                {device === "desktop" ? (
                  <Row
                    style={{
                      alignSelf: "stretch",

                      justifyContent: "space-between"
                    }}
                  >
                    <InlineButton
                      style={{}}
                      onPress={() => {
                        this.props.updateMapFilters({
                          property_flags_and_or:
                            property_flags_and_or === "or" ? "and" : "or"
                        });
                      }}
                      disabled={property_flags.length === 0}
                      icon={
                        property_flags_and_or === "and"
                          ? "toggle-on"
                          : "toggle-off"
                      }
                      iconColor={
                        property_flags_and_or === "and"
                          ? colors.success_color
                          : null
                      }
                    >
                      Match All
                    </InlineButton>
                    <InlineButton
                      disabled={property_flags.length === 0}
                      onPress={() => {
                        this.props.updateMapFilters({
                          property_flags: []
                        });
                      }}
                    >
                      Clear
                    </InlineButton>
                  </Row>
                ) : null}
                {device === "mobile" ? (
                  <InlineButton
                    loading={this.props.properties_on_map_count_loading}
                    onPress={() => {
                      this.props.updateMainState({
                        show_quick_filters: false
                      });
                    }}
                    button_type={"full"}
                  >
                    {this.props.properties_on_map_count == 1
                      ? "View 1 Property"
                      : "View " +
                        numberWithCommas(this.props.properties_on_map_count) +
                        " Properties"}
                  </InlineButton>
                ) : null}
              </BottomNavBar>
            );
          }}
          onSelectedOptionsChange={options => {
            this.props.updateMapFilters({
              property_flags: options
            });
          }}
        />
      </>
    );
  }
}

export default QuickFilters;
