import React, { Component } from "react";

import Scene1 from "./Scene1";
import Scene3 from "./Scene3";
import Scene4 from "./Scene4";

class GetInTouch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 1
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);

    if (this.state.scene == 1) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 2 });
        this.startScene();
      }, 2000);
    } else if (this.state.scene == 2) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 3 });
        this.startScene();
      }, 500);
    } else if (this.state.scene == 3) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 4 });
        this.startScene();
      }, 2000);
    } else if (this.state.scene == 4) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 1 });
        this.startScene();
      }, 2000);
    }
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 200,
          textAlign: "center"
        }}
      >
        <Scene1 scene={this.state.scene} />
        <Scene3 scene={this.state.scene} />
        <Scene4 scene={this.state.scene} />
      </div>
    );
  }
}

export default GetInTouch;
