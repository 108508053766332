import React, { Component } from "react";
import { Wrapper, Row, Copy, Bold, Icon } from "app/NativeComponents/common";

import TriggerComponent from "./TriggerComponent";
import BranchComponent from "./BranchComponent";
import DelayComponent from "./DelayComponent";
import WebhookComponent from "./WebhookComponent";
import InternalCommunicationComponent from "./InternalCommunicationComponent";
import SkipTraceComponent from "./SkipTraceComponent";
import NoteComponent from "./NoteComponent";
import StatusComponent from "./StatusComponent";
import ListComponent from "./ListComponent";
import TagComponent from "./TagComponent";
import CampaignComponent from "./CampaignComponent";
import TaskComponent from "./TaskComponent";
import AssignLeadComponent from "./AssignLeadComponent";
import CustomFieldsComponent from "./CustomFieldsComponent";
import ExportComponent from "./ExportComponent";
import Line from "./Line";
import TopLine from "./TopLine";
import NewComponentButton from "./NewComponentButton";

class WorkflowComponents extends Component {
  renderWorkflowComplete({ step_type = "", index = 0, number_of_steps = 0 }) {
    const { colors } = this.props;
    if (
      step_type !== "filter_branch" &&
      (index == number_of_steps - 1 || number_of_steps == 0)
    )
      return (
        <>
          <Row style={{ justifyContent: "center" }}>
            <Wrapper
              style={{
                borderColor: colors.border_color,
                backgroundColor: colors.success_color_muted,
                borderWidth: 1,
                borderRadius: 30,
                padding: 15,
                paddingTop: 10,

                paddingBottom: 10,
                borderStyle: "solid",
                marginBottom: 50
              }}
            >
              <Row>
                <Icon icon="check" size={18} style={{ marginRight: 5 }} />
                <Wrapper>
                  <Copy>
                    <Bold>Complete</Bold>
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
          </Row>
        </>
      );
  }

  renderNewButton({
    step,
    step_index,
    branch_index = null,
    branch_step_index = null,
    branch_button = false
  }) {
    const { colors } = this.props;

    return (
      <>
        <Line colors={colors} />
        <NewComponentButton
          {...this.props}
          step_index={step_index}
          branch_index={branch_index}
          branch_step_index={branch_step_index}
          onPress={() => {
            this.props.setNewStepType({
              step_index,
              branch_index,
              branch_step_index:
                branch_step_index != null && !branch_button
                  ? branch_step_index + 1
                  : branch_step_index
            });
          }}
          onCancel={() => {
            this.props.setNewStepType(null);
          }}
        />
        <Line colors={colors} />
      </>
    );
  }

  renderStepType({
    step,
    step_index,
    branch_index = null,
    branch_step_index = null
  }) {
    const { active_step, colors } = this.props;

    const is_active =
      step_index == active_step?.step_index &&
      branch_index == active_step?.branch_index &&
      branch_step_index == active_step?.branch_step_index;

    switch (step.type) {
      case "enrollment_triggers":
        return (
          <>
            <TriggerComponent
              {...this.props}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "create_task":
        return (
          <>
            <TaskComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );

      case "assign_lead":
        return (
          <>
            <AssignLeadComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );

      case "update_custom_fields":
        return (
          <>
            <CustomFieldsComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "add_tags":
      case "remove_tags":
        return (
          <>
            <TagComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );

      case "add_to_lists":
      case "remove_from_lists":
        return (
          <>
            <ListComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );

      case "start_campaign":
      case "pause_campaign":
      case "end_campaign":
        return (
          <>
            <CampaignComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "skip_trace":
        return (
          <>
            <SkipTraceComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "change_lead_status":
        return (
          <>
            <StatusComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "create_note":
        return (
          <>
            <NoteComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );

      case "send_internal_email":
      case "send_internal_push_notification":
        return (
          <>
            <InternalCommunicationComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "delay":
      case "delay_set_amount_of_time":
      case "delay_until_day_or_time":
        return (
          <>
            <DelayComponent
              {...this.props}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "trigger_export":
        return (
          <>
            <ExportComponent
              {...this.props}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );

      case "trigger_webhook":
        return (
          <>
            <WebhookComponent
              {...this.props}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              step={step}
              is_active={is_active}
            />
            {this.renderNewButton({
              step,
              step_index,
              branch_index,
              branch_step_index
            })}
          </>
        );
      case "filter_branch":
        return (
          <>
            <BranchComponent
              {...this.props}
              step={step}
              step_index={step_index}
              branch_index={branch_index}
              branch_step_index={branch_step_index}
              onPress={() => {
                this.props.setActiveStep({
                  step,
                  step_index,
                  branch_index,
                  branch_step_index
                });
              }}
              is_active={is_active}
            />
            <Line colors={colors} />
            <Row
              style={{
                justifyContent: "center",
                alignItems: "flex-start"
              }}
            >
              {step.data.map((branch, i) => {
                return (
                  <Wrapper key={"branch_" + i}>
                    <Row style={{ alignItems: "flex-start" }}>
                      <Wrapper>
                        {i == 0 ? (
                          <Row>
                            <Wrapper style={{ width: "50%" }} />
                            <TopLine colors={colors} style={{ width: "50%" }} />
                          </Row>
                        ) : i == step.data.length - 1 ? (
                          <Row>
                            <TopLine colors={colors} style={{ width: "50%" }} />
                            <Wrapper style={{ width: "50%" }} />
                          </Row>
                        ) : (
                          <TopLine colors={colors} style={{ width: "100%" }} />
                        )}

                        <Line colors={colors} />
                        <Row style={{ justifyContent: "center" }}>
                          <Wrapper
                            style={{
                              borderColor: colors.border_color,
                              backgroundColor: branch.else_branch
                                ? colors.error_color_muted
                                : colors.card_color,
                              borderWidth: 1,
                              borderRadius: 30,
                              padding: 15,
                              paddingTop: 10,
                              paddingBottom: 10,
                              borderStyle: "solid"
                            }}
                          >
                            <Copy>
                              <Bold>{branch.branch_name}</Bold>
                            </Copy>
                          </Wrapper>
                        </Row>

                        {this.renderNewButton({
                          step,
                          step_index,
                          branch_index: i,
                          branch_step_index: 0,
                          branch_button: true
                        })}

                        {branch.steps.length == 0 ? (
                          <Wrapper>{this.renderWorkflowComplete({})}</Wrapper>
                        ) : (
                          branch.steps.map((step, j) => {
                            return (
                              <Wrapper key={"component_" + j}>
                                {this.renderStepType({
                                  step,
                                  step_index: step_index,
                                  branch_index: i,
                                  branch_step_index: j
                                })}
                                {this.renderWorkflowComplete({
                                  step,
                                  index: j,
                                  number_of_steps: branch.steps.length
                                })}
                              </Wrapper>
                            );
                          })
                        )}
                      </Wrapper>
                      {i != step.data.length - 1 ? (
                        <TopLine colors={colors} />
                      ) : null}
                    </Row>
                  </Wrapper>
                );
              })}
            </Row>
          </>
        );
      case "action":
      default:
        return null;
    }
  }

  render() {
    const {
      workflow,
      workflow: { workflow_steps },
      active_step,
      colors,
      device,
      scale
    } = this.props;
    return (
      <Wrapper
        style={
          device == "mobile"
            ? { transform: [{ scale }], paddingBottom: 100 }
            : {
                transform: "scale(" + scale + ")"
              }
        }
      >
        {workflow_steps.map((step, i) => {
          return (
            <Wrapper key={"component_" + i}>
              {this.renderStepType({ step, step_index: i })}
              {this.renderWorkflowComplete({
                step_type: step.type,
                index: i,
                number_of_steps: workflow_steps.length
              })}
            </Wrapper>
          );
        })}
      </Wrapper>
    );
  }
}

export default WorkflowComponents;
