import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  PillButton,
  SmallHeader,
  SpinWrapper,
  GhostButton,
  InputBox,
  InlineButton,
  IconButton
} from "app/NativeComponents/snippets";
import { toTitleCase } from "app/NativeActions";

import AdvancedFilters from "app/DealMachineCore/reuseable/AdvancedFilters";
import { PopoverMenu } from "app/NativeComponents/snippets";

class BranchStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branches:
        props.active_step.data && props.active_step.data.length > 0
          ? props.active_step.data
          : [
              {
                branch_name: "Branch 1",
                filters: [],
                steps: []
              },
              {
                branch_name: "None met",
                else_branch: true,
                filters: [],
                steps: []
              }
            ]
    };
  }

  checkIfNeedsToSave() {
    if (
      this.state.branches !== this.props.active_step?.data &&
      this.state.branches.length > 0
    ) {
      return true;
    }

    return false;
  }

  removeBranch({ branch_index }) {
    let new_branches = JSON.parse(JSON.stringify(this.state.branches));
    new_branches.splice(branch_index, 1);

    this.setState({
      branches: new_branches
    });
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading
    } = this.props;

    const { branches, editing_branch } = this.state;
    if (active_step) {
      return (
        <>
          <SmallHeader
            title="If/Then Branch"
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {enrollment_object_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                {branches.map((branch, i) => {
                  return (
                    <Wrapper key={"branch_" + i}>
                      <Wrapper style={{}}>
                        <Wrapper style={{ padding: 25 }}>
                          <Row>
                            <Wrapper style={{ flex: 1 }}>
                              <Copy>
                                {i == 0
                                  ? "First, check if:"
                                  : i == branches.length - 1
                                  ? "If none of the criteria is met, go here:"
                                  : "If above criteria is not met, then check if:"}
                              </Copy>
                            </Wrapper>

                            {i !== 0 && i !== branches.length - 1 ? (
                              <PopoverMenu
                                icon={"more-vert"}
                                renderMenu={({ pressedIn, hovering }) => {
                                  return (
                                    <IconButton
                                      icon={"more-vert"}
                                      noPress={true}
                                      pressedIn={hovering || pressedIn}
                                    />
                                  );
                                }}
                                style={{ paddingRight: 5, paddingLeft: 5 }}
                                icon_size={18}
                                menu_items={[
                                  {
                                    title: "Delete Branch",
                                    icon: "delete",
                                    type: "delete",
                                    onPress: () => {
                                      this.removeBranch({
                                        branch_index: i
                                      });
                                    }
                                  }
                                ]}
                              />
                            ) : null}
                          </Row>
                        </Wrapper>

                        <InputBox
                          ref="branch_name"
                          style={{ flex: 1 }}
                          autoFocus={false}
                          name="branch_name"
                          returnKeyType="next"
                          placeholder={"Enter branch name"}
                          onChange={value => {
                            let new_branches = JSON.parse(
                              JSON.stringify(branches)
                            );

                            new_branches[i] = {
                              ...new_branches[i],
                              branch_name: value
                            };
                            this.setState({
                              branches: new_branches
                            });
                          }}
                          onSubmitEditing={() => {}}
                          blurOnSubmit={false}
                          value={branch.branch_name}
                          type="text"
                        />

                        {i !== branches.length - 1 ? (
                          <AdvancedFilters
                            {...this.props}
                            filters={branches[i].filters}
                            onChange={filters => {
                              let new_branches = JSON.parse(
                                JSON.stringify(branches)
                              );
                              new_branches[i] = {
                                ...new_branches[i],
                                filters
                              };
                              this.setState({
                                branches: new_branches
                              });
                            }}
                          />
                        ) : null}
                      </Wrapper>

                      {i == branches.length - 2 ? (
                        <Row>
                          <InlineButton
                            icon={"add"}
                            style={{
                              borderRadius: 5,
                              margin: 10,
                              marginBottom: 0
                            }}
                            onPress={() => {
                              let new_branches = JSON.parse(
                                JSON.stringify(branches)
                              );

                              new_branches.splice(branches.length - 1, 0, {
                                branch_name:
                                  "Branch " + parseInt(branches.length),
                                filters: [],
                                steps: []
                              });
                              this.setState({
                                branches: new_branches
                              });
                            }}
                          >
                            Add another branch
                          </InlineButton>
                        </Row>
                      ) : null}
                    </Wrapper>
                  );
                })}
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: branches
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ workflow }) => {
  const { enrollment_object, enrollment_object_loading } = workflow;
  return {
    enrollment_object,
    enrollment_object_loading
  };
};

export default connect(mapStateToProps, {})(BranchStep);
