import React, { Component } from "react";
import { connect } from "react-redux";

import { InputBox, SelectBox, DateBox } from "app/NativeComponents/snippets";
import { store } from "app/store";
import { dismissMobileKeyboard } from "app/NativeActions";

import moment from "moment";

import { updateLead } from "app/NativeActions";

class EditableCustomField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      focus: false,
      select_focus: false,
      edit_value: props.custom_field?.value || ""
    };

    this._input_ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.property?.property_id !== prevProps.property?.property_id) {
      this.setState({
        focus: false,
        edit_value: this.props.custom_field?.value,
        loading: false,
        select_focus: false
      });
    }
  }

  formatItems(field_options) {
    let options = field_options.split(",");

    let formatted_options = [
      {
        value: "",
        label: "--"
      }
    ];
    for (let i = 0; i < options.length; i++) {
      formatted_options.push({
        value: options[i]?.trim(),
        label: options[i]?.trim()
      });
    }

    return formatted_options;
  }

  onConfirm() {
    const { edit_value } = this.state;
    const { property, custom_field } = this.props;
    this.props.updateLead({
      token: this.props.token,
      type: "update_lead_custom_field",
      deal_ids: property.deal.id,
      value: edit_value,
      custom_field_id: custom_field.custom_field_id,
      no_loading: true,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results?.properties && results?.properties.length > 0
              ? results?.properties[0]
              : this.state.property
          );
        }
      }
    });
  }

  render() {
    const { edit_value, focus, editing_item, loading } = this.state;
    const { custom_field, colors, property, user } = this.props;
    const has_changed =
      custom_field.value && edit_value
        ? edit_value != custom_field.value
        : !custom_field.value
        ? edit_value != "" && edit_value != null
          ? true
          : false
        : true;
    switch (custom_field.field_type) {
      default:
        return (
          <InputBox
            input_ref={this._input_ref}
            autoFocus={false}
            name={"input_" + custom_field.custom_field_id}
            disabled={false}
            returnKeyType="done"
            forceLabel={true}
            forceLabelPlaceholder={"--"}
            placeholder={custom_field.title}
            onFocus={() => {
              this.setState({
                focus: true
              });
            }}
            onBlur={() => {
              this.setState({
                focus: false
              });
            }}
            onChange={value => {
              this.setState({
                edit_value: value
              });
            }}
            blurOnSubmit={true}
            value={edit_value}
            type={custom_field.field_type == "number" ? "number" : "text"}
            single_form={true}
            submit_button_primary={true}
            submit_button_title={"Confirm"}
            require_confirm={true}
            has_changed={has_changed}
            onSubmit={() => {
              if (has_changed) {
                this.onConfirm();
              }
              dismissMobileKeyboard();
            }}
            onSubmitEditing={() => {
              if (has_changed) {
                this.onConfirm();
              }
            }}
            secondary_button={"Cancel"}
            onSecondaryPress={() => {
              this.setState({
                edit_value: custom_field.value ? custom_field.value : ""
              });
              dismissMobileKeyboard();
              this._input_ref.current.blur();
            }}
            loading={loading}
            hover_fa_icon={"pencil-square-o"}
          />
        );

      case "date":
        return (
          <DateBox
            input_ref={this._input_ref}
            name="date"
            placeholder={custom_field.title}
            onChange={value => {
              this.setState({
                edit_value: moment(value).format("YYYY-MM-DD")
              });
            }}
            value={
              !!edit_value ? moment(edit_value).format("MMM Do, YYYY") : "--"
            }
            date_value={
              !!edit_value
                ? moment(edit_value).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD")
            }
            disabled={this.state.loading}
            forceLabel={true}
            forceLabelPlaceholder={"--"}
            show={this.state.select_focus}
            onFocus={() => {
              this.setState({ select_focus: true });
            }}
            onBlur={() => {
              this.setState({
                select_focus: false
              });
            }}
            onSelect={item => {
              this.setState({
                edit_value: item,
                select_focus: false
              });
            }}
            onConfirm={item => {
              this.setState({
                select_focus: false
              });
              this.onConfirm();
            }}
            loading={loading}
            submit_button_primary={true}
            submit_button_title={"Confirm"}
            require_confirm={true}
            has_changed={has_changed}
            onSubmit={() => {
              if (has_changed) {
                this.onConfirm();
              }
              dismissMobileKeyboard();
            }}
            onSubmitEditing={() => {
              if (has_changed) {
                this.onConfirm();
              }
            }}
            secondary_button={"Cancel"}
            onSecondaryPress={() => {
              this.setState({
                edit_value: custom_field.value ? custom_field.value : "",
                select_focus: false
              });
            }}
            hover_fa_icon={"pencil-square-o"}
          />
        );
      case "select":
        return (
          <SelectBox
            select_ref={this._input_ref}
            items={this.formatItems(custom_field.field_options)}
            disabled={this.state.loading}
            value={edit_value}
            placeholder={custom_field.title}
            forceLabel={true}
            forceLabelPlaceholder={"--"}
            show={this.state.select_focus}
            onFocus={() => {
              this.setState({ select_focus: true });
            }}
            onBlur={() => {
              this.setState({
                select_focus: false
              });
            }}
            onSelect={item => {
              this.setState({
                edit_value: item,
                select_focus: false
              });
            }}
            onConfirm={item => {
              this.setState({
                select_focus: false
              });
              this.onConfirm();
            }}
            submit_button_primary={true}
            submit_button_title={"Confirm"}
            require_confirm={true}
            has_changed={has_changed}
            onSubmit={() => {
              if (has_changed) {
                this.onConfirm();
              }
              dismissMobileKeyboard();
            }}
            onSubmitEditing={() => {
              if (has_changed) {
                this.onConfirm();
              }
            }}
            secondary_button={"Cancel"}
            onSecondaryPress={() => {
              this.setState({
                edit_value: custom_field.value ? custom_field.value : "",
                select_focus: false
              });
            }}
            loading={loading}
            hover_fa_icon={"pencil-square-o"}
          />
        );
    }
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { colors } = settings;

  return {
    token,
    user,
    colors
  };
};

export default connect(mapStateToProps, {
  updateLead
})(EditableCustomField);
