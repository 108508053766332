import React, { Component } from "react";
import { connect } from "react-redux";

import ReactMapboxGl, {
  ZoomControl,
  Source,
  Layer,
  Image
} from "react-mapbox-gl";

import DrawControl from "react-mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { store } from "app/store";
const StaticMode = require("@mapbox/mapbox-gl-draw-static-mode");

class Map extends Component {
  sendCoordinates(features) {
    let drawing_coordinates = [];

    if (features && features.length > 0) {
      const geometry = features[0].geometry;
      if (geometry && geometry.coordinates.length > 0) {
        const coordinates = geometry.coordinates[0];

        for (let i = 0; i < coordinates.length; i++) {
          drawing_coordinates.push({
            latitude: coordinates[i][1],
            longitude: coordinates[i][0]
          });
        }
      }
    }

    if (drawing_coordinates.length > 2) {
      this.props.onDraw(drawing_coordinates);
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return false;
  }

  render() {
    const {
      user,
      scrollZoom = false,
      onDraw = () => {},
      drawing = false,
      latitude,
      longitude
    } = this.props;
    const colors = store.getState().settings.colors;
    const dark_mode = store.getState().settings.dark_mode;
    const Map = ReactMapboxGl({
      accessToken:
        "pk.eyJ1IjoiZGVhbG1hY2hpbmUiLCJhIjoiY2t4YXZna3ZiM2t2djJvcDlkaXF5ZTF3YyJ9.ies_4s0QK2FRGcaXHsIXXg"
    });

    const onDrawCreate = ({ features }) => {
      this.props.drawingCreated();
      this.sendCoordinates(features);
    };

    const onDrawUpdate = ({ features }) => {
      this.sendCoordinates(features);
    };

    const getCenter = map => {
      const coordinate = map.getCenter();
      this.props.updateCenter({
        latitude: coordinate.lat,
        longitude: coordinate.lng
      });

      const bounds = map.getBounds();

      this.props.updateBounds(map);
    };

    const calculateZoom = map => {
      const zoom = map.getZoom();

      this.props.updateZoomProperties({
        zoom: zoom
      });

      this.props.updateBounds(map);
    };

    return (
      <Map
        ref={this.props.onRef}
        style={
          this.props.map_type == "satellite"
            ? "mapbox://styles/dealmachine/ckzhc5qak000i15qpidgpl4ch?optimize=true"
            : dark_mode == "dark_mode"
            ? "mapbox://styles/dealmachine/ckzhc2ntq004c15lcuo7q4qex?optimize=true"
            : "mapbox://styles/dealmachine/ckzhbuxyo000h14qudkuurjga?optimize=true"
        }
        containerStyle={{
          width: "100%",
          height: "100%"
        }}
        zoom={[parseInt(this.props.starting_zoom)]}
        maxZoom={parseInt(this.props.maxZoom)}
        minZoom={parseInt(this.props.minZoom)}
        center={[
          parseFloat(this.props.starting_lng),
          parseFloat(this.props.starting_lat)
        ]}
        scrollZoom={scrollZoom}
        scrollWheelZoom={scrollZoom}
        onZoomEnd={calculateZoom}
        onMoveEnd={getCenter}
        onDragEnd={getCenter}
        onStyleLoad={getCenter}
      >
        <Image id={"lead"} url={"/assets/images/active_pin.png"} />

        <Source
          id="lists_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="fill"
          id="lists_on_map_polygon"
          sourceId="lists_on_map"
          paint={{
            "fill-color": colors.active_color,
            "fill-outline-color": colors.active_color,
            "fill-opacity": 0.25
          }}
        />

        <Source
          id="leads_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        {/*
        <Layer
          type="circle"
          id="leads_on_map_circle"
          sourceId="leads_on_map"
          paint={{
            "circle-color":
              dark_mode == "dark_mode"
                ? "rgba(0, 79, 100,0.5)"
                : "rgba(0, 79, 100,0.5)",
            "circle-radius": 3,
            "circle-stroke-width": 1,
            "circle-stroke-color": colors.card_color
          }}
        />
        */}

        <Layer
          type="symbol"
          minZoom={4}
          id="leads_on_map_symbol"
          sourceId="leads_on_map"
          layout={{
            "icon-image": "lead",
            "icon-size": 0.3,
            "icon-anchor": "bottom"
          }}
        />

        <Source
          id="highlights_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="fill"
          id="highlights_on_map_polygon"
          sourceId="highlights_on_map"
          paint={{
            "fill-color": colors.orange_color,
            "fill-outline-color": colors.orange_color,
            "fill-opacity": this.props?.map_type == "satellite" ? 0.4 : 0.3
          }}
        />

        <DrawControl
          ref={this.props.onDrawRef}
          position="top-left"
          touchEnabled={!drawing}
          displayControlsDefault={false}
          defaultMode={"simple_select"}
          onDrawCreate={onDrawCreate}
          onDrawUpdate={onDrawUpdate}
          minZoom={this.props.minZoom}
          maxZoom={this.props.maxZoom}
          modes={{ static: StaticMode }}
          styles={[
            {
              id: "gl-draw-line",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["!=", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.orange_color,
                "line-dasharray": [0.2, 2],
                "line-width": 2
              }
            },
            // polygon fill
            {
              id: "gl-draw-polygon-fill",
              type: "fill",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["!=", "mode", "static"]
              ],
              paint: {
                "fill-color": colors.orange_color_muted,
                "fill-outline-color": colors.orange_color_muted,
                "fill-opacity": 0.25
              }
            },
            // polygon mid points
            {
              id: "gl-draw-polygon-midpoint",
              type: "circle",
              filter: [
                "all",
                ["==", "$type", "Point"],
                ["==", "meta", "midpoint"]
              ],
              paint: {
                "circle-radius": 3,
                "circle-color": colors.orange_color
              }
            },
            {
              id: "gl-draw-polygon-stroke-active",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["!=", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.orange_color,
                "line-dasharray": [0.2, 2],
                "line-width": 2
              }
            },
            // vertex point halos
            {
              id: "gl-draw-polygon-and-line-vertex-halo-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"]
              ],
              paint: {
                "circle-radius": 5,
                "circle-color": colors.card_color
              }
            },
            // vertex points
            {
              id: "gl-draw-polygon-and-line-vertex-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"]
              ],
              paint: {
                "circle-radius": 3,
                "circle-color": colors.orange_color
              }
            },

            // INACTIVE (static, already drawn)
            // line stroke
            {
              id: "gl-draw-line-static",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["==", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.orange_color,
                "line-width": 3
              }
            },
            // polygon fill
            {
              id: "gl-draw-polygon-fill-static",
              type: "fill",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["==", "mode", "static"]
              ],
              paint: {
                "fill-color": colors.orange_color_muted,
                "fill-outline-color": colors.orange_color_muted,
                "fill-opacity": 0.25
              }
            },
            // polygon outline
            {
              id: "gl-draw-polygon-stroke-static",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["==", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.orange_color,
                "line-width": 2
              }
            }
          ]}
        />
      </Map>
    );
  }
}

export default Map;
