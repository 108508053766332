import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Copy,
  Row,
  KeyboardView,
  Wrapper,
  Card,
  Button,
  Icon,
  StatusBarStyle
} from "app/NativeComponents/common";
import {
  SelectItem,
  BottomNavBar,
  InlineButton,
  IconButton,
  AttentionBox,
  GhostButton,
  SearchBar,
  SpinWrapper,
  PullUpSheet,
  ConfirmInlineButton,
  CircleButton,
  InlineTabs,
  CloseButton
} from "app/NativeComponents/snippets";

import {
  setStreetViewCoords,
  setStreetViewLine,
  getPropertyNew,
  getPropertiesOnMap,
  setMapProperties,
  pushSidePanel,
  popSidePanel,
  renderLabelText,
  checkIfHasGoogleStreetPic,
  startTrackedRoute,
  stopTrackedRoute,
  selectActiveProperty,
  selectActiveCoordinates,
  selectActiveRoute,
  tabNavigation,
  checkIfUserHasSubscription,
  toggleVirtualDriveStart,
  getMarketingOnboardingData,
  updateUser,
  showErrorMessage,
  selectMultipleProperties
} from "app/NativeActions";
import NativeStreetView from "app/NativeComponents/components/NativeStreetView";
import { getDistance, isPointInPolygon } from "geolib";

import PropertyView from "../../PropertyView";
import AllPropertyInfo from "app/DealMachineCore/components/Property/AllPropertyInfo";
import PropertyInfoNew from "app/DealMachineCore/components/Property/PropertyInfoNew";

import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";
import StreetViewOnboardingText from "./StreetViewOnboardingText";
import StreetViewOnboardingTextMobile from "./StreetViewOnboardingTextMobile";
import BottomSlider from "app/DealMachineCore/components/BottomTabBar/BottomSlider";
import moment from "moment";
class Street extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      loading: false,
      route_loading: false,
      property: null,
      full_properties: [],
      properties_on_map_loading: false,
      last_fetch_location: null,
      info_search: "",
      info_tab: "info",
      show_panel: false,
      show_card: true,
      last_date: null,
      page_length: props?.map_side_panel_pages?.length,
      motion_tracking: false,
      onboarding_map:
        props.onboarding_map || panel_data?.onboarding_map ? true : false,
      refreshing: false
    };

    this.getFullPropertyInfo = this.getFullPropertyInfo.bind(this);
    this.setViewingProperty = this.setViewingProperty.bind(this);
    this.propertyUpdated = this.propertyUpdated.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);

    this._scroll_view = React.createRef();
  }

  toRad(n) {
    return (n * Math.PI) / 180;
  }
  toDeg(n) {
    return (n * 180) / Math.PI;
  }

  scrollToSection(section) {
    if (this.props.device === "desktop" && section?.id && this._scroll_view) {
      const elmnt = document.getElementById(section?.id);

      this._scroll_view.current.scroll({
        top: elmnt.offsetTop - 300,
        behavior: "smooth"
      });
    } else if (
      device === "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.offset
    ) {
      let y = section.offset + this.state.top_view_height - 7;
      if (section?.id === "owner-information") {
        y = section.offset + this.state.above_owner_height - 15;
      }
      this._scroll_view.current.scrollTo({
        x: 0,
        y: y,
        animated: true
      });
    }
  }

  destVincenty(lat1, lon1, brng, dist) {
    var a = 6378137,
      b = 6356752.3142,
      f = 1 / 298.257223563, // WGS-84 ellipsiod
      s = dist,
      alpha1 = this.toRad(brng),
      sinAlpha1 = Math.sin(alpha1),
      cosAlpha1 = Math.cos(alpha1),
      tanU1 = (1 - f) * Math.tan(this.toRad(lat1)),
      cosU1 = 1 / Math.sqrt(1 + tanU1 * tanU1),
      sinU1 = tanU1 * cosU1,
      sigma1 = Math.atan2(tanU1, cosAlpha1),
      sinAlpha = cosU1 * sinAlpha1,
      cosSqAlpha = 1 - sinAlpha * sinAlpha,
      uSq = (cosSqAlpha * (a * a - b * b)) / (b * b),
      A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq))),
      B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq))),
      sigma = s / (b * A),
      sigmaP = 2 * Math.PI;
    while (Math.abs(sigma - sigmaP) > 1e-12) {
      var cos2SigmaM = Math.cos(2 * sigma1 + sigma),
        sinSigma = Math.sin(sigma),
        cosSigma = Math.cos(sigma),
        deltaSigma =
          B *
          sinSigma *
          (cos2SigmaM +
            (B / 4) *
              (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
                (B / 6) *
                  cos2SigmaM *
                  (-3 + 4 * sinSigma * sinSigma) *
                  (-3 + 4 * cos2SigmaM * cos2SigmaM)));
      sigmaP = sigma;
      sigma = s / (b * A) + deltaSigma;
    }
    var tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1,
      lat2 = Math.atan2(
        sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
        (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp)
      ),
      lambda = Math.atan2(
        sinSigma * sinAlpha1,
        cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1
      ),
      C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha)),
      L =
        lambda -
        (1 - C) *
          f *
          sinAlpha *
          (sigma +
            C *
              sinSigma *
              (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM))),
      revAz = Math.atan2(sinAlpha, -tmp); // final bearing
    return {
      latitude: this.toDeg(lat2),
      longitude: lon1 + this.toDeg(L)
    };
  }

  getPropertyLookingAt() {
    //create a lat lng 100 m
    const { map_properties } = this.props;
    if (
      this.props.street_view_coords?.latitude &&
      this.props.street_view_coords?.longitude
    ) {
      const { latitude, longitude, heading } = this.props.street_view_coords;

      const distance = 20; //100 m
      const radius = 6371008.8; //radius of the earth in meters
      const destination = this.destVincenty(
        latitude,
        longitude,
        heading,
        distance
      );

      this.props.setStreetViewLine([
        [longitude, latitude],
        [destination.longitude, destination.latitude]
      ]);
      let found_property = null;
      if (map_properties) {
        for (let i = 0; i < map_properties.length; i++) {
          if (map_properties[i].coordinates) {
            for (let j = 0; j < map_properties[i].coordinates.length; j++) {
              if (
                isPointInPolygon(
                  {
                    latitude: destination.latitude,
                    longitude: destination.longitude
                  },
                  map_properties[i].coordinates[j]
                ) &&
                !found_property
              ) {
                found_property = map_properties[i];
              }
            }
          }
        }
      }
      if (found_property?.id !== this.state.property?.id) {
        this.setViewingProperty(found_property);
      }
    }
  }

  setViewingProperty(viewing_property) {
    const { map_properties } = this.props;
    let new_properties_on_map = [];
    for (let i = 0; i < map_properties?.length; i++) {
      if (viewing_property && viewing_property?.id == map_properties[i]?.id) {
        new_properties_on_map.push({ ...map_properties[i], viewing: true });
      } else {
        new_properties_on_map.push({ ...map_properties[i], viewing: false });
      }
    }
    this.props.setMapProperties(new_properties_on_map);

    this.setState(
      {
        property: viewing_property,
        info_tab: "info",
        show_panel:
          this.state.viewing_property &&
          viewing_property?.property_id !=
            this.state.viewing_property?.property_id
            ? false
            : this.state.show_panel,
        last_date: !viewing_property ? null : this.state.last_date
      },
      () => {
        this.getMoreData(viewing_property);
      }
    );
  }

  componentDidMount() {
    //this.getPropertyLookingAt();
    this.props.setMapProperties([]);
    this.getPropertyData();
  }

  getFullPropertyInfo(property_id) {
    if (property_id) {
      for (let i = 0; i < this.state.full_properties.length; i++) {
        if (this.state.full_properties[i].property_id == property_id) {
          return this.state.full_properties[i];
        }
      }
    }

    return null;
  }

  getPropertyData() {
    if (
      (!this.props.properties_on_map_loading &&
        this.props.street_view_coords?.latitude &&
        this.props.street_view_coords?.longitude &&
        (!this.state.last_fetch_location ||
          getDistance(this.state.last_fetch_location, {
            latitude: this.props.street_view_coords?.latitude,
            longitude: this.props.street_view_coords?.longitude
          }) > 100)) ||
      this.state.refresh
    ) {
      this.setState(
        {
          refresh: false
        },
        () => {
          this.props.getPropertiesOnMap({
            token: this.props.token,
            bounds: {
              centerLat: this.props.street_view_coords?.latitude,
              centerLng: this.props.street_view_coords?.longitude,
              distance: 200
            },
            filters: this.props.applied_highlight,
            highlight:
              this.props.applied_highlight?.data?.length > 0 ? true : false,
            load_type:
              this.state.updated_zoom < 15
                ? this.state.updated_zoom < 13
                  ? "leads_only"
                  : "highlights_only"
                : "all_properties",

            onLoading: () => {
              this.setState({
                properties_on_map_loading: true
              });
            },
            onError: () => {
              this.setState({
                properties_on_map_loading: false,
                refreshing: false
              });
            },
            onSuccess: results => {
              let new_properties_on_map = JSON.parse(
                JSON.stringify(this.props.map_properties)
              );

              for (let i = 0; i < results?.properties_on_map?.length; i++) {
                let found_property = false;
                for (let j = 0; j < new_properties_on_map.length; j++) {
                  if (
                    results.properties_on_map[i].property_id ==
                    new_properties_on_map[j].property_id
                  ) {
                    found_property = true;
                    new_properties_on_map[j] = {
                      ...results.properties_on_map[i],
                      property_label: renderLabelText({
                        property: results.properties_on_map[i],
                        property_label_type:
                          this.props.main_map_location?.property_label_type
                      })
                    };
                  } else if (
                    results.properties_on_map[i].location?.latitude ==
                      new_properties_on_map[j].location?.latitude &&
                    results.properties_on_map[i].location?.longitude ==
                      new_properties_on_map[j].location?.longitude
                  ) {
                    found_property = true;
                  }
                }
                if (!found_property) {
                  new_properties_on_map.push({
                    ...results.properties_on_map[i],
                    property_label: renderLabelText({
                      property: results.properties_on_map[i],
                      property_label_type:
                        this.props.main_map_location?.property_label_type
                    })
                  });
                }
              }
              this.props.setMapProperties(new_properties_on_map);
              this.setState({
                properties_on_map_loading: false,
                last_fetch_location: {
                  latitude: this.props.street_view_coords?.latitude,
                  longitude: this.props.street_view_coords?.longitude
                },
                refreshing: false
              });

              this.getPropertyLookingAt();
            }
          });
        }
      );
    }
  }

  getMoreData(property) {
    if (
      !this.state.loading &&
      (this.state.property?.property_id != property?.property_id ||
        this.state.property?.property_id)
    ) {
      this.setState(
        {
          loading: true
        },
        () => {
          checkIfHasGoogleStreetPic({
            address: !!this.state.property?.property_address
              ? this.state.property?.property_address_full
              : null,
            onSuccess: results => {
              this.setState({
                last_date: results.date
              });
            }
          });

          this.props.getPropertyNew({
            token: this.props.token,
            property_id: this.state.property?.property_id,
            onLoading: () => {
              this.setState({
                loading: true
              });
            },
            onError: () => {
              this.setState({
                loading: false
              });
            },
            onSuccess: results => {
              let properties = JSON.parse(
                JSON.stringify(this.state.full_properties)
              );
              let found_property = false;
              for (let j = 0; j < properties.length; j++) {
                if (
                  results.property?.property_id == properties[j].property_id
                ) {
                  found_property = true;
                  properties[j] = {
                    ...properties[j],
                    ...results.property
                  };
                }
              }
              if (!found_property) {
                properties.push(results.property);
              }

              this.setState({
                loading: false,
                show_card: true,
                property:
                  !this.state.property ||
                  this.state.property?.id == results.property?.id
                    ? {
                        ...this.state.property,
                        ...results.property
                      }
                    : this.state.property,
                full_properties: properties
              });
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.street_view_coords &&
      JSON.stringify(this.props.street_view_coords) !==
        JSON.stringify(prevProps.street_view_coords)
    ) {
      this.getPropertyLookingAt();
    }

    if (
      this.state.property &&
      this.state.property?.id !== prevState.property?.id
    ) {
      this.getMoreData(this.state.property);
    }

    if (
      this.props.street_view_coords &&
      (this.props.street_view_coords?.latitude !=
        prevProps.street_view_coords?.latitude ||
        this.props.street_view_coords?.longitude !=
          prevProps.street_view_coords?.longitude)
    ) {
      this.getPropertyData();
    }
    if (
      this.props.map_properties &&
      JSON.stringify(this.props.map_properties) !==
        JSON.stringify(prevProps.map_properties)
    ) {
      this.getPropertyLookingAt();
    }
  }

  propertyUpdated(property) {
    this.setState({
      property,
      full_properties: this.state.full_properties.map(p => {
        if (property?.property_id == p.property_id) {
          return property;
        }
        return p;
      })
    });

    this.props.setMapProperties(
      this.props.map_properties.map(p => {
        if (property?.property_id == p.property_id) {
          return property;
        }
        return p;
      })
    );
  }

  render() {
    const { all_leads_count, all_leads_count_loading, property } = this.state;
    const {
      user,
      source_of_truth,
      colors,
      isMobile,
      device,
      total_deals_added,
      total_miles,
      total_time,
      current_route,
      current_route_id,
      current_route_section,
      track_route_type,
      track_route
    } = this.props;

    const full_property_info = this.getFullPropertyInfo(property?.property_id);

    const isIphoneX = this.props.isIphoneX;
    let top = 0;

    if (device == "desktop") {
      const slideup_property_handle = document.getElementById(
        "slideup_property_handle"
      );
      top =
        this.props.window_height -
        parseInt(slideup_property_handle?.clientHeight) -
        150;
    }

    return (
      <>
        <StatusBarStyle statusBarStyle={"light-content"} />

        <Wrapper
          style={{ position: "relative", flex: 1, backgroundColor: "#000000" }}
          className={"animated fadeIn"}
        >
          <Wrapper
            style={{
              margin: 0,
              borderRadius: 0,
              overflow: "hidden",
              flex: 1
            }}
          >
            <NativeStreetView
              map_properties={this.props.map_properties}
              expanded={this.props.expanded}
              motion_tracking={this.state.motion_tracking}
            />
          </Wrapper>

          {this.props.device == "desktop" && isMobile == true ? (
            <PullUpSheet
              show={this.state.show_panel}
              onShow={() => {
                this.setState({
                  show_panel: true
                });
              }}
              onHide={() => {
                this.setState({
                  show_panel: false
                });
              }}
              increase_height_on_keyboard={false}
              scroll_to_hide={false}
              desktop_bottom={0}
              bottom={device == "desktop" ? 1 : 0}
              top={"75%"}
              desktop_top={top}
              noHandle={false}
              renderHandle={() => {
                return (
                  <Wrapper id="slideup_property_handle">
                    {property ? (
                      <>
                        {!!this.state.last_date && !this.state.show_panel ? (
                          <Wrapper
                            style={{
                              position: "absolute",
                              top: -40,
                              left: 5,
                              borderRadius: 5,
                              margin: 5,
                              padding: 15,
                              paddingTop: 5,
                              paddingBottom: 5,
                              backgroundColor: "rgba(0,0,0,0.25)"
                            }}
                          >
                            <Copy
                              style={{
                                color: "#ffffff",
                                fontSize: 10
                              }}
                            >
                              Photo taken on{" "}
                              {moment(this.state.last_date).format(
                                "MMMM, YYYY"
                              )}
                            </Copy>
                          </Wrapper>
                        ) : null}

                        <Wrapper
                          style={{
                            //padding: 20,
                            paddingTop: 0,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <Wrapper
                            style={{
                              padding: 15,
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <Wrapper
                              style={{
                                height: 8,
                                width: 60,
                                borderRadius: 4,
                                backgroundColor: colors.gray_color
                              }}
                            />
                          </Wrapper>
                        </Wrapper>
                        <Wrapper style={{ paddingBottom: 20 }}>
                          <PropertyView
                            property={
                              full_property_info ? full_property_info : property
                            }
                            property_overlay={this.props.expanded}
                            no_refresh={true}
                            include_tags={true}
                            propertyUpdated={property => {
                              this.propertyUpdated(property);
                            }}
                            expanded={this.props.expanded}
                            onboarding_map={this.state.onboarding_map}
                          />
                        </Wrapper>
                      </>
                    ) : null}
                  </Wrapper>
                );
              }}
            />
          ) : this.props.device == "desktop" &&
            property &&
            this.state.show_card ? (
            <Wrapper
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                backgroundColor: "transparent",
                zIndex: 2
              }}
            >
              {!!this.state.last_date ? (
                <Row style={{ justifyContent: "flex-end" }}>
                  <Wrapper
                    style={{
                      borderRadius: 5,
                      margin: 5,
                      padding: 15,
                      paddingTop: 5,
                      paddingBottom: 5,
                      backgroundColor: "rgba(0,0,0,0.15)"
                    }}
                  >
                    <Copy
                      style={{
                        color: "#ffffff",
                        fontSize: 10
                      }}
                    >
                      Photo taken on{" "}
                      {moment(this.state.last_date).format("MMMM, YYYY")}
                    </Copy>
                  </Wrapper>
                </Row>
              ) : null}
              <Wrapper
                style={{
                  maxHeight: "100vh",
                  minHeight: 220,
                  width: 450
                }}
              >
                <Card
                  style={{
                    flex: 1,
                    alignSelf: "stretch",
                    overflow: "hidden",
                    paddingBottom: this.state.show_panel
                      ? 0
                      : this.props.onboarding_map
                      ? 80
                      : 25,
                    margin: 0,
                    borderRadius: 0,
                    borderTopRightRadius: 30,
                    borderTopLeftRadius: 30,
                    marginTop: 5
                  }}
                >
                  <Button
                    onPress={() => {
                      this.setState({
                        show_panel: !this.state.show_panel
                      });
                    }}
                  >
                    <Wrapper
                      style={{
                        //padding: 20,
                        paddingTop: 0,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Wrapper
                        style={{
                          padding: 15,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Wrapper
                          style={{
                            height: 8,
                            width: 60,
                            borderRadius: 4,
                            backgroundColor: colors.gray_color
                          }}
                        />
                      </Wrapper>
                    </Wrapper>
                  </Button>
                  <CloseButton
                    onPress={() => {
                      this.setState({ show_card: false });
                    }}
                  />
                  <PropertyView
                    property={
                      full_property_info ? full_property_info : property
                    }
                    property_overlay={this.props.expanded}
                    no_refresh={true}
                    include_tags={true}
                    propertyUpdated={property => {
                      this.propertyUpdated(property);
                    }}
                    expanded={this.props.expanded}
                    onboarding_map={this.state.onboarding_map}
                  />
                  {this.state.show_panel ? (
                    <KeyboardView
                      style={{ flex: 1 }}
                      scroll_ref={this._scroll_view}
                    >
                      <PropertyInfoNew
                        {...this.props}
                        property={property}
                        search={this.state.info_search}
                        no_editing={false}
                        no_county_sync={true}
                        include_more_data={true}
                        include_custom_fields={false}
                        propertyUpdated={this.propertyUpdated}
                        scrollToSection={this.scrollToSection}
                        property_loading={this.state.loading}
                      />
                    </KeyboardView>
                  ) : null}
                </Card>
              </Wrapper>
            </Wrapper>
          ) : null}

          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: this.props.device == "mobile" ? 0 : null,
              backgroundColor: "transparent",
              zIndex: 1
            }}
          >
            {checkIfUserHasSubscription(
              this.props.user,
              this.props.source_of_truth
            ) &&
            !this.state.onboarding_map &&
            (track_route_type === "virtual" || !track_route) ? (
              <>
                <Wrapper
                  style={{
                    backgroundColor:
                      device === "mobile"
                        ? "rgba(0,0,0,0.5)"
                        : "rgba(0,0,0,0.65)",
                    alignSelf: "stretch",
                    borderRadius: 5,
                    margin: 5,
                    padding: 5,
                    maxWidth: device === "mobile" ? "100%" : 550,
                    marginTop: device === "mobile" ? 55 : 5,
                    borderBottomRightRadius:
                      device === "mobile" && this.state.last_date ? 0 : 5
                  }}
                >
                  <Row>
                    {(!isMobile ||
                      !current_route?.route_id ||
                      track_route_type !== "virtual") &&
                    !current_route?.route_id ? (
                      <HeaderButton
                        buttonContents={{
                          onPress: () => {
                            if (device === "mobile") {
                              this.props.setStreetViewCoords({
                                latitude: null,
                                longitude: null
                              });
                              this.props.popSidePanel();
                            } else {
                              this.setViewingProperty(null);

                              if (!this.props.track_route) {
                                this.props.setStreetViewCoords({
                                  latitude: null,
                                  longitude: null
                                });
                              }

                              this.props.toggleVirtualDriveStart(false);
                            }
                          },
                          icon: "close",
                          color: "#ffffff",
                          backgroundColor: colors.hover_color
                        }}
                        style={{
                          backgroundColor: "rgba(0,0,0,0.25)"
                        }}
                      />
                    ) : null}
                    <Wrapper
                      style={{
                        flex: 1,
                        paddingRight: 15,
                        paddingLeft: 15
                      }}
                    >
                      <Copy
                        style={{
                          fontSize: 10,
                          color: "#ffffff",
                          opacity: !track_route ? 0.25 : 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        Leads added:
                      </Copy>
                      <Copy
                        style={{
                          color: "#ffffff",
                          opacity: !track_route ? 0.25 : 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {total_deals_added || 0}
                      </Copy>
                    </Wrapper>
                    <Wrapper
                      style={{
                        flex: 1,
                        paddingRight: 15,
                        paddingLeft: 15
                      }}
                    >
                      <Copy
                        style={{
                          fontSize: 10,
                          color: "#ffffff",
                          opacity: !track_route ? 0.25 : 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        Miles driven:
                      </Copy>
                      <Copy
                        style={{
                          color: "#ffffff",
                          opacity: !track_route ? 0.25 : 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {total_miles || "0.00"} mi
                      </Copy>
                    </Wrapper>

                    <Wrapper
                      style={{
                        flex: 1,
                        paddingRight: 15,
                        paddingLeft: 15
                      }}
                    >
                      <Copy
                        style={{
                          fontSize: 10,
                          color: "#ffffff",
                          opacity: !track_route ? 0.25 : 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        Time driven:
                      </Copy>
                      <Copy
                        style={{
                          color: "#ffffff",
                          opacity: !track_route ? 0.25 : 1,
                          whiteSpace: "nowrap"
                        }}
                      >
                        {total_time || "0:00:00"}
                      </Copy>
                    </Wrapper>

                    {current_route?.route_id ? (
                      <ConfirmInlineButton
                        confirm_text="Really End?"
                        confirm_icon="stop"
                        icon="stop"
                        primary={true}
                        loading_text="Ending..."
                        loading={this.state.route_loading}
                        onPress={() => {
                          if (current_route?.route_id) {
                            this.props.stopTrackedRoute({
                              token: this.props.token,
                              route_id: current_route.route_id,
                              start_time: current_route_section
                                ? current_route_section.start_time
                                : moment().format("YYYY-MM-DD HH:mm:ss"),
                              coordinates: current_route_section
                                ? current_route_section.coordinates
                                : [],
                              current_route: current_route,
                              onLoading: () => {
                                this.setState({
                                  route_loading: true
                                });
                              },
                              onError: () => {
                                this.setState({
                                  route_loading: false
                                });
                              },
                              onSuccess: results => {
                                this.setState({
                                  route_loading: false
                                });

                                if (results.routes?.length > 0) {
                                  this.props.setStreetViewCoords({
                                    latitude: null,
                                    longitude: null
                                  });

                                  if (device == "mobile") {
                                    this.props.selectActiveRoute(
                                      results.routes[0]
                                    );
                                    this.props.popSidePanel();
                                  } else {
                                    this.props.selectActiveRoute(
                                      results.routes[0]
                                    );
                                    this.props.toggleVirtualDriveStart(false);
                                    this.props.pushSidePanel({
                                      slug: "view_route"
                                    });
                                  }
                                }
                              }
                            });
                          }
                        }}
                      >
                        End
                      </ConfirmInlineButton>
                    ) : (
                      <InlineButton
                        loading={this.state.route_loading}
                        onPress={() => {
                          this.props.startTrackedRoute({
                            token: this.props.token,
                            track_route_type: "virtual",
                            drive_type: "virtual",
                            coordinates: [
                              {
                                latitude:
                                  this.props.street_view_coords?.latitude,
                                longitude:
                                  this.props.street_view_coords?.longitude
                              }
                            ],
                            onLoading: () => {
                              this.setState({
                                route_loading: true
                              });
                            },
                            onError: () => {
                              this.setState({
                                route_loading: false
                              });
                            },
                            onSuccess: () => {
                              this.setState({
                                route_loading: false
                              });
                            }
                          });
                        }}
                        primary={true}
                      >
                        Start
                      </InlineButton>
                    )}
                  </Row>
                </Wrapper>
              </>
            ) : isMobile ||
              !checkIfUserHasSubscription(
                this.props.user,
                this.props.source_of_truth
              ) ||
              this.state.onboarding_map ? (
              <Wrapper
                style={{
                  marginTop: device === "mobile" ? 55 : 0
                }}
              >
                <Row>
                  <HeaderButton
                    buttonContents={{
                      onPress: () => {
                        if (device === "mobile") {
                          this.props.setStreetViewCoords({
                            latitude: null,
                            longitude: null
                          });
                          this.props.popSidePanel();
                        } else {
                          this.setViewingProperty(null);

                          if (!this.props.track_route) {
                            this.props.setStreetViewCoords({
                              latitude: null,
                              longitude: null
                            });
                          }
                          this.props.toggleVirtualDriveStart(false);
                        }
                      },
                      icon: "close",
                      color: "#ffffff",
                      backgroundColor: colors.hover_color
                    }}
                    style={{
                      backgroundColor: "rgba(0,0,0,0.25)"
                    }}
                  />

                  {this.props.onboarding_map &&
                  this.props.device == "desktop" ? (
                    <InlineButton
                      loading={this.state.skip_loading}
                      onPress={() => {
                        this.props.getMarketingOnboardingData({
                          token: this.props.token,
                          type: "skip_and_add_lead",
                          onLoading: () => {
                            this.setState({ skip_loading: true });
                          },
                          onError: () => {
                            this.setState({ skip_loading: false });
                          },
                          onSuccess: results => {
                            if (results?.lead) {
                              this.props.updateUser({
                                token: this.props.token,
                                type: "onboarding_stepthrough_step",
                                payload: {
                                  onboarding_stepthrough_current_step:
                                    "alma_step"
                                },
                                onLoading: () => {},
                                onError: () => {
                                  this.setState({
                                    skip_loading: false
                                  });
                                },
                                onSuccess: () => {
                                  this.props.toggleVirtualDriveStart(false);
                                },
                                no_loading: true
                              });
                            } else {
                              this.setState({ skip_loading: false });
                              this.props.showErrorMessage(
                                "Something went wrong.",
                                "Error"
                              );
                            }
                          }
                        });
                      }}
                      style={{
                        textAlign: "center",
                        backgroundColor: colors.orange_color_muted
                      }}
                      marketing_slug={"skipped_driving_on_street_onboard"}
                      icon_right={"arrow-right-alt"}
                    >
                      {"Skip and add a lead for me."}
                    </InlineButton>
                  ) : null}
                </Row>
              </Wrapper>
            ) : null}
            {this.state.onboarding_map ? (
              <HeaderButton
                buttonContents={{
                  onPress: () => {
                    this.setState(
                      {
                        refresh: true,
                        refreshing: true
                      },
                      () => {
                        this.getPropertyData();
                      }
                    );
                  },
                  icon: "refresh",
                  color: "#ffffff",
                  backgroundColor: colors.hover_color
                }}
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)"
                }}
              />
            ) : (
              <InlineButton
                loading={this.state.refreshing}
                onPress={() => {
                  this.setState(
                    {
                      refresh: true,
                      refreshing: true
                    },
                    () => {
                      this.getPropertyData();
                    }
                  );
                }}
                icon={"refresh"}
                textColor={"#ffffff"}
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)"
                }}
              >
                Refresh
              </InlineButton>
            )}
          </Wrapper>

          {device === "mobile" ? (
            <>
              {this.state.last_date &&
              checkIfUserHasSubscription(
                this.props.user,
                this.props.source_of_truth
              ) &&
              (track_route_type === "virtual" || !track_route) ? (
                <Wrapper
                  style={{
                    position: "absolute",
                    top: this.state.onboarding_map ? 55 : 120,
                    right: 0,
                    borderRadius: 5,
                    borderTopRightRadius: this.state.onboarding_map ? 5 : 0,
                    borderTopLeftRadius: this.state.onboarding_map ? 5 : 0,
                    margin: 5,
                    padding: 15,
                    paddingTop: 5,
                    paddingBottom: 5,
                    backgroundColor: "rgba(0,0,0,0.25)"
                  }}
                >
                  <Copy
                    style={{
                      color: colors.white_text_color,
                      fontSize: 10
                    }}
                  >
                    Photo taken on{" "}
                    {moment(this.state.last_date).format("MMMM, YYYY")}
                  </Copy>
                </Wrapper>
              ) : null}
            </>
          ) : null}
        </Wrapper>

        {this.props.device == "mobile" ? (
          <>
            <BottomSlider
              property={this.state.property}
              show_street_button={false}
              coordinates={null}
              route={null}
              route_plan_active={null}
              route_plan_legs={null}
              next_planned_property={null}
              selectActiveProperty={this.props.selectActiveProperty}
              selectActiveCoordinates={this.props.selectActiveCoordinates}
              selectActiveRoute={this.props.selectActiveRoute}
              route_plan_completed={null}
              onboarding_map={this.state.onboarding_map}
              selected_active_properties={this.props.selected_active_properties}
              selectMultipleProperties={this.props.selectMultipleProperties}
              property_loading={this.state.loading}
            />
          </>
        ) : null}

        {this.state.onboarding_map && this.props.device == "mobile" ? (
          <StreetViewOnboardingTextMobile />
        ) : null}

        {this.props.device == "desktop" ? (
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 5,
              borderRadius: 5,
              width: 300,
              height: this.state.show_panel ? 0 : "auto",
              overflow: "hidden"
            }}
          >
            <Wrapper
              style={{
                padding: 25,
                backgroundColor: "rgba(0,0,0,0.25)",
                borderRadius: 5
              }}
            >
              <Wrapper style={{ flex: 1, paddingBottom: 10 }}>
                <Copy style={{ color: colors.white_text_color, fontSize: 10 }}>
                  Click on the street view part of the screen and then...
                </Copy>
              </Wrapper>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  flex: 1
                }}
              >
                <Wrapper style={{ flex: 1 }}>
                  <Copy style={{ color: colors.white_text_color }}>
                    Move forward/backward:
                  </Copy>
                </Wrapper>
                <Row
                  style={{
                    marginLeft: 10,
                    width: 30,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Wrapper
                    style={{
                      borderWidth: 1,
                      width: 10,
                      height: 10,
                      borderColor: colors.white_text_color,
                      borderStyle: "solid",
                      padding: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 5,
                      borderRadius: 5,
                      marginRight: 0
                    }}
                  >
                    <Icon
                      icon={"arrow-upward"}
                      size={12}
                      color={colors.white_text_color}
                    />
                  </Wrapper>
                  <Wrapper
                    style={{
                      borderWidth: 1,
                      width: 10,
                      height: 10,
                      borderColor: colors.white_text_color,
                      borderStyle: "solid",
                      padding: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 5,
                      borderRadius: 5
                    }}
                  >
                    <Icon
                      icon={"arrow-downward"}
                      size={12}
                      color={colors.white_text_color}
                    />
                  </Wrapper>
                </Row>
              </Row>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  flex: 1
                }}
              >
                <Wrapper style={{ flex: 1 }}>
                  <Copy style={{ color: colors.white_text_color }}>
                    Pan left/right:
                  </Copy>
                </Wrapper>
                <Row
                  style={{
                    marginLeft: 10,
                    width: 30,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Wrapper
                    style={{
                      borderWidth: 1,
                      width: 10,
                      height: 10,
                      borderColor: colors.white_text_color,
                      borderStyle: "solid",
                      padding: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 5,
                      borderRadius: 5,
                      marginRight: 0
                    }}
                  >
                    <Icon
                      icon={"arrow-back"}
                      size={12}
                      color={colors.white_text_color}
                    />
                  </Wrapper>
                  <Wrapper
                    style={{
                      borderWidth: 1,
                      width: 10,
                      height: 10,
                      borderColor: colors.white_text_color,
                      borderStyle: "solid",
                      padding: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 5,
                      borderRadius: 5
                    }}
                  >
                    <Icon
                      icon={"arrow-forward"}
                      size={12}
                      color={colors.white_text_color}
                    />
                  </Wrapper>
                </Row>
              </Row>
            </Wrapper>
            {this.props.onboarding_map ? <StreetViewOnboardingText /> : null}
          </Wrapper>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  route,
  property_map,
  billing
}) => {
  const { token, user } = auth;
  const { device, window_height, isMobile, isIphoneX } = native;
  const { colors, dark_mode } = settings;
  const { source_of_truth } = billing;

  const {
    street_view_coords,
    map_properties,
    main_map_location,
    selected_active_properties
  } = property_map;

  const {
    route_plan_active,
    track_route,
    total_miles,
    total_time,
    total_deals_added,
    current_route,
    current_route_id,
    current_route_section,
    track_route_type
  } = route;

  return {
    token,
    user,
    device,
    isMobile,
    isIphoneX,
    window_height,
    colors,
    dark_mode,
    street_view_coords,
    map_properties,
    main_map_location,

    route_plan_active,
    track_route,
    total_miles,
    total_time,
    total_deals_added,
    current_route,
    current_route_id,
    current_route_section,
    track_route_type,
    source_of_truth,
    selected_active_properties
  };
};

export default connect(mapStateToProps, {
  setStreetViewCoords,
  setStreetViewLine,
  getPropertyNew,
  getPropertiesOnMap,
  setMapProperties,
  pushSidePanel,
  popSidePanel,
  startTrackedRoute,
  stopTrackedRoute,
  selectActiveRoute,
  selectActiveProperty,
  selectActiveCoordinates,
  tabNavigation,
  toggleVirtualDriveStart,
  getMarketingOnboardingData,
  updateUser,
  showErrorMessage,
  selectMultipleProperties
})(Street);
