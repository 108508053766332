import React, { Component } from "react";

import {
  Split,
  Stretch,
  CenterLeft,
  Title,
  Input
} from "app/NativeComponents/common";

class Scene2 extends Component {
  render() {
    if (this.props.scene > 1) {
      return (
        <div>
          <Split>
            <Stretch>
              <CenterLeft style={{ paddingLeft: 20 }}>
                <Title>Repeat Every:</Title>
              </CenterLeft>
            </Stretch>
            <Stretch>
              <Input
                last={true}
                ref="sample_resend_freq"
                name="sample_resend_freq"
                blurOnSubmit={true}
                autoCapitalize="none"
                returnKeyType={"default"}
                keyboardType="numeric"
                placeholder="Day(s)"
                onChange={() => {}}
                value={
                  this.props.scene == 2
                    ? 21
                    : this.props.scene == 3
                    ? 1
                    : this.props.scene == 4
                    ? ""
                    : this.props.scene == 5
                    ? 3
                    : this.props.scene >= 6
                    ? 30
                    : 21
                }
                type="number"
              />
            </Stretch>
          </Split>
        </div>
      );
    }

    return <div />;
  }
}

export default Scene2;
