import {
  CREATE_TAG_FAIL,
  CREATE_TAG_SUCCESS,
  ERROR_MESSAGE,
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  UPDATE_TEAM_TAG_ORDER_SUCCESS,
  UPDATE_TEAM_TAG_ORDER_FAIL,
  HIDE_TAG_FAIL,
  HIDE_TAG_SUCCESS,
  IS_LOADING,
  REMOVE_TAG_FAIL,
  REMOVE_TAG_SUCCESS,
  SHOW_TAG_FAIL,
  SHOW_TAG_SUCCESS,
  SUCCESS_MESSAGE,
  TRIGGER_LOGOUT,
  GET_PROPERTY_TAGS,
  GET_PROPERTY_TAGS_SUCCESS,
  GET_PROPERTY_TAGS_FAIL
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";

import API from "app/DealMachineCore/apis/DealMachineAPI";

const api = API.create();

export const getTags = (token, search) => {
  return dispatch => {
    dispatch({ type: GET_TAGS });

    api.getTags(token, search).then(response => {
      if (response.problem != null) {
        getTeamTagsFail(dispatch, response.data.error);
      } else if (response.data.error != false) {
        getTeamTagsFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({ type: IS_LOADING, payload: false });
        getTeamTagsSuccess(dispatch, response.data.results);
      }
    });
  };
};

export const getPropertyTags = (token, property_data_id) => {
  return dispatch => {
    dispatch({ type: GET_PROPERTY_TAGS });
    api.getPropertyTags(token, property_data_id).then(response => {
      if (response.problem != null) {
      } else if (response.data.error != false) {
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({ type: IS_LOADING, payload: false });
        getPropertyTagsSuccess(dispatch, response.data.results);
      }
    });
  };
};
const getPropertyTagsSuccess = (dispatch, results) => {
  dispatch({
    type: GET_PROPERTY_TAGS_SUCCESS,
    payload: results
  });
};

export const createTeamTag = ({
  token,
  title = "",
  type = "create",
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    api.updateTags(token, { title }, type).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        createTeamTagFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        createTeamTagFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (onSuccess) {
          onSuccess(response.data.results);
        }
        createTeamTagSuccess(dispatch, response.data.results, onSuccess);
      }
    });
  };
};

export const updateTeamTagOrder = ({
  token,
  tag_order,
  type = "update_tag_order",
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    api.updateTags(token, { tag_order }, type).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        updateTeamTagOrderFail(dispatch, response.problem);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        updateTeamTagOrderFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (onSuccess) {
          onSuccess(response.data.results);
        } else {
          dispatch({ type: IS_LOADING, payload: false });
        }
        updateTeamTagOrderSuccess(dispatch, response.data.results);
      }
    });
  };
};

export const removeTeamTag = ({
  token,
  tag_id,
  type = "remove",
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    api.updateTags(token, { tag_id }, type).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        removeTeamTagFail(dispatch, response.data.error);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        removeTeamTagFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (onSuccess) {
          onSuccess(response.data.results);
        } else {
          dispatch({ type: IS_LOADING, payload: false });
        }
        removeTeamTagSuccess(dispatch, response.data.results);
      }
    });
  };
};

export const hideDefaultTag = ({
  token,
  tag_id,
  type = "hide",
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    api.updateTags(token, { tag_id }, type).then(response => {
      if (response.problem != null) {
        if (onError) {
          onError();
        }
        hideDefaultTagFail(dispatch, response.data.error);
      } else if (response.data.error != false) {
        if (onError) {
          onError();
        }
        hideDefaultTagFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        if (onSuccess) {
          onSuccess(response.data.results);
        } else {
          dispatch({ type: IS_LOADING, payload: false });
        }
        hideDefaultTagSuccess(dispatch, response.data.results);
      }
    });
  };
};

export const showDefaultTag = ({ token, tag_id, type = "show" }) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });

    api.updateTags(token, { tag_id }, type).then(response => {
      if (response.problem != null) {
        showDefaultTagFail(dispatch, response.data.error);
      } else if (response.data.error != false) {
        showDefaultTagFail(dispatch, response.data.error);
        if (response.data.valid == "invalid") {
          dispatch({ type: TRIGGER_LOGOUT, payload: true });
        }
      } else {
        dispatch({ type: IS_LOADING, payload: false });
        showDefaultTagSuccess(dispatch, response.data.results);
      }
    });
  };
};

const createTeamTagSuccess = (dispatch, results, onSuccess = null) => {
  dispatch({
    type: CREATE_TAG_SUCCESS,
    payload: { tags: results.tags }
  });

  if (!onSuccess) {
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: { message: "Tag was successfully created.", title: "Success!" }
    });
    appRedirect({
      dispatch,
      redirect: "goBack",
      payload: { remove: "create-tag" }
    });
  }
  //appRedirect({dispatch, redirect: "goBack", payload: {type: "tags"}});
};

const createTeamTagFail = (dispatch, error) => {
  dispatch({
    type: CREATE_TAG_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const updateTeamTagOrderSuccess = (dispatch, results) => {
  dispatch({
    type: UPDATE_TEAM_TAG_ORDER_SUCCESS,
    payload: { tags: results.tags }
  });
};

const updateTeamTagOrderFail = (dispatch, error) => {
  dispatch({
    type: UPDATE_TEAM_TAG_ORDER_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const removeTeamTagSuccess = (dispatch, results) => {
  dispatch({
    type: REMOVE_TAG_SUCCESS,
    payload: { tags: results.tags }
  });
};

const removeTeamTagFail = (dispatch, error) => {
  dispatch({
    type: REMOVE_TAG_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const hideDefaultTagSuccess = (dispatch, results) => {
  dispatch({
    type: HIDE_TAG_SUCCESS,
    payload: { tags: results.tags }
  });
};

const hideDefaultTagFail = (dispatch, error) => {
  dispatch({
    type: HIDE_TAG_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const showDefaultTagSuccess = (dispatch, results) => {
  dispatch({
    type: SHOW_TAG_SUCCESS,
    payload: { tags: results.tags }
  });
  dispatch({
    type: SUCCESS_MESSAGE,
    payload: {
      message: "The default tag is now available to use.",
      title: "Success!"
    }
  });
};

const showDefaultTagFail = (dispatch, error) => {
  dispatch({
    type: SHOW_TAG_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const getTeamTagsSuccess = (dispatch, results) => {
  dispatch({
    type: GET_TAGS_SUCCESS,
    payload: { tags: results.tags }
  });
};

const getTeamTagsFail = (dispatch, error) => {
  dispatch({
    type: GET_TAGS_FAIL,
    payload: { error }
  });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};
