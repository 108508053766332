import React, { Component } from "react";

import { Paragraph } from "app/NativeComponents/website/common";

const FeatureSubtitle = ({ feature }) => {
  if (feature.subTitle) {
    return <Paragraph>{feature.subTitle}</Paragraph>;
  }

  return <div />;
};

export default FeatureSubtitle;
