import React, { Component } from "react";

import {
  Wrapper,
  Spin,
  Button,
  Row,
  Icon,
  Copy
} from "app/NativeComponents/common";

class InfiniteScroll extends Component {
  render() {
    const {
      items,
      items_loading,
      loaded_all_items = false,
      loading_more_text
    } = this.props;
    if (items.length > 0 && loaded_all_items == false) {
      if (items_loading) {
        return (
          <Wrapper
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spin text={loading_more_text} />
          </Wrapper>
        );
      } else {
        return (
          <Button
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center"
            }}
            onPress={() => this.props.onLoadMore()}
          >
            <Row>
              <Icon size={18} icon="refresh" style={{ marginRight: 5 }} />
              <Copy>Load More</Copy>
            </Row>
          </Button>
        );
      }
    }

    return null;
  }
}

export default InfiniteScroll;
