import React, { Component } from "react";
import { connect } from "react-redux";

import {
  updateUser,
  popSidePanel,
  replaceSidePanel,
  appRedirect,
  logout,
  showErrorMessage,
  getStripePriceInformation,
  billingFieldChanged,
  stripeCardAction,
  manageSubscription
} from "app/NativeActions";
import PayloadBody from "./PayloadBody";

class IAPPaywall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upsell_loading: false,
      customPayload: null,
      selected_product: null,
      interval_toggle: "month"
    };

    this.handleBack = this.handleBack.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.updateInterval = this.updateInterval.bind(this);

    this.saveCard = this.saveCard.bind(this);
  }

  componentDidMount() {
    const slug = !!this.props.upsell_adapty_slug
      ? this.props.upsell_adapty_slug
      : "main_plan_paywall";

    this.props.getStripePriceInformation({
      token: this.props.token,
      type: "iap_paywall",
      slug,
      onLoading: () => {
        this.setState({ upsell_loading: true });
      },
      onError: () => {
        this.setState({ upsell_loading: false });
      },
      onSuccess: results => {
        const options = results?.paywall_info?.payload?.options;

        if (options) {
          const selected_product = options.find(p => p.selected === true);

          this.setState({
            upsell_loading: false,
            customPayload: results?.paywall_info?.payload,
            selected_product
          });
        }
      }
    });
  }
  selectProduct(selected_product) {
    this.setState({ selected_product });
  }

  updateInterval(interval_toggle) {
    this.setState({ interval_toggle });
  }

  componentDidUpdate(prevProps) {}
  handleBack() {
    this.props.popSidePanel();
  }

  saveCard(card_token, error) {
    const { token, card, platform } = this.props;

    this.setState({ upsell_loading: true }, () => {
      if (this.props.source_of_truth?.has_stripe_card_on_file) {
        if (this.props.source_of_truth?.subscription?.stripe_id) {
          const stripe_price_ids =
            this.state.interval_toggle == "year"
              ? this.state.selected_product?.stripe_price_ids_yearly.split(",")
              : this.state.selected_product?.stripe_price_ids_monthly.split(
                  ","
                );
          this.props.manageSubscription({
            token: this.props.token,
            type: "add_to_subscription",
            stripe_price_id: stripe_price_ids[0],
            onLoading: () => {
              this.setState({
                upsell_loading: true
              });
            },

            onError: () => {
              this.setState({
                upsell_loading: false
              });
            },
            onSuccess: () => {
              this.setState(
                {
                  upsell_loading: false
                },
                () => {
                  this.handleBack();
                }
              );
            }
          });
        } else {
          this.props.manageSubscription({
            token: this.props.token,
            type: "start_trial_from_stripe_id",
            stripe_price_ids:
              this.state.interval_toggle == "year"
                ? this.state.selected_product?.stripe_price_ids_yearly
                : this.state.selected_product?.stripe_price_ids_monthly,
            onLoading: () => {
              this.setState({
                upsell_loading: true
              });
            },

            onError: () => {
              this.setState({
                upsell_loading: false
              });
            },
            onSuccess: () => {
              this.setState(
                {
                  upsell_loading: false
                },
                () => {
                  this.props.replaceSidePanel({
                    slug: "stripe_upgrades",
                    overlay: true,
                    data: {
                      selected_product: this.state.selected_product,
                      interval_toggle: this.state.interval_toggle
                    }
                  });
                }
              );
            }
          });
        }
      } else {
        if (card_token || error) {
          this.props.stripeCardAction({
            token: token,
            card_token: card_token,
            error: error,
            stripe_price_ids:
              this.state.interval_toggle == "year"
                ? this.state.selected_product?.stripe_price_ids_yearly
                : this.state.selected_product?.stripe_price_ids_monthly,
            type: "start_trial_from_stripe_id",
            onLoading: () => {
              this.setState({ upsell_loading: true });
            },
            onError: () => {
              this.props.showErrorMessage(error, "Error");

              this.setState({ upsell_loading: false });
            },
            onSuccess: () => {
              this.setState({ upsell_loading: false }, () => {
                this.props.replaceSidePanel({
                  slug: "stripe_upgrades",
                  overlay: true,
                  data: {
                    selected_product: this.state.selected_product,
                    interval_toggle: this.state.interval_toggle
                  }
                });
              });
            }
          });
        } else {
          const { number, expMonth, expYear, cvc } = card;
          this.props.stripeCardAction({
            token: token,
            number,
            expMonth,
            expYear,
            cvc,
            stripe_price_ids:
              this.state.interval_toggle == "year"
                ? this.state.selected_product?.stripe_price_ids_yearly
                : this.state.selected_product?.stripe_price_ids_monthly,
            type: "start_trial_from_stripe_id",
            onLoading: () => {
              this.setState({ upsell_loading: true });
            },
            onError: error => {
              this.props.showErrorMessage(error, "Error");
              this.setState({ upsell_loading: false });
            },
            onSuccess: results => {
              this.setState({ upsell_loading: false });

              this.setState({ upsell_loading: false }, () => {
                this.props.replaceSidePanel({
                  slug: "stripe_upgrades",
                  overlay: true,
                  data: {
                    selected_product: this.state.selected_product,
                    interval_toggle: this.state.interval_toggle
                  }
                });
              });
            }
          });
        }
      }
    });
  }
  render() {
    const { customPayload } = this.state;

    return (
      <PayloadBody
        customPayload={customPayload}
        upsell_loading={this.state.upsell_loading}
        selectProduct={this.selectProduct}
        selected_product={this.state.selected_product}
        interval_toggle={this.state.interval_toggle}
        updateInterval={this.updateInterval}
        creditCard={true}
        card={this.props.card}
        onPurchase={this.saveCard}
        billingFieldChanged={this.props.billingFieldChanged}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { card, source_of_truth } = billing;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    card,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateUser,
  appRedirect,
  popSidePanel,
  replaceSidePanel,
  logout,
  showErrorMessage,
  getStripePriceInformation,
  billingFieldChanged,
  stripeCardAction,
  manageSubscription
})(IAPPaywall);
