import React, { Component } from "react";

const Markdown = require("react-markdown");

const CardDescription = ({ card }) => {
  if (card.description) {
    return <Markdown source={card.description} escapeHtml={false} />;
  }

  return <div />;
};

export default CardDescription;
