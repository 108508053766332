import React, { Component } from "react";
import { Link } from "react-router-dom";

import NavBar from "./NavBar";
import DropDown from "./DropDown";

class HeaderNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOpen: false,
      isTop: true,
      isMobile: false,
      includeButtons: true,
      clickableLogo: true,
      navBarStyle: "dark",
      landingPage: false
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.onscroll = () => {
      // const isTop = window.pageYOffset < 1 || document.documentElement.scrollTop < 1;
      const isTop =
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) < 1;

      if (isTop != this.state.isTop) {
        this.onScroll(isTop);
      }
    };
    this.checkWindowSize();
    window.onresize = () => {
      this.checkWindowSize();
    };

    switch (this.props.navBarStyle) {
      case "DarkHeader":
      default:
        this.setState({ navBarStyle: "dark" });
        this.setState({ includeButtons: true });
        this.setState({ clickableLogo: true });
        this.setState({ landingPage: false });

        break;

      case "WhiteHeader":
        this.setState({ navBarStyle: "white" });
        this.setState({ includeButtons: true });
        this.setState({ clickableLogo: true });
        this.setState({ landingPage: false });

        break;

      case "LandingPageDark":
        this.setState({ navBarStyle: "dark" });
        this.setState({ includeButtons: false });
        this.setState({ clickableLogo: false });
        this.setState({ landingPage: true });
        break;

      case "LandingPageDarkClickable":
        this.setState({ navBarStyle: "dark" });
        this.setState({ includeButtons: false });
        this.setState({ clickableLogo: true });
        this.setState({ landingPage: true });
        break;

      case "LandingPageWhite":
        this.setState({ navBarStyle: "white" });
        this.setState({ includeButtons: false });
        this.setState({ clickableLogo: false });
        this.setState({ landingPage: true });

        break;
      case "LandingPageWhiteClickable":
        this.setState({ navBarStyle: "white" });
        this.setState({ includeButtons: false });
        this.setState({ clickableLogo: true });
        this.setState({ landingPage: true });

        break;
    }
  }

  checkWindowSize() {
    if (window.innerWidth > 767) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
      this.setState({ dropDownOpen: false });
    }
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  dropdownToggleClickHandler = () => {
    this.setState(prevState => {
      return { dropDownOpen: !prevState.dropDownOpen };
    });
  };

  navClass = () => {
    var navbarClass = "dm-website-navbar";

    if (this.state.landingPage) {
      navbarClass += " dm-website-navbar-landing-page";
    }
    if (this.state.navBarStyle == "dark") {
      navbarClass += " dm-website-navbar-dark";
    } else if (this.state.navBarStyle == "white") {
      navbarClass += " dm-website-navbar-white";
    }

    if (this.state.dropDownOpen) {
      return navbarClass + " dm-website-navbar-dropdown-open";
    } else if (!this.state.isTop && !this.state.dropDownOpen) {
      return (
        navbarClass + " dm-website-navbar dm-website-navbar-scroll shadow "
      );
    } else {
      return navbarClass;
    }
  };

  render() {
    let dropDown;

    if (this.state.dropDownOpen && this.state.includeButtons) {
      dropDown = <DropDown show={this.state.dropDownOpen} />;
    } else {
      dropDown = <div />;
    }

    return (
      <div className={this.navClass()}>
        <NavBar
          clickableLogo={this.state.clickableLogo}
          includeButtons={this.state.includeButtons}
          dropDownClickHandler={this.dropdownToggleClickHandler}
          show={this.state.dropDownOpen}
          isTop={this.state.isTop}
          dropDownOpen={this.state.dropDownOpen}
          isMobile={this.state.isMobile}
          navBarStyle={this.state.navBarStyle}
        />
        {dropDown}
      </div>
    );
  }
}

export { HeaderNavigation };
