import React from "react";

const Scroll = ({
  id,
  className,
  scroll_ref,
  children,
  horizontal,
  style,
  onScroll = () => {}
}) => {
  if (horizontal) {
    return (
      <div
        id={id}
        className={" deal-scroll-horizontal " + className}
        style={style}
        ref={scroll_ref}
        onScroll={e => {
          if (onScroll) {
            onScroll(e);
          }
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      id={id}
      className={" deal-scroll " + className}
      style={style}
      ref={scroll_ref}
      onScroll={e => {
        if (onScroll) {
          onScroll(e);
        }
      }}
    >
      {children}
    </div>
  );
};

export { Scroll };
