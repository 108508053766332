import React, { Component } from "react";
import {
  Wrapper,
  Card,
  CardBody,
  Row,
  Button,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";

class SideTabs extends Component {
  render() {
    const { side_tab, colors, toggleSide, template } = this.props;
    return (
      <Wrapper style={{ paddingTop: 20 }}>
        <Card style={{ overflow: "hidden" }}>
          <Row>
            <Button
              onPress={() => toggleSide("front")}
              style={{
                alignItems: "center",
                justifyContent: "center",

                backgroundColor:
                  side_tab == "front" ? colors.card_color : colors.gray_color,
                borderRightWidth: 1,
                borderRightColor: colors.border_color,
                borderRigthStyle: "solid",
                width: 150
              }}
            >
              <CardBody style={{ padding: 10 }}>
                <Row>
                  <Copy>
                    <Bold>
                      {template.template_type == "handwritten"
                        ? "Letter"
                        : "Front"}
                    </Bold>
                  </Copy>
                </Row>
              </CardBody>
            </Button>

            <Button
              onPress={() => toggleSide("back")}
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  side_tab == "back" ? colors.card_color : colors.gray_color,
                width: 150
              }}
            >
              <CardBody style={{ padding: 10 }}>
                <Row>
                  <Copy>
                    <Bold>
                      {template.template_type == "handwritten"
                        ? "Envelope"
                        : "Back"}
                    </Bold>
                  </Copy>
                </Row>
              </CardBody>
            </Button>
          </Row>
        </Card>
      </Wrapper>
    );
  }
}

export default SideTabs;
