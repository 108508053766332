import React, { Component } from "react";
import { connect } from "react-redux";

import { Card } from "app/NativeComponents/common";
import { Wrapper, Row, Copy, Bold, Icon } from "app/NativeComponents/common";
import {
  SelectItem,
  IconButton,
  Carousel,
  SmallLabel
} from "app/NativeComponents/snippets";

import PersonItem from "app/DealMachineCore/components/Property/OwnerInfo/PersonItem";

import {
  pushSidePanel,
  toTitleCase,
  getHelperDescription
} from "app/NativeActions";

class CompanyPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  componentWillUnmount() {
    clearInterval(this._interval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { isMobile, device, colors, officers } = this.props;

    if (officers && Object.keys(officers).length > 0) {
      return (
        <>
          <Card
            style={{ paddingTop: 10, marginTop: 15 }}
            label={"People (" + Object.keys(officers).length + ")"}
            id={"company-people"}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                offset: y,
                height: height
              });
            }}
          >
            {Object.keys(officers).map((key, index) => {
              if (officers[key].length === 1) {
                const officer = officers[key][0];
                if (officer?.is_tracing_match && officer?.contact) {
                  return (
                    <PersonItem
                      no_border={true}
                      person={officer.contact}
                      expanded={this.props.expanded}
                      no_options={false}
                      use_person_flags={false}
                      property={this.props.property}
                      propertyUpdated={this.props.propertyUpdated}
                      include_description={true}
                      description={toTitleCase(officer?.position)}
                      renderDescription={
                        !!officer?.position
                          ? () => {
                              return (
                                <Row>
                                  <Copy>{toTitleCase(officer?.position)}</Copy>

                                  {device === "desktop" ? (
                                    <Wrapper
                                      tooltipPlacement={"top"}
                                      tooltip={getHelperDescription(
                                        "position_" +
                                          officer?.position.toLowerCase()
                                      )}
                                      style={{ marginLeft: 5 }}
                                    >
                                      <Icon
                                        icon={"help"}
                                        color={colors.light_text_color}
                                        size={this.props.round ? 12 : 12}
                                      />
                                    </Wrapper>
                                  ) : null}
                                </Row>
                              );
                            }
                          : null
                      }
                    />
                  );
                } else if (officer?.is_company_match) {
                  return (
                    <SelectItem
                      select_type={"none"}
                      pressedIn={false}
                      onPress={() => {
                        this.props.pushSidePanel({
                          slug: "company",
                          expanded_side_panel: this.props.expanded,
                          overlay: true,
                          id:
                            officer?.matched_company_number +
                            "+" +
                            officer?.matched_company_jurisdiction_code,
                          data: {
                            property: this.props.property,
                            expanded: this.props.expanded,
                            propertyUpdated: this.props.propertyUpdated
                          }
                        });
                      }}
                      label={""}
                      subtext={""}
                      selected={true}
                      style={() => {
                        return (
                          <Row>
                            <IconButton
                              button_type={"small"}
                              icon={"keyboard-arrow-right"}
                              noPress={true}
                            />
                          </Row>
                        );
                      }}
                      innerStyle={{ paddingLeft: 25, paddingRight: 5 }}
                      renderContent={() => {
                        return (
                          <Row style={{ flex: 1 }}>
                            <Wrapper style={{ flex: 1 }}>
                              <Row>
                                <Wrapper style={{ flex: 1 }}>
                                  <Copy
                                    style={{
                                      color: colors.actionable_text_color
                                    }}
                                  >
                                    <Bold>
                                      {toTitleCase(
                                        officer?.matched_company_name
                                      )}
                                    </Bold>
                                  </Copy>
                                  <Copy>{toTitleCase(officer?.position)}</Copy>
                                </Wrapper>

                                <IconButton
                                  button_type={"small"}
                                  icon={"keyboard-arrow-right"}
                                  noPress={true}
                                />
                              </Row>
                            </Wrapper>
                          </Row>
                        );
                      }}
                    />
                  );
                } else {
                  return (
                    <SelectItem
                      select_type={"none"}
                      pressedIn={false}
                      noPress={true}
                      label={""}
                      subtext={""}
                      selected={true}
                      innerStyle={{ paddingLeft: 25, paddingRight: 5 }}
                      renderContent={() => {
                        return (
                          <Row style={{ flex: 1 }}>
                            <Wrapper style={{ flex: 1 }}>
                              <Row>
                                <Wrapper style={{ flex: 1 }}>
                                  <Copy
                                    style={{
                                      color: colors.actionable_text_color
                                    }}
                                  >
                                    <Bold>{toTitleCase(officer?.name)}</Bold>
                                  </Copy>
                                  <Copy>{toTitleCase(officer?.position)}</Copy>
                                </Wrapper>
                              </Row>
                            </Wrapper>
                          </Row>
                        );
                      }}
                    />
                  );
                }
              } else {
                const first_officer = officers[key][0];
                return (
                  <>
                    <SmallLabel>
                      Possible matches for {first_officer.name} (
                      {officers[key].length})
                    </SmallLabel>
                    <Wrapper
                      style={{
                        marginBottom: this.props.device === "desktop" ? 40 : 0,
                        marginLeft: this.props.device === "mobile" ? -10 : 0,
                        marginRight: this.props.device === "mobile" ? -10 : 0,
                        justifyContent: "center"
                      }}
                      className={"contact-people " + this.props.dark_mode}
                    >
                      <Carousel
                        firstItem={0}
                        items={officers[key]}
                        slidesToShow={1}
                        centerMode={true}
                        infinite={true}
                        dots={officers[key].length < 7 ? true : false}
                        centerPadding={this.props.expanded ? "20px" : "20px"}
                        itemWidth={this.props.device === "desktop" ? 320 : 320}
                        onSwipe={() => {}}
                        renderItem={obj => {
                          const person = obj?.item?.contact;
                          return (
                            <PersonItem
                              person={person}
                              no_border={false}
                              property={this.props.property}
                              expanded={this.props.expanded}
                              use_linking_flags={false}
                              propertyUpdated={this.props.propertyUpdated}
                              display_warning={false}
                              include_description={true}
                              description={toTitleCase(obj?.item?.position)}
                            />
                          );
                        }}
                        include_pagination={true}
                        dotColor={colors.text_color}
                      />
                    </Wrapper>
                  </>
                );
              }
            })}
          </Card>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, { pushSidePanel })(CompanyPeople);
