import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  SelectBox,
  SelectItem,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineDisplayProperty
} from "app/NativeActions";

class EditPropertyAddressDisplay extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    const display_property = determineDisplayProperty(panel_data?.property);

    this.state = {
      address: display_property?.property_address || "",
      address2:
        !!display_property?.property_address2 &&
        !display_property?.property_address.includes(
          display_property?.property_address2
        )
          ? display_property?.property_address2
          : "",
      city: display_property?.property_address_city || "",
      state: display_property?.property_address_state || "",
      zip: display_property?.property_address_zip || "",
      state_focus: false,
      loading: false,
      panel_data
    };

    this._address_input = React.createRef();
    this._address2_input = React.createRef();
    this._city_input = React.createRef();
    this._state_input = React.createRef();
    this._zip_input = React.createRef();

    this.savePropertyAddressDisplay =
      this.savePropertyAddressDisplay.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }
  checkIfNeedsToSave() {
    const display_property = determineDisplayProperty(
      this.state.panel_data?.property
    );
    if (
      !!this.state.address &&
      !!this.state.city &&
      !!this.state.state &&
      !!this.state.zip &&
      (this.state.address != display_property?.property_address ||
        this.state.address2 != display_property?.property_address2 ||
        this.state.city != display_property?.property_address_city ||
        this.state.state != display_property?.property_address_state ||
        this.state.zip != display_property?.property_address_zip)
    ) {
      return true;
    }

    return false;
  }

  savePropertyAddressDisplay() {
    const {
      panel_data: { property }
    } = this.state;

    const { address, address2, city, state, zip } = this.state;

    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "edit_property_address",
      address: address,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      panel_data: { property, mailing_address }
    } = this.state;
    const { address, address2, city, state, zip, loading } = this.state;

    let state_data = [];
    for (let i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    return (
      <Container>
        <NewHeader
          title="Edit Property Address Display"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            autoFocus={true}
            input_ref={this._address_input}
            name="address"
            disabled={loading}
            returnKeyType="next"
            placeholder="Address"
            onChange={value => {
              this.setState({
                address: value
              });
            }}
            onSubmitEditing={() => {
              this._address2_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address}
            type="text"
          />

          <InputBox
            input_ref={this._address2_input}
            name="address2"
            disabled={loading}
            returnKeyType="next"
            placeholder="Address Line 2"
            onChange={value => {
              this.setState({
                address2: value
              });
            }}
            onSubmitEditing={() => {
              this._city_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address2}
            type="text"
          />

          <InputBox
            input_ref={this._city_input}
            name="city"
            disabled={loading}
            returnKeyType="next"
            autoCapitalize="words"
            keyboardType="default"
            placeholder="City"
            onChange={value => {
              this.setState({
                city: value
              });
            }}
            blurOnSubmit={true}
            onSubmitEditing={() => {
              this.setState({ state_focus: true });
            }}
            value={city}
            type="text"
          />
          <SelectBox
            select_ref={this._state_input}
            items={state_data}
            disabled={loading}
            value={state}
            placeholder="State"
            show={this.state.state_focus}
            onFocus={() => {
              this.setState({ state_focus: true });
            }}
            onBlur={() => {
              this.setState({ state_focus: false });
            }}
            onSelect={item => {
              this.setState({
                state: item
              });
            }}
            onSubmitEditing={() => {
              this._zip_input.current.focus();
            }}
          />

          <InputBox
            input_ref={this._zip_input}
            name="zip"
            disabled={loading}
            returnKeyType="done"
            keyboardType="numeric"
            placeholder="Zip Code"
            onChange={value => {
              this.setState({
                zip: value
              });
            }}
            onSubmitEditing={this.savePropertyAddressDisplay}
            blurOnSubmit={false}
            value={zip}
            type="number"
            maxLength={"5"}
          />

          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.savePropertyAddressDisplay}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Save Property Address Display
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { states, colors } = settings;

  return { token, user, device, states, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(EditPropertyAddressDisplay);
