import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Row, Scroll } from "app/NativeComponents/common";

import { SmallLabel, Tag, InlineButton } from "app/NativeComponents/snippets";

import { updateLead, getTags } from "app/NativeActions";

class Tags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_options: props.property?.deal?.tags || [],
      loading_options: [],
      show_all_tags: false
    };
  }

  componentDidMount() {
    if (this.props.all_tags.length == 0) {
      this.props.getTags(this.props.token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { property, all_tags, tags_loading } = this.props;

    if (
      property?.property_id !== prevProps.property?.property_id ||
      (property?.deal?.tags !== prevProps.property?.deal?.tags &&
        this.state.loading_options.length == 0) ||
      (this.state.show && prevState.show !== this.state.show)
    ) {
      this.setState({
        selected_options:
          property && property.deal && property.deal.tags
            ? property.deal.tags
            : [],
        loading_options: []
      });
    }
  }

  onRemoveOption(option) {
    const {
      property: { deal }
    } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "remove_tags_from_leads",
      deal_ids: deal.id,
      tag_ids: option.id,
      no_loading: true,
      onLoading: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: selected_options.filter(
            ({ id }) => id !== option.id
          ),
          loading_options: [...loading_options, option]
        });
      },
      onSuccess: results => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });

        this.props.propertyUpdated(results.property);
      },
      onError: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: [...selected_options, option],
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  onAddOption(option) {
    const {
      property: { deal }
    } = this.props;

    this.props.updateLead({
      token: this.props.token,
      type: "add_tags_to_leads",
      deal_ids: deal.id,
      tag_ids: option.id,
      no_loading: true,
      onLoading: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: [...selected_options, option],
          loading_options: [...loading_options, option]
        });
      },
      onSuccess: results => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });

        this.props.propertyUpdated(results.property);
      },
      onError: () => {
        const { selected_options, loading_options } = this.state;

        this.setState({
          selected_options: selected_options.filter(
            ({ id }) => id !== option.id
          ),
          loading_options: loading_options.filter(({ id }) => id !== option.id)
        });
      }
    });
  }

  render() {
    const { colors, all_tags, style, property } = this.props;
    const { selected_options, loading_options, show_all_tags } = this.state;
    if (all_tags.length > 0) {
      let temp_all_tags = [];
      const max_length =
        all_tags?.length > 10 && !show_all_tags ? 10 : all_tags?.length;
      for (let i = 0; i < max_length; i++) {
        temp_all_tags.push(all_tags[i]);
      }

      return (
        <>
          <Wrapper
            style={{
              padding: 20,
              paddingTop: 15,
              paddingBottom: 15,
              ...style
            }}
          >
            <SmallLabel style={{ paddingRight: 0, paddingLeft: 0 }}>
              Add tags to your lead:
            </SmallLabel>
            <Scroll style={{ maxHeight: 500 }}>
              <Row style={{ flexWrap: "wrap" }}>
                {temp_all_tags.map((tag, i) => {
                  if (tag.hidden_tag != 1) {
                    let in_selected_options = false;
                    for (let i = 0; i < selected_options.length; i++) {
                      if (tag.value == selected_options[i].value) {
                        in_selected_options = true;
                      }
                    }

                    let in_loading_options = false;
                    for (let i = 0; i < loading_options.length; i++) {
                      if (tag.value == loading_options[i].value) {
                        in_loading_options = true;
                      }
                    }

                    return (
                      <Tag
                        key={"tag_" + i}
                        //loading={in_loading_options}
                        selected={in_selected_options}
                        onPress={() => {
                          if (!in_loading_options && property) {
                            if (in_selected_options) {
                              this.onRemoveOption(tag);
                            } else {
                              this.onAddOption(tag);
                            }
                          }
                        }}
                      >
                        {tag.title}
                      </Tag>
                    );
                  }
                  return null;
                })}
                {!show_all_tags &&
                temp_all_tags?.length < all_tags?.length &&
                all_tags.length > 0 ? (
                  <Tag
                    style={{
                      backgroundColor: colors.card_color,
                      borderColor: "transparent",
                      borderWidth: 1,
                      borderStyle: "solid"
                    }}
                    onPress={() => {
                      this.setState({ show_all_tags: true });
                    }}
                  >
                    Show All Tags
                  </Tag>
                ) : null}
              </Row>
            </Scroll>
          </Wrapper>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, deal }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { all_tags } = deal;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    all_tags
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getTags
})(Tags);
