import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import { GhostButton, SelectItem } from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import QuestionSelect from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/QuestionSelect";

import { numberWithCommas, startRedirect, updateUser } from "app/NativeActions";
class HowManyDealsQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_option: null
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/how-many-deals-have-you-done");
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>How many deals have you done?</Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            <Copy
              style={{
                textAlign: "center"
              }}
            >
              Select an option below and click continue.
            </Copy>
          </Wrapper>
        </Wrapper>
        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <Row
              style={
                this.props.device == "mobile"
                  ? {
                      padding: 10,
                      alignSelf: "stretch",
                      flexDirection: "column"
                    }
                  : this.props.desktopMobile
                  ? {
                      padding: 10,
                      alignSelf: "stretch",
                      flexDirection: "column"
                    }
                  : {
                      flexWrap: "wrap",
                      padding: 25,
                      justifyContent: "center"
                    }
              }
            >
              <QuestionSelect
                slug={"I'm looking for my first deal"}
                selected_option={this.state.selected_option}
                onPress={() => {
                  this.setState({
                    selected_option: "I'm looking for my first deal"
                  });
                }}
                title={"I'm looking for my first deal."}
              />
              <QuestionSelect
                slug={"I've done 1-10 deals"}
                selected_option={this.state.selected_option}
                onPress={() => {
                  this.setState({ selected_option: "I've done 1-10 deals" });
                }}
                title={"I've done 1-10 deals"}
              />
              <QuestionSelect
                slug={"I've done more than 10 deals"}
                selected_option={this.state.selected_option}
                onPress={() => {
                  this.setState({
                    selected_option: "I've done more than 10 deals"
                  });
                }}
                title={"I've done more than 10 deals"}
              />
            </Row>
            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              disabled={!this.state.selected_option}
              icon_right={"arrow-right-alt"}
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "how_many_deals_have_you_done",
                  property_value: this.state.selected_option
                });
                this.props.nextStep();
              }}
              loading={this.props.next_step_loading}
            >
              Continue
            </GhostButton>
          </Wrapper>
          <Wrapper style={{ height: 40 }} />
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, desktopMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    desktopMobile,
    device,
    initialRedirect
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser
})(HowManyDealsQuestion);
