import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Copy,
  InnerCopy,
  Bold
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  SelectItem,
  SpinWrapper,
  List,
  IconButton,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  getLeadActivity,
  selectActiveTask,
  completeTask,
  renderDate,
  openUrl,
  openEmail,
  callPhoneNumber,
  formatAddress,
  formatUsPhone
} from "app/NativeActions";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks_loading: []
    };
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.property?.deal?.id !== prevProps?.property?.deal?.id) {
      this.getData({ load_type: "load" });
    } else if (
      JSON.stringify(this.props.property) !== JSON.stringify(prevProps.property)
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.props;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title?.toLowerCase().indexOf(search?.toLowerCase()) != -1 ||
        options[i].description?.toLowerCase().indexOf(search?.toLowerCase()) !=
          -1 ||
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  renderHyperlinks(desc) {
    const RE_URL = /\w+:\/\/\S+/gm;
    const RE_PHONE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\0-9]*$/gm;
    const RE_EMAIL = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/gm;
    const RE_ALL =
      /(\w+:\/\/\S+|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\0-9]*$|[a-z0-9]+@[a-z]+\.[a-z]{2,3})/gm;
    const { colors } = this.props;
    let match;
    const results = [];
    let lastIndex = 0;
    while ((match = RE_ALL.exec(desc))) {
      const link = match[0];
      if (lastIndex !== match.index) {
        const text = desc.substring(lastIndex, match.index);
        results.push(<InnerCopy key={results.length}>{text}</InnerCopy>);
      }

      results.push(
        <InnerCopy
          key={results.length}
          style={{ color: colors.active_color, textDecoration: "underline" }}
          onPress={() => {
            if (link.match(RE_PHONE)) {
              callPhoneNumber(link);
            } else if (link.match(RE_EMAIL)) {
              openEmail({ email: link });
            } else {
              openUrl(link);
            }
          }}
        >
          {link}
        </InnerCopy>
      );
      lastIndex = match.index + link.length;
    }
    if (results.length === 0) {
      return desc;
    }
    if (lastIndex !== desc.length) {
      results.push(
        <InnerCopy key={results.length}>{desc.substring(lastIndex)}</InnerCopy>
      );
    }

    return results;
  }

  getData({ load_type = "load" }) {
    this.props.getLeadActivity({
      token: this.props.token,
      lead_id: this.props.property?.deal?.id,
      onLoading: () => {
        if (load_type == "load") {
          this.props.updateMainState({
            lead_activity_loading: true,
            lead_activity_loading_id: this.props.property?.deal?.id
          });
        } else {
          this.props.updateMainState({
            lead_activity_loading_id: this.props.property?.deal?.id
          });
        }
      },
      onError: () => {
        if (
          this.props.property?.deal?.id == this.props.lead_activity_loading_id
        ) {
          this.props.updateMainState({
            lead_activity_loading: false,
            lead_activity_loading_id: null
          });
        }
      },
      onSuccess: results => {
        if (
          this.props.property?.deal?.id == this.props.lead_activity_loading_id
        ) {
          this.props.updateMainState({
            lead_activity_loading: false,
            lead_activity_loading_id: null,
            activity: results.activity
          });
        }
      }
    });
  }

  render() {
    const { colors, isMobile, device, property } = this.props;

    return (
      <>
        {this.props.lead_activity_loading ? (
          <SpinWrapper text="Loading Activity" />
        ) : (
          <List
            rowNumber={1}
            style={{ flex: 1 }}
            items={this.filterOptions(this.props.activity)}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              let noPress = true;
              let renderRight = null;
              let renderBottom = null;
              let icon = "";

              let select_type = "none";
              let renderCustomSelect = null;

              let description = item.description;

              switch (item.activity_type) {
                default:
                  break;

                case "call_log":
                  icon = "keyboard-arrow-right";

                  noPress = false;

                  break;

                case "note":
                  icon = "keyboard-arrow-right";
                  description = "";

                  if (
                    this.props.user?.id == item?.user?.id ||
                    this.props.user?.team_clearance_level > 0
                  ) {
                    noPress = false;
                  }

                  if (!!item.description) {
                    const files = JSON.parse(item.description);
                    if (files.length > 0) {
                      renderBottom = () => {
                        return (
                          <Wrapper
                            style={{
                              backgroundColor: colors.background_color,
                              borderRadius: 5,
                              margin: 5
                            }}
                          >
                            <SmallLabel>Attached Files:</SmallLabel>
                            {files &&
                              files.length > 0 &&
                              files.map((file, i) => {
                                return (
                                  <SelectItem
                                    key={"file_" + i}
                                    innerStyle={{
                                      paddingTop: 10,
                                      paddingBottom: 10,
                                      paddingRight: 20,
                                      paddingLeft: 20
                                    }}
                                    select_type={"icon"}
                                    select_icon="description"
                                    onPress={() => {
                                      openUrl(file.file);
                                    }}
                                    style={{}}
                                    hover_icon={"launch"}
                                    hover_icon_color={colors.orange_color}
                                  >
                                    {file.title}
                                  </SelectItem>
                                );
                              })}
                          </Wrapper>
                        );
                      };
                    }
                  }

                  break;

                case "task":
                  //TODO check if can edit task

                  noPress = false;

                  if (item.user?.id == this.props.user.id) {
                    select_type = "custom";

                    renderCustomSelect = () => {
                      return (
                        <IconButton
                          icon={
                            item.data?.status == "Active"
                              ? "check-box-outline-blank"
                              : "check-box"
                          }
                          tooltip={
                            item.data?.status == "Active"
                              ? "Complete Task"
                              : "Undo Complete"
                          }
                          innerStyle={
                            item.data?.status != "Active"
                              ? { textDecoration: "line-through" }
                              : {}
                          }
                          tooltipPlacement="top"
                          loading={this.state.tasks_loading?.includes(item.id)}
                          onPress={() => {
                            this.props.completeTask({
                              token: this.props.token,
                              type:
                                item.data?.status == "Active"
                                  ? "complete"
                                  : "undo_complete",
                              task_id: item.id,
                              onLoading: () => {
                                this.setState({
                                  tasks_loading: [
                                    ...this.state.tasks_loading,
                                    item.id
                                  ]
                                });
                              },
                              onError: () => {
                                this.setState({
                                  tasks_loading:
                                    this.state.tasks_loading.filter(
                                      id => id !== item.id
                                    )
                                });
                              },
                              onSuccess: results => {
                                this.setState({
                                  tasks_loading:
                                    this.state.tasks_loading.filter(
                                      id => id !== item.id
                                    )
                                });
                                if (results.lead_activity) {
                                  this.props.updateMainState({
                                    activity: results.lead_activity
                                  });
                                }
                              }
                            });
                          }}
                        />
                      );
                    };
                  }

                  if (!!item.sub_id) {
                    renderRight = () => {
                      return (
                        <>
                          <Row>
                            <Copy style={{ fontSize: 12 }}>
                              Due: {renderDate(item.sub_id)}
                            </Copy>
                            <IconButton
                              button_type="small"
                              noPress
                              icon="keyboard-arrow-right"
                            />
                          </Row>
                        </>
                      );
                    };
                  } else {
                    icon = "keyboard-arrow-right";
                  }

                  break;
              }

              if (item.activity_type === "call_log" && false) {
                const call = item.data;
                return (
                  <SelectItem
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "call_log",
                        overlay: true,
                        data: {
                          call: call,
                          onNoteUpdate: results => {
                            this.getData({ load_type: "refresh" });
                          }
                        }
                      });
                    }}
                    select_type={"icon"}
                    select_icon={
                      call?.call_format === "sms"
                        ? "sms"
                        : call?.call_type === "outgoing"
                        ? "call-made"
                        : "call-received"
                    }
                    icon={"keyboard-arrow-right"}
                    renderContent={() => {
                      const property = call?.property;
                      const formatted_address = formatAddress({
                        address: {
                          address: property?.property_address,
                          address2: property?.property_address2,
                          address_city: property?.property_address_city,
                          address_state: property?.property_address_state,
                          address_zip: property?.property_address_zip,
                          latitude: property?.location?.latitude,
                          longitude: property?.location?.longitude
                        }
                      });
                      return (
                        <Wrapper style={{ flex: 1 }}>
                          <Row>
                            <Wrapper style={{ marginRight: 10 }}>
                              <Row>
                                <Copy
                                  style={{ marginBottom: 5, marginRight: 10 }}
                                >
                                  <Bold>{call?.contact?.full_name}</Bold>
                                </Copy>

                                <Copy
                                  style={{
                                    color: colors.active_color,
                                    fontSize: 12
                                  }}
                                >
                                  {call?.call_results_label}
                                </Copy>
                              </Row>

                              <Row>
                                <Copy>
                                  {formatUsPhone(call?.to_phone_number)}
                                </Copy>
                              </Row>
                            </Wrapper>
                          </Row>
                        </Wrapper>
                      );
                    }}
                  />
                );
              }

              return (
                <SelectItem
                  key={"activity_" + index}
                  select_type={select_type}
                  renderCustomSelect={renderCustomSelect}
                  noPress={noPress}
                  onPress={() => {
                    switch (item.activity_type) {
                      default:
                        break;

                      case "call_log":
                        const call = item.data;
                        this.props.pushSidePanel({
                          slug: "call_log",
                          overlay: true,
                          data: {
                            call: call,
                            onNoteUpdate: results => {
                              this.getData({ load_type: "refresh" });
                            }
                          }
                        });
                        break;

                      case "task":
                        //TODO check if can edit/view task
                        this.props.selectActiveTask({
                          token: this.props.token,
                          task: item.data
                        });
                        this.props.pushSidePanel({
                          slug: "edit_task",
                          overlay: true,
                          data: {
                            property,
                            task: item.data,
                            onSuccess: results => {
                              if (results.lead_activity) {
                                this.props.updateMainState({
                                  activity: results.lead_activity
                                });
                              }
                            }
                          }
                        });

                        break;

                      case "note":
                        //TODO check if can edit note
                        this.props.pushSidePanel({
                          slug: "edit_note",
                          overlay: true,
                          data: {
                            property,
                            note: {
                              id: item.id,
                              text: item.title,
                              file_urls: item.description
                            },
                            onSuccess: results => {
                              if (results.lead_activity) {
                                this.props.updateMainState({
                                  activity: results.lead_activity
                                });
                              }
                            }
                          }
                        });
                        break;
                    }
                  }}
                  subtext={renderDate(item.date_created)}
                  label={item.label}
                  description={description}
                  icon={icon}
                  renderRight={renderRight}
                  renderBottom={renderBottom}
                >
                  {this.renderHyperlinks(item.title)}
                </SelectItem>
              );
            }}
            canRefresh={true}
            onRefresh={() => {
              this.getData({ load_type: "refresh" });
            }}
            is_refreshing={this.props.lead_activity_loading}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors, dark_mode } = settings;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  selectActiveTask,
  completeTask,
  getLeadActivity
})(Activity);
