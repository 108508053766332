import React, { Component } from "react";

import {
  Card,
  CardBody,
  PrimaryButton,
  SecondaryButton,
  Copy,
  Bold,
  Title,
  Spin
} from "app/NativeComponents/common";

class Scene3 extends Component {
  render() {
    if (this.props.scene == 3) {
      return (
        <div className="animated fadeIn">
          <Card>
            <CardBody
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div className="animated zoomIn">
                <Spin />
              </div>
              <Title style={{ marginTop: 10 }}>
                <Bold>Sending Mail...</Bold>
              </Title>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene3;
