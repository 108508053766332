import React, { PureComponent } from "react";
import { store } from "app/store";

class MultiLineInput extends PureComponent {
  render() {
    const {
      placeholder,
      value,
      type,
      maxLength,
      charCount,
      onChange,
      onFocus,
      onBlur,
      className,
      label,
      no_container,
      inputStyle,
      editable = true,
      containerStyle = {},
      forceLabel = false,
      forceLabelPlaceholder = "",
      input_style = "dm-input",
      autoFocus = false
    } = this.props;
    var val = value;
    if (!val) {
      val = "";
    }

    const colors = store.getState().settings.colors;

    return (
      <div
        className={no_container ? input_style + " no_container" : input_style}
        style={{ alignItems: "flex-start" }}
      >
        <div style={{ flex: 1 }}>
          {!no_container && label ? (
            <div className="standard-label">
              <label
                style={{
                  color: colors.light_text_color
                }}
              >
                {label}
              </label>
              {!!charCount ? (
                <label
                  style={{
                    color: colors.light_text_color
                  }}
                >
                  {charCount}
                  {maxLength ? " / " + maxLength : null}
                </label>
              ) : null}
            </div>
          ) : null}

          <div style={{ ...containerStyle }}>
            <textarea
              readOnly={!editable}
              className={val.length > 0 ? "active " + className : className}
              type={type}
              placeholder={placeholder}
              onChange={ev => onChange(ev.target.value)}
              onFocus={onFocus}
              onBlur={onBlur}
              value={value}
              autoComplete="off"
              maxLength={maxLength ? maxLength : null}
              style={{
                color: colors.text_color,
                ...inputStyle
              }}
              autoFocus={autoFocus}
            />
          </div>
        </div>
      </div>
    );
  }
}
export { MultiLineInput };
