import React, { Component } from "react";
import { connect } from "react-redux";

import { SelectItem } from "app/NativeComponents/snippets";

import {
  bulkUpdateLeads,
  setModal,
  updateModal,
  toggleModal,
  numberWithCommas
} from "app/NativeActions";
class DeleteButton extends Component {
  render() {
    const { button_search, title, selected_all, selected_leads } = this.props;
    const { token, user, filtered_count } = this.props;

    if (
      !button_search ||
      title.toLowerCase().includes(button_search.toLowerCase())
    ) {
      return (
        <SelectItem
          select_type="none"
          icon="keyboard-arrow-right"
          onPress={() => {
            this.props.setModal({
              title: "Remove leads from this list",
              description:
                "Are you sure you want to remove leads from this list? Your leads will not be deleted.",
              submit: "Remove Leads",
              buttonType: "destroy",
              require_complete_function: true,
              onPress: complete => {
                this.props.bulkUpdateLeads({
                  token: token,
                  type: "remove_leads_from_this_list",
                  list_ids: this.props.list_id,
                  select_all: selected_all ? 1 : 0,
                  total_count: selected_all
                    ? filtered_count
                    : selected_leads.length,
                  filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.filters
                      ? this.props.applied_filter?.filters
                      : null,
                  property_flags: this.props.lead_filters?.property_flags
                    .map(({ value }) => value)
                    .join(","),
                  property_flags_and_or:
                    this.props.lead_filters?.property_flags_and_or,
                  search: this.props.search,
                  search_type: this.props.search_type,
                  list_id: this.props.active_list_tab?.id
                    ? this.props.active_list_tab?.id
                    : this.props.active_list_tab,
                  list_history_id: this.props.list_history_id || "",
                  lead_ids: selected_all
                    ? ""
                    : selected_leads
                        .map(property => {
                          return property.deal.id;
                        })
                        .join(),
                  onLoading: () => {
                    this.props.updateModal({
                      submit_loading: true
                    });
                  },
                  onError: () => {
                    this.props.updateModal({
                      submit_loading: false
                    });
                  },
                  onSuccess: () => {
                    this.props.updateModal({
                      submit_loading: false
                    });
                    this.props.clearAll();
                    complete();
                  }
                });
              },
              cancel: "Cancel",
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
            this.props.toggleMenu(false);
          }}
        >
          {title}
        </SelectItem>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, lead }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  bulkUpdateLeads,
  setModal,
  updateModal,
  toggleModal
})(DeleteButton);
