import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Button,
  Spin,
  Title,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";
import { PopoverMenu, InlineButton } from "app/NativeComponents/snippets";
import BulkLoading from "./BulkLoading";
import { setPropertiesLimit, setPropertiesPage } from "app/NativeActions";

class Pagination extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      total_pages: 0,
      last_page: false,
      first_page: false,
      current_page: 1,
      showing_page_limit: 6
    };
  }

  componentDidMount() {
    this.calculatePages();
  }

  changePage(page) {
    const { list_properties_loading } = this.props;
    if (!this.props.list_properties_loading) {
      this.props.setPropertiesPage(page);
      //this.props.clearAll();
    }
  }

  componentDidUpdate(prevProps) {
    const { filtered_count, list_properties, list_properties_page } =
      this.props;
    if (
      filtered_count !== prevProps.filtered_count ||
      list_properties.length !== prevProps.list_properties.length ||
      (list_properties_page &&
        list_properties_page !== prevProps.list_properties_page)
    ) {
      //this.props.clearAll();
      this.calculatePages();
    }
  }

  calculatePages() {
    const {
      filtered_count,
      user,
      list_properties_limit,
      list_properties_page
    } = this.props;

    let total_pages = 0;
    if (parseInt(filtered_count) === 0) {
      total_pages = Math.ceil(user.total_leads_count / list_properties_limit);
    } else {
      total_pages = Math.ceil(filtered_count / list_properties_limit);
    }
    let last_page = false;
    if (
      list_properties_page * list_properties_limit >
      filtered_count - list_properties_limit
    ) {
      last_page = true;
    }

    this.setState({
      total_pages: parseInt(total_pages),
      last_page: last_page,
      first_page: parseInt(list_properties_page) === 1 ? true : false,
      current_page: parseInt(list_properties_page)
    });

    //if the page is too high
    if (list_properties_page > total_pages) {
      this.props.setPropertiesPage(total_pages);
    }

    //if page is too low
    if (list_properties_page < 1) {
      this.props.setPropertiesPage(1);
    }
  }

  renderPrevButton() {
    const { total_pages, current_page, first_page } = this.state;
    if (!first_page && total_pages > 1) {
      return (
        <Button
          style={{
            marginRight: 10
          }}
          onPress={() => {
            this.changePage(current_page - 1);
          }}
        >
          <Copy>Previous</Copy>
        </Button>
      );
    }
  }

  renderNumberButtons() {
    const { total_pages, current_page, showing_page_limit } = this.state;

    if (total_pages > 1) {
      let first_half_of_showing_pages = showing_page_limit / 2;

      if (current_page - first_half_of_showing_pages <= 0) {
        first_half_of_showing_pages = current_page - 1;
      }

      let second_half_of_showing_pages = showing_page_limit / 2;
      if (current_page + second_half_of_showing_pages > total_pages) {
        second_half_of_showing_pages = total_pages - current_page;
      }

      let first_number = current_page - first_half_of_showing_pages;
      let last_number = current_page + second_half_of_showing_pages;

      let number_array = [];
      for (let i = first_number; i <= last_number; i++) {
        number_array.push(i);
      }

      return (
        <Row>
          {number_array.map((number, i) => {
            if (number === current_page) {
              return (
                <Button
                  style={{
                    marginRight: 10
                  }}
                  key={i}
                  onPress={() => {
                    this.changePage(number);
                  }}
                >
                  <Copy>
                    <Bold>{number}</Bold>
                  </Copy>
                </Button>
              );
            }

            return (
              <Button
                style={{
                  marginRight: 10
                }}
                key={i}
                onPress={() => {
                  this.changePage(number);
                }}
              >
                <Copy>{number}</Copy>
              </Button>
            );
          })}
        </Row>
      );
    }
  }

  renderNextButton() {
    const { total_pages, current_page, last_page } = this.state;
    if (!last_page && total_pages > 1) {
      return (
        <Button
          onPress={() => {
            this.changePage(current_page + 1);
          }}
        >
          <Copy>
            <Bold>Next</Bold>
          </Copy>
        </Button>
      );
    }
  }

  renderOnlyPageMessage() {
    const { list_properties, filtered_count, user } = this.props;
    const { first_page, last_page } = this.state;
    if (
      list_properties.length > 0 &&
      first_page &&
      last_page &&
      ((filtered_count <= list_properties.length && filtered_count > 0) ||
        (filtered_count == 0 &&
          user.total_leads_count <= list_properties.length))
    ) {
      if (list_properties.length === 1) {
        return <Copy>Showing 1 of 1 lead.</Copy>;
      }
      return (
        <Copy>
          Showing {list_properties.length} of {list_properties.length} leads.
        </Copy>
      );
    }
  }

  renderTotalToDisplayButton() {
    const { total_pages } = this.state;
    const { list_properties_limit } = this.props;
    if (total_pages > 1) {
      return (
        <PopoverMenu
          style={{ padding: 0 }}
          popoverPlacement={"top"}
          popover_width={250}
          icon={"keyboard-arrow-down"}
          title={list_properties_limit + " per page"}
          icon_size={18}
          menu_items={[
            {
              title: "25 per page",
              select_type: "radio",
              selected: list_properties_limit == 25 ? true : false,
              onPress: () => {
                this.props.setPropertiesLimit(25);
              }
            },
            {
              title: "50 per page",
              select_type: "radio",
              selected: list_properties_limit == 50 ? true : false,
              onPress: () => {
                this.props.setPropertiesLimit(50);
              }
            },
            {
              title: "100 per page",
              select_type: "radio",
              selected: list_properties_limit == 100 ? true : false,
              onPress: () => {
                this.props.setPropertiesLimit(100);
              }
            }
          ]}
        />
      );
    }
  }

  renderColumnsButton() {
    const { colors } = this.props;
    return (
      <InlineButton
        onPress={() => {
          this.props.pushSidePanel({ slug: "lead_options", overlay: true });
        }}
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginLeft: 0
        }}
        icon="view-column"
      >
        Edit Columns
      </InlineButton>
    );
  }

  render() {
    const {
      list_properties,
      list_properties_loading,
      list_properties_limit,
      filtered_count_loading,
      user,
      colors
    } = this.props;
    if (list_properties.length === 0 && !list_properties_loading) {
      return (
        <Wrapper
          id="leads-pages"
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 15
          }}
        ></Wrapper>
      );
    }

    if (filtered_count_loading) {
      return (
        <Wrapper>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              borderTopStyle: "solid",
              borderTopWidth: 1,
              borderTopColor: colors.border_color,
              backgroundColor: colors.background_color,
              padding: 15
            }}
          >
            <Copy>Loading Total Pages...</Copy>
          </Wrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderTopStyle: "solid",
            borderTopWidth: 1,
            borderTopColor: colors.border_color,
            backgroundColor: colors.background_color,
            paddingRight: 0,
            paddingLeft: 15
          }}
        >
          <Row style={{ alignSelf: "stretch" }}>
            {this.renderColumnsButton()}

            <Wrapper
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 15,
                paddingBottom: 15
              }}
            >
              <Row>
                <Row
                  style={{
                    marginRight: 20
                  }}
                >
                  {this.renderPrevButton()}
                  {this.renderNumberButtons()}
                  {this.renderNextButton()}
                </Row>
                {this.renderTotalToDisplayButton()}
                {this.renderOnlyPageMessage()}
              </Row>
            </Wrapper>
            <BulkLoading />
          </Row>
        </Wrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, native }) => {
  const { token, user } = auth;

  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  setPropertiesLimit,
  setPropertiesPage
})(Pagination);
