import {
  LOGOUT,
  GET_WORKFLOW_INFO,
  GET_WORKFLOW_INFO_SUCCESS,
  GET_WORKFLOW_INFO_FAIL,
  GET_WORKFLOW_ENROLLMENT_OBJECT,
  GET_WORKFLOW_ENROLLMENT_OBJECT_SUCCESS,
  GET_TESTING_CRITERIA,
  GET_TESTING_CRITERIA_SUCCESS,
  GET_WORKFLOW_STEP_TYPES,
  GET_WORKFLOW_STEP_TYPES_SUCCESS,
  TOGGLE_WORKFLOW_DESIGNER,
  SWITCH_WORKFLOW_TAB,
  SET_ACTIVE_WORKFLOW,
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAIL,
  LOAD_MORE_WORKFLOWS,
  LOAD_MORE_WORKFLOWS_SUCCESS,
  REFRESH_WORKFLOWS,
  UPDATE_WORKFLOW,
  UPDATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_FAIL,
  CREATE_WORKFLOW_SUCCESS,
  REMOVE_WORKFLOW_SUCCESS,
  GET_WORKFLOW_HISTORY,
  GET_WORKFLOW_HISTORY_SUCCESS,
  GET_WORKFLOW_HISTORY_FAIL,
  LOAD_MORE_WORKFLOW_HISTORY,
  LOAD_MORE_WORKFLOW_HISTORY_SUCCESS,
  REFRESH_WORKFLOW_HISTORY,
  SET_ACTIVE_WORKFLOW_HISTORY,
  RELOAD_WORKFLOW_HISTORY_SUCCESS,
  GET_WORKFLOW_HISTORY_FOR_LEAD,
  GET_WORKFLOW_HISTORY_FOR_LEAD_SUCCESS,
  MANUALLY_ENROLL_LEAD_IN_WORKFLOW,
  MANUALLY_ENROLL_LEAD_IN_WORKFLOW_FAIL,
  MANUALLY_ENROLL_LEAD_IN_WORKFLOW_SUCCESS,
  GET_BASIC_AUTOMATION,
  GET_BASIC_AUTOMATION_FAIL,
  GET_BASIC_AUTOMATION_SUCCESS,
  UPDATE_BASIC_AUTOMATION,
  UPDATE_BASIC_AUTOMATION_FAIL,
  UPDATE_BASIC_AUTOMATION_SUCCESS,
  GET_API_INFO,
  GET_API_INFO_FAIL,
  GET_API_INFO_SUCCESS,
  UPDATE_API_INFO,
  UPDATE_API_INFO_FAIL,
  UPDATE_API_INFO_SUCCESS,
  GET_ENROLLMENT_TEMPLATES,
  GET_ENROLLMENT_TEMPLATES_FAIL,
  GET_ENROLLMENT_TEMPLATES_SUCCESS,
  GET_TOTAL_ENROLLMENT_COUNT,
  GET_TOTAL_ENROLLMENT_COUNT_SUCCESS
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  active_workflow: null,
  workflow_categories: [],
  sample_request_object: "",
  enrollment_object: null,
  enrollment_object_loading: false,
  enrollment_object_type: null,
  workflow_step_types: [],
  workflow_step_types_loading: false,

  workflows: [],
  workflow_count: null,

  workflows_loading: false,
  workflows_refreshing: false,
  workflows_begin: 0,
  workflows_limit: 25,
  workflows_loaded_all: false,
  workflows_error: "",

  toggle_workflow_designer: false,
  workflow_tab: "workflows",

  testing_criteria: [],
  testing_criteria_loading: false,

  active_workflow_history: null,
  workflow_history: [],
  workflow_history_begin: 0,
  workflow_history_limit: 25,
  workflow_history_loading: false,
  workflow_history_refreshing: false,
  workflow_history_error: "",
  workflow_history_loaded_all: false,
  workflow_history_for_lead_loading: false,
  manually_enroll_lead_in_workflow_loading: false,

  lead_workflow_history: {
    lead_id: null,
    workflow_history: []
  },

  basic_automation: [],
  basic_automation_loading: false,

  api_info: null,
  api_info_loading: false,

  enrollment_templates: [],
  enrollment_templates_loading: false,

  total_enrollment_count: 0,
  total_enrollment_count_loading: false
};

const updateWorkflow = (current_workflow, new_workflows) => {
  for (let i = 0; i < new_workflows.length; i++) {
    if (new_workflows[i].id == current_workflow.id) {
      return new_workflows[i];
    }
  }
  return current_workflow;
};

const updateWorkflowHistory = (
  current_workflow_event,
  new_workflows_events
) => {
  for (let i = 0; i < new_workflows_events.length; i++) {
    if (new_workflows_events[i].id == current_workflow_event.id) {
      return new_workflows_events[i];
    }
  }
  return current_workflow_event;
};

const updateOrAddToWorkflowHistory = (
  current_workflow_events,
  new_workflow_event
) => {
  let workflow_events = current_workflow_events;
  let found_event = false;
  for (let i = 0; i < current_workflow_events.length; i++) {
    if (current_workflow_events[i].id == new_workflow_event.id) {
      current_workflow_events[i] = new_workflow_event;
      found_event = true;
    }
  }
  if (!found_event) {
    workflow_events = [new_workflow_event, ...workflow_events];
  }

  return workflow_events;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case GET_WORKFLOW_INFO:
      return {
        ...state
      };

    case GET_WORKFLOW_INFO_FAIL:
      return {
        ...state,
        enrollment_object_loading: false,
        workflow_step_types_loading: false,
        testing_criteria_loading: false,
        total_enrollment_count_loading: false
      };

    case GET_WORKFLOW_INFO_SUCCESS:
      return {
        ...state,
        workflow_categories: action.payload.workflow_categories
      };

    case GET_WORKFLOW_ENROLLMENT_OBJECT:
      return {
        ...state,
        enrollment_object: null,
        enrollment_object_loading: true
      };

    case GET_WORKFLOW_ENROLLMENT_OBJECT_SUCCESS:
      return {
        ...state,
        enrollment_object: action.payload?.results?.enrollment_object,
        sample_request_object: action.payload?.results?.sample_request_object,
        enrollment_object_type: action.payload?.type,
        enrollment_object_loading: false
      };

    case GET_TESTING_CRITERIA:
      return {
        ...state,
        testing_criteria: null,
        testing_criteria_loading: true
      };

    case GET_TESTING_CRITERIA_SUCCESS:
      return {
        ...state,
        testing_criteria: action.payload?.testing_criteria,
        testing_criteria_loading: false
      };

    case GET_TOTAL_ENROLLMENT_COUNT:
      return {
        ...state,
        total_enrollment_count: 0,
        total_enrollment_count_loading: true
      };

    case GET_TOTAL_ENROLLMENT_COUNT_SUCCESS:
      return {
        ...state,
        total_enrollment_count: action.payload.total_enrollment_count
          ? action.payload.total_enrollment_count
          : state.total_enrollment_count,
        total_enrollment_count_loading: false
      };

    case GET_WORKFLOW_STEP_TYPES:
      return {
        ...state,
        workflow_step_types_loading: true
      };

    case GET_WORKFLOW_STEP_TYPES_SUCCESS:
      return {
        ...state,
        workflow_step_types: action.payload.workflow_step_types,
        workflow_step_types_loading: false
      };

    case SET_ACTIVE_WORKFLOW:
      return {
        ...state,
        active_workflow: action.payload
      };
    case TOGGLE_WORKFLOW_DESIGNER:
      return {
        ...state,
        toggle_workflow_designer: action.payload
      };
    case SWITCH_WORKFLOW_TAB:
      return {
        ...state,
        workflow_tab: action.payload
      };

    case GET_WORKFLOWS:
      return {
        ...state,
        workflows: [],
        workflows_begin: 0,
        workflows_error: "",
        workflows_loading: true
      };

    case LOAD_MORE_WORKFLOWS:
      return {
        ...state,
        workflows_error: "",
        workflows_loading: true
      };
    case REFRESH_WORKFLOWS:
      return {
        ...state,
        workflows_error: "",
        workflows_loading: true,
        workflows_refreshing: true,
        workflows_begin: 0,
        workflows: []
      };

    case GET_WORKFLOWS_FAIL:
      return {
        ...state,
        workflows_error: action.payload,
        workflows_loading: false,
        workflows_refreshing: false
      };

    case GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows_loading: false,
        workflows_refreshing: false,
        workflows: action.payload.workflows,
        workflow_count: action.payload.workflow_count
          ? action.payload.workflow_count
          : state.workflow_count,
        workflows_begin:
          state.workflows_begin + action.payload.workflows?.length,
        workflows_loaded_all:
          action.payload.workflows.length < state.workflows_limit
      };

    case LOAD_MORE_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows_loading: false,
        workflows_refreshing: false,
        workflows: state.workflows.concat(action.payload.workflows),
        workflow_count: action.payload.workflow_count
          ? action.payload.workflow_count
          : state.workflow_count,
        workflows_begin:
          state.workflows_begin + action.payload.workflows?.length,
        workflows_loaded_all:
          action.payload.workflows.length < state.workflows_limit
      };

    case UPDATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        active_workflow:
          state.active_workflow && action.payload.workflows
            ? updateWorkflow(state.active_workflow, action.payload.workflows)
            : state.active_workflow,
        workflows: action.payload.workflows
          ? state.workflows.map(campaign => {
              return updateWorkflow(campaign, action.payload.workflows);
            })
          : state.workflows
      };

    case CREATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        active_workflow: action.payload.workflows
          ? action.payload.workflows[0]
          : state.active_workflow,
        workflows: [action.payload.workflows[0], ...state.workflows],
        workflow_count: action.payload.workflow_count
          ? action.payload.workflow_count
          : state.workflow_count
      };
    case REMOVE_WORKFLOW_SUCCESS:
      return {
        ...state,
        active_workflow:
          state.active_workflow?.id == action.payload.workflow_id
            ? null
            : state.active_workflow,

        workflows: action.payload.workflow_id
          ? state.workflows.filter(({ id }) => id != action.payload.workflow_id)
          : state.workflows,
        workflow_count: action.payload.workflow_count
          ? action.payload.workflow_count
          : state.workflow_count
      };

    case GET_WORKFLOW_HISTORY:
      return {
        ...state,
        workflow_history: [],
        workflow_history_begin: 0,
        workflow_history_error: "",
        workflow_history_loading: true
      };

    case LOAD_MORE_WORKFLOW_HISTORY:
      return {
        ...state,
        workflow_history_error: "",
        workflow_history_loading: true
      };
    case REFRESH_WORKFLOW_HISTORY:
      return {
        ...state,
        workflow_history_error: "",
        workflow_history_loading: true,
        workflow_history_refreshing: true,
        workflow_history_begin: 0,
        workflow_history: []
      };

    case GET_WORKFLOW_HISTORY_FAIL:
      return {
        ...state,
        workflow_history_error: action.payload,
        workflow_history_loading: false,
        workflow_history_refreshing: false,
        workflow_history_for_lead_loading: false
      };

    case GET_WORKFLOW_HISTORY_SUCCESS:
      return {
        ...state,
        workflow_history_loading: false,
        workflow_history_refreshing: false,
        workflow_history: action.payload.workflow_history,

        workflow_history_begin:
          state.workflows_begin + action.payload.workflow_history?.length,
        workflow_history_loaded_all:
          action.payload.workflow_history.length < state.workflow_history_limit
      };

    case LOAD_MORE_WORKFLOW_HISTORY_SUCCESS:
      return {
        ...state,
        workflow_history_loading: false,
        workflow_history_refreshing: false,
        workflow_history: state.workflow_history.concat(
          action.payload.workflow_history
        ),
        workflow_history_begin:
          state.workflow_history_begin +
          action.payload.workflow_history?.length,
        workflow_history_loaded_all:
          action.payload.workflow_history.length < state.workflow_history_limit
      };

    case SET_ACTIVE_WORKFLOW_HISTORY:
      return {
        ...state,
        active_workflow_history: action.payload
      };

    case RELOAD_WORKFLOW_HISTORY_SUCCESS:
      return {
        ...state,
        active_workflow_history: state.active_workflow_history
          ? updateWorkflowHistory(
              state.active_workflow_history,
              action.payload.workflow_history
            )
          : state.active_workflow_history,
        workflow_history: action.payload.workflow_history
          ? updateOrAddToWorkflowHistory(
              state.workflow_history,
              action.payload.workflow_history[0]
            )
          : state.workflow_history,
        lead_workflow_history:
          action.payload.workflow_history &&
          action.payload.workflow_history[0].lead_id ==
            state.lead_workflow_history.lead_id
            ? {
                ...state.lead_workflow_history,
                workflow_history: updateOrAddToWorkflowHistory(
                  state.lead_workflow_history.workflow_history,
                  action.payload.workflow_history[0]
                )
              }
            : state.lead_workflow_history
      };

    case GET_WORKFLOW_HISTORY_FOR_LEAD:
      return {
        ...state,
        workflow_history_for_lead_loading: true,
        lead_workflow_history: {
          lead_id: action.payload.lead_id,
          workflow_history: []
        }
      };

    case GET_WORKFLOW_HISTORY_FOR_LEAD_SUCCESS:
    case MANUALLY_ENROLL_LEAD_IN_WORKFLOW_SUCCESS:
      return {
        ...state,
        manually_enroll_lead_in_workflow_loading: false,
        workflow_history_for_lead_loading: false,
        lead_workflow_history:
          action.payload.lead_id == state.lead_workflow_history.lead_id
            ? {
                ...state.lead_workflow_history,
                workflow_history: action.payload.workflow_history
              }
            : state.lead_workflow_history
      };

    case MANUALLY_ENROLL_LEAD_IN_WORKFLOW:
      return {
        ...state,
        manually_enroll_lead_in_workflow_loading: true
      };
    case MANUALLY_ENROLL_LEAD_IN_WORKFLOW_FAIL:
      return {
        ...state,
        manually_enroll_lead_in_workflow_loading: false
      };

    case GET_BASIC_AUTOMATION:
      return {
        ...state,
        basic_automation: [],
        basic_automation_loading: true
      };
    case GET_BASIC_AUTOMATION_FAIL:
      return {
        ...state,
        basic_automation_loading: false
      };

    case UPDATE_BASIC_AUTOMATION_SUCCESS:
    case GET_BASIC_AUTOMATION_SUCCESS:
      return {
        ...state,
        basic_automation_loading: false,
        basic_automation: action.payload.basic_automation
      };

    case GET_API_INFO:
      return {
        ...state,
        api_info: null,
        api_info_loading: true
      };
    case GET_API_INFO_FAIL:
      return {
        ...state,
        api_info_loading: false
      };

    case GET_API_INFO_SUCCESS:
    case UPDATE_API_INFO_SUCCESS:
      return {
        ...state,
        api_info_loading: false,
        api_info: action.payload.api_info
      };

    case GET_ENROLLMENT_TEMPLATES:
      return {
        ...state,
        enrollment_templates: [],
        enrollment_templates_loading: true
      };
    case GET_ENROLLMENT_TEMPLATES_FAIL:
      return {
        ...state,
        enrollment_templates_loading: false
      };

    case GET_ENROLLMENT_TEMPLATES_SUCCESS:
      return {
        ...state,
        enrollment_templates_loading: false,
        enrollment_templates: action.payload.enrollment_templates
      };
    default:
      return state;
  }
};
