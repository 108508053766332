import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  Copy,
  KeyboardView,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  ConfirmLabel,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateStackedLists,
  triggerLeadsReload,
  renderDate
} from "app/NativeActions";

class LevelSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_stacking_level_1:
        props.user?.team_lead_scoring_criteria?.list_stacking_level_1,
      list_stacking_level_2:
        props.user?.team_lead_scoring_criteria?.list_stacking_level_2,
      list_stacking_level_3:
        props.user?.team_lead_scoring_criteria?.list_stacking_level_3,
      loading: false
    };

    this._list_stacking_level_1 = React.createRef();
    this._list_stacking_level_2 = React.createRef();
    this._list_stacking_level_3 = React.createRef();
  }

  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    if (
      (this.state.list_stacking_level_1 !=
        this.props.user.team_lead_scoring_criteria.list_stacking_level_1 &&
        !!this.state.list_stacking_level_1) ||
      (this.state.list_stacking_level_2 !=
        this.props.user.team_lead_scoring_criteria.list_stacking_level_2 &&
        !!this.state.list_stacking_level_2) ||
      (this.state.list_stacking_level_3 !=
        this.props.user.team_lead_scoring_criteria.list_stacking_level_3 &&
        !!this.state.list_stacking_level_3)
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      colors,
      isMobile,
      lists_refreshing,
      lists,
      lists_loading,
      lists_loaded_all
    } = this.props;
    const { search, stacked_lists } = this.state;
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"List Stacking Level Settings"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        {this.checkIfNeedsToSave() ? (
          <ConfirmLabel
            style={{
              alignSelf: "stretch"
            }}
            icon="check-circle"
            label={"Settings Changed"}
            primary={this.checkIfNeedsToSave()}
            confirmButton={"Confirm Changes"}
            loading={this.state.loading}
            onPress={() => {
              if (this.checkIfNeedsToSave()) {
                this.props.updateStackedLists({
                  token: this.props.token,
                  list_stacking_level_1: this.state.list_stacking_level_1,
                  list_stacking_level_2: this.state.list_stacking_level_2,
                  list_stacking_level_3: this.state.list_stacking_level_3,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                    this.props.triggerLeadsReload(true);
                    this.props.popSidePanel();
                  }
                });
              } else {
              }
            }}
          />
        ) : null}
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox
            description={
              "This determines your stack count. Talk about the meters below that determine how your list stack is displayed."
            }
          />

          <>
            <InputBox
              renderLeft={() => {
                return (
                  <Wrapper
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: colors.active_color_muted,
                      marginLeft: 25,
                      marginRight: -10
                    }}
                  />
                );
              }}
              input_ref={this._list_stacking_level_1}
              name="list_stacking_level_1"
              disabled={this.state.loading}
              returnKeyType="next"
              placeholder="Stack Level 1"
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  list_stacking_level_1: value,
                  list_stacking_level_2:
                    parseInt(value) >= this.state.list_stacking_level_2
                      ? parseInt(value) + 1
                      : this.state.list_stacking_level_2,
                  list_stacking_level_3:
                    parseInt(value) + 1 >= this.state.list_stacking_level_3
                      ? parseInt(value) + 2
                      : this.state.list_stacking_level_3
                });
              }}
              onSubmitEditing={() => {
                this._list_stacking_level_2.current.focus();
              }}
              blurOnSubmit={false}
              value={this.state.list_stacking_level_1.toString()}
              type="number"
            />

            <InputBox
              input_ref={this._list_stacking_level_2}
              renderLeft={() => {
                return (
                  <Wrapper
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: colors.success_color_muted,
                      marginLeft: 25,
                      marginRight: -10
                    }}
                  />
                );
              }}
              name="list_stacking_level_2"
              disabled={this.state.loading}
              returnKeyType="next"
              placeholder="Stack Level 2"
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  list_stacking_level_1:
                    parseInt(value) <= this.state.list_stacking_level_1
                      ? parseInt(value) - 1
                      : this.state.list_stacking_level_1,
                  list_stacking_level_2: value,
                  list_stacking_level_3:
                    parseInt(value) >= this.state.list_stacking_level_3
                      ? parseInt(value) + 1
                      : this.state.list_stacking_level_3
                });
              }}
              onSubmitEditing={() => {
                this._list_stacking_level_3.current.focus();
              }}
              blurOnSubmit={false}
              value={this.state.list_stacking_level_2.toString()}
              type="number"
            />
            <InputBox
              input_ref={this._list_stacking_level_3}
              renderLeft={() => {
                return (
                  <Wrapper
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 50,
                      backgroundColor: colors.orange_color_muted,
                      marginLeft: 25,
                      marginRight: -10
                    }}
                  />
                );
              }}
              name="list_stacking_level_3"
              disabled={this.state.loading}
              returnKeyType="next"
              placeholder="Stack Level 3"
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  list_stacking_level_1:
                    parseInt(value) - 1 <= this.state.list_stacking_level_2
                      ? parseInt(value) - 2
                      : this.state.list_stacking_level_1,
                  list_stacking_level_2:
                    parseInt(value) <= this.state.list_stacking_level_2
                      ? parseInt(value) - 1
                      : this.state.list_stacking_level_2,
                  list_stacking_level_3: value
                });
              }}
              onSubmitEditing={() => {}}
              blurOnSubmit={false}
              value={this.state.list_stacking_level_3.toString()}
              type="number"
            />
          </>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  list,
  property_map,
  lead
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { active_property, map_properties } = property_map;
  const { list_properties } = lead;
  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,

    active_property,
    map_properties,
    list_properties
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateStackedLists,
  triggerLeadsReload
})(LevelSettings);
