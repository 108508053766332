import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getApiInfo,
  popSidePanel,
  setModal,
  toggleModal,
  openUrl,
  updateApiInfo
} from "app/NativeActions";
import {
  Container,
  Wrapper,
  KeyboardView,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  InlineButton,
  SmallLabel,
  SelectItem,
  AttentionBox
} from "app/NativeComponents/snippets";

import ZapierOptions from "./ZapierOptions";

class API extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    this.props.getApiInfo({
      token: this.props.token
    });
  }

  render() {
    const { token, isMobile, device, colors, api_info_loading, api_info } =
      this.props;

    return (
      <Container>
        <NewHeader
          title={"API"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {api_info_loading ? (
            <SpinWrapper text="Loading API Information" />
          ) : api_info ? (
            <>
              <SelectItem
                noPress={true}
                style={{ backgroundColor: colors.active_color_muted }}
                select_type="none"
                description="Take a look at our full api documentation to take your DealMachine account to the next level."
                renderRight={() => {
                  return (
                    <InlineButton
                      style={{ marginTop: 10 }}
                      onPress={() => {
                        openUrl("https://docs.dealmachine.com");
                      }}
                    >
                      View Documenation
                    </InlineButton>
                  );
                }}
              >
                Full API Documentation
              </SelectItem>
              <>
                <SmallLabel>API Keys:</SmallLabel>
                <SelectItem
                  noPress={true}
                  select_type="none"
                  renderRight={() => {
                    return (
                      <InlineButton
                        loading={this.state.loading}
                        icon="refresh"
                        onPress={() => {
                          this.props.setModal({
                            title: "Regenerate API Key?",
                            description:
                              "Your current api key will no longer be functional. Are you sure you want to do this",
                            icon: "",
                            buttonType: "destroy",
                            submit: "Regenerate API Key",
                            onPress: () => {
                              this.props.updateApiInfo({
                                token: this.props.token,
                                type: "regenerate_api_key",
                                onLoading: () => {
                                  this.setState({ loading: true });
                                },
                                onError: () => {
                                  this.setState({ loading: false });
                                },
                                onSuccess: () => {
                                  this.setState({ loading: false });
                                }
                              });
                            },
                            cancel: "Cancel",
                            onCancel: () => {}
                          });
                          this.props.toggleModal({
                            show: true,
                            type: "normal"
                          });
                        }}
                      >
                        Regenerate Key
                      </InlineButton>
                    );
                  }}
                >
                  {api_info.api_key}
                </SelectItem>
              </>
            </>
          ) : (
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 20
              }}
            >
              <Copy style={{ textAlign: "center" }}>
                There is nothing to display.
              </Copy>
            </Wrapper>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, workflow }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { api_info, api_info_loading } = workflow;

  return {
    token,
    user,
    isMobile,
    colors,
    device,
    api_info,
    api_info_loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getApiInfo,
  setModal,
  toggleModal,
  updateApiInfo
})(API);
