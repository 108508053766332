import React from "react";
import Slider from "react-rangeslider";
import "app/assets/css/slider.css";
import { store } from "app/store";

const RangeSlider = ({ value, min, max, step = 1, onChange }) => {
  const dark_mode = store.getState().settings.dark_mode;

  return (
    <div className={dark_mode + " range-wrapper"}>
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        tooltip={false}
      />
    </div>
  );
};

export { RangeSlider };
