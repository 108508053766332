import React, { Component } from "react";

import {
  Card,
  Map,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { ToggleSwitch } from "app/NativeComponents/snippets";

import Scene2 from "./Scene2";

class AutomatedFollowUps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 0
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);

    if (this.state.scene == 0) {
      this.setState({ scene: 1 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 2000);
    } else if (this.state.scene == 1) {
      this.setState({ scene: 2 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 2000);
    } else if (this.state.scene == 2) {
      this.setState({ scene: 3 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 250);
    } else if (this.state.scene == 3) {
      this.setState({ scene: 4 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 500);
    } else if (this.state.scene == 4) {
      this.setState({ scene: 5 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 250);
    } else if (this.state.scene == 5) {
      this.setState({ scene: 6 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 2000);
    } else {
      this.setState({ scene: 0 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 500);
    }
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 200,
          textAlign: "left"
        }}
      >
        <div className="animated fadeIn">
          <Card>
            <CardBody
              style={{
                padding: "10px 15px"
              }}
            >
              <ToggleSwitch
                onChange={() => {}}
                value={this.state.scene <= 1 ? false : true}
                title={"Send Repeating Mail?"}
                text={""}
              />

              <Scene2 scene={this.state.scene} />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default AutomatedFollowUps;
