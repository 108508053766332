import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon,
  SwitchButton
} from "app/NativeComponents/common";
import {
  Select,
  CheckBoxInput,
  ToggleSwitch,
  SelectBox
} from "app/NativeComponents/snippets";
import { renderPrice, numberWithCommas } from "app/NativeActions";
import { store } from "app/store";

class AddCredit extends Component {
  constructor(props) {
    super(props);

    let original_credit_options = [
      {
        key: 5,
        value: 5000,
        label: "$50.00"
      },
      {
        key: 6,
        value: 6000,
        label: "$60.00"
      },
      {
        key: 7,
        value: 7000,
        label: "$70.00"
      },
      {
        key: 8,
        value: 8000,
        label: "$80.00"
      },
      {
        key: 9,
        value: 9000,
        label: "$90.00"
      },
      {
        key: 10,
        value: 10000,
        label: "$100.00"
      },
      {
        key: 11,
        value: 20000,
        label: "$200.00"
      },
      {
        key: 12,
        value: 30000,
        label: "$300.00"
      },
      {
        key: 13,
        value: 40000,
        label: "$400.00"
      },
      {
        key: 14,
        value: 50000,
        label: "$500.00"
      },
      {
        key: 15,
        value: 60000,
        label: "$600.00"
      },
      {
        key: 16,
        value: 70000,
        label: "$700.00"
      },
      {
        key: 17,
        value: 80000,
        label: "$800.00"
      },
      {
        key: 18,
        value: 90000,
        label: "$900.00"
      },
      {
        key: 19,
        value: 100000,
        label: "$1000.00"
      }
    ];

    let credit_options = [];
    const min_amount =
      props.estimated_invoice_info.final_dealcredit_balance * -1;
    for (let i = 0; i < original_credit_options.length; i++) {
      if (original_credit_options[i].value >= min_amount) {
        credit_options.push(original_credit_options[i]);
      }
    }

    if (credit_options.length > 0) {
      props.updateSelectedCredit(credit_options[0].value);
    } else {
      props.updateSelectedCredit(min_amount);
    }

    this.state = {
      credit_options,
      original_credit_options,
      credit_focus: false
    };
  }

  render() {
    const {
      estimated_invoice_info,
      estimated_invoice_info: { number_of_leads },
      colors,
      selected_credit,
      selected_reload_amount,
      auto_reload,
      started_on,
      confirm_box,
      invoice_type
    } = this.props;
    const { credit_options, original_credit_options } = this.state;
    const device = store.getState().native.device;
    return (
      <Wrapper>
        {/*
        Calculate if they need to add more to their account or turn on autopay
        --if total due later is greater than dealcredit amount plus trigger this
        */}
        {auto_reload ? (
          <Wrapper
            style={{
              margin: 10,
              borderRadius: 5,
              backgroundColor: colors.background_color
            }}
          >
            <Row
              style={{
                padding: 25
              }}
            >
              <Wrapper style={{ flex: 1 }}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Copy>
                    <Bold>
                      {auto_reload ? "Pay as You Go" : "One-time Charge"}
                    </Bold>
                  </Copy>

                  {/*
                    <SwitchButton
                      style={{ marginLeft: 10 }}
                      value={true}
                      onChange={value => {
                        this.props.toggleCreditReload(
                          device == "mobile" ? value : !value
                        );
                      }}
                    />
                    */}
                </Row>
                <Copy
                  style={{
                    fontSize: 12,
                    marginTop: 10
                  }}
                >
                  You’re all set...{"\n"}
                  When your marketing credit balance dips below $0,{" "}
                  {renderPrice(
                    estimated_invoice_info.default_credit_reload > 0
                      ? estimated_invoice_info.default_credit_reload
                      : selected_reload_amount
                  )}{" "}
                  will be added.
                </Copy>
              </Wrapper>
            </Row>
            {!started_on ? (
              <Wrapper style={{ flex: 1 }}>
                <SelectBox
                  icon_right="keyboard-arrow-down"
                  items={original_credit_options}
                  disabled={false}
                  hide_search={true}
                  value={selected_reload_amount}
                  placeholder="Reload amount:"
                  show={this.state.credit_focus}
                  onFocus={() => {
                    this.setState({ credit_focus: true });
                  }}
                  onBlur={() => {
                    this.setState({ credit_focus: false });
                  }}
                  onSelect={item => {
                    this.props.updateSelectedReloadAmount(item);
                  }}
                />
              </Wrapper>
            ) : null}
          </Wrapper>
        ) : // {number_of_leads > 1 ? (
        //   <CheckBoxInput
        //     onPress={() => {
        //       this.props.toggleConfirmBox(!confirm_box);
        //     }}
        //     innerStyle={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
        //     value={confirm_box}
        //     text={
        //       invoice_type == "mailer_campaign"
        //         ? "I agree to use and/or add an estimated amount of " +
        //           renderPrice(estimated_invoice_info.total_to_subtract) +
        //           " to my account during these mail sequences."
        //         : "I agree to use and/or add an estimated amount of " +
        //           renderPrice(estimated_invoice_info.total_to_subtract) +
        //           " for these skip traces."
        //     }
        //   />
        // ) : !started_on ? (
        //   <CheckBoxInput
        //     onPress={() => {
        //       this.props.toggleConfirmBox(!confirm_box);
        //     }}
        //     innerStyle={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
        //     value={confirm_box}
        //     text={
        //       "I agree to turning auto-reload on. This will charge " +
        //       renderPrice(
        //         estimated_invoice_info.default_credit_reload > 0
        //           ? estimated_invoice_info.default_credit_reload
        //           : selected_reload_amount
        //       ) +
        //       " to my account when my balance falls below $0. This will also loop together larger charges."
        //     }
        //   />
        // ) : number_of_leads == 1 &&
        //   !estimated_invoice_info.skip_single_lead_prompt ? (
        //   <CheckBoxInput
        //     onPress={() => {
        //       this.props.toggleConfirmBox(!confirm_box);
        //     }}
        //     innerStyle={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
        //     value={confirm_box}
        //     text={"Do not show this prompt again for individual leads."}
        //   />
        // ) : null}
        estimated_invoice_info.final_dealcredit_balance <= 0 ? (
          <Wrapper style={{ marginBottom: 10 }}>
            <Row
              style={{
                margin: 10,
                padding: 25,
                borderRadius: 5,
                backgroundColor: colors.background_color
              }}
            >
              <Wrapper style={{ flex: 1 }}>
                <Row>
                  <Copy>
                    <Bold>
                      {started_on ? "Pay as You Go" : "One-time Charge"}
                    </Bold>
                  </Copy>
                </Row>
              </Wrapper>
              <SwitchButton
                style={{ marginLeft: 10 }}
                value={false}
                onChange={value => {
                  this.props.toggleCreditReload(
                    device == "mobile" ? value : !value
                  );
                }}
              />
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Wrapper
                style={{
                  margin: 10,
                  marginBottom: 10,
                  paddingBottom: 10,
                  flex: 1,
                  marginRight: 10,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.border_color,
                  borderBottomWidth: 1
                }}
              />
              <Copy>Or</Copy>
              <Wrapper
                style={{
                  margin: 10,

                  marginBottom: 10,
                  paddingBottom: 10,
                  flex: 1,
                  marginLeft: 10,
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.border_color,
                  borderBottomWidth: 1
                }}
              />
            </Row>

            <Row
              style={{
                margin: 10,
                borderRadius: 5,
                backgroundColor: colors.success_color_muted
              }}
            >
              <Wrapper style={{ flex: 1, padding: 25 }}>
                <Row>
                  <Copy>
                    <Bold>Add credit to your account</Bold>
                  </Copy>
                </Row>
                <Copy
                  style={{
                    fontSize: 12
                  }}
                >
                  Select an amount of credit to add to your account.
                </Copy>
              </Wrapper>
              {estimated_invoice_info.final_dealcredit_balance >= -100000 ? (
                <Wrapper style={{ flex: 1 }}>
                  <SelectBox
                    icon_right="keyboard-arrow-down"
                    items={credit_options}
                    disabled={false}
                    hide_search={true}
                    value={selected_credit}
                    placeholder="Credit To Add:"
                    show={this.state.credit_focus}
                    onFocus={() => {
                      this.setState({ credit_focus: true });
                    }}
                    onBlur={() => {
                      this.setState({ credit_focus: false });
                    }}
                    onSelect={item => {
                      this.props.updateSelectedCredit(item);
                    }}
                  />
                </Wrapper>
              ) : (
                <Wrapper style={{ padding: 25 }}>
                  <Copy>
                    <Bold>Credit To Add</Bold>
                  </Copy>
                  <Copy>{renderPrice(selected_credit)}</Copy>
                </Wrapper>
              )}
            </Row>
          </Wrapper>
        ) : (
          /*<CheckBoxInput
                          onPress={() => {
                            this.props.toggleConfirmBox(!confirm_box);
                          }}
                          innerStyle={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
                          value={confirm_box}
                          text={
                            invoice_type == "mailer_campaign"
                              ? "Charge " +
                                renderPrice(selected_credit) +
                                " to my card and start mail sequence."
                              : "Charge " +
                                renderPrice(selected_credit) +
                                " to my card and start skip tracing."
                          }
                        />*/

          <Wrapper>
            <Row
              style={{
                margin: 10,
                padding: 25,
                borderRadius: 5
              }}
            >
              <Wrapper style={{ flex: 1 }}>
                <Row>
                  <Copy>
                    <Bold>
                      {auto_reload ? "Pay as You Go" : "One-time Charge"}
                    </Bold>
                  </Copy>
                </Row>
              </Wrapper>
              <SwitchButton
                style={{ marginLeft: 10 }}
                value={auto_reload}
                onChange={value => {
                  this.props.toggleCreditReload(
                    device == "mobile" ? value : !value
                  );
                }}
              />
            </Row>
          </Wrapper>
          // {number_of_leads > 1 ? (
          //   <CheckBoxInput
          //     onPress={() => {
          //       this.props.toggleConfirmBox(!confirm_box);
          //     }}
          //     innerStyle={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
          //     value={confirm_box}
          //     text={
          //       invoice_type == "mailer_campaign"
          //         ? "I agree to use an estimated amount of " +
          //           renderPrice(estimated_invoice_info.total_to_subtract) +
          //           " from my balance during these mail sequences."
          //         : "I agree to use an estimated amount of " +
          //           renderPrice(estimated_invoice_info.total_to_subtract) +
          //           " from my balance for these skip traces."
          //     }
          //   />
          // ) : null}
        )}
      </Wrapper>
    );
  }
}

export default AddCredit;
