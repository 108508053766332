import moment from "moment";
import tinycolor from "tinycolor2";

let currentRoutePolyLines = [];
let routePolyLines = [];

export const renderCurrentRoute = (map, current_route) => {
  if (current_route) {
    currentRoutePolyLines.map((currentRoutePolyLine, i) => {
      currentRoutePolyLine.setMap(null);
      return currentRoutePolyLine;
    });

    if (current_route.coordinates && current_route.coordinates != "") {
      const route_coordinates = current_route.coordinates;

      let path = [];

      route_coordinates.forEach(function (location) {
        let pathLatLong = {
          lat: location.a,
          lng: location.b
        };
        path.push(pathLatLong);
      });

      let drawnPolyline = new window.google.maps.Polyline({
        strokeColor: "#4EE8C3",
        strokeOpacity: 1,
        strokeWeight: 4,
        path: path //decodedPolyline.geometry.coordinates
      });
      drawnPolyline.setMap(map);
      currentRoutePolyLines.push(drawnPolyline);
    }
  }
};

export const renderRoutes = (map, routes, user) => {
  routePolyLines.map((routePolyLine, i) => {
    routePolyLine.setMap(null);
    return routePolyLine;
  });

  routes.map((route, i) => {
    if (route) {
      if (route.coordinates && route.coordinates != "") {
        const route_coordinates = route.coordinates;

        let path = [];

        route_coordinates.forEach(function (location) {
          let pathLatLong = {
            lat: location.latitude,
            lng: location.longitude
          };
          path.push(pathLatLong);
        });

        let drawnPolyline = new window.google.maps.Polyline({
          strokeColor: renderRouteColor(route, user),
          strokeOpacity: 1,
          strokeWeight: 4,
          path: path //decodedPolyline.geometry.coordinates
        });
        drawnPolyline.setMap(map);
        routePolyLines.push(drawnPolyline);
      }
    }
    return route;
  });
};

const renderRouteColor = (route, user) => {
  const months = moment().diff(route.start_time, "months", false);
  let color = "";

  if (months > 24) {
    //transparent
    color = "transparent";
  } else if (months > 12) {
    //red
    color = "#B24C63";
  } else if (months > 6) {
    //yellow
    color = "#F9F871";
  } else {
    //green
    color = "#4EE8C3";
  }

  if (user && months < 24) {
    if (route.user_id != user.id) {
      //get opacity
      const color_item = tinycolor(color);
      color =
        "rgba(" +
        color_item._r +
        "," +
        color_item._g +
        "," +
        color_item._b +
        ", 0.7)";
    }
  }

  return color;
};
