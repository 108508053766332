import React, { Component } from "react";

import { Wrapper, Row, Copy, Bold, Icon } from "app/NativeComponents/common";

import { renderPrice, numberWithCommas } from "app/NativeActions";

import AddCredit from "./AddCredit";

class DealCreditBalance extends Component {
  render() {
    const {
      card,
      card_info,
      loading,
      editing_card,
      address,
      estimated_invoice_info,
      colors,
      selected_credit,
      selected_reload_amount,
      auto_reload,
      started_on,
      confirm_box,
      invoice_type,
      updateSelectedCredit,
      updateSelectedReloadAmount,
      toggleCreditReload,
      toggleConfirmBox
    } = this.props;
    if (estimated_invoice_info) {
      const {
        estimated_invoice_info: { mailer_campaign, number_of_leads }
      } = this.props;
      return (
        <Wrapper>
          <AddCredit
            estimated_invoice_info={estimated_invoice_info}
            colors={colors}
            selected_credit={selected_credit}
            selected_reload_amount={selected_reload_amount}
            auto_reload={auto_reload}
            started_on={started_on}
            confirm_box={confirm_box}
            updateSelectedCredit={updateSelectedCredit}
            updateSelectedReloadAmount={updateSelectedReloadAmount}
            toggleCreditReload={toggleCreditReload}
            toggleConfirmBox={toggleConfirmBox}
            invoice_type={invoice_type}
          />
          {!auto_reload ? (
            <Wrapper style={{ padding: 25 }}>
              <Row>
                <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                  <Copy>Marketing credits in your account:</Copy>
                </Wrapper>
                <Wrapper>
                  <Copy>
                    {renderPrice(estimated_invoice_info.dealcredit_balance)}
                  </Copy>
                </Wrapper>
              </Row>
              {invoice_type == "mailer_campaign" ? (
                <Row>
                  <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                    <Copy>
                      <Bold>Cost of Mail Sequence:</Bold>
                    </Copy>
                  </Wrapper>
                  <Wrapper>
                    <Copy>
                      <Bold>
                        {renderPrice(
                          estimated_invoice_info.total_price_on_end_of_campaign
                        )}
                      </Bold>
                    </Copy>
                  </Wrapper>
                </Row>
              ) : (
                <Row>
                  <Wrapper style={{ flex: 1, paddingRight: 20 }}>
                    <Copy>Mail in queue or scheduled to send:</Copy>
                  </Wrapper>
                  <Wrapper>
                    <Copy>
                      {renderPrice(
                        estimated_invoice_info.scheduled_to_send_balance,
                        "negative"
                      )}
                    </Copy>
                  </Wrapper>
                </Row>
              )}

              <Wrapper style={{ flex: 1 }}>
                {invoice_type == "mailer_campaign" ? (
                  <Row
                    style={{
                      marginTop: 10,
                      justifyContent: "space-between",
                      alignItems: "flex-start"
                    }}
                  >
                    <Wrapper>
                      <Copy
                        style={{
                          color:
                            estimated_invoice_info.final_dealcredit_balance < 0
                              ? colors.error_color
                              : colors.text_color,
                          fontStyle: "italic"
                        }}
                      >
                        Remaining Balance:
                      </Copy>
                      <Copy
                        style={{
                          fontSize: 12,
                          color: colors.light_text_color
                        }}
                      >
                        (Including mail in queue and scheduled to send)
                      </Copy>
                    </Wrapper>
                    <Copy
                      style={{
                        color:
                          estimated_invoice_info.final_dealcredit_balance < 0
                            ? colors.error_color
                            : colors.text_color,
                        fontStyle: "italic"
                      }}
                    >
                      {renderPrice(
                        estimated_invoice_info.final_dealcredit_balance,
                        "negative"
                      )}
                    </Copy>
                  </Row>
                ) : (
                  <Row
                    style={{ marginTop: 10, justifyContent: "space-between" }}
                  >
                    <Wrapper>
                      <Copy>
                        <Bold>
                          {number_of_leads == 1
                            ? "Balance after skip trace:"
                            : "Balance after skip traces:"}
                        </Bold>
                      </Copy>
                      <Copy
                        style={{
                          fontSize: 12,
                          color: colors.light_text_color
                        }}
                      >
                        (Including mail in queue and scheduled to send)
                      </Copy>
                    </Wrapper>
                    <Copy>
                      <Bold>
                        {renderPrice(
                          estimated_invoice_info.final_dealcredit_balance,
                          "negative"
                        )}
                      </Bold>
                    </Copy>
                  </Row>
                )}
              </Wrapper>
            </Wrapper>
          ) : null}
        </Wrapper>
      );
    }

    return null;
  }
}

export default DealCreditBalance;
