import React, { Component } from "react";
import { SelectItem } from "app/NativeComponents/snippets";
import { Bold, Copy, Wrapper, Row } from "app/NativeComponents/common";
import { renderDate } from "app/NativeActions";

class TimelineItem extends Component {
  render() {
    if (this.props.display) {
      return (
        <SelectItem
          noPress={true}
          select_type="icon"
          select_icon={this.props.icon}
          renderContent={() => {
            return (
              <Wrapper>
                <Row>
                  <Copy>
                    <Bold>{this.props.title}</Bold>
                  </Copy>
                  {this.props.tooltip ? this.props.tooltip : null}
                </Row>
                <Wrapper>
                  <Copy>
                    {this.props.date
                      ? renderDate(this.props.date, this.props.just_date)
                      : null}
                  </Copy>
                </Wrapper>
              </Wrapper>
            );
          }}
        />
      );
    }

    return null;
  }
}

export default TimelineItem;
