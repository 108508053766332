import React, { Component } from "react";

import { Header4 } from "app/NativeComponents/website/common";

const StatTitle = ({ stat }) => {
  if (stat.title) {
    return (
      <Header4>
        <b>{stat.title}</b>
      </Header4>
    );
  }

  return <div />;
};

export default StatTitle;
