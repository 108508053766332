import React, { Component } from "react";
import {
  Wrapper,
  Card,
  Gradient,
  ExternalImage,
  Row,
  Copy,
  Title,
  Bold,
  ContentfulRichText
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";

import CaseStudies from "./CaseStudies";

class News extends Component {
  render() {
    const { colors } = this.props;
    return (
      <Wrapper
        style={{
          alignSelf: "stretch",
          maxWidth: !this.props.dashboardMobile ? 400 : "100%",
          minWidth: 320
        }}
      >
        <CaseStudies {...this.props} />
      </Wrapper>
    );
  }
}

export default News;
