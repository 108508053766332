import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  ContentfulRichText,
  Copy,
  Wrapper,
  KeyboardView
} from "app/NativeComponents/common";
import {
  InlineButton,
  NewHeader,
  SelectBox
} from "app/NativeComponents/snippets";
import {
  loadUpsellModal,
  popSidePanel,
  manageSubscription,
  getTeam
} from "app/NativeActions";
import Addon from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/Addon";

class BuyVoicemailLicense extends Component {
  constructor(props) {
    super(props);

    //check if in props.source_of_truth.dialer_seat there is an item with assigned_to = props.user.id and seat_type = 'dialer'
    const assigned_voicemail_seat =
      props.source_of_truth?.dialer_seats?.filter(
        seat =>
          seat.assigned_user === props.user.id && seat.seat_type === "voicemail"
      )[0] || null;

    this.state = {
      interval_toggle: "month" /*props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month"*/,
      plan_interval: "month" /*props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month"*/,
      contentful_data: null,
      contentful_loading: false,
      assign_to_focus: false,
      assign_to: !assigned_voicemail_seat ? props?.user?.id : null
    };

    this._assign_to_select = React.createRef();
  }

  componentDidMount() {
    this.getContentfulData();

    if (!this.props.team_loading && this.props.my_team?.length === 0) {
      this.props.getTeam({ token: this.props.token, type: "load" });
    }
  }

  getContentfulData() {
    const contentful_slug = "additional_voicemail_seat";
    this.setState({ contentful_loading: true }, () => {
      loadUpsellModal(contentful_slug)
        .then(data => {
          if (data && data.items) {
            if (data.items.length > 0) {
              this.setState({
                contentful_data: data.items[0]
                  ? data.items[0].fields
                    ? data.items[0].fields
                    : null
                  : null,
                contentful_loading: false
              });
            }
          }
          this.setState({ contentful_loading: false });
        })
        .catch(err => {
          this.setState({ contentful_loading: false });
        });
    });
  }

  componentDidUpdate(prevProps) {}

  render() {
    const { colors } = this.props;
    const { contentful_data } = this.state;
    const plan = contentful_data?.planItem?.fields;

    if (!contentful_data) {
      return null;
    }
    const subscription = this.props.source_of_truth?.subscription;
    const is_on_trial = subscription?.status == "is_trialing" ? true : false;
    const button_copy = "Buy AI Voicemail License";

    return (
      <Container>
        <NewHeader
          title={"Buy AI Voicemail License"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />

        <KeyboardView>
          {!!this.state.description ? (
            <Wrapper
              style={{
                padding: 25
              }}
            >
              <Copy>{this.state.description}</Copy>
            </Wrapper>
          ) : !!contentful_data?.content ? (
            <Wrapper
              style={{
                padding: 25
              }}
            >
              <ContentfulRichText richText={contentful_data.content} />
            </Wrapper>
          ) : null}

          <SelectBox
            select_ref={this._assign_to_select}
            items={this.props.my_team}
            disabled={this.props.team_loading}
            loading={this.props.team_loading}
            value={this.state.assign_to}
            placeholder="Assign license to:"
            show={this.state.assign_to_focus}
            onFocus={() => {
              this.setState({ assign_to_focus: true });
            }}
            onBlur={() => {
              this.setState({ assign_to_focus: false });
            }}
            onSelect={item => {
              this.setState({
                assign_to: item
              });
            }}
            onSubmitEditing={() => {}}
          />
          {/*
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <InlineButton
              iconColor={
                this.state.plan_interval == "year" ? colors.success_color : null
              }
              marketing_slug={
                this.state.plan_interval == "year"
                  ? "unselected_annual_on_plan_selection"
                  : "selected_annual_on_plan_selection"
              }
              icon={
                this.state.plan_interval == "year" ? "toggle-on" : "toggle-off"
              }
              style={{
                backgroundColor:
                  this.state.plan_interval == "month"
                    ? colors.orange_color_muted
                    : colors.success_color_muted
              }}
              onPress={() => {
                this.setState({
                  plan_interval:
                    this.state.plan_interval == "month" ? "year" : "month"
                });
              }}
            >
              Save 17% with annual
            </InlineButton>
          </Wrapper>
          */}

          <Addon
            colors={this.props.colors}
            desktopMobile={this.props.desktopMobile}
            device={this.props.device}
            marketing_slug={plan?.marketing_event_slug}
            token={this.props.token}
            title={plan?.title}
            stripe_price_id_month={plan?.stripePriceIdMonthly}
            stripe_price_id_year={plan?.stripePriceIdYearly}
            featured={true}
            price={plan?.monthPrice}
            year_price={plan?.yearPrice}
            plan_interval={this.state.plan_interval}
            includes_items={plan?.includesItems ? plan.includesItems : null}
            description={plan?.description}
            subtext={plan?.subtext}
            manageSubscription={this.props.manageSubscription}
            purchase_type={"purchase_dialer_license"}
            assign_to={this.state.assign_to}
            seat_type={"voicemail"}
            onSuccess={() => {
              this.props.popSidePanel();
            }}
            offer_promo={plan?.promo}
            upsell={true}
            buttonText={button_copy}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing, team }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { source_of_truth } = billing;
  const { my_team, team_loading } = team;
  return {
    token,
    user,
    isMobile,
    colors,
    device,
    source_of_truth,
    my_team,
    team_loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  manageSubscription,
  getTeam
})(BuyVoicemailLicense);
