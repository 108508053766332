import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Row,
  Scroll,
  Copy,
  Spin,
  Icon,
  Bold,
  Button,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  PillButton,
  SelectMultiple,
  SmallHeader,
  GhostButton,
  SpinWrapper
} from "app/NativeComponents/snippets";
import { getLists } from "app/NativeActions";

class ListStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.active_step?.data
        ? props.active_step.data
        : {
            lists: []
          }
    };
  }

  componentDidMount() {
    this.props.getLists({
      token: this.props.token,
      load_type: "load",
      no_count: true,
      type: "all_lists",
      begin: 0
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (this.state.data?.lists.length > 0) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      lists,
      lists_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title={
              active_step.type == "add_to_lists"
                ? "Add to List(s)"
                : active_step.type == "remove_from_lists"
                ? "Remove from List(s)"
                : "Lists"
            }
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {lists_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <SelectMultiple
                  options={this.props.lists}
                  selected_options={data.lists}
                  placeholder={
                    active_step.type == "add_to_lists"
                      ? "Select lists to add:"
                      : active_step.type == "remove_from_lists"
                      ? "Select lists to remove:"
                      : "Select lists:"
                  }
                  search_title="Search lists"
                  onSelectedOptionsChange={options => {
                    this.setState({
                      data: {
                        ...data,
                        lists: options
                      }
                    });
                  }}
                />
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ list }) => {
  const { lists, lists_loading } = list;

  return {
    lists,
    lists_loading
  };
};

export default connect(mapStateToProps, { getLists })(ListStep);
