import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  KeyboardView,
  Animation
} from "app/NativeComponents/common";
import {
  NewHeader,
  ConfirmInlineButton,
  BottomNavBar,
  InputBox,
  ConfirmLabel,
  SelectBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateCustomFields,
  dismissMobileKeyboard
} from "app/NativeActions";

class NewCustomField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      deleting: false,
      show_options: false,
      field_title: props.active_field?.title ? props.active_field.title : "",
      field_type: props.active_field?.field_type
        ? props.active_field.field_type
        : "text",
      field_options: props.active_field?.field_options
        ? props.active_field.field_options
        : "",
      field_type_options: [
        {
          label: "Single-line Text",
          value: "text"
        },
        {
          label: "Multi-line Text",
          value: "multiline_text"
        },
        {
          label: "Multiple Options",
          value: "select"
        },
        {
          label: "Number",
          value: "number"
        },
        {
          label: "Date Picker",
          value: "date"
        }
      ]
    };

    this._field_title = React.createRef();
    this._field_options = React.createRef();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {}

  handleBack() {
    dismissMobileKeyboard();
    this.props.setActiveField(null);
  }

  checkIfNeedsToSave() {
    if (
      this.props.type == "new_field"
        ? !!this.state.field_title &&
          !!this.state.field_type &&
          (this.state.field_type != "select" || !!this.state.field_options)
        : !!this.state.field_title &&
          !!this.state.field_type &&
          (this.state.field_type != "select" || !!this.state.field_options) &&
          (this.state.field_title != this.props.active_field?.title ||
            this.state.field_type != this.props.active_field?.field_type ||
            this.state.field_options != this.props.active_field?.field_options)
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { edit_field_info, colors } = this.props;
    const { field_title, loading, deleting } = this.state;
    return (
      <Container>
        <NewHeader
          title={
            this.props.type == "new_field"
              ? "New Custom Field"
              : "Edit Custom Field"
          }
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._field_title}
            autoFocus={this.props.type == "new_field"}
            name="field_title"
            disabled={loading}
            returnKeyType="done"
            placeholder={
              !!field_title ? "Field name" : "Enter a name for this field"
            }
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                field_title: value
              });
            }}
            blurOnSubmit={true}
            value={field_title}
            input_type="text"
          />

          <SelectBox
            items={this.state.field_type_options}
            disabled={this.state.loading}
            value={this.state.field_type}
            show={this.state.show_options}
            onFocus={() => {
              this.setState({
                show_options: true
              });
            }}
            onBlur={() => {
              this.setState({
                show_options: false
              });
            }}
            placeholder="Field type"
            onSelect={item => {
              this.setState({
                field_type: item,
                show_options: false
              });
            }}
          />
          {this.state.field_type == "select" ? (
            <InputBox
              input_ref={this._field_options}
              name="field_options"
              disabled={loading}
              returnKeyType="done"
              placeholder={"Options (Seperate By Comma)"}
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  field_options: value
                });
              }}
              blurOnSubmit={true}
              value={this.state.field_options}
              input_type="text"
            />
          ) : null}

          <GhostButton
            primary={true}
            button_type="full"
            loading={this.state.loading}
            disabled={!this.checkIfNeedsToSave()}
            onPress={() => {
              this.props.updateCustomFields({
                token: this.props.token,
                type:
                  this.props.type == "new_field"
                    ? "create_custom_field"
                    : "update_custom_field",
                title: this.state.field_title,
                field_type: this.state.field_type,
                field_options: this.state.field_options,
                custom_field_id: this.props.active_field?.id
                  ? this.props.active_field.id
                  : "new_field",
                custom_field_group_id: this.props.custom_field_group_id,
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onSuccess: results => {
                  this.setState({
                    loading: false
                  });
                  this.props.setActiveField(null);
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                }
              });
            }}
          >
            {this.props.type == "new_field"
              ? "Create New Field"
              : "Save Custom Field"}
          </GhostButton>
        </KeyboardView>

        {this.props.type != "new_field" ? (
          <BottomNavBar>
            <ConfirmInlineButton
              confirm_text="Really Delete?"
              confirm_icon="delete"
              loading_text="Deleting"
              loading={this.state.deleting}
              button_type="full"
              onPress={() => {
                this.props.updateCustomFields({
                  token: this.props.token,
                  type: "remove_custom_field",
                  custom_field_id: this.props.active_field?.id,
                  onLoading: () => {
                    this.setState({
                      deleting: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      deleting: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      deleting: false
                    });
                    this.handleBack();
                  }
                });
              }}
            >
              Delete Field
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, field, modal }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { side_panel_views } = modal;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateCustomFields
})(NewCustomField);
