import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  Copy,
  Bold,
  Input,
  Row,
  Button,
  Icon,
  InternalImage
} from "app/NativeComponents/common";
import { Select } from "app/NativeComponents/snippets";

import { updateDashboard, numberWithCommas } from "app/NativeActions";
import moment from "moment";

import Check from "./Check";
class CheckGoals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      save_mode: props.save_mode ? props.save_mode : "auto",
      edit_goals: props.edit_goals ? props.edit_goals : false,
      timeframe_options: [
        {
          label: "3 months",
          value: "3_months"
        },
        {
          label: "6 months",
          value: "6_months"
        },

        {
          label: "1 year",
          value: "1_year"
        },
        {
          label: "2 years",
          value: "2_years"
        },
        {
          label: "5 years",
          value: "5_years"
        }
      ],
      timeframe: props.user.dashboard_goals
        ? props.user.dashboard_goals.timeframe
        : "6_months",
      amount_options: [
        {
          label: "$5,000",
          value: "5000"
        },
        {
          label: "$15,000",
          value: "15000"
        },
        {
          label: "$45,000",
          value: "45000"
        },
        {
          label: "$100,000",
          value: "100000"
        },
        {
          label: "$500,000",
          value: "500000"
        },
        {
          label: "$1,000,000",
          value: "1000000"
        },
        {
          label: "$5,000,000",
          value: "5000000"
        }
      ],
      amount: props.user.dashboard_goals
        ? props.user.dashboard_goals.amount
        : "45000",
      goal_1_options: [
        {
          label: "support my family",
          value: "family"
        },
        {
          label: "supplement my franchise leads",
          value: "supplement"
        },
        {
          label: "scale to get better",
          value: "scale"
        },
        {
          label: "buy back my time",
          value: "time"
        },
        {
          label: "generate more reliable leads",
          value: "leads"
        },
        {
          label: "Buy a vacation home",
          value: "vacation"
        },
        {
          label: "make my friends jealous",
          value: "friends"
        }
      ],
      goal_1: props.user.dashboard_goals
        ? props.user.dashboard_goals.goal_1
        : "family",
      goal_2_options: [
        {
          label: "build wealth",
          value: "build_wealth"
        },
        {
          label: "become self reliant",
          value: "self_reliant"
        },
        {
          label: "avoid stress",
          value: "avoid_stress"
        },
        {
          label: "hold my team accountable",
          value: "team"
        },
        {
          label: "give back to my community",
          value: "community"
        }
      ],
      goal_2: props.user.dashboard_goals
        ? props.user.dashboard_goals.goal_2
        : "build_wealth"
    };
  }

  cancelEdit() {
    this.setState({
      edit_goals: false,
      timeframe: this.props.user.dashboard_goals
        ? this.props.user.dashboard_goals.timeframe
        : "6_months",
      amount: this.props.user.dashboard_goals
        ? this.props.user.dashboard_goals.amount
        : "45000",
      goal_1: this.props.user.dashboard_goals
        ? this.props.user.dashboard_goals.goal_1
        : "family",
      goal_2: this.props.user.dashboard_goals
        ? this.props.user.dashboard_goals.goal_2
        : "build_wealth"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.timeframe !== this.state.timeframe ||
        prevState.amount !== this.state.amount ||
        prevState.goal_1 !== this.state.goal_1 ||
        prevState.goal_2 !== this.state.goal_2) &&
      this.state.save_mode === "auto"
    ) {
      this.saveGoals();
    }
  }

  saveGoals() {
    this.props.updateDashboard({
      token: this.props.token,
      type:
        this.state.save_mode == "confirm"
          ? "dashboard_goals_confirm"
          : "dashboard_goals",
      goals: {
        timeframe: this.state.timeframe,
        amount: this.state.amount,
        goal_1: this.state.goal_1,
        goal_2: this.state.goal_2
      }
    });
  }

  getOptionTitle({ value, array }) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value == value) {
        return array[i].label;
      }
    }
  }

  renderDate() {
    let current_date = moment().format("M-D-YYYY");
    if (!this.state.edit_goals) {
      current_date = this.props.user.dashboard_goals
        ? this.props.user.dashboard_goals.date_created
        : current_date;
    }

    switch (this.state.timeframe) {
      case "6_months":
      default:
        return moment(current_date).add(6, "month").format("M-D-YYYY");

      case "3_months":
        return moment(current_date).add(3, "month").format("M-D-YYYY");

      case "1_year":
        return moment(current_date).add(1, "year").format("M-D-YYYY");

      case "2_years":
        return moment(current_date).add(2, "year").format("M-D-YYYY");

      case "5_years":
        return moment(current_date).add(5, "year").format("M-D-YYYY");
    }
  }

  renderButtons() {
    if (this.state.save_mode == "confirm") {
      if (this.state.edit_goals) {
        return (
          <Row style={{ justifyContent: "center" }}>
            <Button
              onPress={() => {
                this.cancelEdit();
              }}
              style={{
                padding: 5,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10
              }}
            >
              <Row>
                <Icon icon="close" size={14} style={{ marginRight: 5 }} />
                <Copy style={{ fontSize: 12 }}>Cancel</Copy>
              </Row>
            </Button>
            <Button
              onPress={() => {
                this.setState({
                  edit_goals: false
                });

                this.saveGoals();
              }}
              style={{
                padding: 5,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Row>
                <Icon icon="check" size={14} style={{ marginRight: 5 }} />
                <Copy style={{ fontSize: 12 }}>Confirm Reset</Copy>
              </Row>
            </Button>
          </Row>
        );
      } else {
        return (
          <Row style={{ justifyContent: "center" }}>
            <Button
              onPress={() => {
                this.setState({
                  edit_goals: true
                });
              }}
              style={{
                padding: 5,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Row>
                {/*<Icon icon="refresh" size={14} style={{ marginRight: 5 }} />*/}
                <Copy style={{ fontSize: 12 }}>Reset Goals</Copy>
              </Row>
            </Button>
          </Row>
        );
      }
    }
  }

  renderEditOptions() {
    if (this.state.edit_goals) {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            minWidth: 320
          }}
        >
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Copy>
              <Bold>In</Bold>
            </Copy>
            <Wrapper>
              <Card style={{ borderRadius: 30 }}>
                <Select
                  size="small"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    paddingBottom: 5
                  }}
                  item_ref={"timeframe"}
                  items={this.state.timeframe_options}
                  title=""
                  label="Select an option"
                  value={this.state.timeframe}
                  text={this.getOptionTitle({
                    value: this.state.timeframe,
                    array: this.state.timeframe_options
                  })}
                  onSelect={value => {
                    this.setState({
                      timeframe: value
                    });
                  }}
                />
              </Card>
            </Wrapper>
            <Copy>
              <Bold>I will earn</Bold>
            </Copy>
            <Wrapper>
              <Card style={{ borderRadius: 30 }}>
                <Select
                  size="small"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    paddingBottom: 5
                  }}
                  item_ref={"amount"}
                  items={this.state.amount_options}
                  title=""
                  label="Select an option"
                  value={this.state.amount}
                  text={this.getOptionTitle({
                    value: this.state.amount,
                    array: this.state.amount_options
                  })}
                  onSelect={value => {
                    this.setState({
                      amount: value
                    });
                  }}
                />
              </Card>
            </Wrapper>
          </Row>

          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Copy>
              <Bold>to</Bold>
            </Copy>
            <Wrapper>
              <Card style={{ borderRadius: 30 }}>
                <Select
                  size="small"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    paddingBottom: 5
                  }}
                  item_ref={"goal_1"}
                  items={this.state.goal_1_options}
                  title=""
                  label="Select an option"
                  value={this.state.goal_1}
                  text={this.getOptionTitle({
                    value: this.state.goal_1,
                    array: this.state.goal_1_options
                  })}
                  onSelect={value => {
                    this.setState({
                      goal_1: value
                    });
                  }}
                />
              </Card>
            </Wrapper>
          </Row>

          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Copy>
              <Bold>and</Bold>
            </Copy>
            <Wrapper>
              <Card style={{ borderRadius: 30 }}>
                <Select
                  size="small"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    paddingBottom: 5
                  }}
                  item_ref={"goal_2"}
                  items={this.state.goal_2_options}
                  title=""
                  label="Select an option"
                  value={this.state.goal_2}
                  text={this.getOptionTitle({
                    value: this.state.goal_2,
                    array: this.state.goal_2_options
                  })}
                  onSelect={value => {
                    this.setState({
                      goal_2: value
                    });
                  }}
                />
              </Card>
            </Wrapper>
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    return (
      <Wrapper>
        {this.renderEditOptions()}
        <Check
          name={
            this.props.user.lastname && this.props.user.firstname
              ? this.props.user.firstname + " " + this.props.user.lastname
              : this.props.user.email
          }
          amount={this.state.amount}
          date={this.renderDate()}
          goal_1={this.state.goal_1}
          goal_2={this.state.goal_2}
          colors={this.props.colors}
          device={this.props.device}
        />
        {this.renderButtons()}
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device } = native;

  return {
    token,
    user,
    colors,
    device
  };
};

export default connect(mapStateToProps, {
  updateDashboard
})(CheckGoals);
