import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  KeyboardView,
  Row,
  Icon
} from "app/NativeComponents/common";
import {
  ConfirmLabel,
  InputBox,
  ConfirmInlineButton,
  BottomNavBar,
  InformationItem,
  SmallHeader,
  InlineButton
} from "app/NativeComponents/snippets";

import AdvancedFilters from "app/DealMachineCore/reuseable/AdvancedFilters";
import { getHelperDescription } from "../../actions";

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      filter_name: props.saved_filter?.filter_name || "",
      filters: props.saved_filter?.filters || {
        data: [],
        andor_type: "or"
      },
      is_selecting_filter: false,
      save_and_apply: false,
      confirm_save: false
    };

    this._filter_name = React.createRef();

    this.checkIfThereAreFilters = this.checkIfThereAreFilters.bind(this);
  }

  checkIfThereAreFilters() {
    const { property_flags } = this.props.lead_filters;
    if (property_flags.length > 0) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.saved_filter &&
      JSON.stringify(prevProps.saved_filter) !==
        JSON.stringify(this.props.saved_filter)
    ) {
      this.setState({
        filter_name: this.props.saved_filter?.filter_name || "",
        filters: this.props.saved_filter?.filters || {
          data: [],
          andor_type: "or"
        }
      });
    }
  }

  checkIfNeedsToApply() {
    if (
      JSON.stringify(this.props.saved_filter.filters) !=
        JSON.stringify(this.state.filters) &&
      !this.state.is_selecting_filter &&
      this.state.filters.data.length > 0
    ) {
      return true;
    }
    return false;
  }

  checkIfNeedsToSave() {
    if (
      (JSON.stringify(this.props.saved_filter.filters) !=
        JSON.stringify(this.state.filters) ||
        this.state.filter_name !== this.props.saved_filter.filter_name) &&
      !!this.state.filter_name &&
      !this.state.is_selecting_filter &&
      this.state.filters.data.length > 0
    ) {
      return true;
    }
    return false;
  }

  saveFilter(apply) {
    const { filter_name, filters } = this.state;
    const { saved_filter } = this.props;

    this.setState({
      loading: true
    });
    this.props.updateSavedFilters({
      token: this.props.token,
      title: filter_name,
      filters,
      type:
        saved_filter?.id == "new_filter"
          ? "create_saved_filter_with_title"
          : "update_saved_filter",
      advanced_filter_id: saved_filter.id,
      filter_type: "leads",
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (apply) {
          const found_filter = results?.saved_filters.find(
            f => f.id == saved_filter.id
          );
          this.applyFilter(
            found_filter ? found_filter : results?.saved_filters[0]
          );
        }
        this.props.popSidePanel();
        this.setState({
          loading: false,
          confirm_save: false
        });
      }
    });
  }
  applyFilter(new_filter = null) {
    const { saved_filter } = this.props;
    const { filters, filter_name } = this.state;
    this.props.setAppliedFilter({
      applied_filter: new_filter
        ? {
            id: new_filter.id,
            filter_name: new_filter.title,
            filters: new_filter.filters
          }
        : {
            ...saved_filter,
            filter_name,
            filters: filters
          }
    });
  }

  deleteFilter() {
    const { saved_filter } = this.props;
    this.setState({
      deleting: true
    });
    this.props.updateSavedFilters({
      token: this.props.token,
      type: "remove_saved_filter",
      filter_type: "leads",
      system_type: saved_filter.system_type,
      advanced_filter_id: saved_filter.id,
      onLoading: () => {
        this.setState({
          deleting: true
        });
      },
      onError: () => {
        this.setState({
          deleting: false
        });
      },
      onSuccess: results => {
        this.setState({
          deleting: false
        });

        this.props.backToFilters();
      }
    });
  }

  render() {
    const {
      loading,
      deleting,
      filters,
      filter_name,
      is_selecting_filter,
      save_and_apply,
      confirm_save
    } = this.state;
    const { show_save, saved_filter, colors } = this.props;
    const { property_flags, property_flags_and_or } = this.props.lead_filters;

    return (
      <>
        {this.props.saved_filter?.system_type == "legacy_filter" ? (
          <>
            <InformationItem
              item={this.props.saved_filter.filter_name}
              label={"Filter name: "}
              format={"text"}
            />
            <KeyboardView style={{ flex: 1 }}>
              <Wrapper style={{ padding: 25 }}>
                <Copy>
                  You cannot edit a legacy filter. We recommend re-creating this
                  filter using the new filter system and deleting this filter
                  for optimal performance.
                </Copy>
              </Wrapper>
            </KeyboardView>

            <BottomNavBar>
              <ConfirmInlineButton
                button_type="full"
                confirm_text="Really Delete?"
                confirm_icon="delete"
                loading_text="Deleting"
                loading={deleting}
                onPress={() => {
                  this.deleteFilter();
                }}
              >
                Delete Filter
              </ConfirmInlineButton>
            </BottomNavBar>
          </>
        ) : (
          <>
            {this.checkIfThereAreFilters() ? (
              <>
                {property_flags && property_flags.length > 0 ? (
                  <>
                    <SmallHeader
                      title={
                        property_flags_and_or == "and"
                          ? "Quick filters (matching all): "
                          : "Quick filters: "
                      }
                    />

                    <Row
                      style={{
                        flexWrap: "wrap",
                        paddingRight: 25,
                        paddingLeft: 25
                      }}
                    >
                      {property_flags &&
                        property_flags.length > 0 &&
                        property_flags.map((flag, i) => {
                          return (
                            <Wrapper
                              key={"flag" + i}
                              style={{
                                backgroundColor: colors.active_color_muted,
                                borderRadius: 5,
                                padding: 5,
                                paddingRight: 15,
                                paddingLeft: 15,
                                marginRight: 5,
                                marginBottom: 5
                              }}
                            >
                              <Row>
                                <Copy style={{ fontSize: 12 }}>
                                  {flag?.label}
                                </Copy>

                                <Wrapper
                                  tooltipPlacement={"top"}
                                  tooltip={getHelperDescription(flag?.value)}
                                  style={{ marginLeft: 5 }}
                                >
                                  <Icon
                                    icon={"help"}
                                    color={colors.light_text_color}
                                    size={12}
                                  />
                                </Wrapper>
                              </Row>
                            </Wrapper>
                          );
                        })}
                    </Row>
                  </>
                ) : null}
              </>
            ) : null}
            {this.checkIfNeedsToApply() && !save_and_apply ? (
              <ConfirmLabel
                style={{
                  alignSelf: "stretch"
                }}
                primary={true}
                icon="tune"
                label={"Filters changed"}
                loading={loading}
                secondaryButton={
                  this.props.saved_filter.id == "new_filter"
                    ? "Save Filter"
                    : confirm_save
                    ? "Just Save"
                    : ""
                }
                onSecondaryPress={() => {
                  this.saveFilter(true);
                }}
                confirmButton={
                  this.props.saved_filter.id == "new_filter"
                    ? "Apply"
                    : confirm_save
                    ? "Save And Apply"
                    : "Save"
                }
                onPress={() => {
                  if (this.props.saved_filter.id == "new_filter") {
                    this.applyFilter();
                    this.props.popSidePanel();
                  } else if (confirm_save) {
                    this.saveFilter(true);
                  } else {
                    this.setState({
                      confirm_save: true
                    });
                  }
                }}
              />
            ) : (show_save &&
                filters &&
                filters?.data?.length > 0 &&
                !is_selecting_filter) ||
              save_and_apply ? (
              <InputBox
                input_ref={this._filter_name}
                autoFocus={save_and_apply ? true : false}
                name="filter_name"
                disabled={false}
                returnKeyType="done"
                style={{
                  margin: 5,
                  borderRadius: 5
                }}
                placeholder={
                  !!filter_name ? "Filter name" : "Enter a name for this filter"
                }
                onFocus={() => {}}
                onChange={value => {
                  this.setState({
                    filter_name: value
                  });
                }}
                blurOnSubmit={true}
                value={filter_name}
                input_type="text"
                require_confirm={true}
                has_changed={
                  (!!filter_name &&
                    filter_name != this.props.saved_filter?.filter_name) ||
                  this.checkIfNeedsToSave()
                }
                submit_button_primary={true}
                submit_button_title={
                  this.checkIfNeedsToApply() ? "Save and Apply" : "Save Filter"
                }
                onSubmit={() => {
                  this.saveFilter(this.checkIfNeedsToApply());
                }}
                onSubmitEditing={() => {
                  this.saveFilter(this.checkIfNeedsToApply());
                }}
                loading={loading}
              />
            ) : null}

            <AdvancedFilters
              filters={filters}
              onSelecting={is_selecting => {
                this.setState({
                  is_selecting_filter: is_selecting
                });
              }}
              onChange={filters => {
                this.setState({
                  filters
                });
              }}
              renderHeader={
                !this.state.is_selecting_filter && !show_save
                  ? () => {
                      return (
                        <Row>
                          <Wrapper style={{ flex: 1 }}>
                            <SmallHeader title={"Select a filter:"} />
                          </Wrapper>
                          {this.props.saved_filters &&
                          this.props.saved_filters?.length > 0 ? (
                            <InlineButton
                              onPress={() => this.props.showSavedFilters(true)}
                            >
                              Saved Filters
                            </InlineButton>
                          ) : null}
                        </Row>
                      );
                    }
                  : null
              }
            />
          </>
        )}

        {show_save &&
        filters &&
        filters?.data?.length > 0 &&
        this.props.saved_filter?.id &&
        saved_filter.id != "new_filter" &&
        !is_selecting_filter ? (
          <BottomNavBar>
            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Delete?"
              confirm_icon="delete"
              loading_text="Deleting"
              loading={deleting}
              onPress={() => {
                this.deleteFilter();
              }}
            >
              Delete Filter
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : null}
      </>
    );
  }
}

export default Filter;
