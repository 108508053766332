import React from "react";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";

const PrimaryCTA = ({ children, to, slug = null, style, onPress }) => {
  return (
    <Link
      to={to}
      className="dm-website-primary-cta"
      style={style}
      onClick={ev => {
        if (onPress) {
          onPress();
        }

        //track click function
        if (slug) {
          ReactGA.event({
            category: "CTA",
            action: slug
          });
        }
      }}
    >
      {children}
    </Link>
  );
};

export { PrimaryCTA };
