import React from "react";
import {
  Wrapper,
  Copy,
  Bold,
  Title,
  Button,
  Row,
  Blur,
  Icon
} from "app/NativeComponents/common";
import { SmallLabel } from "app/NativeComponents/snippets";
import {
  toTitleCase,
  numberFormat,
  openUrl,
  openEmail,
  callPhoneNumber,
  checkIfUserHasSubscription,
  getHelperDescription
} from "app/NativeActions";
import { store } from "app/store";
import moment from "moment";
import ManageDataUpdate from "app/DealMachineCore/reuseable/ManageDataUpdate";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";

const handleUrlPress = url => {
  openUrl(url);
};

const InformationItem = ({
  style,
  innerStyle,
  label,
  item,
  format,
  size,
  onPress = null,
  search = "",
  include_data_update_flag = false,
  slug,
  property = null,
  propertyUpdated = null,
  hiddenFromNonSubs = false,
  helper = null
}) => {
  const colors = store.getState().settings.colors;
  const device = store.getState().native.device;
  let formatted_item = "";
  if (item != "" && item != null) {
    switch (format) {
      default:
        formatted_item = item;
        if (formatted_item == 0 || formatted_item == "0") {
          formatted_item = "--";
        }
        break;

      case "url":
      case "email":
      case "phone":
        formatted_item = item;
        if (!formatted_item) {
          formatted_item = "--";
        }
        break;

      case "name":
      case "text":
        formatted_item = toTitleCase(item);

        break;

      case "company_name":
        formatted_item = toTitleCase(item);
        break;

      case "money":
      case "price":
        if (parseInt(item) == 0) {
          formatted_item = "--";
        } else {
          formatted_item = "$" + numberFormat(parseInt(item));
        }

        break;

      case "date":
        formatted_item = moment(item).format("MMM Do, YYYY");
        break;
      case "percent":
        formatted_item = parseInt(parseFloat(item) * 100) + "%";
        break;
      case "interest_rate":
        formatted_item = item && item != "0" ? item + "%" : "--";
        break;
      case "term":
        if (parseInt(item) > 60) {
          formatted_item = item + " Month";
        } else {
          formatted_item = item + " Year";
        }
        break;
      case "number":
        formatted_item = numberFormat(parseInt(item));

        break;
      case "acre":
        formatted_item = parseFloat(item).toFixed(2) + " acres";
        break;

      case "feet":
        formatted_item = numberFormat(parseInt(item)) + " ft";
        break;
      case "sqft":
        formatted_item = numberFormat(parseInt(item)) + " sqft";
        break;
    }
  } else {
    formatted_item = "--";
  }

  if (label.toLowerCase().indexOf(search?.toLowerCase()) !== -1) {
    if (
      (((format == "phone" || format == "email" || format == "url") &&
        formatted_item != "--") ||
        onPress) &&
      (checkIfUserHasSubscription() || !hiddenFromNonSubs)
    ) {
      return (
        <Button
          onPress={() => {
            if (onPress) {
              onPress();
            } else if (format == "url") {
              openUrl(item);
            } else if (format == "email") {
              openEmail({ email: item });
            } else if (format == "phone") {
              callPhoneNumber(item);
            }
          }}
          style={{ padding: 15, paddingRight: 25, paddingLeft: 25, ...style }}
        >
          <SmallLabel style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}>
            {label}
          </SmallLabel>
          <Copy>{formatted_item}</Copy>
        </Button>
      );
    }

    return (
      <Wrapper style={{ ...style }}>
        <Row
          style={{
            padding: 15,
            paddingRight: 25,
            paddingLeft: 25,
            ...innerStyle
          }}
        >
          <Wrapper style={{ flex: 1 }}>
            <Row>
              <SmallLabel
                style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
              >
                {label}
              </SmallLabel>
              {!!helper ? (
                device === "mobile" ? (
                  <MoreInfoButton
                    title={label}
                    content={getHelperDescription(helper)}
                    style={{ alignSelf: "stretch" }}
                    innerStyle={{ alignSelf: "stretch" }}
                    renderComponent={({ hovering, pressedIn, buttonText }) => {
                      return (
                        <Wrapper style={{ marginLeft: 5 }}>
                          <Icon
                            icon={"help"}
                            color={colors.light_text_color}
                            size={16}
                          />
                        </Wrapper>
                      );
                    }}
                  />
                ) : (
                  <Wrapper
                    tooltipPlacement={"top"}
                    tooltip={getHelperDescription(helper)}
                    style={{ marginLeft: 5 }}
                  >
                    <Icon
                      icon={"help"}
                      color={colors.light_text_color}
                      size={12}
                    />
                  </Wrapper>
                )
              ) : null}
            </Row>

            {!checkIfUserHasSubscription() && hiddenFromNonSubs ? (
              <Blur>
                <Copy>Upgrade to view</Copy>
              </Blur>
            ) : (
              <Copy>{formatted_item}</Copy>
            )}
          </Wrapper>
          {include_data_update_flag &&
          property &&
          checkIfUserHasSubscription() ? (
            <ManageDataUpdate
              property={property}
              slug={slug}
              format={format}
              propertyUpdated={propertyUpdated}
            />
          ) : null}
        </Row>
      </Wrapper>
    );
  }
  return null;
};

export { InformationItem };
