import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView, Wrapper } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  SelectBox,
  GhostButton,
  AttentionBox,
  SmallLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  saveCard,
  billingFieldChanged,
  dismissMobileKeyboard,
  stripeCardAction,
  showErrorMessage,
  showSuccess
} from "app/NativeActions";
import StripeForm from "app/NativeComponents/components/StripeForm";

class CreditCardOnboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  saveCard(card_token, error) {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    const { token, card, platform } = this.props;
    const { address } = this.state;
    if (card_token || error) {
      this.props.stripeCardAction({
        token: token,
        card_token: card_token,
        error: error,
        type: "add_stripe_payment_method",
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });

          this.props.showSuccess(
            "Your card has been saved to your account.",
            "Success!"
          );

          if (panel_data.onSuccess) {
            panel_data.onSuccess();
          } else {
            this.handleBack();
          }
        }
      });
    } else {
      const { number, expMonth, expYear, cvc } = card;
      this.props.stripeCardAction({
        token: token,
        number,
        expMonth,
        expYear,
        cvc,
        type: "add_stripe_payment_method",
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });

          this.props.showSuccess(
            "Your card has been saved to your account.",
            "Success!"
          );

          if (panel_data.onSuccess) {
            panel_data.onSuccess();
          } else {
            this.handleBack();
          }
        }
      });
    }
  }

  render() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    return (
      <Container>
        <NewHeader
          title="Add Card On File"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {!!panel_data?.description ? (
            <AttentionBox description={panel_data?.description} />
          ) : null}
          <StripeForm
            {...this.props}
            loading={this.state.loading}
            buttonText={
              panel_data?.buttonText ? panel_data.buttonText : "Save Card"
            }
            saveBilling={(token, error) => {
              this.saveCard(token, error);
            }}
            card={this.props.card}
            billingFieldChanged={this.props.billingFieldChanged}
          />
          <Wrapper style={{ marginBottom: 40 }}></Wrapper>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, states } = settings;
  const { card } = billing;
  return { token, user, device, colors, states, card };
};

export default connect(mapStateToProps, {
  popSidePanel,
  saveCard,
  billingFieldChanged,
  stripeCardAction,
  showSuccess
})(CreditCardOnboarding);
