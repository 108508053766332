import React, { Component } from "react";

import {
  Wrapper,
  Card,
  Map,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { ToggleSwitch } from "app/NativeComponents/snippets";

class AutomatedFollowUps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 0
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);

    if (this.state.scene == 0) {
      this.setState({ scene: 1 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 4000);
    } else if (this.state.scene == 1) {
      this.setState({ scene: 0 });
      this._interval = setTimeout(() => {
        this.startScene();
      }, 4000);
    }
  }

  renderLengend() {
    if (this.state.scene > 0) {
      return (
        <Wrapper>
          <Row>
            <Row
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Wrapper
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#4EE8C3",
                  marginRight: 5
                }}
              />
              <Copy style={{ fontSize: 10 }}>0-6 months</Copy>
            </Row>

            <Row
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Wrapper
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#F9F871",
                  marginRight: 5
                }}
              />
              <Copy style={{ fontSize: 10 }}>6-12 months</Copy>
            </Row>

            <Row
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Wrapper
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: "#B24C63",
                  marginRight: 5
                }}
              />
              <Copy style={{ fontSize: 10 }}>12-24 months</Copy>
            </Row>
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 200,
          textAlign: "left"
        }}
      >
        <div className="animated fadeIn">
          <Card>
            <CardBody
              style={{
                padding: "10px 15px"
              }}
            >
              <ToggleSwitch
                onChange={() => {}}
                value={this.state.scene < 1 ? false : true}
                title={"Show Driving Routes?"}
                text={"Shows past driving routes on the map."}
              />
              {this.renderLengend()}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default AutomatedFollowUps;
