import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { KeyboardView, Wrapper, Copy, Bold } from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  SpinWrapper,
  UpsellButton
} from "app/NativeComponents/snippets";
import {
  getListBuilderTemplates,
  getNewFiltersString,
  renderDate
} from "app/NativeActions";

class ListBuilderTemplates extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      templates_loading: false
    };
  }

  componentDidMount() {
    this.props.getListBuilderTemplates({
      token: this.props.token,
      onLoading: () => {
        this.setState({
          templates_loading:
            this.props.list_builder_templates.length == 0 ? true : false
        });
      },
      onError: () => {
        this.setState({
          templates_loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          templates_loading: false
        });
      }
    });
  }

  renderRecentlyBuilt() {
    const { recently_built_lists } = this.props;

    if (this.state.templates_loading) {
      return <SpinWrapper text="Loading..." />;
    }

    if (recently_built_lists?.length > 0) {
      return (
        <>
          <SmallLabel
            style={{
              padding: 25
            }}
          >
            Start from a recently built list.
          </SmallLabel>
          {recently_built_lists &&
            recently_built_lists.map((built_list, i) => {
              return (
                <SelectItem
                  key={"list_builder_template_" + i}
                  select_type="none"
                  icon="keyboard-arrow-right"
                  onPress={() => {
                    this.props.selectTemplate({
                      slug: built_list.id,
                      filters: built_list.filters
                    });
                    this.props.nextStep("filters");
                  }}
                  //description={getNewFiltersString(built_list.filters)}
                  subtext={renderDate(built_list.date_created)}
                  //require_confirm={true}
                >
                  {built_list.title}
                </SelectItem>
              );
            })}
        </>
      );
    }
  }

  render() {
    const {
      colors,
      filters,
      selected_template,
      list_builder_templates,
      recently_built_lists,
      list_builder_limits,
      source_of_truth
    } = this.props;

    let over_the_limit = false;
    if (this.props.user?.user_version >= 6) {
      over_the_limit =
        source_of_truth?.subscription?.metadata?.list_builder_filters &&
        source_of_truth?.subscription?.metadata?.list_builder_filters !=
          "unlimited" &&
        source_of_truth?.current_limits?.number_of_list_builder_filters
          ?.amount > 0;
    } else {
      over_the_limit =
        list_builder_limits &&
        list_builder_limits?.limit != -1 &&
        list_builder_limits?.used_filters?.length > 0;
    }

    return (
      <KeyboardView style={{ flex: 1 }}>
        {over_the_limit ? (
          <Wrapper
            style={{
              padding: 25,
              margin: 5,
              borderRadius: 5
            }}
          >
            {this.props.user?.user_version >= 6 ? (
              <Copy style={{ color: colors.orange_color }}>
                You have built{" "}
                {
                  source_of_truth?.current_limits
                    ?.number_of_list_builder_filters?.amount
                }{" "}
                of your{" "}
                {source_of_truth?.subscription?.metadata[
                  "list_builder_filters"
                ] == "unlimited"
                  ? "unlimited list types"
                  : source_of_truth?.subscription?.metadata[
                      "list_builder_filters"
                    ] == 1
                  ? "1 list type"
                  : source_of_truth?.subscription?.metadata[
                      "list_builder_filters"
                    ] + " list types"}{" "}
                allowed this month. Your account will reset on{" "}
                {renderDate(
                  source_of_truth?.subscription?.monthly_limit_end_date,
                  true
                )}
                .
              </Copy>
            ) : (
              <Copy style={{ color: colors.orange_color }}>
                You have built {list_builder_limits?.used_filters?.length} of
                your{" "}
                {list_builder_limits?.limit == -1
                  ? "unlimited list types"
                  : list_builder_limits?.limit == 1
                  ? "1 list type"
                  : list_builder_limits?.limit + " list types"}{" "}
                allowed this month. Your account will reset on{" "}
                {renderDate(list_builder_limits?.reset_day, true)}.
              </Copy>
            )}
          </Wrapper>
        ) : null}

        {/*over_the_limit ? this.renderRecentlyBuilt() : null*/}
        {list_builder_templates.length > 0 ? (
          <>
            {list_builder_templates.map((template, i) => {
              return (
                <UpsellButton
                  key={"template_" + i}
                  type="plan"
                  slug="lists"
                  tier={0}
                  force_lock={
                    list_builder_limits?.limit != -1 &&
                    list_builder_limits?.limit <=
                      list_builder_limits?.used_filters?.length &&
                    this.props.user?.user_version < 6
                  }
                  meta_slug="list_builder_filters"
                  force_unlock={
                    (list_builder_limits?.limit == -1 &&
                      this.props.user?.user_version < 6) ||
                    (this.props.user?.user_version >= 6 &&
                      !source_of_truth?.subscription?.metadata?.list_builder)
                  }
                  contentful_slug="unlock_list_builder"
                  onPress={() => {
                    this.props.selectTemplate({
                      slug: template.slug,
                      filters: template.filters
                    });
                    this.props.nextStep("filters");
                  }}
                  renderLockedChildren={({ hovering }) => {
                    return (
                      <SelectItem
                        key={"list_builder_template_" + i}
                        noPress={true}
                        pressedIn={hovering}
                        select_type="none"
                        icon="lock"
                        //description={template.description}
                      >
                        {template.title}
                      </SelectItem>
                    );
                  }}
                  renderChildren={({ hovering }) => {
                    return (
                      <SelectItem
                        key={"list_builder_template_" + i}
                        noPress={true}
                        pressedIn={hovering}
                        select_type="none"
                        icon="keyboard-arrow-right"

                        //description={template.description}
                      >
                        {template.title}
                      </SelectItem>
                    );
                  }}
                />
              );
            })}
          </>
        ) : null}
        <UpsellButton
          type="plan"
          slug="lists"
          tier={0}
          force_lock={
            filters.data.length == 0 &&
            list_builder_limits?.limit != -1 &&
            list_builder_limits?.limit <=
              list_builder_limits?.used_filters?.length &&
            this.props.user?.user_version < 6
          }
          force_unlock={
            (list_builder_limits?.limit == -1 &&
              this.props.user?.user_version < 6) ||
            (this.props.user?.user_version >= 6 &&
              !source_of_truth?.subscription?.metadata?.list_builder)
          }
          contentful_slug="unlock_list_builder"
          meta_slug="list_builder_filters"
          onPress={() => {
            this.props.selectTemplate({ slug: "start_from_stratch" });
            this.props.nextStep("filters");
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <SelectItem
                noPress={true}
                pressedIn={hovering}
                select_type="none"
                icon="lock"
                primary={true}

                //description="Select filters to build your list."
              >
                Create a filter
              </SelectItem>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <SelectItem
                noPress={true}
                primary={true}
                pressedIn={hovering}
                select_type="none"
                icon="keyboard-arrow-right"
                //description="Select filters to build your list."
              >
                {filters.data.length > 0
                  ? "Continue building"
                  : "Create a filter"}
              </SelectItem>
            );
          }}
        />
        {/*!over_the_limit ? */ this.renderRecentlyBuilt() /* : null*/}
      </KeyboardView>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, list, billing }) => {
  const { token } = auth;
  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { list_builder_templates, recently_built_lists } = list;
  const { source_of_truth } = billing;
  return {
    token,
    colors,
    isMobile,
    platform,
    device,
    list_builder_templates,
    recently_built_lists,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  getListBuilderTemplates
})(ListBuilderTemplates);
