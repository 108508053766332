import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Scroll,
  KeyboardView
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  SmallLabel,
  SelectItem,
  InlineButton,
  IconButton,
  GhostButton,
  SearchBar,
  SmallHeader
} from "app/NativeComponents/snippets";

import { selectActiveRoute } from "app/NativeActions";

import ViewRoute from "app/DealMachineCore/components/MainMap/MapSidePanel/Routes/ViewRoute";

class RouteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card_height: 0
    };
  }

  render() {
    const { colors, route } = this.props;

    const height = this.state.card_height;
    return (
      <Wrapper
        style={{
          flex: 1,
          backgroundColor: colors.popover_color,
          overflow: "hidden"
        }}
      >
        <ViewRoute
          current_index={this.props.index}
          onResize={({ height }) => {
            this.setState({
              card_height: height
            });
            this.props.updateSliderHeight({
              height: height + 80
            });
          }}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;

  const { device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile
  };
};

export default connect(mapStateToProps, {
  selectActiveRoute
})(RouteItem);
