import React, { Component } from "react";
import { store } from "app/store";
import { Row, Wrapper } from "app/NativeComponents/common";
import { GhostButton, InlineButton } from "app/NativeComponents/snippets";
import LeadActionsButton from "./LeadActionsButton";
import DriveListButton from "./DriveListButton";
import AssignToCampaignButton from "./AssignToCampaignButton";
import DialerButton from "./DialerButton";
import { checkIfUserHasMetadata, formatUsPhone } from "app/NativeActions";

class ActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrubDNC: !!props.user?.user_settings?.dialer_settings
        ? props.user?.user_settings?.dialer_settings?.scrub_dnc
        : props.default_dialer_settings?.scrub_dnc,
      scrubDNCLoading: false
    };

    this.toggleScrubDNC = this.toggleScrubDNC.bind(this);
  }

  toggleScrubDNC = () => {
    let dialer_settings = this.props.user?.user_settings?.dialer_settings;
    if (!dialer_settings) {
      dialer_settings = {
        callback_number: formatUsPhone(this.props.user?.team_phone),
        ...this.props.default_dialer_settings
      };
    }

    this.setState(
      prevState => ({ scrubDNC: !prevState.scrubDNC }),
      () => {
        this.props.updateUserSettings({
          token: this.props.token,
          type: "dialer_settings",
          value: {
            ...dialer_settings,
            scrub_dnc: this.state.scrubDNC
          },
          onLoading: () => {
            this.setState({ scrubDNCLoading: true });
          },
          onError: () => {
            this.setState({ scrubDNCLoading: false });
          },
          onSuccess: () => {
            // Update was successful
            this.setState({ scrubDNCLoading: false });
          }
        });
      }
    );
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user?.user_settings?.dialer_settings?.scrub_dnc !==
      this.props.user?.user_settings?.dialer_settings?.scrub_dnc
    ) {
      this.setState({
        scrubDNC: this.props.user?.user_settings?.dialer_settings?.scrub_dnc
      });
    }
  }

  render() {
    const { active_list_tab, selected_leads, selected_all, filtered_count } =
      this.props;
    const is_list =
      active_list_tab?.id && (selected_leads.length == 0 || selected_all)
        ? true
        : false;

    const is_actionable =
      ((active_list_tab?.id && selected_leads.length == 0) ||
        selected_leads.length > 0 ||
        selected_all) &&
      active_list_tab?.building != 1
        ? true
        : false;
    const selected_all_leads =
      (active_list_tab?.id && selected_leads?.length == 0) || selected_all;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;

    const phone_plan = checkIfUserHasMetadata("dialer");

    return (
      <>
        {!isMobile ? (
          <Row>
            {this.props.user?.team_owner == 1 ||
            this.props.user?.can_send_message == 1 ||
            ((this.props.user.team_owner == 1 ||
              this.props.user.team_clearance_level > 1) &&
              checkIfUserHasMetadata("free_plan") &&
              !checkIfUserHasMetadata("dialer")) ? (
              <Row
                style={{
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: colors.border_color,
                  backgroundColor: colors.background_color,
                  borderStyle: "solid",
                  borderRadius: 30,
                  padding: 0,
                  margin: 5,
                  marginRight: 0
                }}
              >
                <InlineButton
                  icon={this.state.scrubDNC ? "toggle-on" : "toggle-off"}
                  iconColor={this.state.scrubDNC ? colors.success_color : null}
                  onPress={this.toggleScrubDNC}
                  tooltip='Filter out phone numbers that are on the federal "Do Not Call" list.'
                  tooltipPlacement="top"
                  style={{ margin: 0 }}
                  loading={this.state.scrubDNCLoading}
                >
                  Scrub DNC
                </InlineButton>
                <DialerButton
                  action_button={true}
                  filtered_count={filtered_count}
                  is_actionable={is_actionable}
                  is_list={is_list}
                  selected_leads={selected_leads}
                  selected_all={selected_all_leads}
                  clearAll={this.props.clearAll}
                  applied_filter={this.props.applied_filter}
                  search={this.props.search}
                  search_type={this.props.search_type}
                  active_list_tab={this.props.active_list_tab}
                  lead_filters={this.props.lead_filters}
                />
              </Row>
            ) : null}

            {is_list && !phone_plan ? (
              <DriveListButton
                action_button={true}
                filtered_count={filtered_count}
                is_actionable={is_actionable}
                is_list={is_list}
                selected_leads={selected_leads}
                selected_all={selected_all_leads}
                clearAll={this.props.clearAll}
                applied_filter={this.props.applied_filter}
                search={this.props.search}
                search_type={this.props.search_type}
                active_list_tab={this.props.active_list_tab}
                lead_filters={this.props.lead_filters}
              />
            ) : null}

            <AssignToCampaignButton
              action_button={true}
              filtered_count={filtered_count}
              is_actionable={is_actionable}
              is_list={is_list}
              selected_leads={selected_leads}
              selected_all={selected_all_leads}
              clearAll={this.props.clearAll}
              applied_filter={this.props.applied_filter}
              search={this.props.search}
              search_type={this.props.search_type}
              active_list_tab={this.props.active_list_tab}
              lead_filters={this.props.lead_filters}
            />
            <LeadActionsButton {...this.props} />
          </Row>
        ) : (
          <LeadActionsButton {...this.props} />
        )}
      </>
    );
  }
}

export default ActionButtons;
