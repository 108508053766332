import React, { Component } from "react";

const Markdown = require("react-markdown");

const StatDescription = ({ stat }) => {
  if (stat.description) {
    return <Markdown source={stat.description} escapeHtml={false} />;
  }

  return <div />;
};

export default StatDescription;
