import React from "react";
import { store } from "app/store";

const InternalImage = ({ style, image, date_created, contain, alt = "" }) => {
  if (image && image != "") {
    const dark_mode = store.getState().settings.dark_mode;

    return (
      <img
        alt={alt}
        className={contain ? "deal-image-contain" : "deal-image"}
        src={image}
        style={{ ...style, opacity: dark_mode == "dark_mode" ? 0.6 : 1 }}
      />
    );
  }

  return <div style={style} />;
};

export { InternalImage };
