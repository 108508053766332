import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Scroll,
  InnerCopy,
  KeyboardView,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  IconButton,
  SmallLabel,
  BottomNavBar,
  InlineButton,
  CloseButton
} from "app/NativeComponents/snippets";
import {
  pushSidePanel,
  getPropertyNew,
  updateLead,
  determineDisplayProperty,
  determineMainOwnerInfo,
  toTitleCase,
  numberFormat,
  numberWithCommas,
  formatAddress
} from "app/NativeActions";
import moment from "moment";
import OwnerUpdateDisplay from "./OwnerUpdateDisplay";

class ManageDataUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      property_loading: false,
      property: props.property
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.property &&
      JSON.stringify(prevProps.property) !== JSON.stringify(this.props.property)
    ) {
      this.setState({
        property: this.props.property
      });
    } else if (prevState.show != this.state.show && this.state.show) {
      this.getData();
    }
  }

  getData() {
    if (!this.state.property_loading) {
      this.props.getPropertyNew({
        token: this.props.token,
        property_id: this.state.property?.property_id,
        onLoading: () => {
          this.setState({ property_loading: true });
        },
        onError: () => {
          this.setState({ property_loading: false });
        },
        onSuccess: results => {
          this.setState({
            property_loading: false,
            property:
              results.property?.property_id == this.state?.property?.property_id
                ? {
                    ...this.state.property,
                    ...results.property
                  }
                : this.state.property
          });
        }
      });
    }
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      tooltip,
      popoverPlacement = "bottom",
      slug,
      icon_style
    } = this.props;
    const { show, property } = this.state;

    let has_updates = false;
    let current_data = "";
    let is_owner_slug = false;
    if (
      property?.deal &&
      !property?.deal?.removed &&
      property?.deal?.lead_data_update_confirmation &&
      property?.deal?.last_property_history &&
      (slug == "owner" ||
        slug == "owner_name" ||
        slug == "owner_firstname" ||
        slug == "owner_lastname" ||
        slug == "owner_status" ||
        slug == "owner_address" ||
        slug == "saleprice" ||
        slug == "saledate" ||
        slug == "calculated_total_value" ||
        slug == "calculated_improvement_value" ||
        slug == "calculated_land_value" ||
        slug == "mortgage_amount" ||
        slug == "tax_market_improvements_percent" ||
        slug == "lender_name" ||
        slug == "mortgage_date" ||
        slug == "mortgage_term" ||
        slug == "mortgage_due_date" ||
        slug == "second_mortgage_amount" ||
        slug == "mortgage_interest_rate" ||
        slug == "mortgage_financing_type" ||
        slug == "mortgage_loan_type" ||
        slug == "second_mortgage_interest_rate" ||
        slug == "second_mortgage_loan_type" ||
        slug == "mortgage_financing_type" ||
        slug == "equity_percent" ||
        slug == "is_vacant" ||
        slug == "is_vacant_text" ||
        slug == "preforclosure_status_text" ||
        slug == "preforclosure_status")
    ) {
      switch (slug) {
        case "owner_status":
        case "owner_status_info":
          if (
            property?.owner_status_info?.slug !=
            property?.deal?.last_property_history.owner_status_info?.slug
          ) {
            has_updates = true;
          }
          break;

        case "owner":
          if (
            (property?.deal?.custom_owner &&
              (property?.deal?.owner_status_info?.slug !=
                property?.owner_status_info?.slug ||
                property?.deal?.owner_name != property?.owner_name ||
                property?.deal?.owner_address != property?.owner_address ||
                property?.deal?.owner_address2 != property?.owner_address2 ||
                property?.deal?.owner_address_city !=
                  property?.owner_address_city ||
                property?.deal?.owner_address_state !=
                  property?.owner_address_state ||
                property?.deal?.owner_address_zip !=
                  property?.owner_address_zip)) ||
            property?.deal?.owner_name !=
              property?.deal?.last_property_history?.owner_name ||
            property?.deal?.owner_address !=
              property?.deal?.last_property_history?.owner_address ||
            property?.deal?.owner_address2 !=
              property?.deal?.last_property_history?.owner_address2 ||
            property?.deal?.owner_address_city !=
              property?.deal?.last_property_history?.owner_address_city ||
            property?.deal?.owner_address_state !=
              property?.deal?.last_property_history?.owner_address_state ||
            property?.deal?.owner_address_zip !=
              property?.deal?.last_property_history?.owner_address_zip
          ) {
            has_updates = true;
          }
          break;

        default:
          if (property?.[slug] != property?.deal?.last_property_history[slug]) {
            has_updates = true;
          }
          break;
      }
    }

    if (
      slug == "owner" ||
      slug == "owner_name" ||
      slug == "owner_firstname" ||
      slug == "owner_lastname" ||
      slug == "owner_status" ||
      slug == "owner_address"
    ) {
      is_owner_slug = true;
    }

    const display_property = determineDisplayProperty(property);
    const formatted_property_address = formatAddress({
      address: {
        address: display_property.property_address,
        address2: display_property.property_address2,
        address_city: display_property.property_address_city,
        address_state: display_property.property_address_state,
        address_zip: display_property.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    if (has_updates) {
      return (
        <PopoverMenu
          show={show}
          no_swipe={true}
          no_cancel={true}
          onShow={s => {
            this.setState({
              show: s
            });
          }}
          popover_width={350}
          popoverSheetTop={"50%"}
          popoverPlacement={popoverPlacement}
          popover_title={""}
          includeCloseButton={true}
          renderComponent={options => {
            if (this.props.device == "mobile") {
              return (
                <Row>
                  <IconButton
                    icon="system-update-alt"
                    primary={true}
                    noPress={true}
                    pressedIn={options.hovering || options.pressed_in || show}
                    tooltip={tooltip}
                    tooltipPlacement="top"
                    button_type={"small"}
                    style={icon_style}
                  />
                </Row>
              );
            }
            return (
              <Wrapper wrapper_ref={this._popover} style={{ flex: 1 }}>
                <IconButton
                  icon="system-update-alt"
                  primary={true}
                  noPress={true}
                  pressedIn={options.hovering || options.pressed_in || show}
                  tooltip={tooltip}
                  tooltipPlacement="top"
                  button_type={"small"}
                  style={icon_style}
                />
              </Wrapper>
            );
          }}
          renderMenu={() => {
            return (
              <Wrapper style={{ alignSelf: "stretch" }}>
                {device == "desktop" ? (
                  <CloseButton
                    style={{ marginTop: 5 }}
                    onPress={() => {
                      this.setState({
                        show: false
                      });
                    }}
                  />
                ) : null}
                <KeyboardView style={{ flex: 1 }}>
                  <OwnerUpdateDisplay
                    slug={slug}
                    property={property}
                    format={this.props.format}
                    include_title={
                      "Data updates for " + formatted_property_address.line1
                    }
                  />
                </KeyboardView>
                <BottomNavBar>
                  <Row
                    style={{
                      justifyContent: !property?.deal?.custom_owner
                        ? "space-between"
                        : "center"
                    }}
                  >
                    {!property?.deal?.custom_owner ? (
                      <InlineButton
                        primary={true}
                        loading={this.state.loading}
                        onPress={() => {
                          this.props.updateLead({
                            token: this.props.token,
                            deal_ids: property?.deal?.id,
                            type: "accept_data_changes",
                            accept_new_owner: 1,
                            onLoading: () => {
                              this.setState({
                                loading: true
                              });
                            },
                            onError: () => {
                              this.setState({
                                loading: false
                              });
                            },
                            onSuccess: results => {
                              if (
                                this.props.propertyUpdated &&
                                results?.properties?.length == 1
                              ) {
                                this.props.propertyUpdated(
                                  results.properties[0]
                                );
                              }

                              this.setState({
                                loading: false,
                                show: false
                              });
                            }
                          });
                        }}
                      >
                        Acknowledge Changes
                      </InlineButton>
                    ) : null}

                    <InlineButton
                      button_type={property?.deal?.custom_owner ? "full" : ""}
                      onPress={() => {
                        this.setState(
                          {
                            show: false
                          },
                          () => {
                            this.props.pushSidePanel({
                              slug: "view_lead_data_updates",
                              overlay: true,
                              data: {
                                property,
                                propertyUpdated: this.props.propertyUpdated
                              }
                            });
                          }
                        );
                      }}
                    >
                      Review Updates
                    </InlineButton>
                  </Row>
                </BottomNavBar>
              </Wrapper>
            );
          }}
          menu_items={null}
        />
      );
    }
    return null;
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;
  return {
    token,
    user,
    isMobile,
    device,
    colors
  };
};

export default connect(mapStateToProps, {
  updateLead,
  pushSidePanel,
  getPropertyNew
})(ManageDataUpdate);
