import React, { PureComponent } from "react";
import GoogleMapReact, { Polyline } from "google-map-react";

import Crosshairs from "./Crosshairs";
import CurrentLocation from "./CurrentLocation";
import Pin from "./Pin";

import { isPointInPolygon } from "geolib";

import { sample_data } from "./SampleData";

import { renderCurrentRoute, renderRoutes } from "./Routes";

import { renderProperties } from "./Properties";

import { getMapOptions } from "./MapOptions";

const default_lat = 39.97411;
const default_lng = -86.11756;

class Map extends PureComponent {
  constructor(props) {
    super(props);

    let lat = default_lat;
    let lng = default_lng;

    if (props.user) {
      if (props.user.default_lat && props.user.default_lng) {
        lat = parseFloat(props.user.default_lat);
        lng = parseFloat(props.user.default_lng);
      }
    }

    if (this.props.region) {
      lat = this.props.region.latitude ? this.props.region.latitude : lat;
      lng = this.props.region.longitude ? this.props.region.longitude : lng;
    } else if (this.props.lat && this.props.long) {
      lat = this.props.lat ? this.props.lat : lat;
      lng = this.props.long ? this.props.long : lng;
    }

    this.state = {
      isMapReady: false,
      map: null,
      maps: null,
      lat: lat,
      lng: lng,
      current_lat: 0,
      current_lng: 0,
      error: null,

      polygon_clicked: null
    };
  }

  onMapReady(map, maps) {
    this.setState({ isMapReady: true, map: map, maps: maps });
    if (this.props.routes) {
      renderRoutes(this.state.map, this.props.routes, this.props.user);
    }
  }

  togglePolygonClicked(toggle) {
    this.setState({ polygon_clicked: toggle });
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    //we'll want to add something to update this in the future as the user moves
    if (!this.props.dontGetPosition) {
      navigator.geolocation.getCurrentPosition(
        position => {
          /*
           this.setState({
             current_lat: position.coords.latitude,
             current_lng: position.coords.longitude,
             error: null,
           });
           */
        },
        error => this.setState({ error: error.message }),
        { enableHighAccuracy: true }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.routes) {
      if (prevProps.routes != this.props.routes && this.state.isMapReady) {
        renderRoutes(this.state.map, this.props.routes, this.props.user);
      }
    }

    if (
      this.props.region_toggle == true &&
      prevProps.region_toggle == false &&
      this.props.region
    ) {
      this.setState({
        lat: this.props.region.latitude,
        lng: this.props.region.longitude
      });
    }
  }

  checkIfCoordinatesAreInPolyon(point) {
    for (let i = 0; i < this.props.properties.length; i++) {
      for (let j = 0; j < this.props.properties[i].coordinates.length; j++) {
        if (isPointInPolygon(point, this.props.properties[i].coordinates[j])) {
          return this.props.properties[i];
        }
      }
    }

    return false;
  }

  render() {
    let d_lat = default_lat;
    let d_lng = default_lng;

    if (this.props.user) {
      if (this.props.user.default_lat && this.props.user.default_lng) {
        d_lat = parseFloat(this.props.user.default_lat);
        d_lng = parseFloat(this.props.user.default_lng);
      }
    }

    const {
      style,
      lat = d_lat,
      long = d_lng,
      region,
      initialRegion,
      onRegionChange,
      onRegionChangeComplete,
      cacheEnabled,
      withMarker,
      onPress,
      markers = [],
      routes = [],
      mapType,
      zoom = 18,
      scrollEnabled,
      pitchEnabled,
      renderCustomMarker = () => {
        return <div />;
      },
      onMarkerPress = () => {},
      locationSelect,
      pin = { lat: null, lng: null },

      properties = [],
      onPropertyPress = () => {},
      property_map_options = {}
    } = this.props;
    //if(lat != null && long != null){
    return (
      <div className="deal-map-container" style={style}>
        <GoogleMapReact
          ref="map"
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: "AIzaSyCSqWxybt6K0wSW77qnddvl7evwcybsYqI" }}
          center={{
            lat: this.state.lat,
            lng: this.state.lng
          }}
          options={getMapOptions(
            mapType,
            this.props.darkMode,
            this.props.disableDefaultUI
          )}
          onGoogleApiLoaded={({ map, maps }) => {
            this.onMapReady(map, maps);
          }}
          onChange={({ center, bounds }) => {
            if (center) {
              const longitudeDelta = Math.abs(bounds.se.lng - bounds.nw.lng);
              const latitudeDelta = Math.abs(bounds.se.lat - bounds.nw.lat);

              this.setState({
                lat: center.lat,
                lng: center.lng
              });

              if (center) {
                onRegionChangeComplete({
                  latitude: center.lat,
                  longitude: center.lng,
                  latitudeDelta: latitudeDelta,
                  longitudeDelta: longitudeDelta
                });
              }
            }
          }}
          draggable={scrollEnabled}
          mapTypeId={mapType}
          zoom={zoom}
        >
          {/*
              <CurrentLocation
                lat={this.state.current_lat}
                lng={this.state.current_lng}
              />
              */}

          <Pin
            lat={this.props.pin ? this.props.pin.lat : 0}
            lng={this.props.pin ? this.props.pin.lng : 0}
            {...this.props}
          />
        </GoogleMapReact>

        <Crosshairs {...this.props} />
      </div>
    );
  }
}

export { Map };
