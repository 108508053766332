import React, { useEffect, useState, useMemo } from "react";

import {
  Wrapper,
  Card,
  Row,
  Spin,
  Copy,
  Icon,
  DraggableList
} from "app/NativeComponents/common";
import { Select, SelectItem } from "app/NativeComponents/snippets";

const ListView = ({ ...props }) => {
  const checkColumnSearch = column_title => {
    if (props.column_search && props.column_search.length > 0) {
      if (
        column_title
          .toLowerCase()
          .startsWith(props.column_search.toLowerCase().trim())
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  if (props.list_columns) {
    if (props.list_columns.length > 0) {
      return (
        <>
          <DraggableList
            items={props.list_columns}
            reverse={false}
            style={{ flex: 1, width: 400 }}
            disabled={true}
            renderHandle={() => {
              return (
                <Wrapper
                  className="dm-drag"
                  style={{
                    padding: 15,
                    paddingLeft: 0,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Icon icon="drag-indicator" size={18} />
                </Wrapper>
              );
            }}
            renderItem={({ item, wrappedHandle }) => {
              if (item.main_column !== true && checkColumnSearch(item.title)) {
                return (
                  <SelectItem
                    key={"column_i" + item.slug}
                    disabled={item.main_column}
                    selected={item.main_column === true || !!item.show}
                    onPress={() => {
                      props.checkListItem(item);
                    }}
                    select_type="check"
                  >
                    {item.title}
                  </SelectItem>
                );
              }
              return null;
            }}
            onReorder={({ previousIndex, nextIndex }) => {
              props.reorderItems({
                previousIndex,
                nextIndex
              });
            }}
          />
        </>
      );
    }
  }

  if (props.list_columns.length === 0) {
    return (
      <Wrapper>
        <Wrapper
          style={{
            padding: 20,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Row>
            <Spin size="small" />
            <Copy style={{ marginLeft: 10 }}>Loading...</Copy>
          </Row>
        </Wrapper>
      </Wrapper>
    );
  }

  return <Wrapper />;
};
export default ListView;
