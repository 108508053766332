import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  Copy,
  KeyboardView,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  SearchBar,
  List,
  InlineButton,
  IconButton,
  SelectItem,
  BottomNavBar,
  PopoverMenu,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLists,
  updateStackedLists,
  triggerLeadsReload,
  renderDate
} from "app/NativeActions";
class ListStackSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      original_list_ids:
        props.lists && props.lists.length > 0
          ? props.lists.filter(
              ({ included_in_stack_count }) => included_in_stack_count
            )
          : [],
      stacked_lists:
        props.lists && props.lists.length > 0
          ? props.lists.filter(
              ({ included_in_stack_count }) => included_in_stack_count
            )
          : [],
      loading: false
    };
  }

  componentDidMount() {
    this.getData({ load_type: "refresh" });
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.lists) !== JSON.stringify(prevProps.lists)) {
      this.setState({
        stacked_lists:
          this.props.lists && this.props.lists.length > 0
            ? this.props.lists.filter(
                ({ included_in_stack_count }) => included_in_stack_count
              )
            : [],
        original_list_ids:
          this.props.lists && this.props.lists.length > 0
            ? this.props.lists.filter(
                ({ included_in_stack_count }) => included_in_stack_count
              )
            : []
      });
    }
  }

  checkIfNeedsToSave() {
    if (
      this.state.stacked_lists.length > 0 &&
      JSON.stringify(this.state.stacked_lists) !==
        JSON.stringify(this.state.original_list_ids)
    ) {
      return true;
    }

    return false;
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  getData({ load_type = "load" }) {
    const { lists, token, lists_loading, lists_refreshing, lists_begin } =
      this.props;

    if (!lists_loading && !lists_refreshing) {
      this.props.getLists({
        token: this.props.token,
        load_type: lists.length == 0 ? "load" : "reload",
        no_count: true,
        type: "all_lists",
        begin: 0
      });
    }
  }

  render() {
    const {
      colors,
      isMobile,
      lists_refreshing,
      lists,
      lists_loading,
      lists_loaded_all
    } = this.props;
    const { search, stacked_lists } = this.state;
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"List Stacking"}
          subtitle={""}
          leftButton={{
            icon: this.props.device == "mobile" ? "arrow-back" : "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <>
          <ConfirmLabel
            style={{
              alignSelf: "stretch"
            }}
            icon="playlist-add-check"
            label={
              stacked_lists.length == 1
                ? "1 list selected for Stack Count"
                : stacked_lists.length + " lists selected for Stack Count"
            }
            primary={this.checkIfNeedsToSave()}
            confirmButton={
              this.checkIfNeedsToSave() ? "Confirm Changes" : "Edit Levels"
            }
            loading={this.state.loading}
            onPress={() => {
              if (this.checkIfNeedsToSave()) {
                this.props.updateStackedLists({
                  token: this.props.token,
                  list_ids: this.state.stacked_lists
                    .map(list => {
                      return list.id;
                    })
                    .join(","),
                  //list_stacking_level_1: this.state.list_stacking_level_1,
                  //list_stacking_level_2: this.state.list_stacking_level_2,
                  //list_stacking_level_3: this.state.list_stacking_level_3,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                    this.props.triggerLeadsReload(true);
                    this.props.popSidePanel();
                  }
                });
              } else {
                this.props.pushSidePanel({
                  slug: "list_stack_level_settings",
                  overlay: true
                });
              }
            }}
          />

          <Row>
            <Wrapper style={{ flex: 1 }}>
              <SearchBar
                value={search}
                style={{ margin: 10, marginRight: 0 }}
                title="Search Lists"
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
              />
            </Wrapper>
            {this.state.stacked_lists.length == this.props.lists.length ? (
              <InlineButton
                onPress={() => {
                  this.setState({
                    stacked_lists: []
                  });
                }}
              >
                Clear All
              </InlineButton>
            ) : (
              <InlineButton
                onPress={() => {
                  this.setState({
                    stacked_lists: this.props.lists
                  });
                }}
              >
                Select All
              </InlineButton>
            )}
          </Row>
          {lists_loading && lists.length == 0 ? (
            <Wrapper style={{ flex: 1 }}>
              <SpinWrapper text="Loading Lists..." />
            </Wrapper>
          ) : (
            <>
              <List
                style={{ flex: 1 }}
                items={this.filterOptions(lists)}
                itemStructure={({ item, index }) => {
                  let in_stacked_lists = false;
                  for (let i = 0; i < stacked_lists.length; i++) {
                    if (item.id == stacked_lists[i].id) {
                      in_stacked_lists = true;
                    }
                  }

                  return (
                    <SelectItem
                      key={"option_" + index}
                      //hover_icon={"keyboard-arrow-right"}
                      subtext={renderDate(item.date_created)}
                      selected={in_stacked_lists}
                      onPress={() => {
                        if (in_stacked_lists) {
                          this.setState({
                            stacked_lists: stacked_lists.filter(
                              ({ id }) => id !== item.value
                            )
                          });
                        } else {
                          this.setState({
                            stacked_lists: [...stacked_lists, item]
                          });
                        }
                      }}
                    >
                      {item.title}
                    </SelectItem>
                  );
                }}
              />
            </>
          )}
        </>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  list,
  property_map,
  lead
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const {
    lists,
    lists_loading,
    lists_refreshing,
    lists_begin,
    lists_loaded_all,
    lists_limit,
    list_modal,
    list_search
  } = list;
  const { active_property, map_properties } = property_map;
  const { list_properties } = lead;
  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,

    lists,
    lists_loading,
    lists_refreshing,
    lists_begin,
    lists_loaded_all,
    lists_limit,
    list_modal,
    list_search,
    active_property,
    map_properties,
    list_properties
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getLists,
  pushSidePanel,
  updateStackedLists,
  triggerLeadsReload
})(ListStackSettings);
