import React, { Component } from "react";

import { Wrapper } from "app/NativeComponents/common";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = JSON.parse(JSON.stringify(list));
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class DragDropList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: items
    });

    this.props.handleReorder(items);
  }

  renderHeader(listHeader) {
    if (listHeader) {
      return listHeader();
    }
  }
  renderFooter(listFooter) {
    if (listFooter) {
      return listFooter();
    }
  }

  render() {
    if (this.state.items) {
      return (
        <Wrapper id={this.props.id}>
          {this.renderHeader(this.props.listHeader)}
          <DragDropContext
            onDragEnd={(this.onDragEnd = this.onDragEnd.bind(this))}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.items.map((item, index) => (
                    <Draggable
                      key={"draggable_" + index}
                      draggableId={"draggable_" + index}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {this.props.itemStructure({
                            item,
                            index,
                            isDragging: snapshot.isDragging
                          })}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {this.renderFooter(this.props.listFooter)}
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export { DragDropList };
