export const GET_DEALS = "get_deals";
export const GET_MAP_DEALS = "get_map_deals";
export const SET_ACTIVE_DEAL = "set_active_deal";

export const SET_DEAL_SCROLL_POSITION = "set_deal_scroll_position";

export const GET_DEALS_FAIL = "get_deals_fail";
export const GET_DEALS_SUCCESS = "get_deals_success";
export const GET_MAP_DEALS_SUCCESS = "get_map_deals_success";

export const GET_DEALS_SUCCESS_REPLACE = "get_deals_success_replace";
export const RELOAD_DEALS = "reload_deals";

export const GET_SINGLE_DEAL = "get_single_deal";
export const GET_SINGLE_DEAL_FAIL = "get_single_deal_fail";
export const GET_SINGLE_DEAL_SUCCESS = "get_single_deal_success";

export const SEARCH_CHANGED = "search_changed";
export const EDIT_SEARCH_CHANGED = "edit_search_changed";
export const FILTER_CHANGED = "filter_changed";

export const TOGGLE_DEALS_OPTIONS = "toggle_deals_options";
export const TOGGLE_TEAM_OPTIONS = "toggle_team_options";

export const SELECT_DEAL = "select_deal";
export const DESELECT_DEAL = "deselect_deal";
export const SELECT_ALL_DEALS = "select_all_deals";
export const DESELECT_ALL_DEALS = "deselect_all_deals";

export const REFRESH_DEALS = "refresh_deals";
export const LOAD_MORE_DEALS = "load_more_deals";
export const NEW_DEAL = "new_deal";
export const NEW_DEAL_FAIL = "new_deal_fail";
export const NEW_DEAL_SUCCESS = "new_deal_success";
export const VALID_ADDRESS = "validate_address";

export const INIT_EDIT_FILTERS = "init_edit_filters";
export const EDIT_FILTER = "edit_filter";
export const SAVE_FILTERS = "save_filters";
export const CLEAR_FILTER = "clear_filter";
export const CLEAR_ALL_FILTERS = "clear_all_filters";

export const INIT_EDIT_DEALS = "init_edit_deals";
export const EDIT_DEALS = "edit_deals";
export const SAVE_EDIT_DEALS = "save_edit_deals";
export const SAVE_EDIT_DEALS_FAIL = "save_edit_deals_fail";
export const SAVE_EDIT_DEALS_SUCCESS = "save_edit_deals_success";
export const SAVE_EDIT_DEALS_SUCCESS_DELETE = "save_edit_deals_success_delete";
export const TOGGLE_INVALID_MODAL = "toggle_invalid_modal";
export const INVALID_NEW_DEAL_ADDRESS = "invalid_new_deal";
export const VALID_ADDRESS_FAIL = "valid_address";

export const GET_TOTAL_PURCHASE_AMOUNT = "get_total_purchase_amount;";
