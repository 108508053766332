import {
  LOGOUT,
  ADDRESS_INIT,
  ADDRESS_FIELD_CHANGE,
  UPDATE_RETURN_ADDRESS,
  UPDATE_RETURN_ADDRESS_FAIL,
  UPDATE_RETURN_ADDRESS_SUCCESS,
  RESET_SETTINGS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_PHOTO_TYPE,
  INIT_UPDATE_USER,
  UPDATE_USER_FIELD_CHANGE,
  SELECT_STATE,
  SELECT_PROFILE_PHOTO,
  UPLOAD_PROFILE_PHOTO,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_FAIL,
  UPLOAD_PROFILE_PROGRESS,
  UPLOAD_PROFILE_ANIMATION,
  SELECT_SIGNATURE_PHOTO,
  UPLOAD_SIGNATURE_PHOTO,
  UPLOAD_SIGNATURE_PHOTO_SUCCESS,
  UPLOAD_SIGNATURE_PHOTO_FAIL,
  UPLOAD_SIGNATURE_PROGRESS,
  UPLOAD_SIGNATURE_ANIMATION,
  SELECT_DEFAULT_SENDING_OPTIONS,
  SET_EDIT_RETURN_LOCATION,
  CREATE_TEMPLATE_SUCCESS,
  SIGNATURE_INIT,
  SET_DARK_MODE,
  SET_PHOTO_CAMERAROLL,
  UPLOAD_LIST_SUCCESS,
  UPLOAD_LIST_MAPPING_SUCCESS,
  EDIT_BULK_UPLOAD_INFO,
  UPDATE_USER_SETTINGS_SUCCESS,
  GET_DASHBOARD_SETTINGS,
  GET_DASHBOARD_SETTINGS_SUCCESS,
  GET_MENTOR_AUTOCOMPLETE,
  GET_MENTOR_AUTOCOMPLETE_FAIL,
  GET_MENTOR_AUTOCOMPLETE_SUCCESS,
  CLEAR_MENTOR_AUTOCOMPLETE,
  SET_TRACKING,
  TOGGLE_BULK_UPLOAD_MODAL,
  BYPASS_LOCATION
} from "app/DealMachineCore/types";

import { AppConfig } from "app/NativeActions";

const INITIAL_STATE = {
  return_address: {
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phone: ""
  },
  tracking: true,
  loading: false,
  photoCameraRoll: false,
  map_style: null,
  error: "",
  photo_type: "camera",
  editUser: {},
  upload_list_info: null,
  upload_photo: {
    source: null,
    location: "",
    uploading: false,
    uploaded: false,
    uploaded_animation: false,
    progress: 0,
    error: "",
    use_full: false,
    did_upload: false
  },
  edit_return_location: null,
  states: [
    {
      name: "Alabama",
      abbr: "AL"
    },
    {
      name: "Alaska",
      abbr: "AK"
    },
    {
      name: "American Samoa",
      abbr: "AS"
    },
    {
      name: "Arizona",
      abbr: "AZ"
    },
    {
      name: "Arkansas",
      abbr: "AR"
    },
    {
      name: "California",
      abbr: "CA"
    },
    {
      name: "Colorado",
      abbr: "CO"
    },
    {
      name: "Connecticut",
      abbr: "CT"
    },
    {
      name: "Delaware",
      abbr: "DE"
    },
    {
      name: "District Of Columbia",
      abbr: "DC"
    },
    {
      name: "Florida",
      abbr: "FL"
    },
    {
      name: "Georgia",
      abbr: "GA"
    },
    {
      name: "Guam",
      abbr: "GU"
    },
    {
      name: "Hawaii",
      abbr: "HI"
    },
    {
      name: "Idaho",
      abbr: "ID"
    },
    {
      name: "Illinois",
      abbr: "IL"
    },
    {
      name: "Indiana",
      abbr: "IN"
    },
    {
      name: "Iowa",
      abbr: "IA"
    },
    {
      name: "Kansas",
      abbr: "KS"
    },
    {
      name: "Kentucky",
      abbr: "KY"
    },
    {
      name: "Louisiana",
      abbr: "LA"
    },
    {
      name: "Maine",
      abbr: "ME"
    },
    {
      name: "Maryland",
      abbr: "MD"
    },
    {
      name: "Massachusetts",
      abbr: "MA"
    },
    {
      name: "Michigan",
      abbr: "MI"
    },
    {
      name: "Minnesota",
      abbr: "MN"
    },
    {
      name: "Mississippi",
      abbr: "MS"
    },
    {
      name: "Missouri",
      abbr: "MO"
    },
    {
      name: "Montana",
      abbr: "MT"
    },
    {
      name: "Nebraska",
      abbr: "NE"
    },
    {
      name: "Nevada",
      abbr: "NV"
    },
    {
      name: "New Hampshire",
      abbr: "NH"
    },
    {
      name: "New Jersey",
      abbr: "NJ"
    },
    {
      name: "New Mexico",
      abbr: "NM"
    },
    {
      name: "New York",
      abbr: "NY"
    },
    {
      name: "North Carolina",
      abbr: "NC"
    },
    {
      name: "North Dakota",
      abbr: "ND"
    },
    {
      name: "Ohio",
      abbr: "OH"
    },
    {
      name: "Oklahoma",
      abbr: "OK"
    },
    {
      name: "Oregon",
      abbr: "OR"
    },
    {
      name: "Pennsylvania",
      abbr: "PA"
    },
    {
      name: "Puerto Rico",
      abbr: "PR"
    },
    {
      name: "Rhode Island",
      abbr: "RI"
    },
    {
      name: "South Carolina",
      abbr: "SC"
    },
    {
      name: "South Dakota",
      abbr: "SD"
    },
    {
      name: "Tennessee",
      abbr: "TN"
    },
    {
      name: "Texas",
      abbr: "TX"
    },
    {
      name: "Utah",
      abbr: "UT"
    },
    {
      name: "Vermont",
      abbr: "VT"
    },
    {
      name: "Virgin Islands",
      abbr: "VI"
    },
    {
      name: "Virginia",
      abbr: "VA"
    },
    {
      name: "Washington",
      abbr: "WA"
    },
    {
      name: "West Virginia",
      abbr: "WV"
    },
    {
      name: "Wisconsin",
      abbr: "WI"
    },
    {
      name: "Wyoming",
      abbr: "WY"
    },
    {
      name: "Armed Forces",
      abbr: "AE"
    },
    {
      name: "Armed Forces America",
      abbr: "AA"
    },
    {
      name: "Armed Forces Pacific",
      abbr: "AP"
    },
    {
      name: "Alberta",
      abbr: "AB"
    },

    {
      name: "British Columbia",
      abbr: "BC"
    },
    {
      name: "Manitoba",
      abbr: "MB"
    },
    {
      name: "New Brunswick",
      abbr: "NB"
    },
    {
      name: "Newfoundland and Labrador",
      abbr: "NL"
    },
    {
      name: "Northwest Territories",
      abbr: "NT"
    },
    {
      name: "Nova Scotia",
      abbr: "NS"
    },
    {
      name: "Nunavut",
      abbr: "NU"
    },
    {
      name: "Ontario",
      abbr: "ON"
    },
    {
      name: "Prince Edward Island",
      abbr: "PE"
    },
    {
      name: "Quebec",
      abbr: "QC"
    },
    {
      name: "Saskatchewan",
      abbr: "SK"
    },
    {
      name: "Yukon",
      abbr: "YT"
    }
  ],
  select_default_sending_options: false,
  notification: [],
  notification_settings: {
    all_push_notifications: true,
    smart_list_email_updates: true
  },
  bypass_location: false,
  dark_mode:
    AppConfig().dark_mode == "dark_mode" ? "dark_mode" : "dark_mode_off",
  dark_mode_colors: {
    white_color: "rgba(255, 255, 255, 0.87)",
    card_button_color: "#bdbdbd",

    white_text_color: "rgba(255, 255, 255, 0.6)",

    light_text_color: "rgba(255, 255, 255, 0.37)",

    dot_color: "#192129",

    blue_color: "#d8f0f4",
    active_color: "#028FA3",
    active_color_muted: "rgba(0, 0, 0, 0.25)",

    gradient_color_1: "#192129",
    gradient_color_2: "#192129",
    gradient_button_color_1: "#028FA3",
    gradient_button_color_2: "#00A9A9",

    gradient_color_1_opacity: "rgba(30, 30, 30, 0.85)",
    gradient_color_2_opacity: "rgba(30, 30, 30, 0.85)",
    side_gradient_color_1: "#192129",
    side_gradient_color_2: "#192129",

    success_color: "#00897B",
    success_card_color: "#00897B",
    success_color_muted: "#002924",
    error_color: "#B24C63",
    error_color_muted: "#B24C63",
    error_card_color: "rgba(0, 0, 0, 0.25)",
    yellow_color: "#ff8f00",
    yellow_card_color: "#ff8f00",
    yellow_card_color_muted: "rgba(0, 0, 0, 0.25)",
    green_color: "#14ccad",

    background_color: "#1F2933",
    card_color: "#1F2933",
    popover_color: "#192129",

    light_grey_color: "#192129",
    grey_color: "#192129",

    white_color_opacity: "rgba(0, 0, 0, 0.25)",

    orange_color: "#E08F02", //#D06518
    orange_color_muted: "#192129",
    orange_color_light: "#fdf1c2",

    purple_color: "#444BC6",
    purple_color_muted: "#5a5c7a",
    pink_color: "#E546D9",

    gray_selected_color: "#495663",
    text_color: "rgba(255, 255, 255, 0.6)",
    text_button_color: "#323F4B",
    title_color: "rgba(255, 255, 255, 0.87)",
    actionable_text_color: "#3891A6",

    border_color: "#192129",
    hover_color: "rgba(0,0,0,0.05)",
    hover_white_color: "rgba(255,255,255,0.25)",
    dark_phone_color: "#192129",
    dark_phone_inner_color: "#1F2933"
  },
  regular_colors: {
    white_color: "#ffffff",
    card_color: "#ffffff",
    popover_color: "#ffffff",

    card_button_color: "#ffffff",

    white_text_color: "#ffffff",

    //text_color: "#2C2C2C",
    //text_button_color: "#2C2C2C",
    //title_color: "#2C2C2C",
    dot_color: "#2C2C2C",

    blue_color: "#d8f0f4",
    active_color: "#31cce5",
    active_color_muted: "#D0F3F9",

    gradient_color_1: "#31CCE5",
    gradient_color_2: "#4EE8C3",
    gradient_button_color_1: "#31CCE5",
    gradient_button_color_2: "#4EE8C3",

    side_gradient_color_1: "#004F64",
    side_gradient_color_2: "#00747f",
    gradient_color_1_opacity: "rgba(255, 255, 255, 0.85)",
    gradient_color_2_opacity: "rgba(255, 255, 255, 0.85)",

    success_color: "#4EE8C3",
    success_card_color: "#4EE8C3",
    success_color_muted: "#c9f8ed",

    error_color: "#B24C63",
    error_color_muted: "#FFD4E8",

    error_card_color: "#B24C63",

    yellow_color: "#ff8f00",
    yellow_card_color: "#ff8f00",
    yellow_card_color_muted: "#fff6eb",

    //border_color: "#eeeeee",

    green_color: "#14ccad",

    background_color: "#f7f7f7", //"f7f7f7"
    light_grey_color: "#eeeeee",
    light_gray_color: "#eeeeee",
    grey_color: "#eeeeee",
    gray_color: "#eeeeee",

    white_color_opacity: "rgba(255, 255, 255, 0.75)",

    orange_color: "#F2633A", //#D06518
    orange_color_muted: "#fdf1c2",
    orange_color_light: "#fdf1c2",

    purple_color: "#444BC6",
    purple_color_muted: "#cacdfc",
    pink_color: "#E546D9",

    gray_selected_color: "#E5EAEC",
    text_color: "#2C2C2C",
    title_color: "#2C2C2C",
    light_text_color: "#acacac",

    text_button_color: "#E5EAEC",
    actionable_text_color: "#004F64",
    border_color: "#e8e8e8",
    hover_color: "rgba(0,0,0,0.05)",
    hover_white_color: "rgba(255,255,255,0.25)",
    dark_phone_color: "#192129",
    dark_phone_inner_color: "#1F2933"
  },
  colors: {
    white_color: "#ffffff",
    card_color: "#ffffff",
    popover_color: "#ffffff",

    card_button_color: "#ffffff",

    white_text_color: "#ffffff",

    //text_color: "#2C2C2C",
    //text_button_color: "#2C2C2C",

    //title_color: "#2C2C2C",
    dot_color: "#2C2C2C",

    blue_color: "#d8f0f4",
    active_color: "#31cce5",
    active_color_muted: "#D0F3F9",

    gradient_color_1: "#31CCE5",
    gradient_color_2: "#4EE8C3",
    gradient_button_color_1: "#31CCE5",
    gradient_button_color_2: "#4EE8C3",

    gradient_color_1_opacity: "rgba(255, 255, 255, 0.85)",
    gradient_color_2_opacity: "rgba(255, 255, 255, 0.85)",

    side_gradient_color_1: "#004F64",
    side_gradient_color_2: "#004F64",

    success_color: "#4EE8C3",
    success_card_color: "#4EE8C3",
    success_color_muted: "#c9f8ed",
    error_color: "#B24C63",
    error_color_muted: "#FFD4E8",

    error_card_color: "#B24C63",

    yellow_color: "#ff8f00",
    yellow_card_color: "#ff8f00",
    yellow_card_color_muted: "#fff6eb",

    green_color: "#14ccad",

    //border_color: "#eeeeee",

    background_color: "#f7f7f7", //"f7f7f7",
    light_grey_color: "#eeeeee",
    light_gray_color: "#eeeeee",
    grey_color: "#eeeeee",
    gray_color: "#eeeeee",

    white_color_opacity: "rgba(255, 255, 255, 0.75)",

    orange_color: "#F2633A", //#D06518
    orange_color_muted: "#fdf1c2",
    orange_color_light: "#fdf1c2",

    purple_color: "#444BC6",
    purple_color_muted: "#cacdfc",
    pink_color: "#E546D9",

    gray_selected_color: "#E5EAEC",
    text_color: "#2C2C2C",
    title_color: "#2C2C2C",
    light_text_color: "#acacac",

    text_button_color: "#E5EAEC",
    actionable_text_color: "#004F64",
    border_color: "#e8e8e8",
    hover_color: "rgba(0,0,0,0.05)",
    hover_white_color: "rgba(255,255,255,0.25)",
    dark_phone_color: "#192129",
    dark_phone_inner_color: "#1F2933"
  },
  starting_point: "dashboard",
  dashboard_settings_loading: false,
  properties_added_last_30_days: 0,
  properties_added_last_24_hours: 0,
  dashboard_goals: null,
  active_snapshot: [],

  mentor_autocomplete_error: "",
  mentor_autocomplete_items: [],
  mentor_autocomplete_loading: false,

  enterprise_goal_variables: {
    state: "",
    marketing_cost: 0,
    mailers_per_lead: 0,
    properties_per_hour: 0,
    dollars_per_hour: 0,
    leads_per_deal: 0
  },
  show_upload_modal: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_SETTINGS:
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case ADDRESS_FIELD_CHANGE:
      return {
        ...state,
        return_address: {
          ...state.return_address,
          [action.payload.prop]: action.payload.value
        }
      };
    case ADDRESS_INIT:
      return {
        ...state,
        return_address: action.payload.return_address
      };
    case UPDATE_RETURN_ADDRESS:
      return {
        ...state,
        error: "",
        loading: true
      };
    case UPDATE_RETURN_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_RETURN_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        return_address: {
          ...state.return_address,
          address: action.payload.address,
          address2: action.payload.address2,
          city: action.payload.address_city,
          state: action.payload.address_state,
          zipcode: action.payload.address_zip,
          phone: action.payload.phone
        }
      };
    case SELECT_STATE:
      return {
        ...state,
        return_address: {
          ...state.return_address,
          state: action.payload.state
        }
      };
    case UPDATE_USER:
      return {
        ...state,
        error: "",
        loading: true
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        editUser: {
          ...state.editUser,
          password: ""
        }
      };
    case LOGIN_USER_SUCCESS:
    case UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        photoCameraRoll: action.payload.user
          ? action.payload.user.user_settings
            ? action.payload.user.user_settings.save_to_camera_roll
            : state.photoCameraRoll
          : state.photoCameraRoll,
        tasks_sort_by: action.payload.user
          ? action.payload.user.user_settings
            ? action.payload.user.user_settings.tasks_sort_by
            : state.tasks_sort_by
          : state.tasks_sort_by,
        dark_mode: action.payload.user
          ? action.payload.user.user_settings
            ? action.payload.user.user_settings.dark_mode
            : state.dark_mode
          : state.dark_mode,
        starting_point: action.payload.user
          ? action.payload.user.user_settings
            ? action.payload.user.user_settings.starting_point
            : state.starting_point
          : state.starting_point,
        notification_settings:
          action.payload.user?.user_settings?.notification_settings ||
          state.notification_settings
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        editUser: {
          ...state.editUser,
          password: "",
          new_password: "",
          new_password_confirm: ""
        },
        notifications: action.payload.notifications
      };
    case UPDATE_PHOTO_TYPE:
      return {
        ...state,
        photo_type: action.payload
      };
    case SET_TRACKING:
      return {
        ...state,
        tracking: action.payload
      };
    case INIT_UPDATE_USER:
      return {
        ...state,
        editUser: action.payload.user,
        upload_photo: INITIAL_STATE.upload_photo
      };
    case SIGNATURE_INIT:
      return {
        ...state,
        upload_photo: INITIAL_STATE.upload_photo
      };
    case UPDATE_USER_FIELD_CHANGE:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          [action.payload.prop]: action.payload.value
        }
      };
    case SELECT_PROFILE_PHOTO:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          source: action.payload
        },
        editUser: {
          ...state.editUser,
          image: action.payload.uri
        }
      };
    case SELECT_SIGNATURE_PHOTO:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          source: action.payload
        }
      };
    case UPLOAD_PROFILE_PHOTO:
    case UPLOAD_SIGNATURE_PHOTO:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          uploading: true,
          error: ""
        }
      };

    case UPLOAD_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          uploading: false,
          uploaded: true,
          did_upload: true,
          use_full: true,
          location: action.payload
        },
        editUser: {
          ...state.editUser,
          image: action.payload
        }
      };
    case UPLOAD_SIGNATURE_PHOTO_SUCCESS:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          uploading: false,
          uploaded: true,
          did_upload: true,
          use_full: true,
          location: action.payload
        }
      };
    case UPLOAD_PROFILE_PHOTO_FAIL:
    case UPLOAD_SIGNATURE_PHOTO_FAIL:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          uploading: false,
          progress: 0,
          error: action.payload
        }
      };
    case UPLOAD_PROFILE_PROGRESS:
    case UPLOAD_SIGNATURE_PROGRESS:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          progress: action.payload
        }
      };
    case UPLOAD_PROFILE_ANIMATION:
    case UPLOAD_SIGNATURE_ANIMATION:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          uploaded_animation: true
        }
      };
    case SELECT_DEFAULT_SENDING_OPTIONS:
      return {
        ...state,
        select_default_sending_options: action.payload
      };
    case SET_EDIT_RETURN_LOCATION:
      return {
        ...state,
        edit_return_location: action.payload
      };

    case SET_DARK_MODE:
      return {
        ...state,
        dark_mode: action.payload,
        colors:
          action.payload == "dark_mode"
            ? state.dark_mode_colors
            : state.regular_colors
      };

    case SET_PHOTO_CAMERAROLL:
      return {
        ...state,
        photoCameraRoll: action.payload
      };

    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          default_template_id: action.payload.new_template_id,
          default_template_name: action.payload.new_template_title
        }
      };

    case UPLOAD_LIST_SUCCESS:
      return {
        ...state,
        upload_list_info: action.payload
      };
    case TOGGLE_BULK_UPLOAD_MODAL:
      return {
        ...state,
        show_upload_modal: action.payload
      };
    case UPLOAD_LIST_MAPPING_SUCCESS:
      return {
        ...state,
        upload_list_info: null
      };
    case EDIT_BULK_UPLOAD_INFO:
      return {
        ...state,
        upload_list_info: {
          ...state.upload_list_info,
          [action.payload.prop]: action.payload.value
        }
      };
    case GET_DASHBOARD_SETTINGS:
      return {
        ...state,
        dashboard_settings_loading: true
      };

    case GET_DASHBOARD_SETTINGS_SUCCESS:
      return {
        ...state,
        dashboard_settings_loading: false,
        properties_added_last_30_days: action.payload.properties_added
          ? action.payload.properties_added.last_30_days
          : state.properties_added_last_30_days,
        properties_added_last_24_hours: action.payload.properties_added
          ? action.payload.properties_added.last_24_hours
          : state.properties_added_last_24_hours,
        dashboard_goals: action.payload.dashboard_goals
          ? action.payload.dashboard_goals
          : state.dashboard_goals,
        active_snapshot: action.payload.active_snapshot
          ? action.payload.active_snapshot
          : state.active_snapshot,

        enterprise_goal_variables: action.payload.enterprise_goal_variables
          ? action.payload.enterprise_goal_variables
          : state.enterprise_goal_variables
      };

    case GET_MENTOR_AUTOCOMPLETE:
      return {
        ...state,
        mentor_autocomplete_items: [],
        mentor_autocomplete_loading: true,
        mentor_autocomplete_error: ""
      };
    case CLEAR_MENTOR_AUTOCOMPLETE:
      return {
        ...state,
        mentor_autocomplete_items: [],
        mentor_autocomplete_error: ""
      };
    case GET_MENTOR_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        mentor_autocomplete_loading: false,
        mentor_autocomplete_error: action.payload
      };
    case GET_MENTOR_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        mentor_autocomplete_items: action.payload.mentors,
        mentor_autocomplete_loading: false
      };

    case BYPASS_LOCATION:
      return {
        ...state,
        bypass_location: action.payload
      };
    default:
      return state;
  }
};
