import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Card,
  CardBody,
  Icon,
  Title,
  Copy,
  Bold,
  Spin
} from "app/NativeComponents/common";

class Scene2 extends Component {
  render() {
    if (this.props.scene == 3) {
      return (
        <div className={"animated fadeInUp"}>
          <Card>
            <CardBody>
              <Row>
                <Wrapper
                  style={{
                    flex: 1
                  }}
                >
                  <Title>Exporting...</Title>
                </Wrapper>
                <Spin size={"small"} />
              </Row>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene2;
