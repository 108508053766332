import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";

import Buttons from "./Buttons";
import Inputs from "./Inputs";

import { StripeProvider, Elements } from "react-stripe-elements";
import CardForm from "./CardForm";

import { AppConfig } from "app/NativeActions";

class StripeForm extends Component {
  render() {
    return (
      <>
        <StripeProvider apiKey={AppConfig().stripe_live}>
          <Elements>
            <CardForm {...this.props} />
          </Elements>
        </StripeProvider>
      </>
    );
  }
}

export default StripeForm;
