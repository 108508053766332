import React, { Component } from "react";
import {
  Wrapper,
  Card,
  Row,
  PrimaryButton,
  SecondaryButton,
  DeleteButton
} from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

class SecondButton extends Component {
  constructor(props) {
    super(props);

    this.handleSecondary = this.handleSecondary.bind(this);
  }

  handleSecondary() {
    this.props.toggleModal({ show: false, type: null });
    if (this.props.onSecondaryPress) {
      this.props.onSecondaryPress();
    }
  }

  render() {
    if (!!this.props.secondary) {
      return (
        <GhostButton
          disabled={this.props.submit_loading}
          onPress={this.handleSecondary}
          button_type="full"
        >
          {this.props.secondary}
        </GhostButton>
      );
    }

    return null;
  }
}

export default SecondButton;
