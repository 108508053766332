import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  GhostButton,
  InputBox,
  OnboardingWrapper
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";
import QuestionSelect from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/QuestionSelect";

import { numberWithCommas, startRedirect, updateUser } from "app/NativeActions";
class HowDidYouHearAboutDealMachine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      heard_about_dealmachine_from: ""
    };

    this._heard_about_dealmachine_from = React.createRef();
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect(
        "/onboarding/how-did-you-hear-about-dealmachine"
      );
    }
  }

  addOrRemoveOption(slug) {
    const { selected_options } = this.state;
    if (selected_options.includes(slug)) {
      this.setState({
        selected_options: selected_options.filter(option => option !== slug)
      });
    } else {
      this.setState({
        selected_options: [...selected_options, slug]
      });
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>How did you hear about DealMachine?</Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            <Copy
              style={{
                textAlign: "center"
              }}
            >
              Select all that apply and click continue.
            </Copy>
          </Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <Row
              style={
                this.props.device == "mobile"
                  ? {
                      padding: 10,
                      alignSelf: "stretch",
                      flexDirection: "column"
                    }
                  : this.props.desktopMobile
                  ? {
                      padding: 10,
                      alignSelf: "stretch",
                      flexDirection: "column"
                    }
                  : {
                      flexWrap: "wrap",
                      padding: 25,
                      justifyContent: "center"
                    }
              }
            >
              <QuestionSelect
                slug={"Google search"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("Google search")}
                title={"Google search"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"App store search"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("App store search")}
                title={"App store search"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"Social media"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("Social media")}
                title={"Social media"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"Youtube"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("Youtube")}
                title={"Youtube"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"Podcast"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("Podcast")}
                title={"Podcast"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"From an influencer"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("From an influencer")}
                title={"From an influencer"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"From a friend"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("From a friend")}
                title={"From a friend"}
                select_type={"check"}
              />
              <QuestionSelect
                slug={"Other"}
                selected_options={this.state.selected_options}
                onPress={() => this.addOrRemoveOption("Other")}
                title={"Other"}
                select_type={"check"}
              />
            </Row>
            {this.state.selected_options.includes("Podcast") ||
            this.state.selected_options.includes("From a friend") ||
            this.state.selected_options.includes("From an influencer") ||
            this.state.selected_options.includes("Other") ? (
              <Wrapper
                style={
                  this.props.desktopMobile
                    ? {
                        width: "100%",
                        margin: this.props.device == "desktop" ? "5px auto" : 5
                      }
                    : {
                        maxWidth: 500,
                        width: "100%",
                        margin: this.props.device == "desktop" ? "5px auto" : 5
                      }
                }
              >
                <Card>
                  <InputBox
                    input_ref={this._heard_about_dealmachine_from}
                    name="heard_about_dealmachine_from"
                    autoFocus={true}
                    returnKeyType="done"
                    placeholder={
                      this.state.selected_options.includes("Other")
                        ? "Please tell us more about how you heard about DealMachine."
                        : this.state.selected_options.includes(
                            "From a podcast"
                          ) ||
                          this.state.selected_options.includes("From a friend")
                        ? "Who told you about DealMachine?"
                        : this.state.selected_options.includes("From a podcast")
                        ? "What podcast did you hear about us on?"
                        : "Who told you about DealMachine?"
                    }
                    onFocus={() => {}}
                    onChange={value => {
                      this.setState({
                        heard_about_dealmachine_from: value
                      });
                    }}
                    blurOnSubmit={true}
                    value={this.state.heard_about_dealmachine_from}
                    input_type="text"
                    submit_button_title={"Verify"}
                    onSubmitEditing={() => {
                      this.props.submitAnswer({
                        property_slug: "how_did_you_hear_about_dealmachine",
                        property_value: this.state.selected_options?.join(";")
                      });
                      this.props.nextStep();
                    }}
                    style={{ borderRadius: 5, margin: 5 }}
                  />
                </Card>
              </Wrapper>
            ) : null}
            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              loading={this.props.next_step_loading}
              disabled={
                this.state.selected_options.length == 0
                  ? true
                  : (this.state.selected_options.includes("Podcast") ||
                      this.state.selected_options.includes("From a friend") ||
                      this.state.selected_options.includes(
                        "From an influencer"
                      ) ||
                      this.state.selected_options.includes("Other")) &&
                    !this.state.heard_about_dealmachine_from
                  ? true
                  : false
              }
              icon_right={"arrow-right-alt"}
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "how_did_you_hear_about_dealmachine",
                  property_value: this.state.selected_options?.join(";")
                });

                if (
                  !!this.state.heard_about_dealmachine_from &&
                  (this.state.selected_options.includes("Podcast") ||
                    this.state.selected_options.includes("From a friend") ||
                    this.state.selected_options.includes(
                      "From an influencer"
                    ) ||
                    this.state.selected_options.includes("Other"))
                ) {
                  this.props.submitAnswer({
                    property_slug: "heard_about_dealmachine_from",
                    property_value: this.state.heard_about_dealmachine_from
                  });
                }

                this.props.nextStep();
              }}
            >
              Continue
            </GhostButton>
          </Wrapper>
          <Wrapper style={{ height: 40 }} />
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    desktopMobile,
    initialRedirect
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser
})(HowDidYouHearAboutDealMachine);
