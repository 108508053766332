import React, { Component } from "react";
import { connect } from "react-redux";

import { getCashBuyers } from "app/NativeActions";

import moment from "moment";
import { Copy, Wrapper } from "app/NativeComponents/common";
import { AttentionBox, SpinWrapper, List } from "app/NativeComponents/snippets";

import PersonItem from "app/DealMachineCore/components/Property/OwnerInfo/PersonItem";

class DispoTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cash_buyers: [],
      cash_buyers_loading: true
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { property } = this.props;
    this.props.getCashBuyers({
      token: this.props.token,
      centerLat: property?.location?.latitude,
      centerLng: property?.location?.longitude,
      zip: property?.property_address_zip,
      onLoading: () => {
        this.setState({
          cash_buyers_loading: true
        });
      },
      onError: error => {
        console.log(error);
        this.setState({
          cash_buyers_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          cash_buyers_loading: false,
          cash_buyers: results?.cash_buyers
        });
      }
    });
  }

  render() {
    const {
      property: { deal }
    } = this.props;

    const { cash_buyers, cash_buyers_loading } = this.state;

    return (
      <>
        {cash_buyers_loading ? (
          <SpinWrapper text={"Searching Cash Buyers..."} />
        ) : cash_buyers?.length > 0 ? (
          <>
            <AttentionBox
              title="Got the contract on lock? Let's find a buyer!"
              description={
                "These are cash buyers in the area that are looking for deals. Reach out to them to see if they are interested in your property."
              }
            />
            <List
              rowNumber={1}
              style={{ flex: 1 }}
              items={cash_buyers}
              infiniteScroll={true}
              itemStructure={({ item, index }) => {
                return (
                  <PersonItem
                    key={"person_" + index}
                    person={item}
                    no_options={false}
                    use_person_flags={false}
                    include_search_information={true}
                    expanded={this.state.expanded}
                    no_border={true}
                    property={this.state.property}
                    show_linked={true}
                    propertyUpdated={this.state.propertyUpdated}
                  />
                );
              }}
              canRefresh={true}
              onRefresh={() => {}}
              is_refreshing={false}
              canLoadMore={false}
              isLoadingMore={false}
              onLoadMore={() => {}}
            />
          </>
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Copy style={{ textAlign: "center" }}>
              We couldn't find any cash buyers in this area.
            </Copy>
          </Wrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, platform, isMobile } = native;
  return {
    token,
    user,
    colors,
    device,
    platform,
    isMobile
  };
};

export default connect(mapStateToProps, { getCashBuyers })(DispoTab);
