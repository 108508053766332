import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "151615756";
ReactGA.initialize(TRACKING_ID);

class PageViewWrapper extends React.Component {
  trackPage() {
    const pathname = window.location.pathname;

    //google analytics
    ReactGA.send("pageview");

    //hubspot
    const _hsq = (window._hsq = window._hsq || []);
    if (_hsq) {
      _hsq.push(["setPath", pathname]);
      _hsq.push(["trackPageView"]);
    }

    /*
    if (window.fbq) {
      window.fbq("track", "PageView");
    }
    */
  }

  componentDidMount() {
    this.trackPage();
  }

  componentDidUpdate({ location, history }) {
    if (location.pathname === this.props.location?.pathname) {
      // don't log identical link clicks (nav links likely)

      return;
    }

    if (history.action === "PUSH") {
      this.trackPage();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(PageViewWrapper);
