import React, { PureComponent } from "react";
import ReactPlayer from "react-player";

class WistiaVideo extends PureComponent {
  render() {
    const { width = 320, height = 180, wistia_url, wistia_id } = this.props;
    if (!!wistia_url) {
      return (
        <div size={{ width, height }}>
          <ReactPlayer
            url={wistia_url}
            width={width}
            height={height}
            config={{ wistia: { options: {}, player_id: wistia_id } }}
          />
        </div>
      );
    }
    return <div />;
  }
}

export { WistiaVideo };
