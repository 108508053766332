import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  Copy,
  Input,
  Row
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import Quote from "app/DealMachineCore/reuseable/Quote";
import SkipButton from "./SkipButton";

import { loadDashboardStep } from "app/NativeActions";

class BuildYourTeamStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null,
      phone: props.user.phone,
      completed_step: false
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("build_your_team")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderContents(dashboard_step) {
    //if (!this.state.completed_step) {
    return (
      <Wrapper>
        <Quote
          center={this.props.isMobile ? true : false}
          style={{ padding: 15, paddingTop: 5, maxWidth: 600 }}
          image={
            dashboard_step.quoteImage
              ? dashboard_step.quoteImage.fields.file.url
              : ""
          }
          title={dashboard_step.quoteText}
          description={dashboard_step.quoteDescription}
        />
        <Card>
          <Row>
            <Input
              ref={"email"}
              name={"email"}
              style={{ flex: 1 }}
              placeholder={
                !!this.state.email
                  ? "Email of your team member"
                  : "Email Address"
              }
              keyboardType="email-address"
              onChange={value => {
                this.setState({
                  email: value
                });
              }}
              value={this.state.email}
              type="text"
            />

            <PillButton
              primary={true}
              onPress={() => {
                this.props.updateTeamMembers({
                  token: this.props.token,
                  email: this.state.email,
                  type: "invite_member_from_dashboard",
                  onSuccess: () => {
                    this.setState({
                      email: ""
                    });
                  }
                });
              }}
            >
              Send Invite
            </PillButton>
          </Row>
        </Card>
      </Wrapper>
    );
    //}
  }

  completedStep() {
    this.props.updateTab("add_properties");
    this.props.updateDashboard({
      token: this.props.token,
      type: "enterprise_step",
      current_step: "add_properties"
    });
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab == "build_your_team") {
      if (!loading && dashboard_step) {
        return (
          <Wrapper>
            <CardBody>
              {this.renderContents(dashboard_step)}
              <SkipButton
                //completed_step={this.state.completed_step}
                onPress={this.completedStep}
                title={dashboard_step.nextButtonText}
              />
            </CardBody>
          </Wrapper>
        );
      }
    }
    return <Wrapper />;
  }
}

export default BuildYourTeamStep;
