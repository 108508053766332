import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SpinWrapper,
  SmallLabel,
  List,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  getLeadUpdates,
  updateLead,
  getTotalLeadsCount,
  determineMainOwnerInfo,
  numberWithCommas
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";

class ReviewAllLeadDataUpdates extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      submit_loading: false,
      loading: false,
      refreshing: false,
      limit: 25,
      loaded_all: false,
      updated_leads: [],
      panel_data,
      search: panel_data?.search || "",
      count_loading: false,
      count: panel_data?.count || 0
    };

    this.propertyUpdated = this.propertyUpdated.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.count !== this.state.count && this.state.count == 0) {
      if (this.state.panel_data.onAcceptAllChanges) {
        this.state.panel_data.onAcceptAllChanges();
      }

      this.handleBack();
    }
  }

  getData({ load_type }) {
    const { active_list_tab, sort_by, applied_filter } = this.props;
    const { search, limit, loaded_all, loading, refreshing } = this.state;
    this.props.getLeadUpdates({
      token: this.props.token,
      limit,
      begin: this.state.updated_leads?.length,
      onLoading: () => {
        this.setState({
          loading: load_type == "refresh" ? false : true,
          refreshing: load_type == "refresh" ? true : false
        });
      },
      onError: () => {
        this.setState({
          loading: false,
          refreshing: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false,
          refreshing: false,
          loaded_all: results?.properties.length < limit ? true : false,
          updated_leads: results?.properties
            ? load_type === "load_more"
              ? [...this.state.updated_leads, ...results?.properties]
              : results?.properties
            : this.state.updated_leads
        });
      }
    });
  }
  componentWillUnmount() {}

  handleBack() {
    this.props.popSidePanel();

    if (this.state.panel_data?.onBack) {
      this.state.panel_data.onBack();
    }
  }

  propertyUpdated(property) {
    this.setState({
      updated_leads: this.state.updated_leads.filter(({ property_id }) => {
        return property_id != property.property_id;
      }),
      count: this.state.count - 1
    });
  }

  render() {
    const { colors, active_list_tab, applied_filter } = this.props;
    const { limit, loaded_all, refreshing, loading, updated_leads } =
      this.state;
    return (
      <Container>
        <NewHeader
          title="Review Updates"
          leftButton={{
            icon: this.props.device == "desktop" ? "close" : "arrow-back",
            onPress: this.handleBack
          }}
        />
        {this.state.count > 0 ? (
          <ConfirmLabel
            label={
              this.state.count == 1
                ? "There are data updates for 1 lead"
                : "There are data updates for " +
                  numberWithCommas(this.state.count) +
                  " leads"
            }
            loading={this.state.submit_loading}
            confirmButton={"Acknowledge All Changes"}
            primary={true}
            disabled={false}
            onPress={() => {
              this.props.updateLead({
                token: this.props.token,
                type: "accept_data_changes",
                select_all: 1,
                total_count: this.state.count,
                list_id: "all_leads",
                get_updated_data: "get_updated_data",
                deal_ids: "",
                no_loading: true,
                onLoading: () => {
                  this.setState({
                    submit_loading: true
                  });
                },
                onError: () => {
                  this.setState({
                    submit_loading: false
                  });
                },
                onSuccess: () => {
                  this.setState(
                    {
                      submit_loading: false
                    },
                    () => {
                      if (this.state.panel_data?.onAcceptAllChanges) {
                        this.state.panel_data.onAcceptAllChanges();
                      }

                      this.handleBack();
                    }
                  );
                }
              });
            }}
          />
        ) : null}

        {(updated_leads?.length == 0 && loading) || refreshing ? (
          <SpinWrapper text="Loading Leads" />
        ) : updated_leads?.length == 0 ? (
          <Wrapper style={{ padding: 25 }}>
            <Copy>There is nothing to display.</Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            style={{ flex: 1 }}
            items={updated_leads}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              let owner_name = "";
              const owner_info = determineMainOwnerInfo(item);
              owner_name = owner_info?.owner_name;

              const owner_updated =
                item?.deal?.lead_data_update_confirmation
                  ?.owner_information_updated == 1
                  ? true
                  : false;

              return (
                <SelectItem
                  key={"lead_" + index}
                  style={{ marginTop: 0 }}
                  subtext={owner_updated ? "Owner Updated" : ""}
                  subtextStyle={{ color: colors.orange_color }}
                  description={item.property_address_full}
                  select_type="none"
                  icon="keyboard-arrow-right"
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "view_lead_data_updates",
                      overlay: true,
                      data: {
                        property: item,
                        propertyUpdated: this.propertyUpdated
                      }
                    });
                  }}
                  renderLeft={() => {
                    return (
                      <Wrapper
                        style={{
                          padding: 15,
                          paddingLeft: 25,
                          paddingRight: 0
                        }}
                      >
                        <LeadMainPhoto
                          property={item}
                          height={parseInt(50)}
                          style={{
                            width: parseInt(50),
                            margin: 0
                          }}
                        />
                      </Wrapper>
                    );
                  }}
                >
                  {owner_name}
                </SelectItem>
              );
            }}
            canRefresh={true}
            onRefresh={() => {
              this.getData({ load_type: "refresh" });
            }}
            is_refreshing={refreshing}
            canLoadMore={
              !loaded_all && !loading && !refreshing && updated_leads.length > 0
            }
            isLoadingMore={loading && !refreshing && updated_leads.length > 0}
            onLoadMore={() => {
              if (!loaded_all && refreshing != true && loading != true) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, list, filter }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;
  const { active_list_tab } = list;
  const { sort_by, applied_filter } = filter;

  return {
    token,
    user,
    device,
    colors,
    active_list_tab,
    sort_by,
    applied_filter
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getLeadUpdates,
  getTotalLeadsCount,
  updateLead
})(ReviewAllLeadDataUpdates);
