import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Bold, Row, Scroll } from "app/NativeComponents/common";

import {
  SpinWrapper,
  CloseButton,
  GhostButton
} from "app/NativeComponents/snippets";

import PropertyView from "./PropertyView";
import {
  updateTrackedRoute,
  startTrackedRoute,
  selectActiveProperty,
  toggleVirtualDriveStart,
  replaceSidePanel,
  numberWithCommas
} from "app/NativeActions";
import HeaderButton from "app/DealMachineCore/native/GlobalSnippets/NewHeader/HeaderButton";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

class PropertyPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select_drive_loading: false
    };
  }

  render() {
    const { popover_info, zoom, select_drive_start, colors } = this.props;
    if (popover_info) {
      const selected_properties = popover_info?.selected_properties
        ? this.props.highlight
          ? popover_info?.selected_properties?.filter(property => {
              return property.highlighted == 1 ? property : null;
            })
          : popover_info?.selected_properties
        : [];

      if (
        selected_properties?.length > 1 &&
        zoom >= 16 &&
        !select_drive_start &&
        !this.props.no_action_button
      ) {
        return (
          <Wrapper
            style={{
              alignSelf: "stretch",
              position: "relative",
              height: 450
            }}
          >
            <Row
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: this.props.colors?.border_color
              }}
            >
              <Wrapper style={{ flex: 1, padding: 25 }}>
                <Copy>
                  <Bold>
                    {this.props.highlight
                      ? "Properties at location matching filters: (" +
                        numberWithCommas(selected_properties?.length) +
                        ")"
                      : "Properties at location: (" +
                        numberWithCommas(selected_properties?.length) +
                        ")"}
                  </Bold>
                </Copy>
              </Wrapper>
              <HeaderButton
                buttonContents={{
                  icon: "close",
                  color: colors.light_text_color,
                  hover_color: colors.hover_color,
                  onPress: () => {
                    this.props.togglePopover(false);
                  }
                }}
              />
            </Row>
            <Scroll style={{ flex: 1 }}>
              {selected_properties?.map((item, index) => {
                return (
                  <LeadItem
                    key={"item_" + index}
                    include_add_button={true}
                    include_check_icon={true}
                    property={item}
                    onPress={() => {
                      this.props.togglePopover(false);

                      this.props.replaceSidePanel({
                        slug: "property",
                        id: item?.property_id,
                        focus_side_panel:
                          this.props.user?.user_settings?.property_expanded ===
                          "not_expanded"
                            ? false
                            : true,
                        overlay: true,
                        data: {
                          property: {
                            ...item
                          }
                        }
                      });
                    }}
                  />
                );
              })}
            </Scroll>
          </Wrapper>
        );
      }

      return (
        <Wrapper
          style={{
            alignSelf: "stretch",
            position: "relative",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <PropertyView
            chat_interface={this.props.chat_interface}
            property={popover_info.property}
            coordinates={popover_info.coordinates}
            show_street_button={this.props.no_action_button ? false : true}
            no_action_button={this.props.no_action_button}
            onPress={() => this.props.togglePopover(false)}
            select_drive_start={this.props.select_drive_start}
            no_photo={true}
            select_drive_loading={this.state.select_drive_loading}
            propertyUpdated={this.props.propertyUpdated}
            add_lead_marketing_data={{
              app_location: "from_map_popover"
            }}
            start_mail_marketing_data={{
              app_location: "from_map_popover"
            }}
            onStreetViewPress={({ street_view_data, heading }) => {
              if (
                this.props.select_drive_start &&
                this.props.user &&
                !this.props.onboarding_map
              ) {
                this.props.startTrackedRoute({
                  token: this.props.token,
                  track_route_type: "virtual",
                  drive_type: "virtual",
                  coordinates: [
                    {
                      latitude: street_view_data?.location?.lat,
                      longitude: street_view_data?.location?.lng
                    }
                  ],
                  onLoading: () => {
                    this.setState({
                      select_drive_loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      select_drive_loading: false
                    });
                    this.props.toggleSelectDriveStart(false);
                  },
                  onSuccess: () => {
                    this.setState({
                      select_drive_loading: false
                    });
                    this.props.toggleSelectDriveStart(false);
                  }
                });
              } else {
                this.props.toggleSelectDriveStart(false);
              }

              this.props.toggleVirtualDriveStart(true);
              this.props.setStreetViewCoords({
                latitude: street_view_data?.location?.lat,
                longitude: street_view_data?.location?.lng,
                heading: heading,
                zoom: 17,
                pitch: 0
              });
              this.props.updateLatLng({
                latitude: street_view_data?.location?.lat,
                longitude: street_view_data?.location?.lng,
                heading: heading,
                zoom: 17
              });

              if (
                this.props.track_route &&
                this.props.current_route &&
                this.props.current_route_section?.coordinates?.length > 0
              ) {
                this.props.updateTrackedRoute({
                  token: this.props.token,
                  route_id: this.props.current_route?.route_id,
                  coordinates: this.props.current_route_section?.coordinates,
                  current_route: this.props.current_route
                });
              }

              this.props.togglePopover(false);
            }}
            loading_height={this.props.no_action_button ? 160 : 253}
          />

          <CloseButton
            onPress={
              this.props.device == "mobile"
                ? () => this.props.selectActiveProperty(this.props.token, null)
                : () => this.props.togglePopover(false)
            }
          />
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, route }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, isMobile } = native;
  const { current_route, track_route, current_route_section } = route;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    current_route,
    track_route,
    current_route_section
  };
};

export default connect(mapStateToProps, {
  updateTrackedRoute,
  startTrackedRoute,
  toggleVirtualDriveStart,
  replaceSidePanel,
  selectActiveProperty
})(PropertyPopover);
