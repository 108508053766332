import React, { Component } from "react";
import { connect } from "react-redux";

import { Scroll, Row, Wrapper, Copy, Bold } from "app/NativeComponents/common";
import {
  SelectItem,
  InlineButton,
  PopoverMenu,
  IconButton
} from "app/NativeComponents/snippets";

import { formatAddress, numberWithCommas } from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import moment from "moment";
import { store } from "../../../../store";

class CompsProperties extends Component {
  render() {
    const { colors, isMobile, unselected_comps_on_map, comps_on_map, sort_by } =
      this.props;

    let comps = [];

    for (let i = 0; i < comps_on_map.length; i++) {
      let dont_include = false;
      for (let j = 0; j < unselected_comps_on_map.length; j++) {
        if (
          unselected_comps_on_map[j].property_id == comps_on_map[i].property_id
        ) {
          dont_include = true;
        }
      }
      if (!dont_include) {
        comps.push(comps_on_map[i]);
      }
    }

    return (
      <>
        <Row>
          <Wrapper
            style={{
              flex: 1,
              padding: 20,
              paddingRight: 0,
              paddingTop: 5,
              paddingBottom: 5
            }}
          >
            <Row>
              <Copy>
                {comps?.length == 1
                  ? "Showing 1 selected comp."
                  : "Showing " + comps?.length + " selected comps."}
              </Copy>
            </Row>
          </Wrapper>
          {comps?.length == comps_on_map?.length ? (
            <InlineButton
              inBottomSheet={false}
              onPress={() => {
                this.props.updateMainState({
                  unselected_comps_on_map: comps_on_map
                });
              }}
              style={{ marginRight: 0 }}
            >
              Clear All
            </InlineButton>
          ) : (
            <InlineButton
              inBottomSheet={false}
              onPress={() => {
                this.props.updateMainState({
                  unselected_comps_on_map: []
                });
              }}
              style={{ marginRight: 0 }}
            >
              Select All
            </InlineButton>
          )}
          <PopoverMenu
            popover_width={350}
            renderComponent={({ pressedIn, hovering }) => {
              return (
                <InlineButton
                  inBottomSheet={false}
                  onPress={() => {}}
                  noPress={true}
                  pressedIn={pressedIn || hovering}
                  style={{ marginLeft: 0 }}
                >
                  Sort
                </InlineButton>
              );
            }}
            menu_items={[
              {
                title: "Closest",
                select_type: "radio",
                selected: sort_by == "distance",
                onPress: () => {
                  this.props.updateMainState({
                    sort_by: "distance"
                  });
                }
              },
              {
                title: "Highest Sale Price",
                select_type: "radio",
                selected: sort_by == "saleprice_desc",
                onPress: () => {
                  this.props.updateMainState({
                    sort_by: "saleprice_desc"
                  });
                }
              },
              {
                title: "Lowest Sale Price",
                select_type: "radio",
                selected: sort_by == "saleprice_asc",
                onPress: () => {
                  this.props.updateMainState({
                    sort_by: "saleprice_asc"
                  });
                }
              },
              {
                title: "Latest Sale Date",
                select_type: "radio",
                selected: sort_by == "saledate_desc",
                onPress: () => {
                  this.props.updateMainState({
                    sort_by: "saledate_desc"
                  });
                }
              },
              {
                title: "Earliest Sale Date",
                select_type: "radio",
                selected: sort_by == "saledate_asc",
                onPress: () => {
                  this.props.updateMainState({
                    sort_by: "saledate_asc"
                  });
                }
              }
            ]}
          />
        </Row>
        <Scroll>
          {comps_on_map.map((item, index) => {
            const formatted_address = formatAddress({
              address: {
                address: item.property_address,
                address2: item.property_address2,
                address_city: item.property_address_city,
                address_state: item.property_address_state,
                address_zip: item.property_address_zip,
                latitude: item?.latitude,
                longitude: item?.longitude
              }
            });

            let in_unselected_comps = false;

            for (let i = 0; i < unselected_comps_on_map.length; i++) {
              if (item.property_id == unselected_comps_on_map[i].property_id) {
                in_unselected_comps = true;
              }
            }

            let description = "";

            if (!!item.saleprice) {
              description += "$" + numberWithCommas(item.saleprice);
            }

            if (!!item.saledate) {
              if (!!description) {
                description +=
                  " - " + moment(item.saledate).format("MMM Do, YYYY");
              } else {
                description += moment(item.saledate).format("MMM Do, YYYY");
              }
            }

            const width = store.getState().native.window_width;
            const isMobile = store.getState().native.isMobile;
            return (
              <SelectItem
                inBottomSheet={false}
                key={"lead_" + index}
                style={
                  !in_unselected_comps
                    ? {
                        width: isMobile ? width : "auto",
                        marginTop: 0,
                        borderLeftWidth: 5,
                        borderLeftStyle: "solid",
                        borderLeftColor: colors.actionable_text_color,
                        padding: 15,
                        paddingTop: 10,
                        paddingBottom: 10
                      }
                    : {
                        width: isMobile ? width : "auto",
                        marginTop: 0,
                        borderLeftWidth: 5,
                        borderLeftStyle: "solid",
                        borderLeftColor: "transparent",
                        padding: 15,
                        paddingTop: 10,
                        paddingBottom: 10
                      }
                }
                label={""}
                description={description}
                select_type="none"
                selected={!in_unselected_comps}
                onPress={() => {
                  if (in_unselected_comps) {
                    this.props.updateMainState({
                      unselected_comps_on_map: unselected_comps_on_map.filter(
                        ({ property_id }) => property_id !== item.property_id
                      )
                    });
                  } else {
                    this.props.updateMainState({
                      unselected_comps_on_map: [
                        ...unselected_comps_on_map,
                        item
                      ]
                    });
                  }
                }}
                renderLeft={() => {
                  return (
                    <Wrapper
                      style={{
                        padding: 15,
                        paddingLeft: isMobile || this.props.expanded ? 10 : 25,
                        paddingRight: 0
                      }}
                    >
                      <LeadMainPhoto
                        property={item}
                        height={isMobile || this.props.expanded ? 40 : 50}
                        style={{
                          width: isMobile || this.props.expanded ? 40 : 50,
                          margin: 0
                        }}
                      />
                    </Wrapper>
                  );
                }}
                renderRight={() => {
                  return (
                    <Row>
                      {isMobile || this.props.expanded ? (
                        <IconButton
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "property",
                              id: item?.property_id,
                              focus_side_panel: this.props.expanded
                                ? this.props.expanded
                                : this.props.user?.user_settings
                                    ?.property_expanded === "not_expanded"
                                ? false
                                : true,
                              overlay: true,
                              data: {
                                property: item
                              }
                            });
                          }}
                          icon="launch"
                        />
                      ) : (
                        <InlineButton
                          inBottomSheet={true}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "property",
                              id: item?.property_id,
                              focus_side_panel: this.props.expanded
                                ? this.props.expanded
                                : this.props.user?.user_settings
                                    ?.property_expanded === "not_expanded"
                                ? false
                                : true,
                              overlay: true,
                              data: {
                                property: item
                              }
                            });
                          }}
                          icon="launch"
                        >
                          View
                        </InlineButton>
                      )}
                    </Row>
                  );
                }}
                subtext={
                  <Copy
                    style={{
                      color: item.source_type == "mls" ? "#f2633a" : "#004f64"
                    }}
                  >
                    {item.source_type == "mls" ? "MLS" : "County Records"}
                  </Copy>
                }
              >
                {formatted_address.line1}
              </SelectItem>
            );
          })}
          <Wrapper style={{ height: 80 }} />
        </Scroll>
      </>
    );
  }
}

export default CompsProperties;
