import React from "react";

const ModalOverlay = ({
  children,
  style,
  onPress = null,
  animation_type = "fadeIn",
  disabled = false
}) => {
  return (
    <div
      onClick={onPress && !disabled ? onPress : () => {}}
      className={
        disabled
          ? ""
          : "deal-modal-overlay animated animate_delay_025s " + animation_type
      }
    >
      <div
        style={
          !disabled
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }
            : null
        }
      >
        {children}
      </div>
    </div>
  );
};

export { ModalOverlay };
