import React from "react";
import { store } from "app/store";

const ProgressBar = ({ style, progress, width, height = 7, color }) => {
  const colors = store.getState().settings.colors;

  let p = progress * 100;
  if (p > 100) {
    p = 100;
  }
  return (
    <div
      style={{
        height: parseInt(height),
        width: width,
        ...style
      }}
      className="progress dm-progress"
    >
      <div
        className="progress-bar dm-progress-bar"
        role="progressbar"
        aria-valuenow={p}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{
          width: p + "%",
          height: "100%",
          backgroundColor: color || colors.white_color
        }}
      ></div>
    </div>
  );
};

export { ProgressBar };
