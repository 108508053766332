import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Container,
  KeyboardView,
  DraggableList,
  Wrapper,
  Icon
} from "app/NativeComponents/common";

import {
  NewHeader,
  InlineTabs,
  SpinWrapper,
  ConfirmLabel,
  SearchBar,
  AttentionBox,
  InlineButton
} from "app/NativeComponents/snippets";

import {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  changeTab,
  getTags,
  popSidePanel,
  removeTeamTag,
  hideDefaultTag,
  showDefaultTag,
  updateTeamTagOrder,
  setModal,
  toggleModal,
  pushSidePanel
} from "app/NativeActions";

import DefaultTags from "./DefaultTags";
import CustomTag from "./CustomTag";

class ManageLeadTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "custom_tags",
      custom_tags: props.custom_tags,
      custom_tag_search: "",
      loading: false
    };
    this.handleBack = this.handleBack.bind(this);
    this.reorderItems = this.reorderItems.bind(this);
  }
  componentDidMount() {
    this.props.getTags(this.props.token);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.custom_tags &&
      JSON.stringify(this.props.custom_tags) !==
        JSON.stringify(prevProps.custom_tags)
    ) {
      this.setState({
        custom_tags: this.props.custom_tags
      });
    }
  }

  handleBack() {
    this.props.popSidePanel();
  }

  reorderItems({ previousIndex, nextIndex }) {
    const { custom_tags } = this.state;
    const reordered_items = JSON.parse(JSON.stringify(custom_tags));

    const [removed] = reordered_items.splice(previousIndex, 1);
    reordered_items.splice(nextIndex, 0, removed);
    for (let i = 0; i < reordered_items.length; i++) {
      reordered_items[i].tag_order = i;
    }

    this.setState({
      custom_tags: reordered_items
    });
  }

  filterOptions(options) {
    let filtered_options = [];
    const { custom_tag_search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title
          ?.toLowerCase()
          .indexOf(custom_tag_search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  checkIfNeedsToSave() {
    if (
      JSON.stringify(this.props.custom_tags) !==
      JSON.stringify(this.state.custom_tags)
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { tab } = this.state;
    return (
      <Container>
        <NewHeader
          title="Manage Lead Tags"
          leftButton={{
            onPress: this.handleBack,
            icon: "arrow-back"
          }}
        />
        <InlineTabs
          tab_type="underline"
          selected_tab={tab}
          tabs={[
            {
              onPress: () => {
                this.setState({
                  tab: "custom_tags"
                });
              },
              slug: "custom_tags",
              title: "Custom Tags"
            },
            {
              onPress: () => {
                this.setState({
                  tab: "default_tags"
                });
              },

              slug: "default_tags",
              title: "Default Tags"
            }
          ]}
        />

        <KeyboardView style={{ flex: 1 }}>
          {tab == "custom_tags" ? (
            <>
              {this.checkIfNeedsToSave() ? (
                <ConfirmLabel
                  style={{
                    alignSelf: "stretch"
                  }}
                  icon="check"
                  label={"Order changed"}
                  primary={true}
                  confirmButton={"Confirm Changes"}
                  loading={this.state.loading}
                  onPress={() => {
                    this.props.updateTeamTagOrder({
                      token: this.props.token,
                      tag_order: this.state.custom_tags,
                      onLoading: () => {
                        this.setState({ loading: true });
                      },
                      onError: () => {
                        this.setState({ loading: false });
                      },
                      onSuccess: results => {
                        this.setState({
                          loading: false,
                          custom_tags: results.tags
                            ? results.tags.filter(function (obj) {
                                return obj.custom_tag == 1;
                              })
                            : this.state.custom_tags
                        });
                      }
                    });
                  }}
                />
              ) : null}
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <SearchBar
                    title={"Search Custom Tags"}
                    onChange={value => {
                      this.setState({
                        custom_tag_search: value
                      });
                    }}
                    value={this.state.custom_tag_search}
                    style={{
                      margin: 10,
                      marginRight: 0,
                      borderWidth: 0,
                      borderBottomWidth: 0
                    }}
                  />
                </Wrapper>
                <InlineButton
                  icon="add"
                  primary={true}
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "new_tag",
                      overlay: true
                    });
                  }}
                >
                  New Tag
                </InlineButton>
              </Row>
              {this.props.tags_loading ? (
                <SpinWrapper text="Loading Tags..." />
              ) : this.props.tags_loaded &&
                this.state.custom_tags.length == 0 ? (
                <AttentionBox
                  title="Property tags help you stay organized."
                  description={
                    'Create a custom tag here so you can organize properties while you\'re driving. Example tags: "Important", "Unique list name", or "For Sale By Owner."'
                  }
                />
              ) : (
                <DraggableList
                  items={this.filterOptions(this.state.custom_tags)}
                  reverse={false}
                  disabled={!!this.state.custom_tag_search}
                  renderHandle={() => {
                    return (
                      <Wrapper
                        className="dm-drag"
                        style={{
                          padding: 15,
                          paddingLeft: 0,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Icon icon="drag-indicator" size={18} />
                      </Wrapper>
                    );
                  }}
                  renderItem={({ item, wrappedHandle }) => {
                    return (
                      <CustomTag
                        tag={item}
                        token={this.props.token}
                        removeTeamTag={this.props.removeTeamTag}
                        wrappedHandle={
                          !this.state.custom_tag_search ? wrappedHandle : null
                        }
                      />
                    );
                  }}
                  onReorder={({ previousIndex, nextIndex }) => {
                    this.reorderItems({
                      previousIndex,
                      nextIndex
                    });
                  }}
                />
              )}
            </>
          ) : null}
          {tab == "default_tags" ? <DefaultTags {...this.props} /> : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, deal, property_tags, native, settings }) => {
  const { token, user } = auth;
  const { all_tags } = deal;
  const { device } = native;
  const { colors } = settings;
  const { tags_loading, custom_tags, default_tags, tags_loaded } =
    property_tags;

  return {
    all_tags,
    custom_tags,
    default_tags,
    tags_loading,
    tags_loaded,
    device,
    token,
    user,
    colors
  };
};

export default connect(mapStateToProps, {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  changeTab,

  getTags,
  popSidePanel,
  removeTeamTag,
  hideDefaultTag,
  showDefaultTag,
  updateTeamTagOrder,

  setModal,
  toggleModal,
  pushSidePanel
})(ManageLeadTags);
