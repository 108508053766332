import React, { Component } from "react";

import BasicSectionContent from "./BasicSectionContent";
import FormSectionContent from "./FormSectionContent";

class SectionContent extends Component {
  render() {
    switch (this.props.section.sectionStyle) {
      case "FormSection":
        return <FormSectionContent {...this.props} />;

      default:
        return <BasicSectionContent {...this.props} />;
    }
  }
}

export default SectionContent;
