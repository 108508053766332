import React, { Component } from "react";

import {
  SectionInner,
  SectionContainer
} from "app/NativeComponents/website/common";

import SectionImage from "./SectionImage";
import SectionTitle from "./SectionTitle";
import SectionSubtitle from "./SectionSubtitle";
import SectionDescription from "./SectionDescription";
import ContentItems from "./ContentItems";
import SectionFooterContent from "./SectionFooterContent";
import CallToActions from "./CallToActions";

class BasicSectionContent extends Component {
  render() {
    return (
      <SectionInner>
        <SectionContainer>
          <SectionImage {...this.props} />
          <SectionTitle {...this.props} />
          <SectionSubtitle {...this.props} />
          <SectionDescription {...this.props} />
        </SectionContainer>
        <ContentItems {...this.props} />
        <SectionContainer>
          <SectionFooterContent {...this.props} />
          <CallToActions {...this.props} />
        </SectionContainer>
      </SectionInner>
    );
  }
}

export default BasicSectionContent;
