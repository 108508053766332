import React from "react";
import { Wrapper, Copy, Bold, Row } from "app/NativeComponents/common";

import { store } from "app/store";

const SmallLabel = ({
  children,
  style,
  textStyle,
  description,
  descriptionStyle,
  subtext,
  subtextStyle
}) => {
  const colors = store.getState().settings.colors;

  return (
    <Wrapper
      style={{ padding: 25, paddingTop: 10, paddingBottom: 10, ...style }}
    >
      <Row>
        <Copy
          style={{
            fontSize: 12,
            color: colors.text_color,
            ...textStyle
          }}
        >
          <Bold>{children}</Bold>
        </Copy>
        {!!subtext ? (
          <Copy
            style={{
              marginLeft: 5,
              fontSize: 10,
              color: colors.light_text_color
            }}
          >
            {subtext}
          </Copy>
        ) : null}
      </Row>
      {!!description ? (
        <Copy
          style={{
            fontSize: 12,
            color: colors.light_text_color,
            ...descriptionStyle
          }}
        >
          {description}
        </Copy>
      ) : null}
    </Wrapper>
  );
};

export { SmallLabel };
