import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Button,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";
import { IconButton } from "../../../../../../../native/GlobalSnippets";

class ZoomButtons extends Component {
  render() {
    const { colors, onZoom, scale, isMobile } = this.props;
    if (!isMobile) {
      return (
        <Row>
          <IconButton
            tooltip={"Zoom In"}
            tooltipPlacement={"top"}
            style={{
              backgroundColor:
                scale <= 1 ? colors.card_color : colors.background_color,
              margin: 0
            }}
            onPress={() => {
              if (scale <= 1) {
                onZoom(scale + 0.1);
              }
            }}
            icon={"zoom-in"}
          />
          <IconButton
            tooltip={"Zoom Out"}
            tooltipPlacement={"top"}
            style={{
              backgroundColor:
                scale > 0.5 ? colors.card_color : colors.background_color,
              margin: 0
            }}
            onPress={() => {
              if (scale > 0.5) {
                onZoom(scale - 0.1);
              }
            }}
            icon={"zoom-out"}
          />
          <IconButton
            tooltip={"Actual Size"}
            tooltipPlacement={"top"}
            style={{
              backgroundColor:
                scale != 1 ? colors.card_color : colors.background_color,
              margin: 0
            }}
            onPress={() => {
              onZoom(1);
            }}
            icon={"aspect-ratio"}
          />
        </Row>
      );
    }
    return null;
  }
}

export default ZoomButtons;
