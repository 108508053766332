import React, { Component } from "react";
import { Wrapper, Card, Row } from "app/NativeComponents/common";
import { RemoveTextButton, GhostButton } from "app/NativeComponents/snippets";

class Buttons extends Component {
  render() {
    return (
      <GhostButton
        loading={this.props.loading}
        style={{ margin: 10 }}
        disabled={!this.props.checkIfNeedsToSave()}
        primary={true}
        onPress={() => this.props.addProperty()}
        button_type="full"
      >
        Add Lead From Address
      </GhostButton>
    );
  }
}

export default Buttons;
