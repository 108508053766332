import React, { PureComponent } from "react";

import { Icon } from "app/NativeComponents/common";

class Crosshairs extends PureComponent {
  render() {
    if (this.props.withMarker) {
      if (this.props.onPress) {
        return (
          <a
            role="button"
            onClick={this.props.onPress}
            className="deal-map-overlay "
          >
            <Icon
              style={{
                marginBottom: 32
              }}
              size={32}
              icon="location-on"
              color={"#31cce5"}
            />
          </a>
        );
      } else {
        return (
          <div className="deal-map-overlay-transparent">
            <img
              alt={""}
              src="/app/assets/images/crosshairs.svg"
              style={{
                width: 32,
                height: 32,
                objectFit: "contain"
              }}
              pointerEvents={"none"}
            />
          </div>
        );
      }
    }

    return <div />;
  }
}

export default Crosshairs;
