export const GET_TEMPLATES = "get_templates";
export const REFRESH_TEMPLATES = "refresh_templates";
export const GET_TEMPLATES_FAIL = "get_templates_fail";
export const GET_TEMPLATES_SUCCESS = "get_templates_success";
export const GET_TEMPLATE = "get_template";
export const GET_TEMPLATE_FAIL = "get_template_fail";
export const GET_TEMPLATE_SUCCESS = "get_template_success";
export const UPDATE_HOUSE_LOCAL = "update_house_local";
export const TEMPLATE_INIT = "template_init";
export const TEMPLATE_RESET = "template_reset";
export const TEMPLATE_FIELD_CHANGED = "template_field_changed";
export const SAVE_TEMPLATE = "save_template";
export const SAVE_TEMPLATE_SUCCESS = "save_template_success";
export const CREATE_TEMPLATE_SUCCESS = "create_template_success";
export const SAVE_TEMPLATE_FAIL = "save_template_fail";
export const GET_PREVIEW = "get_preview";
export const GET_PREVIEW_SUCCESS = "get_preview_success";
export const GET_PREVIEW_FAIL = "get_preview_fail";
export const RESET_PREVIEW = "reset_preview";
export const PREVIEW_IMAGE_LOADED = "preview_image_loaded";
export const SELECT_DEFAULT_TEMPLATE = "select_default_template";
export const SET_PREVIEW_TYPE = "set_preview_type";
export const DELETE_TEMPLATE = "delete_template";
export const SHOW_TEMPLATE_PREVIEW = "show_template_preview";
export const RELOAD_PREVIEWS = "reload_previews";

export const GENERATE_PREVIEW_IMAGES = "generate_preview_images";
export const GENERATE_PREVIEW_IMAGES_SUCCESS = "generate_preview_success";
export const GENERATE_PREVIEW_IMAGES_FAIL = "generate_preview_success";

export const SELECT_TEMPLATE_PHOTO = "select_template_photo";
export const UPLOAD_TEMPLATE_PHOTO = "upload_template_photo";
export const UPLOAD_TEMPLATE_PHOTO_FAIL = "upload_template_photo_fail";
export const UPLOAD_TEMPLATE_PHOTO_SUCCESS = "upload_template_photo_success";
export const UPLOAD_TEMPLATE_PHOTO_PROGRESS = "upload_template_photo_progress";
