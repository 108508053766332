import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  WistiaVideo,
  Title,
  Card,
  Copy,
  Row
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import Quote from "app/DealMachineCore/reuseable/Quote";

import { loadDashboardStep, displayIntercom } from "app/NativeActions";

class StartStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("start_step")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderVideo(dashboard_step) {
    if (
      dashboard_step.video1WistiaUrl ||
      (this.props.is_enterprise && dashboard_step.video2WistiaUrl)
    ) {
      return (
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <Card style={{ alignSelf: "center", overflow: "hidden" }}>
            <WistiaVideo
              wistia_url={
                this.props.is_enterprise
                  ? dashboard_step.video2WistiaUrl
                  : dashboard_step.video1WistiaUrl
              }
              wistia_id={
                this.props.is_enterprise
                  ? dashboard_step.video2WistiaId
                  : dashboard_step.video1WistiaId
              }
              height={!this.props.dashboardMobile ? 270 : 180}
              width={!this.props.dashboardMobile ? 480 : 320}
            />
          </Card>
        </Wrapper>
      );
    }
  }
  renderTitle(dashboard_step) {
    if (!this.props.is_enterprise) {
      return (
        <Title style={{ flex: 1, textAlign: "center" }}>
          {dashboard_step.header}
        </Title>
      );
    }
  }
  renderQuote(dashboard_step) {
    if (this.props.is_enterprise) {
      return (
        <Quote
          center={this.props.isMobile ? true : false}
          style={{ padding: 15, paddingTop: 5, maxWidth: 600 }}
          image={
            dashboard_step.quoteImage
              ? dashboard_step.quoteImage.fields.file.url
              : ""
          }
          title={dashboard_step.quoteText}
          description={dashboard_step.quoteDescription}
        />
      );
    }
  }

  renderSecondaryButton(dashboard_step) {
    if (this.props.is_enterprise) {
      return (
        <PillButton
          id="intercom_button"
          primary={false}
          onPress={() => displayIntercom()}
        >
          {dashboard_step.secondaryButtonText}
        </PillButton>
      );
    }
  }

  completedStep() {
    this.props.updateTab(
      this.props.is_enterprise ? "set_goals_enterprise" : "set_goals"
    );
    this.props.updateDashboard({
      token: this.props.token,
      type: this.props.is_enterprise ? "enterprise_step" : "dashboard_step",
      current_step: this.props.is_enterprise
        ? "set_goals_enterprise"
        : "set_goals"
    });
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab == "start") {
      if (!loading && dashboard_step) {
        return (
          <Wrapper>
            <CardBody>
              {this.renderQuote(dashboard_step)}

              {this.renderVideo(dashboard_step)}
              <Wrapper
                style={{
                  paddingTop: 20,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {this.renderTitle(dashboard_step)}
                <Row>
                  {this.renderSecondaryButton(dashboard_step)}
                  <PillButton primary={true} onPress={this.completedStep}>
                    {dashboard_step.nextButtonText}
                  </PillButton>
                </Row>
              </Wrapper>
            </CardBody>
          </Wrapper>
        );
      }
    }
    return <Wrapper />;
  }
}

export default StartStep;
