import React, { Component } from "react";

import {
  Wrapper,
  CardBody,
  Row,
  Title,
  Icon,
  Copy,
  Bold
} from "app/NativeComponents/common";

import {
  PillButton,
  ConfirmInlineButton,
  SelectItem
} from "app/NativeComponents/snippets";

import { checkIfUserHasModule } from "app/NativeActions";

class ModuleAccess extends Component {
  constructor(props) {
    super(props);
    const plan_module_info = checkIfUserHasModule({
      plan_modules: props.plan_modules,
      user: props.active_team_member,
      slug: this.props.slug
    });

    this.state = {
      plan_module_info: plan_module_info,
      removing: false,
      granting: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active_team_member && prevProps.plan_modules) {
      if (
        this.props.plan_modules !== prevProps.plan_modules ||
        this.props.active_team_member.plan_modules !==
          prevProps.active_team_member.plan_modules
      ) {
        const plan_module_info = checkIfUserHasModule({
          plan_modules: this.props.plan_modules,
          user: this.props.active_team_member,
          slug: this.props.slug
        });
        this.setState({ plan_module_info: plan_module_info });
      }
    }
  }

  renderAccessButton() {
    if (this.props.active_team_member.team_clearance_level > 0) {
      if (this.props.slug == "crm") {
        return null;
      }

      if (this.state.plan_module_info.user_has_module) {
        return (
          <Row>
            <ConfirmInlineButton
              confirm_text="Really Remove?"
              loading_text="Removing"
              loading={this.state.removing}
              onPress={() => {
                this.props.updateTeamMembers({
                  token: this.props.token,
                  type: "remove_access",
                  module_type: this.props.slug,
                  team_member_id: this.props.active_team_member.id,
                  member_type: "team_member",

                  onLoading: () => {
                    this.setState({
                      removing: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      removing: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      removing: false
                    });
                  }
                });
              }}
            >
              Remove Access
            </ConfirmInlineButton>
          </Row>
        );
      } else if (this.state.plan_module_info.team_has_module) {
        return (
          <Row>
            <ConfirmInlineButton
              primary={true}
              confirm_text="Really Grant?"
              loading_text="Granting Access"
              loading={this.state.granting}
              onPress={() => {
                this.props.updateTeamMembers({
                  token: this.props.token,
                  type: "grant_access",
                  module_type: this.props.slug,
                  team_member_id: this.props.active_team_member.id,
                  member_type: "team_member",
                  onLoading: () => {
                    this.setState({
                      granting: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      granting: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      granting: false
                    });
                  }
                });
              }}
            >
              Grant Access
            </ConfirmInlineButton>
          </Row>
        );
      }
    }

    return null;
  }

  renderAccessText() {
    if (this.props.active_team_member.team_clearance_level > 0) {
      if (this.props.slug == "crm") {
        return "Full Access";
      }

      if (this.state.plan_module_info.user_has_module) {
        return "Full Access";
      } else if (this.state.plan_module_info.team_has_module) {
        return "No Access";
      }
    } else if (this.props.slug == "driving") {
      return "Limited Access";
    } else if (this.props.active_team_member.team_clearance_level == 0) {
      return "Not Available for Drivers";
    }

    return "";
  }

  renderSeats() {
    if (this.props.slug == "crm") {
      if (this.props.team_member_limit == -1) {
        return this.props.current_team_members + " of unlimited seats taken";
      } else {
        return (
          this.props.current_team_members +
          " of " +
          this.props.team_member_limit +
          " seats taken"
        );
      }
    }

    if (this.state.plan_module_info.team_has_module) {
      if (
        this.props.slug == "driving" &&
        this.props.active_team_member.team_clearance_level == 0
      ) {
        if (this.state.plan_module_info.dealfinder_limit == -1) {
          return (
            this.state.plan_module_info.dealfinder_count +
            " of unlimited seats taken"
          );
        } else {
          return (
            this.state.plan_module_info.dealfinder_count +
            " of " +
            this.state.plan_module_info.dealfinder_limit +
            " seats taken"
          );
        }
      } else if (this.props.active_team_member.team_clearance_level > 0) {
        if (this.state.plan_module_info.team_member_limit == -1) {
          return (
            this.state.plan_module_info.team_member_count +
            " of unlimited seats taken"
          );
        } else {
          return (
            this.state.plan_module_info.team_member_count +
            " of " +
            this.state.plan_module_info.team_member_limit +
            " seats taken"
          );
        }
      }
    } else if (this.props.platform != "ios") {
      return "You team has not purchased this service.";
    }
  }

  render() {
    if (
      this.state.plan_module_info.team_has_access ||
      this.props.platform != "ios"
    ) {
      return (
        <SelectItem
          select_type="none"
          noPress={true}
          description={this.renderSeats()}
          subtext={this.renderAccessText()}
          renderRight={() => {
            return this.renderAccessButton();
          }}
        >
          {this.props.title}
        </SelectItem>
      );
    }
    return null;
  }
}

export default ModuleAccess;
