export const TOGGLE_SETTINGS_PANEL = "toggle_settings_panel";

export const GET_CUSTOM_FIELDS = "get_custom_fields";
export const GET_CUSTOM_FIELDS_FAIL = "get_custom_fields_fail";
export const GET_CUSTOM_FIELDS_SUCCESS = "get_custom_fields_success";

export const UPDATE_CUSTOM_FIELDS = "update_custom_fields";
export const UPDATE_CUSTOM_FIELDS_FAIL = "update_custom_fields_fail";
export const UPDATE_CUSTOM_FIELDS_SUCCESS = "update_custom_fields_success";
export const REMOVE_CUSTOM_FIELD_SUCCESS = "remove_custom_field_success";
export const REMOVE_CUSTOM_FIELD_GROUP_SUCCESS =
  "remove_custom_field_group_success";

export const GET_LEAD_STATUSES = "get_lead_statuses";
export const GET_LEAD_STATUSES_FAIL = "get_lead_statuses_fail";
export const GET_LEAD_STATUSES_SUCCESS = "get_lead_statuses_success";

export const UPDATE_LEAD_STATUS = "update_lead_status";
export const UPDATE_LEAD_STATUS_FAIL = "update_lead_status_fail";
export const UPDATE_LEAD_STATUS_SUCCESS = "update_lead_status_success";
