import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Button,
  Title,
  SecondaryButton
} from "app/NativeComponents/common";
import { GhostButton, TextButton } from "app/NativeComponents/snippets";
import { toTitleCase } from "app/NativeActions";
class SelectedOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing_items: false
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {}

  renderSelectedText() {
    const {
      selected_all,
      selected_all_in_account,
      total_count,
      selected_items,
      item_name = "item(s)",
      isMobile,
      hide_edit_button = false,
      custom_edit_button,
      device
    } = this.props;

    if (selected_all_in_account) {
      return (
        <Wrapper>
          <Row>
            <Copy style={{ fontSize: 12, marginRight: 5 }}>
              All {total_count} {item_name} are selected.
            </Copy>
            <Button onPress={() => {}}>
              <Copy style={{ fontSize: 12 }}>
                <Bold>Clear selection</Bold>
              </Copy>
            </Button>
          </Row>
        </Wrapper>
      );
    }

    if (selected_all && total_count > selected_items.length) {
      return (
        <Wrapper>
          <Row>
            <Copy style={{ fontSize: 12, marginRight: 5 }}>
              All {selected_items.length} {item_name} on this page are selected.
            </Copy>
            <Button
              onPress={() => {
                this.props.selectAllLeadsInAccount();
              }}
            >
              <Copy style={{ fontSize: 12 }}>
                <Bold>
                  Select All {total_count} {toTitleCase(item_name)}
                </Bold>
              </Copy>
            </Button>
          </Row>
        </Wrapper>
      );
    } else if (this.props.selected_all) {
      return (
        <Wrapper>
          <Row>
            <Copy style={{ fontSize: 12, marginRight: 5 }}>
              All {selected_items.length} {item_name} on this page are selected.
            </Copy>
          </Row>
        </Wrapper>
      );
    }

    if (isMobile) {
      return (
        <Wrapper>
          <Row>
            <Copy style={{ fontSize: 12, marginRight: 5 }}>
              {selected_items.length + " " + item_name + " are selected."}{" "}
            </Copy>
            <Button onPress={() => {}}>
              <Copy style={{ fontSize: 12 }}>
                <Bold>
                  Select All {total_count} {toTitleCase(item_name)}
                </Bold>
              </Copy>
            </Button>
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    const {
      colors,
      device,
      include_checkboxes,
      is_selecting,
      selected_all,
      selected_all_in_account,
      total_count = 0,
      selected_items,
      edit_button_text = "Edit Selected",
      item_name = "item(s)",
      selected_option_actions = [],
      hide_edit_button = false,
      custom_edit_button
    } = this.props;
    const { editing_items } = this.state;
    if (include_checkboxes && is_selecting) {
      if (editing_items) {
        return (
          <Wrapper
            style={{
              position: device === "mobile" ? "absolute" : "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              minHeight: 150,

              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                marginTop: 10,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                borderTopWidth: 1,
                borderTopColor: colors.border_color,
                borderTopStyle: "solid",
                backgroundColor: colors.card_color,
                shadowColor: "#000",
                shadowOpacity: 0.2,
                shadowOffset: { width: 0, height: 2 },
                elevation: 5
              }}
            >
              <Wrapper style={{ padding: 20, alignSelf: "stretch" }}>
                <Wrapper>
                  <Copy style={{ marginBottom: 15 }}>
                    Edit{" "}
                    {selected_all_in_account
                      ? total_count
                      : selected_items.length}{" "}
                    {toTitleCase(item_name)}
                  </Copy>
                  {selected_option_actions &&
                    selected_option_actions.map((selected_option_action, i) => {
                      return (
                        <SecondaryButton
                          key={"option_action_" + i}
                          onPress={selected_option_action.onPress}
                        >
                          {selected_option_action.title}
                        </SecondaryButton>
                      );
                    })}
                  <TextButton onPress={() => {}} text={"Keep Selecting"} />
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        );
      } else {
        return (
          <Wrapper
            style={{
              position: device === "mobile" ? "absolute" : "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              minHeight: 150,

              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                marginTop: 10,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                borderTopWidth: 1,
                borderTopColor: colors.border_color,
                borderTopStyle: "solid",
                backgroundColor: colors.card_color,
                shadowColor: "#000",
                shadowOpacity: 0.2,
                shadowOffset: { width: 0, height: 2 },
                elevation: 5
              }}
            >
              <Wrapper style={{ padding: 20 }}>
                <Wrapper>
                  <Title>
                    {selected_all_in_account
                      ? total_count
                      : selected_items.length}{" "}
                    Selected
                  </Title>
                </Wrapper>
                {this.renderSelectedText()}
                <Row style={{ marginTop: 15 }}>
                  {custom_edit_button && custom_edit_button()}
                  {!hide_edit_button && !custom_edit_button && (
                    <GhostButton
                      style={{ margin: 0 }}
                      primary={true}
                      onPress={() => {
                        this.setState({
                          editing_items: true
                        });
                      }}
                    >
                      {edit_button_text}
                    </GhostButton>
                  )}
                </Row>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        );
      }
    }

    return null;
  }
}

export default SelectedOptions;
