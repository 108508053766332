import React, { Component } from "react";

import { Row, Wrapper, Copy, Icon } from "app/NativeComponents/common";
import {
  PopoverMenu,
  List,
  SearchBar,
  SelectItem,
  Tag,
  InlineButton,
  GhostButton
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";
class SelectMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: props.show || false,
      selected_options: props.selected_options || []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.selected_options) !==
      JSON.stringify(prevProps.selected_options)
    ) {
      this.setState({
        selected_options: this.props.selected_options
      });
    }

    if (
      JSON.stringify(this.state.selected_options) !==
        JSON.stringify(prevState.selected_options) &&
      JSON.stringify(this.state.selected_options) !==
        JSON.stringify(this.props.selected_options)
    ) {
      if (this.props.onSelectedOptionsChange) {
        this.props.onSelectedOptionsChange(this.state.selected_options);
      }
    }

    if (
      this.state.show !== this.props.show &&
      this.props.show !== prevProps.show
    ) {
      this.setState({ show: this.props.show });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  getLabel(value) {
    const { selected_options } = this.state;

    let selected_string = "";

    for (let i = 0; i < selected_options.length; i++) {
      if (i == 0) {
        selected_string += selected_options[i].label;
      } else {
        selected_string += ", " + selected_options[i].label;
      }
    }
    if (!!selected_string) {
      return selected_string;
    }

    return "--";
  }

  onRemoveOption(option) {
    const { selected_options } = this.state;
    this.setState({
      selected_options: selected_options.filter(
        ({ value }) => value !== option?.value
      )
    });
  }

  onAddOption(option) {
    const { selected_options } = this.state;

    this.setState({
      selected_options: [...selected_options, option]
    });
  }

  render() {
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;

    const {
      options = [],
      style,
      popoverPlacement = "bottom",

      title,
      search_title = "Search",
      innerStyle,
      placeholder,
      value,
      selected_background_color = null,
      hover_background_color = null,
      forceLabel,
      forceLabelPlaceholder,
      icon,
      fa_icon,
      icon_right,
      fa_icon_right,
      renderLeft = null,
      renderRight = null,
      renderMiddle = null,
      renderHover = null,
      pressedIn = false,

      hover_icon = null,
      hover_fa_icon = null,

      has_changed = false,
      show_buttons = false,
      secondary_button,
      secondary_button_icon,
      onSecondaryPress = () => {},
      require_confirm = false,
      loading = false,
      onSubmit = () => {},
      submit_button_title = "Save",
      submit_button_icon = "",
      submit_button_primary = false,
      submit_disabled = false,
      error = "",
      renderBottom = null,
      select_type = "check",
      renderComponent = null,
      disabled = false,
      include_search = true,
      include_tags = true,
      renderTopButtons = null,
      includeCloseButton = false
    } = this.props;
    const { selected_options, search, show } = this.state;
    const background_color = show
      ? !!selected_background_color
        ? selected_background_color
        : (require_confirm && has_changed) || error
        ? colors.orange_color_muted
        : colors.active_color_muted
      : (require_confirm && has_changed) || error
      ? colors.orange_color_muted
      : options?.hovering
      ? hover_background_color || colors.hover_color
      : "transparent";

    const hover_icon_color = colors.orange_color;
    const icon_color = colors.text_color;
    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
          if (this.props.onShow) {
            this.props.onShow(s);
          }
        }}
        disabled={disabled}
        popover_width={350}
        popover_height={450}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={title}
        includeCloseButton={includeCloseButton}
        renderTopButtons={renderTopButtons}
        renderComponent={
          renderComponent
            ? renderComponent
            : options => {
                return (
                  <Row
                    wrapper_ref={this._popover}
                    style={{
                      backgroundColor: background_color,
                      alignSelf: "stretch",
                      ...style
                    }}
                  >
                    {renderLeft ? renderLeft() : null}

                    <Wrapper
                      style={{
                        padding: 25,
                        flex: 1,
                        ...innerStyle
                      }}
                    >
                      {renderMiddle ? (
                        renderMiddle()
                      ) : (
                        <Row>
                          {!!icon || fa_icon ? (
                            <Icon
                              size={18}
                              color={icon_color}
                              icon={icon}
                              fa_icon={fa_icon}
                              style={{ marginRight: 20 }}
                            />
                          ) : null}
                          {selected_options.length == 0 && !forceLabel ? (
                            <Wrapper style={{ flex: 1 }}>
                              <Copy style={{ color: colors.light_text_color }}>
                                {placeholder}
                              </Copy>
                            </Wrapper>
                          ) : (
                            <Wrapper style={{ flex: 1 }}>
                              <Copy
                                style={{
                                  color: colors.light_text_color,
                                  fontSize: 10,
                                  lineHeight: device === "desktop" ? "10px" : 15
                                }}
                              >
                                {placeholder}
                              </Copy>
                              <Copy>
                                {forceLabel && selected_options.length === 0
                                  ? forceLabelPlaceholder
                                  : this.getLabel()}
                              </Copy>
                            </Wrapper>
                          )}

                          {!!icon_right || fa_icon_right ? (
                            <Icon
                              size={18}
                              color={colors.text_color}
                              icon={icon_right}
                              fa_icon={fa_icon_right}
                              style={{ marginLeft: 20 }}
                            />
                          ) : (!!hover_icon || hover_fa_icon) &&
                            options?.hovering &&
                            !has_changed &&
                            !show ? (
                            <Icon
                              size={18}
                              color={hover_icon_color}
                              icon={hover_icon}
                              fa_icon={hover_fa_icon}
                              style={{ marginLeft: 20 }}
                            />
                          ) : null}
                        </Row>
                      )}
                    </Wrapper>

                    {has_changed || show_buttons ? (
                      <>
                        {!!secondary_button && !loading ? (
                          <InlineButton
                            disabled={false}
                            icon={secondary_button_icon}
                            onPress={onSecondaryPress}
                          >
                            {secondary_button}
                          </InlineButton>
                        ) : null}

                        {submit_button_primary ? (
                          <GhostButton
                            primary={true}
                            disabled={!has_changed ? true : false}
                            icon={submit_button_icon}
                            onPress={onSubmit}
                            loading={loading}
                          >
                            {submit_button_title}
                          </GhostButton>
                        ) : (
                          <InlineButton
                            disabled={!has_changed ? true : false}
                            icon={submit_button_icon}
                            onPress={onSubmit}
                            loading={loading}
                          >
                            {submit_button_title}
                          </InlineButton>
                        )}
                      </>
                    ) : null}

                    {renderRight ? renderRight() : null}
                    {renderHover && (options?.hovering || pressedIn)
                      ? renderHover()
                      : null}
                  </Row>
                );
              }
        }
        renderMenu={() => {
          return (
            <>
              {selected_options.length > 0 && include_tags ? (
                <Wrapper
                  style={{
                    alignSelf: "stretch",
                    padding: 10,
                    paddingBottom: 0
                  }}
                >
                  <Row
                    style={{
                      flexWrap: "wrap",
                      justifyContent: "flex-start"
                    }}
                  >
                    {selected_options.map((option, i) => {
                      return (
                        <Tag
                          key={"selected_option_" + i}
                          onPress={() => {
                            this.onRemoveOption(option);
                          }}
                          icon={"close"}
                          selected={true}
                        >
                          {option.label}
                        </Tag>
                      );
                    })}
                  </Row>
                </Wrapper>
              ) : null}
              {include_search ? (
                <Wrapper style={{ alignSelf: "stretch" }}>
                  <SearchBar
                    style={{
                      margin: 10,
                      marginTop: selected_options.length > 0 ? 5 : 10
                    }}
                    title={search_title}
                    onChange={value => {
                      this.setState({
                        search: value
                      });
                    }}
                    value={search}
                  />
                </Wrapper>
              ) : null}

              <List
                style={{ alignSelf: "stretch" }}
                items={this.filterOptions(options)}
                itemStructure={({ item, index }) => {
                  let in_selected_options = false;
                  for (let i = 0; i < selected_options.length; i++) {
                    if (item.value == selected_options[i].value) {
                      in_selected_options = true;
                    }
                  }

                  return (
                    <SelectItem
                      key={"option_" + index}
                      selected={in_selected_options}
                      select_type={item?.locked ? "icon" : select_type}
                      select_icon={item?.locked ? "lock" : null}
                      tooltip={item?.tooltip}
                      description={item.description}
                      noPress={item?.locked ? true : false}
                      disabled={item?.locked ? true : false}
                      onPress={() => {
                        if (select_type === "radio") {
                          this.setState({
                            selected_options: [item]
                          });
                        } else {
                          if (in_selected_options) {
                            this.onRemoveOption(item);
                          } else {
                            this.onAddOption(item);
                          }
                        }
                      }}
                      renderRight={
                        !!item?.helper && device === "desktop"
                          ? () => {
                              return (
                                <Wrapper
                                  tooltip={item?.helper}
                                  tooltipPlacement={"top"}
                                  style={{ paddingRight: 25 }}
                                >
                                  <Icon
                                    icon={"help"}
                                    size={14}
                                    color={colors.light_text_color}
                                  />
                                </Wrapper>
                              );
                            }
                          : !!item?.helper && device === "mobile"
                          ? () => {
                              return (
                                <MoreInfoButton
                                  title={item?.label}
                                  content={item?.helper}
                                  style={{ alignSelf: "stretch" }}
                                  innerStyle={{ alignSelf: "stretch" }}
                                  renderComponent={({
                                    hovering,
                                    pressedIn
                                  }) => {
                                    return (
                                      <Wrapper style={{ paddingRight: 25 }}>
                                        <Icon
                                          icon={"help"}
                                          size={14}
                                          color={colors.light_text_color}
                                        />
                                      </Wrapper>
                                    );
                                  }}
                                />
                              );
                            }
                          : null
                      }
                    >
                      {item.label}
                    </SelectItem>
                  );
                }}
              />
              {renderBottom ? renderBottom() : null}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}

export { SelectMultiple };
