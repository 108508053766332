import React, { Component } from "react";

import ManageTagsButton from "app/DealMachineCore/reuseable/ManageTagsButton";
import { CircleButton } from "app/NativeComponents/snippets";

class TagsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number_of_tags: props.property?.deal?.tags?.length
    };
  }

  componentDidUpdate(prevProps) {
    const { property } = this.props;
    if (property !== prevProps.property) {
      this.setState({
        number_of_tags:
          property && property?.deal && property?.deal?.tags
            ? property?.deal?.tags?.length
            : 0
      });
    }
  }

  render() {
    const { colors, user, property, device } = this.props;
    const { number_of_tags, loading } = this.state;
    const nt = loading ? number_of_tags : property?.deal?.tags?.length;

    return (
      <ManageTagsButton
        popoverPlacement="bottom"
        propertyUpdated={this.props.propertyUpdated}
        onLoading={({ loading, number_of_tags }) => {
          this.setState({
            loading,
            number_of_tags
          });
        }}
        renderComponent={({ pressedIn, hovering }) => {
          return (
            <CircleButton
              noPress={true}
              pressedIn={pressedIn || hovering}
              style={{ marginLeft: 0 }}
              tooltip={nt == 0 ? "Add Tags" : "Manage Tags"}
              tooltipPlacement={"top"}
              icon="style"
              show_notification={true}
              notification_color={
                nt == 0 ? colors.success_color : colors.active_color
              }
              notification_icon={nt == 0 ? "add" : ""}
              notification_number={nt}
            />
          );
        }}
        property={property}
      />
    );
  }
}

export default TagsButton;
