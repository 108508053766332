import React, { PureComponent } from "react";

import {
  Wrapper,
  Row,
  Button,
  Spin,
  Title,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";
import { PopoverMenu } from "app/NativeComponents/snippets";
class Pagination extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      total_pages: 0,
      last_page: false,
      first_page: false,
      current_page: 1,
      showing_page_limit: 6
    };
  }

  componentDidMount() {
    this.calculatePages();
  }

  changePage(page) {
    const { items_loading, onPageChange } = this.props;

    if (!items_loading) {
      onPageChange(page);
    }
  }

  componentDidUpdate(prevProps) {
    const { total_count, items, begin, page } = this.props;

    if (
      total_count !== prevProps.total_count ||
      items.length !== prevProps.items.length ||
      begin !== prevProps.begin ||
      page !== prevProps.page
    ) {
      this.calculatePages();
    }
  }

  calculatePages() {
    const { total_count, limit, page = 1 } = this.props;

    let total_pages = Math.ceil(total_count / limit);

    let last_page = false;
    if (page * limit > total_count - limit) {
      last_page = true;
    }

    this.setState({
      total_pages: parseInt(total_pages),
      last_page: last_page,
      first_page: parseInt(page) === 1 ? true : false,
      current_page: parseInt(page)
    });

    //if the page is too high
    if (page > total_pages) {
    }

    //if page is too low
    if (page < 1) {
    }
  }

  renderPrevButton() {
    const { first_page, total_pages } = this.state;

    if (!this.state.first_page && this.state.total_pages > 1) {
      return (
        <Button
          style={{
            marginRight: 10
          }}
          onPress={() => {
            this.changePage(this.state.current_page - 1);
          }}
        >
          <Copy>Previous</Copy>
        </Button>
      );
    }
  }

  renderNumberButtons() {
    const { total_pages, showing_page_limit, current_page } = this.state;
    if (total_pages > 1) {
      let first_half_of_showing_pages = showing_page_limit / 2;

      if (current_page - first_half_of_showing_pages <= 0) {
        first_half_of_showing_pages = current_page - 1;
      }

      let second_half_of_showing_pages = showing_page_limit / 2;
      if (current_page + second_half_of_showing_pages > total_pages) {
        second_half_of_showing_pages = total_pages - current_page;
      }

      let first_number = current_page - first_half_of_showing_pages;
      let last_number = current_page + second_half_of_showing_pages;

      let number_array = [];
      for (let i = first_number; i <= last_number; i++) {
        number_array.push(i);
      }

      return (
        <Row>
          {number_array.map((number, i) => {
            if (number === current_page) {
              return (
                <Button
                  style={{
                    marginRight: 10
                  }}
                  key={i}
                  onPress={() => {
                    this.changePage(number);
                  }}
                >
                  <Copy>
                    <Bold>{number}</Bold>
                  </Copy>
                </Button>
              );
            }

            return (
              <Button
                style={{
                  marginRight: 10
                }}
                key={i}
                onPress={() => {
                  this.changePage(number);
                }}
              >
                <Copy>{number}</Copy>
              </Button>
            );
          })}
        </Row>
      );
    }
  }

  renderNextButton() {
    const { current_page, last_page, total_pages } = this.state;
    if (!this.state.last_page && this.state.total_pages > 1) {
      return (
        <Button
          onPress={() => {
            this.changePage(this.state.current_page + 1);
          }}
        >
          <Copy>
            <Bold>Next</Bold>
          </Copy>
        </Button>
      );
    }
  }

  renderOnlyPageMessage() {
    const { items, total_count } = this.props;
    const { first_page, last_page } = this.state;

    if (
      items.length > 0 &&
      this.state.first_page &&
      this.state.last_page &&
      total_count <= items.length &&
      total_count > 0
    ) {
      if (items.length === 1) {
        return <Copy>Showing 1 of 1.</Copy>;
      }
      return (
        <Copy>
          Showing {items.length} of {items.length}.
        </Copy>
      );
    }
  }

  renderTotalToDisplayButton() {
    const { limit, onLimitChange } = this.props;
    const { total_pages } = this.state;

    if (total_pages > 1) {
      return (
        <PopoverMenu
          style={{ padding: 15 }}
          popoverPlacement={"top"}
          icon={"keyboard-arrow-down"}
          title={limit + " per page"}
          icon_size={18}
          menu_items={[
            {
              title: "25 per page",
              select_type: "radio",
              selected: limit == 25 ? true : false,
              onPress: () => {
                onLimitChange(25);
              }
            },
            {
              title: "50 per page",
              select_type: "radio",
              selected: limit == 50 ? true : false,
              type: "normal",
              onPress: () => {
                onLimitChange(50);
              }
            },
            {
              title: "100 per page",
              select_type: "radio",
              selected: limit == 100 ? true : false,
              onPress: () => {
                onLimitChange(100);
              }
            }
          ]}
        />
      );
    }
  }

  render() {
    const { colors, limit, total_count, total_count_loading, items_loading } =
      this.props;

    if (limit > 0 && total_count > 0 && !items_loading) {
      return (
        <Wrapper>
          {/*this.renderLoading()*/}
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              borderTopStyle: "solid",
              borderTopWidth: 1,
              borderTopColor: colors.border_color,
              padding: 15
            }}
          >
            <Row>
              <Row
                style={{
                  marginRight: 20
                }}
              >
                {this.renderPrevButton()}
                {this.renderNumberButtons()}
                {this.renderNextButton()}
              </Row>

              {this.renderTotalToDisplayButton()}

              {this.renderOnlyPageMessage()}
            </Row>
          </Wrapper>
        </Wrapper>
      );
    }

    if (total_count_loading) {
      return (
        <Wrapper>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              borderTopStyle: "solid",
              borderTopWidth: 1,
              borderTopColor: colors.border_color,
              padding: 15
            }}
          >
            <Copy>Loading Pages...</Copy>
          </Wrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper
        style={{
          borderTopStyle: "solid",
          borderTopWidth: 1,
          borderTopColor: colors.border_color
        }}
      />
    );
  }
}

export default Pagination;
