import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon,
  Button
} from "app/NativeComponents/common";

class StepTab extends Component {
  renderNotifications() {
    if (this.props.notifications > 0) {
      return (
        <Wrapper
          style={{
            marginLeft: 5,
            alignItems: "center",
            justifyContent: "center",
            width: 14,
            height: 14,
            backgroundColor: this.props.colors.active_color,
            borderRadius: 7
          }}
        >
          <Copy
            style={{ fontSize: 10, color: this.props.colors.white_text_color }}
          >
            <Bold>{this.props.notifications}</Bold>
          </Copy>
        </Wrapper>
      );
    }
  }

  renderText() {
    if (this.props.active) {
      return (
        <Row>
          <Icon size={14} icon={this.props.icon} fa_icon={this.props.fa_icon} />
          <Copy style={{ marginLeft: 5, fontSize: 12, whiteSpace: "nowrap" }}>
            <Bold>{this.props.title}</Bold>
          </Copy>
          {this.renderNotifications()}
        </Row>
      );
    }

    return (
      <Row>
        <Copy style={{ marginLeft: 5, fontSize: 12, whiteSpace: "nowrap" }}>
          {this.props.title}
        </Copy>
        {this.renderNotifications()}
      </Row>
    );
  }

  render() {
    if (this.props.show_tab) {
      if (this.props.active) {
        return (
          <Button
            style={{
              height: 40,
              padding: 10,
              paddingTop: 0,
              paddingBottom: 0,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: this.props.colors.card_color,
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20
            }}
            onPress={this.props.onPress}
          >
            {this.renderText()}
          </Button>
        );
      }

      return (
        <Button
          style={{
            padding: 10,
            paddingTop: 0,
            paddingBottom: 0,
            height: 40,
            alignItems: "center",
            justifyContent: "center"
          }}
          onPress={this.props.onPress}
        >
          {this.renderText()}
        </Button>
      );
    }
    return <Wrapper />;
  }
}

export default StepTab;
