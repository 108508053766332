import React, { PureComponent } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Wrapper,
  Title,
  CenterCenter,
  InternalImage,
  Copy,
  Bold
} from "app/NativeComponents/common";

class Carousel extends PureComponent {
  render() {
    const {
      autoplay = false,
      infinite = false,
      dots = true,
      arrows = true,
      slidesToShow = 1,
      centerMode = false,
      centerPadding = "20px",
      onSwipe = () => {}
    } = this.props;

    return (
      <Slider
        dots={dots}
        arrows={arrows}
        infinite={infinite}
        speed={500}
        slidesToShow={slidesToShow}
        autoplay={autoplay}
        centerMode={centerMode}
        centerPadding={centerPadding}
        onSwipe={onSwipe}
      >
        {this.props.items.map((item, index) => {
          return this.props.renderItem({ item, index });
        })}
      </Slider>
    );
  }
}

export { Carousel };
