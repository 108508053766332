import React, { PureComponent } from "react";
import { InputBox } from "app/NativeComponents/snippets";
import { store } from "app/store";
import { openUrl } from "app/NativeActions";
class ListName extends PureComponent {
  constructor(props) {
    super(props);

    this._list_name_input = React.createRef();
  }
  render() {
    const {
      colors,
      isMobile,
      device,
      estimated_count,
      count_loading,
      list_name,
      renderBottom = null,
      renderLeft = null,
      renderTop = null,
      style
    } = this.props;
    const can_press = this.props.checkIfCanBuild();
    const dark_mode = store.getState().settings.dark_mode;

    return (
      <InputBox
        input_ref={this._list_name_input}
        autoFocus={false}
        name="list_name"
        disabled={false}
        returnKeyType="done"
        placeholder={!!list_name ? "List name" : "Enter a name for this list"}
        onFocus={() => {}}
        onChange={this.props.updateListName}
        blurOnSubmit={true}
        value={list_name}
        input_type="text"
        require_confirm={!isMobile ? true : false}
        has_changed={can_press}
        show_buttons={!isMobile ? true : false}
        submit_button_primary={true}
        submit_button_title={"Build List"}
        onSubmit={
          this.props.demo
            ? () => {
                openUrl("https://app.dealmachine.com/sign-up");
              }
            : () => this.props.buildList()
        }
        onSubmitEditing={
          this.props.demo
            ? () => {
                openUrl("https://app.dealmachine.com/sign-up");
              }
            : device == "mobile"
            ? () => this.props.buildList()
            : () => {}
        }
        loading={this.props.loading}
        //autoFocus={isMobile ? true : false}
        type={isMobile ? "multiline" : "text"}
        numberOfLines={3}
        style={style}
        innerStyle={
          !isMobile
            ? {
                padding: 20,
                margin: 5,
                borderRadius: 5,
                backgroundColor:
                  dark_mode == "dark_mode"
                    ? "rgba(0, 0, 0, 0.25)"
                    : "rgba(255, 255, 255, 0.5)"
              }
            : {}
        }
        renderBottom={renderBottom}
        renderLeft={renderLeft}
        renderTop={renderTop}
      />
    );
  }
}

export default ListName;
