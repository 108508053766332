import React, { Component } from "react";
import { Spin } from "app/NativeComponents/common";

import HubspotForm from "react-hubspot-form";

import { AppConfig } from "app/NativeActions";

class Hubspot extends Component {
  render() {
    return (
      <div>
        <HubspotForm
          portalId={AppConfig().hubspot_id}
          formId={this.props.form.formID}
          onSubmit={() => {}}
          onReady={form => {}}
          loading={
            <div style={{ textAlign: "center" }}>
              <Spin size="small" />
            </div>
          }
        />
      </div>
    );
  }
}

export default Hubspot;
