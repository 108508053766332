import React, { PureComponent } from "react";
import { connect } from "react-redux";

class ProftiwellWrapper extends PureComponent {
  constructor() {
    super();
    this.state = {};

    this.trackProfitwellUser = this.trackProfitwellUser.bind(this);
  }
  componentDidMount() {
    if (this.props.user) {
      this.trackProfitwellUser(this.props.user);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user?.id !== prevProps?.user?.id && this.props.user?.id) {
      this.trackProfitwellUser(this.props.user);
    }
  }

  trackProfitwellUser(user) {
    if (window?.profitwell && user?.email) {
      window.profitwell("start", { user_email: user.email });
    }
  }

  render() {
    return null;
  }
}
const mapStateToProps = ({ auth, native }) => {
  const { user } = auth;

  const { device, platform } = native;

  return {
    user,
    device,
    platform
  };
};

export default connect(mapStateToProps, {})(ProftiwellWrapper);
