import {
  SET_ONBOARDING_STEPS,
  SET_CURRENT_ONBOARDING_STEP,
  ONBOARDING_AUTO_COMPLETE,
  ONBOARDING_LOADING_SUCCESS,
  SET_ONBOARDING_LAYOUT,
  SET_ROUTE_FOR_ONBOARDING
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  currentStep: {},
  steps: [],
  isOnboardingActive: false,
  autocompleteInProgress: false,
  onboardingLoading: true,
  onboardingLayouts: {},
  routeForOnboarding: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ROUTE_FOR_ONBOARDING:
      return {
        ...state,
        routeForOnboarding: action.payload
      };
    case SET_ONBOARDING_STEPS:
      return {
        ...state,
        steps: action.payload.steps,
        currentStep: action.payload.initStep
          ? action.payload.initStep
          : action.payload.steps[0],
        isOnboardingActive: action.payload?.isOnboardingActive
          ? action.payload?.isOnboardingActive
          : false
      };

    case SET_CURRENT_ONBOARDING_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
        isOnboardingActive:
          typeof action.payload?.isOnboardingActive == "boolean"
            ? action.payload?.isOnboardingActive
            : state.isOnboardingActive
      };
    case ONBOARDING_LOADING_SUCCESS:
      return {
        ...state,
        onboardingLoading: action.payload
      };

    case ONBOARDING_AUTO_COMPLETE:
      return {
        ...state,
        autocompleteInProgress: action.payload
      };

    case SET_ONBOARDING_LAYOUT:
      return {
        ...state,
        onboardingLayouts: {
          ...state.onboardingLayouts,
          [`${action.payload.componentName}`]: action.payload.layout
        }
      };
    default:
      return state;
  }
};
