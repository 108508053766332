import React, { PureComponent } from "react";

import Dropzone from "react-dropzone";
class DropzoneButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show || false,
      color: props.value,
      hovering: false
    };
  }

  render() {
    const {
      style,
      children,
      accept,
      onDrop,
      onPress,
      maxSize = null,
      multiple = false,
      maxFiles = 10,
      renderComponent = null
    } = this.props;
    return (
      <Dropzone
        onClick={ev => {
          if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
          }

          if (onPress) {
            onPress();
          }
        }}
        className="dropzone-button"
        style={{ ...style, border: "none" }}
        accept={accept}
        onDrop={onDrop}
        multiple={multiple}
        maxFiles={maxFiles}
        onMouseEnter={() => {
          this.setState({
            hovering: true
          });
        }}
        onMouseLeave={() => {
          this.setState({
            hovering: false
          });
        }}
      >
        {renderComponent
          ? renderComponent({ hovering: this.state.hovering })
          : children}
      </Dropzone>
    );
  }
}

export { DropzoneButton };
