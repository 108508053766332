import React, { Component } from "react";

import { Button, Wrapper, Icon } from "app/NativeComponents/common";

class SelectAllCheck extends Component {
  render() {
    const {
      selected_all,
      selected_leads,
      selected_pages,
      list_properties_page,
      colors
    } = this.props;

    return (
      <Button
        onPress={() => {
          this.props.checkAllLeadsOnPage(list_properties_page);
        }}
        style={{
          backgroundColor: colors.background_color,
          borderRightWidth: 1,
          borderRightColor: colors.border_color,
          borderRightStyle: "solid",

          borderBottomWidth: 1,
          borderBottomColor: colors.border_color,
          borderBottomStyle: "solid"
        }}
      >
        <Wrapper
          tooltip={
            selected_pages.includes(list_properties_page)
              ? "Clear all on page"
              : "Select all on page"
          }
          tooltipPlacement="top"
          style={{
            padding: 10,
            paddingRight: 0,
            paddingLeft: 0,
            width: 60,
            height: 25,

            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Icon
            icon={
              selected_all || selected_pages.includes(list_properties_page)
                ? "check-box"
                : /*selected_leads.length > 0
                ? "indeterminate-check-box"
                : */ "check-box-outline-blank"
            }
            size={22}
          />
        </Wrapper>
      </Button>
    );
  }
}

export default SelectAllCheck;
