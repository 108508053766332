import React, { Component } from "react";
import { connect } from "react-redux";

import {
  updateRouteTimer,
  updateMapLocation,
  toggleRegionChange,
  lockLocationTracking,
  setActiveDeal,
  removePin,
  updateTrackedRoute,
  updateCurrentRouteSection,
  getMyLocation
} from "app/NativeActions";

import moment from "moment";

class BackgroundLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      save_route: true,
      location_tracker_ready: false,
      in_background: false,
      started_background_tracker: false,
      last_tracked_location: null
    };
  }

  startBackgroundTracker() {}

  componentDidMount() {}

  startRouteTimer(track_route) {
    if (track_route && this.props.track_route_type == "location") {
      clearInterval(this._time_interval);
      this._time_interval = setInterval(() => {
        this.props.updateRouteTimer();
      }, 1000);
    }
  }

  async watchPosition() {
    if (this.props.track_route_type == "location") {
      this.startRouteTimer(this.props.track_route);
      /*
    getMyLocation((coordinate)=>{
      this.onLocation({
        coords: {
          latitude: coordinate.latitude,
          longitude: coordinate.longitude,
          heading: coordinate.heading  }
      })
    });
    */

      if (
        (this.props.lock_location_tracking || this.props.track_route) &&
        !this.state.started_watch
      ) {
        //start watch and prevent multiple starts
        this.setState({ started_watch: true });
        //start watch position
        //if(!this._watchId){

        this._watchId = navigator.geolocation.watchPosition(
          position => {
            this.onLocation(position);
          },
          error => {
            this.clearWatch();
            this.watchPosition();
          },
          { enableHighAccuracy: true, timeout: 10000, distanceFilter: 10 }
        );

        //}
      }
    }
  }

  clearWatch() {
    this.setState({ started_watch: false });
    if (this._watchId) {
      navigator.geolocation.clearWatch(this._watchId);
    }
  }

  handleActive() {
    this.setState({ in_background: false });

    if (this.props.lock_location_tracking) {
      getMyLocation(coordinate => {
        this.onLocation({
          coords: {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
            heading: coordinate.heading
          }
        });
      });
    }

    if (this.props.lock_location_tracking || this.props.track_route) {
      this.watchPosition();
    }

    //lets start a brand new route section
    this.breakCurrentRoute();
  }

  handleBackground() {
    this.setState({ in_background: true });
    this.clearWatch();

    //end route tracking section
    this.breakCurrentRoute();
  }

  breakCurrentRoute() {
    if (
      this.props.track_route &&
      !this.props.save_route_loading &&
      this.props.current_route_section
    ) {
      if (this.props.current_route) {
        if (this.props.current_route_section.coordinates.length > 0) {
          this.props.updateTrackedRoute({
            token: this.props.token,
            route_id: this.props.current_route.route_id,
            coordinates: this.props.current_route_section.coordinates,
            current_route: this.props.current_route
          });
        }
      }
    }
  }

  onLocation(location) {
    this.setState({
      last_tracked_location: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        heading: location.coords.heading
      }
    });

    if (this.props.lock_location_tracking && !this.state.in_background) {
      this.props.updateMapLocation({
        coordinates: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          heading: location.coords.heading
        }
      });
    }

    if (!this.state.in_background) {
      this.trackRoute({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      });
    }
  }

  trackRoute(coordinates) {
    if (
      this.props.track_route &&
      !this.props.save_route_loading &&
      this.props.current_route_section
    ) {
      this.props.updateCurrentRouteSection({
        current_route: this.props.current_route,
        coordinates: [
          ...this.props.current_route_section.coordinates,
          coordinates
        ]
      });

      if (this.props.current_route) {
        if (this.props.current_route_section.coordinates.length > 15) {
          this.props.updateTrackedRoute({
            token: this.props.token,
            route_id: this.props.current_route.route_id,
            coordinates: this.props.current_route_section.coordinates,
            current_route: this.props.current_route
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.lock_location_tracking == false &&
        this.props.lock_location_tracking == true) ||
      (prevProps.track_route == false && this.props.track_route == true)
    ) {
      this.startBackgroundTracker();
      this.watchPosition();
    } else if (
      (prevProps.track_route == true ||
        prevProps.lock_location_tracking == true) &&
      this.props.track_route == false &&
      this.props.lock_location_tracking == false
    ) {
      this.endBackgroundTracker();
      this.clearWatch();
    }

    if (
      prevProps.lock_location_tracking == false &&
      this.props.lock_location_tracking == true &&
      prevProps.track_route == true
    ) {
      if (this.state.last_tracked_location) {
        this.props.updateMapLocation({
          coordinates: this.state.last_tracked_location
        });
      }
    }

    if (prevProps.track_route == false && this.props.track_route == true) {
      this.startBackgroundTracker();
    } else if (
      prevProps.track_route == true &&
      this.props.track_route == false
    ) {
      this.endBackgroundTracker();
    }
  }

  endBackgroundTracker() {
    this.setState({ started_background_tracker: false });
  }

  componentWillUnmount() {
    this.clearWatch();
    this.endBackgroundTracker();
    clearInterval(this._time_interval);
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ auth, native, property_map, map, route }) => {
  const { token, user } = auth;
  const { platform } = native;
  const { lock_location_tracking, locationAllowed } = map;
  const {
    track_route,
    current_route,
    save_route_loading,
    current_route_section,
    track_route_type
  } = route;

  return {
    token,
    user,
    platform,
    locationAllowed,
    lock_location_tracking,
    track_route,
    current_route,
    save_route_loading,
    current_route_section,
    track_route_type
  };
};

export default connect(mapStateToProps, {
  updateRouteTimer,
  updateMapLocation,
  toggleRegionChange,
  lockLocationTracking,
  setActiveDeal,
  removePin,

  updateTrackedRoute,
  updateCurrentRouteSection
})(BackgroundLocation);
