import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Page,
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  InputBox,
  GhostButton,
  InlineButton,
  OnboardingWrapper,
  OnboardingNavbar
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess,
  dismissMobileKeyboard
} from "app/NativeActions";
class ValidateEmailStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verification_code: "",
      loading_new_code: false,
      submitting: false
    };
    this._verification_input = React.createRef();

    this.verifyEmail = this.verifyEmail.bind(this);
  }

  verifyEmail() {
    dismissMobileKeyboard();

    if (
      this.state.verification_code &&
      !this.state.submitting &&
      !this.state.loading_new_code
    ) {
      this.props.updateUser({
        token: this.props.token,
        type: "verify_email",
        payload: {
          verification_code: this.state.verification_code
        },
        onLoading: () => {
          this.setState({
            submitting: true
          });
        },
        onError: () => {
          this.setState({
            submitting: false
          });
        },
        onSuccess: () => {
          if (this.props.user?.team_owner != 1) {
            this.props.startRedirect("/");
          }
        }
      });
    }
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/verify-email");
    }
    if (this.props.user?.email_sent == 0) {
      if (!this.props.loading) {
        this.props.updateUser({
          token: this.props.token,
          type: "send_verify_email",
          onLoading: () => {
            this.setState({
              loading: true
            });
          },
          onError: () => {
            this.setState({
              loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              loading: false
            });
          }
        });
      }
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    return (
      <Page
        metaTitle={"Verify your email - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25,
                maxWidth: 500,
                margin: this.props.device == "desktop" ? "0px auto" : 0
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                <Bold>Verify your email</Bold>
              </Header1>
              <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  Please check your inbox for a verification code to confirm
                  your identity.
                </Header3>
              </Wrapper>
            </Wrapper>

            <Wrapper
              style={{
                maxWidth: 500,
                width: "100%",
                margin: this.props.device == "desktop" ? "5px auto" : 0
              }}
            >
              <Wrapper style={{ padding: 25 }}>
                <Copy
                  style={
                    this.props.device === "mobile"
                      ? { textAlign: "center" }
                      : {}
                  }
                >
                  Email sent to <Bold>{user.email}</Bold>.
                </Copy>
              </Wrapper>
              <Card>
                <Wrapper>
                  <InputBox
                    input_ref={this._verification_input}
                    autoFocus={this.props.device == "mobile" ? false : true}
                    name="verify_email"
                    disabled={this.state.submitting}
                    returnKeyType="done"
                    placeholder={"Verification Code"}
                    onFocus={() => {}}
                    onChange={value => {
                      this.setState(
                        {
                          verification_code: value
                        },
                        () => {
                          if (this.state.verification_code.length == 6) {
                            this.verifyEmail();
                          }
                        }
                      );
                    }}
                    blurOnSubmit={true}
                    value={this.state.verification_code}
                    input_type="text"
                    submit_button_title={"Verify"}
                    onSubmitEditing={this.verifyEmail}
                    style={{ borderRadius: 5, margin: 5 }}
                  />
                  <GhostButton
                    button_type={"full"}
                    loading={this.state.submitting}
                    disabled={!this.state.verification_code}
                    primary={true}
                    icon_right={"arrow-right-alt"}
                    onPress={this.verifyEmail}
                  >
                    Verify
                  </GhostButton>
                </Wrapper>
              </Card>
              <Row style={{ justifyContent: "space-between" }}>
                <InlineButton
                  loading={this.state.loading_new_code}
                  onPress={() => {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "resend_verify_email",
                      onLoading: () => {
                        this.setState({
                          loading_new_code: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          loading_new_code: false
                        });
                      },
                      onSuccess: results => {
                        this.setState({
                          loading_new_code: false
                        });

                        this.props.showSuccess("Email verification code sent.");
                      }
                    });
                  }}
                >
                  Send a new code
                </InlineButton>

                <InlineButton
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "verify_use_different_email",
                      overlay: true
                    });
                  }}
                >
                  Try a different email
                </InlineButton>
              </Row>
            </Wrapper>
          </>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  pushSidePanel,
  updateUser,
  showSuccess
})(ValidateEmailStep);
