import React, { Component } from "react";
import { Wrapper, Copy, Bold, Row } from "app/NativeComponents/common";
import { store } from "app/store";
import { IconButton } from "app/NativeComponents/snippets";
class Header extends Component {
  constructor(props) {
    super(props);
    this.sortBy = this.sortBy.bind(this);
  }

  sortBy() {
    const { slug, sort_by } = this.props;
    this.props.onSort({
      slug: this.props.slug,
      type:
        sort_by?.slug == slug
          ? sort_by?.type == "desc"
            ? "asc"
            : "desc"
          : sort_by.type
    });
  }

  renderSortIcon() {
    const colors = store.getState().settings.colors;

    const { slug, sort_by, can_sort_by } = this.props;
    if (slug === sort_by.slug) {
      switch (sort_by.type) {
        case "asc":
          return (
            <IconButton
              size={18}
              icon={"keyboard-arrow-up"}
              onPress={this.sortBy}
            />
          );

        case "desc":
        default:
          return (
            <IconButton
              size={18}
              icon={"keyboard-arrow-down"}
              onPress={this.sortBy}
            />
          );
      }
    }

    if (can_sort_by) {
      return (
        <IconButton
          size={12}
          fa_icon={"sort"}
          icon_color={colors.light_text_color}
          onPress={this.sortBy}
        />
      );
    }

    //hide this to keep same width for header
    return null;
  }

  render() {
    const colors = store.getState().settings.colors;
    const {
      title,
      sort_by,
      slug,
      main_column = false,
      width = 120
    } = this.props;
    return (
      <Wrapper
        style={
          main_column
            ? { flex: 1, padding: 25, paddingTop: 10, paddingBottom: 10 }
            : { width, padding: 25, paddingTop: 10, paddingBottom: 10 }
        }
      >
        <Row>
          {main_column ? (
            <Wrapper
              style={{
                width: 50,
                alignItems: "center",
                justifyContent: "center"
              }}
            />
          ) : null}

          <Wrapper>
            {}
            <Copy
              style={{
                whiteSpace: "nowrap",
                color:
                  slug === sort_by?.slug
                    ? colors.actionable_text_color
                    : colors.text_color
              }}
            >
              {slug === sort_by?.slug ? <Bold>{title}</Bold> : <>{title}</>}
            </Copy>
          </Wrapper>

          {this.renderSortIcon()}
        </Row>
      </Wrapper>
    );
  }
}

export default Header;
