import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Title,
  Bold,
  Copy,
  Icon,
  RangeSlider
} from "app/NativeComponents/common";

import {
  updateDashboard,
  getDashboardSettings,
  numberWithCommas,
  renderPrice
} from "app/NativeActions";

class EnterpriseGoals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current_state: props.enterprise_goal_variables
        ? props.enterprise_goal_variables.state
        : "",
      marketing_cost: props.enterprise_goal_variables
        ? props.enterprise_goal_variables.marketing_cost
        : 0,
      mailers_per_lead: props.enterprise_goal_variables
        ? props.enterprise_goal_variables.mailers_per_lead
        : 0,
      properties_per_hour: props.enterprise_goal_variables
        ? props.enterprise_goal_variables.properties_per_hour
        : 0,
      dollars_per_hour: props.enterprise_goal_variables
        ? props.enterprise_goal_variables.dollars_per_hour
        : 0,
      leads_per_deal: props.enterprise_goal_variables
        ? props.enterprise_goal_variables.leads_per_deal
        : 0,

      profit_per_month: props.user.enterprise_goals
        ? props.user.enterprise_goals.profit_per_month
        : 10000,
      min_profit_per_month: 10000,
      max_profit_per_month: 200000,

      profit_per_deal: props.user.enterprise_goals
        ? props.user.enterprise_goals.profit_per_deal
        : 1000,
      min_profit_per_deal: 1000,
      max_profit_per_deal: 80000,

      number_of_drivers: props.user.enterprise_goals
        ? props.user.enterprise_goals.number_of_drivers
        : 2,
      min_number_of_drivers: 2,
      max_number_of_drivers: 100
    };
  }

  componentDidMount() {
    this.saveGoals();
    this.props.getDashboardSettings({
      token: this.props.token,
      type: "enterprise_goal_variables"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.profit_per_month !== this.state.profit_per_month ||
      prevState.profit_per_deal !== this.state.profit_per_deal ||
      prevState.number_of_drivers !== this.state.number_of_drivers
    ) {
      this.saveGoals();
    }

    if (
      prevProps.enterprise_goal_variables !==
      this.props.enterprise_goal_variables
    ) {
      this.setState({
        current_state: this.props.enterprise_goal_variables.state,
        marketing_cost: this.props.enterprise_goal_variables.marketing_cost,
        mailers_per_lead: this.props.enterprise_goal_variables.mailers_per_lead,
        properties_per_hour:
          this.props.enterprise_goal_variables.properties_per_hour,
        dollars_per_hour: this.props.enterprise_goal_variables.dollars_per_hour,
        leads_per_deal: this.props.enterprise_goal_variables.leads_per_deal
      });
    }
  }

  saveGoals() {
    this.props.updateDashboard({
      token: this.props.token,
      type: "enterprise_goals",
      goals: {
        profit_per_month: this.state.profit_per_month,
        profit_per_deal: this.state.profit_per_deal,
        number_of_drivers: this.state.number_of_drivers
      }
    });
  }

  calculateNumberOfDeals() {
    return Math.ceil(this.state.profit_per_month / this.state.profit_per_deal);
  }

  calculateNumberOfLeads() {
    return this.calculateNumberOfDeals() * this.state.leads_per_deal;
  }

  calculateNumberOfPropertiesPerWeek() {
    let number_of_properties = Math.ceil(this.calculateNumberOfLeads() / 4);
    number_of_properties = Math.ceil(
      number_of_properties / this.state.number_of_drivers
    );

    return number_of_properties;
  }

  calculateNumberOfHoursPerWeek() {
    const properties_per_hour = this.state.properties_per_hour;
    return Math.ceil(
      this.calculateNumberOfPropertiesPerWeek() / properties_per_hour
    );
  }

  calculateMonthlyDriverExpense(type) {
    const dollars_per_hour = this.state.dollars_per_hour; //in cents
    const weekly_expense =
      this.calculateNumberOfHoursPerWeek() *
      this.state.number_of_drivers *
      dollars_per_hour;
    if (type == "yearly") {
      return weekly_expense * 4 * 12;
    }
    return weekly_expense * 4;
  }

  calculateMarketingExpenses(type) {
    const marketing_cost = this.state.marketing_cost;
    if (type == "yearly") {
      const leads_per_year = this.calculateNumberOfLeads() * 12;

      return leads_per_year * marketing_cost * this.state.mailers_per_lead;
    }
    return (
      this.calculateNumberOfLeads() *
      marketing_cost *
      this.state.mailers_per_lead
    );
  }

  calculateAnnualTechnologyExpenses() {
    return 299000;
  }

  calculateAnnualRevenue() {
    return (
      this.calculateNumberOfDeals() * (this.state.profit_per_deal * 100) * 12
    );
  }

  calculateAnnualProfit() {
    return (
      this.calculateAnnualRevenue() -
      this.calculateAnnualTechnologyExpenses() -
      this.calculateMonthlyDriverExpense("yearly") -
      this.calculateMarketingExpenses("yearly")
    );
  }

  render() {
    if (
      this.props.enterprise_goal_variables &&
      this.props.enterprise_goal_variables.leads_per_deal > 0
    ) {
      return (
        <Wrapper>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: this.props.dashboardMobile ? "column" : "row"
            }}
          >
            <Wrapper>
              <Wrapper style={{ padding: 15 }}>
                <Title style={{ textAlign: "center" }}>
                  I want to create{" "}
                  <Bold style={{ color: this.props.colors.active_color }}>
                    {numberWithCommas(this.state.profit_per_month)} of profit
                  </Bold>{" "}
                  per month
                </Title>
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "stretch"
                  }}
                >
                  <RangeSlider
                    min={this.state.min_profit_per_month}
                    max={this.state.max_profit_per_month}
                    value={this.state.profit_per_month}
                    step={1000}
                    onChange={value => {
                      this.setState({
                        profit_per_month: value
                      });
                    }}
                  />
                </Wrapper>
              </Wrapper>

              <Wrapper style={{ padding: 15 }}>
                <Title style={{ textAlign: "center" }}>
                  I expect to make{" "}
                  <Bold style={{ color: this.props.colors.active_color }}>
                    {numberWithCommas(this.state.profit_per_deal)} profit per
                    deal
                  </Bold>
                </Title>
                <Wrapper
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <RangeSlider
                    min={this.state.min_profit_per_deal}
                    max={this.state.max_profit_per_deal}
                    value={this.state.profit_per_deal}
                    step={1000}
                    onChange={value => {
                      this.setState({
                        profit_per_deal: value
                      });
                    }}
                  />
                </Wrapper>
              </Wrapper>

              <Wrapper style={{ padding: 15 }}>
                <Title style={{ textAlign: "center" }}>
                  I have{" "}
                  <Bold style={{ color: this.props.colors.active_color }}>
                    {numberWithCommas(this.state.number_of_drivers)} drivers
                  </Bold>
                </Title>
                <Wrapper
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <RangeSlider
                    min={this.state.min_number_of_drivers}
                    max={this.state.max_number_of_drivers}
                    value={this.state.number_of_drivers}
                    step={1}
                    onChange={value => {
                      this.setState({
                        number_of_drivers: value
                      });
                    }}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <Wrapper style={{ padding: 15, flex: 1, alignSelf: "stretch" }}>
                <Row>
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 50
                    }}
                  >
                    <Icon
                      fa_icon="trophy"
                      size={32}
                      color={this.props.colors.active_color}
                    />
                  </Wrapper>
                  <Wrapper style={{ flex: 1 }}>
                    <Copy>
                      <Bold>
                        {this.calculateNumberOfDeals() == 1
                          ? "1 deal"
                          : numberWithCommas(this.calculateNumberOfDeals()) +
                            " deals"}{" "}
                        per month
                      </Bold>{" "}
                      is our goal
                    </Copy>
                  </Wrapper>
                </Row>
              </Wrapper>

              <Wrapper style={{ padding: 15, flex: 1, alignSelf: "stretch" }}>
                <Row>
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 50
                    }}
                  >
                    <Icon
                      icon="home"
                      size={32}
                      color={this.props.colors.active_color}
                    />
                  </Wrapper>
                  <Wrapper style={{ flex: 1 }}>
                    <Copy>
                      Add{" "}
                      <Bold>
                        {numberWithCommas(this.calculateNumberOfLeads())} leads{" "}
                      </Bold>{" "}
                      per month
                    </Copy>
                    <Copy
                      style={{
                        color: this.props.colors.light_text_color,
                        fontSize: 12
                      }}
                    >
                      DealMachine members average one deal for every{" "}
                      {this.state.leads_per_deal} leads in{" "}
                      {this.state.current_state}.
                    </Copy>
                  </Wrapper>
                </Row>
              </Wrapper>
              <Wrapper style={{ padding: 15, flex: 1, alignSelf: "stretch" }}>
                <Row>
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 50
                    }}
                  >
                    <Icon
                      icon="drive-eta"
                      size={32}
                      color={this.props.colors.active_color}
                    />
                  </Wrapper>
                  <Wrapper style={{ flex: 1 }}>
                    <Copy>
                      Each driver must{" "}
                      <Bold>
                        {" "}
                        add{" "}
                        {numberWithCommas(
                          this.calculateNumberOfPropertiesPerWeek()
                        )}{" "}
                        properties
                      </Bold>{" "}
                      per week...
                    </Copy>
                    <Copy>
                      ...while{" "}
                      <Bold>
                        {" "}
                        logging{" "}
                        {this.calculateNumberOfHoursPerWeek() == 1
                          ? "1 hour"
                          : numberWithCommas(
                              this.calculateNumberOfHoursPerWeek()
                            ) + " hours"}
                      </Bold>{" "}
                      of weekly drive time.
                    </Copy>
                    <Copy
                      style={{
                        color: this.props.colors.light_text_color,
                        fontSize: 12
                      }}
                    >
                      Drivers typically add {this.state.properties_per_hour}{" "}
                      properties in an hour
                    </Copy>
                  </Wrapper>
                </Row>
              </Wrapper>
              <Wrapper style={{ padding: 15, flex: 1, alignSelf: "stretch" }}>
                <Row>
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 50
                    }}
                  >
                    <Icon
                      icon="attach-money"
                      size={32}
                      color={this.props.colors.active_color}
                    />
                  </Wrapper>
                  <Wrapper style={{ flex: 1 }}>
                    <Wrapper>
                      <Title>
                        My <Bold>annual profit</Bold> should be{" "}
                        <Bold
                          style={{
                            color:
                              this.calculateAnnualProfit() > 0
                                ? this.props.colors.success_color
                                : this.props.colors.error_color
                          }}
                        >
                          {this.calculateAnnualProfit() < 0 ? "-" : ""}
                          {renderPrice(
                            Math.abs(this.calculateAnnualProfit()),
                            "no_decimal"
                          )}
                        </Bold>
                      </Title>
                    </Wrapper>
                  </Wrapper>
                </Row>
              </Wrapper>
              <Wrapper
                style={{
                  padding: 15,
                  paddingTop: 0,
                  flex: 1,
                  alignSelf: "stretch"
                }}
              >
                <Row>
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: 50
                    }}
                  ></Wrapper>
                  <Wrapper style={{ flex: 1 }}>
                    <Copy>
                      <Bold>Expenses:</Bold>
                    </Copy>
                    <Wrapper style={{ marginBottom: 10 }}>
                      <Copy>
                        {renderPrice(
                          this.calculateAnnualTechnologyExpenses(),
                          "no_decimal"
                        )}
                        /yr for <Bold>technology</Bold>
                      </Copy>
                    </Wrapper>
                    <Wrapper style={{ marginBottom: 10 }}>
                      <Copy>
                        {renderPrice(
                          this.calculateMonthlyDriverExpense(),
                          "no_decimal"
                        )}
                        /mo for <Bold>drivers</Bold>
                      </Copy>
                      <Copy
                        style={{
                          color: this.props.colors.light_text_color,
                          fontSize: 12
                        }}
                      >
                        Estimating $
                        {Math.ceil(this.state.dollars_per_hour / 100)} an hour
                      </Copy>
                    </Wrapper>
                    <Wrapper style={{ marginBottom: 10 }}>
                      <Copy>
                        {renderPrice(
                          this.calculateMarketingExpenses(),
                          "no_decimal"
                        )}
                        /mo for <Bold>marketing</Bold>
                      </Copy>
                      <Copy
                        style={{
                          color: this.props.colors.light_text_color,
                          fontSize: 12
                        }}
                      >
                        Estimating {this.state.mailers_per_lead} mail pieces per
                        lead at {renderPrice(this.state.marketing_cost)} per
                        mail piece
                      </Copy>
                    </Wrapper>
                  </Wrapper>
                </Row>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user } = auth;
  const { colors, enterprise_goal_variables } = settings;
  const { device, dashboardMobile } = native;
  return {
    token,
    user,
    colors,
    device,
    dashboardMobile,
    enterprise_goal_variables
  };
};

export default connect(mapStateToProps, {
  updateDashboard,
  getDashboardSettings
})(EnterpriseGoals);
