import React, { Component } from "react";
import {
  Button,
  Card,
  Row,
  Wrapper,
  CardBody,
  Split,
  Stretch,
  Title,
  Icon,
  CenterCenter,
  Copy,
  ExternalImage
} from "app/NativeComponents/common";

import UploadButton from "./UploadButton";

import { Select, TemplatePreview } from "app/NativeComponents/snippets";

class SelectTemplateImage extends Component {
  render() {
    if (
      this.props.template.html_template != null &&
      this.props.template.template_type != "handwritten" &&
      this.props.template.id
    ) {
      return (
        <Wrapper>
          <Card>
            <Select
              item_ref={"select_image_option"}
              items={[
                {
                  key: 0,
                  value: "property_image",
                  label: "Use property image"
                },
                {
                  key: 1,
                  value: "include_custom_image",
                  label: "Upload an image to always show on template"
                }
              ]}
              title="Template Image:"
              label="Select an option"
              value={this.props.template.include_custom_image}
              text={
                this.props.template.include_custom_image == "property_image"
                  ? "Use property image"
                  : this.props.template.include_custom_image ==
                    "include_custom_image"
                  ? "Upload an image to always show on template"
                  : ""
              }
              onSelect={item => {
                this.props.updateTemplate({
                  prop: "include_custom_image",
                  value: item
                });
              }}
            />
            <UploadButton {...this.props} />
          </Card>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default SelectTemplateImage;
