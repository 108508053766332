import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Title,
  WebContainer,
  Wrapper
} from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  /*mobile*/
  toggleCanPop,
  tabNavigation,
  editTeam,
  toggleOnboarding,
  toggleDrawer
} from "app/NativeActions";

class OnboardingTrainingVideos extends Component {
  constructor(props) {
    super(props);

    //lets create the video array
    let video_array = [];

    if (this.props.user_dealfinder_page?.include_video_1 == 1) {
      video_array.push(1);
    }

    if (this.props.user_dealfinder_page?.include_video_2 == 1) {
      video_array.push(2);
    }

    if (this.props.user_dealfinder_page?.include_video_3 == 1) {
      video_array.push(3);
    }

    if (this.props.user_dealfinder_page?.include_video_4 == 1) {
      video_array.push(4);
    }

    if (this.props.user_dealfinder_page?.include_video_5 == 1) {
      video_array.push(5);
    }

    if (this.props.user_dealfinder_page?.include_video_6 == 1) {
      video_array.push(6);
    }

    if (this.props.user_dealfinder_page?.include_video_7 == 1) {
      video_array.push(7);
    }

    this.state = {
      step: video_array[0],
      index: 1,
      download_app: false,
      complete: false,
      video_array: video_array
    };
  }

  nextStep(index) {
    if (index > this.state.video_array.length) {
      //complete training

      this.setState({ complete: true, step: null, index: null });
    } else {
      this.setState({
        complete: false,
        download_app: false,
        step: this.state.video_array[parseInt(index) - 1],
        index: parseInt(index)
      });
    }
  }

  componentDidMount() {
    if (
      this.props.user?.team_clearance_level > 0 ||
      this.props.user?.complete_dealfinder_training == 1 ||
      this.props.user_dealfinder_page?.require_training == 0
    ) {
      this.props.tabNavigation({ slug: "map" });
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <Container>
        {this.props.device == "mobile" ? (
          <NewHeader title="Driver Training" />
        ) : null}
        <WebContainer>
          <Body
            step={this.state.step}
            index={this.state.index}
            download_app={this.state.download_app}
            complete={this.state.complete}
            nextStep={this.nextStep.bind(this)}
            video_array={this.state.video_array}
            {...this.props}
          />
        </WebContainer>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, drawer, team_link }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { colors } = settings;
  const { stats } = drawer;
  const { require_training, user_dealfinder_page, dealfinder_page_defaults } =
    team_link;

  return {
    token,
    user,
    device,
    platform,
    colors,
    stats,
    isMobile,

    require_training,
    user_dealfinder_page,
    dealfinder_page_defaults
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,
  editTeam,
  toggleOnboarding,
  toggleDrawer,
  tabNavigation
})(OnboardingTrainingVideos);
