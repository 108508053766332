import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Button, PrimaryButton } from "app/NativeComponents/common";
import { ToggleSwitch } from "app/NativeComponents/snippets";

import { setModal, toggleModal, updateBillingAddon } from "app/NativeActions";

class LockButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      unlocked: false,
      addon: null
    };
  }

  setFeatureLock(billing_addons) {
    const feature_slug = this.props.feature_slug;

    for (var i = 0; i < billing_addons.length; i++) {
      if (billing_addons[i].slug == feature_slug) {
        this.setState({ addon: billing_addons[i] });

        if (
          billing_addons[i].team_has_addon == 1 ||
          billing_addons[i].included_in_team_plan == 1
        ) {
          this.setState({ unlocked: true });
        }
      }
    }
  }

  componentDidMount() {
    this.setFeatureLock(this.props.billing_addons);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.billing_addons) {
      if (this.props.billing_addons != prevProps.billing_addons) {
        this.setFeatureLock(this.props.billing_addons);
      }
    }
  }

  triggerFeatureLock(value) {
    if (this.state.unlocked == false && this.state.addon != null) {
      if (this.props.user.team_owner == 1) {
        if (this.props.platform == "ios") {
          this.props.setModal({
            title: 'You need the "' + this.state.addon.title + '" product.',
            description:
              'You need the "' +
              this.state.addon.title +
              '" product to use this feature. Please go to DealMachine on the web to manage your account.',
            image: this.state.addon.image,
            submit: "Dismiss",
            onPress: () => {},
            cancel: "",
            onCancel: () => {}
          });
          this.props.toggleModal({ show: true, type: "normal" });
        } else {
          //check if user is not an old user to prevent randomness
          if (
            this.props.user?.user_version >= 2 &&
            this.props.user.user_mail_version >= 2
          ) {
            this.props.setModal({
              title: this.state.addon.marketing_title,
              description: this.state.addon.marketing_text,
              image: this.state.addon.image,
              submit: this.state.addon.button_text_yes,
              onPress: () => {
                this.props.updateBillingAddon({
                  token: this.props.token,
                  type: "purchase",
                  slug: this.state.addon.slug,
                  addon_id: this.state.addon.id
                });
              },
              cancel: this.state.addon.button_text_no,
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
          } else {
            this.props.setModal({
              title: "You're on an older DealMachine plan",
              description:
                "Please talk to Customer Support about updating your plan. Once you're on a newer plan you can purchase this product.",
              icon: "error",
              submit: "Dismiss",
              onPress: () => {},
              cancel: "",
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
          }
        }
      } else {
        this.props.setModal({
          title: "This feature is locked.",
          description:
            "Your team owner will need to purchase this feature to unlock it.",
          icon: "lock",
          submit: "Dismiss",
          onPress: () => {},
          cancel: "",
          onCancel: () => {}
        });
        this.props.toggleModal({ show: true, type: "normal" });
      }
    } else {
      this.props.onPress(value);
    }
  }

  render() {
    if (this.props.isToggleSwitch) {
      return (
        <ToggleSwitch
          value={this.props.toggle_value}
          onChange={value => {
            this.triggerFeatureLock(value);
          }}
          title={this.props.toggle_title}
          text={this.props.toggle_text}
        />
      );
    }

    if (this.props.isPrimaryButton) {
      return (
        <PrimaryButton
          style={this.props.style}
          onPress={() => this.triggerFeatureLock()}
        >
          {this.props.children}
        </PrimaryButton>
      );
    }

    return (
      <Button
        style={this.props.style}
        onPress={() => this.triggerFeatureLock()}
      >
        {this.props.children}
      </Button>
    );
  }
}

const mapStateToProps = ({ auth, native, billing }) => {
  const { token, user } = auth;
  const { platform } = native;
  const { billing_addons } = billing;

  return {
    token,
    user,
    platform,
    billing_addons
  };
};

export default connect(mapStateToProps, {
  setModal,
  toggleModal,
  updateBillingAddon
})(LockButton);
