import React, { Component } from "react";
import { Row, Button } from "app/NativeComponents/common";
import { store } from "app/store";
import Column from "./Column";

import { numberWithCommas } from "app/NativeActions";
import moment from "moment";
class MailSequence extends Component {
  constructor(props) {
    super(props);

    this.state = { hovering: false };
  }

  render() {
    const colors = store.getState().settings.colors;
    const { mail_sequence } = this.props;
    const { hovering } = this.state;
    return (
      <Button
        style={{
          backgroundColor: hovering ? colors.hover_color : null,
          paddingRight: 50
        }}
        onHover={() => {
          this.setState({ hovering: true });
        }}
        onHoverOut={() => {
          this.setState({ hovering: false });
        }}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "view_mail_sequence",
            overlay: true,
            data: {
              mail_sequence,
              onSuccess: ms => {
                this.props.editMailSequenceSuccess(ms);
              }
            }
          });
        }}
      >
        <Row>
          <Column
            token={this.props.token}
            title={mail_sequence.campaign_title}
            mail_sequence={mail_sequence}
            is_default_campaign={mail_sequence.is_default_campaign}
            main_column={true}
            hovering={this.state.hovering}
            pushSidePanel={this.props.pushSidePanel}
            newMailSequenceSuccess={this.props.newMailSequenceSuccess}
            editMailSequenceSuccess={this.props.editMailSequenceSuccess}
            removeFirstChoiceFromAll={this.props.removeFirstChoiceFromAll}
            removeMailSequenceSuccess={this.props.removeMailSequenceSuccess}
            updateMailerCampaign={this.props.updateMailerCampaign}
          />
          <Column
            title={mail_sequence?.number_of_steps}
            boxed={true}
            pushSidePanel={this.props.pushSidePanel}
          />

          <Column
            title={numberWithCommas(mail_sequence?.active_leads)}
            pushSidePanel={this.props.pushSidePanel}
            onPress={() => {
              this.props.pushSidePanel({
                slug: "view_leads",
                overlay: true,
                data: {
                  subtitle:
                    "Showing active leads using the " +
                    mail_sequence.campaign_title +
                    " mail sequence.",
                  filters: {
                    data: [
                      {
                        data: [
                          {
                            label: "Mail Status",
                            key: "campaign_status",
                            equal_type: "is_equal_to_any_of",
                            type: "object",
                            values: [
                              {
                                value: "Mail Sequence In Progress",
                                label: "Mail In Progress"
                              }
                            ]
                          },
                          {
                            label: "Assigned Mail Sequence",
                            key: "mail_sequence",
                            equal_type: "is_equal_to_any_of",
                            type: "object",
                            values: [
                              {
                                value: mail_sequence.id,
                                label: mail_sequence.campaign_title
                              }
                            ]
                          }
                        ],
                        andor_type: "and"
                      }
                    ],
                    andor_type: "or"
                  }
                }
              });
            }}
          />
          <Column
            title={numberWithCommas(mail_sequence?.paused_leads)}
            pushSidePanel={this.props.pushSidePanel}
            onPress={() => {
              this.props.pushSidePanel({
                slug: "view_leads",
                overlay: true,
                data: {
                  subtitle:
                    "Showing paused leads using the " +
                    mail_sequence.campaign_title +
                    " mail sequence.",
                  filters: {
                    data: [
                      {
                        data: [
                          {
                            label: "Mail Status",
                            key: "campaign_status",
                            equal_type: "is_equal_to_any_of",
                            type: "object",
                            values: [
                              {
                                value: "Mail Sequence Paused",
                                label: "Mail Paused"
                              }
                            ]
                          },
                          {
                            label: "Assigned Mail Sequence",
                            key: "mail_sequence",
                            equal_type: "is_equal_to_any_of",
                            type: "object",
                            values: [
                              {
                                value: mail_sequence.id,
                                label: mail_sequence.campaign_title
                              }
                            ]
                          }
                        ],
                        andor_type: "and"
                      }
                    ],
                    andor_type: "or"
                  }
                }
              });
            }}
          />
          <Column
            title={numberWithCommas(mail_sequence?.completed_leads)}
            pushSidePanel={this.props.pushSidePanel}
            onPress={() => {
              this.props.pushSidePanel({
                slug: "view_leads",
                overlay: true,
                data: {
                  subtitle:
                    "Showing completed leads using the " +
                    mail_sequence.campaign_title +
                    " mail sequence.",
                  filters: {
                    data: [
                      {
                        data: [
                          {
                            label: "Mail Status",
                            key: "campaign_status",
                            equal_type: "is_equal_to_any_of",
                            type: "object",
                            values: [
                              {
                                value: "Mail Sequence Ended",
                                label: "Mail Ended"
                              }
                            ]
                          },
                          {
                            label: "Assigned Mail Sequence",
                            key: "mail_sequence",
                            equal_type: "is_equal_to_any_of",
                            type: "object",
                            values: [
                              {
                                value: mail_sequence.id,
                                label: mail_sequence.campaign_title
                              }
                            ]
                          }
                        ],
                        andor_type: "and"
                      }
                    ],
                    andor_type: "or"
                  }
                }
              });
            }}
          />
          <Column
            title={moment(mail_sequence.date_created).format("MMM Do, YYYY")}
            pushSidePanel={this.props.pushSidePanel}
          />
        </Row>
      </Button>
    );
  }
}

export default MailSequence;
