import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ModalOverlay,
  Modal,
  Card,
  Form,
  CardBody,
  Title,
  Copy,
  PrimaryButton,
  ContentfulRichText,
  ExternalImage,
  Row
} from "app/NativeComponents/common";

import {
  toggleUpsellModal,
  setPricingPage,
  updateBillingAddon,
  loadOnboardingText,
  checkIfUserHasModule,
  getBillingAddonId,
  updatePlan,
  setFreeTrialInfo
} from "app/NativeActions";
import UpsellModalContent from "./UpsellModalContent";
class UpsellModal extends PureComponent {
  render() {
    const { toggle_upsell_modal } = this.props;
    if (this.props.toggle_upsell_modal) {
      return (
        <ModalOverlay
          isVisible={true}
          onPress={() => {
            this.props.toggleUpsellModal(false);
          }}
        >
          <Modal actionSheet={true}>
            <UpsellModalContent {...this.props} />
          </Modal>
        </ModalOverlay>
      );
    }
    return <Wrapper />;
  }
}

const mapStateToProps = ({ auth, native, settings, modal, billing, photo }) => {
  const { token, user } = auth;
  const { device, platform } = native;
  const { colors } = settings;
  const { plan_modules, billing_addons, plans_on_trial } = billing;
  const { toggle_upsell_modal, upsell_modal } = modal;
  return {
    token,
    user,
    device,
    platform,
    colors,
    toggle_upsell_modal,
    upsell_modal,
    plan_modules,
    billing_addons,
    plans_on_trial
  };
};

export default connect(mapStateToProps, {
  toggleUpsellModal,
  setPricingPage,
  updateBillingAddon,
  getBillingAddonId,
  updatePlan,
  setFreeTrialInfo
})(UpsellModal);
