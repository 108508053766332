import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Row,
  Bold
} from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel,
  GhostButton,
  InlineButton,
  BottomNavBar,
  SpinWrapper
} from "app/NativeComponents/snippets";
import OwnerUpdateDisplay from "app/DealMachineCore/reuseable/ManageDataUpdate/OwnerUpdateDisplay";
import {
  popSidePanel,
  pushSidePanel,
  getPropertyNew,
  updateLead,
  determineMainOwnerInfo
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";

class ViewLeadDataUpdates extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      panel_data,
      property: panel_data?.property || null,
      property_loading: false,
      loading: false,
      use_custom_loading: false
    };

    this.acceptChanges = this.acceptChanges.bind(this);
  }
  componentDidMount() {
    this.props.getPropertyNew({
      token: this.props.token,
      property_id: this.state.property?.property_id,
      onLoading: () => {
        this.setState({ property_loading: true });
      },
      onError: () => {
        this.setState({ property_loading: false });
      },
      onSuccess: results => {
        this.setState({
          property_loading: false,
          property:
            results.property?.property_id == this.state?.property?.property_id
              ? {
                  ...this.state.property,
                  ...results.property
                }
              : this.state.property
        });
      }
    });
  }
  componentWillUnmount() {}

  acceptChanges({ accept_new_owner = false }) {
    const { property } = this.state;
    this.props.updateLead({
      token: this.props.token,
      deal_ids: property?.deal?.id,
      type: "accept_data_changes",
      accept_new_owner: accept_new_owner ? 1 : 0,
      onLoading: () => {
        this.setState({
          loading: accept_new_owner ? true : false,
          use_custom_loading: !accept_new_owner ? true : false
        });
      },
      onError: () => {
        this.setState({
          loading: false,
          use_custom_loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }

        this.setState(
          {
            loading: false,
            use_custom_loading: false
          },
          () => {
            this.props.popSidePanel();
          }
        );
      }
    });
  }

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const { property } = this.state;
    const { colors } = this.props;

    let owner_name = "";
    const owner_info = determineMainOwnerInfo(property);
    owner_name = owner_info?.owner_name;

    return (
      <Container>
        <NewHeader
          title="View Updates"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <SelectItem
          style={{ marginTop: 0 }}
          subtextStyle={{ color: colors.orange_color }}
          description={property.property_address_full}
          select_type="none"
          label={"Viewing updates for:"}
          icon="keyboard-arrow-right"
          onPress={() => {
            this.props.pushSidePanel({
              slug: "property",
              id: property?.property_id,
              focus_side_panel:
                this.props.user?.user_settings?.property_expanded ===
                "not_expanded"
                  ? false
                  : true,
              overlay: true,
              data: {
                property
              }
            });
          }}
          renderLeft={() => {
            return (
              <Wrapper
                style={{
                  padding: 15,
                  paddingLeft: 25,
                  paddingRight: 0
                }}
              >
                <LeadMainPhoto
                  property={property}
                  height={parseInt(50)}
                  style={{
                    width: parseInt(50),
                    margin: 0
                  }}
                />
              </Wrapper>
            );
          }}
        >
          {owner_name}
        </SelectItem>
        <KeyboardView style={{ flex: 1 }}>
          {this.state.property_loading ? (
            <SpinWrapper text="Loading History" />
          ) : (
            <>
              <OwnerUpdateDisplay
                slug="owner"
                property={property}
                row={true}
                include_title="Owner:"
              />
              <OwnerUpdateDisplay
                slug="saleprice"
                property={property}
                row={true}
                include_title="Sale Price:"
                format={"money"}
              />
              <OwnerUpdateDisplay
                slug="saledate"
                property={property}
                row={true}
                include_title="Sale Date:"
                format={"date"}
              />
              <OwnerUpdateDisplay
                slug="calculated_total_value"
                property={property}
                row={true}
                include_title="Total Assessed Value:"
                format={"money"}
              />
              <OwnerUpdateDisplay
                slug="calculated_improvement_value"
                property={property}
                row={true}
                include_title="Improvement Value:"
                format={"money"}
              />
              <OwnerUpdateDisplay
                slug="calculated_land_value"
                property={property}
                row={true}
                include_title="Land Value:"
                format={"money"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_amount"
                property={property}
                row={true}
                include_title="Mortgage Amount:"
                format={"money"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_financing_type"
                property={property}
                row={true}
                include_title="Financing Type:"
                format={"text"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_loan_type"
                property={property}
                row={true}
                include_title="Loan Type:"
                format={"text"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_interest_rate"
                property={property}
                row={true}
                include_title="Interest Rate:"
                format={"interest_rate"}
              />
              <OwnerUpdateDisplay
                slug="lender_name"
                property={property}
                row={true}
                include_title="Lender Name:"
                format={"name"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_date"
                property={property}
                row={true}
                include_title="Mortgage Date:"
                format={"date"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_term"
                property={property}
                row={true}
                include_title="Mortgage Term:"
                format={"text"}
              />
              <OwnerUpdateDisplay
                slug="mortgage_due_date"
                property={property}
                row={true}
                include_title="Mortgage Due Date:"
                format={"date"}
              />
              <OwnerUpdateDisplay
                slug="second_mortgage_amount"
                property={property}
                row={true}
                include_title="2nd Mortgage Amount:"
                format={"money"}
              />
              <OwnerUpdateDisplay
                slug="second_mortgage_financing_type"
                property={property}
                row={true}
                include_title="2nd Financing Type:"
                format={"text"}
              />
              <OwnerUpdateDisplay
                slug="second_mortgage_loan_type"
                property={property}
                row={true}
                include_title="2nd Loan Type:"
                format={"text"}
              />
              <OwnerUpdateDisplay
                slug="second_mortgage_interest_rate"
                property={property}
                row={true}
                include_title="2nd Interest Rate:"
                format={"interest_rate"}
              />
              <OwnerUpdateDisplay
                slug="equity_percent"
                property={property}
                row={true}
                include_title="Equity Percent:"
                format={"percent"}
              />
              <OwnerUpdateDisplay
                slug="is_vacant_text"
                property={property}
                row={true}
                include_title="Is Vacant:"
                format={"text"}
              />
              <OwnerUpdateDisplay
                slug="preforclosure_status_text"
                property={property}
                row={true}
                include_title="Preforclosure Status:"
                format={"text"}
              />
            </>
          )}
        </KeyboardView>
        <BottomNavBar>
          {property?.deal?.custom_owner &&
          property?.deal?.lead_data_update_confirmation
            ?.owner_information_updated == 1 ? (
            <>
              <InlineButton
                primary={true}
                loading={this.state.loading}
                disabled={this.state.use_custom_loading}
                onPress={() => {
                  this.acceptChanges({ accept_new_owner: true });
                }}
                button_type={"full"}
                style={{
                  backgroundColor: colors.orange_color_muted
                }}
              >
                Use Updated Data
              </InlineButton>
              <InlineButton
                primary={false}
                loading={this.state.use_custom_loading}
                disabled={this.state.loading}
                onPress={() => {
                  this.acceptChanges({ accept_new_owner: false });
                }}
                button_type={"full"}
              >
                Use Custom Data
              </InlineButton>
            </>
          ) : (
            <InlineButton
              button_type={"full"}
              primary={true}
              loading={this.state.loading}
              style={{
                backgroundColor: colors.orange_color_muted
              }}
              onPress={() => {
                this.acceptChanges({ accept_new_owner: true });
              }}
            >
              Acknowledge Changes
            </InlineButton>
          )}
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;
  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  getPropertyNew,
  updateLead
})(ViewLeadDataUpdates);
