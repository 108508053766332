import React from "react";
import { store } from "app/store";

const PrimaryButton = ({
  children,
  formButton,
  onPress,
  disabled,
  style,
  id
}) => {
  const colors = store.getState().settings.colors;
  const dark_mode = store.getState().settings.dark_mode;

  if (formButton) {
    return (
      <button
        disabled={disabled}
        type={"submit"}
        className={"deal-primary-button"}
        style={{
          ...style,
          backgroundColor:
            dark_mode == "dark_mode"
              ? colors.active_color
              : colors.active_color,
          color: colors.white_text_color
        }}
      >
        {children}
      </button>
    );
  }
  return (
    <button
      disabled={disabled}
      className={"deal-primary-button"}
      style={{
        ...style,
        backgroundColor:
          dark_mode == "dark_mode" ? colors.active_color : colors.active_color,
        color: colors.white_text_color
      }}
      type={"button"}
      onClick={onPress}
      id={id}
    >
      {children}
    </button>
  );
};

export { PrimaryButton };
