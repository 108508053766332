import React, { Component } from "react";
import { connect } from "react-redux";

import {
  ModalOverlay,
  RightPanelModal,
  Wrapper,
  Input,
  KeyboardView,
  Spin,
  Row,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  SelectItem,
  GhostButton,
  InlineButton,
  BottomNavBar,
  ConfirmInlineButton,
  UpsellButton
} from "app/NativeComponents/snippets";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

import moment from "moment";

import Steps from "./Steps";
import SendEvents from "./SendEvents";
import SentMailers from "./SentMailers";
import {
  setRightPanel,
  setModal,
  toggleModal,
  updateLead,
  getLeadMailerCampaign,
  setStartMailerCampaignPage,
  switchMarketingTab,
  appRedirect,
  tabNavigation,
  pushSidePanel
} from "app/NativeActions";
import Cta from "./Cta";

class Mail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.getData(this.props.property?.deal?.id);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.property &&
      (this.props.property?.property_id !== prevProps?.property?.property_id ||
        this.props.property?.deal?.id !== prevProps?.property?.deal?.id ||
        this.props.property?.deal?.campaign_id !==
          prevProps?.property?.deal?.campaign_id ||
        this.props.property?.deal?.approved !==
          prevProps?.property?.deal?.approved ||
        this.props.property?.deal?.paused !==
          prevProps?.property?.deal?.paused ||
        this.props.property?.deal?.campaign_complete !==
          prevProps?.property?.deal?.campaign_complete ||
        this.props.property?.deal?.mail_template_id !==
          prevProps?.property?.deal?.mail_template_id ||
        this.props.property?.deal?.resend_freq !==
          prevProps?.property?.deal?.resend_freq ||
        this.props.property?.deal?.resend_limit !==
          prevProps?.property?.deal?.resend_limit ||
        JSON.stringify(this.props.property?.deal?.mailing_addresses) !==
          JSON.stringify(prevProps?.property?.deal?.mailing_addresses))
    ) {
      this.getData(this.props.property?.deal?.id);
    }
  }

  getData(lead_id) {
    if (!this.props.lead_mailer_campaign_loading) {
      this.props.getLeadMailerCampaign({
        token: this.props.token,
        lead_id
      });
    }
  }

  renderMailerDescription() {
    const {
      property: { deal }
    } = this.props;
    if (deal.resend_freq == 0) {
      return !deal?.campaign_id.includes("repeating")
        ? "Sending once this step."
        : "Sending once.";
    } else if (deal.resend_limit == 0) {
      return "Sending every " + deal.resend_freq + " days. Repeating forever.";
    } else if (deal.resend_limit == 1) {
      return !deal?.campaign_id.includes("repeating")
        ? "Sending once this step."
        : "Sending once.";
    } else if (deal.resend_limit > 0) {
      if (!deal?.campaign_id.includes("repeating")) {
        return (
          "Sending every " +
          deal.resend_freq +
          " days. Sending " +
          deal.resend_limit +
          " total mail pieces for this step."
        );
      } else {
        return (
          "Sending every " +
          deal.resend_freq +
          " days. Sending " +
          deal.resend_limit +
          " total mail pieces."
        );
      }
    }

    return "";
  }

  renderBody() {
    const {
      device,
      token,
      colors,
      isMobile,
      user,
      right_panel,
      lead_mailer_campaign_loading,
      lead_mailer_campaign,
      mail_loading,
      property
    } = this.props;

    const { deal } = property;

    const has_permission =
      user.team_clearance_level > 1 || user.can_approve_mail == 1;
    let mailing_addresses = [];
    if (deal?.send_to_owner_address == 1) {
      mailing_addresses.push({
        name: property.owner_name,
        address: property.owner_address,
        address2: property.owner_address2,
        city: property.owner_address_city,
        state: property.owner_address_state,
        zip: property.owner_address_zip
      });
    }
    for (let i = 0; i < deal.owner?.mailing_addresses?.length; i++) {
      if (deal.owner.mailing_addresses[i].send_to_address) {
        mailing_addresses.push(deal.owner.mailing_addresses[i]);
      }
    }

    if (
      property?.deal?.mailer?.bracket_variables &&
      mailing_addresses.length > 0
    ) {
      let i = 0;
      while (i <= property?.deal?.mailer?.bracket_variables?.length) {
        if (
          property?.deal?.mailer?.bracket_variables[i]?.slug == "{{owner_name}}"
        ) {
          property.deal.mailer.bracket_variables[i].value =
            mailing_addresses[0].name;
        }

        if (
          property?.deal?.mailer?.bracket_variables[i]?.slug ==
          "{{owner_address}}"
        ) {
          property.deal.mailer.bracket_variables[i].value =
            mailing_addresses[0].address;
        }
        if (
          property?.deal?.mailer?.bracket_variables[i]?.slug ==
          "{{owner_address2}}"
        ) {
          property.deal.mailer.bracket_variables[i].value =
            mailing_addresses[0].address2;
        }
        if (
          property?.deal?.mailer?.bracket_variables[i]?.slug ==
          "{{owner_address_city}}"
        ) {
          property.deal.mailer.bracket_variables[i].value =
            mailing_addresses[0].city;
        }
        if (
          property?.deal?.mailer?.bracket_variables[i]?.slug ==
          "{{owner_address_state}}"
        ) {
          property.deal.mailer.bracket_variables[i].value =
            mailing_addresses[0].state;
        }
        if (
          property?.deal?.mailer?.bracket_variables[i]?.slug ==
          "{{owner_address_zip}}"
        ) {
          property.deal.mailer.bracket_variables[i].value =
            mailing_addresses[0].zip;
        }
        i++;
      }
    }
    return (
      <>
        <>
          <SelectItem
            select_type="none"
            noPress={true}
            label="Selected Mail Sequence"
            renderRight={
              !property?.deal?.campaign_complete
                ? () => {
                    return (
                      <UpsellButton
                        meta_slug="mail"
                        disabled={!has_permission}
                        onPress={() => {
                          this.props.setStartMailerCampaignPage({
                            title: "Change Options",
                            subtitle: property.property_address,
                            lead_ids: property?.deal?.id,
                            number_of_leads: 1,
                            onSuccess: results => {
                              if (
                                this.props.propertyUpdated &&
                                results?.properties?.length == 1
                              ) {
                                this.props.propertyUpdated(
                                  results.properties[0]
                                );
                              }
                            }
                          });
                          this.props.pushSidePanel({
                            slug: "start_mail_campaign",
                            overlay: true
                          });
                        }}
                        renderLockedChildren={({ hovering }) => {
                          return (
                            <>
                              <InlineButton
                                noPress={true}
                                pressedIn={hovering}
                                icon_right="keyboard-arrow-right"
                              >
                                Change
                              </InlineButton>
                            </>
                          );
                        }}
                        renderChildren={({ hovering }) => {
                          return (
                            <>
                              <InlineButton
                                noPress={true}
                                pressedIn={hovering}
                                icon_right="keyboard-arrow-right"
                              >
                                Change
                              </InlineButton>
                            </>
                          );
                        }}
                      />
                    );
                  }
                : null
            }
          >
            {property?.deal?.campaign_title}
          </SelectItem>

          {property?.deal?.mailer ? (
            <>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <MailerFlipCard
                  mailer={property?.deal?.mailer}
                  scale={
                    property?.deal?.mailer?.template_type == "postcard6x11"
                      ? 0.3
                      : 0.35
                  }
                  back_scale={
                    property?.deal?.mailer?.template_type == "postcard6x11"
                      ? 0.3
                      : 0.35
                  }
                  onMailerPress={() => {
                    this.setState({
                      confirm_mail: false
                    });
                  }}
                />
              </Wrapper>
              <Wrapper style={{ padding: 25 }}>
                <Copy style={{ textAlign: "center" }}>
                  <Bold>{property?.deal?.mail_template_name}</Bold>
                </Copy>
                <Copy style={{ textAlign: "center", fontSize: 12 }}>
                  {this.renderMailerDescription()}
                </Copy>
              </Wrapper>
            </>
          ) : null}

          {!property?.deal?.approved &&
          !property?.deal?.paused &&
          !property?.deal?.campaign_complete ? (
            <UpsellButton
              primary={true}
              meta_slug="mail"
              disabled={!has_permission}
              onPress={() => {
                this.props.updateLead({
                  token: this.props.token,
                  deal_ids: property?.deal?.id,
                  campaign_id: property?.deal?.current_default_campaign_id,
                  type: "start_mailers",
                  onLoading: () => {
                    this.props.updateMainState({
                      mail_loading: true
                    });
                  },
                  onError: () => {
                    this.props.updateMainState({
                      mail_loading: false
                    });
                  },
                  onSuccess: results => {
                    if (
                      this.props.propertyUpdated &&
                      results?.properties?.length == 1
                    ) {
                      this.props.propertyUpdated(results.properties[0]);
                    }

                    this.props.updateMainState({
                      mail_loading: false
                    });
                  }
                });
              }}
              renderLockedChildren={({ hovering }) => {
                return (
                  <>
                    <GhostButton
                      primary={true}
                      loading={mail_loading}
                      noPress={true}
                      pressedIn={hovering}
                      button_type="full"
                      disabled={!has_permission}
                      style={{
                        marginLeft: 20,
                        marginRight: 5
                      }}
                    >
                      Start Mail
                    </GhostButton>
                  </>
                );
              }}
              renderChildren={({ hovering }) => {
                return (
                  <>
                    <GhostButton
                      primary={true}
                      loading={mail_loading}
                      noPress={true}
                      pressedIn={hovering}
                      button_type="full"
                      disabled={!has_permission}
                      style={{
                        marginLeft: 20,
                        marginRight: 5
                      }}
                    >
                      Start Mail
                    </GhostButton>
                    {lead_mailer_campaign &&
                    (property?.deal?.deal_status_type == "trash_status" ||
                      property?.deal?.deal_status_type == "inactive_status" ||
                      property?.deal?.deal_status_type ==
                        "marketing_complete_status" ||
                      property?.deal?.deal_status_type ==
                        "not_interested_status" ||
                      property?.deal?.deal_status_type ==
                        "unqualified_status" ||
                      property?.deal?.deal_status_type ==
                        "returned_to_sender_status") ? (
                      <SentMailers
                        user={user}
                        colors={colors}
                        lead_mailer_campaign={lead_mailer_campaign}
                      />
                    ) : null}
                  </>
                );
              }}
            />
          ) : null}
        </>
        {property?.deal?.approved ||
        property?.deal?.paused ||
        property?.deal?.campaign_complete ? (
          <>
            {lead_mailer_campaign_loading ? (
              <SpinWrapper text="Loading Mail" />
            ) : lead_mailer_campaign ? (
              <>
                <Cta
                  token={token}
                  user={user}
                  colors={colors}
                  lead_mailer_campaign={lead_mailer_campaign}
                  updateLead={this.props.updateLead}
                  setModal={this.props.setModal}
                  toggleModal={this.props.toggleModal}
                  getLeadMailerCampaign={this.props.getLeadMailerCampaign}
                  switchMarketingTab={this.props.switchMarketingTab}
                  appRedirect={this.props.appRedirect}
                  tabNavigation={this.props.tabNavigation}
                  pushSidePanel={this.props.pushSidePanel}
                  setStartMailerCampaignPage={
                    this.props.setStartMailerCampaignPage
                  }
                  propertyUpdated={this.props.propertyUpdated}
                />

                <Steps
                  colors={colors}
                  isMobile={isMobile}
                  lead_mailer_campaign={lead_mailer_campaign}
                  user={user}
                />

                {property?.deal?.campaign_id.includes("repeating") ? (
                  <>
                    <SentMailers
                      user={user}
                      colors={colors}
                      lead_mailer_campaign={lead_mailer_campaign}
                    />
                    {!property?.deal?.paused && !user.paused_sending ? (
                      <SendEvents
                        user={user}
                        colors={colors}
                        lead_mailer_campaign={lead_mailer_campaign}
                        mailing_addresses={mailing_addresses}
                      />
                    ) : null}
                  </>
                ) : null}

                {!property?.deal?.campaign_complete ? (
                  <BottomNavBar>
                    <ConfirmInlineButton
                      button_type="full"
                      confirm_text="Really End?"
                      loading_text="Ending"
                      loading={this.state.loading}
                      disabled={!has_permission}
                      onPress={() => {
                        this.props.updateLead({
                          token: this.props.token,
                          deal_ids: property.deal.id,
                          type: "complete_campaign",
                          onLoading: () => {
                            this.setState({
                              loading: true
                            });
                          },
                          onError: () => {
                            this.setState({
                              loading: false
                            });
                          },
                          onSuccess: results => {
                            this.setState({
                              loading: false
                            });
                            if (
                              this.props.propertyUpdated &&
                              results?.properties?.length == 1
                            ) {
                              this.props.propertyUpdated(results.properties[0]);
                            }
                          }
                        });
                      }}
                    >
                      {!property?.deal?.campaign_id.includes("repeating")
                        ? "End Mail Sequence"
                        : "End Mail"}
                    </ConfirmInlineButton>
                  </BottomNavBar>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
  render() {
    const { device, right_panel } = this.props;
    return this.renderBody();
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  mailer,
  modal,
  feature_toggle
}) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, platform, isMobile } = native;
  const { lead_mailer_campaign, lead_mailer_campaign_loading } = mailer;
  const { right_panel } = modal;

  return {
    token,
    user,
    colors,
    device,
    platform,
    isMobile,
    lead_mailer_campaign,
    lead_mailer_campaign_loading,
    right_panel
  };
};

export default connect(mapStateToProps, {
  setRightPanel,
  setModal,
  toggleModal,
  updateLead,
  getLeadMailerCampaign,
  setStartMailerCampaignPage,
  switchMarketingTab,
  appRedirect,
  tabNavigation,

  pushSidePanel
})(Mail);
