import React, { Component } from "react";

import TestimonialImage from "./TestimonialImage";
import TestimonialName from "./TestimonialName";
import TestimonialQuote from "./TestimonialQuote";

class Testimonial extends Component {
  render() {
    if (this.props.testimonial) {
      return (
        <div className="dm-website-testimonial">
          <TestimonialImage testimonial={this.props.testimonial} />
          <TestimonialName testimonial={this.props.testimonial} />
          <TestimonialQuote testimonial={this.props.testimonial} />
        </div>
      );
    }

    return <div />;
  }
}

export { Testimonial };
