import React from "react";

const Modal = ({ children, style }) => {
  return (
    <div
      style={style}
      onClick={e => {
        e.stopPropagation();
      }}
      className={"deal-modal animated fadeInUp"}
    >
      {children}
    </div>
  );
};

export { Modal };
