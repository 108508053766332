import React, { PureComponent } from "react";
import { Wrapper } from "app/NativeComponents/common";

import { store } from "app/store";

class SwipeView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getSlideFromSlug(slug) {
    const { slides } = this.props;

    for (let i = 0; i < slides.length; i++) {
      if (slides[i].slug == slug) {
        return i;
      }
    }

    return 0;
  }

  render() {
    const {
      style = {},
      slides = [],
      current_slug,
      current_slide = 0,
      onSlideUpdate = null,
      scrollEnabled = true
    } = this.props;

    if (slides.length > 0) {
      return (
        <>
          {slides.map((slide, i) => {
            if (slide.slug == current_slug) {
              return (
                <Wrapper style={style} key={"swipe_" + i}>
                  {slide.renderComponent ? slide.renderComponent() : null}
                </Wrapper>
              );
            }
            return null;
          })}
        </>
      );
    }
    return null;
  }
}

export { SwipeView };
