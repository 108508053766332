import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Card,
  CardBody,
  Title,
  Input,
  Icon,
  Spin,
  Button,
  Copy,
  Bold,
  KeyboardView
} from "app/NativeComponents/common";
import {
  Header,
  List,
  SearchBar,
  BottomNavBar,
  InlineButton,
  SpinWrapper,
  SelectItem,
  InlineTabs,
  UpsellButton
} from "app/NativeComponents/snippets";

import { popSidePanel } from "app/NativeActions";

import MailSequences from "./MailSequences";
import MailTemplates from "./MailTemplates";

class StartMailerCampaignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "mail_templates"
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  changeTab(tab) {
    this.setState({ tab });
  }

  render() {
    const {
      show_start_mailer_campaign_page,
      start_mailer_campaign_page,
      device,
      isMobile,
      colors,
      user
    } = this.props;
    const { tab } = this.state;
    return (
      <>
        <InlineTabs
          tab_type="underline"
          selected_tab={tab}
          tabs={[
            {
              onPress: () => {
                this.changeTab("mail_templates");
              },
              slug: "mail_templates",
              title: "Templates"
            },
            this.props.user?.user_version >= 6 &&
            !this.props.user?.has_mail_sequences
              ? {
                  onPress: () => {
                    this.changeTab("mail_sequences");
                  },
                  slug: "mail_sequences",
                  title: "Mail Sequences",
                  renderComponent: ({ key, tab, selected }) => {
                    return (
                      <UpsellButton
                        key={key}
                        slug="campaigns"
                        type="billing_addon"
                        contentful_slug="campaignsAddon"
                        meta_slug="advanced_mail_sequences"
                        onPress={tab.onPress}
                        renderLockedChildren={({ hovering }) => {
                          return (
                            <InlineButton
                              icon={"lock"}
                              selected={selected}
                              noPress={true}
                              pressedIn={selected || hovering}
                              disabled={tab?.disabled}
                              pressed_in_background_color={
                                selected ? "transparent" : colors.hover_color
                              }
                              tooltip={tab?.tooltip}
                              tooltipPlacement={tab?.tooltipPlacement}
                              style={
                                selected
                                  ? {
                                      borderBottomWidth: 2,
                                      borderBottomColor:
                                        colors.actionable_text_color,
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                                  : {
                                      borderBottomWidth: 2,
                                      borderBottomColor: "transparent",
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                              }
                            >
                              {tab?.title}
                            </InlineButton>
                          );
                        }}
                        renderChildren={({ hovering }) => {
                          return (
                            <InlineButton
                              icon={""}
                              selected={selected}
                              noPress={true}
                              pressedIn={selected || hovering}
                              disabled={tab?.disabled}
                              pressed_in_background_color={
                                selected ? "transparent" : colors.hover_color
                              }
                              tooltip={tab?.tooltip}
                              tooltipPlacement={tab?.tooltipPlacement}
                              style={
                                selected
                                  ? {
                                      borderBottomWidth: 2,
                                      borderBottomColor:
                                        colors.actionable_text_color,
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                                  : {
                                      borderBottomWidth: 2,
                                      borderBottomColor: "transparent",
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                              }
                            >
                              {tab?.title}
                            </InlineButton>
                          );
                        }}
                      />
                    );
                  }
                }
              : {
                  onPress: () => {
                    this.changeTab("mail_sequences");
                  },
                  slug: "mail_sequences",
                  title: "Mail Sequences"
                }
          ]}
        />
        {tab == "mail_templates" ? <MailTemplates {...this.props} /> : null}
        {tab == "mail_sequences" ? <MailSequences {...this.props} /> : null}
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { start_mailer_campaign_page, show_start_mailer_campaign_page } =
    mailer;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,

    start_mailer_campaign_page,
    show_start_mailer_campaign_page
  };
};

export default connect(mapStateToProps, {
  popSidePanel
})(StartMailerCampaignModal);
