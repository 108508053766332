import React, { PureComponent } from "react";
import { Wrapper, Row, Copy, Bold } from "app/NativeComponents/common";
import {
  PillButton,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";
import { numberWithCommas } from "app/NativeActions";
class ListBuilderCountLimits extends PureComponent {
  render() {
    const {
      filters,
      estimated_count,
      estimated_count_loading,

      colors,
      lists_info_banner,
      source_of_truth
    } = this.props;
    let new_list_count = 0;
    new_list_count =
      parseInt(
        source_of_truth?.current_limits?.number_of_leads_added_from_list_builder
          ?.amount
      ) + parseInt(estimated_count);

    let lead_count_text = "";
    if (
      (source_of_truth?.subscription?.metadata?.leads_added_from_list_builder ==
        0 ||
        !source_of_truth?.subscription?.metadata
          ?.leads_added_from_list_builder ||
        !source_of_truth?.subscription?.metadata?.list_builder) &&
      !this.props.demo
    ) {
      lead_count_text =
        "Add List Builder to your subscription to build this list of " +
        estimated_count +
        " leads.";
    } else if (
      !estimated_count_loading &&
      estimated_count > 0 &&
      new_list_count >
        parseInt(
          source_of_truth?.subscription?.metadata?.leads_added_from_list_builder
        ) &&
      source_of_truth?.subscription?.metadata
        ?.leads_added_from_list_buildermit != "unlimited" &&
      !this.props.demo
    ) {
      const difference =
        new_list_count -
        parseInt(
          source_of_truth?.subscription?.metadata?.leads_added_from_list_builder
        );
      lead_count_text =
        "Building this list of " +
        numberWithCommas(estimated_count) +
        " leads puts your account " +
        numberWithCommas(difference) +
        " over your " +
        numberWithCommas(
          parseInt(
            source_of_truth?.subscription?.metadata
              ?.leads_added_from_list_builder
          )
        ) +
        " lead limit ";
    }

    return (
      <Wrapper style={{}}>
        <Copy
          style={{
            fontSize: 12,
            textAlign: this.props.isMobile ? "center" : "left"
          }}
        >
          {lead_count_text}
        </Copy>
      </Wrapper>
    );

    return null;
  }
}

export default ListBuilderCountLimits;
