import React, { Component } from "react";
import { InputBox } from "app/NativeComponents/snippets";

class Inputs extends Component {
  constructor(props) {
    super(props);
    this._credit_amount_input = React.createRef();
  }

  render() {
    return (
      <InputBox
        input_ref={this._credit_amount_input}
        autoFocus={true}
        name="credit_amount"
        disabled={this.props.loading}
        returnKeyType="done"
        placeholder={"Dollar Amount"}
        onFocus={() => {}}
        onChange={value => {
          this.props.creditPurchaseChanged(value);
        }}
        blurOnSubmit={true}
        value={this.props.credit_amount}
        input_type="number"
        require_confirm={true}
        has_changed={true}
        submit_button_primary={true}
        submit_button_title={"Confirm Purchase"}
        onSubmit={this.props.purchaseCredits}
        loading={this.props.loading}
        style={{ borderRadius: 5, margin: 5 }}
      />
    );
  }
}

export default Inputs;
