import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper } from "app/NativeComponents/common";
import {
  AttentionBox,
  IconButton,
  List,
  SelectItem,
  SpinWrapper,
  InlineButton,
  UpsellButton,
} from "app/NativeComponents/snippets";

import {
  pushSidePanel,
  getMailers,
  determineDisplayProperty,
  formatAddress,
} from "app/NativeActions";
import moment from "moment";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

class SentMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_events: false,
      refreshing_events: false,
      events: [],
      begin: 0,
      limit: 25,
      can_load_more: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.start_date !== this.props.start_date ||
      prevProps.end_date !== this.props.end_date ||
      prevProps.is_selecting_range !== this.props.is_selecting_range ||
      prevProps.selected_date !== this.props.selected_date ||
      prevProps.range_selected_dates !== this.props.range_selected_dates ||
      JSON.stringify(prevProps.selected_templates_and_sequences) !==
        JSON.stringify(this.props.selected_templates_and_sequences)
    ) {
      this.getData("refresh");
    }
  }
  checkBallPointDeliveredDate = (mailer) => {
    const deliveryDateIsValid =
      mailer.processed_for_delivery_date &&
      moment(mailer.processed_for_delivery_date) >=
        moment(mailer.in_transit_date).add(15, "days");

    const shouldBeMarkedDelivered =
      moment() >= moment(mailer.in_transit_date).add(15, "days");

    const deliveryDate = shouldBeMarkedDelivered
      ? deliveryDateIsValid
        ? mailer.processed_for_delivery_date
        : moment(mailer.in_transit_date).add(15, "days")
      : null;

    return deliveryDate;
  };

  checkBallpointMailStatus = (mailer) => {
    const delivered = this.checkBallPointDeliveredDate(mailer);
    if (delivered) {
      return "Delivered";
    }
    if (
      mailer.in_transit_date &&
      moment() < moment(mailer.in_transit_date).add(5, "days")
    ) {
      return "Recieved by Provider";
    }
    if (
      mailer.in_transit_date &&
      moment() >= moment(mailer.in_transit_date).add(5, "days")
    ) {
      return "In Transit";
    }
    return "Sent to Provider";
  };

  getData(load_type = "load") {
    const {
      start_date,
      end_date,
      is_selecting_range,
      selected_date,
      range_selected_dates,
    } = this.props;
    const { begin } = this.state;
    let d1 = null;
    let d2 = null;
    if (is_selecting_range) {
      if (range_selected_dates.length > 1) {
        d1 = moment(range_selected_dates[0]).format("YYYY-MM-DD");
        d2 = moment(range_selected_dates[1]).format("YYYY-MM-DD");
      }
    } else if (selected_date) {
      d1 = moment(selected_date).format("YYYY-MM-DD");
      d2 = moment(selected_date).format("YYYY-MM-DD");
    } else {
      d1 = moment(start_date).format("YYYY-MM-DD");
      d2 = moment(end_date).format("YYYY-MM-DD");
    }

    let campaign_ids = [];
    let template_ids = [];

    for (
      let i = 0;
      i < this.props.selected_templates_and_sequences?.length;
      i++
    ) {
      if (this.props.selected_templates_and_sequences[i].type == "template") {
        template_ids.push(this.props.selected_templates_and_sequences[i].id);
      } else {
        campaign_ids.push(this.props.selected_templates_and_sequences[i].id);
      }
    }

    if (d1 && d2) {
      this.props.getMailers({
        token: this.props.token,
        min_date_mailed: d1,
        max_date_mailed: d2,
        mailer_campaign_id: null,
        begin: load_type == "refresh" ? 0 : begin,
        load_type,
        campaign_ids: campaign_ids.join(","),
        template_ids: template_ids.join(","),
        onLoading: () => {
          this.setState({
            can_load_more:
              load_type === "refresh" ? true : this.state.can_load_more,
            loading_events: load_type === "refresh" ? false : true,
            refreshing_events: load_type === "refresh" ? true : false,
            events: load_type === "refresh" ? [] : this.state.events,
          });
        },
        onError: () => {
          this.setState({ loading_events: false, refreshing_events: false });
        },
        onSuccess: (results) => {
          if (results?.mailers) {
            this.setState({
              loading_events: false,
              refreshing_events: false,
              events: [...this.state.events, ...results.mailers],
              can_load_more:
                results.mailers?.length < this.state.limit
                  ? false
                  : this.state.can_load_more,
              begin: begin + results.mailers?.length,
            });
          } else {
            this.setState({ loading_events: false });
          }
        },
      });
    }
  }

  render() {
    const { user, colors, isMobile } = this.props;
    const { events, loading_events, refreshing_events, can_load_more } =
      this.state;
    return (
      <>
        {(loading_events || refreshing_events) && events.length == 0 ? (
          <SpinWrapper text="Loading" />
        ) : events.length == 0 ? (
          <AttentionBox
            title={"No mail sent"}
            description={
              "There was no mail sent during the selected timeframe."
            }
          />
        ) : (
          <List
            rowNumber={1}
            items={events}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              const property_address = formatAddress({
                address: {
                  address: item.mailed_address,
                  address2: item.mailed_address_2,
                  address_city: item.mailed_address_city,
                  address_state: item.mailed_address_state,
                  address_zip: item.mailed_address_zip,
                },
              });

              let scale = 0.2;
              switch (item?.template_type) {
                case "postcard":
                default:
                  scale = 0.2;
                  break;
                case "postcard6x9":
                  scale = 0.15;
                  break;
                case "postcard6x11":
                  scale = 0.125;
                  break;
              }

              return (
                <SelectItem
                  select_type={"none"}
                  onPress={() => {}}
                  noPress={true}
                  description={property_address?.line2}
                  renderLeft={
                    !isMobile
                      ? () => {
                          return (
                            <Wrapper style={{ margin: 5, marginRight: 0 }}>
                              <MailerFlipCard
                                mailer={item}
                                scale={scale}
                                back_scale={scale}
                              />
                            </Wrapper>
                          );
                        }
                      : null
                  }
                  renderRight={() => {
                    return (
                      <Row style={{ margin: 5, marginLeft: 0 }}>
                        <UpsellButton
                          meta_slug="mail_tracking"
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "track_mail",
                              overlay: true,
                              data: { mailer: item },
                            });
                          }}
                          renderLockedChildren={({ hovering }) => {
                            return (
                              <InlineButton
                                icon="lock"
                                noPress={true}
                                pressedIn={hovering}
                                primary={true}
                              >
                                {"Track Mail"}
                              </InlineButton>
                            );
                          }}
                          renderChildren={({ hovering }) => {
                            const mailStatus = !!item?.returned_to_sender_date
                              ? "Returned To Sender"
                              : !!item?.processed_for_delivery_date
                              ? "Delivered"
                              : !!item?.re_routed_date
                              ? "Re-Routed"
                              : !!item?.in_local_area_date
                              ? "In Local Area"
                              : !!item?.in_transit_date
                              ? "In Transit"
                              : "Track Mail";
                            return (
                              <InlineButton
                                noPress={true}
                                pressedIn={hovering}
                                primary={true}
                                tooltip={"Track Mail"}
                                tooltipPlacement={"top"}
                                icon={"local-post-office"}
                              >
                                {item.mail_provider == "ballpoint"
                                  ? this.checkBallpointMailStatus(item)
                                  : mailStatus}
                              </InlineButton>
                            );
                          }}
                        />
                        {item?.property ? (
                          <InlineButton
                            icon_right={"keyboard-arrow-right"}
                            onPress={() => {
                              this.props.pushSidePanel({
                                slug: "property",
                                overlay: true,
                                focus_side_panel:
                                  this.props.user?.user_settings
                                    ?.property_expanded === "not_expanded"
                                    ? false
                                    : true,
                                id: item?.property?.property_id,
                                data: { property: item?.property },
                              });
                            }}
                          >
                            {this.props.isMobile ? "View" : "View Lead"}
                          </InlineButton>
                        ) : null}
                      </Row>
                    );
                  }}
                  label={
                    "Sent " + moment(item?.date_created).format("MMM Do, YYYY")
                  }
                >
                  {property_address?.line1}
                </SelectItem>
              );
            }}
            onRefresh={() => {
              this.getData("refresh");
            }}
            is_refreshing={refreshing_events}
            canLoadMore={can_load_more}
            isLoadingMore={loading_events}
            onLoadMore={() => {
              this.getData("load_more");
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile } = native;

  return {
    token,
    user,
    colors,
    isMobile,
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  getMailers,
})(SentMail);
