import React, { Component } from "react";

import { InlineTabs } from "app/NativeComponents/snippets";
class SideTabs extends Component {
  render() {
    const { side_tab, toggleSide, template_type } = this.props;
    return (
      <InlineTabs
        style={{ margin: 0 }}
        selected_tab={side_tab}
        tabs={[
          {
            onPress: () => {
              toggleSide("front");
            },
            title: template_type == "handwritten" ? "Letter" : "Front",
            slug: "front"
          },
          {
            onPress: () => {
              toggleSide("back");
            },
            title: template_type == "handwritten" ? "Envelope" : "Back",
            slug: "back"
          }
        ]}
      />
    );
  }
}

export default SideTabs;
