import React, { Component } from "react";
import { connect } from "react-redux";
import {
  formatUsPhone,
  popSidePanel,
  updateCallTrackingNumbers,
  showErrorMessage
} from "app/NativeActions";
import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  InputBox,
  NewHeader,
  GhostButton,
  AttentionBox,
  ConfirmLabel,
  BottomNavBar,
  ConfirmInlineButton,
  SelectItem
} from "app/NativeComponents/snippets";

class EditCallTrackingNumber extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      loading: false,
      deleting: false,
      default_tracking_number:
        panel_data?.call_tracking_number?.default_tracking_number,
      phone_label: panel_data?.call_tracking_number?.phone_label,
      call_tracking_number: panel_data?.call_tracking_number,
      call_tracking_number_id: panel_data?.call_tracking_number?.id,
      callback_type: panel_data?.call_tracking_number?.callback_type,
      callback_number: !!panel_data?.call_tracking_number?.callback_number
        ? formatUsPhone(panel_data?.call_tracking_number?.callback_number)
        : ""
    };

    this._phone_label_input = React.createRef();
    this._callback_number_input = React.createRef();

    this.editPhoneNumber = this.editPhoneNumber.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  checkIfNeedsToSave() {
    if (
      !!this.state.callback_number &&
      (this.state.callback_number !=
        formatUsPhone(this.state.call_tracking_number?.callback_number) ||
        this.state.default_tracking_number !==
          this.state.call_tracking_number?.default_tracking_number ||
        this.state.phone_label !== this.state.call_tracking_number?.phone_label)
    ) {
      return true;
    }

    return false;
  }

  editPhoneNumber() {
    if (!!this.state.callback_number) {
      this.props.updateCallTrackingNumbers({
        token: this.props.token,
        type: "edit_call_tracking_number",
        phone_label: this.state.phone_label,
        call_tracking_number_id: this.state.call_tracking_number_id,
        callback_number: this.state.callback_number,
        callback_type: this.state.callback_type,
        default_tracking_number: this.state.default_tracking_number,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: error => {
          this.setState({ loading: false });
          this.props.showErrorMessage(error, "Error");
        },
        onSuccess: results => {
          this.setState({ loading: false }, () => {
            const panel_data =
              this.props.device == "mobile"
                ? this.props?.route?.params?.panel_data
                : this.props.panel_data;
            if (panel_data?.handleEditExistingNumber) {
              panel_data.handleEditExistingNumber(
                results?.call_tracking_number
              );
            }

            this.props.popSidePanel();
          });
        }
      });
    }
  }

  render() {
    const { colors } = this.props;
    const { loading, deleting } = this.state;

    return (
      <Container>
        <NewHeader
          title={"Get Call Tracking Number"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              loading={this.state.loading}
              icon="settings"
              label={"Tracking Number Changed"}
              primary={true}
              confirmButton={"Confirm Changes"}
              onPress={this.editPhoneNumber}
            />
          ) : null}

          <InputBox
            input_ref={this._phone_label_input}
            name="phone_label"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="none"
            keyboardType="normal"
            disabled={loading}
            placeholder="Label"
            onChange={value => this.setState({ phone_label: value })}
            value={this.state.phone_label}
            type="text"
            onSubmitEditing={() => {
              if (this._callback_number_input?.current) {
                this._callback_number_input.current.focus();
              }
            }}
          />

          <InputBox
            input_ref={this._callback_number_input}
            name="callback_number"
            disabled={loading}
            returnKeyType="next"
            placeholder="Callback Number"
            onChange={value => this.setState({ callback_number: value })}
            onSubmitEditing={() => {}}
            blurOnSubmit={true}
            value={this.state.callback_number}
            type="text"
            mask_type={"cel-phone"}
            mask={"(999) 999-9999"}
          />

          {!this.state.call_tracking_number?.default_tracking_number ? (
            <>
              <SelectItem
                selected={this.state.default_tracking_number}
                onPress={() => {
                  this.setState({
                    default_tracking_number: !this.state.default_tracking_number
                  });
                }}
                select_type="toggle"
                description={
                  "Make this my default tracking number. This will be the number used for all new mail designs."
                }
              >
                Default Tracking Number
              </SelectItem>
            </>
          ) : null}
        </KeyboardView>
        {!this.state.call_tracking_number?.default_tracking_number ? (
          <BottomNavBar>
            <ConfirmInlineButton
              button_type="full"
              confirm_text="Really Delete?"
              confirm_icon="delete"
              loading_text="Deleting"
              loading={deleting}
              onPress={() => {
                this.props.updateCallTrackingNumbers({
                  token: this.props.token,
                  type: "remove_call_tracking_number",
                  call_tracking_number_id: this.state.call_tracking_number_id,
                  onLoading: () => {
                    this.setState({
                      deleting: true
                    });
                  },
                  onError: error => {
                    this.setState({
                      deleting: false
                    });
                    this.props.showErrorMessage(error, "Error");
                  },
                  onSuccess: results => {
                    this.setState({
                      deleting: false
                    });

                    const panel_data =
                      this.props.device == "mobile"
                        ? this.props?.route?.params?.panel_data
                        : this.props.panel_data;

                    if (panel_data?.handleRemoveExistingNumber) {
                      panel_data.handleRemoveExistingNumber(
                        this.state.call_tracking_number_id
                      );
                    }

                    this.props.popSidePanel();
                  }
                });
              }}
            >
              Delete Call Tracking Number
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;

  return {
    token,
    user,
    isMobile,
    colors,
    device
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateCallTrackingNumbers,
  showErrorMessage
})(EditCallTrackingNumber);
