import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  Header,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";

import { popSidePanel, pushSidePanel } from "app/NativeActions";

class EditProfile extends Component {
  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="Edit Profile"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <SmallLabel>General</SmallLabel>
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.pushSidePanel({
                slug: "edit_basic_profile_information",
                overlay: true
              });
            }}
          >
            Edit Basic Information
          </SelectItem>

          <SmallLabel>Security</SmallLabel>
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.pushSidePanel({
                slug: "edit_user_email_address",
                overlay: true
              });
            }}
          >
            Edit Email Address
          </SelectItem>
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.pushSidePanel({
                slug: "edit_user_phone_number",
                overlay: true
              });
            }}
          >
            Edit Phone Number
          </SelectItem>
          <SelectItem
            select_type="none"
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.pushSidePanel({
                slug: "edit_user_password",
                overlay: true
              });
            }}
          >
            Change Password
          </SelectItem>

          {this.props.platform === "ios" ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "delete_account",
                  overlay: true
                });
              }}
            >
              Delete Account
            </SelectItem>
          ) : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, platform } = native;
  const { colors } = settings;

  return { token, user, device, colors, platform };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel
})(EditProfile);
