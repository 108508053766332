import React, { useEffect, useRef, useState } from "react";
import { store } from "app/store";

import { Page } from "./Page";

const Container = ({
  id,
  children,
  style,
  fill,
  onResize,
  outsideStyle,
  title = null,
  description = null,
  page_slug = null,
  page_type = null
}) => {
  const colors = store.getState().settings.colors;

  const containerRef = useRef();
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (containerRef.current && onResize) {
      const width = containerRef.current.offsetWidth;
      const height = containerRef.current.offsetHeight;
      if (size.width !== width || size.height !== height) {
        setSize({ width: parseInt(width), height: parseInt(height) });
        onResize(width, height);
      }
    }
  }, [onResize, size.width, size.height]);

  if (!!title) {
    return (
      <Page
        metaTitle={!!title ? title + " - DealMachine for Real Estate" : null}
        metaDescription={!!description ? description : null}
        page_slug={page_slug}
        page_type={page_type}
      >
        <div
          style={{ ...outsideStyle }}
          className={
            fill ? "deal-container-fill-wrapper" : "deal-container-wrapper"
          }
        >
          <div
            ref={containerRef}
            id={id}
            style={{
              backgroundColor: colors.card_color,
              ...style
            }}
            className={fill ? "deal-container-fill" : "deal-container"}
          >
            {children}
          </div>
        </div>
      </Page>
    );
  } else {
    return (
      <div
        style={{ ...outsideStyle }}
        className={
          fill ? "deal-container-fill-wrapper" : "deal-container-wrapper"
        }
      >
        <div
          ref={containerRef}
          id={id}
          style={{
            backgroundColor: colors.card_color,
            ...style
          }}
          className={fill ? "deal-container-fill" : "deal-container"}
        >
          {children}
        </div>
      </div>
    );
  }
};

export { Container };
