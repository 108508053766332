export const TOGGLE_IMAGE_PICKER = "toggle_image_picker";
export const CANCEL_IMAGE_PICKER = "cancel_image_picker";
export const SELECT_PHOTO = "select_photo";

export const UPLOAD_PHOTO = "upload_photo";
export const UPLOAD_PHOTO_SUCCESS = "upload_photo_success";
export const UPLOAD_PHOTO_FAIL = "upload_photo_fail";

export const UPLOAD_PROGRESS = "upload_progress";
export const UPLOAD_ANIMATION = "upload_animation";
export const RESET_PHOTO = "reset_photo";
export const SELECT_EDIT_PHOTO = "select_edit_photo";
export const UPLOAD_EDIT_PHOTO = "upload_edit_photo";
export const UPLOAD_EDIT_PHOTO_SUCCESS = "upload_edit_photo_success";
export const UPLOAD_EDIT_PHOTO_FAIL = "upload_edit_photo_fail";
export const UPLOAD_EDIT_PROGRESS = "upload_edit_progress";
export const UPLOAD_EDIT_ANIMATION = "upload_edit_animation";

export const SELECT_PROFILE_PHOTO = "select_profile_photo";
export const UPLOAD_PROFILE_PHOTO = "upload_profile_photo";
export const UPLOAD_PROFILE_PHOTO_SUCCESS = "upload_profile_photo_success";
export const UPLOAD_PROFILE_PHOTO_FAIL = "upload_profile_photo_fail";
export const UPLOAD_PROFILE_PROGRESS = "upload_profile_progress";
export const UPLOAD_PROFILE_ANIMATION = "upload_profile_animation";

export const SELECT_SIGNATURE_PHOTO = "select_signature_photo";
export const UPLOAD_SIGNATURE_PHOTO = "upload_signature_photo";
export const UPLOAD_SIGNATURE_PHOTO_SUCCESS = "upload_signature_photo_success";
export const UPLOAD_SIGNATURE_PHOTO_FAIL = "upload_signature_photo_fail";
export const UPLOAD_SIGNATURE_PROGRESS = "upload_signature_progress";
export const UPLOAD_SIGNATURE_ANIMATION = "upload_signature_animation";

export const TOGGLE_CAMERA = "toggle_camera";
export const PHOTO_BUTTON_TOGGLE = "photo_button_toggle";

export const UPLOAD_LIST_SUCCESS = "upload_list_success";
export const UPLOAD_LIST_MAPPING_SUCCESS = "upload_list_mapping_success";
export const EDIT_BULK_UPLOAD_INFO = "edit_bulk_upload_info";

export const TOGGLE_BULK_UPLOAD_MODAL = "toggle_bulk_upload_modal";
