import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Copy, Row } from "app/NativeComponents/common";
import { IconButton } from "app/NativeComponents/snippets";
import ManageDataUpdate from "app/DealMachineCore/reuseable/ManageDataUpdate";
import { formatAddress, determineMainOwnerInfo } from "app/NativeActions";
class MainOwnerColumnItem extends PureComponent {
  renderOwnerAddressInnerContent() {
    const { property, active_property, colors, section, type } = this.props;
    const main_owner = determineMainOwnerInfo(property);

    switch (section.slug) {
      default:
        break;
      case "owner_address":
        const owner_address = formatAddress({
          address: {
            address: main_owner.owner_address,
            address2: main_owner.owner_address2,
            address_city: main_owner.owner_address_city,
            address_state: main_owner.owner_address_state,
            address_zip: main_owner.owner_address_zip
          }
        });

        return (
          <Wrapper>
            <Copy
              style={{
                whiteSpace: "nowrap"
              }}
            >
              {owner_address.line1}
            </Copy>
            <Copy style={{ fontSize: 10, whiteSpace: "nowrap" }}>
              {owner_address.line2}
            </Copy>
          </Wrapper>
        );

      case "owner_name":
        return (
          <Copy
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {this.props.getHighlightedText(main_owner.owner_name)}
          </Copy>
        );

      case "owner_firstname":
      case "owner_first_name":
        return (
          <Copy
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {this.props.getHighlightedText(main_owner.owner_firstname)}
          </Copy>
        );

      case "owner_lastname":
      case "owner_last_name":
        return (
          <Copy
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {this.props.getHighlightedText(main_owner.owner_lastname)}
          </Copy>
        );
      case "owner_status":
        return (
          <Copy style={{ whiteSpace: "nowrap" }}>
            {main_owner.owner_status_info
              ? main_owner.owner_status_info.text
              : "Owner Not Found"}
          </Copy>
        );
    }
  }
  render() {
    const { property, colors, section, row_height, type } = this.props;

    return (
      <Wrapper
        style={{
          padding: 10,
          height: row_height,
          position: "relative"
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignItems: "flex-start",
            justifyContent: "center"
          }}
        >
          <Row>
            <ManageDataUpdate
              property={property}
              slug={"owner"}
              icon_style={{ marginLeft: 0 }}
            />

            {this.renderOwnerAddressInnerContent()}
          </Row>
        </Wrapper>
      </Wrapper>
    );
  }
}

export default MainOwnerColumnItem;
