import React, { Component } from "react";

import { Row, Wrapper } from "app/NativeComponents/common";
import { ConfirmLabel } from "app/NativeComponents/snippets";

class SaveButton extends Component {
  render() {
    if (this.props.checkIfNeedsToSave()) {
      return (
        <ConfirmLabel
          style={{
            alignSelf: "stretch"
          }}
          icon="check"
          label={"Permissions Changed"}
          primary={true}
          confirmButton={"Save Permission"}
          loading={this.props.loading}
          onPress={() => this.props.savePermissions()}
        />
      );
    }

    return null;
  }
}

export default SaveButton;
