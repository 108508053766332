import React from "react";
import { store } from "app/store";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";

const ContentfulRichText = ({ style, richText }) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </a>
        );
      }
    }
  };
  let dark_mode =
    store.getState().settings.dark_mode == "dark_mode" ? true : false;
  return (
    <div
      className={dark_mode ? "deal-rich-text-dark-mode" : "deal-rich-text"}
      style={style}
    >
      {documentToReactComponents(richText, options)}
    </div>
  );
};

export { ContentfulRichText };
