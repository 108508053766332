import React, { Component } from "react";
import { connect } from "react-redux";

import { Copy, Row, Wrapper, Button } from "app/NativeComponents/common";
import {
  IconButton,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";

import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

import {
  getMailerStats,
  numberWithCommas,
  pushSidePanel
} from "app/NativeActions";
class Design extends Component {
  constructor(props) {
    super(props);

    this.state = { hovering: false };
  }

  render() {
    const { colors, isMobile } = this.props;

    const { mailer } = this.props;
    const { hovering } = this.state;
    let scale = 0.35;
    let back_scale = 0.35;

    switch (mailer?.template_type) {
      case "handwritten":
        scale = 0.35;
        back_scale = 0.35;

        break;
      case "postcard":
      default:
        scale = 0.35;
        back_scale = 0.35;
        break;
      case "postcard6x9":
        scale = 0.3;
        back_scale = 0.3;
        break;
      case "postcard6x11":
        scale = 0.25;
        back_scale = 0.25;
        break;
    }
    return (
      <Button
        style={{
          padding: 5,

          alignItems: "center",
          justifyContent: "center",

          backgroundColor: hovering
            ? colors?.orange_color_muted
            : colors.background_color,
          borderRadius: 5,
          margin: 5
        }}
        onHover={() => {
          this.setState({ hovering: true });
        }}
        onHoverOut={() => {
          this.setState({ hovering: false });
        }}
        onPress={() => {
          this.props.selectDesignTemplate(mailer);
        }}
      >
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: 200,
            width: isMobile ? "100%" : 300
          }}
        >
          <MailerFlipCard
            mailer={mailer}
            scale={scale}
            back_scale={back_scale}
            include_options={hovering ? true : false}
          />
        </Wrapper>
        <Row style={{ alignSelf: "stretch" }}>
          <Wrapper
            style={{
              padding: 10,
              flex: 1
            }}
          >
            <Copy
              style={{
                color: hovering
                  ? colors.actionable_text_color
                  : colors.text_color
              }}
            >
              {mailer?.title}
            </Copy>
            <Copy style={{ fontSize: 12, color: colors.light_text_color }}>
              {mailer?.industry_flag}
            </Copy>
          </Wrapper>

          {isMobile ? (
            <Wrapper style={{}}>
              <InlineButton
                onPress={() => {
                  this.props.selectDesignTemplate(mailer);
                }}
                primary={true}
                icon_right={"keyboard-arrow-right"}
              >
                Select Design
              </InlineButton>
            </Wrapper>
          ) : null}
        </Row>

        {hovering && !isMobile ? (
          <Wrapper
            style={{
              alignSelf: "stretch",
              height: 60,
              padding: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <GhostButton
              button_type={"full"}
              onPress={() => {
                this.props.selectDesignTemplate(mailer);
              }}
              primary={true}
            >
              Select Design
            </GhostButton>
          </Wrapper>
        ) : !isMobile ? (
          <Wrapper
            style={{
              alignSelf: "stretch",
              height: 60,
              padding: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          />
        ) : null}
      </Button>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { colors } = settings;
  return {
    token,
    user,
    colors
  };
};

export default connect(mapStateToProps, { getMailerStats, pushSidePanel })(
  Design
);
