import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon,
  Button,
  Gradient
} from "app/NativeComponents/common";
import { store } from "app/store";
import { numberWithCommas } from "app/NativeActions";

class TabButton extends Component {
  renderNotifications() {
    const { notification, notification_type } = this.props;

    const colors = store.getState().settings.colors;

    if (!!notification && notification != 0) {
      let gradient_color_1 = colors.gradient_color_1;
      let gradient_color_2 = colors.gradient_color_2;
      let text_color = colors.white_text_color;

      switch (notification_type) {
        case "error":
          gradient_color_1 = colors.error_color_muted;
          gradient_color_2 = colors.error_color_muted;
          text_color = colors.text_color;
          break;

        default:
          break;
      }
      return (
        <Gradient
          style={{
            marginLeft: 5,
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 10,
            paddingLeft: 10,
            borderRadius: 20
          }}
          color1={gradient_color_1}
          color2={gradient_color_2}
        >
          <Copy style={{ fontSize: 10, color: text_color }}>
            <Bold>
              {Number.isNaN(notification)
                ? notification
                : numberWithCommas(notification)}
            </Bold>
          </Copy>
        </Gradient>
      );
    }
  }

  render() {
    const {
      active,
      title,
      subtitle,
      mobile_title,
      icon,
      fa_icon,
      onPress = () => {}
    } = this.props;

    const colors = store.getState().settings.colors;
    const isMobile = store.getState().native.isMobile;

    return (
      <Button
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          height: active ? 41 : 40,
          alignItems: "center",
          justifyContent: "center",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: active
            ? colors.actionable_text_color
            : colors.border_color,
          borderRightWidth: active ? 1 : 1,
          borderLeftWidth: active ? 1 : 0,
          borderBottomWidth: active ? 1 : 0,
          borderBottomColor: active ? colors.card_color : "transparent",
          borderBottomStyle: "solid",
          backgroundColor: active ? colors.card_color : colors.background_color,
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          overflow: "hidden",
          marginBottom: active ? -1 : 0,

          opacity: 1
        }}
        onPress={onPress}
      >
        <Row>
          {!!icon || !!fa_icon ? (
            <Icon
              size={18}
              icon={icon}
              fa_icon={fa_icon}
              style={{ marginRight: 5 }}
            />
          ) : null}
          <Wrapper>
            <Copy
              style={{
                whiteSpace: "nowrap",
                //maxWidth: 150,
                overflow: "hidden"
              }}
            >
              <Bold>{isMobile && !!mobile_title ? mobile_title : title}</Bold>
            </Copy>
            {!!subtitle && !isMobile ? (
              <Copy
                style={{
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  //maxWidth: 150,
                  overflow: "hidden",
                  color: active
                    ? colors.actionable_text_color
                    : colors.text_color
                }}
              >
                {subtitle}
              </Copy>
            ) : null}
          </Wrapper>
          {this.renderNotifications()}
        </Row>
      </Button>
    );
  }
}

export default TabButton;
