import React, { Component } from "react";

const Markdown = require("react-markdown");

const FormDescription = ({ form }) => {
  if (form.description) {
    return (
      <div style={{ textAlign: "left" }}>
        <Markdown source={form.description} escapeHtml={false} />
      </div>
    );
  }

  return <div />;
};

export default FormDescription;
