import React, { Component } from "react";
import moment from "moment-timezone";

import { Container, KeyboardView, Row } from "app/NativeComponents/common";

import { openUrl, AppConfig } from "app/NativeActions";

import {
  NewHeader,
  AttentionBox,
  SpinWrapper,
  BottomNavBar,
  InlineButton,
  DateRangePicker
} from "app/NativeComponents/snippets";

import MarketingCreditList from "./MarketingCreditList";

class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCalendarOpen: false,
      start_date: null,
      end_date: null
    };

    this.handleBack = this.handleBack.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  toggleCalendar() {
    this.setState(prevState => {
      return { isCalendarOpen: !prevState.isCalendarOpen };
    });
  }

  exportMarketingCreditsString() {
    let export_string = "";
    if (this.props.start_date == null && this.props.end_date == null) {
      export_string =
        "v1/credits/?token=" +
        this.props.token +
        "&type=marketing_credits&export_type=csv";
    } else {
      export_string =
        "v1/credits/?token=" +
        this.props.token +
        "&type=marketing_credits&export_type=csv&start_date=" +
        moment(this.props.start_date).startOf("day").format() +
        "&end_date=" +
        moment(this.props.end_date).endOf("day").format();
    }
    return export_string;
  }

  render() {
    return (
      <Container>
        <NewHeader
          title={"Marketing Spend Report"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          <DateRangePicker
            {...this.props}
            disabledDays={{
              before: new Date(new Date().getFullYear(), 0, 1),
              after: new Date()
            }}
            isCalendarOpen={this.state.isCalendarOpen}
            toggleCalendar={this.toggleCalendar.bind(this)}
            selectDates={({ start_date, end_date }) => {
              this.props.getMarketingCredits({
                token: this.props.token,
                begin: 0,
                type: "marketing_credits",
                team: this.props.user.team_id,
                start_date: moment(start_date).startOf("day").format(),
                end_date: moment(end_date).endOf("day").format(),
                utc_offset: moment().utcOffset(),
                timezone: moment.tz.guess()
              });
            }}
          />

          {this.props.marketing_credits_refreshing ||
          (this.props.marketing_credits_loading &&
            this.props.marketing_credits.length === 0) ? (
            <SpinWrapper text="Loading Marketing Credit Purchases..." />
          ) : this.props.marketing_credits.length > 0 ? (
            <MarketingCreditList
              toggleCalendar={this.toggleCalendar.bind(this)}
              isCalendarOpen={this.state.isCalendarOpen}
              {...this.props}
            />
          ) : (
            <AttentionBox
              title="No Marketing Credit Purchases"
              description="It doesn't look like there are any marketing credit purchases to display based on your search parameters."
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            />
          )}
        </KeyboardView>
        {this.props.marketing_credits.length > 0 ? (
          <BottomNavBar>
            <Row style={{ justifyContent: "space-between" }}>
              <InlineButton
                primary={true}
                onPress={() => {
                  openUrl(
                    AppConfig().api_url.concat(
                      this.exportMarketingCreditsString()
                    )
                  );
                }}
              >
                Export Purchases
              </InlineButton>
              <InlineButton onPress={this.toggleCalendar}>
                Select Date Range
              </InlineButton>
            </Row>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

export default Body;
