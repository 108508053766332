import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Spin,
  Row,
  PieContainer
} from "app/NativeComponents/common";

import moment from "moment";
import { numberWithCommas } from "app/NativeActions";

class ActiveLeadSnapshot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.props.getDashboardSettings({
      token: this.props.token,
      type: "active_snapshot"
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active_snapshot != this.props.active_snapshot) {
      let pie_colors = [
        "#80ffff",
        "#52e2f9",
        "#29c7e0",
        "#00abc4",
        "#0090a8",
        "#00758d",
        "#005c73",
        "#00435a",
        "#002c42"
      ];

      let data = [];

      let total_leads = 0;
      if (this.props.active_snapshot && this.props.active_snapshot.length > 0) {
        for (let i = 0; i < this.props.active_snapshot.length; i++) {
          total_leads =
            total_leads + parseInt(this.props.active_snapshot[i].deal_count);
        }
      }

      for (let i = 0; i < this.props.active_snapshot.length; i++) {
        data.push({
          name: this.props.active_snapshot[i].title,
          value: parseInt(this.props.active_snapshot[i].deal_count),
          percentage:
            (parseInt(this.props.active_snapshot[i].deal_count) / total_leads) *
            100,

          color:
            pie_colors.length >= i - 1
              ? pie_colors[i]
              : this.props.colors.active_color
        });
      }
      this.setState({ data });
    }
  }

  renderLegend() {
    if (this.state.data && this.state.data.length > 0) {
      return (
        <Wrapper style={{ padding: 10 }}>
          {this.state.data.map((item, i) => {
            return (
              <Wrapper key={i}>
                <Row>
                  <Wrapper
                    style={{
                      width: 20,
                      height: 10,
                      backgroundColor: item.color,
                      margin: 5
                    }}
                  />
                  <Copy style={{ fontSize: 12 }}>
                    {numberWithCommas(item.value)} {item.name}
                  </Copy>
                </Row>
              </Wrapper>
            );
          })}
        </Wrapper>
      );
    }
  }

  renderActiveLeads() {
    let total_leads = 0;
    if (this.state.data && this.state.data.length > 0) {
      for (let i = 0; i < this.state.data.length; i++) {
        total_leads = total_leads + parseInt(this.state.data[i].value);
      }
    }
    return numberWithCommas(total_leads);
  }

  render() {
    const { dashboard_settings_loading } = this.props;

    if (dashboard_settings_loading) {
      return (
        <Wrapper>
          <Card style={{ flex: 1 }}>
            <CardBody>
              <Wrapper
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Row>
                  <Spin size={"small"} />
                  <Copy style={{ marginLeft: 10 }}>Loading...</Copy>
                </Row>
              </Wrapper>
            </CardBody>
          </Card>
        </Wrapper>
      );
    } else if (this.state.data.length > 0) {
      return (
        <Wrapper style={{ alignSelf: "stretch", flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <CardBody>
              <Wrapper
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Title style={{ textAlign: "center" }}>
                  I have {this.renderActiveLeads()} active leads
                </Title>
                <Wrapper
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: this.props.dashboardMobile
                      ? "column"
                      : "row",
                    paddingTop: 20
                  }}
                >
                  <PieContainer
                    width={150}
                    height={150}
                    data={this.state.data}
                    fillColor={this.props.colors.active_color}
                  />

                  {this.renderLegend()}
                </Wrapper>
              </Wrapper>
            </CardBody>
          </Card>
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

export default ActiveLeadSnapshot;
