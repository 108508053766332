import React, { Component } from "react";

import RegisterForm from "./RegisterForm";
import Hubspot from "./Hubspot";

class FormType extends Component {
  render() {
    if (this.props.form) {
      switch (this.props.form.formType) {
        case "RegisterForm":
        case "Register30DayForm":
        default:
          return <RegisterForm form={this.props.form} />;

        case "HubspotForm":
          return <Hubspot form={this.props.form} />;
      }
    }

    return <div />;
  }
}

export default FormType;
