import React, { PureComponent } from "react";
import { store } from "app/store";

import { Wrapper, Row, Bold, Copy } from "app/NativeComponents/common";
import HeaderButton from "./HeaderButton";

class SmallHeader extends PureComponent {
  render() {
    const colors = store.getState().settings.colors;
    const platform = store.getState().native.platform;
    const isIphoneX = store.getState().native.isIphoneX;

    const {
      style,
      backgroundColor = colors.card_color,
      title,
      subtitle,
      title_color = colors.text_color,

      leftButton = null,
      rightButton = null
    } = this.props;

    return (
      <Wrapper
        style={{
          height: 50,
          backgroundColor,
          ...style
        }}
      >
        <Row
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center"
          }}
        >
          {leftButton ? <HeaderButton buttonContents={leftButton} /> : null}
          <Wrapper
            style={{
              flex: 1,
              paddingRight: rightButton ? 0 : 15,
              paddingLeft: leftButton ? 0 : 15
            }}
          >
            <Copy style={{ color: title_color }}>
              <Bold>{title}</Bold>
            </Copy>
            {!!subtitle ? (
              <Copy style={{ color: title_color, fontSize: 12 }}>
                {subtitle}
              </Copy>
            ) : null}
          </Wrapper>

          {rightButton ? <HeaderButton buttonContents={rightButton} /> : null}
        </Row>
      </Wrapper>
    );
  }
}

export { SmallHeader };
