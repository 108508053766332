import React, { Component } from "react";
import { connect } from "react-redux";

import { KeyboardView, Container } from "app/NativeComponents/common";
import { NewHeader, SpinWrapper } from "app/NativeComponents/snippets";
import moment from "moment";

import CampaignSteps from "../CampaignSteps";
import {
  updateMailerCampaign,
  setRightPanel,
  popSidePanel,
  setMailerSelector,
  updateBillingAddon,
  setModal,
  toggleModal,
  pushSidePanel,
  getMailerTemplates
} from "app/NativeActions";

class NewMailSequence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_mailer_campaign: null,
      templates_loading: false,
      mailer_templates: []
    };

    this.editMailerCampaign = this.editMailerCampaign.bind(this);
    this.getRandomTemplate = this.getRandomTemplate.bind(this);
  }

  editMailerCampaign({ prop, value }) {
    const { new_mailer_campaign } = this.state;
    this.setState({
      new_mailer_campaign: {
        ...new_mailer_campaign,
        [prop]: value
      }
    });
  }

  getRandomTemplate() {
    let template = {};
    const { mailer_templates } = this.state;
    const { user } = this.props;
    let found_success = false;
    while (found_success === false) {
      template =
        mailer_templates[Math.floor(Math.random() * mailer_templates.length)];
      if (template?.id !== user?.default_template_id) {
        found_success = true;
      }
    }

    return template;
  }

  setNewMailerCampaign(mailer_campaign) {
    const { user } = this.props;
    if (mailer_campaign) {
      return {
        ...mailer_campaign,
        id: "new_mailer_campaign",
        campaign_title: mailer_campaign.campaign_title + " (Clone)",
        campaign_steps: mailer_campaign.campaign_steps.map((step, i) => {
          return {
            ...step,
            type: "new_step"
          };
        })
      };
    }

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    const template = this.getRandomTemplate();
    return {
      id: "new_mailer_campaign",
      campaign_title: "Mail Sequence #" + (panel_data?.mail_sequence_count + 1),
      repeat_mail_sequence: 1,
      campaign_steps: [
        {
          id: "first_step",
          type: "new_step",
          template_id: user?.default_template_id,
          template_title: user?.default_template_name,
          resend_freq: 21,
          resend_limit: 6,
          step_type: "direct_mail",
          step_order: 1,
          send_after: 0,
          date_created: moment().format("YYYY-MM-DD HH:mm:ss"),
          mailer: this.props.user?.team_default_template
        },
        {
          id: "second_step",
          type: "new_step",
          template_id: template?.id,
          template_title: template?.template_title,
          resend_freq: 21,
          resend_limit: 6,
          step_type: "direct_mail",
          step_order: 2,
          send_after: 21,
          date_created: moment().format("YYYY-MM-DD HH:mm:ss"),
          mailer: template
        }
      ]
    };
  }

  componentDidMount() {
    const { new_mailer_campaign_settings } = this.props;

    this.props.getMailerTemplates({
      token: this.props.token,
      load_all: true,
      onLoading: () => {
        this.setState({ templates_loading: true });
      },
      onError: () => {
        this.setState({ templates_loading: false });
      },
      onSuccess: results => {
        this.setState({
          templates_loading: false,
          mailer_templates: results.mailer_templates
        });

        const panel_data =
          this.props.device == "mobile"
            ? this.props?.route?.params?.panel_data
            : this.props.panel_data;

        this.setState({
          new_mailer_campaign: this.setNewMailerCampaign(
            panel_data?.mail_sequence
          )
        });
      }
    });
  }

  render() {
    const {
      colors,
      user,
      right_panel,
      new_mailer_campaign_settings,
      isMobile
    } = this.props;
    const { new_mailer_campaign, templates_loading } = this.state;

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    return (
      <Container>
        <NewHeader
          title={"New Mail Sequence"}
          leftButton={{
            onPress: () => {
              this.props.popSidePanel();
            },
            icon: isMobile ? "arrow-back" : "close"
          }}
        />
        {templates_loading ? (
          <SpinWrapper text={"Loading..."} />
        ) : this.state.new_mailer_campaign ? (
          <KeyboardView style={{ flex: 1 }}>
            <CampaignSteps
              token={this.props.token}
              user={user}
              colors={colors}
              new_mailer_campaign_settings={new_mailer_campaign_settings}
              mailer_campaign={this.state.new_mailer_campaign}
              mailer_templates={this.state.mailer_templates}
              editMailerCampaign={this.editMailerCampaign}
              new_mailer_campaign={true}
              updateMailerCampaign={this.props.updateMailerCampaign}
              source_of_truth={this.props.source_of_truth}
              getRandomTemplate={this.getRandomTemplate}
              popSidePanel={this.props.popSidePanel}
              onSuccess={panel_data?.onSuccess}
              editable={true}
            />
          </KeyboardView>
        ) : null}
      </Container>
    );
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, mailer, billing }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device, platform, isMobile } = native;
  const { active_mailer_campaign } = mailer;
  const { billing_addons, card_info, source_of_truth } = billing;
  return {
    token,
    user,
    colors,
    device,
    platform,
    isMobile,
    active_mailer_campaign,
    billing_addons,
    card_info,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateMailerCampaign,
  setRightPanel,
  setMailerSelector,
  updateBillingAddon,
  setModal,
  toggleModal,
  pushSidePanel,
  popSidePanel,
  getMailerTemplates
})(NewMailSequence);
