import React, { Component } from "react";

import {
  Card,
  Map,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";

class SearchAndTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 1
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);
    if (this.state.scene == 4) {
      this._interval = setTimeout(() => {
        this.setState({ scene: 1 });
        this.startScene();
      }, 3000);
    } else {
      this._interval = setTimeout(() => {
        this.setState({ scene: this.state.scene + 1 });
        this.startScene();
      }, 1000);
    }
  }

  renderTag(title) {
    return (
      <div className="animated zoomIn">
        <Card
          style={{
            marginRight: 0
          }}
        >
          <Row
            style={{
              padding: 10,
              paddingLeft: 15,
              paddingRight: 15
            }}
          >
            <Icon icon="check" style={{ marginRight: 5 }} />
            <Copy>{title}</Copy>
          </Row>
        </Card>
      </div>
    );
  }

  renderTags() {
    if (this.state.scene == 2) {
      return (
        <Row
          style={{
            flexWrap: "wrap"
          }}
        >
          {this.renderTag("Vacant")}
        </Row>
      );
    } else if (this.state.scene == 3) {
      return (
        <Row
          style={{
            flexWrap: "wrap"
          }}
        >
          {this.renderTag("Vacant")}
          {this.renderTag("Boarded Up Windows")}
        </Row>
      );
    } else if (this.state.scene == 4) {
      return (
        <Row
          style={{
            flexWrap: "wrap"
          }}
        >
          {this.renderTag("Vacant")}
          {this.renderTag("Boarded Up Windows")}
          {this.renderTag("Tall Grass")}
        </Row>
      );
    }
  }

  render() {
    return (
      <div
        style={{
          width: 320,
          height: 300,
          textAlign: "left"
        }}
      >
        <div className="animated fadeIn">
          <Card>
            <img
              style={{
                objectFit: "cover",
                height: 175,
                width: "100%"
              }}
              src="https://api.dealmachine.com/preview/templates/images/house-1.jpg"
              alt="Boarded Up House"
            />
          </Card>
          <div>{this.renderTags()}</div>
        </div>
      </div>
    );
  }
}

export default SearchAndTag;
