import React, { Component } from "react";
import {
  Wrapper,
  ProfilePic,
  Title,
  Copy,
  Bold,
  Row,
  Icon
} from "app/NativeComponents/common";
import {
  PillButton,
  SelectItem,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";

import { renderDate, renderTeamStatus } from "app/NativeActions";

class UserProfile extends Component {
  render() {
    if (this.props.active_team_member.member_type == "invite") {
      return (
        <SelectItem
          select_type="icon"
          select_icon="mail"
          description={
            "Invite sent on " +
            renderDate(this.props.active_team_member.date_created)
          }
        >
          {this.props.active_team_member.email}
        </SelectItem>
      );
    }

    if (this.props.active_team_member.member_type == "request") {
      return (
        <>
          <SelectItem
            select_type="profile_pic"
            user={this.props.active_team_member}
            description={
              "Requested to join your team on " +
              renderDate(this.props.active_team_member.date_created)
            }
          >
            {this.props.active_team_member.firstname &&
            this.props.active_team_member.lastname
              ? this.props.active_team_member.firstname +
                " " +
                this.props.active_team_member.lastname
              : this.props.active_team_member.email}
          </SelectItem>

          <GhostButton button_type="full" onPress={() => {}} primary={true}>
            Accept Request
          </GhostButton>
          <InlineButton
            style={{ margin: 0, marginTop: 10, marginLeft: 10 }}
            onPress={() => {}}
          >
            Deny Request
          </InlineButton>
        </>
      );
    }

    return (
      <>
        <SelectItem
          select_type="profile_pic"
          user={this.props.active_team_member}
          description={
            !!this.props.active_team_member.phone
              ? this.props.active_team_member.email +
                " | " +
                this.props.active_team_member.phone
              : this.props.active_team_member.email
          }
        >
          {this.props.active_team_member.firstname &&
          this.props.active_team_member.lastname
            ? this.props.active_team_member.firstname +
              " " +
              this.props.active_team_member.lastname
            : this.props.active_team_member.email}
        </SelectItem>
      </>
    );
  }
}

export default UserProfile;
