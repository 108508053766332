import React, { Component } from "react";
import { Wrapper, Copy, Row } from "app/NativeComponents/common";
import { InlineButton } from "app/NativeComponents/snippets";
import { store } from "app/store";
class InlineTabs extends Component {
  render() {
    const {
      tabs = [],
      selected_tab,
      tab_type = "normal",
      underline_color,
      text_color,
      selected_text_color,
      outer_style = {}
    } = this.props;
    const colors = store.getState().settings.colors;

    let selected_background_color = colors.active_color_muted;
    let s = {
      margin: 0
    };
    let selected_style = {
      margin: 0
    };
    let o_style = {
      margin: 5,
      borderRadius: 30,
      overflow: "hidden",
      ...outer_style
    };
    switch (tab_type) {
      default:
        break;

      case "underline":
        selected_background_color = "transparent";
        s = {
          ...s,
          borderBottomWidth: 2,
          borderBottomColor: "transparent",
          borderBottomStyle: "solid",
          borderRadius: 0
        };
        selected_style = {
          ...selected_style,
          borderBottomWidth: 2,
          borderBottomColor: underline_color
            ? underline_color
            : colors.actionable_text_color,
          borderBottomStyle: "solid",
          borderRadius: 0
        };
        o_style = {
          ...o_style,
          borderRadius: 0
        };
        break;
    }

    return (
      <Row style={outer_style}>
        {tabs.map((tab, i) => {
          if (tab) {
            const selected = tab?.slug == selected_tab;
            if (tab.renderComponent) {
              return tab.renderComponent({
                key: "tab_" + i,
                selected,
                tab
              });
            }

            return (
              <InlineButton
                key={"tab_" + i}
                icon={tab?.icon}
                primary={tab?.primary}
                selected={selected}
                pressedIn={selected}
                disabled={tab?.disabled}
                pressed_in_background_color={
                  selected ? selected_background_color : colors.hover_color
                }
                textColor={
                  selected && selected_text_color
                    ? selected_text_color
                    : text_color
                    ? text_color
                    : null
                }
                onPress={tab?.onPress ? tab.onPress : () => {}}
                tooltip={tab?.tooltip}
                tooltipPlacement={tab?.tooltipPlacement}
                style={selected ? selected_style : s}
                renderRight={
                  !!tab.notification
                    ? () => {
                        return (
                          <Wrapper
                            style={{
                              backgroundColor: colors.orange_color,
                              borderRadius: 30,
                              padding: 0,
                              paddingRight: 5,
                              paddingLeft: 5,
                              marginLeft: 5,
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <Copy
                              style={{
                                color: colors.white_text_color,
                                fontSize: 12
                              }}
                            >
                              {tab?.notification}
                            </Copy>
                          </Wrapper>
                        );
                      }
                    : null
                }
              >
                {tab?.title}
              </InlineButton>
            );
          }
          return null;
        })}
      </Row>
    );
  }
}

export { InlineTabs };
