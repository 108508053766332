import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Row, Wrapper } from "app/NativeComponents/common";
import {
  InlineButton,
  InlineTabs,
  UpsellButton,
  NewHeader,
  Tag,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import { popSidePanel } from "app/NativeActions";

import MailSequences from "./MailSequences";
import MailTemplates from "./MailTemplates";

class FilterMailCenter extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      selected_templates_and_sequences:
        panel_data?.selected_templates_and_sequences || [],
      tab: "mail_templates"
    };
    this.onSelect = this.onSelect.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }
  componentDidMount() {}
  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  changeTab(tab) {
    this.setState({ tab });
  }

  isSelected(item, item_type = "template") {
    const { selected_templates_and_sequences } = this.state;

    let in_selected_options = false;
    for (let i = 0; i < selected_templates_and_sequences.length; i++) {
      if (
        item.id == selected_templates_and_sequences[i].id &&
        selected_templates_and_sequences[i].type == item_type
      ) {
        in_selected_options = true;
      }
    }

    return in_selected_options;
  }

  onSelect(item, item_type = "template") {
    const { selected_templates_and_sequences } = this.state;

    if (this.isSelected(item, item_type)) {
      this.setState({
        selected_templates_and_sequences:
          selected_templates_and_sequences.filter(
            ({ id, type }) => id !== item.id || type !== item_type
          )
      });
    } else {
      this.setState({
        selected_templates_and_sequences: [
          ...selected_templates_and_sequences,
          {
            id: item.id,
            type: item_type,
            title:
              item_type == "template"
                ? item?.template_title
                : item?.campaign_title
          }
        ]
      });
    }
  }
  onConfirm() {
    const { selected_templates_and_sequences } = this.state;

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data?.onConfirm) {
      panel_data.onConfirm(selected_templates_and_sequences);
    }
  }

  render() {
    const {
      show_start_mailer_campaign_page,
      start_mailer_campaign_page,
      device,
      isMobile,
      colors,
      user
    } = this.props;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    const { tab, selected_templates_and_sequences } = this.state;
    return (
      <Container>
        <NewHeader
          title={"Filter Mail Center"}
          subtitle={
            "Filter your mail center to only show selected designs & sequences"
          }
          leftButton={{
            onPress: () => {
              this.props.popSidePanel();
            },
            icon: isMobile ? "arrow-back" : "close"
          }}
        />
        {JSON.stringify(selected_templates_and_sequences) !==
        JSON.stringify(panel_data?.selected_templates_and_sequences) ? (
          <ConfirmLabel
            style={{
              alignSelf: "stretch"
            }}
            primary={true}
            icon="tune"
            label={"Filters changed"}
            confirmButton={"Apply"}
            onPress={() => {
              this.onConfirm();
              this.props.popSidePanel();
            }}
          />
        ) : null}
        {selected_templates_and_sequences.length > 0 ? (
          <>
            <Wrapper
              style={{
                alignSelf: "stretch",
                padding: 10,
                paddingBottom: 0
              }}
            >
              <Row
                style={{
                  flexWrap: "wrap",
                  justifyContent: "flex-start"
                }}
              >
                {selected_templates_and_sequences.map((option, i) => {
                  return (
                    <Tag
                      key={"selected_option_" + i}
                      onPress={() => {
                        this.onSelect(option, option.type);
                      }}
                      icon={"close"}
                      selected={true}
                    >
                      {option.title}
                    </Tag>
                  );
                })}
              </Row>
            </Wrapper>
          </>
        ) : null}

        <InlineTabs
          tab_type="underline"
          selected_tab={tab}
          tabs={[
            {
              onPress: () => {
                this.changeTab("mail_templates");
              },
              slug: "mail_templates",
              title: "Designs"
            },
            this.props.user?.user_version >= 6 &&
            !this.props.user?.has_mail_sequences
              ? {
                  onPress: () => {
                    this.changeTab("mail_sequences");
                  },
                  slug: "mail_sequences",
                  title: "Mail Sequences",
                  renderComponent: ({ key, tab, selected }) => {
                    return (
                      <UpsellButton
                        key={key}
                        slug="campaigns"
                        type="billing_addon"
                        contentful_slug="campaignsAddon"
                        meta_slug="advanced_mail_sequences"
                        onPress={tab.onPress}
                        renderLockedChildren={({ hovering }) => {
                          return (
                            <InlineButton
                              icon={"lock"}
                              selected={selected}
                              noPress={true}
                              pressedIn={selected || hovering}
                              disabled={tab?.disabled}
                              pressed_in_background_color={
                                selected ? "transparent" : colors.hover_color
                              }
                              tooltip={tab?.tooltip}
                              tooltipPlacement={tab?.tooltipPlacement}
                              style={
                                selected
                                  ? {
                                      borderBottomWidth: 2,
                                      borderBottomColor:
                                        colors.actionable_text_color,
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                                  : {
                                      borderBottomWidth: 2,
                                      borderBottomColor: "transparent",
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                              }
                            >
                              {tab?.title}
                            </InlineButton>
                          );
                        }}
                        renderChildren={({ hovering }) => {
                          return (
                            <InlineButton
                              icon={""}
                              selected={selected}
                              noPress={true}
                              pressedIn={selected || hovering}
                              disabled={tab?.disabled}
                              pressed_in_background_color={
                                selected ? "transparent" : colors.hover_color
                              }
                              tooltip={tab?.tooltip}
                              tooltipPlacement={tab?.tooltipPlacement}
                              style={
                                selected
                                  ? {
                                      borderBottomWidth: 2,
                                      borderBottomColor:
                                        colors.actionable_text_color,
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                                  : {
                                      borderBottomWidth: 2,
                                      borderBottomColor: "transparent",
                                      borderBottomStyle: "solid",
                                      borderRadius: 0,
                                      margin: 0
                                    }
                              }
                            >
                              {tab?.title}
                            </InlineButton>
                          );
                        }}
                      />
                    );
                  }
                }
              : {
                  onPress: () => {
                    this.changeTab("mail_sequences");
                  },
                  slug: "mail_sequences",
                  title: "Mail Sequences"
                }
          ]}
        />
        {tab == "mail_templates" ? (
          <MailTemplates
            {...this.props}
            selected_templates_and_sequences={
              this.state.selected_templates_and_sequences
            }
            isSelected={this.isSelected}
            onSelect={this.onSelect}
          />
        ) : null}
        {tab == "mail_sequences" ? (
          <MailSequences
            {...this.props}
            selected_templates_and_sequences={
              this.state.selected_templates_and_sequences
            }
            isSelected={this.isSelected}
            onSelect={this.onSelect}
          />
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { start_mailer_campaign_page, show_start_mailer_campaign_page } =
    mailer;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,

    start_mailer_campaign_page,
    show_start_mailer_campaign_page
  };
};

export default connect(mapStateToProps, {
  popSidePanel
})(FilterMailCenter);
