import React, { Component } from "react";
import { connect } from "react-redux";

import { Page, Copy, Row, Bold, Wrapper } from "app/NativeComponents/common";
import { Header1, Header3 } from "app/NativeComponents/common";
import { InlineButton, OnboardingWrapper } from "app/NativeComponents/snippets";
import Addon from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/Addon";
import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  manageSubscription
} from "app/NativeActions";
class UpsellOnboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan_interval: props?.source_of_truth?.subscription?.interval
        ? props?.source_of_truth?.subscription?.interval
        : "month",
      contentful_content: null,
      contentful_loading: true
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/upgrade-your-trial");
    }

    this.loadData("upgrade_trial");
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, desktopMobile, device } = this.props;

    return (
      <Page
        metaTitle={contentful_content?.title + " - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          {this.state.contentful_content ? (
            <>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  <Bold>{contentful_content?.title}</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  {contentful_content?.subtitle}
                </Header3>
              </Wrapper>

              <Row
                style={
                  !desktopMobile
                    ? {
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap"
                      }
                    : {
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }
                }
              >
                {this.props.desktopMobile ? (
                  <>
                    {contentful_content?.contentItems &&
                      contentful_content?.contentItems.map((item, i) => {
                        const plan = item?.fields;
                        if (plan?.featured) {
                          return (
                            <Addon
                              key={"plan_" + i}
                              colors={colors}
                              desktopMobile={desktopMobile}
                              device={device}
                              marketing_slug={plan?.marketing_event_slug}
                              token={this.props.token}
                              title={plan?.title}
                              stripe_price_id_month={plan?.stripePriceIdMonthly}
                              stripe_price_id_year={plan?.stripePriceIdYearly}
                              featured={plan?.featured}
                              price={plan?.monthPrice}
                              year_price={plan?.yearPrice}
                              plan_interval={this.state.plan_interval}
                              buttonText={plan?.buttonText}
                              includes_items={
                                plan?.includesItems ? plan.includesItems : null
                              }
                              description={plan?.description}
                              subtext={plan?.subtext}
                              manageSubscription={this.props.manageSubscription}
                              onSuccess={() => {
                                this.props.updateUser({
                                  token: this.props.token,
                                  type: "onboarding_stepthrough_step",
                                  payload: {
                                    onboarding_stepthrough_current_step:
                                      "final_step_stage_one"
                                  },
                                  onLoading: () => {},
                                  onError: () => {
                                    //show some kind of error here
                                  },
                                  onSuccess: () => {},
                                  no_loading: true
                                });
                              }}
                            />
                          );
                        }
                        return null;
                      })}
                  </>
                ) : null}
                {contentful_content?.contentItems &&
                  contentful_content?.contentItems.map((item, i) => {
                    const plan = item?.fields;
                    if (!plan?.featured || !this.props.desktopMobile) {
                      return (
                        <Addon
                          key={"plan_" + i}
                          colors={colors}
                          desktopMobile={desktopMobile}
                          device={device}
                          marketing_slug={plan?.marketing_event_slug}
                          token={this.props.token}
                          title={plan?.title}
                          stripe_price_id_month={plan?.stripePriceIdMonthly}
                          stripe_price_id_year={plan?.stripePriceIdYearly}
                          featured={plan?.featured}
                          price={plan?.monthPrice}
                          year_price={plan?.yearPrice}
                          plan_interval={this.state.plan_interval}
                          buttonText={plan?.buttonText}
                          includes_items={
                            plan?.includesItems ? plan.includesItems : null
                          }
                          description={plan?.description}
                          subtext={plan?.subtext}
                          manageSubscription={this.props.manageSubscription}
                          onSuccess={() => {
                            this.props.updateUser({
                              token: this.props.token,
                              type: "onboarding_stepthrough_step",
                              payload: {
                                onboarding_stepthrough_current_step:
                                  "final_step_stage_one"
                              },
                              onLoading: () => {},
                              onError: () => {
                                //show some kind of error here
                              },
                              onSuccess: () => {},
                              no_loading: true
                            });
                          }}
                        />
                      );
                    }
                    return null;
                  })}
              </Row>
              {!!contentful_content?.description ? (
                <Wrapper
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 25
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    {contentful_content?.description}
                  </Copy>
                </Wrapper>
              ) : null}
              <Wrapper
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 25
                }}
              >
                <InlineButton
                  loading={this.state.skip_loading}
                  onPress={() => {
                    this.props.updateUser({
                      token: this.props.token,
                      type: "onboarding_stepthrough_step",
                      payload: {
                        onboarding_stepthrough_current_step:
                          "final_step_stage_one"
                      },
                      onLoading: () => {
                        this.setState({
                          skip_loading: true
                        });
                      },
                      onError: () => {
                        this.setState({
                          skip_loading: false
                        });
                      },
                      onSuccess: () => {},
                      no_loading: true
                    });
                  }}
                  style={{ textAlign: "center" }}
                >
                  {!!contentful_content?.cancelButtonText
                    ? contentful_content?.cancelButtonText
                    : "Skip for now."}
                </InlineButton>
              </Wrapper>
            </>
          ) : null}
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, marketing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { desktopMobile, device, initialRedirect } = native;
  const { visitor, signup_funnel } = marketing;

  return {
    token,
    user,
    init,
    loading,
    colors,
    desktopMobile,
    device,
    initialRedirect,
    source_of_truth,
    visitor,
    signup_funnel
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  manageSubscription
})(UpsellOnboarding);
