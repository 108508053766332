import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Button,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";

class ZoomButtons extends Component {
  render() {
    const { colors, onZoom, scale, isMobile } = this.props;
    //if (!isMobile) {
    return (
      <Wrapper>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            marginLeft: 5,

            overflow: "hidden",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: colors.border_color
          }}
        >
          <Button
            tooltip={"Zoom In"}
            tooltipPlacement={"top"}
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              borderRightWidth: 1,
              borderRightStyle: "solid",
              borderRightColor: colors.border_color,
              backgroundColor:
                scale < 1 ? colors.card_color : colors.background_color
            }}
            onPress={() => {
              if (scale < 1) {
                onZoom(scale + 0.1);
              }
            }}
          >
            <Icon icon="add" size={22} />
          </Button>
          <Button
            tooltip={"Zoom Out"}
            tooltipPlacement={"top"}
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              borderRightWidth: 1,
              borderRightStyle: "solid",
              borderRightColor: colors.border_color,
              backgroundColor:
                scale > 0.5 ? colors.card_color : colors.background_color
            }}
            onPress={() => {
              if (scale > 0.5) {
                onZoom(scale - 0.1);
              }
            }}
          >
            <Icon icon="remove" size={22} />
          </Button>

          <Button
            tooltip={"Actual Size"}
            tooltipPlacement={"top"}
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
              backgroundColor:
                scale != 1 ? colors.card_color : colors.background_color
            }}
            onPress={() => {
              onZoom(1);
            }}
          >
            <Icon icon="aspect-ratio" size={22} />
          </Button>
        </Row>
      </Wrapper>
    );
    //}
    return null;
  }
}

export default ZoomButtons;
