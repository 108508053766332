import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView, Wrapper } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  SelectBox,
  GhostButton,
  AttentionBox,
  SmallLabel,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  updateUser,
  popSidePanel,
  dismissMobileKeyboard
} from "app/NativeActions";

class SignatureOnboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      loading: false,
      state_focus: false
    };

    this.saveUserInfo = this.saveUserInfo.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
    this.updateUserFieldChange = this.updateUserFieldChange.bind(this);

    this._firstname_input = React.createRef();
    this._lastname_input = React.createRef();
    this._phone_input = React.createRef();

    this._address = React.createRef();
    this._address2 = React.createRef();
    this._city = React.createRef();
    this._state = React.createRef();
    this._zip = React.createRef();
  }

  checkIfNeedsToSave() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    if (!panel_data.onboarding) {
      if (
        (!!this.state.user.address &&
          this.state.user.address != this.props.user?.address) ||
        this.state.user.address2 != this.props.user?.address2 ||
        (!!this.state.user.phone &&
          this.state.user.phone != this.props.user?.phone) ||
        (!!this.state.user.address_city &&
          this.state.user.address_city != this.props.user?.address_city) ||
        (!!this.state.user.address_state &&
          this.state.user.address_state != this.props.user?.address_state) ||
        (!!this.state.user.address_zip &&
          this.state.user.address_zip != this.props.user?.address_zip) ||
        (!!this.state.user.firstname &&
          this.state.user.firstname != this.props.user?.firstname) ||
        (!!this.state.user.lastname &&
          this.state.user.lastname != this.props.user?.lastname)
      ) {
        return true;
      }
    } else {
      if (
        !!this.state.user.address &&
        !!this.state.user.phone &&
        !!this.state.user.address_city &&
        !!this.state.user.address_state &&
        !!this.state.user.address_zip &&
        !!this.state.user.firstname &&
        !!this.state.user.lastname
      ) {
        return true;
      }
    }

    return false;
  }

  updateUserFieldChange({ prop, value }) {
    this.setState({
      user: {
        ...this.state.user,
        [prop]: value
      }
    });
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  saveUserInfo() {
    /*mobile*/

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    dismissMobileKeyboard();

    const {
      firstname,
      lastname,
      phone,
      address,
      address2,
      address_city,
      address_state,
      address_zip
    } = this.state.user;

    this.props.updateUser({
      token: this.props.token,
      type: "update_user_information_and_signature",
      payload: {
        firstname,
        lastname,
        phone,
        check_phone: 1,
        address: {
          address,
          address2,
          city: address_city,
          state: address_state,
          zip: address_zip
        }
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        if (panel_data.onSuccess) {
          panel_data.onSuccess(results);
        } else {
          this.handleBack();
        }
      }
    });
  }

  render() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    let state_data = [];
    for (let i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    return (
      <Container>
        <NewHeader
          title={
            panel_data?.onboarding
              ? "Enter Contact Info"
              : "Edit Contact Information"
          }
          leftButton={{
            icon: this.props.device == "desktop" ? "close" : "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {!!panel_data?.description ? (
            <AttentionBox description={panel_data?.description} />
          ) : null}

          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="check"
              label={
                panel_data?.onboarding
                  ? "Contact Info Complete"
                  : "Contact Information Changed"
              }
              confirmButton={
                panel_data?.onboarding ? "Save Info" : "Confirm Changes"
              }
              loading={this.state.loading}
              primary={true}
              disabled={false}
              onPress={() => this.saveUserInfo()}
            />
          ) : null}

          <SmallLabel>Contact Name: </SmallLabel>
          <InputBox
            input_ref={this._firstname_input}
            name="firstname"
            autoCapitalize="words"
            disabled={this.state.loading}
            returnKeyType="next"
            placeholder="First Name"
            onChange={value =>
              this.updateUserFieldChange({ prop: "firstname", value })
            }
            onSubmitEditing={() => {
              this._lastname_input.current.focus();
            }}
            blurOnSubmit={false}
            value={this.state.user.firstname}
            type="text"
            autoComplete={"name-given"}
            textContentType={"givenName"}
          />
          <InputBox
            input_ref={this._lastname_input}
            name="lastname"
            autoCapitalize="words"
            disabled={this.state.loading}
            returnKeyType="next"
            placeholder="Last Name"
            onChange={value =>
              this.updateUserFieldChange({ prop: "lastname", value })
            }
            onSubmitEditing={() => {
              if (this._phone_input?.current) {
                if (this.props.device == "desktop") {
                  this._phone_input.current.getInputDOMNode().focus();
                } else {
                  this._phone_input.current.focus();
                }
              }
            }}
            blurOnSubmit={false}
            value={this.state.user.lastname}
            type="text"
            autoComplete={"name-family"}
            textContentType={"familyName"}
          />
          <SmallLabel>Contact Phone Number: </SmallLabel>

          <InputBox
            input_ref={this._phone_input}
            name="phone_number"
            disabled={this.state.loading}
            returnKeyType="next"
            placeholder="Phone Number"
            onChange={value =>
              this.updateUserFieldChange({ prop: "phone", value })
            }
            onSubmitEditing={() => {
              this._address.current.focus();
            }}
            blurOnSubmit={false}
            value={this.state.user.phone}
            type={this.props.device === "mobile" ? "number" : "text"}
            mask_type={"cel-phone"}
            mask={"(999) 999-9999"}
            autoComplete={"tel"}
            textContentType={"telephoneNumber"}
          />
          <SmallLabel>Mailing Address: </SmallLabel>

          <InputBox
            autoFocus={false}
            input_ref={this._address}
            name="address"
            returnKeyType="next"
            placeholder="Address"
            autoCapitalize="words"
            onChange={value => {
              this.updateUserFieldChange({ prop: "address", value });
            }}
            onSubmitEditing={() => {
              this._address2.current.focus();
            }}
            blurOnSubmit={false}
            value={this.state.user?.address?.toString()}
            type="text"
            autoComplete={"postal-address"}
            textContentType={"streetAddressLine1"}
          />

          <InputBox
            autoFocus={false}
            input_ref={this._address2}
            name="address"
            returnKeyType="next"
            placeholder="Address Line 2"
            autoCapitalize="words"
            onChange={value => {
              this.updateUserFieldChange({ prop: "address2", value });
            }}
            onSubmitEditing={() => {
              this._city.current.focus();
            }}
            blurOnSubmit={false}
            value={this.state.user?.address2?.toString()}
            type="text"
            textContentType={"streetAddressLine2"}
          />

          <InputBox
            autoFocus={false}
            input_ref={this._city}
            name="city"
            returnKeyType="next"
            placeholder="City"
            autoCapitalize="words"
            onChange={value => {
              this.updateUserFieldChange({ prop: "address_city", value });
            }}
            onSubmitEditing={() => {
              this.setState({
                state_focus: true
              });
            }}
            blurOnSubmit={false}
            value={this.state.user?.address_city?.toString()}
            type="text"
            autoComplete={"postal-address-locality"}
            textContentType={"addressCity"}
          />

          <SelectBox
            select_ref={this._state}
            items={state_data}
            value={this.state.user?.address_state}
            placeholder="State"
            show={this.state.state_focus}
            onFocus={() => {
              this.setState({ state_focus: true });
            }}
            onBlur={() => {
              this.setState({ state_focus: false });
            }}
            onSelect={item => {
              this.updateUserFieldChange({
                prop: "address_state",
                value: item
              });
            }}
            onSubmitEditing={() => {
              this._zip.current.focus();
            }}
          />

          <InputBox
            autoFocus={false}
            input_ref={this._zip}
            name="zip"
            returnKeyType="next"
            placeholder="Zip Code"
            onChange={value => {
              this.updateUserFieldChange({ prop: "address_zip", value });
            }}
            onSubmitEditing={() => {
              this.saveUserInfo();
            }}
            blurOnSubmit={false}
            value={this.state.user?.address_zip?.toString()}
            type="text"
            autoComplete={"postal-address"}
            textContentType={"postal-code"}
          />

          {this.props.device == "mobile" && panel_data?.onboarding ? (
            <GhostButton
              button_type="full"
              primary={true}
              onPress={() => this.saveUserInfo()}
              disabled={!this.checkIfNeedsToSave()}
              loading={this.state.loading}
            >
              {!!panel_data?.buttonText
                ? panel_data?.buttonText
                : "Save Contact Information"}
            </GhostButton>
          ) : null}
          <Wrapper style={{ marginBottom: 40 }}></Wrapper>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors, states } = settings;

  return { token, user, device, colors, states };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateUser
})(SignatureOnboarding);
