import React from "react";
import { Wrapper, Copy, Icon } from "app/NativeComponents/common";
import { store } from "app/store";

const Notification = ({
  style,
  notification_size = 20,
  notification_font_size = 14,
  notification_number = 0,
  notification_icon,
  notification_fa_icon,
  notification_color,
  notification_text_color,
  notification_offset = -4,
  notification_offset_x = notification_offset,
  notification_offset_y = notification_offset
}) => {
  const colors = store.getState().settings.colors;

  return (
    <Wrapper
      style={{
        borderRadius: 10,
        width: notification_size,
        height: notification_size,
        backgroundColor: notification_color || colors.active_color,
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        bottom: notification_offset_y,
        right: notification_offset_x,
        ...style
      }}
    >
      {notification_number > 0 ? (
        <Copy
          style={{
            color: notification_text_color || colors.white_text_color,
            fontSize: notification_font_size
          }}
        >
          {notification_number}
        </Copy>
      ) : !!notification_icon || !!notification_fa_icon ? (
        <Icon
          icon={notification_icon}
          fa_icon={notification_fa_icon}
          size={notification_font_size}
          color={notification_text_color || colors.white_text_color}
        />
      ) : null}
    </Wrapper>
  );
};

export { Notification };
