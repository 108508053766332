export const INIT_HOUSE = "init_house";
export const CHANGE_HOUSE_TAB = "change_house_tab";
export const OWNER_LOOKUP = "owner_lookup";
export const OWNER_LOOKUP_FAIL = "owner_lookup_fail";
export const OWNER_LOOKUP_SUCCESS = "owner_lookup_success";

export const OTHER_OWNER_LOOKUP = "other_owner_lookup";
export const OTHER_OWNER_LOOKUP_FAIL = "other_owner_lookup_fail";
export const OTHER_OWNER_LOOKUP_SUCCESS = "other_owner_lookup_success";

export const HOUSE_RESET = "house_reset";
export const SOFT_HOUSE_RESET = "soft_house_reset";
export const TOGGLE_HOUSE_ACTION_SHEET = "toggle_house_action_sheet";
export const UPDATE_HOUSE = "update_house";
export const UPDATE_HOUSE_IMAGE = "update_house_image";
export const UPDATE_HOUSE_MARKETING_IMAGE = "update_house_marketing_image";
export const DELETE_HOUSE_IMAGE = "delete_house_image";
export const UPDATE_HOUSE_ADDITIONAL_IMAGES = "update_house_additional_images";
export const SET_HOUSE_ADDITIONAL_IMAGE_LOADING =
  "SET_HOUSE_ADDITIONAL_IMAGE_LOADING";
export const UPDATE_HOUSE_SUCCESS = "update_house_success";
export const UPDATE_HOUSE_FAIL = "update_house_fail";
export const SENT_MAIL_COUNT_ADD = "sent_mail_count_add";
export const OTHER_POSSIBLE_MATCHES_TOGGLE = "other_possible_matches_toggle";
export const DELETE_DEAL = "delete_deal";

export const TRIGGER_LOOKUP = "trigger_lookup";
export const INIT_EDIT_HOUSE = "init_edit_house";
export const EDIT_HOUSE_FIELD_CHANGE = "edit_house_field_change";

export const EDIT_OPM = "edit_opm";
export const EDIT_OPM_SUCCESS = "edit_opm_success";
export const EDIT_OPM_FAIL = "edit_opm_fail";
export const TOGGLE_ALL_ADDRESSES_SUCCESS = "toggle_all_addresses_success";

export const HIDE_CTA = "hide_cta";

export const GOOGLE_STREET_VIEW_SUCCESS = "google_street_view_success";
