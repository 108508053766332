import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Copy,
  Card,
  KeyboardView,
  Title,
  StatusBarStyle,
  InternalImage
} from "app/NativeComponents/common";

import {
  askLocationPermissionsThenContinue,
  setUserLocation,
  updateMainMapLocation,
  openMobileSettings,
  checkDevicePermissions,
  getDeviceLocation,
  bypassLocation
} from "app/NativeActions";
import {
  GhostButton,
  InlineButton,
  OnboardingNavbar
} from "app/NativeComponents/snippets";
class AcceptLocationPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = { current_location_loading: false };
  }

  componentDidMount() {
    this.props.checkDevicePermissions();
  }

  render() {
    const { colors, isMobile } = this.props;
    return (
      <Container>
        <StatusBarStyle statusBarStyle={"dark-content"} />

        <KeyboardView style={{ flex: 1 }}>
          <OnboardingNavbar />
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 25
              }}
            >
              <Title
                style={{
                  textAlign: "center",
                  marginBottom: 10
                }}
              >
                <Bold>
                  {this.props.user?.onboarding_stepthrough_completed == 1
                    ? "Enable Location"
                    : "👋 Welcome to DealMachine!"}
                </Bold>
              </Title>
              <Copy
                style={{
                  textAlign: "center"
                }}
              >
                {this.props.user?.onboarding_stepthrough_completed == 1
                  ? "Please allow DealMachine to use your location."
                  : "First let's do a bit of house keeping."}
              </Copy>
            </Wrapper>

            {this.props.platform == "ios" ? (
              <Wrapper
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                {!this.props.devicePermissions?.is_location_blocked ? (
                  <>
                    <Card>
                      <InternalImage
                        style={{
                          width: 180,
                          height: 300,
                          borderRadius: 5,
                          overlay: "hidden"
                        }}
                        resizeMode={"cover"}
                        source={require("app/assets/images/LocationNotification.png")}
                      />
                    </Card>
                  </>
                ) : (
                  <>
                    <Card>
                      <InternalImage
                        style={{
                          width: 260,
                          height: 300,
                          borderRadius: 5,

                          overlay: "hidden"
                        }}
                        resizeMode={"cover"}
                        source={require("app/assets/images/SettingsPage.png")}
                      />
                    </Card>
                  </>
                )}
              </Wrapper>
            ) : null}

            <Wrapper
              style={{
                padding: 25,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Copy style={{ textAlign: "center", marginBottom: 10 }}>
                {!this.props.devicePermissions?.is_location_blocked
                  ? "We need to know your location to look for nearby off-market properties."
                  : "Your location is turned off. You'll need to turn it on in your device's settings."}
              </Copy>

              <Copy style={{ textAlign: "center" }}>
                {!this.props.devicePermissions?.checked_locations ? (
                  <>
                    <Copy>
                      You must select <Bold>"Always Allow"</Bold> or{" "}
                      <Bold>"Allow while using the app"</Bold> in order for
                      DealMachine to work.
                    </Copy>
                  </>
                ) : (
                  <>
                    <Copy>
                      You must select <Bold>"Always Allow"</Bold> or{" "}
                      <Bold>"Allow while using the app"</Bold>
                      in your device's settings order for DealMachine to work.
                    </Copy>
                  </>
                )}
              </Copy>
            </Wrapper>

            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <GhostButton
                primary={true}
                loading={this.state.completing}
                button_type={"full"}
                onPress={() => {
                  if (!this.props.devicePermissions?.is_location_blocked) {
                    this.props.askLocationPermissionsThenContinue({
                      onSuccess: () => {
                        getDeviceLocation({
                          onLoading: () => {
                            this.setState({
                              current_location_loading: true
                            });
                          },
                          onError: () => {
                            this.setState({
                              current_location_loading: false
                            });
                          },
                          onSuccess: results => {
                            this.setState(
                              {
                                current_location_loading: false
                              },
                              () => {
                                this.props.setUserLocation({
                                  longitude: results.longitude,
                                  latitude: results.latitude,
                                  heading: results.heading
                                });

                                const new_main_map_location = {
                                  ...this.props.main_map_location,
                                  longitude: results.longitude,
                                  latitude: results.latitude
                                };
                                this.props.updateMainMapLocation(
                                  new_main_map_location
                                );
                              }
                            );
                          }
                        });
                      }
                    });
                  } else {
                    openMobileSettings();
                  }
                }}
              >
                {!this.props.devicePermissions?.is_location_blocked
                  ? "Continue"
                  : "Go To Settings"}
              </GhostButton>
              {this.props.devicePermissions?.is_location_blocked ? (
                <InlineButton
                  button_type={"full"}
                  onPress={() => {
                    this.props.bypassLocation(true);
                  }}
                >
                  Continue Without Location Permissions
                </InlineButton>
              ) : null}
            </Wrapper>
          </>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, property_map }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, platform, initialRedirect } = native;
  const { devicePermissions, main_map_location } = property_map;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    platform,
    device,
    initialRedirect,
    source_of_truth,
    devicePermissions,
    main_map_location
  };
};

export default connect(mapStateToProps, {
  askLocationPermissionsThenContinue,
  setUserLocation,
  updateMainMapLocation,
  checkDevicePermissions,
  bypassLocation
})(AcceptLocationPermissions);
