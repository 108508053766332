import React, { Component } from "react";
import {
  Wrapper,
  Row,
  ExternalImage,
  CardBody
} from "app/NativeComponents/common";
import { PhotoOverlay } from "app/NativeComponents/snippets";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";

class Screenshot extends Component {
  render() {
    const { mailer } = this.props;
    if (mailer.template_preview && mailer.template_preview_back) {
      return <MailerFlipCard mailer={mailer} scale={0.25} back_scale={0.25} />;
    }

    return null;
  }
}

export default Screenshot;
