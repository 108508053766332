import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ContentfulRichText,
  Copy,
  Bold,
  KeyboardView
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  SpinWrapper,
  InlineButton
} from "app/NativeComponents/snippets";
import { loadOnboardingText } from "app/NativeActions";

class MoreInfoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      contentful_data: null,
      contentful_loading: false
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (!!this.props.contentful_slug) {
      this.loadData(this.props.contentful_slug);
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const {
      device,
      style,
      innerStyle,
      popoverPlacement = "bottom",
      renderComponent = () => {}
    } = this.props;
    const { show, contentful_content, contentful_loading } = this.state;

    let height = "85%";
    if (this.state.content_height && this.state.content_height > 0) {
      height = this.state.content_height + 190;
    }

    return (
      <PopoverMenu
        show={show}
        style={style}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={350}
        popover_height={450}
        popoverSheetTop={height}
        popoverPlacement={popoverPlacement}
        popover_title={
          !!this.props.contentful_slug
            ? contentful_content?.title
            : this.props.title
        }
        includeCloseButton={true}
        renderComponent={options => {
          return (
            <Wrapper
              wrapper_ref={this._popover}
              style={{ flex: device == "mobile" ? 0 : 1, ...innerStyle }}
            >
              {renderComponent({
                ...options,
                pressedIn: show,
                buttonText: contentful_content?.buttonText
              })}
            </Wrapper>
          );
        }}
        disabled={!this.props.contentful_slug && !this.props.content}
        renderMenu={
          !!this.props.contentful_slug
            ? () => {
                return (
                  <>
                    {contentful_loading ? (
                      <SpinWrapper text="Loading..." />
                    ) : contentful_content ? (
                      <KeyboardView style={{ flex: 1 }}>
                        <Wrapper style={{ padding: 25 }}>
                          {this.props.device == "desktop" ? (
                            <Copy>
                              <Bold>{contentful_content?.title}</Bold>
                            </Copy>
                          ) : null}
                          <ContentfulRichText
                            style={{}}
                            richText={contentful_content?.richContent}
                          />
                        </Wrapper>
                      </KeyboardView>
                    ) : null}
                  </>
                );
              }
            : () => {
                return (
                  <Wrapper>
                    <Wrapper
                      style={{ padding: 25, paddingTop: 0 }}
                      onLayout={event => {
                        const { x, y, width, height } =
                          event?.nativeEvent?.layout;
                        this.setState({
                          content_height: height
                        });
                      }}
                    >
                      <Copy>{this.props.content}</Copy>
                    </Wrapper>
                    <InlineButton
                      button_type={"full"}
                      onPress={() => {
                        this.setState({ show: false });
                      }}
                    >
                      Dismiss
                    </InlineButton>
                  </Wrapper>
                );
              }
        }
        menu_items={null}
      />
    );

    return null;
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { colors, dark_mode } = settings;
  const { isMobile, device } = native;

  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {})(MoreInfoButton);
