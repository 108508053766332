import React from "react";

const ExternalLink = ({ style, children, to = "#", target = null }) => {
  return (
    <a style={style} href={to} target={target}>
      {children}
    </a>
  );
};

export { ExternalLink };
