import React, { Component } from "react";
import { connect } from "react-redux";

import {
  KeyboardView,
  Wrapper,
  Copy,
  Container
} from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateUser,
  dismissMobileKeyboard
} from "app/NativeActions";

class ChangePhone extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      phone_number: props.user?.user_phone,
      onboarding: panel_data?.onboarding ? true : false
    };
    this.handleBack = this.handleBack.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this._phone_number_input = React.createRef();
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  changePhone() {
    dismissMobileKeyboard();

    this.props.updateUser({
      token: this.props.token,
      type: "change_verify_phone",
      payload: {
        phone: this.state.phone_number,
        onboarding: this.state.onboarding
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  renderBody() {
    return (
      <KeyboardView style={{ flex: 1 }}>
        <Wrapper style={{ padding: 25 }}>
          <Copy>
            Enter a new phone number to change the phone number associated with
            your account. We'll then send a new verification code via SMS to
            that number.
          </Copy>
        </Wrapper>

        <InputBox
          input_ref={this._phone_number_input}
          name="phone_number"
          disabled={this.state.loading}
          returnKeyType="next"
          placeholder="Phone Number"
          onChange={value => {
            this.setState({ phone_number: value });
          }}
          onSubmitEditing={this.changePhone}
          blurOnSubmit={false}
          value={this.state.phone_number}
          type="text"
          mask_type={"cel-phone"}
          mask={"(999) 999-9999"}
        />
        <GhostButton
          button_type="full"
          primary={true}
          onPress={this.changePhone}
          loading={this.state.loading}
        >
          Change Phone Number
        </GhostButton>
      </KeyboardView>
    );
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="Change Phone Number"
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />
        {this.renderBody()}
      </Container>
    );
  }
}
const mapStateToProps = ({ auth, native, property_map, settings }) => {
  const { token, user } = auth;
  const { device, platform, isMobile } = native;
  const { loading } = settings;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    loading
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateUser
})(ChangePhone);
