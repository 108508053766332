import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class EditEmailAddress extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      panel_data,
      email_address_text: panel_data?.email_address?.email_address || "",
      email_label: panel_data?.email_address?.email_label || "",
      loading: false,
      deleting: false
    };

    this._email_address_input = React.createRef();
    this._email_address_label = React.createRef();

    this.saveEmailAddress = this.saveEmailAddress.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }
  checkIfNeedsToSave() {
    if (
      !!this.state.email_address_text &&
      (this.state.email_address_text !=
        this.state.panel_data?.email_address?.email_address ||
        this.state.email_label !=
          this.state.panel_data?.email_address?.email_label)
    ) {
      return true;
    }

    return false;
  }

  saveEmailAddress() {
    const {
      panel_data: { property, email_address }
    } = this.state;

    const { email_label, email_address_text } = this.state;
    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "edit_email_address",
      email_label,
      email_address: email_address_text,
      owner_email_address_id: email_address.owner_email_address_id,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      panel_data: { property, email_address }
    } = this.state;
    const { email_address_text, email_label, loading } = this.state;
    let formatted_property_address = "";
    if (property) {
      const display_property = determineDisplayProperty(property);
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    }
    return (
      <Container>
        <NewHeader
          title="Edit Email Address"
          subtitle={
            "Edit email address for " + formatted_property_address.line1
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._email_address_label}
            name="email_address_label"
            autoFocus={true}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            placeholder="Label"
            disabled={loading}
            onChange={value => {
              this.setState({
                email_label: value
              });
            }}
            value={email_label}
            type="text"
            onSubmitEditing={() => {
              if (this._email_address_input?.current) {
                this._email_address_input.current.focus();
              }
            }}
          />
          <InputBox
            input_ref={this._email_address_input}
            name="email_address"
            disabled={loading || !email_address.manual}
            returnKeyType="done"
            placeholder="Email Address"
            autoCapitalize="none"
            keyboardType="email-address"
            onChange={value =>
              this.setState({
                email_address_text: value
              })
            }
            onSubmitEditing={this.saveEmailAddress}
            blurOnSubmit={true}
            value={email_address_text}
            type="text"
          />

          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.saveEmailAddress}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Save Email Address
          </GhostButton>
        </KeyboardView>
        {email_address.manual ? (
          <BottomNavBar>
            <ConfirmInlineButton
              confirm_text="Really Delete?"
              confirm_icon="delete"
              loading_text="Deleting"
              loading={this.state.deleting}
              button_type="full"
              onPress={() => {
                this.props.updateOwnerInfo({
                  token: this.props.token,
                  deal_id: property?.deal?.id,
                  type: "remove_email_address",
                  owner_email_address_id: email_address.owner_email_address_id,
                  onLoading: () => {
                    this.setState({
                      deleting: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      deleting: false
                    });
                  },
                  onSuccess: results => {
                    if (
                      this.state.panel_data?.propertyUpdated &&
                      results?.properties?.length == 1
                    ) {
                      this.state.panel_data.propertyUpdated(
                        results.properties[0]
                      );
                    }
                    this.setState({
                      deleting: false
                    });
                    this.handleBack();
                  }
                });
              }}
            >
              Delete Email Address
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(EditEmailAddress);
