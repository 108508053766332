import React, { Component } from "react";
import { connect } from "react-redux";
import { KeyboardView } from "app/NativeComponents/common";
import { SpinWrapper, List, AttentionBox } from "app/NativeComponents/snippets";
import { getMailerDesignTemplates } from "app/NativeActions";
import Design from "./Design";
class SelectDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      design_templates: [],
      design_templates_loading: false
    };
  }
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.template_type !== prevProps.template_type) {
      this.getData();
    }
  }

  getData() {
    const { template_type } = this.props;
    this.props.getMailerDesignTemplates({
      token: this.props.token,
      template_type,
      onLoading: () => {
        this.setState({ design_templates_loading: true });
      },
      onError: () => {
        this.setState({ design_templates_loading: false });
      },
      onSuccess: results => {
        this.setState({
          design_templates_loading: false,
          design_templates: results.mailer_design_templates
        });
      }
    });
  }

  render() {
    const { design_templates, design_templates_loading } = this.state;
    const { isMobile, colors } = this.props;

    if (design_templates_loading) {
      return <SpinWrapper text="Loading designs..." />;
    }
    return (
      <>
        <AttentionBox
          title={"Select a design"}
          description={"Choose a design to create your postcard."}
        />
        <List
          rowNumber={1}
          innerStyle={
            isMobile
              ? { flex: 1 }
              : {
                  flex: 1,
                  flexDirection: "row",
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: 20
                }
          }
          items={design_templates}
          infiniteScroll={true}
          itemStructure={({ item, index }) => {
            return (
              <Design
                mailer={item}
                key={"design_" + index}
                selectDesignTemplate={this.props.selectDesignTemplate}
                isMobile={isMobile}
                colors={colors}
              />
            );
          }}
          canRefresh={false}
          onRefresh={() => {}}
          canLoadMore={false}
          isLoadingMore={false}
          onLoadMore={() => {}}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors
  };
};

export default connect(mapStateToProps, {
  getMailerDesignTemplates
})(SelectDesign);
