import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar,
  Icon
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton,
  PopoverMenu,
  CloseButton
} from "app/NativeComponents/snippets";
import {
  numberWithCommas,
  toTitleCase,
  getHelperDescription,
  getTransactionHelperDescription,
  checkIfUserHasMetadata
} from "app/NativeActions";
import moment from "moment";
import MoreInfoButton from "app/DealMachineCore/reuseable/MoreInfoButton";

import LinkedContact from "../LinkedContact";
import LinkedCompany from "../LinkedCompany";

class TaxInformation extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, sale_offset: 0, mortgage_offset: 0 };

    this.getLinkedItemFromTransaction =
      this.getLinkedItemFromTransaction.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  getLinkedItemFromTransaction(transaction_id, matched_party) {
    const { property } = this.props;
    if (checkIfUserHasMetadata("company_data")) {
      if (property?.transaction_linked_companies) {
        const transaction_linked_companies =
          property?.transaction_linked_companies;

        for (let i = 0; i < transaction_linked_companies?.length; i++) {
          if (
            transaction_linked_companies[i]?.transaction_id == transaction_id &&
            transaction_linked_companies[i]?.matched_party == matched_party
          ) {
            return {
              type: "company",
              data: transaction_linked_companies[i]
            };
          }
        }
      }

      if (property?.transaction_linked_contacts) {
        const transaction_linked_contacts =
          property?.transaction_linked_contacts;

        let matching_contacts = [];

        for (let i = 0; i < transaction_linked_contacts?.length; i++) {
          if (
            transaction_linked_contacts[i]?.transaction_id == transaction_id &&
            transaction_linked_contacts[i]?.matched_party == matched_party
          ) {
            matching_contacts.push(transaction_linked_contacts[i]);
          }
        }

        if (matching_contacts.length > 0) {
          return {
            type: "contact",
            data: matching_contacts
          };
        }
      }
    }

    return null;
  }

  renderSalePrice(sale_price, sale_price_code, state_abbrev) {
    const { colors, device } = this.props;

    const information_item_helper_tooltip_text_lookup_keys = {
      "Estimated Sales Price": "sale_price_estimated",
      "Non-Disclosure State": "sale_price_non_disclosure_state"
    };

    const sale_price_non_disclosure_state_abbrev_array = [
      "AK",
      "ID",
      "KS",
      "LA",
      "MO",
      "MS",
      "MT",
      "ND",
      "NM",
      "TX",
      "UT",
      "WY"
    ];

    const tooltip_is_visible =
      !!information_item_helper_tooltip_text_lookup_keys[sale_price_code];
    const tooltip_helper_key =
      tooltip_is_visible &&
      sale_price_non_disclosure_state_abbrev_array.includes(state_abbrev)
        ? information_item_helper_tooltip_text_lookup_keys[
            "Non-Disclosure State"
          ]
        : tooltip_is_visible
        ? information_item_helper_tooltip_text_lookup_keys[sale_price_code]
        : "";
    const sale_price_format =
      !!sale_price && sale_price !== "0"
        ? "$" + numberWithCommas(sale_price)
        : "--";

    return (
      <Row>
        <Title style={{ color: colors.actionable_text_color }}>
          <Bold>{sale_price_format}</Bold>
        </Title>
        {tooltip_is_visible ? (
          device === "mobile" ? (
            <MoreInfoButton
              title={"Sales Price"}
              content={getHelperDescription(tooltip_helper_key)}
              style={{ alignSelf: "stretch" }}
              innerStyle={{ alignSelf: "stretch" }}
              renderComponent={({ hovering, pressedIn, buttonText }) => {
                return (
                  <Wrapper style={{ marginLeft: 5 }}>
                    <Icon
                      icon={"help"}
                      color={colors.light_text_color}
                      size={16}
                    />
                  </Wrapper>
                );
              }}
            />
          ) : (
            <Wrapper
              tooltipPlacement={"top"}
              tooltip={getHelperDescription(tooltip_helper_key)}
              style={{ marginLeft: 5 }}
            >
              <Icon icon={"help"} color={colors.light_text_color} size={12} />
            </Wrapper>
          )
        ) : null}
      </Row>
    );
  }

  render() {
    const { property, search, colors, device } = this.props;

    let first_mortgage_description = "";
    if (
      !!property?.ConcurrentMtg1InterestRate &&
      property?.ConcurrentMtg1InterestRate != "0"
    ) {
      first_mortgage_description =
        parseFloat(property?.ConcurrentMtg1InterestRate).toFixed(2) + "%";
    }
    if (!!property?.ConcurrentMtg1Term && property?.ConcurrentMtg1Term != "0") {
      if (!!first_mortgage_description) {
        first_mortgage_description +=
          " | " + property?.ConcurrentMtg1Term + " months";
      } else {
        first_mortgage_description = property?.ConcurrentMtg1Term + " months";
      }
    }
    if (!!property?.ConcurrentMtg1LoanDueDate) {
      if (!!first_mortgage_description) {
        first_mortgage_description +=
          " | Maturity date: " +
          moment(property?.ConcurrentMtg1LoanDueDate).format("MMM Do, YYYY");
      } else {
        first_mortgage_description =
          "Maturity date: " +
          moment(property?.ConcurrentMtg1LoanDueDate).format("MMM Do, YYYY");
      }
    }

    let second_mortgage_description = "";
    if (
      !!property?.ConcurrentMtg2InterestRate &&
      property?.ConcurrentMtg2InterestRate != "0"
    ) {
      second_mortgage_description =
        parseFloat(property?.ConcurrentMtg2InterestRate).toFixed(2) + "%";
    }
    if (!!property?.ConcurrentMtg2Term && property?.ConcurrentMtg2Term != "0") {
      if (!!second_mortgage_description) {
        second_mortgage_description +=
          " | " + property?.ConcurrentMtg2Term + " months";
      } else {
        second_mortgage_description = property?.ConcurrentMtg2Term + " months";
      }
    }
    if (!!property?.ConcurrentMtg2LoanDueDate) {
      if (!!second_mortgage_description) {
        second_mortgage_description +=
          " | Maturity date: " +
          moment(property?.ConcurrentMtg2LoanDueDate).format("MMM Do, YYYY");
      } else {
        second_mortgage_description =
          "Maturity date: " +
          moment(property?.ConcurrentMtg2LoanDueDate).format("MMM Do, YYYY");
      }
    }

    let third_mortgage_description = "";
    if (
      !!property?.PrevMtg1InterestRate &&
      property?.PrevMtg1InterestRate != "0"
    ) {
      third_mortgage_description =
        parseFloat(property?.PrevMtg1InterestRate).toFixed(2) + "%";
    }
    if (!!property?.PrevMtg1Term && property?.PrevMtg1Term != "0") {
      if (!!third_mortgage_description) {
        third_mortgage_description +=
          " | " + property?.PrevMtg1Term + " months";
      } else {
        third_mortgage_description = property?.PrevMtg1Term + " months";
      }
    }
    if (!!property?.PrevMtg1LoanDueDate) {
      if (!!third_mortgage_description) {
        third_mortgage_description +=
          " | Maturity date: " +
          moment(property?.PrevMtg1LoanDueDate).format("MMM Do, YYYY");
      } else {
        third_mortgage_description =
          "Maturity date: " +
          moment(property?.PrevMtg1LoanDueDate).format("MMM Do, YYYY");
      }
    }
    let positive = false;
    let percent_change = 0;

    if (!!property?.PrevSalesPrice && !!property?.saleprice) {
      //get percent change
      if (parseFloat(property?.PrevSalesPrice) > 0) {
        percent_change =
          (parseFloat(property?.saleprice) -
            parseFloat(property?.PrevSalesPrice)) /
          parseFloat(property?.PrevSalesPrice);
      }
      percent_change = percent_change * 100;
      percent_change = Math.ceil(percent_change);
      //if percent change is negative set
      if (percent_change < 0) {
        positive = false;
      } else {
        positive = true;
      }
    }

    let saledate = null;

    if (
      !!property?.mls?.sold_date &&
      (moment(property?.saledate) > moment(property?.mls?.sold_date) ||
        !property?.saledate)
    ) {
      saledate = property?.mls?.sold_date;
    } else if (!!property?.saledate) {
      saledate = property?.saledate;
    }

    const current_sale_linked_buyer_1 = this.getLinkedItemFromTransaction(
      property?.CurrentSaleTransactionID,
      "transaction_buyer_1"
    );
    const current_sale_linked_buyer_2 = this.getLinkedItemFromTransaction(
      property?.CurrentSaleTransactionID,
      "transaction_buyer_2"
    );

    const current_sale_linked_seller_1 = this.getLinkedItemFromTransaction(
      property?.CurrentSaleTransactionID,
      "transaction_seller_1"
    );

    const current_sale_linked_seller_2 = this.getLinkedItemFromTransaction(
      property?.CurrentSaleTransactionID,
      "transaction_seller_2"
    );

    const previous_sale_linked_buyer_1 = this.getLinkedItemFromTransaction(
      property?.PrevSaleTransactionID,
      "transaction_buyer_1"
    );

    const previous_sale_linked_buyer_2 = this.getLinkedItemFromTransaction(
      property?.PrevSaleTransactionID,
      "transaction_buyer_2"
    );

    const previous_sale_linked_seller_1 = this.getLinkedItemFromTransaction(
      property?.PrevSaleTransactionID,
      "transaction_seller_1"
    );

    const previous_sale_linked_seller_2 = this.getLinkedItemFromTransaction(
      property?.PrevSaleTransactionID,
      "transaction_seller_2"
    );

    return (
      <>
        {(!!property?.saleprice || !!saledate) &&
        ("Sale History".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Sale Date".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Sale Price".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Previous Sale Price".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Previous Sale Date".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          !search) ? (
          <Card
            id={"sale-history"}
            label={"Sale History"}
            style={{ paddingTop: 10, marginTop: 15 }}
            onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                sale_offset: y
              });
            }}
          >
            {!!property?.saleprice || !!property.saledate ? (
              <SelectItem
                select_type={"none"}
                noPress={true}
                renderLeft={() => {
                  return (
                    <Wrapper style={{ paddingLeft: 20, width: 150 }}>
                      <Row>
                        {this.renderSalePrice(
                          property?.saleprice,
                          property?.CurrentSalesPriceCode,
                          property?.property_address_state
                        )}
                        {percent_change > 0 ? (
                          <Copy
                            style={{
                              paddingLeft: 5,
                              color: positive
                                ? colors.success_color
                                : colors.error_color
                            }}
                          >
                            <Bold>
                              {positive
                                ? "+" + percent_change + "%"
                                : "-" + percent_change + "%"}
                            </Bold>
                          </Copy>
                        ) : null}
                      </Row>
                      <Copy style={{ marginTop: 5 }}>
                        {!!saledate
                          ? moment(saledate).format("MMM Do, YYYY")
                          : "Unknown Date"}
                      </Copy>
                      {property.CurrentSaleDocumentType ? (
                        device === "mobile" ? (
                          <MoreInfoButton
                            title={property.CurrentSaleDocumentType}
                            content={getTransactionHelperDescription(
                              property.CurrentSaleDocumentTypeCode
                            )}
                            style={{ alignSelf: "stretch" }}
                            innerStyle={{ alignSelf: "stretch" }}
                            renderComponent={({
                              hovering,
                              pressedIn,
                              buttonText
                            }) => {
                              return (
                                <Wrapper>
                                  <Row>
                                    <Copy
                                      style={{
                                        fontSize: 12,
                                        color: colors.light_text_color,
                                        marginRight: 5
                                      }}
                                    >
                                      {property.CurrentSaleDocumentType}
                                    </Copy>
                                    <Icon
                                      icon={"help"}
                                      color={colors.light_text_color}
                                      size={12}
                                    />
                                  </Row>
                                </Wrapper>
                              );
                            }}
                          />
                        ) : (
                          <Wrapper
                            tooltipPlacement={"top"}
                            tooltip={getTransactionHelperDescription(
                              property.CurrentSaleDocumentTypeCode
                            )}
                          >
                            <Row>
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color,
                                  marginRight: 5
                                }}
                              >
                                {property.CurrentSaleDocumentType}
                              </Copy>
                              <Icon
                                icon={"help"}
                                color={colors.light_text_color}
                                size={12}
                              />
                            </Row>
                          </Wrapper>
                        )
                      ) : null}
                    </Wrapper>
                  );
                }}
                contentStyle={{ flex: 1, flexDirection: "column" }}
                renderContent={() => {
                  return (
                    <Row style={{ alignSelf: "stretch" }}>
                      <Wrapper style={{ flex: 1 }}>
                        <Copy>
                          {current_sale_linked_seller_1 &&
                          current_sale_linked_seller_1?.type === "company" ? (
                            <LinkedCompany
                              {...this.props}
                              company={current_sale_linked_seller_1?.data}
                            />
                          ) : current_sale_linked_seller_1 &&
                            current_sale_linked_seller_1?.type === "contact" ? (
                            <LinkedContact
                              {...this.props}
                              contacts={current_sale_linked_seller_1?.data}
                            />
                          ) : (
                            <Copy>
                              {!!property?.CurrentSaleSeller1FullName
                                ? toTitleCase(
                                    property?.CurrentSaleSeller1FullName
                                  )
                                : "Unknown Seller"}
                            </Copy>
                          )}
                        </Copy>
                        {!!property.CurrentSaleSeller2FullName ? (
                          <>
                            {current_sale_linked_seller_2 &&
                            current_sale_linked_seller_2?.type === "company" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>
                                  <LinkedCompany
                                    {...this.props}
                                    company={current_sale_linked_seller_2?.data}
                                  />
                                </Row>
                              </>
                            ) : current_sale_linked_seller_2 &&
                              current_sale_linked_seller_2?.type ===
                                "contact" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>
                                  <LinkedContact
                                    {...this.props}
                                    contacts={
                                      current_sale_linked_seller_2?.data
                                    }
                                  />
                                </Row>
                              </>
                            ) : (
                              <Copy
                                style={{
                                  fontSize: 10,
                                  color: colors.light_text_color
                                }}
                              >
                                {"& " +
                                  toTitleCase(
                                    property.CurrentSaleSeller2FullName
                                  )}
                              </Copy>
                            )}
                          </>
                        ) : !!property.CurrentSaleBuyer2FullName &&
                          this.props.device === "desktop" ? (
                          <Copy> </Copy>
                        ) : null}
                      </Wrapper>
                      <Icon icon={"arrow-forward"} style={{ margin: 15 }} />
                      <Wrapper style={{ flex: 1 }}>
                        {current_sale_linked_buyer_1 &&
                        current_sale_linked_buyer_1?.type === "company" ? (
                          <LinkedCompany
                            {...this.props}
                            company={current_sale_linked_buyer_1?.data}
                          />
                        ) : current_sale_linked_buyer_1 &&
                          current_sale_linked_buyer_1?.type === "contact" ? (
                          <>
                            <LinkedContact
                              {...this.props}
                              contacts={current_sale_linked_buyer_1?.data}
                            />
                          </>
                        ) : (
                          <Copy>
                            {!!property.CurrentSaleBuyer1FullName
                              ? toTitleCase(property.CurrentSaleBuyer1FullName)
                              : "Unknown Buyer"}
                          </Copy>
                        )}

                        {!!property.CurrentSaleBuyer2FullName ? (
                          <>
                            {current_sale_linked_buyer_2 &&
                            current_sale_linked_buyer_2?.type === "company" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>
                                  <LinkedCompany
                                    {...this.props}
                                    company={current_sale_linked_buyer_2?.data}
                                  />
                                </Row>
                              </>
                            ) : current_sale_linked_buyer_2 &&
                              current_sale_linked_buyer_2?.type ===
                                "contact" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>
                                  <LinkedContact
                                    {...this.props}
                                    contacts={current_sale_linked_buyer_2?.data}
                                  />
                                </Row>
                              </>
                            ) : (
                              <Copy
                                style={{
                                  fontSize: 10,
                                  color: colors.light_text_color
                                }}
                              >
                                {"& " +
                                  toTitleCase(
                                    property.CurrentSaleBuyer2FullName
                                  )}
                              </Copy>
                            )}
                          </>
                        ) : !!property.CurrentSaleSeller2FullName &&
                          this.props.device === "desktop" ? (
                          <Copy> </Copy>
                        ) : null}
                      </Wrapper>
                    </Row>
                  );
                }}
              />
            ) : null}
            {!!property?.PrevSalesPrice || !!property.PrevSaleContractDate ? (
              <SelectItem
                select_type={"none"}
                noPress={true}
                renderLeft={() => {
                  return (
                    <Wrapper style={{ paddingLeft: 20, width: 150 }}>
                      {this.renderSalePrice(
                        property?.PrevSalesPrice,
                        property?.PrevSalesPriceCode,
                        property?.property_address_state
                      )}
                      <Copy style={{ marginTop: 5 }}>
                        {property.PrevSaleContractDate
                          ? moment(property.PrevSaleContractDate).format(
                              "MMM Do, YYYY"
                            )
                          : "Unknown Date"}
                      </Copy>
                      {property.PrevSaleDocumentType ? (
                        device === "mobile" ? (
                          <MoreInfoButton
                            title={property.PrevSaleDocumentType}
                            content={getTransactionHelperDescription(
                              property.PrevSaleDocumentTypeCode
                            )}
                            style={{ alignSelf: "stretch" }}
                            innerStyle={{ alignSelf: "stretch" }}
                            renderComponent={({
                              hovering,
                              pressedIn,
                              buttonText
                            }) => {
                              return (
                                <Wrapper>
                                  <Row>
                                    <Copy
                                      style={{
                                        fontSize: 12,
                                        color: colors.light_text_color,
                                        marginRight: 5
                                      }}
                                    >
                                      {property.PrevSaleDocumentType}
                                    </Copy>
                                    <Icon
                                      icon={"help"}
                                      color={colors.light_text_color}
                                      size={12}
                                    />
                                  </Row>
                                </Wrapper>
                              );
                            }}
                          />
                        ) : (
                          <Wrapper
                            tooltipPlacement={"top"}
                            tooltip={getTransactionHelperDescription(
                              property.PrevSaleDocumentTypeCode
                            )}
                          >
                            <Row>
                              <Copy
                                style={{
                                  fontSize: 12,
                                  color: colors.light_text_color,
                                  marginRight: 5
                                }}
                              >
                                {property.PrevSaleDocumentType}
                              </Copy>
                              <Icon
                                icon={"help"}
                                color={colors.light_text_color}
                                size={12}
                              />
                            </Row>
                          </Wrapper>
                        )
                      ) : null}
                    </Wrapper>
                  );
                }}
                contentStyle={{ flex: 1, flexDirection: "column" }}
                renderContent={() => {
                  return (
                    <Row style={{ alignSelf: "stretch" }}>
                      <Wrapper style={{ flex: 1 }}>
                        {previous_sale_linked_seller_1?.type === "company" ? (
                          <>
                            <Row>
                              <LinkedCompany
                                {...this.props}
                                company={previous_sale_linked_seller_1?.data}
                              />
                            </Row>
                          </>
                        ) : previous_sale_linked_seller_1 &&
                          previous_sale_linked_seller_1?.type === "contact" ? (
                          <>
                            <LinkedContact
                              {...this.props}
                              contacts={previous_sale_linked_seller_1?.data}
                            />
                          </>
                        ) : (
                          <Copy>
                            {!!property.PrevSaleSeller1FullName
                              ? toTitleCase(property.PrevSaleSeller1FullName)
                              : "Unknown Seller"}
                          </Copy>
                        )}

                        {!!property.PrevSaleSeller2FullName ? (
                          <>
                            {previous_sale_linked_seller_2 &&
                            previous_sale_linked_seller_2?.type ===
                              "company" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>

                                  <LinkedCompany
                                    {...this.props}
                                    company={
                                      previous_sale_linked_seller_2?.data
                                    }
                                  />
                                </Row>
                              </>
                            ) : previous_sale_linked_seller_2 &&
                              previous_sale_linked_seller_2?.type ===
                                "contact" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>

                                  <LinkedContact
                                    {...this.props}
                                    contacts={
                                      previous_sale_linked_seller_2?.data
                                    }
                                  />
                                </Row>
                              </>
                            ) : (
                              <Copy
                                style={{
                                  fontSize: 10,
                                  color: colors.light_text_color
                                }}
                              >
                                {"& " +
                                  toTitleCase(property.PrevSaleSeller2FullName)}
                              </Copy>
                            )}
                          </>
                        ) : null}
                      </Wrapper>
                      <Icon
                        icon={"arrow-forward"}
                        color={colors.light_text_color}
                        size={16}
                        style={{
                          marginHorizontal: 5,
                          marginRight: 25,
                          marginLeft: 25
                        }}
                      />
                      <Wrapper style={{ flex: 1 }}>
                        {previous_sale_linked_buyer_1 &&
                        previous_sale_linked_buyer_1?.type === "company" ? (
                          <>
                            <LinkedCompany
                              {...this.props}
                              company={previous_sale_linked_buyer_1?.data}
                            />
                          </>
                        ) : previous_sale_linked_buyer_1 &&
                          previous_sale_linked_buyer_1?.type === "contact" ? (
                          <>
                            <LinkedContact
                              {...this.props}
                              contacts={previous_sale_linked_buyer_1?.data}
                            />
                          </>
                        ) : (
                          <Copy>
                            {!!property.PrevSaleBuyer1FullName
                              ? toTitleCase(property.PrevSaleBuyer1FullName)
                              : "Unknown Buyer"}
                          </Copy>
                        )}

                        {!!property.PrevSaleBuyer2FullName ? (
                          <>
                            {previous_sale_linked_buyer_2 &&
                            previous_sale_linked_buyer_2?.type === "company" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>
                                  <LinkedCompany
                                    {...this.props}
                                    company={previous_sale_linked_buyer_2?.data}
                                  />
                                </Row>
                              </>
                            ) : previous_sale_linked_buyer_2 &&
                              previous_sale_linked_buyer_2?.type ===
                                "contact" ? (
                              <>
                                <Row>
                                  <Copy style={{ marginRight: 10 }}>
                                    {" & "}
                                  </Copy>
                                  <LinkedContact
                                    {...this.props}
                                    contacts={
                                      previous_sale_linked_buyer_2?.data
                                    }
                                  />
                                </Row>
                              </>
                            ) : (
                              <Copy
                                style={{
                                  fontSize: 10,
                                  color: colors.light_text_color
                                }}
                              >
                                {"& " +
                                  toTitleCase(property.PrevSaleBuyer2FullName)}
                              </Copy>
                            )}
                          </>
                        ) : null}
                      </Wrapper>
                    </Row>
                  );
                }}
              />
            ) : null}
          </Card>
        ) : null}

        {/*!!property?.ConcurrentMtg1LoanAmt &&
        property?.ConcurrentMtg1LoanAmt > 0 &&
        ("Mortgage History".toLowerCase().indexOf(search?.toLowerCase()) !==
          -1 ||
          !search) ? (
          <Card
              id={"mortgage-history"}
            label={"Mortgage History"}
            style={{ paddingTop: 10, marginTop: 15 }}

              onLayout={event => {
              const { x, y, width, height } = event?.nativeEvent?.layout;
              this.setState({
                mortgage_offset: y
              });
            }}
          >
            {(!!property?.ConcurrentMtg1LoanAmt &&
              property?.ConcurrentMtg1LoanAmt != "0") ||
            !!property.ConcurrentMtg1RecordingDate ? (
              <SelectItem
                select_type={"none"}
                noPress={true}
                renderLeft={() => {
                  return (
                    <Wrapper style={{ paddingLeft: 20, width: 150 }}>
                      <Title style={{ color: colors.actionable_text_color }}>
                        <Bold>
                          {!!property?.ConcurrentMtg1LoanAmt &&
                          property?.ConcurrentMtg1LoanAmt != "0"
                            ? "$" +
                              numberWithCommas(property?.ConcurrentMtg1LoanAmt)
                            : "--"}
                        </Bold>
                      </Title>
                      <Copy>
                        {!!property.ConcurrentMtg1RecordingDate
                          ? "Rec. Date: " +
                            moment(property.ConcurrentMtg1RecordingDate).format(
                              "MMM Do, YYYY"
                            )
                          : "Unknown Rec. Date"}
                      </Copy>
                    </Wrapper>
                  );
                }}
                subtext={property?.ConcurrentMtg1LoanType}
                description={first_mortgage_description}
              >
                {!!property?.ConcurrentMtg1Lender
                  ? toTitleCase(property?.ConcurrentMtg1Lender)
                  : "Unknown Lender"}
              </SelectItem>
            ) : null}
            {(!!property?.ConcurrentMtg2LoanAmt &&
              property?.ConcurrentMtg2LoanAmt != "0") ||
            !!property.ConcurrentMtg2RecordingDate ? (
              <SelectItem
                select_type={"none"}
                noPress={true}
                renderRight={() => {
                  return (
                    <Wrapper style={{ paddingLeft: 20, width: 150 }}>
                      <Title style={{ color: colors.actionable_text_color }}>
                        <Bold>
                          {!!property?.ConcurrentMtg2LoanAmt &&
                          property?.ConcurrentMtg2LoanAmt != "0"
                            ? "$" +
                              numberWithCommas(property?.ConcurrentMtg2LoanAmt)
                            : "--"}
                        </Bold>
                      </Title>
                      <Copy>
                        {!!property.ConcurrentMtg2RecordingDate
                          ? "Rec. Date: " +
                            moment(property.ConcurrentMtg2RecordingDate).format(
                              "MMM Do, YYYY"
                            )
                          : "Unknown Rec. Date"}
                      </Copy>
                    </Wrapper>
                  );
                }}
                subtext={property?.ConcurrentMtg2LoanType}
                description={second_mortgage_description}
              >
                {!!property?.ConcurrentMtg2Lender
                  ? toTitleCase(property?.ConcurrentMtg2Lender)
                  : "Unknown Lender"}
              </SelectItem>
            ) : null}

            {(!!property?.PrevMtg1LoanAmt &&
              property?.PrevMtg1LoanAmt != "0") ||
            !!property.PrevMtg1RecordingDate ? (
              <SelectItem
                select_type={"none"}
                noPress={true}
                renderLeft={() => {
                  return (
                    <Wrapper style={{ paddingLeft: 20, width: 150 }}>
                      <Title style={{ color: colors.actionable_text_color }}>
                        <Bold>
                          {!!property?.PrevMtg1LoanAmt &&
                          property?.PrevMtg1LoanAmt != "0"
                            ? "$" + numberWithCommas(property?.PrevMtg1LoanAmt)
                            : "--"}
                        </Bold>
                      </Title>
                      <Copy>
                        {!!property.PrevMtg1RecordingDate
                          ? "Rec. Date: " +
                            moment(property.PrevMtg1RecordingDate).format(
                              "MMM Do, YYYY"
                            )
                          : "Unknown Rec. Date"}
                      </Copy>
                    </Wrapper>
                  );
                }}
                subtext={property?.PrevMtg1LoanType}
                description={third_mortgage_description}
              >
                {!!property?.PrevMtg1Lender
                  ? toTitleCase(property?.PrevMtg1Lender)
                  : "Unknown Lender"}
              </SelectItem>
            ) : null}
          </Card>
        ) : null*/}
      </>
    );
  }
}

export default TaxInformation;
