import React, { Component } from "react";
import { Wrapper, CardBody, Row } from "app/NativeComponents/common";
import Quote from "app/DealMachineCore/reuseable/Quote";
import SkipButton from "./SkipButton";
import CheckGoals from "../Goals/CheckGoals";
import { loadDashboardStep } from "app/NativeActions";

class SetGoalsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("set_goals_enterprise")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderContents(dashboard_step) {
    //if (!this.state.completed_step) {
    return (
      <Wrapper>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: this.props.dashboardMobile ? "column" : "row"
          }}
        >
          <Wrapper>
            <Quote
              center={this.props.isMobile ? true : false}
              style={{ padding: 15, paddingTop: 5 }}
              image={
                dashboard_step.quoteImage
                  ? dashboard_step.quoteImage.fields.file.url
                  : ""
              }
              title={dashboard_step.quoteText}
              description={dashboard_step.quoteDescription}
            />
          </Wrapper>
          <Wrapper style={{ padding: 20 }}>
            <CheckGoals save_mode="auto" edit_goals={true} />
            <SkipButton
              //completed_step={this.state.completed_step}
              onPress={this.completedStep}
              title={dashboard_step.nextButtonText}
            />
          </Wrapper>
        </Wrapper>
      </Wrapper>
    );
    //}
  }

  completedStep() {
    this.props.updateTab("get_the_app");
    this.props.updateDashboard({
      token: this.props.token,
      type: "dashboard_step",
      current_step: "get_the_app"
    });
  }

  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab == "set_goals") {
      if (!loading && dashboard_step) {
        return (
          <Wrapper>
            <CardBody>{this.renderContents(dashboard_step)}</CardBody>
          </Wrapper>
        );
      }
    }
    return <Wrapper />;
  }
}

export default SetGoalsStep;
