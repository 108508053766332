import {
  LOGOUT,
  RESET_BILLING,
  BILLING_FIELD_CHANGED,
  SAVE_CARD,
  SAVE_CARD_SUCCESS,
  SAVE_CARD_FAIL,
  GET_CARD,
  GET_CARD_FAIL,
  GET_CARD_SUCCESS,
  SELECT_PLAN,
  RESET_SELECTED_PLAN,
  CANCEL_PLAN,
  CANCEL_PLAN_SUCCESS,
  CANCEL_PLAN_FAIL,
  TOGGLE_PLAN_FREQUENCY,
  GET_INVOICES,
  LOAD_MORE_INVOICES,
  REFRESH_INVOICES,
  GET_INVOICES_SUCCESS,
  LOAD_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_MARKETING_CREDITS,
  LOAD_MORE_MARKETING_CREDITS,
  GET_MARKETING_CREDITS_FAIL,
  LOAD_MARKETING_CREDITS_SUCCESS,
  GET_MARKETING_CREDITS_SUCCESS,
  INIT_EDIT_CREDIT_PURCHASE,
  EDIT_CREDIT_PURCHASE,
  EDIT_CREDITS,
  EDIT_CREDITS_FAIL,
  EDIT_CREDITS_SUCCESS,
  GET_PAUSE_PLAN_INFO,
  GET_PAUSE_PLAN_INFO_FAIL,
  GET_PAUSE_PLAN_INFO_SUCCESS,
  PAUSE_OR_CANCEL_PLAN,
  PAUSE_OR_CANCEL_PLAN_FAIL,
  PAUSE_OR_CANCEL_PLAN_SUCCESS,
  GET_DEAL_CREDITS,
  GET_PLAN_MODUALS,
  GET_BILLING_FAIL,
  GET_DEAL_CREDITS_SUCCESS,
  TRIGGER_DEAL_CREDIT_RELOAD,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_SUCCESS,
  SET_FEATURE_MODAL,
  GET_PLAN_MODUALS_SUCCESS,
  UPDATE_TEAM_MEMBERS_SUCCESS,
  UPDATE_BILLING_ADDON_SUCCESS,
  GET_LEAD_LIMITS,
  GET_LEAD_LIMITS_SUCCESS,
  GET_CONVERSATION_LIMITS,
  GET_CONVERSATION_LIMITS_SUCCESS,
  GET_LIST_LEAD_LIMITS,
  GET_LIST_LEAD_LIMITS_SUCCESS,
  SET_BILLING_DETAILS,
  ADD_DEAL_SUCCESS,
  GET_ESTIMATED_PAYMENT,
  GET_ESTIMATED_PAYMENT_SUCCESS,
  SEND_TEXT_MESSAGE_SUCCESS,
  GET_DRIVING_INFO_BANNER_SUCCESS,
  GET_LISTS_INFO_BANNER_SUCCESS,
  GET_MESSAGES_INFO_BANNER_SUCCESS,
  GET_CRM_INFO_BANNER_SUCCESS,
  SET_PAYMENT_PAGE,
  SET_PRICING_PAGE,
  SET_DEMO_PAGE,
  SET_CANCEL_PAGE,
  TOGGLE_PAYMENT_PAGE,
  TOGGLE_PRICING_PAGE,
  TOGGLE_DEMO_PAGE,
  TOGGLE_CANCEL_PAGE,
  TOGGLE_CREDIT_RELOAD,
  RELOAD_LIST,
  CHECK_CANADA_STATE,
  GET_BILLING_USER_CARDS_SUCCESS,
  UPDATE_PAYMENT_METHODS_ORDER_SUCCESS,
  ADD_ADDITIONAL_CARD,
  MARK_EDITING_CARD,
  DELETE_CARD_SUCCESS,
  MAKE_PRIMARY_CARD_SUCCESS,
  SET_FREE_TRIAL_INFO,
  GET_SOURCE_OF_TRUTH,
  GET_SOURCE_OF_TRUTH_FAIL,
  GET_SOURCE_OF_TRUTH_SUCCESS
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  source_of_truth: null,
  source_of_truth_loading: false,
  all_feature_metadata: [],
  card: {
    number: "",
    expMonth: "",
    expYear: "",
    cvc: ""
  },
  error: "",
  loading: false,
  selected_plan: {
    id: 0,
    enterprise: 0,
    commitment_length_months: 0,
    trial_length_days: 14
  },
  frequency: "",
  price: 0,

  invoices: [],
  invoices_loading: false,
  invoices_error: "",
  invoices_refreshing: false,
  invoices_loaded_all: false,
  begin: 0,
  invoices_begin: 0,
  invoices_limit: 25,
  editCreditPurchase: {
    credit_amount: "50"
  },

  marketing_credits: [],
  marketing_credits_loading: false,
  marketing_credits_error: "",
  marketing_credits_refreshing: false,
  marketing_credits_loaded_all: false,
  marketing_credits_begin: 0,
  marketing_credits_limit: 50,

  pause_plan_info: null,
  pause_plan_info_loading: false,

  deal_credits_loading: false,
  deal_credits: null,
  trigger_deal_credit_reload: false,
  pricing: {
    pricing_tier: 0,
    pricing_tier_name: "Free Plan",
    mailer_price: 99,
    ballpoint_price: 297,
    skip_trace_price: 22,
    corporate_skip_trace_price: 44
  },
  card_info: {
    has_card: 0,
    bad_card: null,
    dealcredit_bad_card: null,
    last4: null,
    bad_card_reason: null,
    cards: [],
    adding_additional: false,
    editing_card_id: null
  },
  free_trial_info: {
    plan_module_title: "",
    plan_module_type: "",
    plan_module_tier: 0,
    is_on_free_trial: false,
    is_trial_over: false,
    is_approved_to_charge: 0,
    days_left_on_trial: 0,
    total_trial_days: 0,
    trial_end_date: null,
    show_banner: true,
    banner_text: "",
    banner_button_text: "",
    banner_button_link: ""
  },
  canceled_access_info: {
    has_access: true,
    plan_module_title: "",
    plan_module_type: "",
    plan_module_tier: 0,
    days_left: 0,
    did_cancel: false,
    did_pause: false,
    show_banner: true
  },
  warning_info: {
    show_warning: false,
    banner_text: "",
    banner_button_text: "",
    banner_button_link: ""
  },
  plan_modules: [],
  feature_modal: null,

  current_team_members: 0,
  team_member_limit: 30,

  loading_plan_module_options: false,
  current_plan_module_coupon: null,
  current_plan_module_options: [],

  billing_addons: [],
  billing_details: null,

  plan_frequency: "monthly",
  loading_conversation_limits: false,
  current_billing_cycle_conversation_count: 0,
  conversation_limit: 0,

  loading_list_lead_limits: false,
  list_lead_limit: 0,
  current_billing_cycle_list_lead_count: 0,

  loading_lead_limits: false,
  total_lead_count: 0,
  current_lead_limit: 50000,
  additional_limit_price: 0,
  current_billing_cycle_driving_lead_count: 0,
  driving_lead_limit: 0,

  loading_estimated_payment: false,
  estimated_payment: null,

  driving_info_banner: null,
  lists_info_banner: null,
  messages_info_banner: null,
  crm_info_banner: null,

  payment_page: null,
  pricing_page: null,
  demo_page: null,
  cancel_page: null,
  show_pricing_page: false,
  show_demo_page: false,
  show_payment_page: false,
  show_cancel_page: false,
  is_canadian_state: false,
  auto_reload: false,
  plans_on_trial: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case GET_SOURCE_OF_TRUTH:
      return {
        ...state,
        source_of_truth_loading: true
      };

    case GET_SOURCE_OF_TRUTH_FAIL:
      return {
        ...state,
        source_of_truth_loading: false
      };

    case GET_SOURCE_OF_TRUTH_SUCCESS:
      return {
        ...state,
        source_of_truth_loading: false,
        source_of_truth: action.payload.source_of_truth
          ? action.payload.source_of_truth
          : state.source_of_truth,
        all_feature_metadata: action.payload.all_feature_metadata
          ? action.payload.all_feature_metadata
          : state.all_feature_metadata
      };
    case RESET_BILLING:
      return {
        ...state,
        card: {
          number: "",
          expMonth: "",
          expYear: "",
          cvc: ""
        }
      };
    case BILLING_FIELD_CHANGED:
      return {
        ...state,
        card: {
          ...state.card,
          [action.payload.prop]: action.payload.value
        }
      };
    case SELECT_PLAN:
      return {
        ...state,
        selected_plan: action.payload.selected_plan,
        frequency: action.payload.frequency,
        price: action.payload.price
      };
    case SAVE_CARD:
    case CANCEL_PLAN:
      return {
        ...state,
        error: "",
        loading: true
      };
    case CANCEL_PLAN_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SAVE_CARD_FAIL:
    case CANCEL_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        card_info: { ...state.card_info, adding_additional: false }
      };

    case RESET_SELECTED_PLAN:
      return {
        ...state,
        selected_plan: action.payload.plan
          ? action.payload.plan
          : action.payload.plans
          ? action.payload.plans.length > 0
            ? action.payload.plans[0]
            : state.selected_plan
          : state.selected_plan,
        frequency: action.payload.frequency,
        price: action.payload.price
      };
    case TOGGLE_PLAN_FREQUENCY:
      return {
        ...state,
        frequency: action.payload
      };
    case REFRESH_INVOICES:
      return {
        ...state,
        invoices: [],
        invoices_loading: false,
        invoices_refreshing: true,
        invoices_error: "",
        invoices_loaded_all: false,
        begin: 0
      };
    case GET_INVOICES:
      return {
        ...state,
        invoices: [],
        invoices_loading: true,
        invoices_refreshing: false,
        invoices_error: "",
        invoices_loaded_all: false,
        begin: 0
      };
    case LOAD_MORE_INVOICES:
      return {
        ...state,
        invoices_loading: true,
        invoices_refreshing: false,
        invoices_error: "",
        invoices_loaded_all: false
      };
    case GET_INVOICES_FAIL:
      return {
        ...state,
        invoices_loading: false,
        invoices_refreshing: false,
        invoices_error: action.payload
      };
    case LOAD_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: state.invoices.concat(action.payload.invoices),
        invoices_loading: false,
        invoices_refreshing: false,
        invoices_loaded_all:
          action.payload.invoices.length < state.invoices_limit,
        total_spent: action.payload.total_spent,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        begin: state.begin + action.payload.invoices.length
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload.invoices,
        invoices_loading: false,
        invoices_refreshing: false,
        invoices_loaded_all:
          action.payload.invoices.length < state.invoices_limit,
        total_spent: action.payload.total_spent,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        begin: state.begin + action.payload.invoices.length
      };

    case GET_MARKETING_CREDITS:
      return {
        ...state,
        marketing_credits: [],
        marketing_credits_loading: true,
        marketing_credits_refreshing: false,
        marketing_credits_error: "",
        marketing_credits_loaded_all: false,
        begin: 0
      };
    case LOAD_MORE_MARKETING_CREDITS:
      return {
        ...state,
        marketing_credits_loading: true,
        marketing_credits_refreshing: false,
        marketing_credits_error: "",
        marketing_credits_loaded_all: false
      };
    case GET_MARKETING_CREDITS_FAIL:
      return {
        ...state,
        marketing_credits_loading: false,
        marketing_credits_refreshing: false,
        marketing_credits_error: action.payload
      };
    case LOAD_MARKETING_CREDITS_SUCCESS:
      return {
        ...state,
        marketing_credits: state.marketing_credits.concat(
          action.payload.marketing_credits
        ),
        marketing_credits_loading: false,
        marketing_credits_refreshing: false,
        marketing_credits_loaded_all:
          action.payload.marketing_credits.length <
          state.marketing_credits_limit,
        total_spent: action.payload.total_spent,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        begin: state.begin + action.payload.marketing_credits.length
      };
    case GET_MARKETING_CREDITS_SUCCESS:
      return {
        ...state,
        marketing_credits: action.payload.marketing_credits,
        marketing_credits_loading: false,
        marketing_credits_refreshing: false,
        marketing_credits_loaded_all:
          action.payload.marketing_credits.length <
          state.marketing_credits_limit,
        total_spent: action.payload.total_spent,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        begin: state.begin + action.payload.marketing_credits.length
      };

    case INIT_EDIT_CREDIT_PURCHASE:
      return {
        ...state,
        editCreditPurchase: action.payload
      };

    case EDIT_CREDIT_PURCHASE:
      return {
        ...state,
        editCreditPurchase: {
          ...state.editCreditPurchase,
          [action.payload.prop]: action.payload.value
        }
      };
    case EDIT_CREDITS:
      return {
        ...state
      };
    case EDIT_CREDITS_FAIL:
      return {
        ...state
      };

    case GET_PAUSE_PLAN_INFO:
      return {
        ...state,
        pause_plan_info: null,
        pause_plan_info_loading: true
      };
    case GET_PAUSE_PLAN_INFO_FAIL:
      return {
        ...state,
        pause_plan_info_loading: false
      };

    case GET_PAUSE_PLAN_INFO_SUCCESS:
      return {
        ...state,
        pause_plan_info: action.payload,
        pause_plan_info_loading: false
      };

    case GET_DEAL_CREDITS:
      return {
        ...state,
        deal_credits_loading: true,
        trigger_deal_credit_reload: false
      };

    case GET_BILLING_FAIL:
      return {
        ...state,
        loading_plan_module_options: false,
        deal_credits_loading: false,
        trigger_deal_credit_reload: false,
        loading_estimated_payment: false
      };

    case GET_DEAL_CREDITS_SUCCESS:
      return {
        ...state,
        deal_credits_loading: false,
        deal_credits: action.payload?.deal_credits
          ? action.payload?.deal_credits
          : state.deal_credits,
        trigger_deal_credit_reload: false
      };

    case TRIGGER_DEAL_CREDIT_RELOAD:
      return {
        ...state,
        trigger_deal_credit_reload: action.payload
      };

    case SEND_TEXT_MESSAGE_SUCCESS:
      return {
        ...state,
        messages_info_banner: action.payload.messages_info_banner
          ? action.payload.messages_info_banner
          : state.messages_info_banner
      };

    case LOGIN_USER_SUCCESS:
    case REGISTER_USER_SUCCESS:
    case SAVE_CARD_SUCCESS:
    case PAUSE_OR_CANCEL_PLAN_SUCCESS:
    case EDIT_CREDITS_SUCCESS:
    case ADD_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        deal_credits:
          action.payload.billing && action.payload.billing?.deal_credits
            ? action.payload.billing?.deal_credits
            : action.payload?.deal_credits
            ? action.payload.deal_credits
            : state.deal_credits,
        pricing: {
          pricing_tier: action.payload.billing
            ? action.payload.billing.pricing_tier
            : state.pricing_tier,
          pricing_tier_name: action.payload.billing
            ? action.payload.billing.pricing_tier_name
            : state.pricing_tier_name,
          mailer_price: action.payload.billing
            ? action.payload.billing.mailer_price
            : state.pricing.mailer_price,
          ballpoint_price: action.payload.billing
            ? action.payload.billing.ballpoint_price
            : state.pricing.ballpoint_price,
          skip_trace_price: action.payload.billing
            ? action.payload.billing.skip_trace_price
            : state.pricing.skip_trace_price,
          corporate_skip_trace_price: action.payload.billing
            ? action.payload.billing.corporate_skip_trace_price
            : state.pricing.corporate_skip_trace_price
        },
        team_member_limit: action.payload.billing
          ? action.payload.billing.team_member_limit
          : state.team_member_limit,
        current_team_members: action.payload.billing
          ? action.payload.billing.current_team_members
          : state.current_team_members,
        signature_limit: action.payload.billing
          ? action.payload.billing.signature_limit
          : state.signature_limit,
        card_info: action.payload.billing
          ? {
              ...state.card_info,
              ...action.payload.billing.card_info,
              adding_additional: false,
              editing_card_id: null
            }
          : {
              ...state.card_info,
              adding_additional: false,
              editing_card_id: null
            },
        plan_modules: action.payload.billing
          ? action.payload.billing.plan_modules
          : state.plan_modules,
        free_trial_info: action.payload.billing
          ? action.payload.billing.free_trial_info
          : state.free_trial_info,
        canceled_access_info: action.payload.billing
          ? action.payload.billing.canceled_access_info
          : state.canceled_access_info,
        warning_info: action.payload.billing
          ? action.payload.billing.warning_info
          : state.warning_info,
        billing_addons: action.payload.billing
          ? action.payload.billing.billing_addons
          : state.billing_addons,
        plan_frequency: action.payload.billing
          ? action.payload.billing.plan_frequency
          : state.plan_frequency,

        driving_info_banner: action.payload.driving_info_banner
          ? action.payload.driving_info_banner
          : state.driving_info_banner,
        lists_info_banner: action.payload.lists_info_banner
          ? action.payload.lists_info_banner
          : state.lists_info_banner,
        messages_info_banner: action.payload.messages_info_banner
          ? action.payload.messages_info_banner
          : state.messages_info_banner,

        source_of_truth: action.payload.number_of_leads_added_from_map
          ? {
              ...state.source_of_truth,
              current_limits: {
                ...state.source_of_truth?.current_limits,
                number_of_leads_added_from_map: action.payload
                  .number_of_leads_added_from_map
                  ? action.payload.number_of_leads_added_from_map
                  : state.source_of_truth?.current_limits
                      ?.number_of_leads_added_from_map
              }
            }
          : state.source_of_truth
      };

    case SET_FEATURE_MODAL:
      return {
        ...state,
        feature_modal: action.payload
      };

    case GET_LEAD_LIMITS:
      return {
        ...state,
        loading_lead_limits: true,

        total_lead_count: INITIAL_STATE.total_lead_count,
        current_lead_limit: INITIAL_STATE.current_lead_limit,
        additional_limit_price: INITIAL_STATE.additional_limit_price,
        current_billing_cycle_driving_lead_count:
          INITIAL_STATE.current_billing_cycle_driving_lead_count,
        driving_lead_limit: INITIAL_STATE.driving_lead_limit
      };
    case GET_LEAD_LIMITS_SUCCESS:
      return {
        ...state,
        loading_lead_limits: false,
        total_lead_count: action.payload.total_lead_count
          ? action.payload.total_lead_count
          : state.total_lead_count,
        current_lead_limit: action.payload.current_lead_limit
          ? action.payload.current_lead_limit
          : state.current_lead_limit,
        additional_limit_price: action.payload.additional_limit_price
          ? action.payload.additional_limit_price
          : state.additional_limit_price,
        current_billing_cycle_driving_lead_count: action.payload
          .current_billing_cycle_driving_lead_count
          ? action.payload.current_billing_cycle_driving_lead_count
          : state.current_billing_cycle_driving_lead_count,
        driving_lead_limit: action.payload.driving_lead_limit
          ? action.payload.driving_lead_limit
          : state.driving_lead_limit
      };

    case GET_LIST_LEAD_LIMITS:
      return {
        ...state,
        loading_list_lead_limits: true,
        current_billing_cycle_list_lead_count:
          INITIAL_STATE.current_billing_cycle_list_lead_count,
        list_lead_limit: INITIAL_STATE.list_lead_limit
      };
    case GET_LIST_LEAD_LIMITS_SUCCESS:
      return {
        ...state,
        loading_list_lead_limits: false,
        current_billing_cycle_list_lead_count: action.payload
          .current_billing_cycle_list_lead_count
          ? action.payload.current_billing_cycle_list_lead_count
          : state.current_billing_cycle_list_lead_count,
        list_lead_limit: action.payload.list_lead_limit
          ? action.payload.list_lead_limit
          : state.list_lead_limit
      };

    case GET_CONVERSATION_LIMITS:
      return {
        ...state,
        loading_conversation_limits: true,
        current_billing_cycle_conversation_count:
          INITIAL_STATE.current_billing_cycle_conversation_count,
        conversation_limit: INITIAL_STATE.conversation_limit
      };
    case GET_CONVERSATION_LIMITS_SUCCESS:
      return {
        ...state,
        loading_conversation_limits: false,
        current_billing_cycle_conversation_count: action.payload
          .current_billing_cycle_conversation_count
          ? action.payload.current_billing_cycle_conversation_count
          : state.current_billing_cycle_conversation_count,
        conversation_limit: action.payload.conversation_limit
          ? action.payload.conversation_limit
          : state.conversation_limit
      };

    case GET_ESTIMATED_PAYMENT:
      return {
        ...state,
        loading_estimated_payment: true,
        estimated_payment: null
      };
    case GET_ESTIMATED_PAYMENT_SUCCESS:
      return {
        ...state,
        loading_estimated_payment: false,
        estimated_payment: action.payload.estimated_payment
      };
    case GET_PLAN_MODUALS:
      return {
        ...state,
        loading_plan_module_options: true,
        current_plan_module_options: [],
        current_plan_module_coupon: null
      };
    case GET_PLAN_MODUALS_SUCCESS:
      return {
        ...state,
        loading_plan_module_options: false,
        current_plan_module_coupon: action.payload.plan_module_coupon
          ? action.payload.plan_module_coupon
          : state.current_plan_module_coupon,
        current_plan_module_options: action.payload.plan_module_options
          ? action.payload.plan_module_options
          : state.current_plan_module_options
      };
    case UPDATE_BILLING_ADDON_SUCCESS:
      return {
        ...state,
        card_info: action.payload.billing
          ? {
              ...state.card_info,
              ...action.payload.billing.card_info
            }
          : state.card_info,
        plan_modules: action.payload.billing
          ? action.payload.billing.plan_modules
          : state.plan_modules,
        billing_addons: action.payload.billing
          ? action.payload.billing.billing_addons
          : state.billing_addons
      };
    case UPDATE_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        plan_modules: action.payload.billing
          ? action.payload.billing.plan_modules
          : state.billing.plan_modules,
        current_team_members: action.payload.billing
          ? action.payload.billing.current_team_members
          : state.billing.current_team_members,
        team_member_limit: action.payload.billing
          ? action.payload.billing.team_member_limit
          : state.billing.team_member_limit
      };
    case SET_BILLING_DETAILS:
      return {
        ...state,
        billing_details: {
          title: action.payload.title,
          plan_module_title: action.payload.plan_module_title,
          slug: action.payload.slug,
          plan_module_info: action.payload.plan_module_info,
          show_limits: action.payload.show_limits,
          current_count: action.payload.current_count,
          current_limit: action.payload.current_limit,
          limit_title: action.payload.limit_title
        }
      };

    case GET_DRIVING_INFO_BANNER_SUCCESS:
      return {
        ...state,
        driving_info_banner: action.payload.driving_info_banner
      };
    case GET_LISTS_INFO_BANNER_SUCCESS:
    case RELOAD_LIST:
      return {
        ...state,
        lists_info_banner: action.payload.lists_info_banner
          ? action.payload.lists_info_banner
          : state.lists_info_banner
      };
    case GET_MESSAGES_INFO_BANNER_SUCCESS:
      return {
        ...state,
        messages_info_banner: action.payload.messages_info_banner
      };
    case GET_CRM_INFO_BANNER_SUCCESS:
      return {
        ...state,
        crm_info_banner: action.payload.crm_info_banner
      };
    case SET_PAYMENT_PAGE:
      return {
        ...state,
        payment_page: action.payload
      };
    case SET_PRICING_PAGE:
      return {
        ...state,
        pricing_page: action.payload
      };
    case SET_DEMO_PAGE:
      return {
        ...state,
        demo_page: action.payload
      };
    case SET_CANCEL_PAGE:
      return {
        ...state,
        cancel_page: action.payload
      };
    case TOGGLE_DEMO_PAGE:
      return {
        ...state,
        show_demo_page: action.payload
      };
    case TOGGLE_PRICING_PAGE:
      return {
        ...state,
        show_pricing_page: action.payload
      };
    case TOGGLE_PAYMENT_PAGE:
      return {
        ...state,
        show_payment_page: action.payload
      };
    case TOGGLE_CANCEL_PAGE:
      return {
        ...state,
        show_cancel_page: action.payload
      };
    case CHECK_CANADA_STATE:
      return {
        ...state,
        is_canadian_state: action.payload
      };
    case GET_BILLING_USER_CARDS_SUCCESS:
      return {
        ...state,
        card_info: { ...state.card_info, cards: action.payload }
      };
    case UPDATE_PAYMENT_METHODS_ORDER_SUCCESS:
      return {
        ...state,
        card_info: {
          ...state.card_info,
          cards: action.payload.cards
            ? action.payload.cards
            : state.card_info.cards
        }
      };
    case ADD_ADDITIONAL_CARD:
      // Store in state that we're adding an additional card, so as to not replace the primary
      return {
        ...state,
        card_info: { ...state.card_info, adding_additional: true }
      };
    case MARK_EDITING_CARD:
      // Store in state the value of the card we're editing so that we can send it in the API request
      // See DealMachineAPIV2.saveCard
      return {
        ...state,
        card_info: {
          ...state.card_info,
          editing_card_id: action.payload.card_id
        }
      };

    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        card_info: { ...state.card_info, cards: action.payload.cards.cards }
      };
    case MAKE_PRIMARY_CARD_SUCCESS:
      return {
        ...state,
        card_info: { ...state.card_info, cards: action.payload.cards.cards }
      };
    case TOGGLE_CREDIT_RELOAD:
      return {
        ...state,
        auto_reload: action.payload
      };
    case SET_FREE_TRIAL_INFO:
      return {
        ...state,
        plans_on_trial: action.payload
      };
    default:
      return state;
  }
};
