import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  ModalContainer,
  CardBody,
  Wrapper,
  Title,
  Copy,
  Bold,
  InternalImage
} from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import Body from "./Body";

import {
  saveCard,
  resetBilling,
  resetSelectedPlan,
  billingFieldChanged,
  toggleOnboarding,
  changeTab,
  popSidePanel,
  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

class CardOnFile extends Component {
  constructor(props) {
    super(props);

    const {
      user: {
        address,
        address2,
        address_city,
        address_state,
        address_zip,
        firstname,
        lastname,
        team_email
      }
    } = this.props;

    this.state = {
      address: {
        name: [firstname, lastname].join(" ") || "",
        address: address || "",
        address2: address2 || "",
        city: address_city || "",
        state: address_state || "",
        zip: address_zip || "",
        email: team_email || ""
      },
      loading: false
    };
  }

  editAddressField({ prop, value }) {
    this.setState({
      address: {
        ...this.state.address,
        [prop]: value
      }
    });
  }

  componentDidMount() {
    if (
      this.props.user.team_owner == 1 ||
      this.props.user.can_mimic_team_owner == 1
    ) {
      this.props.resetBilling();
      this.props.resetSelectedPlan({
        plan: this.props.stats.billing.plan,
        frequency: this.props.stats.billing.frequency,
        price: this.props.stats.billing.price,
        plans: this.props.stats.plans
      });
    } else {
      this.handleBack();
    }
  }
  componentWillUnmount() {
    this.props.resetBilling();
  }

  handleBack() {
    /* mobile*/
    dismissMobileKeyboard();
    if (this.props.onboarding) {
      this.props.toggleOnboarding(false);
    }
    this.props.popSidePanel();
  }

  saveBilling(card_token, error) {
    dismissMobileKeyboard();

    // A value of non-empty string in card_info.editing_card_id indicates that the user is
    // trying to edit an existing card
    const editing_card_id = this.props.card_info.editing_card_id
      ? this.props.card_info.editing_card_id
      : null;

    let edit_card = null;
    if (editing_card_id) {
      edit_card = this.props.card_info.cards.find(
        c => c.stripe_card_id == editing_card_id
      );
    }
    const editing_primary_card =
      editing_card_id && edit_card && edit_card.card_order == 0;

    let should_replace_primary = true;
    const bad_or_no_card =
      this.props.card_info.bad_card == 1 ||
      !this.props.card_info.cards ||
      this.props.card_info.cards.length == 0;
    if (bad_or_no_card || editing_primary_card) {
      should_replace_primary = true;
    } else if (this.props.card_info.adding_additional) {
      should_replace_primary = false;
    }
    // Basically, ^ this says replace the primary card iff I have no good cards
    // and I've indicated that I only want to add an additional non-primary one,
    // i.e., by interacting with /settings/billing/manage-payment-methods
    // Or - I am editing the primary card

    if (card_token || error) {
      const save_card_params = {
        token: this.props.token,
        card_token: card_token,
        error: error,
        price: this.props.price,
        plan: this.props.selected_plan.id,
        frequency: this.props.frequency,
        type: this.props.onboarding
          ? this.props.card_info.bad_card == 1
            ? "reactivate_card"
            : "onboarding_add_card"
          : "update_card",
        device: this.props.platform,
        address: this.state.address,
        email: this.state.address.email,
        onLoading: () => {
          this.setState({
            loading: true
          });
        },
        onError: () => {
          this.setState({
            loading: false
          });
        },
        onSuccess: () => {
          this.setState({
            loading: false
          });
          this.props.popSidePanel();
        }
      };

      if (!should_replace_primary) {
        save_card_params.replace_primary = false;
        save_card_params.expected_card_order =
          this.props.card_info.cards.length;
      }
      if (editing_card_id) {
        save_card_params.editing_card_id = editing_card_id;
      }

      this.props.saveCard(save_card_params);
    } else {
      const { number, expMonth, expYear, cvc } = this.props.card;
      const save_card_params = {
        token: this.props.token,
        number,
        expMonth,
        expYear,
        cvc,
        price: this.props.price,
        plan: this.props.selected_plan.id,
        frequency: this.props.frequency,
        type: this.props.onboarding
          ? this.props.card_info.bad_card == 1
            ? "reactivate_card"
            : "onboarding_add_card"
          : "update_card",
        device: this.props.platform,
        address: this.state.address,
        email: this.state.address.email,
        onLoading: () => {
          this.setState({
            loading: true
          });
        },
        onError: () => {
          this.setState({
            loading: false
          });
        },
        onSuccess: () => {
          this.setState({
            loading: false
          });
          this.props.popSidePanel();
        }
      };
      if (!should_replace_primary) {
        save_card_params.replace_primary = false;
        save_card_params.expected_card_order =
          this.props.card_info.cards.length;
      }
      if (editing_card_id) {
        save_card_params.editing_card_id = editing_card_id;
      }

      this.props.saveCard(save_card_params);
    }
  }

  checkIfNeedsToSave() {
    if (
      this.props.card.number &&
      this.props.card.number.length > 0 &&
      this.props.card.expMonth &&
      this.props.card.expMonth.length > 0 &&
      this.props.card.expYear &&
      this.props.card.expYear.length > 0 &&
      this.props.card.cvc &&
      this.props.card.cvc.length > 0
    ) {
      return true;
    }

    return false;
  }

  render() {
    if (this.props.platform == "ios") {
      return (
        <Container>
          <NewHeader
            title={"Credit Card Required"}
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack.bind(this)
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            <Wrapper style={{ padding: 25 }}>
              <Copy>
                <Bold>You need more marketing credit.</Bold>
              </Copy>
              <Copy>
                To continue to send mail pieces or skip trace your leads, your
                need to add a credit card on your account. You can't do this
                from the the app. We know it's a hassle. Add your card on our
                web app and come back to start sending mail pieces and skip
                tracing leads.
              </Copy>
            </Wrapper>
          </KeyboardView>
        </Container>
      );
    }

    if (this.props.onboarding) {
      return (
        <Container style={{}}>
          <NewHeader
            title={
              this.props.card_info.bad_card == 1
                ? "Update The Card On Your Account"
                : "Add A Card To Your Account"
            }
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack.bind(this)
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            <Body
              {...this.props}
              address={this.state.address}
              editAddressField={this.editAddressField.bind(this)}
              saveBilling={this.saveBilling.bind(this)}
              checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
              loading={this.state.loading}
            />
          </KeyboardView>
        </Container>
      );
    }

    return (
      <Container>
        <NewHeader
          title={"Card On File"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack.bind(this)
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <Body
            {...this.props}
            address={this.state.address}
            editAddressField={this.editAddressField.bind(this)}
            saveBilling={this.saveBilling.bind(this)}
            checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, billing, drawer, settings }) => {
  const { token, user, onboarding } = auth;
  const { device, platform } = native;
  const { card, price, selected_plan, frequency, loading, card_info } = billing;
  const { states, colors } = settings;
  const { stats } = drawer;

  return {
    token,
    user,
    onboarding,
    device,
    platform,
    card,
    price,
    selected_plan,
    frequency,
    stats,
    loading,
    card_info,
    states,
    colors
  };
};

export default connect(mapStateToProps, {
  saveCard,
  resetBilling,
  resetSelectedPlan,
  billingFieldChanged,
  toggleOnboarding,

  changeTab,
  popSidePanel
})(CardOnFile);
