import React, { Component } from "react";
import { Wrapper, FlipOverCard, Web } from "app/NativeComponents/common";
import SideTabs from "./SideTabs";
import MailerPreview from "app/DealMachineCore/reuseable/MailerPreview";
import {AppConfig} from "app/NativeActions";

class MainBody extends Component {
  render() {
    const {
      token,
      isMobile,
      side_tab,
      front_width,
      front_height,
      back_width,
      back_height,
      template
    } = this.props;

    let url = AppConfig().api_url+"preview/?token=" +
      token +
      "&preview=true" +
      "&template_id=" +
      encodeURIComponent(!!template?.id ? template.id : "") +
      "&html_template_id=" +
      encodeURIComponent(
        !!template?.html_template?.id ? template.html_template.id : ""
      ) +
      "&section_a=" +
      encodeURIComponent(!!template.section_a ? template.section_a : "") +
      "&section_b=" +
      encodeURIComponent(!!template.section_b ? template.section_b : "") +
      "&section_c=" +
      encodeURIComponent(!!template.section_c ? template.section_c : "") +
      "&section_d=" +
      encodeURIComponent(!!template.section_d ? template.section_d : "") +
      "&primary_color=" +
      encodeURIComponent(
        !!template.primary_color ? template.primary_color : ""
      ) +
      "&secondary_color=" +
      encodeURIComponent(
        !!template.secondary_color ? template.secondary_color : ""
      );
    let back_url = url + "&side=back";

    //scale for ballpoint
    let scale = 1;
    let back_scale = 1;
    if (template.template_type == "handwritten") {
      if (isMobile) {
        scale = 0.5;
        back_scale = 0.5;
      } else {
        url = url + "&scale=0.4";
      }
    } else if (isMobile) {
      scale = 0.5;
      back_scale = 0.5;
    }

    let f_width = parseInt(parseInt(front_width) * parseFloat(scale));
    let f_height = parseInt(parseInt(front_height) * parseFloat(scale));
    let b_width = parseInt(parseInt(back_width) * parseFloat(back_scale));
    let b_height = parseInt(parseInt(back_height) * parseFloat(back_scale));

    return (
      <Wrapper
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 20
        }}
      >
        <Wrapper
          style={{
            width: f_width,
            height: f_height,
            position: "relative"
          }}
        >
          <FlipOverCard
            width={f_width}
            height={f_height}
            flipped={side_tab == "back"}
            frontSide={() => {
              return (
                <Wrapper
                  style={{
                    width: f_width,
                    height: f_height,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Web
                    width={f_width}
                    height={f_height}
                    url={url}
                    scrollEnabled={false}
                  />
                </Wrapper>
              );
            }}
            backSide={() => {
              return (
                <Wrapper
                  style={{
                    width: f_width,
                    height: f_height,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Web
                    width={b_width}
                    height={b_height}
                    url={back_url}
                    scrollEnabled={false}
                  />
                </Wrapper>
              );
            }}
          />
        </Wrapper>

        <SideTabs {...this.props} side_tab={side_tab} />
      </Wrapper>
    );
  }
}

export default MainBody;
