import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper } from "app/NativeComponents/common";

import {
  SearchBar,
  InlineButton,
  IconButton
} from "app/NativeComponents/snippets";

import { pushSidePanel } from "app/NativeActions";
class SearchAndFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      show_quick_filters: false
    };
  }
  render() {
    const { colors, isMobile } = this.props;
    return (
      <Row
        style={{
          padding: 20,
          paddingBottom: isMobile ? 0 : 20,
          paddingLeft: isMobile ? 10 : 20,
          paddingRight: isMobile ? 10 : 20,
          flexWrap: "wrap"
        }}
      >
        <Wrapper style={{ flex: 1 }}>
          <Row>
            <Wrapper style={isMobile ? { flex: 1 } : {}}>
              <SearchBar
                style={{
                  width: isMobile ? "100%" : 300,
                  marginBottom: isMobile ? 10 : 0,
                  marginRight: isMobile ? 10 : 0
                }}
                value={this.state.search}
                title="Search People"
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
                showButton={true}
                disabledButton={
                  this.props.search_loading ||
                  (this.state.search == this.props.search &&
                    !this.props.search_loading)
                }
                buttonText={
                  this.state.search !== this.props.search &&
                  this.state.search.length == 0
                    ? "Clear"
                    : "Search"
                }
                buttonLoading={this.props.search_loading}
                onPress={() => {
                  this.props.updateSearch(
                    this.state.search.replace(/[^A-Z0-9]+/gi, " ")
                  );
                }}
                renderRight={
                  !!this.state.search && !this.props.search_loading
                    ? () => {
                        return (
                          <IconButton
                            icon={"close"}
                            button_type="small"
                            onPress={() => {
                              this.setState({
                                search: ""
                              });
                              this.props.updateSearch("");
                            }}
                          />
                        );
                      }
                    : null
                }
              />
            </Wrapper>

            <InlineButton
              icon={"tune"}
              onPress={() => {
                this.setState(
                  {
                    show_quick_filters: false
                  },
                  () => {
                    this.props.pushSidePanel({
                      slug: "advanced_filters",
                      overlay: true
                    });
                  }
                );
              }}
            >
              All Filters
            </InlineButton>
          </Row>
        </Wrapper>
      </Row>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, { pushSidePanel })(SearchAndFilter);
