import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  GhostButton,
  SpinWrapper,
  Select,
  SmallHeader,
  SelectBox
} from "app/NativeComponents/snippets";
import { getTeamMembers, toTitleCase } from "app/NativeActions";

import AdvancedFilters from "app/DealMachineCore/reuseable/AdvancedFilters";

class ExportStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.active_step?.data
        ? props.active_step.data
        : {
            data: [],
            andor_type: "or",

            export_to_user: props.user?.id || 0,
            export_to_user_name:
              props.user?.firstname + " " + props.user.lastname || ""
          }
    };
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (
        this.state.data?.data.length > 0 &&
        this.state.data.export_to_user > 0
      ) {
        return true;
      }
    }

    return false;
  }

  componentDidMount() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "replace",
      type: "accepted_members",
      begin: 0
    });
  }

  renderUserName(user_id) {
    const { team_members } = this.props;
    for (let i = 0; i < team_members.length; i++) {
      if (team_members[i].value == user_id) {
        return team_members[i].label;
      }
    }

    return "None selected";
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading,
      team_members_loading,
      team_members
    } = this.props;
    const { data } = this.state;
    if (active_step) {
      return (
        <>
          <SmallHeader
            title={"Trigger Lead Export"}
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {enrollment_object_loading || team_members_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <>
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>Trigger a export of leads, sending to:</Copy>
                  </Wrapper>

                  <SelectBox
                    items={team_members}
                    value={data.export_to_user}
                    placeholder="Select team member"
                    onFocus={() => {}}
                    onBlur={() => {}}
                    onSelect={value => {
                      this.setState({
                        data: {
                          ...data,
                          export_to_user: value,
                          export_to_user_name: this.renderUserName(value)
                        }
                      });
                    }}
                    onSubmitEditing={() => {}}
                  />
                </>

                <Wrapper style={{ padding: 25 }}>
                  <Copy>...meeting the following criteria:</Copy>
                </Wrapper>

                <AdvancedFilters
                  {...this.props}
                  filters={data}
                  onChange={filters => {
                    this.setState({
                      data: {
                        ...data,
                        data: filters.data,
                        andor_type: filters.andor_type
                      }
                    });
                  }}
                />
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ workflow, team }) => {
  const { enrollment_object, enrollment_object_loading } = workflow;
  const { team_members, team_members_loading } = team;

  return {
    enrollment_object,
    enrollment_object_loading,
    team_members,
    team_members_loading
  };
};

export default connect(mapStateToProps, { getTeamMembers })(ExportStep);
