import React, { PureComponent } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import moment from "moment";

import { Icon } from "app/NativeComponents/common";
import { store } from "app/store";

class InputDatePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: moment().format("YYYY-MM-DD")
    };
  }

  componentDidMount() {
    if (this.props.date) {
      this.setState({
        date: moment(this.props.date).format("YYYY-MM-DD")
      });
    } else if (this.props.allowEmpty) {
      this.setState({
        date: ""
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.date &&
      this.props.date &&
      this.props.date != prevProps.date
    ) {
      this.setState({
        date: moment(this.props.date).format("YYYY-MM-DD")
      });
    }
  }

  renderIcon() {
    if (this.props.icon) {
      if (this.props.icon == "blank") {
        return (
          <div
            className="flex-item"
            style={{
              width: 80,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            &nbsp;
          </div>
        );
      }

      return (
        <div
          className="flex-item"
          style={{ width: 80, alignItems: "center", justifyContent: "center" }}
        >
          <Icon icon={this.props.icon} size={26} />
        </div>
      );
    }
  }

  render() {
    const colors = store.getState().settings.colors;

    return (
      <div className="dm-input flex-item left-flex">
        {this.renderIcon()}
        <div style={{ flex: 1 }}>
          <div className={"floating-label active"}>
            <label
              style={{
                color: colors.light_text_color
              }}
            >
              {this.props.placeholder}
            </label>
          </div>
          <div
            style={{
              marginTop: 8
            }}
          >
            <DayPickerInput
              dayPickerProps={{
                containerProps: { style: { ...this.props.overlayStyle } }
              }}
              inputProps={{ style: { ...this.props.inputStyle } }}
              value={
                this.props.allowEmpty && !this.state.date
                  ? this.props.allowEmpty
                  : moment(this.state.date).format("MMM Do, YYYY")
              }
              onDayChange={date => {
                this.setState({
                  date: moment(date).format("YYYY-MM-DD")
                });
                this.props.onChange(moment(date).format("YYYY-MM-DD"));
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export { InputDatePicker };
