import React, { Component } from "react";
import { Wrapper, Copy, Bold, Button, Row } from "app/NativeComponents/common";
import { PillButton, PopoverMenu } from "app/NativeComponents/snippets";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";
class ExportComponent extends Component {
  render() {
    const {
      is_active,
      onPress,
      step,
      step_index,
      branch_index = null,
      branch_step_index = null,
      colors,
      workflow,
      active_step
    } = this.props;
    if (step) {
      return (
        <Button
          onPress={onPress}
          style={{
            borderColor: is_active ? colors.active_color : colors.border_color,
            backgroundColor: colors.card_color,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 5,
            margin: "auto",
            marginBottom: 0,
            marginTop: 0,
            width: 350
          }}
        >
          <Wrapper>
            <Wrapper
              style={{
                padding: 15,
                borderBottomColor: is_active
                  ? colors.active_color
                  : colors.border_color,
                borderBottomWidth: 1,
                borderBottomStyle: "solid"
              }}
            >
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>Trigger lead export</Bold>
                  </Copy>
                </Wrapper>
                <PopoverMenu
                  icon={"more-vert"}
                  style={{ paddingLeft: 15 }}
                  icon_size={18}
                  menu_items={[
                    {
                      title: "Delete Component",
                      icon: "delete",
                      type: "delete",
                      onPress: () => {
                        this.props.setModal({
                          title: "Delete Component?",
                          description:
                            "Are you sure you want to delete this workflow component?",
                          submit: "Delete Component",
                          icon: "delete",
                          buttonType: "destroy",
                          onPress: () => {
                            this.props.deleteStep({
                              step_index,
                              branch_index,
                              branch_step_index
                            });
                          },
                          cancel: "",
                          onCancel: () => {}
                        });
                        this.props.toggleModal({
                          show: true,
                          type: "normal"
                        });
                      }
                    }
                  ]}
                />
              </Row>
            </Wrapper>

            <Wrapper>
              {is_active ? (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Configuring...
                  </Copy>
                </Wrapper>
              ) : step.data?.data && step.data?.data.length > 0 ? (
                <Wrapper style={{ padding: 20 }}>
                  <Copy style={{ marginBottom: 10 }}>
                    Export leads meeting the following criteria:
                  </Copy>
                  <ShowcaseFilters
                    {...this.props}
                    style={{ padding: 0 }}
                    filters={step.data?.data}
                    andor_type={step.data?.andor_type}
                    editable={false}
                  />
                </Wrapper>
              ) : (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Not configured
                  </Copy>
                </Wrapper>
              )}
            </Wrapper>
          </Wrapper>
        </Button>
      );
    }
    return null;
  }
}

export default ExportComponent;
