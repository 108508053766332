import React, { Component } from "react";
import { SelectItem } from "app/NativeComponents/snippets";

import { renderDate } from "app/NativeActions";

class InviteItem extends Component {
  render() {
    return (
      <SelectItem
        onPress={this.props.onPress}
        select_type="profile_pic"
        user={this.props.member}
        subtext={"Invite sent on " + renderDate(this.props.member.date_created)}
        icon="keyboard-arrow-right"
      >
        {this.props.member.email}
      </SelectItem>
    );
  }
}

export default InviteItem;
