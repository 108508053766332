import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import { Container, Spinner } from "app/NativeComponents/common";
import { getUserFromRecoveryToken, logout } from "app/NativeActions";

const queryString = require("query-string");

class SignUpSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    //get recovery token from get param and look up user...then redirect
    const parsed = queryString.parse(this.props.location?.search);
    if (parsed.token) {
      this.props.getUserFromRecoveryToken({
        token: parsed.token,
        device: this.props.device,
        redirect: "/onboarding/add-a-lead"
      });
    } else {
      //log the user out?
      this.props.logout();
    }
  }

  render() {
    return (
      <div className="dm-app-wrapper">
        <div id="deal-signup-container">
          <Spinner />
          <iframe
            title="Sign Up Success"
            src="https://start.dealmachine.com/m/success"
          ></iframe>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, signUpMobile, device, platform } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    signUpMobile,
    device,
    platform
  };
};

export default withRouter(
  connect(mapStateToProps, { getUserFromRecoveryToken, logout })(SignUpSuccess)
);
