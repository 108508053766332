import React, { Component } from "react";

import { FeatureCTA } from "app/NativeComponents/website/common";

const FeatureCallToAction = ({ feature }) => {
  if (feature.callToAction) {
    if (feature.callToAction.fields) {
      return (
        <div className="dm-website-ctas">
          <FeatureCTA
            to={feature.callToAction.fields.buttonLink}
            slug={feature.callToAction.fields.slug}
          >
            {feature.callToAction.fields.buttonText}
          </FeatureCTA>
        </div>
      );
    }
  }

  return <div />;
};

export default FeatureCallToAction;
