import React, { PureComponent } from "react";
import {
  Wrapper,
  Copy,
  Bold,
  Button,
  Row,
  Icon
} from "app/NativeComponents/common";

class Headers extends PureComponent {
  render() {
    const {
      headers,
      sort_by,
      sort_by_dir,
      onSortBy,
      include_checkboxes,
      onCheckBoxHeaderPress,
      colors,
      selected_all,
      selected_items
    } = this.props;
    return (
      <Wrapper
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.border_color,
          borderBottomStyle: "solid"
        }}
      >
        <Row>
          {include_checkboxes ? (
            <Button
              onPress={() => onCheckBoxHeaderPress({})}
              style={{ padding: 20 }}
            >
              <Icon
                icon={
                  selected_all
                    ? "check-box"
                    : selected_items.length > 0
                    ? "indeterminate-check-box"
                    : "check-box-outline-blank"
                }
              />
            </Button>
          ) : null}
          {headers &&
            headers.map((header, i) => {
              if (header.sortable) {
                return (
                  <Button
                    style={{
                      padding: 20,
                      flex: header.main_column ? 1 : "inherit",
                      width: !header.main_column ? 120 : "auto",
                      minWidth: header.main_column ? 200 : 120
                    }}
                    onPress={() =>
                      onSortBy({
                        sort_by: header.slug,
                        sort_by_dir:
                          sort_by !== header.slug
                            ? sort_by_dir
                            : sort_by_dir == "asc"
                            ? "desc"
                            : "asc",
                        sort_by_title:
                          header.title + " " + sort_by !== header.slug
                            ? sort_by_dir
                            : sort_by_dir == "Ascending"
                            ? "Descending"
                            : "Ascending"
                      })
                    }
                  >
                    <Row>
                      <Copy style={{ marginRight: 10, fontSize: 12 }}>
                        <Bold>{header.title}</Bold>
                      </Copy>
                      <Icon
                        icon={
                          sort_by == header.slug
                            ? sort_by_dir == "asc"
                              ? "keyboard-arrow-up"
                              : "keyboard-arrow-down"
                            : null
                        }
                        fa_icon={sort_by !== header.slug ? "sort" : null}
                        size={sort_by !== header.slug ? 12 : 14}
                        color={
                          sort_by !== header.slug
                            ? "rgba(0,0,0,0.15)"
                            : colors.text_color
                        }
                      />
                    </Row>
                  </Button>
                );
              }
              return (
                <Wrapper
                  key={"header_" + i}
                  style={{
                    padding: 20,
                    flex: header.main_column ? 1 : "inherit",
                    width: !header.main_column ? 120 : "auto",
                    minWidth: header.main_column ? 200 : 120
                  }}
                >
                  <Copy style={{ fontSize: 12 }}>
                    <Bold>{header.title}</Bold>
                  </Copy>
                </Wrapper>
              );
            })}
        </Row>
      </Wrapper>
    );
  }
}

export default Headers;
