import React, { Component } from "react";

import {
  Wrapper,
  ExternalImage,
  Title,
  Copy,
  Row
} from "app/NativeComponents/common";

class Quote extends Component {
  renderImage() {
    if (this.props.image) {
      return (
        <ExternalImage
          style={{
            resizeMode: "contain",
            width: 80,
            height: 80,
            margin: 20
          }}
          contain={true}
          image={this.props.image}
          no_lazy={true}
        />
      );
    }
  }

  renderTitle() {
    if (this.props.title && this.props.title != "") {
      return (
        <Title style={{ textAlign: this.props.center ? "center" : "left" }}>
          {this.props.title}
        </Title>
      );
    }
  }

  renderCopy() {
    if (this.props.description && this.props.description != "") {
      return (
        <Copy style={{ textAlign: this.props.center ? "center" : "left" }}>
          {this.props.description}
        </Copy>
      );
    }
  }

  render() {
    if (this.props.title || this.props.description) {
      return (
        <Wrapper style={{ ...this.props.style }}>
          <Row
            style={
              this.props.center
                ? {
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }
                : {
                    flexDirection: "row"
                  }
            }
          >
            {this.renderImage()}
            <Wrapper
              style={{
                flex: this.props.center ? 0 : 1,
                ...this.props.innerStyle
              }}
            >
              {this.renderTitle()}
              {this.renderCopy()}
            </Wrapper>
          </Row>
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}
export default Quote;
