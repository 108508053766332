import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Scroll,
  Row,
  Title,
  Copy,
  InnerCopy,
  Bold,
  Spin
} from "app/NativeComponents/common";

import {
  getLeads,
  getTotalLeadsCount,
  selectActiveProperty,
  pushSidePanel,
  checkIfUserHasMetadata
} from "app/NativeActions";

import Headers from "./Headers";
import ColumnItem from "./ColumnItem";
import CheckBoxes from "./CheckBoxes";
import Pagination from "./Pagination";
import SelectAllText from "app/DealMachineCore/components/Leads/SelectAllText";
import DummyScroll from "./DummyScroll";

class NativeLeadsView extends Component {
  constructor(props) {
    super(props);

    this._scroll_view = React.createRef();
    this._header_scroll_view = React.createRef();
    this._dummy_scroll_view = React.createRef();

    this.renderColumns = this.renderColumns.bind(this);
    this.updateScrollPositions = this.updateScrollPositions.bind(this);
    this.dummyScroll = this.dummyScroll.bind(this);
    this.getHighlightedText = this.getHighlightedText.bind(this);
    this.shouldPhoneNumberBeHighlighted =
      this.shouldPhoneNumberBeHighlighted.bind(this);
    this.setHoverRow = this.setHoverRow.bind(this);

    this.state = {
      hover_row: null
    };
  }

  setHoverRow(hover_row) {
    this.setState({
      hover_row
    });
  }

  updateScrollPositions(offset, time) {
    //this._scroll_view.current.scrollLeft = offset;
    this._header_scroll_view.current.scrollLeft = offset;
    this._dummy_scroll_view.current.scrollLeft = offset;
  }

  dummyScroll(offset, time) {
    //this._scroll_view.current.scrollLeft = offset;
    this._header_scroll_view.current.scrollLeft = offset;
    this._scroll_view.current.scrollLeft = offset;
  }

  componentDidUpdate(prevProps, prevState) {}

  renderColumns({
    renderItem = ({ key }) => {
      return <Wrapper key={key} />;
    }
  }) {
    const { list_settings, list_properties, phone_column_widths } = this.props;

    let max_phone_numbers = 0;
    max_phone_numbers = Math.max.apply(
      Math,
      list_properties.map(({ phone_numbers, deal }) =>
        deal?.removed != 1 && deal?.phone_numbers
          ? deal?.phone_numbers?.length + phone_numbers?.length
          : phone_numbers?.length
      )
    );

    const initialPhoneWidth = phone_column_widths.find(
      pcw => pcw.slug === "phone_number_0"
    );
    let phone_data_columns = max_phone_numbers
      ? []
      : [
          {
            slug: "phone_number_0",
            format: "phone_numbers",
            title: `Phone Numbers`,
            index: -1,
            width: initialPhoneWidth ? initialPhoneWidth.width : 150
          }
        ];

    return list_settings.user_list_columns.flatMap((column, i) => {
      switch (column.slug) {
        default:
          return renderItem({
            key: "column_" + column.slug + "_" + i,
            item: column
          });

        case "phone_numbers":
          if (column.show) {
            for (let j = 0; j < max_phone_numbers; j++) {
              const slug = `phone_number_${j + 1}`;
              const phoneColumnWidth = phone_column_widths.find(
                pcw => pcw.slug === slug
              );
              const width = phoneColumnWidth ? phoneColumnWidth.width : 150;
              phone_data_columns = [
                ...phone_data_columns,
                {
                  slug,
                  format: "phone_numbers",
                  title: `Phone ${j + 1}`,
                  index: j,
                  width
                }
              ];
            }

            return phone_data_columns.map((col, k) => {
              return renderItem({
                key: "column_" + col.slug + "_" + k,
                item: {
                  card_column: false,
                  deal: true,
                  show: true,
                  sort_by: false,
                  format: col.format,
                  slug: col.slug,
                  title: col.title,
                  column_format: "split",
                  column_format_index: col.index,
                  width: col.width
                }
              });
            });
          }
      }
    });
  }

  renderLoading(show_loading = false) {
    const { list_properties_loading, dark_mode } = this.props;
    if (list_properties_loading || show_loading) {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor:
              dark_mode == "dark_mode"
                ? "rgba(0,0,0, 0.4)"
                : "rgba(255, 255, 255, 0.75)"
          }}
        >
          <Spin text="Loading Leads..." />
        </Wrapper>
      );
    }
  }

  shouldPhoneNumberBeHighlighted(phonenumber) {
    const { search } = this.props;
    if (!!phonenumber && !!search) {
      let number_search = search.replace(/\D/g, "");
      let phonenumber_number = phonenumber.replace(/\D/g, "");

      if (number_search.length > 0 && phonenumber_number.length > 0) {
        if (phonenumber_number.includes(number_search)) {
          return true;
        }
      }
    }
    return false;
  }

  getHighlightedText(text) {
    const { search, colors } = this.props;
    // Split text on highlight term, include term itself into parts, ignore case
    if (!!text) {
      const parts = text.split(new RegExp(`(${search})`, "gi"));
      return (
        <InnerCopy>
          {parts.map(part =>
            part.toLowerCase() === search.toLowerCase() ? (
              //create random key
              <Bold
                key={Math.random().toString(36).substring(2, 15)}
                style={{ backgroundColor: colors.active_color_muted }}
              >
                {part}
              </Bold>
            ) : (
              part
            )
          )}
        </InnerCopy>
      );
    }
    return text;
  }

  render() {
    const {
      list_settings,
      list_properties,
      list_properties_loading,

      renderTopLabel = null
    } = this.props;

    if (list_settings && list_settings?.user_list_columns.length > 0) {
      return (
        <>
          <SelectAllText
            {...this.props}
            selected_leads={this.props.selected_leads}
            selected_all={this.props.selected_all}
            checkAllLeads={this.props.checkAllLeads}
            clearAll={this.props.clearAll}
            filtered_count={this.props.filtered_count}
            filtered_count_loading={this.props.filtered_count_loading}
          />
          {renderTopLabel ? renderTopLabel() : null}
          <Wrapper
            style={{ flex: 1, overflow: "hidden", position: "relative" }}
          >
            <>
              {
                <Headers
                  {...this.props}
                  list_settings={list_settings}
                  _header_scroll_view={this._header_scroll_view}
                  _scroll_view={this._scroll_view}
                  updateScrollPositions={this.updateScrollPositions}
                  renderColumns={this.renderColumns}
                />
              }

              {list_properties.length === 0 && !list_properties_loading ? (
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 25
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    <Bold>No leads found.</Bold>
                  </Copy>
                  <Copy style={{ textAlign: "center" }}>
                    There are no leads matching your criteria.
                  </Copy>
                </Wrapper>
              ) : null}

              <Scroll
                style={{
                  flex: 1,
                  overflow: "auto",
                  height: "100%"
                }}
              >
                <Row
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    overflow: "hidden"
                  }}
                >
                  <CheckBoxes
                    {...this.props}
                    hover_row={this.state.hover_row}
                    setHoverRow={this.setHoverRow}
                  />
                  <ColumnItem
                    {...this.props}
                    section={{
                      slug: "list_stack",
                      card_column: false,
                      deal: true,
                      show: true,
                      sort_by: false,
                      format: "list_stack",
                      title: "List Stack"
                    }}
                    pressProperty={this.props.pressProperty}
                    hover_row={this.state.hover_row}
                    setHoverRow={this.setHoverRow}
                  />

                  {list_settings.user_list_columns.map((column, i) => {
                    if (column.main_column) {
                      return (
                        <ColumnItem
                          {...this.props}
                          key={"column_main_" + i}
                          section={column}
                          pressProperty={this.props.pressProperty}
                          getHighlightedText={this.getHighlightedText}
                          shouldPhoneNumberBeHighlighted={
                            this.shouldPhoneNumberBeHighlighted
                          }
                          hover_row={this.state.hover_row}
                          setHoverRow={this.setHoverRow}
                        />
                      );
                    }
                    return null;
                  })}
                  <Scroll
                    className={"hide_scroll_bar"}
                    scroll_ref={this._scroll_view}
                    id={"scrollCols"}
                    horizontal
                    style={{
                      scrollBehavior: "initial"
                    }}
                    onScroll={e => {
                      requestAnimationFrame(() => {
                        this.updateScrollPositions(
                          this._scroll_view.current.scrollLeft
                        );
                      });
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start"
                      }}
                    >
                      {this.renderColumns({
                        renderItem: ({ key, item }) => {
                          if (!item.main_column && item.show) {
                            return (
                              <ColumnItem
                                {...this.props}
                                key={"column_" + key}
                                section={item}
                                pressProperty={this.props.pressProperty}
                                getHighlightedText={this.getHighlightedText}
                                shouldPhoneNumberBeHighlighted={
                                  this.shouldPhoneNumberBeHighlighted
                                }
                                hover_row={this.state.hover_row}
                                setHoverRow={this.setHoverRow}
                              />
                            );
                          }
                          return null;
                        }
                      })}
                    </Row>
                  </Scroll>
                </Row>
              </Scroll>
              {
                <DummyScroll
                  {...this.props}
                  list_settings={list_settings}
                  _dummy_scroll_view={this._dummy_scroll_view}
                  _header_scroll_view={this._header_scroll_view}
                  _scroll_view={this._scroll_view}
                  dummyScroll={this.dummyScroll}
                  renderColumns={this.renderColumns}
                />
              }

              <Pagination
                {...this.props}
                filtered_count={this.props.filtered_count}
              />
              {this.renderLoading()}
            </>
          </Wrapper>
        </>
      );
    }

    return this.renderLoading(true);
  }
}

const mapStateToProps = ({
  auth,
  native,
  drawer,
  settings,
  lead,
  property_map,
  filter,
  feature_toggle
}) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const {
    list_properties,
    list_properties_loading,
    list_properties_error,
    list_properties_page,
    list_properties_begin,
    list_properties_limit,
    total_lead_count,
    total_lead_count_loading,
    list_properties_refreshing,
    list_properties_loaded_all,
    toggle_lead_images
  } = lead;

  const { active_property } = property_map;
  const { sort_by, phone_column_widths, list_settings, applied_filter } =
    filter;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    list_properties,
    list_properties_loading,
    list_properties_refreshing,
    list_properties_loaded_all,
    list_properties_error,
    list_properties_page,
    list_properties_begin,
    list_properties_limit,
    toggle_lead_images,
    total_lead_count,
    total_lead_count_loading,
    sort_by,
    list_settings,
    phone_column_widths,
    applied_filter,
    active_property
  };
};

export default connect(mapStateToProps, {
  getLeads,
  getTotalLeadsCount,
  selectActiveProperty,

  pushSidePanel
})(NativeLeadsView);
