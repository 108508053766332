import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, HTMLMarkdown } from "app/NativeComponents/common";

import { appRedirect, getTeamLinkInfo, startRedirect } from "app/NativeActions";

import {
  Page,
  Loading,
  Section,
  ColorOverlay,
  Diangle,
  SectionInner,
  SectionContainer,
  Header1,
  Header2,
  Header3,
  Header4
} from "app/NativeComponents/website/common";

import {
  WebpageSection,
  HeaderNavigation,
  LandingPageFooter,
  Sections
} from "app/NativeComponents/website/snippets";

import { Link } from "react-router-dom";
import Register from "app/DealMachineCore/components/Register";

import GreetingVideo from "./GreetingVideo";

const queryString = require("query-string");

class DealFinderPage extends Component {
  componentDidMount() {
    if (
      this.props.match.params.team_link &&
      this.props.match.params.team_link != ""
    ) {
      //get team link info
      this.props.getTeamLinkInfo(this.props.match.params.team_link);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.team_link != prevProps.match.params.team_link) {
      if (
        this.props.match.params.team_link &&
        this.props.match.params.team_link != ""
      ) {
        //get team link info
        this.props.getTeamLinkInfo(this.props.match.params.team_link);
      }
    }

    if (this.props.team_link_info != prevProps.team_link_info) {
      if (this.props.team_link_info.live_page != null) {
        //get team link info
        const parsed = queryString.parse(window.location.search);
        if (
          this.props.team_link_info.live_page == "off" &&
          parsed.preview != "true"
        ) {
          this.props.startRedirect(
            "https://www.dealmachine.com/page-not-found"
          );
        }
      }
    }
  }

  render() {
    if (!this.props.team_link_loading && this.props.team_link_info) {
      return (
        <Page
          id={"dealfinder-page"}
          responsive_design={true}
          metaTitle={
            this.props.team_link_info.header
              ? this.props.team_link_info.header +
                " | DealMachine for Real Estate"
              : "Join My Team | DealMachine for Real Estate"
          }
          metaDescription={
            this.props.team_link_info.description
              ? this.props.team_link_info.description.replace(
                  /(<([^>]+)>)/gi,
                  ""
                )
              : "Making Extra Money In Your spare Time is Easy. Anytime you drive, bike, walk or run by a distressed property, just send the picture and address of it with your smartphone using DealMachine. It's that easy."
          }
          pagePath={"team/" + this.props.match.params.team_link}
        >
          <HeaderNavigation navBarStyle={"LandingPageDark"} />

          <Section type={"HeaderSection"} media={this.props.featuredImage}>
            <ColorOverlay type={"Blue"} has_media={true}>
              <SectionInner>
                <SectionContainer>
                  <Header1>
                    <b>{this.props.team_link_info.header}</b>
                  </Header1>
                  <Header4>{this.props.team_link_info.subheader}</Header4>
                  <div
                    className="dm-website-logos dm-website-logos-centered"
                    style={{
                      padding: "40px 0px 70px"
                    }}
                  >
                    <img src="/assets/images/forbes.svg" alt="Forbes" />
                    <img src="/assets/images/inman.svg" alt="Inman" />
                    <img
                      src="/assets/images/biggerpockets.svg"
                      alt="BiggerPockets"
                    />
                    <img
                      src="/assets/images/wsj.svg"
                      alt="Wall Street Journal"
                    />
                  </div>
                </SectionContainer>
              </SectionInner>
            </ColorOverlay>
            <Diangle type={"Bottom"} />
          </Section>

          <Section>
            <ColorOverlay type={"White"} has_media={false}>
              <SectionInner>
                <GreetingVideo {...this.props} />

                <div
                  className={
                    this.props.team_link_info.greeting_video &&
                    this.props.team_link_info.greeting_video != ""
                      ? "dm-website-offer-container dm-website-offer-video-container"
                      : "dm-website-offer-container"
                  }
                >
                  <div className="dm-website-offer-content">
                    <HTMLMarkdown
                      source={this.props.team_link_info.description}
                      escapeHtml={false}
                    />
                  </div>
                  <div className="dm-website-offer-form">
                    <div
                      style={{
                        marginLeft: -20,
                        marginRight: -20
                      }}
                    >
                      <div className="dm-website-card dm-website-form-card dealfinder">
                        <div className="dm-website-card-body">
                          <Header3>{this.props.team_link_info.cta}</Header3>
                          <Register
                            buttonText={"Get Started"}
                            no_container
                            no_redirect
                            dealfinder
                            dealfinder_team_id={
                              this.props.team_link_info.team_id
                            }
                          />
                          {/*
                           <div className="dm-website-terms-text">
                             <p>By signing up, you agree to our <Link to="/terms-of-service">Terms of Service.</Link></p>
                           </div>
                           */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SectionInner>
            </ColorOverlay>
          </Section>

          <Section>
            <ColorOverlay type={"White"} has_media={false}>
              <SectionInner>
                <div
                  style={{
                    maxWidth: 500,
                    margin: "0px auto"
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      lineHeight: "20px"
                    }}
                  >
                    Contact:{" "}
                    <a href={"tel:" + this.props.team_link_info.phone}>
                      {this.props.team_link_info.phone}
                    </a>
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      lineHeight: "20px"
                    }}
                  >
                    This DealMachine Site is hosted for{" "}
                    <b>{this.props.team_link_info.company}</b>. This job
                    opportunity is provided to you by{" "}
                    <b>{this.props.team_link_info.company}</b>.{" "}
                    <b>{this.props.team_link_info.company}</b> is responsible
                    for your compensation based on their criteria and payment
                    terms. <b>{this.props.team_link_info.company}</b> owns all
                    of the property data you send to them via DealMachine.
                  </p>
                </div>
              </SectionInner>
            </ColorOverlay>
          </Section>

          <Sections {...this.props} />
          <LandingPageFooter {...this.props} />
        </Page>
      );
    }

    return <Loading />;
  }
}

const mapStateToProps = ({ auth, native, team_link }) => {
  const { token, user } = auth;
  const { device } = native;
  const { team_link_loading, team_link_info } = team_link;
  return {
    token,
    user,
    device,

    team_link_loading,
    team_link_info
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getTeamLinkInfo,
  startRedirect
})(DealFinderPage);
