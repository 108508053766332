import React from "react";

import { Icon } from "app/NativeComponents/common";

const ToggleButton = props => {
  if (props.visible) {
    return (
      <button className="dm-website-toggle-button" onClick={props.onClick}>
        <Icon icon="close" color="#31cce5" size={32} />
      </button>
    );
  }
  return (
    <button className="dm-website-toggle-button" onClick={props.onClick}>
      <Icon icon="menu" color="#fff" size={32} />
    </button>
  );
};

export default ToggleButton;
