import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Bold,
  Container,
  Copy,
  Page,
  Row,
  Spinner,
  Wrapper
} from "app/NativeComponents/common";
import {
  getUserFromEmailToken,
  loadOnboardingText,
  logMarketingEvent,
  logout,
  startRedirect,
  AppConfig,
  manageSubscription,
  tabNavigation,
  billingFieldChanged,
  stripeCardAction,
  getUser,
  getSourceOfTruth
} from "app/NativeActions";
import { Header1, Header3 } from "app/NativeComponents/common";
import DisplayPlans from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/DisplayPlans";
import DisplayAddons from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/DisplayAddons";
import {
  GhostButton,
  InlineButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";
import AcceptOffer from "app/DealMachineCore/MarketingOnboardingAndFunnel/GlobalSnippets/AcceptOffer";
import StripeForm from "app/NativeComponents/components/StripeForm";

class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      contentful_content: null,
      contentful_loading: true,
      plan_interval: "month",
      selected_list_builder: false,
      success: false,
      hide_annual_toggle: false
    };

    this.saveCard = this.saveCard.bind(this);
  }

  componentDidMount() {
    const email_token = this.props.match?.params?.email_token;
    const offer_slug = this.props.match?.params?.offer_slug;

    //get recovery token from get param and look up user...then redirect
    if (!!email_token) {
      this.props.getUserFromEmailToken({
        token: email_token,
        type: offer_slug,
        device: this.props.device,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.props.startRedirect(AppConfig().app_url + "sign-up");
        },
        onSuccess: result => {
          this.setState({ loading: false });
          if (!!offer_slug) {
            this.loadData(offer_slug);
          }
        }
      });
    } else {
      const token = window.localStorage.getItem("token");
      if (token != null) {
        if (!this.props.user) {
          this.props.getUser({
            token,
            device: this.props.device,
            initialRedirect: this.props.initialRedirect,
            onLoading: () => {
              this.setState({ loading: true });
            },
            onError: () => {
              this.props.startRedirect(AppConfig().app_url + "sign-up");
            },
            onSuccess: () => {
              this.setState({ loading: false });
              if (!!offer_slug) {
                this.loadData(offer_slug);
              }
            }
          });
          this.props.getSourceOfTruth({ token });
        }
      } else {
        this.props.startRedirect(AppConfig().app_url + "sign-up");
      }
    }
  }

  saveCard(card_token, error) {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    const { token, card, platform } = this.props;
    const { address } = this.state;
    if (card_token || error) {
      this.props.stripeCardAction({
        token: token,
        card_token: card_token,
        error: error,
        type: "add_stripe_payment_method",
        onLoading: () => {
          this.setState({ card_loading: true });
        },
        onError: () => {
          this.setState({ card_loading: false });
        },
        onSuccess: () => {
          this.setState({ card_loading: false, success: true });
        }
      });
    } else {
      const { number, expMonth, expYear, cvc } = card;
      this.props.stripeCardAction({
        token: token,
        number,
        expMonth,
        expYear,
        cvc,
        type: "add_stripe_payment_method",
        attempt_payment: true,
        onLoading: () => {
          this.setState({ card_loading: true });
        },
        onError: () => {
          this.setState({ card_loading: false });
        },
        onSuccess: () => {
          this.setState({ card_loading: false, success: true });
        }
      });
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            const marketing_slug = data.items[0].fields?.marketing_slug;
            const content_type = data.items[0].fields?.content_type;
            const annual_toggle = data.items[0].fields?.annual_toggle;
            let render_content = true;
            switch (content_type) {
              case "start_trial_offer":
                if (this.props.user?.finished_onboarding == 1) {
                  this.props.startRedirect(AppConfig().app_url + "sign-up");
                  render_content = false;
                }
                break;
              case "completed_trial_offer":
              case "completed_trial_offer_annual":
                if (this.props.user?.did_pay_for_subscription) {
                  this.props.startRedirect(AppConfig().app_url + "sign-up");
                  render_content = false;
                }
                break;
              case "reactivation_offer":
                break;

              default:
                break;
            }

            if (render_content) {
              this.setState({
                contentful_content: data.items[0]
                  ? data.items[0].fields
                    ? data.items[0].fields
                    : null
                  : null,
                contentful_loading: false,
                plan_interval:
                  annual_toggle === "inherit"
                    ? !!this.props.source_of_truth?.subscription?.interval
                      ? this.props.source_of_truth?.subscription?.interval
                      : this.state.plan_interval
                    : !!annual_toggle && annual_toggle !== "none"
                    ? annual_toggle === "year_only" || annual_toggle === "year"
                      ? "year"
                      : annual_toggle === "month_only" ||
                        annual_toggle === "month"
                      ? "month"
                      : this.state.plan_interval
                    : this.state.plan_interval,
                hide_annual_toggle:
                  annual_toggle === "year_only" ||
                  annual_toggle === "month_only"
                    ? true
                    : false
              });

              if (!!marketing_slug) {
                logMarketingEvent({ slug: marketing_slug });
              }
            }
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, contentful_loading, plan_interval } =
      this.state;
    const { colors, desktopMobile, device } = this.props;

    let success_content = null;
    if (
      contentful_content?.contentItems2 &&
      contentful_content?.contentItems2.length > 0
    ) {
      success_content = contentful_content?.contentItems2[0]?.fields;
    }

    if (this.state.loading || contentful_loading) {
      return (
        <OnboardingWrapper>
          <Spinner />
        </OnboardingWrapper>
      );
    }

    if (contentful_content) {
      if (this.state.success && success_content) {
        return (
          <Page
            metaTitle={
              success_content?.title + " - DealMachine for Real Estate"
            }
            responsive_design={true}
          >
            <OnboardingWrapper>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 25
                }}
              >
                <Header1
                  style={{
                    textAlign: "center"
                  }}
                >
                  <Bold>{success_content?.title}</Bold>
                </Header1>
                <Header3
                  style={{
                    textAlign: "center"
                  }}
                >
                  {success_content?.subtitle}
                </Header3>
              </Wrapper>

              {!!success_content?.description ? (
                <Wrapper
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 25,
                    paddingTop: 0
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    {success_content?.description}
                  </Copy>
                </Wrapper>
              ) : null}
              <Wrapper
                style={{
                  padding: 25,
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 0
                }}
              >
                <GhostButton
                  primary={true}
                  button_type={"full"}
                  loading={this.state.completing}
                  onPress={() => {
                    this.props.tabNavigation({ slug: "map" });
                  }}
                  icon_right={"arrow-right-alt"}
                >
                  Take Me To My Account
                </GhostButton>
              </Wrapper>
            </OnboardingWrapper>
          </Page>
        );
      }

      switch (contentful_content.content_type) {
        case "failed_payment":
          return (
            <Page
              metaTitle={
                contentful_content?.title + " - DealMachine for Real Estate"
              }
              responsive_design={true}
            >
              <OnboardingWrapper>
                {this.state.contentful_content ? (
                  <>
                    <Wrapper
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: 25
                      }}
                    >
                      <Header1
                        style={{
                          textAlign: "center"
                        }}
                      >
                        <Bold>{contentful_content?.title}</Bold>
                      </Header1>
                      <Header3
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {contentful_content?.subtitle}
                      </Header3>
                    </Wrapper>

                    <Wrapper
                      style={{
                        alignSelf: "stretch",
                        flex: 1,
                        width: "100%",
                        maxWidth: 700,
                        margin: "0px auto"
                      }}
                    >
                      <StripeForm
                        {...this.props}
                        loading={this.props.card_loading}
                        buttonText={"Save Payment Method"}
                        saveBilling={this.saveCard}
                        card={this.props.card}
                        billingFieldChanged={this.props.billingFieldChanged}
                      />
                    </Wrapper>

                    {!!contentful_content?.description ? (
                      <Wrapper
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 25
                        }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          {contentful_content?.description}
                        </Copy>
                      </Wrapper>
                    ) : null}
                  </>
                ) : null}
              </OnboardingWrapper>
            </Page>
          );

        case "custom_offer":
        case "complete_trial_offer":
        case "complete_trial_offer_annual":
        case "marketing_credit_offer":
        case "annual_offer":
        case "reactivation_offer":
          return (
            <Page
              metaTitle={
                contentful_content?.title + " - DealMachine for Real Estate"
              }
              responsive_design={true}
            >
              <OnboardingWrapper>
                {this.state.contentful_content ? (
                  <>
                    <Wrapper
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: 25
                      }}
                    >
                      <Header1
                        style={{
                          textAlign: "center"
                        }}
                      >
                        <Bold>{contentful_content?.title}</Bold>
                      </Header1>
                      <Header3
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {contentful_content?.subtitle}
                      </Header3>
                    </Wrapper>

                    {!!contentful_content.annual_toggle &&
                    contentful_content.annual_toggle !== "none" &&
                    !this.state.hide_annual_toggle ? (
                      <Wrapper
                        style={{
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <InlineButton
                          iconColor={
                            plan_interval == "year"
                              ? colors.success_color
                              : null
                          }
                          marketing_slug={
                            this.state.plan_interval == "year"
                              ? "unselected_annual_on_plan_selection"
                              : "selected_annual_on_plan_selection"
                          }
                          icon={
                            plan_interval == "year" ? "toggle-on" : "toggle-off"
                          }
                          style={{
                            backgroundColor:
                              plan_interval == "month"
                                ? colors.orange_color_muted
                                : colors.success_color_muted
                          }}
                          onPress={() => {
                            this.setState({
                              plan_interval:
                                plan_interval == "month" ? "year" : "month"
                            });
                          }}
                        >
                          Save 17% with annual
                        </InlineButton>
                      </Wrapper>
                    ) : null}

                    <Wrapper style={{ alignSelf: "stretch" }}>
                      <Row
                        style={
                          !desktopMobile
                            ? {
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap"
                              }
                            : {
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                              }
                        }
                      >
                        {contentful_content?.contentItems &&
                          contentful_content?.contentItems.map((item, i) => {
                            const plan = item?.fields;
                            if (plan?.featured && desktopMobile) {
                              return (
                                <>
                                  <AcceptOffer
                                    key={"plan_" + i}
                                    colors={colors}
                                    desktopMobile={desktopMobile}
                                    device={device}
                                    marketing_slug={plan?.marketing_event_slug}
                                    token={this.props.token}
                                    title={plan?.title}
                                    stripe_price_id_month={
                                      plan?.stripePriceIdMonthly
                                    }
                                    stripe_price_id_year={
                                      plan?.stripePriceIdYearly
                                    }
                                    featured={plan?.featured}
                                    price={plan?.monthPrice}
                                    year_price={plan?.yearPrice}
                                    plan_interval={this.state.plan_interval}
                                    buttonText={plan?.buttonText}
                                    includes_items={
                                      plan?.includesItems
                                        ? plan.includesItems
                                        : null
                                    }
                                    description={plan?.description}
                                    subtext={plan?.subtext}
                                    manageSubscription={
                                      this.props.manageSubscription
                                    }
                                    offer_type={plan?.offer_type}
                                    offer_id={plan?.offer_id}
                                    onSuccess={() => {
                                      this.setState({ success: true });
                                    }}
                                  />
                                </>
                              );
                            }
                            return null;
                          })}

                        {contentful_content?.contentItems &&
                          contentful_content?.contentItems.map((item, i) => {
                            const plan = item?.fields;
                            if (!plan?.featured || !desktopMobile) {
                              return (
                                <AcceptOffer
                                  key={"plan_" + i}
                                  colors={colors}
                                  desktopMobile={desktopMobile}
                                  device={device}
                                  marketing_slug={plan?.marketing_event_slug}
                                  token={this.props.token}
                                  title={plan?.title}
                                  stripe_price_id_month={
                                    plan?.stripePriceIdMonthly
                                  }
                                  stripe_price_id_year={
                                    plan?.stripePriceIdYearly
                                  }
                                  featured={plan?.featured}
                                  price={plan?.monthPrice}
                                  year_price={plan?.yearPrice}
                                  plan_interval={this.state.plan_interval}
                                  buttonText={plan?.buttonText}
                                  includes_items={
                                    plan?.includesItems
                                      ? plan.includesItems
                                      : null
                                  }
                                  description={plan?.description}
                                  subtext={plan?.subtext}
                                  manageSubscription={
                                    this.props.manageSubscription
                                  }
                                  offer_type={plan?.offer_type}
                                  offer_id={plan?.offer_id}
                                  onSuccess={() => {
                                    this.setState({ success: true });
                                  }}
                                />
                              );
                            }
                            return null;
                          })}
                      </Row>
                    </Wrapper>

                    {!!contentful_content?.description ? (
                      <Wrapper
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 25
                        }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          {contentful_content?.description}
                        </Copy>
                      </Wrapper>
                    ) : null}
                  </>
                ) : null}
              </OnboardingWrapper>
            </Page>
          );

        case "upsell_offer":
        case "is_canceling_offer":
          return (
            <Page
              metaTitle={
                contentful_content?.title + " - DealMachine for Real Estate"
              }
              responsive_design={true}
            >
              <OnboardingWrapper>
                {this.state.contentful_content ? (
                  <>
                    <Wrapper
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: 25
                      }}
                    >
                      <Header1
                        style={{
                          textAlign: "center"
                        }}
                      >
                        <Bold>{contentful_content?.title}</Bold>
                      </Header1>
                      <Header3
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {contentful_content?.subtitle}
                      </Header3>
                    </Wrapper>
                    {!!contentful_content.annual_toggle &&
                    contentful_content.annual_toggle !== "none" &&
                    !this.state.hide_annual_toggle ? (
                      <Wrapper
                        style={{
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <InlineButton
                          iconColor={
                            plan_interval == "year"
                              ? colors.success_color
                              : null
                          }
                          marketing_slug={
                            this.state.plan_interval == "year"
                              ? "unselected_annual_on_plan_selection"
                              : "selected_annual_on_plan_selection"
                          }
                          icon={
                            plan_interval == "year" ? "toggle-on" : "toggle-off"
                          }
                          style={{
                            backgroundColor:
                              plan_interval == "month"
                                ? colors.orange_color_muted
                                : colors.success_color_muted
                          }}
                          onPress={() => {
                            this.setState({
                              plan_interval:
                                plan_interval == "month" ? "year" : "month"
                            });
                          }}
                        >
                          Save 17% with annual
                        </InlineButton>
                      </Wrapper>
                    ) : null}
                    <DisplayAddons
                      contentful_content={this.state.contentful_content}
                      plan_interval={plan_interval}
                      manageSubscription={this.props.manageSubscription}
                      onSuccess={() => {
                        this.setState({ success: true });
                      }}
                    />

                    {!!contentful_content?.description ? (
                      <Wrapper
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 25
                        }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          {contentful_content?.description}
                        </Copy>
                      </Wrapper>
                    ) : null}
                  </>
                ) : null}
              </OnboardingWrapper>
            </Page>
          );

        case "start_trial_offer":
          return (
            <Page
              metaTitle={
                contentful_content?.title + " - DealMachine for Real Estate"
              }
              responsive_design={true}
            >
              <OnboardingWrapper>
                {this.state.contentful_content ? (
                  <>
                    <Wrapper
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: 25
                      }}
                    >
                      <Header1
                        style={{
                          textAlign: "center"
                        }}
                      >
                        <Bold>{contentful_content?.title}</Bold>
                      </Header1>
                      <Header3
                        style={{
                          textAlign: "center"
                        }}
                      >
                        {contentful_content?.subtitle}
                      </Header3>
                    </Wrapper>
                    {!this.state.hide_annual_toggle ? (
                      <Wrapper
                        style={{
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <InlineButton
                          iconColor={
                            plan_interval == "year"
                              ? colors.success_color
                              : null
                          }
                          marketing_slug={
                            this.state.plan_interval == "year"
                              ? "unselected_annual_on_plan_selection"
                              : "selected_annual_on_plan_selection"
                          }
                          icon={
                            plan_interval == "year" ? "toggle-on" : "toggle-off"
                          }
                          style={{
                            backgroundColor:
                              plan_interval == "month"
                                ? colors.orange_color_muted
                                : colors.success_color_muted
                          }}
                          onPress={() => {
                            this.setState({
                              plan_interval:
                                plan_interval == "month" ? "year" : "month"
                            });
                          }}
                        >
                          Save 17% with annual
                        </InlineButton>
                      </Wrapper>
                    ) : null}
                    <DisplayPlans
                      contentful_content={this.state.contentful_content}
                      selected_list_builder={this.state.selected_list_builder}
                      needs_lists={false}
                      plan_interval={plan_interval}
                      onSelectListBuilder={selected_list_builder => {
                        this.setState({
                          selected_list_builder
                        });
                      }}
                    />

                    {!!contentful_content?.description ? (
                      <Wrapper
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 25
                        }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          {contentful_content?.description}
                        </Copy>
                      </Wrapper>
                    ) : null}
                  </>
                ) : null}
              </OnboardingWrapper>
            </Page>
          );
        default:
          return null;
      }
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;
  const { source_of_truth, card } = billing;
  const { colors } = settings;
  const { isMobile, signUpMobile, device, platform } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    signUpMobile,
    device,
    platform,
    source_of_truth,
    card
  };
};

export default connect(mapStateToProps, {
  getUserFromEmailToken,
  logout,
  startRedirect,
  manageSubscription,
  tabNavigation,
  billingFieldChanged,
  stripeCardAction,
  getUser,
  getSourceOfTruth
})(Offers);
