import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row,
  MultiLineInput
} from "app/NativeComponents/common";
import {
  GhostButton,
  SelectItem,
  InlineButton
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription,
  renderDate,
    logout
} from "app/NativeActions";

class AnythingElse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      marketing_offer_loading: false
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/cancel/anything-else");
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.accepted_an_offer) {
      return (
        <>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <Header1
              style={{
                textAlign: "center"
              }}
            >
              <Bold>We're sad to see you go.</Bold>
            </Header1>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              You've completed canceling your subscription. You'll have access
              to your account until{" "}
              {renderDate(
                this.props.source_of_truth?.subscription?.cancel_at,
                true
              )}
            </Header3>
          </Wrapper>

          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 0
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              loading={this.state.completing}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.tabNavigation({ slug: "leads" });
              }}
              icon_right={"arrow-right-alt"}
            >
              Take Me To My Account
            </GhostButton>
            <InlineButton
              button_type={"full"}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.logout();
              }}
            >
              Log me out.
            </InlineButton>
          </Wrapper>
        </>
      );
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>We'll miss you.</Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              If there is anything else, we're listening...
            </Header3>
          </Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
              maxWidth: 550,
              width: "100%",
              margin: this.props.device == "desktop" ? "0px auto" : 0
            }}
          >
            <MultiLineInput
              ref="code"
              name="code"
              blurOnSubmit={true}
              returnKeyType="done"
              keyboardType="default"
              placeholder={"Enter your feedback here..."}
              label={
                this.props.device == "desktop"
                  ? ""
                  : "We appriciate your feedback."
              }
              style={{
                alignSelf: "stretch",
                padding: 25,
                margin: 5,
                borderRadius: 5,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: this.props.colors?.border_color
              }}
              onChange={value =>
                this.setState({
                  canceling_anything_else: value
                })
              }
              value={this.state.canceling_anything_else}
              type={"text"}
            />

            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              loading={this.state.canceling_loading}
              icon_right={"arrow-right-alt"}
              onPress={() => {
                if (!!this.state.canceling_anything_else) {
                  this.props.submitAnswer({
                    property_slug: "canceling_anything_else",
                    property_value: this.state.canceling_anything_else
                  });
                }

                this.props.manageSubscription({
                  token: this.props.token,
                  type: "cancel_all_plans",
                  stripe_sub_id:
                    this.props.source_of_truth?.subscription?.stripe_id,
                  no_messaging: true,
                  onLoading: () => {
                    this.setState({
                      canceling_loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      canceling_loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        canceling_loading: false
                      },
                      () => {
                        this.props.acceptedAnOffer();
                      }
                    );
                  }
                });
              }}
            >
              Confirm Cancelation
            </GhostButton>

            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.tabNavigation({ slug: "leads" });
                this.props.toggleChurnBuster(false);
              }}
            >
              Nevermind, I don't want to cancel.
            </InlineButton>
            <Wrapper style={{ height: 40 }} />
          </Wrapper>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    desktopMobile,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription,
    logout
})(AnythingElse);
