import React, { Component } from "react";
import { Copy, Bold, Wrapper, KeyboardView } from "app/NativeComponents/common";
import {
  SmallHeader,
  SearchBar,
  InlineButton,
  List,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  dismissMobileKeyboard,
  checkIfUserHasMetadata
} from "app/NativeActions";

class SearchObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search_object: ""
    };

    this.getTotalCount = this.getTotalCount.bind(this);

    this.renderSearchObjectItem = this.renderSearchObjectItem.bind(this);
  }

  getTotalCount(group_slug) {
    //get the total number of items in the this.props.enrollment_object that match the group_slug
    let total = 0;
    let objects = Object.keys(
      this.filterObject(this.props.enrollment_object?.data)
    );
    for (let i = 0; i < objects.length; i++) {
      let object_item = objects[i];

      if (
        this.props.enrollment_object.data[object_item].group_slug === group_slug
      ) {
        total++;
      }
    }

    return total;
  }

  filterObject(object) {
    const { search_object } = this.state;
    const { include_lead_info, use_list_builder_info } = this.props;
    let filtered_object = {};
    for (const [key, value] of Object.entries(object)) {
      if (!!value.label && value.filter) {
        if (
          (value.label?.toLowerCase().indexOf(search_object?.toLowerCase()) !=
            -1 ||
            value.group_title
              ?.toLowerCase()
              .indexOf(search_object?.toLowerCase()) != -1) &&
          (include_lead_info || !value.lead_data) &&
          (!use_list_builder_info || value.list_builder_data)
        ) {
          filtered_object[key] = value;
        }
      }
    }
    return filtered_object;
  }

  renderSearchObjectItem(item, index) {
    const { enrollment_object, selected_filter } = this.props;

    const { search_object } = this.state;
    let group_titles = [];
    if (enrollment_object && enrollment_object.data) {
      let objects = Object.keys(this.filterObject(enrollment_object?.data));
      for (let i = 0; i < objects.length; i++) {
        let object_item = objects[i];

        let found_item = false;
        for (let j = 0; j < group_titles.length; j++) {
          if (
            group_titles[j].group_slug ==
            enrollment_object.data[object_item].group_slug
          ) {
            found_item = true;
          }
        }
        if (!found_item) {
          group_titles.push({
            group_slug: enrollment_object.data[object_item].group_slug,
            item: object_item
          });
        }
      }
    }

    const is_selected = item === selected_filter ? true : false;
    if (enrollment_object.data[item] && enrollment_object.data[item].filter) {
      let show_label = false;
      for (let j = 0; j < group_titles.length; j++) {
        if (group_titles[j].item == item) {
          show_label = true;
        }
      }

      return (
        <Wrapper key={"trigger_" + index}>
          {show_label && !search_object ? (
            <SelectItem
              onPress={() => {
                //if the enrollment_object.data[item].group_slug in in this.state.opened_menus, remove it...otherwise add it and save the state
                let opened_menus = this.props.opened_menus;
                if (
                  opened_menus?.includes(
                    enrollment_object.data[item].group_slug
                  )
                ) {
                  opened_menus.splice(
                    opened_menus.indexOf(
                      enrollment_object.data[item].group_slug
                    ),
                    1
                  );
                } else {
                  opened_menus.push(enrollment_object.data[item].group_slug);
                }
                this.props.updateOpenedMenus(opened_menus);
              }}
              selected={this.props.opened_menus?.includes(
                enrollment_object.data[item].group_slug
              )}
              subtext={
                "(" +
                this.getTotalCount(enrollment_object.data[item].group_slug) +
                ")"
              }
              select_type="none"
              icon={
                this.props.opened_menus?.includes(
                  enrollment_object.data[item].group_slug
                )
                  ? "keyboard-arrow-up"
                  : "keyboard-arrow-down"
              }
            >
              {enrollment_object.data[item].group_title + ":"}
            </SelectItem>
          ) : null}

          {this.props.opened_menus?.includes(
            enrollment_object.data[item].group_slug
          ) || !!search_object ? (
            <SelectItem
              onPress={() => {
                this.props.selectFilter(item);
              }}
              innerStyle={!search_object ? { paddingLeft: 50 } : {}}
              selected={is_selected}
              select_type="none"
              icon="keyboard-arrow-right"
            >
              {enrollment_object.data[item].label}
            </SelectItem>
          ) : null}
        </Wrapper>
      );
    }
    return null;
  }

  render() {
    const {
      enrollment_object,
      selected_filter,
      colors,
      isMobile,
      filters,
      renderHeader = null
    } = this.props;
    const { search_object } = this.state;
    let group_titles = [];
    if (enrollment_object && enrollment_object.data) {
      let objects = Object.keys(this.filterObject(enrollment_object?.data));
      for (let i = 0; i < objects.length; i++) {
        let object_item = objects[i];

        let found_item = false;
        for (let j = 0; j < group_titles.length; j++) {
          if (
            group_titles[j].group_slug ==
            enrollment_object.data[object_item].group_slug
          ) {
            found_item = true;
          }
        }
        if (!found_item) {
          group_titles.push({
            group_slug: enrollment_object.data[object_item].group_slug,
            item: object_item
          });
        }
      }
    }

    return (
      <>
        {renderHeader ? (
          renderHeader()
        ) : (
          <SmallHeader
            leftButton={
              filters.length > 0
                ? {
                    onPress: () => {
                      this.props.selectingFilterToggle(false);
                    },
                    icon: "keyboard-arrow-left"
                  }
                : null
            }
            title={"Select a filter:"}
          />
        )}

        <SearchBar
          title={"Search"}
          onChange={value => {
            this.setState({
              search_object: value
            });
          }}
          value={search_object}
          style={{
            margin: 10,
            marginTop: 0,
            marginBottom: this.props.isMobile ? 0 : 10,
            borderWidth: this.props.isMobile ? 0 : 1,
            borderBottomWidth: this.props.isMobile ? 0 : 1
          }}
        />

        {this.props.no_list ? (
          <>
            {enrollment_object &&
              Object.keys(this.filterObject(enrollment_object?.data)).map(
                (item, index) => {
                  return this.renderSearchObjectItem(item, index);
                }
              )}
          </>
        ) : (
          <List
            onScroll={() => {
              dismissMobileKeyboard();
            }}
            style={{ flex: 1 }}
            items={
              enrollment_object
                ? Object.keys(this.filterObject(enrollment_object?.data))
                : []
            }
            itemStructure={({ item, index }) =>
              this.renderSearchObjectItem(item, index)
            }
          />
        )}
      </>
    );
  }
}

export default SearchObject;
