import React, { Component } from "react";

import {
  Card,
  CardBody,
  PrimaryButton,
  SecondaryButton,
  Copy,
  Bold,
  Title
} from "app/NativeComponents/common";

class Scene1 extends Component {
  render() {
    if (this.props.scene == 1 || this.props.scene == 2) {
      return (
        <div className="animated fadeInUp">
          <Card>
            <CardBody>
              <Copy>
                Press <Bold>Start Mail Sequences</Bold> to reach the property
                owner.
              </Copy>
              <div className={this.props.scene == 2 ? "animated pulse" : ""}>
                <Card style={{ borderRadius: 30, overflow: "hidden" }}>
                  <PrimaryButton onPress={() => {}}>
                    Start Mail Sequences
                  </PrimaryButton>
                </Card>
              </div>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene1;
