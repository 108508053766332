import React from "react";

const Loading = () => {
  return (
    <div className="dm-website-loading">
      <div className="dm-website-loading-image animated pulse infinite">
        <img alt="" src="/assets/images/DealMachineIconWhite.svg" />
      </div>
    </div>
  );
};

export { Loading };
