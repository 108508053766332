import React, { Component } from "react";
import { connect } from "react-redux";

import {
  selectActiveProperty,
  setActivityPropertiesType,
  pushSidePanel,
  toggleTeamActivityModal,
  toggleTeamActivityList,
  getTeamActivity
} from "app/NativeActions";

import ActivityList from "./ActivityList";

class Body extends Component {
  componentDidMount() {
    this.getActivity({ load_type: "load", filter_by: this.props.filter_by });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter_by !== this.props.filter_by) {
      this.getActivity({ load_type: "load", filter_by: this.props.filter_by });
    }
  }

  getActivity({ load_type = "load", search = "", begin = 0, filter_by }) {
    if (
      !this.props.team_activity_loading &&
      !this.props.team_activity_refreshing
    ) {
      this.props.getTeamActivity({
        token: this.props.token,
        search: search,
        load_type: load_type,
        begin: begin,
        filter_by: filter_by
      });
    }
  }

  render() {
    return (
      <ActivityList {...this.props} getActivity={this.getActivity.bind(this)} />
    );
  }
}

const mapStateToProps = ({ auth, settings, native, activity }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;

  const {
    team_activity,
    team_activity_loading,
    team_activity_refreshing,
    team_activity_loaded_all,
    team_activity_begin
  } = activity;

  return {
    token,
    user,
    isMobile,
    colors,
    device,

    team_activity,
    team_activity_loading,
    team_activity_refreshing,
    team_activity_loaded_all,
    team_activity_begin
  };
};

export default connect(mapStateToProps, {
  selectActiveProperty,
  toggleTeamActivityModal,
  setActivityPropertiesType,
  pushSidePanel,
  toggleTeamActivityList,
  getTeamActivity
})(Body);
