import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Card,
  KeyboardView,
  Copy,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  SearchBar,
  List,
  SelectItem,
  InputBox,
  SpinWrapper,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  getLists,
  resetListModal,
  updateListSearch,
  setListModal,
  popSidePanel,
  updateLead,
  dismissMobileKeyboard,
  renderDate
} from "app/NativeActions";
class Lists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selected_lists: props.list_modal ? props.list_modal.selected_lists : [],
      selected_leads: props.list_modal ? props.list_modal.selected_leads : [],
      new_list_name: "",
      page: "lists",
      search: ""
    };

    this.toggleCreateList = this.toggleCreateList.bind(this);
  }

  componentDidMount() {
    this.getData("refresh");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.list_modal && prevProps.list_modal) {
      if (
        prevProps.list_modal.selected_lists &&
        this.props.list_modal.selected_lists &&
        this.props.list_modal
      ) {
        if (
          prevProps.list_modal.selected_lists.length !==
          this.props.list_modal.selected_lists.length
        ) {
          if (this.props.list_modal.fieldsUpdated) {
            this.props.list_modal.fieldsUpdated(
              this.props.list_modal.selected_lists
            );
          }
        }
      }
    }

    if (
      this.props.list_modal &&
      this.props.list_modal !== prevProps.list_modal
    ) {
      this.setState({
        selected_lists: this.props.list_modal.selected_lists,
        selected_leads: this.props.list_modal.selected_leads,
        page: "lists"
      });
    }

    if (
      this.props.show_list_modal &&
      this.props.show_list_modal !== prevProps.show_list_modal
    ) {
    }
  }
  toggleCreateList(page) {
    this.setState({
      page
    });
  }

  checkListItem(list_item) {
    let found_list = false;
    for (let i = 0; i < this.props.list_modal.selected_lists.length; i++) {
      if (this.props.list_modal.selected_lists[i].id === list_item.id) {
        found_list = true;
      }
    }
    if (found_list) {
      this.props.setListModal({
        ...this.props.list_modal,
        selected_lists: this.props.list_modal.selected_lists.filter(
          ({ id }) => id !== list_item.id
        )
      });
    } else {
      this.props.setListModal({
        ...this.props.list_modal,
        selected_lists: [...this.props.list_modal.selected_lists, list_item]
      });
    }
  }

  isChecked(item) {
    for (let i = 0; i < this.state.selected_lists.length; i++) {
      if (this.state.selected_lists[i].id === item.id) {
        return true;
      }
    }

    return false;
  }

  confirmUpdate() {
    dismissMobileKeyboard();

    if (this.props.list_modal.modalAction) {
      this.props.list_modal.modalAction({
        selected_leads: this.state.selected_leads,
        selected_lists: this.props.list_modal.selected_lists,
        new_list_name: this.state.new_list_name,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });
          this.props.popSidePanel();
        }
      });
    }
  }

  clearAll() {
    this.props.setListModal({
      ...this.props.list_modal,
      selected_lists: []
    });
  }

  getData(load_type = "load") {
    if (!this.props.lists_loading && !this.props.lists_refreshing) {
      this.props.getLists({
        token: this.props.token,
        load_type: "load",
        no_count: true,
        type: "all_lists",
        begin: 0
      });
    }
  }

  handleBack() {
    dismissMobileKeyboard();

    if (this.props.list_modal) {
      if (this.props.list_modal.cancelAction) {
        this.props.list_modal.cancelAction();
      }
    }
    this.props.popSidePanel();
  }

  render() {
    if (this.props.list_modal) {
      return (
        <>
          <NewHeader
            title={this.props.list_modal.title}
            subtitle={this.props.list_modal.description}
            leftButton={{
              icon: this.props.device === "desktop" ? "close" : "arrow-back",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
          {this.props.list_modal.type == "create_list" ? (
            <InputBox
              input_ref={this._list_title}
              autoFocus={true}
              name="list_title"
              disabled={this.state.loading}
              returnKeyType="done"
              placeholder={
                !!this.state.new_list_name
                  ? "List name"
                  : "Enter a name for this list"
              }
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  new_list_name: value
                });
              }}
              blurOnSubmit={true}
              value={this.state.new_list_name}
              input_type="text"
              require_confirm={true}
              has_changed={!!this.state.new_list_name}
              submit_button_primary={true}
              submit_button_title={"Create List"}
              onSubmit={() => this.confirmUpdate()}
              loading={this.state.loading}
            />
          ) : (
            <>
              {this.state.selected_lists.length > 0 ? (
                <ConfirmLabel
                  style={{
                    alignSelf: "stretch"
                  }}
                  primary={true}
                  icon="playlist-add-check"
                  label={
                    this.state.selected_lists.length == 1
                      ? "1 List Selected"
                      : this.state.selected_lists.length + " Lists Selected"
                  }
                  loading={this.state.loading}
                  confirmButton={"Confirm Selection"}
                  onPress={() => {
                    this.confirmUpdate();
                  }}
                />
              ) : null}
              <SearchBar
                title="Search Lists"
                value={this.state.search}
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
                style={{ margin: 10 }}
              />

              <KeyboardView>
                {this.props.lists_loading ? (
                  <SpinWrapper text="Loading Lists..." />
                ) : (
                  <>
                    <List
                      style={{ alignSelf: "stretch" }}
                      items={this.props.lists}
                      itemStructure={({ item, index }) => {
                        if (
                          !!item.title &&
                          item.title
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          return (
                            <SelectItem
                              key={"option_" + index}
                              select_type={"check"}
                              select_disabled={
                                item.list_type == "build_list" ||
                                item.list_type == "bulk_import" ||
                                !item.included_in_stack_count
                              }
                              select_tooltip={
                                item.list_type == "build_list"
                                  ? "You cannot add or remove leads from a smart list"
                                  : item.list_type == "bulk_import"
                                  ? "You cannot add or remove leads from a imported list"
                                  : ""
                              }
                              icon_tooltip={
                                item.list_type == "build_list"
                                  ? "List Builder"
                                  : item.list_type == "bulk_import"
                                  ? "Imported List"
                                  : ""
                              }
                              selected={this.isChecked(item)}
                              onPress={() => {
                                if (
                                  item.list_type != "build_list" &&
                                  item.list_type != "bulk_import"
                                ) {
                                  //add or remove
                                  this.checkListItem(item);
                                }
                              }}
                              subtext={renderDate(item.date_created)}
                            >
                              {item.title}
                            </SelectItem>
                          );
                        }
                        return null;
                      }}
                    />
                  </>
                )}
              </KeyboardView>
            </>
          )}
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, modal, list }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { is_loading } = modal;
  const {
    lists,
    lists_loading,
    lists_refreshing,
    lists_begin,
    lists_loaded_all,
    lists_limit,
    list_modal,
    list_search
  } = list;
  return {
    token,
    user,
    isMobile,
    platform,
    device,
    colors,
    is_loading,
    lists,
    lists_loading,
    lists_refreshing,
    lists_begin,
    lists_loaded_all,
    lists_limit,
    list_modal,

    list_search
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getLists,
  resetListModal,
  updateListSearch,
  setListModal,
  popSidePanel,
  updateLead
})(Lists);
