import React, { Component } from "react";
import { Form, Wrapper } from "app/NativeComponents/common";

import Buttons from "./Buttons";
import Inputs from "./Inputs";
import { injectStripe } from "react-stripe-elements";

class CardForm extends Component {
  render() {
    return (
      <Form
        id="stripe-form"
        onSubmit={() => {
          this.props.stripe
            .createToken({
              type: "card"
            })
            .then(({ token, error }) => {
              this.props.saveBilling(token, error);
            });
        }}
      >
        <Inputs {...this.props} />
        {this.props.renderBillingAddress && this.props.renderBillingAddress()}
        {this.props.billingTextArea && this.props.billingTextArea()}

        <Buttons {...this.props} />
      </Form>
    );
  }
}

export default injectStripe(CardForm);
