import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Card,
  Container,
  Copy,
  KeyboardView,
  MultiLineInput
} from "app/NativeComponents/common";
import {
  GhostButton,
  NewHeader,
  AttentionBox
} from "app/NativeComponents/snippets";
import { popSidePanel, showErrorMessage, showSuccess } from "app/NativeActions";

class ImportDesign extends Component {
  constructor(props) {
    super(props);

    this.state = { code: "" };
  }
  render() {
    const { colors, isMobile } = this.props;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data) {
      return (
        <Container>
          <NewHeader
            title={"Import Design"}
            leftButton={{
              onPress: () => {
                this.props.popSidePanel();
              },
              icon: "arrow-back"
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            <AttentionBox
              description={
                "Paste any DealMachine Mailer JSON code below. This will overwrite anything you have for this design."
              }
            />

            <MultiLineInput
              ref="code"
              name="code"
              blurOnSubmit={true}
              returnKeyType="done"
              keyboardType="default"
              placeholder={"Enter your code here."}
              label={"JSON Code"}
              onChange={value =>
                this.setState({
                  code: value
                })
              }
              value={this.state.code}
              type={"text"}
            />

            <GhostButton
              button_type={"full"}
              primary={true}
              onPress={() => {
                panel_data.importCode({
                  json_string: this.state.code,
                  onError: error => {
                    this.props.showErrorMessage(error, "Error");
                  },
                  onSuccess: () => {
                    this.props.showSuccess(
                      "Your design has been imported.",
                      "Success!"
                    );
                    this.props.popSidePanel();
                  }
                });
              }}
            >
              Import Code
            </GhostButton>
          </KeyboardView>
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  showErrorMessage,
  showSuccess
})(ImportDesign);
