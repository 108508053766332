import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  Copy,
  KeyboardView
} from "app/NativeComponents/common";

import Inputs from "./Inputs";
import Buttons from "./Buttons";

class Body extends Component {
  render() {
    return (
      <>
        <Inputs {...this.props} />
        <Buttons {...this.props} />
      </>
    );
  }
}

export default Body;
