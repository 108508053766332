import React, { Component } from "react";

import FeatureImage from "./FeatureImage";
import FeatureTitle from "./FeatureTitle";
import FeatureSubtitle from "./FeatureSubtitle";
import FeatureDescription from "./FeatureDescription";
import FeatureCallToAction from "./FeatureCallToAction";

import CustomFeature from "./CustomFeature";

class Feature extends Component {
  render() {
    if (this.props.feature) {
      if (
        this.props.feature.customFeatureSlug &&
        this.props.feature.customFeatureSlug != ""
      ) {
        return (
          <div className="dm-website-feature dm-website-feature-custom">
            <CustomFeature feature={this.props.feature} />
            <div className="dm-website-feature-info">
              <FeatureTitle feature={this.props.feature} />
              <FeatureSubtitle feature={this.props.feature} />
              <FeatureDescription feature={this.props.feature} />
              <FeatureCallToAction feature={this.props.feature} />
            </div>
          </div>
        );
      }
      return (
        <div className="dm-website-feature">
          <FeatureImage feature={this.props.feature} />
          <div className="dm-website-feature-info">
            <FeatureTitle feature={this.props.feature} />
            <FeatureSubtitle feature={this.props.feature} />
            <FeatureDescription feature={this.props.feature} />
            <FeatureCallToAction feature={this.props.feature} />
          </div>
        </div>
      );
    }

    return <div />;
  }
}

export { Feature };
