import React, { Component } from "react";
import {
  KeyboardView,
  Wrapper,
  Card,
  Copy,
  Button,
  Row,
  Bold,
  InnerCopy
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  GhostButton,
  IconButton
} from "app/NativeComponents/snippets";

import moment from "moment";
import { store } from "app/store";
class ShowcaseFilters extends Component {
  render() {
    const {
      colors,
      filters,
      andor_type,
      editable,
      style,
      innerStyle,
      text_only
    } = this.props;

    const device = store.getState().native.device;

    const renderInner = ({ filter, i }) => {
      return (
        <>
          {filter.data.map((item, j) => {
            let values = "";
            for (let i = 0; i < item.values.length; i++) {
              values += item.values[i]?.label;
              if (i !== item.values.length - 1) {
                values += ", ";
              }
            }

            if (text_only) {
              return (
                <InnerCopy key={"filter_" + i}>
                  <Bold>{item?.label + " "}</Bold>

                  {item.type == "date" || item.type == "number" ? (
                    <>
                      {item.equal_type == "is_between" ||
                      item.equal_type == "is_not_between" ? (
                        <>
                          {item.equal_type.split("_").join(" ")}{" "}
                          {item.type == "date"
                            ? moment(item.date_object.date_1).format(
                                "MMM Do, YYYY"
                              )
                            : item.date_object.date_1}
                          {" and "}
                          {item.type == "date"
                            ? moment(item.date_object.date_2).format(
                                "MMM Do, YYYY"
                              )
                            : item.date_object.date_2}
                        </>
                      ) : item.equal_type == "is_more_than_generated_date" ? (
                        <>
                          {"is more than "}
                          {item.date_object.date_amount}{" "}
                          {item.date_object.date_amount == 1
                            ? item.date_object.date_type.substring(
                                0,
                                item.date_object.date_type.length - 1
                              )
                            : item.date_object.date_type}{" "}
                          {item.date_object.time_type.split("_").join(" ")}
                        </>
                      ) : item.equal_type == "is_less_than_generated_date" ? (
                        <>
                          {"is less than "}
                          {item.date_object.date_amount}{" "}
                          {item.date_object.date_amount == 1
                            ? item.date_object.date_type.substring(
                                0,
                                item.date_object.date_type.length - 1
                              )
                            : item.date_object.date_type}{" "}
                          {item.date_object.time_type.split("_").join(" ")}
                        </>
                      ) : item.equal_type == "is_less_than" &&
                        item.type == "date" ? (
                        <>
                          {"is before"} {moment(values).format("MMM Do, YYYY")}
                        </>
                      ) : item.equal_type == "is_greater_than" &&
                        item.type == "date" ? (
                        <>
                          {"is after"} {moment(values).format("MMM Do, YYYY")}
                        </>
                      ) : (
                        <>
                          {item.equal_type.split("_").join(" ")} {values}
                        </>
                      )}
                    </>
                  ) : item.equal_type == "is_greater_than" ? (
                    <>
                      {"is more than"} {values}
                    </>
                  ) : item.equal_type == "is_less_than" ? (
                    <>
                      {"is before"} {values}
                    </>
                  ) : (
                    <>
                      {item.equal_type.split("_").join(" ")} {values}
                    </>
                  )}

                  {j < filter.data.length - 1
                    ? filter.andor_type == "and"
                      ? " AND "
                      : " OR "
                    : null}
                </InnerCopy>
              );
            }

            return (
              <Wrapper key={"filter_item_" + j}>
                <Card style={{ margin: 0 }}>
                  <Row>
                    <Wrapper style={{ flex: 1, padding: 20 }}>
                      <Copy>
                        <Bold>{item?.label} </Bold>
                        {item.type == "date" || item.type == "number" ? (
                          <>
                            {item.equal_type == "is_between" ||
                            item.equal_type == "is_not_between" ? (
                              <>
                                {item.equal_type.split("_").join(" ")}{" "}
                                {item.type == "date"
                                  ? moment(item.date_object.date_1).format(
                                      "MMM Do, YYYY"
                                    )
                                  : item.date_object.date_1}
                                {" and "}
                                {item.type == "date"
                                  ? moment(item.date_object.date_2).format(
                                      "MMM Do, YYYY"
                                    )
                                  : item.date_object.date_2}
                              </>
                            ) : item.equal_type ==
                              "is_more_than_generated_date" ? (
                              <>
                                {"is more than "}
                                {item.date_object.date_amount}{" "}
                                {item.date_object.date_amount == 1
                                  ? item.date_object.date_type.substring(
                                      0,
                                      item.date_object.date_type.length - 1
                                    )
                                  : item.date_object.date_type}{" "}
                                {item.date_object.time_type
                                  .split("_")
                                  .join(" ")}
                              </>
                            ) : item.equal_type ==
                              "is_less_than_generated_date" ? (
                              <>
                                {"is less than "}
                                {item.date_object.date_amount}{" "}
                                {item.date_object.date_amount == 1
                                  ? item.date_object.date_type.substring(
                                      0,
                                      item.date_object.date_type.length - 1
                                    )
                                  : item.date_object.date_type}{" "}
                                {item.date_object.time_type
                                  .split("_")
                                  .join(" ")}
                              </>
                            ) : item.equal_type == "is_less_than" &&
                              item.type == "date" ? (
                              <>
                                {"is before"}{" "}
                                {moment(values).format("MMM Do, YYYY")}
                              </>
                            ) : item.equal_type == "is_greater_than" &&
                              item.type == "date" ? (
                              <>
                                {"is after"}{" "}
                                {moment(values).format("MMM Do, YYYY")}
                              </>
                            ) : (
                              <>
                                {item.equal_type.split("_").join(" ")} {values}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {item.equal_type.split("_").join(" ")} {values}
                          </>
                        )}
                      </Copy>
                    </Wrapper>
                    {editable ? (
                      <PopoverMenu
                        renderComponent={options => {
                          return (
                            <IconButton
                              icon="more-vert"
                              noPress={true}
                              pressedIn={options.hovering || options.pressedIn}
                            />
                          );
                        }}
                        menu_items={[
                          {
                            title: "Edit Condition",
                            icon: "keyboard-arrow-right",
                            type: "normal",
                            onPress: () => {
                              this.props.setEditFilter({
                                filter_index: i,
                                item_index: j,
                                item
                              });
                            }
                          },
                          {
                            title: "Delete Condition",
                            icon: "delete",
                            type: "delete",
                            onPress: () => {
                              this.props.removeFitler({
                                filter_index: i,
                                item_index: j
                              });
                            }
                          }
                        ]}
                      />
                    ) : null}
                  </Row>
                </Card>
                {j == filter.data.length - 1 && editable ? (
                  <Row>
                    <GhostButton
                      style={{ marginLeft: 0 }}
                      innerStyle={{ paddingTop: 5, paddingBottom: 5 }}
                      onPress={() => {
                        this.props.setAddFilter(i);
                      }}
                    >
                      {filter.andor_type == "and" ? "AND" : "OR"}
                    </GhostButton>
                    <PopoverMenu
                      renderComponent={options => {
                        return (
                          <IconButton
                            icon="swap-horiz"
                            noPress={true}
                            pressedIn={options.hovering || options.pressedIn}
                          />
                        );
                      }}
                      popover_width={150}
                      menu_items={[
                        {
                          title: "And",
                          select_type: "radio",
                          selected: filter.andor_type == "and" ? true : false,
                          onPress: () => {
                            this.props.swapInnerAndOr(i, "and");
                          }
                        },
                        {
                          title: "Or",
                          select_type: "radio",
                          selected: filter.andor_type == "or" ? true : false,
                          onPress: () => {
                            this.props.swapInnerAndOr(i, "or");
                          }
                        }
                      ]}
                    />
                  </Row>
                ) : j < filter.data.length - 1 ? (
                  <Wrapper
                    style={{
                      margin: 5,
                      marginRight: 10,
                      marginLeft: 10
                    }}
                  >
                    <Copy>
                      <Bold>{filter.andor_type == "and" ? "and" : "or"}</Bold>
                    </Copy>
                  </Wrapper>
                ) : null}
              </Wrapper>
            );
          })}
        </>
      );
    };

    if (text_only) {
      return (
        <Copy>
          {filters &&
            filters.map((filter, i) => {
              return (
                <InnerCopy
                  style={device === "desktop" ? { display: "block" } : null}
                  key={"filter_" + i}
                >
                  {filters.length > 1 ? "( " : null}
                  {renderInner({ filter, i })}
                  {i < filters.length - 1
                    ? andor_type == "and"
                      ? " ) AND "
                      : " ) OR "
                    : filters.length > 1
                    ? " )"
                    : null}
                </InnerCopy>
              );
            })}
        </Copy>
      );
    }

    return (
      <KeyboardView style={{ flex: 1, ...style }}>
        <Wrapper
          style={{
            ...innerStyle,
            padding: 5
          }}
        >
          {filters &&
            filters.map((filter, i) => {
              return (
                <Wrapper key={"filter_" + i}>
                  <Card style={{ padding: 20 }}>
                    {renderInner({ filter, i })}
                  </Card>
                  {i == filters.length - 1 && editable ? (
                    <Row>
                      <GhostButton
                        primary={true}
                        style={{ marginTop: 0 }}
                        innerStyle={{ paddingTop: 5, paddingBottom: 5 }}
                        onPress={() => {
                          this.props.setAddFilter(null);
                        }}
                      >
                        {andor_type == "and" ? "AND" : "OR"}
                      </GhostButton>
                      <PopoverMenu
                        renderComponent={options => {
                          return (
                            <IconButton
                              style={{ marginTop: 0 }}
                              noPress={true}
                              icon="swap-horiz"
                              pressedIn={options.hovering || options.pressedIn}
                            />
                          );
                        }}
                        popover_width={150}
                        menu_items={[
                          {
                            title: "And",
                            select_type: "radio",
                            selected: andor_type == "and" ? true : false,
                            onPress: () => {
                              this.props.swapMainAndOr("and");
                            }
                          },
                          {
                            title: "Or",
                            select_type: "radio",
                            selected: andor_type == "or" ? true : false,
                            onPress: () => {
                              this.props.swapMainAndOr("or");
                            }
                          }
                        ]}
                      />
                    </Row>
                  ) : i < filters.length - 1 ? (
                    <Wrapper style={{ marginRight: 10, marginLeft: 10 }}>
                      <Copy>
                        <Bold>{andor_type == "and" ? "and" : "or"}</Bold>
                      </Copy>
                    </Wrapper>
                  ) : null}
                </Wrapper>
              );
            })}
        </Wrapper>
      </KeyboardView>
    );
  }
}

export default ShowcaseFilters;
