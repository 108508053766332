export const TOGGLE_MODAL = "toggle_modal";
export const START_MODAL_FADE = "start_modal_fade";
export const SET_MODAL = "set_modal";
export const UPDATE_MODAL = "update_modal";
export const SET_PLAN_MODAL = "set_plan_modal";
export const TOGGLE_PLAN_MODAL = "toggle_plan_modal";
export const TOGGLE_PLAN_FREQUENCY = "toggle_plan_frequency";
export const SUCCESS_MESSAGE = "success_message";
export const RESET_SUCCESS_MESSAGE = "reset_success_message";
export const ERROR_MESSAGE = "error_message";
export const RESET_ERROR_MESSAGE = "reset_error_message";
export const IS_LOADING = "is_loading";
export const SET_LOADING_MESSAGE = "set_loading_message";
export const SHOW_NOTIFICATION = "show_notification";
export const SET_NOTIFICATION = "set_notification";
export const TOGGLE_REDIRECT = "toggle_redirect";

export const SET_ACTIVE_POPOVER = "set_active_popover";

export const UNDO_ACTION = "undo_actions";
export const UNDO_ACTION_FAIL = "undo_action_fail";
export const UNDO_ACTION_SUCCESS = "undo_action_success";

export const TOGGLE_DEAL_CREDIT_MODAL = "toggle_deal_credit_modal";
export const SET_EDIT_MODAL = "set_edit_modal";
export const TOGGLE_EDIT_MODAL = "toggle_edit_modal";

export const SET_RIGHT_PANEL_META = "set_right_panel_meta";
export const SET_RIGHT_PANEL = "set_right_panel";
export const SET_LEAD_MODAL = "set_lead_modal";

export const SET_UPSELL_MODAL = "set_upsell_modal";
export const TOGGLE_UPSELL_MODAL = "toggle_upsell_modal";

export const PUSH_SIDE_PANEL = "push_side_panel";
export const REPLACE_SIDE_PANEL = "replace_side_panel";
export const UPDATE_SIDE_PANEL = "update_side_panel";
export const POP_SIDE_PANEL = "pop_side_panel";
export const COMPLETE_POP_SIDE_PANEL = "complete_pop_side_panel";
export const POP_ALL_SIDE_PANEL = "pop_all_side_panel";
export const COMPLETE_POP_ALL_SIDE_PANEL = "complete_pop_all_side_panel";
export const BUILD_SIDE_PANELS = "build_side_panels";

export const ADD_PROGRESS_BAR = "add_progress_bar";
export const UPDATE_PROGRESS_BAR = "update_progress_bar";
export const REMOVE_PROGRESS_BAR = "remove_progress_bar";
export const COMPLETE_PROGRESS_BAR = "complete_progress_bar";
