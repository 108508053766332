import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  KeyboardView,
  Animation
} from "app/NativeComponents/common";
import {
  NewHeader,
  BottomNavBar,
  InputBox,
  ConfirmLabel,
  SelectBox,
  GhostButton,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import { updateLeadStatus, dismissMobileKeyboard } from "app/NativeActions";

class EditLeadStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status_title: props.lead_status?.title,
      loading: false,
      deleting: false,
      status_type: props.lead_status?.status_type,
      status_type_options: [
        {
          label: "No Status Type",
          value: ""
        },
        {
          label: "Lead Generation",
          value: "lead_generation_status"
        },
        {
          label: "Marketing",
          value: "marketing_status"
        },
        {
          label: "Warm Lead",
          value: "warm_lead_status"
        },
        {
          label: "Hot Lead",
          value: "hot_lead_status"
        },
        {
          label: "Acquisitions",
          value: "acquisition_status"
        },
        {
          label: "Dispositions",
          value: "disposition_status"
        },
        {
          label: "Not Interested (Complete)",
          value: "not_interested_status"
        },
        {
          label: "Unqualified (Complete)",
          value: "unqualified_status"
        },
        {
          label: "Unresponsive (Complete)",
          value: "marketing_complete_status"
        },
        {
          label: "Inactive (Complete)",
          value: "inactive_status"
        },
        {
          label: "Won (Complete)",
          value: "won_status"
        }
      ]
    };

    this._status_title = React.createRef();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {}

  handleBack() {
    dismissMobileKeyboard();
    this.props.setActiveLeadStatus(null);
  }

  checkIfNeedsToSave() {
    const { status_title } = this.state;
    if (!!status_title) {
      return true;
    }

    return false;
  }

  render() {
    const { edit_status_info, colors } = this.props;
    const { status_title, loading, deleting } = this.state;
    return (
      <Container>
        <NewHeader
          title={"Edit Lead Status"}
          subtitle={""}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._status_title}
            autoFocus={true}
            name="status_title"
            disabled={loading}
            returnKeyType="done"
            placeholder={
              !!status_title ? "Status name" : "Enter a name for this status"
            }
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                status_title: value
              });
            }}
            blurOnSubmit={true}
            value={status_title}
            input_type="text"
          />

          <SelectBox
            items={this.state.status_type_options}
            disabled={this.state.loading}
            value={this.state.status_type}
            placeholder="Status Category:"
            onSelect={item => {
              this.setState({
                status_type: item
              });
            }}
          />

          <GhostButton
            primary={true}
            button_type="full"
            loading={this.state.loading}
            disabled={!this.state.status_title}
            onPress={() => {
              this.props.updateLeadStatus({
                token: this.props.token,
                type: "update_lead_status",
                title: this.state.status_title,
                status_type: this.state.status_type,
                lead_status_id: this.props.lead_status?.id,
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onSuccess: results => {
                  this.setState({
                    loading: false
                  });
                  this.handleBack();
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                }
              });
            }}
          >
            Save Lead Status
          </GhostButton>
        </KeyboardView>
        <BottomNavBar>
          <ConfirmInlineButton
            confirm_text="Really Delete?"
            confirm_icon="delete"
            loading_text="Deleting"
            loading={this.state.deleting}
            button_type="full"
            onPress={() => {
              this.props.updateLeadStatus({
                token: this.props.token,
                type: "remove_lead_status",
                lead_status_id: this.props.lead_status?.id,
                onLoading: () => {
                  this.setState({
                    deleting: true
                  });
                },
                onError: () => {
                  this.setState({
                    deleting: false
                  });
                },
                onSuccess: () => {
                  this.setState({
                    deleting: false
                  });
                  this.handleBack();
                }
              });
            }}
          >
            Delete Status
          </ConfirmInlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, status, modal }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { side_panel_views } = modal;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  updateLeadStatus
})(EditLeadStatus);
