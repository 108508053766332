import React from "react";
import { store } from "app/store";
import { Animation } from "app/NativeComponents/common";

const AlmaOrb = ({ style, size = 20, active = true, big_orb = false }) => {
  const colors = store.getState().settings.colors;
  const dark_mode = store.getState().settings.dark_mode;

  if (big_orb) {
    return (
      <Animation type={"fadeInUp"}>
        <div
          className={
            active
              ? "glowing-orb big-orb " + dark_mode
              : "glowing-orb not-active big-orb" + dark_mode
          }
          style={{
            width: size,
            height: size,
            ...style
          }}
        ></div>
      </Animation>
    );
  }
  return (
    <div
      className={
        active
          ? "glowing-orb " + dark_mode
          : "glowing-orb not-active " + dark_mode
      }
      style={{
        width: size,
        height: size,
        ...style
      }}
    ></div>
  );
};

export { AlmaOrb };
