import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Spin,
  Icon,
  Title,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";

class Scene3 extends Component {
  render() {
    if (this.props.scene > 3) {
      return (
        <div className="animated slideInRight">
          <Row style={{ justifyContent: "flex-end" }}>
            <Card>
              <CardBody style={{ padding: "10px 15px" }}>
                <Copy>
                  <Bold>1 email address found!</Bold>
                </Copy>
              </CardBody>
            </Card>
          </Row>
        </div>
      );
    }

    return <div />;
  }
}

export default Scene3;
