import React, { PureComponent } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  Card,
  Wrapper,
  CardBody,
  Title,
  Copy,
  Row
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";

import moment from "moment";
import Popover from "react-simple-popover";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

class DateSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show_datepicker: false,
      date: props.date ? moment(props.date).toDate() : new Date()
    };

    this._popover = React.createRef();
  }
  componentDidMount() {
    if (this.props.autoFocus) {
      this.setState({
        show_datepicker: true
      });
    }
  }

  render() {
    const {
      style,
      children,
      minimumDate,
      maximumDate,
      onDateChange = () => {},
      popoverPlacement = "top"
    } = this.props;
    const { show_datepicker, date } = this.state;
    return (
      <div style={{ ...style }}>
        {
          <Wrapper wrapper_ref={this._popover}>
            <Button
              style={{
                alignItems: "flex-start",
                justifyContent: "center",
                alignSelf: "stretch"
              }}
              onPress={() => {
                this.setState({
                  show_datepicker: true
                });
              }}
            >
              {children}
            </Button>
          </Wrapper>
        }
        {show_datepicker ? (
          <Popover
            placement={popoverPlacement}
            target={this._popover.current}
            show={show_datepicker}
            containerStyle={{ opacity: 1, zIndex: 1 }}
            style={{
              width: 250,
              borderRadius: 5,
              padding: 0,
              overflow: "hidden"
            }}
            onHide={() => this.setState({ show_datepicker: false })}
          >
            <CardBody>
              <Copy style={{ textAlign: "center" }}>Select a date:</Copy>

              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <DayPicker
                  onDayClick={day => {
                    this.setState({
                      date: day
                    });
                    this.props.onDateChange(moment(day).format("YYYY-MM-DD"));
                    this.setState({ show_datepicker: false });
                  }}
                />
              </Wrapper>
            </CardBody>
          </Popover>
        ) : null}
      </div>
    );
  }
}

export { DateSelector };
