import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  SelectBox,
  SelectItem,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class EditMailingAddress extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      address_label: panel_data?.mailing_address?.address_label || "",
      name: panel_data?.mailing_address?.name || "",
      address: panel_data?.mailing_address?.address || "",
      address2: panel_data?.mailing_address?.address2 || "",
      city: panel_data?.mailing_address?.city || "",
      state: panel_data?.mailing_address?.state || "",
      zip: panel_data?.mailing_address?.zip || "",
      send_to_address: panel_data?.mailing_address?.send_to_address || "",
      state_focus: false,
      loading: false,
      panel_data
    };
    this._address_label_input = React.createRef();
    this._name_input = React.createRef();
    this._address_input = React.createRef();
    this._address2_input = React.createRef();
    this._city_input = React.createRef();
    this._state_input = React.createRef();
    this._zip_input = React.createRef();

    this.saveMailingAddress = this.saveMailingAddress.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }
  checkIfNeedsToSave() {
    if (
      !!this.state.name &&
      !!this.state.address &&
      !!this.state.city &&
      !!this.state.state &&
      !!this.state.zip &&
      (this.state.address_label !=
        this.state.panel_data?.mailing_address?.address_label ||
        this.state.name != this.state.panel_data?.mailing_address?.name ||
        this.state.address != this.state.panel_data?.mailing_address?.address ||
        this.state.address2 !=
          this.state.panel_data?.mailing_address?.address2 ||
        this.state.city != this.state.panel_data?.mailing_address?.city ||
        this.state.state != this.state.panel_data?.mailing_address?.state ||
        this.state.zip != this.state.panel_data?.mailing_address?.zip ||
        this.state.send_to_address !=
          this.state.panel_data?.mailing_address?.send_to_address)
    ) {
      return true;
    }

    return false;
  }

  saveMailingAddress() {
    const {
      panel_data: { property, mailing_address }
    } = this.state;

    const {
      address_label,
      name,
      address,
      address2,
      city,
      state,
      zip,
      send_to_address
    } = this.state;
    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "edit_mailing_address",
      address_label,
      name,
      address,
      address2,
      city,
      state,
      zip,
      send_to_address: send_to_address ? 1 : 0,
      owner_mailing_address_id: mailing_address.owner_mailing_address_id,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      panel_data: { property, mailing_address }
    } = this.state;
    const {
      address_label,
      name,
      address,
      address2,
      city,
      state,
      zip,
      loading
    } = this.state;

    let state_data = [];
    for (let i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    let formatted_property_address = "";
    if (property) {
      const display_property = determineDisplayProperty(property);
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    }

    return (
      <Container>
        <NewHeader
          title="Edit Mailing Address"
          subtitle={
            formatted_property_address
              ? "Edit mailing address associated with " +
                formatted_property_address.line1
              : ""
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            autoFocus={true}
            input_ref={this._address_label_input}
            name="label"
            disabled={loading}
            returnKeyType="next"
            placeholder="Label (Optional)"
            onChange={value => {
              this.setState({
                address_label: value
              });
            }}
            onSubmitEditing={() => {
              this._name_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address_label}
            type="text"
          />
          <InputBox
            autoFocus={false}
            input_ref={this._name_input}
            name="name"
            disabled={loading}
            returnKeyType="next"
            placeholder="Name On Mail"
            onChange={value => {
              this.setState({
                name: value
              });
            }}
            onSubmitEditing={() => {
              this._address_input.current.focus();
            }}
            blurOnSubmit={false}
            value={name}
            type="text"
          />
          <InputBox
            autoFocus={false}
            input_ref={this._address_input}
            name="address"
            disabled={loading}
            returnKeyType="next"
            placeholder="Address"
            onChange={value => {
              this.setState({
                address: value
              });
            }}
            onSubmitEditing={() => {
              this._address2_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address}
            type="text"
          />

          <InputBox
            input_ref={this._address2_input}
            name="address2"
            disabled={loading}
            returnKeyType="next"
            placeholder="Address Line 2"
            onChange={value => {
              this.setState({
                address2: value
              });
            }}
            onSubmitEditing={() => {
              this._city_input.current.focus();
            }}
            blurOnSubmit={false}
            value={address2}
            type="text"
          />

          <InputBox
            input_ref={this._city_input}
            name="city"
            disabled={loading}
            returnKeyType="next"
            autoCapitalize="words"
            keyboardType="default"
            placeholder="City"
            onChange={value => {
              this.setState({
                city: value
              });
            }}
            blurOnSubmit={true}
            onSubmitEditing={() => {
              this.setState({ state_focus: true });
            }}
            value={city}
            type="text"
          />
          <SelectBox
            select_ref={this._state_input}
            items={state_data}
            disabled={loading}
            value={state}
            placeholder="State"
            show={this.state.state_focus}
            onFocus={() => {
              this.setState({ state_focus: true });
            }}
            onBlur={() => {
              this.setState({ state_focus: false });
            }}
            onSelect={item => {
              this.setState({
                state: item
              });
            }}
            onSubmitEditing={() => {
              this._zip_input.current.focus();
            }}
          />

          <InputBox
            input_ref={this._zip_input}
            name="zip"
            disabled={loading}
            returnKeyType="done"
            keyboardType="numeric"
            placeholder="Zip Code"
            onChange={value => {
              this.setState({
                zip: value
              });
            }}
            onSubmitEditing={this.saveMailingAddress}
            blurOnSubmit={false}
            value={zip}
            type="number"
            maxLength={"5"}
          />

          <SelectItem
            select_type="toggle"
            selected={this.state.send_to_address}
            onPress={() => {
              this.setState({
                send_to_address: !this.state.send_to_address
              });
            }}
          >
            Send mail to this address
          </SelectItem>

          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.saveMailingAddress}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Save Mailing Address
          </GhostButton>
        </KeyboardView>

        <BottomNavBar>
          <ConfirmInlineButton
            confirm_text="Really Delete?"
            confirm_icon="delete"
            loading_text="Deleting"
            loading={this.state.deleting}
            button_type="full"
            onPress={() => {
              this.props.updateOwnerInfo({
                token: this.props.token,
                deal_id: property?.deal?.id,
                type: "remove_mailing_address",
                owner_mailing_address_id:
                  mailing_address.owner_mailing_address_id,
                onLoading: () => {
                  this.setState({
                    deleting: true
                  });
                },
                onError: () => {
                  this.setState({
                    deleting: false
                  });
                },
                onSuccess: results => {
                  if (
                    this.state.panel_data?.propertyUpdated &&
                    results?.properties?.length == 1
                  ) {
                    this.state.panel_data.propertyUpdated(
                      results.properties[0]
                    );
                  }
                  this.setState({
                    deleting: false
                  });
                  this.handleBack();
                }
              });
            }}
          >
            Delete Mailing Address
          </ConfirmInlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { states, colors } = settings;

  return { token, user, device, states, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(EditMailingAddress);
