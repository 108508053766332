import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Copy,
  Row,
  Spin,
  Wrapper
} from "app/NativeComponents/common";
import {
  List,
  SpinWrapper,
  NewHeader,
  SelectItem,
  AttentionBox
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  getContacts,
  getCompanies,
  getCompanyPeople
} from "app/NativeActions";
import PersonItem from "app/DealMachineCore/components/Property/OwnerInfo/PersonItem";

class PersonSearch extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      firstname: panel_data.firstname,
      lastname: panel_data.lastname,
      middlename: panel_data.middlename,
      firstname2: panel_data.firstname2,
      lastname2: panel_data.lastname2,
      middlename2: panel_data.middlename2,

      firstname3: null,
      middlename3: null,
      firstname4: null,
      lastname4: null,
      middlename4: null,
      firstname5: null,
      lastname5: null,
      middlename5: null,

      address_zipcode: panel_data.address_zipcode,
      address_state: panel_data.address_state,

      company_name: panel_data.company_name,
      company_address: panel_data.company_address,
      company_city: panel_data.company_city,
      company_state: panel_data.company_state,
      company_zip: panel_data.company_zip,

      companies: [],
      company_people: [],
      selected_company: null,
      companies_loading: false,
      company_people_loading: null,

      contacts_loading: true,
      contacts_limit: 25,
      contacts_begin: 0,
      contacts_loaded_all: false,
      contacts: [],
      contacts_refreshing: false,
      expanded: panel_data.expanded,
      property: panel_data.property,
      propertyUpdated: panel_data.propertyUpdated
    };

    this.getContactData = this.getContactData.bind(this);
    this.getCompanyData = this.getCompanyData.bind(this);
    this.getCompanyPeople = this.getCompanyPeople.bind(this);
  }

  componentDidMount() {
    if (!!this.state.company_name) {
      this.getCompanyData();
    } else {
      this.getContactData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selected_company !== prevState.selected_company) {
      this.getCompanyPeople();
    }
  }

  getCompanyPeople() {
    if (this.state.selected_company) {
      this.props.getCompanyPeople({
        token: this.props.token,
        company_number: this.state.selected_company?.company_number,
        jurisdiction_code: this.state.selected_company?.jurisdiction_code,
        onLoading: () => {
          this.setState({
            company_people_loading: this.state.selected_company?.company_number
          });
        },
        onError: () => {
          this.setState({
            company_people_loading: null
          });
        },
        onSuccess: results => {
          if (results?.company_people?.length > 0) {
            const company_people = results.company_people;

            this.setState(
              {
                company_people_loading: null,
                company_people: company_people,

                firstname:
                  company_people.length > 0
                    ? company_people[0].firstname
                    : null,
                lastname:
                  company_people.length > 0 ? company_people[0].lastname : null,
                middlename:
                  company_people.length > 0
                    ? company_people[0].middlename
                    : null,
                firstname2:
                  company_people.length > 1
                    ? company_people[1].firstname
                    : null,
                lastname2:
                  company_people.length > 1 ? company_people[1].lastname : null,
                middlename2:
                  company_people.length > 1
                    ? company_people[1].middlename
                    : null,
                firstname3:
                  company_people.length > 2 ? company_people[2].lastname : null,
                lastname3:
                  company_people.length > 2 ? company_people[2].lastname : null,
                firstname4:
                  company_people.length > 3 ? company_people[3].lastname : null,
                lastname4:
                  company_people.length > 3 ? company_people[3].lastname : null,
                firstname5:
                  company_people.length > 4 ? company_people[4].lastname : null,

                contacts_loading: true,
                contacts_limit: 25,
                contacts_begin: 0,
                contacts_loaded_all: false,
                contacts: [],
                contacts_refreshing: false
              },
              () => {
                this.getContactData();
              }
            );
          } else {
            this.setState({
              company_people_loading: null
            });
          }
        }
      });
    }
  }

  getCompanyData() {
    this.props.getCompanies({
      token: this.props.token,
      company_name: this.state.company_name,
      company_address: this.state.company_address,
      company_city: this.state.company_city,
      company_state: this.state.company_state,
      company_zip: this.state.company_zip,
      property_id: this.state.property ? this.state.property.property_id : null,
      onLoading: () => {
        this.setState({
          companies_loading: true
        });
      },
      onError: () => {
        this.setState({
          companies_loading: false,
          contacts_loading: false,
          company_people_loading: null
        });
      },
      onSuccess: results => {
        if (results.companies && results.companies.length > 0) {
          const company_people =
            results.companies.length === 1 &&
            results.company_people &&
            results.company_people.length > 0
              ? results.company_people
              : [];

          this.setState(
            {
              selected_company:
                results.companies.length === 1 ? results.companies[0] : null,
              companies: results.companies,
              company_people: company_people,
              address_zipcode: this.state.company_zip,
              address_state: this.state.company_state,
              companies_loading: false
            },
            () => {}
          );
        } else {
          this.setState({
            companies_loading: false,
            contacts_loading: false,
            company_people_loading: null
          });
        }
      }
    });
  }

  getContactData() {
    this.props.getContacts({
      token: this.props.token,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      firstname2: this.state.firstname2,
      lastname2: this.state.lastname2,
      middlename: this.state.middlename,
      middlename2: this.state.middlename2,
      address_zipcode: this.state.address_zipcode,
      address_state: this.state.address_state,
      begin: this.state.contacts_begin,
      limit: this.state.contacts_limit,
      sort_by: "best_match",
      onLoading: () => {
        this.setState({
          contacts_loading: true
        });
      },
      onError: () => {
        this.setState({
          contacts_loading: false
        });
      },
      onSuccess: results => {
        this.setState(
          {
            contacts_loading: false,
            contacts: [...this.state.contacts, ...results.contacts],
            contacts_begin:
              results?.contacts?.length + this.state.contacts_begin,
            contacts_loaded_all:
              results?.contacts.length < this.state.contacts_limit
                ? true
                : false
          },
          () => {}
        );
      }
    });
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="Person Search"
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />

        {this.state.companies_loading ||
        (this.state.company_people_loading &&
          this.state.companies.length === 1) ? (
          <SpinWrapper text={"Searching Company..."} />
        ) : (
          <>
            {this.state.companies.length > 0 ? (
              <>
                {this.state.companies.length === 1 &&
                this.state.selected_company ? (
                  <SelectItem
                    noPress={true}
                    select_type={"none"}
                    label={"Found Company Match: "}
                    subtext={this.state.selected_company?.company_type}
                    selected={true}
                    //loop through all the company people and display there full_name split by a comma
                    description={
                      this.state.company_people?.length > 0
                        ? "Associated officers: " +
                          this.state.company_people
                            .map((person, i) => {
                              return person.full_name;
                            })
                            .join(", ")
                        : null
                    }
                  >
                    {this.state.selected_company?.name}
                  </SelectItem>
                ) : (
                  <></>
                )}
              </>
            ) : null}

            {this.state.company_people_loading ? (
              <SpinWrapper text={"Searching Company People..."} />
            ) : this.state.contacts_loading &&
              this.state.contacts.length === 0 ? (
              <SpinWrapper text={"Loading Possible Matches..."} />
            ) : this.state.contacts?.length > 0 ? (
              <>
                <AttentionBox
                  title="Select a person and link them to the property."
                  description={
                    "Here you can see every person in the United States matching the mailing address name listed in the county records. We've then ordered by what we think is the most likely match. If you can't find the person you're looking for, there might be a name misspelling, name change, or in rare cases they might not be in our database."
                  }
                />
                <List
                  rowNumber={1}
                  style={{ flex: 1 }}
                  items={this.state.contacts}
                  infiniteScroll={true}
                  itemStructure={({ item, index }) => {
                    return (
                      <PersonItem
                        key={"person_" + index}
                        person={item}
                        no_options={false}
                        use_person_flags={false}
                        include_search_information={true}
                        expanded={this.state.expanded}
                        no_border={true}
                        property={this.state.property}
                        show_linked={true}
                        propertyUpdated={this.state.propertyUpdated}
                      />
                    );
                  }}
                  canRefresh={true}
                  onRefresh={() => {}}
                  is_refreshing={this.state.contacts_refreshing}
                  canLoadMore={
                    !this.state.contacts_loaded_all &&
                    !this.state.contacts_loading &&
                    !this.state.contacts_refreshing &&
                    this.state.contacts.length > 0
                  }
                  isLoadingMore={
                    this.state.contacts_loading &&
                    !this.state.contacts_refreshing &&
                    this.state.contacts.length > 0
                  }
                  onLoadMore={() => {
                    if (
                      !this.state.contacts_loaded_all &&
                      this.state.contacts_refreshing != true &&
                      this.state.contacts_loading != true
                    ) {
                      this.getContactData();
                    }
                  }}
                />
              </>
            ) : this.state.contacts.length === 0 ? (
              <>
                <AttentionBox
                  title="Select a person and link them to the property."
                  description={
                    "Here you can see every person in the United States matching the mailing address name listed in the county records. We've then ordered by what we think is the most likely match. If you can't find the person you're looking for, there might be a name misspelling, name change, or in rare cases they might not be in our database."
                  }
                />

                <Wrapper style={{ padding: 25 }}>
                  {!!this.state.company_name ? (
                    <>
                      {this.state.companies.length > 0 ? (
                        <Copy style={{ textAlign: "center" }}>
                          We couldn't find any people associated with this
                          company.
                        </Copy>
                      ) : (
                        <Copy style={{ textAlign: "center" }}>
                          We couldn't identify this company.
                        </Copy>
                      )}
                    </>
                  ) : (
                    <Copy style={{ textAlign: "center" }}>
                      {!!this.state.firstname2
                        ? "" +
                          "There are no contacts in our database matching these names."
                        : "There are no contacts in our database matching this name."}
                    </Copy>
                  )}
                </Wrapper>
              </>
            ) : null}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { sending_queue_counts } = mailer;

  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    sending_queue_counts
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getContacts,
  getCompanies,
  getCompanyPeople
})(PersonSearch);
