import React from "react";
import Popover from "react-simple-popover";
import { Animation, ModalOverlay } from "app/NativeComponents/common";
import { store } from "app/store";

const OverlayContainer = ({
  children,
  style,
  popoverTarget = null,
  hidePopover,
  popoverPlacement = "right",
  popoverWidth = 300,
  is_loading = false,
  overlay = true,
  animation_type = "fadeInUp",
  no_overlay = false,
  onPress = null,
  disabled = false,
  ...props
}) => {
  const colors = store.getState().settings.colors;

  let zIndex = document.querySelectorAll(".deal-modal-overlay").length;
  if (!zIndex) {
    zIndex = 1;
  }

  return (
    <div
      onClick={onPress && !disabled ? onPress : () => {}}
      className={
        disabled
          ? ""
          : "dm-white-overlay-modal animated animate_delay_025s " +
            animation_type
      }
      ov
    >
      <div
        style={
          !disabled
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }
            : null
        }
      >
        <div style={{ zIndex: zIndex }} onClick={() => {}} {...props}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { OverlayContainer };
