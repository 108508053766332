import React, { Component } from "react";

import {
  Wrapper,
  CardBody,
  Video,
  Title,
  Card,
  Copy,
  Bold,
  Input,
  Row,
  InternalImage
} from "app/NativeComponents/common";

import { numberWithCommas } from "app/NativeActions";

class Check extends Component {
  renderMemo() {
    let memo = "";

    switch (this.props.goal_1) {
      case "family":
      default:
        memo = "My Family & ";
        break;
      case "vaction":
        memo = "A Vacation Home & ";
        break;
      case "friends":
        memo = "My friends & ";
        break;
      case "supplement":
        memo = "Supplement My Leads & ";
        break;

      case "scale":
        memo = "Scale & ";
        break;

      case "time":
        memo = "My Time & ";
        break;
      case "leads":
        memo = "More Reliable Leads & ";
        break;
    }

    switch (this.props.goal_2) {
      case "build_wealth":
      default:
        memo += "Building Wealth";
        break;
      case "self_reliant":
        memo += "Self Reliance";
        break;
      case "avoid_stress":
        memo += "A Stress Free Life";
        break;

      case "team":
        memo += "My Team";
        break;
      case "community":
        memo += "My Community";
        break;
    }

    return memo;
  }

  render() {
    return (
      <Wrapper
        style={{
          width: 320,
          backgroundColor: this.props.colors.background_color,
          padding: 10,
          marginTop: 20,
          marginBottom: 10
        }}
      >
        <Wrapper
          style={{
            backgroundColor: this.props.colors.background_color,
            borderColor: this.props.colors.border_color,
            borderWidth: 5,
            borderStyle: "solid",
            padding: 20
          }}
        >
          <Row
            style={{
              justifyContent: "space-between"
            }}
          >
            <InternalImage
              style={{
                width: 100,
                height: 20
              }}
              contain={true}
              resizeMode={"contain"}
              source={
                this.props.device == "mobile"
                  ? require("app/assets/images/dm_blue_logo_medium.png")
                  : null
              }
              image={
                this.props.device == "desktop"
                  ? "/assets/images/dm_blue_logo_medium.png"
                  : ""
              }
            />

            <Copy style={{ fontSize: 12 }}>Date: {this.props.date}</Copy>
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
              paddingTop: 20,
              paddingBottom: 20
            }}
          >
            <Row>
              <Copy>
                <Bold>Pay to:</Bold>
              </Copy>
              <Wrapper
                style={{
                  backgroundColor: this.props.colors.card_color,
                  padding: 5,
                  marginLeft: 5
                }}
              >
                <Copy>{this.props.name}</Copy>
              </Wrapper>
            </Row>
            <Row>
              <Copy>
                <Bold>$</Bold>
              </Copy>
              <Wrapper
                style={{
                  backgroundColor: this.props.colors.card_color,
                  padding: 5
                }}
              >
                <Copy>{numberWithCommas(this.props.amount)}</Copy>
              </Wrapper>
            </Row>
          </Row>
          <Row
            style={{
              justifyContent: "space-between"
            }}
          >
            <Copy style={{ fontSize: 12 }}>
              Memo: {this.props.memo ? this.props.memo : this.renderMemo()}
            </Copy>
          </Row>
        </Wrapper>
      </Wrapper>
    );
  }
}

export default Check;
