import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Button, PrimaryButton } from "app/NativeComponents/common";
import { ToggleSwitch } from "app/NativeComponents/snippets";

import {
  setUpsellModal,
  toggleUpsellModal,
  toggleModal,
  setModal,
  checkIfUserHasBillingAddon,
  checkIfUserHasModule,
  triggerHapticFeedback
} from "app/NativeActions";

class LockButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      unlocked: false,
      addon: null,
      hovering: false
    };
  }

  setFeatureLock(billing_addons) {
    const feature_slug = this.props.feature_slug;

    for (var i = 0; i < billing_addons.length; i++) {
      if (billing_addons[i].slug == feature_slug) {
        this.setState({ addon: billing_addons[i] });

        if (
          billing_addons[i].team_has_addon == 1 ||
          billing_addons[i].included_in_team_plan == 1
        ) {
          this.setState({ unlocked: true });
        }
      }
    }
  }

  componentDidMount() {
    this.setFeatureLock(this.props.billing_addons);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.billing_addons) {
      if (this.props.billing_addons != prevProps.billing_addons) {
        this.setFeatureLock(this.props.billing_addons);
      }
    }
  }

  triggerFeatureLock(value) {
    if (this.state.unlocked == false && this.state.addon != null) {
      if (this.props.user.team_owner == 1) {
        if (this.props.platform == "ios") {
          this.props.setModal({
            title: 'You need the "' + this.state.addon.title + '" product.',
            description:
              'You need the "' +
              this.state.addon.title +
              '" product to use this feature. Please go to DealMachine on the web to manage your account.',
            image: this.state.addon.image,
            submit: "Dismiss",
            onPress: () => {},
            cancel: "",
            onCancel: () => {}
          });
          this.props.toggleModal({ show: true, type: "normal" });
        } else {
          //check if user is not an old user to prevent randomness
          if (
            this.props.user?.user_version >= 2 &&
            this.props.user.user_mail_version >= 2
          ) {
            this.props.setModal({
              title: this.state.addon.marketing_title,
              description: this.state.addon.marketing_text,
              image: this.state.addon.image,
              submit: this.state.addon.button_text_yes,
              onPress: () => {
                this.props.updateBillingAddon({
                  token: this.props.token,
                  type: "purchase",
                  slug: this.state.addon.slug,
                  addon_id: this.state.addon.id
                });
              },
              cancel: this.state.addon.button_text_no,
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
          } else {
            this.props.setModal({
              title: "You're on an older DealMachine plan",
              description:
                "Please talk to Customer Support about updating your plan. Once you're on a newer plan you can purchase this product.",
              icon: "error",
              submit: "Dismiss",
              onPress: () => {},
              cancel: "",
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
          }
        }
      } else {
        this.props.setModal({
          title: "This feature is locked.",
          description:
            "Your team owner will need to purchase this feature to unlock it.",
          icon: "lock",
          submit: "Dismiss",
          onPress: () => {},
          cancel: "",
          onCancel: () => {}
        });
        this.props.toggleModal({ show: true, type: "normal" });
      }
    } else {
      this.props.onPress(value);
    }
  }

  render() {
    const {
      type,
      tier = 1,
      contentful_slug,
      slug,
      onPress = () => {},
      trackingId,
      billing_addons,
      plan_modules,
      user,
      onLockPress = null,
      onLockPressOverride = null,
      force_lock = false,
      force_unlock = false
    } = this.props;
    const { hovering } = this.state;

    let has_feature = false;
    switch (type) {
      default:
        break;
      case "billing_addon":
        has_feature = checkIfUserHasBillingAddon({
          billing_addons: billing_addons,
          slug: slug
        });
        break;
      case "plan":
        const plan_module_info = checkIfUserHasModule({
          plan_modules: plan_modules,
          user: user,
          slug: slug
        });
        if (plan_module_info.tier >= tier && plan_module_info.team_has_module) {
          has_feature = true;
        }
        break;
    }
    if (force_lock) {
      has_feature = false;
    }
    if (force_unlock) {
      has_feature = true;
    }

    return (
      <Button
        style={this.props.style}
        onHover={() => {
          this.setState({
            hovering: true
          });
        }}
        trackingId={trackingId}
        onHoverOut={() => {
          this.setState({
            hovering: false
          });
        }}
        onPress={() => {
          if (this.props.primary) {
            triggerHapticFeedback({ type: "impactHeavy" });
          } else {
            triggerHapticFeedback({ type: "impactLight" });
          }
          if (has_feature) {
            this.props.onPress();
          } else {
            if (onLockPress) {
              onLockPress();
            }

            if (onLockPressOverride) {
              onLockPressOverride();
            } else {
              this.props.setUpsellModal({
                type: type,
                slug,
                tier,
                contentful_slug
              });
              this.props.toggleUpsellModal(true);
            }
          }
        }}
      >
        {has_feature
          ? this.props.renderChildren({ hovering })
          : this.props.renderLockedChildren({ hovering })}
      </Button>
    );
  }
}

const mapStateToProps = ({ auth, native, billing }) => {
  const { token, user } = auth;
  const { platform } = native;
  const { billing_addons, plan_modules, plan_on_trial } = billing;

  return {
    token,
    user,
    platform,
    billing_addons,
    plan_modules,
    plan_on_trial
  };
};

export default connect(mapStateToProps, {
  setUpsellModal,
  toggleUpsellModal,
  toggleModal,
  setModal
})(LockButton);
