import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  KeyboardView,
  Spin,
  Title,
  Copy,
  DraggableList,
  Icon,
  Button,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  PillButton,
  SpinWrapper,
  AttentionBox,
  GhostButton,
  InlineButton,
  ConfirmLabel,
  SearchBar,
  UpsellButton
} from "app/NativeComponents/snippets";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";
import EditLeadStatus from "./EditLeadStatus";
import {
  popSidePanel,
  pushSidePanel,
  getLeadStatuses,
  updateLeadStatus,
  setModal,
  toggleModal,
  getLimitsForAllPlan
} from "app/NativeActions";

import LeadStatus from "./LeadStatus";

class CustomStatusesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: "",
      lead_statuses: props.lead_statuses || [],
      new_lead_status: null,
      active_lead_status: null,
      needs_to_save_order: false
    };

    this.setActiveLeadStatus = this.setActiveLeadStatus.bind(this);
    this.reorderLeadStatuses = this.reorderLeadStatuses.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.lead_statuses &&
      prevProps.lead_statuses !== this.props.lead_statuses
    ) {
      this.setState({
        lead_statuses: this.props.lead_statuses
      });
    }
  }

  setActiveLeadStatus(active_lead_status) {
    const reset_active = this.state.active_lead_status == active_lead_status;

    this.setState({
      active_lead_status: reset_active ? null : active_lead_status
    });
  }

  reorderLeadStatuses({ previousIndex, nextIndex }) {
    const { lead_statuses } = this.state;
    const reordered_statuses = JSON.parse(JSON.stringify(lead_statuses));
    const [removed] = reordered_statuses.splice(previousIndex, 1);
    reordered_statuses.splice(nextIndex, 0, removed);

    this.setState({
      needs_to_save_order: true,
      lead_statuses: reordered_statuses
    });
  }

  checkIfNeedsToSave() {
    if (
      JSON.stringify(this.props.lead_statuses) !==
      JSON.stringify(this.state.lead_statuses)
    ) {
      return true;
    }

    return false;
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  componentDidMount() {
    this.props.getLeadStatuses({
      token: this.props.token
    });
  }

  render() {
    const {
      lead_statuses,
      active_lead_status,
      new_lead_status,
      needs_to_save_order
    } = this.state;
    const { colors, plan_modules, source_of_truth } = this.props;

    const limits = getLimitsForAllPlan(source_of_truth);

    if (active_lead_status) {
      return (
        <EditLeadStatus
          lead_status={active_lead_status}
          setActiveLeadStatus={this.setActiveLeadStatus}
        />
      );
    }

    return (
      <Container>
        <NewHeader
          title={"Lead Statuses"}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
          /*
          rightButtonTitle={needs_to_save_order ? "Save Order" : ""}
          rightButtonPrimary={true}
          rightButtonAction={() => {
            this.saveChanges();
          }}
          */
        />

        {/*!limits.include_custom_statuses ? (
          <OnboardingText
            slug={"custom_statuses_onboarding"}
            style={{
              padding: 25,
              margin: 5
            }}
          />
        ) : null*/}
        <>
          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="check"
              label={"Order Changed"}
              primary={true}
              confirmButton={"Confirm Changes"}
              loading={this.state.loading}
              onPress={() => {
                this.props.updateLeadStatus({
                  token: this.props.token,
                  type: "update_lead_status_order",
                  lead_statuses: this.state.lead_statuses,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                  }
                });
              }}
            />
          ) : null}
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <SearchBar
                title={"Search Lead Statuses"}
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
                value={this.state.search}
                style={{
                  margin: 10,
                  marginRight: 0,
                  borderWidth: 0,
                  borderBottomWidth: 0
                }}
              />
            </Wrapper>

            <UpsellButton
              type="plan"
              slug="driving"
              tier={3}
              contentful_slug="unlock_feature"
              meta_slug="custom_statuses"
              force_unlock={
                limits?.include_custom_statuses &&
                this.props.user?.user_version < 6
              }
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "new_lead_status",
                  overlay: true
                });
              }}
              renderLockedChildren={({ hovering }) => {
                return (
                  <InlineButton
                    icon="lock"
                    primary={true}
                    style={{ marginRight: 0 }}
                    pressedIn={hovering}
                    noPress={true}
                    tooltipPlacement="top"
                  >
                    New Status
                  </InlineButton>
                );
              }}
              renderChildren={({ hovering }) => {
                return (
                  <InlineButton
                    icon="add"
                    primary={true}
                    style={{ marginRight: 0 }}
                    pressedIn={hovering}
                    noPress={true}
                    tooltipPlacement="top"
                  >
                    New Status
                  </InlineButton>
                );
              }}
            />
          </Row>
        </>

        {this.props.lead_statuses_loading ? (
          <SpinWrapper text={"Loading Statuses..."} />
        ) : lead_statuses?.length == 0 &&
          !new_lead_status &&
          !this.props.lead_statuses_loading &&
          limits.include_custom_statuses ? (
          <AttentionBox
            title="No Lead Statuses"
            description={
              "You haven't added any lead status. Add lead statuses to track the progress of your leads."
            }
          />
        ) : (
          <>
            <KeyboardView style={{ flex: 1 }}>
              {!!active_lead_status ||
              !limits.include_custom_statuses ||
              !!this.state.search ? (
                <>
                  {this.filterOptions(lead_statuses).map((lead_status, i) => {
                    if (
                      lead_status.status_type != "trash_status" &&
                      lead_status.status_type != "return_to_sender_status"
                    ) {
                      return (
                        <LeadStatus
                          {...this.props}
                          locked={
                            !limits.include_custom_statuses ? true : false
                          }
                          status_order={i}
                          key={"lead_status_" + i}
                          lead_status={lead_status}
                          active_lead_status={active_lead_status}
                          setActiveLeadStatus={this.setActiveLeadStatus}
                          updateLeadStatus={this.props.updateLeadStatus}
                          new_lead_status={new_lead_status}
                          needs_to_save_order={needs_to_save_order}
                          colors={colors}
                        />
                      );
                    }
                    return null;
                  })}
                </>
              ) : (
                <>
                  <DraggableList
                    items={this.filterOptions(lead_statuses)}
                    renderHandle={() => {
                      return (
                        <Wrapper
                          className="dm-drag"
                          style={{
                            padding: 15,
                            paddingLeft: 0,
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <Icon icon="drag-indicator" size={18} />
                        </Wrapper>
                      );
                    }}
                    renderItem={({ item, wrappedHandle, index }) => {
                      if (
                        item.status_type != "trash_status" &&
                        item.status_type != "return_to_sender_status"
                      ) {
                        return (
                          <LeadStatus
                            {...this.props}
                            wrappedHandle={wrappedHandle}
                            lead_status={item}
                            status_order={index}
                            active_lead_status={active_lead_status}
                            setActiveLeadStatus={this.setActiveLeadStatus}
                            updateLeadStatus={this.props.updateLeadStatus}
                            new_lead_status={new_lead_status}
                            needs_to_save_order={needs_to_save_order}
                            colors={colors}
                          />
                        );
                      }

                      return null;
                    }}
                    onReorder={({ previousIndex, nextIndex }) => {
                      this.reorderLeadStatuses({
                        previousIndex,
                        nextIndex
                      });
                    }}
                  />
                </>
              )}
            </KeyboardView>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  customsettings,
  billing
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { lead_statuses, lead_statuses_loading } = customsettings;
  const { plan_modules, source_of_truth } = billing;
  return {
    token,
    user,
    platform,
    colors,
    device,
    isMobile,
    lead_statuses,
    lead_statuses_loading,
    plan_modules,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  setModal,
  toggleModal,
  updateLeadStatus,
  getLeadStatuses
})(CustomStatusesModal);
