import { TRIGGER_LOGOUT } from "app/DealMachineCore/types";

import API from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = API.create();

export const getCashBuyers = ({
  token,
  centerLng,
  centerLat,
  zip,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getCashBuyers({
        token,
        centerLng,
        centerLat,
        zip
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};
