import React, { Component } from "react";
import { connect } from "react-redux";

import { List, SpinWrapper, AttentionBox } from "app/NativeComponents/snippets";
import MailerIssueItem from "./MailerIssueItem";

import {
  appRedirect,
  getMailFailedDeals,
  selectActiveProperty,
  pushSidePanel
} from "app/NativeActions";

class MailerIssues extends Component {
  componentDidMount() {
    this.getData({ load_type: "load", begin: 0 });
  }

  getData({ load_type, begin }) {
    const {
      token,
      mail_failed_properties_loading,
      mail_failed_properties_begin
    } = this.props;
    if (!mail_failed_properties_loading) {
      this.props.getMailFailedDeals({
        token: this.props.token,
        begin: begin,
        load_type: load_type
      });
    }
  }

  render() {
    const {
      mail_failed_properties,
      mail_failed_properties_loading,
      mail_failed_properties_begin,
      mail_failed_properties_loaded_all,
      mail_failed_properties_refreshing,
      sending_queue_counts,
      device,
      isMobile,
      colors
    } = this.props;

    if (mail_failed_properties.length == 0 && mail_failed_properties_loading) {
      return <SpinWrapper text="Loading mail issues..." />;
    }
    if (mail_failed_properties.length == 0) {
      return (
        <AttentionBox
          title={"There are no issues"}
          description={
            "All your mail pieces are sending as scheduled. If any issues arise, they will populate here."
          }
        />
      );
    }
    return (
      <List
        rowNumber={1}
        items={mail_failed_properties}
        infiniteScroll={true}
        itemStructure={({ item, index }) => {
          return (
            <MailerIssueItem
              key={"issue_" + index}
              token={this.props.token}
              user={this.props.user}
              item={item}
              colors={colors}
              device={device}
              isMobile={isMobile}
              appRedirect={this.props.appRedirect}
              selectActiveProperty={this.props.selectActiveProperty}
              pushSidePanel={this.props.pushSidePanel}
            />
          );
        }}
        canRefresh={false}
        onRefresh={() => {}}
        is_refreshing={false}
        canLoadMore={
          !mail_failed_properties_loaded_all &&
          !mail_failed_properties_loading &&
          !mail_failed_properties_refreshing &&
          mail_failed_properties.length > 0
        }
        isLoadingMore={
          mail_failed_properties_loading &&
          !mail_failed_properties_refreshing &&
          mail_failed_properties.length > 0
        }
        onLoadMore={() => {
          if (
            !mail_failed_properties_loaded_all &&
            mail_failed_properties_refreshing != true &&
            mail_failed_properties_loading != true
          ) {
            this.getData({
              load_type: "load_more",
              begin: mail_failed_properties_begin
            });
          }
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer, lead }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const {
    mail_failed_properties,
    mail_failed_properties_loading,
    mail_failed_properties_refreshing,
    mail_failed_properties_begin,
    mail_failed_properties_limit,
    mail_failed_properties_loaded_all
  } = lead;
  const { sending_queue_counts } = mailer;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,

    mail_failed_properties,
    mail_failed_properties_loading,
    mail_failed_properties_refreshing,
    mail_failed_properties_begin,
    mail_failed_properties_limit,
    mail_failed_properties_loaded_all,

    sending_queue_counts
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getMailFailedDeals,
  selectActiveProperty,
  pushSidePanel
})(MailerIssues);
