import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ModalContainer,
  ExternalImage,
  Spin,
  Copy,
  Bold,
  Row,
  Scroll,
  Icon,
  DropzoneButton
} from "app/NativeComponents/common";
import { FocusModal } from "app/NativeComponents/snippets";
import Navbar from "./Navbar";
import {
  getPropertyImages,
  popSidePanel,
  updatePropertyImage,
  checkIfHasGoogleStreetPic,
  checkIfUserHasBillingAddon,
  updateLeadPhotoUpload,
  uploadFile,
  randomString,
  determineDisplayProperty,
  togglePhotoGallery
} from "app/NativeActions";
import Photos from "app/DealMachineCore/reuseable/LeadPhotoManager/Photos";
class PhotoGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading_images: [],
      property: props.photo_gallery?.property || null
    };

    this.propertyUpdated = this.propertyUpdated.bind(this);
  }

  componentDidMount() {
    const { photo_gallery } = this.props;
    if (photo_gallery && photo_gallery.property) {
      this.props.getPropertyImages({
        token: this.props.token,
        lead_id: photo_gallery?.lead_id,
        no_loading:
          photo_gallery && photo_gallery?.images?.length > 0 ? true : false
      });
    }
  }

  propertyUpdated(property) {
    this.setState({
      property
    });

    if (this.props.photo_gallery?.propertyUpdated) {
      this.props.photo_gallery.propertyUpdated(property);
    }
  }

  uploadLeadPhoto(acceptedFiles) {
    const { token } = this.props;
    const { property } = this.state;
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const d = new Date();
      let unique_string = d.getTime();
      unique_string = unique_string + randomString(10) + ".jpeg";

      this.props.uploadFile({
        acceptedFiles: file,
        token,
        type: "lead_photo",
        payload: {
          lead_id: property.deal.id,
          unique_string
        },
        onLoading: () => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            file: file,
            type: "start"
          });
        },
        onSuccess: results => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            type: "complete",
            property: results.property
          });

          this.propertyUpdated(results.property);
        },
        onError: error => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            type: "error",
            error
          });
        },
        onProgress: progress => {
          this.props.updateLeadPhotoUpload({
            lead_id: property.deal.id,
            unique_string,
            type: "progress",
            progress
          });
        }
      });
    }
  }

  render() {
    const {
      isMobile,
      colors,
      device,
      photo_gallery,

      uploading_lead_images
    } = this.props;

    return (
      <>
        <FocusModal
          title="Photo Gallery"
          subtitle={
            "Viewing images for " +
            determineDisplayProperty(photo_gallery.property).property_address
          }
          onClose={() => {
            this.props.popSidePanel();
          }}
          show_side_bar={false}
          renderMainView={() => {
            return (
              <Scroll
                style={
                  isMobile
                    ? {
                        flex: 1,
                        alignSelf: "stretch",
                        display: "flex",
                        padding: 10
                      }
                    : {
                        alignSelf: "stretch",
                        display: "flex",
                        padding: 10
                      }
                }
              >
                <Row
                  style={
                    !isMobile
                      ? {
                          flexWrap: "wrap",
                          justifyContent: isMobile ? "center" : "flex-start",
                          alignItems: "flex-start",
                          alignSelf: "stretch"
                        }
                      : {
                          flexDirection: "column"
                        }
                  }
                >
                  <Photos
                    horizontal={isMobile ? false : true}
                    onStreetPickLockPress={() => {
                      this.props.togglePhotoGallery(false);
                    }}
                    listHeader={() => {
                      return (
                        <DropzoneButton
                          style={{
                            height: "auto",
                            alignSelf: "stretch",
                            cursor: "pointer"
                          }}
                          accept="image/jpeg, image/png"
                          multiple={true}
                          maxSize={5242880}
                          onDrop={acceptedFiles => {
                            this.uploadLeadPhoto(acceptedFiles);
                          }}
                        >
                          <Wrapper
                            style={{
                              width: isMobile ? "auto" : 380,
                              height: isMobile ? 250 : 250,
                              borderWidth: 1,
                              borderColor: colors.border_color,
                              borderStyle: "solid",
                              margin: 5,
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: colors.card_color,
                              marginTop: isMobile ? 5 : 0,
                              marginBottom: 5,
                              marginRight: isMobile ? 0 : 5
                            }}
                          >
                            <Icon icon="add-a-photo" size={32} />
                            <Copy
                              style={{ textAlign: "center", marginTop: 10 }}
                            >
                              <Bold>Upload A Photo</Bold>
                            </Copy>
                          </Wrapper>
                        </DropzoneButton>
                      );
                    }}
                    property={this.state.property}
                    images={photo_gallery.images}
                    photo_width={isMobile ? "100%" : 380}
                    photo_height={isMobile ? 250 : 250}
                    photoStyle={
                      isMobile
                        ? { marginTop: 5, marginBottom: 5 }
                        : { marginBottom: 5, marginRight: 5 }
                    }
                    include_menu={true}
                    propertyUpdated={this.propertyUpdated}
                  />
                </Row>
              </Scroll>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  billing,
  lead,
  property_map
}) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX } = native;
  const { colors } = settings;
  const { billing_addons } = billing;
  const { photo_gallery, toggle_photo_gallery, uploading_lead_images } = lead;
  const { property_images_loading } = property_map;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    billing_addons,
    photo_gallery,
    toggle_photo_gallery,
    uploading_lead_images,
    property_images_loading
  };
};

export default connect(mapStateToProps, {
  getPropertyImages,
  updatePropertyImage,
  uploadFile,
  updateLeadPhotoUpload,
  popSidePanel,
  togglePhotoGallery
})(PhotoGallery);
