import React, { Component } from "react";

import {
  Card,
  CardBody,
  Row,
  Spin,
  Title,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { Map } from "app/NativeComponents/snippets";

import route from "./route.json";
import moment from "moment";

class RealTimeDrivingRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: 0,
      lat: 39.959523948244396,
      long: -86.07703135356842
    };
  }

  componentDidMount() {
    this.startScene();
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  startScene() {
    clearInterval(this._interval);
  }

  renderSpinner() {
    return (
      <Card>
        <CardBody style={{ padding: "10px 15px" }}>
          <Copy>Move the map around to view this route!</Copy>
        </CardBody>
      </Card>
    );
  }

  render() {
    const routes = [];

    routes.push({
      ...route,
      start_time: moment().format("YYYY-MM-DD hh:mm:ss"),
      end_time: moment().format("YYYY-MM-DD hh:mm:ss")
    });

    return (
      <div
        style={{
          width: 320,
          height: 320,
          textAlign: "left"
        }}
      >
        <div className="animated fadeIn">
          <Card>
            <Map
              style={{
                height: 200
              }}
              lat={parseFloat(this.state.lat)}
              long={parseFloat(this.state.long)}
              zoom={16}
              initialRegion={{
                latitude: parseFloat(this.state.lat),
                longitude: parseFloat(this.state.long),
                latitudeDelta: 0.006566047668457031,
                longitudeDelta: 0.0032894482084486754
              }}
              onRegionChange={() => {}}
              onRegionChangeComplete={() => {}}
              locationSelect={() => {}}
              withMarker={false}
              cacheEnabled={false}
              dontGetPosition={true}
              onPress={() => {}}
              routes={routes}
            />
          </Card>
          {this.renderSpinner()}
        </div>
      </div>
    );
  }
}

export default RealTimeDrivingRoutes;
