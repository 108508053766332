import React, { useState, useRef, useEffect } from "react";
import {
  Wrapper,
  Icon,
  Row,
  Button,
  Copy,
  Bold
} from "app/NativeComponents/common";
import { SelectItem } from "app/NativeComponents/snippets";
import { store } from "app/store";
import Popover from "react-simple-popover";
import { useDispatch, useSelector } from "react-redux";
import { setActivePopover } from "app/NativeActions";

const PopoverMenu = props => {
  const [show, setShow] = useState(props.show || false);
  const [hovering, setHovering] = useState(false);
  const [activePop, setActivePop] = useState(null);
  const popoverRef = useRef(null);
  const dispatch = useDispatch();
  const { active_popover } = useSelector(state => state.modal);

  const toggleShow = toggle => {
    setShow(toggle);
    if (toggle) {
      let dp = Date.now();
      setActivePop(dp);

      dispatch(setActivePopover(dp));
    }
    if (props.onShow) {
      props.onShow(toggle);
    }
  };

  useEffect(() => {
    if (props.show) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [props.show]);

  useEffect(() => {
    if (active_popover != activePop && !props.allow_multiple) {
      toggleShow(false);
    }
  }, [active_popover]);

  const determinePlacement = (target, preferredPlacement, modalWidth) => {
    if (!target) return "right";
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const targetRect = target.getBoundingClientRect();
    const xPos = targetRect.x;
    const yPos = targetRect.y;

    const targetHeight = targetRect.height;
    const targetWidth = targetRect.width;

    const top = yPos;
    const left = xPos;
    const bottom = windowHeight - top - targetHeight;
    const right = windowWidth - left - targetWidth;

    let leftOrRight = "right";
    if (left > right) {
      leftOrRight = "left";
    }

    let topOrBottom = "bottom";
    if (top > bottom) {
      leftOrRight = "top";
    }

    switch (preferredPlacement) {
      case "right":
      default:
        if (right - modalWidth > 0) {
          return "right";
        } else if (left > right) {
          if (bottom > left) {
            return "bottom";
          } else if (top > left) {
            return "top";
          }
          return "left";
        }
        break;

      case "left":
        if (left - modalWidth > 0) {
          return "left";
        } else if (right > left) {
          if (bottom > right) {
            return "bottom";
          } else if (top > right) {
            return "top";
          }
          return "right";
        }
        break;

      case "top":
      case "bottom":
        if (top > bottom) {
          return "top";
        }
        return "bottom";
    }

    return preferredPlacement;
  };

  const renderPopover = () => {
    const {
      popover_width = 250,
      popover_height = "auto",
      popoverPlacement = "bottom",
      renderMenu = null,
      menu_items = [],
      hideWithOutsideClick = true,
      showPopoverOverlay = false,
      containerStyle,
      popoverStyle = null
    } = props;
    const colors = store.getState().settings.colors;

    if (show) {
      let placement = determinePlacement(
        popoverRef.current,
        popoverPlacement,
        popover_width
      );

      let zIndex = document.querySelectorAll(".deal-modal-overlay").length;
      if (!zIndex || zIndex <= 0) {
        zIndex = 1;
      }

      let s = {
        width: popover_width,
        height: popover_height,
        borderRadius: 5,
        padding: 0,
        overflow: "hidden",
        backgroundColor: colors.popover_color,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      };

      if (showPopoverOverlay) {
        s.boxShadow = "rgba(0, 0, 0, 50%) 0 0 0 10000px";
      }

      return (
        <Popover
          placement={placement}
          target={popoverRef.current}
          show={show}
          hideWithOutsideClick={hideWithOutsideClick}
          containerStyle={{
            opacity: 1,
            zIndex: zIndex,
            borderColor: "transparent transparent " + colors.popover_color,
            ...containerStyle
          }}
          className={"dealPops"}
          style={{
            ...s,
            ...popoverStyle
          }}
          arrowStyle={{ width: 20, height: 20 }}
          onHide={e => toggleShow(false)}
        >
          {renderMenu
            ? renderMenu()
            : menu_items &&
              menu_items.map((item, i) => {
                if (item) {
                  return (
                    <SelectItem
                      key={"option_" + i}
                      id={item.id}
                      primary={item.type == "primary"}
                      select_type={item.select_type || "none"}
                      select_icon={
                        item.select_type == "icon" && !!item.icon
                          ? item.icon
                          : ""
                      }
                      icon={
                        item.select_type !== "icon" && !!item.icon
                          ? item.icon
                          : ""
                      }
                      selected={item.selected}
                      onPress={() => {
                        item.onPress();
                        toggleShow(false);
                      }}
                      buttonContainerStyle={{ alignSelf: "stretch" }}
                      description={item.description}
                      disabled={item.disabled}
                      subtext={item.subtext}
                    >
                      {item.title}
                    </SelectItem>
                  );
                }
                return null;
              })}
        </Popover>
      );
    }
  };

  const {
    style,
    title,
    icon,
    color,
    icon_size = 18,
    renderComponent = null,
    tooltip,
    tooltipPlacement = "bottom",
    componentStyle,
    disabled = false,
    longPress = false
  } = props;
  const colors = store.getState().settings.colors;

  return (
    <Wrapper
      className={"dealPop"}
      wrapper_ref={popoverRef}
      style={componentStyle}
    >
      <Button
        tooltip={tooltip}
        tooltipPlacement={tooltipPlacement}
        disabled={disabled}
        onLongPress={
          !disabled
            ? longPress
              ? () => toggleShow(!show)
              : () => {}
            : () => {}
        }
        onPress={
          !disabled
            ? !longPress
              ? () => toggleShow(!show)
              : () => {}
            : () => {}
        }
        style={{
          alignItems: "center",
          justifyContent: "center",
          ...style
        }}
        onHover={!disabled ? () => setHovering(true) : () => {}}
        onHoverOut={!disabled ? () => setHovering(false) : () => {}}
      >
        {renderComponent ? (
          renderComponent({ hovering, pressedIn: show })
        ) : (
          <Row>
            {!!icon ? (
              <Icon
                color={color || colors.text_color}
                icon={icon}
                size={icon_size}
              />
            ) : null}
            {!!title ? (
              <Copy
                style={{
                  marginLeft: icon ? 5 : 0,
                  color: color || colors.text_color
                }}
              >
                <Bold>{title}</Bold>
              </Copy>
            ) : null}
          </Row>
        )}
      </Button>
      {renderPopover()}
    </Wrapper>
  );
};

export { PopoverMenu };
