import React from "react";
import {
  Button,
  CardBody,
  Split,
  Wrapper,
  Title,
  Copy,
  Icon
} from "app/NativeComponents/common";

const renderIcon = ({ value }) => {
  if (value) {
    return <Icon style={{ marginLeft: 10 }} icon="check-box" />;
  } else {
    return <Icon style={{ marginLeft: 10 }} icon="check-box-outline-blank" />;
  }
};

const renderCopy = (componentView, text) => {
  if (componentView) {
    return componentView();
  } else if (text && text != "") {
    return <Copy>{text}</Copy>;
  }
};

const CheckBoxInput = ({
  style,
  innerStyle,
  title,
  text,
  componentView,
  onPress,
  value,
  disabled = false,
  titleStyle = {}
}) => {
  if (disabled) {
    return (
      <CardBody style={innerStyle}>
        <Split>
          <Wrapper
            style={{
              marginRight: 10,
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            {renderIcon({ value })}
          </Wrapper>

          <Wrapper
            style={{
              flex: 1,
              alignSelf: "stretch",
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            <Title style={titleStyle}>{title}</Title>
            {renderCopy(componentView, text)}
          </Wrapper>
        </Split>
      </CardBody>
    );
  }
  return (
    <Button style={style} onPress={onPress}>
      <CardBody style={innerStyle}>
        <Split>
          <Wrapper
            style={{
              marginRight: 10,
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            {renderIcon({ value })}
          </Wrapper>

          <Wrapper
            style={{
              flex: 1,
              alignSelf: "stretch",
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            <Title style={titleStyle}>{title}</Title>
            {renderCopy(componentView, text)}
          </Wrapper>
        </Split>
      </CardBody>
    </Button>
  );
};

export { CheckBoxInput };
