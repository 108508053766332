import React, { Component } from "react";
import { connect } from "react-redux";

import {
  KeyboardView,
  Container,
  LeftPanelContainer,
  WebContainer,
  Wrapper,
  CardBody,
  Spin,
  Row,
  Copy
} from "app/NativeComponents/common";
import {
  NewHeader,
  SearchBar,
  BottomNavBar,
  InlineButton,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  popSidePanel,
  pushSidePanel,
  toggleModal,
  setModal,
  selectActiveTeamMember,
  getTeamMembers,
  toggleCanPop,
  lockDrawer,
  setInviteType,
  toggleDrawer,
  dismissMobileKeyboard
} from "app/NativeActions";

import TeamList from "./TeamList";

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_search: "",
      team_tab: "all",
      team_tab_title: "All"
    };
    this.updateTeamSearch = this.updateTeamSearch.bind(this);
    this.toggleTeamTab = this.toggleTeamTab.bind(this);

    this.handleBack = this.handleBack.bind(this);
  }

  updateTeamSearch(value) {
    this.setState({
      team_search: value
    });
  }

  toggleTeamTab({ value, title }) {
    this.setState({
      team_tab: value,
      team_tab_title: title
    });
  }

  componentDidMount() {
    this.props.selectActiveTeamMember(null);
    //TODO double check billing?
    this.loadItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.team_search !== this.state.team_search) {
      clearInterval(this._search_interval);
      this._search_interval = setTimeout(() => {
        this.loadItems();
      }, 250);
    } else if (prevState.team_tab !== this.state.team_tab) {
      this.loadItems();
    }
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  loadItems() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "load",
      type: this.state.team_tab,
      search: this.state.team_search,
      begin: 0
    });
  }

  renderBody() {
    if (this.props.team_members) {
      return (
        <>
          <TeamList
            team_search={this.state.team_search}
            team_tab={this.state.team_tab}
            team_tab_title={this.state.team_tab_title}
            updateTeamSearch={this.updateTeamSearch}
            toggleTeamTab={this.toggleTeamTab}
            {...this.props}
          />
        </>
      );
    }

    return null;
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  render() {
    if (
      !this.props.isMobile ||
      this.props.active_team_member == null ||
      this.props.device == "mobile"
    ) {
      return (
        <Container>
          <NewHeader
            title="Team"
            leftButton={{
              icon: "arrow-back",
              onPress: this.handleBack
            }}
          />
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <SearchBar
                title={"Search Team"}
                onChange={value => {
                  this.setState({
                    team_search: value
                  });
                }}
                value={this.state.team_search}
                style={{
                  margin: 10,
                  borderWidth: 0,
                  borderBottomWidth: 0,
                  marginRight: 0
                }}
              />
            </Wrapper>
            {this.props.user?.team_clearance_level > 1 ? (
              <>
                <InlineButton
                  onPress={() => {
                    this.props.pushSidePanel({
                      slug: "invite_by_email",
                      overlay: true
                    });
                  }}
                  style={{ marginRight: 0, marginLeft: 0 }}
                >
                  {this.props.isMobile ? "Invite" : "Invite By Email"}
                </InlineButton>
                <Wrapper
                  style={{
                    alignSelf: "stretch",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <UpsellButton
                    type="plan"
                    slug="driving"
                    contentful_slug="unlock_recruit_drivers"
                    tier={2}
                    meta_slug="driver_recruitment"
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "recruit_drivers",
                        overlay: true,
                        focus_side_panel: true
                      });
                    }}
                    renderLockedChildren={({ hovering }) => {
                      return (
                        <InlineButton
                          icon="lock"
                          noPress={true}
                          pressedIn={hovering}
                          primary={true}
                        >
                          Recruit Drivers
                        </InlineButton>
                      );
                    }}
                    renderChildren={({ hovering }) => {
                      return (
                        <InlineButton
                          noPress={true}
                          pressedIn={hovering}
                          primary={true}
                        >
                          Recruit Drivers
                        </InlineButton>
                      );
                    }}
                  />
                </Wrapper>
              </>
            ) : null}
          </Row>
          <KeyboardView style={{ flex: 1 }}>{this.renderBody()}</KeyboardView>
        </Container>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, billing, team }) => {
  const { token, user } = auth;
  const { colors } = settings;

  const { device, platform, isMobile } = native;

  const { plan_modules, card_info, source_of_truth } = billing;

  const {
    active_team_member,
    team_members,
    team_members_loading,
    team_members_error,
    team_members_refreshing,
    team_members_loaded_all,
    team_members_limit,
    team_members_begin,
    team_members_search
  } = team;

  return {
    token,
    user,
    device,
    platform,
    isMobile,

    plan_modules,
    card_info,

    colors,

    active_team_member,
    team_members,
    team_members_loading,
    team_members_error,
    team_members_refreshing,
    team_members_loaded_all,
    team_members_limit,
    team_members_begin,
    team_members_search,

    source_of_truth
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  popSidePanel,
  pushSidePanel,
  toggleModal,
  setModal,
  selectActiveTeamMember,
  getTeamMembers,
  toggleCanPop,
  lockDrawer,
  setInviteType,
  toggleDrawer
})(Team);
