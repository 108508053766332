import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Button,
  Icon,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { InlineButton } from "app/NativeComponents/snippets";

import {
  appRedirect,
  switchMarketingTab,
  popSidePanel,
  pushSidePanel
} from "app/NativeActions";

class PausedCard extends Component {
  render() {
    const { colors, user, style, onSuccess = null } = this.props;
    if (user.pause_sending == 1) {
      //all mailers are paused

      return (
        <Wrapper
          style={{
            backgroundColor: colors.orange_color_muted,
            margin: 10,
            borderRadius: 5,
            ...style
          }}
        >
          <Row>
            <Wrapper style={{ flex: 1, padding: 25 }}>
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>The Mail Queue is paused</Bold>
                  </Copy>
                  <Copy>
                    Mail will not be sent out until you resume the sending
                    queue.
                  </Copy>
                </Wrapper>
              </Row>
            </Wrapper>
            <InlineButton
              icon_right="keyboard-arrow-right"
              primary={true}
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "mail_queue",
                  overlay: true
                });

                if (onSuccess) {
                  onSuccess();
                }
              }}
            >
              Mail Queue
            </InlineButton>
          </Row>
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { token, user } = auth;
  const { colors } = settings;
  return {
    token,
    user,
    colors
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  switchMarketingTab,

  popSidePanel,
  pushSidePanel
})(PausedCard);
