import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";
import { InlineButton } from "app/NativeComponents/snippets";

class CancelButton extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    this.props.toggleModal({ show: false, type: null });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    if (this.props.cancel && this.props.cancel != "") {
      return (
        <InlineButton
          disabled={this.props.submit_loading}
          onPress={this.handleCancel}
          button_type="full"
        >
          {this.props.cancel}
        </InlineButton>
      );
    }
    return null;
  }
}

export default CancelButton;
