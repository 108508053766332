import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Input,
  MultiLineInput,
  KeyboardView
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  PillButton,
  SelectMultiple,
  SmallHeader,
  SpinWrapper,
  GhostButton,
  InputBox
} from "app/NativeComponents/snippets";
import BracketPicker from "./BracketPicker";
import { getTeamMembers } from "app/NativeActions";

class InternalCommunicationStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.active_step?.data
        ? props.active_step.data
        : {
            send_to_users: [],
            subject: "",
            body: ""
          }
    };
  }

  componentDidMount() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "replace",
      type: "accepted_members_and_dealfinders",
      begin: 0
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (
        this.state.data?.send_to_users.length > 0 &&
        !!this.state.data?.subject &&
        !!this.state.data?.body
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading,
      team_members,
      team_members_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title={
              active_step.type == "send_internal_email"
                ? "Send internal email notification"
                : active_step.type == "send_internal_push_notification"
                ? "Send internal in-app notification"
                : "Internal communication"
            }
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {enrollment_object_loading || team_members_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <SelectMultiple
                  options={[
                    {
                      id: "all_team_members",
                      value: "all_team_members",
                      label: "All Team Members"
                    },
                    {
                      id: "lead_owner",
                      value: "lead_owner",
                      label: "Lead Owner"
                    },
                    ...this.props.team_members
                  ]}
                  selected_options={data.send_to_users}
                  placeholder={"Send to team members:"}
                  search_title="Search Team"
                  onSelectedOptionsChange={selected_options => {
                    this.setState({
                      data: {
                        ...data,
                        send_to_users: selected_options
                      }
                    });
                  }}
                />

                <InputBox
                  ref="subject"
                  style={{ flex: 1 }}
                  autoFocus={false}
                  name="subject"
                  returnKeyType="done"
                  placeholder="Subject"
                  onChange={value => {
                    this.setState({
                      data: {
                        ...data,
                        subject: value
                      }
                    });
                  }}
                  onSubmitEditing={() => {}}
                  blurOnSubmit={false}
                  value={data.subject}
                  type="text"
                  renderRight={() => {
                    return (
                      <BracketPicker
                        onSelect={bracket => {
                          this.setState({
                            data: {
                              ...data,
                              subject: data.subject + "{{" + bracket.key + "}}"
                            }
                          });
                        }}
                        enrollment_object={enrollment_object}
                        enrollment_object_loading={enrollment_object_loading}
                      />
                    );
                  }}
                />

                <Wrapper
                  style={{
                    position: "relative"
                  }}
                >
                  <InputBox
                    name="body"
                    returnKeyType="default"
                    placeholder="Body"
                    onChange={value => {
                      this.setState({
                        data: {
                          ...data,
                          body: value
                        }
                      });
                    }}
                    onFocus={() => {}}
                    selected_background_color={"transparent"}
                    hover_background_color={"transparent"}
                    blurOnSubmit={false}
                    value={data.body}
                    input_type="text"
                    type={"multiline"}
                    numberOfLines={5}
                    autogrow={true}
                  />
                  <Wrapper
                    style={{
                      alignSelf: "stretch",
                      position: "absolute",
                      top: 0,
                      right: 0
                    }}
                  >
                    <BracketPicker
                      onSelect={bracket => {
                        this.setState({
                          data: {
                            ...data,
                            body: data.body + "{{" + bracket.key + "}}"
                          }
                        });
                      }}
                      enrollment_object={enrollment_object}
                      enrollment_object_loading={enrollment_object_loading}
                    />
                  </Wrapper>
                </Wrapper>
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ workflow, team }) => {
  const {
    enrollment_object,
    sample_request_object,
    enrollment_object_loading
  } = workflow;
  const { team_members, team_members_loading } = team;

  return {
    enrollment_object,
    sample_request_object,
    enrollment_object_loading,
    team_members,
    team_members_loading
  };
};

export default connect(mapStateToProps, { getTeamMembers })(
  InternalCommunicationStep
);
