import React, { Component } from "react";

import { PrimaryCTA, SecondaryCTA } from "app/NativeComponents/website/common";

class CallToActions extends Component {
  render() {
    if (this.props.section.callToActions) {
      if (this.props.section.callToActions.length > 0) {
        return (
          <div className="dm-website-ctas">
            {this.props.section.callToActions.map((cta, i) => {
              if (i == 0) {
                return (
                  <PrimaryCTA
                    key={i}
                    to={cta.fields.buttonLink}
                    slug={cta.fields.slug}
                  >
                    {cta.fields.buttonText}
                  </PrimaryCTA>
                );
              } else {
                return (
                  <SecondaryCTA
                    key={i}
                    to={cta.fields.buttonLink}
                    slug={cta.fields.slug}
                  >
                    {cta.fields.buttonText}
                  </SecondaryCTA>
                );
              }
            })}
          </div>
        );
      }
    }

    return <div />;
  }
}

export default CallToActions;
