import React, { PureComponent } from "react";
import {
  Button,
  Gradient,
  Icon,
  Wrapper,
  Row,
  Card,
  CardBody,
  Copy,
  Bold
} from "app/NativeComponents/common";

class ActionButton extends PureComponent {
  renderText(text) {
    if (text && text != "") {
      return (
        <Card style={{ alignSelf: "inherit" }}>
          <CardBody style={{ padding: 5, paddingRight: 10, paddingLeft: 10 }}>
            <Copy>
              <Bold>{text}</Bold>
            </Copy>
          </CardBody>
        </Card>
      );
    }
  }

  render() {
    return (
      <Button
        onPress={this.props.onPress}
        style={{
          margin: 15,
          marginTop: 0
        }}
      >
        <Row
          style={{
            justifyContent: "flex-end"
          }}
        >
          {this.renderText(this.props.text)}
          <Gradient
            style={{
              borderRadius: 30,
              width: 60,
              height: 60,
              shadowColor: "#000",
              shadowOpacity: 0.1,
              shadowOffset: { width: 0, height: 2 },
              elevation: 5
            }}
            color1={this.props.color1}
            color2={this.props.color2}
          >
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flex: 1,
                alignSelf: "stretch",
                height: "100%"
              }}
            >
              <Icon
                icon={this.props.icon}
                size={26}
                color={this.props.iconColor}
              />
            </Wrapper>
          </Gradient>
        </Row>
      </Button>
    );
  }
}

export { ActionButton };
