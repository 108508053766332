import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  DropzoneButton
} from "app/NativeComponents/common";
import { NewHeader, GhostButton } from "app/NativeComponents/snippets";
import { popSidePanel, uploadList } from "app/NativeActions";
import UploadMapping from "./UploadMapping";
class ImportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      upload_list_info: null
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    const { isMobile, device, colors } = this.props;
    const { uploading, upload_list_info } = this.state;

    const subtitle = upload_list_info
      ? upload_list_info.row_count === 1
        ? "Importing 1 Leads"
        : "Importing " + upload_list_info.row_count + " Leads"
      : "";
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"Import List"}
          subtitle={subtitle}
          leftButton={{
            icon: isMobile ? "arrow-back" : "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        {upload_list_info ? (
          <>
            <UploadMapping upload_list_info={upload_list_info} />
          </>
        ) : (
          <KeyboardView style={{ flex: 1 }}>
            <Wrapper style={{ padding: 20 }}>
              <Copy>Upload an excel, .csv, or .sheet file to get started.</Copy>
              <Copy style={{ paddingTop: 10 }}>
                For optimal performance we recommend imports of 10,000 leads or
                less.
              </Copy>
            </Wrapper>
            <DropzoneButton
              style={{
                height: "auto",
                alignSelf: "stretch",
                cursor: "pointer"
              }}
              multiple={false}
              accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              upload_type="list"
              onDrop={acceptedFiles => {
                this.props.uploadList({
                  acceptedFiles: acceptedFiles,
                  token: this.props.token,
                  type: "bulk_list",
                  onLoading: () => {
                    this.setState({
                      uploading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      uploading: false
                    });
                  },
                  onSuccess: results => {
                    this.setState({
                      uploading: false,
                      upload_list_info: results
                    });
                  }
                });
              }}
              renderComponent={options => {
                return (
                  <GhostButton
                    icon="file-upload"
                    primary={true}
                    noPress={true}
                    pressedIn={options.hovering}
                    button_type="full"
                    loading={uploading}
                    loading_text="Uploading..."
                  >
                    Upload List
                  </GhostButton>
                );
              }}
            ></DropzoneButton>
          </KeyboardView>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, { popSidePanel, uploadList })(
  ImportList
);
