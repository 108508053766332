import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  pushSidePanel,
  selectActiveTeamMember
} from "app/NativeActions";

class MoreSettings extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.selectActiveTeamMember("collect_dealfinders");
  }
  componentWillUnmount() {
    this.props.selectActiveTeamMember(null);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  render() {
    return (
      <Container>
        <NewHeader
          title="More Settings"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {this.props.user?.team_clearance_level > 1 ||
          this.props.user?.can_mimic_team_owner == 1 ? (
            <SelectItem
              select_type="none"
              icon="keyboard-arrow-right"
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "enter_promo_code",
                  overlay: true
                });
              }}
            >
              Enter Referral Code
            </SelectItem>
          ) : null}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel,
  selectActiveTeamMember
})(MoreSettings);
