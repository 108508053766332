import React, { Component } from "react";
import { connect } from "react-redux";

import { Scroll, Row, Wrapper, Copy, Bold } from "app/NativeComponents/common";

import { numberWithCommas } from "app/NativeActions";

class CompsProperties extends Component {
  render() {
    return (
      <Row
        style={{
          padding: 25,
          paddingTop: 0,
          paddingBottom: 25,
          margin: 5
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            paddingRight: 15
          }}
        >
          <Copy
            style={{
              fontSize: 10,
              whiteSpace: "nowrap"
            }}
          >
            Estimated Value:
          </Copy>
          <Copy
            style={{
              whiteSpace: "nowrap",
              color: this.props.colors?.orange_color
            }}
          >
            <Bold>
              {this.props.estimated_value == 0
                ? "--"
                : "$" + numberWithCommas(this.props.estimated_value)}
            </Bold>
          </Copy>
        </Wrapper>
        <Wrapper
          style={{
            flex: 1,
            paddingRight: 15,
            paddingLeft: 15
          }}
        >
          <Copy
            style={{
              fontSize: 10,

              whiteSpace: "nowrap"
            }}
          >
            {"Average $/sqft"}
          </Copy>
          <Copy
            style={{
              whiteSpace: "nowrap"
            }}
          >
            <Bold>
              {this.props.average_sqft == 0
                ? "--"
                : "$" + numberWithCommas(this.props.average_sqft)}
            </Bold>
          </Copy>
        </Wrapper>
        <Wrapper
          style={{
            flex: 1,
            paddingLeft: 15
          }}
        >
          <Copy
            style={{
              fontSize: 10,
              whiteSpace: "nowrap"
            }}
          >
            Average Price:
          </Copy>
          <Copy
            style={{
              whiteSpace: "nowrap"
            }}
          >
            <Bold>
              {this.props.average_price == 0
                ? "--"
                : "$" + numberWithCommas(this.props.average_price)}
            </Bold>
          </Copy>
        </Wrapper>
      </Row>
    );
  }
}

export default CompsProperties;
