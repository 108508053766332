import React, { Component } from "react";
import { Wrapper, Card, Row, Button, Copy } from "app/NativeComponents/common";

import { GhostButton, InlineButton } from "app/NativeComponents/snippets";
class Buttons extends Component {
  render() {
    return (
      <>
        <GhostButton
          formButton
          disabled={this.props.loading ? true : false}
          loading={this.props.loading}
          primary={true}
          button_type="full"
          style={{ alignSelf: "stretch" }}
        >
          {this.props.buttonText ? this.props.buttonText : "Complete"}
        </GhostButton>

        {!!this.props.cancelButton ? (
          <InlineButton onPress={this.props.onCancel} button_type="full">
            Cancel Update
          </InlineButton>
        ) : null}
      </>
    );
  }
}

export default Buttons;
