import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  KeyboardView
} from "app/NativeComponents/common";
import {
  SmallHeader,
  SelectItem,
  SpinWrapper,
  GhostButton,
  BottomNavBar
} from "app/NativeComponents/snippets";
import { toTitleCase } from "app/NativeActions";
import { getEnrollmentTemplates } from "app/NativeActions";
import TestCriteria from "./TestCriteria";

import AdvancedFilters from "app/DealMachineCore/reuseable/AdvancedFilters";

class TriggerStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.workflow?.enrollment_triggers,
      only_enroll_manually: props.workflow?.only_enroll_manually
    };
  }

  checkIfNeedsToSave() {
    if (
      (this.state.filters?.data !== this.props.workflow?.enrollment_triggers &&
        this.state.filters?.data.length > 0) ||
      this.state.only_enroll_manually !==
        this.props.workflow?.only_enroll_manually
    ) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.getTotalCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters)
    ) {
      this.getTotalCount();
    }
  }

  getTotalCount() {
    if (
      this.state.filters &&
      this.state.filters.data &&
      this.state.filters.data.length > 0 &&
      !this.props.total_enrollment_count_loading
    ) {
      this.props.getWorkflowInfo({
        token: this.props.token,
        type: "get_total_enrollment_count",
        filters: this.state.filters
      });
    }
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading,
      enrollment_templates,
      enrollment_templates_loading,
      total_enrollment_count_loading,
      total_enrollment_count
    } = this.props;
    const { only_enroll_manually, filters } = this.state;
    if (active_step) {
      return (
        <>
          <Row style={{ justifyContent: "space-between" }}>
            <SmallHeader
              title="Enrollment Triggers"
              leftButton={{
                icon: "close",
                onPress: () => {
                  this.props.setActiveStep({ step: null });
                }
              }}
            />

            <TestCriteria
              {...this.props}
              filters={{
                data: filters.data,
                andor_type: filters.andor_type
              }}
              disabled={only_enroll_manually || filters?.data.length == 0}
            />
          </Row>

          <KeyboardView className="dm-look" style={{ flex: 1 }}>
            {enrollment_object_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <Wrapper style={{ padding: 25 }}>
                  <Copy style={{ marginBottom: 10 }}>
                    Trigger a workflow when the following criteria is met:
                  </Copy>
                  <Copy
                    style={{ fontSize: 12, color: colors.light_text_color }}
                  >
                    *{toTitleCase(workflow.category)} can always be enrolled
                    manually.
                  </Copy>
                </Wrapper>

                <SelectItem
                  select_type={"check"}
                  selected={only_enroll_manually}
                  onPress={() => {
                    this.setState({
                      only_enroll_manually: !only_enroll_manually,
                      filter: filters
                    });
                  }}
                >
                  Only allow leads to be enrolled manually.
                </SelectItem>

                {!only_enroll_manually ? (
                  <AdvancedFilters
                    {...this.props}
                    filters={filters}
                    onChange={filters => {
                      this.setState({
                        only_enroll_manually: false,
                        filters
                      });
                    }}
                  />
                ) : null}
              </>
            )}

            <Wrapper style={{ height: isMobile ? 200 : 0 }} />
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              primary={true}
              button_type={"full"}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveEnrollmentTriggers({
                  filters: this.state.filters,
                  only_enroll_manually
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ workflow }) => {
  const {
    enrollment_object,
    enrollment_object_loading,
    enrollment_templates,
    enrollment_templates_loading
  } = workflow;
  return {
    enrollment_object,
    enrollment_object_loading,
    enrollment_templates,
    enrollment_templates_loading
  };
};

export default connect(mapStateToProps, { getEnrollmentTemplates })(
  TriggerStep
);
