import React, { Component } from "react";
import { Wrapper, Copy, Bold, Button, Row } from "app/NativeComponents/common";
import { PopoverMenu } from "app/NativeComponents/snippets";

class DelayComponent extends Component {
  render() {
    const {
      is_active,
      onPress,
      step,
      colors,
      workflow,
      active_step,
      step_index,
      branch_index = null,
      branch_step_index = null
    } = this.props;
    if (step) {
      return (
        <Button
          onPress={onPress}
          style={{
            borderColor: is_active ? colors.active_color : colors.border_color,
            backgroundColor: colors.card_color,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 5,
            margin: "auto",
            width: 350
          }}
        >
          <Wrapper>
            <Wrapper
              style={{
                padding: 15,
                borderBottomColor: is_active
                  ? colors.active_color
                  : colors.border_color,
                borderBottomWidth: 1,
                borderBottomStyle: "solid"
              }}
            >
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>
                      {step.type == "delay_set_amount_of_time"
                        ? "Delay for a set amount of time"
                        : step.type == "delay_until_day_or_time"
                        ? "Delay until a day or time"
                        : "Delay"}
                    </Bold>
                  </Copy>
                </Wrapper>
                <PopoverMenu
                  icon={"more-vert"}
                  style={{ paddingLeft: 15 }}
                  icon_size={18}
                  menu_items={[
                    {
                      title: "Delete Component",
                      icon: "delete",
                      type: "delete",
                      onPress: () => {
                        this.props.setModal({
                          title: "Delete Component?",
                          description:
                            "Are you sure you want to delete this workflow component?",
                          submit: "Delete Component",
                          icon: "delete",
                          buttonType: "destroy",
                          onPress: () => {
                            this.props.deleteStep({
                              step_index,
                              branch_index,
                              branch_step_index
                            });
                          },
                          cancel: "",
                          onCancel: () => {}
                        });
                        this.props.toggleModal({
                          show: true,
                          type: "normal"
                        });
                      }
                    }
                  ]}
                />
              </Row>
            </Wrapper>
            <Wrapper>
              {is_active ? (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Configuring...
                  </Copy>
                </Wrapper>
              ) : step.data ? (
                <Wrapper style={{ padding: 20 }}>
                  {step.type == "delay_set_amount_of_time" ? (
                    <Wrapper>
                      <Copy>
                        Wait{" "}
                        {step.data.days == 1 ? (
                          <Bold>1 day</Bold>
                        ) : (
                          <Bold>{parseInt(step.data.days || 0)} days</Bold>
                        )}
                        {", "}
                        {step.data.hours == 1 ? (
                          <Bold>1 hour</Bold>
                        ) : (
                          <Bold>{parseInt(step.data.hours || 0)} hours</Bold>
                        )}
                        {" and "}
                        {step.data.minutes == 1 ? (
                          <Bold>1 minute</Bold>
                        ) : (
                          <Bold>
                            {parseInt(step.data.minutes || 0)} minutes
                          </Bold>
                        )}
                      </Copy>
                    </Wrapper>
                  ) : step.type == "delay_until_day_or_time" ? (
                    <Wrapper>
                      <Copy>
                        Wait until{" "}
                        {step.data.delay_until_a_day_of_week ? (
                          <Bold>{step.data.day_of_week}</Bold>
                        ) : null}
                        {step.data.delay_until_a_day_of_week &&
                        step.data.delay_until_a_time_of_day
                          ? " at "
                          : null}
                        {step.data.delay_until_a_time_of_day ? (
                          <Bold>
                            {step.data.time_of_day_time}{" "}
                            {step.data.time_of_day_timezone}
                          </Bold>
                        ) : null}
                      </Copy>
                    </Wrapper>
                  ) : null}
                </Wrapper>
              ) : (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Not configured
                  </Copy>
                </Wrapper>
              )}
            </Wrapper>
          </Wrapper>
        </Button>
      );
    }
    return null;
  }
}

export default DelayComponent;
