import React, { Component } from "react";

import {
  Card,
  CardBody,
  PrimaryButton,
  SecondaryButton,
  Copy,
  Bold,
  Title,
  Row,
  Wrapper
} from "app/NativeComponents/common";

import Typist from "react-typist";

class Scene2 extends Component {
  render() {
    if (this.props.scene == 4) {
      return (
        <div className={"animated fadeInDown"}>
          <Card>
            <CardBody
              style={{
                padding: "10px 15px"
              }}
            >
              <Row>
                <Wrapper
                  style={{
                    marginRight: 10
                  }}
                >
                  <img
                    alt=""
                    style={{
                      width: 30,
                      height: 30,
                      objectFit: "cover",
                      borderRadius: "50%"
                    }}
                    src="/assets/images/dave.jpg"
                  />
                </Wrapper>

                <Wrapper>
                  <div
                    style={{
                      fontSize: 12,
                      lineHeight: "18px"
                    }}
                  >
                    <b>Dave Oster wrote a note.</b>
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      lineHeight: "26px"
                    }}
                  >
                    <b>@DavidLecko</b> make sure to follow up with this lead!
                  </div>
                </Wrapper>
              </Row>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene2;
