import React, { Component } from "react";

import {
  Wrapper,
  Button,
  Copy,
  Bold,
  Title,
  Spin,
  Row
} from "app/NativeComponents/common";

import { store } from "app/store";
class StatBlock extends Component {
  render() {
    const colors = store.getState().settings.colors;
    const isMobile = store.getState().native.isMobile;

    const { smallBlock, onPress } = this.props;
    if (onPress) {
      return (
        <Button
          onPress={onPress}
          style={{
            margin: 5,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Wrapper
            style={{
              backgroundColor: colors.background_color,
              padding: isMobile ? 10 : 25,
              minWidth: smallBlock || isMobile ? 50 : 75,
              margin: 5,
              borderRadius: 5
            }}
          >
            <Title style={{ textAlign: "center" }}>
              {this.props.loading ? "--" : this.props.number || "--"}
            </Title>
          </Wrapper>
          <Row>
            {this.props.loading ? (
              <Wrapper style={{ marginRight: 5 }}>
                <Spin size={"small"} style={{ marginRight: 5 }} />
              </Wrapper>
            ) : null}
            <Copy
              style={{
                textAlign: "center",
                fontSize: smallBlock || isMobile ? 10 : 14
              }}
            >
              <Bold>{this.props.title}</Bold>
            </Copy>
          </Row>
        </Button>
      );
    } else {
      return (
        <Wrapper
          style={{
            margin: 5,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Wrapper
            style={{
              backgroundColor: colors.background_color,
              padding: 25,
              minWidth: smallBlock || isMobile ? 50 : 75,
              margin: 5,
              borderRadius: 5
            }}
          >
            <Title style={{ textAlign: "center" }}>
              {this.props.loading ? "--" : this.props.number || "--"}
            </Title>
          </Wrapper>
          <Row>
            {this.props.loading ? (
              <Wrapper style={{ marginRight: 5 }}>
                <Spin size={"small"} style={{ marginRight: 5 }} />
              </Wrapper>
            ) : null}
            <Copy
              style={{
                textAlign: "center",
                fontSize: smallBlock || isMobile ? 12 : 14
              }}
            >
              <Bold>{this.props.title}</Bold>
            </Copy>
          </Row>
        </Wrapper>
      );
    }
  }
}

export default StatBlock;
