import React, { Component } from "react";
import { connect } from "react-redux";

import { Row } from "app/NativeComponents/common";

import {
  addDeal,
  updateLead,
  setModal,
  updateModal,
  toggleModal
} from "app/NativeActions";
import AssignButton from "./AssignButton";
import ListsButton from "./ListsButton";
import StatusButton from "./StatusButton";
import TagsButton from "./TagsButton";
import DeleteButton from "./DeleteButton";

class TopPropertyOptions extends Component {
  render() {
    const { colors, user, property } = this.props;
    return (
      <>
        <Row style={{ paddingTop: 15, flexWrap: "wrap", alignSelf: "stretch" }}>
          {property?.deal &&
          !property?.deal?.removed &&
          !property?.deal?.archived &&
          property?.deal?.deal_status_id ? (
            <>
              <AssignButton {...this.props} />
              <ListsButton {...this.props} />
              <TagsButton {...this.props} />
              <DeleteButton {...this.props} />
            </>
          ) : null}
          <StatusButton {...this.props} />
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, property_map, billing }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { add_deal_loading } = property_map;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    colors,
    add_deal_loading,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  addDeal,
  updateLead,
  setModal,
  updateModal,
  toggleModal
})(TopPropertyOptions);
