import React, { Component } from "react";
import { connect } from "react-redux";
import { ModalContainer, Copy } from "app/NativeComponents/common";
import WorkflowDesigner from "./WorkflowDesigner";
import { toggleWorkflowDesigner } from "app/NativeActions";

class WorkflowDesignerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { active_workflow, toggle_workflow_designer, device } = this.props;
    if (active_workflow && toggle_workflow_designer) {
      return <WorkflowDesigner />;
    }

    return null;
  }
}

const mapStateToProps = ({ workflow, native }) => {
  const { active_workflow, toggle_workflow_designer } = workflow;
  const { device } = native;
  return {
    active_workflow,
    toggle_workflow_designer,
    device
  };
};

export default connect(mapStateToProps, {
  toggleWorkflowDesigner
})(WorkflowDesignerModal);
