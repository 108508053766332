import React, { Component } from "react";
import { connect } from "react-redux";

import {
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata
} from "app/DealMachineCore/actions/BillingActions";

import {
  Wrapper,
  Title,
  Copy,
  Spin,
  Button,
  InternalImage,
  KeyboardView
} from "app/NativeComponents/common";
import {
  SpinWrapper,
  List,
  GhostButton,
  AttentionBox,
  InlineButton
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import QueueItem from "./QueueItem";

import {
  appRedirect,
  getSendingQueue,
  getSendingQueueCounts,
  updateUser,
  updateLead,
  selectActiveProperty,
  pushSidePanel
} from "app/NativeActions";
import { Link } from "react-router-dom";

class Queue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pause_sending_loading: false
    };
  }
  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    const { token, sending_queue_loading, sending_queue_begin } = this.props;
    if (!sending_queue_loading) {
      this.props.getSendingQueue({
        token,
        load_type,
        begin: load_type == "load" ? 0 : sending_queue_begin
      });
    }
  }

  renderPauseCard(selected_template_type) {
    const {
      token,
      device,
      colors,
      user,
      sending_queue_counts,
      all_feature_metadata,
      platform
    } = this.props;
    const displayMailTrackingText = !checkIfUserHasMetadata("mail_tracking");
    return (
      <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
        {displayMailTrackingText ? (
          <Wrapper
            style={{
              alignItems: "center",
              flexDirection: this.props.isMobile ? "column" : "row",
              padding: 25
            }}
          >
            <InternalImage
              style={{
                width: 100,
                height: 100
              }}
              contain={true}
              source={require("app/assets/images/MailTrackingIcon.png")}
              image={"/assets/images/MailTrackingIcon.png"}
            />
            <Copy
              style={{
                marginLeft: 20
              }}
            >
              Receive notifications when your mail arrives when you
            </Copy>
            <Button
              style={{ alignSelf: "center", marginLeft: 10 }}
              onPress={() => {
                if (user?.user_version >= 6) {
                  let feature_upsell_info = null;
                  for (let i = 0; i < all_feature_metadata?.length; i++) {
                    if (all_feature_metadata[i].slug == "mail_tracking") {
                      feature_upsell_info = all_feature_metadata[i];
                    }
                  }
                  this.props.pushSidePanel({
                    slug: "unlock_feature_modal",
                    overlay: true,
                    data: {
                      feature_upsell_info
                    }
                  });
                } else {
                  this.props.pushSidePanel({
                    slug: "plan_settings",
                    overlay: true
                  });
                }
              }}
            >
              <Copy
                style={{
                  color: this.props.colors.active_color,
                  textDecoration: "underline"
                }}
              >
                Unlock Mail Tracking
              </Copy>
            </Button>
          </Wrapper>
        ) : null}
        <AttentionBox
          primary={user.pause_sending == 1}
          title={
            user.pause_sending == 1
              ? "The mail queue is paused."
              : "You're sending mail."
          }
          renderDescription={() => {
            return (
              <>
                <Wrapper style={{ paddingBottom: 25 }}>
                  <Copy>
                    {user.pause_sending == 1
                      ? "Mail pieces in the queue will not be sent until you resume sending. Mail Sequences will continue to add mail pieces to the queue until you pause or end them. You have " +
                        sending_queue_counts.sending_queue +
                        " mail piece(s) in the queue and " +
                        sending_queue_counts.scheduled_today +
                        " other mail piece(s) scheduled to be added later today."
                      : "You have " +
                        sending_queue_counts.sending_queue +
                        " mail piece(s) in the queue. These will send within the hour. There are also " +
                        sending_queue_counts.scheduled_today +
                        " other mail piece(s) scheduled to be sent today."}
                  </Copy>
                </Wrapper>
                {user.pause_sending == 1 ? (
                  <GhostButton
                    primary={true}
                    button_type="full"
                    loading={this.state.pause_sending_loading}
                    onPress={() => {
                      this.props.updateUser({
                        token,
                        type: "pause_sending",
                        payload: {
                          pause_sending: 0,
                          auto_approve: 0
                        },
                        onLoading: () => {
                          this.setState({
                            pause_sending_loading: true
                          });
                        },
                        onSuccess: () => {
                          this.setState({
                            pause_sending_loading: false
                          });
                        },
                        onError: () => {
                          this.setState({
                            pause_sending_loading: false
                          });
                        }
                      });
                    }}
                  >
                    Resume Mail Queue
                  </GhostButton>
                ) : (
                  <InlineButton
                    button_type="full"
                    loading={this.state.pause_sending_loading}
                    onPress={() => {
                      this.props.updateUser({
                        token,
                        type: "pause_sending",
                        payload: {
                          pause_sending: 1,
                          auto_approve: 0
                        },
                        onLoading: () => {
                          this.setState({
                            pause_sending_loading: true
                          });
                        },
                        onSuccess: () => {
                          this.setState({
                            pause_sending_loading: false
                          });
                        },
                        onError: () => {
                          this.setState({
                            pause_sending_loading: false
                          });
                        }
                      });
                    }}
                  >
                    Pause Mail Queue
                  </InlineButton>
                )}
              </>
            );
          }}
        />
      </Wrapper>
    );
  }

  render() {
    const {
      token,
      user,
      sending_queue,
      sending_queue_loading,
      sending_queue_begin,
      sending_queue_loaded_all,
      device,
      isMobile,
      colors
    } = this.props;

    if (sending_queue.length == 0 && sending_queue_loading) {
      return (
        <Wrapper>
          {this.renderPauseCard()}

          <SpinWrapper text="Loading queue..." />
        </Wrapper>
      );
    }

    return (
      <KeyboardView style={{ flex: 1 }}>
        {this.renderPauseCard()}
        <List
          rowNumber={1}
          items={sending_queue}
          infiniteScroll={true}
          itemStructure={({ item, index }) => {
            return (
              <QueueItem
                key={"sending_queue_" + index}
                token={token}
                item={item}
                colors={colors}
                user={user}
                device={device}
                isMobile={isMobile}
                appRedirect={this.props.appRedirect}
                updateLead={this.props.updateLead}
                getSendingQueueCounts={this.props.getSendingQueueCounts}
                selectActiveProperty={this.props.selectActiveProperty}
                pushSidePanel={this.props.pushSidePanel}
              />
            );
          }}
          canRefresh={false}
          onRefresh={() => {}}
          is_refreshing={false}
          canLoadMore={
            !sending_queue_loaded_all &&
            !sending_queue_loading &&
            sending_queue.length > 0
          }
          isLoadingMore={sending_queue_loading && sending_queue.length > 0}
          onLoadMore={() => {
            if (!sending_queue_loaded_all && sending_queue_loading !== true) {
              this.getData({ load_type: "load_more" });
            }
          }}
        />
      </KeyboardView>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer, billing }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const {
    sending_queue,
    sending_queue_loading,
    sending_queue_begin,
    sending_queue_loaded_all,
    sending_queue_counts
  } = mailer;
  const { all_feature_metadata, source_of_truth } = billing;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    all_feature_metadata,
    source_of_truth,
    sending_queue,
    sending_queue_loading,
    sending_queue_begin,
    sending_queue_loaded_all,
    sending_queue_counts
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getSendingQueue,
  getSendingQueueCounts,
  updateUser,
  updateLead,
  selectActiveProperty,

  pushSidePanel
})(Queue);
