import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  ModalOverlay,
  Modal,
  Card,
  Form,
  CardBody,
  Title,
  Copy,
  PrimaryButton,
  ContentfulRichText,
  ExternalImage,
  Row
} from "app/NativeComponents/common";
import { TextButton } from "app/NativeComponents/snippets";

import {
  loadOnboardingText,
  getBillingAddonId,
  checkIfUserHasModule,
  renderPrice
} from "app/NativeActions";

class UpsellModalContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contentful_info: null
    };
  }

  componentDidMount() {
    const { upsell_modal } = this.props;
    if (!!upsell_modal?.contentful_slug) {
      this.loadData(upsell_modal.contentful_slug);
    }

    this.props.setFreeTrialInfo(this.props.plan_modules);
    this.renderEndOfTrialPrice(this.props.plans_on_trial);
  }

  loadData(slug) {
    const { platform, user } = this.props;

    if (platform == "ios") {
      this.setState({
        contentful_info: {
          title: "Feature Locked",
          description:
            "This feature is locked. Please go to DealMachine on the web to manage your account.",
          buttonText: "Dismiss"
        }
      });
    } else if (user.team_clearance_level < 2) {
      this.setState({
        contentful_info: {
          title: "Feature Locked",
          description:
            "This feature is locked. A team admin will need to purchase this feature to unlock it.",
          buttonText: "Dismiss"
        }
      });
    } else {
      this.setState({
        contentful_info: null
      });
      loadOnboardingText(slug)
        .then(data => {
          if (data && data.items) {
            if (data.items.length > 0) {
              this.setState({
                contentful_info: data.items[0]
                  ? data.items[0].fields
                    ? data.items[0].fields
                    : null
                  : null
              });
            }
          }
        })
        .catch(err => {
          this.setState(() => ({ contentful_info: null }));
        });
    }
  }

  upgradeAction() {
    const {
      platform,
      user,
      upsell_modal,
      billing_addons,
      plan_modules,
      plans_on_trial
    } = this.props;
    if (platform !== "ios" && user.team_clearance_level >= 2) {
      switch (upsell_modal.type) {
        default:
          break;

        case "billing_addon":
          const billing_addon_id = getBillingAddonId(
            billing_addons,
            upsell_modal.slug
          );
          this.props.updateBillingAddon({
            token: this.props.token,
            type: "purchase",
            slug: upsell_modal.slug,
            addon_id: billing_addon_id,
            onSuccess: () => {}
          });
          this.props.toggleUpsellModal(false);

          break;

        case "plan":
          const plan_module_info = checkIfUserHasModule({
            plan_modules: plan_modules,
            user: user,
            slug: upsell_modal.slug
          });

          break;
        case "end_trial":
          for (var i = 0; i < plans_on_trial.length; i++) {
            this.props.updatePlan({
              token: this.props.token,
              slug: plans_on_trial[i].module_type,
              type: "bypass_trial"
            });
          }

          this.props.toggleUpsellModal(false);
          break;
      }
    } else {
      this.props.toggleUpsellModal(false);
    }
  }

  renderEndOfTrialPrice(plans_on_trial) {
    let end_trial_price = 0;
    for (var i = 0; i < plans_on_trial.length; i++) {
      end_trial_price = end_trial_price + parseInt(plans_on_trial[i].price);
    }
    return (
      "By ending your free trial you'll be charged " +
      renderPrice(end_trial_price) +
      " today"
    );
  }

  render() {
    const {
      toggle_upsell_modal,
      upsell_modal,
      colors,
      platform,
      user,
      plans_on_trial
    } = this.props;
    const { contentful_info } = this.state;

    return (
      <Card
        style={{
          minWidth: 200,
          alignSelf: "stretch"
        }}
      >
        <CardBody>
          {!!contentful_info?.title ? (
            <Title style={{ textAlign: "center", marginBottom: 5 }}>
              {contentful_info?.title}
            </Title>
          ) : null}
          {!!contentful_info?.description ? (
            <Copy style={{ textAlign: "center" }}>
              {contentful_info?.description}
            </Copy>
          ) : null}
          {contentful_info?.image ? (
            <ExternalImage
              style={{
                resizeMode: "contain",
                width: "100%",
                height: 100,
                marginTop: 10,
                marginBottom: 0
              }}
              contain={true}
              image={contentful_info?.image.fields.file.url}
            />
          ) : null}
          {!!contentful_info?.richContent ? (
            <ContentfulRichText
              style={{
                textAlign: "center"
              }}
              richText={contentful_info?.richContent}
            />
          ) : null}
          <Copy style={{ textAlign: "center" }}>
            {plans_on_trial.length > 0
              ? this.renderEndOfTrialPrice(plans_on_trial)
              : null}
          </Copy>
        </CardBody>

        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Card style={{ borderRadius: 30, overflow: "hidden", flex: 1 }}>
            <PrimaryButton onPress={() => this.upgradeAction()}>
              {!!contentful_info?.buttonText
                ? contentful_info?.buttonText
                : "Upgrade Now"}
            </PrimaryButton>
          </Card>
        </Row>
        {platform !== "ios" && user.team_clearance_level >= 2 ? (
          <TextButton
            style={{ padding: 10 }}
            onPress={() => this.props.toggleUpsellModal(false)}
            text={
              !!contentful_info?.cancelButtonText
                ? contentful_info?.cancelButtonText
                : "Not right now"
            }
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  modal,
  billing,
  photo,
  feature_toggle
}) => {
  const { token, user } = auth;
  const { device, platform } = native;
  const { colors } = settings;
  const { plan_modules, billing_addons, plans_on_trial } = billing;
  const { toggle_upsell_modal, upsell_modal } = modal;

  return {
    token,
    user,
    device,
    platform,
    colors,
    toggle_upsell_modal,
    upsell_modal,
    plan_modules,
    billing_addons,

    plans_on_trial
  };
};

export default UpsellModalContent;
