import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Spin } from "app/NativeComponents/common";
import {
  SearchBar,
  IconButton,
  SmallLabel,
  SelectItem,
  InlineButton
} from "app/NativeComponents/snippets";
import {
  getAutocompleteMap,
  getRecentSearch,
  saveRecentSearch,
  getDeviceLocation,
  lockLocationTracking,
  selectActiveProperty,
  selectActiveCoordinates,
  selectActiveRoute,
  askLocationPermissionsThenContinue,
  dismissMobileKeyboard,
  replaceSidePanel,
  renderDate,
  setOnboardingAutoCompleteInProgress,
  pushSidePanel,
  updateMapFilters
} from "app/NativeActions";

class AutocompleteSearchV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_location_loading: false,
      autocomplete_loading: false,
      autocomplete_properties: [],
      autocomplete_locations: [],
      recent_search: [],
      location_text: "",
      search_wait: false,
      search_entered: false
    };
    this._location_text = React.createRef();

    this.triggerAutocomplete = this.triggerAutocomplete.bind(this);
    this.getLoadingId = this.getLoadingId.bind(this);
  }
  getLoadingId() {
    //cerate a random id and set it to the state loading_id
    return Math.floor(Math.random() * 100000000000000000);
  }
  componentWillUnmount() {
    clearInterval(this._search_interval);
    clearInterval(this._updatelatlng_timer);
  }

  componentDidMount() {
    this.props.getRecentSearch({
      token: this.props.token,
      search_type: "map",
      onSuccess: results => {
        this.setState({
          recent_search: results.recent_search
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  triggerAutocomplete(value) {
    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      const property_loading_id = this.getLoadingId();

      if (value.length > 2) {
        this.setState(
          {
            property_loading_id
          },
          () => {
            this.props.getAutocompleteMap({
              token: this.props.token,
              search: value.length > 2 ? value : "",
              centerLat: this.props.centerLat,
              centerLng: this.props.centerLng,
              onLoading: () => {
                this.setState({
                  autocomplete_loading: true
                });
              },
              onError: () => {
                this.setState({
                  autocomplete_loading: false
                });
              },
              onSuccess: results => {
                if (property_loading_id === this.state.property_loading_id) {
                  this.setState({
                    autocomplete_loading: false,
                    autocomplete_locations: results?.locations,
                    autocomplete_properties: results?.properties
                  });
                }
              }
            });
          }
        );
      }
    }, 750);
  }

  render() {
    const {
      isMobile,
      style,
      placeholder = "Search by city or zip code",
      borderRadius = 0
    } = this.props;
    const {
      location_text,
      autocomplete_items,
      recent_search,
      autocomplete_loading
    } = this.state;

    return (
      <SearchBar
        input_ref={this._location_text}
        title={!!location_text ? placeholder : placeholder}
        value={location_text}
        onChange={value => {
          this.setState({
            location_text: value,
            search_wait: true
          });
          this.triggerAutocomplete(value);
        }}
        onPress={() => {
          if (this.state.autocomplete_locations?.length > 0) {
            const location = this.state.autocomplete_locations[0];

            this.props.updateLatLng({
              longitude: location?.longitude,
              latitude: location?.latitude,
              heading: 0,
              pitch: 0,
              zoom: 13,
              property: null,
              location_type: location?.location_type,
              coordinates: location?.coordinates
            });

            let search_locations = this.props.map_filters.search_locations;
            let found = false;
            const value =
              location?.location_type === "city"
                ? location?.city
                : location?.location_type === "fips"
                ? location?.fips
                : location?.zip;
            for (let i = 0; i < search_locations.length; i++) {
              if (
                search_locations[i].type === location?.location_type &&
                search_locations[i].value === value &&
                search_locations[i].value2 === location?.state
              ) {
                found = true;
                break;
              }
            }
            if (!found && search_locations.length < 5) {
              this.props.updateMapFilters({
                drawing_created: false,
                drawing: false,
                drawing_coordinates: null,
                search_locations:
                  this.props.map_filters?.search_locations &&
                  this.props.map_filters?.search_locations?.length < 5
                    ? [
                        ...this.props.map_filters.search_locations,
                        {
                          type: location?.location_type,
                          value:
                            location?.location_type === "city"
                              ? location?.city
                              : location?.location_type === "fips"
                              ? location?.fips
                              : location?.zip,
                          label: location?.label,
                          value2: location?.state,
                          latitude: location?.latitude,
                          longitude: location?.longitude,
                          coordinates: location?.coordinates
                        }
                      ]
                    : this.props.map_filters
              });
            }
          }
        }}
        renderAutocomplete={
          this.props.search_focused
            ? () => {
                return (
                  <>
                    {this.state.autocomplete_locations?.length === 0 ||
                    this.state.autocomplete_properties?.length === 0 ||
                    !location_text?.length < 3 ? (
                      <SelectItem
                        select_type={"none"}
                        icon={"keyboard-arrow-right"}
                        primary={true}
                        onPress={() => {
                          this.props.askLocationPermissionsThenContinue({
                            onSuccess: () => {
                              getDeviceLocation({
                                onLoading: () => {
                                  this.setState({
                                    current_location_loading: true
                                  });
                                },
                                onError: () => {
                                  this.setState({
                                    current_location_loading: false
                                  });
                                },
                                onSuccess: results => {
                                  this.props.updateLatLng({
                                    longitude: results.longitude,
                                    latitude: results.latitude,
                                    location_type: "current_location"
                                  });
                                  this.setState({
                                    current_location_loading: false,
                                    location_text: ""
                                  });
                                }
                              });
                            }
                          });

                          dismissMobileKeyboard();
                          this.props.updateSearchFocus(false);
                        }}
                      >
                        Current Location
                      </SelectItem>
                    ) : null}

                    {this.state.autocomplete_properties &&
                    this.state.autocomplete_properties?.length > 0 &&
                    !!location_text ? (
                      <>
                        <SmallLabel
                          style={{
                            backgroundColor: this.props.colors.background_color
                          }}
                        >
                          Properties:
                        </SmallLabel>
                        {this.state.autocomplete_properties.map(
                          (property, index) => {
                            return (
                              <SelectItem
                                key={"property_" + index}
                                select_type={"none"}
                                icon={"keyboard-arrow-right"}
                                subtext={
                                  property?.property
                                    ? "Open Property"
                                    : "Jump to Location"
                                }
                                onPress={() => {
                                  if (this.props.user) {
                                    this.props.saveRecentSearch({
                                      token: this.props.token,
                                      search_type: "map",
                                      full_text: property?.title,
                                      search_text: this.state.location_text,
                                      property_id:
                                        property?.property?.property_id,
                                      lead_id: null,
                                      result_type: "mapbox",
                                      data: property,
                                      latitude: property?.latitude,
                                      longitude: property?.longitude,
                                      location_type: null,
                                      onSuccess: results => {
                                        this.setState({
                                          recent_search: results?.recent_search
                                        });
                                      }
                                    });
                                  }

                                  this.setState(
                                    {
                                      location_text: property?.title,
                                      autocomplete_loading: false,
                                      autocomplete_items: []
                                    },
                                    () => {
                                      clearInterval(this._updatelatlng_timer);
                                      this._updatelatlng_timer = setTimeout(
                                        () => {
                                          this.props.updateLatLng({
                                            longitude: property?.longitude,
                                            latitude: property?.latitude,
                                            heading: 0,
                                            pitch: 0,
                                            zoom: 17,
                                            property: property?.property
                                          });
                                        },
                                        property?.property ? 500 : 0
                                      );

                                      if (property?.property) {
                                        this.props.selectActiveProperty(
                                          this.props.token,
                                          property?.property
                                        );

                                        if (
                                          this.props.and_push_property &&
                                          !this.props.isMobile &&
                                          !this.props.isOnboardingActive &&
                                          !this.props.onboarding_map
                                        ) {
                                          this.props.replaceSidePanel({
                                            slug: "property",
                                            id: property?.property?.property_id,
                                            focus_side_panel:
                                              this.props.user?.user_settings
                                                ?.property_expanded ===
                                              "not_expanded"
                                                ? false
                                                : true,
                                            data: {
                                              property: property?.property
                                            }
                                          });
                                        }
                                      }
                                    }
                                  );
                                  dismissMobileKeyboard();
                                  this.props.updateSearchFocus(false);
                                }}
                              >
                                {property?.title}
                              </SelectItem>
                            );
                          }
                        )}
                      </>
                    ) : null}

                    {this.state.autocomplete_locations &&
                    this.state.autocomplete_locations?.length > 0 ? (
                      <>
                        <SmallLabel
                          style={{
                            backgroundColor: this.props.colors.background_color
                          }}
                        >
                          {!!location_text
                            ? "Locations:"
                            : "Suggested Locations:"}
                        </SmallLabel>
                        {this.state.autocomplete_locations.map(
                          (location, index) => {
                            return (
                              <SelectItem
                                key={"location_" + index}
                                select_type={"none"}
                                icon={"keyboard-arrow-right"}
                                onPress={() => {
                                  if (this.props.user) {
                                    this.props.saveRecentSearch({
                                      token: this.props.token,
                                      search_type: "map",
                                      full_text: location?.label,
                                      search_text: this.state.location_text,
                                      property_id: null,
                                      lead_id: null,
                                      result_type: "location",
                                      data: location,
                                      latitude: location.latitude,
                                      longitude: location.longitude,
                                      onSuccess: results => {
                                        this.setState({
                                          recent_search: results.recent_search
                                        });
                                      }
                                    });
                                  }

                                  this.setState(
                                    {
                                      location_text: location?.label,
                                      autocomplete_loading: false,
                                      autocomplete_items: []
                                    },
                                    () => {
                                      this.props.updateLatLng({
                                        longitude: location?.longitude,
                                        latitude: location?.latitude,
                                        heading: 0,
                                        pitch: 0,
                                        zoom: 11,
                                        property: null,
                                        location_type: location?.location_type,
                                        coordinates: location?.coordinates
                                      });

                                      let search_locations =
                                        this.props.map_filters.search_locations;
                                      let found = false;
                                      const value =
                                        location?.location_type === "city"
                                          ? location?.city
                                          : location?.location_type === "fips"
                                          ? location?.fips
                                          : location?.zip;
                                      for (
                                        let i = 0;
                                        i < search_locations.length;
                                        i++
                                      ) {
                                        if (
                                          search_locations[i].type ===
                                            location?.location_type &&
                                          search_locations[i].value === value &&
                                          search_locations[i].value2 ===
                                            location?.state
                                        ) {
                                          found = true;
                                          break;
                                        }
                                      }
                                      if (
                                        !found &&
                                        search_locations.length < 5
                                      ) {
                                        this.props.updateMapFilters({
                                          drawing_created: false,
                                          drawing: false,
                                          drawing_coordinates: null,
                                          search_locations:
                                            this.props.map_filters
                                              ?.search_locations &&
                                            this.props.map_filters
                                              ?.search_locations?.length < 5
                                              ? [
                                                  ...this.props.map_filters
                                                    .search_locations,
                                                  {
                                                    type: location?.location_type,
                                                    value:
                                                      location?.location_type ===
                                                      "city"
                                                        ? location?.city
                                                        : location?.location_type ===
                                                          "fips"
                                                        ? location?.fips
                                                        : location?.zip,
                                                    label: location?.label,
                                                    value2: location?.state,
                                                    latitude:
                                                      location?.latitude,
                                                    longitude:
                                                      location?.longitude,
                                                    coordinates:
                                                      location?.coordinates
                                                  }
                                                ]
                                              : this.props.map_filters
                                        });
                                      }

                                      dismissMobileKeyboard();
                                      this.props.updateSearchFocus(false);
                                    }
                                  );
                                }}
                                subtext={
                                  location.location_type === "city"
                                    ? "City"
                                    : location.location_type === "fips"
                                    ? "County"
                                    : "Zip"
                                }
                              >
                                {location.label}
                              </SelectItem>
                            );
                          }
                        )}
                      </>
                    ) : null}

                    {recent_search &&
                    recent_search.length > 0 &&
                    ((this.state.autocomplete_locations?.length === 0 &&
                      this.state.autocomplete_properties?.length === 0) ||
                      location_text?.length < 3) ? (
                      <>
                        <SmallLabel
                          style={{
                            backgroundColor: this.props.colors.background_color
                          }}
                        >
                          Recent search:
                        </SmallLabel>

                        {recent_search.map((search, index) => {
                          switch (search?.result_type) {
                            case "mapbox":
                              return (
                                <SelectItem
                                  key={"property_" + index}
                                  select_type={"none"}
                                  icon={"keyboard-arrow-right"}
                                  subtext={
                                    search?.data?.property
                                      ? "Open Property"
                                      : "Jump to Location"
                                  }
                                  onPress={() => {
                                    this.setState(
                                      {
                                        location_text: search?.full_text,
                                        autocomplete_loading: false,
                                        autocomplete_items: []
                                      },
                                      () => {
                                        clearInterval(this._updatelatlng_timer);
                                        this._updatelatlng_timer = setTimeout(
                                          () => {
                                            this.props.updateLatLng({
                                              longitude: search?.longitude,
                                              latitude: search?.latitude,
                                              heading: 0,
                                              pitch: 0,
                                              zoom: 17,
                                              property: search?.data?.property
                                            });
                                          },
                                          search?.data?.property ? 500 : 0
                                        );

                                        if (search?.data?.property) {
                                          this.props.selectActiveProperty(
                                            this.props.token,
                                            search?.data?.property
                                          );

                                          if (
                                            this.props.and_push_property &&
                                            !this.props.isMobile &&
                                            !this.props.isOnboardingActive &&
                                            !this.props.onboarding_map
                                          ) {
                                            this.props.replaceSidePanel({
                                              slug: "property",
                                              id: search?.data?.property
                                                ?.property_id,
                                              focus_side_panel:
                                                this.props.user?.user_settings
                                                  ?.property_expanded ===
                                                "not_expanded"
                                                  ? false
                                                  : true,
                                              data: {
                                                property: search?.data?.property
                                              }
                                            });
                                          }
                                        }
                                      }
                                    );
                                    dismissMobileKeyboard();
                                    this.props.updateSearchFocus(false);
                                  }}
                                >
                                  {search?.full_text}
                                </SelectItem>
                              );
                            case "location":
                              return (
                                <SelectItem
                                  key={"location_" + index}
                                  select_type={"none"}
                                  icon={"keyboard-arrow-right"}
                                  onPress={() => {
                                    this.setState(
                                      {
                                        location_text: "",
                                        autocomplete_loading: false,
                                        autocomplete_items: []
                                      },
                                      () => {
                                        this.props.updateLatLng({
                                          longitude: search?.longitude,
                                          latitude: search?.latitude,
                                          heading: 0,
                                          pitch: 0,
                                          zoom: 11,
                                          property: null,
                                          location_type:
                                            search?.data?.location_type,
                                          coordinates: search?.data?.coordinates
                                        });

                                        //check to see if this.props.map_filters.search_locations already has a object with the same type, value and value2
                                        //if it does, don't add it
                                        //if it doesn't, add it
                                        let search_locations =
                                          this.props.map_filters
                                            .search_locations;
                                        let found = false;
                                        const value =
                                          search?.data?.location_type === "city"
                                            ? search?.data?.city
                                            : search?.data?.location_type ===
                                              "fips"
                                            ? search?.data?.fips
                                            : search?.data?.zip;
                                        for (
                                          let i = 0;
                                          i < search_locations.length;
                                          i++
                                        ) {
                                          if (
                                            search_locations[i].type ===
                                              search?.data?.location_type &&
                                            search_locations[i].value ===
                                              value &&
                                            search_locations[i].value2 ===
                                              search?.data?.state
                                          ) {
                                            found = true;
                                            break;
                                          }
                                        }
                                        if (
                                          !found &&
                                          search_locations.length < 5
                                        ) {
                                          this.props.updateMapFilters({
                                            drawing_created: false,
                                            drawing: false,
                                            drawing_coordinates: null,
                                            search_locations:
                                              this.props.map_filters
                                                ?.search_locations &&
                                              this.props.map_filters
                                                ?.search_locations?.length < 5
                                                ? [
                                                    ...this.props.map_filters
                                                      .search_locations,
                                                    {
                                                      type: search?.data
                                                        ?.location_type,
                                                      value:
                                                        search?.data
                                                          ?.location_type ===
                                                        "city"
                                                          ? search?.data?.city
                                                          : search?.data
                                                              ?.location_type ===
                                                            "fips"
                                                          ? search?.data?.fips
                                                          : search?.data?.zip,
                                                      label:
                                                        search?.data?.label,
                                                      value2:
                                                        search?.data?.state,
                                                      latitude:
                                                        search?.latitude,
                                                      longitude:
                                                        search?.longitude,
                                                      coordinates:
                                                        search?.data
                                                          ?.coordinates
                                                    }
                                                  ]
                                                : this.props.map_filters
                                          });
                                        }
                                        dismissMobileKeyboard();
                                        this.props.updateSearchFocus(false);
                                      }
                                    );
                                  }}
                                  subtext={
                                    search?.data?.location_type === "city"
                                      ? "View City"
                                      : search?.data?.location_type === "fips"
                                      ? "View County"
                                      : search?.data?.location_type === "zip"
                                      ? "View Zip"
                                      : "Go To Location"
                                  }
                                >
                                  {!!search?.data?.label
                                    ? search?.data?.label
                                    : search?.full_text}
                                </SelectItem>
                              );

                            default:
                              return null;
                          }
                        })}
                      </>
                    ) : null}

                    {this.state.autocomplete_locations?.length === 0 ||
                    this.state.autocomplete_properties?.length === 0 ||
                    !location_text?.length < 3 ? (
                      <>
                        <SmallLabel
                          style={{
                            backgroundColor: this.props.colors.background_color
                          }}
                        >
                          {"Other Options:"}
                        </SmallLabel>
                        <SelectItem
                          select_type={"none"}
                          icon={"keyboard-arrow-right"}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "manually_add_lead",
                              overlay: true
                            });

                            dismissMobileKeyboard();
                            this.props.updateSearchFocus(false);
                          }}
                        >
                          Manually Add Lead
                        </SelectItem>
                      </>
                    ) : null}
                  </>
                );
              }
            : null
        }
        loading={autocomplete_loading}
        borderRadius={borderRadius}
        style={{
          margin: isMobile ? 0 : 5,
          marginLeft: isMobile ? 0 : 10,
          borderWidth: isMobile ? 0 : 1,
          borderBottomWidth: isMobile ? 0 : 1,
          ...style
        }}
        onFocus={() => {
          this.props.selectActiveProperty(this.props.token, null);
          this.props.selectActiveCoordinates(null);
          this.props.selectActiveRoute(null);
          this.props.updateSearchFocus(true);
        }}
        onBlur={() => {
          if (this.props.device === "desktop") {
            this.props.updateSearchFocus(false);
          }
        }}
        icon={
          this.props.device === "mobile" && this.props.search_focused
            ? "keyboard-arrow-left"
            : "search"
        }
        onIconPress={
          this.props.device === "mobile"
            ? () => {
                this.setState(
                  {
                    location_text: ""
                  },
                  () => {
                    dismissMobileKeyboard();
                    this.props.updateSearchFocus(false);
                  }
                );
              }
            : null
        }
        renderRight={
          this.props.search_focused
            ? () => {
                return (
                  <IconButton
                    icon={"close"}
                    button_type="small"
                    onPress={() => {
                      if (!!this.state.location_text) {
                        this.setState({
                          location_text: ""
                        });
                      } else {
                        if (this._location_text?.current?.blur) {
                          this._location_text.current.blur();
                        }
                        dismissMobileKeyboard();
                        this.props.updateSearchFocus(false);
                      }
                    }}
                  />
                );
              }
            : () => {
                return (
                  <IconButton
                    loading={this.state.current_location_loading}
                    icon_color={
                      this.props.lock_location_tracking
                        ? this.props.colors.active_color
                        : this.props.colors.text_color
                    }
                    icon={
                      this.props.lock_location_tracking
                        ? "navigation"
                        : "near-me"
                    }
                    style={{ marginRight: 0 }}
                    onPress={() => {
                      if (this.props.device === "desktop") {
                        this.props.askLocationPermissionsThenContinue({
                          onSuccess: () => {
                            getDeviceLocation({
                              onLoading: () => {
                                this.setState({
                                  current_location_loading: true
                                });
                              },
                              onError: () => {
                                this.setState({
                                  current_location_loading: false
                                });
                              },
                              onSuccess: results => {
                                this.setState({
                                  current_location_loading: false
                                });
                                this.props.updateLatLng(results);
                              }
                            });
                          }
                        });
                      } else {
                        this.props.selectActiveRoute(null);

                        if (this.props.lock_location_tracking) {
                          this.props.lockLocationTracking(false);
                        } else {
                          this.props.askLocationPermissionsThenContinue({
                            onSuccess: () => {
                              this.props.lockLocationTracking(true);
                            }
                          });
                        }
                      }
                    }}
                  />
                );
              }
        }
      />
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  map,
  onboarding,
  property_map
}) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { map_filters } = property_map;
  const { lock_location_tracking } = map;

  const { isOnboardingActive } = onboarding;
  return {
    token,
    user,
    colors,
    isMobile,
    device,
    lock_location_tracking,
    isOnboardingActive,
    map_filters
  };
};

export default connect(mapStateToProps, {
  getAutocompleteMap,
  getRecentSearch,
  saveRecentSearch,
  lockLocationTracking,
  selectActiveProperty,
  selectActiveCoordinates,
  selectActiveRoute,
  askLocationPermissionsThenContinue,
  replaceSidePanel,
  setOnboardingAutoCompleteInProgress,
  pushSidePanel,
  updateMapFilters
})(AutocompleteSearchV2);
