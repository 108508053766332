import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Bold,
  Wrapper,
  Row,
  Card,
  Copy,
  StatusBarStyle
} from "app/NativeComponents/common";
import {
  GhostButton,
  InlineButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";
import { Header1, Header3 } from "app/NativeComponents/common";
import MainMap from "app/DealMachineCore/components/MainMap";

import { getGreatCircleBearing } from "geolib";
import {
  checkIfHasGoogleStreetPic,
  setStreetViewCoords,
  toggleVirtualDriveStart,
  getMarketingOnboardingData,
  showErrorMessage,
  isLocationInUSA
} from "app/NativeActions";

import {
  logout,
  loadOnboardingText,
  startRedirect,
  updateUser,
  updateMainMapLocation
} from "app/NativeActions";

import MapInner from "app/NativeComponents/components/NativeMainMap/MapInner";

class AddPropertyStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      checked_pic: false,
      failedGoogleStreetPic: false,
      street_view_data: null
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/add-a-lead");
    }
    this.loadData("add_property_step");

    this.checkGoogleForStreetPicData({
      coordinates: {
        latitude: this.props.main_map_location?.latitude,
        longitude: this.props.main_map_location?.longitude
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.main_map_location?.latitude !==
        prevProps.main_map_location?.latitude ||
      this.props.main_map_location?.longitude !==
        prevProps.main_map_location?.longitude
    ) {
      this.checkGoogleForStreetPicData({
        coordinates: {
          latitude: this.props.main_map_location?.latitude,
          longitude: this.props.main_map_location?.longitude
        }
      });
    }
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  checkGoogleForStreetPicData({ address, coordinates }) {
    checkIfHasGoogleStreetPic({
      address,
      coordinates,
      onSuccess: results => {
        this.setState({
          checked_pic: true,
          failedGoogleStreetPic: results.status == "OK" ? false : true,
          street_view_data: results
        });
      }
    });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;
    let validArea = false;
    if (
      isLocationInUSA(
        this.props.main_map_location?.latitude,
        this.props.main_map_location?.longitude
      )
    ) {
      validArea = true;
    } else {
      validArea = false;
    }
    if (this.props.device == "mobile") {
      return (
        <Container>
          <StatusBarStyle statusBarStyle={"dark-content"} />
          <MapInner validArea={validArea} onboarding_map={true} />
        </Container>
      );
    }

    return (
      <Container title={"👋 Welcome to DealMachine!"}>
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25
              }}
            >
              <Header1
                style={{
                  textAlign: "center",
                  color: colors.text_color
                }}
              >
                <Bold>👋 Welcome to DealMachine!</Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center",
                  color: colors.text_color
                }}
              >
                Complete this quick tutorial and we'll take you to your account.
              </Header3>
            </Wrapper>

            <Row style={{ alignSelf: "stretch" }}>
              <Wrapper style={{ flex: 1 }}>
                <Card style={{ overflow: "hidden", height: 500 }}>
                  {this.props.user && this.props.user?.user_settings ? (
                    <MainMap onboarding_map={true} />
                  ) : null}
                </Card>
              </Wrapper>

              <Wrapper style={{ width: 450, color: colors.text_color }}>
                <Wrapper style={{ padding: 25, paddingBottom: 15 }}>
                  <Header3
                    style={{ marginBottom: 10, color: colors.text_color }}
                  >
                    <Bold>Find your first property.</Bold>
                  </Header3>
                  <Copy style={{ marginBottom: 10 }}>
                    We're going to find a property and send mail to the owner by{" "}
                    <Bold>"virtually driving".</Bold>
                  </Copy>
                  <Copy style={{ marginBottom: 10 }}>
                    Go ahead and click an area on the map to select a precise
                    location.
                  </Copy>
                  <Copy>
                    Or just click <Bold>"Start Driving"</Bold> below.
                  </Copy>
                </Wrapper>

                <GhostButton
                  primary={true}
                  disabled={
                    !this.state.street_view_data ||
                    this.state.failedGoogleStreetPic ||
                    !this.state.checked_pic ||
                    !validArea
                  }
                  onPress={() => {
                    const { street_view_data } = this.state;

                    if (street_view_data) {
                      const heading = getGreatCircleBearing(
                        {
                          latitude: parseFloat(street_view_data?.location?.lat),
                          longitude: parseFloat(street_view_data?.location?.lng)
                        },
                        {
                          latitude: parseFloat(
                            this.props.main_map_location?.latitude
                          ),
                          longitude: parseFloat(
                            this.props.main_map_location?.longitude
                          )
                        }
                      );
                      this.props.toggleVirtualDriveStart(true);

                      this.props.setStreetViewCoords({
                        latitude: street_view_data?.location?.lat,
                        longitude: street_view_data?.location?.lng,
                        heading: heading,
                        zoom: 17,
                        pitch: 0
                      });
                    }
                  }}
                  button_type="full"
                  style={{
                    marginLeft: 20,
                    marginRight: 20
                  }}
                >
                  {validArea
                    ? "Start Driving"
                    : "The location must be in the United States, to start driving."}
                </GhostButton>

                <Wrapper style={{ padding: 25, paddingTop: 15 }}>
                  <Copy
                    style={{ fontSize: 12, color: colors.light_text_color }}
                  >
                    If the "Start Driving" button doesn't appear clickable, the
                    location you selected not might have a visible street view.
                  </Copy>
                </Wrapper>
              </Wrapper>
            </Row>
            <Wrapper
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 25
              }}
            >
              <InlineButton
                loading={this.state.skip_loading}
                onPress={() => {
                  this.props.getMarketingOnboardingData({
                    token: this.props.token,
                    type: "skip_and_add_lead",
                    onLoading: () => {
                      this.setState({ skip_loading: true });
                    },
                    onError: () => {
                      this.setState({ skip_loading: false });
                    },
                    onSuccess: results => {
                      if (results?.lead) {
                        this.props.updateUser({
                          token: this.props.token,
                          type: "onboarding_stepthrough_step",
                          payload: {
                            onboarding_stepthrough_current_step: "alma_step"
                          },
                          onLoading: () => {},
                          onError: () => {
                            this.setState({
                              skip_loading: false
                            });
                          },
                          onSuccess: () => {},
                          no_loading: true
                        });
                      } else {
                        this.setState({ skip_loading: false });
                        this.props.showErrorMessage(
                          "Something went wrong.",
                          "Error"
                        );
                      }
                    }
                  });
                }}
                style={{ textAlign: "center" }}
                marketing_slug={"skipped_driving_on_map_onboarding"}
                icon_right={"arrow-right-alt"}
              >
                {"Skip and add a lead for me."}
              </InlineButton>
            </Wrapper>
          </>
        </OnboardingWrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native, property_map }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect } = native;
  const { main_map_location } = property_map;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    main_map_location
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  updateUser,
  setStreetViewCoords,
  toggleVirtualDriveStart,
  updateMainMapLocation,
  getMarketingOnboardingData,
  showErrorMessage,
  isLocationInUSA
})(AddPropertyStep);
