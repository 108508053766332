import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectItem } from "app/NativeComponents/snippets";

import {
  bulkUpdateLeads,
  pushSidePanel,
  setListModal,
  addListToListTabs,
  numberWithCommas
} from "app/NativeActions";
class CreateListButton extends Component {
  render() {
    const { button_search, title, selected_all, selected_leads } = this.props;
    const { token, user, filtered_count } = this.props;

    if (
      !button_search ||
      title.toLowerCase().includes(button_search.toLowerCase())
    ) {
      return (
        <SelectItem
          select_type="none"
          icon="keyboard-arrow-right"
          onPress={() => {
            this.props.setListModal({
              title: "Create List",
              description: selected_all
                ? "Create a list to add all " + filtered_count + " leads."
                : selected_leads.length === 1
                ? "Create a list to add " +
                  selected_leads[0].property_address +
                  "."
                : "Create a list to add your selected " +
                  selected_leads.length +
                  " leads.",
              type: "create_list",
              selected_leads: selected_all ? "all" : selected_leads,
              selected_lists: [],
              modalAction: ({
                selected_leads,
                new_list_name,
                onLoading = () => {},
                onError = () => {},
                onSuccess = () => {}
              }) => {
                onLoading();
                this.props.bulkUpdateLeads({
                  token: token,
                  type: "create_list_with_leads",
                  new_list_name,
                  select_all: selected_all ? 1 : 0,
                  total_count: selected_all
                    ? filtered_count
                    : selected_leads.length,
                  filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.filters
                      ? this.props.applied_filter?.filters
                      : null,
                  property_flags: this.props.lead_filters?.property_flags
                    .map(({ value }) => value)
                    .join(","),
                  property_flags_and_or:
                    this.props.lead_filters?.property_flags_and_or,
                  using_old_filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.old_filters
                      ? true
                      : false,
                  search: this.props.search,
                  search_type: this.props.search_type,
                  list_id: this.props.active_list_tab?.id
                    ? this.props.active_list_tab?.id
                    : this.props.active_list_tab,
                  list_history_id: this.props.list_history_id || "",
                  lead_ids: selected_all
                    ? ""
                    : selected_leads
                        .map(property => {
                          return property.deal.id;
                        })
                        .join(),
                  onLoading: () => {
                    onLoading();
                  },
                  onError: () => {
                    onError();
                  },
                  onSuccess: results => {
                    onSuccess();
                    if (results.new_list) {
                      this.props.addListToListTabs(results.new_list);
                    }
                    this.props.clearAll();
                  }
                });
              }
            });

            this.props.pushSidePanel({ slug: "lists", overlay: true });
            this.props.toggleMenu(false);
          }}
        >
          {title}
        </SelectItem>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { token, user } = auth;
  return {
    token,
    user
  };
};

export default connect(mapStateToProps, {
  bulkUpdateLeads,
  pushSidePanel,
  setListModal,
  addListToListTabs
})(CreateListButton);
