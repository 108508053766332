import React, { Component } from "react";
import { Wrapper, Scroll } from "app/NativeComponents/common";
import WorkflowComponents from "./WorkflowComponents";
class MainWorkflowView extends Component {
  render() {
    return <WorkflowComponents {...this.props} />;
  }
}

export default MainWorkflowView;
