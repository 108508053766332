import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  Card,
  Bold,
  Row,
  Title,
  ProgressBar,
  Scroll
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  InformationItem,
  Carousel,
  InlineButton
} from "app/NativeComponents/snippets";
import { formatUsPhone, numberWithCommas } from "app/NativeActions";
class Foreclosures extends Component {
  constructor(props) {
    super(props);

    this.state = { more_info: false, offset: 0 };
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { property, search, colors } = this.props;
    const foreclosure_data = property?.foreclosure_data;
    if (foreclosure_data) {
      return (
        <>
          {"Foreclosure Information"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Default date".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Unpaid balance".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Past due amount".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Due date".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Current lender name".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Foreclosure document recording date"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Document type".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Auction date".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Auction time".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Auction location".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Auction city".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Auction minimum bid amount"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Trustee name".toLowerCase().indexOf(search?.toLowerCase()) !== -1 ||
          "Trustee address".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          "Trustee phone number"
            .toLowerCase()
            .indexOf(search?.toLowerCase()) !== -1 ||
          "Trustee case number".toLowerCase().indexOf(search?.toLowerCase()) !==
            -1 ||
          !search ? (
            <Wrapper
              id={"foreclosure-information"}
              style={{ marginTop: 10 }}
              onLayout={event => {
                const { x, y, width, height } = event?.nativeEvent?.layout;
                this.setState({
                  offset: y
                });
              }}
            >
              <Card
                label={"Foreclosure Information"}
                style={{ paddingTop: 10, flex: 1 }}
              >
                <Row
                  style={
                    this.props.expanded
                      ? {
                          flexDirection: "row",
                          alignItems: "stretch",
                          flexWrap: "wrap"
                        }
                      : { flexDirection: "column", alignItems: "stretch" }
                  }
                >
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={foreclosure_data?.DefaultDate}
                    label={"Default date:"}
                    format={"date"}
                    hiddenFromNonSubs={true}
                    helper={"default_date"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={foreclosure_data?.UnpaidBalance}
                    label={"Unpaid balance:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"foreclosure_unpaid_balance"}
                  />
                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={foreclosure_data?.PastDueAmount}
                    label={"Past due amount:"}
                    format={"money"}
                    hiddenFromNonSubs={true}
                    helper={"past_due_amount"}
                  />

                  <InformationItem
                    style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                    search={search}
                    item={foreclosure_data?.DueDate}
                    label={"Due date:"}
                    format={"date"}
                    hiddenFromNonSubs={true}
                    helper={"due_date"}
                  />

                  {this.state.more_info || !!search ? (
                    <>
                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.CurrentLenderName}
                        label={"Lender name :"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"foreclosure_lender_name"}
                      />

                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.RecordingDate}
                        label={"Foreclosure document recording date:"}
                        format={"date"}
                        hiddenFromNonSubs={true}
                        helper={"notice_of_default_recording_date"}
                      />

                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.DocumentType}
                        label={"Document type:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"foreclosure_doc_type"}
                      />

                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.AuctionDate}
                        label={"Auction date:"}
                        format={"date"}
                        hiddenFromNonSubs={true}
                        helper={"auction_date"}
                      />

                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.AuctionTime}
                        label={"Auction time:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"auction_time"}
                      />
                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.AuctionLocation}
                        label={"Auction location:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"auction_location"}
                      />
                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.AuctionCity}
                        label={"Auction city:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"auction_city"}
                      />

                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.AuctionMinimumBidAmount}
                        label={"Auction minimum bid amount:"}
                        format={"money"}
                        hiddenFromNonSubs={true}
                        helper={"auction_min_bid_amount"}
                      />

                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={
                          !!foreclosure_data?.TrusteeFirstNameAndMiddleInitial
                            ? foreclosure_data?.TrusteeFirstNameAndMiddleInitial +
                              " " +
                              foreclosure_data?.TrusteeLastNameORCorporationName
                            : foreclosure_data?.TrusteeLastNameORCorporationName
                        }
                        label={"Trustee name:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"trustee_name"}
                      />
                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.TrusteeFullStreetAddress}
                        label={"Trustee address:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"trustee_address"}
                      />
                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={
                          !!foreclosure_data?.TrusteeTelephoneNumber
                            ? formatUsPhone(
                                foreclosure_data?.TrusteeTelephoneNumber
                              )
                            : ""
                        }
                        label={"Trustee phone number:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"trustee_phone"}
                      />
                      <InformationItem
                        style={
                          this.props.expanded ? { width: "50%" } : { flex: 1 }
                        }
                        search={search}
                        item={foreclosure_data?.TrusteeSaleNumber}
                        label={"Trustee case number:"}
                        format={"text"}
                        hiddenFromNonSubs={true}
                        helper={"trustee_case_number"}
                      />
                    </>
                  ) : null}
                </Row>

                {!search && this.state.more_info ? (
                  <InlineButton
                    onPress={() => {
                      this.setState(
                        {
                          more_info: false
                        },
                        () => {
                          if (this.props.device === "desktop") {
                            this.props.scrollToSection({
                              id: "foreclosure-information",
                              offset: this.state.offset
                            });
                          } else {
                            this._interval = setTimeout(() => {
                              this.props.scrollToSection({
                                id: "foreclosure-information",
                                offset: this.state.offset
                              });
                            }, 50);
                          }
                        }
                      );
                    }}
                    button_type={"full"}
                    icon={"keyboard-arrow-up"}
                  >
                    Less Info
                  </InlineButton>
                ) : !search ? (
                  <InlineButton
                    onPress={() => {
                      this.setState(
                        {
                          more_info: true
                        },
                        () => {
                          this.props.scrollToSection({
                            id: "foreclosure-information",
                            offset: this.state.offset
                          });
                        }
                      );
                    }}
                    button_type={"full"}
                    icon={"keyboard-arrow-down"}
                  >
                    More Info
                  </InlineButton>
                ) : null}
              </Card>
            </Wrapper>
          ) : null}
        </>
      );
    }
    return null;
  }
}

export default Foreclosures;
