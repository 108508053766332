import React, { Component } from "react";

import {
  Wrapper,
  Scroll,
  CardBody,
  Title,
  PrimaryButton,
  Copy,
  Card,
  Spin,
  HTMLMarkdown
} from "app/NativeComponents/common";

import { SpinWrapper } from "app/NativeComponents/snippets";

class Body extends Component {
  render() {
    if (this.props.terms_loading) {
      return (
        <Wrapper
          style={{
            padding: 25,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <SpinWrapper text="Loading" />
        </Wrapper>
      );
    } else if (this.props.terms_error != "") {
      return (
        <Wrapper style={{ padding: 25 }}>
          <Copy>{this.props.terms_error}</Copy>
        </Wrapper>
      );
    } else if (this.props.terms_of_service != "") {
      return (
        <Wrapper style={{ padding: 25 }}>
          <HTMLMarkdown
            source={this.props.terms_of_service}
            escapeHtml={false}
          />
        </Wrapper>
      );
    }

    return null;
  }
}

export default Body;
