import React, { Component } from "react";

import { Header4 } from "app/NativeComponents/website/common";

const CardTitle = ({ card }) => {
  if (card.title) {
    return (
      <Header4>
        <b>{card.title}</b>
      </Header4>
    );
  }

  return <div />;
};

export default CardTitle;
