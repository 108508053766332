import React, { Component } from "react";
import {
  Row,
  Wrapper,
  Button,
  DropzoneButton
} from "app/NativeComponents/common";
import { InputBox, CircleButton } from "app/NativeComponents/snippets";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false
    };

    this._phone_number_input = React.createRef();
  }

  render() {
    const { editUser, loading } = this.props;
    return (
      <>
        <InputBox
          input_ref={this._phone_number_input}
          name="phone_number"
          disabled={loading}
          returnKeyType="next"
          placeholder="Phone Number"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "user_phone", value })
          }
          onSubmitEditing={() => {
            this.props.saveUserInfo();
          }}
          blurOnSubmit={false}
          value={editUser.user_phone}
          type="text"
          mask_type={"cel-phone"}
          mask={"(999) 999-9999"}
        />
      </>
    );
  }
}

export default Inputs;
