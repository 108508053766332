import React from "react";

const Diangle = ({ type = null, style, diangles, location = "Bottom" }) => {
  if (diangles) {
    if (diangles.length) {
      for (var i = 0; i < diangles.length; i++) {
        if (diangles[i] == location) {
          if (location == "Top") {
            return (
              <div className={"dm-website-diangle-top"} style={{ style }}>
                <img alt="diangle" src={"/assets/images/diangle-top.svg"} />
              </div>
            );
          } else if (location == "Bottom") {
            return (
              <div className={"dm-website-diangle-bottom"} style={{ style }}>
                <img alt="diangle" src={"/assets/images/diangle-bottom.svg"} />
              </div>
            );
          }
        }
      }
    }
  } else if (type != null) {
    return (
      <div
        className={
          type == "Top"
            ? "dm-website-diangle-top "
            : "dm-website-diangle-bottom"
        }
        style={{ ...style }}
      >
        <img
          alt="diangle"
          src={
            type == "Top"
              ? "/assets/images/diangle-top.svg"
              : "/assets/images/diangle-bottom.svg"
          }
        />
      </div>
    );
  }

  return <div />;
};

export { Diangle };
