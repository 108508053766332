import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  RightPanelContainer,
  WebContainer,
  KeyboardView,
  Scroll,
  Wrapper,
  Row,
  Card,
  CardBody,
} from "app/NativeComponents/common";

import { Header, IconButton } from "app/NativeComponents/snippets";
import MainHeader from "app/DealMachineCore/reuseable/MainHeader";

import {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  mobileToggleDrawer,
  changeTab,
  appRedirect,
  checkIfOnAEnterprisePlan,
  updateTeamMembers,
  updateDashboard,
  getDashboardSettings,
  toggleTeamActivityModal,
  updateUserSettings,
  pushSidePanel,
  tabNavigation,
} from "app/NativeActions";

import Steps from "./Steps";
import CompletedSteps from "./CompletedSteps";
import CompletedStepsEnterprise from "./CompletedStepsEnterprise";

import News from "./News";
import Webinars from "./News/Webinars";
import MonthlyLeadGoal from "./MonthlyLeadGoal";
import ActiveMailersGoal from "./ActiveMailersGoal";
import ActiveLeadSnapshot from "./ActiveLeadSnapshot";
import DownloadApp from "./DownloadApp";

import Analytics from "./Analytics";
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_enterprise:
        props.user?.user_version < 6
          ? checkIfOnAEnterprisePlan({
              plan_modules: props.plan_modules,
            })
          : props.source_of_truth?.subscription?.metadata?.enterprise,
    };

    this._scroll_view = React.createRef();
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    /*mobile*/
    if (this.props.device == "mobile") {
      this.props.changeTab("dashboard");
    }

    if (this.props.dashboardMobile) {
      this.props.toggleCanPop("");
      this.props.lockDrawer(false);
    }
  }
  componentDidUpdate(prevProps) {
    if (parseInt(this.props.user?.team_clearance_level) === 0) {
      this.props.appRedirect({ redirect: "map" });
    }
  }

  renderAnalytics() {
    if (
      (this.props.user?.completed_dashboard_steps &&
        !this.state.is_enterprise) ||
      (this.props.user?.completed_enterprise_steps && this.state.is_enterprise)
    ) {
      return (
        <Row
          style={{
            flexDirection: this.props.dashboardMobile ? "column" : "row",
          }}
        >
          <Wrapper style={{ alignSelf: "stretch", flex: 1 }}>
            <MonthlyLeadGoal
              {...this.props}
              is_enterprise={this.state.is_enterprise}
            />
            <ActiveMailersGoal
              {...this.props}
              is_enterprise={this.state.is_enterprise}
            />
            <ActiveLeadSnapshot
              {...this.props}
              is_enterprise={this.state.is_enterprise}
            />
          </Wrapper>
          <Analytics {...this.props} />
        </Row>
      );
    }
  }

  scrollToTop() {
    if (this.props.device == "mobile") {
      if (this._scroll_view) {
        if (this._scroll_view.current) {
          this._scroll_view.current.scrollToPosition(
            0,
            this.props.platform == "android" ? -40 : 0
          );
        }
      }
    } else {
    }
  }

  render() {
    return (
      <Container fill={true} title={"Dashboard"}>
        <MainHeader title={"Dashboard"} />

        <KeyboardView style={{ maxWidth: 1200 }} scroll_ref={this._scroll_view}>
          <Wrapper
            style={{
              paddingTop: 0,
              paddingBottom: 20,
              padding: this.props.dashboardMobile ? 0 : 20,
            }}
          >
            <Row
              style={{
                flexDirection: this.props.dashboardMobile ? "column" : "row",
              }}
            >
              {this.state.is_enterprise &&
              this.props.user.completed_enterprise_steps === false ? (
                <Steps
                  {...this.props}
                  is_enterprise={this.state.is_enterprise}
                  scrollToTop={this.scrollToTop}
                />
              ) : (
                <DownloadApp {...this.props} />
              )}

              <CompletedSteps
                {...this.props}
                is_enterprise={this.state.is_enterprise}
              />
              <CompletedStepsEnterprise
                {...this.props}
                is_enterprise={this.state.is_enterprise}
              />
              <News {...this.props} is_enterprise={this.state.is_enterprise} />
            </Row>
            <Webinars {...this.props} />
            {this.renderAnalytics()}
          </Wrapper>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  settings,
  native,
  billing,
  feature_toggle,
}) => {
  const { token, user } = auth;
  const { navbarBeta } = feature_toggle;
  const {
    colors,
    dashboard_settings_loading,
    completed_dashboard_steps,
    properties_added_last_30_days,
    properties_added_last_24_hours,
    dashboard_goals,
    enterprise_goals,
    enterprise_goal_variables,
    active_snapshot,
    starting_point,
  } = settings;

  const { dashboardMobile, isMobile, device, platform, mobile_toggle_drawer } =
    native;
  const { plan_modules, source_of_truth } = billing;
  return {
    token,
    user,
    dashboardMobile,
    isMobile,
    colors,
    device,
    platform,
    mobile_toggle_drawer,
    plan_modules,
    dashboard_settings_loading,
    properties_added_last_30_days,
    properties_added_last_24_hours,
    dashboard_goals,
    enterprise_goals,
    enterprise_goal_variables,
    completed_dashboard_steps,
    active_snapshot,
    starting_point,
    navbarBeta,
    source_of_truth,
  };
};

export default connect(mapStateToProps, {
  toggleCanPop,
  toggleDrawer,
  lockDrawer,
  mobileToggleDrawer,
  changeTab,
  appRedirect,
  updateDashboard,
  updateTeamMembers,
  updateUserSettings,
  getDashboardSettings,
  toggleTeamActivityModal,
  pushSidePanel,
  tabNavigation,
})(Dashboard);
