import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DraggableList = ({
  style,
  children,
  items = [],
  reverse = false,
  direction = "vertical",
  renderItem = () => {},
  onReorder = () => {},
  renderHandle = () => {},
  noHandle = false,
  disabled = false
}) => {
  return (
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) {
          return;
        }
        if (reverse) {
          let previousIndex = 0;
          for (let i = 0; i < items.length; i++) {
            if (items[i].id == result.draggableId) {
              previousIndex = i;
            }
          }

          onReorder({
            previousIndex: previousIndex,
            nextIndex: items.length - result.destination.index - 1
          });
        } else {
          onReorder({
            previousIndex: result.source.index,
            nextIndex: result.destination.index
          });
        }
      }}
    >
      <Droppable droppableId="droppable" direction={direction}>
        {(provided, snapshot) => (
          <div
            style={
              direction == "horizontal"
                ? { display: "flex", flexDirection: "row" }
                : {}
            }
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {reverse
              ? items
                  .slice(0)
                  .reverse()
                  .map((item, index) => (
                    <Draggable
                      key={item?.id ? item?.id.toString() : ""}
                      draggableId={item?.id ? item?.id.toString() : ""}
                      index={index}
                      isDragDisabled={disabled}
                    >
                      {(provided, snapshot) =>
                        noHandle ? (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided?.dragHandleProps}
                          >
                            {renderItem({
                              item,
                              wrappedHandle: () => {
                                return null;
                              },
                              index
                            })}
                          </div>
                        ) : (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {renderItem({
                              item,
                              wrappedHandle: () => {
                                return (
                                  <div {...provided?.dragHandleProps}>
                                    {renderHandle()}
                                  </div>
                                );
                              },
                              index
                            })}
                          </div>
                        )
                      }
                    </Draggable>
                  ))
              : items.map((item, index) => (
                  <Draggable
                    key={item?.id ? item?.id.toString() : ""}
                    draggableId={item?.id ? item?.id.toString() : ""}
                    index={index}
                  >
                    {(provided, snapshot) =>
                      noHandle ? (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided?.dragHandleProps}
                        >
                          {renderItem({
                            item,
                            wrappedHandle: () => {
                              return null;
                            },
                            index
                          })}
                        </div>
                      ) : (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {renderItem({
                            item,
                            wrappedHandle: () => {
                              return (
                                <div {...provided?.dragHandleProps}>
                                  {renderHandle()}
                                </div>
                              );
                            },
                            index
                          })}
                        </div>
                      )
                    }
                  </Draggable>
                ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DraggableList };
