import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Spin,
  Row,
  SemiCircleProgress
} from "app/NativeComponents/common";

import moment from "moment";
import { numberWithCommas } from "app/NativeActions";
class MonthlyLeadGoal extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  calculateNumberOfDeals() {
    if (
      this.props.user.enterprise_goals &&
      this.props.user.enterprise_goals.profit_per_month &&
      this.props.user.enterprise_goals.profit_per_deal
    )
      return Math.ceil(
        this.props.user.enterprise_goals.profit_per_month /
          this.props.user.enterprise_goals.profit_per_deal
      );
  }

  calculateNumberOfLeads() {
    const leads_per_deal = this.props.enterprise_goal_variables
      ? this.props.enterprise_goal_variables.leads_per_deal
      : 300;
    return this.calculateNumberOfDeals() * leads_per_deal;
  }

  render() {
    const {
      dashboard_goals,
      dashboard_settings_loading,
      colors,
      user,
      is_enterprise
    } = this.props;

    if (dashboard_settings_loading) {
      return (
        <Wrapper style={{ alignSelf: "stretch", flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <CardBody>
              <Wrapper
                style={{
                  flex: 1,
                  alignSelf: "stretch",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Row>
                  <Spin size={"small"} />
                  <Copy style={{ marginLeft: 10 }}>Loading...</Copy>
                </Row>
              </Wrapper>
            </CardBody>
          </Card>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Card>
          <CardBody>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Wrapper style={{ paddingBottom: 15 }}>
                <Title style={{ textAlign: "center" }}>
                  {dashboard_goals
                    ? dashboard_goals.leads_added_this_month == 1
                      ? "1 Lead"
                      : dashboard_goals.leads_added_this_month + " Leads"
                    : "0 Leads"}{" "}
                  Added In{" "}
                  {dashboard_goals
                    ? dashboard_goals.current_month
                    : moment().format("MMMM")}
                </Title>
                <Copy style={{ textAlign: "center" }}>
                  {(!user?.completed_dashboard_steps && !is_enterprise) ||
                  (!user?.completed_enterprise_steps && is_enterprise)
                    ? "Complete the success steps to see your goal"
                    : "My goal is " +
                      numberWithCommas(
                        is_enterprise
                          ? this.calculateNumberOfLeads()
                          : dashboard_goals
                          ? dashboard_goals.monthly_goal
                          : 100
                      ) +
                      " this month"}
                </Copy>
              </Wrapper>
              <Wrapper>
                <SemiCircleProgress
                  percentage={
                    dashboard_goals
                      ? is_enterprise
                        ? (dashboard_goals.leads_added_this_month /
                            this.calculateNumberOfLeads()) *
                          100
                        : parseInt(
                            (dashboard_goals.leads_added_this_month /
                              dashboard_goals.monthly_goal) *
                              100
                          )
                      : 0
                  }
                  strokeWidth={30}
                  strokeColor={colors.success_color}
                  backgroundColor={colors.background_color}
                  showPercentValue={true}
                />
              </Wrapper>
            </Wrapper>
          </CardBody>
        </Card>
      </Wrapper>
    );
  }
}

export default MonthlyLeadGoal;
