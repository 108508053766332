import React, { Component } from "react";
import {
  Wrapper,
  Card,
  PrimaryButton,
  Button,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  TextButton,
  CheckBoxInput,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";

import { openUrl, displayIntercom } from "app/NativeActions";

class Buttons extends Component {
  renderTermsOfServiceText() {
    if (this.props.affiliate_partner) {
      return (
        <Wrapper>
          <Copy style={{ textAlign: "left" }}>
            By checking this box, you agree to our
          </Copy>
          <Button
            onPress={() => {
              openUrl(
                "https://dealmachine.com/affiliate-partner-terms-of-service"
              );
            }}
          >
            <Copy
              style={{ textAlign: "left", textDecorationLine: "underline" }}
            >
              <Bold>affiliate terms of service.</Bold>
            </Copy>
          </Button>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Copy style={{ textAlign: "left" }}>
          By checking this box, you agree to our
        </Copy>
        <Button
          onPress={() => {
            openUrl("https://dealmachine.com/terms-of-service");
          }}
        >
          <Copy style={{ textAlign: "left", textDecorationLine: "underline" }}>
            <Bold>terms of service.</Bold>
          </Copy>
        </Button>
      </Wrapper>
    );
  }

  render() {
    return (
      <Wrapper>
        <CheckBoxInput
          onPress={() =>
            this.props.authFieldChanged({
              prop: "accepted_terms",
              value: this.props.accepted_terms == 1 ? 0 : 1
            })
          }
          value={this.props.accepted_terms == 1 ? true : false}
          title={""}
          componentView={() => {
            return (
              <Wrapper
                style={{
                  alignItems: "flex-start",
                  justfyContent: "center"
                }}
              >
                {this.renderTermsOfServiceText()}
              </Wrapper>
            );
          }}
        />

        <GhostButton
          primary={true}
          button_type="full"
          onPress={() => this.props.register()}
          loading={this.props.loading}
        >
          {this.props.user_info.signup_type == "team_invite"
            ? "Join Team"
            : this.props.no_container
            ? this.props.buttonText && this.props.buttonText != ""
              ? this.props.buttonText
              : this.props.affiliate_partner
              ? "Join Our Affiliate Program"
              : this.props.onboarding_content
              ? this.props.onboarding_content.signUpNextButton
              : "Next"
            : this.props.onboarding_content
            ? this.props.onboarding_content.signUpNextButton
            : "Next"}
        </GhostButton>

        <InlineButton
          button_type="full"
          onPress={() => displayIntercom()}
          id="intercom_button"
        >
          Need help? Talk to a human.
        </InlineButton>
      </Wrapper>
    );
  }
}

export default Buttons;
