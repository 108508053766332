import React, { PureComponent } from "react";
import { store } from "app/store";

import { Wrapper, Gradient, Row, Stretch } from "app/NativeComponents/common";

import HeaderTitle from "./HeaderTitle";
import HeaderSubtitle from "./HeaderSubtitle";
import HeaderButton from "./HeaderButton";

class Header extends PureComponent {
  render() {
    const colors = store.getState().settings.colors;
    const {
      style,
      title,
      titleColor,
      subtitle,
      backgroundColor = colors.background_color,
      backgroundColor2 = null,
      leftButtonIcon,
      leftButtonFAIcon,
      leftButtonTitle,
      leftButtonColor,
      leftButtonAction,
      leftButtonDisabled,
      rightButtonIcon,
      rightButtonFAIcon,
      rightButtonTitle,
      rightButtonPrimary = false,
      rightButtonColor,
      rightButtonStyle,
      rightButtonAction,
      rightButtonDisabled,
      rightButtonDropzone,
      rightButtonDropzoneAccept,
      rightButtonPopover = false,
      rightButtonPopoverItems = [],
      rightButtonFeatureLock,
      rightButtonComponents = null,
      rightButtonIcon2,
      rightButtonFAIcon2,
      rightButtonStyle2,
      rightButtonTitle2,
      rightButtonColor2,
      rightButtonAction2,
      rightButtonDisabled2,
      rightButtonComponents2 = null,
      rightButtonPrimary2 = false,
      rightButtonPopover2 = false,
      rightButtonPopoverItems2 = [],
      rightButtonIcon3,
      rightButtonFAIcon3,
      rightButtonTitle3,
      rightButtonColor3,
      rightButtonAction3,
      rightButtonDisabled3,
      rightButtonComponents3 = null,
      rightButtonPopover3 = false,
      rightButtonPopoverItems3 = [],
      absolute,
      component
    } = this.props;

    return (
      <Gradient
        style={
          absolute
            ? {
                width: "100%",
                top: 0,
                left: 0,
                position: "absolute"
              }
            : {}
        }
        color1={backgroundColor}
        color2={backgroundColor2 ? backgroundColor2 : backgroundColor}
      >
        <Wrapper
          style={{
            height: "auto",
            minHeight: 65,
            display: "flex",
            justifyContent: "center",
            ...style
          }}
        >
          <Row>
            <HeaderButton
              buttonIcon={leftButtonIcon}
              buttonFAIcon={leftButtonFAIcon}
              buttonTitle={leftButtonTitle}
              buttonColor={leftButtonColor}
              buttonAction={leftButtonAction}
              buttonDisabled={leftButtonDisabled}
            />

            <Stretch
              style={{
                alignItems: "flex-start",
                justifyContent: "center"
              }}
            >
              <HeaderTitle title={title} titleColor={titleColor} />
              <HeaderSubtitle subtitle={subtitle} titleColor={titleColor} />
              {component ? component() : <Wrapper />}
            </Stretch>

            <HeaderButton
              buttonIcon={rightButtonIcon}
              buttonOrder={1}
              buttonFAIcon={rightButtonFAIcon}
              buttonTitle={rightButtonTitle}
              buttonPrimary={rightButtonPrimary}
              buttonColor={rightButtonColor}
              buttonAction={rightButtonAction}
              buttonDisabled={rightButtonDisabled}
              isDropzone={rightButtonDropzone}
              dropzoneAccept={rightButtonDropzoneAccept}
              buttonComponents={rightButtonComponents}
              featureLock={rightButtonFeatureLock}
              buttonPopover={rightButtonPopover}
              buttonPopoverItems={rightButtonPopoverItems}
              buttonStyle={rightButtonStyle}
            />
            <HeaderButton
              buttonIcon={rightButtonIcon2}
              buttonOrder={2}
              buttonFAIcon={rightButtonFAIcon2}
              buttonTitle={rightButtonTitle2}
              buttonPrimary={rightButtonPrimary2}
              buttonColor={rightButtonColor2}
              buttonAction={rightButtonAction2}
              buttonDisabled={rightButtonDisabled2}
              buttonComponents={rightButtonComponents2}
              buttonPopover={rightButtonPopover2}
              buttonPopoverItems={rightButtonPopoverItems2}
              buttonStyle={rightButtonStyle2}
            />
            <HeaderButton
              buttonIcon={rightButtonIcon3}
              buttonOrder={3}
              buttonFAIcon={rightButtonFAIcon3}
              buttonTitle={rightButtonTitle3}
              buttonColor={rightButtonColor3}
              buttonAction={rightButtonAction3}
              buttonDisabled={rightButtonDisabled3}
              buttonComponents={rightButtonComponents3}
              buttonPopover={rightButtonPopover3}
              buttonPopoverItems={rightButtonPopoverItems3}
            />
          </Row>
        </Wrapper>
      </Gradient>
    );
  }
}

export { Header };
