import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Title,
  Bold
} from "app/NativeComponents/common";
import {
  NewHeader,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";

import {
  openUrl,
  renderDate,
  AppConfig,
  appRedirect,
  manageSubscription,
  displayIntercom,
  logout
} from "app/NativeActions";

class BadCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }
  render() {
    const { source_of_truth, user } = this.props;

    return (
      <Container>
        {this.props.device == "mobile" ? (
          <NewHeader title="Unpaid Invoice" />
        ) : (
          <Wrapper style={{ padding: 25 }}>
            <Title style={{ textAlign: "center" }}>Unpaid Invoice</Title>
          </Wrapper>
        )}

        {user?.team_clearance_level >= 2 ? (
          <>
            <Wrapper style={{ padding: 25 }}>
              <Copy style={{ textAlign: "center" }}>
                <Bold>You have an unpaid invoice.</Bold>
              </Copy>
              <Copy style={{ textAlign: "center" }}>
                Pay the balance due to access your account.
              </Copy>
            </Wrapper>
            <GhostButton
              primary={true}
              button_type="full"
              loading={this.state.loading}
              onPress={() => {
                this.props.manageSubscription({
                  token: this.props.token,
                  type: "pay_invoice",
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },

                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        loading: false
                      },
                      () => {}
                    );
                  }
                });
              }}
            >
              Pay Invoice
            </GhostButton>
            <InlineButton
              button_type="full"
              id="intercom_button"
              onPress={() => {
                openUrl(
                  AppConfig().api_url +
                    "billing/customer-portal/?token=" +
                    this.props.token +
                    "&return_url=" +
                    AppConfig().app_url,
                  true
                );
              }}
            >
              Manage Payment Methods
            </InlineButton>

            <InlineButton
              button_type="full"
              id="intercom_button"
              onPress={() => {
                displayIntercom();
              }}
            >
              Need help? Talk to a human.
            </InlineButton>
            <InlineButton
              button_type="full"
              onPress={() => this.props.logout()}
            >
              Logout
            </InlineButton>
          </>
        ) : (
          <>
            <Wrapper style={{ padding: 25 }}>
              <Copy style={{ textAlign: "center" }}>
                <Bold>You have an unpaid invoice.</Bold>
              </Copy>
              <Copy style={{ textAlign: "center" }}>
                An admin on your account needs to pay the balance due in order
                to access your account.
              </Copy>
            </Wrapper>

            <InlineButton
              button_type="full"
              id="intercom_button"
              onPress={() => {
                displayIntercom();
              }}
            >
              Need help? Talk to a human.
            </InlineButton>
            <InlineButton
              button_type="full"
              onPress={() => this.props.logout()}
            >
              Logout
            </InlineButton>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;

  return { token, user, device, colors, source_of_truth };
};

export default connect(mapStateToProps, {
  manageSubscription,
  appRedirect,
  logout
})(BadCard);
