import React, { Component } from "react";
import { store } from "app/store";

import { Wrapper, Copy, Bold, Icon } from "app/NativeComponents/common";

class NumberCheck extends Component {
  render() {
    const isMobile = store.getState().native.isMobile;
    const colors = store.getState().settings.colors;

    if (!isMobile || (isMobile && this.props.show)) {
      if (this.props.full_design) {
        if (this.props.is_successful) {
          return (
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "stretch",
                padding: 20,
                marginRight: 10,
                backgroundColor: colors.success_color
              }}
            >
              <Icon icon="check" size={18} color={colors.white_text_color} />
            </Wrapper>
          );
        }
        return (
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
              padding: 20,
              marginRight: 10,
              backgroundColor: colors.gray_color
            }}
          >
            {!!this.props.icon ? (
              <Icon icon={this.props.icon} size={18} c />
            ) : (
              <Copy>
                <Bold>{this.props.number}</Bold>
              </Copy>
            )}
          </Wrapper>
        );
      }
      if (this.props.is_successful) {
        return (
          <Wrapper
            style={{
              width: 36,
              height: 36,
              borderRadius: 18,
              margin: 10,
              marginRight: 20,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.success_color
            }}
          >
            <Icon icon="check" size={18} color={colors.white_text_color} />
          </Wrapper>
        );
      }
      return (
        <Wrapper
          style={{
            width: 36,
            height: 36,
            borderRadius: 18,
            margin: 10,
            marginRight: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: colors.gray_color
          }}
        >
          {!!this.props.icon ? (
            <Icon icon={this.props.icon} size={18} c />
          ) : (
            <Copy>
              <Bold>{this.props.number}</Bold>
            </Copy>
          )}
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

export default NumberCheck;
