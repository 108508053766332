import React, { Component } from "react";
import {
  Wrapper,
  CardBody,
  WistiaVideo,
  Title,
  Card,
  Copy,
  Row
} from "app/NativeComponents/common";
import { PillButton } from "app/NativeComponents/snippets";
import SkipButton from "./SkipButton";

import { loadDashboardStep } from "app/NativeActions";

class ContactLeadsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboard_step: null
    };

    this.completedStep = this.completedStep.bind(this);
  }

  componentDidMount() {
    loadDashboardStep("contact_leads")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              dashboard_step: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  renderVideo1(dashboard_step) {
    if (dashboard_step.video1WistiaUrl) {
      return (
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <Card style={{ alignSelf: "center", overflow: "hidden" }}>
            <WistiaVideo
              wistia_url={dashboard_step.video1WistiaUrl}
              wistia_id={dashboard_step.video1WistiaId}
              height={!this.props.dashboardMobile ? 180 : 90}
              width={!this.props.dashboardMobile ? 320 : 160}
            />
          </Card>
        </Wrapper>
      );
    }
  }
  renderVideo2(dashboard_step) {
    if (dashboard_step.video2WistiaUrl) {
      return (
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <Card style={{ alignSelf: "center", overflow: "hidden" }}>
            <WistiaVideo
              wistia_url={dashboard_step.video2WistiaUrl}
              wistia_id={dashboard_step.video2WistiaId}
              height={!this.props.dashboardMobile ? 180 : 90}
              width={!this.props.dashboardMobile ? 320 : 160}
            />
          </Card>
        </Wrapper>
      );
    }
  }
  completedStep() {
    // Either move on to "Work With Us" as elite, or complete dashboard steps for non-elite
    if (this.props.is_enterprise) {
      this.props.updateTab("work_with_us");
      this.props.updateDashboard({
        token: this.props.token,
        type: "enterprise_step",
        current_step: "work_with_us"
      });
    } else {
      this.props.updateDashboard({
        token: this.props.token,
        type: "completed_dashboard_steps"
      });
    }
  }
  render() {
    const { loading, dashboard_step } = this.state;
    if (this.props.active_tab != "contact_leads") return <Wrapper />;

    if (!loading && dashboard_step) {
      return (
        <Wrapper>
          <CardBody>
            <Row
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: this.props.dashboardMobile ? "column" : "row"
              }}
            >
              <Wrapper style={{ padding: 20 }}>
                <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: this.props.dashboardMobile ? "row" : "column"
                  }}
                >
                  {this.renderVideo1(dashboard_step)}
                  {this.renderVideo2(dashboard_step)}
                </Wrapper>
              </Wrapper>
              <Wrapper
                style={{
                  padding: 20,
                  paddingLeft: this.props.dashboardMobile ? 0 : 20,
                  paddingTop: this.props.dashboardMobile ? 20 : 0
                }}
              >
                <Title style={{ textAlign: "center", marginBottom: 10 }}>
                  {dashboard_step.header}
                </Title>
                <Copy style={{ textAlign: "center" }}>
                  {dashboard_step.description}
                </Copy>
                {/*
                  If user is elite, the next step will be something like "Work With Us".
                  If not, this is the last step, so we should see the primary pill button with text like "Complete Setup"
                  Copy is sourced from Contentful
                */}
                {this.props.is_enterprise ? (
                  <SkipButton
                    onPress={this.completedStep}
                    title={dashboard_step.secondaryButtonText}
                  />
                ) : (
                  <Row style={{ justifyContent: "center" }}>
                    <PillButton primary={true} onPress={this.completedStep}>
                      {dashboard_step.nextButtonText}
                    </PillButton>
                  </Row>
                )}
              </Wrapper>
            </Row>
          </CardBody>
        </Wrapper>
      );
    }
    return <Wrapper />;
  }
}

export default ContactLeadsStep;
