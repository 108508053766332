import React from "react";
import { store } from "app/store";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownView = ({ source, escapeHtml, style }) => {
  function markdownAsEscapedSequence(str) {
    const specialChars = /[!()[\]`\-*+=~_#|]|[0-9]/g;
    if (!!str) {
      return str?.replace(specialChars, function (match) {
        const string = "" + match.charCodeAt(0) || "00";
        const prefix = "&#";
        const suffix = ";";
        return prefix + string + suffix;
      });
    }
    return "";
  }

  const dark_mode = store.getState().settings.dark_mode;
  return (
    <ReactMarkdown
      style={style}
      className={
        dark_mode == "dark_mode" ? "dm-markdown dark-mode" : "dm-markdown"
      }
      children={source}
      skipHtml={escapeHtml}
      remarkPlugins={[remarkGfm]}
    />
  );
};

export { MarkdownView };
