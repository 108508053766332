import React from "react";

const CardBody = ({ children, style }) => {
  return (
    <div className="deal-card-body" style={style}>
      {children}
    </div>
  );
};

export { CardBody };
