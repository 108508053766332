import {
  LOGOUT,
  RESET_UPLOAD_LIST,
  CHANGE_UPLOAD_LIST_FIELD
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  uploadList: {
    approveDeals: 0,
    searchDeals: 0
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case RESET_UPLOAD_LIST:
      return {
        ...state,
        uploadList: INITIAL_STATE.uploadList
      };
    case CHANGE_UPLOAD_LIST_FIELD:
      return {
        ...state,
        uploadList: {
          ...state.uploadList,
          [action.payload.prop]: action.payload.value
        }
      };

    default:
      return state;
  }
};
