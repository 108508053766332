import React, { Component } from "react";
import { connect } from "react-redux";
import { KeyboardView } from "app/NativeComponents/common";
import {
  PillButton,
  Select,
  SmallHeader,
  BottomNavBar,
  GhostButton,
  SpinWrapper,
  SelectBox
} from "app/NativeComponents/snippets";
import { getLeadStatuses } from "app/NativeActions";

class StatusStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_team: false,
      lead_status_focus: false,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            lead_status_id: 0,
            lead_status_title: ""
          }
    };
  }

  componentDidMount() {
    this.props.getLeadStatuses({
      token: this.props.token
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (this.state.data?.lead_status_id != 0) {
        return true;
      }
    }

    return false;
  }

  renderStatusTitle(lead_status_id) {
    const { lead_statuses } = this.props;
    for (let i = 0; i < lead_statuses.length; i++) {
      if (lead_statuses[i].id == lead_status_id) {
        return lead_statuses[i].label;
      }
    }

    return "None selected";
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      lead_statuses,
      lead_statuses_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Change Lead Status "
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {lead_statuses_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <SelectBox
                items={lead_statuses}
                value={data.lead_status_id}
                placeholder="Select a lead status"
                show={this.state.lead_status_focus}
                onFocus={() => {
                  this.setState({ lead_status_focus: true });
                }}
                onBlur={() => {
                  this.setState({ lead_status_focus: false });
                }}
                onSelect={value => {
                  this.setState({
                    data: {
                      ...data,
                      lead_status_id: value,
                      lead_status_title: this.renderStatusTitle(value)
                    }
                  });
                }}
                onSubmitEditing={() => {}}
              />
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ customsettings }) => {
  const { lead_statuses, lead_statuses_loading } = customsettings;

  return {
    lead_statuses,
    lead_statuses_loading
  };
};

export default connect(mapStateToProps, { getLeadStatuses })(StatusStep);
