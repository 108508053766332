import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy,
  Bold,
  ProgressBar,
  Spin
} from "app/NativeComponents/common";

import { getBulkEdits, numberWithCommas } from "app/NativeActions";
import Pusher from "./Pusher";

class ListBuilding extends Component {
  componentDidMount() {
    this.props.getBulkEdits({ token: this.props.token });
  }

  checkForMatchingBulkEdit() {
    const { bulk_edits, active_list_tab } = this.props;

    for (let i = 0; i < bulk_edits.length; i++) {
      const bulk_edit = bulk_edits[i];
      if (bulk_edit.id == active_list_tab.bulk_job_id) {
        if (parseInt(bulk_edit.percent_complete) / 100 < 1 && i == 0) {
          return true;
        }
      }
    }
    return false;
  }

  checkForMatchingListBuild() {
    const { building_lists, active_list_tab } = this.props;

    for (let i = 0; i < building_lists.length; i++) {
      const list_building = building_lists[i];

      if (list_building?.list_id == active_list_tab?.id) {
        return true;
      }
    }
    return false;
  }
  render() {
    const { bulk_edits, active_list_tab, colors, building_lists } = this.props;

    if (active_list_tab.list_type == "build_list") {
      if (this.checkForMatchingListBuild()) {
        return (
          <>
            {building_lists.map((building_list, i) => {
              if (building_list?.list_id == active_list_tab?.id) {
                return (
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                      alignSelf: "stretch"
                    }}
                    key={"build_list" + i + "_" + building_list.list_id}
                  >
                    <Pusher list_id={active_list_tab.id} />

                    <Wrapper
                      style={{
                        padding: 25,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Wrapper style={{ marginBottom: 10 }}>
                        <Spin text={"Building List..."} />
                      </Wrapper>
                      <Wrapper style={{ marginBottom: 10 }}>
                        <Copy style={{ textAlign: "center" }}>
                          Building this list may take time. Feel free to work on
                          other things. You'll be emailed when your list is
                          complete.
                        </Copy>
                      </Wrapper>

                      <ProgressBar
                        style={{ marginTop: 0 }}
                        color={colors.success_color}
                        backgroundColor={colors.white_color}
                        width={320}
                        progress={
                          parseInt(building_list.success_counter) /
                          parseInt(building_list.total)
                        }
                      />
                      <Wrapper
                        style={{
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Copy style={{ textAlign: "center" }}>
                          {Math.min(
                            100,
                            Math.floor(
                              (100 * parseInt(building_list.success_counter)) /
                                parseInt(building_list.total)
                            )
                          )}
                          {"%"}
                        </Copy>
                      </Wrapper>
                    </Wrapper>
                  </Wrapper>
                );
              }
              return null;
            })}
          </>
        );
      }
    }

    if (
      bulk_edits &&
      bulk_edits.length > 0 &&
      this.checkForMatchingBulkEdit()
    ) {
      return (
        <>
          {bulk_edits.map((bulk_edit, i) => {
            if (bulk_edit.id == active_list_tab.bulk_job_id) {
              if (parseInt(bulk_edit.percent_complete) / 100 < 1 && i == 0) {
                return (
                  <Wrapper
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                      alignSelf: "stretch"
                    }}
                    key={"bulk_edit_" + i + "_" + bulk_edit.id}
                  >
                    <Wrapper
                      style={{
                        padding: 25,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Wrapper style={{ marginBottom: 10 }}>
                        <Spin text={bulk_edit.text} />
                      </Wrapper>
                      <ProgressBar
                        style={{ marginTop: 0 }}
                        color={colors.success_color}
                        backgroundColor={colors.white_color}
                        width={300}
                        progress={parseInt(bulk_edit.percent_complete) / 100}
                      />
                    </Wrapper>
                  </Wrapper>
                );
              }
            }
            return null;
          })}
        </>
      );
    }

    if (active_list_tab.building == 1) {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Wrapper style={{ marginBottom: 10 }}>
              <Spin
                text={
                  active_list_tab.list_type == "bulk_import"
                    ? "Importing List..."
                    : active_list_tab.list_type == "build_list"
                    ? "Building List..."
                    : "Loading..."
                }
              />
            </Wrapper>
            {active_list_tab.list_type == "build_list" ? (
              <Wrapper style={{ marginBottom: 10 }}>
                <Copy style={{ textAlign: "center" }}>
                  Building this list may take time. Feel free to work on other
                  things. You'll be emailed when your list is complete.
                </Copy>
              </Wrapper>
            ) : null}
          </Wrapper>
        </Wrapper>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, lead, list }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile } = native;
  const { bulk_edits } = lead;
  const { active_list_tab, building_lists } = list;

  return {
    token,
    user,
    colors,
    isMobile,
    bulk_edits,
    active_list_tab,
    building_lists
  };
};

export default connect(mapStateToProps, {
  getBulkEdits
})(ListBuilding);
