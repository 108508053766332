import React, { PureComponent } from "react";
import { Row, Copy, Wrapper, Icon, Spin } from "app/NativeComponents/common";

import { store } from "app/store";
import ButtonWrapper from "./ButtonWrapper";

const BottomActionButton = props => {
  const {
    children,
    primary,
    style,
    innerStyle,
    disabled = false,
    deleteButton = false,
    icon,
    fa_icon,
    icon_right,
    fa_icon_right,
    pressedIn = false, //pressed in override from props
    loading = false,
    button_type = "normal"
  } = props;
  const colors = store.getState().settings.colors;

  return (
    <ButtonWrapper
      {...props}
      renderInner={({
        hovering /*actual pressed in from state of button wrapper*/
      }) => {
        const background_color = disabled
          ? colors.gray_selected_color
          : pressedIn || hovering
          ? deleteButton
            ? colors.error_color
            : primary
            ? colors.orange_color
            : colors.actionable_text_color
          : primary
          ? colors.orange_color_muted
          : colors.active_color_muted;

        const border_color = disabled
          ? colors.gray_selected_color
          : deleteButton
          ? colors.error_color
          : primary
          ? colors.orange_color
          : colors.actionable_text_color;

        const text_color =
          disabled || pressedIn || hovering
            ? colors.white_text_color
            : deleteButton
            ? colors.error_color
            : primary
            ? colors.orange_color
            : colors.actionable_text_color;

        let iStyle = {
          borderRadius: 5,
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          backgroundColor: background_color
          /*
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: border_color
          */
        };

        let s = {
          borderRadius: 5,
          minWidth: 50,
          margin: 5
        };

        return (
          <Wrapper
            style={{
              ...s,
              ...style
            }}
          >
            <Wrapper style={{ ...iStyle, ...innerStyle }}>
              <Wrapper>
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                  {!!icon || !!fa_icon ? (
                    <>
                      {loading ? (
                        <Wrapper style={{ marginRight: 5 }}>
                          <Spin
                            size="small"
                            color={text_color}
                            style={{ fontSize: 18 }}
                          />
                        </Wrapper>
                      ) : (
                        <Icon
                          size={18}
                          color={text_color}
                          icon={icon}
                          fa_icon={fa_icon}
                          style={{ marginRight: 5 }}
                        />
                      )}
                    </>
                  ) : null}
                  <Copy
                    style={{
                      color: text_color,
                      textAlign: "center",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {children}
                  </Copy>
                  {loading &&
                  !icon &&
                  !fa_icon &&
                  !icon_right &&
                  !fa_icon_right ? (
                    <Wrapper style={{ marginLeft: 5 }}>
                      <Spin
                        size="small"
                        color={text_color}
                        style={{ fontSize: 18 }}
                      />
                    </Wrapper>
                  ) : null}
                  {!!icon_right || !!fa_icon_right ? (
                    <>
                      {loading ? (
                        <Wrapper style={{ marginLeft: 5 }}>
                          <Spin
                            size="small"
                            color={text_color}
                            style={{ fontSize: 18 }}
                          />
                        </Wrapper>
                      ) : (
                        <Icon
                          size={18}
                          color={text_color}
                          icon={icon_right}
                          fa_icon={fa_icon_right}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </>
                  ) : null}
                </Row>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        );
      }}
    />
  );
};

export { BottomActionButton };
