import React from "react";
import {
  Row,
  Button,
  Card,
  Gradient,
  Copy,
  Bold,
  Wrapper,
  Icon
} from "app/NativeComponents/common";

import { store } from "app/store";

const PillButton = ({
  id,
  children,
  primary,
  formButton = false,
  onPress,
  style,
  buttonContainerStyle,
  buttonStyle,
  innerStyle,
  disabled = false,
  deleteButton = false,
  icon,
  fa_icon,
  noPress = false,
  inBottomSheet = false,
  trackingId
}) => {
  const colors = store.getState().settings.colors;
  const device = store.getState().native.device;

  const innerButton = () => {
    return (
      <Card
        style={{
          borderRadius: 30,
          minWidth: 50,
          ...style
        }}
      >
        <Gradient
          style={{
            borderRadius: 30,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15,
            ...innerStyle
          }}
          color1={
            disabled
              ? colors.gray_color
              : deleteButton
              ? colors.error_color
              : primary
              ? colors.gradient_button_color_1
              : colors.card_button_color
          }
          color2={
            disabled
              ? colors.gray_color
              : deleteButton
              ? colors.error_color
              : primary
              ? colors.gradient_button_color_2
              : colors.card_button_color
          }
        >
          <Wrapper>
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
              {!!icon || !!fa_icon ? (
                <Icon
                  size={18}
                  color={
                    disabled
                      ? colors.white_text_color
                      : deleteButton
                      ? colors.white_text_color
                      : primary
                      ? colors.white_text_color
                      : colors.text_color
                  }
                  icon={icon}
                  fa_icon={fa_icon}
                  style={{ marginRight: 5 }}
                />
              ) : null}
              <Copy
                style={{
                  color: disabled
                    ? colors.white_text_color
                    : deleteButton
                    ? colors.white_text_color
                    : primary
                    ? colors.white_text_color
                    : colors.text_color,
                  textAlign: "center"
                }}
              >
                <Bold>{children}</Bold>
              </Copy>
            </Row>
          </Wrapper>
        </Gradient>
      </Card>
    );
  };

  return (
    <Row style={buttonContainerStyle}>
      {noPress ? (
        <Wrapper
          id={id}
          style={{ outline: "none", borderWidth: 0, ...buttonStyle }}
        >
          {innerButton()}
        </Wrapper>
      ) : (
        <Button
          id={id}
          inBottomSheet={inBottomSheet}
          onPress={() => !disabled && onPress()}
          formButton={formButton}
          trackingId={trackingId}
          style={{ outline: "none", borderWidth: 0, ...buttonStyle }}
        >
          {innerButton()}
        </Button>
      )}
    </Row>
  );
};

export { PillButton };
