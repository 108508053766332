import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import ToggleButton from "./ToggleButton";
import Buttons from "./Buttons";
import { Icon } from "app/NativeComponents/common";

class NavBar extends Component {
  renderLogo(logo, partner_logo, clickableLogo) {
    if (clickableLogo) {
      if (
        this.props.partner_info.partner_id != 0 &&
        this.props.partner_info.partner_id != "" &&
        !!partner_logo
      ) {
        return (
          <a href="https://dealmachine.com">
            <div className="dm-website-partner-logos">
              <img alt="" className="dm-website-navbar-logo" src={logo} />
              <span>
                <Icon icon="add" size={18} style={{}} color="#fff" />
              </span>
              <img
                alt=""
                className="dm-website-navbar-partner-logo"
                src={partner_logo}
              />
            </div>
          </a>
        );
      }

      return (
        <a href="https://dealmachine.com">
          <img alt="" className="dm-website-navbar-logo" src={logo} />
        </a>
      );
    }

    return <img alt="" className="dm-website-navbar-logo" src={logo} />;
  }

  renderButtons(includeButtons) {
    if (includeButtons) {
      return <Buttons />;
    }
  }

  render() {
    let className = this.props.classname;
    var sourceUrl = "";
    var partnerUrl = "";

    if (this.props.isTop && !this.props.dropDownOpen) {
      if (this.props.navBarStyle == "dark") {
        sourceUrl = "/assets/images/DealMachineLogoWhite.svg";
        partnerUrl = this.props.partner_info.partner_logo_white;
      } else {
        sourceUrl = "/assets/images/DealMachineLogo.svg";
        partnerUrl = this.props.partner_info.partner_logo;
      }
    } else if (this.props.isMobile || this.props.dropDownOpen) {
      sourceUrl = "/assets/images/DealMachineLogo.svg";
      partnerUrl = this.props.partner_info.partner_logo;
    } else if (this.props.navBarStyle == "dark") {
      if (!this.props.isTop) {
        sourceUrl = "/assets/images/DealMachineLogo.svg";
        partnerUrl = this.props.partner_info.partner_logo;
      } else {
        sourceUrl = "/assets/images/DealMachineLogoWhite.svg";
        partnerUrl = this.props.partner_info.partner_logo_white;
      }
    } else {
      sourceUrl = "/assets/images/DealMachineLogo.svg";
      partnerUrl = this.props.partner_info.partner_logo;
    }

    return (
      <div className="dm-website-navbar-inner">
        {this.renderLogo(sourceUrl, partnerUrl, this.props.clickableLogo)}

        <div className="dm-website-navbar-spacing" />
        <div className="dm-website-navbar-items">
          {this.renderButtons(this.props.includeButtons)}
        </div>
        <div>
          <ToggleButton
            onClick={this.props.dropDownClickHandler}
            visible={this.props.show}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ marketing }) => {
  const { partner_info } = marketing;

  return {
    partner_info
  };
};

export default connect(mapStateToProps, {})(NavBar);
