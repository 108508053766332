import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, Wrapper } from "app/NativeComponents/common";
import { Tabs, NewHeader, InlineTabs } from "app/NativeComponents/snippets";
import Queue from "./Queue";
import MailerIssues from "./MailerIssues";

import { getSendingQueueCounts, popSidePanel } from "app/NativeActions";

class SendingQueue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "sending_queue",
      search_mailers: ""
    };
  }

  componentDidMount() {
    this.props.getSendingQueueCounts({
      token: this.props.token
    });
  }

  render() {
    const { tab } = this.state;
    const { sending_queue_counts } = this.props;
    return (
      <Container>
        <NewHeader
          title="Mail Queue"
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel
          }}
        />
        <InlineTabs
          tab_type="underline"
          selected_tab={tab}
          tabs={[
            {
              onPress: () => {
                this.setState({
                  tab: "sending_queue"
                });
              },
              slug: "sending_queue",
              title: "In Mail Queue",
              notification:
                sending_queue_counts?.sending_queue > 0
                  ? sending_queue_counts?.sending_queue
                  : ""
            },
            {
              onPress: () => {
                this.setState({
                  tab: "mailers_stuck"
                });
              },

              slug: "mailers_stuck",
              title: "Mail Issues",
              notification:
                sending_queue_counts?.mailers_stuck > 0
                  ? sending_queue_counts?.mailers_stuck
                  : ""
            }
          ]}
        />
        {tab == "sending_queue" ? <Queue /> : null}
        {tab == "mailers_stuck" ? <MailerIssues /> : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { sending_queue_counts } = mailer;

  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    sending_queue_counts
  };
};

export default connect(mapStateToProps, {
  getSendingQueueCounts,
  popSidePanel
})(SendingQueue);
