import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Button,
  Stretch,
  InternalImage,
  ExternalImage,
  Icon
} from "app/NativeComponents/common";
import { openUrl } from "app/NativeActions";
class PartnerLogo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  render() {
    if (
      this.props.user_partner_info &&
      this.props.user_partner_info?.id &&
      this.props.user_partner_info?.id != 0 &&
      this.props.user_partner_info?.white_image &&
      this.props.user_partner_info?.white_image != ""
    ) {
      return (
        <Wrapper>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: 0
              }}
            >
              <Icon
                size={18}
                icon={"add"}
                color={this.props.colors.white_text_color}
              />
            </Wrapper>
            <Button
              onPress={() => {
                if (
                  this.props.user_partner_info.id != 0 &&
                  this.props.user_partner_info.popup_button_link &&
                  this.props.user_partner_info.popup_button_link != ""
                ) {
                  this.props.setModal({
                    title: this.props.user_partner_info.popup_title,
                    description: this.props.user_partner_info.popup_description,
                    icon: "",
                    image:
                      this.props.dark_mode == "dark_mode"
                        ? this.props.user_partner_info.white_image
                          ? this.props.user_partner_info.white_image
                          : this.props.user_partner_info.image
                        : this.props.user_partner_info.image,
                    submit: this.props.user_partner_info.popup_button_title,
                    onPress: () => {
                      openUrl(this.props.user_partner_info.popup_button_link);
                    },
                    cancel: this.props.user_partner_info.popup_cancel_button,
                    onCancel: () => {}
                  });
                  this.props.toggleModal({ show: true, type: "normal" });
                }
              }}
              onHover={() => {
                this.setState({
                  hovering: true
                });
              }}
              onHoverOut={() => {
                this.setState({
                  hovering: false
                });
              }}
              style={{
                margin: 0,
                height: 60,
                alignItems: "center",
                justifyContent: "center",
                padding: 15,
                backgroundColor: this.state.hovering
                  ? this.props.colors.hover_color
                  : "transparent",
                paddingTop: 0,
                paddingBottom: 0
              }}
            >
              <ExternalImage
                style={{
                  height: 25,
                  width: "100%",
                  resizeMode: "contain"
                }}
                contain
                image={
                  this.props.dark_mode == "dark_mode"
                    ? this.props.user_partner_info.white_image
                      ? this.props.user_partner_info.white_image
                      : this.props.user_partner_info.white_image
                    : this.props.user_partner_info.white_image
                }
              />
            </Button>
          </Row>
        </Wrapper>
      );
    }

    return null;
  }
}

export default PartnerLogo;
