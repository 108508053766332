import { TRIGGER_LOGOUT } from "app/DealMachineCore/types";

import API from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = API.create();

export const getCallTrackingNumbers = ({
  token,
  type = "get_call_tracking_numbers",
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .getCallTrackingNumbers({
        token,
        type
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const updateCallTrackingNumbers = ({
  token,
  type,
  areacode,
  call_tracking_number_id,
  phone_label,
  callback_type,
  callback_number,
  default_tracking_number,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return async dispatch => {
    onLoading();
    api
      .updateCallTrackingNumbers({
        token,
        type,
        call_tracking_number_id,
        phone_label,
        areacode,
        callback_type,
        callback_number,
        default_tracking_number
      })
      .then(response => {
        if (response.problem != null) {
          onError(response.problem);
        } else if (response.data.error != false) {
          onError(response.data.error);
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};
