import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Spin,
  Title,
  Copy,
  Bold,
  Row,
  Button,
  Icon
} from "app/NativeComponents/common";
import { List, GhostButton } from "app/NativeComponents/snippets";
import ItemType from "../ItemType";
import TopOptions from "./TopOptions";
import SelectedOptions from "./SelectedOptions";

class MobileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_selecting: false
    };

    this.toggleSelecting = this.toggleSelecting.bind(this);
  }

  componentDidMount() {}
  componentDidUpdate(prevProps) {}

  checkIfSelected({ item }) {
    const { selected_items, selected_all } = this.props;
    if (selected_all) {
      return true;
    }

    for (let i = 0; i < selected_items.length; i++) {
      if (selected_items[i].id == item.id) {
        return true;
      }
    }
    return false;
  }

  toggleSelecting(is_selecting) {
    this.setState({
      is_selecting
    });
  }
  render() {
    const {
      colors,
      device,
      items,
      headers,
      selected_items,
      selected_all,
      selected_all_in_account,
      total_count,

      items_loading,
      items_refreshing,
      loaded_all_items,
      loading_text,
      refreshing_text,

      limit,
      begin,

      primary_button,
      primary_button_right_icon,
      onPrimaryButtonPress,
      secondary_button,
      secondary_button_right_icon,
      onSecondaryButtonPress,

      onRefresh,
      onLoadMore,

      no_items_title,
      no_items_description,
      no_items_button_text,
      onNoItemsButtonPress,

      include_checkboxes,
      onCheckBoxPress,
      onCheckBoxHeaderPress,
      onSortBy,
      sort_by_title,
      selected_option_actions,

      renderItem = null,
      hide_edit_button,
      custom_edit_button,
      renderTooltip = null
    } = this.props;
    const { is_selecting } = this.state;
    if ((items_loading && items === 0) || items_refreshing) {
      return (
        <Wrapper style={{ flex: 1, width: "100%" }}>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              alignSelf: "stretch",
              flex: 1
            }}
          >
            <CardBody>
              <Spin text={items_loading ? loading_text : refreshing_text} />
            </CardBody>
          </Wrapper>
        </Wrapper>
      );
    } else if (!items_refreshing && items.length > 0) {
      return (
        <Wrapper style={{ flex: 1 }}>
          <List
            rowNumber={1}
            style={{ flex: 1, alignSelf: "stretch", width: "100%" }}
            items={items}
            listHeader={() => {
              return (
                <TopOptions
                  include_checkboxes={include_checkboxes}
                  is_selecting={is_selecting}
                  selected_all={selected_all}
                  toggleSelecting={this.toggleSelecting}
                  onCheckBoxHeaderPress={onCheckBoxHeaderPress}
                  headers={headers}
                  onSortBy={onSortBy}
                  sort_by_title={sort_by_title}
                />
              );
            }}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <Row key={"item_" + index}>
                  {include_checkboxes && is_selecting ? (
                    <Button
                      onPress={() => onCheckBoxPress({ item })}
                      style={{ padding: 20 }}
                    >
                      <Icon
                        icon={
                          this.checkIfSelected({ item })
                            ? "check-box"
                            : "check-box-outline-blank"
                        }
                      />
                    </Button>
                  ) : null}

                  {renderItem ? (
                    renderItem()
                  ) : (
                    <Wrapper
                      style={{
                        marginTop: 0,
                        flex: 1,
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                        borderBottomColor: colors.border_color
                      }}
                    >
                      <CardBody>
                        {headers &&
                          headers.map(header => {
                            if (header.main_column) {
                              return (
                                <Title style={{ marginBottom: 10 }}>
                                  {item[header.slug]}
                                </Title>
                              );
                            }
                            return null;
                          })}
                        <Row style={{ flexWrap: "wrap" }}>
                          {headers &&
                            headers.map(header => {
                              if (!header.main_column && !header.hide_mobile) {
                                return (
                                  <Wrapper style={{ width: "50%" }}>
                                    <Copy style={{ fontSize: 12 }}>
                                      <Bold>{header.title}</Bold>
                                    </Copy>
                                    <ItemType
                                      style={{ fontSize: 12 }}
                                      item={item}
                                      header={header}
                                    />
                                  </Wrapper>
                                );
                              }
                              return null;
                            })}
                        </Row>
                        <Row>
                          {!!secondary_button ? (
                            <Button
                              style={{
                                borderWidth: 1,
                                borderColor: colors.border_color,
                                borderStyle: "solid",
                                borderRadius: 30,
                                padding: 15,
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 15,
                                marginRight: 5,
                                marginLeft: 0,
                                marginBottom: 0,
                                backgroundColor: colors.card_color
                              }}
                              onPress={() => onSecondaryButtonPress({ item })}
                            >
                              <Row>
                                <Copy>{secondary_button}</Copy>
                                {secondary_button_right_icon ? (
                                  <Icon
                                    size={14}
                                    icon={secondary_button_right_icon}
                                    style={{ marginLeft: 5 }}
                                  />
                                ) : null}
                              </Row>
                            </Button>
                          ) : null}

                          {!!primary_button ? (
                            <Button
                              style={{
                                borderWidth: 1,
                                borderColor: colors.border_color,
                                borderStyle: "solid",
                                borderRadius: 30,
                                padding: 15,
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 15,
                                marginRight: 5,
                                marginLeft: 0,
                                marginBottom: 0,
                                backgroundColor: colors.card_color
                              }}
                              onPress={() => onPrimaryButtonPress({ item })}
                            >
                              <Row>
                                <Copy>{primary_button}</Copy>
                                {primary_button_right_icon ? (
                                  <Icon
                                    size={14}
                                    icon={primary_button_right_icon}
                                    style={{ marginLeft: 5 }}
                                  />
                                ) : null}
                              </Row>
                            </Button>
                          ) : null}
                        </Row>
                      </CardBody>
                    </Wrapper>
                  )}
                </Row>
              );
            }}
            canRefresh={true}
            onRefresh={() => onRefresh()}
            is_refreshing={items_refreshing}
            canLoadMore={
              !loaded_all_items &&
              !items_loading &&
              !items_refreshing &&
              items.length > 0
            }
            isLoadingMore={
              items_loading && !items_refreshing && items.length > 0
            }
            onLoadMore={() => onLoadMore()}
          />
          <SelectedOptions
            include_checkboxes={include_checkboxes}
            is_selecting={is_selecting}
            selected_all={selected_all}
            selected_all_in_account={selected_all_in_account}
            total_count={total_count}
            selected_items={selected_items}
            toggleSelecting={this.toggleSelecting}
            onCheckBoxHeaderPress={onCheckBoxHeaderPress}
            colors={colors}
            device={device}
            selected_option_actions={selected_option_actions}
            hide_edit_button={hide_edit_button}
            custom_edit_button={custom_edit_button}
          />
        </Wrapper>
      );
    }

    if (!items_loading && !items_refreshing) {
      return (
        <Wrapper style={{ flex: 1, width: "100%" }}>
          <CardBody
            style={{
              marginTop: 20,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Title style={{ textAlign: "center" }}>{no_items_title}</Title>
            <Copy style={{ textAlign: "center", marginBottom: 10 }}>
              {no_items_description}
            </Copy>
            {!!no_items_button_text ? (
              <GhostButton
                primary={true}
                style={{}}
                innerStyle={{}}
                onPress={() => onNoItemsButtonPress()}
              >
                {no_items_button_text}
              </GhostButton>
            ) : null}
          </CardBody>
        </Wrapper>
      );
    }
  }
}

export default MobileList;
