import React, { Component } from "react";
import {
  Wrapper,
  Title,
  Input,
  Button,
  Icon,
  Row,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  PillButton,
  GhostButton,
  InputBox,
  AttentionBox,
  IconButton
} from "app/NativeComponents/snippets";

import PillTabButton from "app/DealMachineCore/native/GlobalSnippets/Tabs/PillTabButton";

import { renderPrice } from "app/NativeActions";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_export_template: false,
      show_import_template: false
    };
  }

  renderNavbarItems() {
    const {
      colors,
      template,
      template_id,
      device,
      isMobile,
      updateTemplate,
      show_design_templates = false
    } = this.props;

    if (!isMobile) {
      if (
        this.props.front_layers?.length > 0 ||
        this.props.back_layers?.length > 0
      ) {
        return (
          <Wrapper>
            <Button
              onPress={() => this.props.toggleDesignTemplates(false)}
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: 38,
                paddingRight: 20,
                paddingLeft: 20,
                borderRadius: 30,
                backgroundColor: colors.background_color
              }}
            >
              <Row>
                <Icon size={14} icon="close" style={{ marginRight: 10 }} />
                <Copy style={{ fontSize: 12 }}>
                  <Bold>Cancel Selection</Bold>
                </Copy>
              </Row>
            </Button>
          </Wrapper>
        );
      }
    } else {
      return (
        <Row>
          <Wrapper>
            {this.props.checkIfNeedsToSave() ? (
              <PillButton
                primary={true}
                onPress={this.props.saveMailerTemplate}
              >
                Save
              </PillButton>
            ) : null}
          </Wrapper>
        </Row>
      );
    }
  }

  renderMobileNavbarItems() {
    const {
      colors,
      template,
      template_id,
      device,
      isMobile,
      updateTemplate,
      mobile_tab,
      show_design_templates = false
    } = this.props;
    if (isMobile) {
      if (show_design_templates) {
        return (
          <Wrapper
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10
            }}
          >
            <Row
              style={{
                justifyContent: "center",
                borderRadius: 30,
                backgroundColor: colors.background_color,
                height: 40,
                marginLeft: 20,
                marginRight: 20
              }}
            >
              <PillTabButton
                title={"Cancel Selection"}
                mobile_title={"Cancel Selection"}
                icon={"close"}
                notification={""}
                active={true}
                onPress={() => this.props.toggleDesignTemplates(false)}
                colors={colors}
                isMobile={isMobile}
              />
            </Row>
          </Wrapper>
        );
      }
      return (
        <Wrapper
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10
          }}
        >
          <Row
            style={{
              justifyContent: "center",
              borderRadius: 30,
              backgroundColor: colors.background_color,
              height: 40,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.border_color,
              marginLeft: 20,
              marginRight: 20
            }}
          >
            <PillTabButton
              title={"Layers"}
              mobile_title={"Layers"}
              icon={""}
              notification={""}
              active={mobile_tab == "layers" ? true : false}
              onPress={() => this.props.toggleMobileTab("layers")}
              colors={colors}
              isMobile={isMobile}
            />
            <PillTabButton
              title={"Preview"}
              mobile_title={"Preview"}
              icon={""}
              notification={""}
              active={mobile_tab == "preview" ? true : false}
              onPress={() => this.props.toggleMobileTab("preview")}
              colors={colors}
              isMobile={isMobile}
            />
            <PillTabButton
              title={"Signature"}
              mobile_title={"Signature"}
              icon={""}
              notification={""}
              active={mobile_tab == "signatures" ? true : false}
              onPress={() => this.props.toggleMobileTab("signatures")}
              colors={colors}
              isMobile={isMobile}
            />
          </Row>
        </Wrapper>
      );
    }
  }

  render() {
    const {
      colors,
      template,
      template_id,
      device,
      updateTemplate,
      source_of_truth,
      front_layers,
      back_layers,
      isMobile
    } = this.props;

    return (
      <InputBox
        input_ref={this._list_name_input_template_title}
        autoFocus={false}
        name="template_title"
        disabled={false}
        returnKeyType="done"
        placeholder={
          !!template.template_title ? "Design Name" : "Untitled Design"
        }
        onFocus={() => {}}
        onChange={value => updateTemplate({ prop: "template_title", value })}
        blurOnSubmit={true}
        value={template.template_title}
        input_type="text"
        onSubmit={this.props.saveMailerTemplate}
        onSubmitEditing={this.props.saveMailerTemplate}
        type={"text"}
        style={{
          backgroundColor: this.props.checkIfNeedsToSave()
            ? colors.orange_color_muted
            : null
        }}
        innerStyle={{
          padding: 20,
          margin: 5,
          borderRadius: 5,
          backgroundColor:
            this.props.dark_mode == "dark_mode"
              ? "rgba(0, 0, 0, 0.25)"
              : "rgba(255, 255, 255, 0.5)"
        }}
        renderLeft={
          !this.props.isMobile
            ? () => {
                let template_type_title = "4x6 Postcard";
                let subtext = "";

                let postcard_price = 0;

                switch (template.template_type) {
                  case "postcard":
                  default:
                    template_type_title = "4x6 Postcard";

                    if (
                      !!source_of_truth?.subscription?.metadata[
                        "4x6_postcard_price"
                      ]
                    ) {
                      postcard_price =
                        source_of_truth?.subscription?.metadata[
                          "4x6_postcard_price"
                        ];
                      subtext =
                        renderPrice(postcard_price) +
                        " - 408 x 600 pixels - 300 dpi";
                    } else {
                      subtext = "408 x 600 pixels - 300 dpi";
                    }

                    break;

                  case "postcard6x9":
                    template_type_title = "6x9 Postcard";

                    if (
                      !!source_of_truth?.subscription?.metadata[
                        "6x9_postcard_price"
                      ]
                    ) {
                      postcard_price =
                        source_of_truth?.subscription?.metadata[
                          "6x9_postcard_price"
                        ];
                      subtext =
                        renderPrice(postcard_price) +
                        " - 600 x 888 pixels - 300 dpi";
                    } else {
                      subtext = "600 x 888 pixels - 300 dpi";
                    }

                    break;

                  case "postcard6x11":
                    template_type_title = "6x11 Postcard";

                    if (
                      !!source_of_truth?.subscription?.metadata[
                        "6x11_postcard_price"
                      ]
                    ) {
                      postcard_price =
                        source_of_truth?.subscription?.metadata[
                          "6x11_postcard_price"
                        ];
                      subtext =
                        renderPrice(postcard_price) +
                        " - 600 x 1080 pixels - 300 dpi";
                    } else {
                      subtext = "600 x 1080 pixels - 300 dpi";
                    }

                    break;

                  case "handwritten":
                    template_type_title = "Ballpoint Letter";

                    if (
                      !!source_of_truth?.subscription?.metadata[
                        "ballpoint_letter_price"
                      ]
                    ) {
                      postcard_price =
                        source_of_truth?.subscription?.metadata[
                          "ballpoint_letter_price"
                        ];
                      subtext =
                        renderPrice(postcard_price) +
                        " - Handwritten Letter via Ballpoint";
                    } else {
                      subtext = "Handwritten Letter via Ballpoint";
                    }

                    break;
                }
                return (
                  <AttentionBox
                    style={{
                      maxWidth: 300,
                      padding: 5,
                      margin: 0,
                      marginRight: 15,
                      paddingLeft: 15
                    }}
                    row={true}
                    primary={this.props.checkIfNeedsToSave()}
                    backgroundColor={
                      this.props.checkIfNeedsToSave()
                        ? colors.orange_color_muted
                        : "transparent"
                    }
                    title={template_type_title}
                    description={subtext}
                  />
                );
              }
            : null
        }
        renderRight={() => {
          return (
            <Row>
              <GhostButton
                primary={true}
                disabled={!this.props.checkIfNeedsToSave()}
                loading={this.props.saving}
                onPress={this.props.saveMailerTemplate}
              >
                Save Design
              </GhostButton>
              {(template.id !== "new_template" ||
                (template.template_system_type != "legacy" &&
                  template.template_type != "handwritten")) &&
              !this.props.hide_options ? (
                <PopoverMenu
                  renderComponent={options => {
                    return (
                      <IconButton
                        icon={"more-vert"}
                        noPress={true}
                        pressedIn={options.hovering || options.pressed_in}
                      />
                    );
                  }}
                  menu_items={[
                    template.template_system_type != "legacy" &&
                    template.template_type != "handwritten"
                      ? {
                          title: "Design Templates",
                          icon: "view-quilt",
                          type: "normal",
                          onPress: () => {
                            this.props.setModal({
                              title: "Exit to select a design?",
                              description:
                                "Selecting a design template will erase all your change. Are you sure you want to continue ",
                              buttonType: "destroy",
                              submit: "Select A Design",
                              onPress: () => {
                                this.props.toggleDesignTemplates(true);
                              },
                              cancel: "Cancel selection",
                              onCancel: () => {}
                            });
                            this.props.toggleModal({
                              show: true,
                              type: "normal"
                            });
                          }
                        }
                      : null,
                    source_of_truth?.subscription?.metadata?.mail_designer &&
                    template.template_system_type != "legacy" &&
                    template.template_type != "handwritten" &&
                    device == "desktop"
                      ? {
                          title: "Export Design",
                          icon: "file-download",
                          type: "normal",
                          onPress: () => {
                            this.props.pushSidePanel({
                              slug: "export_design",
                              overlay: true,
                              data: {
                                front_layers,
                                back_layers,
                                template
                              }
                            });
                          }
                        }
                      : null,
                    source_of_truth?.subscription?.metadata?.mail_designer &&
                    template.template_system_type != "legacy" &&
                    template.template_type != "handwritten" &&
                    device == "desktop"
                      ? {
                          title: "Import Design",
                          icon: "file-upload",
                          type: "normal",
                          onPress: () => {
                            this.props.pushSidePanel({
                              slug: "import_design",
                              overlay: true,
                              data: {
                                importCode: this.props.importCode
                              }
                            });
                          }
                        }
                      : null,

                    template.id !== "new_template"
                      ? {
                          title: "Delete Design",
                          icon: "delete",
                          type: "delete",
                          onPress: () => {
                            this.props.setModal({
                              title: "Delete Design",
                              description:
                                "Are you sure you want to delete this mail piece design?",
                              icon: "delete",
                              submit: "Delete Design",
                              buttonType: "destroy",
                              onPress: () => {
                                this.props.updateMailerTemplate({
                                  token: this.props.token,
                                  type: "remove_mailer_template",
                                  mailer_template_id: template_id,
                                  onSuccess: () => {
                                    this.props.onDeleteSuccess(template_id);
                                    this.props.popSidePanel();
                                  }
                                });
                              },
                              cancel: "Cancel",
                              onCancel: () => {}
                            });
                            this.props.toggleModal({
                              show: true,
                              type: "normal"
                            });
                          }
                        }
                      : null
                  ]}
                />
              ) : null}
            </Row>
          );
        }}
      />
    );
  }
}

export default Navbar;
