import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  KeyboardView,
  Spin,
  Title,
  Bold,
  Copy,
  DraggableList,
  Icon,
  Button,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  PillButton,
  SpinWrapper,
  AttentionBox,
  GhostButton,
  SearchBar,
  InlineButton,
  UpsellButton,
  SelectItem,
  ConfirmLabel
} from "app/NativeComponents/snippets";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

import {
  updateCustomFields,
  popSidePanel,
  pushSidePanel,
  getCustomFields,
  setModal,
  toggleModal,
  getLimitsForAllPlan,
  openUrl,
  checkIfUserHasModule,
  updatePlan,
  dismissMobileKeyboard
} from "app/NativeActions";

import CustomFieldGroup from "./CustomFieldGroup";

class CustomFieldsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      custom_fields: props.custom_fields,
      active_field_group: null,
      active_field: null,
      needs_to_save_order: false,
      new_field_group: null,
      new_field: null,
      search: ""
    };

    this.reorderFieldGroupLayers = this.reorderFieldGroupLayers.bind(this);
    this.reorderFields = this.reorderFields.bind(this);
    this.setActiveFieldGroup = this.setActiveFieldGroup.bind(this);

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.custom_fields &&
      prevProps.custom_fields !== this.props.custom_fields
    ) {
      let new_active_field_group = null;
      const custom_field_groups = this.props.custom_fields;
      for (let i = 0; i < custom_field_groups.length; i++) {
        if (custom_field_groups[i].id == this.state.active_field_group?.id) {
          new_active_field_group = custom_field_groups[i];
        }
      }

      this.setState({
        custom_fields: this.props.custom_fields,
        active_field_group: new_active_field_group
          ? new_active_field_group
          : this.state.active_field_group
      });
    }
  }

  componentDidMount() {
    this.props.getCustomFields({
      token: this.props.token,
      type: "get_custom_fields"
    });
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  reorderFieldGroupLayers({ previousIndex, nextIndex }) {
    const { custom_fields } = this.state;

    const reordered_fields = JSON.parse(JSON.stringify(custom_fields));
    const [removed] = reordered_fields.splice(previousIndex, 1);
    reordered_fields.splice(nextIndex, 0, removed);

    this.setState({
      needs_to_save_order: true,
      custom_fields: reordered_fields
    });
  }

  checkIfNeedsToSave() {
    if (
      JSON.stringify(this.state.custom_fields) !==
      JSON.stringify(this.props.custom_fields)
    ) {
      return true;
    }
    return false;
  }

  reorderFields({ custom_field_group_id, previousIndex, nextIndex }) {
    const { custom_fields } = this.state;

    let new_fields = [];
    for (let i = 0; i < custom_fields.length; i++) {
      if (custom_fields[i].id == custom_field_group_id) {
        const reordered_fields = JSON.parse(
          JSON.stringify(custom_fields[i].fields)
        );

        const [removed] = reordered_fields.splice(previousIndex, 1);
        reordered_fields.splice(nextIndex, 0, removed);
        new_fields.push({
          ...custom_fields[i],
          fields: reordered_fields
        });
      } else {
        new_fields.push(custom_fields[i]);
      }
    }

    this.setState({
      needs_to_save_order: true,
      custom_fields: new_fields
    });
  }

  setActiveFieldGroup(active_field_group) {
    const reset_active = this.state.active_field_group == active_field_group;

    this.setState({
      active_field_group: reset_active ? null : active_field_group
    });
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  renderCustomFieldsOnboarding() {
    const { plan_modules, user, colors, source_of_truth } = this.props;

    if (user?.user_version >= 6) {
      if (
        source_of_truth?.subscription?.metadata?.custom_fields_limit &&
        source_of_truth?.subscription?.metadata?.custom_fields_limit <=
          source_of_truth?.current_limits?.number_of_custom_fields?.amount &&
        source_of_truth?.subscription?.metadata?.custom_fields_limit !=
          "unlimited"
      ) {
        return (
          <OnboardingText
            slug={"custom_fields_onboarding_limit_reached"}
            style={{
              padding: 20,
              backgroundColor: colors.gray_color,
              margin: 20
            }}
            center={true}
          />
        );
      }
    } else {
      const limits = getLimitsForAllPlan({ plan_modules });

      if (
        limits.number_of_custom_fields <= user.custom_fields_count &&
        limits.number_of_custom_fields != -1
      ) {
        return (
          <OnboardingText
            slug={"custom_fields_onboarding_limit_reached"}
            style={{
              padding: 20,
              backgroundColor: colors.gray_color,
              margin: 20
            }}
            center={true}
          />
        );
      }
    }
  }

  render() {
    const {
      custom_fields,
      active_field_group,
      active_field,
      needs_to_save_order,
      new_field_group,
      new_field
    } = this.state;
    const { colors, device, plan_modules, user, source_of_truth } = this.props;

    if (this.props.user?.user_version >= 6) {
      if (!source_of_truth?.subscription?.metadata?.custom_fields_limit) {
        return (
          <Container>
            <NewHeader
              title={"Custom Fields"}
              leftButton={{
                icon: "arrow-back",
                onPress: this.handleBack
              }}
            />
            <KeyboardView style={{ flex: 1 }}>
              <AttentionBox
                primary={true}
                title="Upgrade to access custom fields"
                renderDescription={() => {
                  return (
                    <>
                      <Wrapper style={{ paddingBottom: 25 }}>
                        <Copy>
                          Talk to sales to upgrade your account to access custom
                          fields.
                        </Copy>
                      </Wrapper>
                      <GhostButton
                        button_type="full"
                        primary={true}
                        onPress={() => {
                          openUrl("https://dealmachine.com/talk-to-sales");
                        }}
                      >
                        Talk To Sales
                      </GhostButton>
                    </>
                  );
                }}
              />
            </KeyboardView>
          </Container>
        );
      }
    } else {
      const limits = getLimitsForAllPlan({ plan_modules });
      const plan_module_info = checkIfUserHasModule({
        plan_modules: plan_modules,
        user: user,
        slug: "driving"
      });
      if (limits.number_of_custom_fields == 0) {
        return (
          <Container>
            <NewHeader
              title={"Custom Fields"}
              leftButton={{
                icon: "arrow-back",
                onPress: this.handleBack
              }}
            />
            <KeyboardView style={{ flex: 1 }}>
              <AttentionBox
                primary={true}
                title="Upgrade to access custom fields"
                renderDescription={() => {
                  return (
                    <>
                      <Wrapper style={{ paddingBottom: 25 }}>
                        <Copy>
                          You can only use custom fields on a{" "}
                          <Bold>Professional plan</Bold>. This plan includes 3
                          custom fields.
                        </Copy>
                      </Wrapper>
                      <GhostButton
                        button_type="full"
                        primary={true}
                        onPress={() => {}}
                      >
                        Upgrade To Professional
                      </GhostButton>
                    </>
                  );
                }}
              />
            </KeyboardView>
          </Container>
        );
      }
    }

    if (active_field_group) {
      return (
        <CustomFieldGroup
          active_field_group={active_field_group}
          setActiveFieldGroup={this.setActiveFieldGroup}
        />
      );
    }

    return (
      <Container>
        <NewHeader
          title={"Custom Fields"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}

          /*
          rightButtonTitle={needs_to_save_order ? "Save Order" : ""}
          rightButtonPrimary={true}
          rightButtonAction={() => {
            this.saveChanges();
          }}
          */
        />

        <>
          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="check"
              label={"Order Changed"}
              primary={true}
              confirmButton={"Confirm Changes"}
              loading={this.state.loading}
              onPress={() => {
                this.props.updateCustomFields({
                  token: this.props.token,
                  type: "update_custom_fields",
                  custom_fields: this.state.custom_fields,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                  }
                });
              }}
            />
          ) : null}
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <SearchBar
                title={"Search Groups"}
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
                value={this.state.search}
                style={{
                  margin: 10,
                  marginRight: 0,
                  borderWidth: 0,
                  borderBottomWidth: 0
                }}
              />
            </Wrapper>
            {this.props.user?.user_version < 6 ? (
              <InlineButton
                icon="add"
                primary={true}
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "new_custom_field_group",
                    overlay: true
                  });
                }}
              >
                New Group
              </InlineButton>
            ) : (
              <UpsellButton
                meta_slug="custom_fields_limit"
                onPress={() => {
                  this.props.pushSidePanel({
                    slug: "new_custom_field_group",
                    overlay: true
                  });
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      icon="lock"
                      primary={true}
                      style={{ marginRight: 0 }}
                      pressedIn={hovering}
                      noPress={true}
                      tooltipPlacement="top"
                    >
                      New Group
                    </InlineButton>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      icon="add"
                      primary={true}
                      style={{ marginRight: 0 }}
                      pressedIn={hovering}
                      noPress={true}
                      tooltipPlacement="top"
                    >
                      New Group
                    </InlineButton>
                  );
                }}
              />
            )}
          </Row>
        </>

        {this.props.custom_fields_loading ? (
          <SpinWrapper text={"Loading Custom Fields..."} />
        ) : custom_fields?.length == 0 &&
          !new_field_group &&
          !this.props.custom_fields_loading ? (
          <AttentionBox
            title="No custom fields"
            description={
              "You haven't added any custom fields. Add a custom field group to get started and track custom data points for your leads."
            }
          />
        ) : (
          <>
            <KeyboardView style={{ flex: 1 }}>
              {!!active_field_group ||
              device !== "desktop" ||
              !!this.state.search ? (
                <>
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>
                      Custom fields are separated into group. Click on a group
                      to view all the custom fields within that group.
                    </Copy>
                  </Wrapper>
                  {this.filterOptions(custom_fields).map(
                    (custom_field_group, i) => {
                      return (
                        <SelectItem
                          select_type="none"
                          onPress={() => {
                            this.setActiveFieldGroup(custom_field_group);
                          }}
                          icon="keyboard-arrow-right"
                          subtext={
                            "(" + custom_field_group?.fields?.length + ")"
                          }
                        >
                          {custom_field_group.title}
                        </SelectItem>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>
                      Custom fields are separated into group. Click on a group
                      to view all the custom fields within that group.
                    </Copy>
                  </Wrapper>
                  <DraggableList
                    items={this.filterOptions(custom_fields)}
                    renderHandle={() => {
                      return (
                        <Wrapper
                          className="dm-drag"
                          style={{
                            padding: 15,
                            paddingLeft: 0,
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <Icon icon="drag-indicator" size={18} />
                        </Wrapper>
                      );
                    }}
                    renderItem={({ item, wrappedHandle }) => {
                      return (
                        <SelectItem
                          select_type="none"
                          onPress={() => {
                            this.setActiveFieldGroup(item);
                          }}
                          renderRight={
                            wrappedHandle
                              ? () => {
                                  return wrappedHandle();
                                }
                              : null
                          }
                          icon="keyboard-arrow-right"
                          subtext={"(" + item?.fields?.length + ")"}
                        >
                          {item.title}
                        </SelectItem>
                      );
                    }}
                    onReorder={({ previousIndex, nextIndex }) => {
                      this.reorderFieldGroupLayers({
                        previousIndex,
                        nextIndex
                      });
                    }}
                  />
                </>
              )}
            </KeyboardView>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  customsettings,
  billing
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { custom_fields, custom_fields_loading } = customsettings;
  const { plan_modules, source_of_truth } = billing;

  return {
    token,
    user,
    platform,
    colors,
    device,
    isMobile,
    custom_fields,
    custom_fields_loading,
    plan_modules,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  updateCustomFields,
  popSidePanel,
  pushSidePanel,
  getCustomFields,
  setModal,
  toggleModal,

  checkIfUserHasModule,
  updatePlan
})(CustomFieldsModal);
