import React, { Component } from "react";
import {
  Row,
  Wrapper,
  Button,
  DropzoneButton
} from "app/NativeComponents/common";
import { InputBox, CircleButton } from "app/NativeComponents/snippets";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false
    };

    this._email_address_input = React.createRef();
    this._password_input = React.createRef();
  }

  render() {
    const { editUser, loading } = this.props;
    return (
      <>
        <InputBox
          input_ref={this._email_address_input}
          name="email_address"
          disabled={loading}
          returnKeyType="next"
          placeholder="Email Address"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "email", value })
          }
          onSubmitEditing={() => {
            this._password_input.current.focus();
          }}
          blurOnSubmit={false}
          value={editUser.email}
          type="text"
        />
        <InputBox
          input_ref={this._password_input}
          name="password"
          disabled={loading}
          returnKeyType="next"
          placeholder="Confirm Password"
          onChange={value =>
            this.props.updateUserFieldChange({ prop: "password", value })
          }
          onSubmitEditing={() => {
            this.props.saveUserInfo();
          }}
          blurOnSubmit={false}
          value={editUser.password}
          secureTextEntry
          type="password"
        />
      </>
    );
  }
}

export default Inputs;
