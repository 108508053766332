import React, { Component } from "react";

import { Header4 } from "app/NativeComponents/website/common";

const FeatureTitle = ({ feature }) => {
  if (feature.title) {
    return (
      <Header4>
        <b>{feature.title}</b>
      </Header4>
    );
  }

  return <div />;
};

export default FeatureTitle;
