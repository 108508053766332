import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Wrapper,
  Copy,
  Icon,
  Row,
  Bold
} from "app/NativeComponents/common";
import { SelectItem } from "app/NativeComponents/snippets";
import moment from "moment";
import { formatAddress } from "app/NativeActions";

import MailerTimeline from "./MailerTimeline";

class SendEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_mailer: 0
    };
  }

  render() {
    const { user, colors, lead_mailer_campaign, campaign_step } = this.props;
    const { selected_mailer } = this.state;
    const {
      property,
      property: { deal },
      campaign_info,
      mailers
    } = lead_mailer_campaign;

    return (
      <>
        {mailers &&
          mailers.map((mailer, i) => {
            if (
              (!campaign_step ||
                mailer?.campaign_step == campaign_step.step_order ||
                (!mailer?.campaign_step && campaign_step?.step_order == 1)) &&
              ((user.pause_sending != 1 && deal.paused != 1) ||
                moment(mailer.date_to_send) <= moment())
            ) {
              return (
                <Wrapper key={"event_" + i}>
                  <SelectItem
                    select_type={"icon"}
                    select_icon="mail"
                    onPress={() => {
                      this.setState({
                        selected_mailer:
                          mailer.id == selected_mailer ? 0 : mailer.id
                      });
                    }}
                    description={
                      formatAddress({
                        address: {
                          address: mailer.mailed_address,
                          address2: mailer.mailed_address2,
                          address_city: mailer.mailed_address_city,
                          address_state: mailer.mailed_address_state,
                          address_zip: mailer.mailed_address_zip
                        }
                      }).line1 +
                      ", " +
                      formatAddress({
                        address: {
                          address: mailer.mailed_address,
                          address2: mailer.mailed_address2,
                          address_city: mailer.mailed_address_city,
                          address_state: mailer.mailed_address_state,
                          address_zip: mailer.mailed_address_zip
                        }
                      }).line2
                    }
                    icon={
                      selected_mailer == mailer.id
                        ? "keyboard-arrow-up"
                        : "keyboard-arrow-down"
                    }
                  >
                    {moment(mailer.date_created).format("MMMM Do, YYYY")}
                  </SelectItem>
                  {selected_mailer == mailer.id ? (
                    <MailerTimeline
                      colors={colors}
                      mailer={mailer}
                      user={user}
                    />
                  ) : null}
                </Wrapper>
              );
            }
            return null;
          })}
      </>
    );
  }
}

export default SendEvents;
