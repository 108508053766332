import React, { Component } from "react";
import { Wrapper, FlipOverCard, Card } from "app/NativeComponents/common";
import BottomNav from "./BottomNav";
import MailerPreview from "app/DealMachineCore/reuseable/MailerPreview";
class MainBody extends Component {
  render() {
    const {
      template,
      isMobile,
      device,
      front_elements,
      back_elements,
      bracket_variables,
      selected_element,
      side_tab,
      card_width,
      card_height,
      card_width_back,
      card_height_back,
      scale,
      updateLayer,
      grid,
      show_text_safe,
      advanced_editing_mode
    } = this.props;
    let s = scale;
    let width = parseInt(parseInt(card_width) * parseFloat(s));
    let height = parseInt(parseInt(card_height) * parseFloat(s));
    let width_back = parseInt(parseInt(card_width_back) * parseFloat(s));
    let height_back = parseInt(parseInt(card_height_back) * parseFloat(s));

    return (
      <Wrapper
        style={{
          flex: 1,
          alignSelf: "stretch",
          overflow: "hidden"
        }}
      >
        <Wrapper
          style={{
            flex: 1,
            alignSelf: "stretch",
            overflow: "scroll",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Wrapper
            style={{
              width: side_tab == "front" ? width : width_back,
              height: side_tab == "front" ? height : height_back,
              position: "relative",
              margin: "auto",
              padding: device == "desktop" ? 20 : 0,
              paddingBottom: device == "desktop" ? 40 : 0
            }}
          >
            <FlipOverCard
              width={side_tab == "front" ? width : width_back}
              height={side_tab == "front" ? height : height_back}
              flipped={side_tab == "back"}
              frontSide={() => {
                return (
                  <Card>
                    <MailerPreview
                      {...this.props}
                      no_lazy={true}
                      template_type={template.template_type}
                      width={card_width}
                      height={card_height}
                      scale={s}
                      elements={front_elements}
                      bracket_variables={bracket_variables}
                      selected_element={selected_element}
                      updateLayer={updateLayer}
                      side={side_tab}
                      grid={
                        template.template_type !== "handwritten" ? grid : grid
                      }
                      show_text_safe={
                        template.template_type !== "handwritten"
                          ? show_text_safe
                          : false
                      }
                      draggable={
                        scale == 1 && advanced_editing_mode ? true : false
                      }
                    />
                  </Card>
                );
              }}
              backSide={() => {
                return (
                  <Card>
                    <MailerPreview
                      {...this.props}
                      no_lazy={true}
                      template_type={template.template_type}
                      width={card_width_back}
                      height={card_height_back}
                      scale={s}
                      elements={back_elements}
                      bracket_variables={bracket_variables}
                      selected_element={selected_element}
                      updateLayer={updateLayer}
                      side={side_tab}
                      grid={
                        template.template_type !== "handwritten" ? grid : grid
                      }
                      show_text_safe={
                        template.template_type !== "handwritten"
                          ? show_text_safe
                          : false
                      }
                      draggable={
                        scale == 1 && advanced_editing_mode ? true : false
                      }
                    />
                  </Card>
                );
              }}
            />
          </Wrapper>
        </Wrapper>
        <BottomNav {...this.props} />
      </Wrapper>
    );
  }
}

export default MainBody;
