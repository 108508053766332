import React, { Component } from "react";
import { Wrapper, Icon, ProfilePic } from "app/NativeComponents/common";

class NotificationIcon extends Component {
  undoAction() {}

  render() {
    if (this.props.notification) {
      switch (this.props.notification.type) {
        default:
          return (
            <Icon
              color={this.props.colors.active_color}
              icon={"notifications"}
              size={32}
              style={{ marginRight: 10 }}
            />
          );
        case "sms_message":
          return (
            <Icon
              color={this.props.colors.active_color}
              icon={"chat"}
              size={32}
              style={{ marginRight: 10 }}
            />
          );
        case "at_mention":
        case "conversation_at_mention":
          if (
            !!this.props.notification.image ||
            !!this.props.notification.email
          ) {
            return (
              <ProfilePic
                email={this.props.notification.email}
                image={this.props.notification.image}
                size={32}
              />
            );
          }
          return (
            <Icon
              color={this.props.colors.active_color}
              icon={"notifications"}
              size={32}
              style={{ marginRight: 10 }}
            />
          );
      }
    }

    return <Wrapper />;
  }
}

export default NotificationIcon;
