import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Page,
  InternalImage,
  Spinner,
  Card,
  Row
} from "app/NativeComponents/common";
import { getUser, getSourceOfTruth, appRedirect } from "app/NativeActions";
import Login from "app/DealMachineCore/components/Login";

class LoginContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }
    return (
      <Page
        metaTitle={"Loin to your account - DealMachine for Real Estate"}
        responsive_design={true}
        theme_color={"#444BC6"}
      >
        <div id={"login-container"}>
          <div id={"login-container-inner"}>
            <div id={"login-container-card"}>
              <div style={{ alignItems: "center", justifyContent: "center" }}>
                <Row style={{ padding: 20 }}>
                  <a
                    className={"deal-button"}
                    href={"https://dealmachine.com/"}
                  >
                    <InternalImage
                      style={{
                        width: 40,
                        objectFit: "contain",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginRight: 15
                      }}
                      contain
                      image={"/assets/images/Icon-blue.png"}
                    />
                  </a>
                  <h1 className={"dm-title"}>Log in to your account</h1>
                </Row>
              </div>

              <Login />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect
  };
};

export default withRouter(
  connect(mapStateToProps, { getUser, getSourceOfTruth, appRedirect })(
    LoginContainer
  )
);
