import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Copy,
  Bold,
  Icon,
  Button
} from "app/NativeComponents/common";
import NumberCheck from "app/DealMachineCore/reuseable/NumberCheck";
import MailerPreview from "app/DealMachineCore/reuseable/MailerPreview";

import SendEvents from "./SendEvents";
import SentMailers from "./SentMailers";
import { SelectItem } from "app/NativeComponents/snippets";

import moment from "moment";
class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view_step:
        props.lead_mailer_campaign?.property?.deal?.campaign_step == 0
          ? 1
          : props.lead_mailer_campaign?.property?.deal?.campaign_step
    };
  }

  render() {
    const { colors, lead_mailer_campaign, user, isMobile } = this.props;
    if (lead_mailer_campaign) {
      const {
        property,
        property: { deal },
        campaign_info,
        mailers,
        send_events
      } = lead_mailer_campaign;
      const { view_step } = this.state;

      let mailing_addresses = [];
      if (deal.send_to_owner_address == 1) {
        mailing_addresses.push({
          address: deal.owner_address,
          address2: deal.owner_address2,
          city: deal.owner_address_city,
          state: deal.owner_address_state,
          zip: deal.owner_address_zip
        });
      }
      for (let i = 0; i < deal.owner?.mailing_addresses?.length; i++) {
        if (deal.owner.mailing_addresses[i].send_to_address) {
          mailing_addresses.push(deal.owner.mailing_addresses[i]);
        }
      }

      return (
        <Wrapper>
          {campaign_info?.campaign_steps &&
            !deal?.campaign_id.includes("repeating") &&
            campaign_info?.campaign_steps.map((campaign_step, i) => {
              let description = "";

              description =
                campaign_step.step_order >= deal.campaign_step
                  ? campaign_step.total_mailers == 1
                    ? "Sending 1 mail piece"
                    : "Sending " + campaign_step.total_mailers + " mail pieces"
                  : campaign_step.total_mailers == 1
                  ? "Sent 1 mail piece"
                  : "Sent " + campaign_step.total_mailers + " mail pieces";

              description +=
                mailing_addresses.length > 1
                  ? " to " + mailing_addresses.length + " addresses"
                  : "";

              return (
                <Wrapper key={"step_" + i}>
                  <SelectItem
                    onPress={() => {
                      this.setState({
                        view_step:
                          campaign_step.step_order == view_step
                            ? 0
                            : campaign_step.step_order
                      });
                    }}
                    select_type={"custom"}
                    renderCustomSelect={() => {
                      return (
                        <NumberCheck
                          number={campaign_step.step_order}
                          icon={
                            (user.pause_sending == 1 || deal.paused == 1) &&
                            (campaign_step.step_order == deal.campaign_step ||
                              (campaign_step.step_order == 1 &&
                                deal.campaign_step == 0))
                              ? "pause"
                              : deal.campaign_complete == 1 &&
                                (campaign_step.step_order ==
                                  deal.campaign_step ||
                                  (campaign_step.step_order == 1 &&
                                    deal.campaign_step == 0))
                              ? "stop"
                              : null
                          }
                          colors={colors}
                          is_successful={
                            deal.campaign_step > campaign_step.step_order
                              ? true
                              : false
                          }
                          isMobile={false}
                        />
                      );
                    }}
                    label={
                      deal.campaign_step == campaign_step.step_order ||
                      (campaign_step.step_order == 1 && deal.campaign_step == 0)
                        ? "Current Step"
                        : campaign_step.step_label
                    }
                    description={description}
                    subtext={campaign_step.template_type_text}
                    icon={
                      (deal.paused != 1 && deal.campaign_complete != 1) ||
                      campaign_step.step_order < deal.campaign_step
                        ? view_step == campaign_step.step_order
                          ? "keyboard-arrow-up"
                          : "keyboard-arrow-down"
                        : ""
                    }
                  >
                    {campaign_step.template_title}
                  </SelectItem>
                  {view_step == campaign_step.step_order ? (
                    <Wrapper style={{ marginLeft: isMobile ? 20 : 60 }}>
                      <SentMailers
                        user={user}
                        campaign_step={campaign_step}
                        colors={colors}
                        lead_mailer_campaign={lead_mailer_campaign}
                      />
                      <SendEvents
                        user={user}
                        campaign_step={campaign_step}
                        colors={colors}
                        lead_mailer_campaign={lead_mailer_campaign}
                        mailing_addresses={mailing_addresses}
                      />
                    </Wrapper>
                  ) : null}
                </Wrapper>
              );
            })}
        </Wrapper>
      );
    }
    return null;
  }
}

export default Steps;
