import React, { Component } from "react";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import {
  getBasicAutomation,
  updateBasicAutomation,
  getLeadStatuses,
  popSidePanel,
  updateUserSettings,
} from "app/NativeActions";
import {
  Container,
  Wrapper,
  KeyboardView,
  Spin,
  Title,
  Copy,
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  AttentionBox,
  SelectItem,
} from "app/NativeComponents/snippets";

class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification_settings_loading: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !_isEqual(
        this.props.notification_settings,
        prevProps.notification_settings
      )
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const {
      token,
      isMobile,
      device,
      colors,
      basic_automation,
      notification_settings_loading,
      notification_settings,
      lead_statuses,
    } = this.props;
    const { loading } = this.state;
    const { smart_list_email_updates, all_push_notifications } =
      notification_settings;

    return (
      <Container>
        <NewHeader
          title={"Notifications"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.props.popSidePanel,
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {notification_settings_loading ? (
            <SpinWrapper text="Loading..." />
          ) : (
            <>
              <AttentionBox
                title="Notification Settings"
                description={
                  "Manage notifications for your account. See all your options below."
                }
              />
              {this.props.user?.team_owner == 1 ? (
                <SelectItem
                  select_type="toggle"
                  selected={smart_list_email_updates}
                  description={"Turn on or off smart list email updates."}
                  loading={loading == "smart_list"}
                  disabled={loading}
                  onPress={() => {
                    this.setState({ loading: "smart_list" });
                    this.props.updateUserSettings({
                      token,
                      type: "notification_settings",
                      value: {
                        ...this.props.notification_settings,
                        smart_list_email_updates: !smart_list_email_updates,
                      },
                    });
                  }}
                >
                  Smart List Email Updates
                </SelectItem>
              ) : null}
              <SelectItem
                select_type="toggle"
                selected={all_push_notifications}
                description={"Turn on or off all push notifications."}
                loading={loading == "all_notifications"}
                disabled={loading}
                onPress={() => {
                  this.setState({ loading: "all_notifications" });
                  this.props.updateUserSettings({
                    token,
                    type: "notification_settings",
                    value: {
                      ...this.props.notification_settings,
                      all_push_notifications: !all_push_notifications,
                    },
                  });
                }}
              >
                Push Notifications
              </SelectItem>
            </>
          )}
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;
  const { colors, notification_settings } = settings;
  const { isMobile, device } = native;

  return {
    token,
    user,
    isMobile,
    colors,
    device,
    notification_settings,
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateUserSettings,
})(NotificationSettings);
