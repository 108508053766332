import {
  LOGOUT,
  UPDATE_MARKETING_USER,
  UPDATE_MARKETING_PARTNER,
  UPDATE_MARKETING_PARTNER_FROM_PROMO,
  GET_VISITOR,
  GET_VISITOR_ERROR,
  GET_VISITOR_SUCCESS,
  LOGIN_USER_SUCCESS,
  TOGGLE_CHURN_BUSTER
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  user_info: {
    branch_id: "",
    from_campaign: "",
    from_source: "",
    promoText: "",
    special: "",
    email: "",

    signup_tier: 2,
    signup_engine: "",
    signup_frequency: "annually",
    signup_extra_fee: null,
    signup_extra_fee_text: null,
    signup_type: "",
    team_name: "",
    team_company: "",
    team_id: 0,
    branch_link: "",

    interest: "driving",
    deals_last_12_mo_signup: "0"
  },
  promo_info: null,
  partner_info: {
    partner_id: 0,
    partner_title: "",
    partner_logo: "",
    partner_logo_white: "",
    partner_promo: ""
  },
  visitor_loading: false,
  visitor: null,
  signup_funnel: null,
  toggle_churn_buster: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE,
        signup_funnel: state?.signup_funnel,
        visitor: state?.visitor
      };

    case GET_VISITOR:
      return {
        ...state,
        visitor_loading: true
      };
    case GET_VISITOR_ERROR:
      return {
        ...state,
        visitor_loading: false
      };
    case GET_VISITOR_SUCCESS:
      return {
        ...state,
        visitor_loading: false,
        visitor: action.payload.visitor,
        signup_funnel: action.payload.signup_funnel
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        visitor:
          state.visitor && action.payload.visitor?.user_id
            ? {
                ...state.visitor,
                user_id: action.payload.visitor?.user_id
              }
            : state.visitor
      };

    case UPDATE_MARKETING_USER:
      return {
        ...state,
        user_info: {
          ...state.user_info,
          [action.payload.prop]: action.payload.value
        }
      };

    case UPDATE_MARKETING_PARTNER:
      return {
        ...state,
        partner_info: {
          ...state.partner_info,
          [action.payload.prop]: action.payload.value
        }
      };
    case UPDATE_MARKETING_PARTNER_FROM_PROMO:
      return {
        ...state,
        partner_info: {
          ...state.partner_info,
          partner_id: action.payload.partner_id,
          partner_title: action.payload.partner_title,
          partner_logo: action.payload.partner_logo,
          partner_logo_white: action.payload.partner_logo_white
        },
        promo_info: action.payload.promo_info
          ? action.payload.promo_info
          : null,
        user_info: {
          ...state.user_info,
          signup_tier: action.payload.signup_tier || 2,
          signup_engine: action.payload.signup_engine || "driving",
          signup_frequency: action.payload.signup_frequency || "annually"
        }
      };
    case TOGGLE_CHURN_BUSTER:
      return {
        ...state,
        toggle_churn_buster: action.payload
      };

    default:
      return state;
  }
};
