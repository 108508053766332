import React from "react";
import moment from "moment";

import ReactImageFallback from "react-image-fallback";
import LazyLoad from "react-lazy-load";

import { AppConfig } from "app/NativeActions";
import { store } from "app/store";

const getThumbnail = ({ image, date_created, use_full = false }) => {
  if (image && image != "" && use_full == false) {
    if (date_created) {
      if (moment(date_created).format("YYYY-MM-DD") < "2017-04-03") {
        return image;
      }
    }

    if (image.startsWith("https://dealmachine.s3.amazonaws.com")) {
      let thumbnail =
        AppConfig().api_url + "image/?url=" + image + "&type=thumb";
      return thumbnail;
    }
  }

  return image;
};

const ExternalImage = ({
  style,
  image,
  date_created,
  contain,
  use_full = false,
  alt = "",
  no_lazy = false,
  onError = null
}) => {
  const dark_mode = store.getState().settings.dark_mode;

  if (!!image) {
    if (no_lazy) {
      return (
        <ReactImageFallback
          alt={alt}
          className={contain ? "deal-image-contain" : "deal-image"}
          style={{
            opacity: dark_mode == "dark_mode" ? 0.6 : 1,
            ...style
          }}
          src={getThumbnail({ image, date_created, use_full })}
          fallbackImage="/assets/images/default.svg"
          onError={e => {
            if (onError) {
              onError(e);
            }
          }}
        />
      );
    }
    return (
      <LazyLoad
        className={contain ? "deal-image-contain" : "deal-image"}
        height={style?.height}
        width={style?.width}
        debounce={true}
      >
        <ReactImageFallback
          alt={alt}
          className={contain ? "deal-image-contain" : "deal-image"}
          style={{
            opacity: dark_mode == "dark_mode" ? 0.6 : 1,
            ...style,
            width: "100%",
            height: "100%"
          }}
          src={getThumbnail({ image, date_created, use_full })}
          fallbackImage="/assets/images/default.svg"
          onError={e => {
            if (onError) {
              onError(e);
            }
          }}
        />
      </LazyLoad>
    );
  } else if (no_lazy) {
    return (
      <ReactImageFallback
        alt={alt}
        className={contain ? "deal-image-contain" : "deal-image"}
        style={{
          ...style,
          opacity: dark_mode == "dark_mode" ? 0.6 : 1,
          width: "100%",
          height: "100%"
        }}
        src={"/assets/images/default.svg"}
        fallbackImage="/assets/images/default.svg"
        onError={e => {
          if (onError) {
            onError(e);
          }
        }}
      />
    );
  } else {
    return (
      <LazyLoad
        className={contain ? "deal-image-contain" : "deal-image"}
        height={style?.height}
        width={style?.width}
        debounce={true}
      >
        <ReactImageFallback
          alt={alt}
          className={contain ? "deal-image-contain" : "deal-image"}
          style={{
            ...style,
            opacity: dark_mode == "dark_mode" ? 0.6 : 1,
            width: "100%",
            height: "100%"
          }}
          src={"/assets/images/default.svg"}
          fallbackImage="/assets/images/default.svg"
          onError={e => {
            if (onError) {
              onError(e);
            }
          }}
        />
      </LazyLoad>
    );
  }
};

export { ExternalImage };
