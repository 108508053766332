import React, { Component } from "react";
import { Wrapper, Row, Copy } from "app/NativeComponents/common";
import { BottomNavBar } from "app/NativeComponents/snippets";

import SideTabs from "./SideTabs";
import ZoomButtons from "./ZoomButtons";
import GridButtons from "./GridButtons";
class BottomNav extends Component {
  render() {
    const { colors } = this.props;
    return (
      <>
        {
          /*
        this.props.scale != 1 &&
        this.props.selected_element &&
        !this.props.isMobile ? (
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              maxWidth: 500,
              padding: 10,
              marginBottom: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.border_color,
              backgroundColor: colors.error_color_muted
            }}
          >
            <Copy
              style={{
                textAlign: "center",
                fontSize: 12
              }}
            >
              You can only drag/resize layers while viewing the "actual size" of
              the mail piece.
            </Copy>
          </Wrapper>
        ) :
        */
          this.props.device == "mobile" ? (
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                maxWidth: 500,
                padding: 10,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.border_color,
                backgroundColor: colors.error_color_muted,
                borderRaidus: 5
              }}
            >
              <Copy
                style={{
                  textAlign: "center",
                  fontSize: 12
                }}
              >
                Custom Fonts cannot be rendered on the app. Renders on the
                mobile app are also not 100% accurate. To view the most accurate
                version, view this on our web app.
              </Copy>
            </Wrapper>
          ) : null
        }
        <BottomNavBar>
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <Row>
                <SideTabs {...this.props} />
              </Row>
            </Wrapper>
            <Row>
              {this.props.template?.template_type !== "handwritten" ? (
                <GridButtons {...this.props} />
              ) : null}
              <ZoomButtons {...this.props} />
            </Row>
          </Row>
        </BottomNavBar>
      </>
    );
  }
}

export default BottomNav;
