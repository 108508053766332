import React, { Component } from "react";

import { Row, Icon, Wrapper, Copy, Bold } from "app/NativeComponents/common";

class Scene4 extends Component {
  render() {
    if (this.props.scene > 3) {
      return (
        <div className="animated fadeInUp">
          <Row
            style={{
              padding: 10
            }}
          >
            <div className="animated zoomIn">
              <Wrapper
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                  marginLeft: 10,
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  backgroundColor: "#eee"
                }}
              >
                <Icon color={"rgb(99, 89, 92)"} icon={"drive-eta"} size={20} />
              </Wrapper>
            </div>
            <Wrapper
              style={{
                justifyContent: "center",
                alignItems: "flex-start",
                flex: 1
              }}
            >
              <Copy>
                <Bold>32.3 total miles</Bold>
              </Copy>
            </Wrapper>
          </Row>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene4;
