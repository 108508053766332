import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDashboardStats,
  numberWithCommas,
  pushSidePanel
} from "app/NativeActions";

import {
  SpinWrapper,
  LineGraph,
  SmallLabel
} from "app/NativeComponents/snippets";
import { Wrapper, Copy, Row } from "app/NativeComponents/common";
import IncludeOption from "./IncludeOption";
import StatBlock from "./StatBlock";

class CallsMade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calls_made: null,

      total_previous_calls_made: 0,
      previous_total_leads: 0,
      previous_connected_calls: 0,
      previous_no_answer: 0,
      previous_left_voicemail: 0,
      previous_disconnected: 0,
      previous_wrong_contact: 0,
      previous_wrong_number: 0,
      previous_not_interested: 0,
      previous_unqualified: 0,
      previous_warm_leads: 0,
      previous_hot_leads: 0,
      previous_appointment_set: 0,
      previous_contact_rate: 0,

      total_calls_made: 0,
      total_leads: 0,
      connected_calls: 0,
      no_answer: 0,
      left_voicemail: 0,
      disconnected: 0,
      wrong_contact: 0,
      wrong_number: 0,
      not_interested: 0,
      unqualified: 0,
      warm_leads: 0,
      hot_leads: 0,
      appointment_set: 0,
      contact_rate: 0
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    this.props.getDashboardStats({
      token: this.props.token,
      type: "get_calls_made",
      filter_date: this.props.filter_date,
      filter_start_date: this.props.filter_start_date,
      filter_end_date: this.props.filter_end_date,
      filter_user: this.props.filter_user ? this.props.filter_user?.id : null,
      include_call_statuses: this.props.include_call_statuses?.join(","),
      onLoading: () => {
        this.props.updateMainState({ prop: "calls_made_loading", value: true });

        this.setState({
          calls_made: null,
          total_previous_calls_made: 0,
          previous_total_leads: 0,
          previous_connected_calls: 0,
          previous_no_answer: 0,
          previous_left_voicemail: 0,
          previous_disconnected: 0,
          previous_wrong_contact: 0,
          previous_wrong_number: 0,
          previous_not_interested: 0,
          previous_unqualified: 0,
          previous_warm_leads: 0,
          previous_hot_leads: 0,
          previous_appointment_set: 0,
          previous_contact_rate: 0,

          total_calls_made: 0,
          total_leads: 0,
          connected_calls: 0,
          no_answer: 0,
          left_voicemail: 0,
          disconnected: 0,
          wrong_contact: 0,
          wrong_number: 0,
          not_interested: 0,
          unqualified: 0,
          warm_leads: 0,
          hot_leads: 0,
          appointment_set: 0,
          contact_rate: 0
        });
      },
      onError: () => {
        this.props.updateMainState({
          prop: "calls_made_loading",
          value: false
        });
      },
      onSuccess: results => {
        this.props.updateMainState({
          prop: "calls_made_loading",
          value: false
        });

        this.setState({
          calls_made: results?.calls_made ? results.calls_made : null,
          total_previous_calls_made: results?.total_previous_calls_made,
          previous_total_leads: results?.previous_total_leads,
          previous_connected_calls: results?.previous_connected_calls,
          previous_no_answer: results?.previous_no_answer,
          previous_left_voicemail: results?.previous_left_voicemail,
          previous_disconnected: results?.previous_disconnected,
          previous_wrong_contact: results?.previous_wrong_contact,
          previous_wrong_number: results?.previous_wrong_number,
          previous_not_interested: results?.previous_not_interested,
          previous_unqualified: results?.previous_unqualified,
          previous_warm_leads: results?.previous_warm_leads,
          previous_hot_leads: results?.previous_hot_leads,
          previous_appointment_set: results?.previous_appointment_set,
          previous_contact_rate: results?.previous_contact_rate,

          total_calls_made: results?.total_calls_made,
          total_leads: results?.total_leads,
          connected_calls: results?.connected_calls,
          no_answer: results?.no_answer,
          left_voicemail: results?.left_voicemail,
          disconnected: results?.disconnected,
          wrong_contact: results?.wrong_contact,
          wrong_number: results?.wrong_number,
          not_interested: results?.not_interested,
          unqualified: results?.unqualified,
          warm_leads: results?.warm_leads,
          hot_leads: results?.hot_leads,
          appointment_set: results?.appointment_set,
          contact_rate: results?.contact_rate
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.filter_user?.id != prevProps.filter_user?.id ||
      this.props.filter_date != prevProps.filter_date ||
      this.props.filter_start_date != prevProps.filter_start_date ||
      this.props.filter_end_date != prevProps.filter_end_date ||
      JSON.stringify(this.props.include_call_statuses) !==
        JSON.stringify(prevProps.include_call_statuses)
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  handleIncludeCallResultsToggle(slug) {
    //if slug is in include_properties, remove it...if not add it then send it to updateMainState
    let new_include_call_results = this.props.include_call_statuses.includes(
      slug
    )
      ? this.props.include_call_statuses.filter(item => item !== slug)
      : [...this.props.include_call_statuses, slug];

    this.props.updateMainState({
      prop: "include_call_statuses",
      value: new_include_call_results
    });
  }

  render() {
    const { colors, calls_made_loading, filter_date } = this.props;

    const {
      calls_made,
      total_previous_calls_made,
      previous_total_leads,
      previous_connected_calls,
      previous_no_answer,
      previous_left_voicemail,
      previous_wrong_contact,
      previous_wrong_number,
      previous_not_interested,
      previous_unqualified,
      previous_warm_leads,
      previous_hot_leads,
      previous_appointment_set,
      previous_contact_rate,
      total_calls_made,
      total_leads,
      connected_calls,
      no_answer,
      left_voicemail,
      wrong_contact,
      wrong_number,
      not_interested,
      unqualified,
      warm_leads,
      hot_leads,
      appointment_set,
      contact_rate
    } = this.state;

    return (
      <>
        {this.props.device === "desktop" ? (
          <Row>
            <StatBlock
              title={"Total Calls Made"}
              number={total_calls_made}
              prev_number={total_previous_calls_made}
              prev_title={this.props.renderPreviousTitle()}
            />
            <StatBlock
              title={"Total Connections"}
              number={connected_calls}
              prev_number={previous_connected_calls}
              prev_title={this.props.renderPreviousTitle()}
            />

            <StatBlock
              title={"Contact Rate"}
              selected={true}
              number={contact_rate}
              prev_number={previous_contact_rate}
              format={"percent"}
              prev_title={this.props.renderPreviousTitle()}
              tooltip={
                "The contact rate is the number of calls that were answered by a person divided by the total number of prospected properties."
              }
            />
          </Row>
        ) : (
          <>
            <Row>
              <StatBlock
                title={"Total Calls Made"}
                number={total_calls_made}
                prev_number={total_previous_calls_made}
                prev_title={this.props.renderPreviousTitle()}
              />
              <StatBlock
                title={"Total Connections"}
                number={connected_calls}
                prev_number={previous_connected_calls}
                prev_title={this.props.renderPreviousTitle()}
              />
            </Row>
            <Row>
              <StatBlock
                title={"Contact Rate"}
                selected={true}
                number={contact_rate}
                prev_number={previous_contact_rate}
                format={"percent"}
                prev_title={this.props.renderPreviousTitle()}
                tooltip={
                  "The contact rate is the number of calls that were answered by a person divided by the total number of prospected properties."
                }
              />
            </Row>
          </>
        )}
        {this.props.calls_made_loading ? (
          <Wrapper style={{ height: 300, justifyContent: "center" }}>
            <SpinWrapper />
          </Wrapper>
        ) : calls_made &&
          filter_date !== "yesterday" &&
          filter_date !== "today" ? (
          <Wrapper
            style={
              this.props.device !== "desktop"
                ? { paddingTop: 25, paddingBottom: 25 }
                : {}
            }
          >
            <LineGraph
              title="Calls Made"
              data1={calls_made}
              x_title={"Date"}
              y_title={"Calls Made"}
              x_key1={"date"}
              y_key1={"calls_made"}
              x_key2={"prev_date"}
              y_key2={"prev_calls_made"}
            />
          </Wrapper>
        ) : filter_date === "yesterday" ||
          filter_date === "today" ||
          calls_made?.length === 0 ? (
          <>
            <Wrapper
              style={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: colors.border_color,
                borderStyle: "solid",
                margin: 5,
                padding: 25,
                alignItems: "center",
                justifyContent: "center",
                height: 300
              }}
            >
              <Copy style={{ textAlign: "center" }}>
                Not enough data to display graph.
              </Copy>
            </Wrapper>
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  getDashboardStats,
  pushSidePanel
})(CallsMade);
