import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Spinner,
  Wrapper,
  Card,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  GhostButton,
  SelectItem,
  InlineButton
} from "app/NativeComponents/snippets";

import { Header1, Header3 } from "app/NativeComponents/common";

import {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription
} from "app/NativeActions";

class MarketingOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_options: [],
      marketing_offer_loading: false
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/cancel/free-marketing-credit");
    }
  }

  render() {
    const { colors, user } = this.props;
    if (this.props.init && this.props.user) {
    } else if (this.props.loading) {
      return <Spinner />;
    }

    if (this.props.accepted_an_offer) {
      return (
        <>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: 25
            }}
          >
            <Header1
              style={{
                textAlign: "center"
              }}
            >
              <Bold>🎉 Success!</Bold>
            </Header1>
            <Header3
              style={{
                textAlign: "center"
              }}
            >
              We have applied the credits to your account. Now go continue your
              journey!
            </Header3>
          </Wrapper>

          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 0
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              loading={this.state.completing}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.tabNavigation({ slug: "leads" });
              }}
              icon_right={"arrow-right-alt"}
            >
              Take Me To My Account
            </GhostButton>
            <InlineButton
              button_type={"full"}
              onPress={() => {
                this.props.toggleChurnBuster(false);
                this.props.logout();
              }}
            >
              Log me out. I'll come back later.
            </InlineButton>
          </Wrapper>
        </>
      );
    }

    return (
      <>
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25
          }}
        >
          <Header1
            style={{
              textAlign: "center"
            }}
          >
            <Bold>
              Making the investment in yourself is intimidating. Let us help.
            </Bold>
          </Header1>
          <Wrapper style={{ padding: 25, paddingBottom: 0 }}>
            {this.props.source_of_truth?.subscription?.status == "trialing" ? (
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                Here's <Bold>$100 in marketing credit</Bold> to help kick start
                your journey. Start your subscription now to accept the offer.
              </Header3>
            ) : (
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                Here's <Bold>$100 in marketing credit</Bold> to help continue
                your journey. Stick around to accept the offer.
              </Header3>
            )}
          </Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            width: "100%",
            margin: this.props.device == "desktop" ? "5px auto" : 0,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch"
            }}
          >
            <GhostButton
              primary={true}
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              loading={this.state.marketing_offer_loading}
              marketing_slug={"pressed_churn_buster_marketing"}
              icon_right={"arrow-right-alt"}
              onPress={() => {
                this.props.manageSubscription({
                  token: this.props.token,
                  type: "accept_offer",
                  offer_type: "complete_trial_credit",
                  offer_id: "churn_buster_credit_100",
                  no_messaging: true,
                  onLoading: () => {
                    this.setState({
                      marketing_offer_loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      marketing_offer_loading: false
                    });
                    this.props.needsBudget(false);
                  },
                  onSuccess: () => {
                    this.setState(
                      {
                        marketing_offer_loading: false
                      },
                      () => {
                        this.props.acceptedAnOffer();
                      }
                    );
                  }
                });
              }}
            >
              💰 $100? I'll take it!
            </GhostButton>
            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.submitAnswer({
                  property_slug: "how_did_we_fall_short",
                  property_value: this.state.selected_options?.join(";")
                });
                this.props.nextStep();
              }}
            >
              No, I'd rather just cancel.
            </InlineButton>
            <InlineButton
              button_type={"full"}
              buttonContainerStyle={
                this.props.desktopMobile ? { alignSelf: "stretch" } : {}
              }
              onPress={() => {
                this.props.tabNavigation({ slug: "leads" });
                this.props.toggleChurnBuster(false);
              }}
            >
              Nevermind, I don't want to cancel.
            </InlineButton>
            <Wrapper style={{ height: 40 }} />
          </Wrapper>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { isMobile, device, initialRedirect, desktopMobile } = native;
  const { source_of_truth } = billing;
  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    desktopMobile,
    source_of_truth
  };
};

export default connect(mapStateToProps, {
  startRedirect,
  updateUser,
  toggleChurnBuster,
  tabNavigation,
  manageSubscription
})(MarketingOffer);
