import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalOverlay,
  Button,
  Icon,
  Wrapper,
  Copy
} from "app/NativeComponents/common";

import { CloseButton } from "app/NativeComponents/snippets";
import MailerFlipCard from "app/DealMachineCore/reuseable/MailerFlipCard";
import { setMailerView } from "app/NativeActions";

class MailerModalView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      active_mailer_view,
      active_mailer_view_flipped,
      colors,
      isMobile,
      device
    } = this.props;

    let mobile_scale = 0.5;
    switch (active_mailer_view?.template_type) {
      default:
        break;
      case "postcard6x11":
        mobile_scale = 0.35;
        break;

      case "postcard6x9":
        mobile_scale = 0.4;
        break;
    }

    if (active_mailer_view) {
      return (
        <ModalOverlay
          isVisible={true}
          onPress={() => {
            this.props.setMailerView({ mailer: null, flipped: false });
          }}
        >
          <CloseButton
            style={{
              marginTop: this.props.isIphoneX
                ? 40
                : this.props.platform == "ios"
                ? 20
                : 10
            }}
            onPress={() => {
              this.props.setMailerView({ mailer: null, flipped: false });
            }}
          />

          <Wrapper
            style={{
              width: isMobile
                ? active_mailer_view.front_width * mobile_scale
                : active_mailer_view.front_width,
              height: isMobile
                ? active_mailer_view.front_height * mobile_scale
                : active_mailer_view.front_height
            }}
          >
            <MailerFlipCard
              mailer={active_mailer_view}
              flipped={active_mailer_view_flipped}
              scale={isMobile ? mobile_scale : 1}
              is_viewing={true}
            />
          </Wrapper>
          {device == "mobile" ? (
              <Wrapper
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 10,
                    left: 0,
                    right: 0,
                    padding: 15,
                    margin: 15,
                    marginBottom: 40,
                    backgroundColor: "rgba(0,0,0,0.5)"
                  }}
              >
                <Copy
                    style={{
                      textAlign: "center",
                      color: colors.white_text_color,
                      fontSize: 12
                    }}
                >
                  Custom Fonts cannot be rendered on the app. Renders on the
                  mobile app are also not 100% accurate. To view the most accurate
                  version, view this on our web app.
                </Copy>
              </Wrapper>
          ) : null}
        </ModalOverlay>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ mailer, native, settings }) => {
  const { active_mailer_view, active_mailer_view_flipped } = mailer;
  const { isMobile, device, isIphoneX, platform } = native;
  const { colors } = settings;

  return {
    active_mailer_view,
    active_mailer_view_flipped,
    isMobile,
    device,
    isIphoneX,
    platform,
    colors
  };
};

export default connect(mapStateToProps, {
  setMailerView
})(MailerModalView);
