import React, { Component } from "react";
import { connect } from "react-redux";

import {
  appRedirect,
  getWorkflows,
  setActiveWorkflow,
  toggleWorkflowDesigner,
  updateWorkflow,
  setModal,
  toggleModal,
  pushSidePanel
} from "app/NativeActions";
import {
  Wrapper,
  Icon,
  Button,
  Copy,
  Bold,
  Row
} from "app/NativeComponents/common";
import {
  Header,
  ResponsiveList,
  SearchBar,
  PopoverMenu,
  GhostButton
} from "app/NativeComponents/snippets";

class WorkflowList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_by: "date_created",
      selected_workflows: [],
      selected_all_workflows: false,
      sort_by_dir: "desc",
      edit_search: "",
      search: "",
      search_loading: false,
      show_action_items: false
    };

    this.selectAll = this.selectAll.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sort_by, sort_by_dir, search } = this.state;
    if (
      sort_by !== prevState.sort_by ||
      sort_by_dir !== prevState.sort_by_dir ||
      search !== prevState.search
    ) {
      this.getData("refresh");
    }
  }

  getData(load_type = "load") {
    const { workflows_begin, workflows_limit } = this.props;
    const { sort_by, sort_by_dir } = this.state;
    this.props.getWorkflows({
      token: this.props.token,
      load_type: load_type,
      search: this.state.search,
      begin: load_type !== "load_more" ? 0 : workflows_begin,
      limit: workflows_limit,
      sort_by: sort_by + "_" + sort_by_dir,
      onError: () => {
        this.setState({
          search_loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          search_loading: false
        });
      }
    });
  }
  selectAll({ clear = false }) {
    const { selected_all_workflows } = this.state;
    const { workflows } = this.props;
    if (selected_all_workflows || clear) {
      this.setState({
        selected_all_workflows: false,
        selected_workflows: []
      });
    } else {
      this.setState({
        selected_all_workflows: true,
        selected_workflows: []
      });
    }
  }
  selectItem({ item }) {
    let found_item = false;
    const { selected_workflows } = this.state;
    for (let i = 0; i < selected_workflows.length; i++) {
      if (selected_workflows[i].id === item.id) {
        found_item = true;
      }
    }
    if (found_item) {
      this.setState({
        selected_workflows: selected_workflows.filter(
          ({ id }) => id !== item.id
        ),
        selected_all_workflows: false
      });
    } else {
      this.setState({
        selected_workflows: [...selected_workflows, item]
      });
    }
  }

  render() {
    const {
      token,
      isMobile,
      workflows,
      workflows_begin,
      workflows_limit,
      workflows_loading,
      active_workflow,
      colors
    } = this.props;
    const {
      sort_by,
      sort_by_dir,
      selected_workflows,
      selected_all_workflows,
      show_action_items
    } = this.state;

    const is_actionable =
      selected_workflows.length > 0 || selected_all_workflows;
    return (
      <>
        <Row style={{ padding: 20, justifyContent: "space-between" }}>
          <SearchBar
            style={{
              width: isMobile ? "100%" : 300,
              marginBottom: isMobile ? 10 : 0,
              marginRight: isMobile ? 10 : 0
            }}
            value={this.state.edit_search}
            title="Search Workflows"
            onChange={value => {
              this.setState({
                edit_search: value
              });
            }}
            showButton={true}
            disabledButton={
              this.state.search_loading ||
              (this.state.search == this.state.edit_search &&
                !this.state.search_loading)
            }
            buttonText={
              this.state.search !== this.state.edit_search &&
              this.state.edit_search.length == 0
                ? "Clear"
                : "Search"
            }
            buttonLoading={this.state.search_loading}
            onPress={() => {
              this.setState({
                search: this.state.edit_search,
                search_loading: true
              });
            }}
          />

          <Row>
            {is_actionable ? (
              <PopoverMenu
                show={show_action_items}
                no_swipe={true}
                no_cancel={true}
                onShow={s => {
                  this.setState({
                    show_action_items: s
                  });
                }}
                popover_width={350}
                renderComponent={() => {
                  //{hovering}) => {
                  return (
                    <GhostButton
                      style={{ marginRight: 0 }}
                      noPress={true}
                      pressedIn={show_action_items} //|| hovering}
                      disabled={!is_actionable}
                      icon_right="keyboard-arrow-down"
                    >
                      Bulk Actions
                    </GhostButton>
                  );
                }}
                menu_items={[
                  {
                    title: "Turn Workflows On",
                    type: "normal",
                    select_type: "icon",
                    icon: "toggle-on",
                    onPress: () => {
                      this.props.setModal({
                        title: "Turn Workflows On",
                        description:
                          "Are you sure you want turn these workflows on?",
                        submit: "Turn Workflows On",
                        onPress: () => {
                          this.props.updateWorkflow({
                            token: this.props.token,
                            type: "update_workflows_turn_on",
                            workflow_ids: selected_workflows
                              .map(item => {
                                return item.id;
                              })
                              .join(","),
                            selected_all: selected_workflows,
                            onSuccess: () => {
                              this.setState({
                                selected_all_workflows: false,
                                selected_workflows: []
                              });
                              this.getData("refresh");
                            }
                          });
                        },
                        cancel: "Cancel",
                        onCancel: () => {}
                      });
                      this.props.toggleModal({
                        show: true,
                        type: "normal"
                      });
                    }
                  },
                  {
                    title: "Turn Workflows Off",
                    type: "normal",
                    select_type: "icon",
                    icon: "toggle-off",
                    onPress: () => {
                      this.props.setModal({
                        title: "Turn Workflows Off",
                        description:
                          "Are you sure you want turn these workflows off?",
                        submit: "Turn Workflows Off",
                        buttonType: "destroy",
                        onPress: () => {
                          this.props.updateWorkflow({
                            token: this.props.token,
                            type: "update_workflows_turn_off",
                            workflow_ids: selected_workflows
                              .map(item => {
                                return item.id;
                              })
                              .join(","),
                            selected_all: selected_workflows,
                            onSuccess: () => {
                              this.setState({
                                selected_all_workflows: false,
                                selected_workflows: []
                              });
                              this.getData("refresh");
                            }
                          });
                        },
                        cancel: "Cancel",
                        onCancel: () => {}
                      });
                      this.props.toggleModal({
                        show: true,
                        type: "normal"
                      });
                    }
                  },
                  {
                    title: "Delete Workflows",
                    icon: "delete",
                    type: "delete",
                    select_type: "icon",
                    onPress: () => {
                      this.props.setModal({
                        title: "Delete Workflows",
                        description:
                          "Are you sure you want to delete these workflows?",
                        submit: "Delete Workflows",
                        buttonType: "destroy",
                        onPress: () => {
                          this.props.updateWorkflow({
                            token: this.props.token,
                            type: "update_workflows_delete",
                            workflow_ids: selected_workflows
                              .map(item => {
                                return item.id;
                              })
                              .join(","),
                            selected_all: selected_workflows,
                            onSuccess: () => {
                              this.setState({
                                selected_all_workflows: false,
                                selected_workflows: []
                              });
                              this.getData("refresh");
                            }
                          });
                        },
                        cancel: "Cancel",
                        onCancel: () => {}
                      });
                      this.props.toggleModal({
                        show: true,
                        type: "normal"
                      });
                    }
                  }
                ]}
              />
            ) : (
              <GhostButton
                style={{ marginRight: 0 }}
                noPress={true}
                disabled={!is_actionable}
                icon_right="keyboard-arrow-down"
              >
                Bulk Actions
              </GhostButton>
            )}
            <GhostButton
              primary={true}
              icon="add"
              onPress={() => {
                let new_workflow_title = "";
                if (this.props.workflow_count) {
                  const new_count = this.props.workflow_count + 1;
                  new_workflow_title = "Workflow #" + new_count;
                } else {
                  new_workflow_title = "Workflow #1";
                }
                this.props.setActiveWorkflow({
                  token: this.props.token,
                  workflow: {
                    workflow_id: "new_workflow",
                    workflow_title: new_workflow_title,
                    category: "leads",
                    workflow_steps: [
                      {
                        id: "enrollment_triggers_step",
                        type: "enrollment_triggers",
                        data: [],
                        step_index: 0,
                        branch_index: null,
                        branch_step_index: null
                      }
                    ],
                    only_enroll_manually: false,
                    workflow_on: false,
                    enrollment_triggers: {
                      data: [],
                      andor_type: "or",
                      template_type: ""
                    }
                  }
                });
                this.props.pushSidePanel({
                  slug: "workflow_designer",
                  focus_modal: true,
                  locked: true,
                  overlay: true
                });
                this.props.toggleWorkflowDesigner(true);
              }}
            >
              New Workflow
            </GhostButton>
          </Row>
        </Row>
        <ResponsiveList
          items={workflows}
          active_item_id={active_workflow?.id}
          selected_items={selected_workflows}
          selected_all={selected_all_workflows}
          items_loading={workflows_loading}
          headers={[
            {
              slug: "workflow_title",
              title: "Title",
              sortable: true,
              main_column: true,
              item_type: "text"
            },
            {
              slug: "workflow_on",
              title: "On/Off",
              sortable: false,
              item_type: "custom",
              renderComponent: item => {
                return (
                  <Copy>{item.workflow_on ? <Bold>On</Bold> : "Off"}</Copy>
                );
              }
            },
            {
              slug: "total_enrolled",
              title: "Total Enrolled",
              sortable: true,
              item_type: "number"
            },
            {
              slug: "active_enrolled",
              title: "Active Enrolled",
              sortable: true,
              item_type: "number"
            },
            {
              slug: "date_updated",
              title: "Last Updated",
              sortable: true,
              item_type: "date"
            },
            {
              slug: "date_created",
              title: "Date Created",
              sortable: true,
              item_type: "date"
            }
          ]}
          onItemPress={({ item }) => {
            this.props.setActiveWorkflow({
              token: this.props.token,
              workflow: item
            });
            this.props.toggleWorkflowDesigner(true);
          }}
          secondary_button={isMobile ? "View Workflow" : null}
          secondary_button_right_icon={"keyboard-arrow-right"}
          onSecondaryButtonPress={({ item }) => {
            this.props.setActiveWorkflow({
              token: this.props.token,
              workflow: item
            });
            this.props.toggleWorkflowDesigner(true);
          }}
          sort_by={sort_by}
          sort_by_dir={sort_by_dir}
          limit={workflows_limit}
          begin={workflows_begin}
          onSortBy={({ sort_by, sort_by_dir }) => {
            this.setState({
              sort_by,
              sort_by_dir
            });
          }}
          onPageChange={page => {}}
          onLimitChange={limit => {}}
          onRefresh={() => {
            this.getData("refresh");
          }}
          infinite_scroll={true}
          loaded_all_items={this.props.workflows_loaded_all}
          onLoadMore={() => {
            this.getData("load_more");
          }}
          include_checkboxes={true}
          onCheckBoxPress={this.selectItem}
          onCheckBoxHeaderPress={this.selectAll}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, workflow }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const {
    active_workflow,
    workflows,
    workflow_count,
    workflows_begin,
    workflows_limit,
    workflows_loading,
    workflows_refreshing,
    workflows_error,
    workflows_loaded_all
  } = workflow;
  return {
    token,
    user,
    isMobile,
    colors,
    device,
    active_workflow,
    workflows,
    workflow_count,
    workflows_begin,
    workflows_limit,
    workflows_loading,
    workflows_refreshing,
    workflows_error,
    workflows_loaded_all
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  getWorkflows,
  setActiveWorkflow,
  toggleWorkflowDesigner,
  updateWorkflow,
  setModal,
  toggleModal,
  pushSidePanel
})(WorkflowList);
