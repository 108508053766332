import React, { Component } from "react";

import { Header1, Header2 } from "app/NativeComponents/website/common";

class SectionTitle extends Component {
  render() {
    if (this.props.section.title) {
      if (this.props.section.sectionStyle == "HeaderSection") {
        return (
          <Header1>
            <b>{this.props.section.title}</b>
          </Header1>
        );
      }

      return (
        <Header2>
          <b>{this.props.section.title}</b>
        </Header2>
      );
    }

    return <div />;
  }
}

export default SectionTitle;
