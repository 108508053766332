import React, { PureComponent } from "react";

import { Editor } from "@tinymce/tinymce-react";

class HTMLEditor extends PureComponent {
  render() {
    return (
      <Editor
        value={this.props.data}
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey="gpl"
        init={{
          height: 300,
          menubar: false,
          plugins: this.props.plugins
            ? this.props.plugins
            : [
                "advlist",
                "autolink",
                "lists",
                "link",
                "visualblocks",
                "paste",
                "code",
                "help"
              ],
          toolbar: this.props.toolbar
            ? this.props.toolbar
            : "formatselect | bold italic | bullist numlist outdent indent | removeformat",
          ...this.props.additionalInit
        }}
        onEditorChange={newValue => this.props.onChange(newValue)}
      />
    );
  }
}

export { HTMLEditor };
