import React, { Component } from "react";
import { connect } from "react-redux";
import { Copy } from "app/NativeComponents/common";

import { FocusModal, AttentionBox } from "app/NativeComponents/snippets";
import {
  getBilling,
  popSidePanel,
  getAutocomplete,
  getEstimatedListCount,
  getNewFiltersString,
  getReverseGeocode,
  startBuildingList,
  addListToListTabs,
  tabNavigation,
  changeTab,
  updateListBuilderLocation,
  getListsOnMap,
  getLeadsOnMapForListBuilder,
  getHighlightsOnMapForListBuilder,
  setModal,
  toggleModal,
  updateUserSettings,
  askLocationPermissionsThenContinue,
  updatePlan,
  getSourceOfTruth,
  getPropertyFIPS
} from "app/NativeActions";

import ListBuilderTemplates from "./ListBuilderTemplates";
import ListBuilderFilters from "./ListBuilderFilters";
import ListBuilderMap from "./ListBuilderMap";
import ListBuilderComplete from "./ListBuilderComplete";

class ListBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list_name:
        this.props.demo && !!this.props.demo_filter_title
          ? this.props.demo_filter_title
          : "",
      loading: false,
      selected_template: "start_from_stratch",
      confirmed_template: false,
      filters:
        this.props.demo && this.props.demo_filters
          ? this.props.demo_filters
          : {
              data: [],
              andor_type: "or"
            },
      location_type: "zip",
      city: "",
      zip: "",
      state: "",
      fips: "",
      fips_name: "",
      drawing_coordinates: [],
      count_loading: false,
      drawing: false,
      drawing_created: false,
      current_location_loading: false,

      step_slug: "templates",
      naming_list: false,
      templates_loading: false,
      can_filter: false
    };

    this._list_name = React.createRef();

    this.checkIfCanBuild = this.checkIfCanBuild.bind(this);
    this.buildList = this.buildList.bind(this);
    this.updateListName = this.updateListName.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.updateLocationTab = this.updateLocationTab.bind(this);
  }

  componentDidMount() {
    this.props.getSourceOfTruth({
      token: this.props.token,
      no_loading: true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      zip,
      city,
      state,
      fips,
      fips_name,
      location_type,
      drawing_coordinates,
      filters
    } = this.state;

    if (
      this.checkIfCanBuild() &&
      (prevState.location_type !== location_type ||
        prevState.city !== city ||
        prevState.zip !== zip ||
        prevState.fips !== fips ||
        prevState.fips_name !== fips_name ||
        prevState.state !== state ||
        JSON.stringify(prevState.drawing_coordinates) !==
          JSON.stringify(drawing_coordinates) ||
        JSON.stringify(prevState.filters) !== JSON.stringify(filters))
    ) {
      this.getEstimatedCount();
      this.buildListName();
    } else if (JSON.stringify(prevState.filters) !== JSON.stringify(filters)) {
      this.buildListName();
    }
  }

  updateLocationTab({
    location_type,
    city,
    state,
    zip,
    fips,
    fips_name,
    drawing_coordinates = []
  }) {
    this.setState({
      location_type: !!location_type ? location_type : this.state.location_type,
      drawing_coordinates: drawing_coordinates
        ? drawing_coordinates
        : this.state.drawing_coordinates,
      city: city != null ? city : this.state.city,
      state: !!state ? state : this.state.state,
      zip: zip != null ? zip : this.state.zip,
      fips: fips != null ? fips : this.state.fips,
      fips_name: fips_name != null ? fips_name : this.state.fips_name
    });
  }

  updateListName(value) {
    this.setState({
      list_name: value
    });
  }

  buildListName() {
    const { filters, location_type, city, state, zip, fips_name } = this.state;

    let build_list_name = "";
    for (let i = 0; i < filters.data.length; i++) {
      for (let j = 0; j < filters.data[i].data.length; j++) {
        if (build_list_name.length == 0) {
          build_list_name += filters.data[i].data[j].label;
        } else {
          build_list_name += " + " + filters.data[i].data[j].label;
        }
      }
    }

    //const filter_names = getNewFiltersString(filters);
    //build_list_name = filter_names;

    switch (location_type) {
      case "city":
        if (!!city && !!state) {
          build_list_name += " in " + city + ", " + state;
        }
        break;

      case "zip":
        if (!!zip) {
          build_list_name += " in " + zip;
        }
        break;

      case "county":
        if (!!fips_name) {
          build_list_name += " in " + fips_name + ", " + state;
        }
        break;

      default:
        break;
    }

    this.setState({
      list_name: build_list_name
    });
  }

  getEstimatedCount() {
    const {
      count_loading,
      location_type,
      city,
      zip,
      state,
      fips,
      drawing_coordinates,
      filters
    } = this.state;
    this.props.getEstimatedListCount({
      token: this.props.token,
      location_type,
      city,
      zip,
      state,
      fips,
      drawing_coordinates,
      list_filters: filters,
      onLoading: () => {
        this.setState({
          count_loading: true,
          estimated_count: 0
        });
      },
      onError: () => {
        this.setState({
          count_loading: false,
          estimated_count: 0
        });
      },
      onSuccess: results => {
        this.setState({
          count_loading: false,
          estimated_count: results.lead_count ? results.lead_count : 0
        });
      }
    });
  }

  checkIfCanBuild() {
    const { location_type, zip, city, fips, drawing_coordinates, filters } =
      this.state;
    if (
      !!location_type &&
      ((location_type == "zip" && !!zip) ||
        (location_type == "city" && !!city) ||
        (location_type == "county" && !!fips) ||
        (location_type == "draw" && drawing_coordinates.length > 2)) &&
      filters?.data?.length > 0
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  selectTemplate({ slug, filters }) {
    if (slug == "start_from_stratch") {
      this.setState({
        selected_template: !!this.props.selected_template
          ? this.props.selected_template
          : slug,
        confirmed_template: true
      });
    } else {
      this.setState({
        selected_template: slug,
        confirmed_template: true,
        filters: filters
      });
    }
  }

  updateFilters(filters) {
    this.setState({
      filters
    });
  }

  nextStep(slug) {
    this.setState({
      step_slug: slug
    });
  }

  buildList() {
    const {
      list_name,
      filters,
      location_type,
      zip,
      city,
      state,
      fips,
      fips_name,
      drawing_coordinates,
      estimated_count_loading,
      estimated_count
    } = this.state;
    if (!estimated_count_loading && estimated_count > 0) {
      this.props.startBuildingList({
        token: this.props.token,
        title: list_name,
        list_filters: filters,
        location_type,
        zip,
        city,
        state,
        fips,
        fips_name,
        drawing_coordinates,
        onLoading: () => {
          this.setState({
            loading: true
          });
        },
        onError: () => {
          this.setState({
            loading: false
          });
        },
        onSuccess: results => {
          this.setState({
            loading: false
          });

          if (results.list) {
            this.props.addListToListTabs(results.list);
          }
          this.props.tabNavigation({ slug: "leads" });
          this.props.popSidePanel();
        }
      });
    }
  }

  render() {
    const { device, colors, isMobile } = this.props;
    const {
      filters,
      list_name,
      location_type,
      selected_template,
      confirmed_template,
      latitude,
      longitude,

      drawing,
      count_loading,
      naming_list,
      estimated_count
    } = this.state;

    const mobile_pages = [
      {
        slug: "templates",
        title: "List Builder",
        subtitle: "Select motivations",
        renderComponent: () => {
          return (
            <>
              {
                <AttentionBox
                  //primary={true}
                  //icon={"home"}
                  includeCloseButton={false}
                  title={"Identify your target properties"}
                  /*description={
                                    "Think about the motivations of sellers in this market and build your own equations for lead generation."
                                  }*/
                />
              }
              <ListBuilderTemplates
                filters={this.state.filters}
                nextStep={this.nextStep}
                selected_template={this.state.selected_template}
                templates_loading={this.state.templates_loading}
                selectTemplate={this.selectTemplate}
                {...this.props}
              />
            </>
          );
        },
        onBack: () => {
          this.props.popSidePanel();
        }
      },
      {
        slug: "filters",
        title: "List Builder",
        subtitle: "Select motivations",
        renderComponent: () => {
          return (
            <ListBuilderFilters
              buildList={this.buildList}
              selectTemplate={this.selectTemplate}
              checkIfCanBuild={this.checkIfCanBuild}
              updateFilters={this.updateFilters}
              nextStep={this.nextStep}
              filters={this.state.filters}
              count_loading={this.state.count_loading}
              loading={this.state.loading}
              updateListName={this.updateListName}
              estimated_count={this.state.estimated_count}
              estimated_count_loading={this.state.estimated_count_loading}
              list_name={this.state.list_name}
              {...this.props}
            />
          );
        },
        onBack: () => {
          this.nextStep("templates");
        }
      },
      {
        slug: "map",
        title: "List Builder",
        subtitle: "Choose your market",
        renderComponent: () => {
          return (
            <ListBuilderMap
              {...this.props}
              checkIfCanBuild={this.checkIfCanBuild}
              updateLocationTab={this.updateLocationTab}
              nextStep={this.nextStep}
              location_type={this.state.location_type}
              city={this.state.city}
              state={this.state.state}
              zip={this.state.zip}
              fips={this.state.fips}
              fips_name={this.state.fips_name}
              drawing_coordinates={this.state.drawing_coordinates}
              count_loading={this.state.count_loading}
              filters={this.state.filters}
              updateListName={this.updateListName}
              estimated_count={this.state.estimated_count}
              estimated_count_loading={this.state.estimated_count_loading}
              list_name={this.state.list_name}
              buildList={this.buildList}
            />
          );
        },
        onBack: () => {
          this.setState({
            location_type:
              location_type == "draw" &&
              this.state.drawing_coordinates.length == 0
                ? "zip"
                : location_type
          });
          this.nextStep("filters");
        }
      },
      {
        slug: "complete",
        title: "List Builder",
        subtitle: "Name your list",
        renderComponent: () => {
          return (
            <ListBuilderComplete
              {...this.props}
              checkIfCanBuild={this.checkIfCanBuild}
              buildList={this.buildList}
              updateListName={this.updateListName}
              nextStep={this.nextStep}
              count_loading={this.state.count_loading}
              loading={this.state.loading}
              estimated_count={this.state.estimated_count}
              naming_list={this.state.naming_list}
              list_name={this.state.list_name}
              location_type={this.state.location_type}
              city={this.state.city}
              state={this.state.state}
              zip={this.state.zip}
              fips={this.state.fips}
              fips_name={this.state.fips_name}
              filters={this.state.filters}
            />
          );
        },
        onBack: () => {
          this.nextStep("map");
        }
      }
    ];

    if (this.props.demo) {
      return (
        <ListBuilderMap
          {...this.props}
          checkIfCanBuild={this.checkIfCanBuild}
          updateLocationTab={this.updateLocationTab}
          nextStep={this.nextStep}
          location_type={this.state.location_type}
          city={this.state.city}
          state={this.state.state}
          zip={this.state.zip}
          fips={this.state.fips}
          fips_name={this.state.fips_name}
          drawing_coordinates={this.state.drawing_coordinates}
          count_loading={this.state.count_loading}
          filters={this.state.filters}
          demo_latitude={this.props.demo_latitude}
          demo_longitude={this.props.demo_longitude}
          updateListName={this.updateListName}
          estimated_count={this.state.estimated_count}
          estimated_count_loading={this.state.estimated_count_loading}
          list_name={this.state.list_name}
          buildList={this.buildList}
          loading={this.state.loading}
          demo={this.props.demo}
        />
      );
    }

    return (
      <FocusModal
        title="List Builder"
        current_mobile_slug={this.state.step_slug}
        mobile_pages={mobile_pages}
        onClose={() => {
          this.props.popSidePanel();
        }}
        show_side_bar={true}
        renderSideBar={() => {
          return (
            <>
              {this.state.step_slug == "filters" ? (
                <ListBuilderFilters
                  buildList={this.buildList}
                  selectTemplate={this.selectTemplate}
                  updateListName={this.updateListName}
                  checkIfCanBuild={this.checkIfCanBuild}
                  updateFilters={this.updateFilters}
                  nextStep={this.nextStep}
                  filters={this.state.filters}
                  count_loading={this.state.count_loading}
                  loading={this.state.loading}
                  estimated_count={this.state.estimated_count}
                  list_name={this.state.list_name}
                  {...this.props}
                />
              ) : (
                <>
                  {this.state.filters.data.length == 0 ? (
                    <AttentionBox
                      //primary={true}
                      //icon={"home"}
                      includeCloseButton={false}
                      title={"Identify your target properties"}
                      /*description={
                                            "Think about the motivations of sellers in this market and build your own equations for lead generation."
                                          }*/
                    />
                  ) : null}
                  <ListBuilderTemplates
                    filters={this.state.filters}
                    nextStep={this.nextStep}
                    selected_template={this.state.selected_template}
                    templates_loading={this.state.templates_loading}
                    selectTemplate={this.selectTemplate}
                    {...this.props}
                  />
                </>
              )}
            </>
          );
        }}
        renderMainView={() => {
          if (!isMobile || this.state.step_slug == "map") {
            return (
              <ListBuilderMap
                {...this.props}
                checkIfCanBuild={this.checkIfCanBuild}
                updateLocationTab={this.updateLocationTab}
                nextStep={this.nextStep}
                location_type={this.state.location_type}
                city={this.state.city}
                state={this.state.state}
                zip={this.state.zip}
                fips={this.state.fips}
                fips_name={this.state.fips_name}
                drawing_coordinates={this.state.drawing_coordinates}
                count_loading={this.state.count_loading}
                filters={this.state.filters}
                updateListName={this.updateListName}
                estimated_count={this.state.estimated_count}
                estimated_count_loading={this.state.estimated_count_loading}
                list_name={this.state.list_name}
                buildList={this.buildList}
                loading={this.state.loading}
              />
            );
          } else if (isMobile && this.state.step_slug == "complete") {
            return (
              <ListBuilderComplete
                {...this.props}
                checkIfCanBuild={this.checkIfCanBuild}
                buildList={this.buildList}
                updateListName={this.props.updateListName}
                nextStep={this.nextStep}
                count_loading={this.state.count_loading}
                loading={this.state.loading}
                estimated_count={this.state.estimated_count}
                naming_list={this.state.naming_list}
                list_name={this.state.list_name}
                location_type={this.state.location_type}
                city={this.state.city}
                state={this.state.state}
                zip={this.state.zip}
                fips={this.state.fips}
                fips_name={this.state.fips_name}
                filters={this.state.filters}
              />
            );
          }
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native, list, billing }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { platform, device, isMobile, desktopMobile } = native;
  const { list_builder_location, list_builder_limits } = list;
  const { lists_info_banner, source_of_truth } = billing;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    list_builder_location,
    list_builder_limits,
    lists_info_banner,
    source_of_truth,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  getBilling,
  popSidePanel,
  getAutocomplete,
  getEstimatedListCount,
  startBuildingList,
  addListToListTabs,
  tabNavigation,
  changeTab,
  getReverseGeocode,
  updateListBuilderLocation,
  getListsOnMap,
  getLeadsOnMapForListBuilder,
  getHighlightsOnMapForListBuilder,
  setModal,
  toggleModal,
  updateUserSettings,
  updatePlan,
  askLocationPermissionsThenContinue,
  getSourceOfTruth,
  getPropertyFIPS
})(ListBuilder);
