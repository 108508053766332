import {
  LOGOUT,
  UPDATE_FEATURE_TOGGLE,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_SUCCESS,
  UPDATE_USER_SUCCESS
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  mailCenter: false,
  listBuilderMap: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case LOGIN_USER_SUCCESS:
    case REGISTER_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        mailCenter: action.payload?.user?.beta_access?.includes("mailCenter")
          ? true
          : state.mailCenter,

        listBuilderMap: action.payload?.user?.beta_access?.includes(
          "listBuilderMap"
        )
          ? true
          : state.listBuilderMap
      };

    case UPDATE_FEATURE_TOGGLE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
