export const TOGGLE_DRAWER = "toggle_drawer";
export const CHANGE_TAB = "change_tab";

export const GET_STATS = "get_stats";
export const GET_STATS_FAIL = "get_stats_fail";
export const GET_STATS_SUCCESS = "get_stats_success";
export const LOCK_DRAWER = "lock_drawer";
export const TOGGLE_VIDEO = "toggle_video";
export const SET_VIDEO = "set_video";
export const TOGGLE_CAN_POP = "toggle_can_pop";
export const SET_SHARE_OPTIONS = "set_share_options";
