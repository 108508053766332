import React, { PureComponent } from "react";
import { InlineButton, GhostButton } from "app/NativeComponents/snippets";
import { store } from "app/store";

class ConfirmInlineButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirm_delete: props?.confirm_delete ? props.confirm_delete : false,
      double_click_prevent: false
    };
  }
  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (prevProps.confirm_delete !== this.props.confirm_delete) {
      this.setState({ confirm_delete: this.props.confirm_delete });
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval);
    clearInterval(this._double_click_prevent_interval);
  }

  startTimer() {
    const { onConfirmDismiss = () => {} } = this.props;
    clearInterval(this._interval);
    this._interval = setTimeout(() => {
      this.setState(
        { confirm_delete: false, double_click_prevent: false },
        () => {
          onConfirmDismiss();
        }
      );
    }, 5000);
  }

  doubleClickPreventTimer() {
    clearInterval(this._double_click_prevent_interval);
    this._double_click_prevent_interval = setTimeout(() => {
      this.setState({ double_click_prevent: false });
    }, 250);
  }

  render() {
    const {
      confirm_text = "Really Delete?",
      button_type = "",
      confirm_description = "",
      confirm_icon = "",
      onPress = () => {},
      loading = false,
      disabled = false,
      loading_text = "Deleting",
      icon_right = null,
      onConfirmDismiss = () => {},
      onConfirmDisplay = () => {},
      require_confirm = true
    } = this.props;
    const { confirm_delete, loading_state } = this.state;
    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    if (confirm_delete) {
      return (
        <InlineButton
          pressedIn={true}
          primary={true}
          button_type={button_type}
          pressed_in_background_color={colors.orange_color_muted}
          {...this.props}
          onPress={() => {
            if (!this.state.double_click_prevent) {
              clearInterval(this._interval);

              this.setState({ confirm_delete: false }, () => {});

              onPress();
            } else {
            }
          }}
          icon={confirm_icon}
          onHoverOut={() => {
            if (device === "desktop") {
              this.startTimer();
            }
          }}
          onHover={() => {
            clearInterval(this._interval);
          }}
          description={confirm_description}
        >
          {confirm_text}
        </InlineButton>
      );
    }

    return (
      <InlineButton
        {...this.props}
        button_type={button_type}
        icon_right={icon_right}
        loading={loading}
        onPress={() => {
          if (!loading && !disabled) {
            if (!require_confirm) {
              onPress();
            } else {
              this.setState(
                {
                  confirm_delete: true,
                  double_click_prevent: true,
                  loading_state: false
                },
                () => {
                  this.doubleClickPreventTimer();
                  onConfirmDisplay();
                  if (device === "mobile") {
                    this.startTimer();
                  }
                }
              );
            }
          }
        }}
      >
        {loading && !!loading_text ? loading_text : this.props.children}
      </InlineButton>
    );
  }
}

export { ConfirmInlineButton };
