import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { Wrapper } from "./Wrapper";
import crypto from "crypto";
import { AppConfig } from "app/NativeActions";
const AppleLogin = ({
  onSucc,
  style,
  title = "Login with Apple",
  redirectPath = ""
}) => {
  let nonce = "darkMachine123444";

  return (
    <Wrapper
      className={"deal-oauth"}
      style={{
        ...style
      }}
    >
      <AppleSignin
        buttonExtraChildren={title}
        style={{
          width: "100%", // You must specify a width
          height: 52 // You must specify a height
        }}
        /** Auth options passed to AppleID.auth.init() */
        authOptions={{
          clientId: "com.dealmachine",
          redirectURI: AppConfig().app_url + "login",
          scope: "email name",
          state: "state",
          /** sha256 nonce before sending to apple to unify with native firebase behavior - https://github.com/invertase/react-native-apple-authentication/issues/28 */
          nonce: crypto.createHash("sha256").update(nonce).digest("hex"),
          /** We have to usePopup since we need clientSide authentication */
          usePopup: true
        }}
        onSuccess={response => {
          onSucc(response);
          // {
          //     "authorization": {
          //       "state": "[STATE]",
          //       "code": "[CODE]",
          //       "id_token": "[ID_TOKEN]"
          //     },
          //     "user": {
          //       "email": "[EMAIL]",
          //       "name": {
          //         "firstName": "[FIRST_NAME]",
          //         "lastName": "[LAST_NAME]"
          //       }
          //     }
          // }
        }}
        onError={error => console.log("error", error)}
      />
    </Wrapper>
  );
};

export { AppleLogin };
