import React, { Component } from "react";
import { Wrapper, Card, PrimaryButton } from "app/NativeComponents/common";
import { GhostButton } from "app/NativeComponents/snippets";

class Buttons extends Component {
  render() {
    return (
      <GhostButton
        primary={true}
        loading={this.props.loading}
        button_type="full"
        onPress={() => this.props.savePassword()}
      >
        Reset Password
      </GhostButton>
    );
  }
}

export default Buttons;
