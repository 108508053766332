import React, { Component } from "react";
import { connect } from "react-redux";

import OldDashboard from "./OldDashboard";
import NewDashboard from "./NewDashboard";
import { checkIfUserHasMetadata } from "app/NativeActions";
class Dashboard extends Component {
  render() {
    const call_tracking = checkIfUserHasMetadata("call_tracking");

    if (call_tracking) {
      return <NewDashboard />;
    }

    return <OldDashboard />;
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {})(Dashboard);
