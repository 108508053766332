import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Copy,
  Bold,
  Row,
  Icon,
  Button,
} from "app/NativeComponents/common";
import { PopoverMenu, IconButton } from "app/NativeComponents/snippets";

import { renderPrice } from "app/NativeActions";

class TemplateOverlay extends Component {
  renderFlippedText() {
    const { mailer, flipped } = this.props;

    switch (mailer.template_type) {
      case "handwritten":
        return flipped ? "Show Letter" : "Show Envelope";

      default:
        return "Flip";
    }
  }
  render() {
    const {
      title,
      scale,
      mailer,
      colors,
      options = [],
      flipped,
      is_viewing = false,
      include_swap_button,
      include_edit_button,
      onSwap = () => {},
    } = this.props;

    return (
      <Button
        style={{
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: is_viewing ? "transparent" : "rgba(0,0,0,0.1)",
          zIndex: 9999,
        }}
        onPress={() => {
          if (this.props.onMailerPress) {
            this.props.onMailerPress();
          }
          this.props.setMailerView({
            flipped,
            mailer,
          });
        }}
      >
        {!is_viewing ? (
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Wrapper style={{ flex: 1, padding: 10, overflow: "hidden" }}>
              {!!title ? (
                <Copy
                  style={{
                    color: colors.white_text_color,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Bold>{title}</Bold>
                </Copy>
              ) : null}
            </Wrapper>

            {options.length > 0 ? (
              <PopoverMenu
                style={{ padding: 15 }}
                icon={"more-vert"}
                icon_size={18}
                color={colors.white_text_color}
                menu_items={options}
              />
            ) : null}
          </Row>
        ) : null}
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          {include_swap_button ? (
            <IconButton
              style={{
                backgroundColor:
                  !is_viewing && !!title ? "transparent" : "rgba(0,0,0,0.3)",
              }}
              tooltip={"Switch Design"}
              tooltipPlacement={"left"}
              icon={"mail"}
              icon_color={colors.white_text_color}
              onPress={onSwap}
            />
          ) : null}

          {include_edit_button ? (
            <IconButton
              style={{
                backgroundColor:
                  !is_viewing && !!title ? "transparent" : "rgba(0,0,0,0.3)",
              }}
              tooltip={"Edit Design"}
              tooltipPlacement={"left"}
              icon={"edit"}
              icon_color={colors.white_text_color}
              onPress={this.props.onEditButtonPress}
            />
          ) : null}

          <IconButton
            style={{
              backgroundColor:
                !is_viewing && !!title ? "transparent" : "rgba(0,0,0,0.3)",
            }}
            tooltip={this.renderFlippedText()}
            tooltipPlacement={
              include_swap_button || include_edit_button ? "left" : "top"
            }
            icon={"flip"}
            icon_color={colors.white_text_color}
            onPress={this.props.onFlip}
          />
        </Wrapper>
      </Button>
    );
  }
}

export default TemplateOverlay;
