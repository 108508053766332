import React, { Component } from "react";

import {
  Bold,
  Wrapper,
  Copy,
  Title,
  Icon,
  Row
} from "app/NativeComponents/common";
import { store } from "app/store";
import { numberWithCommas } from "app/NativeActions";

class StatBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const colors = store.getState().settings.colors;
    const {
      selected,
      title,
      number,
      prev_number,
      prev_title,
      tooltip,
      format
    } = this.props;

    return (
      <Wrapper
        style={{
          flex: 1,
          padding: 25,
          backgroundColor: selected ? colors.active_color_muted : "transparent",
          borderRadius: 5,
          margin: 5
        }}
      >
        <Row>
          <Copy>{title}</Copy>

          {!!tooltip ? (
            <Wrapper
              tooltipPlacement={"top"}
              tooltip={tooltip}
              style={{ marginLeft: 5 }}
            >
              <Icon icon={"help"} color={colors.light_text_color} size={12} />
            </Wrapper>
          ) : null}
        </Row>

        <Title
          style={{
            color: colors.active_color,
            fontSize: 28,
            marginTop: 10
          }}
        >
          <Bold>
            {!!number
              ? format === "percent"
                ? number + "%"
                : numberWithCommas(number)
              : "--"}
          </Bold>
        </Title>
        {!!prev_title ? (
          <>
            {!!prev_number ? (
              <Copy
                style={{
                  fontSize: 12,
                  color: colors.light_text_color,
                  marginTop: 5
                }}
              >
                {format === "percent"
                  ? prev_number + "%"
                  : numberWithCommas(prev_number)}
                {" " + prev_title}
              </Copy>
            ) : (
              <Copy
                style={{
                  fontSize: 12,
                  color: colors.light_text_color,
                  marginTop: 5
                }}
              >
                {"-- " + prev_title}
              </Copy>
            )}
          </>
        ) : null}
      </Wrapper>
    );
  }
}

export default StatBlock;
