import React, { Component } from "react";
import { Wrapper, Copy, Bold, Button, Icon } from "app/NativeComponents/common";
import { ConfirmInput } from "app/NativeComponents/snippets";
import { store } from "app/store";
import moment from "moment";

class ConfirmInputButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing_item: false,
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.active_property?.property_id !==
      prevProps.active_property?.property_id
    ) {
      this.setState({
        editing_item: false
      });
    }
  }

  renderFormat() {
    const { type, value, items } = this.props;
    switch (type) {
      case "text":
      default:
        return value;

      case "select":
        for (let i = 0; i < items.length; i++) {
          if (items[i].value == value) {
            return items[i].label;
          }
        }
        break;

      case "number":
        return value;
      case "date":
        return moment(value).format("MMM Do, YYYY");
    }

    return "--";
  }

  renderContents() {
    const { title, value } = this.props;
    const colors = store.getState().settings.colors;

    return (
      <Wrapper style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Copy style={{ fontSize: 10, color: colors.light_text_color }}>
          {title}
        </Copy>
        <Copy style={{ fontSize: 14 }}>
          {!!value ? this.renderFormat(value) : "--"}
        </Copy>
      </Wrapper>
    );
  }

  renderHoverIcon() {
    const device = store.getState().native.device;
    if (device == "desktop") {
      const dark_mode = store.getState().settings.dark_mode;
      const colors = store.getState().settings.colors;

      return (
        <Wrapper
          className="deal-preview"
          style={{
            position: "absolute",
            right: 0,
            width: 50,

            height: "100%",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Wrapper
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              margin: 5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                dark_mode == "dark_mode"
                  ? "rgba(0,0,0, 0.25)"
                  : "rgba(255, 255, 255, 0.75)"
            }}
            tooltip={"Edit"}
          >
            <Icon icon={"edit"} size={14} color={colors.active_color} />
          </Wrapper>
        </Wrapper>
      );
    }
  }

  render() {
    const { editing_item, loading } = this.state;
    const {
      style = {},
      activeStyle = {},
      loadingStyle = {},
      items = [],
      type = "text",
      title,
      value,
      onConfirm = () => {}
    } = this.props;
    const colors = store.getState().settings.colors;

    if (editing_item) {
      return (
        <Wrapper
          style={
            loading
              ? { ...style, ...loadingStyle }
              : { ...style, ...activeStyle }
          }
        >
          <ConfirmInput
            containerStyle={{ paddingRight: 0, paddingLeft: 0 }}
            loadingStyle={{ paddingRight: 0 }}
            clearButtonStyle={{ paddingRight: 0 }}
            autoFocus={false}
            placeholder={title}
            forceLabel={true}
            forceLabelPlaceholder={"--"}
            allow_no_value={true}
            confirmEdit={value => {
              onConfirm({
                value,
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onSuccess: results => {
                  this.setState({
                    loading: false,
                    editing_item: false
                  });
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                }
              });
            }}
            loading={loading}
            onClear={() => {
              this.setState({
                editing_item: false
              });
            }}
            value={value}
            items={items}
            input_type={type}
          />
        </Wrapper>
      );
    }
    return (
      <Button
        className="deal-hover-preview custom-field-hover"
        style={{
          position: "relative",
          ...style
        }}
        onPress={() => {
          this.setState({
            editing_item: true
          });
        }}
      >
        {this.renderContents()}

        {this.renderHoverIcon()}
      </Button>
    );
  }
}

export { ConfirmInputButton };
