import React, { useState, useEffect, useMemo } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import "./tag_input_style.css";
import { Wrapper, CardBody } from "app/NativeComponents/common";
import { useDispatch } from "react-redux";

import { PillButton } from "app/NativeComponents/snippets";

const MultiInputFilter = ({
  filter_title,
  filter_value,
  filter_prop,
  filter_search,
  editLeadFilter
}) => {
  const dispatch = useDispatch();
  const [hideFilter, setHideFilter] = useState(false);

  const val = useMemo(() => {
    return filter_value ? filter_value.split(",") : [];
  }, [filter_value]);

  useEffect(() => {
    setHideFilter(
      filter_search &&
        filter_search.length > 0 &&
        !filter_title.toLowerCase().includes(filter_search.toLowerCase())
    );
  }, [filter_search, filter_title]);

  if (hideFilter) {
    return <Wrapper />;
  }

  return (
    <CardBody>
      <ReactTagInput
        placeholder={filter_title}
        tags={val}
        removeOnBackSpace={true}
        onChange={tags => {
          dispatch(
            editLeadFilter({
              prop: filter_prop,
              value: tags ? tags.join(",") : ""
            })
          );
        }}
        validator={value => {
          try {
            parseInt(value);
            return true;
          } catch (e) {
            return false;
          }
        }}
      />
    </CardBody>
  );
};

export { MultiInputFilter };
