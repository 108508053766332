import React, { Component } from "react";

import { Wrapper, Card } from "app/NativeComponents/common";

import {
  List,
  CardLabel,
  DateRangePicker
} from "app/NativeComponents/snippets";

import Invoice from "./Invoice";
import MarketingCreditCharges from "./MarketingCreditCharges";
import moment from "moment";

class MarketingCreditList extends Component {
  render() {
    return (
      <Wrapper style={{ flex: 1 }}>
        <List
          rowNumber={1}
          style={{ flex: 1 }}
          items={this.props.marketing_credits}
          infiniteScroll={true}
          listHeader={() => {
            return <MarketingCreditCharges {...this.props} />;
          }}
          itemStructure={({ item }) => {
            return (
              <Invoice
                key={item.id}
                invoice={item}
                device={this.props.device}
                {...this.props}
              />
            );
          }}
          canRefresh={true}
          onRefresh={() => {
            this.props.getMarketingCredits({
              token: this.props.token,
              type: "refresh_marketing_credits",
              begin: 0,
              utc_offset: moment().utcOffset(),
              timezone: moment.tz.guess()
            });
          }}
          is_refreshing={this.props.marketing_credits_refreshing}
          canLoadMore={
            !this.props.marketing_credits_loaded_all &&
            !this.props.marketing_credits_loading &&
            !this.props.marketing_credits_refreshing &&
            this.props.marketing_credits.length > 0
          }
          isLoadingMore={
            this.props.marketing_credits_loading &&
            !this.props.marketing_credits_refreshing &&
            this.props.marketing_credits.length > 0
          }
          onLoadMore={() => {
            if (
              !this.props.marketing_credits_loaded_all &&
              this.props.marketing_credits_refreshing != true &&
              this.props.marketing_credits_loading != true
            ) {
              if (this.props.start_date === this.props.end_date) {
                this.props.getMarketingCredits({
                  token: this.props.token,
                  type: "load_more_marketing_credits",
                  begin: this.props.begin,
                  utc_offset: moment().utcOffset(),
                  timezone: moment.tz.guess()
                });
              } else {
                this.props.getMarketingCredits({
                  token: this.props.token,
                  type: "load_more_marketing_credits",
                  begin: this.props.begin,
                  start_date: moment(this.props.start_date)
                    .startOf("day")
                    .format(),
                  end_date: moment(this.props.end_date).endOf("day").format(),
                  utc_offset: moment().utcOffset(),
                  timezone: moment.tz.guess()
                });
              }
            }
          }}
        />
      </Wrapper>
    );
  }
}

export default MarketingCreditList;
