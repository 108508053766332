import React, { Component } from "react";

import {
  Feature,
  Stat,
  CardItem,
  Testimonial,
  Form
} from "app/NativeComponents/website/snippets";

class ContentItems extends Component {
  render() {
    if (this.props.section.contentItems) {
      if (this.props.section.contentItems.length > 0) {
        switch (this.props.section.sectionStyle) {
          case "FeaturedIcons":
            return (
              <div
                className={
                  "dm-website-features-icons dm-website-features-" +
                  this.props.section.contentItems.length
                }
              >
                {this.props.section.contentItems.map((feature, i) => {
                  return <Feature key={i} feature={feature.fields} />;
                })}
              </div>
            );
          case "FeaturedList":
            return (
              <div
                className={
                  "dm-website-features-list dm-website-features-" +
                  this.props.section.contentItems.length
                }
              >
                {this.props.section.contentItems.map((feature, i) => {
                  return <Feature key={i} feature={feature.fields} />;
                })}
              </div>
            );

          case "StatsSection":
            return (
              <div
                className={
                  "dm-website-stats dm-website-stats-" +
                  this.props.section.contentItems.length
                }
              >
                {this.props.section.contentItems.map((stat, i) => {
                  return <Stat key={i} stat={stat.fields} />;
                })}
              </div>
            );

          case "CardSection":
            return (
              <div
                className={
                  "dm-website-cards dm-website-cards-" +
                  this.props.section.contentItems.length
                }
              >
                {this.props.section.contentItems.map((card, i) => {
                  return <CardItem key={i} card={card.fields} />;
                })}
              </div>
            );

          case "TestimonialSection":
            return (
              <div className="dm-website-testimonial-wrapper">
                <Testimonial
                  testimonial={this.props.section.contentItems[0].fields}
                />
              </div>
            );

          case "FormSection":
            return (
              <div className="dm-website-form-wrapper">
                <div className="dm-website-card">
                  <div className="dm-website-card-body">
                    <Form form={this.props.section.contentItems[0].fields} />
                  </div>
                </div>
              </div>
            );
          default:
            break;
        }
      }
    }

    return <div />;
  }
}

export default ContentItems;
