import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Card,
  Input,
  Wrapper,
  Copy,
  Spin,
  Row,
  Bold,
  Button
} from "app/NativeComponents/common";

import {
  getMentorAutocomplete,
  clearMentorAutocomplete
} from "app/NativeActions";

class MentorAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.name !== this.state.name) {
      if (this.props.selectName) {
        this.props.selectName(this.state.name);
      }
    }
  }

  renderAutoComplete() {
    if (this.props.mentor_autocomplete_loading) {
      return (
        <Wrapper
          style={{
            borderTopWidth: 1,
            borderTopColor: this.props.colors.border_color,
            borderTopStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: this.props.colors.border_color,
            borderBottomStyle: "solid",
            backgroundColor: this.props.colors.background_color,
            padding: 20,
            paddingTop: 10,
            paddingBottom: 10
          }}
        >
          <Row>
            <Spin size="small" />
            <Copy style={{ marginLeft: 10 }}>Loading Suggestions...</Copy>
          </Row>
        </Wrapper>
      );
    } else if (
      this.props.mentor_autocomplete_items &&
      this.props.mentor_autocomplete_items.length > 0 &&
      this.state.name.length > 3
    ) {
      return (
        <Wrapper
          style={{
            backgroundColor: this.props.colors.background_color,
            borderTopWidth: 1,
            borderTopColor: this.props.colors.border_color,
            borderTopStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: this.props.colors.border_color,
            borderBottomStyle: "solid"
          }}
        >
          <Wrapper style={{ padding: 20, paddingTop: 10, paddingBottom: 10 }}>
            <Copy>
              <Bold>Select An Option:</Bold>
            </Copy>
          </Wrapper>
          {this.props.mentor_autocomplete_items.map((item, i) => {
            return (
              <Button
                key={i}
                style={{ padding: 20, paddingTop: 10, paddingBottom: 10 }}
                onPress={() => {
                  this.setState({
                    name: item
                  });
                  this.props.clearMentorAutocomplete();
                }}
              >
                <Copy>{item}</Copy>
              </Button>
            );
          })}
        </Wrapper>
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this._autocomplete_interval);
    clearInterval(this._blur_interval);
  }

  triggerAutocomplete(value) {
    this.props.clearMentorAutocomplete();
    if (value.length > 3 && !this.props.autocomplete_loading) {
      clearInterval(this._autocomplete_interval);
      this._autocomplete_interval = setTimeout(() => {
        this.props.getMentorAutocomplete({
          token: this.props.token,
          search: value
        });
      }, 250);
    }
  }

  render() {
    return (
      <Card style={{ alignSelf: "stretch" }}>
        <Wrapper>
          <Input
            ref="name"
            name="name"
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            keyboardType="default"
            placeholder="Name of mentor or requested mentor"
            onChange={value => {
              this.triggerAutocomplete(value);
              this.setState({
                name: value
              });
            }}
            onSubmitEditing={() => {}}
            value={this.state.name}
            type="text"
          />
          {this.renderAutoComplete()}
        </Wrapper>
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user, onboarding } = auth;
  const { device, platform, isMobile } = native;

  const {
    colors,
    mentor_autocomplete_loading,
    mentor_autocomplete_items,
    mentor_autocomplete_error
  } = settings;
  return {
    token,
    user,
    colors,
    onboarding,
    device,
    platform,
    isMobile,

    mentor_autocomplete_loading,
    mentor_autocomplete_items,
    mentor_autocomplete_error
  };
};

export default connect(mapStateToProps, {
  getMentorAutocomplete,
  clearMentorAutocomplete
})(MentorAutocomplete);
