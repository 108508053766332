export const BILLING_FIELD_CHANGED = "billing_field_changed";
export const RESET_BILLING = "reset_billing";
export const SAVE_CARD = "save_card";
export const SAVE_CARD_SUCCESS = "save_card_success";
export const SAVE_CARD_FAIL = "save_card_fail";
export const SELECT_PLAN = "select_plan";
export const RESET_SELECTED_PLAN = "reset_selected_plan";

export const UPDATE_BILLING_ADDON = "update_billing_addon";
export const UPDATE_BILLING_ADDON_FAIL = "update_billing_addon_fail";
export const UPDATE_BILLING_ADDON_SUCCESS = "update_billing_addon_success";

export const UPDATE_PAYMENT_METHODS_ORDER_FAIL =
  "update_payment_methods_order_fail";
export const UPDATE_PAYMENT_METHODS_ORDER_SUCCESS =
  "update_payment_methods_order_success";
export const ADD_ADDITIONAL_CARD = "add_additional_card";
export const MARK_EDITING_CARD = "mark_editing_card";
export const DELETE_CARD = "delete_card";
export const DELETE_CARD_SUCCESS = "delete_card_success";
export const DELETE_CARD_FAIL = "delete_card_fail";
export const MAKE_PRIMARY_CARD = "make_primary_card";
export const MAKE_PRIMARY_CARD_SUCCESS = "make_primary_card_success";
export const MAKE_PRIMARY_CARD_FAIL = "make_primary_card_fail";

export const CANCEL_PLAN = "cancel_plan";
export const CANCEL_PLAN_SUCCESS = "cancel_plan_success";
export const CANCEL_PLAN_FAIL = "cancel_plan_fail";

export const DELETE_ACCOUNT = "delete_account";
export const DELETE_ACCOUNT_SUCCESS = "delete_account_success";
export const DELETE_ACCOUNT_FAIL = "delete_account_fail";

export const GET_INVOICES = "get_invoices";
export const LOAD_MORE_INVOICES = "load_more_invoices";
export const REFRESH_INVOICES = "refresh_invoices";
export const GET_INVOICES_FAIL = "get_invoices_fail";
export const GET_INVOICES_SUCCESS = "get_invoices_success";
export const LOAD_INVOICES_SUCCESS = "load_invoices_success";

export const GET_MARKETING_CREDITS = "get_marketing_credits";
export const LOAD_MORE_MARKETING_CREDITS = "load_more_marketing_credits";
export const REFRESH_MARKETING_CREDITS = "refresh_marketing_credits";
export const GET_MARKETING_CREDITS_FAIL = "get_marketing_credits_fail";
export const GET_MARKETING_CREDITS_SUCCESS = "get_marketing_credits_success";
export const LOAD_MARKETING_CREDITS_SUCCESS = "load_marketing_credits_success";

export const INIT_EDIT_CREDIT_PURCHASE = "init_edit_credits";
export const EDIT_CREDIT_PURCHASE = "edit_credit_purchase";
export const EDIT_CREDITS = "edit_credits";
export const EDIT_CREDITS_FAIL = "edit_credits_fail";
export const EDIT_CREDITS_SUCCESS = "edit_credits_success";

export const GET_PAUSE_PLAN_INFO = "get_pause_plan_info";
export const GET_PAUSE_PLAN_INFO_FAIL = "get_pause_plan_info_fail";
export const GET_PAUSE_PLAN_INFO_SUCCESS = "get_pause_plan_info_success";

export const PAUSE_OR_CANCEL_PLAN = "pause_or_cancel_plan";
export const PAUSE_OR_CANCEL_PLAN_FAIL = "pause_or_cancel_plan_fail";
export const PAUSE_OR_CANCEL_PLAN_SUCCESS = "pause_or_cancel_plan_success";

export const GET_DEAL_CREDITS = "get_deal_credits";
export const GET_BILLING_FAIL = "get_billing_fail";
export const GET_DEAL_CREDITS_SUCCESS = "get_deal_credits_success";
export const TRIGGER_DEAL_CREDIT_RELOAD = "trigger_deal_credit_reload";
export const GET_BILLING_USER_CARDS = "get_billing_user_cards";
export const GET_BILLING_USER_CARDS_SUCCESS = "get_billing_user_cards_success";

export const SET_FEATURE_MODAL = "set_feature_modal";
export const GET_PLAN_MODUALS_SUCCESS = "get_plan_moduals_success";
export const GET_PLAN_MODUALS = "get_plan_modules";

export const GET_LEAD_LIMITS_SUCCESS = "get_lead_limits_success";
export const GET_LEAD_LIMITS = "get_lead_limits";
export const GET_CONVERSATION_LIMITS = "get_conversation_limits";
export const GET_CONVERSATION_LIMITS_SUCCESS =
  "get_conversation_limits_success";

export const GET_LIST_LEAD_LIMITS = "get_list_lead_limits";
export const GET_LIST_LEAD_LIMITS_SUCCESS = "get_list_lead_limits_success";

export const SET_BILLING_DETAILS = "set_billing_details";

export const GET_ESTIMATED_PAYMENT = "get_estimated_payment";
export const GET_ESTIMATED_PAYMENT_SUCCESS = "get_estimated_payment_success";

export const GET_DRIVING_INFO_BANNER_SUCCESS =
  "get_driving_info_banner_success";
export const GET_LISTS_INFO_BANNER_SUCCESS = "get_lists_info_banner_success";
export const GET_MESSAGES_INFO_BANNER_SUCCESS =
  "get_messages_info_banner_success";
export const GET_CRM_INFO_BANNER_SUCCESS = "get_crm_info_banner_success";

export const SET_PAYMENT_PAGE = "set_payment_page";
export const SET_PRICING_PAGE = "set_pricing_page";
export const SET_DEMO_PAGE = "set_demo_page";
export const TOGGLE_DEMO_PAGE = "toggle_demo_page";
export const TOGGLE_PAYMENT_PAGE = "toggle_payment_page";
export const TOGGLE_PRICING_PAGE = "toggle_pricing_page";
export const TOGGLE_CREDIT_RELOAD = "toggle_credit_reload";

export const SET_CANCEL_PAGE = "set_cancel_page";
export const TOGGLE_CANCEL_PAGE = "toggle_cancel_page";
export const CHECK_CANADA_STATE = "check_canada_state";
export const SET_FREE_TRIAL_INFO = "set_free_trial_info";

export const GET_SOURCE_OF_TRUTH = "get_source_of_truth";
export const GET_SOURCE_OF_TRUTH_FAIL = "get_source_of_truth_fail";
export const GET_SOURCE_OF_TRUTH_SUCCESS = "get_source_of_truth_success";
