import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Wrapper,
  Copy,
  KeyboardView,
  Row,
  Icon
} from "app/NativeComponents/common";
import {
  NewHeader,
  SpinWrapper,
  SelectItem,
  List,
  BottomNavBar,
  InputBox,
  ConfirmInlineButton,
  SmallLabel
} from "app/NativeComponents/snippets";

import {
  getHelperDescription,
  popSidePanel,
  updateSavedFilters
} from "app/NativeActions";
import ShowcaseFilters from "app/DealMachineCore/reuseable/AdvancedFilters/ShowcaseFilters";

class SavedSearch extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      loading: false,
      saved_filter: panel_data?.saved_filter ? panel_data.saved_filter : null
    };

    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  checkIfNeedsToSave() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (
      this.state.saved_filter?.default_filter !=
      panel_data.saved_filter?.default_filter
    ) {
      return true;
    }
  }

  checkIfThereAreFilters() {
    const {
      property_types,
      property_flags,
      value_range_max,
      value_range_min,
      beds_min,
      baths_min
    } = this.state.saved_filter;
    if (
      property_flags.length > 0 ||
      property_types.length > 0 ||
      !!value_range_min ||
      !!value_range_max ||
      !!beds_min ||
      !!baths_min
    ) {
      return true;
    }

    return false;
  }
  renderPriceRange(price) {
    let label = price;
    let num = parseInt(price);
    if (num >= 1000000) {
      label = (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1000) {
      label = (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return label;
  }

  renderRangeLabel() {
    const { value_range_min, value_range_max } = this.state.saved_filter;

    return !!value_range_min && !value_range_max
      ? "$" + this.renderPriceRange(value_range_min) + "+"
      : !!value_range_max && !value_range_min
      ? "Up to $" + this.renderPriceRange(value_range_max)
      : !!value_range_max && !!value_range_min
      ? "$" +
        this.renderPriceRange(value_range_min) +
        " - " +
        "$" +
        this.renderPriceRange(value_range_max)
      : "";
  }

  renderBedAndBathLabel() {
    const { beds_min, baths_min, use_beds_exact } = this.state.saved_filter;

    return !!beds_min && !baths_min
      ? use_beds_exact
        ? beds_min + " bd, 0+ ba"
        : beds_min + "+ bd, 0+ ba"
      : !!baths_min && !beds_min
      ? " 0+ bd, " + baths_min + "+ ba"
      : !!baths_min && !!beds_min
      ? use_beds_exact
        ? beds_min + " bd, " + baths_min + "+ ba"
        : beds_min + "+ bd, " + baths_min + "+ ba"
      : "";
  }

  saveFilter() {
    const { saved_filter } = this.state;

    this.props.updateSavedFilters({
      token: this.props.token,
      title: saved_filter?.title,
      filters: saved_filter?.filters,
      advanced_filter_id: saved_filter?.id,
      default_filter: saved_filter?.default_filter,
      filter_type: "highlights",
      type: "update_saved_filter",
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          loading: false
        });

        this.props.popSidePanel();
      }
    });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { colors, isMobile, saved_highlights } = this.props;
    const { saved_filter, loading } = this.state;

    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    return (
      <Container style={{ backgroundColor: colors.card_color }}>
        <NewHeader
          title={"Edit Saved Search"}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <Wrapper
            style={{
              borderRadius: 5,
              margin: 5,
              backgroundColor:
                (!!saved_filter?.title &&
                  saved_filter?.title !== panel_data?.saved_filter?.title) ||
                this.checkIfNeedsToSave()
                  ? colors.orange_color_muted
                  : "transparent"
            }}
          >
            <InputBox
              input_ref={this._filter_name}
              autoFocus={false}
              name="filter_name"
              disabled={false}
              returnKeyType="done"
              style={{
                margin: 5,
                borderRadius: 5
              }}
              placeholder={
                !!saved_filter?.title
                  ? "Search name"
                  : "Enter a name for this search"
              }
              onFocus={() => {}}
              onChange={value => {
                this.setState({
                  saved_filter: {
                    ...saved_filter,
                    title: value
                  }
                });
              }}
              blurOnSubmit={true}
              value={saved_filter.title}
              input_type="text"
              require_confirm={true}
              has_changed={
                (!!saved_filter.title &&
                  saved_filter.title !== panel_data?.saved_filter?.title) ||
                this.checkIfNeedsToSave()
              }
              submit_button_primary={true}
              submit_button_title={"Save"}
              onSubmit={() => {
                this.saveFilter();
              }}
              onSubmitEditing={() => {
                this.saveFilter();
              }}
              loading={loading}
            />
            <SelectItem
              selected={this.state.saved_filter?.default_filter}
              onPress={() => {
                this.setState({
                  saved_filter: {
                    ...saved_filter,
                    default_filter: !this.state.saved_filter?.default_filter
                  }
                });
              }}
              select_type="toggle"
              description={
                "Set this search as the default display for your drivers"
              }
            >
              Set for Drivers
            </SelectItem>
          </Wrapper>
          <Wrapper style={{ padding: 25 }}>
            {this.checkIfThereAreFilters() ? (
              <>
                {saved_filter?.property_flags_full &&
                saved_filter?.property_flags_full.length > 0 ? (
                  <>
                    <SmallLabel
                      style={{
                        marginTop: 25,
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      {saved_filter?.property_flags_and_or == "and"
                        ? "Quick filters (matching all): "
                        : "Quick filters: "}
                    </SmallLabel>

                    <Row
                      style={{
                        flexWrap: "wrap"
                      }}
                    >
                      {saved_filter?.property_flags_full &&
                        saved_filter?.property_flags_full.length > 0 &&
                        saved_filter?.property_flags_full.map((flag, i) => {
                          return (
                            <Wrapper
                              key={"flag" + i}
                              style={{
                                backgroundColor: colors.active_color_muted,
                                borderRadius: 5,
                                padding: 5,
                                paddingRight: 15,
                                paddingLeft: 15,
                                marginRight: 5,
                                marginBottom: 5
                              }}
                            >
                              <Row>
                                <Copy style={{ fontSize: 12 }}>
                                  {flag?.label}
                                </Copy>

                                {this.props.device === "desktop" ? (
                                  <Wrapper
                                    tooltipPlacement={"top"}
                                    tooltip={getHelperDescription(flag?.value)}
                                    style={{ marginLeft: 5 }}
                                  >
                                    <Icon
                                      icon={"help"}
                                      color={colors.light_text_color}
                                      size={12}
                                    />
                                  </Wrapper>
                                ) : null}
                              </Row>
                            </Wrapper>
                          );
                        })}
                    </Row>
                  </>
                ) : null}

                {saved_filter?.property_types &&
                saved_filter?.property_types.length > 0 ? (
                  <>
                    <SmallLabel
                      style={{
                        marginTop: 25,
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      Property types:
                    </SmallLabel>
                    <Row
                      style={{
                        flexWrap: "wrap"
                      }}
                    >
                      {saved_filter?.property_types &&
                        saved_filter?.property_types.length > 0 &&
                        saved_filter?.property_types.map((p_type, i) => {
                          return (
                            <Wrapper
                              key={"p_type" + i}
                              style={{
                                backgroundColor: colors.active_color_muted,
                                borderRadius: 5,
                                padding: 5,
                                paddingRight: 15,
                                paddingLeft: 15,
                                marginRight: 5,
                                marginBottom: 5
                              }}
                            >
                              <Copy style={{ fontSize: 12 }}>
                                {p_type?.label}
                              </Copy>
                            </Wrapper>
                          );
                        })}
                    </Row>
                  </>
                ) : null}
                {!!saved_filter?.value_range_min ||
                !!saved_filter?.value_range_max ? (
                  <>
                    <SmallLabel
                      style={{
                        marginTop: 25,
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      Price range:
                    </SmallLabel>
                    <Wrapper>
                      <Copy>{this.renderRangeLabel()}</Copy>
                    </Wrapper>
                  </>
                ) : null}

                {!!saved_filter?.beds_min || saved_filter?.baths_min ? (
                  <>
                    <SmallLabel
                      style={{
                        marginTop: 25,
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      Beds & Baths:
                    </SmallLabel>
                    <Wrapper>
                      <Copy>{this.renderBedAndBathLabel()}</Copy>
                    </Wrapper>
                  </>
                ) : null}
              </>
            ) : null}

            {saved_filter.filters?.data &&
            saved_filter.filters?.data?.length > 0 ? (
              <>
                <SmallLabel
                  style={{
                    marginTop: 25,

                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                >
                  More filters:
                </SmallLabel>
                <ShowcaseFilters
                  filters={saved_filter.filters?.data}
                  andor_type={saved_filter.filters?.andor_type}
                  editable={false}
                  text_only={true}
                />
              </>
            ) : null}
          </Wrapper>
        </KeyboardView>
        <BottomNavBar>
          <ConfirmInlineButton
            confirm_text="Really Delete?"
            confirm_icon="delete"
            loading_text="Deleting"
            button_type="full"
            loading={this.state.deleting}
            onPress={() => {
              this.props.updateSavedFilters({
                token: this.props.token,
                type: "remove_saved_filter",
                advanced_filter_id: saved_filter?.id,
                filter_type: "highlights",
                onLoading: () => {
                  this.setState({
                    deleting: true
                  });
                },
                onError: () => {
                  this.setState({
                    deleting: false
                  });
                },
                onSuccess: () => {
                  this.setState({
                    deleting: false
                  });
                  this.props.popSidePanel();
                }
              });
            }}
          >
            Delete Search
          </ConfirmInlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, property_map }) => {
  const { token, user } = auth;
  const { device, window_height, isMobile } = native;
  const { colors, dark_mode } = settings;
  const { highlight_templates, saved_highlights } = property_map;

  return {
    token,
    user,
    device,
    isMobile,
    window_height,
    colors,
    dark_mode,
    highlight_templates,
    saved_highlights
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateSavedFilters
})(SavedSearch);
