import React from "react";
import { Wrapper, Spin } from "app/NativeComponents/common";

import { store } from "app/store";

const SpinWrapper = ({ style, text, color }) => {
  const colors = store.getState().settings.colors;

  return (
    <Wrapper
      style={{
        padding: 25,
        alignItems: "center",
        justifyContent: "center",
        ...style
      }}
    >
      <Spin text={text} color={color || colors.text_color} size="small" />
    </Wrapper>
  );
};

export { SpinWrapper };
