import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";
class Line extends Component {
  render() {
    const { colors } = this.props;
    return (
      <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
        <Wrapper
          style={{
            backgroundColor: colors.light_text_color,
            width: 1,
            margin: "auto",
            height: 25
          }}
        />
      </Wrapper>
    );
  }
}

export default Line;
