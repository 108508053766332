import React from "react";
import { store } from "app/store";

const SecondaryButton = ({ children, submit, onPress, style }) => {
  const colors = store.getState().settings.colors;
  const dark_mode = store.getState().settings.dark_mode;

  return (
    <button
      className={"deal-secondary-button"}
      style={{
        ...style,
        backgroundColor:
          dark_mode == "dark_mode"
            ? colors.background_color
            : colors.background_color,
        color: colors.text_color
      }}
      type={submit ? "submit" : "button"}
      onClick={submit ? () => {} : onPress}
    >
      {children}
    </button>
  );
};

export { SecondaryButton };
