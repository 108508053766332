import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  CardBody,
  Card,
  Copy,
  KeyboardView
} from "app/NativeComponents/common";
import { AttentionBox } from "app/NativeComponents/snippets";

import ModuleAccess from "./ModuleAccess";

class Access extends Component {
  render() {
    if (this.props.tab == "access" && this.props.platform != "ios") {
      return (
        <KeyboardView style={{ flex: 1 }}>
          <ModuleAccess
            {...this.props}
            title={"DealMachine CRM"}
            slug={"crm"}
          />
          <ModuleAccess
            {...this.props}
            title={"DealMachine Driving For Dollars Plan"}
            slug={"driving"}
          />
          <ModuleAccess
            {...this.props}
            title={"DealMachine List Builder Plan"}
            slug={"lists"}
          />
        </KeyboardView>
      );
    } else if (this.props.tab == "access" && this.props.platform == "ios") {
      return (
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox description="You cannot edit this on the app. Please go to DealMachine on the web to edit these options." />
        </KeyboardView>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, billing }) => {
  const { token, user } = auth;
  const { plan_modules, current_team_members, team_member_limit } = billing;

  return {
    token,
    user,
    plan_modules,
    current_team_members,
    team_member_limit
  };
};

export default connect(mapStateToProps, {})(Access);
