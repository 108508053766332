import React, { Component } from "react";

import { Bold, PrimaryButton } from "app/NativeComponents/common";

import {
  Section,
  ColorOverlay,
  SectionInner,
  SectionContainer,
  Diangle
} from "app/NativeComponents/website/common";

import FooterNavColumn1 from "./FooterNavColumn1";
import FooterNavColumn2 from "./FooterNavColumn2";
import FooterForm from "./FooterForm";

import moment from "moment";

class Footer extends Component {
  render() {
    return (
      <Section id="dm-website-footer-section">
        <Diangle type="Top" />
        <ColorOverlay type={"DarkGray"} has_media={false}>
          <SectionInner>
            <SectionContainer>
              <div className="dm-website-footer-icon">
                <img alt="icon" src="/assets/images/DealMachineIcon.svg" />
              </div>
            </SectionContainer>
            <SectionContainer>
              <div className="dm-website-footer-wrapper">
                <div className="dm-website-footer-info">
                  <FooterNavColumn1 {...this.props} />
                  <FooterNavColumn2 {...this.props} />
                </div>
                <div className="dm-website-footer-form">
                  <FooterForm {...this.props} />
                </div>
              </div>
            </SectionContainer>
            <SectionContainer>
              <div className="dm-website-footer-bottom-text">
                <p>
                  <a href="mailto: support@dealmachine.com">
                    support@dealmachine.com
                  </a>{" "}
                  | Copyright DealMachine {moment().format("YYYY")}
                  <br />
                  735 Shelby Street, STE 105, Indianapolis, IN 46203
                </p>
              </div>
            </SectionContainer>
          </SectionInner>
        </ColorOverlay>
      </Section>
    );
  }
}

export { Footer };
