export const INIT_APP = "init_app";
export const AUTH_FIELD_CHANGED = "auth_field_changed";
export const GET_USER = "get_user";
export const LOGIN_USER = "login_user";
export const LOGIN_USER_FAIL = "login_user_fail";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const TOGGLE_ACCOUNT_ACTION_SHEET = "toggle_account_action_sheet";
export const LOGOUT = "logout";
export const TRIGGER_LOGOUT = "trigger_logout";

export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESS = "register_user_success";
export const REGISTER_USER_FAIL = "register_user_fail";
export const AUTH_UNLOAD = "auth_unload";
export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_FAIL = "forgot_password_fail";
export const FORGOT_PASSWORD_SUCCESS = "forgot_password_success";

export const TOGGLE_ONBOARDING = "toggle_onboarding";
export const SET_PLATFORM_TYPE = "set_platform_type";
export const SET_OAUTH_INFO = "set_oauth_info";
export const SET_LOGINS = "set_logins";
export const TOGGLE_COPY = "toggle_cpoy";
export const GET_MAIN_PLANS = "get_main_plans";
export const GET_MAIN_PLANS_FAIL = "get_main_plans_fail";
export const GET_MAIN_PLANS_SUCCESS = "get_main_plans_success";

export const GET_TERMS = "get_terms";
export const GET_TERMS_SUCCESS = "get_terms_success";
export const GET_TERMS_FAIL = "get_terms_fail";

export const GET_TUTORIAL_SUCCESS = "get_tutorial_success";
export const COMPLETE_TUTORIAL = "complete_tutorial";
export const COMPLETE_TUTORIAL_SUCCESS = "complete_tutorial_success";

export const UPDATE_USER_SETTINGS = "update_user_settings";
export const UPDATE_USER_SETTINGS_FAIL = "update_user_settings_fail";
export const UPDATE_USER_SETTINGS_SUCCESS = "update_user_settings_success";

export const OUTDATED_VERSION = "outdated_version";
export const TOGGLE_USER_REFRESH = "toggle_user_refresh";
