import React, { Component } from "react";
import {
  Wrapper,
  Copy,
  HTMLMarkdown,
  ExternalImage,
  Draggable
} from "app/NativeComponents/common";

class ElementItem extends Component {
  renderSelectedOutline(selected) {
    const { colors, element, device, draggable } = this.props;

    if (selected && device == "desktop") {
      return (
        <Wrapper
          style={{
            position: "absolute",

            top: 0,
            right: 1,
            left: 0,
            bottom: 1,

            borderWidth: 2,
            borderStyle: "dashed",
            borderColor: colors.active_color
          }}
        >
          {draggable ? (
            <>
              <Wrapper
                style={{
                  position: "absolute",
                  top: -6,
                  left: -6,
                  width: 8,
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: colors.card_color,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: colors.active_color
                }}
              />
              <Wrapper
                style={{
                  position: "absolute",
                  top: -6,
                  right: -6,
                  width: 8,
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: colors.card_color,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: colors.active_color
                }}
              />
              <Wrapper
                style={{
                  position: "absolute",
                  bottom: -6,
                  left: -6,
                  width: 8,
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: colors.card_color,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: colors.active_color
                }}
              />
              <Wrapper
                style={{
                  position: "absolute",
                  bottom: -6,
                  right: -6,
                  width: 8,
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: colors.card_color,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: colors.active_color
                }}
              />
            </>
          ) : null}
        </Wrapper>
      );
    }
  }

  removeHTML(content) {
    let new_content = content;
    new_content = new_content.split("<b>").join("");
    new_content = new_content.split("<B>").join("");
    new_content = new_content.split("</b>").join("");
    new_content = new_content.split("</B>").join("");

    new_content = new_content.split("<u>").join("");
    new_content = new_content.split("<U>").join("");
    new_content = new_content.split("</u>").join("");
    new_content = new_content.split("</U>").join("");
    new_content = new_content.split("<i>").join("");
    new_content = new_content.split("<I>").join("");
    new_content = new_content.split("</i>").join("");
    new_content = new_content.split("</I>").join("");

    new_content = new_content.split("<br/>").join("\n");
    new_content = new_content.split("<BR/>").join("\n");

    return new_content;
  }

  renderContent() {
    const { colors, device, element, no_lazy, template_type } = this.props;

    if (!!element.content && element.layer_type == "text") {
      /*
      if (device == "mobile") {
        return (
          <Copy
            custom_font={element.font_family}
            style={{
              alignSelf: "stretch",

              textAlign: element.text_align,
              color: element.text_color,
              fontSize: !!element.font_size ? parseInt(element.font_size) : 14,
              lineHeight: !!element.line_height
                ? device == "mobile"
                  ? parseInt(element.line_height)
                  : parseInt(element.line_height) + "px"
                : "0px"
            }}
          >
            {this.removeHTML(
              this.props.renderBracketVariables(element.content)
            )}
          </Copy>
        );
      } else {
        */
      return (
        <Wrapper
          style={
            device == "mobile"
              ? {
                  alignSelf: "stretch"
                }
              : {
                  alignSelf: "stretch",
                  textAlign: element.text_align,
                  color: element.text_color,
                  fontFamily: element.font_family,
                  fontSize: !!element.font_size
                    ? parseInt(element.font_size)
                    : 14,
                  lineHeight: parseInt(element.line_height) + "px"
                }
          }
        >
          <HTMLMarkdown
            fontStyle={{
              textAlign: element.text_align,
              fontFamily:template_type === "handwritten" ? "Rose" : element.font_family,
              color: template_type === "handwritten" ? "#003DDC" : element.text_color,
              fontSize: !!element.font_size ? parseInt(element.font_size) : 14,
              lineHeight: !!element.line_height
                ? device == "mobile"
                  ? parseInt(element.line_height)
                  : parseInt(element.line_height) + "px"
                : "0px"
            }}
            source={this.props.renderBracketVariables(element.content)}
            escapeHtml={false}
            template_type={template_type}
          />
        </Wrapper>
      );
      //}
    } else if (element.layer_type == "image") {
      return (
        <ExternalImage
          no_lazy={no_lazy}
          contain={element.image_fit == "contain" ? true : false}
          style={{
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            resizeMode: element.image_fit
          }}
          resizeMode={element.image_fit}
          image={
            !!element.image_url
              ? this.props.renderBracketVariables(element.image_url)
              : ""
          }
        />
      );
    }
  }
  render() {
    const {
      colors,
      device,
      element,
      selected_element,
      updateLayer,
      zIndex,
      grid,
      draggable,
      scale = 1,
      container_width,
      container_height
    } = this.props;

    const selected = selected_element && selected_element.id == element.id;
    let style = {
      display: "flex",
      position: "absolute",
      boxSizing: "border-box",
      zIndex: zIndex,
      backgroundColor:
        element.backgroundType == "color" ? element.background : "transparent",
      paddingTop: !!element.padding_top ? parseInt(element.padding_top) : 0,
      paddingLeft: !!element.padding_left ? parseInt(element.padding_left) : 0,
      paddingBottom: !!element.padding_bottom
        ? parseInt(element.padding_bottom)
        : 0,
      paddingRight: !!element.padding_right
        ? parseInt(element.padding_right)
        : 0,

      borderWidth:
        element.border_style !== "none" && !!element.border_width
          ? parseInt(element.border_width)
          : 0,
      borderStyle:
        element.border_style !== "none" && !!element.border_style
          ? element.border_style
          : "solid",
      borderColor:
        element.border_style !== "none" && !!element.border_color
          ? element.border_color
          : "transparent",

      borderRadius: !!element.border_radius
        ? parseInt(element.border_radius)
        : 0,

      overflow: "hidden",
      //boxShadow: selected ? "0 0 0 1px " + colors.active_color : "none",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column"
    };

    if (selected && !element.locked && draggable && device == "desktop") {
      return (
        <Draggable
          style={style}
          container_width={container_width}
          container_height={container_height}
          element={element}
          onDrag={({ x, y }) => {
            updateLayer({
              layer: {
                ...element,
                x,
                y
              }
            });
          }}
          onResize={({ x, y, width, height }) => {
            updateLayer({
              layer: {
                ...element,
                width,
                height,
                x,
                y
              }
            });
          }}
        >
          {this.renderContent(selected)}
          {this.renderSelectedOutline(selected)}
        </Draggable>
      );
    }

    style = {
      ...style,
      top: !!element.y ? parseInt(element.y) : 0,
      left: !!element.x ? parseInt(element.x) : 0,
      width: !!element.width ? parseInt(element.width) : 0,
      height: !!element.height ? parseInt(element.height) : 0,
      pointerEvents: "none"
    };

    return (
      <Wrapper style={style}>
        {this.renderContent(selected)}
        {this.renderSelectedOutline(selected)}
      </Wrapper>
    );
  }
}

export default ElementItem;
