import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  WebContainer,
  Wrapper,
  Title,
  Copy,
  Bold,
  Header1,
  Header3,
  Spinner,
  Page
} from "app/NativeComponents/common";
import {
  NewHeader,
  PillButton,
  InlineButton,
  GhostButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";
import {
  /*mobile*/
  toggleCanPop,
  appRedirect,
  updateUser,
  saveCard,
  logout,
  billingFieldChanged,
  authFieldChanged,
  updatePlan,
  resetSelectedPlan,
  resetBilling,
  selectPlan,
  togglePlanFrequency,
  dismissMobileKeyboard,
  displayIntercom,
  openUrl,
  AppConfig,
  loadOnboardingText,
  tabNavigation,
  manageSubscription
} from "app/NativeActions";
import DisplayAddons from "../../MarketingOnboardingAndFunnel/GlobalSnippets/DisplayAddons";

class Canceled extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_plans: false,
      contentful_content: null,
      contentful_loading: false,
      plan_interval: "month"
    };

    this.handleBack = this.handleBack.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData("reactivate_plan");
  }

  handleBack() {
    this.props.logout({ token: this.props.token });
  }

  loadData(slug) {
    loadOnboardingText(slug)
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              contentful_content: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              contentful_loading: false
            });
          }
        }
        this.setState(() => ({ contentful_loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ contentful_loading: false }));
      });
  }

  render() {
    const { contentful_content, plan_interval } = this.state;
    const { colors } = this.props;
    if (contentful_content) {
      return (
        <Page
          metaTitle={
            contentful_content?.title + " - DealMachine for Real Estate"
          }
          responsive_design={true}
        >
          <OnboardingWrapper>
            <Wrapper style={{ padding: 25 }}>
              <Header1 style={{ textAlign: "center" }}>
                <Bold>{contentful_content?.title}</Bold>
              </Header1>

              <Header3 style={{ textAlign: "center" }}>
                {contentful_content?.subtitle}
              </Header3>
            </Wrapper>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <InlineButton
                iconColor={
                  plan_interval == "year" ? colors.success_color : null
                }
                marketing_slug={
                  this.state.plan_interval == "year"
                    ? "unselected_annual_on_plan_selection"
                    : "selected_annual_on_plan_selection"
                }
                icon={plan_interval == "year" ? "toggle-on" : "toggle-off"}
                style={{
                  backgroundColor:
                    plan_interval == "month"
                      ? colors.orange_color_muted
                      : colors.success_color_muted
                }}
                onPress={() => {
                  this.setState({
                    plan_interval: plan_interval == "month" ? "year" : "month"
                  });
                }}
              >
                Save 17% with annual
              </InlineButton>
            </Wrapper>
            <DisplayAddons
              contentful_content={this.state.contentful_content}
              manageSubscription={this.props.manageSubscription}
              plan_interval={plan_interval}
              onSuccess={() => {
                this.props.tabNavigation({ slug: "map" });
              }}
            />
            {!!contentful_content?.description ? (
              <Wrapper
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 25,
                  paddingTop: 0
                }}
              >
                <Copy style={{ textAlign: "center" }}>
                  {contentful_content?.description}
                </Copy>
              </Wrapper>
            ) : null}
            <Wrapper
              style={{
                padding: 25,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <InlineButton
                id="intercom_button"
                onPress={() => displayIntercom()}
              >
                Talk To Support
              </InlineButton>
            </Wrapper>
          </OnboardingWrapper>
        </Page>
      );
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = ({ auth, native, billing, settings }) => {
  const { token, user, accepted_terms } = auth;
  const { colors } = settings;
  const { device, platform } = native;
  const { card, price, selected_plan, frequency, loading, source_of_truth } =
    billing;

  return {
    token,
    user,
    device,
    platform,
    card,
    price,
    selected_plan,
    frequency,
    loading,
    accepted_terms,
    source_of_truth,
    colors
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,

  appRedirect,

  updatePlan,
  updateUser,
  saveCard,
  logout,
  billingFieldChanged,
  authFieldChanged,
  resetSelectedPlan,
  resetBilling,
  selectPlan,
  tabNavigation,

  togglePlanFrequency,
  manageSubscription
})(Canceled);
