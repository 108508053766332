import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "app/NativeComponents/common";
import Body from "./Body";
class Lists extends Component {
  render() {
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: this.props.colors.card_color
        }}
      >
        <Body />
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  return {
    token,
    user,
    isMobile,
    platform,
    device,
    colors
  };
};

export default connect(mapStateToProps, {})(Lists);
