import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, KeyboardView, Animation } from "app/NativeComponents/common";
import {
  NewHeader,
  ConfirmInlineButton,
  BottomNavBar,
  InputBox,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import { popSidePanel, createList } from "app/NativeActions";

class ListBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list_title: props.edit_list_info?.title || "",
      loading: false
    };

    this._list_title = React.createRef();
  }

  componentDidMount() {}

  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    const { list_title } = this.state;
    if (!!list_title) {
      return true;
    }

    return false;
  }

  render() {
    const { edit_list_info, colors } = this.props;
    const { list_title, loading, deleting } = this.state;
    return (
      <>
        <NewHeader
          title={"New List"}
          subtitle={""}
          leftButton={{
            icon:
              this.props.device == "mobile" ||
              this.props.side_panel_views.length > 1
                ? "arrow-back"
                : "close",
            onPress: () => {
              this.handleBack();
            }
          }}
        />

        <>
          <InputBox
            input_ref={this._list_title}
            autoFocus={true}
            name="list_title"
            disabled={loading}
            returnKeyType="done"
            placeholder={
              !!list_title ? "List name" : "Enter a name for this list"
            }
            onFocus={() => {}}
            onChange={value => {
              this.setState({
                list_title: value
              });
            }}
            blurOnSubmit={true}
            value={list_title}
            input_type="text"
            require_confirm={true}
            has_changed={this.checkIfNeedsToSave()}
            submit_button_primary={true}
            submit_button_title={"Create List"}
            onSubmit={() => {
              this.props.createList({
                token: this.props.token,
                title: list_title,
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                },
                onSuccess: () => {
                  this.setState({
                    loading: false
                  });
                  this.handleBack();
                }
              });
            }}
            loading={this.state.loading}
          />
        </>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, list, modal }) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { side_panel_views } = modal;

  return {
    token,
    user,
    platform,
    device,
    isMobile,
    colors,
    side_panel_views
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  createList
})(ListBody);
