import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Scroll
} from "app/NativeComponents/common";
import {
  AttentionBox,
  NewHeader,
  GhostButton
} from "app/NativeComponents/snippets";
import { popSidePanel, showSuccess, copyToClipBoard } from "app/NativeActions";
class ExportDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      export_json: null
    };
  }

  componentDidMount() {
    this.renderJSONString();
  }

  renderJSONString() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (panel_data) {
      const { front_layers, back_layers, template } = panel_data;
      let export_json = {};
      export_json.front_layers = front_layers;
      export_json.back_layers = back_layers;
      export_json.template_info = {
        template_title: template.template_title
      };

      this.setState({
        export_json: JSON.stringify(export_json, null, 2)
      });
    }
  }

  render() {
    const { colors, isMobile } = this.props;
    const { export_json } = this.state;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;

    return (
      <Container>
        <NewHeader
          title={"Export Design"}
          leftButton={{
            onPress: () => {
              this.props.popSidePanel();
            },
            icon: "arrow-back"
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox
            description={
              'Copy the JSON code below. You can paste this code into yours or another account with the "Import Design" feature.'
            }
          />
          {this.props.device == "desktop" ? (
            <pre
              style={{
                backgroundColor: colors.background_color,
                wordBreak: "break-all",
                maxHeight: 300,
                overflow: "auto",
                borderWith: 1,
                borderStyle: "solid",
                borderColor: colors.border_color,
                margin: 5,
                borderRadius: 5
              }}
            >
              <code>{export_json}</code>
            </pre>
          ) : (
            <Wrapper
              style={{
                backgroundColor: colors.background_color,
                wordBreak: "break-all",
                maxHeight: 300,
                overflow: "hidden",
                borderWith: 1,
                borderStyle: "solid",
                borderColor: colors.border_color,
                margin: 5,
                borderRadius: 5
              }}
            >
              <Scroll>
                <Copy>{export_json}</Copy>
              </Scroll>
            </Wrapper>
          )}

          <GhostButton
            button_type={"full"}
            primary={true}
            onPress={() => {
              copyToClipBoard(export_json);

              this.props.showSuccess(
                "Your JSON code was copied to your clipboard",
                "Copied!"
              );
            }}
          >
            Copy Code
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  showSuccess
})(ExportDesign);
