import React, { PureComponent } from "react";
import { store } from "app/store";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

const renderText = text => {
  if (text) {
    return <div className="dm-spinner-text">{text}</div>;
  }
};

const Spinner = ({ color, text }) => {
  const colors = store.getState().settings.colors;
  const dark_mode = store.getState().settings.dark_mode;

  return (
    <div
      className={"dm-spinner-overlay center-flex flex-item animated fadeIn"}
      style={{
        backgroundColor:
          dark_mode == "dark_mode"
            ? colors.background_color
            : "rgba(255,255,255,0.75)"
      }}
    >
      {renderText(text)}

      <BeatLoader
        color={color || colors.active_color}
        loading={true}
        size={14}
      />
    </div>
  );
};
export { Spinner };
