import React, { PureComponent } from "react";
import { store } from "app/store";
import { Row, Copy } from "app/NativeComponents/common";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";

const Spin = ({ size, color, text = null, style, className }) => {
  const colors = store.getState().settings.colors;

  return (
    <div className="deal-spin">
      <Row>
        <MoonLoader
          color={color}
          loading={true}
          css={{
            marginRight: !!text ? 5 : 0,
            fontSize: style && style.fontSize ? style.fontSize : "inherit"
          }}
          size={size == "small" || !!text ? 14 : 32}
        />

        {!!text ? (
          <Copy style={{ color: color || colors.text_color, ...style }}>
            {text}
          </Copy>
        ) : null}
      </Row>
    </div>
  );
};

export { Spin };
