import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll, Copy } from "app/NativeComponents/common";
import {
  PopoverMenu,
  SearchBar,
  BottomNavBar,
  InlineButton,
  List,
  CircleButton
} from "app/NativeComponents/snippets";
import { pushSidePanel, showSuccess, formatAddress } from "app/NativeActions";
import PhoneNumberItem from "./PhoneNumberItem";

class PhoneNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      show: false
    };

    this._scroll_view = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  scrollToBottom() {
    const { device } = this.props;

    if (device == "desktop" && this._scroll_view && this._scroll_view.current) {
      this._scroll_view.current.scrollTop =
        this._scroll_view.current.scrollHeight + 20;
    } else if (
      device == "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      this._scroll_view.current.scrollToEnd
    ) {
      this._scroll_view.current.scrollToEnd({ animated: true });
    }
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].phone_number?.toLowerCase().indexOf(search?.toLowerCase()) !=
          -1 ||
        options[i].phone_label?.toLowerCase().indexOf(search?.toLowerCase()) !=
          -1 ||
        options[i].cnam?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  toggleShow(s) {
    this.setState({
      show: s
    });
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      style,
      property,
      popoverPlacement = "bottom",
      renderComponent = () => {},
      renderComponentLoading = () => {},
      componentStyle = {}
    } = this.props;
    const {
      selected_lists,
      stacked_lists,
      loading_options,
      search,
      show,
      hide_unselected
    } = this.state;

    const disabled = !property?.deal || property?.deal?.removed == 1;

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.toggleShow(s);
        }}
        popover_width={450}
        popover_height={350}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={"Manage phone numbers for " + property.property_address}
        includeCloseButton={true}
        disabled={disabled}
        renderComponent={({ hovering, pressed_in }) => {
          return (
            <CircleButton
              noPress={true}
              pressedIn={hovering || pressed_in || show}
              style={{ marginLeft: 0 }}
              tooltip={
                this.props.people_data
                  ? "You skip traced this property in a previous plan and got these mailing addresses. You can find our new unlimited data below."
                  : "Phone Numbers"
              }
              tooltipPlacement={"top"}
              icon="phone"
              show_notification={!disabled}
              notification_color={
                property?.deal?.phone_numbers?.length == 0
                  ? colors.success_color
                  : colors.active_color
              }
              notification_icon={
                property?.deal?.phone_numbers?.length == 0 ? "add" : ""
              }
              notification_number={property?.deal?.phone_numbers?.length}
              disabled={disabled}
            />
          );
        }}
        renderMenu={() => {
          return (
            <>
              <Wrapper style={{ alignSelf: "stretch" }}>
                <Row>
                  <Wrapper style={{ flex: 1 }}>
                    <SearchBar
                      style={{
                        margin: 10,
                        marginRight: 0,
                        borderWidth: 0,
                        borderBottomWidth: 0
                      }}
                      //autoFocus={true}
                      title="Search Phone Numbers"
                      onChange={value => {
                        this.setState({
                          search: value
                        });
                      }}
                      value={search}
                    />
                  </Wrapper>
                  <InlineButton
                    primary={true}
                    onPress={() => {
                      this.props.pushSidePanel({
                        slug: "add_phone_number",
                        overlay: true,
                        data: {
                          property,
                          propertyUpdated: this.props.propertyUpdated
                        }
                      });
                      this.toggleShow(false);
                    }}
                    icon="add"
                  >
                    Add
                  </InlineButton>
                </Row>
              </Wrapper>
              {property?.deal?.owner?.phone_numbers?.length == 0 ? (
                <Wrapper style={{ flex: 1 }}>
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>
                      There are no phone numbers associated with this lead.
                    </Copy>
                  </Wrapper>
                </Wrapper>
              ) : (
                <List
                  style={{ alignSelf: "stretch" }}
                  items={this.filterOptions(
                    property?.deal?.owner?.phone_numbers
                  )}
                  listHeader={() => {
                    return null;
                  }}
                  itemStructure={({ item, index }) => {
                    return (
                      <PhoneNumberItem
                        key={"option_" + index}
                        item={item}
                        toggleShow={this.toggleShow}
                        {...this.props}
                      />
                    );
                  }}
                />
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors, dark_mode } = settings;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    dark_mode
  };
};

export default connect(mapStateToProps, {
  pushSidePanel,
  showSuccess
})(PhoneNumbers);
