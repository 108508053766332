import React, { PureComponent } from "react";

import {
  Row,
  Wrapper,
  Card,
  CardBody,
  Copy,
  Bold,
  Title
} from "app/NativeComponents/common";

import {
  ResponsiveContainer,
  Line,
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import { LineGraphStats } from "app/NativeComponents/snippets";

import moment from "moment";
import { store } from "app/store";

class LineGraph extends PureComponent {
  renderDate(date, other_date) {
    if (
      moment(date).format("YYYY") != moment().format("YYYY") ||
      moment(other_date).format("YYYY") != moment().format("YYYY")
    ) {
      return moment(date).format("M/D/YY");
    } else {
      return moment(date).format("MMM Do");
    }
  }

  renderCustomToolTip({ active, payload, label }) {
    if (active && payload && payload.length > 1) {
      return (
        <Card>
          <LineGraphStats
            title={this.props.title}
            format={this.props.format}
            new_value={
              payload[1] ? (payload[1].value ? payload[1].value : "--") : "--"
            }
            old_value={
              payload[0] ? (payload[0].value ? payload[0].value : "--") : "--"
            }
            new_label={
              payload[0]
                ? payload[0].payload
                  ? this.renderDate(
                      payload[0].payload.date,
                      payload[0].payload.prev_date
                    )
                  : "--"
                : "--"
            }
            old_label={
              payload[0]
                ? payload[0].payload
                  ? this.renderDate(
                      payload[0].payload.prev_date,
                      payload[0].payload.date
                    )
                  : "--"
                : "--"
            }
          />
        </Card>
      );
    }
    return <Wrapper />;
  }

  render() {
    const {
      title,
      data1,
      data2,

      x_key1,
      x_key2,
      y_key1,
      y_key2,

      format
    } = this.props;

    const colors = store.getState().settings.colors;
    return (
      <Wrapper>
        <ResponsiveContainer width={"100%"} height={300} radius={[0, 5, 5, 0]}>
          <ComposedChart
            data={data1}
            radius={[0, 5, 5, 0]}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }}
            border={false}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="80%"
                  stopColor={colors.active_color}
                  stopOpacity={0.2}
                />
                <stop
                  offset="95%"
                  stopColor={colors.active_color}
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <CartesianGrid horizontal={false} />
            <XAxis dataKey={x_key1} hide />
            <YAxis hide />
            <Tooltip
              content={({ active, payload, label }) =>
                this.renderCustomToolTip({ active, payload, label })
              }
            />
            <Line
              activeDot={{ stroke: colors.border_color, strokeWidth: 5 }}
              dataKey={y_key2}
              dot={false}
              stroke={colors.border_color}
              strokeWidth={3}
            />
            <Area
              type="monotone"
              activeDot={{ stroke: colors.active_color, strokeWidth: 5 }}
              dataKey={y_key1}
              stroke={colors.active_color}
              strokeWidth={3}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Wrapper>
    );
  }
}

export { LineGraph };
