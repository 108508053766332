import React, { PureComponent } from "react";
import {
  Button,
  Wrapper,
  Copy,
  Bold,
  Row,
  Spin,
  Icon,
  Card,
  KeyboardView
} from "app/NativeComponents/common";
import {
  SearchBar,
  InlineTabs,
  InlineButton,
  SelectItem,
  BottomNavBar,
  PopoverMenu,
  CircleButton,
  AttentionBox,
  UpsellButton,
  GhostButton,
  IconButton,
  InputBox,
  List
} from "app/NativeComponents/snippets";

import AutocompleteSearch from "app/DealMachineCore/reuseable/AutocompleteSearch";

import NativeListBuilderMap from "app/NativeComponents/components/NativeListBuilderMap";
import { convertDistance, getDistance } from "geolib";
import ListBuilderCountLimits from "../ListBuilderCountLimits";
import ListName from "../ListName";

import {
  numberWithCommas,
  getDeviceLocation,
  getPropertyFIPS,
  openUrl
} from "app/NativeActions";

class ListBuilderMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search_focused: false,
      fips_loading: true,
      fips: [],
      county_search: "",
      latitude: props?.demo_latitude
        ? parseFloat(props.demo_latitude)
        : props.list_builder_location?.latitude
        ? props.list_builder_location.latitude
        : props.user?.default_lat
        ? props.user.default_lat
        : 39.97411,
      longitude: props?.demo_longitude
        ? parseFloat(props.demo_longitude)
        : props.list_builder_location?.longitude
        ? props.list_builder_location.longitude
        : props.user?.default_lng
        ? props.user.default_lng
        : -86.11756,
      updated_latitude: props.list_builder_location?.latitude
        ? props.list_builder_location.latitude
        : props.user?.default_lat
        ? props.user.default_lat
        : 39.97411,
      updated_longitude: props.list_builder_location?.longitude
        ? props.list_builder_location.longitude
        : props.user?.default_lng
        ? props.user.default_lng
        : -86.11756,
      last_fetch_location: null,
      zoom: props.list_builder_location?.zoom
        ? props.list_builder_location.zoom
        : 13,
      updated_zoom: props.list_builder_location?.zoom
        ? props.list_builder_location.zoom
        : 13,
      maxZoom: 17,
      minZoom: 11.5,
      pitch: props.list_builder_location?.pitch
        ? props.list_builder_location.pitch
        : 0,
      updated_pitch: props.list_builder_location?.pitch
        ? props.list_builder_location.pitch
        : 0,
      heading: props.list_builder_location?.heading
        ? props.list_builder_location.heading
        : 0,
      updated_heading: props.list_builder_location?.heading
        ? props.list_builder_location.heading
        : 0,
      drawing_created:
        props.drawing_coordinates.length > 0 && props.location_type == "draw"
          ? true
          : false,
      drawing:
        props.drawing_coordinates.length > 0 && props.location_type == "draw"
          ? true
          : false,
      previous_lists_loading: false,
      lists_on_map: [],
      show_lists_on_map: props.list_builder_location?.show_lists_on_map
        ? props.list_builder_location.show_lists_on_map
        : true,
      lists_on_map_loading: false,
      leads_on_map: [],
      show_leads_on_map: props.list_builder_location?.show_leads_on_map
        ? props.list_builder_location.show_leads_on_map
        : true,
      leads_on_map_loading: false,
      highlights_on_map: [],
      show_highlights_on_map: props.list_builder_location
        ?.show_highlights_on_map
        ? props.list_builder_location.show_highlights_on_map
        : true,
      highlights_on_map_loading: false,
      show_options: false,
      show_location_type_toggle: false,
      bounds: null,

      map_type: props.list_builder_location?.map_type
        ? props.list_builder_location.map_type
        : "standard",
      hide_step_2_copy: true, //props.location_type !== "zip" ? true : false
      current_location_loading: false,
      saving_last_location: false
    };
    this.onDraw = this.onDraw.bind(this);
    this.drawingCreated = this.drawingCreated.bind(this);
    this.toggleDrawing = this.toggleDrawing.bind(this);
    this.updateCenter = this.updateCenter.bind(this);
    this.updateBounds = this.updateBounds.bind(this);
    this.updateZoomProperties = this.updateZoomProperties.bind(this);
    this.updateSearchFocus = this.updateSearchFocus.bind(this);
    this.updateHighlights = this.updateHighlights.bind(this);

    this.updateLatLng = this.updateLatLng.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
  }

  componentDidMount() {
    //get list builder templates
    this.updateLocations();

    this.props.getPropertyFIPS({
      token: this.props.token,
      onLoading: () => {
        this.setState({
          fips_loading: true
        });
      },
      onError: () => {
        this.setState({
          fips_loading: false
        });
      },
      onSuccess: results => {
        this.setState({
          fips: results?.fips || [],
          fips_loading: false
        });
      }
    });
  }

  filterOptions(options) {
    let filtered_options = [];
    const { county_search } = this.state;

    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label?.toLowerCase().indexOf(county_search?.toLowerCase()) !=
          -1 &&
        options[i].state == this.props.state
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.updated_latitude !== this.state.updated_latitude ||
      prevState.updated_longitude !== this.state.updated_longitude
    ) {
      this.updateLocations();
    }
    if (
      prevState.updated_latitude !== this.state.updated_latitude ||
      prevState.updated_longitude !== this.state.updated_longitude ||
      prevState.updated_zoom !== this.state.updated_zoom ||
      prevState.updated_pitch !== this.state.updated_pitch ||
      prevState.updated_heading !== this.state.updated_heading ||
      prevState.show_lists_on_map !== this.state.show_lists_on_map ||
      prevState.show_leads_on_map !== this.state.show_leads_on_map ||
      prevState.show_highlights_on_map !== this.state.show_highlights_on_map ||
      prevState.map_type !== this.state.map_type
    ) {
      const new_location = {
        longitude: this.state.updated_longitude,
        latitude: this.state.updated_latitude,
        pitch: this.state.updated_pitch,
        heading: this.state.updated_heading,
        zoom: this.state.updated_zoom,
        show_lists_on_map: this.state.show_lists_on_map,
        show_leads_on_map: this.state.show_leads_on_map,
        show_highlights_on_map: this.state.show_highlights_on_map,
        map_type: this.state.map_type
      };

      this.props.updateListBuilderLocation(new_location);

      if (
        !this.state.saving_last_location &&
        new_location?.latitude != 0 &&
        new_location?.longitude != 0
      ) {
        this.props.updateUserSettings({
          token: this.props.token,
          type: "list_builder_location",
          value: new_location,
          onLoading: () => {
            this.setState({
              saving_last_location: true
            });
          },
          onError: () => {
            this.setState({
              saving_last_location: false
            });
          },
          onSuccess: () => {
            this.setState({
              saving_last_location: false
            });
          }
        });
      }
    }

    if (
      (prevState.show_lists_on_map !== this.state.show_lists_on_map ||
        prevState.show_leads_on_map !== this.state.show_leads_on_map ||
        prevState.show_highlights_on_map !==
          this.state.show_highlights_on_map) &&
      this.state.bounds
    ) {
      this.updateBounds(this.state.bounds);
    }

    if (
      JSON.stringify(prevProps.filters) !==
        JSON.stringify(this.props.filters) &&
      this.state.bounds
    ) {
      this.setState({
        highlights_on_map: []
      });
      this.updateHighlights(this.state.bounds);
    }
    //force update on map change
    if (prevState.map_type !== this.state.map_type) {
      this.setState({
        highlights_on_map: [],
        lists_on_map: [],
        leads_on_map: []
      });
      this.updateBounds(this.state.bounds, true);
    }
  }

  updateCenter(coordinate) {
    this.setState({
      updated_latitude: coordinate.latitude,
      updated_longitude: coordinate.longitude
    });
  }

  updateBounds(bounds, force = false) {
    //if not already loading...get list builder previously drawn lists
    this.setState({
      bounds
    });

    if (
      force ||
      !this.state.last_fetch_location ||
      getDistance(this.state.last_fetch_location, {
        latitude: bounds.centerLat,
        longitude: bounds.centerLng
      }) >
        2500 / this.state.updated_zoom ||
      this.state.leads_on_map.length == 0 ||
      (this.state.highlights_on_map.length == 0 &&
        this.props.filters?.data?.length > 0)
    ) {
      if (
        this.state.show_lists_on_map &&
        !this.state.lists_on_map_loading &&
        !this.props.demo
      ) {
        this.props.getListsOnMap({
          token: this.props.token,
          bounds,
          onLoading: () => {
            this.setState({
              lists_on_map_loading: true
            });
          },
          onError: () => {
            this.setState({
              lists_on_map_loading: false
            });
          },
          onSuccess: results => {
            let new_lists_on_map = JSON.parse(
              JSON.stringify(this.state.lists_on_map)
            );
            for (let i = 0; i < results?.lists_on_map?.length; i++) {
              let found_list = false;
              for (let j = 0; j < new_lists_on_map.length; j++) {
                if (results.lists_on_map[i].id == new_lists_on_map[j].id) {
                  found_list = true;
                  new_lists_on_map[j] = results.lists_on_map[i];
                }
              }
              if (!found_list) {
                new_lists_on_map.push(results.lists_on_map[i]);
              }
            }

            this.setState({
              lists_on_map_loading: false,
              lists_on_map: new_lists_on_map,
              last_fetch_location: {
                latitude: bounds.centerLat,
                longitude: bounds.centerLng
              }
            });
          }
        });
      }
      if (
        this.state.show_leads_on_map &&
        this.state.updated_zoom > 12 &&
        !this.state.leads_on_map_loading &&
        !this.props.demo
      ) {
        this.props.getLeadsOnMapForListBuilder({
          token: this.props.token,
          bounds,

          onLoading: () => {
            this.setState({
              leads_on_map_loading: true
            });
          },
          onError: () => {
            this.setState({
              leads_on_map_loading: false
            });
          },
          onSuccess: results => {
            let new_leads_on_map = JSON.parse(
              JSON.stringify(this.state.leads_on_map)
            );
            for (let i = 0; i < results?.leads_on_map?.length; i++) {
              let found_lead = false;
              for (let j = 0; j < new_leads_on_map.length; j++) {
                if (results.leads_on_map[i].id == new_leads_on_map[j].id) {
                  found_lead = true;
                  new_leads_on_map[j] = results.leads_on_map[i];
                }
              }
              if (!found_lead) {
                new_leads_on_map.push(results.leads_on_map[i]);
              }
            }

            this.setState({
              leads_on_map_loading: false,
              leads_on_map: new_leads_on_map,
              last_fetch_location: {
                latitude: bounds.centerLat,
                longitude: bounds.centerLng
              }
            });
          }
        });
      }

      this.updateHighlights(bounds);
    }
  }

  updateHighlights(bounds) {
    if (
      this.state.show_highlights_on_map &&
      this.state.updated_zoom > 12 &&
      !this.state.highlights_on_map_loading &&
      this.props.filters?.data?.length > 0
    ) {
      this.props.getHighlightsOnMapForListBuilder({
        token: this.props.token,
        bounds,
        filters: this.props.filters,
        /*dont_include: this.state.highlights_on_map
                          .map(highlight => {
                            return highlight.id;
                          })
                          .join(","),*/
        onLoading: () => {
          this.setState({
            highlights_on_map_loading: true
          });
        },
        onError: () => {
          this.setState({
            highlights_on_map_loading: false
          });
        },
        onSuccess: results => {
          let new_highlights_on_map = JSON.parse(
            JSON.stringify(this.state.highlights_on_map)
          );
          for (let i = 0; i < results?.highlights_on_map?.length; i++) {
            let found_highlight = false;
            for (let j = 0; j < new_highlights_on_map.length; j++) {
              if (
                results.highlights_on_map[i].id == new_highlights_on_map[j].id
              ) {
                found_highlight = true;
                new_highlights_on_map[j] = results.highlights_on_map[i];
              }
            }
            if (!found_highlight) {
              new_highlights_on_map.push(results.highlights_on_map[i]);
            }
          }

          this.setState({
            highlights_on_map_loading: false,
            highlights_on_map: new_highlights_on_map,
            last_fetch_location: {
              latitude: bounds.centerLat,
              longitude: bounds.centerLng
            }
          });
        }
      });
    }
  }

  updateZoomProperties({ zoom, pitch, heading }) {
    this.setState({
      updated_zoom: zoom,
      updated_pitch: pitch,
      updated_heading: heading
    });
  }

  updateLocations() {
    this.props.getReverseGeocode({
      token: this.props.token,
      coordinate: {
        longitude: this.state.updated_longitude,
        latitude: this.state.updated_latitude
      },
      no_property: true,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        if (results.parsed_address) {
          this.props.updateLocationTab({
            city: results.parsed_address?.city,
            state: results.parsed_address?.state,
            zip: results.parsed_address?.zip,
            fips: results?.fips ? results.fips : this.props.fips,
            fips_name: results?.fips_name
              ? results.fips_name
              : this.props.fips_name,
            location_type: this.props.location_type,
            drawing_coordinates: this.props.drawing_coordinates
          });
        }
      }
    });
  }

  updateLatLng({ latitude, longitude, location_type }) {
    this.setState({
      longitude: longitude,
      latitude: latitude,
      updated_longitude: longitude,
      updated_latitude: latitude,
      pitch: 0,
      updated_pitch: 0,
      heading: 0,
      updated_heading: 0,
      zoom: this.state.updated_zoom,
      hide_step_2_copy: true
    });
    if (
      location_type == "city" ||
      location_type == "zip" ||
      location_type == "county"
    ) {
      this.props.updateLocationTab({
        location_type
      });
    }
  }

  onDraw(coordinates) {
    this.props.updateLocationTab({
      drawing_coordinates: coordinates
    });
  }

  drawingCreated(coordinates) {
    this.setState({
      drawing_created: true
    });
  }

  toggleDrawing(toggle) {
    this.setState({
      drawing_created: false,
      drawing: toggle
    });
    /*
            this.props.updateLocationTab({
              drawing_coordinates: toggle ? this.props.drawing_coordinates : []
            });
            */
  }

  updateSearchFocus(search_focused) {
    this.setState({
      search_focused,
      hide_step_2_copy: true
    });

    if (search_focused) {
      this.toggleDrawing(false);
    }
  }

  renderEstimatedText() {
    let estimated_text = "Draw an area to continue";

    if (this.props.count_loading) {
      return "Loading estimated count...";
    }

    if (this.props.checkIfCanBuild() && !this.props.count_loading) {
      estimated_text =
        this.props.estimated_count == 1
          ? "Estimated 1 lead"
          : "Estimated " +
            numberWithCommas(this.props.estimated_count) +
            " leads";

      estimated_text +=
        this.props.location_type == "zip" && !!this.props.zip
          ? " in " + this.props.zip
          : this.props.location_type == "city" &&
            !!this.props.city &&
            !!this.props.state
          ? " in " + this.props.city + ", " + this.props.state
          : this.props.location_type == "county" &&
            !!this.props.fips_name &&
            !!this.props.state
          ? " in " + this.props.fips_name + ", " + this.props.state
          : " in the drawn area";
    }

    return estimated_text;
  }

  renderEstimatedCount() {
    if (this.props.count_loading) {
      return (
        <Wrapper
          style={
            this.props.isMobile
              ? { alignItems: "center", justifyContent: "center" }
              : {}
          }
        >
          <Spin
            text="Loading estimated count"
            color={this.props.colors.actionable_text_color}
            style={{ fontSize: 12 }}
          />
        </Wrapper>
      );
    }
    return (
      <>
        <Copy
          style={{
            fontSize: 12,
            textAlign: this.props.isMobile ? "center" : "left"
          }}
        >
          <Bold>{this.renderEstimatedText()}</Bold>
        </Copy>
        {!this.props.isMobile && !this.props.demo ? (
          <ListBuilderCountLimits {...this.props} />
        ) : null}
      </>
    );
  }

  render() {
    const {
      location_type,
      zip,
      city,
      fips,
      fips_name,
      colors,
      isMobile,
      lists_info_banner,
      list_builder_limits,
      estimated_count,
      source_of_truth
    } = this.props;
    const { drawing } = this.state;
    const new_list_count =
      this.props.user?.user_version >= 6
        ? parseInt(
            source_of_truth?.current_limits
              ?.number_of_leads_added_from_list_builder?.amount
          ) + parseInt(estimated_count)
        : parseInt(lists_info_banner?.current_count) +
          parseInt(estimated_count);

    return (
      <>
        <Row>
          <Wrapper
            style={{
              flex: 1,
              alignSelf: "stretch"
            }}
          >
            <AutocompleteSearch
              search_focused={this.state.search_focused}
              updateSearchFocus={this.updateSearchFocus}
              toggleDrawing={this.toggleDrawing}
              updateLatLng={this.updateLatLng}
              centerLat={this.state.updated_latitude}
              centerLng={this.state.updated_longitude}
              style={{
                marginRight: 0
              }}
            />
          </Wrapper>

          {!this.state.search_focused || !isMobile ? (
            <>
              <Row>
                {location_type !== "draw" ? (
                  <PopoverMenu
                    show={this.state.show_location_type_toggle}
                    no_swipe={true}
                    no_cancel={true}
                    onShow={s => {
                      this.setState({
                        show_location_type_toggle: s
                      });
                    }}
                    popover_width={400}
                    popover_height={location_type === "county" ? 450 : null}
                    popoverSheetTop={"85%"}
                    popoverPlacement={"bottom"}
                    popover_title={"Select Location Type"}
                    includeCloseButton={true}
                    renderComponent={options => {
                      return (
                        <InlineButton
                          tooltip={"Select Location Type"}
                          tooltipPlacement={"top"}
                          noPress={true}
                          style={{
                            overflow: "hidden",
                            maxWidth: 200,
                            marginRight: 0
                          }}
                          innerStyle={{ justifyContent: "flex-start" }}
                          pressedIn={
                            options.hovering ||
                            options.pressedIn ||
                            this.state.show_location_type_toggle
                          }
                        >
                          {location_type == "zip"
                            ? !!zip
                              ? "Zip: " + zip
                              : "Using Zip"
                            : location_type == "city"
                            ? !!city
                              ? "City: " + city
                              : "City"
                            : location_type == "county"
                            ? !!fips_name
                              ? "County: " + fips_name
                              : "County"
                            : ""}
                        </InlineButton>
                      );
                    }}
                    renderMenu={() => {
                      return (
                        <Wrapper
                          style={
                            this.props.device == "desktop"
                              ? {
                                  alignSelf: "stretch",
                                  flex: 1,
                                  overflow: "hidden"
                                }
                              : {
                                  alignSelf: "stretch"
                                }
                          }
                        >
                          <Wrapper
                            style={{
                              margin: 10,
                              marginBottom: 0,
                              alignSelf: "stretch",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <InlineTabs
                              selected_tab={location_type}
                              tabs={[
                                {
                                  onPress: () => {
                                    this.props.updateLocationTab({
                                      location_type: "zip"
                                    });
                                  },
                                  title: "Use Zip Code",
                                  slug: "zip"
                                },
                                {
                                  onPress: () => {
                                    this.props.updateLocationTab({
                                      location_type: "city"
                                    });
                                  },
                                  title: "Use City",
                                  slug: "city"
                                },
                                {
                                  onPress: () => {
                                    this.props.updateLocationTab({
                                      location_type: "county"
                                    });
                                  },
                                  title: "Use County",
                                  slug: "county"
                                }
                              ]}
                            />
                          </Wrapper>

                          {location_type == "county" ? (
                            <>
                              <>
                                <SearchBar
                                  style={{ margin: 10 }}
                                  title="Search Counties"
                                  value={this.state.county_search}
                                  onChange={value =>
                                    this.setState({ county_search: value })
                                  }
                                />
                              </>
                              <List
                                rowNumber={1}
                                style={{ alignSelf: "stretch" }}
                                items={this.filterOptions(this.state?.fips)}
                                infiniteScroll={true}
                                itemStructure={({ item, index }) => {
                                  return (
                                    <SelectItem
                                      key={"fips_" + index}
                                      select_type={"radio"}
                                      selected={this.props.fips == item.value}
                                      onPress={() => {
                                        this.props.updateLocationTab({
                                          fips: item.value,
                                          fips_name: item.label
                                        });
                                      }}
                                      description={this.props.value}
                                    >
                                      {item?.label}
                                    </SelectItem>
                                  );
                                }}
                                canRefresh={false}
                              />
                            </>
                          ) : (
                            <Wrapper
                              style={{ flex: 1, marginTop: !isMobile ? 0 : 20 }}
                            >
                              <InputBox
                                input_ref={this._location_field}
                                autoFocus={false}
                                name="location_field"
                                disabled={false}
                                returnKeyType="done"
                                style={{
                                  margin: 5,
                                  borderRadius: 5
                                }}
                                placeholder={
                                  location_type == "zip"
                                    ? "Zip Codes (seperate by comma)"
                                    : location_type == "county"
                                    ? "County"
                                    : "City"
                                }
                                onFocus={() => {}}
                                onChange={value => {
                                  if (location_type == "zip") {
                                    this.props.updateLocationTab({
                                      zip: value
                                    });
                                  } else {
                                    this.props.updateLocationTab({
                                      city: value
                                    });
                                  }
                                }}
                                blurOnSubmit={true}
                                value={
                                  location_type == "zip"
                                    ? !!zip
                                      ? zip
                                      : ""
                                    : !!city
                                    ? city
                                    : ""
                                }
                                input_type="text"
                              />
                            </Wrapper>
                          )}
                        </Wrapper>
                      );
                    }}
                    menu_items={null}
                  />
                ) : null}
                <InlineButton
                  onPress={() => {
                    this.setState({
                      drawing: !drawing,
                      drawing_created: false
                    });
                    this.props.updateLocationTab({
                      location_type: location_type == "draw" ? "zip" : "draw",
                      drawing_coordinates: []
                    });
                  }}
                  style={{ marginLeft: 0 }}
                  primary={!this.state.drawing ? true : false}
                  icon={
                    drawing
                      ? ""
                      : this.props.device == "desktop"
                      ? "draw"
                      : "edit"
                  }
                  disabled={
                    !this.state.drawing &&
                    (this.state.updated_zoom > this.state.maxZoom ||
                      this.state.updated_zoom < this.state.minZoom)
                  }
                  tooltipPlacement="top"
                  tooltip={
                    this.state.updated_zoom < this.state.minZoom
                      ? "Zoom in to activate drawing tool"
                      : ""
                  }
                >
                  {this.state.drawing
                    ? this.props.drawing_coordinates.length == 0
                      ? this.props.isMobile || this.props.desktopMobile
                        ? "Cancel"
                        : "Clear Drawing"
                      : this.props.isMobile || this.props.desktopMobile
                      ? "Clear"
                      : "Clear Drawing"
                    : this.props.isMobile || this.props.desktopMobile
                    ? "Draw"
                    : "Start Drawing"}
                </InlineButton>
              </Row>
            </>
          ) : null}
        </Row>

        {!this.state.search_focused || !isMobile ? (
          <>
            {!isMobile && this.props.filters.data.length > 0 ? (
              <UpsellButton
                type="plan"
                slug="lists"
                tier={0}
                contentful_slug="unlock_list_builder"
                force_lock={
                  source_of_truth?.subscription?.metadata
                    ?.leads_added_from_list_builder != "unlimited" &&
                  new_list_count >
                    source_of_truth?.subscription?.metadata
                      ?.leads_added_from_list_builder &&
                  !this.props.demo
                }
                force_unlock={this.props.demo}
                meta_slug="leads_added_from_list_builder"
                onPress={() => {
                  if (this.props.demo) {
                    openUrl("https://app.dealmachine.com/sign-up");
                  }
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <Wrapper
                      style={{
                        backgroundColor: colors.orange_color_muted
                      }}
                    >
                      <Row>
                        <Wrapper style={{ flex: 1, padding: 25 }}>
                          <Copy>{this.props.list_name}</Copy>
                          {this.props.count_loading ? (
                            <Spin
                              text="Loading estimated count"
                              color={colors.actionable_text_color}
                              style={{ fontSize: 12 }}
                            />
                          ) : (
                            <ListBuilderCountLimits {...this.props} />
                          )}
                        </Wrapper>
                        <GhostButton
                          noPress={true}
                          pressedIn={hovering}
                          primary={true}
                          icon="lock"
                        >
                          Build List
                        </GhostButton>
                      </Row>
                    </Wrapper>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <>
                      <ListName
                        {...this.props}
                        style={{ backgroundColor: colors.orange_color_muted }}
                        renderBottom={() => {
                          return <ListBuilderCountLimits {...this.props} />;
                        }}
                        renderTop={
                          this.props.desktopMobile
                            ? () => {
                                return (
                                  <AttentionBox
                                    style={{
                                      padding: 5,
                                      margin: 0,
                                      marginRight: 15
                                    }}
                                    row={true}
                                    primary={this.props.filters.data.length > 0}
                                    backgroundColor={colors.orange_color_muted}
                                    icon={"check"}
                                    title={"Give your list a name and build it"}
                                    renderDescription={() => {
                                      return <>{this.renderEstimatedCount()}</>;
                                    }}
                                  />
                                );
                              }
                            : null
                        }
                        renderLeft={
                          !this.props.desktopMobile
                            ? () => {
                                return (
                                  <AttentionBox
                                    style={{
                                      maxWidth: 300,
                                      padding: 5,
                                      margin: 0,
                                      marginRight: 15
                                    }}
                                    row={true}
                                    primary={this.props.filters.data.length > 0}
                                    backgroundColor={colors.orange_color_muted}
                                    icon={"check"}
                                    title={"Give your list a name and build it"}
                                    renderDescription={() => {
                                      return <>{this.renderEstimatedCount()}</>;
                                    }}
                                  />
                                );
                              }
                            : null
                        }
                      />
                    </>
                  );
                }}
              />
            ) : null}

            <Wrapper
              style={{ flex: 1, alignSelf: "stretch", position: "relative" }}
            >
              <NativeListBuilderMap
                latitude={this.state.latitude}
                longitude={this.state.longitude}
                zoom={this.state.zoom}
                minZoom={this.state.minZoom}
                maxZoom={this.state.maxZoom}
                pitch={this.state.pitch}
                heading={this.state.heading}
                drawing={this.state.drawing}
                onDraw={this.onDraw}
                drawingCreated={this.drawingCreated}
                drawing_coordinates={this.props.drawing_coordinates}
                toggleDrawing={this.toggleDrawing}
                updateCenter={this.updateCenter}
                updateBounds={this.updateBounds}
                updateZoomProperties={this.updateZoomProperties}
                lists_on_map={this.state.lists_on_map}
                show_lists_on_map={this.state.show_lists_on_map}
                leads_on_map={this.state.leads_on_map}
                show_leads_on_map={this.state.show_leads_on_map}
                highlights_on_map={this.state.highlights_on_map}
                show_highlights_on_map={this.state.show_highlights_on_map}
                map_type={this.state.map_type}
              />
              {location_type == "zip" &&
              !drawing &&
              this.props.drawing_coordinates.length == 0 &&
              !this.state.hide_step_2_copy ? (
                <Button
                  onPress={() => {
                    this.setState({
                      hide_step_2_copy: true
                    });
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: this.props.filters.data.length == 0 ? 0.85 : 1
                  }}
                >
                  <AttentionBox
                    style={{ maxWidth: 400, margin: isMobile ? 15 : 5 }}
                    primary={this.props.filters.data.length > 0}
                    backgroundColor={
                      this.props.filters.data.length == 0
                        ? colors.card_color
                        : null
                    }
                    icon={"near-me"}
                    includeCloseButton={true}
                    onClose={() => {
                      this.setState({
                        hide_step_2_copy: true
                      });
                    }}
                    title={"Identify a territory to start using List Builder"}
                    description={
                      "Enter a zip code, city, or use the drawing tool to create a customized territory shape."
                    }
                  />
                </Button>
              ) : null}

              <Wrapper style={{ position: "absolute", top: 0, left: 0 }}>
                <PopoverMenu
                  show={this.state.show_options}
                  no_swipe={true}
                  no_cancel={true}
                  onShow={s => {
                    this.setState({
                      show_options: s,
                      hide_step_2_copy: true
                    });
                  }}
                  popover_width={350}
                  //popover_height={400}
                  popoverSheetTop={450}
                  popoverPlacement={"bottom"}
                  popover_title={"Map Options"}
                  includeCloseButton={true}
                  renderComponent={options => {
                    return (
                      <CircleButton
                        tooltip={"Map Options"}
                        tooltipPlacement={"top"}
                        icon={"map"}
                        noPress={true}
                        pressedIn={
                          options.hovering ||
                          options.pressedIn ||
                          this.state.show_options
                        }
                      />
                    );
                  }}
                  renderMenu={() => {
                    return (
                      <Wrapper style={{ alignSelf: "stretch" }}>
                        <Wrapper
                          style={{
                            margin: 10,
                            marginBottom: 0,
                            alignSelf: "stretch",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          <InlineTabs
                            selected_tab={this.state.map_type}
                            tabs={[
                              {
                                onPress: () => {
                                  this.setState({
                                    map_type: "standard"
                                  });
                                },
                                title: "Standard",
                                icon: "map",
                                slug: "standard"
                              },
                              {
                                onPress: () => {
                                  this.setState({
                                    map_type: "satellite"
                                  });
                                },
                                title: "Satellite",
                                icon: "terrain",
                                slug: "satellite"
                              }
                            ]}
                          />
                        </Wrapper>
                        {!this.props.demo ? (
                          <>
                            <SelectItem
                              select_type="toggle"
                              selected={this.state.show_lists_on_map}
                              onPress={() => {
                                this.setState({
                                  show_lists_on_map:
                                    !this.state.show_lists_on_map
                                });
                              }}
                            >
                              Show previous built lists on the map
                            </SelectItem>
                            <SelectItem
                              select_type="toggle"
                              selected={this.state.show_leads_on_map}
                              onPress={() => {
                                this.setState({
                                  show_leads_on_map:
                                    !this.state.show_leads_on_map
                                });
                              }}
                            >
                              Show leads on the map
                            </SelectItem>
                          </>
                        ) : null}

                        <SelectItem
                          select_type="toggle"
                          selected={this.state.show_highlights_on_map}
                          onPress={() => {
                            this.setState({
                              show_highlights_on_map:
                                !this.state.show_highlights_on_map
                            });
                          }}
                          description={
                            "These are determined by your selected filters."
                          }
                        >
                          Show highlights on map
                        </SelectItem>
                      </Wrapper>
                    );
                  }}
                  menu_items={null}
                />
              </Wrapper>
              <Wrapper
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0
                }}
              >
                <CircleButton
                  loading={this.state.current_location_loading}
                  //textColor={this.props.colors.text_color}
                  icon={"near-me"}
                  onPress={() => {
                    this.props.askLocationPermissionsThenContinue({
                      onSuccess: () => {
                        getDeviceLocation({
                          onLoading: () => {
                            this.setState({
                              current_location_loading: true
                            });
                          },
                          onError: () => {
                            this.setState({
                              current_location_loading: false
                            });
                          },
                          onSuccess: results => {
                            this.setState({
                              current_location_loading: false
                            });
                            this.updateLatLng(results);
                          }
                        });
                      }
                    });
                  }}
                />
                <Wrapper
                  style={{
                    backgroundColor: this.props.colors.popover_color,
                    borderRadius: 30,
                    borderColor: this.props.colors.border_color,
                    borderWidth: 1,
                    borderStyle: "solid",
                    margin: 5
                  }}
                >
                  <IconButton
                    style={{
                      width: 36,
                      height: 36
                    }}
                    onPress={() => {
                      this.setState({
                        zoom: parseInt(this.state.zoom) + 1
                      });
                    }}
                    size={22}
                    icon_color={this.props.colors.actionable_text_color}
                    icon="add"
                  />
                  <IconButton
                    style={{
                      width: 36,
                      height: 36
                    }}
                    onPress={() => {
                      this.setState({
                        zoom: parseInt(this.state.zoom) - 1
                      });
                    }}
                    size={22}
                    icon_color={this.props.colors.actionable_text_color}
                    icon="remove"
                  />

                  {this.state.leads_on_map_loading ||
                  this.state.lists_on_map_loading ||
                  this.state.highlights_on_map_loading ? (
                    <Wrapper
                      style={{
                        width: 46,
                        height: 46,
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Spin
                        color={this.props.colors.actionable_text_color}
                        size="small"
                      />
                    </Wrapper>
                  ) : (
                    <IconButton
                      style={{
                        width: 36,
                        height: 36
                      }}
                      onPress={() => {
                        this.updateBounds(this.state.bounds, true);
                      }}
                      size={22}
                      icon_color={this.props.colors.actionable_text_color}
                      icon="refresh"
                    />
                  )}
                </Wrapper>
              </Wrapper>
            </Wrapper>

            {isMobile ? (
              <BottomNavBar>
                <InlineButton
                  button_type={"full"}
                  disabled={
                    !this.props.checkIfCanBuild() || estimated_count == 0
                  }
                  primary={this.props.checkIfCanBuild() && estimated_count > 0}
                  style={{
                    backgroundColor:
                      this.props.checkIfCanBuild() && estimated_count > 0
                        ? colors.orange_color_muted
                        : "transparent"
                  }}
                  onPress={() => {
                    this.props.nextStep("complete");
                  }}
                  icon_right="arrow-forward"
                  description={this.renderEstimatedText()}
                >
                  Next: Name your list
                </InlineButton>
              </BottomNavBar>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default ListBuilderMap;
