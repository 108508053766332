import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Card,
  Container,
  Copy,
  Row,
  Wrapper,
  Scroll,
  Bold
} from "app/NativeComponents/common";
import {
  Carousel,
  NewHeader,
  SelectItem,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  popSidePanel,
  pushSidePanel,
  determineMainOwnerInfo
} from "app/NativeActions";

import OwnerPortfolio from "../OwnerPortfolio";

class RealEstatePortfolio extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      property: panel_data?.property || null,
      expanded: panel_data?.expanded || false
    };
    this._scroll_view = React.createRef();
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  scrollToSection(section) {
    const { device } = this.props;

    if (
      device === "desktop" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.id
    ) {
      const elmnt = document.getElementById(section?.id);
      let top = this.state.expanded
        ? elmnt.offsetTop - 85
        : elmnt.offsetTop - 85;

      this._scroll_view.current.scroll({
        top,
        behavior: "smooth"
      });
    } else if (
      device === "mobile" &&
      this._scroll_view &&
      this._scroll_view.current &&
      section?.offset
    ) {
      let y = section.offset - 15;
      this._scroll_view.current.scrollTo({
        x: 0,
        y: y,
        animated: true
      });
    }
  }

  render() {
    const { isMobile, device, colors } = this.props;
    const { property } = this.state;
    const owner = determineMainOwnerInfo(property);

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={"View Real Estate Portfolio"}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <Scroll style={{ flex: 1 }} scroll_ref={this._scroll_view}>
          <SelectItem
            noPress={true}
            //subtext={owner?.owner_status_info?.text}
            selected={true}
            select_type="none"
            label={"Mailing address:"}
            description={owner?.owner_address_full}
          >
            {owner.owner_name}
          </SelectItem>
          <OwnerPortfolio
            owner_hash={property?.owner_hash}
            scrollToSection={this.scrollToSection}
          />
        </Scroll>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel
})(RealEstatePortfolio);
