import React, { PureComponent } from "react";
import { Wrapper, Copy, Bold } from "app/NativeComponents/common";
import { getListStackColor } from "app/NativeActions";
import ShowStackedListsButton from "app/DealMachineCore/reuseable/ShowStackedListsButton";

class ListStackColumnItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      number_of_lists: props.property?.deal?.lists?.length,
      number_of_stacked_lists: props.property?.deal?.stacked_lists?.length
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.property?.deal?.lists?.length !==
      this.props.property?.deal?.lists?.length
    ) {
      this.setState({
        number_of_lists: this.props.property?.deal?.lists?.length || 0
      });
    }
  }

  render() {
    const { property, colors, section, row_height } = this.props;
    const { loading, number_of_lists, number_of_stacked_lists } = this.state;

    return (
      <ShowStackedListsButton
        popoverPlacement="right"
        renderComponent={({ pressedIn }) => {
          return (
            <Wrapper
              style={{
                padding: 10,
                paddingRight: 0,
                paddingLeft: 0,
                width: 60,
                height: row_height,
                position: "relative"
              }}
            >
              <Wrapper
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Wrapper
                  /*
                  tooltipPlacement="top"
                  tooltip={
                    number_of_lists > 1
                      ? "This lead is in " + number_of_lists + " lists"
                      : number_of_lists == 1
                      ? "This lead is in 1 list"
                      : "This lead is not in a list"
                  }
                  */
                  style={{
                    backgroundColor: getListStackColor(number_of_stacked_lists),
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Copy style={{ textAlign: "center" }}>
                    <Bold>{number_of_stacked_lists}</Bold>
                  </Copy>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          );
        }}
        property={property}
      />
    );
  }
}

export default ListStackColumnItem;
