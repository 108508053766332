import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "app/NativeComponents/common";
import TagBody from "./TagBody";

class EditList extends Component {
  render() {
    const { device, colors } = this.props;

    return (
      <Container>
        <TagBody />
      </Container>
    );
  }
}

const mapStateToProps = ({ settings, native }) => {
  const { colors } = settings;
  const { platform, device, isMobile } = native;

  return {
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {})(EditList);
