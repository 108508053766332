import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";
import { CircleButton } from "app/NativeComponents/snippets";

class DeleteButton extends Component {
  render() {
    if (
      this.props.user?.team_clearance_level == 0 &&
      this.props.property.deal.archived == 0
    ) {
      return (
        <CircleButton
          style={{ marginLeft: 0 }}
          tooltip={"Send lead to trash"}
          tooltipPlacement={"top"}
          icon="delete-forever"
          onPress={() => {
            this.props.setModal({
              title: "Send lead to trash?",
              icon: "delete",
              submit: "Send Lead To Trash",
              buttonType: "destroy",
              require_complete_function: true,
              onPress: () => {
                this.props.updateLead({
                  token: this.props.token,
                  deal_ids: this.props.property.deal.id,
                  type: "edit_status_for_lead",
                  deal_status_slug: "in_trash",
                  onSuccess: () => {
                    this.props.popSidePanel();
                  }
                });
                this.props.toggleModal({ show: false, type: null });
              },
              cancel: "Cancel",
              onCancel: () => {}
            });
            this.props.toggleModal({ show: true, type: "normal" });
          }}
        />
      );
    } else {
      return <Wrapper />;
    }
  }
}

export default DeleteButton;
