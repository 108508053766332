import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Input,
  MultiLineInput,
  KeyboardView
} from "app/NativeComponents/common";
import {
  PillButton,
  Select,
  SelectMultiple,
  Tabs,
  SmallHeader,
  BottomNavBar,
  GhostButton,
  SpinWrapper,
  SelectItem,
  InlineTabs,
  SelectBox
} from "app/NativeComponents/snippets";
import { getTeamMembers } from "app/NativeActions";

class AssignLeadStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assign_to_user_focus: false,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            assign_to_type: "user",
            assign_to_user: 0,
            assign_to_user_name: "",
            assign_to_users: [],
            notify_team_member: false
          }
    };
  }

  componentDidMount() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "replace",
      type: "accepted_members_and_dealfinders",
      begin: 0
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (
        (this.state.data.assign_to_user > 0 &&
          this.state.data.assign_to_type == "user") ||
        (this.state.data.assign_to_type == "round_robin" &&
          this.state.data.assign_to_users.length > 1)
      ) {
        return true;
      }
    }

    return false;
  }

  renderUserName(user_id) {
    const { team_members } = this.props;
    for (let i = 0; i < team_members.length; i++) {
      if (team_members[i].value == user_id) {
        return team_members[i].label;
      }
    }

    return "None selected";
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      team_members,
      team_members_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Assign Lead"
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {team_members_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <InlineTabs
                  tab_type="underline"
                  selected_tab={data.assign_to_type}
                  tabs={[
                    {
                      slug: "user",
                      title: "Select A Team Member",
                      onPress: () => {
                        this.setState({
                          data: {
                            ...data,
                            assign_to_type: "user"
                          }
                        });
                      }
                    },

                    {
                      slug: "round_robin",
                      title: "Round Robin",
                      onPress: () => {
                        this.setState({
                          data: {
                            ...data,
                            assign_to_type: "round_robin"
                          }
                        });
                      }
                    }
                  ]}
                />

                {data.assign_to_type == "user" ? (
                  <SelectBox
                    items={team_members}
                    value={data.assign_to_user}
                    placeholder="Select team member"
                    show={this.state.assign_to_user_focus}
                    onFocus={() => {
                      this.setState({ assign_to_user_focus: true });
                    }}
                    onBlur={() => {
                      this.setState({ assign_to_user_focus: false });
                    }}
                    onSelect={value => {
                      this.setState({
                        data: {
                          ...data,
                          assign_to_user: value,
                          assign_to_user_name: this.renderUserName(value)
                        }
                      });
                    }}
                    onSubmitEditing={() => {}}
                  />
                ) : (
                  <SelectMultiple
                    options={team_members}
                    selected_options={data.assign_to_users}
                    placeholder={"Select team members"}
                    search_title="Search team members"
                    icon_right={"keyboard-arrow-down"}
                    onSelectedOptionsChange={options => {
                      this.setState({
                        data: {
                          ...data,
                          assign_to_users: options
                        }
                      });
                    }}
                  />
                )}

                <SelectItem
                  selected={data.notify_team_member}
                  onPress={() => {
                    this.setState({
                      data: {
                        ...data,
                        notify_team_member: !data.notify_team_member
                      }
                    });
                  }}
                >
                  Send in-app notification to assigned team member
                </SelectItem>
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              primary={true}
              button_type={"full"}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ team }) => {
  const { team_members, team_members_loading } = team;

  return {
    team_members,
    team_members_loading
  };
};

export default connect(mapStateToProps, { getTeamMembers })(AssignLeadStep);
