import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Title,
  Wrapper,
  Copy,
  ExternalImage,
  InternalImage,
  Video
} from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton,
  InlineButton,
  AttentionBox
} from "app/NativeComponents/snippets";
import {
  popSidePanel,
  manageSubscription,
  pushSidePanel,
  loadContentfulAssetById
} from "app/NativeActions";

class BypassTrial extends Component {
  state = {
    loading: false,
    assetUrl: ""
  };

  async componentDidMount() {
    try {
      const asset = await loadContentfulAssetById("y67dmL20Af2C7ONcH8Urv");
      this.setState({ assetUrl: asset?.fields?.file?.url });
    } catch (e) {}
  }
  bypassTrial = () => {
    this.props.manageSubscription({
      token: this.props.token,
      type: "end_trial",
      onLoading: () => {
        this.setState({ loading: true });
      },
      onSuccess: () => {
        this.setState({ loading: false });
        this.props.popSidePanel();
      },
      onError: () => {
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const { panel_data, colors } = this.props;
    const {
      title = "Pay now and start your subscription to unlock the power of real estate.",
      description
    } = { ...panel_data };

    return (
      <Container>
        <NewHeader
          title="Start Your Subscription"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.props.popSidePanel();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          {this.state.assetUrl ? (
            <Wrapper
              style={{
                padding: 25,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Video
                video={this.state.assetUrl}
                height={225}
                width={400}
                autoPlay={true}
                controls={false}
                muted={true}
                loop={true}
              />

              {/* <Title style={{ padding: 10 }}>{title}</Title>
          <Copy style={{ padding: 10 }}>{description}</Copy> */}
            </Wrapper>
          ) : null}
          <Wrapper>
            <AttentionBox
              primary={true}
              title={title}
              description={description}
            />
          </Wrapper>
          <Wrapper
            style={{
              padding: 20,
              justifyContent: "center",
              alignItems: "stretch"
            }}
          >
            {this.props.user?.team_clearance_level > 0 &&
            this.props.device == "mobile" &&
            this.props.platform == "ios" ? (
                <Wrapper style={{ padding: 25, alignSelf: "bottom" }}>
                  <Copy>
                    To make changes to your subscription please log in to{" "}
                    dealmachine.com
                  </Copy>
                </Wrapper>
            ) : <GhostButton
                style={{ backgroundColor: colors.orange_color }}
                primary={true}
                button_type="full"
                onPress={() => {
                  this.bypassTrial();
                }}
                loading={this.state.loading}
            >
              End Trial and Start Subscription
            </GhostButton>}
          </Wrapper>
        </KeyboardView>
      </Container>
    );
  }
}
const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device, platform } = native;
  const { colors } = settings;

  return { token, user, device, platform, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  manageSubscription,
  pushSidePanel
})(BypassTrial);
