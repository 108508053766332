export const GET_LIST_PROPERTIES = "get_list_properties";
export const REFRESH_LIST_PROPERTIES = "refresh_list_properties";
export const GET_LIST_PROPERTIES_SUCCESS = "get_list_properties_success";
export const GET_LIST_PROPERTIES_FAIL = "get_list_properties_fail";
export const LOAD_MORE_PROPERTIES = "load_more_properties";
export const LOAD_MORE_PROPERTIES_SUCCESS = "load_more_properties_success";

export const GET_PROPERTY_COUNT = "get_property_count";
export const GET_PROPERTY_COUNT_FAIL = "get_property_count_fail";
export const GET_PROPERTY_COUNT_SUCCESS = "get_property_count_success";

export const GET_PROPERTY = "get_property";
export const SOFT_REFRESH_PROPERTY = "soft_refresh_property";
export const GET_PROPERTY_FAIL = "get_property";
export const GET_PROPERTY_SUCCESS = "get_property_success";

export const UPDATE_LEAD = "update_lead";
export const UPDATE_LEAD_FAIL = "update_lead_fail";
export const UPDATE_LEAD_SUCCESS = "update_lead_success";

export const SET_PROPERTIES_PAGE = "set_properties_page";
export const SET_PROPERTIES_LIMIT = "set_properties_limit";

export const TRIGGER_LEADS_RELOAD = "trigger_leads_reload";
export const TRIGGER_LEADS_COUNT_RELOAD = "trigger_leads_count_reload";

export const TOGGLE_LEAD_IMAGES = "toggle_lead_images";
export const RELOAD_LEADS_SUCCESS = "reload_leads_success";
export const RELOAD_LEADS = "reload_leads";

export const GET_BULK_EDITS_SUCCESS = "get_bulk_edits_success";
export const UPDATE_BULK_EDIT = "update_bulk_edit";

export const GET_MAIL_FAILED_DEALS = "get_mail_failed_deals";
export const GET_MAIL_FAILED_DEALS_FAIL = "get_mail_failed_deals_fail";
export const GET_MAIL_FAILED_DEALS_SUCCESS = "get_mail_failed_deals_success";
export const LOAD_MORE_MAIL_FAILED_DEALS = "load_more_mail_failed_deals";
export const LOAD_MORE_MAIL_FAILED_DEALS_SUCCESS =
  "load_more_mail_failed_deals_success";
export const REFRESH_MAIL_FAILED_DEALS = "refresh_mail_failed_deals";

export const SET_SKIP_TRACE_PAGE = "set_skip_trace_page";
export const TOGGLE_EXIT_STRATEGY = "toggle_exit_strategy";

export const GET_PROPERTY_IMAGES = "get_property_images";
export const GET_PROPERTY_IMAGES_FAIL = "get_property_images_fail";
export const GET_PROPERTY_IMAGES_SUCCESS = "get_property_images_success";

export const UPDATE_LEAD_PHOTO_START = "update_lead_photo_start";
export const UPDATE_LEAD_PHOTO_PROGRESS = "update_lead_photo_progress";
export const UPDATE_LEAD_PHOTO_COMPLETE = "update_lead_photo_complete";
export const UPDATE_LEAD_PHOTO_ERROR = "update_lead_photo_error";

export const UPDATE_PROPERTY_IMAGE = "update_property_image";
export const UPDATE_PROPERTY_IMAGE_SUCCESS = "update_property_image_success";
export const UPDATE_PROPERTY_IMAGE_FAIL = "update_property_image_fail";
export const SET_PHOTO_GALLERY = "set_photo_gallery";
export const TOGGLE_PHOTO_GALLERY = "toggle_photo_gallery";
export const TOGGLE_LEAD_PHOTO_MANAGER = "toggle_lead_photo_manager";

export const SET_EXPORT_MODAL = "set_export_modal";
export const REMOVE_LEADS_SUCCESS = "remove_leads_success";
export const UPDATE_COMPS_SETTINGS = "update_comps_settings";
export const SET_UPDATED_LEADS = "set_updated_leads";
export const SET_UPDATED_OWNER = "set_updated_owner";
export const UPDATE_MANUAL_PROPERTY_STATE = "update_manual_property_state";
export const RESET_MANUAL_PROPERTY_STATE = "reset_manual_property_state";
