import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Card,
  KeyboardView,
  Copy,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  SearchBar,
  List,
  SelectItem,
  SpinWrapper,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  appRedirect,
  setTagModal,
  popSidePanel,
  getTags,
  dismissMobileKeyboard
} from "app/NativeActions";

class Body extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selected_tags: props.tag_modal ? props.tag_modal.selected_tags : [],
      selected_leads: props.tag_modal ? props.tag_modal.selected_leads : [],
      search: ""
    };
  }

  componentDidMount() {
    this.getData("refresh");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tag_modal && prevProps.tag_modal) {
      if (
        prevProps.tag_modal.selected_tags &&
        this.props.tag_modal.selected_tags &&
        this.props.tag_modal
      ) {
        if (
          prevProps.tag_modal.selected_tags.length !==
          this.props.tag_modal.selected_tags.length
        ) {
          if (this.props.tag_modal.fieldsUpdated) {
            this.props.tag_modal.fieldsUpdated(
              this.props.tag_modal.selected_tags
            );
          }
        }
      }
    }

    if (this.props.tag_modal && this.props.tag_modal !== prevProps.tag_modal) {
      this.setState({
        selected_tags: this.props.tag_modal.selected_tags,
        selected_leads: this.props.tag_modal.selected_leads
      });
    }

    if (
      this.props.show_tag_modal &&
      this.props.show_tag_modal !== prevProps.show_tag_modal
    ) {
      this.getData("refresh");
    }
  }

  checkTagItem(tag_item) {
    let found_tag = false;
    for (let i = 0; i < this.props.tag_modal.selected_tags.length; i++) {
      if (this.props.tag_modal.selected_tags[i].id === tag_item.id) {
        found_tag = true;
      }
    }
    if (found_tag) {
      this.props.setTagModal({
        ...this.props.tag_modal,
        selected_tags: this.props.tag_modal.selected_tags.filter(
          ({ id }) => id !== tag_item.id
        )
      });
    } else {
      this.props.setTagModal({
        ...this.props.tag_modal,
        selected_tags: [...this.props.tag_modal.selected_tags, tag_item]
      });
    }
  }

  isChecked(item) {
    for (let i = 0; i < this.state.selected_tags.length; i++) {
      if (this.state.selected_tags[i].id === item.id) {
        return true;
      }
    }

    return false;
  }

  confirmUpdate() {
    dismissMobileKeyboard();

    if (this.props.tag_modal.modalAction) {
      this.props.tag_modal.modalAction({
        selected_leads: this.state.selected_leads,
        selected_tags: this.props.tag_modal.selected_tags,
        onLoading: () => {
          this.setState({ loading: true });
        },
        onError: () => {
          this.setState({ loading: false });
        },
        onSuccess: () => {
          this.setState({ loading: false });
          this.props.popSidePanel();
        }
      });
    }
  }

  clearAll() {
    this.props.setTagModal({
      ...this.props.tag_modal,
      selected_tags: []
    });
  }

  getData(load_type = "load") {
    if (!this.props.tags_loading) {
      this.props.getTags(this.props.token);
    }
  }

  handleBack() {
    dismissMobileKeyboard();

    if (this.props.tag_modal) {
      if (this.props.tag_modal.cancelAction) {
        this.props.tag_modal.cancelAction();
      }
    }
    this.props.popSidePanel();
  }

  render() {
    if (this.props.tag_modal) {
      return (
        <>
          <NewHeader
            title={this.props.tag_modal.title}
            subtitle={this.props.tag_modal.description}
            leftButton={{
              icon: this.props.device === "desktop" ? "close" : "arrow-back",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
          {this.state.selected_tags.length > 0 ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              primary={true}
              icon="style"
              label={
                this.state.selected_tags.length == 1
                  ? "1 Tag Selected"
                  : this.state.selected_tags.length + " Tags Selected"
              }
              loading={this.state.loading}
              confirmButton={"Confirm Selection"}
              onPress={() => {
                this.confirmUpdate();
              }}
            />
          ) : null}
          <SearchBar
            title="Search Tags"
            value={this.state.search}
            onChange={value => {
              this.setState({
                search: value
              });
            }}
            style={{ margin: 10 }}
          />

          <KeyboardView style={{ flex: 1 }} keyboardShouldPersistTaps="always">
            {this.props.tags_loading ? (
              <SpinWrapper text="Loading Tags..." />
            ) : (
              <>
                <List
                  style={{ alignSelf: "stretch" }}
                  items={this.props.all_tags}
                  itemStructure={({ item, index }) => {
                    if (
                      !!item.title &&
                      item.title
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase())
                    ) {
                      return (
                        <SelectItem
                          key={"option_" + index}
                          select_type="check"
                          selected={this.isChecked(item)}
                          onPress={() => {
                            this.checkTagItem(item);
                          }}
                        >
                          {item.title}
                        </SelectItem>
                      );
                    }
                    return null;
                  }}
                />
              </>
            )}
          </KeyboardView>
        </>
      );
    }
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  modal,
  filter,
  property_tags
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { is_loading } = modal;
  const { show_tag_modal, tag_modal } = filter;
  const { all_tags, tags_loading } = property_tags;

  return {
    token,
    user,
    isMobile,
    platform,
    device,
    colors,
    show_tag_modal,
    tag_modal,
    all_tags,
    tags_loading
  };
};

export default connect(mapStateToProps, {
  appRedirect,
  setTagModal,
  popSidePanel,
  getTags
})(Body);
