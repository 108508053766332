import React, { Component } from "react";

import { Card, Row } from "app/NativeComponents/common";
import { InformationItem, InlineButton } from "app/NativeComponents/snippets";

class ProfessionalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      more_info: false
    };
  }

  render() {
    const { person } = this.props;

    return (
      <Card
        label={"Professional Information:"}
        id={"professional-information"}
        style={{ paddingTop: 10, marginTop: 15 }}
        onLayout={event => {
          const { x, y, width, height } = event?.nativeEvent?.layout;
          this.setState({
            offset: y,
            height: height
          });
        }}
      >
        <Row
          style={
            this.props.expanded
              ? {
                  flexDirection: "row",
                  alignItems: "stretch",
                  flexWrap: "wrap"
                }
              : { flexDirection: "column", alignItems: "stretch" }
          }
        >
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={""}
            item={person?.education_model}
            label={"Education:"}
            hiddenFromNonSubs={true}
            helper={"education"}
          />
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={""}
            item={person?.occupation_group}
            label={"Occupational group:"}
            hiddenFromNonSubs={true}
            helper={"occupation_group"}
          />
          {this.state.more_info ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.occupation_code}
                label={"Occupation:"}
                hiddenFromNonSubs={true}
                helper={"occupation_code"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.net_asset_value}
                label={"Net asset value:"}
                hiddenFromNonSubs={true}
                helper={"net_asset_value"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.est_household_income}
                label={"Est. household income:"}
                hiddenFromNonSubs={true}
                helper={"est_household_income"}
              />
            </>
          ) : null}
        </Row>

        {this.state.more_info ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: false
                },
                () => {
                  if (this.props.device === "desktop") {
                    this.props.scrollToSection({
                      id: "professional-information",
                      offset: this.state.offset
                    });
                  } else {
                    this._interval = setTimeout(() => {
                      this.props.scrollToSection({
                        id: "professional-information",
                        offset: this.state.offset
                      });
                    }, 50);
                  }
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-up"}
          >
            Less Info
          </InlineButton>
        ) : (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: true
                },
                () => {
                  this.props.scrollToSection({
                    id: "professional-information",
                    offset: this.state.offset
                  });
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-down"}
          >
            More Info
          </InlineButton>
        )}
      </Card>
    );
  }
}

export default ProfessionalInformation;
