import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  IconButton,
  SelectItem,
  CircleButton
} from "app/NativeComponents/snippets";
import {
  callPhoneNumber,
  textPhoneNumber,
  toTitleCase,
  copyToClipBoard
} from "app/NativeActions";
class PhoneNumberItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  renderLabel(phone_number) {
    let label_text = "";
    if (phone_number.blocked == 1) {
      label_text = "Blocked";
    } else if (phone_number.is_litigator == 1) {
      label_text = "Known Litigator";
    } else if (phone_number.do_not_call == 1) {
      label_text = "Do Not Call";
    } else if (phone_number.bad_phone == 1) {
      label_text = "Wrong Number";
    } else if (phone_number.landline == 1) {
      label_text = "Landline";
    } else if (
      phone_number.manual != 1 &&
      phone_number.checked_landline == 1 &&
      phone_number.did_litigator_scrub == 1
    ) {
      label_text = "Mobile";
    } else {
      label_text = phone_number.phone_label;
    }

    return label_text;
  }

  render() {
    const { item, colors, property } = this.props;
    let description = "";
    if (!!item.cnam) {
      if (!!description) {
        description += " - Caller Id: " + toTitleCase(item.cnam);
      } else {
        description = "Caller Id: " + toTitleCase(item.cnam);
      }
    }
    if (!!item.carrier) {
      if (!!description) {
        description += " - Carrier: " + toTitleCase(item.carrier);
      } else {
        description = "Carrier: " + toTitleCase(item.carrier);
      }
    }

    return (
      <SelectItem
        icon_color={colors.light_text_color}
        selected={false}
        onPress={() => {
          this.props.pushSidePanel({
            slug: "edit_phone_number",
            overlay: true,
            data: {
              property,
              phone_number: item,
              propertyUpdated: this.props.propertyUpdated
            }
          });
          this.props.toggleShow(false);
        }}
        subtext={item.manual == 1 ? "Manually Added" : "Skip Trace"}
        innerStyle={{ paddingLeft: 15 }}
        select_type="custom"
        renderCustomSelect={
          item.manual !== 1 && !!item?.connected_status
            ? () => {
                return (
                  <IconButton
                    icon={"check-circle"}
                    icon_color={
                      item?.connected_status == "very_likely_connected"
                        ? colors.success_color
                        : item?.connected_status == "likely_connected"
                        ? colors.active_color
                        : !!item?.connected_status
                        ? colors.grey_color
                        : colors.success_color
                    }
                    tooltip={item?.connected_status_title}
                    tooltipPlacement="top"
                    noPress={true}
                  />
                );
              }
            : () => {
                return <></>;
              }
        }
        label={this.renderLabel(item)}
        description={description}
        renderRight={() => {
          return (
            <Row>
              {this.props.device == "mobile" &&
              item.is_litigator != 1 &&
              item.do_not_call != 1 &&
              item.bad_phone != 1 ? (
                <CircleButton
                  icon="phone"
                  tooltip={"Call Phone Number"}
                  tooltipPlacement="top"
                  onPress={() => {
                    callPhoneNumber(item.phone_number.replace(/\D/g, ""));
                  }}
                />
              ) : null}

              {this.props.device == "mobile" &&
              item.is_litigator != 1 &&
              item.do_not_call != 1 &&
              item.landline != 1 &&
              item.blocked != 1 &&
              item.bad_phone != 1 ? (
                <CircleButton
                  icon="chat"
                  tooltip={"Text Phone Number"}
                  tooltipPlacement="top"
                  onPress={() => {
                    textPhoneNumber(item.phone_number.replace(/\D/g, ""));
                  }}
                />
              ) : null}
              <CircleButton
                icon="content-copy"
                tooltip={"Copy Phone Number"}
                tooltipPlacement="top"
                onPress={() => {
                  copyToClipBoard(item.phone_number);
                  this.props.showSuccess(
                    item.phone_number + " was copied to your clipboard",
                    "Copied"
                  );
                }}
              />

              <IconButton
                button_type="small"
                noPress
                icon="keyboard-arrow-right"
              />
            </Row>
          );
        }}
      >
        {item.phone_number}
      </SelectItem>
    );
  }
}

export default PhoneNumberItem;
