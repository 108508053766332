export const SET_TRACKING_EVENT = "set_tracking_event";

export const UPDATE_MARKETING_USER = "update_marketing_user";
export const UPDATE_MARKETING_PARTNER = "update_marketing_partner";
export const UPDATE_MARKETING_PARTNER_FROM_PROMO =
  "update_marketing_partner_from_promo";

export const GET_DEMO = "get_demo";
export const GET_DEMO_FAIL = "get_demo_fail";
export const GET_DEMO_SUCCESS = "get_demo_success";

export const UPDATE_FEATURE_TOGGLE = "update_feature_toggle";
export const GET_VISITOR = "get_visitor";
export const GET_VISITOR_ERROR = "get_visitor_error";
export const GET_VISITOR_SUCCESS = "get_visitor_success";

export const TOGGLE_CHURN_BUSTER = "toggle_churn_buster";
