import React, { PureComponent } from "react";

const renderOverlay = (loading, scrollEnabled) => {
  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#f7f7f7"
        }}
      >
        <i
          className={"fa fa-spin fa-spinner fa-2x"}
          style={{ color: "#2C2C2C" }}
        ></i>
      </div>
    );
  } else if (!scrollEnabled) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "transparent"
        }}
        pointerEvents={"none"}
      />
    );
  }
};

class Web extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    const {
      style,
      url,
      width,
      height,
      scrollEnabled = true,
      title = ""
    } = this.props;

    return (
      <div
        className="deal-iframe"
        style={{
          width: parseInt(width),
          height: parseInt(height),
          backgroundColor: "#f7f7f7",
          position: "relative"
        }}
      >
        <iframe
          title={title}
          style={
            !scrollEnabled
              ? {
                  overflow: "hidden"
                }
              : {}
          }
          onLoad={() => {
            this.setState({ loading: false });
          }}
          height={height}
          width={width}
          scrolling={!scrollEnabled ? "no" : "yes"}
          src={url}
        />
        {renderOverlay(this.state.loading, scrollEnabled)}
      </div>
    );
  }
}

export { Web };
