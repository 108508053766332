import React, { Component } from "react";
import { Spin } from "app/NativeComponents/common";

import FormTitle from "./FormTitle";
import FormDescription from "./FormDescription";

import FormType from "./FormType";

import { AppConfig } from "app/NativeActions";

class Form extends Component {
  render() {
    if (this.props.form) {
      return (
        <div>
          <FormTitle form={this.props.form} />
          <FormDescription form={this.props.form} />
          <FormType form={this.props.form} />
        </div>
      );
    }

    return <div />;
  }
}

export { Form };
