import {
  LOGOUT,
  GET_LEAD_CONVERSATIONS,
  GET_LEAD_CONVERSATIONS_FAIL,
  GET_LEAD_CONVERSATIONS_SUCCESS,
  UPDATE_TEAM_PHONE_NUMBER,
  UPDATE_TEAM_PHONE_NUMBER_FAIL,
  UPDATE_TEAM_PHONE_NUMBER_SUCCESS,
  CREATE_TEAM_PHONE_NUMBER_SUCCESS,
  GET_TEAM_PHONE_NUMBERS,
  GET_TEAM_PHONE_NUMBERS_FAIL,
  GET_TEAM_PHONE_NUMBERS_SUCCESS,
  GET_OWNER_INFO,
  UPDATE_OWNER_INFO,
  GET_OWNER_INFO_SUCCESS,
  UPDATE_OWNER_INFO_SUCCESS,
  SEND_TEXT_MESSAGE,
  SEND_TEXT_MESSAGE_FAIL,
  SEND_TEXT_MESSAGE_SUCCESS,
  READ_TEXT_MESSAGE,
  READ_TEXT_MESSAGE_FAIL,
  READ_TEXT_MESSAGE_SUCCESS,
  POST_MESSAGE_NOTE,
  POST_MESSAGE_NOTE_FAIL,
  POST_MESSAGE_NOTE_SUCCESS,
  SET_TO_PHONE_NUMBER,
  UPDATE_CONVERSATION_FILTER,
  GET_CONVERSATIONS,
  REFRESH_CONVERSATIONS,
  GET_CONVERSATIONS_FAIL,
  GET_CONVERSATIONS_SUCCESS,
  SET_ACTIVE_CONVERSATION,
  SET_ACTIVE_CONVERSATION_SUCCESS,
  SET_ACTIVE_CONVERSATION_FAIL,
  TOGGLE_CONVERSATION_LEAD_VIEW,
  UPDATE_CONVERSATION,
  QUICK_UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_FAIL,
  UPDATE_CONVERSATION_SUCCESS,
  CLOSE_CONVERSATION_SUCCESS,
  GET_SAVED_RESPONSES,
  GET_SAVED_RESPONSES_FAIL,
  GET_SAVED_RESPONSES_SUCCESS,
  CREATE_SAVED_RESPONSE_SUCCESS,
  UPDATE_SAVED_RESPONSE_SUCCESS,
  REMOVE_SAVED_RESPONSE_SUCCESS,
  GET_MULTISEND_NUMBERS,
  GET_MORE_MULTISEND_NUMBERS,
  GET_MULTISEND_NUMBERS_FAIL,
  GET_MULTISEND_NUMBERS_SUCCESS,
  SEND_MULTISEND_MESSAGE,
  SKIP_MULTISEND_NUMBER,
  SET_MULTISEND_FILTERS,
  UPDATE_MESSAGE_DISPLAY,
  GET_MULTISEND_NUMBERS_COUNT,
  GET_MULTISEND_NUMBERS_COUNT_FAIL,
  GET_MULTISEND_NUMBERS_COUNT_SUCCESS,
  UPDATE_LEAD_SUCCESS,
  RELOAD_LEADS_SUCCESS,
  TOGGLE_ADD_PHONE_NUMBER,
  TOGGLE_MULTISEND_MODAL
} from "app/DealMachineCore/types";

import moment from "moment";

const INITIAL_STATE = {
  conversations: [],

  conversations_loading: false,
  conversations_error: "",
  conversations_refreshing: false,
  conversations_begin: 0,
  conversations_loaded_all: false,
  conversations_limit: 25,
  conversations_filters: {
    search: "",
    filter_type: "open",
    filter_title: "Open",
    sort_by: "newest",
    sort_by_title: "Sort By: Newest"
  },
  lead_id: null,

  active_conversation: null,
  active_conversation_loading: false,

  lead_conversations: [],
  lead_conversations_loading: false,
  lead_conversations_error: "",
  lead_to_phone_numbers: [],
  set_to_phone_number: null,
  set_from_phone_number: null,

  sending_message: null,

  team_phone_numbers_loading: false,
  team_phone_number_groups: [],
  available_forwarding_numbers: [],

  posting_note: null,

  toggle_conversation_lead_view: false,
  assign_new_property: false,

  saved_responses: [],
  saved_responses_loading: false,

  multisend_total: "--",
  multisend_current_number: 1,
  multisend_count_loading: false,
  multisend_numbers: [],
  multisend_numbers_loading: false,
  multisend_numbers_begin: 0,
  multisend_numbers_loaded_all: false,
  multisend_numbers_limit: 100,

  starting_responses: [],

  update_conversation_loading: [],

  multisend_filters: {
    lead_ids: "",
    filters: {},
    filter_lists: [],
    total_property_count: 0
  },
  message_display: [],
  show_add_phone_number: false,
  show_multisend_modal: false
};

const updateMessage = (originalMessage, newMessages) => {
  for (var i = 0; i < newMessages.length; i++) {
    if (originalMessage.id === newMessages[i].id) {
      return newMessages[i];
    }
  }
  return originalMessage;
};

const updateConversation = (originalConversation, active_conversations) => {
  if (active_conversations && originalConversation) {
    for (var i = 0; i < active_conversations.length; i++) {
      if (originalConversation.id === active_conversations[i].id) {
        return active_conversations[0];
      }
    }

    return originalConversation;
  }

  return originalConversation;
};
const updateActiveConversation = (
  originalConversations,
  active_conversations
) => {
  if (active_conversations && originalConversations) {
    for (var j = 0; j < originalConversations.length; j++) {
      for (var i = 0; i < active_conversations.length; i++) {
        if (originalConversations[j].id === active_conversations[i].id) {
          return active_conversations;
        }
      }
    }

    return originalConversations;
  }
  return originalConversations;
};
const generateMessageDisplay = (message_display_array, new_message) => {
  let new_message_display_array = [];

  for (let i = 0; i < message_display_array.length; i++) {
    if (
      message_display_array[i].success != true &&
      message_display_array[i].failure != true &&
      message_display_array[i].skipped != true
    ) {
      new_message_display_array.push(message_display_array[i]);
    } else {
      if (moment().diff(message_display_array[i].date_updated, "seconds") < 1) {
        new_message_display_array.push(message_display_array[i]);
      }
    }
  }
  new_message_display_array.push(new_message);
  return new_message_display_array;
};

const updateProperty = (originalProperty, newProperties) => {
  for (var i = 0; i < newProperties.length; i++) {
    if (originalProperty.property_id == newProperties[i].property_id) {
      return {
        ...newProperties[i],
        highlighted: originalProperty.highlighted,
        deal: {
          ...newProperties[i].deal,
          image: originalProperty.deal ? originalProperty.deal.image : ""
        }
      };
    }
  }
  return originalProperty;
};

const updateToPhoneNumber = (originalNumber, newNumbers) => {
  if (originalNumber && newNumbers && newNumbers.length > 0) {
    for (var i = 0; i < newNumbers.length; i++) {
      if (
        originalNumber.owner_phone_number_id ==
        newNumbers[i].owner_phone_number_id
      ) {
        return newNumbers[i];
      }
    }
  }
  return originalNumber;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE
      };

    case GET_LEAD_CONVERSATIONS:
      return {
        ...state,
        sending_message: null,
        posting_note: null,
        set_to_phone_number: null,
        set_from_phone_number: null,
        lead_conversations_loading: true,
        lead_conversations_error: "",
        lead_conversations: []
      };
    case GET_LEAD_CONVERSATIONS_FAIL:
      return {
        ...state,
        lead_conversations_loading: false,
        lead_conversations_error: action.payload,
        lead_to_phone_numbers: []
      };

    case GET_LEAD_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        lead_conversations_loading: false,
        lead_conversations: action.payload.lead_conversations,
        lead_to_phone_numbers: action.payload.lead_to_phone_numbers,
        team_phone_number_groups: action.payload.team_phone_number_groups,
        set_to_phone_number: action.payload.set_to_phone_number,
        set_from_phone_number: action.payload.set_from_phone_number
      };

    case GET_OWNER_INFO_SUCCESS:
    case UPDATE_OWNER_INFO_SUCCESS:
      return {
        ...state,

        active_conversation:
          action.payload.owners &&
          action.payload.owners.length > 0 &&
          action.payload.owners[0].phone_numbers.length > 0 &&
          state.active_conversation &&
          state.active_conversation.length > 0
            ? state.active_conversation.map(conversation_item => {
                return {
                  ...conversation_item,
                  lead_phone_number: updateToPhoneNumber(
                    conversation_item.lead_phone_number,
                    action.payload.owners[0].phone_numbers
                  )
                };
              })
            : state.active_conversation,
        conversations:
          action.payload.owners &&
          action.payload.owners.length > 0 &&
          action.payload.owners[0].phone_numbers.length > 0 &&
          state.conversations &&
          state.conversations.length > 0
            ? state.conversations.map(conversation_item => {
                return {
                  ...conversation_item,
                  lead_phone_number: updateToPhoneNumber(
                    conversation_item.lead_phone_number,
                    action.payload.owners[0].phone_numbers
                  )
                };
              })
            : state.conversations,
        lead_to_phone_numbers: action.payload.owners
          ? action.payload.owners.length > 0
            ? action.payload.owners[0].phone_numbers
            : state.lead_to_phone_numbers
          : state.lead_to_phone_numbers,
        set_to_phone_number:
          action.payload.owners &&
          action.payload.owners.length > 0 &&
          action.payload.owners[0].phone_numbers.length > 0
            ? updateToPhoneNumber(
                state.set_to_phone_number,
                action.payload.owners[0].phone_numbers
              )
            : state.set_to_phone_number
      };

    case CREATE_TEAM_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        available_forwarding_numbers: action.payload
          .available_forwarding_numbers
          ? action.payload.available_forwarding_numbers
          : state.available_forwarding_numbers,

        team_phone_number_groups: action.payload.team_phone_number_groups
          ? action.payload.team_phone_number_groups
          : state.team_phone_number_groups
      };
    case UPDATE_TEAM_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        available_forwarding_numbers: action.payload
          .available_forwarding_numbers
          ? action.payload.available_forwarding_numbers
          : state.available_forwarding_numbers,
        team_phone_number_groups: action.payload.team_phone_number_groups
          ? action.payload.team_phone_number_groups
          : state.team_phone_number_groups
      };

    case GET_TEAM_PHONE_NUMBERS:
      return {
        ...state,
        team_phone_numbers_loading: true
      };

    case GET_TEAM_PHONE_NUMBERS_FAIL:
      return {
        ...state,
        team_phone_numbers_loading: false
      };
    case GET_TEAM_PHONE_NUMBERS_SUCCESS:
      return {
        ...state,
        team_phone_numbers_loading: false,
        available_forwarding_numbers:
          action.payload.available_forwarding_numbers,
        team_phone_number_groups: action.payload.team_phone_number_groups
      };

    case POST_MESSAGE_NOTE:
      return {
        ...state,
        posting_note: action.payload.new_note
      };

    case POST_MESSAGE_NOTE_FAIL:
      return {
        ...state,
        posting_note: {
          ...state.posting_note,
          sending_message: false,
          failed_to_send: true
        }
      };

    case POST_MESSAGE_NOTE_SUCCESS:
      return {
        ...state,
        posting_note: null,
        lead_conversations: action.payload.active_conversation,
        active_conversation: action.payload.active_conversation,
        conversations: state.conversations.map(conversation => {
          return updateConversation(
            conversation,
            action.payload.active_conversation
          );
        })
      };

    case SEND_TEXT_MESSAGE:
      return {
        ...state,
        sending_message: action.payload.new_message
      };

    case SEND_TEXT_MESSAGE_FAIL:
      return {
        ...state,
        sending_message: {
          ...state.sending_message,
          sending_message: false,
          failed_to_send: true,
          failure_message: action.payload.error
        }
      };

    case SEND_TEXT_MESSAGE_SUCCESS:
      return {
        ...state,
        sending_message: null,
        lead_conversations: action.payload.active_conversation,
        active_conversation: action.payload.active_conversation,
        conversations: state.conversations.map(conversation => {
          return updateConversation(
            conversation,
            action.payload.active_conversation
          );
        })
      };

    case SET_TO_PHONE_NUMBER:
      return {
        ...state,
        set_to_phone_number: action.payload
      };

    case UPDATE_CONVERSATION_FILTER:
      return {
        ...state,
        conversations_filters: {
          ...state.conversations_filters,
          ...action.payload
        }
      };

    case GET_CONVERSATIONS:
      return {
        ...state,
        conversations_error: "",
        conversations_loading: true,
        conversations_refreshing: false
      };
    case REFRESH_CONVERSATIONS:
      return {
        ...state,
        conversations_error: "",
        conversations_loading: true,
        conversations_refreshing: true,
        conversations_begin: 0,
        conversations_loaded_all: false,
        conversations: []
      };
    case GET_CONVERSATIONS_FAIL:
      return {
        ...state,
        conversations_loading: false,
        conversations_refreshing: false
      };
    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversations_refreshing: false,
        conversations_loading: false,
        conversations: state.conversations.concat(action.payload.conversations),
        conversations_begin:
          state.conversations_begin + action.payload.conversations.length,
        conversations_loaded_all:
          action.payload.conversations.length < state.conversations_limit,
        conversations_limit: action.payload.conversations_limit
          ? action.payload.conversations_limit
          : state.conversations_limit
      };
    case SET_ACTIVE_CONVERSATION:
      return {
        ...state,
        set_to_phone_number: null,
        set_from_phone_number: null,
        active_conversation:
          action.payload != null &&
          action.payload != "manage_phone_numbers" &&
          action.payload != "manage_saved_replies" &&
          action.payload != "conversation_settings"
            ? [action.payload]
            : action.payload,
        active_conversation_loading:
          action.payload != null &&
          action.payload != "manage_phone_numbers" &&
          action.payload != "manage_saved_replies" &&
          action.payload != "conversation_settings"
            ? true
            : false
      };

    case SET_ACTIVE_CONVERSATION_SUCCESS:
      return {
        ...state,
        set_to_phone_number: action.payload.set_to_phone_number
          ? action.payload.set_to_phone_number
          : null,
        set_from_phone_number: action.payload.set_from_phone_number
          ? action.payload.set_from_phone_number
          : null,
        active_conversation: action.payload.active_conversation
          ? action.payload.active_conversation
          : null,
        active_conversation_loading: false
      };

    case QUICK_UPDATE_CONVERSATION:
      return {
        ...state,
        update_conversation_loading: action.payload.conversation_id
          ? state.update_conversation_loading.concat([
              action.payload.conversation_id
            ])
          : state.update_conversation_loading
      };
    case UPDATE_CONVERSATION_FAIL:
      return {
        ...state,
        update_conversation_loading: action.payload.conversation_id
          ? state.update_conversation_loading.filter(
              conversation_id =>
                conversation_id != action.payload.conversation_id
            )
          : state.update_conversation_loading
      };
    case CLOSE_CONVERSATION_SUCCESS:
      return {
        ...state,
        active_conversation: action.payload.active_conversation
          ? updateActiveConversation(
              state.active_conversation,
              action.payload.active_conversation
            )
          : state.active_conversation,
        update_conversation_loading: action.payload.conversation_id
          ? state.update_conversation_loading.filter(
              conversation_id =>
                conversation_id != action.payload.conversation_id
            )
          : state.update_conversation_loading,
        conversations: action.payload.conversation_id
          ? state.conversations.filter(
              ({ id }) => id != action.payload.conversation_id
            )
          : state.conversations
      };

    case UPDATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        active_conversation: action.payload.active_conversation
          ? updateActiveConversation(
              state.active_conversation,
              action.payload.active_conversation
            )
          : state.active_conversation,
        conversations: action.payload.active_conversation
          ? state.conversations.map(conversation => {
              return updateConversation(
                conversation,
                action.payload.active_conversation
              );
            })
          : state.conversations,
        update_conversation_loading: action.payload.conversation_id
          ? state.update_conversation_loading.filter(
              conversation_id =>
                conversation_id != action.payload.conversation_id
            )
          : state.update_conversation_loading,

        lead_conversations: action.payload.lead_conversations
          ? action.payload.lead_conversations
          : state.lead_conversations,
        lead_to_phone_numbers: action.payload.lead_to_phone_numbers
          ? action.payload.lead_to_phone_numbers
          : state.lead_to_phone_numbers,
        team_phone_number_groups: action.payload.team_phone_number_groups
          ? action.payload.team_phone_number_groups
          : state.team_phone_number_groups,
        set_to_phone_number: action.payload.set_to_phone_number
          ? action.payload.set_to_phone_number
          : state.set_to_phone_number,
        set_from_phone_number: action.payload.set_from_phone_number
          ? action.payload.set_from_phone_number
          : state.set_from_phone_number
      };
    case READ_TEXT_MESSAGE_SUCCESS:
      return {
        ...state,
        active_conversation: action.payload.active_conversation
          ? updateActiveConversation(
              state.active_conversation,
              action.payload.active_conversation
            )
          : state.active_conversation,
        conversations: state.conversations.map(conversation => {
          return updateConversation(
            conversation,
            action.payload.active_conversation
          );
        })
      };

    case SET_ACTIVE_CONVERSATION_FAIL:
      return {
        ...state,
        active_conversation: null,
        active_conversation_loading: false
      };

    case TOGGLE_CONVERSATION_LEAD_VIEW:
      return {
        ...state,
        toggle_conversation_lead_view: action.payload.show,
        assign_new_property: action.payload.new_property
      };

    case GET_SAVED_RESPONSES:
      return {
        ...state,
        saved_responses: [],
        saved_responses_loading: true
      };

    case GET_SAVED_RESPONSES_FAIL:
      return {
        ...state,
        saved_responses_loading: false
      };

    case GET_SAVED_RESPONSES_SUCCESS:
      return {
        ...state,
        saved_responses: action.payload.saved_responses,
        saved_responses_loading: false
      };

    case CREATE_SAVED_RESPONSE_SUCCESS:
      return {
        ...state,
        saved_responses: [
          action.payload.saved_responses[0],
          ...state.saved_responses
        ]
      };

    case UPDATE_SAVED_RESPONSE_SUCCESS:
      return {
        ...state,
        saved_responses:
          action.payload.saved_responses &&
          action.payload.saved_responses.length > 0
            ? state.saved_responses.map(response => {
                if (action.payload.saved_responses[0].id == response.id) {
                  return action.payload.saved_responses[0];
                }
                return response;
              })
            : state.saved_responses
      };

    case REMOVE_SAVED_RESPONSE_SUCCESS:
      return {
        ...state,
        saved_responses: action.payload.saved_response_id
          ? state.saved_responses.filter(
              ({ id }) => id != action.payload.saved_response_id
            )
          : state.saved_responses
      };

    case GET_MULTISEND_NUMBERS:
      return {
        ...state,
        multisend_numbers_loading: true,
        starting_responses: [],
        multisend_numbers: []
      };

    case GET_MORE_MULTISEND_NUMBERS:
      return {
        ...state,
        multisend_numbers_loading: true
      };

    case GET_MULTISEND_NUMBERS_FAIL:
      return {
        ...state,
        multisend_numbers_loading: false
      };
    case GET_MULTISEND_NUMBERS_SUCCESS:
      return {
        ...state,
        message_display:
          state.multisend_numbers.length == 0 ? [] : state.message_display,
        starting_responses: action.payload.saved_starting_replys
          ? action.payload.saved_starting_replys
          : [],
        multisend_numbers_loading: false,
        multisend_numbers: state.multisend_numbers.concat(
          action.payload.multisend_numbers
        ),
        multisend_numbers_begin:
          state.multisend_numbers_begin +
          action.payload.multisend_numbers.length,
        multisend_numbers_loaded_all:
          action.payload.multisend_numbers.length <
          state.multisend_numbers_limit,

        team_phone_number_groups: action.payload.team_phone_number_groups
          ? action.payload.team_phone_number_groups
          : state.team_phone_number_groups
      };
    case SKIP_MULTISEND_NUMBER:
      return {
        ...state,
        multisend_current_number: parseInt(state.multisend_current_number) + 1,
        multisend_numbers: action.payload.lead_phone_id
          ? state.multisend_numbers.filter(
              ({ id }) => id != action.payload.lead_phone_id
            )
          : state.multisend_numbers,
        message_display: generateMessageDisplay(state.message_display, {
          lead_phone_id: action.payload.lead_phone_id,
          lead_phone_number: action.payload.lead_phone_number,
          message_text: "",
          skipped: true,
          success: false,
          failure: false,
          date_updated: moment()
        })
      };
    case SEND_MULTISEND_MESSAGE:
      return {
        ...state,
        multisend_current_number: parseInt(state.multisend_current_number) + 1,
        multisend_numbers: action.payload.lead_phone_id
          ? state.multisend_numbers.filter(
              ({ id }) => id != action.payload.lead_phone_id
            )
          : state.multisend_numbers,
        message_display: generateMessageDisplay(state.message_display, {
          lead_phone_id: action.payload.lead_phone_id,
          lead_phone_number: action.payload.lead_phone_number,
          message_text: action.payload.message_text,
          skipped: false,
          success: false,
          failure: false,
          date_updated: null
        })
      };

    case UPDATE_MESSAGE_DISPLAY:
      return {
        ...state,
        message_display: state.message_display.map(message => {
          if (message.lead_phone_id == action.payload.lead_phone_id) {
            return {
              ...message,
              success: action.payload.success,
              failure: action.payload.failure,
              failure_message: action.payload.failure_message,
              date_updated: moment()
            };
          }

          return message;
        })
      };
    case SET_MULTISEND_FILTERS:
      return {
        ...state,
        multisend_filters: action.payload
      };

    case GET_MULTISEND_NUMBERS_COUNT:
      return {
        ...state,
        multisend_total: "--",
        multisend_current_number: 1,
        multisend_count_loading: true
      };

    case GET_MULTISEND_NUMBERS_COUNT_FAIL:
      return {
        ...state,

        multisend_count_loading: false
      };

    case GET_MULTISEND_NUMBERS_COUNT_SUCCESS:
      return {
        ...state,
        multisend_total: action.payload.multisend_numbers_count
          ? action.payload.multisend_numbers_count
          : "--",
        multisend_count_loading: false
      };

    case UPDATE_LEAD_SUCCESS:
    case RELOAD_LEADS_SUCCESS:
      return {
        ...state,
        conversations: action.payload.properties
          ? state.conversations.map(conversation => {
              if (conversation.property) {
                return {
                  ...conversation,
                  property: updateProperty(
                    conversation.property,
                    action.payload.properties
                  )
                };
              }

              return conversation;
            })
          : state.conversations,
        active_conversation: action.payload.properties
          ? state.active_conversation && state.active_conversation.length > 0
            ? state.active_conversation.map(conversation => {
                if (conversation.property) {
                  return {
                    ...conversation,
                    property: updateProperty(
                      conversation.property,
                      action.payload.properties
                    )
                  };
                }

                return conversation;
              })
            : state.active_conversation
          : state.active_conversation
      };
    case TOGGLE_ADD_PHONE_NUMBER:
      return {
        ...state,
        show_add_phone_number: action.payload
      };
    case TOGGLE_MULTISEND_MODAL:
      return {
        ...state,
        show_multisend_modal: action.payload
      };
    default:
      return state;
  }
};
