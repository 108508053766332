import {
  SET_SALES_PAGE_PREVIEW,
  SET_SALES_PAGE_CUSTOM_URL,
  SET_SALES_PAGE_HEADLINE,
  SET_SALES_PAGE_PROPERTY_DETAILS,
  SET_SALES_PAGE_PRICING_INFO,
  SET_SALES_PAGE_CONTACT_NAME,
  SET_SALES_PAGE_CONTACT_EMAIL,
  SET_SALES_PAGE_CONTACT_PHONE,
  SET_SALES_PAGE_CONTACT_COMPANY,
  SET_SALES_PAGE_FOR_SALE_BY,
  SET_SALES_PAGE_SHOW_PROPERTY_ADDRESS,
  SET_SALES_PAGE_IMAGES,
  SET_SALES_PAGE_HAS_HEADLINE,
  SET_SALES_PAGE_HAS_PROPERTY_DETAILS,
  SET_SALES_PAGE_HAS_PRICING_INFO,
  RESET_SALES_PAGE
} from "app/DealMachineCore/types";

const INITIAL_STATE = {
  salesPagePreview: {
    images: []
  },
  customURL: "",
  contactName: "",
  hasHeadline: false,
  headline: "",
  hasPropertyDetails: false,
  propertyDetails: "",
  hasPricingInfo: false,
  pricingInfo: "",
  contactPhone: "",
  contactEmail: "",
  contactCompany: "",
  forSalesBy: "",
  images: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SALES_PAGE_PREVIEW:
      return {
        ...state,
        salesPagePreview: action.payload
      };
    case SET_SALES_PAGE_CUSTOM_URL:
      return {
        ...state,
        customURL: action.payload
      };
    case SET_SALES_PAGE_HEADLINE:
      return {
        ...state,
        headline: action.payload
      };
    case SET_SALES_PAGE_PROPERTY_DETAILS:
      return {
        ...state,
        propertyDetails: action.payload
      };
    case SET_SALES_PAGE_PRICING_INFO:
      return {
        ...state,
        pricingInfo: action.payload
      };
    case SET_SALES_PAGE_CONTACT_NAME:
      return {
        ...state,
        contactName: action.payload
      };
    case SET_SALES_PAGE_CONTACT_EMAIL:
      return {
        ...state,
        contactEmail: action.payload
      };
    case SET_SALES_PAGE_CONTACT_PHONE:
      return {
        ...state,
        contactPhone: action.payload
      };
    case SET_SALES_PAGE_CONTACT_COMPANY:
      return {
        ...state,
        contactCompany: action.payload
      };
    case SET_SALES_PAGE_FOR_SALE_BY:
      return {
        ...state,
        forSaleBy: action.payload
      };
    case SET_SALES_PAGE_SHOW_PROPERTY_ADDRESS:
      return {
        ...state,
        showPropertyAddress: action.payload
      };
    case SET_SALES_PAGE_IMAGES:
      return {
        ...state,
        images: action.payload
      };
    case SET_SALES_PAGE_HAS_HEADLINE:
      return {
        ...state,
        hasHeadline: action.payload
      };
    case SET_SALES_PAGE_HAS_PROPERTY_DETAILS:
      return {
        ...state,
        hasPropertyDetails: action.payload
      };
    case SET_SALES_PAGE_HAS_PRICING_INFO:
      return {
        ...state,
        hasPricingInfo: action.payload
      };
    case RESET_SALES_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
