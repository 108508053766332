import { END_REDIRECT, START_REDIRECT } from "app/DealMachineCore/types";
import {
  appRedirect,
  logout,
  setModal,
  setPlatformType,
  toggleModal,
  triggerLogout,
  setInitialRedirect
} from "app/NativeActions";
import { Wrapper } from "app/NativeComponents/common";
import MobileDetect from "mobile-detect";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const md = new MobileDetect(window.navigator.userAgent);
const getPlatformFromMobileDetect = os => {
  switch (os) {
    case "iOS":
      return "ios";
    case "AndroidOS":
      return "android";
    default:
      return "desktop";
  }
};

const RedirectSystem = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { user } = useSelector(({ auth }) => auth);
  const { trigger_logout } = useSelector(({ auth }) => auth);
  const { is_partner, accepted_affiliate_latest_terms } = useSelector(
    ({ partner }) => partner
  );
  const { redirectTo, initialRedirect } = useSelector(({ native }) => native);
  const { user_dealfinder_page } = useSelector(({ team_link }) => team_link);

  useEffect(() => {
    dispatch(setPlatformType(getPlatformFromMobileDetect(md.os())));
    if (
      ["login", "forgot-password", "sign-up"].some(
        path => location.pathname && location.pathname.includes(path)
      )
    )
      return;
    dispatch(setInitialRedirect(location.pathname));
  }, []);

  useEffect(() => {
    if (user) {
      dispatch({
        type: END_REDIRECT
      });
    }
  }, [location]);

  useEffect(() => {
    if (trigger_logout) {
      dispatch(triggerLogout(false));
      dispatch(logout());
      dispatch(
        setModal({
          title: "You have been logged out.",
          description:
            "You have been logged out of DealMachine. Your account can only be logged in on 1 mobile device and 1 desktop device.",
          icon: "error",
          submit: "Dismiss",
          onPress: () => {},
          cancel: "",
          onCancel: () => {}
        })
      );
      dispatch(toggleModal({ show: true, type: "normal" }));
    }
    if (redirectTo) {
      if (
        redirectTo.indexOf("http://") == 0 ||
        redirectTo.indexOf("https://") == 0
      )
        return (window.location.href = redirectTo);
      if (redirectTo == "goBack") return history.goBack();

      let first_params_string = redirectTo.split("?")[0];
      let new_params_string = redirectTo.split("?")[1] || "";

      let actual_url = first_params_string.split("#")[0];
      let new_router_redirect_string = first_params_string.split("#")[1] || "";

      let router_redirect_string = "";
      if (!new_router_redirect_string) {
        router_redirect_string = window?.location.href.split("#")[1] || "";
        if (!!router_redirect_string) {
          router_redirect_string = "#" + router_redirect_string;
        }
      } else {
        router_redirect_string = "#" + new_router_redirect_string;
      }

      let query_redirect_string = "";
      const queryString = window?.location?.search;
      let currentUrlParams = null;
      let nextURLParams = null;

      if (!!queryString) {
        currentUrlParams = new URLSearchParams(queryString);
      }
      if (!!new_params_string) {
        nextURLParams = new URLSearchParams(new_params_string);
      }

      if (currentUrlParams) {
        for (const [key, value] of currentUrlParams) {
          if (!!query_redirect_string) {
            query_redirect_string += "&" + key + "=" + value;
          } else {
            query_redirect_string += key + "=" + value;
          }
        }
      }
      if (nextURLParams) {
        for (const [key, value] of nextURLParams) {
          if (!!query_redirect_string) {
            query_redirect_string += "&" + key + "=" + value;
          } else {
            query_redirect_string += key + "=" + value;
          }
        }
      }

      if (!!query_redirect_string) {
        query_redirect_string = "?" + query_redirect_string;
      }

      return history.push(
        actual_url + router_redirect_string + query_redirect_string,
        {
          prevUrl: location.pathname
        }
      );
    }
  }, [redirectTo, trigger_logout]);

  useEffect(() => {
    if (!(user && initialRedirect)) return;
    dispatch({
      type: START_REDIRECT,
      payload: initialRedirect
    });
    dispatch(setInitialRedirect());
  }, [user]);

  useEffect(() => {
    if (user && user_dealfinder_page) {
      appRedirect({
        redirect: "init",
        payload: {
          type: "init",
          user,
          dealfinder_page: user_dealfinder_page,
          is_partner,
          accepted_affiliate_latest_terms
        }
      });
    }
  }, [user, user_dealfinder_page, is_partner, accepted_affiliate_latest_terms]);

  return <Wrapper />;
};
export default RedirectSystem;
