import { AppConfig } from "app/NativeActions";
const contentful = require("contentful/dist/contentful.browser.min.js");

const client = contentful.createClient({
  space: AppConfig().contentful_space,
  accessToken:
    "5151d5ee0d6f0d36963cd6f0a9caf81de03a298bf915027f5994f7a0d2a6d856"
});

export const loadDashboardVideoTutorial = () => {
  return client.getEntries({
    content_type: "dashboardTutorialVideo"
  });
};

export const loadDashboardSuccessSteps = () => {
  return client.getEntries({
    content_type: "dashboardSuccessSteps"
  });
};

export const loadDashboardUpcomingWebinars = () => {
  return client.getEntries({
    content_type: "dashboardWebinar",
    order: "fields.startAt",
    "fields.startAt[gt]": new Date().toISOString(),
    limit: 3
  });
};

export const loadDashboardCaseStudies = () => {
  return client.getEntries({
    content_type: "dashboardCaseStudy"
  });
};

export const loadPlanOnboardingInfo = slug => {
  return client.getEntries({
    content_type: "onboardingPlanModule",
    "fields.slug[in]": slug,
    limit: 1
  });
};
export const loadOnboardingText = slug => {
  return client.getEntries({
    content_type: "onboardingText",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadNewsAndUpdates = slug => {
  return client.getEntries({
    content_type: "testimonial", //temp ID for new&updates
    order: "fields.order"
  });
};

export const loadUrlRedirect = slug => {
  return client.getEntries({
    content_type: "interestedIn", //using this slug but the contentful model is called "URLRedierct"
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadSpecialPlanSelect = slug => {
  return client.getEntries({
    content_type: "textStyle", //using this slug but the contentful model is called "SpecialPlanSelect"
    "fields.name[in]": slug,
    limit: 1
  });
};

export const loadPlanFromId = addon_id => {
  return client.getEntries({
    content_type: "onboardingPlanSelector",
    "sys.id[in]": addon_id,
    limit: 1
  });
};

export const loadSpecialCopy = slug => {
  return client.getEntries({
    content_type: "managePaymentMethodsInformation", //using this slug but the contentful model is called "SpecialCopy"
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadDashboardStep = slug => {
  return client.getEntries({
    content_type: "dashboardStep",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadFeatureInfo = slug => {
  return client.getEntries({
    content_type: "getFeatureSection",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadEngines = () => {
  return client.getEntries({
    content_type: "enginePricing",
    order: "fields.engineOrder"
  });
};
export const loadEngine = slug => {
  return client.getEntries({
    content_type: "enginePricing",
    "fields.slug[in]": slug,
    order: "fields.engineOrder",
    limit: 1
  });
};

export const loadPlans = slug => {
  return client.getEntries({
    content_type: "pricingPlan",
    "fields.slug[in]": slug
  });
};

export const loadSignUpOnboarding = (slug = "onboarding") => {
  let query = {
    content_type: "signUpOnboarding",
    order: "sys.createdAt",
    "fields.slug[in]": slug,
    limit: 1
  };

  return client.getEntries(query);
};

export const loadOnboardingLandingPage = slug => {
  return client.getEntries({
    content_type: "onboadingLandingPage",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadCancelCenter = slug => {
  return client.getEntries({
    content_type: "cancelCenter",
    "fields.slug[in]": slug,
    limit: 1
  });
};
export const loadPausedAccount = slug => {
  return client.getEntries({
    content_type: "pausedAccount",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadCancelReason = slug => {
  return client.getEntries({
    content_type: "cancelCenterOption",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadPricingPage = slug => {
  return client.getEntries({
    content_type: "pricingPage",
    "fields.slug[in]": slug,
    limit: 1
  });
};

export const loadSalesPageInventoryParagraphs = () => {
  return client.getEntries({
    content_type: "salesPageInventoryText",
    order: "fields.order"
  });
};

export const loadSalesPageInventoryParagraphsForWeb = () => {
  return client.getEntries({
    content_type: "salesPageInventoryTextWeb",
    order: "fields.order"
  });
};

export const loadManagePaymentMethodsInformation = () => {
  return client.getEntries({
    content_type: "managePaymentMethodsInformation",
    order: "fields.order"
  });
};

export const loadOnboardingStepthroughSteps = () => {
  return client.getEntries({
    content_type: "onboardingStepThrough",
    order: "fields.stepOrder"
  });
};

export const loadUpsellModal = slug => {
  return client.getEntries({
    content_type: "upsellModal",
    "fields.slug[in]": slug,
    limit: 1
  });
};
export const loadContentfulAssetById = id => {
  return client.getAsset(id);
};
