import React, { PureComponent } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import {
  Wrapper,
  Card,
  CardBody,
  Row,
  ModalOverlay,
  Modal,
  Title,
  Button,
  Icon,
  Copy,
  Bold
} from "app/NativeComponents/common";

import { CloseButton } from "app/NativeComponents/snippets";

import moment from "moment";
import { store } from "app/store";

class DateRangePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    // var one_month = new Date();
    // one_month.setMonth(one_month.getMonth() - 1);

    var one_month = new Date();
    one_month.setMonth(one_month.getMonth() - 1);

    return {
      from: one_month,
      to: new Date()
    };
  }

  handleDayClick(day, modifiers) {
    const range = DateUtils.addDayToRange(day, this.state);
    if (modifiers.disabled) {
      return;
    }
    this.setState(range);
  }

  handleReset() {
    var one_month = new Date();
    one_month.setMonth(one_month.getMonth() - 1);

    this.setState({
      from: one_month,
      to: new Date()
    });

    this.props.selectDates({
      start_date: one_month,
      end_date: new Date()
    });
  }

  handleFilter() {
    this.props.selectDates({
      start_date: moment(this.state.from).utc().format("YYYY-MM-DD"),
      end_date: moment(this.state.to).utc().format("YYYY-MM-DD")
    });

    this.props.toggleCalendar();
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to, disabled: { after: new Date() } };
    const disabledDays = { after: new Date() };
    const colors = store.getState().settings.colors;

    if (this.props.isCalendarOpen) {
      return (
        <ModalOverlay>
          <Modal style={{ maxWidth: 650 }}>
            <Card>
              <CardBody>
                <Copy>
                  <Bold>Filter by date</Bold>
                </Copy>
                <Copy>
                  Selected Date Range:{" "}
                  <Bold>{moment(this.state.from).format("MMM Do, YYYY")}</Bold>{" "}
                  to <Bold>{moment(this.state.to).format("MMM Do, YYYY")}</Bold>
                </Copy>

                <DayPicker
                  className="Selectable"
                  numberOfMonths={2}
                  toMonth={new Date()}
                  disabledDays={disabledDays}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  pagedNavigation={true}
                  onDayClick={this.handleDayClick.bind(this)}
                />

                <Row style={{ justifyContent: "flex-end" }}>
                  <Button onPress={() => this.props.toggleCalendar()}>
                    <Card
                      style={{
                        position: "relative"
                      }}
                    >
                      <Row
                        style={{
                          backgroundColor: colors.white_color,
                          padding: 10,
                          paddingLeft: 15,
                          paddingRight: 15
                        }}
                      >
                        <Copy>Cancel</Copy>
                      </Row>
                    </Card>
                  </Button>

                  <Button onPress={() => this.handleReset()}>
                    <Card
                      style={{
                        position: "relative"
                      }}
                    >
                      <Row
                        style={{
                          backgroundColor: colors.white_color,
                          padding: 10,
                          paddingLeft: 15,
                          paddingRight: 15
                        }}
                      >
                        <Icon
                          icon="close"
                          size={18}
                          style={{ marginRight: 5 }}
                        />
                        <Copy>Reset</Copy>
                      </Row>
                    </Card>
                  </Button>

                  <Button onPress={() => this.handleFilter()}>
                    <Card
                      style={{
                        position: "relative"
                      }}
                    >
                      <Row
                        style={{
                          backgroundColor: colors.active_color,
                          padding: 10,
                          paddingLeft: 15,
                          paddingRight: 15
                        }}
                      >
                        <Icon
                          icon="check"
                          color={colors.white_color}
                          size={18}
                          style={{ marginRight: 5 }}
                        />
                        <Copy style={{ color: colors.white_color }}>
                          Filter
                        </Copy>
                      </Row>
                    </Card>
                  </Button>
                </Row>
              </CardBody>
            </Card>
          </Modal>

          <CloseButton
            style={{
              paddingTop: 30,
              paddingBottom: 20,
              paddingLeft: 20
            }}
            onPress={() => {
              this.props.toggleCalendar();
            }}
            color={colors.white_color}
          />
        </ModalOverlay>
      );
    }
    return <Wrapper />;
  }
}

export { DateRangePicker };
