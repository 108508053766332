import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectItem } from "app/NativeComponents/snippets";
import { UpsellButton, InlineButton } from "app/NativeComponents/snippets";

import {
  setExportModal,
  pushSidePanel,
  exportLeadsFromEmail,
  numberWithCommas,
  setModal,
  toggleModal,
  setFreeTrialInfo,
  checkIfUserHasMetadata
} from "app/NativeActions";
import moment from "moment/moment";
class ExportButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_on_trial: false,
      is_over_export_limit: false
    };
  }
  componentDidMount() {
    this.props.setFreeTrialInfo(this.props.plan_modules);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.plans_on_trial != prevProps.plans_on_trial &&
      this.props.plans_on_trial.length == this.props.plan_modules.length
    ) {
      this.setState({
        is_on_trial: true
      });
    }
  }

  render() {
    const {
      button_search,
      title,
      selected_all,
      selected_leads,
      colors,
      platform
    } = this.props;
    const {
      token,
      user,
      filtered_count,
      source_of_truth,
      all_feature_metadata
    } = this.props;
    let { is_on_trial, is_over_export_limit } = this.state;

    let trialing = is_on_trial;
    let over_export_limit = is_over_export_limit;

    if (this.props.user?.user_version >= 6) {
      trialing =
        source_of_truth?.subscription?.status == "trialing" ? true : false;
      over_export_limit = source_of_truth?.subscription?.over_export_limit;
    }
    if (
      !button_search ||
      title.toLowerCase().includes(button_search.toLowerCase())
    ) {
      if (
        checkIfUserHasMetadata("free_plan") &&
        this.props.source_of_truth?.subscription?.metadata?.export_limit == 0
      ) {
        return (
          <SelectItem
            onPress={() => {
              this.props.pushSidePanel({
                slug: "purchase_plan_prompt",
                overlay_modal: true,
                locked: true,
                data: {
                  title: "Export Properties & Contacts With DealMachine Pro.",
                  dismiss_button: "No thanks. Let me continue without Pro.",
                  no_free: true
                }
              });
            }}
            button_type={"full"}
            select_type="icon"
            select_icon="lock"
          >
            {"Export Leads"}
          </SelectItem>
        );
      }

      return (
        <UpsellButton
          type="limit"
          contentful_slug="driving_for_dollars"
          meta_slug="export_limits_upgrade"
          force_lock={over_export_limit || trialing ? true : false}
          force_unlock={over_export_limit || trialing ? false : true}
          onPress={() => {
            this.props.setExportModal({
              title: "Export Leads",
              description: selected_all
                ? "Export all " + filtered_count + " leads."
                : selected_leads.length === 1
                ? "Export " + selected_leads[0].property_address + "."
                : "Export your selected " +
                  numberWithCommas(selected_leads.length) +
                  " leads.",
              type: "export_leads",
              selected_leads: selected_all ? "all" : selected_leads,
              selected_lists: [],
              select_all: selected_all ? 1 : 0,
              total_count: selected_all
                ? filtered_count
                : selected_leads.length,
              new_filters:
                this.props.applied_filter && this.props.applied_filter.filters
                  ? this.props.applied_filter?.filters
                  : null,
              filters:
                this.props.applied_filter &&
                this.props.applied_filter.old_filters
                  ? this.props.applied_filter?.old_filters
                  : null,
              property_flags: this.props.lead_filters?.property_flags
                .map(({ value }) => value)
                .join(","),
              property_flags_and_or:
                this.props.lead_filters?.property_flags_and_or,
              using_old_filters:
                this.props.applied_filter &&
                this.props.applied_filter.old_filters
                  ? true
                  : false,
              search: this.props.search,
              search_type: this.props.search_type,
              list_id: this.props.active_list_tab?.id
                ? this.props.active_list_tab?.id
                : this.props.active_list_tab,
              list_history_id: this.props.list_history_id || "",
              deal_ids: selected_all
                ? ""
                : selected_leads
                    .map(property => {
                      return property.deal.id;
                    })
                    .join(),

              modalAction: ({
                selected_leads,
                emails,
                include_all_columns,
                selected_columns,
                export_type,
                include_likely_owners,
                include_family,
                include_likely_renters,
                include_potential_property_owners,
                scrub_dnc,
                scrub_landline,
                scrub_wireless,
                deduplicate,
                export_file_name,
                onLoading = () => {},
                onError = () => {},
                onSuccess = () => {}
              }) => {
                onLoading();
                this.props.exportLeadsFromEmail({
                  token: token,
                  selected_columns:
                    selected_columns && selected_columns.length > 0
                      ? selected_columns
                          .map(column => {
                            return column.slug;
                          })
                          .join()
                      : "",
                  emails,
                  include_all_columns,
                  export_type,
                  include_likely_owners,
                  include_family,
                  include_likely_renters,
                  include_potential_property_owners,
                  scrub_dnc,
                  scrub_landline,
                  scrub_wireless,
                  deduplicate,
                  export_file_name,
                  select_all: selected_all ? 1 : 0,
                  total_count: selected_all
                    ? filtered_count
                    : selected_leads.length,
                  new_filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.filters
                      ? this.props.applied_filter?.filters
                      : null,
                  filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.old_filters
                      ? this.props.applied_filter?.old_filters
                      : null,
                  property_flags: this.props.lead_filters?.property_flags
                    .map(({ value }) => value)
                    .join(","),
                  property_flags_and_or:
                    this.props.lead_filters?.property_flags_and_or,
                  using_old_filters:
                    this.props.applied_filter &&
                    this.props.applied_filter.old_filters
                      ? true
                      : false,
                  search: this.props.search,
                  search_type: this.props.search_type,
                  list_id: this.props.active_list_tab?.id
                    ? this.props.active_list_tab?.id
                    : this.props.active_list_tab,
                  list_history_id: this.props.list_history_id || "",
                  deal_ids: selected_all
                    ? ""
                    : selected_leads
                        .map(property => {
                          return property.deal.id;
                        })
                        .join(),

                  onLoading: () => {
                    onLoading();
                  },
                  onError: () => {
                    onError();
                  },
                  onSuccess: () => {
                    onSuccess();
                    this.props.clearAll();
                  }
                });
              }
            });

            this.props.pushSidePanel({
              slug: "export_leads",
              overlay: true
            });
            this.props.toggleMenu(false);
          }}
          onLockPressOverride={() => {
            if (this.props.user?.user_version >= 6) {
              if (over_export_limit) {
                let feature_upsell_info = null;
                for (let i = 0; i < all_feature_metadata?.length; i++) {
                  if (
                    all_feature_metadata[i].slug == "export_limit" &&
                    all_feature_metadata[i].unlock_limit ==
                      source_of_truth?.subscription?.metadata?.export_limit
                  ) {
                    feature_upsell_info = all_feature_metadata[i];
                  }
                }
                this.props.pushSidePanel({
                  slug: "unlock_feature_modal",
                  overlay: true,
                  data: {
                    feature_upsell_info
                  }
                });
                this.props.clearAll();
              }
            }
            if (trialing) {
              if (platform == "desktop") {
                this.props.pushSidePanel({
                  slug: "end_trial",
                  data: {
                    title: "You cannot export until your free trial ends.",
                    description:
                      "Pay now and start your subscription to export your leads."
                  }
                });
              } else {
                this.props.pushSidePanel({
                  slug: "help_center",
                  overlay: true
                });
              }
              this.props.clearAll();
            }
          }}
          renderLockedChildren={({ hovering }) => {
            return (
              <>
                <SelectItem
                  noPress={true}
                  pressedIn={hovering}
                  button_type={"full"}
                  primary={true}
                  style={{
                    backgroundColor: colors.orange_color_muted
                  }}
                  select_type="icon"
                  select_icon="lock"
                >
                  {"Export Leads"}
                </SelectItem>
              </>
            );
          }}
          renderChildren={({ hovering }) => {
            return (
              <>
                <SelectItem
                  select_type="none"
                  icon="keyboard-arrow-right"
                  noPress={true}
                  pressedIn={hovering}
                >
                  {title}
                </SelectItem>
              </>
            );
          }}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, lead, billing, settings, native }) => {
  const { colors } = settings;
  const { token, user } = auth;
  const { isMobile, platform } = native;
  const {
    billing_addons,
    plan_modules,
    plans_on_trial,
    source_of_truth,
    all_feature_metadata
  } = billing;
  return {
    token,
    user,
    billing_addons,
    plan_modules,
    plans_on_trial,
    colors,
    source_of_truth,
    all_feature_metadata,
    platform
  };
};

export default connect(mapStateToProps, {
  setExportModal,
  exportLeadsFromEmail,
  pushSidePanel,
  setModal,
  toggleModal,
  setFreeTrialInfo
})(ExportButton);
