import React, { Component } from "react";
import { connect } from "react-redux";

import { Scroll, Row, Wrapper, Copy, Bold } from "app/NativeComponents/common";
import {
  SelectItem,
  InlineButton,
  PopoverMenu,
  IconButton,
  CloseButton
} from "app/NativeComponents/snippets";

import {
  formatAddress,
  numberWithCommas,
  checkIfUserHasSubscription
} from "app/NativeActions";
import LeadMainPhoto from "app/DealMachineCore/reuseable/LeadMainPhoto";
import moment from "moment";

class CompsProperties extends Component {
  render() {
    const { colors, device, isMobile, popover_info, unselected_comps_on_map } =
      this.props;
    const property = popover_info?.property;
    if (property) {
      const formatted_address = formatAddress({
        address: {
          address: property.property_address,
          address2: property.property_address2,
          address_city: property.property_address_city,
          address_state: property.property_address_state,
          address_zip: property.property_address_zip,
          latitude: property?.latitude,
          longitude: property?.longitude
        }
      });

      let in_unselected_comps = false;
      for (let i = 0; i < unselected_comps_on_map.length; i++) {
        if (property.property_id == unselected_comps_on_map[i].property_id) {
          in_unselected_comps = true;
        }
      }
      let description = "";
      if (!!property.saleprice) {
        description += "$" + numberWithCommas(property.saleprice);
      }
      if (!!property.saledate) {
        if (!!description) {
          description +=
            " - " + moment(property.saledate).format("MMM Do, YYYY");
        } else {
          description += moment(property.saledate).format("MMM Do, YYYY");
        }
      }

      return (
        <>
          <Wrapper
            style={{
              paddingTop: 0,
              position: "relative",
              alignSelf: "stretch"
            }}
          >
            <SelectItem
              label={""}
              style={
                !in_unselected_comps
                  ? {
                      borderLeftWidth: device == "desktop" ? 5 : 7,
                      borderLeftStyle: "solid",
                      borderLeftColor: colors.actionable_text_color,
                      padding: 15,
                      paddingTop: 10,
                      paddingBottom: 10,
                      margin: 0
                    }
                  : {
                      borderLeftWidth: device == "desktop" ? 5 : 7,
                      borderLeftStyle: "solid",
                      borderLeftColor: "transparent",
                      padding: 15,
                      paddingTop: 10,
                      paddingBottom: 10,
                      margin: 0
                    }
              }
              description={description}
              select_type="none"
              selected={!in_unselected_comps}
              onPress={() => {
                if (
                  checkIfUserHasSubscription(
                    this.props.user,
                    this.props.source_of_truth
                  )
                ) {
                  if (in_unselected_comps) {
                    this.props.updateMainState({
                      unselected_comps_on_map: unselected_comps_on_map.filter(
                        ({ property_id }) =>
                          property_id !== property?.property_id
                      )
                    });
                  } else {
                    this.props.updateMainState({
                      unselected_comps_on_map: [
                        ...unselected_comps_on_map,
                        property
                      ]
                    });
                  }
                }
              }}
              renderLeft={() => {
                return (
                  <Wrapper
                    style={{
                      padding: 15,
                      paddingLeft: isMobile ? 10 : 25,
                      paddingRight: 0
                    }}
                  >
                    <LeadMainPhoto
                      property={property}
                      height={50}
                      style={{
                        width: 50
                      }}
                    />
                  </Wrapper>
                );
              }}
              renderRight={() => {
                return (
                  <Row>
                    <InlineButton
                      tooltip={"View Property"}
                      tooltipPlacement={"top"}
                      onPress={() => {
                        this.props.togglePopover(false);
                        this.props.pushSidePanel({
                          slug: "property",
                          focus_side_panel: this.props.expanded
                            ? this.props.expanded
                            : this.props.user?.user_settings
                                ?.property_expanded === "not_expanded"
                            ? false
                            : true,
                          id: property?.property_id,
                          overlay: true,
                          data: {
                            property: property
                          }
                        });
                      }}
                      icon="launch"
                    >
                      View
                    </InlineButton>
                  </Row>
                );
              }}
            >
              {formatted_address.line1}
            </SelectItem>
            <CloseButton
              onPress={() => {
                this.props.togglePopover(false);
                this.props.onClose();
              }}
            />
          </Wrapper>
        </>
      );
    }
    return null;
  }
}

export default CompsProperties;
