import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  RESET_PHOTO,
  CANCEL_IMAGE_PICKER,
  UPLOAD_ANIMATION,
  UPLOAD_EDIT_ANIMATION,
  UPLOAD_PROFILE_ANIMATION,
  UPDATE_SIGNATURE_ANIMATION,
  TOGGLE_CAMERA,
  RELOAD_PREVIEWS,
  UPLOAD_EDIT_PHOTO_SUCCESS,
  UPDATE_HOUSE_FAIL,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_EDIT_PROGRESS,
  UPLOAD_PROGRESS,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  UPLOAD_SIGNATURE_PHOTO_SUCCESS,
  RESET_UPLOAD_LIST,
  CHANGE_UPLOAD_LIST_FIELD,
  TRIGGER_ACTIVITY_UPDATE,
  UPLOAD_LIST_SUCCESS,
  UPLOAD_LIST_MAPPING_SUCCESS,
  EDIT_BULK_UPLOAD_INFO,
  UPLOAD_TEMPLATE_PHOTO_SUCCESS,
  SAVE_TEMPLATE_FAIL,
  TEMPLATE_FIELD_CHANGED,
  UPDATE_HOUSE_IMAGE,
  UPDATE_HOUSE_ADDITIONAL_IMAGES,
  SET_HOUSE_ADDITIONAL_IMAGE_LOADING,
  UPDATE_HOUSE_MARKETING_IMAGE,
  DELETE_HOUSE_IMAGE,
  TOGGLE_BULK_UPLOAD_MODAL
} from "app/DealMachineCore/types";

import { appRedirect, AppConfig } from "app/NativeActions";

import axios from "axios";
import moment from "moment";
import { parse } from "json2csv";
import fileDownload from "js-file-download";

import API from "app/DealMachineCore/apis/DealMachineAPI";
const api = API.create();

const randomString = (len, charSet) => {
  charSet =
    charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
};

/* blank from mobile functions so the web doesn't break */

export const resetPhoto = () => {
  return {
    type: RESET_PHOTO
  };
};

export const cancelImagePicker = () => {
  return {
    type: CANCEL_IMAGE_PICKER
  };
};
export const uploadAnimation = () => {
  return {
    type: UPLOAD_ANIMATION
  };
};
export const uploadEditAnimation = () => {
  return {
    type: UPLOAD_EDIT_ANIMATION
  };
};

export const uploadProfileAnimation = () => {
  return {
    type: UPLOAD_PROFILE_ANIMATION
  };
};

export const toggleCamera = toggle => {
  return {
    type: TOGGLE_CAMERA,
    payload: toggle
  };
};

/* end blank functions */

export const uploadFile = ({
  acceptedFiles,
  token,
  type = "edit_signature_pic", //legacy thing
  payload,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  onProgress = () => {}
}) => {
  return dispatch => {
    if (acceptedFiles) {
      let data = new FormData();

      onLoading();

      data.append("file", acceptedFiles);
      data.append("token", token);
      data.append("type", type);
      data.append("payload", JSON.stringify(payload));

      const axiosConfig = {
        onUploadProgress: progressEvent => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onProgress(progress);
        }
      };

      axios
        .post(AppConfig().api_url + "v2/upload/index.php", data, axiosConfig)
        .then(response => {
          if (response.problem != null) {
            onError(response.problem);
          } else if (response.data.error !== false) {
            onError(response.data.error);
          } else {
            //success!!
            onSuccess(response.data.results);
          }
        })
        .catch(error => {
          onError(error.data);
        });
    } else {
      onError(
        "Please select a valid file. Must be under 5mb and no special characters in the file name."
      );
    }
  };
};

export const uploadActivityFile = ({
  acceptedFiles,
  token,
  type = "activity_file",
  payload,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {},
  onProgress = () => {}
}) => {
  return dispatch => {
    if (acceptedFiles) {
      let data = new FormData();

      onLoading();

      data.append("file", acceptedFiles);
      data.append("token", token);
      data.append("type", type);
      data.append("payload", JSON.stringify(payload));

      const axiosConfig = {
        onUploadProgress: progressEvent => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onProgress(progress);
        }
      };

      axios
        .post(AppConfig().api_url + "v1/upload/index.php", data, axiosConfig)
        .then(response => {
          if (response.problem != null) {
            onError(response.problem);
          } else if (response.data.error !== false) {
            onError(response.data.error);
          } else {
            //success!!
            onSuccess(response.data.results);
          }
        })
        .catch(error => {
          onError(error.data);
        });
    } else {
      onError(
        "Please select a valid file. Must be under 5mb and no special characters in the file name."
      );
    }
  };
};

export const uploadList = ({
  acceptedFiles = [],
  token,
  type,
  payload,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    let data = new FormData();
    if (acceptedFiles.length > 0) {
      data.append("file", acceptedFiles[0]);
      data.append("token", token);
      data.append("type", type);
      data.append("payload", JSON.stringify(payload));

      axios
        .post(AppConfig().api_url + "v2/upload/index.php", data)
        .then(response => {
          if (response.problem != null) {
            if (onError) {
              onError();
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.problem, title: "Error" }
            });
          } else if (response.data.error !== false) {
            if (onError) {
              onError();
            }
            dispatch({
              type: ERROR_MESSAGE,
              payload: { message: response.data.error, title: "Error" }
            });
            if (response.data.valid === "false") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            //success!!
            dispatch({ type: IS_LOADING, payload: false });
            dispatch({
              type: UPLOAD_LIST_SUCCESS,
              payload: response.data.results
            });

            if (onSuccess) {
              onSuccess(response.data.results);
            } else {
              dispatch({
                type: TOGGLE_BULK_UPLOAD_MODAL,
                payload: true
              });
            }
          }
        })
        .catch(error => {
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: error.data, title: "Error" }
          });
        });
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        payload: {
          message:
            "Please select a valid file. Must be under 5mb and no special characters in the file name.",
          title: "Error"
        }
      });
    }
  };
};

export const editBulkUploadInfo = ({ prop, value }) => {
  return {
    type: EDIT_BULK_UPLOAD_INFO,
    payload: { prop, value }
  };
};

export const setUploadListHeaders = ({
  token,
  bulk_job_id,
  mapping,
  options,
  onLoading = null,
  onError = null,

  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    let data = new FormData();

    data.append("token", token);
    data.append("bulk_job_id", bulk_job_id);
    data.append("type", "bulk_list_map");
    data.append("mapping", mapping);
    data.append("options", options);

    axios
      .post(AppConfig().api_url + "v2/upload/index.php", data)
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data.error !== false) {
          if (onError) {
            onError();
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });
          if (response.data.valid === "false") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          //success!!
          dispatch({
            type: UPLOAD_LIST_MAPPING_SUCCESS
          });
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            dispatch({ type: IS_LOADING, payload: false });
          }
          /*
          dispatch({
            type: SUCCESS_MESSAGE,
            payload: {
              message:
                "Your list has been uploaded. You will be notified when all your leads are added to your account. This can take a couple minutes.",
              title: "Success!"
            }
          });
          */
        }
      })
      .catch(error => {
        dispatch({
          type: ERROR_MESSAGE,
          payload: { message: error.data, title: "Error" }
        });
      });
  };
};

export const selectPhoto = ({
  acceptedFiles = [],
  token,
  type,
  payload,
  deal_id = null,
  template_id = null
}) => {
  return dispatch => {
    dispatch({ type: UPLOAD_PHOTO, payload: true });

    let data = new FormData();
    if (acceptedFiles.length > 0) {
      data.append("file", acceptedFiles[0]);
      data.append("token", token);
      data.append("type", type);
      data.append("payload", JSON.stringify(payload));

      // include file extension in fileName
      if (payload) {
        payload.unique_string = `${
          payload.unique_string
        }.${acceptedFiles[0].name.split(".").pop()}`;
        data.append("payload", JSON.stringify(payload));

        if (payload.additional_image) {
          dispatch({
            type: UPDATE_HOUSE_ADDITIONAL_IMAGES,
            payload: {
              unique_string: payload.unique_string,
              use_for_marketing: true,
              loading: true,
              image: acceptedFiles[0].preview
            }
          });
        }
      }
      dispatch({ type: IS_LOADING, payload: true });
      axios
        .post(AppConfig().api_url + "v1/upload/index.php", data)
        .then(response => {
          if (response.problem != null) {
            selectPhotoFail(dispatch, response.problem);
          } else if (response.data.error != false) {
            selectPhotoFail(dispatch, response.data.error);
            if (response.data.valid == "false") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            dispatch({ type: IS_LOADING, payload: false });
            selectPhotoSuccess(
              dispatch,
              token,
              response.data.results,
              type,
              deal_id,
              template_id
            );
            if (payload.additional_image) {
              const fileResult = response.data.results;
              const fileNameParts = fileResult.file.split("/");
              const file_details = fileResult.file_details;
              const fileName = fileNameParts[fileNameParts.length - 1];

              dispatch({
                type: SET_HOUSE_ADDITIONAL_IMAGE_LOADING,
                payload: {
                  unique_string: fileName,
                  loading: false,
                  id: file_details.id,
                  image: fileResult.file
                }
              });
            } else {
              dispatch({
                type: UPDATE_HOUSE_IMAGE,
                payload: response.data.results.file
              });
            }
          }
        })
        .catch(error => {
          selectPhotoFail(dispatch, error.data);
        });
    } else {
      //handel error
      selectPhotoFail(dispatch, "Please select a file.");
    }
  };
};

export const editPhoto = ({
  property_image_id,
  house,
  token,
  type,
  imageURL
}) => {
  return dispatch => {
    const types = [
      "set_primary_house_image",
      "edit_image_marketing",
      "delete_deal_image"
    ];
    if (!types.includes(type))
      return console.warn("Invalid edit photo type.", { type });

    dispatch({ type: IS_LOADING, payload: true });
    dispatch({ type: UPLOAD_PHOTO, payload: true });

    axios
      .post(AppConfig().api_url + "v1/upload/edit/index.php", {
        token,
        type,
        property_image_id,
        house
      })
      .then(response => {
        dispatch({ type: IS_LOADING, payload: false });
        switch (type) {
          case "set_primary_house_image":
            dispatch({
              type: UPDATE_HOUSE_IMAGE,
              payload: imageURL
            });
            break;
          case "edit_image_marketing":
            dispatch({
              type: UPDATE_HOUSE_MARKETING_IMAGE,
              payload: property_image_id
            });
            break;
          case "delete_deal_image":
            dispatch({
              type: DELETE_HOUSE_IMAGE,
              payload: property_image_id
            });
            break;
        }
      });
  };
};

const selectPhotoFail = (dispatch, error) => {
  dispatch({ type: IS_LOADING, payload: false });
  dispatch({
    type: ERROR_MESSAGE,
    payload: { message: error, title: "Error" }
  });
};

const selectPhotoSuccess = (
  dispatch,
  token,
  results,
  type,
  deal_id,
  template_id
) => {
  switch (type) {
    case "new_house":
    default:
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({ type: UPLOAD_PHOTO_SUCCESS, payload: results.file });
      break;

    case "edit_house":
      if (deal_id && deal_id != 0) {
        //update the deal with a new photo
        api.editDeal(token, deal_id, "edit_photo", results.file).then(resp => {
          dispatch({ type: IS_LOADING, payload: false });

          if (resp.problem != null) {
            dispatch({ type: UPDATE_HOUSE_FAIL, payload: resp.problem });
          } else if (resp.data.error != false) {
            dispatch({ type: UPDATE_HOUSE_FAIL, payload: resp.data.error });
          } else {
            dispatch({
              type: RELOAD_PREVIEWS,
              payload: { date: moment().format("X") }
            });
            dispatch({
              type: UPLOAD_EDIT_PHOTO_SUCCESS,
              payload: { deal_id: deal_id, image: results.file }
            });

            dispatch({
              type: TRIGGER_ACTIVITY_UPDATE,
              payload: true
            });
          }
        });
      } else {
        dispatch({ type: IS_LOADING, payload: false });
      }

      break;

    case "edit_template_image":
      if (template_id && template_id != 0) {
        //update the template with a new photo
        api
          .saveTemplate(token, template_id, "edit_template_image", results.file)
          .then(response => {
            dispatch({ type: IS_LOADING, payload: false });

            if (response.problem != null) {
              dispatch({ type: SAVE_TEMPLATE_FAIL, payload: response.problem });
            } else if (response.data.error != false) {
              dispatch({
                type: SAVE_TEMPLATE_FAIL,
                payload: response.data.error
              });
            } else {
              dispatch({
                type: RELOAD_PREVIEWS,
                payload: { date: moment().format("X") }
              });
              dispatch({
                type: UPLOAD_TEMPLATE_PHOTO_SUCCESS,
                payload: {
                  template_id: template_id,
                  custom_image: results.file
                }
              });

              dispatch({
                type: TRIGGER_ACTIVITY_UPDATE,
                payload: true
              });
            }
          });
      } else {
        dispatch({ type: IS_LOADING, payload: false });
      }

      break;

    case "new_template_image":
      dispatch({
        type: TEMPLATE_FIELD_CHANGED,
        payload: { prop: "custom_image", value: results.file }
      });
      dispatch({ type: IS_LOADING, payload: false });

      break;

    case "edit_profile_pic":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({ type: UPLOAD_PROFILE_PHOTO_SUCCESS, payload: results.file });

      break;

    case "edit_signature_pic":
      dispatch({ type: IS_LOADING, payload: false });
      dispatch({ type: UPLOAD_SIGNATURE_PHOTO_SUCCESS, payload: results.file });

      break;

    case "bulk_list":
      dispatch({ type: IS_LOADING, payload: false });
      /*
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: {
          message:
            "Your list has been uploaded. You will be notified when all your deals are added to your account. This can take a couple minutes.",
          title: "Success!"
        }
      });
      */

      appRedirect({ dispatch, redirect: "deals" });

      break;
  }
};

export const resetUploadList = () => {
  return {
    type: RESET_UPLOAD_LIST
  };
};

export const changeUploadListField = ({ prop, value }) => {
  return {
    type: CHANGE_UPLOAD_LIST_FIELD,
    payload: { prop, value }
  };
};

export const resetUploadProgress = deal_id => {
  if (deal_id) {
    return {
      type: UPLOAD_EDIT_PROGRESS,
      payload: {
        progress: 0,
        deal_id: deal_id
      }
    };
  } else {
    return {
      type: UPLOAD_PROGRESS,
      payload: {
        progress: 0
      }
    };
  }
};

// @param: fields | ex ['field1', 'field2', 'field3']
// @param fileName | ex 'file.csv'
export const downloadJSONAsCSV = ({ data, fields, fileName }) => {
  const opts = { fields };

  try {
    const csv = parse(data, opts);
    fileDownload(csv, fileName);
  } catch (err) {
    console.error(err);
  }
};
