import React from "react";

const ColorOverlay = ({ type, has_media, children, style }) => {
  var className = "";

  switch (type) {
    default:
    case "White":
      className = "dm-website-white";
      break;

    case "Gray":
      className = "dm-website-gray";
      break;

    case "Blue":
      className = "dm-website-dark-color dm-website-blue-gradient";
      break;

    case "DarkBlue":
      className = "dm-website-dark-color dm-website-dark-blue-gradient";
      break;

    case "DarkGray":
      className = "dm-website-dark-color dm-website-dark-gray";
      break;

    case "Transparent":
      className = "dm-website-transparent";
      break;
    case "ClassicOverlay":
      className = "dm-website-classic-overlay";
      break;
  }

  if (has_media) {
    className += " dm-website-overlay";
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export { ColorOverlay };
