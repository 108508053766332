import React from "react";
import { store } from "app/store";

const Copy = ({ children, style, inline = false, className }) => {
  const colors = store.getState().settings.colors;

  if (inline)
    return (
      <span
        className={"deal-copy " + className}
        style={{
          color: colors.text_color,
          ...style
        }}
      >
        {children}
      </span>
    );
  return (
    <div
      className={"deal-copy " + className}
      style={{
        color: colors.text_color,
        ...style
      }}
    >
      {children}
    </div>
  );
};

export { Copy };
