import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Spin } from "app/NativeComponents/common";
import { SearchBar, IconButton } from "app/NativeComponents/snippets";
import {
  getAutocompleteMap,
  getRecentSearch,
  saveRecentSearch,
  getDeviceLocation,
  lockLocationTracking,
  selectActiveProperty,
  selectActiveCoordinates,
  selectActiveRoute,
  askLocationPermissionsThenContinue,
  dismissMobileKeyboard,
  replaceSidePanel,
  renderDate,
  setOnboardingAutoCompleteInProgress,
  pushSidePanel
} from "app/NativeActions";

class AutocompleteSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_location_loading: false,
      autocomplete_loading: false,
      autocomplete_items: [],
      recent_search: [],
      location_text: "",
      search_wait: false,
      search_entered: false
    };
    this._location_text = React.createRef();

    this.triggerAutocomplete = this.triggerAutocomplete.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  componentDidMount() {
    this.props.getRecentSearch({
      token: this.props.token,
      search_type: "map",
      onSuccess: results => {
        this.setState({
          recent_search: results.recent_search
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.search_wait &&
      this.state.autocomplete_items !== prevState.autocomplete_items &&
      this.state.autocomplete_items?.length > 0
    ) {
      if (
        this.state.search_entered &&
        this.state.autocomplete_items &&
        !this.state.autocomplete_loading
      ) {
        const item = this.state.autocomplete_items[0];

        switch (item.result_type) {
          default:
            if (this.props.user) {
              this.props.saveRecentSearch({
                token: this.props.token,
                search_type: "map",
                full_text: item.property_address_full,
                search_text: this.state.location_text,
                property_id: item.property_id,
                lead_id: item?.deal?.id,
                result_type: "property",
                data: item,
                latitude: item.location?.latitude,
                longitude: item.location?.longitude,
                onSuccess: results => {
                  this.setState({
                    recent_search: results.recent_search
                  });
                }
              });
            }

            this.setState(
              {
                location_text: item.property_address_full,
                autocomplete_loading: false,
                autocomplete_items: []
              },
              () => {
                this.props.updateLatLng({
                  longitude: item.location?.longitude,
                  latitude: item.location?.latitude,
                  heading: 0,
                  pitch: 0,
                  zoom: 18,
                  property: item,
                  location_type: item.location_type
                });
              }
            );
            break;

          case "mapbox":
            if (this.props.user) {
              this.props.saveRecentSearch({
                token: this.props.token,
                search_type: "map",
                full_text: item.full_address,
                search_text: this.state.location_text,
                property_id: null,
                lead_id: null,
                result_type: "google",
                data: item,
                latitude: item.latitude,
                longitude: item.longitude,
                onSuccess: results => {
                  this.setState({
                    recent_search: results.recent_search
                  });
                }
              });
            }

            this.setState(
              {
                location_text: item.full_address,
                autocomplete_loading: false,
                autocomplete_items: []
              },
              () => {
                this.props.updateLatLng({
                  longitude: item.longitude,
                  latitude: item.latitude,
                  zoom: 16,
                  heading: 0,
                  pitch: 0,
                  location_type: item.location_type
                });
              }
            );
            break;
        }
        this.setState({ search_entered: false });
      }
      this.setState({ search_wait: false });
    }
  }

  triggerAutocomplete(value) {
    if (value.length > 2) {
      clearInterval(this._search_interval);
      this._search_interval = setTimeout(() => {
        this.props.getAutocompleteMap({
          token: this.props.token,
          search: value,
          centerLat: this.props.centerLat,
          centerLng: this.props.centerLng,
          onLoading: () => {
            this.setState({
              autocomplete_loading: true,
              autocomplete_items: []
            });
          },
          onError: () => {
            this.setState({
              autocomplete_loading: false
            });
          },
          onSuccess: results => {
            this.setState({
              autocomplete_loading: false,
              autocomplete_items: results.properties
            });

            if (results?.properties.length > 0) {
              this.props.setOnboardingAutoCompleteInProgress(true);
              this.setState({ search_entered: false });
            }
          }
        });
      }, 750);
    } else {
      this.props.setOnboardingAutoCompleteInProgress(false);
      this.setState({
        autocomplete_loading: false,
        autocomplete_items: []
      });
    }
  }

  render() {
    const {
      isMobile,
      style,
      placeholder = "Search by city or zip code",
      borderRadius = 0
    } = this.props;
    const {
      location_text,
      autocomplete_items,
      recent_search,
      autocomplete_loading
    } = this.state;

    let items = [];

    items.push({
      value: "",
      title: "Current Location",
      primary: true,
      //loading: this.state.current_location_loading,
      onPress: () => {
        this.props.askLocationPermissionsThenContinue({
          onSuccess: () => {
            getDeviceLocation({
              onLoading: () => {
                this.setState({
                  current_location_loading: true
                });
              },
              onError: () => {
                this.setState({
                  current_location_loading: false
                });
              },
              onSuccess: results => {
                this.props.updateLatLng({
                  longitude: results.longitude,
                  latitude: results.latitude,
                  location_type: "current_location"
                });
                this.setState({
                  current_location_loading: false,
                  location_text: ""
                });
              }
            });
          }
        });

        dismissMobileKeyboard();
        this.props.updateSearchFocus(false);
      }
    });
    let count = 0;
    let count_recent = 0;
    if (
      autocomplete_items &&
      autocomplete_items.length > 0 &&
      !!location_text
    ) {
      count = autocomplete_items.length;
      if (count > 5) {
        count = 5;
      }
      for (let i = 0; i < count; i++) {
        const item = autocomplete_items[i];
        let location_type = "address";

        switch (item.result_type) {
          case "mapbox":
            if (item?.type === "postcode") {
              location_type = "zip";
            } else if (item?.type === "place") {
              location_type = "city";
            }

            let mapbox_title = item.title;
            let mapbox_subtitle = item.title;
            if (item?.type == "address" && item.parsed_address) {
              mapbox_title = "";
              mapbox_subtitle = "";

              mapbox_title = item.parsed_address?.address1;
              if (item.parsed_address?.address2) {
                mapbox_title += " " + item.parsed_address?.address2;
              }
              if (item.parsed_address?.city) {
                mapbox_subtitle = item.parsed_address?.city;
              }

              if (item.parsed_address?.state) {
                if (!!mapbox_subtitle) {
                  mapbox_subtitle += ", " + item.parsed_address?.state;
                } else {
                  mapbox_subtitle = item.parsed_address?.state;
                }
              }

              if (item.parsed_address?.zip) {
                if (!!mapbox_subtitle) {
                  mapbox_subtitle += " " + item.parsed_address?.zip;
                } else {
                  mapbox_subtitle = item.parsed_address?.zip;
                }
              }
            }

            items.push({
              value: "",
              title: mapbox_title,
              subtitle: mapbox_subtitle,
              location_type: location_type,
              onPress: () => {
                if (this.props.user) {
                  this.props.saveRecentSearch({
                    token: this.props.token,
                    search_type: "map",
                    full_text: item.title,
                    search_text: this.state.location_text,
                    property_id: item?.property?.property_id,
                    lead_id: null,
                    result_type: "mapbox",
                    data: item,
                    latitude: item?.latitude,
                    longitude: item?.longitude,
                    location_type: location_type,
                    onSuccess: results => {
                      this.setState({
                        recent_search: results?.recent_search
                      });
                    }
                  });
                }

                this.setState(
                  {
                    location_text: item.title,
                    autocomplete_loading: false,
                    autocomplete_items: []
                  },
                  () => {
                    this.props.updateLatLng({
                      longitude: item?.longitude,
                      latitude: item?.latitude,
                      heading: 0,
                      pitch: 0,
                      zoom:
                        location_type === "city" || location_type === "zip"
                          ? 11
                          : 18,
                      property: item,
                      location_type: location_type,
                      add_location:
                        (location_type === "city" &&
                          item?.parsed_address?.city) ||
                        (location_type === "zip" && item?.parsed_address?.zip)
                          ? {
                              type: location_type,
                              value:
                                location_type === "city"
                                  ? item?.parsed_address?.city
                                  : item?.parsed_address?.zip,
                              label:
                                location_type === "city"
                                  ? item?.parsed_address?.city
                                  : item?.parsed_address?.zip,
                              value2: item?.parsed_address?.state
                            }
                          : null
                    });

                    if (item?.property) {
                      this.props.selectActiveProperty(
                        this.props.token,
                        item?.property
                      );

                      if (
                        this.props.and_push_property &&
                        !this.props.isMobile &&
                        !this.props.isOnboardingActive &&
                        !this.props.onboarding_map
                      ) {
                        this.props.replaceSidePanel({
                          slug: "property",
                          id: item?.property?.property_id,
                          focus_side_panel:
                            this.props.user?.user_settings
                              ?.property_expanded === "not_expanded"
                              ? false
                              : true,
                          data: {
                            property: item?.property
                          }
                        });
                      }
                    }
                  }
                );
              }
            });
            break;
          default:
            items.push({
              value: "",
              title: item.property_address + " " + item.property_address2,
              subtitle:
                item.property_address_city +
                ", " +
                item.property_address_state +
                " " +
                item.property_address_zip,
              location_type: "address",
              onPress: () => {
                if (this.props.user) {
                  this.props.saveRecentSearch({
                    token: this.props.token,
                    search_type: "map",
                    full_text: item.property_address_full,
                    search_text: this.state.location_text,
                    property_id: item.property_id,
                    lead_id: item?.deal?.id,
                    result_type: "property",
                    data: item,
                    latitude: item.location?.latitude,
                    longitude: item.location?.longitude,
                    location_type: location_type,

                    onSuccess: results => {
                      this.setState({
                        recent_search: results.recent_search
                      });
                    }
                  });
                }

                this.setState(
                  {
                    location_text: item.property_address_full,
                    autocomplete_loading: false,
                    autocomplete_items: []
                  },
                  () => {
                    this.props.updateLatLng({
                      longitude: item.location?.longitude,
                      latitude: item.location?.latitude,
                      heading: 0,
                      pitch: 0,
                      zoom: 18,
                      property: item,
                      location_type: "address"
                    });
                  }
                );
              }
            });
            break;

          case "google_address":
            if (item?.type && item?.type?.length > 0) {
              if (item.type[0] == "postal_code") {
                location_type = "zip";
              } else if (item.type[0] == "locality") {
                location_type = "city";
              }
            }

            items.push({
              value: "",
              title: !!item.name
                ? item.name
                : item.full_address
                ? item.full_address
                : "",
              subtitle: !!item.name
                ? item.full_address
                  ? item.full_address
                  : ""
                : "",
              location_type,
              onPress: () => {
                if (this.props.user) {
                  this.props.saveRecentSearch({
                    token: this.props.token,
                    search_type: "map",
                    full_text: item.full_address,
                    search_text: this.state.location_text,
                    property_id: null,
                    lead_id: null,
                    result_type: "google",
                    data: item,
                    latitude: item.latitude,
                    longitude: item.longitude,
                    location_type: location_type,

                    onSuccess: results => {
                      this.setState({
                        recent_search: results.recent_search
                      });
                    }
                  });
                }

                this.setState(
                  {
                    location_text: item?.full_address,
                    autocomplete_loading: false,
                    autocomplete_items: []
                  },
                  () => {
                    this.props.updateLatLng({
                      longitude: item.longitude,
                      latitude: item.latitude,
                      zoom:
                        location_type === "zip" || location_type === "city"
                          ? 11
                          : 16,
                      heading: 0,
                      pitch: 0,
                      location_type: location_type
                    });
                  }
                );
              }
            });
            break;
        }
      }
    } else if (recent_search && recent_search.length > 0) {
      count = recent_search.length;
      if (count > 5) {
        count = 5;
      }
      count_recent = count;

      for (let i = 0; i < count; i++) {
        const item = recent_search[i];

        let location_type = "address";

        if (item.result_type == "mapbox") {
          if (item?.data?.type) {
            if (item?.data?.type == "postcode") {
              location_type = "zip";
            } else if (item?.data?.type == "place") {
              location_type = "city";
            }
          }
        } else if (item.result_type == "google") {
          if (item?.data?.type && item?.data?.type?.length > 0) {
            if (item?.data?.type[0] == "postal_code") {
              location_type = "zip";
            } else if (item?.data?.type[0] == "locality") {
              location_type = "city";
            }
          }
        }

        let parsed_address = item?.data?.parsed_address;

        items.push({
          value: "",
          title: item.full_text,
          subtext: "Searched " + renderDate(item.date_created),
          location_type,
          onPress: () => {
            this.setState(
              {
                location_text: item.full_text,
                autocomplete_loading: false,
                autocomplete_items: []
              },
              () => {
                this.props.updateLatLng({
                  longitude: parseFloat(item?.longitude),
                  latitude: parseFloat(item?.latitude),
                  heading: 0,
                  pitch: 0,
                  zoom:
                    location_type === "city" || location_type === "zip"
                      ? 11
                      : 18,
                  location_type: location_type,
                  add_location:
                    (location_type === "city" && parsed_address?.city) ||
                    (location_type === "zip" && parsed_address?.zip)
                      ? {
                          type: location_type,
                          value:
                            location_type === "city"
                              ? parsed_address?.city
                              : parsed_address?.zip,
                          label:
                            location_type === "city"
                              ? parsed_address?.city
                              : parsed_address?.zip,
                          value2: parsed_address?.state
                        }
                      : null
                });
                const data = item?.data;
                if (data?.property) {
                  this.props.selectActiveProperty(
                    this.props.token,
                    data?.property
                  );

                  if (
                    this.props.and_push_property &&
                    !this.props.isMobile &&
                    !this.props.isOnboardingActive &&
                    !this.props.onboarding_map
                  ) {
                    this.props.replaceSidePanel({
                      slug: "property",
                      id: data?.property?.property_id,
                      focus_side_panel:
                        this.props.user?.user_settings?.property_expanded ===
                        "not_expanded"
                          ? false
                          : true,
                      data: {
                        property: data?.property
                      }
                    });
                  }
                }
              }
            );
          }
        });
      }
    }

    if (this.props.include_add_lead_button && this.props.user) {
      items.push({
        value: "",
        title: "Manually Add Lead",
        primary: false,
        //loading: this.state.current_location_loading,
        onPress: () => {
          this.props.pushSidePanel({
            slug: "manually_add_lead",
            overlay: true
          });

          dismissMobileKeyboard();
          this.props.updateSearchFocus(false);
        }
      });
    }

    return (
      <SearchBar
        input_ref={this._location_text}
        title={!!location_text ? placeholder : placeholder}
        value={location_text}
        onChange={value => {
          this.setState({
            location_text: value,
            search_wait: true
          });
          this.triggerAutocomplete(value);
        }}
        items={this.props.search_focused ? items : null}
        loading={autocomplete_loading}
        borderRadius={borderRadius}
        isOnboardingActive={this.props.isOnboardingActive}
        style={{
          margin: isMobile ? 0 : 5,
          marginLeft: isMobile ? 0 : 10,
          borderWidth: isMobile ? 0 : 1,
          borderBottomWidth: isMobile ? 0 : 1,
          ...style
        }}
        onPress={e => {
          if (!this.state.search_wait && items[count_recent + 1]) {
            items[count_recent + 1].onPress();
          } else if (this.state.search_wait) {
            this.setState({ search_entered: true });
          }
        }}
        onFocus={() => {
          if (
            items &&
            items.length > 0 &&
            location_text &&
            location_text.length > 2
          ) {
            this.props.setOnboardingAutoCompleteInProgress(true);
          }
          this.props.selectActiveProperty(this.props.token, null);
          this.props.selectActiveCoordinates(null);
          this.props.selectActiveRoute(null);
          this.props.updateSearchFocus(true);
        }}
        onBlur={() => {
          this.props.updateSearchFocus(false);
          this.props.setOnboardingAutoCompleteInProgress(false);
        }}
        renderRight={
          this.state.current_location_loading
            ? () => {
                return (
                  <Wrapper
                    style={{
                      width: 30,
                      margin: 5,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Spin size="small" color={this.props.colors.text_color} />
                  </Wrapper>
                );
              }
            : this.props.search_focused
            ? () => {
                return (
                  <IconButton
                    icon={"close"}
                    button_type="small"
                    onPress={() => {
                      if (!!this.state.location_text) {
                        this.setState({
                          location_text: ""
                        });
                      } else {
                        if (this._location_text?.current?.blur) {
                          this._location_text.current.blur();
                        }
                        this.props.updateSearchFocus(false);
                      }
                    }}
                  />
                );
              }
            : null
        }
      />
    );
  }
}

const mapStateToProps = ({ auth, settings, native, map, onboarding }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { isMobile, device } = native;
  const { lock_location_tracking } = map;
  const { isOnboardingActive } = onboarding;
  return {
    token,
    user,
    colors,
    isMobile,
    device,
    lock_location_tracking,
    isOnboardingActive
  };
};

export default connect(mapStateToProps, {
  getAutocompleteMap,
  getRecentSearch,
  saveRecentSearch,
  lockLocationTracking,
  selectActiveProperty,
  selectActiveCoordinates,
  selectActiveRoute,
  askLocationPermissionsThenContinue,
  replaceSidePanel,
  setOnboardingAutoCompleteInProgress,
  pushSidePanel
})(AutocompleteSearch);
