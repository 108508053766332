import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  KeyboardView,
  Wrapper,
  Title
} from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import {
  /*mobile*/
  toggleCanPop,
  authFieldChanged,
  forgotPassword,
  appRedirect,
  popSidePanel,
  startRedirect,
  /*common functions*/
  dismissMobileKeyboard
} from "app/NativeActions";

import Body from "./Body";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    /* mobile */
    dismissMobileKeyboard();
    if (this.props.device == "mobile") {
      this.props.popSidePanel();
    } else {
      this.props.startRedirect("/login");
    }
  }

  forgot() {
    /* mobile */
    dismissMobileKeyboard();

    const { email } = this.props;
    this.props.forgotPassword({
      email,
      onLoading: () => {
        this.setState({ loading: true });
      },
      onError: () => {
        this.setState({ loading: false });
      },
      onSuccess: () => {
        this.setState({ loading: false });
        this.handleBack();
      }
    });
  }

  checkIfNeedsToSave() {
    if (this.props.email && this.props.email != "") {
      return true;
    }

    return false;
  }

  render() {
    return (
      <Container>
        {this.props.device == "mobile" ? (
          <NewHeader
            title="Forgot Password"
            leftButton={{
              onPress: this.handleBack,
              icon: "arrow-back"
            }}
          />
        ) : null}

        <KeyboardView style={{ flex: 1 }}>
          <Body
            {...this.props}
            forgot={this.forgot.bind(this)}
            checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
            handleBack={this.handleBack}
            loading={this.state.loading}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native }) => {
  const { email } = auth;
  const { device } = native;
  return {
    email,
    device
  };
};

export default connect(mapStateToProps, {
  /*mobile*/
  toggleCanPop,

  authFieldChanged,
  popSidePanel,
  forgotPassword,
  startRedirect
})(ForgotPassword);
