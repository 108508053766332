import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Row,
  Card,
  Copy,
  Bold,
  Button,
  Icon,
  ExternalImage
} from "app/NativeComponents/common";
import { PhotoOverlay } from "app/NativeComponents/snippets";

import { appRedirect, renderDate } from "app/NativeActions";

import MailerTimeline from "./MailerTimeline";

class MailerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderImage(image, height, width) {
    if (this.props.device == "desktop") {
      return (
        <PhotoOverlay
          image={image}
          use_full={true}
          height={height}
          width={width}
          outerStyle={{
            borderWidth: 1,
            borderColor: this.props.colors.border_color,
            borderStyle: "solid"
          }}
          backgroundColor="transparent"
        />
      );
    }

    return (
      <ExternalImage
        style={{
          width,
          height,
          borderWidth: 1,
          borderColor: this.props.colors.border_color,
          borderStyle: "solid"
        }}
        image={image}
      />
    );
  }

  renderScreenShots() {
    if (
      this.props.mailer.screenshot_front &&
      this.props.mailer.screenshot_front != "" &&
      this.props.mailer.screenshot_back &&
      this.props.mailer.screenshot_back != ""
    ) {
      const width = 150;
      const height = width * 0.68;

      let front_height = width * 0.68;

      switch (this.props.mailer.template_type) {
        case "postcard":
        default:
          front_height = width * 0.68;
          break;
        case "handwritten":
          front_height = width * 1.3;
          break;
      }

      return (
        <Row
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5,
            paddingBottom: 5
          }}
        >
          <Wrapper style={{ padding: 5 }}>
            {this.renderImage(
              this.props.mailer.screenshot_front,
              front_height,
              width
            )}
          </Wrapper>
          <Wrapper style={{ padding: 5 }}>
            {this.renderImage(this.props.mailer.screenshot_back, height, width)}
          </Wrapper>
        </Row>
      );
    }
  }

  render() {
    if (this.props.mailer) {
      return (
        <Wrapper
          style={{
            padding: 20,
            borderBottomWidth: 1,
            borderBottomColor: this.props.colors.border_color,
            borderBottomStyle: "solid"
          }}
        >
          <Wrapper>
            <Copy>
              Sent to <Bold>{this.props.mailer.lead_address}</Bold> on{" "}
              <Bold>{renderDate(this.props.mailer.date_created, true)}</Bold>
            </Copy>
            {this.props.mailer.property ? (
              <Button onPress={this.props.onPropertyPress}>
                <Row>
                  <Copy>View Lead</Copy>
                  <Icon
                    style={{ marginLeft: 5 }}
                    size={14}
                    icon="keyboard-arrow-right"
                  />
                </Row>
              </Button>
            ) : (
              <Copy style={{ color: "red" }}>Lead Removed</Copy>
            )}
          </Wrapper>
          {this.renderScreenShots()}
          <MailerTimeline
            mailer={this.props.mailer}
            billing_addons={this.props.billing_addons}
            user={this.props.user}
          />
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

const mapStateToProps = ({ auth, settings, billing, native }) => {
  const { token, user } = auth;
  const { colors } = settings;

  const { device, isMobile } = native;
  const { billing_addons } = billing;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    billing_addons
  };
};

export default connect(mapStateToProps, {
  appRedirect
})(MailerItem);
