import React, { PureComponent } from "react";

class Video extends PureComponent {
  componentDidMount() {
    if (this.props.autoPlay) {
      this.refs.video.play();
    }
  }

  render() {
    return (
      <video
        ref={"video"}
        className={this.props.cover ? "video-cover" : ""}
        width={this.props.width ? this.props.width : 320}
        height={this.props.height ? this.props.height : 180}
        autoPlay={this.props.autoPlay}
        loop={this.props.loop}
        muted={this.props.muted}
        controls={this.props.controls}
        poster={this.props.poster}
      >
        <source src={this.props.video} type="video/mp4" />
      </video>
    );
  }
}

export { Video };
