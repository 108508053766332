import React, { Component } from "react";

import {
  Wrapper,
  Card,
  CardBody,
  Row,
  Title,
  Icon,
  Copy,
  Bold,
  Button,
  Spin
} from "app/NativeComponents/common";

import CheckGoals from "../Goals/CheckGoals";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";

import moment from "moment";
import { numberWithCommas } from "app/NativeActions";
class CompletedSteps extends Component {
  componentDidMount() {
    //get goals and calculate tasks on api
    this.props.getDashboardSettings({
      token: this.props.token,
      type: "dashboard_goals"
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.dashboard_goals !== this.props.user.dashboard_goals) {
      this.props.getDashboardSettings({
        token: this.props.token,
        type: "dashboard_goals"
      });
    }
  }

  renderQuote() {
    let quote_slug = "family";

    if (this.props.user.dashboard_goals) {
      switch (this.props.user.dashboard_goals.goal_1) {
        case "family":
        default:
          quote_slug = "family";
          break;

        case "vaction":
          quote_slug = "dashboardVacation";
          break;
        case "friends":
          quote_slug = "dashboardFriends";
          break;
        case "supplement":
          quote_slug = "dashboardFranchise";
          break;

        case "scale":
          quote_slug = "dashboardBetter";
          break;

        case "time":
          quote_slug = "dashboardTime";
          break;
        case "leads":
          quote_slug = "dashboardLeads";
          break;
      }
    }

    return (
      <OnboardingText
        center={this.props.isMobile ? true : false}
        slug={quote_slug}
        style={{ maxWidth: 600, marginBottom: 20 }}
      />
    );
  }

  renderDashboardProgress() {
    if (this.props.dashboard_settings_loading) {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignSelf: "stretch",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Row style={{ padding: 25 }}>
              <Spin size="small" />
              <Copy style={{ marginLeft: 10 }}>Loading...</Copy>
            </Row>
          </Wrapper>

          <Wrapper style={{ alignSelf: "stretch" }}>
            <Copy>To achieve greater success,</Copy>
            <OnboardingText slug="dashboardTips" />
          </Wrapper>
        </Wrapper>
      );
    }

    if (
      this.props.dashboard_goals &&
      this.props.dashboard_goals.completed_goal
    ) {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: 25,
            alignSelf: "stretch"
          }}
        >
          <Wrapper
            style={{
              alignSelf: "stretch",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={this.props.colors.success_color}
              size={32}
              icon="check-circle"
              style={{ padding: 15 }}
            />
            <Title style={{ maginBottom: 10, textAlign: "center" }}>
              Nice Job!
            </Title>
            <Copy style={{ textAlign: "center" }}>
              You've added enough properties and sent enough mail to achieve
              your goals! You're on your way to cashing that check. Reset your
              goals to keep going!
            </Copy>
          </Wrapper>
          <Wrapper style={{ alignSelf: "stretch" }}>
            <Copy>To achieve greater success,</Copy>
            <OnboardingText slug="dashboardTips" />
          </Wrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper
        style={{
          padding: 25,
          alignSelf: "stretch"
        }}
      >
        <Wrapper>
          <Wrapper>
            <Copy>Based on your goals</Copy>
            <Title>Accomplish These Tasks:</Title>
          </Wrapper>
          <Wrapper style={{ padding: 15, paddingRight: 0, paddingLeft: 0 }}>
            <Row>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon
                  icon="drive-eta"
                  size={32}
                  color={this.props.colors.active_color}
                />
              </Wrapper>
              <Wrapper>
                <Copy>
                  <Bold>Drive</Bold>{" "}
                  {this.props.dashboard_goals
                    ? this.props.dashboard_goals.miles_to_drive == 1
                      ? "1 more mile"
                      : this.props.dashboard_goals.miles_to_drive <= 0
                      ? "0 more miles"
                      : parseFloat(
                          this.props.dashboard_goals.miles_to_drive
                        ).toFixed(1) + " more miles"
                    : "0 more miles"}
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>
          <Wrapper style={{ padding: 15, paddingRight: 0, paddingLeft: 0 }}>
            <Row>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon
                  icon="home"
                  size={32}
                  color={this.props.colors.active_color}
                />
              </Wrapper>
              <Wrapper>
                <Copy>
                  <Bold>Add</Bold>{" "}
                  {this.props.dashboard_goals
                    ? this.props.dashboard_goals.leads_to_add == 1
                      ? "1 more property"
                      : this.props.dashboard_goals.leads_to_add <= 0
                      ? "0 more properties"
                      : numberWithCommas(
                          this.props.dashboard_goals.leads_to_add
                        ) + " more properties"
                    : "0 more properties"}
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>
          <Wrapper style={{ padding: 15, paddingRight: 0, paddingLeft: 0 }}>
            <Row>
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: 50
                }}
              >
                <Icon
                  icon="mail-outline"
                  size={32}
                  color={this.props.colors.active_color}
                />
              </Wrapper>
              <Wrapper>
                <Copy>
                  <Bold>Start</Bold>{" "}
                  {this.props.dashboard_goals
                    ? this.props.dashboard_goals.mailers_to_start == 1
                      ? "1 more mail piece"
                      : this.props.dashboard_goals.mailers_to_start <= 0
                      ? "0 more mail pieces"
                      : numberWithCommas(
                          this.props.dashboard_goals.mailers_to_start
                        ) + " more mail pieces"
                    : "0 more mail pieces"}
                </Copy>
              </Wrapper>
            </Row>
          </Wrapper>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 5,
              paddingBottom: 20
            }}
          >
            <Button
              onPress={() => {
                this.props.getDashboardSettings({
                  token: this.props.token,
                  type: "dashboard_goals"
                });
              }}
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Row>
                <Icon icon="refresh" size={14} style={{ marginRight: 5 }} />
                <Copy>Refresh</Copy>
              </Row>
            </Button>
          </Wrapper>
        </Wrapper>
        <Wrapper>
          <Copy>To achieve greater success,</Copy>
          <OnboardingText slug="dashboardTips" />
        </Wrapper>
      </Wrapper>
    );
  }

  render() {
    if (
      !this.props.is_enterprise &&
      this.props.user?.completed_dashboard_steps === true
    ) {
      return (
        <Card>
          <Wrapper>
            <Wrapper style={{ padding: 25 }}>
              <Title>Let's Achieve Success Driving for Dollars</Title>
            </Wrapper>
          </Wrapper>
          <Wrapper
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: this.props.dashboardMobile ? "column" : "row"
              }}
            >
              <Wrapper
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 20
                }}
              >
                {this.renderQuote()}
                <CheckGoals save_mode={"confirm"} edit_goals={false} />
              </Wrapper>
              {this.renderDashboardProgress()}
            </Wrapper>
          </Wrapper>
        </Card>
      );
    }

    return <Wrapper />;
  }
}

export default CompletedSteps;
