import React, { Component } from "react";

import { Card, Row } from "app/NativeComponents/common";
import { InformationItem, InlineButton } from "app/NativeComponents/snippets";
import moment from "moment";
import { formatAddress } from "app/NativeActions";

class BasicInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      more_info: false
    };
  }

  render() {
    const { person } = this.props;

    let formated_birthday = "";
    let age = "";

    if (!!person?.mob) {
      //if string ends in 00, replace with 01
      let birthday = person.mob;
      birthday = birthday.slice(0, -2) + "-" + birthday.slice(-2);

      if (birthday === "000000") {
        birthday = "";
        formated_birthday = "";
      } else if (birthday.slice(-2) === "00") {
        birthday = birthday.slice(0, -2);
        birthday += "01";
        formated_birthday = moment(birthday).format("MMMM YYYY");
      } else {
        formated_birthday = moment(birthday).format("MMMM YYYY");
      }

      //calculate age in years
      if (!!birthday) {
        age = moment().diff(birthday, "years");
      }
    }
    const mailing_address = formatAddress({
      address: {
        address: person.primary_address,
        address_city: person.primary_city,
        address_state: person.primary_state,
        address_zip: person.primary_zip
      }
    });

    const previous_address = formatAddress({
      address: {
        address: person.address_previous,
        address_city: person.city_previous,
        address_state: person.state_previous,
        address_zip: person.zip_previous
      }
    });
    return (
      <Card
        label={"Basic Information:"}
        id={"basic-information"}
        style={{ paddingTop: 10, marginTop: 15 }}
        onLayout={event => {
          const { x, y, width, height } = event?.nativeEvent?.layout;
          this.setState({
            offset: y,
            height: height
          });
        }}
      >
        <Row
          style={
            this.props.expanded
              ? {
                  flexDirection: "row",
                  alignItems: "stretch",
                  flexWrap: "wrap"
                }
              : { flexDirection: "column", alignItems: "stretch" }
          }
        >
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={""}
            item={formated_birthday}
            label={"Date of birth:"}
            hiddenFromNonSubs={true}
          />
          <InformationItem
            style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
            search={""}
            item={!!age ? age + " years old" : ""}
            label={"Age:"}
            hiddenFromNonSubs={true}
          />
          {this.state.more_info ? (
            <>
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.gender}
                label={"Gender:"}
                hiddenFromNonSubs={true}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.marital_status}
                label={"Marital status:"}
                hiddenFromNonSubs={true}
                helper={"marital_status"}
              />
              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={person?.language_preference}
                label={"Language preference:"}
                hiddenFromNonSubs={true}
                helper={"language_preference"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={
                  !!person?.primary_address
                    ? mailing_address.line1 + ", " + mailing_address.line2
                    : ""
                }
                label={"Mailing address:"}
                hiddenFromNonSubs={true}
                helper={"mailing_address"}
              />

              <InformationItem
                style={this.props.expanded ? { width: "50%" } : { flex: 1 }}
                search={""}
                item={
                  !!person?.address_previous
                    ? previous_address.line1 + ", " + previous_address.line2
                    : ""
                }
                label={"Previous address:"}
                hiddenFromNonSubs={true}
                helper={"previous_address"}
              />
            </>
          ) : null}
        </Row>

        {this.state.more_info ? (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: false
                },
                () => {
                  if (this.props.device === "desktop") {
                    this.props.scrollToSection({
                      id: "basic-information",
                      offset: this.state.offset
                    });
                  } else {
                    this._interval = setTimeout(() => {
                      this.props.scrollToSection({
                        id: "basic-information",
                        offset: this.state.offset
                      });
                    }, 50);
                  }
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-up"}
          >
            Less Info
          </InlineButton>
        ) : (
          <InlineButton
            onPress={() => {
              this.setState(
                {
                  more_info: true
                },
                () => {
                  this.props.scrollToSection({
                    id: "basic-information",
                    offset: this.state.offset
                  });
                }
              );
            }}
            button_type={"full"}
            icon={"keyboard-arrow-down"}
          >
            More Info
          </InlineButton>
        )}
      </Card>
    );
  }
}

export default BasicInformation;
