import React, { Component } from "react";
import FlipOver from "react-flip-over";

class FlipOverCard extends Component {
  render() {
    const {
      flipped = false,
      width,
      height,
      frontSide = () => {},
      backSide = () => {}
    } = this.props;

    return (
      <FlipOver
        flipped={flipped}
        duration={0.35}
        width={width}
        height={frontSide}
        style={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {frontSide()}
        {backSide()}
      </FlipOver>
    );
  }
}
export { FlipOverCard };
