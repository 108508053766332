import React from "react";
import ToggleButton from "react-toggle-button";
import { store } from "app/store";

const SwitchButton = props => {
  const colors = store.getState().settings.colors;

  return (
    <div className="deal-switch-button" style={props.style}>
      <ToggleButton
        inactiveLabel={""}
        activeLabel={""}
        value={props.value}
        onToggle={props.onChange}
        colors={{
          active: {
            base: colors.success_color
          }
        }}
      />
    </div>
  );
};

export { SwitchButton };
