import React, { Component } from "react";
import { connect } from "react-redux";

import { Wrapper, Spin, Row } from "app/NativeComponents/common";
import { SpinWrapper, PillButton } from "app/NativeComponents/snippets";

import ChooseEquals from "./ChooseEquals";
import SearchObject from "./SearchObject";
import ShowcaseFilters from "./ShowcaseFilters";

import { getWorkflowInfo } from "app/NativeActions";

class AdvancedFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters.data || [],
      andor_type: props.filters.andor_type || "or",
      and_filter:
        !!this.props.open_filter && props.filters.data?.length === 1 ? 0 : null, //set to the first filter if only one filter is present and passing the open filter
      edit_filter: null,
      selecting_filter: false,
      selected_filter: !!this.props.open_filter ? this.props.open_filter : null,
      opened_menus: []
    };

    this.selectFilter = this.selectFilter.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFitler = this.removeFitler.bind(this);

    this.setAddFilter = this.setAddFilter.bind(this);
    this.setEditFilter = this.setEditFilter.bind(this);

    this.selectingFilterToggle = this.selectingFilterToggle.bind(this);
    this.swapMainAndOr = this.swapMainAndOr.bind(this);
    this.swapInnerAndOr = this.swapInnerAndOr.bind(this);

    this.updateOpenedMenus = this.updateOpenedMenus.bind(this);
  }

  componentDidMount() {
    if (
      !this.props.enrollment_object ||
      (this.props.enrollment_object_type !== "leads" &&
        !this.props.use_list_builder_info)
    ) {
      this.props.getWorkflowInfo({
        token: this.props.token,
        type: "enrollment_object",
        category: "leads",
        property_db_only: this.props.use_list_builder_info
      });
    }
  }

  updateOpenedMenus(opened_menus) {
    this.setState({ opened_menus });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.filters &&
      JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
    ) {
      this.setState({
        filters: this.props.filters?.data || [],
        andor_type: this.props.filters?.andor_type || "or"
      });
    }

    if (
      this.state.filters !== prevState.filters ||
      this.state.andor_type !== prevState.andor_type
    ) {
      this.props.onChange({
        data: this.state.filters,
        andor_type: this.state.andor_type
      });
    }

    if (
      this.state.selecting_filter !== prevState.selecting_filter ||
      this.state.selected_filter !== prevState.selected_filter
    ) {
      if (this.props.onSelecting) {
        this.props.onSelecting(
          this.state.selecting_filter || this.state.selected_filter
        );
      }
    }
  }

  selectFilter(selected_filter) {
    this.setState({
      selected_filter
    });
  }

  addFilter(filter) {
    let new_filters = JSON.parse(JSON.stringify(this.state.filters));
    if (this.state.and_filter != null) {
      new_filters[this.state.and_filter].data.push(filter);
    } else if (this.state.edit_filter) {
      new_filters[this.state.edit_filter.filter_index].data[
        this.state.edit_filter.item_index
      ] = filter;
    } else {
      new_filters.push({ data: [filter], andor_type: "and" });
    }

    this.setState({
      and_filter: null,
      selecting_filter: false,
      selected_filter: null,
      filters: new_filters
    });
  }

  swapInnerAndOr(filter_index, andor) {
    let new_filters = JSON.parse(JSON.stringify(this.state.filters));

    new_filters[filter_index].andor_type = andor;

    this.setState({
      filters: new_filters
    });
  }

  setAddFilter(index) {
    this.setState({
      and_filter: index,
      selecting_filter: true,
      edit_filter: null
    });
  }

  selectingFilterToggle(selecting_filter) {
    this.setState({ selecting_filter });
  }

  setEditFilter({ filter_index, item_index, item }) {
    this.setState({
      edit_filter: {
        filter_index,
        item_index,
        values: item.values,
        date_object: item.date_object,
        equal_type: item.equal_type,
        additional_condition_values: item.additional_condition_values
      },
      selected_filter: item.key
    });
  }

  removeFitler({ filter_index, item_index }) {
    let new_filters = JSON.parse(JSON.stringify(this.state.filters));

    new_filters[filter_index].data.splice(item_index, 1);
    if (new_filters[filter_index].data.length == 0) {
      new_filters.splice(filter_index, 1);
    }

    this.setState({
      edit_filter: null,
      add_filter: null,
      filters: new_filters
    });
  }

  swapMainAndOr(andor) {
    this.setState({
      andor_type: andor
    });
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      enrollment_object,
      enrollment_object_loading,
      renderHeader = null,
      include_lead_info = true,
      use_list_builder_info = false,
      editable = true
    } = this.props;
    const {
      selecting_filter,
      filters,
      andor_type,
      edit_filter,
      search_object,
      selected_filter
    } = this.state;

    if (enrollment_object_loading) {
      return (
        <Wrapper style={{ flex: 1 }}>
          <SpinWrapper text="Loading Filters..." />
        </Wrapper>
      );
    } else if (selected_filter && enrollment_object) {
      return (
        <ChooseEquals
          {...this.props}
          additional_condition_values={
            edit_filter ? edit_filter.additional_condition_values : null
          }
          values={edit_filter ? edit_filter.values : null}
          date_object={edit_filter ? edit_filter.date_object : null}
          equal_type={edit_filter ? edit_filter.equal_type : null}
          selected_filter={selected_filter}
          selectFilter={this.selectFilter}
          addFilter={this.addFilter}
        />
      );
    } else if ((selecting_filter || filters.length == 0) && enrollment_object) {
      return (
        <>
          <SearchObject
            {...this.props}
            renderHeader={renderHeader}
            filters={filters}
            selected_filter={selected_filter}
            selectFilter={this.selectFilter}
            selectingFilterToggle={this.selectingFilterToggle}
            include_lead_info={include_lead_info}
            use_list_builder_info={use_list_builder_info}
            updateOpenedMenus={this.updateOpenedMenus}
            opened_menus={this.state.opened_menus}
          />
        </>
      );
    } else if (filters && filters.length > 0 && enrollment_object) {
      return (
        <>
          {renderHeader ? renderHeader() : null}
          <ShowcaseFilters
            {...this.props}
            filters={filters}
            andor_type={andor_type}
            editable={editable}
            removeFitler={this.removeFitler}
            setAddFilter={this.setAddFilter}
            swapInnerAndOr={this.swapInnerAndOr}
            setEditFilter={this.setEditFilter}
            swapMainAndOr={this.swapMainAndOr}
          />
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, workflow }) => {
  const { token, user } = auth;
  const { isMobile, device, platform, isIphoneX } = native;
  const { colors } = settings;
  const {
    enrollment_object,
    enrollment_object_loading,
    enrollment_object_type
  } = workflow;
  return {
    token,
    user,
    isMobile,
    device,
    platform,
    isIphoneX,
    colors,
    enrollment_object,
    enrollment_object_loading,
    enrollment_object_type
  };
};

export default connect(mapStateToProps, {
  getWorkflowInfo
})(AdvancedFilters);
