import React, { Component } from "react";

import {
  Wrapper,
  Spin,
  Scroll,
  Button,
  Row,
  Title,
  Icon,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  SmallLabel,
  SelectItem,
  SpinWrapper
} from "app/NativeComponents/snippets";

class SelectWorkflowTemplate extends Component {
  componentDidMount() {
    const { enrollment_templates } = this.props;
    if (!enrollment_templates || enrollment_templates.length == 0) {
      this.props.getEnrollmentTemplates({ token: this.props.token });
    }
  }

  render() {
    const {
      enrollment_templates,
      enrollment_templates_loading,
      colors,
      workflow,
      isMobile
    } = this.props;
    if (enrollment_templates.length == 0) {
      return <SpinWrapper text="Loading Templates..." />;
    }
    if (enrollment_templates.length > 0) {
      return (
        <>
          <SmallLabel>Start from stratch:</SmallLabel>
          <SelectItem
            select_type="none"
            selected={workflow.template_type == "start_from_stratch"}
            icon="keyboard-arrow-right"
            onPress={() => {
              this.props.updateWorkflowDesign({
                prop: "template_type",
                value: "start_from_stratch"
              });
            }}
            description="Select enrollment criteria for your lead to be enrolled in a workflow."
            require_confirm={true}
            onConfirm={() => {
              this.props.startNewWorkflow();
            }}
            confirm_text="Get Started"
          >
            Start From Stratch
          </SelectItem>
          <SmallLabel>Or select from a template:</SmallLabel>
          {enrollment_templates.map((template, i) => {
            const is_selected =
              template.template_type == workflow.template_type;

            return (
              <SelectItem
                select_type="none"
                icon="keyboard-arrow-right"
                selected={is_selected}
                onPress={() => {
                  this.props.updateWorkflowDesign({
                    prop: "template_type",
                    value: template.template_type
                  });
                }}
                description={template.description}
                require_confirm={true}
                onConfirm={() => {
                  this.props.updateWorkflowFromTemplate(template);
                }}
                confirm_text="Get Started"
              >
                {template.title}
              </SelectItem>
            );
          })}
        </>
      );
    }

    return null;
  }
}

export default SelectWorkflowTemplate;
