import React, { Component } from "react";
import {
  Wrapper,
  Card,
  Input,
  Row,
  Spin,
  Copy,
  Bold,
  Button
} from "app/NativeComponents/common";
import {
  Select,
  SpinWrapper,
  InputBox,
  SelectBox,
  SmallLabel,
  SelectItem
} from "app/NativeComponents/snippets";

import {
  focusNextField,
  getStateName,
  dismissMobileKeyboard
} from "app/NativeActions";

class Inputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state_focus: false
    };

    this._address_input = React.createRef();
    this._address2_input = React.createRef();
    this._city_input = React.createRef();
    this._state_input = React.createRef();
    this._zip_input = React.createRef();
  }

  renderAutoComplete() {
    if (this.props.autocomplete_loading) {
      return <SpinWrapper text="Loading Suggestions..." />;
    } else if (
      this.props.autocomplete_items?.length > 0 &&
      this.props.address?.length > 3
    ) {
      return (
        <Wrapper
          style={{
            backgroundColor: this.props.colors.background_color
          }}
        >
          <SmallLabel>Suggestions</SmallLabel>
          {this.props.autocomplete_items.map((item, i) => {
            return (
              <SelectItem
                key={"auto_complete_" + i}
                select_type={"radio"}
                onPress={() => {
                  this.props.setAddressFields({
                    address: item.parsed_address?.address1,
                    address2: item.parsed_address?.address2,
                    city: item.parsed_address?.city,
                    state: item.parsed_address?.state,
                    zip: item.parsed_address?.zip,
                    property: item?.property
                  });
                  this.props.clearAutocomplete();
                  dismissMobileKeyboard();
                }}
              >
                {item.title}
              </SelectItem>
            );
          })}
        </Wrapper>
      );
    }
  }

  render() {
    let state_data = [];
    for (let i = 0; i < this.props.states.length; i++) {
      state_data.push({
        key: i,
        label: this.props.states[i].name,
        value: this.props.states[i].abbr
      });
    }

    return (
      <Wrapper>
        <Wrapper>
          <InputBox
            input_ref={this._address_input}
            name="address"
            disabled={this.props.loading}
            returnKeyType="next"
            placeholder="Address"
            onFocus={() => this.props.clearAutocomplete()}
            onChange={value => {
              this.props.triggerAutocomplete(value);
              this.props.updateAddressState({ prop: "address", value });
            }}
            onSubmitEditing={() => {
              this._address2_input.current.focus();
            }}
            blurOnSubmit={false}
            value={this.props.address}
            type="text"
          />

          {this.renderAutoComplete()}
        </Wrapper>

        <InputBox
          input_ref={this._address2_input}
          name="address2"
          disabled={this.props.loading}
          returnKeyType="next"
          onFocus={() => this.props.clearAutocomplete()}
          placeholder="Address Line 2"
          onChange={value =>
            this.props.updateAddressState({ prop: "address2", value })
          }
          onSubmitEditing={() => {
            this._city_input.current.focus();
          }}
          blurOnSubmit={false}
          value={this.props.address2}
          type="text"
        />

        <InputBox
          input_ref={this._city_input}
          name="city"
          disabled={this.props.loading}
          returnKeyType="next"
          onFocus={() => this.props.clearAutocomplete()}
          autoCapitalize="words"
          keyboardType="default"
          placeholder="City"
          onChange={value => {
            this.props.updateAddressState({ prop: "city", value });
          }}
          blurOnSubmit={true}
          onSubmitEditing={() => {
            this.setState({ state_focus: true });
          }}
          value={this.props.city}
          type="text"
        />
        <SelectBox
          select_ref={this._state_input}
          items={state_data}
          disabled={this.props.loading}
          value={this.props.state}
          placeholder="State"
          show={this.state.state_focus}
          onFocus={() => {
            this.setState({ state_focus: true });
          }}
          onBlur={() => {
            this.setState({ state_focus: false });
          }}
          onSelect={item => {
            this.props.updateAddressState({ prop: "state", value: item });
          }}
          onSubmitEditing={() => {
            this._zip_input.current.focus();
          }}
        />

        <InputBox
          input_ref={this._zip_input}
          name="zip"
          disabled={this.props.loading}
          returnKeyType="done"
          onFocus={() => this.props.clearAutocomplete()}
          keyboardType="numeric"
          placeholder="Zip Code"
          onChange={value =>
            this.props.updateAddressState({ prop: "zip", value })
          }
          onSubmitEditing={() => {
            this.props.addProperty();
          }}
          blurOnSubmit={false}
          value={this.props.zip}
          type="number"
          maxLength={"5"}
        />
      </Wrapper>
    );
  }
}

export default Inputs;
