import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";

import { popSidePanel, pushSidePanel } from "app/NativeActions";
import CompsProperties from "./CompsProperties";
import CompsAverages from "./CompsAverages";

class CompsDefaultSettings extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = { panel_data };
  }

  handleBack() {
    if (this.state.panel_data.handleBack) {
      this.state.panel_data.handleBack();
    }
    this.props.popSidePanel();
  }

  componentDidUpdate(prevProps, prevState) {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    const prev_panel_data =
      prevProps.device == "mobile"
        ? prevProps?.route?.params?.panel_data
        : prevProps.panel_data;
    if (JSON.stringify(panel_data) !== JSON.stringify(prev_panel_data)) {
      this.setState({
        panel_data
      });
    }
  }

  render() {
    if (this.state.panel_data) {
      const { isMobile, colors } = this.props;
      const {
        property,
        updateMainState,
        unselected_comps_on_map,
        comps_on_map,
        sort_by,
        estimated_value,
        average_sqft,
        average_price
      } = this.state.panel_data;

      return (
        <Container>
          {isMobile ? (
            <NewHeader
              title="Comps"
              subtitle={"Viewing comps for " + property.property_address}
              leftButton={{
                icon: "arrow-back",
                onPress: () => {
                  this.handleBack();
                }
              }}
            />
          ) : (
            <NewHeader
              title="Comps"
              subtitle={"Viewing comps for " + property.property_address}
              leftButton={{
                icon: "close",
                onPress: () => {
                  this.handleBack();
                },
                color: colors.text_color,
                hover_color: colors.hover_color
              }}
              backgroundColor={colors.card_color}
              backgroundColor2={colors.card_color}
              title_color={colors.text_color}
            />
          )}
          <CompsAverages
            {...this.props}
            property={property}
            estimated_value={estimated_value}
            average_sqft={average_sqft}
            average_price={average_price}
          />
          <KeyboardView style={{ flex: 1 }}>
            <CompsProperties
              unselected_comps_on_map={unselected_comps_on_map}
              comps_on_map={comps_on_map}
              sort_by={sort_by}
              updateMainState={updateMainState}
              {...this.props}
            />
          </KeyboardView>
        </Container>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device, isMobile } = native;
  const { colors } = settings;

  return { token, user, device, isMobile, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  pushSidePanel
})(CompsDefaultSettings);
