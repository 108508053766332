import React, { Component } from "react";
import { SelectItem } from "app/NativeComponents/snippets";

import InviteItem from "./InviteItem";
import RequestItem from "./RequestItem";

import { renderDate, renderTeamStatus } from "app/NativeActions";

class TeamMemberList extends Component {
  render() {
    if (this.props.member.member_type == "invite") {
      return <InviteItem {...this.props} />;
    }

    if (this.props.member.member_type == "request") {
      return <RequestItem {...this.props} />;
    }

    return (
      <SelectItem
        onPress={this.props.onPress}
        select_type="profile_pic"
        user={this.props.member}
        subtext={renderTeamStatus(this.props.member)}
        icon="keyboard-arrow-right"
      >
        {this.props.member.firstname && this.props.member.lastname
          ? this.props.member.firstname + " " + this.props.member.lastname
          : this.props.member.email}
      </SelectItem>
    );
  }
}

export default TeamMemberList;
