import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Page,
  KeyboardView,
  Wrapper,
  Title,
  Copy,
  InternalImage,
  Row
} from "app/NativeComponents/common";
import { Header } from "app/NativeComponents/snippets";

import {
  initUpdateUser,
  updateUserFieldChange,
  updateUser,
  appRedirect,
  changeTab,
  /*common functions*/
  dismissMobileKeyboard,
  resetPassword,
  resetPasswordCheck,
  startRedirect
} from "app/NativeActions";

import Body from "./Body";

const queryString = require("query-string");

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.savePassword = this.savePassword.bind(this);
  }
  componentDidMount() {
    if (this.props.device == "desktop") {
      const paramvalue = window.location.search;
      const parsedvalues = queryString.parse(paramvalue);

      if (window.location.pathname == "/reset-password") {
        if (paramvalue == "") {
          window.location.href = window.location.origin + "/login";
        } else {
          this.props.resetPasswordCheck({
            email: parsedvalues.email,
            token: parsedvalues.token,
            type: "reset_password_check"
          });
        }
      }
    }
  }

  checkIfNeedsToSave() {
    if (
      this.props.editUser.new_password != "" ||
      this.props.editUser.new_password_confirm != "" ||
      this.props.editUser.password != ""
    ) {
      return true;
    }

    return false;
  }

  handleBack() {
    /*mobile*/
    dismissMobileKeyboard();

    this.props.appRedirect({
      redirect: "login"
    });
  }

  savePassword() {
    /*mobile*/
    dismissMobileKeyboard();

    const paramvalue = window.location.search;
    const parsedvalues = queryString.parse(paramvalue);

    const { new_password, new_password_confirm } = this.props.editUser;
    this.props.resetPassword({
      type: "reset_password",
      payload: {
        new_password,
        new_password_confirm,
        token: parsedvalues.token,
        email: parsedvalues.email,
        device: this.props.device
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });
        setTimeout(() => { this.props.startRedirect("/"); },1000);
      }
    });
  }

  render() {
    return (
      <Page
        metaTitle={"Reset password - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <div id={"login-container"}>
          <div id={"login-container-inner"}>
            <div id={"login-container-card"}>
              <div style={{ alignItems: "center", justifyContent: "center" }}>
                <Row style={{ padding: 20 }}>
                  <a href={"/login"}>
                    <InternalImage
                      style={{
                        width: 40,
                        objectFit: "contain",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginRight: 15
                      }}
                      contain
                      image={"/assets/images/Icon-blue.png"}
                    />
                  </a>

                  <h1 className={"dm-title"}>Reset Password</h1>
                </Row>
              </div>

              <Copy style={{ padding: 25 }}>
                Enter a new password for your DealMachine account.
              </Copy>
              <Body
                {...this.props}
                checkIfNeedsToSave={this.checkIfNeedsToSave.bind(this)}
                loading={this.state.loading}
                savePassword={this.savePassword}
              />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, native, settings }) => {
  const { token, user } = auth;
  const { device, isMobile } = native;
  const { editUser, colors } = settings;

  return { token, user, device, isMobile, editUser, colors };
};

export default connect(mapStateToProps, {
  initUpdateUser,
  updateUserFieldChange,
  updateUser,
  appRedirect,
  changeTab,
  resetPassword,
  resetPasswordCheck,
  startRedirect
})(ResetPassword);
