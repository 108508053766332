import React, { Component } from "react";
import {
  Wrapper,
  Card,
  CardBody,
  Title,
  Copy
} from "app/NativeComponents/common";

import {
  formatAddress,
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata
} from "app/NativeActions";

import TimelineItem from "./TimelineItem";

class MailerTimeline extends Component {
  render() {
    const {
      mail_provider,
      date_created,
      in_transit_date,
      in_local_area_date,
      processed_for_delivery_date,
      re_routed_date,
      returned_to_sender_date,
      expected_delivery_date
    } = this.props.mailer;
    const { user, billing_addons } = this.props;

    if (
      (checkIfUserHasBillingAddon({
        billing_addons: billing_addons,
        slug: "mail_tracking"
      }) &&
        user?.user_version < 6) ||
      (user?.user_version >= 6 &&
        checkIfUserHasMetadata("mail_tracking") &&
        mail_provider == "lob")
    ) {
      return (
        <Wrapper
          style={{
            marginTop: 10
          }}
        >
          <TimelineItem
            display={date_created}
            icon="print"
            title="Mail Printed"
            date={date_created}
          />
          <TimelineItem
            display={in_transit_date}
            icon="directions-bus"
            title="Mail In Transit"
            date={in_transit_date}
          />
          <TimelineItem
            display={in_local_area_date}
            icon="my-location"
            title="Mail In Local Area"
            date={in_local_area_date}
          />
          <TimelineItem
            display={processed_for_delivery_date}
            icon="mail"
            title="Mail Delivered"
            date={processed_for_delivery_date}
          />
          <TimelineItem
            display={re_routed_date}
            icon="refresh"
            title="Mail Rerouted"
            date={re_routed_date}
          />
          <TimelineItem
            display={returned_to_sender_date}
            icon="error"
            title="Mail Returned To Sender"
            date={returned_to_sender_date}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        style={{
          marginTop: 10
        }}
      >
        <TimelineItem
          display={date_created}
          icon="print"
          title="Mail Printed"
          date={date_created}
        />        
      </Wrapper>
    );
  }
}

export default MailerTimeline;
