import React, { Component } from "react";

import {
  Card,
  CardBody,
  PrimaryButton,
  SecondaryButton,
  Copy,
  Bold,
  Title,
  Wrapper
} from "app/NativeComponents/common";

import { PillButton } from "app/NativeComponents/snippets";

class Scene1 extends Component {
  render() {
    if (this.props.scene == 1) {
      return (
        <div className="animated fadeIn">
          <Card>
            <CardBody
              style={{
                padding: "10px 15px"
              }}
            >
              <Wrapper
                name={"message"}
                style={{
                  flex: 1,
                  fontSize: 14,
                  maxHeight: 120,
                  marginRight: 0,
                  marginLeft: 0,
                  color: "#b1b1b1",
                  height: 75,
                  lineHeight: "18px"
                }}
              >
                Enter Your Note Here
              </Wrapper>
            </CardBody>

            <CardBody
              style={{
                padding: "10px 15px",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <PillButton
                primary={true}
                style={{ margin: 0 }}
                innerStyle={{ padding: 5, paddingRight: 10, paddingLeft: 10 }}
                onPress={() => {}}
              >
                Post
              </PillButton>
            </CardBody>
          </Card>
        </div>
      );
    }
    return <div />;
  }
}

export default Scene1;
