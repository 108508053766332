import React from "react";
import {
  Button,
  Row,
  Wrapper,
  Stretch,
  Copy,
  Icon
} from "app/NativeComponents/common";

import { store } from "app/store";

const renderButton = (hasButton, onPress) => {
  if (hasButton) {
    const colors = store.getState().settings.colors;

    return (
      <Button
        style={{
          flex: 0
        }}
        onPress={onPress}
      >
        <Row>
          <Icon
            style={{ marginRight: 2 }}
            icon={"settings"}
            color={colors.light_text_color}
            size={16}
          />
          <Copy>Options</Copy>
        </Row>
      </Button>
    );
  }
};

const CardLabel = ({
  title,
  icon,
  fa_icon,
  hasButton,
  onPress,
  hasBorder,
  style = {}
}) => {
  const colors = store.getState().settings.colors;

  return (
    <Row
      style={{
        padding: 15,
        paddingLeft: 20,
        paddingRight: 20,
        borderBottomWidth: hasBorder ? 1 : 0,
        borderBottomColor: colors.border_color,
        borderBottomStyle: "solid",
        ...style
      }}
    >
      <Stretch>
        <Row>
          <Icon
            style={{ marginRight: 5 }}
            icon={icon}
            fa_icon={fa_icon}
            color={colors.light_text_color}
            size={20}
          />
          <Copy>{title}</Copy>
        </Row>
      </Stretch>
      {renderButton(hasButton, onPress)}
    </Row>
  );
};

export { CardLabel };
