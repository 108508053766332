import React, { Component } from "react";
import { Wrapper, Copy, Row, Spin, Button } from "app/NativeComponents/common";
import {
  IconButton,
  GhostButton,
  PopoverMenu
} from "app/NativeComponents/snippets";

import { store } from "app/store";
class Column extends Component {
  constructor(props) {
    super(props);

    this.state = { first_choice_loading: false, removing: false };
  }

  render() {
    const colors = store.getState().settings.colors;
    const {
      title,
      main_column = false,
      hovering = false,
      is_default_campaign = false,
      width = 120,
      boxed = false
    } = this.props;
    return (
      <Wrapper
        style={
          main_column
            ? { flex: 1, padding: 25, position: "relative" }
            : { width, padding: 25, position: "relative" }
        }
      >
        <Row>
          {main_column ? (
            <Wrapper
              style={{
                width: 50,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {this.state.first_choice_loading || this.state.removing ? (
                <Spin size="small" />
              ) : hovering ? (
                <PopoverMenu
                  renderComponent={({ pressedIn, hovering }) => {
                    return (
                      <IconButton
                        icon_color={colors.actionable_text_color}
                        icon="more-vert"
                        pressedIn={pressedIn || hovering}
                        noPress={true}
                        style={{ margin: 0 }}
                      />
                    );
                  }}
                  menu_items={[
                    {
                      title: is_default_campaign
                        ? "Remove First Choice"
                        : "Set First Choice",
                      icon: "star",
                      type: "normal",
                      select_type: "icon",
                      onPress: () => {
                        this.props.updateMailerCampaign({
                          token: this.props.token,
                          type: "set_default_mailer_campaign",
                          mailer_campaign_id: is_default_campaign
                            ? 0
                            : this.props.mail_sequence.id,
                          onLoading: () => {
                            this.props.removeFirstChoiceFromAll();
                            this.setState({ first_choice_loading: true });
                          },
                          onError: () => {
                            this.setState({ first_choice_loading: false });
                          },
                          onSuccess: results => {
                            this.setState({ first_choice_loading: false });

                            if (
                              results?.mailer_campaigns &&
                              results?.mailer_campaigns[0]
                            ) {
                              this.props.editMailSequenceSuccess(
                                results?.mailer_campaigns[0]
                              );
                            }
                          }
                        });
                      }
                    },
                    {
                      title: "Clone This Sequence",
                      icon: "content-copy",
                      type: "normal",
                      select_type: "icon",
                      onPress: () => {
                        this.props.pushSidePanel({
                          slug: "new_mail_sequence",
                          overlay: true,
                          data: {
                            mail_sequence: this.props.mail_sequence,
                            onSuccess: new_mail_sequence => {
                              this.props.newMailSequenceSuccess(
                                new_mail_sequence
                              );
                            }
                          }
                        });
                      }
                    },
                    /*
                    {
                      title: "Edit Sequence",
                      icon: "edit",
                      type: "normal",
                      select_type: "icon",
                      onPress: () => {
                        this.props.pushSidePanel({
                          slug: "view_mail_sequence",
                          overlay: true,
                          data: {
                            mail_sequence: this.props.mail_sequence,
                            onSuccess: new_mail_sequence => {
                              this.props.editMailSequenceSuccess(
                                new_mail_sequence
                              );
                            }
                          }
                        });
                      }
                    },
                    */
                    !is_default_campaign
                      ? {
                          title: "Delete Sequence",
                          icon: "delete",
                          type: "normal",
                          select_type: "icon",
                          onPress: () => {
                            this.props.updateMailerCampaign({
                              token: this.props.token,
                              type: "remove_mailer_campaign",
                              mailer_campaign_id: this.props.mail_sequence.id,
                              onLoading: () => {
                                this.setState({ removing: true });
                              },
                              onError: () => {
                                this.setState({ removing: false });
                              },
                              onSuccess: results => {
                                this.setState({ removing: false });
                                this.props.removeMailSequenceSuccess(
                                  this.props.mail_sequence
                                );
                              }
                            });
                          }
                        }
                      : null
                  ]}
                />
              ) : is_default_campaign ? (
                <IconButton
                  noPress={true}
                  icon={"star"}
                  icon_color={colors.active_color}
                  tooltip={"First Choice"}
                  style={{ margin: 0 }}
                />
              ) : null}
            </Wrapper>
          ) : null}

          {this.props.onPress ? (
            <Button
              onPress={this.props.onPress}
              style={
                boxed
                  ? {
                      backgroundColor: colors.background_color,
                      margin: 5,
                      padding: 10,
                      borderRadius: 5
                    }
                  : {}
              }
            >
              <Copy
                style={{
                  whiteSpace: "nowrap",
                  color: is_default_campaign
                    ? colors.active_color
                    : colors.text_color
                }}
              >
                {title}
              </Copy>
            </Button>
          ) : (
            <Wrapper
              style={
                boxed
                  ? {
                      backgroundColor: colors.background_color,
                      margin: 5,
                      padding: 10,
                      borderRadius: 5
                    }
                  : {}
              }
            >
              <Copy
                style={{
                  whiteSpace: "nowrap",
                  color: is_default_campaign
                    ? colors.active_color
                    : colors.text_color
                }}
              >
                {title}
              </Copy>
            </Wrapper>
          )}
        </Row>

        {main_column && hovering && false ? (
          <Wrapper
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Row>
              <GhostButton onPress={() => {}} primary={true}>
                Assign List
              </GhostButton>
            </Row>
          </Wrapper>
        ) : null}
      </Wrapper>
    );
  }
}

export default Column;
