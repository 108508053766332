export const RESET_LOCATION = "reset_location";
export const SET_LOCATION = "set_location";
export const REGION_CHANGE = "region_change";
export const TOGGLE_REGION_CHANGE = "toggle_region_change";
export const TOGGLE_INVALID_ADDRESS = "toggle_invalid_address";
export const TOGGLE_INVALID_REVERSE = "toggle_invalid_reverse";
export const ADDRESS_SEARCH_CHANGED = "address_search_changed";
export const TOGGLE_ADDRESS_SEARCH = "toggle_address_search";
export const GET_LOCATIONS = "get_locations";
export const GET_LOCATIONS_SUCCESS = "get_locations_success";
export const GET_LOCATIONS_FAIL = "get_locations_fail";
export const REVERSE_GEOCODE = "reverse_geocode";
export const REVERSE_GEOCODE_SUCCESS = "reverse_geocode_success";
export const REVERSE_GEOCODE_FAIL = "reverse_geocode_fail";
export const GET_PLACE_DETAILS = "get_place_details";
export const GET_PLACE_DETAILS_SUCCESS = "get_place_details_success";
export const GET_PLACE_DETAILS_FAIL = "get_place_details_fail";
export const TOGGLE_IS_SEARCHED = "toggle_is_searched";
export const SEARCH_PLACE = "search_place";
export const SEARCH_PLACE_FAIL = "search_place_fail";
export const SEARCH_PLACE_SUCCESS = "search_place_success";
export const TOGGLE_MAP_TYPE = "toggle_map_type";

export const MANUAL_ADDRESS_FIELD_CHANGE = "manual_address_field_change";
export const ENTER_MANUAL_ADDRESS = "enter_manual_address";
export const ENTER_MANUAL_ADDRESS_SUCCESS = "enter_manual_address_success";
export const ENTER_MANUAL_ADDRESS_FAIL = "enter_manual_address_fail";

export const INIT_MAP_EDIT = "init_map_edit";
export const RESET_MAP_EDIT = "reset_map_edit";

export const SET_PIN = "set_pin";
export const REMOVE_PIN = "remove_pin";
export const LOCK_LOCATION_TRACKING = "lock_location_tracking";
export const CENTER_LOCATION = "center_location";

export const REDRAW_MARKERS = "redraw_markers";

export const SET_LOCATION_ALLOWED = "set_location_allowed";
export const GET_HIGHLIGHT_TEMPLATES_SUCCESS =
  "get_highlight_templates_success";
