export const SET_SALES_PAGE_PREVIEW = "SET_SALES_PAGE_PREVIEW";
export const SET_SALES_PAGE_CUSTOM_URL = "SET_SALES_PAGE_CUSTOM_URL";
export const SET_SALES_PAGE_HEADLINE = "SET_SALES_PAGE_HEADLINE";
export const SET_SALES_PAGE_PROPERTY_DETAILS =
  "SET_SALES_PAGE_PROPERTY_DETAILS";
export const SET_SALES_PAGE_PRICING_INFO = "SET_SALES_PAGE_PRICING_INFO";
export const SET_SALES_PAGE_CONTACT_NAME = "SET_SALES_PAGE_CONTACT_NAME";
export const SET_SALES_PAGE_CONTACT_EMAIL = "SET_SALES_PAGE_CONTACT_EMAIL";
export const SET_SALES_PAGE_CONTACT_PHONE = "SET_SALES_PAGE_CONTACT_PHONE";
export const SET_SALES_PAGE_CONTACT_COMPANY = "SET_SALES_PAGE_CONTACT_COMPANY";
export const SET_SALES_PAGE_FOR_SALE_BY = "SET_SALES_PAGE_FOR_SALE_BY";
export const SET_SALES_PAGE_SHOW_PROPERTY_ADDRESS =
  "SET_SALES_PAGE_SHOW_PROPERTY_ADDRESS";
export const SET_SALES_PAGE_IMAGES = "SET_SALES_PAGE_IMAGES";
export const SET_SALES_PAGE_HAS_HEADLINE = "SET_SALES_PAGE_HAS_HEADLINE";
export const SET_SALES_PAGE_HAS_PROPERTY_DETAILS =
  "SET_SALES_PAGE_HAS_PROPERTY_DETAILS";
export const SET_SALES_PAGE_HAS_PRICING_INFO =
  "SET_SALES_PAGE_HAS_PRICING_INFO";
export const RESET_SALES_PAGE = "RESET_SALES_PAGE";
