import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Row,
  Wrapper,
  Card,
  CardBody,
  Title,
  Input,
  Icon,
  Spin,
  Button,
  Copy,
  Bold,
  KeyboardView
} from "app/NativeComponents/common";
import {
  Header,
  List,
  SearchBar,
  BottomNavBar,
  InlineButton,
  SpinWrapper,
  SelectItem,
  InlineTabs,
  UpsellButton
} from "app/NativeComponents/snippets";

import {
  setStartMailerCampaignPage,
  setRightPanel,
  popSidePanel,
  setNewMailerCampaign,
  getMailerCampaigns
} from "app/NativeActions";

class MailSequences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }
  componentDidMount() {
    this.getCampaigns();
  }
  componentDidUpdate(prevProps) {}

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  getCampaigns(load_type = "load", search = "") {
    const { mailer_campaigns_begin, mailer_campaigns_limit } = this.props;
    this.props.getMailerCampaigns({
      token: this.props.token,
      load_type: load_type,
      begin: load_type !== "load_more" ? 0 : mailer_campaigns_begin,
      limit: mailer_campaigns_limit,
      search,
      sort_by: "select_campaign"
    });
  }

  updateSearch(search) {
    this.setState({ search });

    clearInterval(this._search_interval);
    this._search_interval = setTimeout(() => {
      this.getCampaigns("refresh", search);
    }, 250);
  }

  triggerSearch(value) {
    this.props.clearAutocomplete();
  }

  render() {
    const {
      show_start_mailer_campaign_page,
      start_mailer_campaign_page,
      device,
      isMobile,
      colors,
      user,
      mailer_campaigns,
      mailer_campaigns_begin,
      mailer_campaigns_limit,
      mailer_campaigns_loading,
      mailer_campaigns_refreshing,
      mailer_campaigns_error,
      mailer_campaigns_loaded_all
    } = this.props;
    const { search, tab } = this.state;
    return (
      <>
        <Row>
          <Wrapper style={{ flex: 1 }}>
            <SearchBar
              value={search}
              style={{ margin: 10, borderWidth: 0, borderBottomWidth: 0 }}
              title="Search Mail Sequences"
              onChange={value => {
                this.updateSearch(value);
              }}
            />
          </Wrapper>
        </Row>

        <KeyboardView style={{ flex: 1 }}>
          {mailer_campaigns_refreshing ||
          (mailer_campaigns_loading && mailer_campaigns.length == 0) ? (
            <SpinWrapper text="Loading Mail Sequences..." />
          ) : mailer_campaigns.length > 0 ? (
            <List
              rowNumber={1}
              style={{ flex: 1 }}
              items={mailer_campaigns}
              infiniteScroll={true}
              itemStructure={({ item, index }) => {
                let description = "";
                if (item.is_default_campaign) {
                  description += "Default - ";
                }
                description +=
                  item.number_of_steps == 1
                    ? "1 Step - "
                    : item.number_of_steps + " Steps -";

                description +=
                  item.number_of_mailers == 1
                    ? "1 Mail piece"
                    : item.number_of_mailers + " Mail pieces";

                if (item.repeat_mail_sequence == 1) {
                  description += " - Auto-repeat";
                }

                return (
                  <>
                    <SelectItem
                      select_type={"check"}
                      selected={this.props.isSelected(item, "mail_sequence")}
                      onPress={() => {
                        this.props.onSelect(item, "mail_sequence");
                      }}
                      description={description}
                    >
                      {item.campaign_title}
                    </SelectItem>
                  </>
                );
              }}
              canRefresh={true}
              onRefresh={() => {
                this.getCampaigns("refresh");
              }}
              is_refreshing={mailer_campaigns_refreshing}
              canLoadMore={
                !mailer_campaigns_loaded_all &&
                !mailer_campaigns_loading &&
                !mailer_campaigns_refreshing &&
                mailer_campaigns.length > 0
              }
              isLoadingMore={
                mailer_campaigns_loading &&
                !mailer_campaigns_refreshing &&
                mailer_campaigns.length > 0
              }
              onLoadMore={() => {
                if (
                  !mailer_campaigns_loaded_all &&
                  mailer_campaigns_refreshing !== true &&
                  mailer_campaigns_loading !== true
                ) {
                  this.getCampaigns("load_more");
                }
              }}
            />
          ) : mailer_campaigns.length == 0 ? (
            <Wrapper />
          ) : null}
        </KeyboardView>
      </>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const {
    start_mailer_campaign_page,
    show_start_mailer_campaign_page,

    mailer_campaigns,
    mailer_campaigns_begin,
    mailer_campaigns_limit,
    mailer_campaigns_loading,
    mailer_campaigns_refreshing,
    mailer_campaigns_error,
    mailer_campaigns_loaded_all
  } = mailer;
  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,

    start_mailer_campaign_page,
    show_start_mailer_campaign_page,

    mailer_campaigns,
    mailer_campaigns_begin,
    mailer_campaigns_limit,
    mailer_campaigns_loading,
    mailer_campaigns_refreshing,
    mailer_campaigns_error,
    mailer_campaigns_loaded_all
  };
};

export default connect(mapStateToProps, {
  setStartMailerCampaignPage,
  setRightPanel,
  setNewMailerCampaign,
  getMailerCampaigns,
  popSidePanel
})(MailSequences);
