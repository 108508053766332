import React, { Component } from "react";
import { connect } from "react-redux";
import { numberWithCommas, toTitleCase } from "app/NativeActions";
import {
  Bold,
  Card,
  Copy,
  Icon,
  Row,
  Title,
  Wrapper
} from "app/NativeComponents/common";
import {
  IconButton,
  SelectItem,
  PopoverMenu,
  Carousel,
  SmallLabel,
  CloseButton,
  AttentionBox,
  InlineButton,
  SpinWrapper,
  BottomNavBar
} from "app/NativeComponents/snippets";
import CallToActions from "./CallToActions";
import MailingAddresses from "./MailingAddresses";
import PhoneNumbers from "./PhoneNumbers";
import EmailAddresses from "./EmailAddresses";

import PersonItem from "./PersonItem";

import {
  getMorePropertyInfo,
  addDeal,
  determineMainOwnerInfo,
  checkIfUserHasMetadata,
  encodeStringToInt,
  formatAddress
} from "app/NativeActions";

class OwnerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owner_properties: [],
      show_other_properties: false,
      more_owner_info_loading: false,
      other_owner_properties: [],
      get_in_touch_height: 0,
      show_portfolio_toolip: false,
      show_unlinked_contacts: false
    };
  }

  componentDidMount() {}
  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { property, user, owner_info, colors } = this.props;
    const owner = determineMainOwnerInfo(property);

    let people = [];

    for (let i = 0; i < property?.all_contacts?.length; i++) {
      //if people does not inlcude an items with the same individual_key add this person

      let person = {
        ...property?.all_contacts[i]
      };
      if (!!person.surname || !!person.given_name) {
        people.push(person);
      }
    }

    let unlinked_people = [];

    for (let i = 0; i < property?.custom_unlinked_contacts?.length; i++) {
      //if people does not inlcude an items with the same individual_key add this person

      let person = {
        ...property?.custom_unlinked_contacts[i]
      };
      unlinked_people.push(person);
    }

    let linked_company = null;
    if (property?.linked_company && checkIfUserHasMetadata("company_data")) {
      linked_company = property?.linked_company;
    }

    const owner_address_formatted = formatAddress({
      address: {
        address: owner?.owner_address,
        address2: owner?.owner_address2,
        address_city: owner?.owner_address_city,
        address_state: owner?.owner_address_state,
        address_zip: owner?.owner_address_zip
      }
    });

    return (
      <>
        <Card
          label={"Contact Information:"}
          id={"owner-information"}
          style={{ marginTop: 15 }}
          onLayout={event => {
            const { x, y, width, height } = event?.nativeEvent?.layout;
            this.setState({
              offset: y,
              height: height
            });
          }}
        >
          <SelectItem
            noPress={true}
            //subtext={owner?.owner_status_info?.text}
            selected={true}
            select_type="none"
            label={"Mailing address:"}
            innerStyle={{
              paddingRight: 0,
              paddingBottom: 0
            }}
            renderBottom={() => {
              return (
                <Row>
                  <Wrapper style={{ flex: 1 }}>
                    <CallToActions
                      {...this.props}
                      property={property}
                      updateMainState={this.props.updateMainState}
                      mail_loading={this.props.mail_loading}
                      propertyUpdated={this.props.propertyUpdated}
                      changeTab={this.props.changeTab}
                      button_type={"full"}
                    />
                  </Wrapper>
                </Row>
              );
            }}
            renderRight={
              !this.props.no_start_mail_button
                ? () => {
                    return (
                      <Row>
                        {property?.deal?.owner?.phone_numbers?.length > 0 ? (
                          <PhoneNumbers
                            {...this.props}
                            property={property}
                            propertyUpdated={this.props.propertyUpdated}
                            people_data={true}
                          />
                        ) : null}

                        {property?.deal?.owner?.email_addresses?.length > 0 ? (
                          <EmailAddresses
                            {...this.props}
                            property={property}
                            propertyUpdated={this.props.propertyUpdated}
                            people_data={true}
                          />
                        ) : null}
                      </Row>
                    );
                  }
                : null
            }
            renderContent={() => {
              return (
                <Wrapper style={{ flex: 1 }}>
                  {linked_company ? (
                    <Row>
                      <SelectItem
                        select_type={"icon"}
                        select_icon={"business"}
                        select_icon_style={{ marginRight: 10 }}
                        onPress={() => {
                          if (checkIfUserHasMetadata("free_plan")) {
                            this.props.pushSidePanel({
                              slug: "purchase_plan_prompt",
                              overlay_modal: true,
                              data: {
                                title:
                                  "Get Unlimited Contact Info With DealMachine Pro.",
                                dismiss_button:
                                  "No thanks. Let me continue without contact info.",
                                no_free: true
                              }
                            });
                          } else {
                            this.props.pushSidePanel({
                              slug: "company",
                              expanded_side_panel: this.props.expanded,
                              overlay: true,
                              id: encodeStringToInt(
                                linked_company?.company_number +
                                  "+" +
                                  linked_company?.jurisdiction_code
                              ),
                              data: {
                                company: linked_company,
                                property: this.props.property,
                                expanded: this.props.expanded,
                                propertyUpdated: this.props.propertyUpdated
                              }
                            });
                          }
                        }}
                        icon={"keyboard-arrow-right"}
                        style={{
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: colors.border_color,
                          marginRight: 0,
                          marginLeft: 0,

                          justifyContent: "center"
                        }}
                        innerStyle={{
                          padding: 10,
                          paddingTop: 10,
                          paddingBottom: 10
                        }}
                        selected={true}
                      >
                        {toTitleCase(linked_company.company_name)}
                      </SelectItem>
                    </Row>
                  ) : (
                    <Row>
                      <Copy style={{ color: colors.actionable_text_color }}>
                        <Bold>{owner.owner_name}</Bold>
                      </Copy>
                      {!!owner.owner_firstname &&
                      !!owner.owner_lastname &&
                      checkIfUserHasMetadata("people_data") ? (
                        <IconButton
                          icon={"person-search"}
                          button_type={"small"}
                          style={{ margin: 0, marginLeft: 5 }}
                          tooltip={"Open Person Search"}
                          tooltipPlacement={"top"}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "person_search",
                              overlay: true,
                              expanded_side_panel: this.props.expanded,
                              data: {
                                expanded: this.props.expanded,
                                expanded_side_panel: this.props.expanded,
                                firstname: property.owner_firstname,
                                lastname: property.owner_lastname,
                                middlename: property.owner_middlename,
                                firstname2: property.owner_2_firstname,
                                lastname2: property.owner_2_lastname,
                                middlename2: property.owner_2_middlename,
                                address_zipcode: property.owner_address_zip,
                                address_state: property.owner_address_state,
                                property,
                                propertyUpdated: this.props.propertyUpdated
                              }
                            });
                          }}
                        />
                      ) : property?.is_corporate_owner &&
                        !property?.is_trust &&
                        checkIfUserHasMetadata("people_data") &&
                        !checkIfUserHasMetadata("company_data") ? (
                        <IconButton
                          icon={"person-search"}
                          button_type={"small"}
                          style={{ margin: 0, marginLeft: 5 }}
                          tooltip={"Open Company Search"}
                          tooltipPlacement={"top"}
                          onPress={() => {
                            this.props.pushSidePanel({
                              slug: "person_search",
                              overlay: true,
                              expanded_side_panel: this.props.expanded,
                              data: {
                                expanded: this.props.expanded,
                                expanded_side_panel: this.props.expanded,
                                company_name: owner.owner_name,
                                company_address: owner?.owner_address,
                                company_city: owner?.owner_address_city,
                                company_state: owner?.owner_address_state,
                                company_zip: owner?.owner_address_zip,
                                property,
                                propertyUpdated: this.props.propertyUpdated
                              }
                            });
                          }}
                        />
                      ) : null}
                    </Row>
                  )}
                  <Row style={{ alignSelf: "stretch", flex: 1 }}>
                    <Wrapper style={{ flex: 1 }}>
                      <Copy>{owner_address_formatted?.line1}</Copy>
                      <Copy>{owner_address_formatted?.line2}</Copy>
                    </Wrapper>
                    <MailingAddresses
                      {...this.props}
                      property={property}
                      propertyUpdated={this.props.propertyUpdated}
                      people_data={true}
                    />
                  </Row>
                </Wrapper>
              );
            }}
          ></SelectItem>

          {people && people.length > 0 ? (
            <>
              <Row>
                <SmallLabel style={{ paddingLeft: 30, paddingRight: 10 }}>
                  Associated contacts: ({people.length})
                </SmallLabel>
                <PopoverMenu
                  show={this.state.show_tooltip}
                  no_swipe={true}
                  no_cancel={false}
                  popover_dismiss={"Dismiss"}
                  onShow={s => {
                    this.setState({
                      show_tooltip: s
                    });
                  }}
                  popover_width={450}
                  popoverSheetTop={this.state.get_in_touch_height + 200}
                  popoverPlacement={"bottom"}
                  popover_title={"Get in touch!"}
                  includeCloseButton={true}
                  tooltip={"Who are these people?"}
                  tooltipPosition={"top"}
                  renderComponent={options => {
                    return (
                      <IconButton
                        pressedIn={options.hovering || options.pressedIn}
                        noPress={true}
                        icon="help"
                        icon_color={colors.light_text_color}
                        button_type={"small"}
                        style={{ marginRight: 0, marginLeft: -5 }}
                      />
                    );
                  }}
                  menu_items={null}
                  renderMenu={() => {
                    return (
                      <Wrapper style={{ alignSelf: "stretch" }}>
                        <Wrapper
                          onLayout={event => {
                            const { x, y, width, height } =
                              event?.nativeEvent?.layout;
                            this.setState({
                              get_in_touch_height: height
                            });
                          }}
                        >
                          <Wrapper
                            style={{
                              padding: 25,
                              paddingTop:
                                this.props.device === "desktop" ? 25 : 0,
                              position: "relative"
                            }}
                          >
                            {this.props.device === "desktop" ? (
                              <Title style={{ marginBottom: 10 }}>
                                Get in touch!
                              </Title>
                            ) : null}

                            <Copy style={{ marginBottom: 10 }}>
                              Below you'll find a list of individuals who are{" "}
                              <Bold>
                                likely to be associated with this property.
                              </Bold>
                              This includes <Bold>property owners</Bold> and the
                              residents that live there.
                            </Copy>
                            <Copy>
                              Should there have been a recent transaction, such
                              as a sale, there's a likelihood that the names
                              provided are of those previously connected to the
                              property.
                            </Copy>
                          </Wrapper>
                          {this.props.device === "desktop" ? (
                            <CloseButton
                              onPress={() => {
                                this.setState({
                                  show_tooltip: false
                                });
                              }}
                            />
                          ) : null}
                        </Wrapper>
                      </Wrapper>
                    );
                  }}
                />
              </Row>
            </>
          ) : null}

          {people.length > 0 ? (
            <>
              {people.length == 1 ? (
                <Wrapper
                  style={{
                    padding: 5,
                    justifyContent: "center"
                  }}
                >
                  <PersonItem
                    person={people[0]}
                    property={property}
                    expanded={this.props.expanded}
                    propertyUpdated={this.props.propertyUpdated}
                    use_linking_flags={true}
                    display_warning={true}
                  />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    marginBottom: this.props.device === "desktop" ? 40 : 0,
                    marginLeft: this.props.device === "mobile" ? -10 : 0,
                    marginRight: this.props.device === "mobile" ? -10 : 0,
                    justifyContent: "center"
                  }}
                  className={"contact-people " + this.props.dark_mode}
                >
                  <Carousel
                    firstItem={0}
                    items={people}
                    slidesToShow={1}
                    centerMode={true}
                    infinite={true}
                    dots={people.length < 7 ? true : false}
                    centerPadding={this.props.expanded ? "20px" : "20px"}
                    itemWidth={this.props.device === "desktop" ? 320 : 320}
                    onSwipe={() => {}}
                    renderItem={obj => {
                      const person = obj?.item;
                      return (
                        <PersonItem
                          person={person}
                          property={property}
                          expanded={this.props.expanded}
                          use_linking_flags={true}
                          propertyUpdated={this.props.propertyUpdated}
                          display_warning={true}
                        />
                      );
                    }}
                    include_pagination={true}
                    dotColor={colors.text_color}
                  />
                </Wrapper>
              )}
            </>
          ) : this.props.property_loading ? (
            <SpinWrapper text={"Loading"} />
          ) : null}

          {!!property?.owner_hash &&
          people?.length === 0 &&
          unlinked_people?.length === 0 &&
          (!this.props.property_loading || people.length > 0) ? (
            <InlineButton
              onPress={() => {
                this.props.pushSidePanel({
                  slug: "real_estate_portfolio",
                  expanded_side_panel: this.props.expanded,
                  overlay: true,
                  data: {
                    property
                  }
                });
              }}
              icon_right={"keyboard-arrow-right"}
              button_type={"full"}
            >
              View Real Estate Portfolio
            </InlineButton>
          ) : null}

          {people.length === 0 &&
          unlinked_people.length === 0 &&
          !this.props.property_loading ? (
            <Wrapper
              style={{
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: colors?.border_color,
                padding: 15,
                backgroundColor: colors.gray_color
              }}
            >
              <PopoverMenu
                show={this.state.show_portfolio_tooltip}
                no_swipe={true}
                no_cancel={false}
                popover_dismiss={"Dismiss"}
                onShow={s => {
                  this.setState({
                    show_portfolio_tooltip: s
                  });
                }}
                popover_width={550}
                popoverSheetTop={this.state.get_in_touch_height + 200}
                popoverPlacement={"bottom"}
                popover_title={
                  "Property Owner Contact Information Unavailable."
                }
                includeCloseButton={true}
                renderComponent={options => {
                  return (
                    <InlineButton
                      pressedIn={options.hovering || options.pressedIn}
                      noPress={true}
                      icon="help"
                      icon_color={colors.light_text_color}
                      button_type={"small"}
                      style={{ marginRight: 5, marginLeft: -5, margin: 0 }}
                    >
                      Why are there no contacts for this property?
                    </InlineButton>
                  );
                }}
                menu_items={null}
                renderMenu={() => {
                  return (
                    <Wrapper style={{ alignSelf: "stretch" }}>
                      <Wrapper
                        onLayout={event => {
                          const { x, y, width, height } =
                            event?.nativeEvent?.layout;
                          this.setState({
                            get_in_touch_height: height
                          });
                        }}
                      >
                        <Wrapper
                          style={{
                            padding: 25,
                            paddingTop:
                              this.props.device === "desktop" ? 25 : 0,
                            position: "relative"
                          }}
                        >
                          {this.props.device === "desktop" ? (
                            <Title style={{ marginBottom: 25 }}>
                              Property Owner Contact Information Unavailable.
                            </Title>
                          ) : null}

                          <Copy style={{ marginBottom: 10 }}>
                            <Bold>
                              Why Can't We Display a Contact for This Property?
                            </Bold>
                          </Copy>
                          <Copy style={{ marginBottom: 25 }}>
                            Our system identifies property owner contacts using{" "}
                            <Bold>mailing addresses from county records</Bold>.
                            If a matching address isn't found, we ensure data
                            integrity by not displaying speculative matches. In
                            this instance,{" "}
                            <Bold>
                              no contacts linked to this property's mailing
                              address
                            </Bold>{" "}
                            were located.
                          </Copy>
                          {!!property?.owner_hash &&
                          (!this.props.property_loading ||
                            people.length > 0) ? (
                            <Copy style={{ marginBottom: 25 }}>
                              Despite this, we have efficiently cross-referenced
                              the property's listed owner against our database,
                              allowing us to construct a{" "}
                              <Bold>
                                comprehensive portfolio of their other
                                properties.
                              </Bold>
                            </Copy>
                          ) : null}

                          <Copy style={{ marginBottom: 10 }}>
                            <Bold>
                              Possible Reasons for Unavailability of Contact
                              Information:
                            </Bold>
                          </Copy>
                          <Copy style={{ marginBottom: 10 }}>
                            1.) Recent Relocation: The owner might have recently
                            moved, leading to an outdated mailing address in the
                            county records.
                          </Copy>
                          <Copy style={{ marginBottom: 10 }}>
                            2.) Alternate Residence: Especially for properties
                            owned by LLCs, the owner's actual residence might
                            differ from the property's mailing address.
                          </Copy>
                        </Wrapper>
                        {this.props.device === "desktop" ? (
                          <CloseButton
                            onPress={() => {
                              this.setState({
                                show_portfolio_tooltip: false
                              });
                            }}
                          />
                        ) : null}
                      </Wrapper>
                    </Wrapper>
                  );
                }}
              />
            </Wrapper>
          ) : null}

          {unlinked_people.length > 0 && this.state.show_unlinked_contacts ? (
            <>
              <Row style={{ justifyContent: "space-between" }}>
                <SmallLabel style={{ paddingLeft: 30, paddingRight: 10 }}>
                  Unlinked contacts: ({unlinked_people.length})
                </SmallLabel>
                <InlineButton
                  onPress={() => {
                    this.setState({
                      show_unlinked_contacts: false
                    });
                  }}
                >
                  Hide
                </InlineButton>
              </Row>
              {unlinked_people.length == 1 ? (
                <Wrapper
                  style={{
                    padding: 5,
                    justifyContent: "center"
                  }}
                >
                  <PersonItem
                    person={unlinked_people[0]}
                    property={property}
                    expanded={this.props.expanded}
                    propertyUpdated={this.props.propertyUpdated}
                    use_linking_flags={true}
                    display_warning={true}
                  />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    marginBottom: this.props.device === "desktop" ? 40 : 0,
                    marginLeft: this.props.device === "mobile" ? -10 : 0,
                    marginRight: this.props.device === "mobile" ? -10 : 0,
                    justifyContent: "center"
                  }}
                  className={"contact-people " + this.props.dark_mode}
                >
                  <Carousel
                    firstItem={0}
                    items={unlinked_people}
                    slidesToShow={1}
                    centerMode={true}
                    infinite={true}
                    dots={unlinked_people.length < 7 ? true : false}
                    centerPadding={this.props.expanded ? "20px" : "20px"}
                    itemWidth={this.props.device === "desktop" ? 320 : 320}
                    onSwipe={() => {}}
                    renderItem={obj => {
                      const person = obj?.item;
                      return (
                        <PersonItem
                          person={person}
                          property={property}
                          expanded={this.props.expanded}
                          use_linking_flags={true}
                          propertyUpdated={this.props.propertyUpdated}
                          display_warning={true}
                        />
                      );
                    }}
                    include_pagination={true}
                    dotColor={colors.text_color}
                  />
                </Wrapper>
              )}
            </>
          ) : null}

          {unlinked_people.length > 0 && !this.state.show_unlinked_contacts ? (
            <Wrapper
              style={{
                padding: 15,
                paddingTop: 0,
                paddingBottom: 0,
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: colors.border_color
              }}
            >
              <InlineButton
                onPress={() => {
                  this.setState({
                    show_unlinked_contacts: true
                  });
                }}
                button_type={"full"}
              >
                View Unlinked Contacts ({unlinked_people.length})
              </InlineButton>
            </Wrapper>
          ) : null}
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, route }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { device } = native;
  const { current_route } = route;
  return {
    token,
    user,
    colors,
    device,
    current_route
  };
};

export default connect(mapStateToProps, { getMorePropertyInfo, addDeal })(
  OwnerInfo
);
