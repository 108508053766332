import React, { Component } from "react";
import { Wrapper, Row, Copy, CardBody } from "app/NativeComponents/common";

class Legend extends Component {
  render() {
    const { colors } = this.props;
    return (
      <Wrapper style={{ padding: 25, paddingTop: 0 }}>
        <Row>
          <Row
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                width: 10,
                height: 10,
                backgroundColor: colors.success_color,
                marginRight: 5
              }}
            />
            <Copy>0-6 months</Copy>
          </Row>

          <Row
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                width: 10,
                height: 10,
                backgroundColor: colors.orange_color_light,
                marginRight: 5
              }}
            />
            <Copy>6-12 months</Copy>
          </Row>

          <Row
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                width: 10,
                height: 10,
                backgroundColor: colors.error_color,
                marginRight: 5
              }}
            />
            <Copy>12-24 months</Copy>
          </Row>
        </Row>
      </Wrapper>
    );
  }
}

export default Legend;
