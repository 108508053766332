import React, { Component } from "react";
import {
  Wrapper,
  Title,
  Input,
  Button,
  Icon,
  Row,
  Copy,
  Bold,
  SwitchButton
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  PillButton,
  InputBox,
  InlineButton,
  GhostButton
} from "app/NativeComponents/snippets";
import PillTabButton from "app/DealMachineCore/native/GlobalSnippets/Tabs/PillTabButton";

class TopNavBar extends Component {
  render() {
    const {
      colors,
      workflow,
      device,
      isMobile,
      isIphoneX,
      platform,
      updateWorkflowDesign,
      show_select_template
    } = this.props;

    return (
      <InputBox
        autoFocus={false}
        name="workflow_title"
        disabled={false}
        returnKeyType="done"
        placeholder={
          !!workflow.workflow_title ? "Workflow Name" : "Untitled Workflow"
        }
        onFocus={() => {}}
        onChange={value =>
          updateWorkflowDesign({ prop: "workflow_title", value })
        }
        blurOnSubmit={true}
        value={workflow.workflow_title}
        input_type="text"
        onSubmit={() => {
          this.props.updateWorkflow({
            token: this.props.token,
            type: "remove_workflow",
            workflow_id: workflow.workflow_id,
            onSuccess: () => {
              this.props.popSidePanel();
            }
          });
        }}
        onSubmitEditing={() => {
          this.props.updateWorkflow({
            token: this.props.token,
            type: "remove_workflow",
            workflow_id: workflow.workflow_id,
            onSuccess: () => {
              this.props.popSidePanel();
            }
          });
        }}
        type={"text"}
        style={{
          backgroundColor: this.props.checkIfNeedsToSave()
            ? colors.orange_color_muted
            : null
        }}
        innerStyle={{
          padding: 20,
          margin: 5,
          borderRadius: 5,
          backgroundColor:
            this.props.dark_mode == "dark_mode"
              ? "rgba(0, 0, 0, 0.25)"
              : "rgba(255, 255, 255, 0.5)"
        }}
        renderRight={() => {
          return (
            <>
              <Row>
                <InlineButton
                  select_type="toggle"
                  icon={workflow.workflow_on ? "toggle-on" : "toggle-off"}
                  onPress={() => {
                    this.props.updateWorkflowDesign({
                      prop: "workflow_on",
                      value: !workflow.workflow_on
                    });
                  }}
                  iconColor={workflow.workflow_on ? colors.success_color : null}
                >
                  {workflow.workflow_on ? "Workflow On" : "Workflow Off"}
                </InlineButton>

                <GhostButton
                  disabled={
                    !this.props.checkIfNeedsToSave() ||
                    !this.props.checkIfCanSave()
                  }
                  primary={true}
                  loading={this.props.saving}
                  onPress={this.props.saveWorkflow}
                >
                  Save Workflow
                </GhostButton>
              </Row>
              {workflow.workflow_id !== "new_workflow" ? (
                <PopoverMenu
                  style={{ padding: 15 }}
                  icon={"more-vert"}
                  icon_size={26}
                  menu_items={[
                    {
                      title: "Delete Workflow",
                      icon: "delete",
                      type: "delete",
                      onPress: () => {
                        this.props.setModal({
                          title: "Delete Workflow",
                          description:
                            "Are you sure you want to delete this workflow?",
                          icon: "delete",
                          submit: "Delete Workflow",
                          buttonType: "destroy",
                          onPress: () => {
                            this.props.updateWorkflow({
                              token: this.props.token,
                              type: "remove_workflow",
                              workflow_id: workflow.workflow_id,
                              onSuccess: () => {
                                this.props.toggleWorkflowDesigner(false);
                              }
                            });
                          },
                          cancel: "Cancel",
                          onCancel: () => {}
                        });
                        this.props.toggleModal({ show: true, type: "normal" });
                      }
                    }
                  ]}
                />
              ) : null}
            </>
          );
        }}
      />
    );
  }
}

export default TopNavBar;
