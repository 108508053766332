import React, { Component } from "react";
import { connect } from "react-redux";

import { Page, Copy, Row, Bold, Wrapper } from "app/NativeComponents/common";
import { Header1, Header3 } from "app/NativeComponents/common";
import {
  InlineButton,
  GhostButton,
  ConfirmInlineButton,
  OnboardingWrapper
} from "app/NativeComponents/snippets";

import {
  logout,
  startRedirect,
  bypassMobileRedriect,
  getMobileRedirectSessionLink,
  openUrl
} from "app/NativeActions";
class NativeMobilePrompt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect_url: null
    };
  }

  componentDidMount() {
    if (this.props.device == "desktop") {
      this.props.startRedirect("/onboarding/continue-on-the-app");
    }
    this.getMobileRedirectSessionLink();
  }

  getMobileRedirectSessionLink() {
    this.props.getMobileRedirectSessionLink({
      token: this.props.token,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        this.setState({
          redirect_url: results?.url
        });
      }
    });
  }

  render() {
    const { contentful_content, contentful_loading } = this.state;
    const { colors, isMobile } = this.props;

    return (
      <Page
        metaTitle={"Download the mobile app - DealMachine for Real Estate"}
        responsive_design={true}
      >
        <OnboardingWrapper>
          <>
            <Wrapper
              style={{
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 25
              }}
            >
              <Header1
                style={{
                  textAlign: "center"
                }}
              >
                📲 <Bold>Download the app to continue</Bold>
              </Header1>
              <Header3
                style={{
                  textAlign: "center"
                }}
              >
                DealMachine is better on the app.
              </Header3>
              <Wrapper
                style={{
                  padding: 25,
                  maxWidth: 500,
                  margin: this.props.device == "desktop" ? "5px auto" : 5
                }}
              >
                <Copy
                  style={{
                    textAlign: "center"
                  }}
                >
                  Our mobile experience is the
                  <Bold>best in the REI industry.</Bold> Just a quick download
                  and you'll pick up right where you left off.
                </Copy>
              </Wrapper>
              <Wrapper
                style={{
                  width: "100%",
                  alignSelf: "stretch",
                  maxWidth: 400,
                  margin: "0px auto"
                }}
              >
                <GhostButton
                  button_type={"full"}
                  primary={true}
                  disabled={!this.state.redirect_url}
                  onPress={() => {
                    openUrl(this.state.redirect_url, true);
                  }}
                >
                  Download and Continue
                </GhostButton>

                <ConfirmInlineButton
                  button_type="full"
                  confirm_text="Really Continue?"
                  onPress={() => {
                    this.props.bypassMobileRedriect(true);
                    this.props.startRedirect("/");
                  }}
                >
                  Continue with desktop app
                </ConfirmInlineButton>
              </Wrapper>
            </Wrapper>
          </>
        </OnboardingWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, billing, settings, native }) => {
  const { token, user, init, loading } = auth;

  const { colors } = settings;
  const { source_of_truth } = billing;
  const { isMobile, device, initialRedirect, desktopMobile } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    device,
    initialRedirect,
    source_of_truth,
    desktopMobile
  };
};

export default connect(mapStateToProps, {
  logout,
  startRedirect,
  bypassMobileRedriect,
  getMobileRedirectSessionLink
})(NativeMobilePrompt);
