import React from "react";
import { store } from "app/store";

const Title = ({ children, style, copyfont, className = "" }) => {
  const colors = store.getState().settings.colors;

  if (copyfont) {
    return (
      <div
        className={`deal-copy-title ${className}`}
        style={{ color: colors.text_style, ...style }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      className={`deal-title ${className}`}
      style={{ color: colors.title_color, ...style }}
    >
      {children}
    </div>
  );
};

export { Title };
