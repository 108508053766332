import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton,
  BottomNavBar,
  ConfirmInlineButton
} from "app/NativeComponents/snippets";
import NoteTextBox from "../NoteTextBox";

import { popSidePanel, updateNote, isJson } from "app/NativeActions";

class EditNote extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      panel_data,
      note_text: panel_data?.note?.text || "",
      file_urls:
        panel_data?.note?.file_urls &&
        isJson(panel_data?.note?.file_urls) &&
        Array.isArray(JSON.parse(panel_data?.note?.file_urls))
          ? panel_data?.note?.file_urls
          : "[]",
      deleting: false,
      loading: false
    };

    this._note_input = React.createRef();
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    if (
      (!!this.state.note_text || JSON.parse(this.state.file_urls).length > 0) &&
      (this.state.note_text != panel_data?.note?.text ||
        this.state.file_urls != panel_data?.note?.file_urls)
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      panel_data: { property, note }
    } = this.state;
    const { note_text, file_urls } = this.state;

    return (
      <Container>
        <NewHeader
          title="Edit Note"
          subtitle={"Edit note for " + property.property_address}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <NoteTextBox
            noteValue={note_text}
            file_urls={file_urls}
            onSubmit={() => {
              this.props.updateNote({
                token: this.props.token,
                deal_id: property?.deal?.id,
                type: "edit_note",
                payload: {
                  note_id: note.id,
                  text: note_text,
                  file_urls: file_urls
                },
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                },
                onSuccess: results => {
                  this.setState({
                    loading: false
                  });
                  if (this.state.panel_data?.onSuccess) {
                    this.state.panel_data.onSuccess(results);
                  }
                  this.handleBack();
                }
              });
            }}
            onNoteChange={value => {
              this.setState({
                note_text: value
              });
            }}
            disabled={!this.checkIfNeedsToSave()}
            loading={this.state.loading}
            button_text="Edit Note"
            onFilesChanged={value => {
              this.setState({
                file_urls: value
              });
            }}
            {...this.props}
          />
        </KeyboardView>

        <BottomNavBar>
          <ConfirmInlineButton
            confirm_text="Really Delete?"
            confirm_icon="delete"
            loading_text="Deleting"
            loading={this.state.deleting}
            button_type="full"
            onPress={() => {
              this.props.updateNote({
                token: this.props.token,
                deal_id: property?.deal?.id,
                type: "remove_note",
                payload: {
                  note_id: note.id
                },
                onLoading: () => {
                  this.setState({
                    deleting: true
                  });
                },
                onError: () => {
                  this.setState({
                    deleting: false
                  });
                },
                onSuccess: results => {
                  this.setState({
                    deleting: false
                  });
                  if (this.state.panel_data?.onSuccess) {
                    this.state.panel_data.onSuccess(results);
                  }
                  this.handleBack();
                }
              });
            }}
          >
            Delete Note
          </ConfirmInlineButton>
        </BottomNavBar>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateNote
})(EditNote);
