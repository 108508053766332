import React, { Component } from "react";
import DocumentMeta from "react-document-meta";

class Page extends Component {
  componentDidMount() {
    const { page_slug, page_type = "page" } = this.props;
  }

  render() {
    const {
      id,
      style,
      children,
      titleTag,
      metaTitle,
      metaDescription,
      metaKeywords = [],
      shareImage,
      author = null,
      contentType = "website",
      responsive_design = false
    } = this.props;

    //const pagePath = window.location.href.replace(window.location.origin, "");
    const meta = {
      title: titleTag
        ? titleTag
        : metaTitle
        ? metaTitle
        : "DealMachine for Real Estate",
      description: metaDescription,
      //canonical: window.location.origin + pagePath,
      meta: {
        charset: "utf-8",
        name: {
          author: !!author ? author : "DealMachine",
          "og:title": titleTag
            ? titleTag
            : metaTitle
            ? metaTitle
            : "DealMachine for Real Estate",
          "og:description": metaDescription,
          "og:image": !!shareImage ? shareImage : "",
          //"og:url": "https://dealmachine.com/" + pagePath,

          "article:publisher": "https://www.facebook.com/dealmachineapp",

          "twitter:description": metaDescription,
          "twitter:title": titleTag,
          "twitter:image": !!shareImage
            ? shareImage
            : "//images.ctfassets.net/tbaxdqcpgx77/49hqSYARAIEu6SWwgIiuey/3c085f0b0b9858a68ab906ba67ed845a/share_image.png",

          "twitter:card": "summary_large_image",
          "twitter:domain": "dealmachine.com",
          "twitter:site": "@DealMachineApp",

          "og:type": contentType
          //keywords: metaKeywords
        }
      }
    };

    return <DocumentMeta {...meta}>{children}</DocumentMeta>;
  }
}

export { Page };
