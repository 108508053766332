import {
  TRIGGER_LOGOUT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  GET_MAILER_CAMPAIGNS,
  GET_MAILER_CAMPAIGNS_SUCCESS,
  GET_MAILER_CAMPAIGNS_FAIL,
  REFRESH_MAILER_CAMPAIGNS,
  LOAD_MORE_MAILER_CAMPAIGNS,
  LOAD_MORE_MAILER_CAMPAIGNS_SUCCESS,
  SET_ACTIVE_MAILER_CAMPAIGN,
  SET_ACTIVE_MAILER_CAMPAIGN_FAIL,
  SET_ACTIVE_MAILER_CAMPAIGN_SUCCESS,
  UPDATE_MAILER_CAMPAIGN,
  UPDATE_MAILER_CAMPAIGN_FAIL,
  UPDATE_MAILER_CAMPAIGN_SUCCESS,
  CREATE_MAILER_CAMPAIGN_SUCCESS,
  SET_START_MAILER_CAMPAIGN_PAGE,
  GET_LEAD_MAILER_CAMPAIGN,
  GET_LEAD_MAILER_CAMPAIGN_SUCCESS,
  GET_LEAD_MAILER_CAMPAIGN_FAIL,
  REMOVE_MAILER_CAMPIGN_SUCCESS,
  SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS,
  GET_MAILER_TEMPLATES,
  GET_MAILER_TEMPLATES_FAIL,
  GET_MAILER_TEMPLATES_SUCCESS,
  REFRESH_MAILER_TEMPLATES,
  LOAD_MORE_MAILER_TEMPLATES,
  LOAD_MORE_MAILER_TEMPLATES_SUCCESS,
  SET_ACTIVE_MAILER_TEMPLATE,
  SET_ACTIVE_MAILER_TEMPLATE_SUCCESS,
  SET_ACTIVE_MAILER_TEMPLATE_FAIL,
  UPDATE_MAILER_TEMPLATE,
  UPDATE_MAILER_TEMPLATE_SUCCESS,
  UPDATE_MAILER_TEMPLATE_FAIL,
  CREATE_MAILER_TEMPLATE_SUCCESS,
  REMOVE_MAILER_TEMPLATE_SUCCESS,
  GET_MAILER_SIGNATURES,
  GET_MAILER_SIGNATURES_FAIL,
  GET_MAILER_SIGNATURES_SUCCESS,
  REFRESH_MAILER_SIGNATURES,
  LOAD_MORE_MAILER_SIGNATURES,
  LOAD_MORE_MAILER_SIGNATURES_SUCCESS,
  SET_ACTIVE_MAILER_SIGNATURE,
  SET_ACTIVE_MAILER_SIGNATURE_SUCCESS,
  SET_ACTIVE_MAILER_SIGNATURE_FAIL,
  UPDATE_MAILER_SIGNATURE,
  UPDATE_MAILER_SIGNATURE_SUCCESS,
  UPDATE_MAILER_SIGNATURE_FAIL,
  CREATE_MAILER_SIGNATURE_SUCCESS,
  REMOVE_MAILER_SIGNATURE_SUCCESS,
  GET_MAILER_DESIGN_TEMPLATES,
  GET_MAILER_DESIGN_TEMPLATES_FAIL,
  GET_MAILER_DESIGN_TEMPLATES_SUCCESS,
  SET_DEFAULT_MAILER_TEMPLATE_SUCCESS,
  SET_DEFAULT_MAILER_SIGNATURE_SUCCESS,
  SET_MAILER_VIEW,
  SET_MAILER_SELECTOR,
  GET_SENDING_QUEUE,
  GET_SENDING_QUEUE_FAIL,
  GET_SENDING_QUEUE_SUCCESS,
  LOAD_MORE_SENDING_QUEUE,
  LOAD_MORE_SENDING_QUEUE_SUCCESS,
  GET_SENDING_QUEUE_COUNTS,
  GET_SENDING_QUEUE_COUNTS_FAIL,
  GET_SENDING_QUEUE_COUNTS_SUCCESS,
  SWITCH_MARKETING_TAB,
  SET_NEW_MAILER_CAMPAIGN
} from "app/DealMachineCore/types";

import { appRedirect } from "app/NativeActions";

import APIV2 from "app/DealMachineCore/apis/DealMachineAPIV2";
const api = APIV2.create();

export const setStartMailerCampaignPage = ({
  title,
  subtitle,
  lead_ids,
  select_all = 0,
  number_of_leads,
  number_of_addresses = null,
  selected_campaign = null,
  filters,
  filter_lists,
  new_filters,
  property_flags,
  property_flags_and_or,
  search,
  search_type,
  list_history_id,
  list_id,
  onSuccess = null
}) => {
  return {
    type: SET_START_MAILER_CAMPAIGN_PAGE,
    payload: {
      title,
      subtitle,
      lead_ids,
      select_all,
      number_of_leads,
      number_of_addresses,
      selected_campaign,
      filters,
      filter_lists,
      new_filters,
      property_flags,
      property_flags_and_or,
      search,
      search_type,
      list_history_id,
      list_id,
      onSuccess
    }
  };
};

export const setActiveMailerCampaign = ({ token, mailer_campaign }) => {
  return dispatch => {
    if (mailer_campaign) {
      dispatch({
        type: SET_ACTIVE_MAILER_CAMPAIGN,
        payload: mailer_campaign
      });
      api
        .getMailerCampaigns({
          token,
          mailer_campaign_id: mailer_campaign.id
        })
        .then(response => {
          if (response.problem != null) {
            dispatch({
              type: SET_ACTIVE_MAILER_CAMPAIGN_FAIL,
              payload: response.problem
            });
          } else if (response.data.error != false) {
            dispatch({
              type: SET_ACTIVE_MAILER_CAMPAIGN_FAIL,
              payload: response.data.error
            });

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            dispatch({
              type: SET_ACTIVE_MAILER_CAMPAIGN_SUCCESS,
              payload: {
                mailer_campaign: response.data?.results?.mailer_campaigns[0],
                statuses: response.data?.results?.statuses
              }
            });
          }
        });
    } else {
      dispatch({
        type: SET_ACTIVE_MAILER_CAMPAIGN,
        payload: mailer_campaign
      });
    }
  };
};

export const getMailerCampaigns = ({
  token,
  load_type,
  begin = 0,
  limit = 25,
  search,
  sort_by,
  filter_type,
  exclude_repeating_mail,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    let type = "mailer_campaigns";
    if (onLoading) {
      onLoading();
    } else {
      switch (load_type) {
        default:
        case "load":
          dispatch({ type: GET_MAILER_CAMPAIGNS });
          break;
        case "load_all":
          dispatch({ type: GET_MAILER_CAMPAIGNS });
          type = "load_all_mailer_campaigns";

          break;

        case "load_more":
          dispatch({ type: LOAD_MORE_MAILER_CAMPAIGNS });
          break;

        case "refresh":
          dispatch({ type: REFRESH_MAILER_CAMPAIGNS });
          break;
      }
    }

    api
      .getMailerCampaigns({
        token,
        type,
        begin,
        limit,
        search,
        sort_by,
        exclude_repeating_mail,
        filter_type
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          } else {
            dispatch({
              type: GET_MAILER_CAMPAIGNS_FAIL,
              payload: response.problem
            });
          }
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          } else {
            dispatch({
              type: GET_MAILER_CAMPAIGNS_FAIL,
              payload: response.data.error
            });
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            switch (load_type) {
              default:
              case "load":
              case "refresh":
                dispatch({
                  type: GET_MAILER_CAMPAIGNS_SUCCESS,
                  payload: response.data.results
                });
                break;

              case "load_more":
                dispatch({
                  type: LOAD_MORE_MAILER_CAMPAIGNS_SUCCESS,
                  payload: response.data.results
                });
                break;
            }
          }
        }
      });
  };
};

export const updateMailerCampaign = ({
  token,
  type = "create_mailer_campaign",
  mailer_campaign_id,
  title,
  on_start_campaign,
  on_start_campaign_status,
  on_pause_campaign,
  on_pause_campaign_status,
  on_end_campaign,
  on_end_campaign_status,
  campaign_steps,
  onLoading = null,
  onError = null,
  onSuccess = null,
  repeat_mail_sequence
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }
    dispatch({
      type: UPDATE_MAILER_CAMPAIGN
    });

    api
      .updateMailerCampaigns({
        token,
        type,
        title,
        mailer_campaign_id,
        on_start_campaign,
        on_start_campaign_status,
        on_pause_campaign,
        on_pause_campaign_status,
        on_end_campaign,
        on_end_campaign_status,
        campaign_steps,
        repeat_mail_sequence
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          }
          dispatch({
            type: UPDATE_MAILER_CAMPAIGN_FAIL,
            payload: response.problem
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data?.error != false) {
          if (onError) {
            onError();
          }
          dispatch({
            type: UPDATE_MAILER_CAMPAIGN_FAIL,
            payload: response.data.error
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data?.results);
          } else {
            dispatch({ type: IS_LOADING, payload: false });

            switch (type) {
              case "create_mailer_campaign":
                dispatch({
                  type: CREATE_MAILER_CAMPAIGN_SUCCESS,
                  payload: response.data?.results
                });

                break;
              case "remove_mailer_campaign":
                dispatch({
                  type: REMOVE_MAILER_CAMPIGN_SUCCESS,
                  payload: { mailer_campaign_id }
                });
                break;
              default:
              case "update_mailer_campaign_title":
                dispatch({
                  type: UPDATE_MAILER_CAMPAIGN_SUCCESS,
                  payload: response.data?.results
                });
                break;
              case "set_default_mailer_campaign":
                dispatch({
                  type: SET_DEFAULT_MAILER_CAMPAIGN_SUCCESS,
                  payload: response.data?.results
                });
                break;
            }
          }
        }
      });
  };
};

export const getLeadMailerCampaign = ({ token, lead_id }) => {
  return dispatch => {
    dispatch({
      type: GET_LEAD_MAILER_CAMPAIGN
    });
    api
      .getMailerCampaigns({
        token,
        type: "lead_mailer_campaign",
        lead_id
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_LEAD_MAILER_CAMPAIGN_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_LEAD_MAILER_CAMPAIGN_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({
            type: GET_LEAD_MAILER_CAMPAIGN_SUCCESS,
            payload: response.data?.results
          });
        }
      });
  };
};

export const getMailerStats = ({
  token,
  mailer_template_id,
  onLoading = () => {},
  onError = () => {},
  onSuccess = () => {}
}) => {
  return dispatch => {
    onLoading();

    api
      .getMailerTemplates({
        token,
        type: "get_mailer_stats",
        mailer_template_id
      })
      .then(response => {
        if (response.problem != null) {
          onError();
        } else if (response.data.error != false) {
          onError();
        } else {
          onSuccess(response.data.results);
        }
      });
  };
};

export const getMailerTemplates = ({
  token,
  load_type,
  begin = 0,
  limit = 25,
  load_all = false,
  search,
  sort_by,
  filter_type,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      switch (load_type) {
        default:
        case "load":
          dispatch({ type: GET_MAILER_TEMPLATES });
          break;

        case "load_more":
          dispatch({ type: LOAD_MORE_MAILER_TEMPLATES });
          break;

        case "refresh":
          dispatch({ type: REFRESH_MAILER_TEMPLATES });
          break;
      }
    }

    api
      .getMailerTemplates({
        token,
        begin,
        limit,
        load_all,
        search,
        sort_by,
        filter_type
      })
      .then(response => {
        if (response.problem != null) {
          if (onError) {
            onError();
          } else {
            dispatch({
              type: GET_MAILER_TEMPLATES_FAIL,
              payload: response.problem
            });
          }
        } else if (response.data.error != false) {
          if (onError) {
            onError();
          } else {
            dispatch({
              type: GET_MAILER_TEMPLATES_FAIL,
              payload: response.data.error
            });
          }

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          } else {
            switch (load_type) {
              default:
              case "load":
              case "refresh":
                dispatch({
                  type: GET_MAILER_TEMPLATES_SUCCESS,
                  payload: response.data.results
                });
                break;

              case "load_more":
                dispatch({
                  type: LOAD_MORE_MAILER_TEMPLATES_SUCCESS,
                  payload: response.data.results
                });
                break;
            }
          }
        }
      });
  };
};

export const setActiveMailerTemplate = ({ token, mailer_template }) => {
  return dispatch => {
    dispatch({
      type: SET_ACTIVE_MAILER_TEMPLATE,
      payload: mailer_template
    });
    /*
    if (mailer_template) {
      dispatch({
        type: SET_ACTIVE_MAILER_TEMPLATE,
        payload: mailer_template
      });
      api
        .getMailerTemplates({
          token,
          mailer_template_id: mailer_template.id
        })
        .then(response => {
          if (response.problem != null) {
            dispatch({
              type: SET_ACTIVE_MAILER_TEMPLATE_FAIL,
              payload: response.problem
            });
          } else if (response.data.error != false) {
            dispatch({
              type: SET_ACTIVE_MAILER_TEMPLATE_FAIL,
              payload: response.data.error
            });

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            dispatch({
              type: SET_ACTIVE_MAILER_TEMPLATE_SUCCESS,
              payload: {
                mailer_template: response.data?.results?.mailer_templates[0]
              }
            });
          }
        });
    } else {
      dispatch({
        type: SET_ACTIVE_MAILER_TEMPLATE,
        payload: mailer_template
      });
    }
    */
  };
};

export const updateMailerTemplate = ({
  token,
  type = "update_mailer_template",
  mailer_template_id,
  template_title,
  template_system_type,
  template_type,
  front_layers,
  back_layers,
  section_a,
  section_b,
  section_c,
  section_d,
  signature_id,
  signature_sign_off,
  return_address,
  return_address2,
  return_address_city,
  return_address_state,
  return_address_zip,
  signature_name,
  signature_phone,
  call_tracking_number_id,
  signature_email,
  signature_image,
  html_template_id,
  primary_color,
  secondary_color,
  custom_image,
  include_custom_image,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    } else {
      dispatch({ type: IS_LOADING, payload: true });
    }

    dispatch({
      type: UPDATE_MAILER_TEMPLATE
    });

    api
      .updateMailerTemplate({
        token,
        type,
        mailer_template_id,
        template_title,
        template_system_type,
        template_type,
        front_layers,
        back_layers,
        section_a,
        section_b,
        section_c,
        section_d,
        signature_id,
        signature_sign_off,
        return_address,
        return_address2,
        return_address_city,
        return_address_state,
        return_address_zip,
        signature_name,
        signature_phone,
        call_tracking_number_id,
        signature_email,
        signature_image,
        html_template_id,
        primary_color,
        secondary_color,
        custom_image,
        include_custom_image
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_MAILER_TEMPLATE_FAIL,
            payload: response.problem
          });
          if (onError) {
            onError();
          }
          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data?.error != false) {
          dispatch({
            type: UPDATE_MAILER_TEMPLATE_FAIL,
            payload: response.data.error
          });
          if (onError) {
            onError();
          }

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });
          if (onSuccess) {
            onSuccess(response.data?.results);
          }
          switch (type) {
            case "create_mailer_template":
              dispatch({
                type: CREATE_MAILER_TEMPLATE_SUCCESS,
                payload: response.data?.results
              });
              break;
            case "remove_mailer_template":
              dispatch({
                type: REMOVE_MAILER_TEMPLATE_SUCCESS,
                payload: { mailer_template_id }
              });
              break;

            case "set_default_mailer_template":
              dispatch({
                type: SET_DEFAULT_MAILER_TEMPLATE_SUCCESS,
                payload: response.data?.results
              });
              break;

            default:
              dispatch({
                type: UPDATE_MAILER_TEMPLATE_SUCCESS,
                payload: response.data?.results
              });

              break;
          }
        }
      });
  };
};

export const getMailerSignatures = ({
  token,
  load_type,
  begin = 0,
  limit = 25,
  search,
  sort_by,
  filter_type
}) => {
  return dispatch => {
    switch (load_type) {
      default:
      case "load":
        dispatch({ type: GET_MAILER_SIGNATURES });
        break;

      case "load_more":
        dispatch({ type: LOAD_MORE_MAILER_SIGNATURES });
        break;

      case "refresh":
        dispatch({ type: REFRESH_MAILER_SIGNATURES });
        break;
    }

    api
      .getMailerSignatures({
        token,
        begin,
        limit,
        search,
        sort_by,
        filter_type
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_MAILER_SIGNATURES_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_MAILER_SIGNATURES_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (load_type) {
            default:
            case "load":
            case "refresh":
              dispatch({
                type: GET_MAILER_SIGNATURES_SUCCESS,
                payload: response.data.results
              });
              break;

            case "load_more":
              dispatch({
                type: LOAD_MORE_MAILER_SIGNATURES_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const setActiveMailerSignature = ({ token, mailer_signature }) => {
  return dispatch => {
    if (mailer_signature) {
      dispatch({
        type: SET_ACTIVE_MAILER_SIGNATURE,
        payload: mailer_signature
      });
      api
        .getMailerSignatures({
          token,
          mailer_signature_id: mailer_signature.id
        })
        .then(response => {
          if (response.problem != null) {
            dispatch({
              type: SET_ACTIVE_MAILER_SIGNATURE_FAIL,
              payload: response.problem
            });
          } else if (response.data.error != false) {
            dispatch({
              type: SET_ACTIVE_MAILER_SIGNATURE_FAIL,
              payload: response.data.error
            });

            if (response.data.valid == "invalid") {
              dispatch({ type: TRIGGER_LOGOUT, payload: true });
            }
          } else {
            dispatch({
              type: SET_ACTIVE_MAILER_SIGNATURE_SUCCESS,
              payload: {
                mailer_signature: response.data?.results?.mailer_signatures[0]
              }
            });
          }
        });
    } else {
      dispatch({
        type: SET_ACTIVE_MAILER_SIGNATURE,
        payload: mailer_signature
      });
    }
  };
};

export const updateMailerSignature = ({
  token,
  type = "update_mailer_signature",
  mailer_signature_id,
  title,
  sign_off,
  address,
  address2,
  city,
  state,
  zip,
  name,
  other_contact,
  email,
  phone,
  signature_image,
  disclaimer,
  onSuccess = null
}) => {
  return dispatch => {
    dispatch({ type: IS_LOADING, payload: true });

    dispatch({
      type: UPDATE_MAILER_SIGNATURE
    });

    api
      .updateMailerSignature({
        token,
        type,
        mailer_signature_id,
        title,
        sign_off,
        address,
        address2,
        city,
        state,
        zip,
        name,
        other_contact,
        email,
        phone,
        signature_image,
        disclaimer
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: UPDATE_MAILER_SIGNATURE_FAIL,
            payload: response.problem
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.problem, title: "Error" }
          });
        } else if (response.data?.error != false) {
          dispatch({
            type: UPDATE_MAILER_SIGNATURE_FAIL,
            payload: response.data.error
          });

          dispatch({
            type: ERROR_MESSAGE,
            payload: { message: response.data.error, title: "Error" }
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          dispatch({ type: IS_LOADING, payload: false });

          switch (type) {
            case "create_mailer_tempalte":
              dispatch({
                type: CREATE_MAILER_SIGNATURE_SUCCESS,
                payload: response.data?.results
              });
              break;
            case "remove_mailer_signature":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've removed this signature",
                  title: "Success!"
                }
              });
              dispatch({
                type: REMOVE_MAILER_SIGNATURE_SUCCESS,
                payload: { mailer_signature_id }
              });
              break;

            case "set_default_mailer_signature":
              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've set your default signature.",
                  title: "Success!"
                }
              });
              dispatch({
                type: SET_DEFAULT_MAILER_SIGNATURE_SUCCESS,
                payload: response.data?.results
              });
              break;

            default:
              dispatch({
                type: UPDATE_MAILER_SIGNATURE_SUCCESS,
                payload: response.data?.results
              });

              dispatch({
                type: SUCCESS_MESSAGE,
                payload: {
                  message: "You've updated this mail piece",
                  title: "Success!"
                }
              });

              break;
          }

          if (onSuccess) {
            onSuccess(response.data?.results);
          }
        }
      });
  };
};

export const getMailerDesignTemplates = ({
  token,
  template_type,
  onLoading = null,
  onError = null,
  onSuccess = null
}) => {
  return dispatch => {
    if (onLoading) {
      onLoading();
    }
    dispatch({ type: GET_MAILER_DESIGN_TEMPLATES });

    api
      .getMailerDesignTemplates({
        token,
        template_type
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_MAILER_DESIGN_TEMPLATES_FAIL,
            payload: response.problem
          });

          if (onError) {
            onError();
          }
        } else if (response.data.error != false) {
          dispatch({
            type: GET_MAILER_DESIGN_TEMPLATES_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }

          if (onError) {
            onError();
          }
        } else {
          if (onSuccess) {
            onSuccess(response.data.results);
          }
          dispatch({
            type: GET_MAILER_DESIGN_TEMPLATES_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const setMailerView = ({ mailer, flipped }) => {
  return {
    type: SET_MAILER_VIEW,
    payload: { mailer, flipped }
  };
};

export const setMailerSelector = ({ title, onSelect }) => {
  return {
    type: SET_MAILER_SELECTOR,
    payload: { title, onSelect }
  };
};

export const switchMarketingTab = tab => {
  return {
    type: SWITCH_MARKETING_TAB,
    payload: tab
  };
};

export const getSendingQueue = ({ token, begin = 0, load_type }) => {
  return dispatch => {
    switch (load_type) {
      default:
      case "load":
      case "refresh":
        dispatch({ type: GET_SENDING_QUEUE });
        break;

      case "load_more":
        dispatch({ type: LOAD_MORE_SENDING_QUEUE });
        break;
    }

    api
      .getSendingQueue({
        token,
        begin
      })
      .then(response => {
        if (response.problem != null) {
          dispatch({
            type: GET_SENDING_QUEUE_FAIL,
            payload: response.problem
          });
        } else if (response.data.error != false) {
          dispatch({
            type: GET_SENDING_QUEUE_FAIL,
            payload: response.data.error
          });

          if (response.data.valid == "invalid") {
            dispatch({ type: TRIGGER_LOGOUT, payload: true });
          }
        } else {
          switch (load_type) {
            default:
            case "load":
            case "refresh":
              dispatch({
                type: GET_SENDING_QUEUE_SUCCESS,
                payload: response.data.results
              });
              break;

            case "load_more":
              dispatch({
                type: LOAD_MORE_SENDING_QUEUE_SUCCESS,
                payload: response.data.results
              });
              break;
          }
        }
      });
  };
};

export const getSendingQueueCounts = ({ token, begin = 0, load_type }) => {
  return dispatch => {
    api
      .getSendingQueue({
        token,
        type: "sending_queue_counts"
      })
      .then(response => {
        if (response.problem != null) {
        } else if (response.data.error != false) {
        } else {
          dispatch({
            type: GET_SENDING_QUEUE_COUNTS_SUCCESS,
            payload: response.data.results
          });
        }
      });
  };
};

export const setNewMailerCampaign = ({
  onSuccess = null,
  onBack = null,
  overlay = false,
  mailer_campaign
}) => {
  return {
    type: SET_NEW_MAILER_CAMPAIGN,
    payload: { onSuccess, onBack, overlay, mailer_campaign }
  };
};
