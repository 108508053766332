import React from "react";

const Bold = ({ children, style }) => {
  return (
    <span className="deal-bold" style={style}>
      {children}
    </span>
  );
};

export { Bold };
