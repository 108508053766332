import QRCode from "react-qr-code";
import React from "react";

const QRCodeWrapper = ({ size = 100, style, value }) => {
  return (
    <QRCode
      size={size}
      style={{
        ...style
      }}
      viewBox={`256 256 256 256`}
      value={value}
    />
  );
};

export { QRCodeWrapper };
