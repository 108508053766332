import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  KeyboardView,
  Wrapper,
  Copy,
  Bold
} from "app/NativeComponents/common";
import {
  NewHeader,
  AttentionBox,
  SelectBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  getInvoices,
  editCredits,

  /*common functions*/
  dismissMobileKeyboard,
  renderPrice
} from "app/NativeActions";

class CreditReload extends Component {
  constructor(props) {
    super(props);
    let credit_reload_array = [
      /*
      {
        key: 1,
        value: 0,
        label: "Off"
      },
      */
      {
        key: 5,
        value: 50,
        label: "$50.00"
      },
      {
        key: 6,
        value: 60,
        label: "$60.00"
      },
      {
        key: 7,
        value: 70,
        label: "$70.00"
      },
      {
        key: 8,
        value: 8000,
        label: "$80.00"
      },
      {
        key: 9,
        value: 90,
        label: "$90.00"
      },
      {
        key: 10,
        value: 100,
        label: "$100.00"
      },
      {
        key: 11,
        value: 200,
        label: "$200.00"
      },
      {
        key: 12,
        value: 300,
        label: "$300.00"
      },
      {
        key: 13,
        value: 400,
        label: "$400.00"
      },
      {
        key: 14,
        value: 500,
        label: "$500.00"
      },
      {
        key: 15,
        value: 600,
        label: "$600.00"
      },
      {
        key: 16,
        value: 700,
        label: "$700.00"
      },
      {
        key: 17,
        value: 800,
        label: "$800.00"
      },
      {
        key: 18,
        value: 900,
        label: "$900.00"
      },
      {
        key: 19,
        value: 1000,
        label: "$1000.00"
      }
    ];

    this.state = {
      credit_reload_array: credit_reload_array,
      credit_amount: props.user?.team_default_credit_reload,
      loading: false,
      show_options: false
    };

    this._credit_input = React.createRef();
    this.handleBack = this.handleBack.bind(this);
  }

  editCreditAmount(amount) {
    this.setState({
      credit_amount: amount
    });
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.popSidePanel();
  }

  saveDefaultCreditAmount(card_token, error) {
    dismissMobileKeyboard();

    this.props.editCredits({
      token: this.props.token,
      type: "set_default_credit_reload",
      payload: {
        amount: this.state.credit_amount ? this.state.credit_amount : 0
      },
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });
        this.props.popSidePanel();
      }
    });
  }

  checkIfNeedsToSave() {
    if (
      this.state.credit_amount != this.props.user?.team_default_credit_reload
    ) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <Container>
        <NewHeader
          title={"Manage Auto-Reload"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <AttentionBox description="Marketing Credits on your account are used to purchase mail pieces and skip traces." />

          <SelectBox
            select_ref={this._credit_input}
            items={this.state.credit_reload_array}
            disabled={this.state.loading}
            value={this.state.credit_amount}
            placeholder="Select Auto-Reload Amount"
            show={this.state.show_options}
            onFocus={() => {
              this.setState({ show_options: true });
            }}
            onBlur={() => {
              this.setState({ show_options: false });
            }}
            onSelect={item => {
              this.editCreditAmount(item);
            }}
          />
          <>
            <Wrapper style={{ padding: 25 }}>
              <Copy>
                If your marketing credit balance falls to zero, recharge your
                balance with{" "}
                <Bold>
                  {renderPrice(parseInt(this.state.credit_amount) * 100)}
                </Bold>
              </Copy>
            </Wrapper>
            <GhostButton
              primary={true}
              button_type={"full"}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => this.saveDefaultCreditAmount()}
              loading={this.state.loading}
            >
              Save Auto-Reload Amount
            </GhostButton>
          </>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, billing }) => {
  const { token, user, onboarding } = auth;
  const { device, platform } = native;
  const { invoices, invoices_loading, invoices_error } = billing;

  return {
    token,
    user,
    onboarding,
    device,
    platform,
    invoices,
    invoices_loading,
    invoices_error
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getInvoices,
  editCredits
})(CreditReload);
