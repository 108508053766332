import React from "react";
import Popover from "react-simple-popover";
import { Animation, ModalOverlay } from "app/NativeComponents/common";
import { store } from "app/store";
const renderLoading = is_loading => {
  if (is_loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.3)"
        }}
      />
    );
  }

  return <div />;
};

const determinePlacement = (target, preferred_placement, modal_width) => {
  const window_width = window.innerWidth;
  const window_height = window.innerHeight;

  const target_rect = target.getBoundingClientRect();
  const x_pos = target_rect.x;
  const y_pos = target_rect.y;

  const target_height = target_rect.height;
  const target_width = target_rect.width;

  const top = y_pos;
  const left = x_pos;
  const bottom = window_height - top - target_height;
  const right = window_width - left - target_width;

  let left_or_right = "right";
  if (left > right) {
    left_or_right = "left";
  }

  let top_or_bottom = "bottom";
  if (top > bottom) {
    left_or_right = "top";
  }

  switch (preferred_placement) {
    case "right":
    default:
      if (right - modal_width > 0) {
        return "right";
      } else if (left > right) {
        if (bottom > left) {
          return "bottom";
        } else if (top > left) {
          return "top";
        }
        return "left";
      }
      break;

    case "left":
      if (left - modal_width > 0) {
        return "left";
      } else if (right > left) {
        if (bottom > right) {
          return "bottom";
        } else if (top > right) {
          return "top";
        }
        return "right";
      }
      break;

    case "top":
    case "bottom":
      if (top > bottom) {
        return "top";
      }
      return "bottom";
  }

  return preferred_placement;
};

const ModalContainer = ({
  children,
  style,
  popoverTarget = null,
  hidePopover,
  popoverPlacement = "right",
  popoverWidth = 300,
  is_loading = false,
  overlay = true,
  animation_type = "fadeInUp",
  no_overlay = false,
  ...props
}) => {
  const colors = store.getState().settings.colors;

  let zIndex = document.querySelectorAll(".deal-modal-overlay").length;
  if (!zIndex) {
    zIndex = 1;
  }

  if (popoverTarget) {
    let placement = determinePlacement(
      popoverTarget,
      popoverPlacement,
      popoverWidth
    );

    return (
      <div
        style={{ zIndex: zIndex }}
        onClick={e => {
          hidePopover();
        }}
      >
        <Popover
          placement={placement}
          target={popoverTarget}
          show={true}
          containerStyle={{ opacity: is_loading ? 0.5 : 1 }}
          style={{
            width: popoverWidth,
            borderRadius: 5,
            padding: 0,
            overflow: "hidden"
          }}
          onHide={() => {}}
        >
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={"deal-popover"}
            style={{
              position: "relative",
              backgroundColor: colors.background_color,
              ...style
            }}
          >
            {children}
          </div>
          {renderLoading(is_loading)}
        </Popover>
      </div>
    );
  }

  if (no_overlay) {
    return (
      <div style={{ zIndex: zIndex }} onClick={() => {}} {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        >
          <Animation
            type={animation_type}
            onClick={e => {
              e.stopPropagation();
            }}
            className={"deal-modal deal-modal-container"}
            style={{
              backgroundColor: colors.background_color,
              ...style
            }}
          >
            {children}
          </Animation>
        </div>
      </div>
    );
  }

  return (
    <ModalOverlay>
      <div style={{ zIndex: zIndex }} onClick={() => {}} {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        >
          <Animation
            type={animation_type}
            onClick={e => {
              e.stopPropagation();
            }}
            className={"deal-modal deal-modal-container"}
            style={{
              backgroundColor: colors.background_color,
              ...style
            }}
          >
            {children}
          </Animation>
        </div>
      </div>
    </ModalOverlay>
  );
};

export { ModalContainer };
