import React, { Component } from "react";
import { Wrapper, Card, PrimaryButton } from "app/NativeComponents/common";
import { GhostButton, InlineButton } from "app/NativeComponents/snippets";

class Buttons extends Component {
  render() {
    return (
      <>
        <GhostButton
          button_type="full"
          primary={true}
          loading={this.props.loading}
          onPress={() => this.props.forgot()}
        >
          Reset Password
        </GhostButton>
        {this.props.device == "desktop" ? (
          <InlineButton
            button_type={"full"}
            internal={true}
            rel={""}
            href={"/login"}
          >
            Back to login
          </InlineButton>
        ) : null}
      </>
    );
  }
}

export default Buttons;
