import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Wrapper, Scroll } from "app/NativeComponents/common";
import {
  PopoverMenu,
  List,
  SelectItem,
  SmallLabel,
  SpinWrapper
} from "app/NativeComponents/snippets";
import { updateLead, getLeadStatuses } from "app/NativeActions";

class LeadStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.show !== this.state.show &&
      this.props.lead_statuses?.length == 0 &&
      !this.props.lead_statuses_loading
    ) {
      this.props.getLeadStatuses({
        token: this.props.token
      });
    }
  }

  updateLeadWithStatus(new_status) {
    const {
      property: { deal }
    } = this.props;
    this.props.updateLead({
      token: this.props.token,
      type: "edit_status_for_lead",
      deal_ids: deal.id,
      deal_status: new_status.id,
      no_loading: true,
      onLoading: () => {
        this.props.onLoading({
          loading: true,
          temp_value: new_status.title
        });
        this.setState({
          show: false
        });
      },
      onSuccess: results => {
        this.props.onLoading({
          loading: false,
          temp_value: null
        });

        if (this.props.propertyUpdated) {
          this.props.propertyUpdated(
            results.properties && results.properties?.length > 0
              ? results.properties[0]
              : this.props.property
          );
        }
      },
      onError: () => {
        this.props.onLoading({ loading: false, temp_value: null });
        this.setState({
          show: true
        });
      }
    });
  }

  render() {
    const {
      colors,
      isMobile,
      device,
      lead_statuses,
      lead_statuses_loading,
      style,
      property,
      property: { deal },
      popoverPlacement = "bottom",
      renderComponent = () => {},
      componentStyle = {}
    } = this.props;
    const { show } = this.state;

    let next_status = null;
    let other_statuses = [];

    if (lead_statuses && show) {
      for (let i = 0; i < lead_statuses.length; i++) {
        if (
          lead_statuses[i].id == deal.deal_status_id &&
          lead_statuses[i].status_type != "trash_status" &&
          lead_statuses[i].status_type != "inactive_status" &&
          lead_statuses[i].status_type != "return_to_sender_status" &&
          lead_statuses[i].status_type != "marketing_complete_status" &&
          lead_statuses[i].status_type != "not_interested_status" &&
          lead_statuses[i].status_type != "unqualified_status" &&
          i < lead_statuses.length - 1
        ) {
          const ns = lead_statuses[i + 1];
          if (
            ns.status_type != "trash_status" &&
            ns.status_type != "inactive_status" &&
            ns.status_type != "return_to_sender_status" &&
            lead_statuses[i].status_type != "marketing_complete_status" &&
            lead_statuses[i].status_type != "not_interested_status" &&
            lead_statuses[i].status_type != "unqualified_status"
          ) {
            next_status = ns;
          }
        } else if (
          lead_statuses[i].status_type == "trash_status" ||
          lead_statuses[i].status_type == "return_to_sender_status"
        ) {
          other_statuses.push(lead_statuses[i]);
        }
      }
    }

    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={350}
        popover_height={398}
        popoverSheetTop={"75%"}
        popoverPlacement={popoverPlacement}
        popover_title={"Update the status for " + property.property_address}
        includeCloseButton={true}
        renderComponent={options => {
          if (this.props.device == "mobile") {
            return (
              <Row>{renderComponent({ ...options, pressedIn: show })}</Row>
            );
          }
          return (
            <Wrapper wrapper_ref={this._popover} style={{ flex: 1 }}>
              {renderComponent({ ...options, pressedIn: show })}
            </Wrapper>
          );
        }}
        renderMenu={() => {
          return (
            <>
              {lead_statuses_loading ? (
                <SpinWrapper text="Loading Statuses..." />
              ) : (
                <>
                  <List
                    style={{ alignSelf: "stretch" }}
                    items={lead_statuses}
                    listHeader={() => {
                      return (
                        <>
                          {next_status ? (
                            <SelectItem
                              icon={"keyboard-arrow-right"}
                              select_type="none"
                              primary={true}
                              onPress={() => {
                                this.updateLeadWithStatus(next_status);
                              }}
                              label={"Next status:"}
                              style={{
                                backgroundColor: colors.orange_color_muted
                              }}
                            >
                              {next_status.title}
                            </SelectItem>
                          ) : null}
                          <SmallLabel
                            style={{
                              padding: 20,
                              paddingTop: 15,
                              paddingBottom: 0
                            }}
                          >
                            Select a status:
                          </SmallLabel>
                        </>
                      );
                    }}
                    itemStructure={({ item, index }) => {
                      if (
                        item?.id != deal?.deal_status_id &&
                        item?.id != next_status?.id &&
                        item.status_type != "trash_status" &&
                        item.status_type != "return_to_sender_status"
                      ) {
                        return (
                          <SelectItem
                            key={"option_" + index}
                            icon={"keyboard-arrow-right"}
                            select_type="none"
                            onPress={() => {
                              this.updateLeadWithStatus(item);
                            }}
                          >
                            {item.title}
                          </SelectItem>
                        );
                      }
                      return null;
                    }}
                    listFooter={() => {
                      return (
                        <>
                          <SmallLabel
                            style={{
                              padding: 20,
                              paddingTop: 15,
                              paddingBottom: 0
                            }}
                          >
                            Other statuses:
                          </SmallLabel>
                          {other_statuses.map((lead_status, i) => {
                            if (
                              lead_status.id != deal.deal_status_id &&
                              lead_status.id != next_status?.id
                            ) {
                              return (
                                <SelectItem
                                  key={"option_" + i}
                                  icon={"keyboard-arrow-right"}
                                  select_type="none"
                                  onPress={() => {
                                    this.updateLeadWithStatus(lead_status);
                                  }}
                                >
                                  {lead_status.title}
                                </SelectItem>
                              );
                            }
                            return null;
                          })}
                          <Wrapper
                            style={{ height: device == "mobile" ? 100 : 0 }}
                          />
                        </>
                      );
                    }}
                  />
                </>
              )}
            </>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ auth, native, settings, customsettings }) => {
  const { token, user } = auth;
  const { isMobile, device } = native;
  const { colors } = settings;
  const { lead_statuses, lead_statuses_loading } = customsettings;
  return {
    token,
    user,
    isMobile,
    device,
    colors,
    lead_statuses,
    lead_statuses_loading
  };
};

export default connect(mapStateToProps, {
  updateLead,
  getLeadStatuses
})(LeadStatusButton);
