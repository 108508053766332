import React, { Component } from "react";
import {
  Wrapper,
  Title,
  Copy,
  Spin,
  Row,
  Card
} from "app/NativeComponents/common";
import { List } from "app/NativeComponents/snippets";
import LeadItem from "app/DealMachineCore/reuseable/LeadItem";

class PropertyList extends Component {
  constructor(props) {
    super(props);
    this.pressProperty = this.pressProperty.bind(this);
  }
  pressProperty(property) {
    this.props.pushSidePanel({
      slug: "property",
      id: property?.property_id,
      focus_side_panel:
        this.props.user?.user_settings?.property_expanded === "not_expanded"
          ? false
          : true,
      data: {
        property
      }
    });
  }
  render() {
    if (this.props.activity_properties_type.slug !== "mailers_sent") {
      if (
        this.props.activity_properties_refreshing ||
        (this.props.activity_properties_loading &&
          this.props.activity_properties.length == 0)
      ) {
        return (
          <Wrapper
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Row>
              <Spin size={"small"} />
              <Copy style={{ marginLeft: 10 }}>Loading Properties...</Copy>
            </Row>
          </Wrapper>
        );
      }
      if (this.props.activity_properties.length > 0) {
        return (
          <List
            rowNumber={1}
            style={{ flex: 1 }}
            items={this.props.activity_properties}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <LeadItem
                  key={"lead_" + item.property_id}
                  property={item}
                  onPress={() => {
                    this.pressProperty(item);
                  }}
                />
              );
            }}
            canRefresh={true}
            onRefresh={() => {
              this.props.getItems({
                load_type: "refresh"
              });
            }}
            is_refreshing={this.props.activity_properties_refreshing}
            canLoadMore={
              !this.props.activity_properties_loaded_all &&
              !this.props.activity_properties_loading &&
              !this.props.activity_properties_refreshing &&
              this.props.activity_properties.length > 0
            }
            isLoadingMore={
              this.props.activity_properties_loading &&
              !this.props.activity_properties_refreshing &&
              this.props.activity_properties.length > 0
            }
            onLoadMore={() => {
              if (
                !this.props.activity_properties_loaded_all &&
                this.props.activity_properties_refreshing != true &&
                this.props.activity_properties_loading != true
              ) {
                this.props.getItems({
                  load_type: "load_more",
                  begin: this.props.activity_properties_begin
                });
              }
            }}
          />
        );
      }
      if (this.props.activity_properties.length == 0) {
        return (
          <Wrapper
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Wrapper
              style={{
                padding: 25,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Copy style={{ textAlign: "center" }}>
                There are no properties to display.
              </Copy>
            </Wrapper>
          </Wrapper>
        );
      }
    }

    return <Wrapper />;
  }
}

export default PropertyList;
