import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Row,
  Scroll,
  Copy,
  Spin,
  Icon,
  Bold,
  Button,
  KeyboardView
} from "app/NativeComponents/common";
import {
  GhostButton,
  BottomNavBar,
  SmallHeader,
  SpinWrapper,
  SelectMultiple
} from "app/NativeComponents/snippets";
import { getTags } from "app/NativeActions";

class tagstep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.active_step?.data
        ? props.active_step.data
        : {
            tags: []
          }
    };
  }

  componentDidMount() {
    this.props.getTags(this.props.token);
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (this.state.data?.tags.length > 0) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      all_tags,
      tags_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title={
              active_step.type == "add_tags"
                ? "Add Tags(s)"
                : active_step.type == "remove_tags"
                ? "Remove Tags(s)"
                : "Tags"
            }
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {tags_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <SelectMultiple
                  options={this.props.all_tags}
                  selected_options={data.tags}
                  placeholder={
                    active_step.type == "add_tags"
                      ? "Select tags to add:"
                      : active_step.type == "remove_tags"
                      ? "Select tags to remove:"
                      : "Select tags:"
                  }
                  search_title="Search tags"
                  onSelectedOptionsChange={options => {
                    this.setState({
                      data: {
                        ...data,
                        tags: options
                      }
                    });
                  }}
                />
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
              disabled={!this.checkIfNeedsToSave()}
            >
              Apply
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ list, deal, property_tags }) => {
  const { all_tags } = deal;
  const { tags_loading } = property_tags;

  return {
    all_tags,
    tags_loading
  };
};

export default connect(mapStateToProps, { getTags })(tagstep);
