import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Wrapper,
  KeyboardView,
  Spin,
  Title,
  Bold,
  Copy,
  DraggableList,
  Icon,
  Button,
  Row
} from "app/NativeComponents/common";
import {
  NewHeader,
  PillButton,
  SpinWrapper,
  AttentionBox,
  GhostButton,
  SearchBar,
  InlineButton,
  SelectItem,
  ConfirmLabel,
  SmallLabel,
  InputBox,
  BottomNavBar,
  ConfirmInlineButton,
  UpsellButton
} from "app/NativeComponents/snippets";
import OnboardingText from "app/DealMachineCore/reuseable/OnboardingText";
import NewCustomField from "./NewCustomField";
import {
  updateCustomFields,
  popSidePanel,
  pushSidePanel,
  dismissMobileKeyboard
} from "app/NativeActions";

class CustomFieldGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group_title: props.active_field_group.title,
      custom_fields: props.custom_fields,
      active_field: null,
      search: ""
    };

    this.reorderFields = this.reorderFields.bind(this);
    this.setActiveField = this.setActiveField.bind(this);

    this.handleBack = this.handleBack.bind(this);
    this.updateGroupTitle = this.updateGroupTitle.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.custom_fields &&
      prevProps.custom_fields !== this.props.custom_fields
    ) {
      this.setState({
        custom_fields: this.props.custom_fields
      });
    }
  }

  handleBack() {
    dismissMobileKeyboard();
    this.props.setActiveFieldGroup(null);
  }

  checkIfNeedsToSave() {
    if (
      JSON.stringify(this.state.custom_fields) !==
      JSON.stringify(this.props.custom_fields)
    ) {
      return true;
    }
    return false;
  }
  checkIfNeedsToSaveTitle() {
    const { group_title } = this.state;
    if (!!group_title && group_title != this.props.active_field_group?.title) {
      return true;
    }

    return false;
  }
  reorderFields({ custom_field_group_id, previousIndex, nextIndex }) {
    const { custom_fields } = this.state;

    let new_fields = [];
    for (let i = 0; i < custom_fields.length; i++) {
      if (custom_fields[i].id == custom_field_group_id) {
        const reordered_fields = JSON.parse(
          JSON.stringify(custom_fields[i].fields)
        );

        const [removed] = reordered_fields.splice(previousIndex, 1);
        reordered_fields.splice(nextIndex, 0, removed);
        new_fields.push({
          ...custom_fields[i],
          fields: reordered_fields
        });
      } else {
        new_fields.push(custom_fields[i]);
      }
    }

    this.setState({
      custom_fields: new_fields
    });
  }

  setActiveField(active_field) {
    this.setState({
      active_field:
        this.state.active_field == active_field ? null : active_field
    });
  }

  filterOptions(options) {
    let filtered_options = [];
    const { search } = this.state;
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].title?.toLowerCase().indexOf(search?.toLowerCase()) != -1
      ) {
        filtered_options.push(options[i]);
      }
    }

    return filtered_options;
  }

  updateGroupTitle() {
    this.props.updateCustomFields({
      token: this.props.token,
      type: "update_custom_field_group",
      title: this.state.group_title,
      custom_field_group_id: this.props.active_field_group?.id,
      onLoading: () => {
        this.setState({
          title_loading: true
        });
      },
      onSuccess: results => {
        this.setState({
          title_loading: false
        });
      },
      onError: () => {
        this.setState({
          title_loading: false
        });
      }
    });
  }

  render() {
    const {
      custom_fields,
      active_field,
      new_field_group,
      new_field,
      group_title
    } = this.state;
    const { colors, device, plan_modules, user, active_field_group } =
      this.props;

    let fields = [];
    for (let i = 0; i < custom_fields.length; i++) {
      if (custom_fields[i].id == active_field_group.id) {
        fields = custom_fields[i].fields;
      }
    }

    if (this.state.active_field) {
      return (
        <NewCustomField
          setActiveField={this.setActiveField}
          active_field={this.state.active_field}
          custom_field_group_id={active_field_group.id}
          type={
            this.state.active_field == "new_field" ? "new_field" : "edit_field"
          }
        />
      );
    }

    return (
      <Container>
        <NewHeader
          title={"Edit Custom Field Group"}
          leftButton={{
            icon: "arrow-back",
            onPress: this.handleBack
          }}
        />

        <InputBox
          input_ref={this._group_title}
          name="group_title"
          disabled={this.state.title_loading}
          returnKeyType="done"
          placeholder={
            !!group_title ? "Group name" : "Enter a name for this group"
          }
          onFocus={() => {}}
          onChange={value => {
            this.setState({
              group_title: value
            });
          }}
          blurOnSubmit={true}
          value={group_title}
          input_type="text"
          require_confirm={true}
          has_changed={this.checkIfNeedsToSaveTitle()}
          submit_button_primary={true}
          submit_button_title={"Save Group Name"}
          onSubmit={this.updateGroupTitle}
          onSubmitEditing={this.updateGroupTitle}
          loading={this.state.title_loading}
        />

        <>
          {this.checkIfNeedsToSave() ? (
            <ConfirmLabel
              style={{
                alignSelf: "stretch"
              }}
              icon="check"
              label={"Order Changed"}
              primary={true}
              confirmButton={"Confirm Changes"}
              loading={this.state.loading}
              onPress={() => {
                this.props.updateCustomFields({
                  token: this.props.token,
                  type: "update_custom_fields",
                  custom_fields: this.state.custom_fields,
                  onLoading: () => {
                    this.setState({
                      loading: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      loading: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      loading: false
                    });
                  }
                });
              }}
            />
          ) : null}
          <Row>
            <Wrapper style={{ flex: 1 }}>
              <SearchBar
                title={"Search Fields"}
                onChange={value => {
                  this.setState({
                    search: value
                  });
                }}
                value={this.state.search}
                style={{
                  margin: 10,
                  marginRight: 0,
                  borderWidth: 0,
                  borderBottomWidth: 0
                }}
              />
            </Wrapper>

            {this.props.user?.user_version < 6 ? (
              <InlineButton
                icon="add"
                primary={true}
                onPress={() => {
                  this.setActiveField("new_field");
                }}
              >
                New Field
              </InlineButton>
            ) : (
              <UpsellButton
                meta_slug="custom_fields_limit"
                onPress={() => {
                  this.setActiveField("new_field");
                }}
                renderLockedChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      icon="lock"
                      primary={true}
                      style={{ marginRight: 0 }}
                      pressedIn={hovering}
                      noPress={true}
                      tooltipPlacement="top"
                    >
                      New Field
                    </InlineButton>
                  );
                }}
                renderChildren={({ hovering }) => {
                  return (
                    <InlineButton
                      icon="add"
                      primary={true}
                      style={{ marginRight: 0 }}
                      pressedIn={hovering}
                      noPress={true}
                      tooltipPlacement="top"
                    >
                      New Field
                    </InlineButton>
                  );
                }}
              />
            )}
          </Row>
        </>
        <KeyboardView style={{ flex: 1 }}>
          {this.props.custom_fields_loading ? (
            <SpinWrapper text={"Loading Custom Fields..."} />
          ) : active_field_group?.fields?.length == 0 ? (
            <AttentionBox
              title="There are no fields in this group"
              description={"You haven't added any custom fields to this group."}
            />
          ) : (
            <>
              <SmallLabel
                style={{ padding: 25, paddingTop: 10, paddingBottom: 10 }}
              >
                Fields in group
              </SmallLabel>
              <>
                <DraggableList
                  items={this.filterOptions(fields)}
                  renderHandle={() => {
                    return (
                      <Wrapper
                        className="dm-drag"
                        style={{
                          padding: 15,
                          paddingLeft: 0,
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <Icon icon="drag-indicator" size={18} />
                      </Wrapper>
                    );
                  }}
                  disabled={!!this.state.search}
                  renderItem={({ item, wrappedHandle }) => {
                    return (
                      <SelectItem
                        select_type="none"
                        onPress={() => {
                          this.setActiveField(item);
                        }}
                        renderRight={
                          wrappedHandle && !this.state.search
                            ? () => {
                                return wrappedHandle();
                              }
                            : null
                        }
                        icon="keyboard-arrow-right"
                      >
                        {item.title}
                      </SelectItem>
                    );
                  }}
                  onReorder={({ previousIndex, nextIndex }) => {
                    this.reorderFields({
                      custom_field_group_id: active_field_group.id,
                      previousIndex,
                      nextIndex
                    });
                  }}
                />
              </>
            </>
          )}
        </KeyboardView>
        {active_field_group.fields.length == 0 ? (
          <BottomNavBar>
            <ConfirmInlineButton
              confirm_text="Really Delete?"
              confirm_icon="delete"
              loading_text="Deleting"
              loading={this.state.deleting}
              button_type="full"
              onPress={() => {
                this.props.updateCustomFields({
                  token: this.props.token,
                  type: "remove_custom_field_group",
                  custom_field_group_id: active_field_group.id,
                  onLoading: () => {
                    this.setState({
                      deleting: true
                    });
                  },
                  onError: () => {
                    this.setState({
                      deleting: false
                    });
                  },
                  onSuccess: () => {
                    this.setState({
                      deleting: false
                    });
                    this.handleBack();
                  }
                });
              }}
            >
              Delete Group
            </ConfirmInlineButton>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth,
  native,
  settings,
  customsettings,
  billing
}) => {
  const { token, user } = auth;
  const { platform, device, isMobile } = native;
  const { colors } = settings;
  const { custom_fields, custom_fields_loading } = customsettings;
  const { plan_modules } = billing;

  return {
    token,
    user,
    platform,
    colors,
    device,
    isMobile,
    custom_fields,
    custom_fields_loading,
    plan_modules
  };
};

export default connect(mapStateToProps, {
  updateCustomFields,
  popSidePanel,
  pushSidePanel
})(CustomFieldGroup);
