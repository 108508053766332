import React, { Component } from "react";

import StatNumber from "./StatNumber";
import StatTitle from "./StatTitle";
import StatDescription from "./StatDescription";

class Stat extends Component {
  render() {
    if (this.props.stat) {
      return (
        <div className="dm-website-stat">
          <StatNumber stat={this.props.stat} />
          <StatTitle stat={this.props.stat} />
          <StatDescription stat={this.props.stat} />
        </div>
      );
    }

    return <div />;
  }
}

export { Stat };
