import React, { Component } from "react";
import {
  Scroll,
  CardBody,
  Wrapper,
  Copy,
  Bold
} from "app/NativeComponents/common";
import { AttentionBox } from "app/NativeComponents/snippets";

import BillingCard from "./BillingCard";
import BillingAddress from "./BillingAddress";
class Body extends Component {
  render() {
    if (this.props.onboarding && this.props.card_info.bad_card != 1) {
      return (
        <>
          <AttentionBox
            title="Update your card on file"
            description={
              "Complete the following to add a card to your DealMachine account."
            }
          />
          <BillingCard {...this.props} buttonText="Add Card" />
        </>
      );
    }
    return (
      <>
        <AttentionBox
          title="Update your card on file"
          description={
            "Complete the following to update the card associated with your DealMachine account."
          }
        />
        <BillingCard {...this.props} />
      </>
    );
  }
}

export default Body;
