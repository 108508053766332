import React from "react";
import { Wrapper, Row, Copy, Icon } from "app/NativeComponents/common";
import {
  InlineButton,
  GhostButton,
  SpinWrapper,
  SmallLabel
} from "app/NativeComponents/snippets";

import { store } from "app/store";

const ConfirmLabel = ({
  style,
  innerStyle,
  label,
  description,
  renderLabel,
  icon,
  primary = false,
  noPrimaryButton = false,
  fa_icon,
  confirmButton,
  confirmButtonIcon,
  onPress = () => {},
  secondaryButton,
  secondaryButtonIcon,
  onSecondaryPress = () => {},
  loading = false,
  loading_text,
  small_label = "",
  renderRight = null,
  renderLeft = null
}) => {
  const colors = store.getState().settings.colors;

  const text_color = primary
    ? colors.orange_color
    : colors.actionable_text_color;
  const background_color = primary
    ? colors.orange_color_muted
    : colors.active_color_muted;
  const isMobile = store.getState().native.isMobile;

  return (
    <Row
      style={{
        borderRadius: 5,
        margin: 5,
        backgroundColor: background_color,
        ...style
      }}
    >
      {renderLeft ? renderLeft() : null}

      <Wrapper
        style={{
          flex: 1,
          padding: 25,
          ...innerStyle
        }}
      >
        {!!small_label ? (
          <SmallLabel style={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}>
            {small_label}
          </SmallLabel>
        ) : null}
        <Row>
          {!!icon || !!fa_icon ? (
            <Icon
              size={18}
              color={text_color}
              icon={icon}
              fa_icon={fa_icon}
              style={{ marginRight: 5 }}
            />
          ) : null}
          {renderLabel ? (
            renderLabel()
          ) : (
            <Wrapper style={{ flex: 1 }}>
              <Copy style={{ color: text_color }}>{label}</Copy>
              {!!description ? (
                <Copy style={{ color: text_color, fontSize: 10 }}>
                  {description}
                </Copy>
              ) : null}
            </Wrapper>
          )}
        </Row>
      </Wrapper>
      {!!secondaryButton && loading ? (
        <SpinWrapper
          size="small"
          text={loading_text}
          color={colors.orange_color}
        />
      ) : (
        <>
          {!!secondaryButton ? (
            <>
              <InlineButton
                icon={secondaryButtonIcon}
                style={{ marginRight: 0, marginLeft: 0 }}
                onPress={onSecondaryPress}
              >
                {secondaryButton}
              </InlineButton>
            </>
          ) : null}

          {!!confirmButton ? (
            <>
              {primary && !noPrimaryButton ? (
                <GhostButton
                  icon={confirmButtonIcon}
                  primary={true}
                  style={{ marginLeft: 0 }}
                  onPress={onPress}
                  loading={loading}
                >
                  {confirmButton}
                </GhostButton>
              ) : (
                <InlineButton
                  loading={loading}
                  icon={confirmButtonIcon}
                  style={{ marginLeft: 0 }}
                  onPress={onPress}
                >
                  {confirmButton}
                </InlineButton>
              )}
            </>
          ) : null}
        </>
      )}
      {renderRight ? renderRight() : null}
    </Row>
  );
};

export { ConfirmLabel };
