import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Input,
  MultiLineInput,
  KeyboardView
} from "app/NativeComponents/common";
import {
  PillButton,
  Select,
  Tabs,
  SelectMultiple,
  SmallHeader,
  SpinWrapper,
  GhostButton,
  SelectBox,
  InputBox,
  SelectItem,
  SmallLabel,
  BottomNavBar,
  DateBox,
  InlineTabs
} from "app/NativeComponents/snippets";
import BracketPicker from "./BracketPicker";
import { getTeamMembers } from "app/NativeActions";
import moment from "moment-timezone";

class TaskStep extends Component {
  constructor(props) {
    super(props);

    let times = [];
    let currentDate = moment("12");
    new Array(96).fill().map((acc, index) => {
      times.push({
        value: currentDate.format("h:mm A"),
        label: currentDate.format("h:mm A")
      });
      currentDate = currentDate.add(15, "minutes");

      return index;
    });

    let timezones = [];
    let moment_tz = moment.tz.names();
    for (let i = 0; i < moment_tz.length; i++) {
      timezones.push({
        value: moment_tz[i],
        label: moment_tz[i]
      });
    }

    this.state = {
      times,
      timezones,
      data: props.active_step?.data
        ? props.active_step.data
        : {
            title: "",
            notes: "",
            due_date: {
              type: "immediately",
              days: 0,
              time: moment("08:00 AM", "h:mm A").format("h:mm A"),
              timezone: moment.tz.guess()
            },
            assign_to_user: 0,
            assign_to_user_name: "",
            assign_to_users: [],
            associated_with_lead: true,
            assign_type: "existing_owner",
            notify_team_member: false
          }
    };
  }

  componentDidMount() {
    this.props.getTeamMembers({
      token: this.props.token,
      load_type: "replace",
      type: "accepted_members_and_dealfinders",
      begin: 0
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (
        !!this.state.data?.title &&
        (this.state.data.assign_type !== "specific_user" ||
          this.state.data.assign_to_user > 0)
      ) {
        return true;
      }
    }

    return false;
  }

  renderUserName(user_id) {
    const { team_members } = this.props;
    for (let i = 0; i < team_members.length; i++) {
      if (team_members[i].value == user_id) {
        return team_members[i].label;
      }
    }

    return "None selected";
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      workflow,
      enrollment_object,
      enrollment_object_loading,
      team_members,
      team_members_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title="Create and Assign Task"
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />

          <KeyboardView style={{ flex: 1 }}>
            {enrollment_object_loading || team_members_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                <SelectItem
                  selected={data.associated_with_lead}
                  onPress={() => {
                    this.setState({
                      data: {
                        ...data,
                        associated_with_lead: !data.associated_with_lead,
                        assign_type: data.associated_with_lead
                          ? "specific_user"
                          : data.assign_type
                      }
                    });
                  }}
                >
                  Associate task with lead
                </SelectItem>

                <SmallLabel>Due date:</SmallLabel>
                <InputBox
                  ref="title"
                  style={{ flex: 1 }}
                  autoFocus={false}
                  name="title"
                  returnKeyType="next"
                  placeholder={"Task title"}
                  onChange={value => {
                    this.setState({
                      data: {
                        ...data,
                        title: value
                      }
                    });
                  }}
                  onSubmitEditing={() => {}}
                  blurOnSubmit={false}
                  value={data.title}
                  type="text"
                  renderRight={() => {
                    return (
                      <BracketPicker
                        onSelect={bracket => {
                          this.setState({
                            data: {
                              ...data,
                              title: data.title + "{{" + bracket.key + "}}"
                            }
                          });
                        }}
                        enrollment_object={enrollment_object}
                        enrollment_object_loading={enrollment_object_loading}
                      />
                    );
                  }}
                />

                <SelectItem
                  selected={data.due_date.type === "immediately"}
                  onPress={() => {
                    this.setState({
                      data: {
                        ...data,
                        due_date:
                          data.due_date.type == "immediately"
                            ? {
                                ...data.due_date,
                                type: "at_set_date"
                              }
                            : {
                                ...data.due_date,
                                type: "immediately"
                              }
                      }
                    });
                  }}
                >
                  Assign task immediately
                </SelectItem>

                {data.due_date.type !== "immediately" ? (
                  <>
                    <InputBox
                      ref="days"
                      style={{ flex: 1 }}
                      autoFocus={false}
                      name="days"
                      returnKeyType="next"
                      placeholder="Days from task creation"
                      onChange={value => {
                        this.setState({
                          data: {
                            ...data,
                            due_date: {
                              ...data.due_date,
                              days: value
                            }
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                      blurOnSubmit={false}
                      value={data?.due_date?.days?.toString()}
                      type="number"
                    />

                    <SelectBox
                      style={{ flex: 1 }}
                      items={this.state.times}
                      value={data.due_date.time}
                      placeholder="Select time of the day"
                      onFocus={() => {}}
                      onBlur={() => {}}
                      onSelect={value => {
                        this.setState({
                          data: {
                            ...data,
                            due_date: {
                              ...data.due_date,
                              time: value
                            }
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                    />
                    <SelectBox
                      style={{ flex: 1 }}
                      items={this.state.timezones}
                      value={data.due_date.timezone}
                      placeholder="Select timezone"
                      onFocus={() => {}}
                      onBlur={() => {}}
                      onSelect={value => {
                        this.setState({
                          data: {
                            ...data,
                            due_date: {
                              ...data.due_date,
                              timezone: value
                            }
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                    />
                  </>
                ) : null}

                <SmallLabel>Due date:</SmallLabel>
                {data.associated_with_lead ? (
                  <>
                    <SelectItem
                      selected={data.assign_type === "existing_owner"}
                      onPress={() => {
                        this.setState({
                          data: {
                            ...data,
                            assign_type:
                              data.assign_type == "existing_owner"
                                ? "specific_user"
                                : "existing_owner"
                          }
                        });
                      }}
                    >
                      Assign to existing lead owner
                    </SelectItem>

                    {!data.associated_with_lead ? (
                      <>
                        <InlineTabs
                          tab_type="pill"
                          selected_tab={data.assign_type}
                          tabs={[
                            {
                              slug: "specific_user",
                              title: "Select A Team Member",
                              onPress: () => {
                                this.setState({
                                  data: {
                                    ...data,
                                    assign_type: "specific_user"
                                  }
                                });
                              }
                            },

                            {
                              slug: "round_robin",
                              title: "Round Robin",
                              onPress: () => {
                                this.setState({
                                  data: {
                                    ...data,
                                    assign_type: "round_robin"
                                  }
                                });
                              }
                            }
                          ]}
                        />

                        {data.assign_to_type == "specific_user" ? (
                          <SelectBox
                            items={team_members}
                            value={data.assign_to_user}
                            placeholder="Select team member"
                            show={this.state.assign_to_user_focus}
                            onFocus={() => {
                              this.setState({ assign_to_user_focus: true });
                            }}
                            onBlur={() => {
                              this.setState({ assign_to_user_focus: false });
                            }}
                            onSelect={value => {
                              this.setState({
                                data: {
                                  ...data,
                                  assign_to_user: value,
                                  assign_to_user_name:
                                    this.renderUserName(value)
                                }
                              });
                            }}
                            onSubmitEditing={() => {}}
                          />
                        ) : (
                          <SelectMultiple
                            options={team_members}
                            selected_options={data.assign_to_users}
                            placeholder={"Select team members"}
                            search_title="Search team members"
                            icon_right={"keyboard-arrow-down"}
                            onSelectedOptionsChange={options => {
                              this.setState({
                                data: {
                                  ...data,
                                  assign_to_users: options
                                }
                              });
                            }}
                          />
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}

                <SelectItem
                  selected={data.notify_team_member}
                  onPress={() => {
                    this.setState({
                      data: {
                        ...data,
                        notify_team_member: !data.notify_team_member
                      }
                    });
                  }}
                >
                  Send in-app notification to assigned team member
                </SelectItem>

                <Wrapper
                  style={{
                    position: "relative"
                  }}
                >
                  <InputBox
                    name="notes"
                    returnKeyType="default"
                    placeholder="Notes"
                    onChange={value => {
                      this.setState({
                        data: {
                          ...data,
                          notes: value
                        }
                      });
                    }}
                    onFocus={() => {}}
                    selected_background_color={"transparent"}
                    hover_background_color={"transparent"}
                    blurOnSubmit={false}
                    value={data.notes}
                    input_type="text"
                    type={"multiline"}
                    numberOfLines={5}
                    autogrow={true}
                  />
                  <Wrapper
                    style={{
                      alignSelf: "stretch",
                      position: "absolute",
                      top: 0,
                      right: 0
                    }}
                  >
                    <BracketPicker
                      onSelect={bracket => {
                        this.setState({
                          data: {
                            ...data,
                            notes: data.notes + "{{" + bracket.key + "}}"
                          }
                        });
                      }}
                      enrollment_object={enrollment_object}
                      enrollment_object_loading={enrollment_object_loading}
                    />
                  </Wrapper>
                </Wrapper>
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
              disabled={!this.checkIfNeedsToSave()}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ workflow, team }) => {
  const { enrollment_object, enrollment_object_loading } = workflow;
  const { team_members, team_members_loading } = team;

  return {
    team_members,
    team_members_loading,
    enrollment_object,
    enrollment_object_loading
  };
};

export default connect(mapStateToProps, { getTeamMembers })(TaskStep);
