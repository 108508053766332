import React, { PureComponent } from "react";
import {
  Wrapper,
  Row,
  Copy,
  Button,
  Card,
  Gradient,
  Bold,
  Icon
} from "app/NativeComponents/common";

import ItemType from "../ItemType";
class Items extends PureComponent {
  renderButtons({ item }) {
    const {
      primary_button,
      primary_button_right_icon,
      secondary_button,
      secondary_button_right_icon,
      onPrimaryButtonPress,
      onSecondaryButtonPress,
      colors
    } = this.props;
    if (!!secondary_button || !!primary_button) {
      return (
        <Wrapper className="responsive-list-main-column-button">
          <Row
            style={{
              alignSelf: "stretch",
              flex: 1,
              justifyContent: "flex-end"
            }}
          >
            {!!secondary_button ? (
              <Button
                onPress={() => onSecondaryButtonPress({ item })}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Row
                  style={{
                    padding: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 5,
                    backgroundColor: colors.card_color,
                    borderWidth: 1,
                    borderColor: colors.border_color,
                    borderStyle: "solid",
                    marginLeft: 5
                  }}
                >
                  <Copy>{secondary_button}</Copy>
                  {secondary_button_right_icon ? (
                    <Icon
                      size={14}
                      icon={secondary_button_right_icon}
                      style={{ marginLeft: 5 }}
                    />
                  ) : null}
                </Row>
              </Button>
            ) : null}

            {!!primary_button ? (
              <Button
                onPress={() => onPrimaryButtonPress({ item })}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Gradient
                  style={{
                    padding: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 5,
                    marginLeft: 5,
                    borderWidth: 1,
                    borderColor: colors.white_color,
                    borderStyle: "solid"
                  }}
                  color1={colors.gradient_color_1}
                  color2={colors.gradient_color_2}
                >
                  <Row>
                    <Copy style={{ color: colors.white_text_color }}>
                      {primary_button}
                    </Copy>
                    {primary_button_right_icon ? (
                      <Icon
                        size={14}
                        color={colors.white_text_color}
                        icon={primary_button_right_icon}
                        style={{ marginLeft: 5 }}
                      />
                    ) : null}
                  </Row>
                </Gradient>
              </Button>
            ) : null}
          </Row>
        </Wrapper>
      );
    }
  }

  checkIfSelected({ item }) {
    const { selected_items, selected_all } = this.props;
    if (selected_all) {
      return true;
    }

    for (let i = 0; i < selected_items.length; i++) {
      if (selected_items[i].id == item.id) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {
      headers,
      items,
      active_item_id,
      colors,
      include_checkboxes,
      onCheckBoxPress,
      onItemPress,
      renderTooltip,
      selected_all
    } = this.props;
    return (
      <Wrapper>
        {items &&
          items.map((item, i) => {
            return (
              <Row
                key={"item_" + i}
                className="responsive-list-main-column"
                style={{
                  backgroundColor:
                    active_item_id == item.id
                      ? colors.active_color_muted
                      : "transparent"
                }}
              >
                {include_checkboxes ? (
                  <Button
                    onPress={() => onCheckBoxPress({ item })}
                    style={{ padding: 20 }}
                  >
                    <Icon
                      icon={
                        this.checkIfSelected({ item })
                          ? "check-box"
                          : "check-box-outline-blank"
                      }
                      color={
                        selected_all
                          ? colors.light_text_color
                          : colors.text_color
                      }
                    />
                  </Button>
                ) : null}
                <Button
                  onPress={() => onItemPress({ item })}
                  style={{ flex: 1 }}
                >
                  <Row>
                    {headers &&
                      headers.map((header, j) => {
                        return (
                          <Wrapper
                            key={"item_section_" + j}
                            style={{
                              padding: 20,
                              flex: header.main_column ? 1 : "inherit",
                              width: !header.main_column ? 120 : "auto",
                              minWidth: header.main_column ? 200 : 120,

                              position: "relative"
                            }}
                          >
                            <Row>
                              <ItemType item={item} header={header} />

                              {renderTooltip && header.main_column
                                ? renderTooltip(item)
                                : null}
                              {header.flag_slug && item[header.flag_slug] ? (
                                <Row>
                                  <Wrapper
                                    style={{
                                      marginLeft: 5,
                                      padding: 5,
                                      paddingRight: 10,
                                      paddingLeft: 10,
                                      borderRadius: 20,
                                      backgroundColor: colors.active_color_muted
                                    }}
                                  >
                                    <Copy
                                      style={{
                                        textAlign: "center",
                                        fontSize: 12
                                      }}
                                    >
                                      {header.flag_title}
                                    </Copy>
                                  </Wrapper>
                                </Row>
                              ) : null}
                            </Row>

                            {header.main_column
                              ? this.renderButtons({ item })
                              : null}
                          </Wrapper>
                        );
                      })}
                  </Row>
                </Button>
              </Row>
            );
          })}
      </Wrapper>
    );
  }
}

export default Items;
