import React from "react";

const Animation = ({
  id,
  children,
  style,
  type,
  onPress,
  pointerEvents = "auto",
  delay = 0,
  className,
  ...props
}) => {
  let animation_delay = "";

  switch (delay >= 1000 ? parseFloat(delay / 1000) : parseInt(delay)) {
    default:
      break;
    case 0.25:
      animation_delay = " animate_delay_025s ";
      break;
    case 0.5:
      animation_delay = " animate_delay_05s ";
      break;

    case 1:
    case 1.0:
      animation_delay = " animate_delay_1s ";
      break;
    case 2:
    case 2.0:
      animation_delay = " animate_delay_2s ";
      break;
    case 3:
    case 3.0:
      animation_delay = " animate_delay_3s ";
      break;
    case 4:
    case 4.0:
      animation_delay = " animate_delay_4s ";
      break;
    case 5:
    case 5.0:
      animation_delay = " animate_delay_5s ";
      break;
  }

  return (
    <div
      id={id}
      pointerEvents={pointerEvents}
      className={
        "deal-animation animated " +
        type +
        " " +
        animation_delay +
        " " +
        className
      }
      style={style}
      {...props}
    >
      {children}
    </div>
  );
};

export { Animation };
