import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "app/NativeComponents/common";
import Library from "./Library";

class ListLibrary extends Component {
  render() {
    const { show_list_library, device, colors } = this.props;
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <Library />
      </Container>
    );
  }
}

const mapStateToProps = ({ settings, native, list }) => {
  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { show_list_library } = list;

  return {
    colors,
    device,
    isMobile,
    platform,
    show_list_library
  };
};

export default connect(mapStateToProps, {})(ListLibrary);
