import React from "react";
import { Row, Copy, Wrapper, Icon, Spin } from "app/NativeComponents/common";
import { Notification } from "app/NativeComponents/snippets";

import ButtonWrapper from "./ButtonWrapper";
import { store } from "app/store";

const IconButton = props => {
  const {
    children,
    primary,
    style,
    innerStyle,
    disabled = false,
    deleteButton = false,
    icon,
    fa_icon,
    icon_right,
    fa_icon_right,
    pressedIn = false, //pressed in override from props
    loading = false,
    button_type = "normal",
    icon_color,
    size = 18,
    backgroundColor = null,

    show_notification = false,
    notification_icon,
    notification_fa_icon,
    notification_number = 0,
    notification_color,
    notification_size = 20,
    notification_font_size = 14,
    notification_offset = 2,
    notification_offset_x = notification_offset,
    notification_offset_y = notification_offset,
    hover_color = null
  } = props;
  const colors = store.getState().settings.colors;
  let icon_size = size;
  return (
    <ButtonWrapper
      {...props}
      renderInner={({
        hovering /*actual pressed in from state of button wrapper*/
      }) => {
        const text_color = icon_color
          ? icon_color
          : deleteButton
          ? colors.error_color
          : primary
          ? colors.orange_color
          : colors.text_color;

        const background_color = !!backgroundColor
          ? backgroundColor
          : pressedIn || hovering
          ? hover_color
            ? hover_color
            : colors.hover_color
          : "transparent";

        let s = {
          width: 45,
          height: 45,
          borderRadius: 23,
          margin: 5,
          backgroundColor: background_color,
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          opacity: disabled ? 0.5 : 1
        };
        switch (button_type) {
          default:
            break;
          case "small":
            s = {
              ...s,
              width: 35,
              height: 35
            };
            icon_size = 14;
            break;
        }

        return (
          <Wrapper style={{ ...s, ...style }}>
            {!!icon || !!fa_icon ? (
              <>
                {loading ? (
                  <Spin
                    size="small"
                    color={text_color}
                    style={{ fontSize: icon_size }}
                  />
                ) : (
                  <Icon
                    size={icon_size}
                    color={text_color}
                    icon={icon}
                    fa_icon={fa_icon}
                  />
                )}
              </>
            ) : null}

            {show_notification ? (
              <Notification
                notification_offset={notification_offset}
                notification_offset_x={notification_offset_x}
                notification_offset_y={notification_offset_y}
                notification_size={notification_size}
                notification_font_size={notification_font_size}
                notification_icon={notification_icon}
                notification_fa_icon={notification_fa_icon}
                notification_number={notification_number}
                notification_color={notification_color}
              />
            ) : null}
          </Wrapper>
        );
      }}
    />
  );
};

export { IconButton };
