import React, { PureComponent } from "react";
import { Wrapper } from "app/NativeComponents/common";
import { InlineButton } from "app/NativeComponents/snippets";

import AssignLeadButton from "app/DealMachineCore/reuseable/AssignLeadButton";

class AssignLeadButtonWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      temp_value: null
    };
  }
  render() {
    const { property, colors, section, row_height } = this.props;
    const { loading, temp_value } = this.state;
    return (
      <AssignLeadButton
        popoverPlacement="right"
        onLoading={({ loading, temp_value }) => {
          this.setState({
            loading,
            temp_value
          });
        }}
        renderComponent={({ pressedIn, hovering }) => {
          return (
            <Wrapper
              style={{
                position: "relative",
                width: section.width
              }}
            >
              <Wrapper
                style={{
                  overflow: "hidden",
                  width: section.width,

                  whiteSpace: "nowrap"
                }}
              >
                <Wrapper
                  style={{
                    padding: 10,
                    paddingLeft: 10,
                    height: row_height,
                    justifyContent: "center"
                  }}
                >
                  <InlineButton
                    pressedIn={pressedIn || hovering}
                    loading={loading}
                    noPress={true}
                    style={{ margin: 0 }}
                    icon_right="keyboard-arrow-down"
                  >
                    {loading && temp_value && temp_value.id != 0
                      ? temp_value.name
                      : loading && temp_value && temp_value.id == 0
                      ? "Not Assigned"
                      : property.deal.assigned_user &&
                        property.deal.assigned_user.name
                      ? property.deal.assigned_user.name
                      : property.deal.assigned_user &&
                        property.deal.assigned_user.email
                      ? property.deal.assigned_user.email
                      : "Not Assigned"}
                  </InlineButton>
                </Wrapper>
              </Wrapper>
            </Wrapper>
          );
        }}
        property={property}
      />
    );
  }
}

export default AssignLeadButtonWrapper;
