import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Wrapper,
  Copy,
  Bold,
  Spin,
  Button,
  Row,
  Icon,
  Scroll,
  Card,
  Input,
  MultiLineInput,
  KeyboardView
} from "app/NativeComponents/common";
import {
  GhostButton,
  BottomNavBar,
  SmallHeader,
  SpinWrapper,
  SelectItem,
  Select,
  SelectBox
} from "app/NativeComponents/snippets";
import { getMailerCampaigns } from "app/NativeActions";

class CampaignStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_team: false,
      data: props.active_step?.data
        ? props.active_step.data
        : props.active_step.type == "start_campaign"
        ? {
            mailer_campaign_id: 0,
            mailer_campaign_title: "",
            end_previous_campaign: true,
            resume_previous_campaign: true
          }
        : props.active_step.type == "pause_campaign"
        ? {
            pause_campaign: true
          }
        : props.active_step.type == "end_campaign"
        ? {
            end_campaign: true
          }
        : {}
    };
  }

  componentDidMount() {
    this.props.getMailerCampaigns({
      token: this.props.token,
      load_type: "load_all"
    });
  }

  checkIfNeedsToSave() {
    if (this.state.data !== this.props.active_step?.data) {
      if (this.props.active_step.type == "start_campaign") {
        if (this.state.data?.mailer_campaign_id != 0) {
          return true;
        }
      }
    }

    return false;
  }

  renderCampaignTitle(campaign_id) {
    const { mailer_campaigns } = this.props;
    for (let i = 0; i < mailer_campaigns.length; i++) {
      if (mailer_campaigns[i].value == campaign_id) {
        if (mailer_campaigns[i].is_default_campaign) {
          return mailer_campaigns[i].label + "(Default)";
        }
        return mailer_campaigns[i].label;
      }
    }

    return "None selected";
  }

  render() {
    const {
      colors,
      device,
      isMobile,
      active_step,
      mailer_campaigns,
      mailer_campaigns_loading
    } = this.props;
    const { data } = this.state;

    if (active_step) {
      return (
        <>
          <SmallHeader
            title={
              active_step.type == "start_campaign"
                ? "Start or Resume Mail for Lead"
                : active_step.type == "pause_campaign"
                ? "Pause Mail for Lead"
                : active_step.type == "end_campaign"
                ? "End Mail for Lead"
                : "Mail"
            }
            leftButton={{
              icon: "close",
              onPress: () => {
                this.props.setActiveStep({ step: null });
              }
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            {mailer_campaigns_loading ? (
              <SpinWrapper text="Loading options..." />
            ) : (
              <>
                {active_step.type == "start_campaign" ? (
                  <>
                    <SelectBox
                      style={{ flex: 1 }}
                      items={mailer_campaigns}
                      value={data.mailer_campaign_id}
                      placeholder="Select a mail campaign"
                      onFocus={() => {}}
                      onBlur={() => {}}
                      onSelect={value => {
                        this.setState({
                          data: {
                            ...data,
                            mailer_campaign_id: value,
                            mailer_campaign_title:
                              this.renderCampaignTitle(value)
                          }
                        });
                      }}
                      onSubmitEditing={() => {}}
                    />

                    <SelectItem
                      selected={data.end_previous_campaign}
                      onPress={() => {
                        this.setState({
                          data: {
                            ...data,
                            end_previous_campaign: !data.end_previous_campaign
                          }
                        });
                      }}
                      description={
                        "* This will end the current mail sequence for the lead and immediatly start the selected mail sequence."
                      }
                    >
                      End current mail sequence if different from selected mail
                      sequence
                    </SelectItem>

                    <SelectItem
                      selected={data.resume_previous_campaign}
                      onPress={() => {
                        this.setState({
                          data: {
                            ...data,
                            resume_previous_campaign:
                              !data.resume_previous_campaign
                          }
                        });
                      }}
                      description={
                        !data.end_previous_campaign
                          ? "* This will resume the mail sequence for the lead if the mail sequence is paused and matches the selected mail sequence."
                          : "* This will resume the mail sequence for the lead if the mail sequence is paused."
                      }
                    >
                      Resume current mail sequence if paused
                    </SelectItem>
                  </>
                ) : active_step.type == "pause_campaign" ? (
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>
                      This step will pause the current mail sequence if one is
                      in progress.
                    </Copy>
                  </Wrapper>
                ) : active_step.type == "end_campaign" ? (
                  <Wrapper style={{ padding: 25 }}>
                    <Copy>
                      This step will end the current mail sequence if one is in
                      progress.
                    </Copy>
                  </Wrapper>
                ) : null}
              </>
            )}
          </KeyboardView>
          <BottomNavBar>
            <GhostButton
              button_type={"full"}
              primary={true}
              disabled={!this.checkIfNeedsToSave()}
              onPress={() => {
                this.props.saveStep({
                  step_index: active_step.step_index,
                  branch_index: active_step.branch_index,
                  branch_step_index: active_step.branch_step_index,
                  step: {
                    ...active_step,
                    data: data
                  }
                });
              }}
            >
              Apply Changes
            </GhostButton>
          </BottomNavBar>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ mailer }) => {
  const { mailer_campaigns, mailer_campaigns_loading } = mailer;

  return {
    mailer_campaigns,
    mailer_campaigns_loading
  };
};

export default connect(mapStateToProps, { getMailerCampaigns })(CampaignStep);
