import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateOwnerInfo,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class AddPhoneNumber extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      phone_number: "",
      phone_label: "",
      loading: false,
      panel_data
    };

    this._phone_number_input = React.createRef();
    this._phone_number_label = React.createRef();

    this.addPhoneNumber = this.addPhoneNumber.bind(this);
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    if (!!this.state.phone_number) {
      return true;
    }

    return false;
  }

  addPhoneNumber() {
    const {
      panel_data: { property }
    } = this.state;

    const { phone_label, phone_number } = this.state;
    this.props.updateOwnerInfo({
      token: this.props.token,
      deal_id: property?.deal?.id,
      type: "create_phone_number",
      phone_label,
      phone_number,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: results => {
        if (
          this.state.panel_data?.propertyUpdated &&
          results?.properties?.length == 1
        ) {
          this.state.panel_data.propertyUpdated(results.properties[0]);
        }
        this.setState({
          loading: false
        });
        this.handleBack();
      }
    });
  }

  render() {
    const {
      panel_data: { property }
    } = this.state;
    const { phone_number, phone_label, loading } = this.state;

    let formatted_property_address = "";
    if (property) {
      const display_property = determineDisplayProperty(property);
      formatted_property_address = formatAddress({
        address: {
          address: display_property.property_address,
          address2: display_property.property_address2,
          address_city: display_property.property_address_city,
          address_state: display_property.property_address_state,
          address_zip: display_property.property_address_zip,
          latitude: property?.location?.latitude,
          longitude: property?.location?.longitude
        }
      });
    }
    return (
      <Container>
        <NewHeader
          title="Add Phone Number"
          subtitle={"Add phone number for " + formatted_property_address.line1}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <InputBox
            input_ref={this._phone_number_label}
            name="phone_number_label"
            autoFocus={true}
            returnKeyType="next"
            blurOnSubmit={false}
            autoCapitalize="words"
            placeholder="Label"
            disabled={loading}
            onChange={value => {
              this.setState({
                phone_label: value
              });
            }}
            value={phone_label}
            type="text"
            onSubmitEditing={() => {
              if (this._phone_number_input?.current) {
                if (this.props.device == "desktop") {
                  this._phone_number_input.current.getInputDOMNode().focus();
                } else {
                  this._phone_number_input.current.focus();
                }
              }
            }}
          />
          <InputBox
            input_ref={this._phone_number_input}
            name="phone_number"
            disabled={loading}
            returnKeyType="done"
            placeholder="Phone Number"
            onChange={value =>
              this.setState({
                phone_number: value
              })
            }
            onSubmitEditing={this.addPhoneNumber}
            blurOnSubmit={true}
            value={phone_number}
            type="text"
            mask_type={"cel-phone"}
            mask={"(999) 999-9999"}
          />

          <GhostButton
            button_type="full"
            primary={true}
            onPress={this.addPhoneNumber}
            disabled={!this.checkIfNeedsToSave()}
            loading={loading}
          >
            Add Phone Number
          </GhostButton>
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateOwnerInfo
})(AddPhoneNumber);
