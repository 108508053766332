import React, { Component } from "react";

import { SelectItem } from "app/NativeComponents/snippets";

import RefundedText from "./RefundedText";

import { renderDate, renderPrice } from "app/NativeActions";

class Invoice extends Component {
  renderDescription(description) {
    if (description && description != "") {
      return description;
    }
    return "";
  }

  renderRefunded() {
    if (this.props.invoice.refunded == 1) {
      var refunded_date_created = renderDate(this.props.invoice.refunded_date);
      var refunded_price = renderPrice(this.props.invoice.refunded_amount);
      var total_price = renderPrice(this.props.invoice.total_cents_paid);

      if (refunded_price == total_price) {
        return " - Refunded on " + refunded_date_created;
      }

      return (
        " - Refunded " +
        refunded_price +
        " of " +
        total_price +
        " on " +
        refunded_date_created
      );
    }
    return "";
  }

  render() {
    const INVOICE_TITLES = {
      crm: "DealMachine CRM",
      driving: "DealMachine Driving For Dollars Plan",
      lists: "DealMachine List Builder Plan"
    };

    var deal_credits_text =
      this.props.invoice.total_cents_paid &&
      this.props.invoice.total_cents_paid != 0
        ? renderPrice(this.props.invoice.total_cents_paid) +
          " added to your account."
        : renderPrice(this.props.invoice.deal_credit_cents) +
          " added to your account.";

    var invoice_date_created = renderDate(this.props.invoice.date_created);
    var invoice_title = "";
    var invoice_description = "";
    var invoice_icon = "";
    var invoice_fa_icon = "";
    switch (this.props.invoice.item) {
      case "deal_credits":
      case "automated_deal_marketing":
      default:
        switch (this.props.invoice.payment_type) {
          case "stripe":
          default:
            invoice_title = "Purchased: " + deal_credits_text;
            invoice_description = renderPrice(
              this.props.invoice.total_cents_paid
            );
            invoice_icon = "attach-money";

            break;

          case "reload":
            invoice_title = "Automatically Reloaded: " + deal_credits_text;
            invoice_description = renderPrice(
              this.props.invoice.total_cents_paid
            );
            invoice_fa_icon = "refresh";

            break;

          case "gift":
            switch (this.props.invoice.token) {
              case "signup_api":
              default:
                invoice_title = "Signup Gift: " + deal_credits_text;
                invoice_description = "Thanks for signing up for DealMachine!";
                invoice_fa_icon = "gift";
                break;

              case "signup_bonus":
              case "email_promo":
                invoice_title = "Email Promo: " + deal_credits_text;
                invoice_description = "An extra gift just for you!";
                invoice_icon = "star";

                break;

              case "customer_support":
              case "customer_service":
                invoice_title = "Customer Service: " + deal_credits_text;
                invoice_description = "";
                invoice_icon = "face";
                break;
            }

            break;

          case "invite_promo":
          case "promo":
            invoice_title =
              "Referral Code: " +
              this.props.invoice.token +
              " " +
              deal_credits_text;
            invoice_description =
              "You used referral code " + this.props.invoice.token;
            invoice_fa_icon = "tag";

            break;

          case "invited_user":
            invoice_title = "Shared Referral Code: " + this.props.invoice.token;
            invoice_description = "You shared you referral code with a friend.";
            invoice_icon = "share";
            break;
        }

        break;

      case "tier_1_plan":
        invoice_title =
          INVOICE_TITLES[this.props.invoice.module_type] ||
          "DealMachine Driving For Dollars Plan";

        if (!this.props.invoice.total_cents_paid) {
          //temp fix until database is updated
          invoice_description = renderPrice(this.props.invoice.deal_credits);
        } else {
          invoice_description = renderPrice(
            this.props.invoice.total_cents_paid
          );
        }
        invoice_icon = "history";

        break;

      case "plan_purchase":
        invoice_title =
          INVOICE_TITLES[this.props.invoice.module_type] ||
          "DealMachine Driving For Dollars Plan";

        invoice_description = renderPrice(this.props.invoice.total_cents_paid);
        invoice_icon = "add-circle";

        break;

      case "addon_purchase":
        invoice_title = "Additional Product Purchase";
        invoice_description = renderPrice(this.props.invoice.total_cents_paid);
        invoice_icon = "add-circle";

        switch (this.props.invoice.payment_type) {
          case "promo":
          case "invite_promo":
            invoice_title = "Referral Code: Additional Product";
            invoice_description =
              "You used referral code " + this.props.invoice.token;
            invoice_fa_icon = "tag";
            invoice_icon = null;
            break;
          default:
            break;
        }

        break;

      case "custom_charge":
        invoice_title = "Custom Charge";
        invoice_description = renderPrice(this.props.invoice.total_cents_paid);
        invoice_icon = "attach-money";

        break;
    }

    return (
      <SelectItem
        select_type={"icon"}
        //select_icon={invoice_icon}
        noPress={true}
        subtext={invoice_date_created}
        description={
          this.renderDescription(invoice_description) + this.renderRefunded()
        }
      >
        {invoice_title}
      </SelectItem>
    );
  }
}

export default Invoice;
