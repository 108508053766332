import React, { PureComponent } from "react";
import { Row, Button, Wrapper } from "app/NativeComponents/common";

import { store } from "app/store";

import { triggerHapticFeedback, logMarketingEvent } from "app/NativeActions";

class ButtonWrapper extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.noPress !== prevProps.noPress && this.state.hovering) {
      this.setState({
        hovering: false
      });
    }
  }

  render() {
    const {
      id,
      primary = false,
      no_haptic_feed_back = false,
      buttonContainerStyle,
      tooltip = "",
      tooltipPlacement = "bottom",
      formButton = false,
      onPress = () => {},
      onLongPress = () => {},
      buttonStyle,
      disabled = false,
      noPress = false,
      loading = false,
      button_type = "normal",
      trackingID,
      renderInner = null,
      onHover = () => {},
      onHoverOut = () => {},
      pointerEvents = "auto",
      inBottomSheet = false,
      marketing_slug = null,
      href = null,
      internal = false,
      rel = null
    } = this.props;
    const colors = store.getState().settings.colors;
    const { hovering } = this.state;

    let bStyle = {
      outline: "none",
      borderWidth: 0
    };
    let bContainerStyle = {
      ...buttonContainerStyle
    };

    switch (button_type) {
      case "full":
      case "select_button":
        bStyle.flex = 1;
        break;
      default:
        break;
    }

    return (
      <Row style={bContainerStyle} pointerEvents={pointerEvents}>
        {noPress ? (
          <Wrapper
            id={id}
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            style={{ ...bStyle, ...buttonStyle }}
          >
            {renderInner ? renderInner({ hovering }) : null}
          </Wrapper>
        ) : (
          <Button
            to={href}
            internal={internal}
            rel={rel}
            inBottomSheet={inBottomSheet}
            id={id}
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            trackingID={trackingID}
            onPress={() => {
              if (!disabled && !loading) {
                if (!no_haptic_feed_back) {
                  if (primary) {
                    triggerHapticFeedback({ type: "impactHeavy" });
                  } else {
                    triggerHapticFeedback({ type: "impactLight" });
                  }
                }

                if (!!marketing_slug) {
                  logMarketingEvent({
                    slug: marketing_slug
                  });
                }

                onPress();
              }
            }}
            onLongPress={() => {
              if (!disabled && !loading) {
                if (!no_haptic_feed_back) {
                  triggerHapticFeedback({ type: "impactHeavy" });
                }
                onLongPress();
              }
            }}
            formButton={formButton}
            style={{ ...bStyle, ...buttonStyle }}
            onHover={() => {
              if (!disabled && !loading) {
                this.setState({
                  hovering: true
                });
              }
              onHover();
            }}
            onHoverOut={() => {
              this.setState({
                hovering: false
              });
              onHoverOut();
            }}
          >
            {renderInner ? renderInner({ hovering }) : null}
          </Button>
        )}
      </Row>
    );
  }
}

export default ButtonWrapper;
