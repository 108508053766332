import React, { Component } from "react";

import {
  Card,
  Wrapper,
  Copy,
  Bold,
  Row,
  Title
} from "app/NativeComponents/common";
import {
  SelectItem,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";
import { AppConfig, openUrl, renderPrice } from "app/NativeActions";
class AcceptOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

    this.acceptOffer = this.acceptOffer.bind(this);
  }
  componentDidMount() {}

  acceptOffer() {
    this.props.manageSubscription({
      token: this.props.token,
      type: "accept_offer",
      offer_type: this.props.offer_type,
      offer_id: this.props.offer_id,
      stripe_price_id:
        this.props.plan_interval === "year"
          ? this.props.stripe_price_id_year
          : this.props.stripe_price_id_month,
      no_messaging: true,
      onLoading: () => {
        this.setState({
          loading: true
        });
      },
      onError: () => {
        this.setState({
          loading: false
        });
      },
      onSuccess: this.props.onSuccess
    });
  }

  render() {
    const {
      featured = false,
      title,
      description,
      buttonText,
      colors,
      desktopMobile,
      device,
      subtext,
      price,
      plan_interval,
      year_price,
      includes_items,
      marketing_slug
    } = this.props;
    return (
      <Card
        style={
          featured
            ? {
                flex: 1,
                alignSelf: device === "mobile" ? "stretch" : "center",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: colors.active_color,
                boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                maxWidth: 400,
                minWidth: 320,
                margin: desktopMobile && device === "desktop" ? "10px auto" : 10
              }
            : {
                flex: 1,
                alignSelf: device === "mobile" ? "stretch" : "center",

                boxShadow: "1px 1px 5px rgba(0,0,0,0.25)",
                borderWidth: 0,
                maxWidth: 400,
                minWidth: 320,
                margin: desktopMobile && device === "desktop" ? "10px auto" : 10
              }
        }
      >
        <SelectItem
          select_type="none"
          subtext={subtext}
          noPress={true}
          selected={featured}
        >
          {title}
        </SelectItem>
        <Wrapper style={{ padding: 25 }}>
          <Copy>{description}</Copy>
        </Wrapper>

        {!!price ? (
          <Wrapper
            style={{ paddingRight: 25, paddingLeft: 25, paddingBottom: 25 }}
          >
            <Row style={{ alignItems: "flex-end" }}>
              <Title style={{ fontSize: 36 }}>
                <Bold>
                  {plan_interval == "year"
                    ? renderPrice(year_price / 12, "no_decimal")
                    : renderPrice(price, "no_decimal")}
                </Bold>
              </Title>
              <Copy style={{ marginLeft: 5 }}>{" / mo"}</Copy>
              {plan_interval == "year" ? (
                <Copy style={{ marginLeft: 5, color: colors.light_text_color }}>
                  {" - billed annually"}
                </Copy>
              ) : null}
            </Row>
          </Wrapper>
        ) : null}
        {includes_items &&
          includes_items.map((item, i) => {
            return (
              <SelectItem
                key={"item_" + i}
                select_type="icon"
                select_icon={"check"}
                icon_color={colors.success_color}
                noPress={true}
                tooltip={!!item?.tooltip ? item.tooltip : ""}
                tooltipPlacement={"top"}
                innerStyle={{
                  paddingTop: 0,
                  paddingRight: 0
                }}
                textStyle={
                  !!item?.tooltip
                    ? {
                        textDecoration: "underline",
                        textDecorationStyle: "dotted"
                      }
                    : {}
                }
              >
                {item?.text}
              </SelectItem>
            );
          })}

        <Wrapper style={{ padding: 25, paddingTop: 0 }}>
          {featured ? (
            <GhostButton
              button_type={"full"}
              loading={this.state.loading}
              onPress={this.acceptOffer}
              primary={false}
              icon_right={"arrow-right-alt"}
              marketing_slug={marketing_slug}
              innerStyle={{
                borderColor: colors.active_color
              }}
            >
              {buttonText}
            </GhostButton>
          ) : (
            <InlineButton
              button_type={"full"}
              loading={this.state.loading}
              onPress={this.acceptOffer}
              primary={featured}
              icon_right={"arrow-right-alt"}
              marketing_slug={marketing_slug}
            >
              {buttonText}
            </InlineButton>
          )}
        </Wrapper>
      </Card>
    );
  }
}

export default AcceptOffer;
