import React, { Component } from "react";

import { WebpageSection } from "app/NativeComponents/website/snippets";

class Sections extends Component {
  render() {
    if (this.props.pageContent) {
      if (this.props.pageContent.length > 0) {
        return (
          <div>
            {this.props.pageContent.map((section, i) => {
              return (
                <WebpageSection
                  key={i}
                  section={section.fields}
                  {...this.props}
                />
              );
            })}
          </div>
        );
      }
    }

    return <div />;
  }
}

export { Sections };
