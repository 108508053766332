import React, { Component } from "react";

import {
  Wrapper,
  Copy,
  Bold,
  Title,
  Modal,
  ModalOverlay
} from "app/NativeComponents/common";

import {
  CloseButton,
  InlineButton,
  BottomNavBar,
  GhostButton
} from "app/NativeComponents/snippets";

import { loadOnboardingText } from "app/NativeActions";
import { store } from "app/store";
class StreetViewOnboardingTextMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful_content: null,
      contentful_loading: true,
      closed: false
    };
  }

  componentDidMount() {}

  render() {
    const { contentful_content, contentful_loading, closed } = this.state;

    const colors = store.getState().settings.colors;

    if (closed) {
      return null;
    }
    return (
      <ModalOverlay
        isVisible={true}
        onPress={() => {
          this.setState({ closed: true });
        }}
      >
        <Modal actionSheet={true}>
          <Wrapper
            style={{
              overflow: "hidden",
              backgroundColor: colors.popover_color,
              borderTopColor: colors.border_color,
              borderTopStyle: "solid",
              borderTopWidth: 1,
              borderTopLeftRadius: 15,
            }}
          >
            <BottomNavBar>
              <Wrapper style={{ padding: 25 }}>
                <Title style={{ marginBottom: 25 }}>
                  🚗 <Bold>Let’s Drive</Bold>
                </Title>
                <Copy style={{ marginBottom: 10 }}>
                  Use your finger to move around the map and look at properties.
                  Property info will show in the bottom right corner (Click the
                  top to expand the panel).
                </Copy>
                <Copy style={{ marginBottom: 10 }}>
                  Once you find a property that meets your criteria, click{" "}
                  <Bold>"Add Lead."</Bold>
                </Copy>
                <Copy style={{ marginBottom: 10 }}>
                  You can add your own property if you are just trying this out.
                </Copy>
              </Wrapper>

              <GhostButton
                button_type={"full"}
                onPress={() => {
                  this.setState({ closed: true });
                }}
                style={{ marginBottom: 25 }}
              >
                Start Exploring
              </GhostButton>

              <CloseButton
                onPress={() => {
                  this.setState({ closed: true });
                }}
              />
            </BottomNavBar>
          </Wrapper>
        </Modal>
      </ModalOverlay>
    );
  }
}

export default StreetViewOnboardingTextMobile;
