import React, { Component } from "react";
import Intercom, { IntercomAPI } from "react-intercom";

import moment from "moment";

class IntercomWrapper extends Component {
  componentDidMount() {
    this.props.triggerIntercom(false);
    clearInterval(this._interval);

    this._interval = setTimeout(() => {
      this.props.triggerIntercom(true);
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  render() {
    // Ignore each of these paths, i.e., ignore app.dealmachine.com/sales/...
    const intercom_ignore_paths = ["sales", "virtual-driving", "team"];

    let intercom_data = {
      alignment: "right",
      custom_launcher_selector: "#intercom_button"
    };

    if (this.props.user && this.props.user?.intercom_hash) {
      intercom_data = {
        user_id: this.props.user.id,
        email: this.props.user.email,
        name: this.props.user.firstname + " " + this.props.user.lastname,
        phone: this.props.user.phone,
        signed_up_at: moment(this.props.user.date_created).format("X"),
        alignment: "right",
        custom_launcher_selector: "#intercom_button",
        user_hash: this.props.user.intercom_hash
          ? this.props.user.intercom_hash
          : null
      };
    }

    let should_show = true;
    intercom_ignore_paths.forEach(path => {
      if (window.location.pathname.startsWith(`/${path}/`)) {
        should_show = false;
      }
    });

    if (this.props.trigger_intercom && should_show) {
      window.Intercom("boot", {
        app_id: "sfur1js7",
        ...intercom_data
      });
      //  return <Intercom appID="sfur1js7" {...intercom_data} />;
    } else {
      window.Intercom("hide");
    }

    return null;
  }
}

export default IntercomWrapper;
