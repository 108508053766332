import React from "react";
import { Wrapper } from "app/NativeComponents/common";

import { store } from "app/store";

const BottomNavBar = ({
  children,
  style,
  textStyle,
  backgroundColor = null,
  borderColor = null
}) => {
  const colors = store.getState().settings.colors;
  const device = store.getState().native.device;
  const isIphoneX = store.getState().native.isIphoneX;

  return (
    <Wrapper
      style={{
        backgroundColor: !!backgroundColor
          ? backgroundColor
          : colors?.card_color,
        padding: 5,
        borderTopStyle: "solid",
        borderTopColor: !!borderColor ? borderColor : colors?.border_color,
        borderTopWidth: 1,
        alignSelf: "stretch",
        paddingBottom: isIphoneX ? 20 : 10,
        ...style
      }}
    >
      {children}
    </Wrapper>
  );
};

export { BottomNavBar };
