import React, { Component } from "react";

import AccountActivity from "app/DealMachineCore/components/AccountActivity";
import ActivityPropertyList from "app/DealMachineCore/components/ActivityPropertyList";

class RightPanels extends Component {
  render() {
    return <></>;
  }
}

export default RightPanels;
