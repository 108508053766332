import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  InputBox,
  GhostButton
} from "app/NativeComponents/snippets";
import NoteTextBox from "../NoteTextBox";

import {
  popSidePanel,
  updateNote,
  determineDisplayProperty,
  formatAddress
} from "app/NativeActions";

class AddNote extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;

    this.state = {
      panel_data,
      note: "",
      file_urls: "[]"
    };

    this._note_input = React.createRef();
    this.checkIfNeedsToSave = this.checkIfNeedsToSave.bind(this);
  }
  handleBack() {
    this.props.popSidePanel();
  }

  checkIfNeedsToSave() {
    if (!!this.state.note || JSON.parse(this.state.file_urls).length > 0) {
      return true;
    }

    return false;
  }

  render() {
    const {
      panel_data: { property }
    } = this.state;

    const display_property = determineDisplayProperty(property);
    const formatted_property_address = formatAddress({
      address: {
        address: display_property.property_address,
        address2: display_property.property_address2,
        address_city: display_property.property_address_city,
        address_state: display_property.property_address_state,
        address_zip: display_property.property_address_zip,
        latitude: property?.location?.latitude,
        longitude: property?.location?.longitude
      }
    });

    return (
      <Container>
        <NewHeader
          title="Add Note"
          subtitle={"Add a note for " + formatted_property_address.line1}
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <NoteTextBox
            noteValue={this.state.note}
            file_urls={this.state.file_urls}
            onSubmit={() => {
              this.props.updateNote({
                token: this.props.token,
                payload: {
                  id: 0,
                  activity_type: "note",
                  text: this.state.note,
                  file_urls: this.state.file_urls,
                  date_created: null,
                  user_id: this.props.user.id,
                  firstname: this.props.user.firstname,
                  lastname: this.props.user.lastname,
                  email: this.props.user.email,
                  image: this.props.user.image
                },
                type: "post_note",
                deal_id: property?.deal?.id,
                onLoading: () => {
                  this.setState({
                    loading: true
                  });
                },
                onError: () => {
                  this.setState({
                    loading: false
                  });
                },
                onSuccess: results => {
                  this.setState({
                    loading: false
                  });
                  if (this.state.panel_data?.onSuccess) {
                    this.state.panel_data.onSuccess(results);
                  }
                  this.handleBack();
                }
              });
            }}
            onNoteChange={value => {
              this.setState({
                note: value
              });
            }}
            onFilesChanged={value => {
              this.setState({
                file_urls: value
              });
            }}
            disabled={!this.checkIfNeedsToSave()}
            loading={this.state.loading}
            button_text="Post Note"
            {...this.props}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device } = native;
  const { colors } = settings;

  return { token, user, device, colors };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateNote
})(AddNote);
