import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Button,
  Copy,
  Bold,
  Icon
} from "app/NativeComponents/common";
import { IconButton } from "app/NativeComponents/snippets";

class ZoomButtons extends Component {
  render() {
    const { colors, toggleGrid, grid, show_text_safe, toggleTextSafe } =
      this.props;
    return (
      <Row>
        <IconButton
          tooltip={grid ? "Turn Grid Off" : "Turn Grid On"}
          tooltipPlacement={"top"}
          style={{
            backgroundColor: grid ? colors.background_color : colors.card_color,
            margin: 0
          }}
          onPress={() => {
            toggleGrid(!grid);
          }}
          icon={grid ? "grid-off" : "grid-on"}
        />
        <IconButton
          tooltip={
            show_text_safe ? "Hide Text Safe Area" : "Show Text Safe Area"
          }
          tooltipPlacement={"top"}
          style={{
            backgroundColor: show_text_safe
              ? colors.background_color
              : colors.card_color,
            margin: 0
          }}
          onPress={() => {
            toggleTextSafe(!show_text_safe);
          }}
          icon={show_text_safe ? "border-clear" : "border-outer"}
        />
      </Row>
    );
  }
}

export default ZoomButtons;
