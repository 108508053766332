import React, { Component } from "react";
import { connect } from "react-redux";

import ReactMapboxGl, {
  RotationControl,
  ZoomControl,
  Source,
  Layer,
  Image
} from "react-mapbox-gl";
import { store } from "app/store";
import { AppConfig } from "app/NativeActions";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import DrawControl from "react-mapbox-gl-draw";
let _movetimeout = null;

class Map extends Component {
  componentWillUnmount() {
    clearInterval(this._movetimeout);
    clearInterval(this._zoomtimeout);
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevProps.map_type !== this.props.map_type ||
      prevProps.dark_mode !== this.props.dark_mode
    ) {
      return true;
    }
    return false;
  }

  sendCoordinates(features) {
    let drawing_coordinates = [];

    if (features && features.length > 0) {
      const geometry = features[0].geometry;
      if (geometry && geometry.coordinates.length > 0) {
        const coordinates = geometry.coordinates[0];

        for (let i = 0; i < coordinates.length; i++) {
          drawing_coordinates.push({
            latitude: coordinates[i][1],
            longitude: coordinates[i][0]
          });
        }
      }
    }

    if (drawing_coordinates.length > 2) {
      this.props.onDraw(drawing_coordinates);
    }
  }

  render() {
    const {
      user,
      scrollZoom = false,
      latitude,
      longitude,
      drawing = false,
      colors,
      dark_mode
    } = this.props;
    //const colors = store.getState().settings.colors;
    //const dark_mode = store.getState().settings.dark_mode;
    const Map = ReactMapboxGl({
      accessToken:
        "pk.eyJ1IjoiZGVhbG1hY2hpbmUiLCJhIjoiY2t4YXZna3ZiM2t2djJvcDlkaXF5ZTF3YyJ9.ies_4s0QK2FRGcaXHsIXXg"
    });

    const onDrawCreate = ({ features }) => {
      this.props.drawingCreated();
      this.sendCoordinates(features);
    };

    const onDrawUpdate = ({ features }) => {
      this.sendCoordinates(features);
    };

    const onClick = (map, e) => {
      this.props.onMapClick(map, e);
    };

    const getCenter = map => {
      if (map) {
        if (map?.getCenter) {
          const coordinate = map?.getCenter();
          this.props.updateCenter({
            latitude: coordinate?.lat,
            longitude: coordinate?.lng
          });
        }

        this.props.updateBounds(map);
      }
    };

    const onStyleLoad = map => {
      this.props.onStyleLoad();
      getCenter(map);
    };

    const calculateZoom = map => {
      if (map) {
        if (map?.getZoom) {
          const zoom = map?.getZoom();
          this.props.updateZoomProperties({
            zoom: zoom
          });
        }

        this.props.updateBounds(map);
      }
    };

    return (
      <Map
        ref={this.props.onRef}
        style={
          this.props.map_type == "satellite"
            ? "mapbox://styles/dealmachine/ckzhc5qak000i15qpidgpl4ch?optimize=true"
            : dark_mode == "dark_mode"
            ? "mapbox://styles/dealmachine/ckzhc2ntq004c15lcuo7q4qex?optimize=true"
            : "mapbox://styles/dealmachine/ckzhbuxyo000h14qudkuurjga?optimize=true"
        }
        containerStyle={{
          width: "100%",
          height: "100%"
        }}
        zoom={[parseInt(this.props.starting_zoom)]}
        maxZoom={parseInt(this.props.maxZoom)}
        minZoom={parseInt(this.props.minZoom)}
        center={[
          parseFloat(this.props.starting_lng),
          parseFloat(this.props.starting_lat)
        ]}
        scrollZoom={scrollZoom}
        scrollWheelZoom={scrollZoom}
        onZoomEnd={map => {
          this.props.toggleMapMoving(false);
          setTimeout(() => {
            if (!this.props.mapIsMoving) {
              calculateZoom(map);
            }
          }, 1000);
        }}
        onMoveEnd={map => {
          this.props.toggleMapMoving(false);
          clearTimeout(_movetimeout);
          _movetimeout = setTimeout(() => {
            if (!this.props.mapIsMoving) {
              getCenter(map);
            }
          }, 1000);
        }}
        onDragEnd={map => {
          this.props.toggleMapMoving(false);
          clearTimeout(_movetimeout);
          _movetimeout = setTimeout(() => {
            if (!this.props.mapIsMoving) {
              getCenter(map);
            }
          }, 1000);
        }}
        onZoomStart={() => {
          this.props.toggleMapMoving(true);
          this.props.hidePopover();
        }}
        onMoveStart={() => {
          clearTimeout(_movetimeout);
          this.props.hidePopover();
        }}
        onDragStart={() => {
          clearTimeout(_movetimeout);
          this.props.hidePopover();
        }}
        onStyleLoad={onStyleLoad}
        onClick={onClick}
        logoPosition={"none"}
        attributionControl={false}
      >
        <Image id={"lead"} url={"/assets/images/active_pin.png"} />
        <Image id={"lead_white"} url={"/assets/images/white_pin.png"} />
        <Image id={"active_lead"} url={"/assets/images/orange_pin.png"} />
        <Image id={"person_pin"} url={"/assets/images/person_pin_orange.png"} />

        <Source
          id="borders_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Source
          id="all_borders_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="fill"
          minZoom={16}
          id="properties_on_map_fill"
          sourceId="all_borders_on_map"
          paint={{
            "fill-color": colors.border_color,
            "fill-outline-color": colors.text_color,
            "fill-opacity": 0.2
          }}
        />

        <Layer
          type="fill"
          minZoom={16}
          id="highlights_on_map_fill"
          sourceId="borders_on_map"
          paint={{
            "fill-color": [
              "match",
              ["get", "active_status"],
              "active_viewing",
              colors.orange_color_muted,
              "viewing",
              colors.active_color_muted,
              "active_highlighted",
              colors.orange_color_muted,
              "highlighted",
              colors.actionable_text_color,
              colors.border_color
            ],
            "fill-outline-color": [
              "match",
              ["get", "active_status"],
              "active_viewing",
              colors.orange_color,
              "viewing",
              colors.actionable_text_color,
              "active_highlighted",
              colors.orange_color,
              "highlighted",
              colors.actionable_text_color,
              colors.border_color
            ],
            "fill-opacity": [
              "match",
              ["get", "active_status"],
              "viewing",
              0.3,
              "active_highlighted",
              0.3,
              "highlighted",
              this.props.main_map_location?.map_type == "satellite"
                ? 0.3
                : 0.15,
              0.15
            ]
          }}
        />
        <Source
          id="locations_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="fill"
          id="locations_on_map_fill"
          sourceId="locations_on_map"
          paint={{
            "fill-color": colors.actionable_text_color,
            "fill-outline-color": colors.actionable_text_color,
            "fill-opacity": 0.05
          }}
        />
        <Layer
          type="line"
          sourceId="locations_on_map"
          id="locations_on_map_line"
          paint={{
            "line-color": colors.actionable_text_color,
            "line-width": 3,
            "line-opacity": 0.7
          }}
        />

        <Source
          id="routes_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="line"
          minZoom={12}
          id="routes_on_map_line"
          sourceId="routes_on_map"
          paint={{
            "line-color": [
              "match",
              ["get", "route_status"],
              "tier_1",
              colors.success_color,
              "tier_2",
              colors.orange_color_light,
              "tier_3",
              colors.error_color,
              "rgba(255, 255, 255, 0)"
            ],
            "line-width": 3,
            "line-opacity": 0.7
          }}
        />
        <Source
          id="active_route_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="line"
          minZoom={6}
          id="active_route_on_map_line"
          sourceId="active_route_on_map"
          paint={{
            "line-color": colors.orange_color,
            "line-width": 3,
            "line-opacity": 1
          }}
        />

        <Source
          id="current_route_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="line"
          minZoom={6}
          id="current_route_on_map_line"
          sourceId="current_route_on_map"
          paint={{
            "line-color": colors.orange_color,
            "line-width": 3,
            "line-opacity": 1
          }}
        />
        {/*
        <Layer
          type="heatmap"
          maxZoom={14}
          minZoom={4}
          id="routes_on_map_head_map"
          sourceId="routes_on_map"
          paint={{
            "heatmap-weight": {
              property: "number_of_days",
              type: "exponential",
              stops: [
                [1, 0],
                [62, 1]
              ]
            },
            // increase intensity as zoom level increases
            "heatmap-intensity": {
              stops: [
                [11, 1],
                [15, 3]
              ]
            },
            // use sequential color palette to use exponentially as the weight increases
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(255,255,255,0)",
              0.2,
              "rgba(78, 232, 195, 0.1)",
              0.4,
              "rgba(78, 232, 195, 0.2)",
              0.6,
              "rgba(78, 232, 195, 0.4)",
              0.8,
              "rgba(78, 232, 195, 0.5)",
              0.9,
              "rgba(78, 232, 195, 0.7)"
            ],
            // increase radius as zoom increases
            "heatmap-radius": {
              stops: [
                [11, 15],
                [15, 20]
              ]
            },
            // decrease opacity to transition into the circle layer
            "heatmap-opacity": {
              default: 1,
              stops: [
                [14, 1],
                [15, 0]
              ]
            }
          }}
        />*/}

        <Source
          id="properties_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="circle"
          minZoom={9}
          id="properties_on_map_circle"
          sourceId="properties_on_map"
          paint={{
            "circle-color": [
              "match",
              ["get", "active_status"],

              "active",
              "rgba(242, 99, 58, 1)",

              "active_highlighted",
              "rgba(242, 99, 58, 1)",

              "viewing",
              "rgba(242, 99, 58, 1)",

              "active_viewing",
              "rgba(242, 99, 58, 1)",

              "highlighted",
              colors.active_color_muted,

              dark_mode == "dark_mode"
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(44, 44, 44, 0.75)"
            ],
            "circle-radius": [
              "match",
              ["get", "lead_status"],
              "active_lead",
              0,
              "viewing_lead",
              0,
              "lead",
              0,
              3
            ],
            "circle-stroke-width": [
              "match",
              ["get", "lead_status"],
              "active_lead",
              0,
              "viewing_lead",
              0,
              "lead",
              0,
              1
            ],
            "circle-stroke-color": [
              "match",
              ["get", "active_status"],

              "active",
              colors.card_color,

              "active_highlighted",
              colors.card_color,

              "viewing",
              colors.card_color,

              "highlighted",
              colors.actionable_text_color,

              "active_viewing",
              colors.card_color,

              colors.card_color
            ]
          }}
        />

        <Layer
          type="symbol"
          minZoom={16}
          id="properties_on_map_text"
          sourceId="properties_on_map"
          layout={{
            "text-field": "{property_label}",
            "text-size": 12,
            "text-anchor": "top",
            "text-justify": "center",
            "text-offset": [0, 0.5]
          }}
          paint={{
            "text-color":
              this.props.map_type == "satellite"
                ? colors.white_text_color
                : colors.text_color,
            "text-halo-width": 3,
            "text-halo-color":
              dark_mode == "dark_mode" || this.props.map_type == "satellite"
                ? "rgba(0, 0, 0, 0.5)"
                : "rgba(255, 255, 255, 0.75)"
          }}
        />
        <Source
          id="leads_on_map"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />
        <Layer
          type="symbol"
          id="leads_on_map_symbol"
          sourceId="properties_on_map"
          layout={{
            "icon-image": [
              "match",
              ["get", "lead_status"],
              "active_lead",
              "active_lead",
              "viewing_lead",
              "active_lead",
              "lead",
              "lead",
              ""
            ],
            "icon-size": 0.3,
            "icon-anchor": "bottom"
          }}
        />

        <Layer
          type="symbol"
          minZoom={16}
          id="leads_on_map_text"
          sourceId="leads_on_map"
          layout={{
            "text-field": "{property_label}",
            "text-size": 12,
            "text-anchor": "top",
            "text-justify": "center",
            "text-offset": [0, 0.5]
          }}
          paint={{
            "text-color": colors.text_color,
            "text-halo-width": 3,
            "text-halo-color":
              dark_mode == "dark_mode" || this.props.map_type == "satellite"
                ? "rgba(0, 0, 0, 0.5)"
                : "rgba(255, 255, 255, 0.75)"
          }}
        />

        <Source
          id="street_view_marker"
          tileJsonSource={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          }}
        />

        <Layer
          type="symbol"
          minZoom={10}
          id="street_view_marker_symbol"
          sourceId="street_view_marker"
          layout={{
            "icon-image": "person_pin",
            "icon-size": 0.5,
            "icon-anchor": "bottom"
          }}
        />

        <DrawControl
          ref={this.props.onDrawRef}
          position="top-left"
          touchEnabled={!drawing}
          displayControlsDefault={false}
          defaultMode={"simple_select"}
          onDrawCreate={onDrawCreate}
          onDrawUpdate={onDrawUpdate}
          minZoom={this.props.minZoom}
          maxZoom={this.props.maxZoom}
          modes={{ static: StaticMode }}
          styles={[
            {
              id: "gl-draw-line",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["!=", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.actionable_text_color,
                "line-dasharray": [0.2, 2],
                "line-width": 2
              }
            },
            // polygon fill
            {
              id: "gl-draw-polygon-fill",
              type: "fill",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["!=", "mode", "static"]
              ],
              paint: {
                "fill-color": colors.actionable_text_color,
                "fill-outline-color": colors.actionable_text_color,
                "fill-opacity": 0.1
              }
            },
            // polygon mid points
            {
              id: "gl-draw-polygon-midpoint",
              type: "circle",
              filter: [
                "all",
                ["==", "$type", "Point"],
                ["==", "meta", "midpoint"]
              ],
              paint: {
                "circle-radius": 3,
                "circle-color": colors.actionable_text_color
              }
            },
            {
              id: "gl-draw-polygon-stroke-active",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["!=", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.actionable_text_color,
                "line-dasharray": [0.2, 2],
                "line-width": 2
              }
            },
            // vertex point halos
            {
              id: "gl-draw-polygon-and-line-vertex-halo-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"]
              ],
              paint: {
                "circle-radius": 5,
                "circle-color": colors.card_color
              }
            },
            // vertex points
            {
              id: "gl-draw-polygon-and-line-vertex-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"]
              ],
              paint: {
                "circle-radius": 3,
                "circle-color": colors.actionable_text_color
              }
            },

            // INACTIVE (static, already drawn)
            // line stroke
            {
              id: "gl-draw-line-static",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["==", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.actionable_text_color,
                "line-width": 3
              }
            },
            // polygon fill
            {
              id: "gl-draw-polygon-fill-static",
              type: "fill",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["==", "mode", "static"]
              ],
              paint: {
                "fill-color": colors.actionable_text_color,
                "fill-outline-color": colors.actionable_text_color,
                "fill-opacity": 0.1
              }
            },
            // polygon outline
            {
              id: "gl-draw-polygon-stroke-static",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["==", "mode", "static"]
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round"
              },
              paint: {
                "line-color": colors.actionable_text_color,
                "line-width": 2
              }
            }
          ]}
        />
      </Map>
    );
  }
}

export default Map;
