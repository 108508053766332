import React, { PureComponent } from "react";
import {
  Wrapper,
  Row,
  Icon,
  Copy,
  InnerCopy,
  Bold,
  Spin,
  ProfilePic
} from "app/NativeComponents/common";
import {
  SmallLabel,
  GhostButton,
  InlineButton
} from "app/NativeComponents/snippets";
import ButtonWrapper from "./ButtonWrapper";

import { store } from "app/store";
const SelectItem = props => {
  const colors = store.getState().settings.colors;

  const {
    children,
    primary,
    style,
    innerStyle,
    rowStyle,
    contentStyle,
    textStyle,
    description,
    sub_description,
    descriptionStyle,
    subtext,
    subtextStyle,
    subtextTooltip = null,
    renderTitleButton = null,
    disabled = false,
    deleteButton = false,
    icon,
    fa_icon,
    hover_icon,
    hover_fa_icon,
    hover_icon_color,
    icon_color,
    text_color,
    icon_tooltip = "",
    icon_tooltip_placement = "top",
    pressedIn = false, //pressed in override from props
    loading = false,
    secondary_loading = false,
    confirm_loading = false,
    button_type = "normal",

    label,
    selected = false,
    select_disabled = false,
    select_type = "check",
    select_tooltip = "",
    select_tooltip_placement = "top",

    renderCustomSelect = null,

    user = null,
    select_icon,
    selected_background_color,
    selected_text_color,
    require_confirm = false,
    onConfirm = () => {},
    confirm_text = "Confirm",
    confirm_disabled = false,
    renderContent = null,
    renderDescription = null,
    renderBottom = null,
    renderTop = null,
    renderLeft = null,
    renderRight = null,
    secondaryButton = "",
    onSecondaryPress = () => {},
    hover_color = colors.hover_color,
    disableHover,
    iconClickOnly = false,
    s_icon_color = null,
    select_icon_style = null
  } = props;

  let icon_selected = "check-box";
  let icon_unselected = "check-box";
  let icon_select_disabled = "indeterminate-check-box";
  let icon_size = 18;

  const tColor = !!s_icon_color
    ? s_icon_color
    : text_color
    ? text_color
    : primary
    ? colors.orange_color
    : selected
    ? selected_text_color || colors.actionable_text_color
    : select_disabled
    ? colors.light_text_color
    : colors.text_color;
  let select_icon_color = tColor;

  switch (select_type) {
    case "check":
    default:
      icon_selected = "check-box";
      icon_unselected = "check-box-outline-blank";
      break;

    case "radio":
      icon_selected = "radio-button-checked";
      icon_unselected = "radio-button-unchecked";
      break;

    case "toggle":
      icon_selected = "toggle-on";
      icon_unselected = "toggle-off";
      icon_size = 32;
      if (selected) {
        select_icon_color = colors.success_color;
      }
      break;
  }

  return (
    <ButtonWrapper
      {...props}
      onPress={
        !iconClickOnly && props.onPress ? () => props.onPress() : () => {}
      }
      noPress={props.noPress || iconClickOnly}
      button_type={"full"}
      renderInner={({
        hovering /*actual pressed in from state of button wrapper*/
      }) => {
        return (
          <Wrapper
            style={{
              margin: 5,
              borderRadius: 5,
              backgroundColor:
                require_confirm && selected && !confirm_disabled
                  ? colors.orange_color_muted
                  : require_confirm && selected
                  ? colors.active_color_muted
                  : (hovering || pressedIn) && !disableHover
                  ? hover_color
                  : selected
                  ? selected_background_color || "transparent"
                  : "transparent",
              opacity: disabled ? 0.5 : 1,

              ...style
            }}
          >
            {renderTop ? renderTop() : null}

            <Row style={props.rowStyle}>
              {renderLeft ? renderLeft() : null}

              <Wrapper style={{ padding: 25, flex: 1, ...innerStyle }}>
                <Row style={{ ...contentStyle }}>
                  {loading ? (
                    <Wrapper style={{ marginRight: 25 }}>
                      <Spin size="small" />
                    </Wrapper>
                  ) : select_type == "custom" && renderCustomSelect ? (
                    renderCustomSelect()
                  ) : select_type == "profile_pic" ? (
                    <>
                      {user == "blank" ? (
                        <Wrapper
                          style={{
                            width: 30,
                            height: 30,
                            backgroundColor: colors.background_color,
                            borderRadius: 15,
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: 25
                          }}
                          tooltip={select_tooltip}
                          tooltipPlacement={select_tooltip_placement}
                        >
                          <Icon icon="person" size={icon_size} />
                        </Wrapper>
                      ) : (
                        <Wrapper
                          style={{
                            width: 30,
                            height: 30,
                            marginRight: 25
                          }}
                          tooltip={select_tooltip}
                          tooltipPlacement={select_tooltip_placement}
                        >
                          <ProfilePic
                            size={30}
                            email={user?.email}
                            image={user?.image}
                            firstname={user?.firstname}
                            lastname={user?.lastname}
                            style={{ margin: 0 }}
                          />
                        </Wrapper>
                      )}
                    </>
                  ) : select_type == "icon" ? (
                    <Wrapper
                      tooltip={select_tooltip}
                      tooltipPlacement={select_tooltip_placement}
                    >
                      <Icon
                        icon={select_icon}
                        size={icon_size}
                        color={
                          selected
                            ? select_icon_color || colors.actionable_text_color
                            : icon_color
                            ? icon_color
                            : tColor
                        }
                        style={{ marginRight: 25, ...select_icon_style }}
                      />
                    </Wrapper>
                  ) : select_type !== "none" ? (
                    <Wrapper
                      tooltip={select_tooltip}
                      tooltipPlacement={select_tooltip_placement}
                      onClick={
                        iconClickOnly && props.onPress
                          ? () => props.onPress()
                          : () => {}
                      }
                      className={iconClickOnly ? "deal-button" : ""}
                      style={{ alignSelf: "auto" }}
                    >
                      <Icon
                        icon={
                          select_disabled
                            ? icon_select_disabled
                            : selected
                            ? icon_selected
                            : icon_unselected
                        }
                        size={icon_size}
                        color={
                          selected
                            ? select_icon_color || colors.actionable_text_color
                            : tColor
                        }
                        style={{
                          marginRight: 25
                        }}
                      />
                    </Wrapper>
                  ) : null}

                  {renderContent ? (
                    renderContent()
                  ) : (
                    <Wrapper style={{ flex: 1 }}>
                      {!!label ? (
                        <SmallLabel
                          style={{
                            padding: 0,
                            paddingTop: 0,
                            paddingBottom: 0
                          }}
                          textStyle={
                            primary && !selected
                              ? { color: colors.orange_color }
                              : {}
                          }
                        >
                          {label}
                        </SmallLabel>
                      ) : null}
                      <Row>
                        {selected ? (
                          <Copy
                            style={{
                              color:
                                selected_text_color ||
                                colors.actionable_text_color,
                              ...textStyle
                            }}
                          >
                            <Bold>{children}</Bold>
                            {!!subtext && !renderTitleButton ? (
                              <InnerCopy
                                tooltip={subtextTooltip}
                                tooltipPlacement={"top"}
                                style={{
                                  fontSize: 10,
                                  marginLeft: 5,
                                  color:
                                    selected_text_color ||
                                    colors.light_text_color,
                                  ...subtextStyle
                                }}
                              >
                                {"   "}
                                {subtext}
                              </InnerCopy>
                            ) : null}
                          </Copy>
                        ) : !!description || primary || renderDescription ? (
                          <Copy style={{ color: tColor, ...descriptionStyle }}>
                            <Bold>{children}</Bold>
                            {!!subtext && !renderTitleButton ? (
                              <InnerCopy
                                tooltip={subtextTooltip}
                                tooltipPlacement={"top"}
                                style={{
                                  fontSize: 10,
                                  marginLeft: 5,
                                  color:
                                    selected_text_color ||
                                    colors.light_text_color,
                                  ...subtextStyle
                                }}
                              >
                                {"   "}
                                {subtext}
                              </InnerCopy>
                            ) : null}
                          </Copy>
                        ) : (
                          <Copy style={{ color: tColor, ...textStyle }}>
                            {children}
                            {!!subtext && !renderTitleButton ? (
                              <InnerCopy
                                tooltip={subtextTooltip}
                                tooltipPlacement={"top"}
                                style={{
                                  fontSize: 10,
                                  marginLeft: 5,
                                  color:
                                    selected_text_color ||
                                    colors.light_text_color,
                                  ...subtextStyle
                                }}
                              >
                                {"   "}
                                {subtext}
                              </InnerCopy>
                            ) : null}
                          </Copy>
                        )}
                        {renderTitleButton ? renderTitleButton() : null}
                      </Row>
                      {renderDescription ? (
                        renderDescription()
                      ) : !!description ? (
                        <Copy style={{ ...descriptionStyle }}>
                          {description}
                        </Copy>
                      ) : null}

                      {!!sub_description ? (
                        <Copy
                          style={{
                            color: "#acacac",
                            fontSize: 12,
                            fontStyle: "italic"
                          }}
                        >
                          {sub_description}
                        </Copy>
                      ) : null}
                    </Wrapper>
                  )}

                  {!require_confirm || !selected ? (
                    <>
                      {!!icon || !!fa_icon ? (
                        <Wrapper
                          tooltip={icon_tooltip}
                          tooltipPlacement={icon_tooltip_placement}
                        >
                          <Icon
                            icon={icon}
                            fa_icon={fa_icon}
                            color={icon_color ? icon_color : tColor}
                            size={icon_size}
                            style={{ marginLeft: 25 }}
                          />
                        </Wrapper>
                      ) : (!!hover_icon || !!hover_fa_icon) && hovering ? (
                        <Wrapper
                          style={{
                            width: 25,
                            marginLeft: 25,
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                          tooltip={icon_tooltip}
                          tooltipPlacement={icon_tooltip_placement}
                        >
                          <Icon
                            icon={hover_icon}
                            fa_icon={hover_fa_icon}
                            size={icon_size}
                            color={hover_icon_color ? hover_icon_color : tColor}
                          />
                        </Wrapper>
                      ) : !!hover_icon || !!hover_fa_icon ? (
                        //leave a space for the icon to hover
                        <Wrapper style={{ width: 25, marginLeft: 25 }} />
                      ) : null}
                    </>
                  ) : null}
                </Row>
              </Wrapper>
              {require_confirm && selected ? (
                <Row>
                  {!!secondaryButton ? (
                    <InlineButton
                      onPress={onSecondaryPress}
                      loading={secondary_loading}
                    >
                      {secondaryButton}
                    </InlineButton>
                  ) : null}

                  <GhostButton
                    onPress={onConfirm}
                    disabled={confirm_disabled}
                    primary={true}
                    loading={confirm_loading}
                  >
                    {confirm_text}
                  </GhostButton>
                </Row>
              ) : null}
              {renderRight ? renderRight() : null}
            </Row>
            {renderBottom ? renderBottom() : null}
          </Wrapper>
        );
      }}
    />
  );
};

export { SelectItem };
