import React, { Component } from "react";

const FeatureImage = ({ feature }) => {
  if (feature.image) {
    return (
      <div className="dm-website-feature-image">
        <img
          src={feature.image.fields.file.url}
          alt={feature.image.fields.file.title}
        />
      </div>
    );
  }

  return <div />;
};

export default FeatureImage;
