import React, { Component } from "react";
import {
  Wrapper,
  Title,
  Card,
  CardBody,
  Row,
  KeyboardView
} from "app/NativeComponents/common";
import { InlineTabs } from "app/NativeComponents/snippets";

import Permissions from "./Permissions";
import Access from "./Access";

class TabbedItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "permissions"
    };
  }

  selectTab(tab) {
    this.setState({ tab });
  }

  render() {
    if (
      this.props.user.team_clearance_level > 1 ||
      this.props.user.can_mimic_team_owner == 1 ||
      this.props.user.team_owner == 1
    ) {
      if (this.props.active_team_member.member_type == "team_member") {
        return (
          <>
            {/*
            <InlineTabs
              tab_type="underline"
              selected_tab={this.state.tab}
              tabs={[
                {
                  onPress: () => {
                    this.setState({
                      tab: "permissions"
                    });
                  },
                  slug: "permissions",
                  title: "Permissions"
                },
                {
                  onPress: () => {
                    this.setState({
                      tab: "access"
                    });
                  },

                  slug: "access",
                  title: "Access"
                }
              ]}
            />
            */}
            <Permissions tab={this.state.tab} {...this.props} />
            {/*<Access tab={this.state.tab} {...this.props} />*/}
          </>
        );
      }
    }

    return <Wrapper />;
  }
}

export default TabbedItems;
