import React, { Component } from "react";
import { Wrapper, Copy, Bold, Button, Row } from "app/NativeComponents/common";
import { PopoverMenu } from "app/NativeComponents/snippets";

class TaskComponent extends Component {
  render() {
    const {
      is_active,
      onPress,
      step,
      colors,
      workflow,
      active_step,
      step_index,
      branch_index = null,
      branch_step_index = null
    } = this.props;
    if (step) {
      return (
        <Button
          onPress={onPress}
          style={{
            borderColor: is_active ? colors.active_color : colors.border_color,
            backgroundColor: colors.card_color,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 5,
            margin: "auto",
            width: 350
          }}
        >
          <Wrapper>
            <Wrapper
              style={{
                padding: 15,
                borderBottomColor: is_active
                  ? colors.active_color
                  : colors.border_color,
                borderBottomWidth: 1,
                borderBottomStyle: "solid"
              }}
            >
              <Row>
                <Wrapper style={{ flex: 1 }}>
                  <Copy>
                    <Bold>Create task</Bold>
                  </Copy>
                </Wrapper>
                <PopoverMenu
                  icon={"more-vert"}
                  style={{ paddingLeft: 15 }}
                  icon_size={18}
                  menu_items={[
                    {
                      title: "Delete Component",
                      icon: "delete",
                      type: "delete",
                      onPress: () => {
                        this.props.setModal({
                          title: "Delete Component?",
                          description:
                            "Are you sure you want to delete this workflow component?",
                          submit: "Delete Component",
                          icon: "delete",
                          buttonType: "destroy",
                          onPress: () => {
                            this.props.deleteStep({
                              step_index,
                              branch_index,
                              branch_step_index
                            });
                          },
                          cancel: "",
                          onCancel: () => {}
                        });
                        this.props.toggleModal({
                          show: true,
                          type: "normal"
                        });
                      }
                    }
                  ]}
                />
              </Row>
            </Wrapper>
            <Wrapper>
              {is_active ? (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Configuring...
                  </Copy>
                </Wrapper>
              ) : step.data ? (
                <Wrapper style={{ padding: 20 }}>
                  <Copy>
                    Assign task to{" "}
                    <Bold>
                      {step.data.assign_type == "existing_owner"
                        ? "lead owner"
                        : step.data.assign_type == "specific_user"
                        ? step.data.assign_to_user_name
                        : step.data.assign_type == "round_robin"
                        ? "via round robin"
                        : "no one"}
                    </Bold>
                  </Copy>
                </Wrapper>
              ) : (
                <Wrapper style={{ padding: 20 }}>
                  <Copy
                    style={{
                      textAlign: "center",
                      color: colors.light_text_color
                    }}
                  >
                    Not configured
                  </Copy>
                </Wrapper>
              )}
            </Wrapper>
          </Wrapper>
        </Button>
      );
    }
    return null;
  }
}

export default TaskComponent;
