import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Spin,
  Copy,
  Bold,
  Row,
  Button,
  Scroll,
  Input,
  Icon
} from "app/NativeComponents/common";
import {
  PopoverMenu,
  PillButton,
  SearchBar,
  InlineButton,
  SpinWrapper,
  SelectItem
} from "app/NativeComponents/snippets";
import { store } from "app/store";
import { getWorkflowInfo, formatAddress } from "app/NativeActions";

class TestCriteria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      search: ""
    };
  }

  filterVariables(data = []) {
    const { search } = this.state;
    let filtered_data = [];
    for (const [key, value] of Object.entries(data)) {
      if (!!value.label && value.type !== "object_array" && value.type !== "flattened_object_collection") {
        if (value.label?.toLowerCase().indexOf(search?.toLowerCase()) != -1) {
          filtered_data.push({
            ...value,
            key
          });
        }
      }
    }

    return filtered_data;
  }

  componentDidMount() {
    this.props.getWorkflowInfo({
      token: this.props.token,
      type: "testing_criteria",
      search: this.state.search,
      filters: this.props.filters
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.search !== prevState.search) {
      this.searchLeads();
    }
  }

  componentWillUnmount() {
    clearInterval(this._search_interval);
  }

  searchLeads() {
    if (this.state.search.length > 3 || this.state.search.length == 0) {
      clearInterval(this._search_interval);
      this._search_interval = setTimeout(() => {
        this.props.getWorkflowInfo({
          token: this.props.token,
          type: "testing_criteria",
          search: this.state.search,
          filters: this.props.filters
        });
      }, 250);
    }
  }

  render() {
    const {
      style,
      testing_criteria,
      testing_criteria_loading,
      disabled = false
    } = this.props;
    const { show, search } = this.state;

    const colors = store.getState().settings.colors;
    const device = store.getState().native.device;
    const isMobile = store.getState().native.isMobile;
    if (disabled) {
      return (
        <InlineButton
          noPress={true}
          pressedIn={false}
          disabled={true}
          icon_right="keyboard-arrow-down"
        >
          Test Criteria
        </InlineButton>
      );
    }
    return (
      <PopoverMenu
        show={show}
        no_swipe={true}
        no_cancel={true}
        onShow={s => {
          this.setState({
            show: s
          });
        }}
        popover_width={450}
        renderComponent={() => {
          return (
            <InlineButton
              noPress={true}
              pressedIn={show}
              disabled={false}
              icon_right="keyboard-arrow-down"
            >
              Test Criteria
            </InlineButton>
          );
        }}
        renderMenu={() => {
          return (
            <Wrapper
              style={{
                alignSelf: "stretch"
              }}
            >
              <SearchBar
                title="Search leads to test criteria"
                onChange={value =>
                  this.setState({
                    search: value
                  })
                }
                style={{ margin: 10, marginBottom: 0 }}
                value={search}
              />
              <Scroll
                style={{
                  maxHeight: 400
                }}
              >
                {testing_criteria_loading ? (
                  <SpinWrapper text="Loading..." />
                ) : testing_criteria.length == 0 ? (
                  <Wrapper
                    style={{
                      padding: 25,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Copy style={{ textAlign: "center" }}>
                      Nothing to display
                    </Copy>
                  </Wrapper>
                ) : (
                  testing_criteria.map((item, i) => {
                    let address = formatAddress({
                      address: {
                        address: item.lead_data?.property_address_line_1?.value,
                        address2:
                          item.lead_data?.property_address_line_2?.value,
                        address_city:
                          item.lead_data?.property_address_city?.value,
                        address_state:
                          item.lead_data?.property_address_state?.value,
                        address_zip:
                          item.lead_data?.property_address_zipcode?.value
                      }
                    });
                    return (
                      <SelectItem
                        key={"item_" + i}
                        label={item.lead_data?.owner_name?.value}
                        description={address.line2}
                        subtext={item.passes_test ? "Pass" : "Fail"}
                        select_type={"none"}
                        icon={item.passes_test ? "check" : "error"}
                        icon_color={
                          item.passes_test
                            ? colors.success_color
                            : colors.error_color
                        }
                      >
                        {address.line1}
                      </SelectItem>
                    );
                  })
                )}
              </Scroll>
            </Wrapper>
          );
        }}
        menu_items={null}
      />
    );
  }
}
const mapStateToProps = ({ workflow, auth }) => {
  const { token } = auth;
  const { testing_criteria, testing_criteria_loading } = workflow;

  return {
    token,
    testing_criteria,
    testing_criteria_loading
  };
};

export default connect(mapStateToProps, { getWorkflowInfo })(TestCriteria);
