import React, { Component } from "react";
import { connect } from "react-redux";

import { Container, KeyboardView } from "app/NativeComponents/common";
import {
  NewHeader,
  SelectBox,
  ConfirmLabel
} from "app/NativeComponents/snippets";

import {
  popSidePanel,
  updateUserSettings,
  updateCompsSettings
} from "app/NativeActions";

class CompsDefaultSettings extends Component {
  constructor(props) {
    super(props);

    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      panel_data
    };

    this.state = {
      panel_data,
      saving_comps_settings: false,
      date_range_focus: false,
      date_range_default:
        props.comps_settings?.date_range_default || "6_months",
      sqft_range_focus: false,
      sqft_range_default: props.comps_settings?.sqft_range_default || "20",
      bathroom_range_focus: false,
      bathroom_range_default:
        props.comps_settings?.bathroom_range_default || "none",
      bedroom_range_focus: false,
      bedroom_range_default:
        props.comps_settings?.bedroom_range_default || "none",
      year_built_range_focus: false,
      year_built_range_default:
        props.comps_settings?.year_built_range_default || "none",
      range_miles_focus: false,
      range_miles_default: props.comps_settings?.range_miles_default || 1,
      dominant_source: props.comps_settings?.dominant_source || "mls",
      dominant_source_focus: false
    };

    this._date_range_input = React.createRef();
    this._sqft_range_input = React.createRef();
    this._bedroom_range_input = React.createRef();
    this._bathroom_range_input = React.createRef();
    this._year_built_range_input = React.createRef();
    this._range_miles_input = React.createRef();
    this._dominant_source_input = React.createRef();

    this.saveUpdates = this.saveUpdates.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidUpdate(prevProps, prevState) {}

  saveUpdates() {
    const new_comps_settings = {
      ...this.props.comps_settings,
      date_range_default: this.state.date_range_default,
      sqft_range_default: this.state.sqft_range_default,
      bathroom_range_default: this.state.bathroom_range_default,
      bedroom_range_default: this.state.bedroom_range_default,
      year_built_range_default: this.state.year_built_range_default,
      range_miles_default: this.state.range_miles_default,
      dominant_source: this.state.dominant_source
    };
    this.props.updateUserSettings({
      token: this.props.token,
      type: "comps_settings",
      value: new_comps_settings,
      onLoading: () => {
        this.setState({
          saving_comps_settings: true
        });
      },
      onError: () => {
        this.setState({
          saving_comps_settings: false
        });
      },
      onSuccess: () => {
        this.setState({
          saving_comps_settings: false
        });
        this.props.updateCompsSettings(new_comps_settings);

        if (this.state.panel_data.onSave) {
          this.state.panel_data.onSave();
        }

        this.props.popSidePanel();
      }
    });
  }

  checkIfNeedsToSave() {
    const { comps_settings } = this.props;
    if (
      this.state.date_range_default != comps_settings.date_range_default ||
      this.state.sqft_range_default != comps_settings.sqft_range_default ||
      this.state.year_built_range_default !=
        comps_settings.year_built_range_default ||
      this.state.range_miles_default != comps_settings.range_miles_default ||
      this.state.dominant_source !== comps_settings.dominant_source
    ) {
      return true;
    }

    return false;
  }

  render() {
    const { isMobile, colors } = this.props;
    return (
      <Container>
        <NewHeader
          title="Comps Default Settings"
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        {this.checkIfNeedsToSave() ? (
          <ConfirmLabel
            style={{
              alignSelf: "stretch"
            }}
            icon="check"
            label={"Settings Changed"}
            primary={true}
            confirmButton={"Confirm Changes"}
            loading={this.state.saving_comps_settings}
            onPress={this.saveUpdates}
          />
        ) : null}
        <KeyboardView style={{ flex: 1 }}>
          <SelectBox
            select_ref={this._dominant_source_input}
            items={[
              {
                value: "mls",
                label: "MLS"
              },
              {
                value: "county_records",
                label: "County Records"
              }
            ]}
            disabled={false}
            value={this.state?.dominant_source}
            placeholder="Preferred Data Source"
            show={this.state.dominant_source_focus}
            onFocus={() => {
              this.setState({ dominant_source_focus: true });
            }}
            onBlur={() => {
              this.setState({ dominant_source_focus: false });
            }}
            onSelect={item => {
              this.setState({
                dominant_source: item
              });
            }}
            hide_search={true}
          />
          <SelectBox
            select_ref={this._date_range_input}
            items={[
              {
                value: "1_month",
                label: "1 month from today"
              },
              {
                value: "2_months",
                label: "2 months from today"
              },
              {
                value: "3_months",
                label: "3 months from today"
              },
              {
                value: "4_months",
                label: "4 months from today"
              },
              {
                value: "5_months",
                label: "5 months from today"
              },
              {
                value: "6_months",
                label: "6 months from today"
              },
              {
                value: "1_year",
                label: "1 year from today"
              },
              {
                value: "2_years",
                label: "2 years from today"
              }
            ]}
            disabled={false}
            value={this.state?.date_range_default}
            placeholder="Date Range"
            show={this.state.date_range_focus}
            onFocus={() => {
              this.setState({ date_range_focus: true });
            }}
            onBlur={() => {
              this.setState({ date_range_focus: false });
            }}
            onSelect={item => {
              this.setState({
                date_range_default: item
              });
            }}
            hide_search={true}
          />

          <SelectBox
            select_ref={this._sqft_range_input}
            items={[
              {
                value: "5",
                label: "5%"
              },
              {
                value: "10",
                label: "10%"
              },
              {
                value: "15",
                label: "15%"
              },
              {
                value: "20",
                label: "20%"
              },
              {
                value: "25",
                label: "25%"
              },
              {
                value: "30",
                label: "30%"
              }
            ]}
            disabled={false}
            value={this.state?.sqft_range_default}
            placeholder="Sqft Range"
            show={this.state.sqft_range_focus}
            onFocus={() => {
              this.setState({ sqft_range_focus: true });
            }}
            onBlur={() => {
              this.setState({ sqft_range_focus: false });
            }}
            onSelect={item => {
              this.setState({
                sqft_range_default: item
              });
            }}
            hide_search={true}
          />
          {/*
          <SelectBox
            select_ref={this._bedroom_range_input}
            items={[
              {
                value: "none",
                label: "None"
              },
              {
                value: "1",
                label: "1 bedroom"
              },
              {
                value: "2",
                label: "2 bedrooms"
              },
              {
                value: "3",
                label: "3 bedrooms"
              }
            ]}
            disabled={false}
            value={this.state?.bedroom_range_default}
            placeholder="Bedroom Range"
            show={this.state.bedroom_range_focus}
            onFocus={() => {
              this.setState({ bedroom_range_focus: true });
            }}
            onBlur={() => {
              this.setState({ bedroom_range_focus: false });
            }}
            onSelect={item => {
              this.setState({
                bedroom_range_default: item
              });
            }}
            hide_search={true}
          />

          <SelectBox
            select_ref={this._bathroom_range_input}
            items={[
              {
                value: "none",
                label: "None"
              },
              {
                value: "1",
                label: "1 bathrooms"
              },
              {
                value: "2",
                label: "2 bathrooms"
              },
              {
                value: "3",
                label: "3 bathrooms"
              }
            ]}
            disabled={false}
            value={this.state?.bathroom_range_default}
            placeholder="Bathroom Range"
            show={this.state.bathroom_range_focus}
            onFocus={() => {
              this.setState({ bathroom_range_focus: true });
            }}
            onBlur={() => {
              this.setState({ bathroom_range_focus: false });
            }}
            onSelect={item => {
              this.setState({
                bathroom_range_default: item
              });
            }}
            hide_search={true}
          />
          */}
          <SelectBox
            select_ref={this._year_built_range_input}
            items={[
              {
                value: "none",
                label: "None"
              },
              {
                value: "5",
                label: "5 years"
              },
              {
                value: "10",
                label: "10 years"
              },
              {
                value: "15",
                label: "15 years"
              },
              {
                value: "20",
                label: "20 years"
              },
              {
                value: "25",
                label: "25 years"
              },
              {
                value: "30",
                label: "30 years"
              }
            ]}
            disabled={false}
            value={this.state?.year_built_range_default}
            placeholder="Year Built Range"
            show={this.state.year_built_range_focus}
            onFocus={() => {
              this.setState({ year_built_range_focus: true });
            }}
            onBlur={() => {
              this.setState({ year_built_range_focus: false });
            }}
            onSelect={item => {
              this.setState({
                year_built_range_default: item
              });
            }}
            hide_search={true}
          />

          <SelectBox
            select_ref={this._range_miles_input}
            items={[
              {
                value: "0.5",
                label: "0.5 miles"
              },
              {
                value: "1",
                label: "1 mile"
              },
              {
                value: "1.5",
                label: "1.5 miles"
              },
              {
                value: "2",
                label: "2 miles"
              },
              {
                value: "2.5",
                label: "2.5 miles"
              },
              {
                value: "3",
                label: "3 miles"
              },
              {
                value: "3.5",
                label: "3.5 miles"
              },
              {
                value: "4",
                label: "4 miles"
              },
              {
                value: "4.5",
                label: "4.5 miles"
              },
              {
                value: "5",
                label: "5 miles"
              }
            ]}
            disabled={false}
            value={this.state?.range_miles_default}
            placeholder="Default Range (miles)"
            show={this.state.range_miles_focus}
            onFocus={() => {
              this.setState({ range_miles_focus: true });
            }}
            onBlur={() => {
              this.setState({ range_miles_focus: false });
            }}
            onSelect={item => {
              this.setState({
                range_miles_default: item
              });
            }}
            hide_search={true}
          />
        </KeyboardView>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, native, settings, lead, billing }) => {
  const { token, user } = auth;
  const { device, isMobile } = native;
  const { colors } = settings;
  const { comps_settings } = lead;

  return { token, user, device, isMobile, colors, comps_settings };
};

export default connect(mapStateToProps, {
  popSidePanel,
  updateUserSettings,
  updateCompsSettings
})(CompsDefaultSettings);
