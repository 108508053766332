import React, { Component } from "react";
import {
  Wrapper,
  Title,
  Button,
  Icon,
  Row,
  Copy
} from "app/NativeComponents/common";
import { NewHeader } from "app/NativeComponents/snippets";
import { determineDisplayProperty } from "app/NativeActions";

class Navbar extends Component {
  render() {
    const {
      colors,
      workflow,
      device,
      isMobile,
      isIphoneX,
      platform,

      photo_gallery
    } = this.props;

    return (
      <NewHeader
        leftButton={
          isMobile
            ? {
                onPress: () => this.props.popSidePanel(),
                icon: "arrow-back"
              }
            : null
        }
        rightButton={
          !isMobile
            ? {
                onPress: () => this.props.popSidePanel(),
                icon: "close"
              }
            : null
        }
        title="Photo Gallery"
        subtitle={
          "Viewing images for " +
          determineDisplayProperty(photo_gallery.property).property_address
        }
      />
    );
  }
}

export default Navbar;
