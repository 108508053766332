import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Copy,
  Title,
  Bold,
  Row,
  Wrapper,
  KeyboardView,
  Icon
} from "app/NativeComponents/common";
import {
  BottomNavBar,
  GhostButton,
  InlineTabs,
  NewHeader,
  SmallLabel
} from "app/NativeComponents/snippets";
import {
  popSidePanel,
  numberWithCommas,
  renderPrice,
  setActiveDialerContact
} from "app/NativeActions";
import moment from "moment";

import QueuedItems from "./QueuedItems";
import Calls from "./Calls";

class CallSession extends Component {
  constructor(props) {
    super(props);
    const panel_data =
      props.device == "mobile"
        ? props?.route?.params?.panel_data
        : props.panel_data;
    this.state = {
      call_session: panel_data?.call_session,
      call_session_tab: panel_data?.call_session?.completed
        ? "called"
        : "in_queue",
      call_time: null,
      isTimerActive: false
    };

    this.getCallSession = this.getCallSession.bind(this);
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.setCallTime();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.current_call_session) !==
        JSON.stringify(prevProps.current_call_session) &&
      this.props.current_call_session &&
      this.props.current_call_session.id == this.state.call_session.id
    ) {
      this.setState({
        call_session: this.props.current_call_session
      });
    }

    if (
      this.props.current_call_session &&
      this.props.current_call_session?.id == this.props.call_session?.id &&
      JSON.stringify(this.props.called_numbers) !==
        JSON.stringify(prevProps.called_numbers)
    ) {
      this.props.getCallSession({
        token: this.props.token,
        type: "get_current_call_session_count",
        call_session_id: this.state.call_session?.id,
        onLoading: () => {},
        onError: () => {},
        onSuccess: results => {
          if (results.call_session) {
            this.setState(
              {
                call_session: results
              },
              () => {}
            );
          }
        }
      });
    }
  }

  getCallSession() {
    this.props.getCallSession({
      token: this.props.token,
      type: "get_call_session",
      call_session_id: this.state.call_session?.id,
      onLoading: () => {},
      onError: () => {},
      onSuccess: results => {
        if (results.call_session) {
          this.setState(
            {
              call_session: results
            },
            () => {
              this.setCallTime();
            }
          );
        }
      }
    });
  }

  componentWillUnmount() {
    clearTimeout(this._call_time_interval);
  }

  stopTimer() {
    this.setState({ isTimerActive: false }, () => {
      clearTimeout(this._call_time_interval);
    });
  }

  setCallTime() {
    if (
      this.state.call_session &&
      this.state.call_session.paused == 0 &&
      this.state.call_session.completed == 0 &&
      this.state.call_session.ended_early == 0
    ) {
      var utc_time = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      var ms = moment(utc_time, "YYYY-MM-DD HH:mm:ss").diff(
        moment(this.state.call_session?.date_created, "YYYY-MM-DD HH:mm:ss")
      );
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      this.setState(
        {
          call_time: s
        },
        () => {
          clearTimeout(this._call_time_interval);
          this._call_time_interval = setTimeout(() => {
            this.setCallTime();
          }, 1000);
        }
      );
    } else if (this.state.call_session) {
      var utc_time = moment(this.state.call_session?.date_completed)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      var ms = moment(utc_time, "YYYY-MM-DD HH:mm:ss").diff(
        moment(this.state.call_session?.date_created, "YYYY-MM-DD HH:mm:ss")
      );
      var d = moment.duration(ms);
      var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

      this.setState({
        call_time: s
      });
    }
  }

  render() {
    const { colors } = this.props;
    const { call_session, call_time } = this.state;

    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <NewHeader
          title={
            "Dialer Session on " +
            moment(call_session.date_created).format("MMM Do, YYYY")
          }
          leftButton={{
            icon: "arrow-back",
            onPress: () => {
              this.handleBack();
            }
          }}
        />
        <KeyboardView style={{ flex: 1 }}>
          <Wrapper>
            <Row>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Total Calls Made:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>{numberWithCommas(call_session.total_calls)}</Bold>
                </Title>

                {!!call_session?.total_duration || !!call_time ? (
                  <Copy
                    style={{ fontSize: 12, color: colors.light_text_color }}
                  >
                    Total time:{" "}
                    {call_session?.completed
                      ? call_session?.total_duration
                      : call_time}
                  </Copy>
                ) : null}

                {call_session?.total_charged > 0 ? (
                  <Row>
                    <Copy
                      style={{ fontSize: 12, color: colors.light_text_color }}
                    >
                      Charged: {renderPrice(call_session?.total_charged)}
                      {" for "}
                      {call_session?.total_charged_minutes == 1
                        ? numberWithCommas(
                            call_session?.total_charged_minutes
                          ) + " min"
                        : numberWithCommas(
                            call_session?.total_charged_minutes
                          ) + " mins"}
                    </Copy>

                    {this.props.device === "desktop" ? (
                      <Wrapper
                        tooltipPlacement={"top"}
                        tooltip={
                          "For billing purposes every call is rounded up to the nearest minute. This is the total number of minutes you were charged for."
                        }
                        style={{ marginLeft: 5 }}
                      >
                        <Icon
                          icon={"help"}
                          color={colors.light_text_color}
                          size={12}
                        />
                      </Wrapper>
                    ) : null}
                  </Row>
                ) : null}
              </Wrapper>

              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Contact Rate:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>{call_session.contact_rate}%</Bold>
                </Title>
                <Copy style={{ fontSize: 12, color: colors.light_text_color }}>
                  {!!call_session.connected_calls
                    ? numberWithCommas(call_session.connected_calls)
                    : "--"}{" "}
                  connections
                </Copy>
              </Wrapper>
            </Row>

            <Row>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Appointment Set:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.appointment_set
                      ? numberWithCommas(call_session.appointment_set)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Hot Leads:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.hot_leads
                      ? numberWithCommas(call_session.hot_leads)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
            </Row>

            <Row>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Warm Leads:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.warm_leads
                      ? numberWithCommas(call_session.warm_leads)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Left Voicemail:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.left_voicemail
                      ? numberWithCommas(call_session.left_voicemail)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
            </Row>

            <Row>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Disconnected:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.disconnected
                      ? numberWithCommas(parseInt(call_session.disconnected))
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Wrong Number/Contact:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.wrong_number || call_session.wrong_contact
                      ? numberWithCommas(
                          parseInt(call_session.wrong_number) +
                            parseInt(call_session.wrong_contact)
                        )
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
            </Row>

            <Row>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Unqualified:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.unqualified
                      ? numberWithCommas(call_session.unqualified)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Not Interested:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.not_interested
                      ? numberWithCommas(call_session.not_interested)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
            </Row>

            <Row>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>No Answer:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.no_answer
                      ? numberWithCommas(call_session.no_answer)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
              <Wrapper style={{ flex: 1, padding: 15 }}>
                <Copy>Skipped:</Copy>
                <Title style={{ color: colors.active_color }}>
                  <Bold>
                    {!!call_session.skipped_calls
                      ? numberWithCommas(call_session.skipped_calls)
                      : "--"}
                  </Bold>
                </Title>
              </Wrapper>
            </Row>
          </Wrapper>

          {!this.state.call_session?.completed &&
          this.state.call_session.total_calls > 0 ? (
            <InlineTabs
              tab_type="underline"
              selected_tab={this.state.call_session_tab}
              tabs={[
                {
                  onPress: () => {
                    this.setState({
                      call_session_tab: "in_queue"
                    });
                  },
                  slug: "in_queue",
                  title: "In Queue"
                },
                {
                  onPress: () => {
                    this.setState({
                      call_session_tab: "called"
                    });
                  },
                  slug: "called",
                  title: "Called"
                }
              ]}
            />
          ) : this.state.call_session?.completed &&
            this.state.call_session.total_calls > 0 ? (
            <SmallLabel>Call History:</SmallLabel>
          ) : !this.state.call_session?.completed ? (
            <SmallLabel>In Queue:</SmallLabel>
          ) : null}

          {(this.state.call_session_tab == "in_queue" &&
            !this.state.call_session?.completed) ||
          (!this.state.call_session?.completed &&
            this.state.call_session.total_calls == 0) ? (
            <QueuedItems {...this.props} call_session={call_session} />
          ) : this.state.call_session_tab == "called" ||
            (this.state.call_session?.completed &&
              this.state.call_session.total_calls > 0) ? (
            <>
              <Calls {...this.props} call_session={call_session} />
            </>
          ) : null}
        </KeyboardView>

        {!this.state.call_session?.completed &&
        this.props.current_call_session &&
        this.props.current_call_session.id === this.state.call_session.id &&
        this.props.queued_numbers &&
        this.props.queued_numbers.length > 0 &&
        !this.props.active_dialer_contact ? (
          <BottomNavBar>
            <Wrapper style={{ padding: 25 }}>
              <Copy>
                <Bold>Continue Session?</Bold>
              </Copy>
              <Copy>
                Continue your session from the next lead in your queue.
              </Copy>
            </Wrapper>

            <GhostButton
              primary={true}
              button_type={"full"}
              onPress={() => {
                const { queued_numbers } = this.props;

                this.props.setActiveDialerContact({
                  contact: {
                    ...queued_numbers[0].contact,
                    selected_phone: {
                      number: queued_numbers[0].number,
                      type: queued_numbers[0].type,
                      do_not_call_flag: queued_numbers[0].do_not_call_flag,
                      owner: queued_numbers[0].owner
                    },
                    associated_lead: queued_numbers[0].property
                  }
                });
              }}
            >
              Continue
            </GhostButton>
          </BottomNavBar>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const {
    current_call_session,
    queued_numbers,
    active_dialer_contact,
    called_numbers
  } = dialer;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    current_call_session,
    queued_numbers,
    active_dialer_contact,
    called_numbers
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  setActiveDialerContact
})(CallSession);
