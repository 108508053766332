import React, { Component } from "react";
import { connect } from "react-redux";
import { Copy, Wrapper } from "app/NativeComponents/common";

import { List, SelectItem, SpinWrapper } from "app/NativeComponents/snippets";
import { getCallSession, renderDate, pushSidePanel } from "app/NativeActions";

import CallItem from "./CallItem";
class Calls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calls: [],
      calls_loading: false,
      calls_refreshing: false,
      calls_loaded_all: false,
      calls_limit: 25,
      calls_begin: 0
    };
  }

  handleBack() {
    this.props.popSidePanel();
  }

  componentDidMount() {
    this.getData({ load_type: "load" });
  }

  getData({ load_type = "load" }) {
    if (!this.state.calls_loading && !this.state.calls_refreshing) {
      this.setState(
        {
          calls_begin:
            load_type === "load" || load_type === "refresh"
              ? 0
              : this.state.calls_begin + this.state.calls_limit,
          calls: load_type === "refresh" ? [] : this.state.calls,
          calls_loading: true,
          calls_refreshing: load_type === "refresh"
        },
        () => {
          this.props.getCallSession({
            token: this.props.token,
            call_session_id: this.props.call_session?.id,
            limit: this.state.calls_limit,
            begin: this.state.calls_begin,
            type: "get_call_session_history",
            onLoading: () => {
              this.setState({ calls_loading: true });
            },
            onError: () => {
              this.setState({
                calls_loading: false,
                calls_refreshing: false
              });
            },
            onSuccess: results => {
              if (results?.call_history) {
                this.setState({
                  calls_loading: false,
                  calls_refreshing: false,
                  calls:
                    load_type === "load_more"
                      ? [...this.state.calls, ...results.call_history]
                      : results.call_history,
                  calls_loaded_all:
                    results.call_history?.length < this.state.calls_limit
                });
              }
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.current_call_session &&
      this.props.current_call_session?.id == this.props.call_session?.id &&
      JSON.stringify(this.props.called_numbers) !==
        JSON.stringify(prevProps.called_numbers)
    ) {
      this.getData({ load_type: "refresh" });
    }
  }

  componentWillUnmount() {}

  render() {
    const { colors } = this.props;
    const { calls, calls_loading, calls_refreshing, calls_loaded_all } =
      this.state;

    return (
      <>
        {(calls_loading && calls?.length === 0) || calls_refreshing ? (
          <SpinWrapper text={"Loading Calls"} />
        ) : calls.length === 0 ? (
          <Wrapper
            style={{
              padding: 25,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Copy>You have not made any calls.</Copy>
          </Wrapper>
        ) : (
          <List
            rowNumber={1}
            items={calls}
            infiniteScroll={true}
            itemStructure={({ item, index }) => {
              return (
                <CallItem
                  key={"call_" + index}
                  call={item}
                  pushSidePanel={this.props.pushSidePanel}
                />
              );
            }}
            canRefresh={false}
            onRefresh={() => {}}
            is_refreshing={false}
            canLoadMore={
              !calls_loaded_all && !calls_loading && calls?.length > 0
            }
            isLoadingMore={calls_loading && calls?.length > 0}
            onLoadMore={() => {
              if (!calls_loaded_all && calls_loading !== true) {
                this.getData({ load_type: "load_more" });
              }
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, settings, native, dialer }) => {
  const { token, user } = auth;

  const { colors } = settings;
  const { platform, device, isMobile } = native;
  const { current_call_session, called_numbers } = dialer;

  return {
    token,
    user,
    colors,
    device,
    isMobile,
    platform,
    current_call_session,
    called_numbers
  };
};

export default connect(mapStateToProps, {
  getCallSession,
  pushSidePanel
})(Calls);
