export const GET_WORKFLOW_INFO = "get_workflow_info";
export const GET_WORKFLOW_INFO_SUCCESS = "get_workflow_info_success";
export const GET_WORKFLOW_INFO_FAIL = "get_workflow_info_fail";

export const GET_WORKFLOW_ENROLLMENT_OBJECT = "get_enrollment_object";
export const GET_WORKFLOW_ENROLLMENT_OBJECT_SUCCESS =
  "get_enrollment_object_success";

export const GET_WORKFLOW_STEP_TYPES = "get_workflow_step_types";
export const GET_WORKFLOW_STEP_TYPES_SUCCESS =
  "get_workflow_step_types_success";

export const TOGGLE_WORKFLOW_DESIGNER = "toggle_workflow_designer";
export const SWITCH_WORKFLOW_TAB = "switch_workflow_tab";
export const SET_ACTIVE_WORKFLOW = "set_active_workflow";

export const GET_WORKFLOWS = "get_workflows";
export const GET_WORKFLOWS_SUCCESS = "get_workflows_success";
export const GET_WORKFLOWS_FAIL = "get_workflows_fail";
export const LOAD_MORE_WORKFLOWS = "load_more_workflows";
export const LOAD_MORE_WORKFLOWS_SUCCESS = "load_more_workflows_success";
export const REFRESH_WORKFLOWS = "refresh_workflows";

export const UPDATE_WORKFLOW = "update_workflow";
export const UPDATE_WORKFLOW_SUCCESS = "update_workflow_success";
export const UPDATE_WORKFLOW_FAIL = "update_workflow_fail";
export const CREATE_WORKFLOW_SUCCESS = "create_workflow_success";
export const REMOVE_WORKFLOW_SUCCESS = "remove_workflow_success";

export const GET_TESTING_CRITERIA = "get_testing_criteria";
export const GET_TESTING_CRITERIA_SUCCESS = "get_testing_criteria_success";

export const GET_WORKFLOW_HISTORY = "get_workflow_history";
export const GET_WORKFLOW_HISTORY_SUCCESS = "get_workflow_history_success";
export const GET_WORKFLOW_HISTORY_FAIL = "get_workflow_history_fail";
export const LOAD_MORE_WORKFLOW_HISTORY = "load_more_workflow_history";
export const LOAD_MORE_WORKFLOW_HISTORY_SUCCESS =
  "load_more_workflow_history_success";
export const REFRESH_WORKFLOW_HISTORY = "refresh_workflow_history";

export const SET_ACTIVE_WORKFLOW_HISTORY = "set_active_workflow_history";

export const RELOAD_WORKFLOW_HISTORY_SUCCESS =
  "reload_workflow_history_success";

export const GET_WORKFLOW_HISTORY_FOR_LEAD = "get_workflow_history_for_lead";
export const GET_WORKFLOW_HISTORY_FOR_LEAD_SUCCESS =
  "get_workflow_history_for_lead_success";

export const MANUALLY_ENROLL_LEAD_IN_WORKFLOW =
  "manually_enroll_lead_in_workflow";
export const MANUALLY_ENROLL_LEAD_IN_WORKFLOW_FAIL =
  "manually_enroll_lead_in_workflow_fail";
export const MANUALLY_ENROLL_LEAD_IN_WORKFLOW_SUCCESS =
  "manually_enroll_lead_in_workflow_success";

export const GET_BASIC_AUTOMATION = "get_basic_automation";
export const GET_BASIC_AUTOMATION_FAIL = "get_basic_automation_fail";
export const GET_BASIC_AUTOMATION_SUCCESS = "get_basic_automation_success";

export const UPDATE_BASIC_AUTOMATION = "update_basic_automation";
export const UPDATE_BASIC_AUTOMATION_FAIL = "update_basic_automation_fail";
export const UPDATE_BASIC_AUTOMATION_SUCCESS =
  "update_basic_automation_success";

export const GET_API_INFO = "get_api_info";
export const GET_API_INFO_FAIL = "get_basic_automation_fail";
export const GET_API_INFO_SUCCESS = "get_api_info_success";

export const UPDATE_API_INFO = "update_api_info";
export const UPDATE_API_INFO_FAIL = "update_basic_automation_fail";
export const UPDATE_API_INFO_SUCCESS = "update_api_info_success";

export const GET_ENROLLMENT_TEMPLATES = "get_enrollment_templates";
export const GET_ENROLLMENT_TEMPLATES_FAIL = "get_enrollment_templates_fail";
export const GET_ENROLLMENT_TEMPLATES_SUCCESS =
  "get_enrollment_templates_success";

export const GET_TOTAL_ENROLLMENT_COUNT = "get_total_enrollment_count";
export const GET_TOTAL_ENROLLMENT_COUNT_SUCCESS =
  "get_total_enrollment_count_success";
