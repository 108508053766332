import React, { Component } from "react";
import { Wrapper } from "app/NativeComponents/common";

import DarkMode from "./DarkMode";
import ViewPort from "./ViewPort";
import PartnerWrapper from "./PartnerWrapper";
import VisitorWrapper from "./VisitorWrapper";
import IntercomWrapper from "./IntercomWrapper";
import PageViewWrapper from "./PageViewWrapper";
import PodsightsWrapper from "./PodsightsWrapper";
import ProfitwellWrapper from "./ProfitwellWrapper";
import GoogleAnalyticsWrapper from "./GoogleAnalyticsWrapper";
import PusherWrapper from "app/DealMachineCore/plugins/PusherWrapper";

class Plugins extends Component {
  render() {
    return (
      <>
        <VisitorWrapper {...this.props} />
        <GoogleAnalyticsWrapper {...this.props} />
        <PageViewWrapper {...this.props} />
        <PartnerWrapper {...this.props} />
        <PusherWrapper />
        <IntercomWrapper {...this.props} />
        <ProfitwellWrapper {...this.props} />
        <PodsightsWrapper {...this.props} />
        <ViewPort {...this.props} />
        <DarkMode {...this.props} />
      </>
    );
  }
}

export default Plugins;
