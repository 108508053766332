import React, { Component } from "react";

import DiscoverAProperty from "./DiscoverAProperty";
import FindTheOwner from "./FindTheOwner";
import GetInTouch from "./GetInTouch";

import AutomatedFollowUps from "./AutomatedFollowUps";
import CustomizeYourStyle from "./CustomizeYourStyle";
import TrackYourMailers from "./TrackYourMailers";

import ManageYourDeals from "./ManageYourDeals";
import Notes from "./Notes";
import SearchAndTag from "./SearchAndTag";

import RealTimeDrivingRoutes from "./RealTimeDrivingRoutes";
import DriveSessionSummaries from "./DriveSessionSummaries";
import RouteFreshness from "./RouteFreshness";
import ExportEverything from "./ExportEverything";

class CustomFeature extends Component {
  render() {
    switch (this.props.feature.customFeatureSlug) {
      case "discover-a-property":
        return (
          <div className="dm-website-custom-feature-container">
            <DiscoverAProperty />
          </div>
        );

      case "find-the-owner":
        return (
          <div className="dm-website-custom-feature-container">
            <FindTheOwner />
          </div>
        );

      case "get-in-touch":
        return (
          <div className="dm-website-custom-feature-container">
            <GetInTouch />
          </div>
        );

      case "automated-follow-ups":
        return (
          <div className="dm-website-custom-feature-container">
            <AutomatedFollowUps />
          </div>
        );
      case "customize-your-style":
        return (
          <div className="dm-website-custom-feature-container">
            <CustomizeYourStyle />
          </div>
        );
      case "track-your-mailers":
        return (
          <div className="dm-website-custom-feature-container">
            <TrackYourMailers />
          </div>
        );

      case "manage-your-deals":
        return (
          <div className="dm-website-custom-feature-container">
            <ManageYourDeals />
          </div>
        );
      case "notes":
        return (
          <div className="dm-website-custom-feature-container">
            <Notes />
          </div>
        );
      case "search-and-tag":
        return (
          <div className="dm-website-custom-feature-container">
            <SearchAndTag />
          </div>
        );

      case "realtime-driving-routes":
        return (
          <div className="dm-website-custom-feature-container">
            <RealTimeDrivingRoutes />
          </div>
        );

      case "drive-session-summaries":
        return (
          <div className="dm-website-custom-feature-container">
            <DriveSessionSummaries />
          </div>
        );

      case "route-freshness":
        return (
          <div className="dm-website-custom-feature-container">
            <RouteFreshness />
          </div>
        );

      case "export-everything":
        return (
          <div className="dm-website-custom-feature-container">
            <ExportEverything />
          </div>
        );
      default:
        break;
    }
    return <div />;
  }
}

export default CustomFeature;
