export const GET_LEAD_CONVERSATIONS = "get_lead_conversations";
export const GET_LEAD_CONVERSATIONS_FAIL = "get_lead_conversations_fail";
export const GET_LEAD_CONVERSATIONS_SUCCESS = "get_lead_conversations_success";

export const UPDATE_TEAM_PHONE_NUMBER = "update_team_phone_number";
export const UPDATE_TEAM_PHONE_NUMBER_FAIL = "update_team_phone_number_fail";
export const UPDATE_TEAM_PHONE_NUMBER_SUCCESS =
  "update_team_phone_number_success";
export const CREATE_TEAM_PHONE_NUMBER_SUCCESS =
  "create_team_phone_number_success";

export const GET_TEAM_PHONE_NUMBERS = "get_team_phone_numbers";
export const GET_TEAM_PHONE_NUMBERS_FAIL = "get_team_phone_numbers_fail";
export const GET_TEAM_PHONE_NUMBERS_SUCCESS = "get_team_phone_numbers_success";

export const SEND_TEXT_MESSAGE = "send_text_message";
export const SEND_TEXT_MESSAGE_FAIL = "send_text_message_fail";
export const SEND_TEXT_MESSAGE_SUCCESS = "send_text_message_success";

export const READ_TEXT_MESSAGE = "read_text_message";
export const READ_TEXT_MESSAGE_FAIL = "read_text_message_fail";
export const READ_TEXT_MESSAGE_SUCCESS = "read_text_message_success";

export const POST_MESSAGE_NOTE = "post_message_note";
export const POST_MESSAGE_NOTE_FAIL = "post_message_note_fail";
export const POST_MESSAGE_NOTE_SUCCESS = "post_message_note_success";

export const SET_TO_PHONE_NUMBER = "set_to_phone_number";
export const UPDATE_CONVERSATION_FILTER = "update_conversation_filter";

export const GET_CONVERSATIONS = "get_conversations";
export const REFRESH_CONVERSATIONS = "refresh_conversations";
export const GET_CONVERSATIONS_FAIL = "get_conversations_fail";
export const GET_CONVERSATIONS_SUCCESS = "get_conversations_success";

export const SET_ACTIVE_CONVERSATION = "set_active_conversation";
export const SET_ACTIVE_CONVERSATION_FAIL = "set_active_conversation_fail";
export const SET_ACTIVE_CONVERSATION_SUCCESS =
  "set_active_conversation_success";

export const TOGGLE_CONVERSATION_LEAD_VIEW = "toggle_conversation_lead_view";

export const GET_SAVED_RESPONSES = "get_saved_responses";
export const GET_SAVED_RESPONSES_FAIL = "get_saved_responses_fail";
export const GET_SAVED_RESPONSES_SUCCESS = "get_saved_responses_success";

export const CREATE_SAVED_RESPONSE_SUCCESS = "create_saved_response_success";
export const UPDATE_SAVED_RESPONSE_SUCCESS = "update_saved_response_success";
export const REMOVE_SAVED_RESPONSE_SUCCESS = "remove_saved_response_success";

export const GET_MULTISEND_NUMBERS = "get_multisend_numbers";
export const GET_MORE_MULTISEND_NUMBERS = "get_more_multisend_numbers";
export const GET_MULTISEND_NUMBERS_FAIL = "get_multisend_numbers_fail";
export const GET_MULTISEND_NUMBERS_SUCCESS = "get_multisend_numbers_success";

export const SEND_MULTISEND_MESSAGE = "send_multisend_message";
export const SKIP_MULTISEND_NUMBER = "skip_multisend_number";

export const SET_MULTISEND_FILTERS = "set_multisend_fitlers";

export const UPDATE_MESSAGE_DISPLAY = "update_message_display";

export const GET_MULTISEND_NUMBERS_COUNT = "get_multisend_numbers_count";
export const GET_MULTISEND_NUMBERS_COUNT_FAIL =
  "get_multisend_numbers_count_fail";
export const GET_MULTISEND_NUMBERS_COUNT_SUCCESS =
  "get_multisend_numbers_count_success";

export const QUICK_UPDATE_CONVERSATION = "quick_update_conversation";

export const UPDATE_CONVERSATION = "update_conversation";
export const UPDATE_CONVERSATION_FAIL = "update_conversation_fail";
export const UPDATE_CONVERSATION_SUCCESS = "update_conversation_success";
export const CLOSE_CONVERSATION_SUCCESS = "close_conversation_success";

export const TOGGLE_ADD_PHONE_NUMBER = "toggle_add_phone_number";
export const TOGGLE_MULTISEND_MODAL = "toggle_multisend_modal";
