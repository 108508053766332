import React, { Component } from "react";
import { Wrapper, Copy } from "app/NativeComponents/common";
import GoogleFontLoader from "react-google-font-loader";

class LoadFonts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fonts: this.consolidateFonts(props.elements) || []
    };
  }

  componentDidMount() {
    if (this.props.elements) {
      this.consolidateFonts(this.props.elements);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.elements && this.props.elements !== prevProps.elements) {
      this.consolidateFonts(this.props.elements);
    }
  }

  consolidateFonts(elements) {
    let fonts = [];

    for (let i = 0; i < elements.length; i++) {
      let found_found = false;
      for (let j = 0; j < fonts.length; j++) {
        if (elements[i].font_family == fonts[j].font) {
          found_found = true;
        }
      }
      if (!found_found) {
        fonts.push({
          font: elements[i].font_family,
          weights: elements[i].font_family_variants
        });
      }
    }
    this.setState({
      fonts
    });
  }

  render() {
    if (this.state.fonts && this.state.fonts.length > 0) {
      return <GoogleFontLoader fonts={this.state.fonts} />;
    }
    return null;
  }
}

export { LoadFonts };
