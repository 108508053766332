import React, { PureComponent } from "react";
import { Row, Wrapper, Copy } from "app/NativeComponents/common";
import {
  UpsellButton,
  GhostButton,
  SmallHeader,
  BottomNavBar,
  InlineButton
} from "app/NativeComponents/snippets";
import AdvancedFilters from "app/DealMachineCore/reuseable/AdvancedFilters";
import ListName from "./ListName";
class ListBuilderFilters extends PureComponent {
  render() {
    const {
      colors,
      isMobile,
      filters,
      lists_info_banner,
      estimated_count,
      source_of_truth
    } = this.props;

    let editable = true;

    if (this.props.user?.user_version >= 6) {
      editable =
        source_of_truth?.subscription?.metadata?.list_builder_filters ==
          "unlimited" ||
        source_of_truth?.subscription?.metadata?.list_builder_filters >
          source_of_truth?.current_limits?.number_of_list_builder_filters
            ?.amount;
    } else {
      editable =
        this.props.list_builder_limits?.limit == -1 ||
        this.props.list_builder_limits?.limit >
          this.props.list_builder_limits?.used_filters?.length;
    }

    return (
      <>
        <AdvancedFilters
          filters={filters}
          use_list_builder_info={true}
          editable={editable}
          renderHeader={() => {
            return (
              <SmallHeader
                title={
                  this.props.filters.data.length == 0
                    ? "Select a filter:"
                    : "Selected filters:"
                }
                leftButton={
                  !isMobile
                    ? {
                        icon: "keyboard-arrow-left",
                        onPress: () => {
                          this.props.nextStep("templates");
                        }
                      }
                    : null
                }
              />
            );
          }}
          onSelecting={is_selecting => {}}
          onChange={this.props.updateFilters}
        />
        {isMobile ? (
          <BottomNavBar>
            <InlineButton
              button_type={"full"}
              disabled={filters.data.length == 0}
              primary={filters.data.length > 0}
              style={{
                backgroundColor:
                  filters.data.length > 0
                    ? colors.orange_color_muted
                    : "transparent"
              }}
              onPress={() => this.props.nextStep("map")}
              icon_right="arrow-forward"
            >
              Next: Choose your market
            </InlineButton>
          </BottomNavBar>
        ) : null}
      </>
    );
  }
}

export default ListBuilderFilters;
