import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Spinner, Page } from "app/NativeComponents/common";
import Property from "app/DealMachineCore/components/Property";
import { getSourceOfTruth } from "app/NativeActions";

class ShareableProperty extends Component {
  constructor(props) {
    super(props);

    this.state = { property: null };
  }

  componentDidMount() {
    this.props.getSourceOfTruth({ token: this.props.token });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    if (this.props.match?.params?.property_id) {
      return (
        <Page
          metaTitle={
            !!this.state.property?.property_address_full
              ? this.state.property?.property_address_full +
                " - DealMachine for Real Estate"
              : "DealMachine for Real Estate"
          }
        >
          <div className="dm-app-wrapper animated fadeIn deal-full-app">
            <Container>
              <Property
                panel_id={this.props.match?.params?.property_id}
                sharable={true}
                onPropertyLoad={property => {
                  this.setState({ property: property });
                }}
                expanded={
                  this.props.desktopMobile ? "not_expanded" : "expanded"
                }
              />
            </Container>
          </div>

          {this.props.source_of_truth_loading ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0
              }}
            >
              <div className="dm-app-wrapper animated fadeIn deal-full-app">
                <Container>
                  <Spinner />
                </Container>
              </div>
            </div>
          ) : null}
        </Page>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ auth, settings, native, billing }) => {
  const { token, user, init, loading } = auth;
  const { source_of_truth, source_of_truth_loading } = billing;
  const { colors } = settings;
  const { isMobile, signUpMobile, device, platform, desktopMobile } = native;

  return {
    token,
    user,
    init,
    loading,
    colors,
    isMobile,
    signUpMobile,
    device,
    platform,
    desktopMobile,
    source_of_truth,
    source_of_truth_loading
  };
};

export default connect(mapStateToProps, { getSourceOfTruth })(
  ShareableProperty
);
