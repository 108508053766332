import React, { Component } from "react";

import { Wrapper, Card, Row, Title } from "app/NativeComponents/common";
import StepTab from "./StepTab";

import StartStep from "./StartStep";
import SetGoalsStep from "./SetGoalsStep";
import SetGoalsEnterpriseStep from "./SetGoalsEnterpriseStep";

import GetTheAppStep from "./GetTheAppStep";
import BuildYourTeamStep from "./BuildYourTeamStep";
import AddPropertiesStep from "./AddPropertiesStep";
import BuildAListStep from "./BuildAListStep";
import ContactLeadsStep from "./ContactLeadsStep";
import FindAMentorStep from "./FindAMentorStep";
import WorkWithUsStep from "./WorkWithUsStep";
import DownloadApp from "../DownloadApp";
class Steps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_tab: props.is_enterprise
        ? props.user.current_enterprise_step
          ? props.user.current_enterprise_step
          : "start"
        : props.user.current_dashboard_step
        ? props.user.current_dashboard_step
        : "get_the_app",
      enterprise_tab_array: [
        {
          title: "Start",
          slug: "start",
          icon: "arrow-forward"
        },
        {
          title: "Set Goals",
          slug: "set_goals_enterprise",
          fa_icon: "bullseye"
        },
        {
          title: "Build Your Team",
          slug: "build_your_team",
          icon: "group"
        },
        {
          title: "Add Properties",
          slug: "add_properties",
          icon: "home"
        },
        {
          title: "Build A List",
          slug: "build_a_list",
          icon: "sort"
        },
        {
          title: "Contact Leads",
          slug: "contact_leads",
          icon: "mail-outline"
        },
        {
          title: "Work With Us",
          slug: "work_with_us",
          fa_icon: "handshake-o"
        }
      ]
    };
    this.updateTab = this.updateTab.bind(this);
  }

  updateTab(tab) {
    this.setState({
      active_tab: tab
    });

    this.props.scrollToTop();
  }

  componentDidMount() {}

  renderTabs() {
    let selected_array = this.props.is_enterprise
      ? this.state.enterprise_tab_array
      : [];
    let tab_array = [];
    if (this.props.dashboardMobile) {
      for (let i = 0; i < selected_array.length; i++) {
        if (selected_array[i].slug === this.state.active_tab) {
          if (i === 0) {
            tab_array = [
              selected_array[i],
              selected_array[i + 1],
              selected_array[i + 2]
            ];
          } else if (i === selected_array.length - 1) {
            tab_array = [
              selected_array[i - 2],
              selected_array[i - 1],
              selected_array[i]
            ];
          } else {
            tab_array = [
              selected_array[i - 1],
              selected_array[i],
              selected_array[i + 1]
            ];
          }
        }
      }
    } else {
      tab_array = selected_array;
    }

    return (
      <Row>
        {tab_array.map((tab, i) => {
          return (
            <StepTab
              key={i}
              title={tab.title}
              icon={tab.icon}
              fa_icon={tab.fa_icon}
              active={this.state.active_tab === tab.slug}
              onPress={() => this.updateTab(tab.slug)}
              colors={this.props.colors}
              dashboardMobile={this.props.dashboardMobile}
              show_tab={true}
            />
          );
        })}
      </Row>
    );
  }

  render() {
    if (
      this.props.is_enterprise &&
      this.props.user.completed_enterprise_steps === false
    ) {
      return (
        <Wrapper style={{ alignSelf: "stretch", flex: 1 }}>
          <Card style={{ flex: 1 }}>
            <Wrapper
              style={{
                backgroundColor: this.props.colors.background_color,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5
              }}
            >
              <Wrapper style={{ padding: 25 }}>
                <Title>Six Steps to Start Driving for Dollars</Title>
              </Wrapper>
              {this.renderTabs()}
            </Wrapper>
            <Wrapper style={{}}>
              <StartStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <SetGoalsStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <SetGoalsEnterpriseStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <GetTheAppStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <BuildYourTeamStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <AddPropertiesStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <BuildAListStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <ContactLeadsStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
              <WorkWithUsStep
                {...this.props}
                active_tab={this.state.active_tab}
                updateTab={this.updateTab}
              />
            </Wrapper>
          </Card>
        </Wrapper>
      );
    }
  }
}

export default Steps;
