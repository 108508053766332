import React from "react";
import {
  SwitchButton,
  CardBody,
  Split,
  Wrapper,
  Stretch,
  Title,
  Copy,
  Icon,
  CenterCenter
} from "app/NativeComponents/common";

import { AppConfig } from "app/NativeActions";

const renderCopy = (text, textComponents) => {
  if (text && text != "") {
    return <Copy>{text}</Copy>;
  } else if (textComponents) {
    return textComponents();
  }
};

const ToggleSwitch = ({
  style,
  title,
  text,
  textComponents = null,
  onChange,
  value,
  disabled
}) => {
  return (
    <CardBody style={style}>
      <Split>
        <Stretch>
          <Title>{title}</Title>
          {renderCopy(text, textComponents)}
        </Stretch>
        <Wrapper>
          <CenterCenter>
            <SwitchButton
              style={{ marginLeft: 10 }}
              value={!disabled ? value : null}
              onChange={value => {
                if (AppConfig().device == "mobile") {
                  onChange(value);
                } else {
                  onChange(!value);
                }
              }}
            />
          </CenterCenter>
        </Wrapper>
      </Split>
    </CardBody>
  );
};

export { ToggleSwitch };
