import React, { Component } from "react";

import {
  Wrapper,
  Card,
  Title,
  Copy,
  Bold,
  CardBody,
  PrimaryButton,
  LineBreak,
  ExternalImage,
  ContentfulRichText
} from "app/NativeComponents/common";
import { loadOnboardingText, appRedirect } from "app/NativeActions";

class DownloadApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding_text: null,
      loading: true
    };
  }

  componentDidMount() {
    loadOnboardingText("completedOnboardingDownloadApp")
      .then(data => {
        if (data && data.items) {
          if (data.items.length > 0) {
            this.setState({
              onboarding_text: data.items[0]
                ? data.items[0].fields
                  ? data.items[0].fields
                  : null
                : null,
              loading: false
            });
          }
        }
        this.setState(() => ({ loading: false }));
      })
      .catch(err => {
        this.setState(() => ({ loading: false }));
      });
  }

  shouldComponentUpdate(prevState) {
    if (
      prevState.onboarding_text !== this.state.onboarding_text ||
      prevState.loading !== this.state.loading
    ) {
      return true;
    }

    return false;
  }

  renderImage() {
    if (this.state.onboarding_text.image) {
      return (
        <ExternalImage
          style={{
            resizeMode: "contain",
            width: 150,
            height: 150,
            margin: 20
          }}
          contain={true}
          image={this.state.onboarding_text.image.fields.file.url}
        />
      );
    }
  }

  renderTitle() {
    if (
      this.state.onboarding_text.title &&
      this.state.onboarding_text.title != ""
    ) {
      return (
        <Title style={{ textAlign: "center" }}>
          {this.state.onboarding_text.title}
        </Title>
      );
    }
  }

  renderCopy() {
    if (
      this.state.onboarding_text.description &&
      this.state.onboarding_text.description != ""
    ) {
      return (
        <Copy style={{ textAlign: "center" }}>
          {this.state.onboarding_text.description}
        </Copy>
      );
    }
  }

  renderContent() {
    if (this.state.onboarding_text.richContent) {
      return (
        <ContentfulRichText
          style={{ textAlign: "center" }}
          richText={this.state.onboarding_text.richContent}
        />
      );
    }
  }

  render() {
    if (this.props.download_app == true) {
      return (
        <Wrapper>
          <CardBody style={{ alignItems: "center" }}>
            {this.renderTitle()}
            {this.renderImage()}
            {this.renderContent()}
          </CardBody>

          <Card style={{ borderRadius: 30, overflow: "hidden", flex: 0 }}>
            <PrimaryButton
              onPress={() => {
                this.props.appRedirect({
                  redirect: "trainingVideosOnboarding",
                  payload: { id: "complete" }
                });
              }}
            >
              Next
            </PrimaryButton>
          </Card>
        </Wrapper>
      );
    }

    return <Wrapper />;
  }
}

export default DownloadApp;
