import "array.prototype.flatmap";
import React, { Component } from "react";

import { Row, Scroll, Wrapper } from "app/NativeComponents/common";

class DummyScroll extends Component {
  render() {
    const { list_settings, colors } = this.props;
    return (
      <Row
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflow: "hidden"
        }}
      >
        <Wrapper
          style={{
            backgroundColor: colors.card_color,
            borderRightWidth: 1,
            borderRightColor: "transparent",
            borderRightStyle: "solid",

            borderBottomWidth: 1,
            borderBottomColor: "transparent",
            borderBottomStyle: "solid"
          }}
        >
          <Wrapper
            style={{
              width: 60,

              height: 10,

              alignItems: "center",
              justifyContent: "center"
            }}
          ></Wrapper>
        </Wrapper>

        <Wrapper
          style={{
            backgroundColor: colors.card_color,
            borderRightWidth: 1,
            borderRightColor: "transparent",
            borderRightStyle: "solid",

            borderBottomWidth: 1,
            borderBottomColor: "transparent",
            borderBottomStyle: "solid"
          }}
        >
          <Wrapper
            style={{
              width: 60,
              height: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          ></Wrapper>
        </Wrapper>

        {list_settings &&
          list_settings.user_list_columns.map((column, i) => {
            if (column.main_column) {
              return (
                <Wrapper
                  key={"dummy_column_" + i}
                  style={{ width: column.width }}
                >
                  <Wrapper
                    style={{
                      overflow: "hidden",
                      width: column.width,
                      minWidth: column.main_column ? 120 : 80,
                      maxWidth: 500
                    }}
                  >
                    <>
                      <Wrapper
                        style={{
                          padding: 10,
                          paddingBottom: 0,
                          paddingTop: 0,
                          height: 10,
                          borderRightWidth: 1,
                          borderRightColor: colors.border_color,
                          borderRightStyle: "solid",
                          borderBottomWidth: 1,
                          borderBottomColor: "transparent",
                          borderBottomStyle: "solid",
                          backgroundColor: colors.card_color,
                          alignItems: "flex-start",
                          justifyContent: "center"
                        }}
                      ></Wrapper>
                    </>
                  </Wrapper>
                </Wrapper>
              );
            }
            return null;
          })}
        <Scroll
          scroll_ref={this.props._dummy_scroll_view}
          horizontal
          style={{
            width: document.getElementById("scrollCols")
              ? document.getElementById("scrollCols").clientWidth
              : "auto",
            scrollBehavior: "initial"
          }}
          onScroll={e => {
            requestAnimationFrame(() => {
              this.props.dummyScroll(
                this.props._dummy_scroll_view.current.scrollLeft
              );
            });
          }}
        >
          <Row
            style={{
              justifyContent: "flex-start",
              alignItems: "flex-start"
            }}
          >
            {this.props.renderColumns({
              renderItem: ({ key, item }) => {
                if (!item.main_column && item.show) {
                  return (
                    <Wrapper
                      key={"dummy_key_" + key}
                      style={{ width: item.width }}
                    >
                      <Wrapper
                        style={{
                          overflow: "hidden",
                          width: item.width,
                          minWidth: item.main_column ? 120 : 80,
                          maxWidth: 500
                        }}
                      >
                        <>
                          <Wrapper
                            style={{
                              padding: 10,
                              paddingTop: 0,
                              paddingBottom: 0,
                              height: 10,
                              borderRightWidth: 1,
                              borderRightColor: "transparent",
                              borderRightStyle: "solid",
                              borderBottomWidth: 1,
                              borderBottomColor: "transparent",
                              borderBottomStyle: "solid",
                              backgroundColor: colors.card_color,
                              alignItems: "flex-start",
                              justifyContent: "center"
                            }}
                          ></Wrapper>
                        </>
                      </Wrapper>
                    </Wrapper>
                  );
                }
                return null;
              }
            })}
          </Row>
        </Scroll>
      </Row>
    );
  }
}

export default DummyScroll;
