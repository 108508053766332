import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Button,
  Icon,
  Copy,
  DropzoneButton,
  Bold
} from "app/NativeComponents/common";
import {
  GhostButton,
  IconButton,
  InlineButton
} from "app/NativeComponents/snippets";
import { dismissMobileKeyboard, randomString } from "app/NativeActions";

class BottomButtons extends Component {
  render() {
    return (
      <Row>
        <Wrapper style={{ flex: 1 }}>
          <Row>
            <IconButton
              onPress={() => {
                this.props.updateMessageProp({
                  prop: "mention_tracker",
                  value: !this.props.mention_tracker
                });
              }}
              tooltip={"Mention Team Member"}
              tooltipPlacement={"top"}
              icon="alternate-email"
            />
            <Wrapper style={{ alignSelf: "stretch", justifyContent: "center" }}>
              <DropzoneButton
                style={{
                  height: "auto",
                  alignSelf: "stretch",
                  cursor: "pointer"
                }}
                multiple={true}
                accept=""
                upload_type="file"
                onDrop={acceptedFiles => {
                  for (let i = 0; i < acceptedFiles.length; i++) {
                    const file = acceptedFiles[i];
                    const d = new Date();
                    let unique_string = d.getTime();
                    unique_string = unique_string + randomString(10) + ".jpeg";

                    this.props.uploadActivityFile({
                      acceptedFiles: file,
                      token: this.props.token,
                      type: "activity_file",
                      payload: {
                        unique_string
                      },
                      onLoading: () => {
                        this.props.addFilesLoading({
                          title: file.name,
                          unique_string,
                          progress: 0
                        });
                      },
                      onSuccess: results => {
                        this.props.addToFiles({
                          unique_string,
                          title: file.name,
                          file: results.file
                        });
                      },
                      onError: error => {
                        this.props.updateFilesLoading({
                          unique_string,
                          error
                        });
                      },
                      onProgress: progress => {
                        this.props.updateFilesLoading({
                          unique_string,
                          progress
                        });
                      }
                    });
                  }
                }}
                renderComponent={options => {
                  return (
                    <InlineButton
                      noPress={true}
                      pressedIn={options.hovering}
                      tooltip={"Attach Files"}
                      tooltipPlacement={"top"}
                      icon="attach-file"
                    >
                      Attach
                    </InlineButton>
                  );
                }}
              />
            </Wrapper>
          </Row>
        </Wrapper>
        <GhostButton
          primary={true}
          disabled={this.props.disabled}
          loading={this.props.loading}
          onPress={() => {
            dismissMobileKeyboard();

            this.props.onSubmit({
              message: this.props.message,
              onSuccess: () => {
                this.props.updateMessageProp({
                  prop: "message",
                  value: ""
                });
              }
            });
          }}
        >
          {this.props.button_text}
        </GhostButton>
      </Row>
    );
  }
}

export default BottomButtons;
