import React, { Component } from "react";

import {
  Wrapper,
  Row,
  Title,
  Copy,
  Bold,
  SwitchButton,
  Spin
} from "app/NativeComponents/common";
import {
  ConfirmInputButton,
  SelectItem,
  SelectBox,
  SmallLabel
} from "app/NativeComponents/snippets";

class AutomationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basic_automation: props.basic_automation,
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.basic_automation &&
      this.props.basic_automation !== prevProps.basic_automation
    ) {
      this.setState({
        basic_automation: this.props.basic_automation,
        loading: false
      });
    }
  }

  updateAutomation({
    basic_automation,
    onLoading = () => {},
    onSuccess = () => {},
    onError = () => {}
  }) {
    this.setState({
      basic_automation
    });
    this.props.updateBasicAutomation({
      token: this.props.token,
      slug: basic_automation.slug,
      automation_on: basic_automation.automation_on,
      automation_data: basic_automation.automation_data,
      onLoading: () => {
        this.setState({
          loading: true
        });
        if (onLoading) {
          onLoading();
        }
      },
      onError: () => {
        this.setState({
          loading: false
        });

        if (onError) {
          onError();
        }
      },
      onSuccess: () => {
        this.setState({
          loading: false
        });

        if (onSuccess) {
          onSuccess();
        }
      }
    });
  }

  render() {
    const { token, colors, device, lead_statuses } = this.props;
    const { loading, basic_automation } = this.state;
    return (
      <>
        <Wrapper
          style={
            basic_automation.slug == "change_lead_status_on_campaign_update" &&
            lead_statuses.length > 0 &&
            basic_automation.automation_on
              ? {
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: colors.border_color,
                  margin: 5,
                  borderRadius: 5
                }
              : {}
          }
        >
          <SelectItem
            select_type="toggle"
            selected={basic_automation.automation_on}
            description={basic_automation.description}
            loading={loading}
            onPress={() => {
              let ba = JSON.parse(JSON.stringify(basic_automation));
              ba.automation_on = !basic_automation.automation_on;
              this.setState({
                basic_automation: ba
              });
              this.updateAutomation({
                basic_automation: ba
              });
            }}
          >
            {basic_automation.title}
          </SelectItem>
          {/*
          <Wrapper style={{ position: "relative" }}>
            {basic_automation.slug == "change_lead_status_on_campaign_update" &&
            lead_statuses.length > 0 &&
            basic_automation.automation_on ? (
              <>

                <SmallLabel
                  style={{ padding: 25, paddingTop: 10, paddingBottom: 10 }}
                >
                  Automation Options:
                </SmallLabel>
                <SelectBox
                  items={lead_statuses}
                  disabled={this.state.loading}
                  value={basic_automation?.automation_data?.on_campaign_start}
                  placeholder="On Mail Start Change Status To:"
                  onSelect={item => {
                    let ba = JSON.parse(JSON.stringify(basic_automation));
                    ba.automation_data.on_campaign_start = item;
                    this.setState({
                      basic_automation: ba
                    });
                    this.updateAutomation({
                      basic_automation: ba
                    });
                  }}
                />

                <SelectBox
                  items={lead_statuses}
                  disabled={this.state.loading}
                  value={basic_automation?.automation_data?.on_campaign_pause}
                  placeholder="On Mail Pause Change Status To:"
                  onSelect={item => {
                    let ba = JSON.parse(JSON.stringify(basic_automation));
                    ba.automation_data.on_campaign_pause = item;
                    this.setState({
                      basic_automation: ba
                    });
                    this.updateAutomation({
                      basic_automation: ba
                    });
                  }}
                />

                <SelectBox
                  items={lead_statuses}
                  disabled={this.state.loading}
                  value={basic_automation?.automation_data?.on_campaign_end}
                  placeholder="On Mail End Change Status To:"
                  onSelect={item => {
                    let ba = JSON.parse(JSON.stringify(basic_automation));
                    ba.automation_data.on_campaign_end = item;
                    this.setState({
                      basic_automation: ba
                    });
                    this.updateAutomation({
                      basic_automation: ba
                    });
                  }}
                />
              </>

            ) : null}
          </Wrapper>*/}
        </Wrapper>
      </>
    );
  }
}

export default AutomationItem;
