import React, { Component } from "react";
import { connect } from "react-redux";
import { KeyboardView } from "app/NativeComponents/common";
import {
  SpinWrapper,
  List,
  AttentionBox,
  FocusModal
} from "app/NativeComponents/snippets";
import { popSidePanel, getMailerTemplates } from "app/NativeActions";
import Design from "./Design";
class SelectDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getMailerTemplates({
      token: this.props.token,
      load_all: true
    });
  }

  render() {
    const { isMobile, colors, mailer_templates, mailer_templates_loading } =
      this.props;
    const panel_data =
      this.props.device == "mobile"
        ? this.props?.route?.params?.panel_data
        : this.props.panel_data;
    return (
      <FocusModal
        title={panel_data.title}
        onClose={() => {
          this.props.popSidePanel();
        }}
        show_side_bar={false}
        renderMainView={() => {
          if (mailer_templates_loading) {
            return <SpinWrapper text="Loading designs..." />;
          }
          return (
            <>
              {/*
                //maybe bring this back?
                    <AttentionBox
                title={"Select a design"}
                description={"Choose a design to create your postcard."}
              />
                     */}
              <List
                rowNumber={1}
                innerStyle={
                  isMobile
                    ? { flex: 1 }
                    : {
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: 20
                      }
                }
                items={mailer_templates}
                infiniteScroll={true}
                itemStructure={({ item, index }) => {
                  return (
                    <Design
                      mailer={item}
                      key={"design_" + index}
                      selectDesignTemplate={
                        panel_data?.onSuccess
                          ? () => panel_data?.onSuccess(item?.id)
                          : () => {}
                      }
                      isMobile={isMobile}
                      colors={colors}
                    />
                  );
                }}
                canRefresh={false}
                onRefresh={() => {}}
                canLoadMore={false}
                isLoadingMore={false}
                onLoadMore={() => {}}
              />
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = ({ auth, native, settings, mailer }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const {
    mailer_templates,
    mailer_template_count,
    default_bracket_variables,
    mailer_templates_begin,
    mailer_templates_limit,
    mailer_templates_loading,
    mailer_templates_refreshing,
    mailer_templates_error,
    mailer_templates_loaded_all
  } = mailer;
  return {
    token,
    user,
    device,
    isMobile,
    platform,
    colors,
    mailer_templates,
    mailer_template_count,
    default_bracket_variables,
    mailer_templates_begin,
    mailer_templates_limit,
    mailer_templates_loading,
    mailer_templates_refreshing,
    mailer_templates_error,
    mailer_templates_loaded_all
  };
};

export default connect(mapStateToProps, {
  popSidePanel,
  getMailerTemplates
})(SelectDesign);
