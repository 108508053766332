export const SET_NAVBAR = "set_navbar";
export const START_REDIRECT = "start_redirect";
export const SET_INITIAL_REDIRECT = "SET_INITIAL_REDIRECT";
export const END_REDIRECT = "end_redirect";
export const IOS_BROWSER_CHECK = "ios_browser_check";

export const TEXT_LINK = "text_link";
export const TEXT_LINK_SUCCESS = "text_link_success";
export const TEXT_LINK_FAIL = "text_link_fail";
export const UPDATE_VIEWPORT = "update_viewport";
export const MOBILE_TOGGLE_DRAWER = "mobile_toggle_drawer";
export const TRIGGER_INTERCOM = "trigger_intercom";
export const BYPASS_MOBILE_REDIRECT = "bypass_mobile_redirect";
