import React, { PureComponent } from "react";
import {
  Wrapper,
  Button,
  Copy,
  Bold,
  Row,
  Icon,
  Spin,
  Input,
  Scroll
} from "app/NativeComponents/common";
import { SelectBox } from "app/NativeComponents/snippets";
import { store } from "app/store";
import axios from "axios";

class FontSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      api_key: "AIzaSyAGepjyt5-YLnjYO3j4QosGbRmWBdDE2VY",
      loading_fonts: false,
      fonts: [],
      filtered_fonts: [],
      selected_font: props.font_family || "Open Sans",
      font_search: "",
      show_fonts: false,
      font_select_focus: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.entireFont &&
      this.state.entireFont !== prevState.entireFont
    ) {
      this.props.onChange(this.state.entireFont);
    }
  }

  componentDidMount() {
    this.getFonts();
  }

  getFonts() {
    const { api_key } = this.state;

    this.setState({ loading_fonts: true, fonts: [], filtered_fonts: [] });
    let url =
      "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=" +
      api_key;
    axios
      .get(url)
      .then(response => {
        if (response.problem != null) {
          this.setState({ loading_fonts: false });
        } else {
          let fonts = [];

          for (let i = 0; i < response?.data?.items?.length; i++) {
            fonts.push({
              value: response?.data?.items[i].family,
              label: response?.data?.items[i].family,
              font: response?.data?.items[i]
            });
          }
          this.setState({
            loading_fonts: false,
            fonts: fonts
          });
        }
      })
      .catch(error => {
        this.setState({ loading_fonts: false });
      });
  }

  render() {
    const { loading_fonts, selected_font, fonts } = this.state;

    return (
      <SelectBox
        loading={loading_fonts}
        select_ref={this._text_align}
        items={fonts}
        value={selected_font}
        placeholder="Select a font:"
        show={this.state.font_select_focus}
        onFocus={() => {
          this.setState({ font_select_focus: true });
        }}
        onBlur={() => {
          this.setState({ font_select_focus: false });
        }}
        onSelect={item => {
          this.setState({
            selected_font: item,
            font_select_focus: false
          });

          for (let i = 0; i < fonts.length; i++) {
            if (fonts[i].value == item && this.props.onChange) {
              this.props.onChange(fonts[i].font);
            }
          }
        }}
        onSubmitEditing={() => {}}
      />
    );
  }
}

export { FontSelector };
