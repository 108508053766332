import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Wrapper, Button } from "app/NativeComponents/common";
import { CloseButton } from "app/NativeComponents/snippets";

import { store } from "app/store";

class PullUpSheet extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.show !== prevProps.show &&
      this.props.show !== this.state.show
    ) {
      this.setState({
        show: this.props.show
      });
    } else if (
      this.state.show !== prevState.show &&
      this.state.show !== this.props.show
    ) {
      if (this.state.show) {
        if (this.props.onShow) {
          this.props.onShow();
        }
      } else {
        if (this.props.onHide) {
          this.props.onHide();
        }
      }
    }
  }

  togglePanel(show) {
    this.setState({
      show
    });
  }

  render() {
    const {
      style,
      cardStyle,
      onShow = () => {},
      onHide = () => {},
      desktop_bottom = 0,
      desktop_top = "70vh",
      show = true,
      increase_height_on_keyboard = true,
      includeCloseButton = false,
      scroll_to_hide = true,
      noHandle = false,
      renderHandle = null
    } = this.props;
    const colors = store.getState().settings.colors;
    const { keyboard_showing } = this.props;
    return (
      <div
        className="deal-sliding-panel closed"
        style={{
          backgroundColor: colors.popover_color
        }}
      >
        {!noHandle ? (
          <>
            <Button
              noFeedback
              onPress={() => this.togglePanel(!this.state.show)}
            >
              {renderHandle ? renderHandle() : null}
            </Button>
          </>
        ) : (
          <>{renderHandle ? renderHandle() : null}</>
        )}

        <Wrapper
          className="dm-activity-slider"
          style={{
            height: this.state.show ? desktop_top : desktop_bottom,
            overflow: "scroll"
          }}
        >
          {this.props.children}
        </Wrapper>
        {includeCloseButton ? (
          <CloseButton
            style={{}}
            onPress={() => {
              this.togglePanel(false);
            }}
          />
        ) : null}
      </div>
    );
  }
}

export { PullUpSheet };
