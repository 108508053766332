import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "app/NativeComponents/common";
import SavedFilters from "./SavedFilters";

class AdvancedFilters extends Component {
  render() {
    const { device, colors } = this.props;
    return (
      <Container
        style={{
          display: "flex",
          alignSelf: "stretch",
          backgroundColor: colors.card_color
        }}
      >
        <SavedFilters />
      </Container>
    );
  }
}

const mapStateToProps = ({ settings, native }) => {
  const { colors } = settings;
  const { platform, device, isMobile } = native;
  return {
    colors,
    device,
    isMobile,
    platform
  };
};

export default connect(mapStateToProps, {})(AdvancedFilters);
