import React, { Component } from "react";
import {
  Wrapper,
  Row,
  Scroll,
  KeyboardView
} from "app/NativeComponents/common";

import TriggerStep from "./TriggerStep";
import BranchStep from "./BranchStep";
import DelayStep from "./DelayStep";
import SelectStepType from "./SelectStepType";
import WebhookStep from "./WebhookStep";
import InternalCommunicationStep from "./InternalCommunicationStep";
import SkipTraceStep from "./SkipTraceStep";
import NoteStep from "./NoteStep";
import StatusStep from "./StatusStep";
import ListStep from "./ListStep";
import TagStep from "./TagStep";
import CampaignStep from "./CampaignStep";
import TaskStep from "./TaskStep";
import AssignLeadStep from "./AssignLeadStep";
import CustomFieldsStep from "./CustomFieldsStep";
import ExportStep from "./ExportStep";
class WorkflowStepEditor extends Component {
  renderStepType(active_step) {
    switch (active_step?.type) {
      default:
        break;

      case "enrollment_triggers":
        return <TriggerStep {...this.props} />;

      case "filter_branch":
        return <BranchStep {...this.props} />;

      case "delay":
      case "delay_set_amount_of_time":
      case "delay_until_day_or_time":
        return <DelayStep {...this.props} />;

      case "trigger_webhook":
        return <WebhookStep {...this.props} />;

      case "send_internal_email":
      case "send_internal_push_notification":
        return <InternalCommunicationStep {...this.props} />;

      case "skip_trace":
        return <SkipTraceStep {...this.props} />;

      case "create_note":
        return <NoteStep {...this.props} />;

      case "change_lead_status":
        return <StatusStep {...this.props} />;

      case "add_to_lists":
      case "remove_from_lists":
        return <ListStep {...this.props} />;

      case "add_tags":
      case "remove_tags":
        return <TagStep {...this.props} />;

      case "start_campaign":
      case "pause_campaign":
      case "end_campaign":
        return <CampaignStep {...this.props} />;

      case "create_task":
        return <TaskStep {...this.props} />;

      case "trigger_export":
        return <ExportStep {...this.props} />;

      case "assign_lead":
        return <AssignLeadStep {...this.props} />;

      case "update_custom_fields":
        return <CustomFieldsStep {...this.props} />;
    }
  }
  render() {
    const { colors, device, isMobile, active_step, add_step_type } = this.props;
    if (active_step) {
      return this.renderStepType(active_step);
    } else if (add_step_type) {
      return <SelectStepType {...this.props} add_step_type={add_step_type} />;
    }

    return null;
  }
}

export default WorkflowStepEditor;
