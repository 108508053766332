import React, { PureComponent } from "react";
import { Wrapper } from "app/NativeComponents/common";

class DarkMode extends PureComponent {
  detectDarkMode() {
    if (this.props.user) {
      if (this.props.user.user_settings) {
        this.props.setDarkMode(this.props.user.user_settings.dark_mode);
      }
    } else {
      const dark_mode = window.localStorage.getItem("dark_mode");
      this.props.setDarkMode(dark_mode);
    }
  }

  componentDidMount() {
    this.detectDarkMode();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && !prevProps.user) {
      this.detectDarkMode();
    }
  }

  render() {
    return null;
  }
}

export default DarkMode;
