import React, { Component } from "react";
import { connect } from "react-redux";

import {
  ExternalImage,
  InternalImage,
  Icon,
  Button,
  Title,
  Wrapper
} from "app/NativeComponents/common";

import {
  AppConfig,
  checkIfUserHasBillingAddon,
  checkIfUserHasMetadata,
  checkIfHasGoogleStreetPic
} from "app/NativeActions";
import { store } from "app/store";

class LeadMainPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failedGoogleStreetPic: false,
      checked_pic: false
    };

    this.checkPic = this.checkPic.bind(this);
    this.getPropertyImageUrl = this.getPropertyImageUrl.bind(this);
  }
  componentDidMount() {
    if (this.props.property) {
      this.checkPic();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.property?.property_id != prevProps.property?.property_id) {
      this.checkPic();
    }
  }

  checkPic() {
    const { property, billing_addons, user } = this.props;

    if (
      (((user?.user_version < 6 &&
        checkIfUserHasBillingAddon({
          billing_addons: billing_addons,
          slug: "street_view"
        })) ||
        (user?.user_version >= 6 && checkIfUserHasMetadata("street_pic"))) &&
        !this.props.property?.deal?.failed_street_pic) ||
      !this.props.property?.deal?.tried_street_pic
    ) {
      checkIfHasGoogleStreetPic({
        address: !!this.props.property?.property_address
          ? this.props.property?.property_address_full
          : null,
        coordinates: this.props.property?.location
          ? this.props.property?.location
          : null,
        onSuccess: results => {
          this.setState({
            checked_pic: true,
            failedGoogleStreetPic: results.status == "OK" ? false : true
          });
        }
      });
    } else {
      this.setState({
        checked_pic: true
      });
    }
  }

  getPropertyImageUrl() {
    const { property } = this.props;
    const billing_addons = store.getState().billing.billing_addons;
    const user = store.getState().auth.user;
    if (
      property &&
      ((property?.deal && property?.deal?.removed != 1) ||
        (!!property?.image && !property?.lead_removed))
    ) {
      if (!!property?.image || !!property?.deal?.image) {
        if (
          (property?.deal?.from_google_street_view == 1 &&
            !this.state.failedGoogleStreetPic &&
            !this.props.property?.deal?.failed_street_pic) ||
          property?.deal?.from_google_street_view != 1
        ) {
          return property?.deal?.image;
        } else if (!!property?.image) {
          return property?.image;
        }
      }
    }

    if (property) {
      if (!!property.image) {
        return property.image;
      } else if (
        !this.state.failedGoogleStreetPic &&
        !!property.streetview_image &&
        ((user?.user_version < 6 &&
          checkIfUserHasBillingAddon({
            billing_addons: billing_addons,
            slug: "street_view"
          })) ||
          (user?.user_version >= 6 && checkIfUserHasMetadata("street_pic"))) &&
        this.props.image_toggle !== "satellite"
      ) {
        return property.streetview_image;
      }

      return property.satellite_image;
    }
    return "";
  }

  render() {
    const {
      height = 200,
      width = "100%",
      property,
      style,
      imageStyle
    } = this.props;
    const colors = store.getState().settings.colors;
    if (property) {
      return (
        <Wrapper
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: height,
            width: width,
            borderRadius: 5,
            margin: 5,
            alignSelf: "stretch",
            backgroundColor: colors.background_color,
            ...style
          }}
        >
          {!this.props.property?.deal?.image &&
          (!!this.props.property?.uploading_image ||
            !!this.props.property?.deal?.uploading_image) ? (
            <InternalImage
              style={{
                height: height,
                width: width,

                alignSelf: "stretch",
                backgroundColor: colors.gray_color,
                borderRadius: 5,
                ...imageStyle
              }}
              source={{
                uri: !!this.props.property?.deal?.uploading_image
                  ? this.props.property?.deal?.uploading_image
                  : this.props.property?.uploading_image
              }}
            />
          ) : this.state.checked_pic ? (
            <ExternalImage
              style={{
                height: height,
                width: width,

                alignSelf: "stretch",
                backgroundColor: colors.gray_color,
                borderRadius: 5,
                ...imageStyle
              }}
              image={this.getPropertyImageUrl()}
            />
          ) : null}
        </Wrapper>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ auth, settings, billing }) => {
  const { token, user } = auth;
  const { colors } = settings;
  const { billing_addons, source_of_truth } = billing;
  return {
    token,
    colors,
    user,
    billing_addons,
    source_of_truth
  };
};

export default connect(mapStateToProps, {})(LeadMainPhoto);
