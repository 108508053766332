import React from "react";
import { Row, Wrapper, Copy, Icon, Spin } from "app/NativeComponents/common";

import { store } from "app/store";
import ButtonWrapper from "./ButtonWrapper";
const Tag = props => {
  const {
    children,
    primary,
    style,
    innerStyle,
    disabled = false,
    deleteButton = false,
    icon,
    fa_icon,
    icon_right,
    fa_icon_right,
    pressedIn = false, //pressed in override from props
    loading = false,
    button_type = "normal",
    fontSize = 10,
    selected = false,
    icon_left = null
  } = props;
  const colors = store.getState().settings.colors;

  return (
    <ButtonWrapper
      {...props}
      renderInner={({
        hovering /*actual pressed in from state of button wrapper*/
      }) => {
        return (
          <Wrapper
            style={{
              borderRadius: 30,

              backgroundColor: selected
                ? colors.active_color_muted
                : colors.card_color,
              borderColor: selected ? colors.active_color : colors.border_color,
              borderWidth: 1,
              borderStyle: "solid",
              margin: 5,
              marginLeft: 0,
              marginTop: 0,
              ...style
            }}
          >
            <Wrapper
              style={{
                padding: 10,
                paddingRight: 15,
                paddingLeft: 15,
                borderRadius: 30,
                backgroundColor: hovering ? colors.hover_color : "transparent",
                ...innerStyle
              }}
            >
              <Row>
                {loading && !!icon_left ? (
                  <Wrapper style={{ marginRight: 5, height: 12 }}>
                    <Spin size="small" style={{ fontSize: fontSize }} />
                  </Wrapper>
                ) : !!icon_left ? (
                  <Icon
                    icon={icon_left}
                    style={{ marginRight: 5 }}
                    size={fontSize}
                  />
                ) : null}

                <Wrapper style={{ maxWidth: 100, maxHeight: 20 }}>
                  <Copy
                    style={{
                      fontSize: fontSize,
                      whiteSpace: "nowrap",
                      overflow: "hidden"
                    }}
                  >
                    {children}
                  </Copy>
                </Wrapper>
                {loading ? (
                  <Wrapper style={{ marginLeft: 5, height: 12 }}>
                    <Spin size="small" style={{ fontSize: fontSize }} />
                  </Wrapper>
                ) : !!icon ? (
                  <Icon icon={icon} style={{ marginLeft: 5 }} size={fontSize} />
                ) : null}
              </Row>
            </Wrapper>
          </Wrapper>
        );
      }}
    />
  );
};
export { Tag };
