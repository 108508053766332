import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Container,
  KeyboardView,
  HTMLMarkdown,
  Copy,
  Bold,
  ExternalImage,
  Video,
  Gradient,
  Card
} from "app/NativeComponents/common";
import {
  NewHeader,
  InlineButton,
  SpinWrapper,
  GhostButton,
  SelectItem,
  BottomNavBar
} from "app/NativeComponents/snippets";

import { popAllSidePanels, openUrl } from "app/NativeActions";
import StripeForm from "app/NativeComponents/components/StripeForm";

class PayloadBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upsell_loading: false
    };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}
  handleBack() {
    this.props.popAllSidePanels();
  }
  render() {
    const {
      colors,
      customPayload,
      upsell_loading,
      selected_product,
      interval_toggle
    } = this.props;

    if (customPayload) {
      return (
        <Container>
          <NewHeader
            title={customPayload?.title}
            subtitle={customPayload?.subtitle}
            leftButton={{
              icon: "close",
              onPress: () => {
                this.handleBack();
              }
            }}
          />
          <KeyboardView style={{ flex: 1 }}>
            {customPayload?.options && customPayload?.options?.length == 1 ? (
              <SelectItem
                select_type={"radio"}
                selected={true}
                noPress={true}
                description={
                  interval_toggle == "year"
                    ? customPayload?.options[0]?.amountTextAnnually
                    : customPayload?.options[0]?.amountText
                }
                selected_background_color={colors.active_color_muted}
                subtext={
                  interval_toggle == "year"
                    ? customPayload?.options[0]?.amountSubTextAnnually
                    : ""
                }
              >
                {customPayload?.options[0].title}
              </SelectItem>
            ) : customPayload?.options ? (
              <>
                {customPayload?.options.map((item, index) => {
                  return (
                    <SelectItem
                      key={"option_" + index}
                      select_type={"radio"}
                      selected={selected_product?.slug == item?.slug}
                      onPress={() => {
                        this.props.selectProduct(item);
                      }}
                      description={
                        interval_toggle == "year"
                          ? item?.amountTextAnnually
                          : item?.amountText
                      }
                      selected_background_color={colors.active_color_muted}
                      subtext={
                        interval_toggle == "year"
                          ? item?.amountSubTextAnnually
                          : ""
                      }
                    >
                      {item.title}
                    </SelectItem>
                  );
                })}
              </>
            ) : null}

            {customPayload?.include_interval_toggle ? (
              <InlineButton
                text_color={colors.white_text_color}
                button_type={"full"}
                icon={interval_toggle == "year" ? "toggle-on" : "toggle-off"}
                iconColor={
                  interval_toggle == "year" ? colors.success_color : null
                }
                onPress={() => {
                  this.props.updateInterval(
                    interval_toggle == "year" ? "month" : "year"
                  );
                }}
              >
                {!!selected_product?.intervalToggle
                  ? customPayload.intervalToggle
                  : "Save 16% by paying annually."}
              </InlineButton>
            ) : null}
            {!!selected_product?.image ? (
              <Wrapper
                style={{
                  padding: 25,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <ExternalImage
                  style={{
                    resizeMode: "contain",
                    width: 400,
                    height: 120
                  }}
                  contain={true}
                  image={selected_product?.image}
                />
              </Wrapper>
            ) : null}
            {!!selected_product?.video ? (
              <Wrapper
                style={{
                  padding: 25,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Video
                  video={selected_product?.video}
                  height={225}
                  width={400}
                  autoPlay={true}
                  controls={false}
                  muted={true}
                  loop={true}
                />
              </Wrapper>
            ) : null}

            {!!selected_product?.content || !!selected_product?.contentTitle ? (
              <Wrapper
                style={{
                  padding: 25,
                  paddingTop:
                    !!selected_product?.image || !!selected_product?.video
                      ? 0
                      : 25
                }}
              >
                {!!selected_product?.contentTitle ? (
                  <Copy>
                    <Bold>{selected_product?.contentTitle}</Bold>
                  </Copy>
                ) : null}

                {!!selected_product?.content ? (
                  <HTMLMarkdown
                    richText={selected_product.content}
                    source={selected_product.content}
                    escapeHtml={false}
                  />
                ) : null}
              </Wrapper>
            ) : null}
          </KeyboardView>
          {this.props.user?.team_owner == 1 ? (
            <BottomNavBar>
              {this.props.creditCard && !!customPayload?.upgradeButton ? (
                <>
                  {!this.props.source_of_truth?.has_stripe_card_on_file ? (
                    <StripeForm
                      {...this.props}
                      loading={this.props.upsell_loading}
                      f
                      buttonText={customPayload?.upgradeButton}
                      saveBilling={this.props.onPurchase}
                      card={this.props.card}
                      billingFieldChanged={this.props.billingFieldChanged}
                    />
                  ) : (
                    <GhostButton
                      primary={true}
                      button_type="full"
                      loading={upsell_loading}
                      onPress={() => this.props.onPurchase(selected_product)}
                    >
                      {customPayload?.upgradeButton}
                    </GhostButton>
                  )}
                </>
              ) : (
                <>
                  {!!customPayload?.upgradeButton ? (
                    <>
                      <GhostButton
                        primary={true}
                        button_type="full"
                        disabled={this.props.restore_loading}
                        loading={upsell_loading}
                        onPress={() => this.props.onPurchase(selected_product)}
                      >
                        {customPayload?.upgradeButton}
                      </GhostButton>
                    </>
                  ) : null}
                </>
              )}
              {!!customPayload?.learnMoreButton &&
              !!customPayload?.learnMoreButtonUrl ? (
                <InlineButton
                  button_type="full"
                  onPress={() => {
                    openUrl(customPayload?.learnMoreButtonUrl);
                  }}
                >
                  {customPayload?.learnMoreButton}
                </InlineButton>
              ) : null}
              {this.props.restorePurchases ? (
                <InlineButton
                  button_type="full"
                  disabled={upsell_loading}
                  loading={this.props.restore_loading}
                  onPress={this.props.restorePurchases}
                >
                  Restore Purchases
                </InlineButton>
              ) : null}

              {!!customPayload?.skipButton ? (
                <InlineButton
                  button_type="full"
                  onPress={() => {
                    this.handleBack();
                  }}
                >
                  {customPayload?.skipButton}
                </InlineButton>
              ) : null}
            </BottomNavBar>
          ) : null}
        </Container>
      );
    } else {
      return (
        <Container>
          <Wrapper
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <SpinWrapper text={"Loading"} />
          </Wrapper>
        </Container>
      );
    }
  }
}

const mapStateToProps = ({ auth, native, settings, billing }) => {
  const { token, user } = auth;
  const { device, isMobile, platform } = native;
  const { colors } = settings;
  const { source_of_truth } = billing;

  return { token, user, device, isMobile, platform, colors, source_of_truth };
};

export default connect(mapStateToProps, { popAllSidePanels })(PayloadBody);
